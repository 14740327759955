<script lang="ts">
import { defineComponent, ref, toRef, watch } from "vue";
import { useI18n } from "vue-i18n";
import remove from "lodash-es/remove";
import some from "lodash-es/some";
import { SearchDropdown } from "@/components/inputs/modalInputs";
import { searchBusinessFunctions } from "@/http_services/entities/common";
import { Tag } from "@/components/tag";

interface BusinessFunction {
  key: string
  label: string
}

export default defineComponent({
  name: "BusinessFunctionFilter",
  components: {
    SearchDropdown,
    Tag,
  },
  props: {
    clear: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["filter:select"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const selected = ref("");
    const businessFunctions = ref<BusinessFunction[]>([]);
    const clear = toRef(props, "clear");

    const clearAll = () => {
      businessFunctions.value = [];
      emit("filter:select", businessFunctions.value);
    };

    watch(clear, () => {
      clearAll();
    });

    watch(businessFunctions.value, (value) => {
      emit("filter:select", value.map(i => i.key));
    });

    const selectBusiness = (bf: BusinessFunction) => {
      if (!some(businessFunctions.value, bf))
        businessFunctions.value.push(bf);
    };

    const removeBusiness = (bf: BusinessFunction) => {
      remove(businessFunctions.value, bf);
    };

    return {
      t,
      selected,
      clearAll,
      searchBusinessFunctions,
      selectBusiness,
      removeBusiness,
      businessFunctions,
    };
  },
});
</script>

<template>
  <div class="filter-wrapper">
    <div v-if="businessFunctions.length > 0" class="tags">
      <Tag
        v-for="(item, index) in businessFunctions"
        :key="index"
        :tag="item.label"
        @click:close="removeBusiness(item)"
      />
    </div>
    <SearchDropdown
      id="business-function-filter"
      fetch-param-key="term"
      :fetch-method="searchBusinessFunctions"
      :select-item-callback="selectBusiness"
      :min-word-length="1"
      :update-input-model="() => ''"
      :input-placeholder="t('text.searchBusinessFunction')"
      :search-not-found-label="t('text.searchBusinessFunctionNotFound')"
      :search-error-label="t('text.searchBusinessFunctionError')"
    >
      <template #list-item="{ item }">
        {{ item.label }}
      </template>
    </SearchDropdown>
    <span class="clear-filter" role="button" @click="clearAll()">
      > {{ t(`persons.clearFilters`) }}
    </span>
  </div>
</template>

<style lang="scss" scoped>
.filter-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.select-filter {
  margin-top: 1rem;
}

.clear-filter {
  margin-left: 0.875rem;
  font-size: 0.875rem;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  gap: 0.5rem;
}
</style>
