<script setup lang="ts">
import ShortlistBody from "./components/ShortlistBody.vue";
import { SvgIcon } from "@/components/icon";
import { SliderLevelView } from "@/components/slider";
import { CULTURAL_MATCH_TITLES } from "@/entities/project/construction-guide";
import type { SearchCandidate } from "@/common/composables/useShortlist";
import attributesEditable from "@/projects/pages/projects/[id]/shortlist/[person]/components/attributesEditable.vue";
import { accordionKeyMapInterviewEvaluation } from "@/projects/pages/projects/[id]/interviews/utils/index";

interface CulturalMatchEvaluation { practical: EvaluationType; logical: EvaluationType; reflexive: EvaluationType; affective: EvaluationType }

interface EvaluationType {
  observations: string
  level: valueMapKey | null
  score: number | null
}
type valueMapKey = keyof typeof valueMap | null;
const valueMap = {
  EMPTY: 0,
  LOW: 1,
  AVERAGE: 2,
  HIGH: 3,
  VERY_HIGH: 4,
};
const route = useRoute();
const { t } = useI18n();

const projectId = computed(() => String(route.params.id));
const personID = computed(() => String(route.params.person));

const { data: project, error } = await useProject(String(route.params.id));

const {
  data: construction,
} = useConstructionOrganizationQuery(String(projectId.value));

const {
  data: interview,
} = useInterviewEvaluationQuery(String(projectId.value), String(personID.value));

const person = ref<SearchCandidate | null>(null);

const sliderDefaults = {
  showLegend: true,
  showScore: true,
  showControlMarker: true,
  background: "white-background",
  selectedColor: "yellow",
  selectedMarkerColor: "yellow",
  controlColor: "yellow-slider",
  controlMarkerColor: "gray-shade-dark",
  showSelectedMarker: false,
};

const icons = {
  [CULTURAL_MATCH_TITLES.AFFECTIVE]: "heart_icon",
  [CULTURAL_MATCH_TITLES.LOGICAL]: "brain_icon",
  [CULTURAL_MATCH_TITLES.PRACTICAL]: "gears_icon",
  [CULTURAL_MATCH_TITLES.REFLEXIVE]: "feather_icon",
};

const choices = computed(() => ([
  "LOW",
  "AVERAGE",
  "HIGH",
  "VERY_HIGH",
]
  .map(data => ({
    label: t(`projects.generic.scale.enum.${data}`).toUpperCase(),
    value: data,
  }))
));

const options = computed(() => Object.keys(construction.value?.ideal_profile?.cultural_match || []).map(match => ({
  key: match.toUpperCase(),
  title: t(`cultural_match.${match.toUpperCase()}`),
  tooltip: match.toUpperCase(),
  answer: construction.value?.ideal_profile?.cultural_match[match as keyof CulturalMatchEvaluation],
  selected: interview.value?.cultural_match[match],
})));

function checkActive(value: any) {
  return !value || value === "EMPTY" ? "" : "active";
}

function formatScore(score: number | null | undefined) {
  try {
    if (!score)
      return 0;
    return (
      (!Number.isInteger(score) ? score?.toFixed(2).replace(".", ",") : score?.toFixed(0)) ?? 0
    );
  }
  catch (error) {
    return 0;
  }
}

async function fetchCandidate() {
  if (!route.params.person)
    return;
  const response = await searchCandidates({
    project_id: String(route.params.id),
    person_id: String(route.params.person),
    project_stage: "SHORTLIST",
    skip: 0,
    limit: 1,
  });
  person.value = (response && response[0]) ? response[0] : null;
}

watchEffect(async () => {
  await fetchCandidate();
});
const isEditing = ref();
</script>

<template>
  <ShortlistBody
    :project="project"
    :candidate="person"
    :error="error"
    :score="person?.calculated_scores?.cultural_match_mean"
    icon="icon_shortlist_cultural_match"
    :title="t('projects.shortlist.titles.culturalMatch')"
    :is-editing="isEditing"
    @edit="isEditing = !isEditing"

    @change:person="fetchCandidate"
  >
    <div v-if="!isEditing" class="attributes-component">
      <div v-for="(item, index) in options" :key="index" class="content">
        <div class="data">
          <span class="data-icon" :class="[checkActive(item.answer)]">
            <SvgIcon :icon="icons[item.key as never]" margin="none" />
          </span>
          <span class="data-label">
            {{ item.title }}
          </span>
        </div>
        <div class="item-evaluate">
          <div class="item-slider">
            <SliderLevelView
              :options="choices"
              :control-value="item.answer"
              :selected-value="item.selected?.level"
              :description="t(`projects.shortlist.enums.cultural.${item.key}`)"
              :show-labels="index === 0"
              :show-selected-marker="sliderDefaults.showSelectedMarker"
              :show-control-marker="sliderDefaults.showControlMarker"
              :control-color="sliderDefaults.controlColor"
              :control-marker-color="sliderDefaults.controlMarkerColor"
              :control-title="t('projects.screening.guide.PostScreening.educationBackground.idealProfile')"
              :selected-color="sliderDefaults.selectedColor"
              :selected-marker-color="sliderDefaults.selectedMarkerColor"
              :selected-title="t('projects.screening.labels.candidate')"
              :score="item.selected?.score"
            />
          </div>
        </div>
      </div>
      <div v-if="sliderDefaults.showLegend" class="legends">
        <div class="item">
          <div class="marker marker-a" :class="[`--bg-${sliderDefaults.controlMarkerColor}`]" />
          <div class="marker-title">
            {{ t("projects.screening.guide.PostScreening.educationBackground.idealProfile") }}
          </div>
        </div>
        <div class="item">
          <div class="marker marker-b" :class="[`--bg-${sliderDefaults.selectedMarkerColor}`]" />
          <div class="marker-title">
            {{ t("projects.screening.labels.candidate") }}
          </div>
        </div>
      </div>
    </div>
    <attributesEditable
      v-else
      track-color="#F4F4F4"
      background-color="#ffb600"
      marker-color="#000"
      icon-color="#ffb600"
      :tab="accordionKeyMapInterviewEvaluation['cultural-match']"
      construction-value-color="#F8D272"
      @edit="isEditing = !isEditing"
    />
  </ShortlistBody>
</template>

<route lang="yaml">
meta:
  layout: project-stage
  stage: shortlist
  tab: cultural-match
</route>

<style lang="scss" scoped>
.attributes-component {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1.5rem;

  .content {
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr 4fr;

    .item-radios {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0 1rem 0 2rem;
    }
  }

  .data {
    display: flex;
    min-width: 13rem;
    flex-direction: row;
    align-items: center;
    gap: 1rem;

    .data-icon {
      display: flex;
      width: 55px;
      min-width: 55px;
      height: 55px;
      min-height: 55px;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      box-shadow: 0 2px 4px #00000029;
      color: $gray-border;
    }

    .data-icon.active {
      background-color: $white-background;
      color: $yellow;
    }

    .data-label {
      width: -webkit-fill-available;
      line-height: 1.3;
      text-transform: uppercase;
    }

    .help {
      text-transform: none;

      svg {
        color: $gray-shade-dark;
      }
    }
  }

  .legends {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 3rem;
    gap: 4rem;
    margin-inline-start: 16rem;

    .item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;

      .marker {
        width: 7px;
        height: 20px;
        background: $gray-shade-dark;
      }

      .marker-a {
        width: 5px;
        height: 26px;
      }

      .marker-b {
        width: 11px;
        height: 20px;
      }

      .marker-title {
        font-size: 0.75rem;
      }
    }
  }

  .form-actions {
    display: flex;
    margin: 2rem 0;
    gap: 2rem;
    grid-column: 1 / -1;
  }

  .item-evaluate {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    gap: 1.5rem;

    .item-slider {
      width: 100%;
    }
  }
}

@mixin colors-change($colors-map) {
  @each $key, $value in $colors-map {
    .attributes-component {
      .legends {
        .item {
          .marker.--bg-#{$key} {
            background: $value;
          }
        }
      }
    }
  }
}

@include colors-change($all-colors);
</style>
