<script setup lang="ts">
import cloneDeep from "lodash-es/cloneDeep";
import get from "lodash-es/get";

import {
  paginateTexts,
} from "@/utils/text";

// schemas
import type {
  InterviewActivities,
  InterviewRankedCandidatesSchema,
} from "@/schemas/project";
import Resume from "@/common/components/Export/Resume.vue";
import ExportPage from "@/common/components/Export/ExportPage.vue";
import { formatScore } from "@/utils/numbers";

const props = withDefaults(
  defineProps<{
    isExport: boolean
    personName?: string | null
    idealProfile: any
    interviewActivitie: InterviewActivities
    ranked: InterviewRankedCandidatesSchema
    score: number | null
    interview: any
  }>(),
  {
    personName: null,
    isExport: false,
  },
);

enum MotivationTypes {
  text = "text",
  attraction = "attraction",
  repulsion = "repulsion",
  retention = "retention",
}

const { t } = useI18n();

interface PageItems {
  label: string | null
  value: string
  type?: string
  bullet: boolean
}

interface PackageType {
  label: string | null
  value: string
  bullet: boolean
  type: string
  score?: string | number | null
}

interface PagesTypes {
  package: PackageType[]
  career: PackageType[]
  environment: PackageType[]
  personal: PackageType[]
  opinion: string | null
}

const { isExport, idealProfile, interviewActivitie, ranked } = toRefs(props);

const emptyValue = {
  package: [],
  career: [],
  environment: [],
  personal: [],
  opinion: null,
} as PagesTypes;

const pages = ref<PagesTypes[]>([]);

const motivation = computed(
  () => props.interview || null,
);

const boxWidth = 200;
const fontSize = "14px";
const font = "Open Sans";
const maxLines = 24;
const page = ref(0);
const line = ref(0);

function separateValues(data: PackageType[], type: string): PageItems[] {
  const _separated: PageItems[] = [];
  (data || [])?.forEach((item: PackageType) => {
    if (item.type === type && item.value !== "" && item.value !== null) {
      _separated.push({
        bullet: item.bullet,
        label: item.label,
        value: item.value,
      });
    }
  });
  return _separated;
}

function addInPage(data: any[], path: string, title: string, type = "text") {
  let breaked = false;
  data?.forEach((item, index) => {
    if (index === 0 && item.text === "")
      breaked = true;
    if (index > 1)
      breaked = false;

    if (line.value >= maxLines) {
      page.value += 1;
      line.value = 0;
    }
    if (!pages.value[page.value])
      pages.value[page.value] = cloneDeep(emptyValue);

    pages.value[page.value][path].push({
      label: (!item.break || breaked) ? title : null,
      type,
      bullet: !item.break,
      value: item.text,
    });

    line.value += item.lines;
  });
}

function paginatePage() {
  const { package: profilePackage, career, work_environment, personal_life } = props.idealProfile;
  pages.value = [];

  pages.value[page.value] = cloneDeep(emptyValue);

  const packageTitles = {
    salary: "salary",
    short_term_incentive: "icp",
    long_term_incentive: "ilp",
    other_incentive: "others",
    total_cash: "totalCash",
  };

  const motivationEvaluateCommon = {
    attraction: "attraction",
    repulsion: "repulsion",
    retention: "retention",
  };

  const motivationTitles = {
    ...packageTitles,
    ...motivationEvaluateCommon,
    career: "career",
    environment: "environment",
    personal: "personal",
  };

  const resetPage = () => {
    page.value = 0;
    line.value = 0;
  };

  const paginate = (text: string, title?: string, breakLines = " ") => {
    return paginateTexts(
      text,
      fontSize,
      boxWidth,
      maxLines,
      line.value,
      title ? String(`${title}: `) : "",
      font,
      breakLines,
      "normal",
    );
  };

  // package
  resetPage();
  Object.keys(packageTitles)?.forEach((key) => {
    const text = get(profilePackage, key, "");
    const title = t(`exportPDF.labels.${get(motivationTitles, key)}`);
    if (text?.trim() !== "") {
      addInPage(paginate(text, title), "package", title, MotivationTypes.text);
      line.value += 1;
    }
  });
  Object.keys(motivationEvaluateCommon)?.forEach((key) => {
    const text = get(motivation.value, `package.${key}`, "");
    const title = t(`exportPDF.labels.${get(motivationTitles, key)}`);
    if (text?.trim()) {
      line.value += 1;
      addInPage(paginate(text, title), "package", title, MotivationTypes[key]);
      line.value += 1;
    }
  });

  // career
  resetPage();
  Object.values(career).forEach((item) => {
    const title = t("exportPDF.labels.career");
    if (item?.trim()) {
      addInPage(paginate(item, null), "career", title, MotivationTypes.text);
      line.value += 1;
    }
  });
  Object.keys(motivationEvaluateCommon)?.forEach((key) => {
    const text = get(motivation.value, `career.${key}`, "");
    const title = t(`exportPDF.labels.${get(motivationTitles, key)}`);
    if (text?.trim()) {
      line.value += 1;
      addInPage(paginate(text, title), "career", title, MotivationTypes[key]);
      line.value += 1;
    }
  });

  // work_environment
  resetPage();
  Object.values(work_environment).forEach((item) => {
    const title = t("exportPDF.labels.environment");
    if (item?.trim()) {
      addInPage(paginate(item, null), "environment", title, MotivationTypes.text);
      line.value += 1;
    }
  });

  Object.keys(motivationEvaluateCommon)?.forEach((key) => {
    const text = get(motivation.value, `work_environment.${key}`, "");
    const title = t(`exportPDF.labels.${get(motivationTitles, key)}`);
    if (text?.trim()) {
      line.value += 1;
      addInPage(paginate(text, title), "environment", title, MotivationTypes[key]);
      line.value += 1;
    }
  });

  // personal_life
  resetPage();
  Object.values(personal_life).forEach((item) => {
    const title = t("exportPDF.labels.personal");
    if (item?.trim()) {
      addInPage(paginate(item, null), "personal", title, MotivationTypes.text);
      line.value += 1;
    }
  });
  line.value += 1;
  Object.keys(motivationEvaluateCommon)?.forEach((key) => {
    const text = get(motivation.value, `personal_life.${key}`, "");
    const title = t(`exportPDF.labels.${get(motivationTitles, key)}`);
    if (text?.trim()) {
      line.value += 1;
      addInPage(paginate(text, title), "personal", title, MotivationTypes[key]);
      line.value += 1;
    }
  });
}

watchEffect(async () => {
  paginatePage();
});
</script>

<template>
  <ExportPage
    v-for="(page, index) in pages"
    :key="index"
    :is-export="isExport"
    :page-index="index"
    :page-name="t('exportPDF.summaryConstructionBeta.motivation')"
    :person-name="personName"
    :opinion="page.opinion"
  >
    <div class="pdf-motivation">
      <div class="box-ranked">
        <div class="box-score final">
          {{ formatScore(props.score || 0) }}%
        </div>
      </div>
      <div class="box-items">
        <div class="box">
          <div class="pdf-title">
            {{ t("exportPDF.labels.package") }}
          </div>
          <div v-if="(page.package || []).length > 0" class="box-content">
            <Resume
              :show-title="false"
              :show-label="true"
              :show-circle="true"
              font-size="0.875rem"
              line-height="1.25rem"
              :items="separateValues(page.package, MotivationTypes.text)"
            />
            <div
              v-for="(data, itemId) in separateValues(page.package, MotivationTypes.attraction)"
              v-show="data?.value"
              :key="itemId"
              class="reason"
              :class="[MotivationTypes.attraction]"
            >
              <strong v-if="data.label"> {{ data.label }}: </strong>
              <span v-html="data.value" />
            </div>
            <div
              v-for="(data, itemId) in separateValues(page.package, MotivationTypes.repulsion)"
              v-show="data?.value"
              :key="itemId"
              class="reason"
              :class="[MotivationTypes.repulsion]"
            >
              <strong v-if="data.label"> {{ data.label }}: </strong>
              <span v-html="data.value" />
            </div>
            <div
              v-for="(data, itemId) in separateValues(page.package, MotivationTypes.retention)"
              v-show="data?.value"
              :key="itemId"
              class="reason"
              :class="[MotivationTypes.retention]"
            >
              <strong v-if="data.label"> {{ data.label }}: </strong>
              <span v-html="data.value" />
            </div>
          </div>
          <div class="box-score">
            {{ formatScore(motivation?.package?.score || 0) }}%
          </div>
        </div>
        <div class="box">
          <div class="pdf-title">
            {{ t("exportPDF.labels.career") }}
          </div>
          <div v-if="(page.career || []).length > 0" class="box-content">
            <Resume
              :show-title="false"
              :show-label="false"
              :show-circle="true"
              font-size="0.875rem"
              line-height="1.25rem"
              :items="separateValues(page.career, MotivationTypes.text)"
            />
            <div
              v-for="(data, itemId) in separateValues(page.career, MotivationTypes.attraction)"
              v-show="data?.value"
              :key="itemId"
              class="reason"
              :class="[MotivationTypes.attraction]"
            >
              <strong v-if="data.label"> {{ data.label }}: </strong>
              <span v-html="data.value" />
            </div>
            <div
              v-for="(data, itemId) in separateValues(page.career, MotivationTypes.repulsion)"
              v-show="data?.value"
              :key="itemId"
              class="reason"
              :class="[MotivationTypes.repulsion]"
            >
              <strong v-if="data.label"> {{ data.label }}: </strong>
              <span v-html="data.value" />
            </div>
            <div
              v-for="(data, itemId) in separateValues(page.career, MotivationTypes.retention)"
              v-show="data?.value"
              :key="itemId"
              class="reason"
              :class="[MotivationTypes.retention]"
            >
              <strong v-if="data.label"> {{ data.label }}: </strong>
              <span v-html="data.value" />
            </div>
          </div>
          <div class="box-score">
            {{ formatScore(motivation?.career?.score || 0) }}%
          </div>
        </div>
        <div class="box">
          <div class="pdf-title">
            {{ t("exportPDF.labels.environment") }}
          </div>
          <div v-if="(page.environment || []).length > 0" class="box-content">
            <Resume
              :show-title="false"
              :show-label="false"
              :show-circle="true"
              font-size="0.875rem"
              line-height="1.25rem"
              :items="separateValues(page.environment, MotivationTypes.text)"
            />
            <div
              v-for="(data, itemId) in separateValues(page.environment, MotivationTypes.attraction)"
              v-show="data?.value"
              :key="itemId"
              class="reason"
              :class="[MotivationTypes.attraction]"
            >
              <strong v-if="data.label"> {{ data.label }}: </strong>
              <span v-html="data.value" />
            </div>
            <div
              v-for="(data, itemId) in separateValues(page.environment, MotivationTypes.repulsion)"
              v-show="data?.value"
              :key="itemId"
              class="reason"
              :class="[MotivationTypes.repulsion]"
            >
              <strong v-if="data.label"> {{ data.label }}: </strong>
              <span v-html="data.value" />
            </div>
            <div
              v-for="(data, itemId) in separateValues(page.environment, MotivationTypes.retention)"
              v-show="data?.value"
              :key="itemId"
              class="reason"
              :class="[MotivationTypes.retention]"
            >
              <strong v-if="data.label"> {{ data.label }}: </strong>
              <span v-html="data.value" />
            </div>
          </div>
          <div class="box-score">
            {{ formatScore(motivation?.work_environment?.score || 0) }}%
          </div>
        </div>
        <div class="box">
          <div class="pdf-title">
            {{ t("exportPDF.labels.personal") }}
          </div>
          <div v-if="(page.personal || []).length > 0" class="box-content">
            <Resume
              :show-title="false"
              :show-label="false"
              :show-circle="true"
              font-size="0.875rem"
              line-height="1.25rem"
              :items="separateValues(page.personal, MotivationTypes.text)"
            />
            <div
              v-for="(data, itemId) in separateValues(page.personal, MotivationTypes.attraction)"
              v-show="data?.value"
              :key="itemId"
              class="reason"
              :class="[MotivationTypes.attraction]"
            >
              <strong v-if="data.label"> {{ data.label }}: </strong>
              <span v-html="data.value" />
            </div>
            <div
              v-for="(data, itemId) in separateValues(page.personal, MotivationTypes.repulsion)"
              v-show="data?.value"
              :key="itemId"
              class="reason"
              :class="[MotivationTypes.repulsion]"
            >
              <strong v-if="data.label"> {{ data.label }}: </strong>
              <span v-html="data.value" />
            </div>
            <div
              v-for="(data, itemId) in separateValues(page.personal, MotivationTypes.retention)"
              v-show="data?.value"
              :key="itemId"
              class="reason"
              :class="[MotivationTypes.retention]"
            >
              <strong v-if="data.label"> {{ data.label }}: </strong>
              <span v-html="data.value" />
            </div>
          </div>
          <div class="box-score">
            {{ formatScore(motivation?.personal_life?.score || 0) }}%
          </div>
        </div>
      </div>
    </div>
  </ExportPage>
</template>

<style lang="scss" scoped>
.pdf-motivation {
  display: flex;
  height: 171mm;
  flex-direction: column;
  gap: 0.5rem;

  .box-ranked {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;

    .box-score {
      border-color: $yellow;
      background: $yellow;
      color: $white-full;
    }
  }

  .box-score {
    display: flex;
    min-width: 5rem;
    height: 2.625rem;
    align-items: center;
    justify-content: center;
    border: 1px solid $gray-text;
    background: $white-full;
    border-radius: 6px;
    color: $gray-text;
    font-size: 1.25rem;
    font-weight: bold;
  }

  .box-items {
    display: flex;
    height: 151mm;
    flex-direction: row;
    gap: 2rem;

    .box {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: space-between;
      gap: 0.5rem;

      .pdf-title {
        margin-bottom: 0.75rem;
        color: $gray-dark;
        font-size: 1.125rem;
        font-weight: bold;
        line-height: 1.5rem;
        text-transform: uppercase;
      }

      .box-content {
        display: flex;
        flex: 1;
        flex-direction: column;
        gap: 1rem;

        .reason {
          padding: 0.5rem 0.875rem;
          border-radius: 0.5rem;
          font-size: 0.875rem;

          span {
            font-size: 0.875rem;
            line-height: 1.25rem;
          }
        }

        .attraction {
          background: $yellow;
          color: $gray-slider;

          strong,
          span {
            color: $gray-slider;
          }
        }

        .retention {
          background: $gray-x17;
        }

        .repulsion {
          background: $gray-dark;
          color: $white-full;

          strong,
          span {
            color: $white-full;
          }
        }
      }
    }
  }
}
</style>
