<script setup lang="ts">
import * as popover from "@zag-js/popover";
import { normalizeProps, useMachine } from "@zag-js/vue";
import bson from "bson-objectid";
import CloseIcon from "@/assets/images/square_x_icon.svg";

interface Props {
  hasCloseAction: boolean
  closeOnBlur: boolean
  closeButtonClassName: string | undefined
}

const props = withDefaults(defineProps<Props>(), {
  hasCloseAction: true,
  closeOnBlur: true,
});
const emit = defineEmits(["close", "open"]);
const { t } = useI18n();
const [state, send] = useMachine(popover.machine({
  id: bson().toHexString(),
  closeOnInteractOutside: props.closeOnBlur,
  onClose: () => emit("close"),
  onOpen: () => emit("open"),
}));
const api = computed(() => popover.connect(state.value, send, normalizeProps));
</script>

<template>
  <div>
    <div v-bind="api.triggerProps">
      <slot name="trigger" />
    </div>
    <Teleport to="body">
      <div class="index-1" v-bind="api.positionerProps">
        <div v-bind="api.contentProps">
          <slot name="title" />

          <button v-if="props.hasCloseAction" v-bind="api.closeTriggerProps" :aria-label="t('text.close')">
            <CloseIcon :class="closeButtonClassName" />
          </button>
          <slot name="content" v-bind="{ close: api.close }" />
        </div>
      </div>
    </Teleport>
  </div>
</template>

<style scoped lang="scss">
[data-part="close-trigger"] {
  border: none;
  background-color: transparent;
  cursor: pointer;
  height: 1.25rem;
  padding: 0;
  margin: 0;
  float: right;

  svg {
    stroke: $gray-low;
  }

  &:hover {
    svg {
      stroke: $primary;
    }
  }
}

[data-part="content"] {
  outline: unset;
  border-radius: 6px;
  background-color: $white-full;
  padding: 0.5rem;
  box-shadow: 0 3px 5px #00000048;

  .modal-close {
    float: right;
  }
}

.index-1 {
  z-index: 1;
}
</style>
