<script lang="ts">
import { defineComponent } from "vue";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiChevronRight } from "@mdi/js";

export default defineComponent({
  name: "DropdownFilter",
  components: {
    SvgIcon,
  },
  props: {
    name: String,
    filterActive: Boolean,
    filterOpen: Boolean,
  },
  emits: ["click", "filter:open"],
  setup(props, { emit }) {
    const openOrCloseDropdown = () => {
      emit("filter:open", props.filterOpen ? "" : props.name);
    };

    return {
      openOrCloseDropdown,
      chevronRight: mdiChevronRight,
    };
  },
});
</script>

<template>
  <div class="dropdown" :class="[{ 'filter-active': filterActive, 'dropdown--open': filterOpen }]">
    <button
      data-test="dropdown-button"
      class="dropdown__button"
      :class="[{ open: filterOpen }]"
      @click="openOrCloseDropdown()"
    >
      <SvgIcon
        type="mdi"
        size="24"
        class="chevron"
        :path="chevronRight"
        :rotate="filterOpen ? 90 : 0"
      />
      {{ name }}
    </button>

    <transition name="slide">
      <div v-show="filterOpen" class="filters">
        <slot name="filter" />
      </div>
    </transition>
  </div>
</template>

<style scoped lang="scss">
.dropdown {
  margin: 2.5rem 0;

  &__button {
    display: flex;
    align-items: center;
    padding: 0;
    border: none;
    background-color: transparent;
    color: $gray-dark;
    cursor: pointer;
    font-family: inherit;
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 2.4px;
    text-align: left;
    text-transform: uppercase;

    .chevron {
      --translation: -35%;

      transform: translateX(var(--translation));
      transition: transform linear 100ms;
    }

    &.open .chevron {
      transform: translateX(var(--translation)) rotate(90deg);
    }
  }

  &--open {
    .dropdown__button {
      color: $primary;
    }
  }

  &.filter-active {
    position: relative;

    &::before {
      --size: 9px;

      position: absolute;
      top: 0.75rem;
      right: 0;
      width: var(--size);
      height: var(--size);
      border-radius: 50%;
      background-color: $yellow;
      content: "";
      transform: translateY(-50%);
    }
  }
}

/* Transition classes */
.slide-enter-from,
.slide-leave-to {
  transform: scaleY(0);
}

.slide-enter-active,
.slide-leave-active {
  transform-origin: top;
  transition: transform ease-out 100ms;
}

.slide-enter-to,
.slide-leave-from {
  transform: scaleY(1);
}
</style>
