export const validator = {
  required: "Required field",
  email: "Invalid e-mail",
  requiredName: "Insert a name",
  requiredDomain: "Insert a domain",
  containsHttp: "The URL must start with https://",
  url: "Insert a valid URL",
  linkedin: "Invalid linkedin URL",
  facebook: "Invalid facebook URL",
  dateGreaterThanToday: "Date must be greater than today",
  requiredFields: "Fill at least one of the fields: phone, email or Linkedin",
};
