export type ImageSizesType = "mini" | "small" | "medium" | "large" | "original";

export enum ImageSizesEnum {
  mini = "30x30",
  small = "60x60",
  medium = "70x70",
  large = "140x140",
  original = "original",
};

export enum ImagesTypesEnum {
  persons = "persons",
  corporations = "corporations",
};
