import type { Ref } from "vue";
import { computed, ref } from "vue";
import type { MomentInput } from "moment";
import moment from "moment";
import { isNumber } from "lodash";
import { differenceInDays, format, parseISO } from "date-fns";
import { range } from "@/utils/array";
import { i18n } from "@/locales/i18n";

const { t } = i18n.global;
const ECMA_START_YEAR = 1970;

export class BuildStringDuration {
  constructor(private days: number, private months: number, private years: number) {
    if (this.days > 15)
      this.months += 1;
  }

  getYearString(): string {
    if (this.years === 0)
      return "";

    if (this.years === 1)
      return `1 ${t("date.yearSingular")}`;

    return `${this.years} ${t("date.yearPlural")}`;
  }

  getMonthString(): string {
    if (this.months === 0)
      return "";

    if (this.months === 1)
      return `1 ${t("date.monthSingular")}`;

    return `${this.months} ${t("date.monthPlural")}`;
  }

  build(): string {
    if (this.years === 0 && this.months === 0)
      return `0 ${t("date.monthPlural")}`;

    if (this.months === 12) {
      this.years += 1;
      return `${this.getYearString()}`;
    }
    return `${this.getYearString()} ${this.getMonthString()}`.trim();
  }
}

export function humanizedDuration(startDate: MomentInput, endDate: MomentInput): string {
  const diff = moment(endDate).diff(moment(startDate));
  return moment.duration(diff).humanize();
}

export function genLastYearsArray(interval: number): number[] {
  const currentYear = new Date().getFullYear();
  const periodArr = range(interval);
  return periodArr.map(i => currentYear - i);
}

export function genYearsFrom(startYear: number = ECMA_START_YEAR, futureYear?: number): number[] {
  const yearsInTheFureture = futureYear || 0;

  const years = Array.from(
    { length: new Date().getFullYear() + yearsInTheFureture - startYear + 1 },
    (_, index) => new Date(`01/01/${startYear + index}`).getFullYear(),
  ).reverse();
  return years;
}

export const years = genYearsFrom();

export function genMonthsArray(): string[] {
  const months = JSON.parse(t("date.months"));
  return months;
}

export const months = computed(() =>
  genMonthsArray().map((month, index) => ({ label: month, value: index })));

export function formatDate(format: string, date: Date | string): string {
  return moment(date).format(format);
}
export function completeDate(date: Date | string | null | undefined): string {
  return date
    ? formatDate("DD/MM/YYYY", date)
    : "";
}
export function isoFormat(date: Date | string | null | undefined): string {
  return date
    ? formatDate("YYYY-MM-DD", date)
    : "";
}

export function parseAndGetDates<T>(date_name: string, obj: Ref<T>) {
  return (): { [key: string]: Ref<string | number | null> } => {
    const date = obj.value[date_name];
    const parsedDate = moment(date);
    const prefix = date_name.split("_")[0];

    return {
      [`${prefix}Month`]: ref(date ? parsedDate.month() : null),
      [`${prefix}Year`]: ref(date ? parsedDate.year() : null),
    };
  };
}

export function watchDatesAndSetInObj<T>(date_name: string, obj: Ref<T>) {
  return ([month, year]: [number, number]): void => {
    if (isNumber(month) && year)
      obj.value[date_name] = moment(new Date(year, month, 1)).format("YYYY-MM-DD");
    else
      obj.value[date_name] = null;
  };
}

export function getDateRef<T>(dateName: string, obj: Ref<T>): Ref<any> {
  const date: string = obj.value[dateName];
  return ref(date ? new Date(date) : null);
}

export function watchDate<T>(dateName: string, obj: Ref<T>) {
  return (value: Date): void => {
    const date = moment(value);
    obj.value[dateName] = date.isValid() ? date.format("YYYY-MM-DD") : null;
  };
}

export function formatMonthAndYear(date: string): string | null {
  if (!date)
    return null;

  const month = format(parseISO(date), "MMM");
  const year = format(parseISO(date), "yyyy");
  const ofString = t("persons.details.of");

  return `${month.toLowerCase()}. ${ofString} ${year}`;
}

export function formatMonthAndYearWithSlash(date: string): string | null {
  if (!date)
    return null;

  const month = format(parseISO(date), "MMM");
  const year = format(parseISO(date), "yyyy");

  return `${month}/${year}`;
}

const isEmptyDatesParams = (dates: string[]) => !dates?.length;

function validateIfAllDateParamsAreStrings(dates: string[]) {
  dates?.forEach((date: string, index: number) => {
    if (typeof date !== "string")
      throw new Error(`The date param of index ${index} is not a string`);
  });
}

function ascendingSortDates(dates: string[]) {
  return dates.sort((a, b) => Number(new Date(a)) - Number(new Date(b)));
}

function descendingSortDates(dates: string[]) {
  return dates.sort((a, b) => Number(new Date(b)) - Number(new Date(a)));
}

export function sortDateString(sortBy: "asc" | "desc", ...dates: string[]) {
  if (isEmptyDatesParams(dates))
    return;

  validateIfAllDateParamsAreStrings(dates);

  if (sortBy === "asc")
    return ascendingSortDates(dates);

  if (sortBy === "desc")
    return descendingSortDates(dates);
}
export function sortByStartDate(a: { start_date: string }, b: { start_date: string }): number {
  const aStartDate: Date = new Date(a.start_date);
  const bStartDate: Date = new Date(b.start_date);
  return bStartDate.getTime() - aStartDate.getTime();
}

export function calculateDaysDifference(init: string, end?: string | null, current?: boolean): number {
  const now = new Date();
  if (!end && current)
    end = now.toISOString();

  if (!current && !end)
    return 0;

  return differenceInDays(parseISO(end || ""), parseISO(init));
}

export function getDateDif(date: { init?: string | null; end?: string | null; current: boolean }[]): string {
  let totalDays = 0;

  for (const period of date) {
    if (!period.init)
      return "";

    totalDays += calculateDaysDifference(period.init, period.end, period.current);
  }

  if (date.length === 1 && totalDays === 0)
    return "";

  const year = Math.floor(totalDays / 365);

  const remainingDays = totalDays - year * 365;

  const month = Math.floor(remainingDays / 30);

  const titleYear = year > 1 ? t("date.yearPlural") : t("date.yearSingular");
  const titleMonth = month > 1 ? t("date.monthPlural") : t("date.monthSingular");

  if (year > 0 && month > 0)
    return `${year} ${titleYear} ${t("date.and")} ${month} ${titleMonth}`;
  else if (year > 0 && month === 0)
    return `${year} ${titleYear}`;
  else if (year === 0 && month > 0)
    return `${month} ${titleMonth}`;
  else if (year === 0 && month === 0)
    return `1 ${t("date.monthSingular")}`;
  else return "";
}
