import type { Module } from "vuex";
import { defaultLocale } from "@/locales/i18n";

const LanguageStoreModule: Module<any, any> = {
  state() {
    const language = defaultLocale;
    return {
      language,
    };
  },
  getters: {
    GET_LANGUAGE(state) {
      return state.language;
    },
  },
  mutations: {
    SET_LANGUAGE: (state, payload) => {
      state.language = payload;
      localStorage.setItem("imua-locale", payload);
    },
  },
};

export default LanguageStoreModule;
