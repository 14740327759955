<script setup lang="ts">
import { computed, defineEmits, inject } from "vue";
import Card from "../card/Card.vue";
import { SvgIcon } from "../icon";
import InfoHeader from "./components/InfoHeader/InfoHeader.vue";
import InfoDescription from "./components/InfoDescription/InfoDescription.vue";
import type { IPersonContent } from "./components";
import { PersonContentEditable } from "./components";
import type { Person } from "@/schemas/person";
import { completeDate } from "@/utils/dates";
import { changeImageSize } from "@/utils/images/resize-images";
import type { ImageSizesType } from "@/schemas/enums/images";

const props = withDefaults(
  defineProps<{
    person: Person
    personContent?: IPersonContent | null
    isEditing?: boolean
    isInProject?: boolean
    hasTimeline?: boolean
    score?: number
    scoreIndex?: number
    isRow?: boolean
    isPersonCard?: boolean
    borderColor?: "yellow" | "gray" | "transparent" | null
    imageSize?: ImageSizesType
  }>(),
  {
    isEditing: false,
    isInProject: false,
    hasTimeline: true,
    personContent: null,
    borderColor: "transparent",
    isPersonCard: false,
    imageSize: "large",
  },
);

const emit = defineEmits(["onSubmitPersonContentEditable", "onRestorePersonContent"]);

const { t } = useI18n();

const closePersonEdit = inject("closePersonEdit", () => []) as () => void;

const onShowDetails = inject("onShowDetails", null) as ((person: Person) => void) | null;

const cardBackgroundColor = computed(() => (props.person.avatar_url ? "#f4f4f4" : "#666"));

function getFirstLetters(value: string) {
  return value
    .split(/\s/)
    .filter(i => i)
    .map(word => word[0].toUpperCase())
    .slice(0, 2)
    .join("");
}

const personInitials = computed(() =>
  getFirstLetters(`${props.person.first_name} ${props.person.last_name}`));

const lastUpdated = computed(() => {
  const { updated_at, inserted_at } = props.person;
  if (updated_at)
    return completeDate(updated_at);
  return completeDate(inserted_at);
});

const progressScoreGap = computed(() => `${props.score || 0}%`);
const colorClass = computed<string>(() => {
  const classes = {
    yellow: "border border-yellow",
    gray: "border border-gray",
    transparent: "no-border",
  };
  return props.borderColor ? classes[props.borderColor] : "transparent";
});

function onSubmitPersonContentEditable(personContent: IPersonContent) {
  emit("onSubmitPersonContentEditable", personContent);
}

function onCancelPersonContentEditable() {
  closePersonEdit();
}
</script>

<template>
  <Card class="person-card" :class="{ 'person-card-score': !!score }">
    <div v-if="score" class="ranking-progress">
      <div class="progress-container">
        <div class="progress-content">
          <div class="progress-base">
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
        <div class="progress-content">
          <div class="progress-score">
            <div
              class="score-floating"
              :class="{
                'floating-right-corner': score > 95,
                'floating-left-corner': score < 5,
                'floating-left-corner_min': score < 2,
              }"
            >
              <div>#{{ (scoreIndex || 0) + 1 }} -</div>
              <div>{{ formatScore(score) }}%</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="person-content">
      <div class="avatar">
        <span
          role="button"
          class="avatar__brand avatar__brand"
          :class="[
            colorClass,
            { active: person.added_in_mapping },
            { isPlacement: person.is_added_in_placement },
          ]"
          @click="onShowDetails ? onShowDetails(person) : null"
        >
          <img
            v-if="person.avatar_url"
            :src="changeImageSize(person.avatar_url, imageSize)"
            :alt="`${person.first_name}  ${person.last_name}`"
            loading="lazy"
          >
          <p v-else style="padding: 0; margin: 0">{{ personInitials }}</p>
        </span>
        <p class="last-update">
          <span v-if="person.is_added_in_placement">
            <SvgIcon icon="star_yellow_icon" width="12" height="12" margin="0" />
            {{ t("projects.generic.placement") }}
          </span>
          <span>
            <SvgIcon icon="refresh_icon" width="14px" height="14px" margin="0" />
            {{ lastUpdated }}
          </span>
        </p>
      </div>
      <div class="details">
        <InfoHeader
          v-if="!isEditing"
          :person="person"
          :person-content="personContent"
          has-search-project
        >
          <template v-for="(_, name) in ($slots as {})" #[name]="slotData">
            <slot :name="name" v-bind="slotData || {}" />
          </template>
        </InfoHeader>
        <InfoDescription
          v-if="!isEditing"
          :is-person-card="isPersonCard"
          :person="person"
          :person-content="personContent"
          :has-timeline="hasTimeline"
          :is-row="isRow"
        />
        <PersonContentEditable
          v-if="isEditing"
          :editing="isEditing"
          :person-content="personContent"
          @submit="onSubmitPersonContentEditable"
          @cancel="onCancelPersonContentEditable"
          @restore="emit('onRestorePersonContent')"
        />
        <slot name="after-details" />
      </div>
    </div>
    <slot name="expand-content" />
  </Card>
</template>

<style lang="scss" scoped>
@mixin round-and-center($size) {
  width: $size;
  height: $size;
  border-radius: 50%;
  line-height: $size;
  text-align: center;
}

.person-card {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  .person-content {
    display: grid;
    width: 100%;
    grid-template-columns: auto 1fr;

    .avatar {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-right: 1rem;
      text-align: center;

      .last-update {
        display: flex;
        flex-direction: column;
        margin-top: 1.25rem;
        gap: 1rem;

        span {
          display: flex;
          align-items: center;
          gap: 0.5rem;
        }
      }

      img {
        border-radius: 100%;
      }

      p {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
      }

      &__brand {
        --brand-size: 140px;

        @include round-and-center(var(--brand-size));

        display: inline-block;
        overflow: hidden;
        background-color: v-bind(cardBackgroundColor);

        img {
          width: 100%;
          height: 100%;
          line-height: var(--brand-size);
          text-align: center;
        }

        p {
          color: #fff;
          font-size: 2rem;
        }
      }
    }
  }

  &.person-card-score {
    padding: 0;
    margin: 3.5rem 0;
    .avatar {
      padding: 1.5rem 0 1.5rem 1.5rem;
    }

    :deep(.details) {
      padding: 1.5rem 1.5rem 1.5rem 0;

      .info {
        width: 100%;
      }
    }
  }
}

.ranking-progress {
  position: relative;
  display: flex;
  height: 1.5rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  grid-column: span 2;

  .ranking-progress-hint {
    position: absolute;
    right: 50%;
    bottom: 2rem;
  }
}

.progress-container {
  display: grid;
  width: 100%;
  height: 1.5rem;
  margin: 0 auto;
  grid-template-columns: 1fr;
}

.progress-content {
  height: 1.5rem;
  grid-column-start: 1;
  grid-row-start: 1;
}

.progress-base {
  display: grid;
  width: 100%;
  height: 1.5rem;
  grid-template-columns: repeat(4, 1fr);

  > div:nth-child(1) {
    background: $gray-border;
    border-top-left-radius: 8px;
  }

  > div:nth-child(2) {
    background: #dcbe8d;
  }

  > div:nth-child(3) {
    background: #ebbb69;
  }

  > div:nth-child(4) {
    background: $yellow-dark;
    border-top-right-radius: 8px;
  }
}

.progress-score {
  position: relative;
  height: 1.5rem;
  margin-left: v-bind(progressScoreGap);
  background: #dedede;
  border-top-right-radius: 8px;
}

.score-floating {
  position: absolute;
  bottom: 2rem;
  left: -2.5rem;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background: #fff;
  box-shadow: 0 2px 3px #0000003b;
  gap: 0.5rem;

  &.floating-right-corner {
    left: -5rem;
  }

  &.floating-left-corner {
    left: -1.5rem;

    &_min {
      left: -0.5rem;
    }
  }

  > div:nth-child(1) {
    font-size: 1rem;
    font-weight: 700;
  }

  > div:nth-child(2) {
    color: $primary;
    font-size: 1rem;
    font-weight: 700;
  }
}

.border {
  padding: 1px;
  border: 3px solid;

  &-yellow {
    border-color: $yellow-light;
  }

  &-gray {
    border-color: $black-variant;
  }
}

.no-border {
  border: none;
}

.active {
  padding: 0.0625rem;
  border: 3px solid $yellow;
}

.isPlacement {
  padding: 0.0625rem;
  border: 3px solid $black-variant;
}
</style>
