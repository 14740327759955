<script lang="ts">
import { defineComponent } from "vue";
import { Card } from "@/components/card";

export default defineComponent({
  name: "AuthCard",
  components: {
    Card,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
});
</script>

<template>
  <Card class="auth-card">
    <h1 class="title">
      {{ title }}
    </h1>

    <slot />
  </Card>
</template>

<style lang="scss" scoped>
.auth-card {
  padding: 3rem;

  .title {
    margin-bottom: 2.5rem;
    color: black;
    font-size: 2rem;
  }
}

@supports (backdrop-filter: none) {
  .auth-card {
    backdrop-filter: blur(42px);
    background-color: rgb(var(--bg-card-color) 0.1);
  }
}

@supports not (backdrop-filter: none) {
  .auth-card {
    background-color: rgba($white-full, 0.7);
  }
}
</style>
