<script lang="ts">
import { computed, defineComponent } from "vue";
import Calendar from "primevue/calendar";

export default defineComponent({
  name: "CalendarInput",
  components: {
    Calendar,
  },
  inheritAttrs: false,
  props: {
    errorMessage: String,
    placeholder: String,
    modelValue: Date,
    selectionMode: String,
    autoFill: Boolean,
    manualInput: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const updateDate = (ev) => {
      emit("update:modelValue", props.modelValue);
    };

    const borderColor = computed(() =>
      props.autoFill
        ? "2px solid #c23584"
        : `1px solid ${props.errorMessage ? "#D80000" : "#c0c0c0"}`);

    return {
      updateDate,
      borderColor,
    };
  },
});
</script>

<template>
  <div class="TextInput" :class="{ 'has-error': !!errorMessage }">
    <Calendar
      v-maska="'XX/XX/XXXX'"
      v-bind="$attrs"
      class="calendar"
      :class="{ auto_fill: autoFill }"
      :placeholder="placeholder"
      :manual-input="manualInput"
      :show-icon="true"
      :selection-mode="selectionMode"
      :model-value="modelValue"
      autocomplete="off"
      @change="updateDate"
    />

    <p v-show="errorMessage" class="help-message">
      {{ errorMessage }}
    </p>
  </div>
</template>

<style scoped lang="scss">
.help-message {
  margin-top: 2px;
  margin-left: 5px;
  color: $red-warning;
  font-family: "Open Sans";
  font-size: 10px;
}

:deep(.calendar.p-component) {
  flex-direction: row-reverse;

  input {
    height: 28px;
    border: v-bind(borderColor);
    border-left: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 6px;
    border-top-left-radius: 0;
    border-top-right-radius: 6px;
    color: #666;
    font-family: "Open Sans";
    font-size: 13px;

    &::placeholder {
      color: #c6c6c6;
    }

    &:focus {
      box-shadow: none;
    }
  }

  .p-button-icon-only {
    width: 25px;
    height: 28px;
    border: v-bind(borderColor);
    border-right: none;
    background-color: #fff;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 0;
    border-top-left-radius: 6px;
    border-top-right-radius: 0;
    color: #c6c6c6;
  }
}
</style>
