import { ref, watchEffect } from "vue";
import { throttle } from "lodash";

interface ICallback {
  (): void
}

export function useOnScroll(callback: ICallback) {
  const scrollHandler = throttle((event) => {
    const target = event.target;

    if (target.scrollHeight === 0 || target.clientHeight === 0)
      return; // Avoid division by zero errors

    const scrolledToBottom
      = target.scrollTop + target.clientHeight >= target.scrollHeight;

    if (scrolledToBottom)
      callback();
  }, 500, { leading: true, trailing: true });

  const scrollRef = ref<HTMLElement | null>(null);

  watchEffect(() => {
    const container = scrollRef.value;
    if (container)
      container.addEventListener("scroll", scrollHandler);

    return () => {
      if (container)
        container.removeEventListener("scroll", scrollHandler);
    };
  });

  return {
    scrollRef,
  };
}
