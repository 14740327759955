<script setup lang="ts">
import { mdiChevronRight as chevron } from "@mdi/js";
import { SvgIcon } from "@/components/icon";
import type { CURRENCY } from "@/entities/common";
import { SALARYCURRENCY } from "@/entities/common";

interface Option {
  value: CURRENCY | string
  label: string
}

interface Props {
  modelValue: string | keyof SALARYCURRENCY
  options?: Option[]
  isContractType?: boolean
  placeholder?: string
}

const props: Props = withDefaults(defineProps<Props>(), {
  options: () =>
    [
      {
        value: "BRL",
        label: "R$",
      },
      {
        value: "USD",
        label: "US$",
      },
      {
        value: "EUR",
        label: "€",
      },
    ] as Option[],
});
const emit = defineEmits(["change"]);
const isDropdownOpen: Ref<boolean> = ref(false);
const placeholder: Ref<string | null> = ref(props.placeholder ?? (props?.options[0]?.label || null));
const selectedOption: Ref<Option> = ref({
  label: SALARYCURRENCY[props.modelValue] || placeholder.value,
  value: props.modelValue,
}) as Ref<Option>;
const optionsRef: Ref<HTMLElement | null> = ref<HTMLElement | null>(null);
function toggleDropdown(): void {
  isDropdownOpen.value = !isDropdownOpen.value;
}

function selectOption(option: Option): void {
  selectedOption.value = toRaw(option);
  emit("change", option.value);
  isDropdownOpen.value = false;
}
const emptySelect = { value: null, label: "R$" };
onClickOutside(optionsRef, () => (isDropdownOpen.value = false));
</script>

<template>
  <div class="dropdown" :class="[isDropdownOpen ? 'border' : null]">
    <div
      ref="optionsRef"
      class="dropdown-toggle"
      :class="[placeholder && !selectedOption ? 'placeholder-text' : null]"
      @click="toggleDropdown"
    >
      {{ selectedOption.label }}
      <SvgIcon
        class="icon-chevron"
        type="mdi"
        :icon="chevron"
        :rotate="isDropdownOpen ? '-90deg' : '90deg'"
        color="#C0C0C0"
        margin="none"
      />
    </div>
    <transition-group name="slide">
      <div v-if="isDropdownOpen" class="dropdown-menu">
        <div
          v-for="option in options"
          :key="option.label"
          class="dropdown-item"
          @click="selectOption(selectedOption.value === option.value ? emptySelect : option)"
        >
          {{ option.label }}
        </div>
      </div>
    </transition-group>
  </div>
</template>

<style scoped lang="scss">
.dropdown {
  position: relative;
  z-index: 11; // to overlay the multiselect from primevue chevron icon
  height: 1.75rem;
  align-items: center;
  border: 1px solid $gray-border;
  border-radius: 0.375rem;
  color: $gray-border;
  transition: 300ms;

  & * {
    font-size: 0.875rem;
    line-height: 1.1875rem;
  }

  .placeholder-text {
    height: 100%;
    color: $gray-border;
    padding-block: 0.3125rem;
    text-align: center;
  }

  .dropdown-toggle {
    position: relative;
    display: flex;
    height: 100%;
    min-height: 0.1rem;
    align-items: center;
    padding-inline: 1rem 0.8856rem;
    text-align: center;

    &:hover {
      color: $gray-dark;
    }

    .icon-chevron {
      flex-shrink: 0;
    }

    svg {
      position: absolute;
      top: 0.132rem;
      right: 0.375rem;
    }
  }

  &:hover {
    border: 1px solid $gray-dark;
    color: $gray-dark;
  }

  .dropdown-menu {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: $white-full;
    gap: 1rem;
    margin-block-end: 0.4375rem;
    margin-block-start: 0;

    .dropdown-item {
      cursor: pointer;
      padding-block: 0.5rem;
      padding-inline: 1rem;

      &:first-child {
        margin-block-start: 1rem;
      }

      &:last-child {
        margin-block-end: 1rem;
      }

      &:hover {
        background-color: #f3f4f6;
      }
    }
  }
}

.dropdown.border {
  border: none;
  transition: 400ms;

  &,
  .dropdown-menu {
    z-index: 12;
    border-radius: 0.25rem 0.25rem 0 0;
    box-shadow: 0 0.1875rem 0.375rem #00000029;
  }

  & * {
    border: none;
  }

  &:hover {
    border: none;
  }
}

.slide-enter-active {
  -moz-transition-duration: 200ms;
  transition-duration: 200ms;
  -moz-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  max-height: 12rem;
  -moz-transition-duration: 200ms;
  transition-duration: 200ms;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

.slide-fade-enter-active {
  transition: all 300ms ease-out;
}

.slide-fade-leave-active {
  transition: all 500ms cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateX(1.25rem);
}
</style>
