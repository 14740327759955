<script setup lang="ts">
import { useI18n } from "vue-i18n";

import ExportPage from "@/common/components/Export/ExportPage.vue";

interface ISummary {
  id: string
  page: string | number | undefined
  name: string | undefined
}

interface ISummaryShortlistEnum {
  [key: string]: string
}

const props = defineProps<{
  companyName: string | null
  isExport: boolean
  listHiddePages: string[]
}>();

const { t, tm } = useI18n();

enum ShortlistSumaryEnum {
  idealProfile = "IDEAL_PROFILE_TAGS",
  searchField = "SEARCH_FIELD",
  processFunnel = "PROCESS_FUNNEL",
  adherenceTable = "ADHERENCE_TABLE",
  candidates = "USERS",
}

const summaryShortlistEnum = tm(
  "exportPDF.summaryShortlistEnum",
) as ISummaryShortlistEnum;

const summary = computed(() => {
  const formatted: ISummary[] = [];
  Object.entries(ShortlistSumaryEnum).forEach(([key, value]) => {
    if (!props.listHiddePages.includes(value)) {
      formatted.push({
        id: value,
        page: value,
        name: summaryShortlistEnum[key],
      });
    }
  });
  return formatted;
});
</script>

<template>
  <ExportPage
    :has-avatar="false"
    :company_name="props.companyName"
    :page-name="t('exportPDF.summary')"
  >
    <template #title />
    <div class="summary">
      <div v-for="(item, index) in summary" :key="index" class="summary-item">
        <a :href="`#${item.id}`"> {{ index + 1 }}. {{ item?.name?.toUpperCase() }} </a>
      </div>
    </div>
  </ExportPage>
</template>

<style scoped lang="scss">
.summary {
  display: grid;
  // margin-top: 4.5rem;
  margin-right: 6rem;
  column-gap: 15%;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(5, 1fr);
  row-gap: 1rem;
}

.summary-item {
  padding-block: 1.5rem;

  &:nth-child(6),
  &:nth-child(12) {
    padding-bottom: 0;
  }

  a {
    font-size: 1.25rem;
    text-decoration: none;
  }
}
</style>
