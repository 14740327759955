<script setup lang="ts">
import type { PropType } from "vue";
import type { ICandidate } from "@/validation/pages/validation/[project_id]/types";
import { PriorityOptions } from "@/validation/pages/validation/[project_id]/types";

const props = defineProps({
  candidate: { type: Object as PropType<ICandidate>, required: true },
});

const emit = defineEmits(["update:priority"]);

const item = ref(props.candidate?.priority_classification);

const isPrzActive = computed(() => props.candidate?.approval_and_priority?.priority_classification === PriorityOptions.IN);

const priorityList = computed<{ label: string; value?: PriorityOptions }[]>(() => [
  { label: "-", value: undefined },
  { label: PriorityOptions.IN, value: PriorityOptions.IN },
  { label: PriorityOptions["A+"], value: PriorityOptions["A+"] },
  { label: PriorityOptions.A, value: PriorityOptions.A },
  { label: PriorityOptions["A-"], value: PriorityOptions["A-"] },
  { label: PriorityOptions.B, value: PriorityOptions.B },
  { label: PriorityOptions.C, value: PriorityOptions.C },
  { label: PriorityOptions.D, value: PriorityOptions.D },
  { label: PriorityOptions.F, value: PriorityOptions.F },
]);

function update(priority?: ICandidate["priority_classification"], callback = () => ({})) {
  callback();

  return emit("update:priority", props.candidate?.candidate_id, priority);
}
</script>

<template>
  <Popover :has-close-action="false">
    <template #trigger>
      <span class="priority-item" :class="{ active: isPrzActive }" v-text="candidate?.approval_and_priority?.priority_classification || '-'" />
    </template>

    <template #content="{ close }">
      <div class="box" v-bind="$attrs">
        <template v-for="({ label, value }) in priorityList" :key="label">
          <div class="item" @click="update(value, close)" v-text="label" />
        </template>
      </div>
    </template>
  </Popover>
</template>

<style scoped lang="scss">
.active {
  background-color: rgba($green, .23);
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.priority-item {
  position: absolute;
  cursor: pointer;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box {
  margin: -0.5rem;
  background: transparent;

  .item {
    border: 1px solid $gray-x13;
    padding: .4em 1.5em;

    &:hover {
      background-color: $white-lotion;
    }
  }

}

.item {
  cursor: pointer;
  font-weight: 600;
  text-align: center;

  &:hover {
    color: $red-violet;
  }
}
</style>
