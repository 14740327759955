<script setup lang="ts">
import moment from "moment";
import { findLastIndex } from "lodash-es";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiPlusCircleOutline as addMore } from "@mdi/js";
import type { WorkExperience } from "@/schemas/person";
import { Avatar } from "@/components/avatar";
import CompanyIcon from "@/assets/images/general-company.svg";
import { formatCurrencyFromValue } from "@/utils/parsers";
import { getDateDif } from "@/utils/dates";
import { PAYMENT_FREQUENCY } from "@/entities/Person/work-experience";
import { Tooltip } from "@/components/tooltip";
import ScrewdriverIcon from "@/assets/images/screwdriver_icon.svg";

interface PeriodType {
  init?: string | null
  end?: string | null
  current: boolean
}
interface RealizationsType {
  title: string
  description: string
}
interface SalaryType {
  amount: number
  contract_type: string | null
  currency: string | null
  payment_frequency: string | null
}
interface CompanyListType {
  company_name: string
  company_id: string
  role_name: string
  is_current?: boolean
  init_date?: string | null
  end_date?: string | null
  avatar_url: string | null
  period?: string | null
  experiences: ExperiencesListType[]
}
interface ExperiencesListType {
  id: string
  is_current: boolean
  role_name: string
  start_date: string
  end_date: string
  salary?: SalaryType | null
  description?: string | null
  conditions?: string | null
  incentive_compensation?: string | null
  job_location?: string | null
  realizations?: RealizationsType[]
}

interface PropsType {
  workExperiences: WorkExperience[]
}

const props = defineProps<PropsType>();
const { t, locale } = useI18n();

const achievementShow = ref<string[]>([]);

const months = computed(() => {
  const data = JSON.parse(t("date.months")) || [];
  return data.map((item: string) => `${item.substring(0, 3)}.`);
});

function paymentFrequencies(value: PAYMENT_FREQUENCY) {
  const data = {
    [PAYMENT_FREQUENCY.MONTHLY]:
          t("persons.workExperienceForm.placeholders.paymentFrequencies.monthly"),
    [PAYMENT_FREQUENCY.ANNUALLY]:
          t("persons.workExperienceForm.placeholders.paymentFrequencies.yearly"),
  };
  if (data[value])
    return ` / ${data[value]}`;
  else
    return "";
}

function getDate(date: string | null | undefined): string {
  if (!date || date === "")
    return "";

  const dateFormat = moment(date);
  const year = dateFormat.format("YYYY");
  const month = Number.parseInt(dateFormat.format("M")) - 1;
  const dateIn = t("date.dateIn");

  return locale.value === "pt-BR"
    ? `${months.value[month]} ${dateIn} ${year}`
    : `${year} ${dateIn} ${months.value[month]}`;
}

function sortExperiencies() {
  const workExperience = props.workExperiences ?? [];

  const current = workExperience
    .filter(item => (item.is_current && item.start_date))
    .sort((a, b) => (b.start_date ?? "").localeCompare(a.start_date ?? ""));

  const previous = workExperience
    .filter(item => (!item.is_current && item.start_date))
    .sort((a, b) => (a.start_date ?? "").localeCompare(b.start_date ?? ""))
    .reverse();

  const dateEmpty = workExperience
    .filter(item => (!item.start_date))
    .sort((a, b) => (a.company.name ?? "").localeCompare(b.company.name ?? ""));

  return current.concat(previous, dateEmpty);
}

function groupExperiences() {
  const grouped: CompanyListType[] = [];

  const experiencesTotal = sortExperiencies();

  experiencesTotal.forEach((item) => {
    const company_name = item.company.name || "";
    const company_id = item.company.corporation_id || "";
    const avatar_url = item.company.corporation_data?.avatar_url || null;
    const role_name = item.role_name;
    const experience: ExperiencesListType = {
      id: item?.id || "",
      is_current: item.is_current,
      role_name,
      start_date: item.start_date || "",
      end_date: item.end_date || "",
      salary: item.salary,
      description: item.description,
      conditions: item.conditions,
      incentive_compensation: item.incentive_compensation,
      job_location: item.job_location,
      realizations: item.realizations,
    };

    const lastIndex = findLastIndex(grouped);

    if (lastIndex < 0) {
      grouped.push({
        company_id,
        company_name,
        role_name,
        avatar_url,
        is_current: experience.is_current,
        init_date: experience.start_date,
        end_date: experience.end_date,
        experiences: [experience],
      });
    }
    else {
      const companyData = grouped[lastIndex];
      if (company_name === companyData?.company_name) {
        grouped[lastIndex].end_date = experience.end_date;
        grouped[lastIndex].experiences.push(experience);
      }
      else {
        grouped.push({
          company_id,
          company_name,
          role_name,
          avatar_url,
          is_current: experience.is_current,
          experiences: [experience],
        });
      }
    }
  });

  grouped.map((item) => {
    const period: PeriodType[] = item.experiences?.reduce((acc: PeriodType[], exp) => {
      acc.push({
        init: exp.start_date,
        end: exp.end_date,
        current: exp.is_current,
      });
      return acc;
    }, []);
    item.period = getDateDif(period);
    return item;
  });

  return grouped;
}

function toggleAchievements(key: string) {
  if (achievementShow.value.includes(key)) {
    const indexToRemove = achievementShow.value.indexOf(key);
    achievementShow.value.splice(indexToRemove, 1);
  }
  else {
    achievementShow.value.push(key);
  }
}

const experiences = computed(() => groupExperiences());
</script>

<template>
  <div class="work-experiences">
    <template v-for="(item, item_index) in experiences" :key="item_index">
      <div>
        <div class="company">
          <div class="avatar" :class="{ icon: !item.avatar_url }">
            <Avatar
              v-if="item.avatar_url"
              :src="item.avatar_url"
              :alt="item.company_name"
              size="71px"
              image-size="large"
            />
            <CompanyIcon v-else />
          </div>
          <div class="infos">
            <template v-if="item.experiences.length === 1">
              <div v-if="item.role_name" class="info-title">
                {{ item.role_name }}&nbsp;
                <template v-if="item.experiences[0]?.conditions">
                  <Tooltip
                    :tooltip-text="item.experiences[0]?.conditions"
                    :tooltip-title="t('persons.workExperienceForm.conditions')"
                    tooltip-width="18.75rem"
                  >
                    <ScrewdriverIcon class="tooltip-icon" />
                  </Tooltip>
                </template>
              </div>
              <div class="info-subtitle">
                {{ item.company_name }}
              </div>
              <div class="time-period">
                <strong>
                  <span>{{ getDate(item.experiences[item.experiences.length - 1]?.start_date) }}</span>
                  <span v-if="!item.is_current && getDate(item.experiences[0]?.end_date) !== ''">
                    - {{ getDate(item.experiences[0]?.end_date) }}
                  </span>
                  <span v-if="item.is_current"> - {{ t("projects.shortlist.about.current") }} </span>
                </strong>
                <span>
                  &nbsp; • &nbsp; {{ item.period }}
                </span>
              </div>
              <template v-if="item.experiences[0]?.salary && item.experiences[0]?.salary?.amount > 0">
                <div class="salary texts">
                  <strong>{{ t("persons.workExperienceForm.salary") }}: </strong>
                  <span>
                    {{
                      formatCurrencyFromValue(
                        item.experiences[0]?.salary?.amount,
                        item.experiences[0]?.salary?.currency,
                      )
                    }}
                  </span>
                  <span>
                    {{
                      paymentFrequencies(item.experiences[0]?.salary?.payment_frequency)
                    }}
                  </span>
                </div>
              </template>
              <template v-if="item.experiences[0]?.incentive_compensation">
                <div class="bonus texts">
                  <strong>
                    {{ t("persons.workExperienceForm.incentiveCompensation") }}:
                  </strong>
                  <span>
                    {{ item.experiences[0]?.incentive_compensation }}
                  </span>
                </div>
              </template>
              <template v-if="item.experiences[0]?.job_location">
                <div class="location texts">
                  <strong>
                    {{ t("persons.workExperienceForm.jobLocation") }}:
                  </strong>
                  <span>
                    {{ item.experiences[0]?.job_location }}
                  </span>
                </div>
              </template>
              <template v-if="item.experiences[0]?.description">
                <div class="description" v-html="item.experiences[0]?.description?.replaceAll('\n', '<br />')" />
              </template>
              <template v-if="item.experiences[0]?.realizations">
                <template v-for="(achievement, realization_index) in item.experiences[0]?.realizations" :key="realization_index">
                  <div class="achievements">
                    <div class="label" @click="toggleAchievements(`${item_index}_${realization_index}`)">
                      <SvgIcon type="mdi" :path="addMore" size="14" />
                      {{ achievement.title }}
                    </div>
                    <template v-if="achievementShow.includes(`${item_index}_${realization_index}`)">
                      <div class="description" v-html="achievement.description?.replaceAll('\n', '<br />')" />
                    </template>
                  </div>
                </template>
              </template>
            </template>
            <template v-else>
              <div class="info-title">
                {{ item.company_name }}&nbsp;
                <template v-if="item.experiences[0]?.conditions">
                  <Tooltip
                    :tooltip-text="item.experiences[0]?.conditions"
                    :tooltip-title="t('persons.workExperienceForm.conditions')"
                    tooltip-width="18.75rem"
                  >
                    <ScrewdriverIcon class="tooltip-icon" />
                  </Tooltip>
                </template>
              </div>
              <div class="time-period">
                <strong>
                  {{ item.period }}
                </strong>
              </div>
            </template>
          </div>
        </div>
        <div v-if="item.experiences.length > 1" class="experiences">
          <template v-for="(experience, exp_index) in item.experiences" :key="exp_index">
            <div class="experience">
              <div class="history">
                <div class="circles gray" />
                <div v-if="exp_index + 1 < item.experiences.length" class="horizontal-line" />
              </div>
              <div class="experience-data">
                <div class="role-name" :class="{ unic: !experience.start_date }">
                  {{ experience.role_name }}
                </div>
                <div class="time-period">
                  <strong>
                    <span>{{ getDate(experience.start_date) }}</span>
                    <span v-if="!item.is_current && getDate(experience.end_date) !== ''">
                      - {{ getDate(experience.end_date) }}
                    </span>
                    <span v-if="item.is_current"> - {{ t("projects.shortlist.about.current") }} </span>
                  </strong>
                  <span>
                    &nbsp; • &nbsp;
                    {{
                      getDateDif([{ init: experience.start_date, end: experience.end_date, current: experience.is_current }])
                    }}
                  </span>
                </div>
                <template v-if="experience.salary && experience.salary?.amount > 0">
                  <div class="texts">
                    <strong>{{ t("persons.workExperienceForm.salary") }}: </strong>
                    <span>
                      {{
                        formatCurrencyFromValue(
                          experience.salary?.amount,
                          experience.salary?.currency,
                        )
                      }}
                    </span>
                    <span>
                      {{
                        paymentFrequencies(experience.salary?.payment_frequency)
                      }}
                    </span>
                  </div>
                </template>
                <template v-if="experience.incentive_compensation">
                  <div class="texts">
                    <strong>
                      {{ t("persons.workExperienceForm.incentiveCompensation") }}:
                    </strong>
                    <span>
                      {{ experience.incentive_compensation }}
                    </span>
                  </div>
                </template>
                <template v-if="experience.job_location">
                  <div class="texts">
                    <strong>
                      {{ t("persons.workExperienceForm.jobLocation") }}:
                    </strong>
                    <span>
                      {{ experience.job_location }}
                    </span>
                  </div>
                </template>
                <template v-if="experience.description">
                  <div class="description" v-html="experience.description?.replaceAll('\n', '<br />')" />
                </template>
                <template v-if="experience.realizations">
                  <template v-for="(achievement, realization_index) in experience.realizations" :key="realization_index">
                    <div class="achievements">
                      <div class="label" @click="toggleAchievements(`${item_index}_${realization_index}`)">
                        <SvgIcon type="mdi" :path="addMore" size="14" />
                        {{ achievement.title }}
                      </div>
                      <template v-if="achievementShow.includes(`${item_index}_${realization_index}`)">
                        <div class="description" v-html="achievement.description?.replaceAll('\n', '<br />')" />
                      </template>
                    </div>
                  </template>
                </template>
              </div>
            </div>
          </template>
        </div>
        <div v-if="experiences.length > item_index + 1" class="separator" />
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.work-experiences {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .company {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        padding-block-end: 1rem;

        .avatar {
            display: flex;
            width: 4.4375rem;
            min-width: 4.4375rem;
            height: 4.4375rem;
            min-height: 4.4375rem;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
        }

        .avatar.icon {
            background-color: $gray-medium;
        }
    }

    .infos {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 0.75rem;

        .info-title,
        .info-subtitle {
          font-size: 1rem;
          font-weight: bold;
          line-height: 1.2;
        }

        .info-title {
          margin-block-start: 1rem;
        }
    }

    .time-period {
      font-size: 0.875rem;
      line-height: 1.2;
    }

    .texts,
    .description {
      font-size: 0.875rem;
      line-height: 1.2;
    }

    .experiences {
        display: flex;
        flex-direction: column;
        width: calc(100% - 5.4375rem);
        margin-block-start: 1rem;
        margin-inline-start: 2.21875rem;

        .experience {
            display: flex;
            flex-direction: row;
            margin-top: -0.45rem;

            .history {
                display: flex;
                width: 0.9rem;
                flex-direction: column;
                margin-block-start: 0.15rem;
                margin-right: 2.3rem;

                .horizontal-line {
                    position: relative;
                    width: 1.5px;
                    height: 100%;
                    margin-left: 0.32rem;
                    background: #c0c0c0;
                }

                .circles {
                    width: 0.75rem;
                    min-width: 0.75rem;
                    height: 0.75rem;
                    min-height: 0.75rem;
                    margin-top: 0.3rem;
                    margin-right: 0.75rem;
                    border-radius: 50%;
                }

                .gray {
                    background: $gray-border;
                }
            }

            .experience-data {
              padding-block-end: 1rem;

                .role-name {
                    font-size: 1rem;
                    font-weight: bold;
                    margin-block-end: 0.5rem;
                }

                .role-name.unic {
                    margin-block-end: 0.875rem;
                }

                .time-period {
                    font-size: 1rem;
                    margin-block-end: 1rem;
                }

                .texts,
                .description {
                    font-size: 0.875rem;
                    margin-block-end: 1rem;
                }
            }
        }
    }

    .achievements {
        .label {
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 0.5rem;
            font-size: 0.875rem;
            font-weight: bold;
            color: $gray-dark;
        }
        .label:hover {
            color: $red-violet;
        }

        .description {
            font-size: 0.875rem;
            color: $gray-dark;
            margin-inline-start: 1.4rem;
            margin-block-start: 0.5rem;
        }
    }

    .tooltip-icon:deep {
      width: 0.875rem;
      height: 0.875rem;

      path {
        stroke-width: 4px;
      }
    }

    .separator {
        width: calc(100% - 5.4375rem);
        height: 0.5px;
        margin-inline-start: 5.4375rem;
        margin-block: 0.5rem;
        background: $gray-border;
    }
}
</style>
