<script setup lang="ts">
import SliderLevelViewForm from "./SliderLevelViewForm.vue";
import { SvgIcon } from "@/components/icon";
import { ATTRIBUTES_TITLES } from "@/entities/project/construction-guide";

interface AttributesRadio { cognitive_intelligence: valueMapKey; emotional_intelligence: valueMapKey; spiritual_intelligence: valueMapKey; energy: valueMapKey; maturity: valueMapKey }
// TODO: short this to reuse
type valueMapKey = keyof typeof valueMap;
const props = defineProps<{
  attributes: AttributesRadio
}>();
const emit = defineEmits(["submit", "cancel"]);
const valueMap = {
  EMPTY: 0,
  LOW: 1,
  AVERAGE: 2,
  HIGH: 3,
  VERY_HIGH: 4,
};
const { t } = useI18n();

const blocked = ref(false);
const attributesMax = ref(2);

const wordsRefs = ref([] as any[]);
const wrapperRef = ref();

const offsets = computed(() => {
  const parentStart = wrapperRef.value?.offsetLeft;
  if (!parentStart)
    return {};
  return wordsRefs.value.reduce((acc, item, index) => {
    if (!item)
      return acc;
    const wordStart = item.offsetLeft;
    const wordCenter = item.offsetWidth / 2;
    const offset = (wordStart - parentStart + wordCenter) / wrapperRef.value.offsetWidth;
    acc[index] = Math.floor(offset * 100);
    return acc;
  }, {});
});

const icons = {
  [ATTRIBUTES_TITLES.COGNITIVE_INTELLIGENCE.toLowerCase()]: "cognitive_icon",
  [ATTRIBUTES_TITLES.EMOTIONAL_INTELLIGENCE.toLowerCase()]: "emotional_icon",
  [ATTRIBUTES_TITLES.SPIRITUAL_INTELLIGENCE.toLowerCase()]: "spiritual_icon",
  [ATTRIBUTES_TITLES.ENERGY.toLowerCase()]: "bolt_icon",
  [ATTRIBUTES_TITLES.MATURITY.toLowerCase()]: "maturity_icon",
};

function checkActive(value: any) {
  // this should be computed reactive to props
  return !value || value === "EMPTY" ? "" : "active";
}

const titles = computed(() => Object.keys(props.attributes).reduce((acc, attr) => {
  acc[attr] = t(`attributes.${attr.toUpperCase()}`);
  return acc;
}, {} as Record<keyof AttributesRadio, string>));

const attributesData = ref({} as AttributesRadio);

function submit() {
  emit("submit", attributesData.value);
}

function update(key: keyof AttributesRadio, value: valueMapKey) {
  attributesData.value = {
    ...attributesData.value,
    [key]: value,
  };

  submit();
}

function checkBlocked(data: any) {
  const total = Object.keys(data).filter(key => data[key] !== "EMPTY");

  return total?.length >= attributesMax.value;
}

watchEffect(() => {
  attributesData.value = { ...toRaw(props.attributes) };
  blocked.value = checkBlocked(attributesData.value);
});
</script>

<template>
  <div>
    <div class="project-content">
      <div class="attributes-component">
        <div class="content">
          <div />
          <div
            :ref="ref => wrapperRef = ref"
            style="display:flex; justify-content: space-between; margin-left: 1rem;"
          >
            <span :ref="ref => wordsRefs[0] = ref" class="titles" />
            <span :ref="ref => wordsRefs[1] = ref" class="titles">
              {{ t('projects.generic.scale.enum.LOW') }}
            </span>
            <span :ref="ref => wordsRefs[2] = ref" class="titles">
              {{ t('projects.generic.scale.enum.AVERAGE') }}
            </span>
            <span :ref="ref => wordsRefs[3] = ref" class="titles">
              {{ t('projects.generic.scale.enum.HIGH') }}
            </span>
            <span :ref="ref => wordsRefs[4] = ref" class="titles">
              {{ t('projects.generic.scale.enum.VERY_HIGH') }}
            </span>
          </div>
        </div>
        <div v-for="(value, key) of attributesData" :key="`attribute[${key}]`" class="content">
          <div class="data">
            <span class="data-icon" :class="[checkActive(value)]">
              <SvgIcon :icon="icons[key]" margin="none" />
            </span>
            <span class="data-label">
              {{ titles[key] }}
            </span>
          </div>
          <div class="item-evaluate">
            <div class="item-slider">
              <SliderLevelViewForm
                :offsets="offsets"
                :model-value="value"
                track-color="#f4f4f4"
                background-color="#a8a8a8"
                :disabled="blocked && attributesData[key] === 'EMPTY'"
                @update:model-value="update(key, $event)"
              />
              <div class="box-description">
                {{ t(`projects.shortlist.enums.attributes.${key.toUpperCase()}`) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-actions">
    <Button type="submit" variation="secondary" @click="$emit('cancel')">
      {{ t("text.form.conclude") }}
    </Button>
  </div>
</template>

<style scoped lang="scss">
.inline {
  display: flex;
  // prevent parent
  margin: 0.5rem !important;
  justify-content: center;
  gap: 1.25rem;

  // prevent parent
  >div {
    margin: 0 !important;
  }
}
.form-actions {
  display: flex;
  padding: 2rem;
  gap: 2rem;
  grid-column: 1 / -1;
}
</style>

<style lang="scss" scoped>
.attributes-component {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-bottom: 3.5rem;
  gap: 1.5rem;
  padding-inline-end: 8rem;
  .content {
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr 4fr;

    .item-radios {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0 1rem 0 2rem;
    }
  }

  .data {
    display: flex;
    min-width: 13rem;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    .data-icon {
      display: flex;
      width: 55px;
      min-width: 55px;
      height: 55px;
      min-height: 55px;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      box-shadow: 0 2px 4px #00000029;
      color: $gray-border;
    }

    .data-icon.active {
      background-color: $white-background;
      color: $gray-a8;
    }

    .data-label {
      width: -webkit-fill-available;
      line-height: 1.3;
      text-transform: uppercase;
    }

    .help {
      text-transform: none;

      svg {
        color: $gray-shade-dark;
      }
    }
  }

  .legends {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 3rem;
    gap: 4rem;

    .item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;

      .marker {
        width: 7px;
        height: 20px;
        background: $gray-shade-dark;
      }

      .marker-title {
        font-size: 0.75rem;
      }
    }
  }

  .form-actions {
    display: flex;
    margin: 2rem 0;
    gap: 2rem;
    grid-column: 1 / -1;
  }

  .item-evaluate {
    display: flex;
    width: 100%;
    align-items: center;

    .item-slider {
      width: inherit;
    }

    .item-score {
      display: flex;
      width: 5rem;
      align-items: center;

      .score {
        display: flex;
        min-width: 5rem;
        height: fit-content;
        align-items: center;
        justify-content: center;
        padding: 0.4375rem 1.18rem;
        border: 1px solid $gray-border;
        border-radius: 6px;
        background: $white-full;
        color: $gray-text;
        font-size: 1.25rem;
        font-weight: bold;
      }
    }
  }
}

.project-content {
  margin-inline: 1.5rem;
}

.box-description {
  margin-left: 10px;
  font-size: 0.75rem;
  line-height: 1.5;
  margin-top: 1rem;
}
.titles {
  color: #707070;
  font-size: 1rem;
  text-transform: uppercase;
  text-align: center;
}
</style>
