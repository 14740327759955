<script setup lang="ts">
import type { PropType } from "vue";
import type { ICandidate } from "@/validation/pages/validation/[project_id]/types";
import PersonDetail from "@/persons/pages/persons/components/PersonDetail/PersonDetail.vue";
import { getPerson } from "@/http_services/entities/persons";

const props = defineProps({
  active: { default: false, required: false, type: Boolean },
  name: { type: String, required: true },
  percent: { type: Number },
  person: { required: true, type: String },
  src: { type: String },
  stage: { type: String as PropType<ICandidate["project_stage"]> },
});

const visibleDetails = ref(false);
const fullPerson = ref();

const inInterview = computed(() => props.stage === "SHORTLIST");
const borderColor = computed(() => props.active ? "#FCAF45" : "#666");
const parsedPercent = computed(() => {
  return props.percent % 1 === 0
    ? props.percent
    : Number(props.percent).toFixed(2);
});

async function showDetails() {
  const data = await getPerson(String(props.person));

  if (!data)
    return;

  fullPerson.value = data;
  visibleDetails.value = true;
}

provide("selectedPerson", fullPerson);
provide("onCloseDetails", () => {
  visibleDetails.value = false;
  fullPerson.value = undefined;
});
</script>

<template>
  <div class="flex-center candidate">
    <div :title="props.name" class="flex-center" @click="showDetails">
      <PersonAvatar
        :border-color="borderColor"
        :name="`${props.name}`"
        :url="props.src"
        active
        border-width="1px"
      />
      <span class="candidate-name" v-text="props.name" />
    </div>

    <span
      v-if="props.percent"
      :class="{ active: inInterview }"
      class="percent"
      v-text="parsedPercent"
    />

    <PersonDetail :person="fullPerson" :visible="visibleDetails" />
  </div>
</template>

<style scoped lang="scss">
.flex-center {
  display: flex;
  align-items: center;
}

.candidate {
  display: flex;
  cursor: pointer;
  padding: 0 1em;
  justify-content: space-between;
  text-align: initial;

  .candidate-name {
    text-align: left;
    padding-left: .5em;
    padding-right: .5em;
    font-weight: 500;

    text-wrap: nowrap;
  }

  .percent {
    position: relative;
    font-weight: 600;

    &.active {
      color: $yellow;
    }

    &:after {
      content: '%'
    }
  }
}
</style>
