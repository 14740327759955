<script lang="ts">
import type { Ref } from "vue";
import { computed, defineComponent, inject } from "vue";
import ChartPersonCard from "../Chart/ChartPersonCard.vue";
import type { SelectPerson } from "../../details/Details";
import type { Corporation } from "@/entities/corporation";
import type { Person } from "@/entities/Person/person";

export default defineComponent({
  name: "OrganizationChart",
  components: {
    ChartPersonCard,
  },
  setup() {
    const corporation = inject("corporation") as Ref<Corporation>;
    const employees = inject("employees") as Ref<Person[]>;
    const selectPerson = inject("selectPerson") as SelectPerson;

    const filteredEmployees = computed(() =>
      employees.value.filter(({ work_experience }) => work_experience.some(({ is_current }) => !!is_current)));

    return {
      corporation,
      employees,
      filteredEmployees,
      selectPerson,
    };
  },
});
</script>

<template>
  <div class="tab-container">
    <ChartPersonCard
      v-for="employee in employees"
      :key="employee.id"
      :employee="employee"
      :corporation="corporation"
      @click:person="selectPerson($event)"
    />
  </div>
</template>

<style lang="scss" scoped>
.tab-container {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
}
</style>
