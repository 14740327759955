<script setup lang="ts">
import * as tooltip from "@zag-js/tooltip";
import { normalizeProps, useMachine } from "@zag-js/vue";
import { computed } from "vue";
import bson from "bson-objectid";

// add a props with arrowShow
const props = defineProps({
  arrow: {
    type: Boolean,
    default: true,
  },
});

const [state, send] = useMachine(tooltip.machine({
  id: bson().toString(),
  closeDelay: 0,
  openDelay: 0,
}));

const api = computed(() => tooltip.connect(state.value, send, normalizeProps));
</script>

<template>
  <div>
    <div v-bind="api.triggerProps">
      <slot name="trigger" />
    </div>
    <div v-if="props.arrow && api.isOpen" v-bind="api.arrowProps">
      <div v-bind="api.arrowTipProps" />
    </div>
    <div v-if="api.isOpen" v-bind="api.positionerProps">
      <div v-bind="api.contentProps">
        <div class="tooltip-container">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
[data-part="arrow"] {
  --arrow-size: 1.25rem;
  --arrow-background: $white-full;
}
.tooltip-container {
  display: block;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: $white-full;
  box-shadow: 0 2px 4px #00000029;
  font-size: 1rem;
  letter-spacing: 0.56px;
  line-height: 1.125rem;
}
</style>
