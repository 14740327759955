import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { addCandidateToScreening, getScreeningActivity } from "@/common/services/screening";
import type { ApprovalCandidateType, SearchCandidatesType } from "@/common/services/candidates";
import { candidateApproveToInterviews, candidateDeclineFromInterviews, searchCandidates } from "@/common/services/candidates";
import type { ActivitiesSearchType } from "@/common/services/screening";

export function useScreeningsActivitiesQuery(query: ActivitiesSearchType) {
  return useQuery({
    queryKey: ["screenings_activities", "project", query.project_id],
    queryFn: () => getScreeningActivities(query),
  });
}

export function useScreeningActivityQuery(id: string) {
  return useQuery({
    queryKey: ["screenings_activities", id],
    queryFn: () => getScreeningActivity(id),
  });
}

export function useScreeningNotes(id: string) {
  return useQuery({
    queryKey: ["screenings_activities", id],
    queryFn: () => getScreeningActivity(id),
    select: data => toRaw(data?.annotations),
  });
}

export function useCreateScreeningActivity() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["screenings_activities"],
    mutationFn: (activity: any) => addScreeningActivity(activity),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["screenings_activities"] });
    },
  });
}

export function useScreeningActivityMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["screenings_activities"],
    mutationFn: (body: { project_id: string; activity: any }) => updateScreeningActvity(body.project_id, body.activity),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["screenings_activities"] });
    },
  });
}

export const isNotesSaving = ref(false);

export function usePatchScreeningAnnotationsMutation(id: string) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (annotations: any) => patchScreeningAnnotations(id, annotations),
    onMutate: async (newAnnotations) => {
      isNotesSaving.value = true;
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: ["screenings_activities", id, "annotations"] });

      // Snapshot the previous value
      const previousTodos = queryClient.getQueryData(["screenings_activities", id, "annotations"]);
      // Optimistically update to the new value
      queryClient.setQueryData(["screenings_activities", id, "annotations"], (old: any) => {
        return {
          ...old,
          annotations: {
            ...old?.annotations,
            ...newAnnotations,
          },
        };
      });

      // Return a context object with the snapshotted value
      return { previousTodos };
    },
    onError: () => {
      isNotesSaving.value = false;
    },
    onSettled: async () => {
      isNotesSaving.value = false;
      await queryClient.invalidateQueries({ queryKey: ["interview_activities", id, "annotations"] });
    },
  });
}

export function useScreeningsFullCandidates() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params: SearchCandidatesType) => searchFullCandidates(params),
    onSuccess: async (data) => {
      await queryClient.refetchQueries({ queryKey: ["screenings_candidates"] });
      return data;
    },
    onError: (error) => {
      console.log("error", error);
    },
  });
}

export async function useScreeningsCandidates(params: SearchCandidatesType) {
  return useQuery({
    queryKey: ["screenings_candidates", "list", params.project_id],
    queryFn: () => searchCandidates(params),
  });
}

export function useScreeningsCandidatesCount(params: SearchCandidatesType) {
  return useQuery({
    queryKey: ["screenings_candidates", "list", params.project_id],
    queryFn: () => searchCandidates(params),
    select: data => data ? data.length : 0,
  });
}

export function userApproveScreeningsCandidate() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: ApprovalCandidateType) => candidateApproveToInterviews(body),
    onSuccess: async (data, variables, context) => {
      await queryClient.refetchQueries({ queryKey: ["screenings_candidates"] });
      await queryClient.refetchQueries({
        queryKey: ["screenings_candidates", "list", variables.id_],
      });
    },
    onError: (error) => {
      console.log("error", error);
    },
  });
}

export function userDeclineScreeningsCandidate() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: ApprovalCandidateType) => candidateDeclineFromInterviews(body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["screenings_candidates"] });
    },
  });
}

export function userAddCandidateToScreening() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: { activity_id: string; person_id: string }) => addCandidateToScreening(body.activity_id, body.person_id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["screenings_candidates"] });
    },
  });
}
