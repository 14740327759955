import type { Module } from "vuex";

const UserStoreModule: Module<any, any> = {
  state() {
    const user = null;
    return {
      user,
    };
  },
  getters: {
    GET_USER_DATA(state) {
      return state.user;
    },
  },
  mutations: {
    SET_USER_DATA: (state, payload) => {
      state.user = payload;
    },
  },
};

export default UserStoreModule;
