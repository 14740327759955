import type { Empty, Maybe, PaginationParams, SuccessPaged } from "../types";

import { NotFoundError } from "../errors";
import { entitiesService, projectsService } from "../client";
import type { Person } from "@/schemas/person";
import { PersonSchema } from "@/schemas/person/person";
import type { Project } from "@/schemas/project";
import type { SearchParams } from "@/entities/project";
import type { ActivityProjectsParticipated } from "@/entities/Person/activity";
import { parseParams } from "@/utils/parsers";

const url = "/persons";
const buildIdUrl = (id: string) => `${url}/${id}`;
const buildPersonUrl = (person: Person) => `${url}/${person.id}`;

export async function createPerson(payload: Person): Promise<Person> {
  const {
    data: { data },
  } = await entitiesService.post(url, payload);
  return data;
}

export async function deletePerson(person: Person): Promise<Empty> {
  await entitiesService.del(buildPersonUrl(person));
  return {};
}

export async function listPersons(params: PaginationParams = {}): Promise<SuccessPaged<Person[]>> {
  const parsedParams = parseParams(params);
  const { data } = await entitiesService.get(url, { params: { ...parsedParams } });
  return data;
}

export async function searchPersons(params: PaginationParams & { [key: string]: string | number | string[] } = {}, email?: string, name?: string): Promise<SuccessPaged<Person[]>> {
  const parsedParams = parseParams(params);
  const { data } = await entitiesService.get("/search/persons", {
    params: { ...parsedParams, ...{ email, name } },
  });

  return data;
}

export async function searchRecruiters(params: PaginationParams & { [key: string]: string | number | string[] } = {}): Promise<SuccessPaged<Person[]>> {
  const parsedParams = parseParams(params);
  const { data } = await entitiesService.get("/search/employees", {
    params: {
      ...parsedParams,
      role: "recruiter",
    },
  });
  return data;
}

export async function getPerson(id: string): Promise<Maybe<Person>> {
  try {
    const {
      data: { data },
    } = await entitiesService.get(buildIdUrl(id));

    const validation = PersonSchema.safeParse(data);

    return data;
  }
  catch (error) {
    if (error instanceof NotFoundError)
      return null;

    throw error;
  }
}

export async function getMe(): Promise<Maybe<Person>> {
  try {
    const {
      data: { data },
    } = await entitiesService.get(buildIdUrl("me"));
    return data;
  }
  catch (error) {
    if (error instanceof NotFoundError)
      return null;

    throw error;
  }
}

export async function updatePerson(person: Person, payload: Person): Promise<Person> {
  const {
    data: { data },
  } = await entitiesService.put(buildPersonUrl(person), payload);
  return data;
}

export async function searchPersonDropdown(params: PaginationParams = {}): Promise<SuccessPaged<Person[]>> {
  const parsedParams = parseParams(params);
  const { data } = await entitiesService.get("/search/persons", { params: { ...parsedParams } });
  return data;
}

export async function getProjectPerson(params: PaginationParams & SearchParams = {}, person_id: string): Promise<SuccessPaged<Project[]>> {
  const parsedParams = parseParams(params);
  const { data } = await
  projectsService.get(`/projects/search/person/${person_id}`, { params: { ...parsedParams } });
  return data;
}
export async function getPersonProjectsParticipated(params: PaginationParams = {}, person_id: string): Promise<SuccessPaged<ActivityProjectsParticipated[]>> {
  const { data } = await
  projectsService.get(`/projects/person/${person_id}`, {});
  return data;
}

export async function addPersonToProject(person_id: string, project_id: string): Promise<SuccessPaged<Project[]>> {
  const { data } = await
  entitiesService.post(`/persons/${person_id}/project/${project_id}`, {
    person_id,
    is_added: true,
  });
  return data;
}
