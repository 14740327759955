<script lang="ts">
import { computed, defineComponent, ref, shallowRef, watch } from "vue";
import { useI18n } from "vue-i18n";
import { mdiChevronLeft } from "@mdi/js";
import { some } from "lodash";
import { breakpoints } from "@/utils/breakpoints";
import { DropdownFilter } from "@/components/dropdown_filter";
import { SearchInput } from "@/components/inputs/modalInputs";
import { Button as ButtonClear, ButtonIcon } from "@/components/button";
import { BusinessFunctionFilter, MarketFilter, MoneyFilter, TagFilter } from "@/components/filters";
import FilterIcon from "@/assets/images/filter_icon.svg";

export default defineComponent({
  name: "CompanyFilter",
  components: {
    DropdownFilter,
    SearchInput,
    ButtonIcon,
    FilterIcon,
    ButtonClear,
  },
  emits: ["search:text", "filter"],
  setup(_, { emit }) {
    const { t, tm } = useI18n();
    const filterOpen = ref<boolean>(true);
    const smallerThanLg = breakpoints.smaller("lg");
    const searchError = ref(false);

    const changeFilter = () => (filterOpen.value = !filterOpen.value);
    const emitSearch = (searchTerm: string) => {
      searchError.value = false;
      emit("search:text", searchTerm);
    };

    const getFilterCallback = (filterType, filterObject) => (values: []) => {
      filterObject.active.value = values.length ? !!values.length : !!Object.keys(values).length;
      emit("filter", [filterType, values]);
    };

    const moneyRangeCallback = (filterType, filterObject) => (values: []) => {
      filterObject.active.value = some(Object.values(values));
      emit("filter", [filterType, values]);
    };

    const currentFilter = ref("");

    const filters = computed(() => [
      {
        name: t("corporations.tagFilters.keywords.0"),
        active: shallowRef(false),
        component: TagFilter,
        props: {
          clear: false,
          filterName: "keywords",
          filterLocale: "corporations",
        },
        get listeners() {
          return {
            "filter:tag": getFilterCallback("keywords", this),
          };
        },
      },
      {
        name: t("corporations.tagFilters.market.0"),
        active: shallowRef(false),
        component: MarketFilter,
        props: {
          clear: false,
          filterName: "market",
          filterLocale: "corporations",
        },
        get listeners() {
          return {
            "filter:select": getFilterCallback("markets", this),
          };
        },
      },
      {
        name: t("corporations.tagFilters.sector.0"),
        active: shallowRef(false),
        component: BusinessFunctionFilter,
        props: {
          clear: false,
        },
        get listeners() {
          return {
            "filter:select": getFilterCallback("business_function", this),
          };
        },
      },
      {
        name: t("corporations.tagFilters.governance.0"),
        active: shallowRef(false),
        component: TagFilter,
        props: {
          clear: false,
          filterName: "governance",
          filterLocale: "corporations",
        },
        get listeners() {
          return {
            "filter:tag": getFilterCallback("governance", this),
          };
        },
      },
      {
        name: t("corporations.tagFilters.strategy.0"),
        active: shallowRef(false),
        component: TagFilter,
        props: {
          clear: false,
          filterName: "strategy",
          filterLocale: "corporations",
        },
        get listeners() {
          return {
            "filter:tag": getFilterCallback("strategy", this),
          };
        },
      },
      {
        name: t("corporations.tagFilters.revenue.0"),
        active: shallowRef(false),
        component: MoneyFilter,
        props: {
          clear: false,
          filterName: "revenue",
          filterLocale: "corporations",
        },
        get listeners() {
          return {
            "filter:money_range": moneyRangeCallback("revenue", this),
          };
        },
      },
    ]);

    watch(smallerThanLg, value => (filterOpen.value = !value));

    const itemRefs = ref([]);

    const clearFilters = () => {
      currentFilter.value = "";

      filters.value.forEach((filter) => {
        filter.props.clear = !filter.props.clear;
        filter.active.value = false;
      });
    };

    return {
      t,
      tm,
      smallerThanLg,
      filterOpen,
      changeFilter,
      emitSearch,
      searchError,
      currentFilter,
      filters,
      chevron: mdiChevronLeft,
      clearFilters,
      itemRefs,
    };
  },
});
</script>

<template>
  <div class="search-group">
    <div class="search-input-wrapper">
      <SearchInput
        :placeholder="t('corporations.searchCorporation')"
        @search="emitSearch($event)"
        @search:error="searchError = true"
        @search:clear="searchError = false"
      />
      <span class="search-error">
        {{ searchError ? t("corporations.searchCorporationError") : "" }}
      </span>
    </div>

    <div class="filters-wrapper">
      <header class="filter-header">
        <h2>
          <FilterIcon />
          <span
            :role="smallerThanLg ? 'button' : 'presentation'"
            @click="smallerThanLg ? changeFilter() : () => null"
          >
            {{ t("corporations.filters") }}
          </span>
        </h2>

        <div v-if="smallerThanLg">
          <ButtonIcon :icon="chevron" :rotation="filterOpen ? -90 : 0" @click="changeFilter()" />
        </div>
      </header>

      <div class="clear-button">
        <ButtonClear
          :text="t('corporations.clearFilters')"
          :no-border="true"
          variation="gray-light"
          @click="clearFilters"
        />
      </div>
      <transition name="slide-down">
        <div v-show="filterOpen">
          <DropdownFilter
            v-for="filter in filters"
            :key="filter.name"
            data-test="filter"
            :name="filter.name"
            :filter-active="filter.active.value"
            :filter-open="currentFilter === filter.name"
            @filter:open="currentFilter = $event"
          >
            <template #filter>
              <component :is="filter.component" v-bind="filter.props" v-on="filter.listeners" />
            </template>
          </DropdownFilter>
        </div>
      </transition>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.search-group {
  .search-input-wrapper {
    height: 2.5rem;
    border-radius: 10px;
    background: white;
  }

  .search-error {
    display: inline-block;
    margin-top: 0.5rem;
    color: $red;
    font-size: 0.8rem;
    text-align: center;
  }
}

.filters-wrapper {
  margin-top: 3rem;

  svg {
    height: 1.5rem;
    margin-right: 10px;
    color: $primary;
  }

  .filter-header {
    --filter-header-color: #{$primary};

    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    color: var(--filter-header-color);
    fill: var(--filter-header-color);
    font-weight: 600;
    stroke: var(--filter-header-color);

    h2 {
      display: flex;
      align-items: center;
      letter-spacing: 2px;
    }
  }
}

::v-deep(.clear-button) {
  display: flex;
  justify-content: flex-end;

  button {
    width: 8.125rem;
    padding: 5px;
    background-color: #fff;
  }
}

.slide-down-enter-from,
.slide-down-leave-to {
  transform: scaleY(0);
}

.slide-down-enter-active,
.slide-down-leave-active {
  transform-origin: top;
  transition: transform 100ms ease-in-out;
}

.slide-down-enter-to,
.slide-down-leave-from {
  transform: scaleY(1);
}
</style>
