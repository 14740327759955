import type { ApprovalCandidateType } from "@/common/services/candidates";
import { candidatesService, projectsService } from "@/http_services/client";

export async function getPlacement(project_id: string) {
  return projectsService.get(`/projects/${project_id}`)
    .then(res => res.data?.data);
}

export async function patchPlacement(project_id: string, placement_details: any) {
  return projectsService.patch(`/projects/${project_id}/placement/patch-details`, { placement_details })
    .then(res => res.data?.data);
}
export async function setPlacement(body: any) {
  return candidatesService.post("candidates/approve-to-placement", body)
    .then(res => res.data?.data);
}

export function candidateApproveToPlacement(body: ApprovalCandidateType) {
  return candidatesService.post(`candidates/${body.id}/decline-from-shortlist`, body)
    .then(res => res.data?.data);
}
