import { z } from "zod";
import { IndustriesEnumSchema, SectorsEnumSchema } from "../enums/corporation";
import { CurrencyEnumSchema } from "../enums/currency";
import {
  ContractTypeEnumSchema,
  ContractTypeMotivationEnumSchema,
  PaymentFrequencyEnumSchema,
} from "../enums/misc";

export const TelephoneNumberSchema = z
  .object({
    number: z.string(),
    calling_code: z.string(),
  })
  .strict();

export const EmailSchema = z.object({
  email_address: z.string(),
  is_main: z.boolean(),
});

export const AddressSchema = z
  .object({
    name: z.string(),
    street: z.string().nullable().optional(),
    postal_code: z.string().nullable().optional(),
    region: z.string().nullable().optional(),
    neighborhood: z.string().nullable().optional(),
    locality: z.string().nullable().optional(),
  })
  .strict();

export const MonetaryAmountSchema = z
  .object({
    amount: z.number(),
    currency: CurrencyEnumSchema,
  })
  .strict();

export const MonetaryAmountRangeSchema = z
  .object({
    currency: CurrencyEnumSchema,
    min_value: z.number(),
    max_value: z.number(),
  })
  .strict();

export const SalarySchema = MonetaryAmountSchema.extend({
  contract_type: ContractTypeEnumSchema.nullable(),
  payment_frequency: PaymentFrequencyEnumSchema.nullable(),
}).strict();

export const SalaryMotivationSchema = MonetaryAmountSchema.extend({
  contract_type: ContractTypeMotivationEnumSchema,
}).strict();

export const SalaryRangeSchema = MonetaryAmountRangeSchema.extend({
  contract_type: ContractTypeEnumSchema.nullable(),
});

export const BusinessCategorySchema = z
  .object({
    sector: SectorsEnumSchema,
    industry: IndustriesEnumSchema,
    is_main: z.boolean(),
  })
  .strict();

export const PersonForRecruiterSchema = z
  .object({
    first_name: z.string(),
    last_name: z.string(),
    avatar_url: z.string().nullable(),
  })
  .strict();

export const RelatedRecruiterSchema = z
  .object({
    person_id: z.string(),
    person_data: PersonForRecruiterSchema.nullish(),
  })
  .strict();

export const CorporationInAggregationSchema = z
  .object({
    _id: z.string(),
    name: z.string(),
    url: z.string(),
    avatar_url: z.string(),
    business_function: z.array(z.string()).nullable(),
    business_categories: z.array(BusinessCategorySchema).nullable(),
  })
  .strict();

export const RelatedCorporationSchema = z
  .object({
    name: z.string().nullish(),
    corporation_id: z.string(),
    corporation_data: CorporationInAggregationSchema.nullish(),
  })
  .strict();

export const PersonInAggregationSchema = z
  .object({
    _id: z.string(),
    first_name: z.string(),
    last_name: z.string(),
    email_addresses: z.array(EmailSchema),
    avatar_url: z.string(),
    linkedin_url: z.string().nullable(),
    related_recruiters: z.array(RelatedRecruiterSchema).nullish(),
    work_experience: z.array(z.any()).nullish(),
  })
  .strict();

export const RelatedPersonSchema = z.object({
  person_id: z.string(),
  person_data: PersonInAggregationSchema.nullish(),
});

export const RelatedPersonsListSchema = z
  .object({
    persons_ids: z.array(z.string()),
    persons_data: z.array(PersonInAggregationSchema).nullish(),
  })
  .strict();

export const RelatedCorporationsListSchema = z
  .object({
    corporations_id: z.array(z.string()),
    corporations_data: z.array(CorporationInAggregationSchema).nullish(),
  })
  .strict();

export const ExternalIdSchema = z
  .object({
    entity_id: z.string().nullable(),
    record_id: z.number().nullable(),
  })
  .strict();

export const ExternalIdsSchema = z
  .object({
    bullhorn_id: ExternalIdSchema.nullish(),
    netsuite_id: ExternalIdSchema.nullish(),
    hubspot_id: ExternalIdSchema.nullish(),
  })
  .strict();

export const GenericSchema = z.object({
  _version: z.number().optional(),
  id: z.string().optional(),
  serial: z.number().optional(),
  user_creator_id: z.string().optional(),
  is_active: z.boolean().optional(),
  inserted_at: z.string().optional(),
  updated_at: z.string().nullish(),
  started_at: z.string().nullish(),
  finished_at: z.string().nullish(),
});
