<script setup lang="ts">
import type { Ref } from "vue";
import bson from "bson-objectid";
import { mdiPlusCircleOutline } from "@mdi/js";
import { SvgIcon } from "@/components/icon";
import { ButtonIcon } from "@/components/button";
import { Modal } from "@/components/modal";
import { useInterviewCandidatesCount } from "@/common/composables/useInterviews";
import { useScreeningsCandidatesCount } from "@/common/composables/useScreening";
import AddBusinessFunction from "@/projects/pages/projects/components/AddBusinessFunction.vue";
import WarningIcon from "@/assets/images/alert_circle_icon.svg";
import { Tooltip } from "@/components/tooltip";
import { ANALYSIS_TYPES } from "@/entities/project/organization-and-assembly";

const { t } = useI18n();

// TODO: short this to reuse
type valueMapKey = keyof typeof valueMap;
const valueMap = {
  EMPTY: 0,
  LOW: 1,
  AVERAGE: 2,
  HIGH: 3,
  VERY_HIGH: 4,
};

interface AnalysisType {
  description: string
  analysis_type: string
}
interface UniqueIdMap {
  [key: string]: string
}
interface Realization {
  description: string
  desired_attribute: string
  desired_cultural_match: string
}
interface RealizationMap {
  [key: string]: Realization
}

interface AttributesRadio {
  cognitive_intelligence: valueMapKey
  emotional_intelligence: valueMapKey
  spiritual_intelligence: valueMapKey
  energy: valueMapKey
  maturity: valueMapKey
}
interface CulturalMatchRadio {
  practical: valueMapKey
  logical: valueMapKey
  reflexive: valueMapKey
  affective: valueMapKey
}
type IdealProfileKey = keyof typeof profileReferenceMap;
type ConstructionBaseKey = keyof typeof baseKeysMap;

const route = useRoute();

const {
  data: construction,
  refetch: refetchConstruction,
} = useConstructionOrganizationQuery(String(route.params.id));

const { data: screeningsCounter } = useScreeningsCandidatesCount({
  project_id: String(route.params.id),
  project_stage: "SCREENINGS",
  skip: 0,
  limit: 1,
});

const { data: interviewsCounter } = useInterviewCandidatesCount({
  project_id: String(route.params.id),
  project_stage: "INTERVIEWS",
  skip: 0,
  limit: 1,
});

const constructionId = computed(() => {
  return construction.value?._id;
});

const { mutate: patchConstruction } = usePatchConstructionOrganizationQuery();

const {
  mutate: patchIdealProfile,
  isPending: pendingProfile,
} = usePatchIdealProfileQuery();

const { mutate: createBusinessFunction } = useCreateBusinessFunctionQuery(
  String(route.params.id),
);

const { mutate: deleteBusinessFunction } = useRemoveBusinessFunctionQuery();

const { data: businessFunctions } = useBusinessFunctionsQuery(String(route.params.id));

type Tags = { text: string; selected: boolean | null }[];
const governance: Ref<AnalysisType[]> = ref([]);
const strategy: Ref<AnalysisType[]> = ref([]);
const executionConditions: Ref<AnalysisType[]> = ref([]);
const academicBackgrounds: Ref<UniqueIdMap> = ref({});
const languages: Ref<UniqueIdMap> = ref({});
const achievementsTags: Ref<Tags | null> = ref([]);
const experiencesTags: Ref<Tags | null> = ref([]);
const workExperiences: Ref<UniqueIdMap> = ref({});
const career: Ref<UniqueIdMap> = ref({});
const workEnvironment: Ref<UniqueIdMap> = ref({});
const personalLife: Ref<UniqueIdMap> = ref({});
const achievements: Ref<RealizationMap> = ref({});

const tags: Ref<{ [key: string]: Tags | null }> = ref({
  strategy: [],
  governance: [],
  execution_conditions: [],
});

const attributesMax = ref(2);

const blocked = ref({
  attributes: false,
  cultural_match: false,
});

const attributes = ref({
  cognitive_intelligence: "EMPTY",
  emotional_intelligence: "EMPTY",
  spiritual_intelligence: "EMPTY",
  energy: "EMPTY",
  maturity: "EMPTY",
} as AttributesRadio);

const culturalMatch = ref({
  practical: "EMPTY",
  logical: "EMPTY",
  reflexive: "EMPTY",
  affective: "EMPTY",
} as CulturalMatchRadio);

const motivationPackage = ref({
  salary: "",
  short_term_incentive: "",
  long_term_incentive: "",
  other_incentive: "",
  total_cash: "",
});

const consultantOpinion = ref("");

const profileReferenceMap = {
  languages,
  experiences: workExperiences,
  work_environment: workEnvironment,
  personal_life: personalLife,
  academic_backgrounds: academicBackgrounds,
  achievements,
  attributes,
  cultural_match: culturalMatch,
  package: motivationPackage,
  career,
  consultant_opinion: consultantOpinion,
  achievements_tags: achievementsTags,
  experiences_tags: experiencesTags,
};

const baseKeysMap = {
  governance,
  strategy,
  execution_conditions: executionConditions,
};

const constructionBase = {
  governance,
  strategy,
  execution_conditions: executionConditions,
};

function add(key: ConstructionBaseKey) {
  constructionBase[key].value.push({
    analysis_type: key.toLocaleUpperCase(),
    description: "",
  });
  save(key);
}

function remove(key: ConstructionBaseKey, index: number) {
  constructionBase[key].value.splice(index, 1);
  save(key);
}

const hasConfirmModal = ref(false);
const showAddProfileModal = ref(false);
const showRemoveProfileModal = ref(false);

const messageModalConfig = computed(() => ({
  error_save: {
    title: t("errors.titles.error").toUpperCase(),
    message: t("errors.messages.save"),
    buttons: {
      primary: t("errors.buttons.tryAgain"),
      secondary: t("errors.buttons.close"),
    },
  },
  cancel: {
    title: t("projects.details.activities.meetingsPanel.modal.title").toUpperCase(),
    message: t("projects.details.activities.meetingsPanel.modal.message"),
    buttons: {
      primary: t("projects.details.activities.meetingsPanel.modal.confirm"),
      secondary: t("projects.details.activities.meetingsPanel.modal.cancel"),
    },
  },
  save_after: {
    title: t("projects.construction.modal.titles.wantToSave").toUpperCase(),
    message: t("projects.construction.modal.messages.constructionAfterFinish", {
      screening: t("projects.construction.titles.screening"),
      interview: !interviewsCounter.value
        ? ""
        : `${t("text.conjunctions.and")}${t("projects.construction.titles.interview")}`,
    }),
    buttons: {
      primary: t("projects.construction.modal.buttons.continue"),
      secondary: t("projects.construction.modal.buttons.cancel"),
    },
  },
  save_after_finish: {
    title: t("projects.construction.modal.titles.wantToFinish").toUpperCase(),
    message: t("projects.construction.modal.messages.constructionAfterFinish", {
      screening: t("projects.construction.titles.screening"),
      interview: !interviewsCounter.value
        ? ""
        : `${t("text.conjunctions.and")}${t("projects.construction.titles.interview")}`,
    }),
    buttons: {
      primary: t("projects.construction.modal.buttons.finish"),
      secondary: t("projects.construction.modal.buttons.cancel"),
    },
  },
}));

const profilePending = ref();
const profilePendingIndex = ref();

function confirmProfileAdd() {
  if (!profilePending.value)
    return;
  const key = profilePending.value;

  let tmp = { ...profileReferenceMap[key].value };
  tmp = tmp || {};
  const newid = bson().toHexString();

  // @ts-expect-error missing map type infer from tmp
  tmp[newid] = "";
  profileReferenceMap[key].value = tmp;

  saveProfile(key);
  hasConfirmModal.value = true;
  showAddProfileModal.value = false;
}

function profileAdd(key: IdealProfileKey) {
  profilePending.value = key;

  // TODO: only show when has screenings
  if (!screeningsCounter.value || hasConfirmModal.value) {
    confirmProfileAdd();
    return;
  }

  if (!hasConfirmModal.value)
    showAddProfileModal.value = true;
}

function confirmProfileRemove() {
  if (!profilePending.value)
    return;

  const key = profilePending.value;
  const index = profilePendingIndex.value;
  const tmp = { ...profileReferenceMap[key].value };

  // @ts-expect-error missing map type infer from tmp
  delete tmp[index];
  profileReferenceMap[key].value = tmp;

  saveProfile(key);
  showRemoveProfileModal.value = false;
}

function removeProfile(key: IdealProfileKey, index: string | number) {
  profilePending.value = key;
  profilePendingIndex.value = index;

  if (!screeningsCounter.value) {
    confirmProfileRemove();
    return;
  }
  showRemoveProfileModal.value = true;
}

function addAchievements() {
  let tmp = { ...achievements.value };
  tmp = tmp || {};
  const newid = bson().toHexString();

  tmp[newid] = {
    description: "",
    desired_attribute: "EMPTY",
    desired_cultural_match: "EMPTY",
  };
  profileReferenceMap.achievements.value = tmp;
  saveProfile("achievements");
}

function saveProfile(key: IdealProfileKey) {
  patchIdealProfile({
    _id: String(constructionId.value),
    [key]: profileReferenceMap[key].value,
  });
}

function save(key: ConstructionBaseKey) {
  patchConstruction({
    _id: String(constructionId.value),
    [key]: {
      analyses: constructionBase[key].value,
      tags: tags.value[key],
    },
  });
}

const accordeonOpened = ref({
  academic: false,
  experience: false,
  motivation: false,
  language: false,
  achievements: false,
  attributes: false,
  cultural_match: false,
});

const baseAccordeons = ref({
  governance: false,
  strategy: false,
  execution_conditions: false,
  ideal_profile: false,
  experience: false,
  business_functions: false,
});

function focusAccordeon(key: string) {
  setTimeout(() => {
    const divFocus = document.getElementById(key);
    if (divFocus)
      divFocus?.scrollIntoView({ behavior: "smooth" });
  }, 250);
}

function toggleAccordeon(accordeon: string) {
  accordeonOpened.value = Object.assign(
    {
      academic: false,
      experience: false,
      motivation: false,
      language: false,
      achievements: false,
      attributes: false,
      cultural_match: false,
    },
    { [accordeon]: true },
  );
  refetchConstruction();

  if (accordeonOpened.value[accordeon])
    focusAccordeon(accordeon);
}

function baseCloseAll(value: boolean) {
  baseAccordeons.value = {
    governance: false,
    strategy: false,
    execution_conditions: false,
    ideal_profile: false,
    experience: false,
    business_functions: false,
  };
}

function toggleBaseAccordeon(accordeon: string) {
  baseAccordeons.value = Object.assign(
    {
      governance: false,
      strategy: false,
      execution_conditions: false,
      ideal_profile: false,
      experience: false,
      business_functions: false,
    },
    { [accordeon]: true },
  );
  if (baseAccordeons.value[accordeon])
    focusAccordeon(accordeon);

  refetchConstruction();
}

function setClosedAll(value: boolean) {
  accordeonOpened.value = {
    academic: false,
    experience: false,
    motivation: false,
    language: false,
    achievements: false,
    attributes: false,
    cultural_match: false,
  };
}

const isAddingLineOfBusiness = ref(false);

async function selectBusiness(item: any) {
  createBusinessFunction({
    project_id: String(route.params.id),
    tag: item.key,
  });
}

const attributesLimit = ref({});
const culturalMatchLimit = ref({});

function validateLimit(levelsData: any, limit = 2) {
  const limitAchieved
    = Object.values(levelsData).filter(el => el !== "EMPTY").length >= limit;
  return Object.keys(levelsData).reduce((acc, curr) => {
    return {
      ...acc,
      [curr]: !limitAchieved ? false : !!(levelsData[curr] === "EMPTY"), // if the limit has reached, only allow to remove
    };
  }, {});
}

function setInitialProfile(data: UniqueIdMap | null) {
  if (data && Object.values(data).length)
    return data;

  return {
    [bson().toHexString()]: "",
  };
}

function setInitialAchivements(data: RealizationMap) {
  if (data && Object.values(data).length)
    return data;

  return {
    [bson().toHexString()]: {
      description: "",
      desired_attribute: "EMPTY",
      desired_cultural_match: "EMPTY",
    },
  };
}

// TODO: Move this to 'using reactQuery as state manager'

watchEffect(() => {
  if (construction.value) {
    governance.value = toRaw(construction.value.governance.analyses);
    strategy.value = toRaw(construction.value.strategy.analyses);
    executionConditions.value = toRaw(construction.value.execution_conditions.analyses);

    academicBackgrounds.value = {
      ...setInitialProfile(construction.value.ideal_profile.academic_backgrounds),
    };
    languages.value = {
      ...setInitialProfile(construction.value.ideal_profile.languages),
    };
    workExperiences.value = {
      ...setInitialProfile(construction.value.ideal_profile.experiences),
    };
    career.value = { ...setInitialProfile(construction.value.ideal_profile.career) };
    workEnvironment.value = {
      ...setInitialProfile(construction.value.ideal_profile.work_environment),
    };
    personalLife.value = {
      ...setInitialProfile(construction.value.ideal_profile.personal_life),
    };

    attributes.value = { ...construction.value.ideal_profile.attributes };
    culturalMatch.value = { ...construction.value.ideal_profile.cultural_match };

    motivationPackage.value = toRaw(construction.value.ideal_profile.package);
    achievements.value = toRaw(
      setInitialAchivements(construction.value.ideal_profile.achievements),
    );
    experiencesTags.value = toRaw(construction.value.ideal_profile.experiences_tags);
    achievementsTags.value = toRaw(construction.value.ideal_profile.achievements_tags);

    // tags
    tags.value.strategy = toRaw(construction.value.strategy.tags);
    tags.value.governance = toRaw(construction.value.governance.tags);
    tags.value.execution_conditions = toRaw(construction.value.execution_conditions.tags);

    consultantOpinion.value = construction.value.ideal_profile.consultant_opinion;
    if (construction.value?.ideal_profile?.attributes)
      attributesLimit.value = validateLimit(construction.value.ideal_profile.attributes);

    if (construction.value?.ideal_profile?.cultural_match) {
      culturalMatchLimit.value = validateLimit(
        construction.value.ideal_profile.cultural_match,
      );
    }

    if (attributes.value)
      blocked.value.attributes = checkBlocked(attributes.value);
    if (culturalMatch.value)
      blocked.value.cultural_match = checkBlocked(culturalMatch.value);
  }
});

enum StatusTypes {
  EMPTY = "EMPTY",
  EDITING = "EDITING",
  COMPLETE = "COMPLETE",
}
enum StatusColors {
  EMPTY = "gray-medium",
  EDITING = "orange",
  COMPLETE = "green",
}

const accordionStatus = computed(() => {
  const analysisStatus = (data: {
    analyses: { description: string }[]
    tags: Array<string>
  }) => {
    function checkIsEmpty(array: typeof data) {
      if (!array || !Array.isArray(array.analyses) || !Array.isArray(array.tags))
        return false;

      return (
        array.analyses.every((item) => {
          if (item && item.description) {
            const trimmedDescription = item.description.trim();
            return trimmedDescription !== "";
          }
          return false;
        }) && array.tags.length > 0
      );
    }

    if (!data || !(data.analyses || []).length)
      return StatusColors.EMPTY;
    if (checkIsEmpty(data))
      return StatusColors.COMPLETE;
    else return StatusColors.EDITING;
  };

  const baseStatus = (items: Record<string, string> | null | undefined) => {
    if (!items)
      return StatusTypes.EMPTY;

    return (Object.values(items) || []).filter(Boolean).length
      ? StatusTypes.COMPLETE
      : StatusTypes.EMPTY;
  };

  const packageStatus = (packageData) => {
    if (!packageData)
      return StatusTypes.EMPTY;

    return Object.keys(packageData).every(
      el => packageData[el] && packageData[el].length,
    )
      ? StatusTypes.COMPLETE
      : Object.keys(packageData).every(el => !packageData[el])
        ? StatusTypes.EMPTY
        : StatusTypes.EDITING;
  };

  const radioStatus = (data) => {
    const VALID_RADIO_COUNT = 2;
    if (!data)
      return StatusTypes.EMPTY;

    const radioEvaluatedCount = Object.values(data).filter(radio => radio !== "EMPTY")
      .length;

    return radioEvaluatedCount >= VALID_RADIO_COUNT
      ? StatusTypes.COMPLETE
      : radioEvaluatedCount === 0
        ? StatusTypes.EMPTY
        : StatusTypes.EDITING;
  };

  const achievementsStatus = (achievements) => {
    if (!achievements || !Object.values(achievements).length)
      return StatusTypes.EMPTY;

    const achievementValid = (achievement) => {
      if (!achievement)
        return false;

      if (achievement.description?.length)
        return true;

      return false;
    };

    const mappedAchievements = Object.values(achievements).map(ach =>
      achievementValid(ach),
    );

    return mappedAchievements.every(el => !!el)
      ? StatusTypes.COMPLETE
      : mappedAchievements.some(el => !!el)
        ? StatusTypes.EDITING
        : StatusTypes.EMPTY;
  };

  const idealProfileStatus = (profile) => {
    const status = {
      academic_backgrounds: baseStatus(profile?.academic_backgrounds),
      languages: baseStatus(profile?.languages),
      experiences: baseStatus(profile?.experiences),
      career: baseStatus(profile?.career),
      personal_life: baseStatus(profile?.personal_life),
      work_environment: baseStatus(profile?.work_environment),
      package: packageStatus(profile?.package),
      attributes: radioStatus(profile?.attributes),
      cultural_match: radioStatus(profile?.cultural_match),
      achievements: achievementsStatus(profile?.achievements),
    };

    return Object.values(status).every(el => el === StatusTypes.COMPLETE)
      ? StatusColors.COMPLETE
      : Object.values(status).every(el => el === StatusTypes.EMPTY)
        ? StatusColors.EMPTY
        : StatusColors.EDITING;
  };

  const businessFunctionsStatus = () => {
    if (!businessFunctions.value)
      return StatusColors.EMPTY;

    return (businessFunctions.value || []).length
      ? StatusColors.COMPLETE
      : StatusColors.EMPTY;
  };

  return {
    governance: analysisStatus(construction.value?.governance),
    strategy: analysisStatus(construction.value?.strategy),
    execution_conditions: analysisStatus(construction.value?.execution_conditions),
    ideal_profile: idealProfileStatus(construction.value?.ideal_profile),
    business_functions: businessFunctionsStatus(),
  };
});

function showBeforeUnloadModal(event) {
  event.preventDefault();
  event.returnValue = "";
}

function checkBlocked(data: any) {
  const total = Object.keys(data).filter(key => data[key] !== "EMPTY");

  return total?.length >= attributesMax.value;
}

function updateAttributes(attribute_key: string, value: any) {
  attributes.value[attribute_key] = value;
  blocked.value.attributes = checkBlocked(attributes.value);

  saveProfile("attributes");
}

function updateCulturalMatch(attribute_key: string, value: any) {
  culturalMatch.value[attribute_key] = value;
  blocked.value.cultural_match = checkBlocked(culturalMatch.value);

  saveProfile("cultural_match");
}

window.addEventListener("beforeunload", showBeforeUnloadModal);

onBeforeUnmount(() => {
  window.removeEventListener("beforeunload", showBeforeUnloadModal);
});

defineExpose({
  accordionStatus,
});
function AnalysisLabel({
  label,
  tooltipWidth = 18,
}: {
  label: string
  tooltipWidth?: number
}) {
  return h("div", { class: "render-governance-label" }, [
    h(
      "label",
      {},
      t(`projects.details.activities.constructionPanel.analysisTypes.${label}`),
    ),
    h(
      Tooltip,
      {
        tooltipText: t(
          `projects.details.activities.constructionPanel.analysisTypesTooltips.${label}`,
        ),
        tooltipWidth: `${tooltipWidth}rem`,
      },
      () => [h(WarningIcon, { stroke: "#d80000", width: "1rem", height: "1rem" })],
    ),
  ]);
}

const accordionsStatusLabels = computed(() => {
  return {
    governance: t("projects.details.activities.constructionPanel.tabLabels.governance"),
    strategy: t("projects.details.activities.constructionPanel.tabLabels.strategy"),
    execution_conditions: t("projects.details.activities.constructionPanel.tabLabels.execution_conditions"),
  };
});
</script>

<template>
  <div class="organization-wrapper">
    <BoxAccordionStatus
      id="governance"
      icon="project_governance"
      :status-color="accordionStatus.governance"
      :title="t('projects.details.activities.constructionPanel.tabs.governance')"
      :open="baseAccordeons.governance"
      @selected="toggleBaseAccordeon('governance')"
      @close-all="baseCloseAll"
    >
      <template #accordion-status-label>
        <p class="label-ccordion mt-0 lh-5 mb-7.5">
          {{ accordionsStatusLabels.governance }}
        </p>
      </template>
      <template v-if="governance">
        <div
          v-for="(_, index) in governance"
          :key="index"
          :class="
            governance[index]?.analysis_type !== ANALYSIS_TYPES.GOVERNANCE
              ? 'base-item-analysis'
              : 'base-item'
          "
        >
          <AnalysisLabel
            v-show="governance[index]?.analysis_type !== ANALYSIS_TYPES.GOVERNANCE"
            :label="governance[index]?.analysis_type"
            :tooltip-width="18"
          />
          <TextArea
            v-model="governance[index].description"
            class="item-input"
            :name="`governance-${governance[index]?.analysis_type}`"
            :warning-chars="250"
            :placeholder="t('projects.details.constructionGuide.ranking.addDescription')"
            rows="5"
            @focus="refetchConstruction()"
            @change="save('governance')"
          />
          <hr
            v-show="governance[index]?.analysis_type !== ANALYSIS_TYPES.GOVERNANCE"
            class="separator"
          >
          <ButtonIcon
            v-show="governance[index]?.analysis_type === ANALYSIS_TYPES.GOVERNANCE"
            @click="remove('governance', index)"
          >
            <SvgIcon icon="trash_icon" width="20" height="20" margin="none" />
          </ButtonIcon>
        </div>
      </template>
      <div class="actions">
        <ButtonIcon class="panel-button add" @click="add('governance')">
          <SvgIcon
            :icon="mdiPlusCircleOutline"
            type="mdi"
            width="20"
            height="20"
            margin="none"
          />
          <span>{{
            t("projects.details.activities.constructionPanel.addAnalysis")
          }}</span>
        </ButtonIcon>
      </div>
      <TagsSearch
        :tags="tags.governance"
        :is-corporation-profile="true"
        :placeholder="
          t(`projects.details.activities.constructionPanel.analysisTypes.GOVERNANCE`)
        "
        :title="
          t(
            'projects.details.activities.constructionPanel.forms.tags.idealProfile.titles',
            {
              Title: t(
                `projects.details.activities.constructionPanel.analysisTypes.GOVERNANCE`,
              ),
            },
          )
        "
        @update-tag-list="(tags.governance = $event), save('governance')"
      />
    </BoxAccordionStatus>

    <BoxAccordionStatus
      id="strategy"
      :title="t('projects.details.activities.constructionPanel.tabs.strategy')"
      :open="baseAccordeons.strategy"
      icon="project_strategy"
      :status-color="accordionStatus.strategy"
      @selected="toggleBaseAccordeon('strategy')"
      @close-all="baseCloseAll"
    >
      <template #accordion-status-label>
        <p class="label-ccordion mt-0">
          {{ accordionsStatusLabels.strategy }}
        </p>
      </template>
      <div
        v-for="(_, index) in strategy"
        :key="index"
        :class="
          strategy[index]?.analysis_type !== ANALYSIS_TYPES.STRATEGY
            ? 'base-item-analysis'
            : 'base-item'
        "
      >
        <AnalysisLabel
          v-show="strategy[index]?.analysis_type !== ANALYSIS_TYPES.STRATEGY"
          :label="strategy[index]?.analysis_type"
          :tooltip-width="22"
        />
        <TextArea
          v-model="strategy[index].description"
          class="item-input"
          :name="`strategy-${index}`"
          :warning-chars="250"
          placeholder="Strategy"
          rows="5"
          @focus="refetchConstruction()"
          @change="save('strategy')"
        />
        <hr
          v-show="strategy[index]?.analysis_type !== ANALYSIS_TYPES.STRATEGY"
          class="separator"
        >
        <ButtonIcon
          v-show="strategy[index]?.analysis_type === ANALYSIS_TYPES.STRATEGY"
          @click="remove('strategy', index)"
        >
          <SvgIcon icon="trash_icon" width="20" height="20" margin="none" />
        </ButtonIcon>
      </div>
      <div class="actions">
        <ButtonIcon class="panel-button add" @click="add('strategy')">
          <SvgIcon
            :icon="mdiPlusCircleOutline"
            type="mdi"
            width="20"
            height="20"
            margin="none"
          />
          <span>{{
            t("projects.details.activities.constructionPanel.addAnalysis")
          }}</span>
        </ButtonIcon>
      </div>
      <TagsSearch
        :tags="tags.strategy"
        :is-corporation-profile="true"
        :placeholder="
          t(`projects.details.activities.constructionPanel.analysisTypes.STRATEGY`)
        "
        :title="
          t(
            'projects.details.activities.constructionPanel.forms.tags.idealProfile.placeholder',
            {
              Title: t(
                `projects.details.activities.constructionPanel.analysisTypes.STRATEGY`,
              ),
            },
          )
        "
        @update-tag-list="(tags.strategy = $event), save('strategy')"
      />
    </BoxAccordionStatus>

    <BoxAccordionStatus
      id="execution_conditions"
      :title="t('projects.details.activities.constructionPanel.tabs.executionConditions')"
      :open="baseAccordeons.execution_conditions"
      icon="project_conditions"
      :status-color="accordionStatus.execution_conditions"
      @selected="toggleBaseAccordeon('execution_conditions')"
      @close-all="baseCloseAll"
    >
      <template #accordion-status-label>
        <p class="label-ccordion mt-0">
          {{ accordionsStatusLabels.execution_conditions }}
        </p>
      </template>
      <div
        v-for="(_, index) in executionConditions"
        :key="index"
        :class="
          executionConditions[index]?.analysis_type
            !== ANALYSIS_TYPES.EXECUTION_CONDITIONS
            ? 'base-item-analysis'
            : 'base-item'
        "
      >
        <AnalysisLabel
          v-show="
            executionConditions[index]?.analysis_type
              !== ANALYSIS_TYPES.EXECUTION_CONDITIONS
          "
          :label="executionConditions[index]?.analysis_type"
        />

        <TextArea
          v-model="executionConditions[index].description"
          class="item-input"
          :name="`execution-conditions-${index}`"
          :warning-chars="250"
          :placeholder="t('projects.details.constructionGuide.ranking.addDescription')"
          rows="5"
          @focus="refetchConstruction()"
          @change="save('execution_conditions')"
        />
        <hr
          v-show="
            executionConditions[index]?.analysis_type
              !== ANALYSIS_TYPES.EXECUTION_CONDITIONS
          "
          class="separator"
        >
        <ButtonIcon
          v-show="
            executionConditions[index]?.analysis_type
              === ANALYSIS_TYPES.EXECUTION_CONDITIONS
          "
          @click="remove('execution_conditions', index)"
        >
          <SvgIcon icon="trash_icon" width="20" height="20" margin="none" />
        </ButtonIcon>
      </div>
      <div class="actions">
        <ButtonIcon class="panel-button add" @click="add('execution_conditions')">
          <SvgIcon
            :icon="mdiPlusCircleOutline"
            type="mdi"
            width="20"
            height="20"
            margin="none"
          />
          <span>{{
            t("projects.details.activities.constructionPanel.addAnalysis")
          }}</span>
        </ButtonIcon>
      </div>
      <TagsSearch
        :tags="tags.execution_conditions"
        :is-corporation-profile="true"
        :placeholder="
          t(
            `projects.details.activities.constructionPanel.analysisTypes.EXECUTION_CONDITIONS`,
          )
        "
        :title="
          t(
            'projects.details.activities.constructionPanel.forms.tags.idealProfile.placeholder',
            {
              Title: t(
                `projects.details.activities.constructionPanel.analysisTypes.EXECUTION_CONDITIONS`,
              ),
            },
          )
        "
        @update-tag-list="
          (tags.execution_conditions = $event), save('execution_conditions')
        "
      />
    </BoxAccordionStatus>

    <BoxAccordionStatus
      id="ideal_profile"
      class="ideal-profile-accordeon"
      :title="t('projects.details.activities.constructionPanel.tabs.idealProfile')"
      :open="baseAccordeons.ideal_profile"
      icon="project_ideal_profile"
      :status-color="accordionStatus.ideal_profile"
      @selected="toggleBaseAccordeon('ideal_profile')"
      @close-all="baseCloseAll"
    >
      <BoxAccordion
        id="academic"
        :title="t('projects.details.constructionGuide.idealProfile.academicFormation')"
        :open="accordeonOpened.academic"
        @selected="toggleAccordeon('academic')"
        @close-all="setClosedAll"
      >
        <div
          v-for="(_, key) of academicBackgrounds"
          :key="`academic-background-${key}`"
          class="base-item"
        >
          <TextArea
            v-model="academicBackgrounds[key]"
            class="item-input"
            :name="`academic-backgrounds-${key}`"
            :warning-chars="250"
            rows="5"
            :placeholder="t('projects.details.constructionGuide.ranking.addDescription')"
            @focus="refetchConstruction()"
            @change="saveProfile('academic_backgrounds')"
          />
          <ButtonIcon
            @click="
              () => (!pendingProfile ? removeProfile('academic_backgrounds', key) : {})
            "
          >
            <SvgIcon icon="trash_icon" width="20" height="20" margin="none" />
          </ButtonIcon>
        </div>
        <div class="actions">
          <ButtonIcon
            class="panel-button add"
            @click="profileAdd('academic_backgrounds')"
          >
            <SvgIcon
              :icon="mdiPlusCircleOutline"
              type="mdi"
              width="20"
              height="20"
              margin="none"
            />
            <span>{{
              t("projects.details.activities.organizationAndAssemblingForm.addFormation")
            }}</span>
          </ButtonIcon>
        </div>
        <div class="separate" />
        <div class="subtitle">
          {{ t("projects.screening.guide.languages") }}
        </div>
        <div v-for="(_, key) of languages" :key="`language-${key}`" class="base-item">
          <TextArea
            v-model="languages[key]"
            class="item-input"
            :name="`languages-${key}`"
            :warning-chars="250"
            rows="5"
            :placeholder="t('projects.details.constructionGuide.ranking.addDescription')"
            @change="saveProfile('languages')"
            @focus="refetchConstruction()"
          />
          <ButtonIcon @click="!pendingProfile ? removeProfile('languages', key) : {}">
            <SvgIcon icon="trash_icon" width="20" height="20" margin="none" />
          </ButtonIcon>
        </div>
        <div class="actions">
          <ButtonIcon class="panel-button add" @click="profileAdd('languages')">
            <SvgIcon
              :icon="mdiPlusCircleOutline"
              type="mdi"
              width="20"
              height="20"
              margin="none"
            />
            <span>{{
              t("projects.details.activities.organizationAndAssemblingForm.addLanguages")
            }}</span>
          </ButtonIcon>
        </div>
      </BoxAccordion>

      <BoxAccordion
        id="experience"
        :title="t('projects.details.constructionGuide.idealProfile.experience')"
        :open="accordeonOpened.experience"
        @selected="toggleAccordeon('experience')"
        @close-all="setClosedAll"
      >
        <div
          v-for="(_, key) of workExperiences"
          :key="`work-experience-${key}`"
          class="base-item"
        >
          <TextArea
            v-model="workExperiences[key]"
            class="item-input"
            :warning-chars="250"
            rows="5"
            :placeholder="t('projects.details.constructionGuide.ranking.addDescription')"
            @change="saveProfile('experiences')"
            @focus="refetchConstruction()"
          />
          <ButtonIcon
            @click="() => (!pendingProfile ? removeProfile('experiences', key) : {})"
          >
            <SvgIcon icon="trash_icon" width="20" height="20" margin="none" />
          </ButtonIcon>
        </div>
        <div class="actions">
          <ButtonIcon class="panel-button add" @click="profileAdd('experiences')">
            <SvgIcon
              :icon="mdiPlusCircleOutline"
              type="mdi"
              width="20"
              height="20"
              margin="none"
            />
            <span>{{
              t("projects.details.activities.organizationAndAssemblingForm.addExperience")
            }}</span>
          </ButtonIcon>
        </div>
        <TagsSearch
          :tags="experiencesTags"
          :placeholder="
            t(
              'projects.details.activities.constructionPanel.forms.tags.idealProfile.titlePath[0]',
            )
          "
          :title="
            t(
              'projects.details.activities.constructionPanel.forms.tags.idealProfile.titles',
              {
                Title: t(
                  'projects.details.activities.constructionPanel.forms.tags.idealProfile.titlePath[0]',
                ),
              },
            )
          "
          :is-corporation-profile="true"
          @update-tag-list="(experiencesTags = $event), saveProfile('experiences_tags')"
        />
      </BoxAccordion>

      <BoxAccordion
        id="achievements"
        :title="t('projects.details.constructionGuide.idealProfile.realizations.title')"
        :open="accordeonOpened.achievements"
        @selected="toggleAccordeon('achievements')"
        @close-all="setClosedAll"
      >
        <div v-if="achievements">
          <div
            v-for="(item, achievement_key, index) in achievements"
            :key="`achievement-${achievement_key}`"
          >
            <div>
              <label>{{ t("projects.details.constructionGuide.idealProfile.realization") }}
                {{ index + 1 }}:</label>
            </div>
            <div class="base-item">
              <TextArea
                v-model="item.description"
                class="item-input"
                :name="`achievement-${achievement_key}`"
                :warning-chars="250"
                rows="5"
                placeholder="Achivement"
                @change="saveProfile('achievements')"
                @focus="refetchConstruction()"
              />
              <ButtonIcon
                @click="
                  () =>
                    !pendingProfile ? removeProfile('achievements', achievement_key) : {}
                "
              >
                <SvgIcon icon="trash_icon" width="20" height="20" margin="none" />
              </ButtonIcon>
            </div>
            <!-- TODO - Vai ser discutido -->
            <!--
              <div>
              <label>
                {{ t("projects.details.constructionGuide.idealProfile.realizations.desiredAttribute") }}:
              </label>
              <Select
                v-model="item.desired_attribute" :placeholder="t('text.select')"
                :options="attributesOptions"
                @change:value="saveProfile('achievements')"
              />
            </div>
            <div>
              <label>
                {{
                  t("projects.details.constructionGuide.idealProfile.realizations.desiredCulturalMatch")
                }}:
              </label>
              <Select
                v-model="item.desired_cultural_match" :placeholder="t('text.select')"
                :options="culturalMatchOptions"
                @change:value="saveProfile('achievements')"
              />
            </div>
            -->
          </div>
          <div class="actions">
            <ButtonIcon class="panel-button add" @click="addAchievements()">
              <SvgIcon
                :icon="mdiPlusCircleOutline"
                type="mdi"
                width="20"
                height="20"
                margin="none"
              />
              <span>{{
                t(
                  "projects.details.activities.organizationAndAssemblingForm.addAchievement",
                )
              }}</span>
            </ButtonIcon>
          </div>
          <TagsSearch
            :tags="achievementsTags"
            :placeholder="
              t(
                'projects.details.activities.constructionPanel.forms.tags.idealProfile.titlePath[1]',
              )
            "
            :title="
              t(
                'projects.details.activities.constructionPanel.forms.tags.idealProfile.titles',
                {
                  Title: t(
                    'projects.details.activities.constructionPanel.forms.tags.idealProfile.titlePath[1]',
                  ),
                },
              )
            "
            :is-corporation-profile="true"
            @update-tag-list="
              (event) => ((achievementsTags = event), saveProfile('achievements_tags'))
            "
          />
        </div>
      </BoxAccordion>

      <BoxAccordion
        id="attributes"
        :title="t('projects.details.constructionGuide.idealProfile.attributes')"
        :open="accordeonOpened.attributes"
        @selected="toggleAccordeon('attributes')"
        @close-all="setClosedAll"
      >
        <div
          v-for="(attribute, attribute_key) of attributes"
          :key="`attribute-${attribute_key}`"
          class="enum-group"
        >
          <!-- TODO: add clamp feature -->
          <div class="enum-title">
            {{ t(`attributes.${attribute_key.toUpperCase()}`) }}
          </div>
          <div class="enum-tooltip">
            {{ t(`projects.shortlist.enums.attributes.${attribute_key.toUpperCase()}`) }}
          </div>
          <SliderKeyed
            :model-value="attribute"
            :disabled="blocked.attributes && attributes[attribute_key] === 'EMPTY'"
            @update:model-value="updateAttributes(attribute_key, $event)"
          />
        </div>
      </BoxAccordion>

      <BoxAccordion
        id="cultural_match"
        :title="t('projects.details.constructionGuide.idealProfile.culturalMatch')"
        :open="accordeonOpened.cultural_match"
        @selected="toggleAccordeon('cultural_match')"
        @close-all="setClosedAll"
      >
        <div
          v-for="(match, cultural_key) of culturalMatch"
          :key="`attribute-${cultural_key}`"
          class="enum-group"
        >
          <div class="enum-title">
            {{ t(`cultural_match.${cultural_key.toUpperCase()}`) }}
          </div>
          <div class="enum-tooltip">
            {{ t(`projects.shortlist.enums.cultural.${cultural_key.toUpperCase()}`) }}
          </div>
          <SliderKeyed
            :model-value="match"
            :disabled="blocked.cultural_match && match === 'EMPTY'"
            background-color="#ffb600"
            @update:model-value="updateCulturalMatch(cultural_key, $event)"
          />
        </div>
      </BoxAccordion>

      <BoxAccordion
        id="motivation"
        :title="t('projects.interview.accordeons.motivation')"
        :open="accordeonOpened.motivation"
        @selected="toggleAccordeon('motivation')"
        @close-all="setClosedAll"
      >
        <div v-if="academicBackgrounds">
          <div class="item-label item-label-lg">
            {{ t("projects.construction.titles.package") }}
          </div>

          <div class="motivation-items">
            <div v-for="(_, key, index) of motivationPackage" :key="index">
              <div class="item-label">
                {{ t(`projects.details.activities.screening.motivationEnum.${key}`) }}:
              </div>
              <TextArea
                v-model.trim="motivationPackage[key]"
                rows="5"
                :placeholder="
                  t('projects.details.constructionGuide.ranking.addDescription')
                "
                @change="saveProfile('package')"
                @focus="refetchConstruction()"
              />
            </div>
          </div>

          <div class="item-label item-label-lg">
            {{ t("projects.construction.titles.career") }}:
          </div>
          <div v-for="(_, key) of career" :key="key">
            <div class="base-item">
              <TextArea
                v-model.trim="career[key]"
                class="base-item-input"
                rows="5"
                :placeholder="
                  t('projects.details.constructionGuide.ranking.addDescription')
                "
                @change="saveProfile('career')"
                @focus="refetchConstruction()"
              />
              <ButtonIcon
                @click="() => (!pendingProfile ? removeProfile('career', key) : {})"
              >
                <SvgIcon icon="trash_icon" width="20" height="20" margin="none" />
              </ButtonIcon>
            </div>
          </div>
          <div class="actions">
            <ButtonIcon class="panel-button add" @click="profileAdd('career')">
              <SvgIcon
                :icon="mdiPlusCircleOutline"
                type="mdi"
                width="20"
                height="20"
                margin="none"
              />
              <span>{{
                t("projects.details.activities.organizationAndAssemblingForm.addCarreer")
              }}</span>
            </ButtonIcon>
          </div>
          <div class="item-label item-label-lg">
            {{ t("projects.construction.titles.environment") }}
          </div>
          <div v-for="(_, key) of workEnvironment" :key="key">
            <div class="base-item">
              <TextArea
                v-model.trim="workEnvironment[key]"
                class="base-item-input"
                rows="5"
                :placeholder="
                  t('projects.details.constructionGuide.ranking.addDescription')
                "
                @change="saveProfile('work_environment')"
                @focus="refetchConstruction()"
              />
              <ButtonIcon @click="removeProfile('work_environment', key)">
                <SvgIcon icon="trash_icon" width="20" height="20" margin="none" />
              </ButtonIcon>
            </div>
          </div>
          <div class="actions">
            <ButtonIcon class="panel-button add" @click="profileAdd('work_environment')">
              <SvgIcon
                :icon="mdiPlusCircleOutline"
                type="mdi"
                width="20"
                height="20"
                margin="none"
              />
              <span>{{
                t("projects.details.activities.organizationAndAssemblingForm.addAmbient")
              }}</span>
            </ButtonIcon>
          </div>

          <div class="item-label item-label-lg">
            {{ t("projects.construction.titles.personal") }}
          </div>
          <div v-for="(_, key) of personalLife" :key="key">
            <div class="base-item">
              <TextArea
                v-model.trim="personalLife[key]"
                class="base-item-input"
                rows="5"
                :placeholder="
                  t('projects.details.constructionGuide.ranking.addDescription')
                "
                @change="saveProfile('personal_life')"
                @focus="refetchConstruction()"
              />
              <ButtonIcon
                @click="
                  () => (!pendingProfile ? removeProfile('personal_life', key) : {})
                "
              >
                <SvgIcon icon="trash_icon" width="20" height="20" margin="none" />
              </ButtonIcon>
            </div>
          </div>
          <div class="actions">
            <ButtonIcon class="panel-button add" @click="profileAdd('personal_life')">
              <SvgIcon
                :icon="mdiPlusCircleOutline"
                type="mdi"
                width="20"
                height="20"
                margin="none"
              />
              <span>{{
                t("projects.details.activities.organizationAndAssemblingForm.addPersonal")
              }}</span>
            </ButtonIcon>
          </div>

          <div class="label-title">
            {{ t("projects.details.constructionGuide.idealProfile.consultantOpinion") }}
          </div>
          <TextArea
            v-model.trim="consultantOpinion"
            rows="5"
            :placeholder="t('projects.details.constructionGuide.ranking.addDescription')"
            @change="saveProfile('consultant_opinion')"
            @focus="refetchConstruction()"
          />
        </div>
      </BoxAccordion>
    </BoxAccordionStatus>

    <BoxAccordionStatus
      id="business_functions"
      :title="t('projects.details.activities.constructionPanel.tabs.lineOfBusiness')"
      :open="baseAccordeons.business_functions"
      icon="search_icon"
      :status-color="accordionStatus.business_functions"
      @selected="toggleBaseAccordeon('business_functions')"
      @close-all="setClosedAll"
    >
      <!-- <div v-if="businessFunctions" class="tags">
        <Tag
          v-for="business in businessFunctions" :key="business.tag" :tag="conclaOptions[business.tag] || business.tag"
          @click:close="removeBusinessFunction(business._id)"
        />
        <span v-show="!isAddingLineOfBusiness" role="button" class="add-tag" @click="isAddingLineOfBusiness = true">
          <SvgIcon type="mdi" :icon="mdiPlusCircleOutline" width="14px" height="14px" />
          {{ t("projects.details.activities.constructionPanel.addLineOfBusiness") }}
        </span>
      </div>
      <div v-show="isAddingLineOfBusiness">
        <SearchDropdown
          :id="`tag-${bson().toHexString()}`" fetch-param-key="term" :fetch-method="getBusinessFunction"
          :select-item-callback="selectBusiness" :min-word-length="1" :update-input-model="() => ''"
          :input-placeholder="t('text.searchCorporation')" :search-not-found-label="t('text.searchCorporationNotFound')"
          :search-error-label="t('text.searchCorporationError')"
        >
          <template #list-item="{ item }">
            {{ item.label }}
          </template>
        </SearchDropdown>
      </div> -->
      <AddBusinessFunction :id="route.params.id" />
    </BoxAccordionStatus>
    <Modal
      v-if="showAddProfileModal"
      class="modal-confirm"
      :title="messageModalConfig.save_after.title"
      :message="messageModalConfig.save_after.message"
      :button-text="messageModalConfig.save_after.buttons.primary"
      :secondary-button-text="messageModalConfig.save_after.buttons.secondary"
      :on-close="() => (showAddProfileModal = false)"
      :on-click="confirmProfileAdd"
      :secondary-on-click="() => (showAddProfileModal = false)"
      attach="body"
      variation="warning"
      secondary-variation="light"
      title-class="modal-title"
      message-class="modal-message"
      wrapper-class="content-wrapper"
      confirm-button-class="confirm-button"
      buttons-wrapper-class="buttons-wrapper"
    />
    <Modal
      v-if="showRemoveProfileModal"
      class="modal-confirm"
      :title="messageModalConfig.save_after.title"
      :message="messageModalConfig.save_after.message"
      :button-text="messageModalConfig.save_after.buttons.primary"
      :secondary-button-text="messageModalConfig.save_after.buttons.secondary"
      :on-close="() => (showRemoveProfileModal = false)"
      :secondary-on-click="() => (showRemoveProfileModal = false)"
      :on-click="confirmProfileRemove"
      attach="body"
      variation="warning"
      secondary-variation="light"
      title-class="modal-title"
      message-class="modal-message"
      wrapper-class="content-wrapper"
      confirm-button-class="confirm-button"
      buttons-wrapper-class="buttons-wrapper"
    />
  </div>
</template>

<style scoped lang="scss">
.render-governance-label {
  font-weight: 700;
  display: flex;
  gap: 0.5rem;
  margin-block-end: 0.8rem;
  align-items: center;

  label {
    color: $gray-dark;
    font-size: 1rem;
  }
  :deep(span.tooltip) {
    display: flex;
    width: 1rem;
    height: 1rem;
  }
}

.separator {
  width: 100%;
  height: 1px;
  border: 0px;
  border-top: 1px solid #bbb;
  background-color: transparent;
  margin-block: 1.75rem;
}

.organization-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.base-opinion {
  padding-block: 2rem;

  .label {
    padding-block-end: 0.5rem;
    color: $gray-dark;
    font-size: 1rem;
    font-weight: bold;
  }
}

.label-title {
  font-size: 0.875rem;
  padding-block: 0.5rem;
  font-weight: 600;
  user-select: none;
}

.inline {
  display: flex;
  // prevent parent
  margin: 0.5rem !important;
  justify-content: center;
  gap: 1.25rem;

  // prevent parent
  > div {
    margin: 0 !important;
  }
}

label {
  text-transform: capitalize;
}

.panel {
  margin: 0.5rem;
  border-radius: 8px;
  background: #fff 0% 0% no-repeat padding-box;
  box-shadow: 0 3px 4px #00000029;
  padding: 0.5rem;
}

.title {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-inline: 0.5rem;
  gap: 1rem;
  letter-spacing: 0.77px;

  span {
    color: $red-violet;
    font-weight: 600;
    font-size: 1.25rem;
    text-transform: uppercase;
    letter-spacing: inherit;
  }
}

.subtitle {
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.75rem;
  text-transform: uppercase;
}

.separate {
  width: 100%;
  height: 1px;
  background: $gray-border;
  margin-block: 1.5rem;
}

.actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-inline-end: 2.5rem;
  margin-block-end: 2rem;

  button.panel-button {
    margin: 0;
  }
}

.center {
  justify-content: center;
}

.icon-wrapper {
  color: inherit;
}

.base-item {
  display: flex;
  align-items: baseline;
  gap: 1rem;
  margin-block: 0.5rem 1.5rem;

  .base-item-analysis {
    display: flex;
    flex-direction: column;
  }
  .item-input {
    width: 100%;
  }
}

.item-label {
  color: $gray-dark;
  font-size: 0.875rem;
  font-weight: 700;
  margin: 1.25rem 0 0.25rem 0;
}

.item-label-lg {
  font-size: 1rem;
  text-transform: uppercase;
}

.motivation-items {
  display: flex;
  flex-direction: column;
}

button.panel-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  margin: 0.5rem 0 1.5rem 0;

  &:focus,
  &:hover {
    &.add,
    &.add > span {
      color: $red-violet-medium;

      svg {
        vertical-align: bottom;
        color: $red-violet-medium;
      }
    }
  }

  &.add,
  &.add > span {
    color: $red-violet;
    font-size: 0.75rem;
    letter-spacing: 0;
    font-weight: 600;

    svg {
      vertical-align: bottom;
      color: $red-violet;
    }
  }
}

.ideal-profile-accordeon:deep(.box-accordeon) {
  margin-left: 2rem;

  .header {
    border-bottom: 1px solid $gray-x14;
    margin-bottom: 1rem;
  }

  .base-item {
    margin-inline: 0;
    gap: 1rem;
  }
}

.base-item-input {
  width: 100%;
}

.tags {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-bottom: 1.5rem;
  gap: 0.75rem;

  .tag {
    white-space: pre-wrap;
    max-width: 36rem;
  }

  .add-tag {
    color: $primary;
    font-size: 0.75rem;
    letter-spacing: 0;

    &:hover {
      color: $red;
    }

    svg {
      vertical-align: sub;
    }
  }
}

.enum-group {
  padding-block: 0.5rem 1.5rem;
  margin: 0 1.5rem 2rem 0.25rem;
}

.enum-title {
  font-size: 0.875rem;
  padding-block: 0.5rem;
  font-weight: 600;
  user-select: none;
}

.enum-tooltip {
  font-size: 0.75rem;
  padding-block: 0.5rem;
  user-select: none;
}

.modal-confirm:deep {
  .modal-title {
    font-size: 1.25rem;
    font-weight: bold;
    text-align: center;
  }

  .modal-message {
    font-size: 1.25rem;
    font-weight: normal;
    line-height: 1.4;
    padding-inline: 1rem;
    text-align: center;
  }

  .content-wrapper {
    width: 46rem;
    min-width: 340px;
    padding: 3rem 3.25rem;
  }

  .buttons-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }

  .confirm-button {
    border-color: $secondary;
    background-color: $white-full;
    color: $secondary;

    &:hover {
      background-color: $secondary;
      color: $white-full;
    }
  }
}

:deep(.tooltip-message) strong {
  line-height: 1.2rem;
}
</style>
