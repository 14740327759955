<script setup lang="ts">
import type { Ref } from "vue";
import { computed, ref, toRef } from "vue";

interface PropsType {
  checked?: boolean
  onChange?: () => void
  label?: string
  variation?: string
}

const props = withDefaults(defineProps<PropsType>(), {
  variation: "primary",
});

const emit = defineEmits(["onCheck"]);

const onChange = toRef(props, "onChange") as Ref<(arg: any) => void>;
const checked = toRef(props, "checked") as Ref<boolean>;
const id = computed(() => (props.label || "").split(" ")[0]);

const isChecked = ref(false);

const color = computed(() => props.variation === "primary" ? "#c23584" : "#ffb600");

function emitChange(event: Event) {
  emit("onCheck", event.currentTarget?.checked);
  emit("onChange", event.currentTarget?.checked);
  if (onChange.value)
    onChange.value(event);
}

watchEffect(() => {
  isChecked.value = checked.value;
});
</script>

<template>
  <div class="form-checkbox-group">
    <input
      :id="id"
      v-model="isChecked"
      class="checkbox"
      type="checkbox"
      @change="emitChange"
    >
    <label v-if="label" :for="id">
      <slot>
        {{ label }}
      </slot>
    </label>
  </div>
</template>

<style lang="scss" scoped>
.checkbox {
  position: relative;
  display: flex;
  width: 20px;
  height: 20px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 2px solid #0003;
  border-radius: 50%;
  border-radius: 4px;
  margin: 0 0.5rem 0 0;
  margin-bottom: auto;
  appearance: none;
  background: transparent;
  cursor: pointer;
  outline: none;

  &::before {
    position: absolute;
    top: 0;
    width: 5px;
    height: 10px;
    border: 3px solid #0003;
    border-radius: 50%;
    border-radius: 0;
    border-top: 0;
    border-left: 0;
    background: transparent;
    content: "";
    opacity: 0;
    transform: rotate(45deg);
    transition: all 50ms ease-in-out;
  }

  &:checked {
    border-color: v-bind(color);
    background-color: v-bind(color);

    &::before {
      border-color: $white-full;
      opacity: 1;
    }
  }
}

.form-checkbox-group {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;

  label {
    position: relative;
    color: $gray-dark;
    font-family: "Open Sans";
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 1.4;
    cursor: pointer;

    .required {
      color: $red;
    }
  }
}
</style>
