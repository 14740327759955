<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { computed, ref } from "vue";
import type { Schedule, Schedules } from "@/entities/project";
import { SvgIcon } from "@/components/icon";
import { Button2 as Button } from "@/components/button";
import Calendar from "@/components/inputs/modalInputs/Calendar.vue";

// import { RelatedPerson } from "@/entities/common";
import { PROJECT_STAGE as ProjectStage } from "@/entities/project/index";

const props = defineProps<{
  schedules?: Schedules | any
}>();

const emit = defineEmits<{
  (event: "cancel"): void
  (event: "submit", value: Schedules): void
}>();

enum ScheduleStages {
  CONSTRUCTION = "construction",
  MAPPING = "mapping",
  SCREENINGS = "screenings",
  INTERVIEWS = "interviews",
  SHORTLIST = "shortlist",
  UNDER_OFFER = "under_offer",
  PLACEMENT = "placement",
}

const emptySchedule = {};
Object.values(ScheduleStages).forEach((stage) => {
  emptySchedule[stage] = {
    start_date: null,
    end_date: null,
    checkpoint_done_date: null,
    checkpoint_date: null,
  };
});

const schedulePayload = ref(props.schedules || emptySchedule);
const { t, tm } = useI18n();

const iconMap = {
  [ProjectStage.CONSTRUCTION]: "construction_icon",
  [ProjectStage.MAPPING]: "search_icon",
  [ProjectStage.SCREENINGS]: "audio_icon",
  [ProjectStage.INTERVIEWS]: "forum_icon",
  [ProjectStage.SHORTLIST]: "fact_check_icon",
  [ProjectStage.UNDER_OFFER]: "handshake_icon",
  [ProjectStage.PLACEMENT]: "star_icon",
};

const stages = computed(() =>
  Object.values(ProjectStage).map(stage => ({
    key: stage,
    title: tm(`projects.generic.stages.enum.${stage}`),
    icon: iconMap[stage],
  })));

// on calendar range select v-model must be valid or null
function getDurationModel(data: Schedule | null) {
  const { start_date, end_date } = data || {};

  return start_date || end_date ? [new Date(start_date), new Date(end_date)] : null;
}

function formatDateISO(date: Date | null) {
  return date ? new Date(date) : null;
}

const duration = ref({
  [ScheduleStages.CONSTRUCTION]: getDurationModel(schedulePayload.value?.construction),
  [ScheduleStages.MAPPING]: getDurationModel(schedulePayload.value?.mapping),
  [ScheduleStages.SCREENINGS]: getDurationModel(schedulePayload.value?.screenings),
  [ScheduleStages.INTERVIEWS]: getDurationModel(schedulePayload.value?.interviews),
  [ScheduleStages.SHORTLIST]: getDurationModel(schedulePayload.value?.shortlist),
  [ScheduleStages.UNDER_OFFER]: getDurationModel(schedulePayload.value?.under_offer),
  [ScheduleStages.PLACEMENT]: getDurationModel(schedulePayload.value?.placement),
});

const checkpoint = ref({
  [ScheduleStages.CONSTRUCTION]: formatDateISO(schedulePayload.value?.construction?.checkpoint_date),
  [ScheduleStages.MAPPING]: formatDateISO(schedulePayload.value?.mapping?.checkpoint_date),
  [ScheduleStages.SCREENINGS]: formatDateISO(schedulePayload.value?.screenings?.checkpoint_date),
  [ScheduleStages.INTERVIEWS]: formatDateISO(schedulePayload.value?.interviews?.checkpoint_date),
  [ScheduleStages.SHORTLIST]: formatDateISO(schedulePayload.value?.shortlist?.checkpoint_date),
  [ScheduleStages.UNDER_OFFER]: formatDateISO(schedulePayload.value?.under_offer?.checkpoint_date),
  [ScheduleStages.PLACEMENT]: formatDateISO(schedulePayload.value?.placement?.checkpoint_date),
});

function formatSchedulePayload() {
  const schedule = {};
  Object.values(ScheduleStages).forEach((stage) => {
    const [start_date, end_date] = duration.value[stage] || [];
    schedule[stage] = {
      start_date: formatDateISO(start_date),
      end_date: formatDateISO(end_date),
      checkpoint_date: formatDateISO(checkpoint.value[stage]),
      checkpoint_done_date: null,
    };
  });
  return schedule;
}
function submitSchedule() {
  emit("submit", formatSchedulePayload());
}
</script>

<template>
  <form class="form" @submit.prevent="submitSchedule()">
    <div class="card-wrapper">
      <template v-for="{ key, title, icon } of stages" :key="key">
        <div class="card">
          <div>
            <SvgIcon :icon="icon" width="4rem" height="4rem" />
          </div>
          <div class="title">
            {{ title }}
          </div>
          <label for="duration" class="text-sm">
            {{ t("projects.details.activities.schedule.duration") }}:
          </label>
          <div class="box-input">
            <Calendar
              v-model="duration[key]"
              class="small-input"
              :manual-input="false"
              name="duration"
              placeholder="02/01 - 16/01/2022"
              selection-mode="range"
            />
          </div>
          <label for="checkpoint" class="text-sm">
            {{ t("projects.details.activities.schedule.checkpoint") }}:
          </label>
          <div class="box-input">
            <Calendar
              v-model="checkpoint[key]"
              :manual-input="false"
              name="checkpoint"
              placeholder="16/01/2022"
            />
          </div>
        </div>
      </template>
    </div>
    <div class="form-actions lowercase">
      <Button type="submit">
        {{ t("projects.details.activities.schedule.save") }}
      </Button>
      <Button variation="light" filled @click="emit('cancel')">
        {{ t("projects.details.activities.schedule.cancel") }}
      </Button>
    </div>
  </form>
</template>

<style lang="scss" scoped>
:deep(.calendar.small-input .p-inputtext) {
  padding: 0.5rem 0.25rem !important;
  font-size: 0.75rem !important;
}

.calendar .p-button {
  width: 2rem !important;
}

.header {
  padding-bottom: 1rem;
  border-bottom: 2px solid #ececec;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
}

.card-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin: 1rem 0;
  gap: 2.5rem;
}

.card {
  display: flex;
  width: 14.5rem;
  height: 16rem;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px #00000029;
  color: $gray-low;
  font-size: 1rem;
  gap: 0.5rem;
  line-height: 1rem;

  > * {
    color: inherit;
  }

  .title {
    padding-bottom: 1rem;
    font-weight: 700;
  }

  .text-sm {
    align-self: flex-start;
    font-size: 0.875rem;
  }

  .box-input,
  .p-calendar {
    width: 100% !important;
  }
}

.form {
  margin: 1rem;
}

.lowercase {
  text-transform: lowercase;
}

.form-actions {
  display: flex;
  gap: 2rem;
  grid-column: 1 / -1;
}
</style>
