import type { Ref } from "vue";

export function removeForm(forms: Ref<any[]>) {
  return (index: number) => (): any => {
    forms.value.splice(index, 1);
  };
}

export function getData(forms: Ref<any[]>) {
  return () => {
    const formsData = Object.values(forms.value).filter(i => i);
    return formsData.map(i => i.formData);
  };
}

interface FormItem {
  value?: {
    clear: () => void
  }
}

type FormItems = FormItem[];

export function clearForm(items: FormItems) {
  return (): void => {
    items.forEach(i => i.value?.clear());
  };
}
