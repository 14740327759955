<script lang="ts">
import type { Ref } from "vue";
import cloneDeep from "lodash-es/cloneDeep";
import { useI18n } from "vue-i18n";
import { Button as ButtonDefault } from "@/components/button";
import TrashIcon from "@/assets/images/trash_icon.svg";
import type { PersonPayload } from "@/entities/Person/form";
import { AwardDataForm, ProjectDataForm, PublicationDataForm, VolunteerDataForm } from "@/persons/pages/persons/components/forms";

export default defineComponent({
  components: {
    TrashIcon,
    ButtonDefault,
    PublicationDataForm,
    AwardDataForm,
    ProjectDataForm,
    VolunteerDataForm,
  },
  props: {
    visible: Boolean,
    onSubmit: Function,
    onCancel: Function,
  },
  setup() {
    const { t } = useI18n();
    const person = inject("personPayload") as Ref<PersonPayload>;
    const payload = ref({
      projects: cloneDeep(person.value.projects),
      publications: cloneDeep(person.value.publications),
      volunteering: cloneDeep(person.value.volunteering),
      awards: cloneDeep(person.value.awards),
    });

    const add = (type: "projects" | "publications" | "awards" | "volunteering") => {
      const typeValuesMap = {
        projects: {
          title: null,
          start_date: null,
          end_date: null,
          is_current: false,
          description: null,
        },
        publications: {
          headline: null,
          publisher: null,
          published_date: null,
        },
        awards: {
          title: null,
          related_position: null,
          award_date: null,
          description: null,
        },
        volunteering: {
          title: null,
          start_date: null,
          end_date: null,
          is_current: false,
          description: null,
        },
      };

      payload.value[type].push(typeValuesMap[type]);
    };

    const removeItem = (
      type: "projects" | "publications" | "awards" | "volunteering",
      index: number,
    ) => {
      payload.value[type].splice(index, 1);
    };

    onMounted(async () => {
      if (!payload.value.projects.length)
        add("projects");
    });

    return {
      t,
      payload,
      add,
      removeItem,
    };
  },
});
</script>

<template>
  <div class="extra-activities-group">
    <div class="container editable-forms">
      <div class="items-wrapper">
        <template v-for="(item, index) in payload.projects" :key="item">
          <ProjectDataForm :project="item">
            <template #icon>
              <div class="remove-wrapper">
                <TrashIcon @click="removeItem('projects', index)" />
              </div>
            </template>
          </ProjectDataForm>
        </template>
        <ButtonDefault :text="t('persons.details.addProject')" @click="add('projects')" />
      </div>
      <div class="items-wrapper">
        <template v-for="(item, index) in payload.awards" :key="item">
          <AwardDataForm :award="item">
            <template #icon>
              <div class="remove-wrapper">
                <TrashIcon @click="removeItem('awards', index)" />
              </div>
            </template>
          </AwardDataForm>
        </template>
        <ButtonDefault :text="t('persons.details.addAward')" @click="add('awards')" />
      </div>
      <div class="items-wrapper">
        <template v-for="(item, index) in payload.publications" :key="item">
          <PublicationDataForm :publication="item">
            <template #icon>
              <div class="remove-wrapper">
                <TrashIcon @click="removeItem('publications', index)" />
              </div>
            </template>
          </PublicationDataForm>
        </template>
        <ButtonDefault :text="t('persons.details.addPublication')" @click="add('publications')" />
      </div>
      <div class="items-wrapper">
        <template v-for="(item, index) in payload.volunteering" :key="item">
          <VolunteerDataForm :volunteering="item">
            <template #icon>
              <div class="remove-wrapper">
                <TrashIcon @click="removeItem('volunteering', index)" />
              </div>
            </template>
          </VolunteerDataForm>
        </template>
        <ButtonDefault :text="t('persons.details.addVolunteering')" @click="add('volunteering')" />
      </div>
    </div>
    <div class="edit-actions">
      <ButtonDefault class="save-button" :text="t('persons.details.submit')" @click="onSubmit(payload)" />
      <ButtonDefault variation="secondary" :text="t('persons.details.cancel')" @click="onCancel()" />
    </div>
  </div>
</template>

<style scoped="true" lang="scss">
.container {
  width: 570px;
}

.edit-icon {
  position: relative;
  top: -4px;
  width: 14px;
  margin-left: 0.3rem;
  cursor: pointer;
  stroke: $gray-border;
}

.extra-activities:last-child {
  margin-bottom: 5px;
}

.extra-activities-group {
  display: flex;

  .extra-activities {
    padding: 15px 0;
  }

  .editable-forms {
    margin-right: 7.5%;
  }

  .edit-actions {
    display: flex;
    flex-direction: column;

    button:nth-of-type(1) {
      margin-bottom: 15px;

      &:hover {
        background: $yellow-dark;
        color: #fff;
      }
    }
  }

  button.add-button {
    width: 100%;
    margin-bottom: 30px;

    &:hover {
      background: $yellow-dark;
      color: #fff;
    }
  }

  .items-wrapper {
    margin-bottom: 1rem;
  }

  .items-wrapper .extra-activities {
    border-top: 1px solid $gray-light;

    &:first-of-type {
      border-top: 0;
    }
  }

  .cancel-button {
    background: $gray-lighter;
    color: $gray-dark;
    opacity: 0.6;
  }

  .remove-wrapper {
    display: flex;
    height: 35px;
    justify-content: flex-end;

    svg {
      height: 18px;
      stroke: $gray-light;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.save-button {
  padding-right: 1rem;
  padding-left: 1rem;
  white-space: nowrap;
}
</style>
