<script setup lang="ts">
import OpinionValue from "./OpinionValue.vue";

// import { IEllipsis } from "@/directives";

// import { OpinionEdit, OpinionValue } from "./components";

const props = withDefaults(
  defineProps<{
    opinion: string
    linebreak?: boolean
    ellipsis?: any
    asExport?: boolean
    editMode?: boolean
    showIcon?: boolean
    fontSize?: string
  }>(),
  {
    opinion: "",
    linebreak: false,
    asExport: false,
    editMode: false,
    showIcon: true,
    fontSize: "1rem",
  },
);

const emit = defineEmits(["onSave"]);

const editMode = ref(props.editMode ?? false);

const components = computed(() => ({
  "opinion-value": {
    component: OpinionValue,
    props: {
      ellipsis: props.ellipsis,
      opinion: props.opinion,
      linebreak: props.linebreak,
      asExport: props.asExport,
      fontSize: props.fontSize,
    },
    event: {
      onEdit: () => {
        editMode.value = true;
      },
    },
  },
}));

const defaultComponent = computed(() => {
  return editMode.value ? components.value["opinion-edit"] : components.value["opinion-value"];
});
</script>

<template>
  <div class="opinion">
    <div v-if="showIcon" class="opinion__avatar" />
    <!-- <component
      :is="defaultComponent.component"
      v-bind="defaultComponent.props"
      v-on="{
        ...defaultComponent.event,
      }"
    /> -->
    <OpinionValue v-bind="$props" />
  </div>
</template>

<style lang="scss" scoped>
.opinion {
  position: relative;
  display: flex;
  width: 100%;

  &__avatar {
    position: absolute;
    display: flex;
    overflow: hidden;
    width: 3.125rem;
    height: 3.125rem;
    align-items: center;
    justify-content: center;
    background: url("@/assets/images/flow_ef_dark.png") no-repeat center center;
    background-size: 3.5rem 3.5rem;
    border-radius: 50%;
  }

  &__content {
    width: 100%;
    min-height: 100px;
    padding: 1.3rem;
    margin: 0.5rem;
    background: $white-background;
    border-radius: 8px;
    box-shadow: 0 2px 4px #00000029;
    line-height: 1.25rem;
  }
}
</style>
