<script lang="ts" setup>
import { computed } from "vue";
import { Modal } from "@/components/modal";

const props = defineProps({
  disabled: { default: false, type: Boolean },
  currency: { default: "BRL", required: false, type: String },
  amount: { default: () => 0, type: Number },
  tag: { default: "span", required: false, type: String },
  personId: { type: String, required: true },
});

const emit = defineEmits(["update:amount"]);

const i18n = useI18n();
const modalVisible = ref(false);
const editable = ref(false);
const amount = ref(props.amount);
const localAmount = ref<string>(`${props.amount ?? 0}`);
const input = ref<HTMLInputElement>();
const localPrice = reactive({ value: "0", digits: "0" });

function saveAmount() {
  editable.value = false;
  if (!localAmount.value.length)
    localAmount.value = "0,00";

  const decimalsSeparator = localAmount.value.slice(-3)[0];
  const thousandsSeparator = decimalsSeparator === "." ? "," : ".";
  const localAmountAsNumber = Number(localAmount.value.replaceAll(thousandsSeparator, "").replace(decimalsSeparator ?? ",", "."));
  if (props.amount === localAmountAsNumber)
    return;

  parseAmount(localAmountAsNumber);
  emit("update:amount", props.personId, localAmountAsNumber);
}

function editMode() {
  if (props.disabled)
    return;
  editable.value = true;

  nextTick(() => input.value?.focus());
}

const nonEditableMessage = computed<string>(() => {
  return i18n.t(
    "projects.validation.extra.nonEditableSalary",
    { remuneration: `<b>${i18n.t("projects.validation.column.salaryShort")}</b>` },
  );
});

const hasEmptyValue = computed<boolean>(() => !amount.value && !editable.value);

function parseAmount(value?: number) {
  const price = value || 0;

  const money = price.toLocaleString("pt-BR", {
    style: "currency",
    currency: props.currency,
  });

  localAmount.value = money.replaceAll(/[^\d.,]/g, "");
  amount.value = Number(localAmount.value.replaceAll(/\./g, "").replace(",", "."));

  if (value === undefined)
    return;

  localPrice.value = money.slice(0, money.length - 3);
  localPrice.digits = money.slice(-3);
}

function onKeyDown(event: KeyboardEvent) {
  const isEditing = event.ctrlKey && ["c", "a", "x", "v"].includes(event.key);
  if (!event.key.match(/[0-9]/) && !isEditing && !["Backspace", "Enter", "Delete", "ArrowLeft", "ArrowRight"].includes(event.key))
    event.preventDefault();
}

watch(props, () => parseAmount(props.amount), { deep: true, immediate: true });
watch(editable, value => !value && saveAmount());
const showModal = () => props.disabled && (modalVisible.value = true);
</script>

<template>
  <div :class="{ 'editable-zone': !props.disabled }" @click="editMode">
    <div v-if="!hasEmptyValue" class="salary-item">
      <component :is="tag" class="currency" @click="showModal">
        <span v-text="localPrice.value" />
        <span v-text="localPrice.digits" />
      </component>

      <FormInput
        v-show="editable"
        ref="input"
        v-model="localAmount"
        :currency="props.currency"
        :money="true"
        :auto-fill="false"
        @blur="editable = false"
        @keydown="onKeyDown"
        @keyup.enter="editable = false"
      />
    </div>

    <div v-else class="empty-salary" @click="showModal">
      <FormInput
        v-show="editable"
        ref="input"
        v-model="localAmount"
        :currency="props.currency"
        :money="true"
        :auto-fill="false"
        @blur="editable = false"
        @keydown="onKeyDown"
        @keyup.enter="editable = false"
      />
    </div>

    <Modal
      v-if="modalVisible && props.disabled"
      :on-click="() => modalVisible = false"
      :on-close="() => modalVisible = false"
      :title="i18n.t('projects.validation.extra.caution').concat('!').toUpperCase()"
      button-text="OK"
      button-variation="orange"
    >
      <template #message>
        <div class="text-center">
          <span v-html="nonEditableMessage" />
        </div>
      </template>
    </Modal>
  </div>
</template>

<style lang="scss" scoped>
.salary-item {
  display: flex;
  justify-content: center;
}

.empty-salary {
  width: 100%;
  height: 100%;
}

.currency {
  display: flex;
  position: absolute;
  inset: 0;
  align-items: center;
  justify-content: center;
}

.text-center {
  text-align: center;
}

.editable-zone {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  :deep(.input-wrapper) {
    border: none;
    outline: none;
    border-radius: unset;
  }

  :deep(input) {
    font-size: 1rem !important;
    font-family: "Open Sans", sans-serif;
    text-align: center;
    padding: unset !important;
  }
}
</style>
