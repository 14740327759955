import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";

export function useCreateBusinessFunctionQuery(project_id: string) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["projects", project_id, "business_functions"],
    mutationFn: (business_function: any) => addBusinessFunction(business_function),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["projects", project_id, "business_functions"] });
    },
  });
}

export function useBusinessFunctionsQuery(project_id: string) {
  return useQuery({
    queryKey: ["projects", project_id, "business_functions"],
    queryFn: () => getBusinessFunctions(project_id),
  });
}
export function useRemoveBusinessFunctionQuery() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (_id: string) => removeBusinessFunction(_id),
    onSuccess: (_, id) => {
      queryClient.invalidateQueries({ queryKey: ["projects", id, "business_functions"] });
    },
  });
}

export function patchBusinessFunctionsQuery() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: any) => patchBusinessFunctions(body),
    onSuccess: (_, data) => {
      queryClient.invalidateQueries({ queryKey: ["projects", data.project_id, "business_functions"] });
    },
  });
}

export function updateBusinessFunctionsQuery() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (payload: any) => updateBusinessFunctions(payload),
    onSuccess: (_, { project_id }) => {
      queryClient.invalidateQueries({ queryKey: ["projects", project_id, "business_functions"] });
    },
  });
}

export function useRemoveBusinessCorporation(project_id: string) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (payload: { business_id: string; corporation_id: string }) => removeBusinessCorporation(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["projects", project_id, "business_functions"] });
    },
  });
}
