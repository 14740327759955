import type { Module } from "vuex";
import type { Ref } from "vue";
import type { Project } from "@/schemas/project";
import type { Person } from "@/schemas/person";

const ProjectStoreModule: Module<any, any> = {
  state() {
    const project = {} as Ref<Project>;
    const checkpoint = null;
    const organizationAndAssembly = null;
    const educationalBackground = null;
    const screeningExperience = null;
    const screeningMotivation = null;
    const vacancyProcess = null;
    const accordeonSelected = null;
    const accordeonInterviewSelected = null;
    const accordeonInterviewEvaluetionSelected = null;
    const PostScreeningMotivation = null;
    const interviewCandidateRealizations = null;
    const interviewCandidateRealizationsCompanies = null;
    const interviewCandidateMotivations = null;
    const shortlistPersons: Person[] = [];
    return {
      project,
      checkpoint,
      organizationAndAssembly,
      educationalBackground,
      screeningExperience,
      screeningMotivation,
      vacancyProcess,
      accordeonSelected,
      accordeonInterviewSelected,
      accordeonInterviewEvaluetionSelected,
      PostScreeningMotivation,
      interviewCandidateRealizations,
      interviewCandidateRealizationsCompanies,
      interviewCandidateMotivations,
      shortlistPersons,
    };
  },
  getters: {
    project: state => state.project,
    checkpoint: state => state.checkpoint,
    organizationAndAssembly: state => state.organizationAndAssembly,
    educationalBackground: state => state.educationalBackground,
    screeningExperience: state => state.screeningExperience,
    screeningMotivation: state => state.screeningMotivation,
    vacancyProcess: state => state.vacancyProcess,
    accordeonSelected: state => state.accordeonSelected,
    accordeonInterviewSelected: state => state.accordeonInterviewSelected,
    accordeonInterviewEvaluetionSelected: state => state.accordeonInterviewEvaluetionSelected,
    PostScreeningMotivation: state => state.PostScreeningMotivation,
    interviewCandidateRealizations: state => state.interviewCandidateRealizations,
    interviewCandidateRealizationsCompanies: state => state.interviewCandidateRealizationsCompanies,
    interviewCandidateMotivations: state => state.interviewCandidateMotivations,
    shortlistPersons: state => state.shortlistPersons,
  },
  mutations: {
    SET_PROJECT: (state, data) => {
      state.project = data;
    },
    SET_CHECKPOINT: (state, data) => {
      state.checkpoint = data;
    },
    SET_ORGANIZATION_AND_ASSEMBLY: (state, data) => {
      state.organizationAndAssembly = data;
    },
    SET_EDUCATION_BACKGROUND: (state, data) => {
      state.educationalBackground = data;
    },
    SET_SCREENING_EXPERIENCE: (state, data) => {
      state.screeningExperience = data;
    },
    SET_SCREENING_MOTIVATION: (state, data) => {
      state.screeningMotivation = data;
    },
    SET_VACANCY_PROCESS: (state, data) => {
      state.vacancyProcess = data;
    },
    SET_ACCORDEON_SELECTED: (state, data) => {
      state.accordeonSelected = data;
    },
    SET_ACCORDEON_INTERVIEW_SELECTED: (state, data) => {
      state.accordeonInterviewSelected = data;
    },
    SET_ACCORDEON_INTERVIEW_EVALUETION_SELECTED: (state, data) => {
      state.accordeonInterviewEvaluetionSelected = data;
    },
    SET_INTERVIEW_CANDIDATE_REALIZATIONS_COMPANIES: (state, data) => {
      state.interviewCandidateRealizationsCompanies = data;
    },
    SET_INTERVIEW_CANDIDATE_REALIZATIONS: (state, data) => {
      state.interviewCandidateRealizations = data;
    },
    SET_INTERVIEW_CANDIDATE_MOTIVATION: (state, data) => {
      state.interviewCandidateMotivations = data;
    },
    SET_POST_SCREENING_MOTIVATION: (state, data) => {
      state.PostScreeningMotivation = data;
    },
    SET_POST_SCREENING_EDUCATION: (state, data) => {
      state.PostScreeningEducation = data;
    },
    SET_SHORTLIST_PERSONS: (state, data) => {
      state.shortlistPersons = data;
    },
  },
};

export default ProjectStoreModule;
