<script setup lang="ts">
import debounce from "lodash-es/debounce";
import ConstructionOrganization from "./components/ConstructionOrganization.vue";
import PersonDetail from "@/persons/pages/persons/components/PersonDetail/PersonDetail.vue";
import { SvgIcon } from "@/components/icon";
import Editor from "@/components/editor/Editor.vue";
import { Modal } from "@/components/modal";
import { getPerson } from "@/http_services/entities/persons";
import { isSaving } from "@/common/composables/useConstructionOrganization";
import { isNotesSaving } from "@/common/composables/useConstruction";
import { FUNCTIONAL_SCOPE_TITLES, GOVERNANCE_TITLES, POSITION_STRATEGY_TITLES, PROFILE_TITLES, REALIZATIONS_TITLES, STRATEGY_TITLES } from "@/entities/project/construction-guide";

export interface Notes {
  executions_conditions: string
  header: string
  ideal_profile: string
  governance: string
  strategy: string
}

export type NotesKeys = keyof Notes;

const props = defineProps<{ id: string }>();
const route = useRoute();
const router = useRouter();
const { t } = useI18n();

const { data: project, error, isLoading } = useProject(props.id);

const projectId = computed(() => route.params.id || "") as Ref<string>;
const activityId = computed(() => route.params.activity || "") as Ref<string>;

const loading = ref(false);
const activity = ref(null);

const recruiter: Ref<{ id: string; alt: string; scr: string }[]> = ref([]);
const requester: Ref<{ id: string; alt: string; scr: string }[]> = ref([]);

const requesterId = ref();

const {
  data: constructionNotes,
  isFetched,
  refetch,
} = useConstructionNotes(String(route.params.activity));

const notes: Ref<Notes> = ref({
  header: "",
  current_moment: "",
  activities_scope: "",
  background: "",
  experience: "",
});

const {
  mutate: pathActivityAnnotations,
} = usePatchConstructionAnnotationsMutation();

const {
  data: activities,
  mutate: fecthActivities,
} = useProjectActivitiesMutate();

const {
  mutate: fetchConstructionActivity,
} = useConstructionActivityMutate();

const requesters = computed(() => {
  let list = [...(activities.value || [])];
  const selected = list.filter(item => item._id === activityId.value);

  if (selected[0]) {
    const selectedIndex = list.indexOf(selected[0]);
    list?.splice(selectedIndex, 1);
    if (selectedIndex > -1)
      list = [...selected, ...list];
  }
  return list?.map(item => ({ ...item.requester, activity_id: item._id }));
});

const recruiters = computed(() => {
  let list = [...(activities.value || [])];
  const selected = list.filter(item => item._id === activityId.value);

  if (selected[0]) {
    const selectedIndex = list.indexOf(selected[0]);
    list?.splice(selectedIndex, 1);
    if (selectedIndex > -1)
      list = [...selected, ...list];
  }
  return list?.map(item => ({ ...item.recruiter, activity_id: item._id }));
});

const hasScreening = ref(false);
const hasInterview = ref(false);

const minimized = ref<string[]>(["guide"]);
const titles = computed(() => ({
  guide: t("projects.details.tabs.constructionGuide"),
  notes: t("projects.details.activities.meetingsPanel.notes"),
  organization: t("projects.details.activities.organizationAndAssembling"),
}));

const messageModalShow = ref(false);
const messageModalType = ref("");
const messageModalConfig = computed(() => ({
  error_save: {
    title: t("errors.titles.error").toUpperCase(),
    message: t("errors.messages.save"),
    buttons: {
      primary: t("errors.buttons.tryAgain"),
      secondary: t("errors.buttons.close"),
    },
  },
  cancel: {
    title: t("projects.details.activities.meetingsPanel.modal.title").toUpperCase(),
    message: t("projects.details.activities.meetingsPanel.modal.message"),
    buttons: {
      primary: t("projects.details.activities.meetingsPanel.modal.confirm"),
      secondary: t("projects.details.activities.meetingsPanel.modal.cancel"),
    },
  },
  save_after: {
    title: t("projects.construction.modal.titles.wantToSave").toUpperCase(),
    message: t("projects.construction.modal.messages.constructionAfterFinish", {
      screening: t("projects.construction.titles.screening"),
      interview: !hasInterview.value
        ? ""
        : `${t("text.conjunctions.and")}${t("projects.construction.titles.interview")}`,
    }),
    buttons: {
      primary: t("projects.construction.modal.buttons.save"),
      secondary: t("projects.construction.modal.buttons.cancel"),
    },
  },
  save_after_finish: {
    title: t("projects.construction.modal.titles.wantToFinish").toUpperCase(),
    message: t("projects.construction.modal.messages.constructionAfterFinish", {
      screening: t("projects.construction.titles.screening"),
      interview: !hasInterview.value
        ? ""
        : `${t("text.conjunctions.and")}${t("projects.construction.titles.interview")}`,
    }),
    buttons: {
      primary: t("projects.construction.modal.buttons.finish"),
      secondary: t("projects.construction.modal.buttons.cancel"),
    },
    fill_out: {
      title: t("projects.construction.modal.fillOut.title"),
      message: t("projects.construction.modal.fillOut.message"),
      buttons: {
        primary: t("projects.construction.modal.fillOut.buttons.primary"),
        secondary: t("projects.construction.modal.fillOut.buttons.secondary"),
      },
    },
  },
}));

function openMessageModal(type: string, temp = true) {
  messageModalType.value = type;
  messageModalShow.value = true;
}

function closeMessageModal() {
  messageModalType.value = "";
  messageModalShow.value = false;
}

const redirectToIndex = () => router.push(`/projects/${route.params.id}/construction/activities`);
const redirectToNextStage = () => router.push(`/projects/${route.params.id}/construction/ideal-profile/about`);

async function primaryClickButton() {
  switch (messageModalType.value) {
    case "save_after":
      console.log("Saved");
      break;
    case "error_save":
      console.log("Error Save");
      break;
    case "save_after_finish":
      redirectToIndex();
      break;
  }
  closeMessageModal();
}

function secondaryClickButton() {
  switch (messageModalType.value) {
    case "cancel":
      redirectToIndex();
      break;
  }
  closeMessageModal();
}

const debouncePatchAnnotations = debounce(pathActivityAnnotations, 300);

function update(key: NotesKeys) {
  debouncePatchAnnotations({
    id: activityId.value,
    annotations: { [key]: notes.value[key] },
  });
}

function minimizedAdd(value: string) {
  minimized.value.push(value);
}

function minimizedRemove(value: string) {
  const indexToRemove = minimized.value.indexOf(value);
  if (indexToRemove > -1)
    minimized.value.splice(indexToRemove, 1);
}

function toggleAccordeon(key: string) {
}

const strategyGuide = computed(() => ([{
  title: t("projects.details.constructionGuide.tabs.strategy"),
  questions: Object.keys(STRATEGY_TITLES).map(key => t(`projects.details.constructionGuide.questions.${key}`)),
}]));

const governanceGuide = computed(() => ([{
  title: t("projects.details.constructionGuide.tabs.governance"),
  questions: Object.keys(GOVERNANCE_TITLES).map(key => t(`projects.details.constructionGuide.questions.${key}`)),
}]));

const profileGuide = computed(() => ([{
  title: t("projects.details.constructionGuide.tabs.profile"),
  questions: Object.keys(PROFILE_TITLES).map(key => t(`projects.details.constructionGuide.questions.${key}`)),
}]));

const positionStrategyGuide = computed(() => ([{
  title: t("projects.details.constructionGuide.tabs.executionConditions"),
  questions: Object.keys(POSITION_STRATEGY_TITLES).map(key => t(`projects.details.constructionGuide.questions.${key}`)),
}]));

const achievementsGuide = computed(() => ([{
  title: t("projects.details.constructionGuide.tabs.achievements"),
  questions: Object.keys(REALIZATIONS_TITLES).map(key => t(`projects.details.constructionGuide.questions.${key}`)),
}]));

const functionalScopeGuide = computed(() => ([{
  title: t("projects.details.constructionGuide.tabs.functionalScope"),
  questions: Object.keys(FUNCTIONAL_SCOPE_TITLES).map(key => t(`projects.details.constructionGuide.questions.${key}`)),
}]));

const showPersonDetail = ref(false);
const selectedPerson = ref(undefined);

async function onSelectRequester(id: string) {
  const data = await getPerson(id);

  if (data) {
    selectedPerson.value = toRaw(data);
    showPersonDetail.value = true;
  }
}

// TODO: remove all provide from app
provide("onCloseDetails", () => {
  showPersonDetail.value = false;
  selectedPerson.value = undefined;
});

const organizationRef = ref();

const showIdealProfileModal = ref(false);
const showFillOutIdealProfileModal = ref(false);

function closeIdealProfileModal() {
  showIdealProfileModal.value = false;
  showFillOutIdealProfileModal.value = false;
}

function confirmIdealProfileModal() {
  showIdealProfileModal.value = false;
  showFillOutIdealProfileModal.value = false;
  redirectToNextStage();
}

function confirm() {
  const { accordionStatus } = organizationRef.value;
  const { stages } = project?.value;

  if (accordionStatus.ideal_profile !== "green") {
    const hasStageWithCount = Object.values(stages).some(stage => stage?.count >= 1);

    if (hasStageWithCount)
      showIdealProfileModal.value = true;

    else
      showFillOutIdealProfileModal.value = true;
  }
  else {
    redirectToNextStage();
  }
}
provide("selectedPerson", selectedPerson);

const showLoader = computed(() => isSaving.value || isNotesSaving.value);

function loadData() {
  if (activity.value) {
    requester.value = activity.value?.requester;
    recruiter.value = activity.value?.recruiter;
    notes.value = { ...activity.value?.annotations };
  }
}

function fetchActivity() {
  activity.value = null;
  fetchConstructionActivity(activityId.value, {
    onSuccess: (result) => {
      activity.value = result;
      loadData();
      loading.value = false;
    },
    onError: () => {
      loadData();
      loading.value = false;
    },
  });
}

function changeRecruiter(activity_id: string) {
  router.push(`/projects/${projectId.value}/construction/activities/${activity_id}`);
  setTimeout(() => fetchActivity(), 300);
}

onMounted(async () => {
  fecthActivities({ project_id: projectId.value });
  fetchActivity();
});
</script>

<template>
  <div>
    <div v-if="!isLoading && !error" id="construction-activity" class="scrollable-container panel-wrapper">
      <HeaderActivity
        type="construction"
        :project-name="project?.project_name"
        :corporation-name="project?.corporation?.corporation_name"
        :requesters="requesters"
        :recruiters="recruiters"
        :activity-id="activityId"
        :loading="showLoader"
        @click:logo="redirectToIndex()"
        @click:profile="onSelectRequester(requesterId)"
        @click:cancel="redirectToIndex()"
        @click:save="confirm"
        @click:temp="confirm"
        @change:recruiter="changeRecruiter($event)"
        @change:requester="onSelectRequester($event)"
      />
      <div class="panels scrollable-content" :class="{ minimized: minimized.length > 0 }">
        <div v-if="minimized.length > 0" class="panel-minimized">
          <div
            v-if="minimized.includes('guide')"
            class="panel-box box-guide"
            @click="minimizedRemove('guide')"
          >
            <div class="panel-title">
              <SvgIcon
                icon="construction_square_x_icon"
                width="18"
                height="18"
                class="icon_title"
              />
              <span>{{ titles.guide }}</span>
            </div>
          </div>
          <div
            v-if="minimized.includes('notes')"
            class="panel-box box-notes"
            @click="minimizedRemove('notes')"
          >
            <div class="panel-title">
              <SvgIcon
                icon="construction_square_x_icon"
                width="18"
                height="18"
                class="icon_title"
              />
              <span>{{ titles.notes }}</span>
            </div>
          </div>
          <div
            v-if="minimized.includes('organization')"
            class="panel-box box-organization"
            @click="minimizedRemove('organization')"
          >
            <div class="panel-title">
              <SvgIcon
                icon="construction_square_x_icon"
                width="18"
                height="18"
                class="icon_title"
              />
              <span>{{ titles.organization }}</span>
            </div>
          </div>
        </div>
        <div v-if="!minimized.includes('guide')" class="scrollable-content panel">
          <div class="title" @click="minimizedAdd('guide')">
            <SvgIcon
              icon="construction_square_x_icon"
              width="18"
              height="18"
              class="icon_title"
            />
            <span>{{ titles.guide }}</span>
          </div>
          <div class="guide-container scrollable-content example-scrollable-full">
            <div style="width: 100%;">
              <BoxAccordion
                :title="t('projects.details.constructionGuide.tabs.company')"
                @selected="toggleAccordeon('academicGuide')"
              >
                <div v-for="(group, idx) in governanceGuide" :key="idx">
                  <div v-if="group.title" class="guide-title">
                    {{ group.title }}
                  </div>
                  <div v-for="(question, questionidx) in group.questions" :key="questionidx" class="guide-question">
                    {{ question }}
                  </div>
                </div>
                <div v-for="(group, idx) in strategyGuide" :key="idx">
                  <div v-if="group.title" class="guide-title">
                    {{ group.title }}
                  </div>
                  <div v-for="(question, questionidx) in group.questions" :key="questionidx" class="guide-question">
                    {{ question }}
                  </div>
                </div>
              </BoxAccordion>
              <BoxAccordion
                :title="t('projects.details.constructionGuide.tabs.position')"
                @selected="toggleAccordeon('positionGuide')"
              >
                <div v-for="(group, idx) in profileGuide" :key="idx">
                  <div v-if="group.title" class="guide-title">
                    {{ group.title }}
                  </div>
                  <div v-for="(question, questionidx) in group.questions" :key="questionidx" class="guide-question">
                    {{ question }}
                  </div>
                </div>
                <div v-for="(group, idx) in positionStrategyGuide" :key="idx">
                  <div v-if="group.title" class="guide-title">
                    {{ group.title }}
                  </div>
                  <div v-for="(question, questionidx) in group.questions" :key="questionidx" class="guide-question">
                    {{ question }}
                  </div>
                </div>
                <div v-for="(group, idx) in achievementsGuide" :key="idx">
                  <div v-if="group.title" class="guide-title">
                    {{ group.title }}
                  </div>
                  <div v-for="(question, questionidx) in group.questions" :key="questionidx" class="guide-question">
                    {{ question }}
                  </div>
                </div>
                <div v-for="(group, idx) in functionalScopeGuide" :key="idx">
                  <div v-if="group.title" class="guide-title">
                    {{ group.title }}
                  </div>
                  <div v-for="(question, questionidx) in group.questions" :key="questionidx" class="guide-question">
                    {{ question }}
                  </div>
                </div>
              </BoxAccordion>
            </div>
          </div>
        </div>
        <div
          v-if="isFetched && constructionNotes && !minimized.includes('notes')"
          class="scrollable-content panel panel-border-sm"
        >
          <div class="title" @click="minimizedAdd('notes')">
            <SvgIcon
              icon="construction_square_x_icon"
              width="18"
              height="18"
              class="icon_title"
            />
            <span>{{ titles.notes }}</span>
          </div>
          <div class="scrollable-content example-scrollable-full">
            <div style="width: 100%">
              <Editor
                v-model="notes.header"
                rows="15"
                :placeholder="t('text.text')"
                @update:model-value="update('header')"
                @blur="fetchActivity()"
              />
            </div>
            <div style="width: 100%;">
              <div class="editor-title">
                {{
                  t(
                    "projects.details.activities.constructionPanel.analysisTypes.IDEAL_PROFILE",
                  )
                }}
              </div>
              <Editor
                v-model="notes.ideal_profile"
                :placeholder="t('text.text')"
                @update:model-value="update('ideal_profile')"
                @blur="fetchActivity()"
              />
            </div>
            <div style="width: 100%;">
              <div class="editor-title">
                {{
                  t(
                    "projects.details.activities.constructionPanel.analysisTypes.GOVERNANCE",
                  )
                }}
              </div>
              <Editor
                v-model="notes.governance"
                :placeholder="t(
                  'projects.details.activities.constructionPanel.analysisTypes.GOVERNANCE',
                )"
                @update:model-value="update('governance')"
                @blur="fetchActivity()"
              />
            </div>
            <div style="width: 100%;">
              <div class="editor-title">
                {{
                  t(
                    "projects.details.activities.constructionPanel.analysisTypes.STRATEGY",
                  )
                }}
              </div>
              <Editor
                v-model="notes.strategy"
                :placeholder="t(
                  'projects.details.activities.constructionPanel.analysisTypes.STRATEGY',
                )"
                @update:model-value="update('strategy')"
                @blur="fetchActivity()"
              />
            </div>

            <div style="width: 100%;">
              <div class="editor-title">
                {{
                  t(
                    "projects.details.activities.constructionPanel.analysisTypes.EXECUTION_CONDITIONS",
                  )
                }}
              </div>
              <Editor
                v-model="notes.executions_conditions"
                :placeholder="t(
                  'projects.details.activities.constructionPanel.analysisTypes.EXECUTION_CONDITIONS',
                )"
                @update:model-value="update('executions_conditions')"
                @blur="fetchActivity()"
              />
            </div>
          </div>
        </div>
        <div
          v-if="!minimized.includes('organization')"
          class="scrollable-content panel panel-border-sm"
        >
          <div class="title" @click="minimizedAdd('organization')">
            <SvgIcon
              icon="construction_square_x_icon"
              width="18"
              height="18"
              class="icon_title"
            />
            <span>{{ titles.organization }}</span>
          </div>
          <div class="scrollable-content example-scrollable-full">
            <ConstructionOrganization :ref="ref => organizationRef = ref" />
          </div>
        </div>
      </div>
    </div>
    <Modal
      v-if="messageModalShow"
      class="modal-confirm"
      :title="messageModalConfig[messageModalType].title"
      :message="messageModalConfig[messageModalType].message"
      :button-text="messageModalConfig[messageModalType].buttons.primary"
      :secondary-button-text="messageModalConfig[messageModalType].buttons.secondary"
      :on-close="closeMessageModal"
      :on-click="primaryClickButton"
      :secondary-on-click="secondaryClickButton"
      attach="body"
      button-variation="filled"
      buttons-wrapper-class="buttons-wrapper"
      wrapper-class="content-wrapper"
      message-align="center"
      variation="warning"
      secondary-variation="light"
      title-class="modal-title"
      message-class="modal-message"
      confirm-button-class="confirm-button"
    />
    <PersonDetail :visible="showPersonDetail" />
    <Modal
      v-if="showIdealProfileModal"
      class="modal-confirm"
      :title="messageModalConfig.save_after.title"
      :message="messageModalConfig.save_after.message"
      :button-text="messageModalConfig.save_after.buttons.primary"
      :secondary-button-text="messageModalConfig.save_after.buttons.secondary"
      :on-close="closeIdealProfileModal"
      :on-click="confirmIdealProfileModal"
      :secondary-on-click="closeIdealProfileModal"
      attach="body"
      variation="warning"
      secondary-variation="light"
      title-class="modal-title"
      message-class="modal-message"
      wrapper-class="content-wrapper"
      confirm-button-class="confirm-button"
      buttons-wrapper-class="buttons-wrapper"
    />
    <Modal
      v-if="showFillOutIdealProfileModal"
      class="modal-confirm"
      :title="messageModalConfig.save_after_finish.fill_out.title.toLocaleUpperCase()"
      :message="messageModalConfig.save_after_finish.fill_out.message"
      :button-text="messageModalConfig.save_after_finish.fill_out.buttons.primary"
      :secondary-button-text="messageModalConfig.save_after_finish.fill_out.buttons.secondary"
      :on-close="closeIdealProfileModal"
      :on-click="closeIdealProfileModal"
      :secondary-on-click="confirmIdealProfileModal"
      attach="body"
      variation="warning"
      secondary-variation="light"
      title-class="modal-title"
      message-class="modal-message-fill-out"
      wrapper-class="content-wrapper"
      confirm-button-class="confirm-button"
      buttons-wrapper-class="buttons-wrapper"
      message-align="center"
    />
  </div>
</template>

<route lang="yaml">
meta:
  layout: activity
</route>

<style scoped lang="scss">
.panel-wrapper {
  display: flex;
  flex-direction: column;
  background: #f4f4f4;

  .header {
    background: #fff 0% 0% no-repeat padding-box;
  }
  .panels {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5rem;
  }

  .panels.minimized {
    grid-template-columns: 3.125rem repeat(calc(3 - v-bind("minimized.length")), 1fr);

    .panel-minimized {
      display: flex;
      width: 3.125rem;
      flex-direction: column;
      // grid-template-rows: 13rem 9rem auto;
      margin-block: 0.5rem;
      gap: 0.5rem;

      .box-guide {
        height: 13rem;
      }

      .box-notes {
        height: 9rem;
      }

      .box-organization {
        height: 15.5rem;
      }

      .panel-box {
        display: flex;
        flex-direction: column;
        width: 3rem;
        align-items: center;
        justify-content: center;
        background: $white-full;
        box-shadow: 0 3px 4px #00000029;
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        padding-block: 1rem;

        .panel-title {
          display: grid;
          grid-template-columns: auto 1fr;
          width: max-content;
          // height: 3.125rem;
          transform: rotate(-90deg);
          transform-origin: center;
          cursor: pointer;

          span {
            color: $primary;
            font-size: 0.875rem;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}

.example-scrollable-full {
  gap: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-inline: 0.5rem;

  &::-webkit-scrollbar {
    width: 23px;
    padding: 3px;
  }

  &::-webkit-scrollbar-thumb {
    width: 6px;
    border: 8px transparent solid;
    border-radius: 99px;
    background: #00000082 0% 0% no-repeat padding-box;
  }
}
.panel {
  margin-block: 0.5rem;
  border-radius: 8px;
  background: #fff 0% 0% no-repeat padding-box;
  box-shadow: 0 3px 4px #00000029;
  padding: 0.5rem;
}

.title {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-inline: 0.5rem;
  gap: 1rem;
  letter-spacing: 0.77px;
  cursor: pointer;

  span {
    color: $red-violet;
    font-weight: 600;
    font-size: 1.25rem;
    text-transform: uppercase;
    letter-spacing: inherit;
  }
}

.editor-title {
  padding: 0.5rem 0;
  margin-top: 1rem;
  font-size: 1.125rem;
  font-weight: 700;
  text-transform: uppercase;
  user-select: none;
}

.modal-confirm:deep {
  .modal-title {
    font-size: 1.25rem;
    font-weight: bold;
    text-align: center;
  }

  .modal-message {
    font-size: 1.25rem;
    font-weight: normal;
    line-height: 1.4;
    padding-inline: 1rem;
    text-align: center;
  }

  .content-wrapper {
    width: 46rem;
    min-width: 340px;
    padding: 3rem 3.25rem;
  }

  .buttons-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }

  .confirm-button {
    border-color: $secondary;
    background-color: $white-full;
    color: $secondary;

    &:hover {
      background-color: $secondary;
      color: $white-full;
    }
  }
}

.guide {
  &-container {
    padding-inline: 0.875rem;
  }

  &-title {
    font-size: 1rem;
    padding-top: 1rem;
    text-transform: uppercase;
    font-weight: 700;
  }

  &-question {
    margin-block: 2rem;
    font-size: 0.875rem;
  }
}
</style>
