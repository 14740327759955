<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { maxLength } from "@vuelidate/validators";
import { SectionTitle } from "@/components/section_title";
import { SectionContent } from "@/components/section_content";
import { TextArea } from "@/components/inputs/modalInputs";
import { Button2 } from "@/components/button";

export default defineComponent({
  name: "AboutSection",
  components: {
    SectionTitle,
    SectionContent,
    TextArea,
    Button: Button2,
  },
  props: {
    description: {
      type: String,
      required: true,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    noEdit: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["submit:description", "click:edit", "click:editCancel"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const payload = ref({ description: props.description });
    const rules = {
      description: {
        maxLength: maxLength(650),
      },
    };
    const validator = useVuelidate(rules, payload);
    const errors = computed(() => ({
      description: validator.value.description.$errors,
    }));

    const submit = () => emit("submit:description", payload.value);

    return {
      t,
      validator,
      errors,
      submit,
    };
  },
});
</script>

<template>
  <section class="about-section">
    <SectionTitle
      :title="t('corporations.details.about')"
      :is-editing="noEdit || isEditing"
      @click:edit="$emit('click:edit')"
    />

    <div class="section-content">
      <template v-if="!isEditing">
        <SectionContent class="format">
          {{ description }}
        </SectionContent>
      </template>

      <template v-else>
        <form @submit.prevent="submit()">
          <div class="form-group">
            <TextArea v-model="validator.description.$model" :warning-chars="650" />
          </div>

          <div class="form-actions">
            <Button type="submit" :disabled="validator.$invalid">
              {{ t("corporations.details.submit") }}
            </Button>

            <Button variation="secondary" no-border @click="$emit('click:editCancel')">
              {{ t("corporations.details.cancel") }}
            </Button>
          </div>
        </form>
      </template>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.format {
  white-space: pre-line;
}

.form-actions {
  display: grid;
  padding-right: 45%;
  margin-top: 2rem;
  grid-gap: 5rem;
  grid-template-columns: repeat(2, 1fr);
}
</style>
