<script lang="ts">
import type { Ref } from "vue";
import { computed, defineComponent, ref, toRef } from "vue";
import { useI18n } from "vue-i18n";
import type { Strategy } from "@/entities/corporation";
import { SectionTitle } from "@/components/section_title";
import { SectionContent } from "@/components/section_content";
import { FormInput, Select, TextArea } from "@/components/inputs/modalInputs";
import { Button2 } from "@/components/button";

export default defineComponent({
  name: "StrategySection",
  components: {
    SectionTitle,
    SectionContent,
    FormInput,
    TextArea,
    Select,
    Button: Button2,
  },
  props: {
    strategy: {
      type: Object,
      required: true,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    noEdit: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["click:edit", "click:editCancel", "submit:strategy"],
  setup(props, { emit }) {
    const { t, tm } = useI18n();
    const strategy = toRef(props, "strategy") as Ref<Strategy>;

    const strategyMap = computed(() => {
      const {
        main_product,
        secondary_products,
        geographical_presence,
        corporation_moment,
        business_plans,
        structuring_projects,
        acquisition_projects,
        asset_disposal_projects,
        market_current_scenario,
        strategy_status,
      } = strategy.value || {};

      return [
        { label: t("corporations.details.mainProduct"), value: main_product },
        { label: t("corporations.details.secondaryProducts"), value: secondary_products },
        { label: t("corporations.details.geographicalPresence"), value: geographical_presence },
        { label: t("corporations.details.corporationMoment"), value: corporation_moment },
        { label: t("corporations.details.businessPlans"), value: business_plans },
        { label: t("corporations.details.structuringProjects"), value: structuring_projects },
        { label: t("corporations.details.aquisitionProjects"), value: acquisition_projects },
        { label: t("corporations.details.assetDisposalProjects"), value: asset_disposal_projects },
        { label: t("corporations.details.marketCurrentScenario"), value: market_current_scenario },
        { label: t("corporations.details.strategyStatus.name"), value: strategy_status },
      ];
    });

    const payload = ref({ ...strategy.value }) as Ref<Strategy>;

    const formStructure = computed(() => [
      {
        label: t("corporations.details.mainProduct"),
        modelName: "main_product",
        props: { id: "main_product" },
        component: "FormInput",
      },
      {
        label: t("corporations.details.secondaryProducts"),
        modelName: "secondary_products",
        props: { id: "secondary_products" },
        component: "FormInput",
      },
      {
        label: t("corporations.details.geographicalPresence"),
        modelName: "geographical_presence",
        props: { id: "geographical_presence" },
        component: "FormInput",
      },
      {
        label: t("corporations.details.corporationMoment"),
        modelName: "corporation_moment",
        props: { id: "corporation_moment", warningChars: 650 },
        component: "TextArea",
      },
      {
        label: t("corporations.details.businessPlans"),
        modelName: "business_plans",
        props: { id: "business_plans", warningChars: 650 },
        component: "TextArea",
      },
      {
        label: t("corporations.details.structuringProjects"),
        modelName: "structuring_projects",
        props: { id: "structuring_projects", warningChars: 650 },
        component: "TextArea",
      },
      {
        label: t("corporations.details.aquisitionProjects"),
        modelName: "acquisition_projects",
        props: { id: "acquisition_projects", warningChars: 650 },
        component: "TextArea",
      },
      {
        label: t("corporations.details.assetDisposalProjects"),
        modelName: "asset_disposal_projects",
        props: { id: "asset_disposal_projects", warningChars: 650 },
        component: "TextArea",
      },
      {
        label: t("corporations.details.marketCurrentScenario"),
        modelName: "market_current_scenario",
        props: { id: "market_current_scenario", warningChars: 650 },
        component: "TextArea",
      },
      {
        label: t("corporations.details.strategyStatus.name"),
        modelName: "strategy_status",
        props: {
          id: "strategy_status",
          options: tm("corporations.details.strategyStatus.enum"),
        },
        component: "Select",
        class: "--fixed-width",
      },
    ]);

    const resetPayload = () => {
      payload.value = { ...strategy.value };
    };
    const submit = () => emit("submit:strategy", { strategy: payload.value });
    const cancelSubmit = () => {
      resetPayload();
      emit("click:editCancel");
    };

    return {
      t,
      strategyMap,
      payload,
      formStructure,
      submit,
      cancelSubmit,
    };
  },
});
</script>

<template>
  <section class="strategy-section">
    <SectionTitle
      :title="t('corporations.details.strategy')"
      :is-editing="noEdit || isEditing"
      @click:edit="$emit('click:edit')"
    />

    <div class="section-content">
      <template v-if="!isEditing">
        <SectionContent>
          <p v-for="item in strategyMap" :key="item" class="strategy-item" :class="[item.extraClass]">
            <b>{{ item.label }}:</b>
            {{ item.value }}
          </p>
        </SectionContent>
      </template>

      <template v-else>
        <form class="strategy-form" @submit.prevent="submit()">
          <div
            v-for="formField in formStructure"
            :key="formField"
            class="form-group"
            :class="[formField.class || '']"
          >
            <label :for="formField.props.id">{{ formField.label }}</label>
            <Component
              :is="formField.component"
              v-model="payload[formField.modelName]"
              v-bind="formField.props"
            />
          </div>

          <div class="form-actions">
            <Button type="submit">
              {{ t("text.form.submit") }}
            </Button>

            <Button variation="light" filled @click="cancelSubmit()">
              {{ t("text.form.cancel") }}
            </Button>
          </div>
        </form>
      </template>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.strategy-section {
  .strategy-item {
    margin-bottom: 0.5rem;
    line-height: 1.35rem;
  }

  .strategy-form {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr;
  }
}

.form-group {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 5fr;

  &.--fixed-width {
    grid-template-columns: 184px 200px;
  }
}

.form-actions {
  display: grid;
  padding-right: 45%;
  margin-top: 2rem;
  grid-gap: 5rem;
  grid-template-columns: repeat(2, 1fr);
}
</style>
