function setElementStyle(element: HTMLElement, property: keyof CSSStyleDeclaration, value: string) {
  element.style[property as never] = value;
}

function setLinePosition(line: HTMLElement, fromElement: HTMLElement, toElement: HTMLElement, position: "onStart" | "onEnd"): void {
  const x1: number = fromElement.offsetLeft + (fromElement.offsetWidth / 2);
  const y1: number = fromElement.offsetTop + (fromElement.offsetHeight / 2);
  let x2: number = 0;
  let y2: number = 0;

  if (position === "onStart") {
    x2 = toElement.offsetLeft;
    y2 = toElement.offsetTop + (toElement.offsetHeight / 2);
  }
  else if (position === "onEnd") {
    x2 = toElement.offsetLeft + toElement.offsetWidth;
    y2 = toElement.offsetTop + (toElement.offsetHeight / 2);
  }

  line.setAttribute("x1", x1.toString());
  line.setAttribute("y1", y1.toString());
  line.setAttribute("x2", x2.toString());
  line.setAttribute("y2", y2.toString());
}

function addCompanyProfileLines({
  containerSvg,
  strategyUnderline,
  strategyLineSvg,
  governanceLineSvg,
  governanceUnderline,
  executionConditionsUnderline,
  executionConditionsLineSvg,
  companySizeUnderline,
  companySizeLineSvg,
  titleHeight = 40,
}: {
  strategyUnderline?: Ref<HTMLElement | null>
  containerSvg?: Ref<HTMLElement | null>
  strategyLineSvg?: Ref<HTMLElement | null>
  governanceLineSvg?: Ref<HTMLElement | null>
  governanceUnderline?: Ref<HTMLElement | null>
  executionConditionsUnderline?: Ref<HTMLElement | null>
  companySizeUnderline?: Ref<HTMLElement | null>
  executionConditionsLineSvg?: Ref<HTMLElement | null>
  companySizeLineSvg?: Ref<HTMLElement | null>
  titleHeight?: number
}) {
  if (!strategyUnderline?.value || !containerSvg?.value || !strategyLineSvg?.value || !governanceLineSvg?.value || !governanceUnderline?.value || !executionConditionsUnderline?.value || !companySizeUnderline?.value || !executionConditionsLineSvg?.value || !companySizeLineSvg?.value)
    return;

  const containerProfile = document.getElementById("profile-container");
  const governanceSection = document.getElementById("governance");
  const strategySection = document.getElementById("strategy");
  const executionConditionsSection = document.getElementById("conditions");

  if (!containerProfile || !governanceSection || !strategySection || !executionConditionsSection)
    return;

  setElementStyle(governanceUnderline.value, "top", `${titleHeight}px`);

  const governanceSectionRect: DOMRect = governanceSection.getBoundingClientRect();
  setElementStyle(strategyUnderline.value, "top", `${governanceSectionRect.height + titleHeight}px`);

  const strategySectionRect: DOMRect = strategySection.getBoundingClientRect();
  const containerProfileRect: DOMRect = containerProfile.getBoundingClientRect();
  setElementStyle(executionConditionsUnderline.value, "left", `${containerProfileRect.width - strategySectionRect.width}px`);
  setElementStyle(executionConditionsUnderline.value, "top", `${titleHeight}px`);

  const executionConditionsSectionRect: DOMRect = executionConditionsSection.getBoundingClientRect();
  setElementStyle(companySizeUnderline.value, "top", `${strategySectionRect.height + titleHeight}px`);
  setElementStyle(companySizeUnderline.value, "left", `${containerProfileRect.width - executionConditionsSectionRect.width}px`);

  setLinePosition(strategyLineSvg.value, containerSvg.value, strategyUnderline.value, "onEnd");
  setLinePosition(governanceLineSvg.value, containerSvg.value, governanceUnderline.value, "onEnd");
  setLinePosition(executionConditionsLineSvg.value, containerSvg.value, executionConditionsUnderline.value, "onStart");
  setLinePosition(companySizeLineSvg.value, containerSvg.value, companySizeUnderline.value, "onStart");
}

export { addCompanyProfileLines, setElementStyle, setLinePosition };
