import { activitiesService } from "@/http_services/client";
import type { ScreeningAssesmentSchemaDto, ScreeningsAssessmentBase, ScreeningsAssessmentPatch } from "@/types/screening-evaluation";
import { ScreeningAssesmentSchemaDtoSchema } from "@/types/screening-evaluation";

export async function getScreeeningEvaluation(project_id: string, person_id: string): Promise<ScreeningAssesmentSchemaDto> {
  return activitiesService.get(`screenings/organization-and-assessment/project/${project_id}/person/${person_id}`)
    .then(res => res.data?.data)
    .then((data) => {
      const result = ScreeningAssesmentSchemaDtoSchema.safeParse(data);
      if (!result.success)
        console.warn("[screening-evaluation]", project_id, person_id, result.error);
      return data;
    });
}

export async function getScreeeningEvaluationByActivity(activity_id: string): Promise<ScreeningAssesmentSchemaDto> {
  return activitiesService.get(`screenings/organization-and-assessment/activity/${activity_id}`)
    .then(res => res.data?.data)
    .then((data) => {
      const result = ScreeningAssesmentSchemaDtoSchema.safeParse(data);
      if (!result.success)
        console.warn("[screening-evaluation]", activity_id, result.error);
      return data;
    });
}

export async function addScreeeningEvaluation(organization_and_assessment: ScreeningsAssessmentBase) {
  return activitiesService.post("screenings/organization-and-assessment", { organization_and_assessment })
    .then(res => res.data?.data);
}

export async function patchScreeeningEvaluation(id: string, organization_and_assessment: ScreeningsAssessmentPatch) {
  return activitiesService.patch(`screenings/organization-and-assessment/${id}`, { organization_and_assessment })
    .then(res => res.data?.data);
}
