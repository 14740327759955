import { setElementStyle, setLinePosition } from "@/projects/pages/projects/[id]/construction/ideal-profile/utils/companyProfileSvgLines";

function addIdealProfileLines(
  {
    academicBackgroundUnderline,
    experiencesUnderline,
    motivationUnderline,
    containerCenterIcon,
    academicBackgroundSvgLine,
    experiencesSvgLine,
    motivationSvgLine,
    achievementsUnderline,
    achievementsSvgLine,
    attributesUnderline,
    attributesSvgLine,
    culturalMatchUnderline,
    culturalMatchSvgLine,
    firstContainer,
    thirdContainer,
    titleHeight = 40,
    marginLeft = 0,
  }: {
    academicBackgroundUnderline: Ref<HTMLElement | null>
    experiencesUnderline: Ref<HTMLElement | null>
    motivationUnderline: Ref<HTMLElement | null>
    containerCenterIcon: Ref<HTMLElement | null>
    academicBackgroundSvgLine: Ref<HTMLElement | null>
    experiencesSvgLine: Ref<HTMLElement | null>
    motivationSvgLine: Ref<HTMLElement | null>
    achievementsUnderline: Ref<HTMLElement | null>
    achievementsSvgLine: Ref<HTMLElement | null>
    attributesUnderline: Ref<HTMLElement | null>
    attributesSvgLine: Ref<HTMLElement | null>
    culturalMatchUnderline: Ref<HTMLElement | null>
    culturalMatchSvgLine: Ref<HTMLElement | null>
    firstContainer: Ref<HTMLElement | null>
    thirdContainer: Ref<HTMLElement | null>
    titleHeight?: number
    marginLeft?: number
  },
) {
  if (!academicBackgroundUnderline.value || !experiencesUnderline.value || !motivationUnderline.value || !containerCenterIcon.value || !academicBackgroundSvgLine.value || !experiencesSvgLine.value || !motivationSvgLine.value || !achievementsUnderline.value || !achievementsSvgLine.value || !attributesUnderline.value || !attributesSvgLine.value || !culturalMatchUnderline.value || !culturalMatchSvgLine.value || !firstContainer.value || !thirdContainer.value)
    return;

  const centerIcon: HTMLElement | null = containerCenterIcon.value;

  const firstContainerSection: HTMLElement | null = firstContainer.value;

  const titleAcademicBackground: HTMLElement | null = document.getElementById("academic");
  const titleMotivation: HTMLElement | null = document.getElementById("motivation");

  const profileContainer: HTMLElement | null = document.getElementById("profile-container");

  const titleAchievements: HTMLElement | null = document.getElementById("achievements");
  const titleAttributes: HTMLElement | null = document.getElementById("attributes");
  const titleCulturalMatch: HTMLElement | null = document.getElementById("cultural-match");

  if (!titleAcademicBackground || !firstContainerSection || !titleMotivation || !profileContainer || !titleAchievements || !titleCulturalMatch || !titleAttributes)
    return;
  const profileContainerRect: DOMRect = profileContainer.getBoundingClientRect();

  const firstContainerRect: DOMRect = firstContainerSection.getBoundingClientRect();
  const titleAcademicBackgroundRect: DOMRect = titleAcademicBackground.getBoundingClientRect();
  const titleMotivationRect: DOMRect = titleMotivation.getBoundingClientRect();

  const titleAttributesRect: DOMRect = titleAttributes.getBoundingClientRect();
  const titleAchievementsRect: DOMRect = titleAchievements.getBoundingClientRect();

  setElementStyle(academicBackgroundUnderline.value, "top", `${titleHeight}px`);
  setElementStyle(experiencesUnderline.value, "top", `${titleAcademicBackgroundRect.height + titleHeight}px`);
  setElementStyle(motivationUnderline.value, "top", `${firstContainerRect.height - titleMotivationRect.height + titleHeight}px`);
  setElementStyle(achievementsUnderline.value, "top", `${titleHeight}px`);
  setElementStyle(achievementsUnderline.value, "left", `${profileContainerRect.width - titleAchievementsRect.width}px`);
  setElementStyle(attributesUnderline.value, "left", `${profileContainerRect.width - titleAchievementsRect.width}px`);
  setElementStyle(attributesUnderline.value, "top", `${titleAchievementsRect.height + titleHeight}px`);
  setElementStyle(culturalMatchUnderline.value, "left", `${profileContainerRect.width - titleAchievementsRect.width}px`);
  setElementStyle(culturalMatchUnderline.value, "top", `${titleAchievementsRect.height + titleAttributesRect.height + titleHeight}px`);

  setLinePosition(academicBackgroundSvgLine.value, centerIcon, academicBackgroundUnderline.value, "onEnd");
  setLinePosition(experiencesSvgLine.value, centerIcon, experiencesUnderline.value, "onEnd");
  setLinePosition(motivationSvgLine.value, centerIcon, motivationUnderline.value, "onEnd");
  setLinePosition(achievementsSvgLine.value, centerIcon, achievementsUnderline.value, "onStart");
  setLinePosition(attributesSvgLine.value, centerIcon, attributesUnderline.value, "onStart");
  setLinePosition(culturalMatchSvgLine.value, centerIcon, culturalMatchUnderline.value, "onStart");
}
export { addIdealProfileLines };
