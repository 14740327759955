<script setup lang="ts">
const props = defineProps({
  number: { type: String },
  code: { type: String },
  isWhatsapp: { type: Boolean },
});

const href = computed(() => props.isWhatsapp
  ? `https://api.whatsapp.com/send?phone=${props.code}${props.number}`
  : undefined);
const text = computed(() => `${props.code} ${props.number}`);
</script>

<template>
  <div class="item">
    <span style="text-wrap: nowrap;" v-text="text" />
    <a v-if="isWhatsapp" :href="href" target="_blank" class="whatspp" />
  </div>
</template>

<style lang="scss" scoped>
.item {
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  user-select: none;
  padding: 1px 6px;
}

.whatspp {
  height: 28px;
  width: 28px;
  background-image: url("@/assets/images/whatsapp.png");
  background-size: contain;

  &:hover {
    background-image: url("@/assets/images/whatsapp_hover.png");
  }
}
</style>
