<script setup lang="ts">
import ShortlistBody from "./components/ShortlistBody.vue";
import type { SearchCandidate } from "@/common/composables/useShortlist";

const route = useRoute();
const { t } = useI18n();

const projectId = computed(() => String(route.params.id));
const personID = computed(() => String(route.params.person));

const { data: project, error } = await useProject(String(route.params.id));

const person = ref<SearchCandidate | null>(null);

const {
  data: construction,
} = useConstructionOrganizationQuery(String(projectId.value));

const {
  data: interview,
} = useInterviewEvaluationQuery(String(projectId.value), String(personID.value));

const {
  mutateAsync: patchInterview,
} = usePatchInterviewEvaluationQuery();

const academicConstruction = ref();
const languagesConstruction = ref();

const academicInterview = ref();
const languagesInterview = ref();

const interviewId = computed(() => {
  return interview.value?._id;
});

const interviewMap = {
  academic_backgrounds: academicInterview,
  languages: languagesInterview,
} as const;

function update(key: "academic_backgrounds" | "languages") {
  patchInterview({
    _id: interviewId.value,
    [key]: interviewMap[key].value,
  });
}

function formatBreakLines(text?: string | null) {
  if (!text)
    return text;
  return text.replaceAll("\n", "<br>");
}

function formatScore(score: number | null | undefined) {
  try {
    if (!score)
      return 0;
    return (
      (!Number.isInteger(score) ? score?.toFixed(2).replace(".", ",") : score?.toFixed(0)) ?? 0
    );
  }
  catch (error) {
    return 0;
  }
}

async function fetchCandidate() {
  if (!route.params.person)
    return;
  const response = await searchCandidates({
    project_id: String(route.params.id),
    person_id: String(route.params.person),
    project_stage: "SHORTLIST",
    skip: 0,
    limit: 1,
  });
  person.value = (response && response[0]) ? response[0] : null;
}

watchEffect(async () => {
  await fetchCandidate();
  if (interview.value) {
    academicInterview.value = toRaw(interview.value?.academic_backgrounds);
    languagesInterview.value = toRaw(interview.value?.languages);
  }
  if (construction.value) {
    academicConstruction.value = construction.value?.ideal_profile?.academic_backgrounds;
    languagesConstruction.value = construction.value?.ideal_profile?.languages;
  }
});

const isEditing = ref();
</script>

<template>
  <ShortlistBody
    :project="project"
    :candidate="person"
    :error="error"
    :score="person?.calculated_scores?.academic_formation_mean"
    :is-editing="isEditing"
    icon="icon_shortlist_school"
    :title="t('projects.shortlist.titles.academicEducation')"
    @edit="isEditing = !isEditing"
    @change:person="fetchCandidate"
  >
    <div v-if="academicConstruction && academicInterview">
      <div class="box-titles">
        <span>{{ t("exportPDF.idealProfile") }}</span>
        <span>
          {{ `${person?.person_data?.first_name}` }}
        </span>
      </div>
      <div v-for="(_, academic_key, index) in academicConstruction" :key="`academic-${academic_key}`" class="box-rows">
        <div class="box-column">
          <div class="marker-circle" />
          <div class="text" v-html="formatBreakLines(academicConstruction[academic_key])" />
        </div>
        <div class="box-column">
          <div v-if="!isEditing" class="marker-circle yellow" />
          <div v-if="!isEditing" class="text" v-html="formatBreakLines(academicInterview[academic_key]?.observations)" />
          <TextArea
            v-else
            v-model="academicInterview[academic_key].observations"
            rows="5"
            class="item-input"
            :name="`academic-${index}`"
            :warning-chars="650"
            :placeholder="t('projects.details.activities.constructionPanel.forms.placeholders.field')"
            @change="update('academic_backgrounds')"
          />
        </div>
        <div v-if="!isEditing" class="box-score">
          <div class="item-score">
            {{ formatScore(academicInterview[academic_key]?.score) }}%
          </div>
        </div>
      </div>
      <div class="separate" />
      <div v-for="(_, language_key) in languagesConstruction" :key="`language-${language_key}`" class="box-rows">
        <div class="box-column">
          <div class="marker-circle" />
          <div class="text" v-html="formatBreakLines(languagesConstruction[language_key])" />
        </div>
        <div class="box-column">
          <div v-if="!isEditing" class="marker-circle yellow" />
          <div v-if="!isEditing" class="text" v-html="formatBreakLines(languagesInterview[language_key]?.observations)" />
          <TextArea
            v-else
            v-model="languagesInterview[language_key].observations"
            rows="5"
            class="item-input"
            :name="`academic-${language_key}`"
            :warning-chars="650"
            :placeholder="t('projects.details.activities.constructionPanel.forms.placeholders.field')"
            @change="update('languages')"
          />
        </div>
        <div v-if="!isEditing" class="box-score">
          <div class="item-score">
            {{ formatScore(languagesInterview[language_key]?.score) }}%
          </div>
        </div>
      </div>
    </div>
    <Button
      v-if="isEditing"
      class="conclude-button"
      type="submit"
      variation="secondary"
      @click="isEditing = !isEditing"
    >
      {{ t("text.form.conclude") }}
    </Button>
  </ShortlistBody>
</template>

<route lang="yaml">
meta:
  layout: project-stage
  stage: shortlist
  tab: academic
</route>

<style lang="scss" scoped>
.separate {
  margin-block: 2rem;
  border-bottom: 1px solid $gray-x17;
}

.box-titles {
  display: grid;
  grid-template-columns: 1fr 1fr 6rem;
  margin-bottom: 1.5rem;
  padding-inline-start: 1rem;
  span {
    color: $primary;
    font-size: 1.25rem;
    font-weight: 700;
    text-transform: uppercase;
  }
}

.box-rows {
  display: grid;
  grid-template-columns: 1fr 1fr 6rem;
  margin-block: 0.75rem;
  padding-inline-start: 1rem;
  gap: 3.25rem;

  .box-column {
    display: flex;
    flex-direction: row;
  }

  .box-score {
    display: flex;
    height: -webkit-fill-available;
    flex-direction: column;
    justify-content: flex-end;

    .item-score {
      height: fit-content;
      min-width: 4rem;
      max-width: 5rem;
      padding: 0.4375rem 1rem;
      border: $gray-medium solid 1px;
      margin-left: auto;
      border-radius: 6px;
      color: $gray-medium;
      font-size: 1.25rem;
      font-weight: 700;
      text-align: center;
    }
  }

  .text {
    font-size: .875rem;
    line-height: 1.25rem;
    overflow-wrap: anywhere;
  }

  .marker-circle {
    width: 0.5rem;
    min-width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    margin-top: 0.35rem;
    margin-right: 0.625rem;
    background: $gray-border;
  }

  .marker-circle.yellow {
    background: $yellow;
  }
}
.item-input {
  width: 30rem;
  margin-block-end: 1.875rem;
}
</style>
