<script lang="ts">
import type { PropType } from "vue";
import { computed, defineComponent } from "vue";
import has from "lodash-es/has";
import get from "lodash-es/get";
import { SvgIcon } from "../icon";

type ButtonIcon =
  | string
  | {
    type: "mdi" | "native"
    path: string
    size?: number
  };

export default defineComponent({
  name: "ButtonSpan",
  components: {
    SvgIcon,
  },
  props: {
    icon: {
      type: [String, Object] as PropType<ButtonIcon>,
      default: "",
    },
    label: {
      type: String,
      required: true,
    },
    variation: {
      type: String as PropType<ButtonIcon>,
      default: "red",
    },
    size: {
      type: [String, Number],
      default: 20,
    },
    margin: {
      type: String,
      default: "right",
    },
  },
  emits: ["click"],
  setup(props) {
    const type = computed<string>(() => {
      return has(props.icon, "type") ? get(props, "icon.type") : "native";
    });

    const path = computed<string>(() => {
      return has(props.icon, "path") ? get(props, "icon.path") : props.icon;
    });

    return {
      type,
      path,
    };
  },
});
</script>

<template>
  <button type="button" class="button-span" :class="[`--${variation}`]" @click="$emit('click')">
    <SvgIcon
      v-if="icon"
      :type="type"
      :icon="path"
      :width="size"
      :height="size"
      :margin="margin"
    />

    {{ label }}
  </button>
</template>

<style lang="scss" scoped>
$variations: (
  "primary": $primary,
  "secondary": $gray-x11,
  "red": $red,
  "red-dark": $red-dark,
  "light": $white-lotion,
);

.button-span {
  display: inline-flex;
  align-items: center;
  padding: 0;
  border: 0;
  background-color: transparent;
  font-size: 0.875rem;

  @each $key, $value in $variations {
    &.--#{$key} {
      color: $value;

      &:focus,
      &:hover {
        color: darken($value, 10%);
      }
    }
  }

  &.--none {
    color: inherit;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
</style>
