<script setup lang="ts">
import type { Ref } from "vue";
import { useI18n } from "vue-i18n";
import cloneDeep from "lodash-es/cloneDeep";
import { Button as ButtonDefault } from "@/components/button";
import CompanyIcon from "@/assets/images/general-company.svg";
import { Avatar } from "@/components/avatar";
import type { PersonPayload, WorkExperiencePayload } from "@/entities/Person/form";
import { PAYMENT_FREQUENCY } from "@/entities/Person/work-experience";
import { CURRENCY } from "@/entities/common";
import { WorkExperienceItem } from "@/persons/pages/persons/components/forms";

type SubmitFunction = ({
  work_experience,
}: {
  work_experience: WorkExperiencePayload[]
}) => Promise<void>;

interface PropsType {
  onSubmit: () => void
  onCancel: () => void
}

const props = defineProps<PropsType>();

const { t } = useI18n();
const personPayload = inject("personPayload") as Ref<PersonPayload>;
const onSubmit = toRef(props, "onSubmit") as Ref<SubmitFunction>;

function initialFormStructure(): WorkExperiencePayload {
  return {
    role_name: "",
    company: {
      name: "",
      corporation: "",
      corporation_data: null,
    },
    salary: {
      amount: 0,
      currency: CURRENCY.BRL,
      payment_frequency: PAYMENT_FREQUENCY.MONTHLY,
    },
    description: "",
    incentive_compensation: "",
    job_location: "",
    is_current: false,
    start_date: null,
    end_date: null,
    linkedin_url: "",
    conditions: "",
    realizations: [],
  };
}

function sortExperiencies() {
  const workExperience = cloneDeep(personPayload.value.work_experience) ?? [];

  const current = workExperience
    .filter(item => (item.is_current && item.start_date))
    .sort((a, b) => (b.start_date ?? "").localeCompare(a.start_date ?? ""));

  const previous = workExperience
    .filter(item => (!item.is_current && item.start_date))
    .sort((a, b) => (a.start_date ?? "").localeCompare(b.start_date ?? ""))
    .reverse();

  const dateEmpty = workExperience
    .filter(item => (!item.start_date))
    .sort((a, b) => (a.company.name ?? "").localeCompare(b.company.name ?? ""));

  return current.concat(previous, dateEmpty);
}

const payload = ref<WorkExperiencePayload[]>(sortExperiencies());

function addWorkExperience() {
  payload.value.push(initialFormStructure());
}

function removeWorkExperience(index: number) {
  payload.value.splice(index, 1);
}

async function submitExperiences() {
  await onSubmit.value({
    work_experience: payload.value.map(({ realizations, ...rest }: WorkExperiencePayload) => ({
      ...rest,
      realizations: (realizations || [])
        .filter(({ title, description }) => title || description),
    })),
  });
}

const avatarVersion = ref(0);
function reloadAvatar(experience: any, company: any) {
  experience.company = {
    ...experience.company,
    corporation_data: {
      ...experience.company.corporation_data,
      avatar_url: company?.avatar_url,
    },
  };

  avatarVersion.value += 1;
}
</script>

<template>
  <div class="work-experience">
    <div class="work-experience__content">
      <template v-for="(experience, index) in payload" :key="experience">
        <div class="content-container mb-2">
          <div class="company-logo-wrapper">
            <Avatar
              v-if="experience.company?.corporation_data?.avatar_url"
              :key="avatarVersion"
              :src="experience.company?.corporation_data?.avatar_url"
              :alt="experience.company?.name"
              size="71px"
              image-size="large"
            />
            <CompanyIcon v-else />
          </div>

          <div>
            <WorkExperienceItem
              edit
              :experience="experience"
              @add:company="reloadAvatar(experience, $event)"
            />
            <ButtonDefault
              type="button"
              :text="t('persons.forms.workExperience.removeWorkExperience')"
              variation="secondary"
              @click="removeWorkExperience(index)"
            />
            <hr v-show="index < payload.length - 1">
          </div>
        </div>
      </template>

      <div class="add-work-experience">
        <ButtonDefault
          type="button"
          :text="t('persons.forms.workExperience.addWorkExperience')"
          variation="warning"
          @click="addWorkExperience()"
        />
      </div>
    </div>

    <div class="work-experience__actions">
      <div class="mb-1">
        <ButtonDefault
          type="button"
          class="save-button"
          :text="t('persons.details.submit')"
          variation="warning"
          @click="submitExperiences()"
        />
      </div>
      <div>
        <ButtonDefault
          type="button"
          :text="t('persons.details.cancel')"
          variation="secondary"
          @click="onCancel()"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.mb-1 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

hr {
  border-top: none;
  border-bottom: 1px solid $gray-light;
  margin-top: 1rem;
}

.content-container {
  display: grid;
  grid-template-columns: auto 1fr;
}

.work-experience {
  display: flex;

  &__actions {
    margin-left: 2rem;
  }

  &__content {
    flex: 1;
  }
}

.add-work-experience {
  --company-icon-size: 4.44rem;
  --container-gap: 1rem;

  margin-left: calc(var(--company-icon-size) + var(--container-gap));
}

.content-container {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: auto 1fr;
}

.company-logo-wrapper {
  display: flex;
  width: 71px;
  height: 71px;
  align-items: center;
  justify-content: center;
  border-radius: 150px;
  background: #cecfd0;
}

.save-button {
  padding-right: 1rem;
  padding-left: 1rem;
  white-space: nowrap;
}
</style>
