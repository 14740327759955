<script lang="ts">
import {
  defineComponent,
  ref,
  toRef,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import { Select } from "@/components/inputs/modalInputs";

export default defineComponent({
  name: "SelectFilter",
  components: {
    Select,
  },
  props: {
    filterLocaleRoot: {
      type: String,
      required: true,
    },
    placeholderLocale: {
      type: String,
      required: true,
    },
    clearLocale: {
      type: String,
      required: true,
    },
    clear: {
      type: Boolean,
      required: true,
    },
    options: [Array, Object],
    searchable: {
      type: Boolean,
      required: false,
    },
  },
  emits: ["filter:select"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const selected = ref(null);
    const clear = toRef(props, "clear");

    const clearAll = () => {
      selected.value = null;
      emit("filter:select", selected.value);
    };

    watch(clear, () => {
      clearAll();
    });

    watch(selected, (value) => {
      emit("filter:select", value);
    });

    return {
      t,
      selected,
      clearAll,
    };
  },
});
</script>

<template>
  <div class="filter-wrapper">
    <Select
      v-model="selected"
      class="select-filter"
      :options="options"
      :placeholder="t(`${filterLocaleRoot}.${placeholderLocale}`)"
      no-feedback
      :searchable="searchable"
    />
    <span
      class="clear-filter"
      role="button"
      @click="clearAll()"
    >
      > {{ t(`${filterLocaleRoot}.${clearLocale}`) }}
    </span>
  </div>
</template>

<style lang="scss" scoped>
.filter-wrapper {
  display: flex;
  flex-direction: column;
}

.select-filter {
  margin-top: 1rem;
}

.clear-filter {
  margin-left: 0.875rem;
  font-size: 0.875rem;
}
</style>
