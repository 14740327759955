<script setup lang="ts">
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import format from "date-fns/format";
import isBefore from "date-fns/isBefore";
import type { Ref } from "vue";
import { useI18n } from "vue-i18n";
import { mdiHelpCircleOutline as help } from "@mdi/js";
import { SvgIcon } from "@/components/icon";

const props = defineProps<{
  schedules: any
  stages: any
  stage?: string
  isExport?: boolean
}>();

const { t, tm } = useI18n();

const { schedules, stages, stage } = toRefs(props);

const hasSchedule = computed(() => {
  if (!props.schedules || !Object.values(props.schedules).filter(Boolean).length)
    return false;
  const scheduleKeys = Object.values(props.schedules || {});
  return scheduleKeys.filter(data => Object.values(data).filter(Boolean)).length > 0;
});

interface stagesListModel {
  stage: string
  status: string
  stage_icon: string
  start_date: string | null
  end_date: string | null
  checkpoint_date: string | null
  checkpoint_done_date: string | null
  start_col: string | null
  width_col: string | null
}

interface weekListModel {
  name: string
  size: string
}

enum PROJECT_STATUS {
  ACTIVE = "ACTIVE",
  CONCLUDED = "CONCLUDED",
  CANCELLED = "CANCELLED",
  SUSPENDED = "SUSPENDED",
  DRAFT = "DRAFT",
}

enum PROJECT_STAGE {
  CONSTRUCTION = "construction",
  MAPPING = "mapping",
  SCREENINGS = "screenings",
  INTERVIEWS = "interviews",
  SHORTLIST = "shortlist",
  UNDER_OFFER = "under_offer",
  PLACEMENT = "placement",
}

const iconMap = {
  [PROJECT_STAGE.CONSTRUCTION]: "construction_icon",
  [PROJECT_STAGE.MAPPING]: "search_icon",
  [PROJECT_STAGE.SCREENINGS]: "audio_icon",
  [PROJECT_STAGE.INTERVIEWS]: "forum_icon",
  [PROJECT_STAGE.SHORTLIST]: "fact_check_icon",
  [PROJECT_STAGE.UNDER_OFFER]: "handshake_icon",
  [PROJECT_STAGE.PLACEMENT]: "star_icon",
};

const weekSize = 7;
const stagesTooltipMap = computed(() => tm("projects.generic.stages.tooltip"));
const initDate = ref("");
const endDate = ref("");
const totalDays = ref(0);
const totalWeeks = ref(0);
const stagesList = ref([]) as Ref<stagesListModel[]>;
const weekList = ref([]) as Ref<weekListModel[]>;
const scheduleSize = ref({
  width: 0,
  height: 0,
});

function getDifDays(init: string, end: string) {
  if (!init && init === "" && !end && end === "")
    return 0;

  const startDate = new Date(init);
  const dif = differenceInCalendarDays(new Date(end), startDate);
  return dif || 0;
}

function formatStages() {
  initDate.value = schedules.value.construction?.start_date || "";
  formatSchedules();
  totalDays.value = getDifDays(initDate.value, endDate.value);
  totalWeeks.value = Number.parseFloat(Number.parseFloat(String(totalDays.value / weekSize)).toFixed(2));
  if (totalWeeks.value <= 0)
    totalWeeks.value = 1;

  formaWeeks();
  formatStartAndEndCols();
}

function checkBeforeDate(date?: string) {
  // console.log(date);
  if (date === null)
    return endDate.value;

  const result = isBefore(new Date(date!), new Date(endDate.value));
  if (result)
    return endDate.value;
  else
    return date;
}

function getDateFormated(date?: string) {
  // console.log(date);
  if (date === null) {
    return "";
  }
  else {
    // TODO: use format from language
    return format(new Date(date!), "dd/MM/yyyy");
  }
}

function formatSchedules() {
  Object.entries(schedules.value).forEach(([key, data]) => {
    if (data) {
      if (data?.start_date !== null) {
        endDate.value = checkBeforeDate(
          data?.checkpoint_date || data?.end_date || data?.start_date || null,
        );
      }
      stagesList.value.push({
        stage: key,
        status:
          stages.value[key as never] && stages.value[key as never] !== null
            ? stages.value[key as never].status
            : PROJECT_STATUS.DRAFT,
        stage_icon: iconMap[key as never] || null,
        start_date: data?.start_date || null,
        end_date: data?.end_date || null,
        checkpoint_date: data?.checkpoint_date || null,
        checkpoint_done_date: data?.checkpoint_done_date || null,
        start_col: null,
        width_col: null,
      });
    }
  });
}

function formaWeeks() {
  const split = String(totalWeeks.value).split(".");
  let total = Number.parseInt(split[0]);
  let valueBreak = false;
  if (Number.parseFloat(split[1]) > 0) {
    total += 1;
    valueBreak = true;
  }
  for (let i = 0; i < total; i++) {
    weekList.value.push({
      name: `S${i + 1}`,
      size:
        valueBreak && i === total - 1
          ? getSize(weekSize * Number.parseFloat(`0.${split[1]}`)).toString()
          : getSize(weekSize).toString(),
    });
  }
}

function formatStartAndEndCols() {
  stagesList.value.map((item) => {
    if (item.start_date !== null) {
      item.start_col
        = getSize(getDifDays(initDate.value, item.start_date || "")).toString() || null;
      item.width_col
        = getSize(
          getDifDays(item.start_date, item.checkpoint_date || item.end_date || ""),
        ).toString() || null;
    }
    return item;
  });
}

function getSize(days: number) {
  if (!days || days === 0)
    return 0;

  return Number.parseFloat(`${(days * 100) / totalDays.value}`).toFixed(2);
}

function formatSizeSchedule() {
  const element = document.getElementById("schedules");
  setTimeout(() => {
    scheduleSize.value = {
      width: element?.offsetWidth || 0,
      height: element?.offsetHeight || 0,
    };
  }, 10);
}

function checkWidth(value: number) {
  if (value > 100)
    return 100;
  else if (value < 0)
    return 0;
  else return value;
}

function checkMarginLeft(value: number, width: number) {
  if (value < 0)
    return 0;
  else if (value < 79.5)
    return value;
  else
    return 100 - (width < 21.5 ? 21.5 : width);
}

onMounted(() => {
  formatStages();
  formatSizeSchedule();
  window.onresize = () => {
    formatSizeSchedule();
  };
});
</script>

<template>
  <div v-if="hasSchedule" class="activitie-schedule">
    <div class="weeks" :style="{ width: `${scheduleSize.width}px`, height: `${scheduleSize.height}px` }">
      <div
        v-for="(item, index) in weekList"
        :key="index"
        class="week"
        :class="[parseInt(item.size) < 3 ? 'vertical' : '']"
        :style="{ width: `${item.size}%`, height: `${scheduleSize.height}px` }"
      >
        {{ item.name }}
      </div>
    </div>
    <div id="schedules" class="schedules">
      <div v-for="(item, index) in stagesList" :key="index" class="stages">
        <div class="stage" :class="[stage === item.stage ? 'active' : '']">
          <SvgIcon width="2rem" height="2rem" :icon="item.stage_icon" margin="none" />
          <span>
            {{ t(`projects.generic.stages.enum.${item.stage}`) }}
          </span>
        </div>
        <div class="schedule">
          <div
            v-if="item.start_date !== null"
            class="box"
            :style="{
              'margin-left': `${checkMarginLeft(item.start_col, item.width_col)}%`,
              'width': `${checkWidth(item.width_col)}%`,
            }"
          >
            <div
              v-if="parseInt(item.width_col) < 60"
              class="box-date"
              :class="[item.checkpoint_date ? '' : 'rounded']"
              :style="{
                width: `${item.checkpoint_date ? 'auto' : `100%`}`,
              }"
            >
              <div class="title fluid-type">
                {{ stagesTooltipMap[item.stage as never] }}
              </div>
              <div class="date fluid-type">
                {{ getDateFormated(item.start_date) }}
              </div>
              <div class="date fluid-type">
                {{ getDateFormated(item.end_date) }}
              </div>
              <div v-if="!isExport" class="flex-end timeline-tooltip-icon">
                <Tooltip :arrow-show="true">
                  <template #trigger>
                    <SvgIcon
                      type="mdi"
                      :icon="help"
                      margin="none"
                      width="1rem"
                      height="1rem"
                    />
                  </template>
                  {{ stagesTooltipMap[item.stage as never] }}
                </Tooltip>
              </div>
            </div>
            <div
              v-else
              class="box-date full"
              :class="[item.checkpoint_date ? '' : 'rounded']"
              :style="{
                width: `${item.checkpoint_date ? 'auto' : `100%`}`,
              }"
            >
              <div class="box-title">
                {{ stagesTooltipMap[item.stage as never] }}
              </div>
              <div class="date fluid-type">
                {{ getDateFormated(item.start_date) }} - {{ getDateFormated(item.end_date) }}
              </div>
            </div>
            <div v-if="item.checkpoint_date" class="box-checkpoint">
              <div class="checkpoint-title">
                {{ t("projects.details.activities.schedule.checkpoint") }}
              </div>
              <div class="checkpoint-value">
                {{ getDateFormated(item.checkpoint_date) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.activitie-schedule {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 1rem 0;

  .schedules {
    position: relative;
    z-index: 2;
    display: flex;
    width: 100%;
    flex-direction: column;
    padding-bottom: 0.5rem;
    margin-top: 1.5rem;

    .stages {
      display: flex;
      width: 100%;
      height: 5rem;
      border-radius: 0.5rem;
      margin-bottom: 1rem;
      background: $white-background-light;

      .stage {
        display: flex;
        width: 8rem;
        height: inherit;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: $gray-medium-dark;

        span {
          margin-top: 0.5rem;
          color: $gray-medium-dark;
          font-size: 0.875rem;
          font-weight: bold;
        }
      }

      .stage.active {
        color: $yellow;

        span {
          color: $yellow;
        }
      }
    }

    .schedule {
      width: 100%;
      padding-left: 1.1rem;

      .box {
        display: flex;
        min-width: 11rem;
        height: 100%;
        flex-direction: row;
        border-radius: 0.5rem;
        cursor: default;
        font-size: 0.875rem;

        .title {
          display: none;
        }

        .box-title {
          color: $white-full;
          font-weight: normal;
        }

        svg {
          color: $white-full;
        }

        .flex-end:hover svg {
          color: $white-full;
        }

        .box-date.full {
          justify-content: space-around;
        }

        .box-date.rounded {
          min-width: 11rem;
          border-radius: 0.5rem;
        }

        .box-date {
          display: flex;
          width: inherit;
          min-width: 5.9375rem;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          padding: 0.25rem 0.5rem;
          background: #9b9b9b;
          border-bottom-left-radius: 0.5rem;
          border-top-left-radius: 0.5rem;

          .date {
            padding-top: 0.5rem;
            color: $white-full;
            font-weight: 700;
          }

          .flex-end {
            position: relative;
            align-self: flex-end;
            cursor: pointer;

            &:hover .tooltip {
              display: block;
            }
          }
        }

        .box-checkpoint {
          display: flex;
          width: fit-content;
          min-width: 5.9375rem;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
          background: $yellow;
          border-bottom-right-radius: 0.5rem;
          border-top-right-radius: 0.5rem;
          padding-inline: 0.25rem;

          .checkpoint-value {
            font-weight: bold;
          }
        }
      }
    }
  }

  .weeks {
    position: absolute;
    z-index: 0;
    display: flex;
    flex-direction: row;
    padding-left: 8rem;

    .week.vertical {
      align-items: center;
      justify-content: flex-start;
      font-size: 0.7rem;
      writing-mode: vertical-rl;
    }

    .week {
      display: flex;
      justify-content: center;
      border-left: 2px solid $gray-x13;
      font-size: 0.85rem;
      font-weight: bold;
    }
  }
}

.tooltip {
  position: absolute;
  z-index: 999;
  bottom: 1rem;
  left: 0;
  display: none;
  width: 16rem;
  padding: 0.5rem;
  border-radius: 8px;
  margin-left: -8.5rem;
  background-color: $white-full;
  box-shadow: 0 2px 4px #00000029;
  font-size: 1rem;
  letter-spacing: 0.56px;
  line-height: 1.125rem;
  margin-block: 0.25rem;
}
</style>
