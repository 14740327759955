<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { format } from "date-fns";
import ptLocale from "date-fns/locale/pt";
import enLocale from "date-fns/locale/en-US";
import FlowIcon from "@/assets/images/flow_ef_yellow.svg";

defineProps<{
  title: string | null | undefined
  companyName: string | null | undefined
}>();

const { t, locale } = useI18n({ useScope: "global" });

const getLocale = (): Locale => locale.value === "en-US" ? enLocale : ptLocale;

const currentMonth = format(new Date(), "MMMM", { locale: getLocale() });
const currentYear = new Date().getFullYear();
</script>

<template>
  <ExportExportPage
    :is-export="false"
    :has-avatar="false"
    :full-page="true"
  >
    <template #title />
    <div class="front-cover">
      <div class="header">
        <div class="date">
          {{ currentMonth }} - {{ currentYear }}
        </div>
        <FlowIcon width="6.75rem" height="4.0625rem" />
      </div>
      <div class="container">
        <span class="company-name uppercase-text">
          {{ companyName }}
        </span>
        <div class="content">
          <div class="role-container">
            <div class="stage">
              {{ t("exportPDF.construction") }}
            </div>
            <div class="role uppercase-text">
              {{ title }}
            </div>
          </div>
          <div class="confidential">
            {{ t("exportPDF.confidential") }}
          </div>
        </div>
      </div>
    </div>
  </ExportExportPage>
</template>

<style scoped lang="scss">
.front-cover {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  padding-top: 1.875rem;
  padding-inline: 2.8125rem;

  .container {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: flex-end;
  }

  .content {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-bottom: 4.75rem;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .date {
      font-size: 1.125rem;
      letter-spacing: 0.0394rem;
      line-height: 1.5rem;
      text-transform: uppercase;
    }
  }

  .icon-container {
    display: flex;
    height: 160mm;
    align-items: center;
    justify-content: center;
  }

  .flow-icon {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .confidential {
    flex-shrink: 0;
    color: #c0c0c0;
    font-size: 0.75rem;
    letter-spacing: 0.49px;
    text-transform: uppercase;
  }

  .role-container {
    .role {
      font-size: 1.875rem;
      font-weight: 700;
      letter-spacing: 1.05;
      line-height: 2.5625rem;
      margin-block-start: 1.25rem;
    }

    .stage {
      max-width: 22rem;
      color: $yellow;
      font-size: 2.5rem;
      letter-spacing: 0.0656rem;
      line-height: 3.4375rem;
      margin-block-start: 0.67rem;
      text-transform: uppercase;
    }
  }

  .company-name {
    position: relative;
    display: flex;
    color: #666;
    font-size: 1.875rem;
    letter-spacing: 0.0656rem;
    line-height: 2.5625rem;

    span {
      margin-left: 0.5rem;
    }
  }
}

.uppercase-text {
  text-transform: uppercase;
}
</style>
