import type { Corporation, Salary } from "../common";

import type { Optional } from "@/utils/typing";

export enum PAYMENT_FREQUENCY {
  MONTHLY = "MONTHLY",
  ANNUALLY = "ANNUALLY",
}

export enum CURRENCY {
  BRL = "BRL",
  USD = "USD",
  EUR = "EUR",
}

export interface Realization {
  title: Optional<string>
  description: string
}

export interface WorkExperience {
  company?: Corporation
  role_name: string
  salary: Salary
  incentive_compensation: Optional<string>
  job_location: Optional<string>
  is_current: Optional<boolean>
  start_date: Optional<string>
  end_date: Optional<string>
  description: Optional<string>
  conditions: Optional<string>
  realizations: Optional<Realization[]>
}
