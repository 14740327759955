import { EducationalOrganizationTypesEnum } from "@/schemas/enums/corporation";
import { PROJECT_STATUS } from "@/entities/project";

const macroMarket = {
  ADM_PUBLIC: "Public Administration",
  AGRIBUSINESS: "Agribusiness",
  CONSTRUCTION: "Construction",
  CONSUMER_GOODS: "Consumer Goods",
  EDUCATION: "Education",
  FINANCIAL_SERVICES: "Financial",
  HEALTH_SERVICES: "Health",
  INDUSTRY: "Industry",
  INSURANCE: "Insurance",
  LOGISTICS_TRANSPORT: "Logistics",
  MINING_STEEL: "Mining",
  OIL_GAS: "Oil & Gas",
  PHARMACEUTICAL: "Pharmaceutical",
  PRIVATE_EQUITY: "Private Equity",
  RETAIL: "Retail",
  SERVICES: "Services",
  TELEMARKETING: "Telemarketing",
  UTILITIES: "Utilities",
  NOT_AVAILABLE: "",
};

const microMarket = {
  ADM_PUBLIC: "Public Administration",
  ADM_STOCKS_MARKETS: "Stock exchanges and markets administration",
  AGRICULTURE: "Agriculture",
  ANIMAL_HEALTH: "Animal health",
  ASSOCIATION_FEDERATION: "Association and Federation",
  AUTOMOTIVE_ASSEMBLY: "Automobile manufacturer",
  AUTOMOTIVE_DEALERSHIP: "Automobile dealership",
  AUTOMOTIVE_PARTS: "Automobile parts",
  AVIATION_AEROPLANES: "Aviation aircraf",
  AVIATION_AEROPORTS: "Aviation airports",
  AVIATION_SERVICES: "Aviation service",
  BANKING: "Bank",
  BEAUTY: "Beauty and Aesthetics",
  BIOTECHNOLOGY: "Biotechnology",
  BROKERAGE: "Broker",
  BROKERAGE_FUNDS: "Brokers and Funds",
  BROKERAGE_REAL_ESTATE: "Real estate brokerage",
  CHARGING: "Charging",
  CHEMICALS_SPECIALTIES: "Chemical specialties",
  CHEMICAL_FIBERS: "Chemical fibers",
  CLINIC: "Clinic",
  CLOTHING: "Clothing and Footwear",
  CONCESSION_MANAGEMENT: "Concession and Management",
  COSMETICS: "Cosmetic",
  CREDIT: "Credit",
  DIAGNOSTICS: "Diagnosis",
  DISTRIBUTOR: "Distributor",
  DISTRIBUTOR_AGRICULTURE: "Agricultural input distributor",
  DISTRIBUTOR_AUTO_PARTS: "Automobile Parts Distributor",
  DISTRIBUTOR_CAPITAL_GOODS: "Capital goods distributor",
  DISTRIBUTOR_FUEL: "Fuel distributor",
  DISTRIBUTOR_QUIMICAL_PRODUCTS: "Chemicals distributor",
  DISTRIBUTOR_TECHNOLOGY: "Technology and electronics distributor",
  DURABLE_GOODS: "Durable goods",
  ECOMMERCE: "ECommerce/Marketplace",
  EDUCATION: "Education",
  ELECTRONICS: "Electronic",
  ENERGY: "Energy",
  ENTERTAINMENT: "Entertainment, leisure, sport and tourism",
  EQUIPMENT: "Equipment",
  EXCHANGE: "Exchange",
  EXTRACTION: "Extraction",
  FAMILY_OFFICE: "Family Office",
  FERTILIZERS_DEFENSIVES: "Fertilizers and Defensives",
  FISHING_AQUACULTURE: "Fishing and Aquaculture",
  FOOD: "Food",
  FOOD_DRINKS: "Food and drinks",
  FOOD_INGREDIENTS: "Food Ingredients",
  FURNITURE: "FURNITURE",
  FURNITURE_UTENSILS: "Furniture and utensils",
  GAS: "Gas",
  HIGHWAYS: "Road",
  HOSPITAL: "Hospital",
  HOSPITAL_SUPPLY: "Hospital supply",
  HOTELS_BUSINESS: "Hotel business",
  IMPORT_EXPORT: "Import and Export",
  INCORPORATION: "Incorporation",
  INDUSTRY: "Industrial",
  INFRASTRUCTURE: "Infrastructure",
  INSTALLATION_MAINTENANCE: "Installation and Maintenance",
  INSURANCE: "Insurers",
  INVESTMENTS: "Investment",
  INVESTMENT_FUNDS_REAL_STATE: "Investment Funds and Real Estate Management",
  LEASE: "Lease",
  LIVESTOCK: "Livestock",
  LOGISTICS: "Logistics",
  LUXURY_ITEMS: "Luxury items",
  MACHINERY: "Machinery",
  MACHINERY_EQUIPMENTS: "Machines and equipment",
  MARITIME: "Maritime",
  MATERIALS_CONSTRUCTION: "Building materials",
  MATERIALS_ELECTRONICS: "Electric materials",
  MEDIA: "Media (vehicles)",
  MEDICINE: "Medicines",
  MINING_STEEL: "Mining & Steel",
  MOBILITY: "Mobility",
  NON_METALLIC: "Non-metallic",
  OPTICS: "Optics",
  OTHERS: "Others",
  OUTSOURCING: "Labor outsorcing",
  PACKAGING: "Packaging",
  PAPER: "Paper And Cellulose",
  PARTS: "Parts and Equipment",
  PAYMENTS: "Payment methods/benefits",
  PETROCHEMICALS: "Petrochemical",
  PHARMACEUTICALS: "Pharmaceutical",
  PHARMACOCHEMICAL: "Pharmacochemical",
  PLASTICS: "Resin, plastics and elastomers",
  PRINTING: "Printing and Reproduction",
  PRIVATE_EQUITY: "Service",
  PRODUCTION: "Production",
  PRODUCTS_HYGIENE: "Hygiene and Cleaning Products",
  PRODUCTS_MEDICAL: "Medical products",
  RAILROADS: "Rail",
  RECREATIONAL: "Recreational and sports",
  REFUSE: "Refuse",
  REINSURER: "Reinsurer",
  RESEARCH: "Research",
  RESEARCH_CLINIC: "Clinical research",
  RESIDENTIAL_CORPORATE: "Residential and Corporate",
  SANITATION: "Water and sanitation",
  SERVICES: "Service",
  SERVICES_MANAGEMENT: "Services and Management",
  SERVICES_PROFESSIONAL: "Professional services",
  SERVICES_PROVIDERS: "Service providers",
  SERVICES_SYSTEMS: "Systems and Services",
  SHOPPING_CENTERS: "Shopping centers",
  SOCIAL_ASSISTANCE: "Social assistance",
  STORAGE: "Storage",
  SUGAR_ETHANOL: "Sugar and ethanol",
  TELECOMMUNICATIONS: "Telephony and Internet",
  TELEVISION: "Television",
  THIRD_SECTOR: "Third sector",
  TOBACCO: "Smoke",
  TOOLS: "Tools",
  TRADING: "Trading",
  UNSKILLED: "Unspecialized",
  UTENSILS: "Utensils",
  VENTURE_CAPITAL: "Venture Capital",
  VET: "Vet",
  WELFARE: "Foresight",
  WELFARE_FOUNDATION: "Pension foundation",
};

const businessHeadquarters = {
  BR: "Brazilian",
  EMPTY: "Multinacional",
  OTHER_WITH_COUNTRY: "Multinacional ({country})",
};

const businessTypes = {
  PUBLICLY_TRADED: "Publicly Traded",
  FAMILY_CONTROL: "Family Control",
  BRAZILIAN_MULTINATIONAL: "Brazilian Multinational",
  STARTUP: "Startup",
  STARTUP_WITH_PARTNER_FUND_PE_OR_VC: "Startup + Partner/Fund (PE or VC)",
  STRATEGIC_PARTNER_JV: "Strategic Partner JV",
};

const businessLevels = {
  CEO_OWNER: "CEO Owner",
  FAMILY_BUSINESS: "Family Business",
  PROFESSIONAL_MANAGEMENT: "Professional Management",
};

const businessEquityTypes = {
  investor: {
    INVESTOR_FUND_PE: "Investor / Fund / PE",
    HOLDING_CONTROLLER: "Holding / Controller",
  },
  subsidiary: {
    SUBSIDIARY: "Subsidiária",
    BUSINESS_UNITY: "Unidade de negócio",
    FUND_INVESTED: "Investida de fundo",
  },
};

const numberOfEmployees = {
  "1_TO_50": "From 1 to 50 employees",
  "51_TO_100": "From 51 to 100 employees",
  "101_TO_500": "From 101 to 500 employees",
  "501_TO_1000": "From 501 to 1,000 employees",
  "1001_TO_5000": "From 1,001 to 5,000 employees",
  "5001_TO_10000": "From 5,001 to 10,000 employees",
  "MORE_THAN_10000": "More than 10,000 employees",
};
const strategyStatus = ["ACTIVE", "INACTIVE"];

const transformationEventTypes = {
  IPO: "IPO",
  FUSION_AND_ACQUISITION: "Merger & Acquisition ( M&A )",
  CHANGE_OF_CONTROL_OR_SELL: "Exchange of control/Sale",
  PROFESSIONALIZATION_FAMILY_OUT: "Professionalization ( Family left the operation )",
  PROFESSIONALIZATION_BOARDS_AND_COMMITTEES: "Professionalization ( Board and Committee Constitution )",
  ACCELERATED_GROWTH_OR_INVESTMENT_ROUNDS: "Accelerated Growth / Investment Rounds",
  DOWNSIZING: "Downsizing",
  JUDICIAL_RECOVERY: "Judicial Recovery",
  INTERNATIONALIZATION: "Internationalization",
  ORGANIC_GROWTH: "Organic Growth",
  UNIT_CLOSURE: "Unit Closure",
  COMPANY_CLOSING: "Company Closing",
  SYSTEM_IMPLEMENTATION: "System Implementation",
  DIGITAL_TRANSFORMATION: "Digital Transformation",
  CHANNEL_TRANSFORMATION: "Channel Transformation",
  PRODUCT_TRANSFORMATION: "Product Transformation",
  PROCESS_TRANSFORMATION: "Process Transformation",
  BRAND_TRANSFORMATION: "Brand Transformation",
  AREA_MERGE: "Area Merge",
  CHAIR_MERGE: "Chair Merge",
};

export const corporations = {
  checkConflict: {
    createCorporation: "Create new company",
    createNewCorporation: "Continue and create new company",
    corporationsFound: "No company found | 1 company found | {n} companies found",
    linkAndEdit: "Details and Edit",
    newAnalysis: "New analysis",
    where: "in",
    continue: "Continue",
    linkCompany: "Link company",
    saveNewProfile: "Save new company profile",
    addNewProfile: "Add profile company",
    addNewCompany: "Add new company",
    companyNotFound: "company name not found in base",
  },
  filters: "FILTERS",
  isEducationalOrganization: "Educational institution",
  createCompany: "Create corporation",
  searchCorporation: "search companies",
  searchCorporationError: "type at least 2 characters to search",
  errorListCorporations: "An error occurred while loading the corporations. Try again later.",
  notFoundSearch: "Could not find any corporation with this search.",
  relevants: "Relevants",
  recents: "Recents",
  tagFilter: "Insert the {tagFilter}",
  tagFilterClear: "Clear filter",
  tagFilters: {
    keywords: ["keywords", "keywords"],
    market: ["Market", "market"],
    sector: ["Business line", "business line"],
    consultant: ["Consultant", "consultant"],
    governance: ["Governance", "governance"],
    strategy: ["Strategy", "strategy"],
    transformationEvents: ["Transformation Events", "transformation event"],
    openVacancies: ["Open Vancancies", "open vacancy"],
    revenue: ["Revenue", "revenue"],
  },
  sortBy: "Sort by",
  sector: {
    name: "Macro Market",
    enum: macroMarket,
  },
  employees: {
    number: "N° of employees",
    enum: numberOfEmployees,
  },
  enums: {
    educationalOrganizationTypes: {
      [EducationalOrganizationTypesEnum.PRIVATE]: "Private",
      [EducationalOrganizationTypesEnum.PUBLIC]: "Public",
    },
  },
  yearlyIncome: "Yearly income",
  jobVacancies: "Job vacancies",
  placements: "Placements",
  phone: "Phone",
  contact: "Contact",
  location: "Site",
  name: "Nome",
  domain: "Domain",
  socialNetworks: "Social Networks",
  corporationCard: {
    refreshed: "Last time it was updated",
    edit: "Edit",
    employeesAlt: "Corporation employees",
    details: "See details",
  },
  details: {
    submit: "Save changes",
    cancel: "Cancel",
    validations: {
      yearWith4Numbers: "Year must have 4 numbers",
    },
    placeholders: {
      history: "Company history",
      details: "Details",
      noTransformationEvent: "No transformation events have been added yet",
    },
    backToList: "Back to list",
    tabs: {
      about: "About",
      transformationEvents: "Transformation events",
      chart: "Organization Chart",
      position: "Projects",
      historic: "History",
      relationship: "Relationships",
      insights: "Insights",
    },
    about: "About",
    historic: "History",
    governance: "Governance",
    foundationYear: "Foundation year",
    headquarters: {
      name: "Headquarters",
      enum: businessHeadquarters,
    },
    type: {
      name: "Type",
      enum: businessTypes,
    },
    level: {
      name: "Level",
      enum: businessLevels,
    },
    economicGroup: "Economic Group",
    investor: "Investor",
    companyRole: "Company Role",
    description: "Description",
    subsidiary: "Subsidiary",
    ceo: "CEO",
    details: "Details",
    employeesNumber: "Number of employees",
    annualRevenue: "Annual Revenue",
    currentRevenue: "Current Revenue",
    netWorth: "Net Worth",
    macroMarket: {
      name: "Macro Market",
      enum: macroMarket,
    },
    microMarket: {
      name: "Micro Market",
      enum: microMarket,
    },
    sector: "business line",
    strategy: "Strategy",
    mainProduct: "Main product",
    secondaryProducts: "Secondary products",
    geographicalPresence: "Geographical presence",
    corporationMoment: "Corporation moment",
    businessPlans: "Business plans",
    structuringProjects: "Structuring projects",
    aquisitionProjects: "Aquisition projects",
    assetDisposalProjects: "Asset disposal projects",
    marketCurrentScenario: "Market current scenario",
    strategyStatus: {
      name: "Strategy status",
      enum: strategyStatus,
    },
    mainInvestor: "Main investor",
    linkInvestorAndParentCompanies: "Link to Investor and Parent Companies",
    linkSubsidiaryAndInvestedCompanies: "Subsidiary and Invested Companies",
    businessEquityType: {
      name: "Company role",
      enum: businessEquityTypes,
    },
    forms: {
      placeholders: {
        netWorth: "100.000.000,00",
        currency: "R$",
        year: "ano",
        addYear: "add year",
        employeesSelect: "ex.: 800",
        removeInvestor: "Remove investor",
        eventActionDescription: "Description of the event's actions",
        eventGoalDescription: "Description of the event's goals",
        ongoingEvent: "Ongoing event",
      },
    },
    name: "Name",
    locality: "Location",
    domain: "Domain",
    phone: "Phone",
    facebook: "Facebook",
    linkedin: "Linkedin",
    addRelationship: "add relationship",
    forecast: "forecast",
    transformationEventType: {
      name: "Event type",
      enum: transformationEventTypes,
    },
    action: "Action",
    goal: "Goal",
    addTransformationEvent: "Add event",
  },
  mockData: {
    filters: [
      {
        name: "Key words",
        options: [],
      },
      {
        name: "Sectors",
        options: ["R&S", "Alimentício", "Transportes"],
      },
      {
        name: "Owners",
        options: ["Ana Paula Ramos", "Bernardo Cavour", "Luiz Gustavo Mariano"],
      },
      {
        name: "N° of employees",
        options: [],
      },
    ],
  },
  clearFilters: "clear filters",
  clearFilter: "Clear filter",
  create: {
    title: "Create new corporation",
    basicData: {
      title: "Basic Data",
      name: "Name",
      about: "About",
      domain: "Domain",
      locality: "Location",
      contact: "Contact",
      linkedin: "Linkedin",
      facebook: "Facebook",
      microMarket: "Mikro Market",
      macroMarket: "Makro Market",
    },
    avatar: {
      title: "Avatar",
      helpText: "The image must have a minimum size of 200px X 200px and be of JPG or PNG type",
    },
    governance: {
      title: "Governance",
      hasEconomicGroup: "Is part of an economic group?",
      yes: "yes",
      no: "no",
    },
    save: "Save company",
    clear: "Clean profile",
    clean: "Clean",
    continue: "Continue",
    back: "Back",
    successMessage: "Successfully registered company",
    clearMessage:
      "When clearing, all filled data will be lost, do you want to continue with the action?",
  },
  project: {
    table: {
      field: {
        type: "Type",
        project: "Project",
        period: "Period",
        participants: "Participants",
        status: "Status",
      },
    },
    cell: {
      status: {
        [PROJECT_STATUS.ACTIVE]: "In progress",
        [PROJECT_STATUS.SUSPENDED]: "Suspended",
        [PROJECT_STATUS.CANCELLED]: "Cancelled",
        [PROJECT_STATUS.DRAFT]: "Draft",
        data_unavailable: "Data unavailable",
      },
    },
  },
};
