<script setup lang="ts">
import SimpleMaskMoney from "simple-mask-money";
import cloneDeep from "lodash-es/cloneDeep";
import map from "lodash-es/map";
import set from "lodash-es/set";
import { SvgIcon } from "@/components/icon";
import { CONTRACT_TYPE, CURRENCY } from "@/entities/common";
import { searchCorporation } from "@/http_services/entities/corporation";
import { searchPersonDropdown } from "@/http_services/entities/persons";
import type { Corporation } from "@/schemas/corporation";
import type { Person } from "@/schemas/person";
import PersonSearch from "@/common/components/PersonSearch.vue";
import { moneyCurrencies } from "@/utils/adapters/form";
import { Modal } from "@/components/modal";

const { t, tm } = useI18n();

const router = useRouter();

const submitStatus = ref(false);
const submitError = ref(false);
const clearConfirmation = ref(false);
const projectId = ref("");

const projectTypes = Object(tm("projects.create.basicData.projectType.enum"));
const classTypes = Object(tm("projects.create.positionData.class.enum"));
const levelOptions = Object(tm("projects.details.edit.level.enum"));
const salaryTypes = Object(tm("projects.create.positionData.salary.enum"));

const initialFormData = {
  project_name: null,
  position_name: null,
  project_type: null,
  position_details: {
    address: {
      name: null,
      street: null,
      postal_code: null,
      region: null,
      neighborhood: null,
      locality: null,
    },
    position_class: null,
    level: null,
    salary_range: {
      currency: CURRENCY.BRL,
      min_value: "0",
      max_value: "0",
      contract_type: CONTRACT_TYPE.CLT,
    },
    bonus: null,
    annual_package: {
      currency: CURRENCY.BRL,
      min_value: "0",
      max_value: "0",
    },
    about: null,
  },
  placement_type: null,
  confidential_placement: false,
  corporation: {
    corporation_id: null,
    name: null,
  },
  published_by: {
    person_id: null,
    name: null,
  },
  participants: [],
};

const payload = ref(cloneDeep(initialFormData));

const updateCorporationInput = (corporation: Corporation) => corporation.name;

function onSelectCorporation(corporation: Corporation) {
  const bf = map((item: string) => ({ tag: item }), corporation.business_function);
  set(payload.value, "stages.construction.organization_and_assembly.business_functions", bf);
  payload.value.corporation = { corporation_id: corporation.id, name: corporation.name };
}

function onSelectPerson(person: Person) {
  payload.value.published_by = {
    person_id: person.id,
    name: `${person.first_name} ${person.last_name}`,
  };
}

function goToProject() {
  router.push(`/projects/${projectId.value}/construction/activities?stage=construction`);
}

function clearAndClose() {
  payload.value = cloneDeep(initialFormData);
  router.push("/projects");
}

async function createProjectAndClose() {
  try {
    const result = await createProject({
      project: {
        ...payload.value,
        position_details: {
          ...payload.value.position_details,
          salary_range: {
            ...payload.value.position_details.salary_range,
            min_value: SimpleMaskMoney.formatToNumber(payload.value.position_details.salary_range.min_value),
            max_value: SimpleMaskMoney.formatToNumber(payload.value.position_details.salary_range.max_value),
          },
          annual_package: {
            ...payload.value.position_details.annual_package,
            min_value: SimpleMaskMoney.formatToNumber(payload.value.position_details.annual_package.min_value),
            max_value: SimpleMaskMoney.formatToNumber(payload.value.position_details.annual_package.max_value),
          },
        },
        // participants: [
        //   ...payload.value.participants?.map(id => ({ person_id: id })),
        // ],
      },
    });
    if (result.response_type === "success") {
      submitStatus.value = true;
      projectId.value = result.data;
    }
    else {
      submitError.value = true;
    }
  }
  catch (error) {
    console.log("error", error);
    submitError.value = true;
  }
}
</script>

<template>
  <div class="create-project">
    <div class="create-project-title">
      <SvgIcon icon="briefcase_icon" width="2rem" height="2rem" margin="none" />
      {{ t('header.projects') }}
    </div>
    <div class="create-project-content">
      <div class="form-subtitle">
        {{ t('projects.create.basicData.title') }}
      </div>
      <div class="form">
        <div class="form-section">
          <div class="form-section-item">
            <label>
              {{ t("projects.create.basicData.projectName") }} <strong>*</strong>
            </label>
            <input v-model="payload.project_name" :placeholder="t('projects.create.placeholders.projectName')">
          </div>
          <div class="form-section-item">
            <label>
              {{ t("projects.create.basicData.positionName") }} <strong>*</strong>
            </label>
            <input v-model="payload.position_name" :placeholder="t('projects.create.placeholders.positionName')">
          </div>
          <div class="form-section-item">
            <label>
              {{ t("projects.create.basicData.projectType.label") }} <strong>*</strong>
            </label>
            <select v-model="payload.project_type" :placeholder="t('text.select')">
              <option
                v-for="(opt_value, opt_key, index) in projectTypes"
                :key="index"
                :value="opt_key"
              >
                {{ opt_value }}
              </option>
            </select>
          </div>
          <div class="form-section-item">
            <label>
              {{ t("projects.create.basicData.corporation") }} <strong>*</strong>
            </label>
            <SearchDropdown
              id="economic-group"
              class="form-input"
              :fetch-method="searchCorporation"
              :select-item-callback="onSelectCorporation"
              :update-input-model="updateCorporationInput"
              :initial-value="payload.corporation.name"
              :input-placeholder="t('projects.create.placeholders.corporation')"
              :search-not-found-label="t('text.searchCorporationNotFound')"
              :search-error-label="t('text.searchCorporationError')"
            >
              <template #list-item="{ item: corporation }">
                {{ corporation?.name }}
              </template>
            </SearchDropdown>
          </div>
        </div>
        <div class="form-section">
          <div class="form-section-item">
            <label>
              {{ t("projects.create.basicData.requester") }} <strong>*</strong>
            </label>
            <SearchDropdown
              id="requester-input"
              class="form-input"
              :fetch-method="searchPersonDropdown"
              :select-item-callback="onSelectPerson"
              :update-input-model="updateCorporationInput"
              :initial-value="payload.published_by.name"
              :input-placeholder="t('projects.create.placeholders.corporation')"
              :search-not-found-label="t('text.searchCorporationNotFound')"
              :search-error-label="t('text.searchCorporationError')"
            >
              <template #list-item="{ item: person }">
                {{ person.first_name }} {{ person.last_name }}
              </template>
            </SearchDropdown>
          </div>
          <div class="form-section-item">
            <label>
              {{ t("projects.create.basicData.allocatedTeam.label") }} <strong>*</strong>
            </label>
            <PersonSearch v-model:selected="payload.participants" class="pl-1" />
          </div>
          <div class="form-section-item">
            <label>
              {{ t("projects.create.basicData.confidentialVacancy") }}
            </label>
            <div class="form-section-radios">
              <div>
                <input
                  id="economic_group-no"
                  v-model="payload.confidential_placement"
                  type="radio"
                  :value="false"
                >
                {{ t('projects.create.basicData.no') }}
              </div>
              <div>
                <input
                  id="economic_group-yes"
                  v-model="payload.confidential_placement"
                  type="radio"
                  :value="true"
                >
                {{ t('projects.create.basicData.yes') }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-subtitle">
        {{ t('projects.create.positionData.title') }}
      </div>
      <div class="form">
        <div class="form-section">
          <div class="form-section-item">
            <label>
              {{ t("projects.create.positionData.locality") }}
            </label>
            <input
              v-model="payload.position_details.address.name"
              :placeholder="t('projects.create.placeholders.locality')"
            >
          </div>
          <div class="form-section-item">
            <label>
              {{ t("projects.create.basicData.projectType.label") }}
            </label>
            <select v-model="payload.position_details.position_class" :placeholder="t('text.select')">
              <option
                v-for="(opt_value, opt_key, index) in classTypes"
                :key="index"
                :value="opt_key"
              >
                {{ opt_value }}
              </option>
            </select>
          </div>
          <div class="form-section-item">
            <label>
              {{ t("projects.create.positionData.level.label") }}
            </label>
            <select v-model="payload.position_details.level" :placeholder="t('text.select')">
              <option
                v-for="(opt_value, _, index) in levelOptions"
                :key="index"
                :value="opt_value"
              >
                {{ opt_value }}
              </option>
            </select>
          </div>
          <div class="form-section-item">
            <label>
              {{ t("projects.create.positionData.salary.label") }}
            </label>
            <div class="form-section-salary">
              <select
                v-model="payload.position_details.salary_range.contract_type"
                :placeholder="t('text.select')"
              >
                <option
                  v-for="(opt_value, opt_key, index) in salaryTypes"
                  :key="index"
                  :value="opt_key"
                >
                  {{ opt_value }}
                </option>
              </select>
              <span>
                {{ t("projects.create.positionData.salary.from") }}
              </span>
              <select
                v-model="payload.position_details.salary_range.currency"
                :placeholder="t('corporations.details.forms.placeholders.currency')"
              >
                <option
                  v-for="(opt_value, opt_key, index) in moneyCurrencies"
                  :key="index"
                  :value="opt_key"
                >
                  {{ opt_value }}
                </option>
              </select>
              <FormInput
                v-model="payload.position_details.salary_range.min_value"
                money
                :placeholder="t('projects.create.placeholders.netWorth')"
              />
              <span>
                {{ t("projects.create.positionData.salary.to") }}
              </span>
              <FormInput
                v-model="payload.position_details.salary_range.max_value"
                money
                :placeholder="t('projects.create.placeholders.netWorth')"
              />
            </div>
          </div>
          <div class="form-section-item">
            <label>
              {{ t("projects.create.positionData.bonus") }}
            </label>
            <FormInput
              v-model="payload.position_details.bonus"
            />
          </div>
          <div class="form-section-item">
            <label>
              {{ t("projects.create.positionData.annualPackage") }}
            </label>
            <div class="form-section-salary-annual">
              <span>
                {{ t("projects.create.positionData.salary.from") }}
              </span>
              <select
                v-model="payload.position_details.annual_package.currency"
                :placeholder="t('text.select')"
              >
                <option
                  v-for="(opt_value, opt_key, index) in moneyCurrencies"
                  :key="index"
                  :value="opt_key"
                >
                  {{ opt_value }}
                </option>
              </select>
              <FormInput
                v-model="payload.position_details.annual_package.min_value"
                money
                :placeholder="t('projects.create.placeholders.netWorth')"
              />
              <span>
                {{ t("projects.create.positionData.salary.to") }}
              </span>
              <FormInput
                v-model="payload.position_details.annual_package.max_value"
                money
                :placeholder="t('projects.create.placeholders.netWorth')"
              />
            </div>
          </div>
          <div class="form-section-item">
            <label>
              {{ t("projects.create.positionData.about") }}
            </label>
            <TextArea
              v-model="payload.position_details.about"
              :placeholder="t('projects.create.placeholders.about')"
            />
          </div>
        </div>
        <div class="form-section">
            &nbsp;
        </div>
      </div>
      <div class="form-actions">
        <Button type="submit" @click="createProjectAndClose()">
          {{ t("projects.create.save") }}
        </Button>
        <Button variation="light" filled @click="clearConfirmation = true">
          {{ t("projects.create.clear") }}
        </Button>
      </div>
    </div>
  </div>

  <Modal
    v-if="submitError"
    :title="t('text.form.error.modal.genericTitle')"
    :message="t('text.form.error.modal.genericMessage')"
    :button-text="t('text.form.error.modal.genericButtonMessage')"
    :on-click="createProjectAndClose"
    :on-close="() => (submitError = false)"
    variation="warning"
    secondary-variation="light"
  />

  <Modal
    v-if="clearConfirmation"
    :message="t('projects.create.clearMessage')"
    :button-text="t('projects.create.clean')"
    :on-click="clearAndClose"
    :on-close="() => (clearConfirmation = false)"
    variation="warning"
    secondary-variation="light"
    :secondary-button-text="t('projects.create.back')"
    :secondary-on-click="() => (clearConfirmation = false)"
  />

  <Modal
    v-if="submitStatus"
    :message="t('projects.create.successMessage')"
    :button-text="t('projects.create.continue')"
    :on-click="goToProject"
    :on-close="clearAndClose"
    variation="warning"
    secondary-variation="light"
  />
</template>

<route lang="yaml">
meta:
  layout: project-stage
  stage: create
</route>

<style lang="scss" scoped>
:deep(.imua-dropdown--active) {
  .search-dropdown-list {
    .search-dropdown-list__item--not-found {
      display: grid;
      color: $primary;
      place-items: center;
    }
  }
}

.create-project {
    display: flex;
    flex-direction: column;
    padding-block: 2rem;

    &-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 2rem;
        font-weight: bold;
        gap: 1rem;
        text-transform: uppercase;
        margin-block-end: 3rem;
    }

    &-content {
        display: flex;
        flex-direction: column;
        gap: 2em;
        background: $white-full;
        padding: 1.25rem;
        border-radius: 0.625rem;
        box-shadow: 0 2px 4px #0003;

        .form-subtitle {
            color: $primary;
            font-size: 1rem;
            font-weight: bold;
            text-transform: uppercase;
        }

        .form {
          display: grid;
          grid-template-columns: 4fr 3fr;
          gap: 5em;

            &-section {
                display: flex;
                flex-direction: column;
                flex: 1;
                gap: 1rem;

                &-item {
                    display: grid;
                    grid-template-columns: 25% auto;
                    gap: 0.75rem;

                    label strong {
                        color: $primary;
                        font-size: 0.875rem;
                    }

                    input,
                    select {
                        height: 2.7rem;
                        font-size: 0.875rem;
                        outline: none;
                        border-radius: 0.3rem;
                        border: 1px solid $gray-border;
                        padding-block: 0.25rem;
                        padding-inline: 0.75rem;
                    }
                }

                &-radios {
                    display: flex;
                    flex-direction: row;
                    gap: 1.5rem;
                }

                &-salary {
                    display: grid;
                    grid-template-columns: 1fr auto 1fr 1fr auto 1fr;
                    gap: 0.5rem;
                }

                &-salary-annual {
                    display: grid;
                    grid-template-columns: auto 1fr 1fr auto 1fr;
                    gap: 0.5rem;
                }
            }
        }

        .form-actions {
            display: flex;
            flex-direction: row;
            gap: 2rem;
        }
    }
}
</style>
