export const date = {
  month: "Mês",
  year: "Ano",
  weekdays: JSON.stringify(["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"]),
  months: JSON.stringify([
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ]),
  and: "e",
  dateIn: "de",
  dateFormat: "dd/mm/yy",
  daySingular: "dia",
  dayPlural: "dias",
  monthSingular: "mês",
  monthPlural: "meses",
  yearSingular: "ano",
  yearPlural: "anos",
};
