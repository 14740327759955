import { z } from "zod";
import { CountrySymbolsEnumSchema } from "../enums/location";
import {
  BusinessLevelEnumSchema,
  BusinessTypeEnumSchema,
  NumberOfEmployeesEnumSchema,
  ParentBusinessEquityTypeEnumSchema,
  SubsidiaryBusinessEquityTypeEnumSchema,
  TransformationEventTypeEnumSchema,
} from "../enums/corporation";
import {
  AddressSchema,
  BusinessCategorySchema,
  CorporationInAggregationSchema,
  ExternalIdsSchema,
  GenericSchema,
  MonetaryAmountSchema,
  PersonInAggregationSchema,
  TelephoneNumberSchema,
} from "../common/schemas";

export const CorporationDetailsSchema = z
  .object({
    description: z.string(),
    historic: z.string(),
  })
  .strict();

export const ContactPointsSchema = z
  .object({
    name: z.string(),
    telephone: TelephoneNumberSchema,
  })
  .strict();

export const RevenueSchema = MonetaryAmountSchema.extend({
  max_amount: z.number().nullable().optional(),
  year: z.string(),
}).strict();

export const InvestmentRelationSchema = z.object({
  corporation_id: z.string(),
  main_investor: z.boolean().optional(),
  relationship_details: z.string().nullable().optional(),
});

export const InvestorAndParentCompanySchema = InvestmentRelationSchema.extend({
  business_equity_type: ParentBusinessEquityTypeEnumSchema.nullable().optional(),
  corporation_data: CorporationInAggregationSchema.nullable().optional(),
}).strict();

export const SubsidiaryAndInvestedCompanySchema = InvestmentRelationSchema.extend({
  business_equity_type: SubsidiaryBusinessEquityTypeEnumSchema.nullable().optional(),
  corporation_data: CorporationInAggregationSchema.nullable().optional(),
}).strict();

export const EconomicGroupSchema = z
  .object({
    corporations_ids: z.array(z.string()),
    corporations_data: z.array(CorporationInAggregationSchema).nullable().optional(),
    corporation_data: z.array(CorporationInAggregationSchema).nullable().optional(),
  })
  .strict();

export const CeoSchema = z
  .object({
    person_id: z.string(),
    person_data: PersonInAggregationSchema.nullable().optional(),
  })
  .strict();

export const GovernanceSchema = z
  .object({
    headquarters: CountrySymbolsEnumSchema.nullable().optional(),
    business_type: BusinessTypeEnumSchema.nullable().optional(),
    business_level: BusinessLevelEnumSchema.nullable().optional(),
    economic_group: EconomicGroupSchema.nullable().optional(),
    investor_and_parent_companies: z.array(InvestorAndParentCompanySchema).nullable(),
    subsidiaries_and_invested_companies: z.array(SubsidiaryAndInvestedCompanySchema).nullable(),
    ceo: CeoSchema.nullable(),
    details: z.string().nullable(),
  })
  .strict();

export const StrategySchema = z
  .object({
    main_product: z.string(),
    secondary_products: z.string(),
    geographical_presence: z.string(),
    corporation_moment: z.string(),
    business_plans: z.string(),
    structuring_projects: z.string(),
    acquisition_projects: z.string(),
    asset_disposal_projects: z.string(),
    market_current_scenario: z.string(),
    strategy_status: z.string(),
  })
  .strict();

export const TransformationEventSchema = z
  .object({
    event_type: TransformationEventTypeEnumSchema,
    start_date: z.string(),
    end_date: z.string().nullable().optional(),
    action: z.string().nullable().optional(),
    goal: z.string().nullable().optional(),
  })
  .strict();

export const educationalOrganizationSchema = z
  .object({
    educational_organization: z.object({
      corporation_id: z.string(),
      user_creator_id: z.string(),
      corporation_type: z.string(),
    }).strict(),
  })
  .strict();

export const educationalOrganizationPutSchema = z
  .object({
    educational_organization: z.object({
      corporation_id: z.string(),
      abbreviation: z.string(),
      first_line: z.boolean(),
      corporation_type: z.string(),
      _version: z.number(),
    }),
  })
  .strict();

export const baseEducationalOrganizationSchema = z
  .object({
    _version: z.number(),
    id: z.string(),
    abbreviation: z.string().nullable(),
    corporation_id: z.string(),
    corporation_type: z.string().nullish(),
    first_line: z.string(),
    is_active: z.string(),
    inserted_at: z.string(),
    updated_at: z.string(),
    user_creator_id: z.string(),
    serial: z.number(),
  })
  .nullish();

export const BaseCorporationSchema = z.object({
  user_owner_id: z.string().nullable(),
  name: z.string(),
  description: z.string().nullable(),
  business_categories: z.array(BusinessCategorySchema).nullable(),
  business_function: z.array(z.string()).nullable(),
  address: AddressSchema.nullable(),
  url: z.string().url(),
  avatar_url: z.string().nullable(),
  linkedin_url: z.string().url().nullable(),
  facebook_url: z.string().url().nullable(),
  phone: TelephoneNumberSchema.nullable(),
  contact_points: z.array(ContactPointsSchema).nullable(),
  number_of_employees: NumberOfEmployeesEnumSchema.nullable(),
  revenues: z.array(RevenueSchema).nullable(),
  patrimony: MonetaryAmountSchema.nullable(),
  foundation_year: z.string().nullable(),
  historic: z.string().nullable(),
  governance: GovernanceSchema.nullable(),
  strategy: StrategySchema.nullable(),
  transformation_events: z.array(TransformationEventSchema).nullable(),
  cnpj: z.string().nullable(),
  external_ids: ExternalIdsSchema.nullish(),
});

export const CorporationSchema = BaseCorporationSchema.merge(GenericSchema).strict();
