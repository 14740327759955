<script setup lang="ts">
const props = withDefaults(defineProps<{
  block?: boolean
  filled?: boolean
  noBorder?: boolean
  hover?: boolean
  variation?: string
  size?: string
}>(
), {
  size: "",
  variation: "primary",
  hover: false,
  noBorder: false,
  filled: false,
  blocked: false,
});

const rootClass = "imua-button";
const blockClass = computed<string>(() => (props.block ? `${rootClass}--block` : ""));
const variationClass = computed<string>(
  () => `${rootClass}--${props.variation}${props.filled ? "-filled" : ""}`,
);
const borderClass = computed<string>(() => (props.noBorder ? "no-border" : ""));
</script>

<template>
  <button
    v-bind="$attrs"
    :type="$attrs.type as any || 'button'"
    :class="[rootClass, blockClass, variationClass, borderClass, size ? `--${size}` : '']"
  >
    <slot />
  </button>
</template>

<style lang="scss" scoped>
@mixin set-variation-classes($class-name, $variations-map) {
  @each $variation, $map in $variations-map {
    #{$class-name}--#{$variation} {
      border-color: map-get($map, $key: "bg");
      background-color: map-get($map: $map, $key: "color");
      color: map-get($map: $map, $key: "bg");

      &:hover {
        background-color: map-get($map: $map, $key: "hover");
        color: map-get($map: $map, $key: "color");
      }
    }

    #{$class-name}--#{$variation}-filled {
      border: none;
      background-color: map-get($map: $map, $key: "bg");
      color: map-get($map: $map, $key: "color");

      &:hover {
        $bg-color: darken(
          map-get(
            $map: $map,
            $key: "bg",
          ),
          15%
        );

        @if --hover {
          background-color: map-get($map: $map, $key: "hover");
        } @else {
          background-color: $bg-color;
        }
      }
    }
  }
}

$root-class: ".imua-button";

#{$root-class} {
  padding: 0.375rem 1.5rem;
  border: 1px solid;
  border-radius: 8px;
  cursor: pointer;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 0.875rem;
  text-transform: uppercase;
  transition: 200ms;
  user-select: none;

  &--block {
    width: 100%;
  }

  &.no-border {
    border-color: transparent;
  }

  &:disabled {
    border-color: $gray-light;
    background-color: $white-disabled;
    color: $gray-light;
    pointer-events: none;
  }

  &.--large {
    padding-block: 0.875rem;
  }
}

@include set-variation-classes($root-class, $variations);
</style>
