export enum ConstructionEnum {
  FRONT_COVER = "FRONT_COVER",
  SUMMARY = "SUMMARY",
  COMPANY = "COMPANY",
  GOVERNANCE = "GOVERNANCE",
  STRATEGY = "STRATEGY",
  EXECUTION_CONDITIONS = "EXECUTION_CONDITIONS",
  COMPANY_PROFILE = "COMPANY_PROFILE",
  IDEAL_PROFILE_TAGS = "IDEAL_PROFILE_TAGS",
  IDEAL_PROFILE = "IDEAL_PROFILE",
  REALIZATIONS = "REALIZATIONS",
  ATTRIBUTES = "ATTRIBUTES",
  CULTURAL_MATCH = "CULTURAL_MATCH",
  MOTIVATION = "MOTIVATION",
  SEARCH_FIELD = "SEARCH_FIELD",
  TIMELINE = "TIMELINE",
  BACK_COVER = "BACK_COVER",
}

export enum ConstructionSumaryI18nEnum {
  COMPANY = "aboutCompany",
  GOVERNANCE = "governance",
  STRATEGY = "strategy",
  EXECUTION_CONDITIONS = "executionConditions",
  COMPANY_PROFILE = "companyProfile",
  IDEAL_PROFILE_TAGS = "idealProfile",
  ATTRIBUTES = "attributes",
  CULTURAL_MATCH = "culturalMatch",
  MOTIVATION = "motivation",
  SEARCH_FIELD = "searchField",
  TIMELINE = "schedule",
}

export enum ShortlistSumaryI18nEnum {
  IDEAL_PROFILE = "idealProfile",
  SEARCH_FIELD = "searchField",
  PROCESS_FUNNEL = "processFunnel",
  ADHERENCE_TABLE = "adherenceTable",
  CANDIDATES_ANALYSIS = "candidatesAnalysis",
}

export enum ShortlistEnum {
  FRONT_COVER = "FRONT_COVER",
  SUMMARY = "SUMMARY",
  COMPANY = "COMPANY",
  COMPANY_PROFILE_TAGS = "COMPANY_PROFILE_TAGS",
  IDEAL_PROFILE_TAGS = "IDEAL_PROFILE_TAGS",
  IDEAL_PROFILE = "IDEAL_PROFILE",
  REALIZATIONS = "REALIZATIONS",
  ATTRIBUTES = "ATTRIBUTES",
  CULTURAL_MATCH = "CULTURAL_MATCH",
  MOTIVATION = "MOTIVATION",
  SEARCH_FIELD = "SEARCH_FIELD",
  PROCESS_FUNNEL = "PROCESS_FUNNEL",
  ADHERENCE_TABLE = "ADHERENCE_TABLE",
  ADHERENCE_TABLE_FINAL = "ADHERENCE_TABLE_FINAL",
  LAST_ADHERENCE_TABLE = "LAST_ADHERENCE_TABLE",
  USERS = "USERS",
  TIMELINE = "TIMELINE",
  BACK_COVER = "BACK_COVER",
}

export enum ShortlistPersonEnum {
  USERS = "USERS",
  USER_CARD_PERSON = "USER_CARD_PERSON",
  USER_ABOUT = "USER_ABOUT",
  USER_ACADEMIC = "USER_ACADEMIC",
  USER_EXPERIENCE = "USER_EXPERIENCE",
  USER_REALIZATIONS = "USER_REALIZATIONS",
  USER_ATTRIBUTES = "USER_ATTRIBUTES",
  USER_CULTURAL_MATCH = "USER_CULTURAL_MATCH",
  USER_MOTIVATION = "USER_MOTIVATION",
  USER_MOTIVATION_ONE = "USER_MOTIVATION_ONE",
  USER_MOTIVATION_TWO = "USER_MOTIVATION_TWO",
  USER_MOTIVATION_FACTORS = "USER_MOTIVATION_FACTORS",
  USER_EVALUATION = "USER_EVALUATION",
  USER_EVALUATION_FINAL = "USER_EVALUATION_FINAL",
}

export enum ConstructionSumaryEnum {
  aboutCompany = "COMPANY",
  governance = "GOVERNANCE",
  strategy = "STRATEGY",
  executionConditions = "EXECUTION_CONDITIONS",
  companyProfile = "COMPANY_PROFILE",
  idealProfile = "IDEAL_PROFILE",
  realizations = "REALIZATIONS",
  attributes = "ATTRIBUTES",
  culturalMatch = "CULTURAL_MATCH",
  motivation = "MOTIVATION",
  searchField = "SEARCH_FIELD",
  schedule = "TIMELINE",
}

export enum ShortlistSumaryEnum {
  idealProfile = "IDEAL_PROFILE",
  aboutCompany = "COMPANY",
  searchField = "SEARCH_FIELD",
  processFunnel = "PROCESS_FUNNEL",
  adherenceTable = "ADHERENCE_TABLE",
  candidatesAnalysis = "CANDIDATES_ANALYSIS",
  schedule = "TIMELINE",
  realizations = "REALIZATIONS",
  motivation = "MOTIVATION",
  attributes = "ATTRIBUTES",
  candidates = "USERS",
}

export interface pageSchema {
  id?: string
  page?: number
  show?: boolean
}

export interface ConstructionPages {
  [ConstructionEnum.FRONT_COVER]: pageSchema
  [ConstructionEnum.SUMMARY]: pageSchema
  [ConstructionEnum.COMPANY]: pageSchema
  [ConstructionEnum.GOVERNANCE]: pageSchema
  [ConstructionEnum.STRATEGY]: pageSchema
  [ConstructionEnum.EXECUTION_CONDITIONS]: pageSchema
  [ConstructionEnum.REALIZATIONS]: pageSchema
  [ConstructionEnum.IDEAL_PROFILE]: pageSchema
  [ConstructionEnum.COMPANY_PROFILE]: pageSchema
  [ConstructionEnum.ATTRIBUTES]: pageSchema
  [ConstructionEnum.CULTURAL_MATCH]: pageSchema
  [ConstructionEnum.MOTIVATION]: pageSchema
  [ConstructionEnum.SEARCH_FIELD]: pageSchema
  [ConstructionEnum.TIMELINE]: pageSchema
  [ConstructionEnum.BACK_COVER]: pageSchema
}

export interface ShortlistPages {
  [ShortlistEnum.FRONT_COVER]: pageSchema
  [ShortlistEnum.SUMMARY]: pageSchema
  [ShortlistEnum.COMPANY]: pageSchema
  [ShortlistEnum.IDEAL_PROFILE]: pageSchema
  [ShortlistEnum.REALIZATIONS]: pageSchema
  [ShortlistEnum.MOTIVATION]: pageSchema
  [ShortlistEnum.SEARCH_FIELD]: pageSchema
  [ShortlistEnum.PROCESS_FUNNEL]: pageSchema
  [ShortlistEnum.ADHERENCE_TABLE]: pageSchema
  [ShortlistEnum.USERS]: ShortlistPersonPages[]
  [ShortlistEnum.BACK_COVER]: pageSchema
}

export interface ShortlistPersonPages {
  [ShortlistPersonEnum.USER_CARD_PERSON]: pageSchema
  [ShortlistPersonEnum.USER_ABOUT]: pageSchema
  [ShortlistPersonEnum.USER_ACADEMIC]: pageSchema
  [ShortlistPersonEnum.USER_EXPERIENCE]: pageSchema
  [ShortlistPersonEnum.USER_REALIZATIONS]: pageSchema
  [ShortlistPersonEnum.USER_ATTRIBUTES]: pageSchema
  [ShortlistPersonEnum.USER_CULTURAL_MATCH]: pageSchema
  [ShortlistPersonEnum.USER_MOTIVATION]: pageSchema
  [ShortlistPersonEnum.USER_MOTIVATION_ONE]: pageSchema
  [ShortlistPersonEnum.USER_MOTIVATION_TWO]: pageSchema
  [ShortlistPersonEnum.USER_MOTIVATION_FACTORS]: pageSchema
  [ShortlistPersonEnum.USER_EVALUATION]: pageSchema
}
