export const modals = {
  personRestore: {
    title: "Atenção",
    description: "Você deseja restaurar a versão original?",
  },
  conflictDate: {
    title: "Atenção",
    message: "A data de fim não pode ser anterior a data de início!",
    button: "Fechar",
  },
};
