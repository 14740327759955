<script lang="ts">
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  props: {
    warningChars: Number,
    warningMessage: String,
  },
  setup() {
    const { t } = useI18n();
    const content = ref("");
    const inputRef = ref(null);

    const clear = () => (content.value = "");

    const setContent = (value: string) => {
      if (inputRef.value)
        inputRef.value.value = value;

      content.value = value;
    };

    return {
      content,
      clear,
      t,
      setContent,
      inputRef,
    };
  },
});
</script>

<template>
  <div class="text-area-wrapper">
    <textarea
      ref="inputRef"
      v-model="content"
      class="textarea-component"
      rows="4"
      :placeholder="t('persons.createPersonForm.description')"
      data-test="text-area"
    />
    <div v-if="warningChars" class="counter-wrapper">
      <div class="counter">
        {{ content?.length }}/{{ warningChars }}
      </div>
      <div v-if="content?.length > warningChars" class="warning">
        {{ warningMessage || t("text.textArea.defaultWarning") }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.text-area-wrapper {
  width: 100%;

  .textarea-component {
    width: 100%;
    padding: 1rem;
    border: 1px solid $gray-border;
    border-radius: 6px;
    color: gray;
    font-family: "Open Sans";
    outline: none;
    resize: none;
    scrollbar-color: #888 transparent;

    /* Works on Firefox */
    scrollbar-width: thin;

    &::placeholder {
      color: $gray-medium;
    }

    /* Works on Chrome, Edge, and Safari */
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-color: #888;
    }
  }

  .counter-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 5px;
    font-family: "Open Sans";
    font-size: 13px;

    .warning {
      color: $red-medium;
    }

    .counter {
      color: $yellow-dark;
    }
  }
}
</style>
