export const exportPDF = {
  construction: "CONSTRUCTION",
  constructionTitle: "CONSTRUCTION - EXPORT PREVIEW",
  shortlistTitle: "SHORTLIST - EXPORT PREVIEW",
  previewSubtitle: "Deselect {complement} page for export",
  inactive: "unwanted",
  confidential: "Confidential",
  exportDocument: "Export document",
  summary: "Schedule",
  shortlist: "Shortlist",
  shortlisTitle: "SHORTLIST - EXPORT PREVIEW",
  idealProfile: "Ideal profile - Persona",
  evaluation: "Evaluation",
  confidentialProject: "CONFIDENTIAL PROJECT",
  companyProfile: "Perfil de empresa",
  summaryEnum: {
    aboutCompany: "About the company",
    governance: "Governance",
    strategy: "Strategy",
    executionConditions: "Execution conditions",
    companyProfile: "Company profile",
    idealProfile: "Ideal profile",
    idealProfileTags: "Ideal profile - persona",
    realizations: "Achievements",
    attributes: "Attributes",
    culturalMatch: "Cultural Match",
    motivation: "Motivation",
    searchField: "Search field",
    schedule: "The Process",
  },
  process: {
    processTitle: "Process funnel",
    processDay: "day of process | {count} days of process",
    processDays: "day of process | {count} days of process",
    mapping: {

      text: "Education, length of experience, type of organizations passed, scope of the chair and main achievements",
      textAfter: "identified",
    },
    screenings: {

      text: "Interest in participating, compensation package, location, travel availability, scope+ and achievements+",
      textAfter: "screenings",
    },
    interviews: {
      text: "Achievements++, package+, motivation and profile",
      textAfter: "interviews",
    },
    shortlist: {
      text: "Minimum project adherence rate (90%)",
      textAfter: "approved",
    },
    finalists: {
      text: "Best ranked candidates",
      textAfter: "finalists",
    },
  },
  summaryShortlist: {
    aboutCompany: "About the company",
    idealProfile: "Ideal profile - Persona",
    realizations: "Achievements",
    motivation: "Motivation",
    searchField: "Search field",
    process: "The Process",
    finalists: "The Finalists",
    attributes: "Attributes",
  },
  summaryShortlistEnum: {
    idealProfile: "Ideal profile",
    searchField: "Search field",
    processFunnel: "Process funnel",
    adherenceTable: "Adherence table",
    candidatesAnalysis: "Candidates analysis",
    candidates: "Candidates analysis",
  },
  summaryConstructionBeta: {
    aboutCompany: "About the company",
    governance: "Governance",
    strategy: "Strategy",
    executionConditions: "Execution conditions",
    companyProfile: "Company profile",
    idealProfile: "Ideal profile",
    attributes: "Attributes",
    culturalMatch: "Cultural Match",
    motivation: "Motivation",
    searchField: "Search field",
    schedule: "The Process",
  },
  summaryShortlistBeta: {
    idealProfile: "Ideal profile",
    searchField: "Search field",
    processFunnel: "Process funnel",
    adherenceTable: "Adherence table",
    candidatesAnalysis: "Candidates analysis",
  },
  labels: {
    schooling: "Education Background",
    language: "Languages",
    experience: "Experience",
    experiences: "Experiences",
    professionalExperience: "Professional Experience",
    realizations: "Achievements",
    package: "Package",
    career: "Career",
    environment: "Environment",
    personal: "Personal",
    salary: "Salary",
    icp: "STI (Short Term Incentive)",
    ilp: "LTI (Long Term Incentive)",
    others: "Others",
    totalCash: "Total Cash",
    adherenceIdealProfile: "Adherence to the Ideal Profile",
    experienceProfessional: "Professional experience",
    attributes: "Attributes",
    culturalMatch: "Cultural Match",
    cultural_match: "Cultural Match",
    motivation: "Motivation",
    motivationFactors: "Motivation - Attraction Factors",
    weights: "Weights",
    ideal: "Ideal",
    idealProfile: "Ideal Profile",
    finalRate: "Final rate of adhesion",
    professionalTrajectory: "Professional Trajectory",
    attraction: "Attraction",
    retention: "Retention",
    repulsion: "Repulsion",
    governance: "Governance",
    executionConditions: "Execution conditions",
    strategy: "Strategy",
  },
  funnel: {
    labels: {
      identified: "Identified",
      screenings: "Screenings",
      interviews: "Interviews",
      shortlists: "Approved",
      finalists: "Finalists",
    },
    resumes: {
      identified: "Training, length of experience, type of organizations you have worked for, scope of the subject and main achievements",
      screenings: "Interest in participating, compensation package, location, travel availability, scope+ and achievements+",
      interviews: "Achievements, package, motivation and profile",
      shortlists: "Minimum project adherence rate (90%)",
      finalists: "Best in ranking",
    },
  },
};
