import { z } from "zod";
import { EntitySchema } from "./entity";

const WorkModelEnumSchema = z.enum(["EMPTY", "REMOTE", "ONSITE", "HYBRID"]);

const WillingToRelocateEnumSchema = z.enum(["EMPTY", "YES", "NO"]);

export const CalculatedScoresSchema = z.object({
  academic_formation_mean: z.number().nullable(),
  experiences_mean: z.number().nullable(),
  achievements_mean: z.number().nullable(),
  attributes_mean: z.number().nullable(),
  cultural_match_mean: z.number().nullable(),
  motivation_mean: z.number().nullable(),
  final_mean: z.number().nullable(),
});

const ScreeningsAssessmentBaseSchema = z.object({
  project_id: z.string(),
  person_id: z.string().nullable(),
  activity_id: z.string().nullable(),
});

export type ScreeningsAssessmentBase = z.infer<typeof ScreeningsAssessmentBaseSchema>;

const ScaleEvaluationSchema = z.object({
  level: z.enum(["EMPTY", "LOW", "AVERAGE", "HIGH", "VERY_HIGH"]).nullable(),
  observations: z.string().nullable(),
});

const ScaleScoreEvaluationSchema = ScaleEvaluationSchema.extend({
  score: z.number().nullable(),
});

const ScoreEvaluationSchema = ScaleScoreEvaluationSchema.omit({ level: true }).partial();

const TurnOverFactorsSchema = z.object({
  attraction: z.string().nullable(),
  repulsion: z.string().nullable(),
  retention: z.string().nullable(),
});

const PackageSchema = TurnOverFactorsSchema.extend({
  salary: z.string().nullable(),
  short_term_incentive: z.string().nullable(),
  long_term_incentive: z.string().nullable(),
  other_incentive: z.string().nullable(),
  total_cash: z.string().nullable(),
});

const ScreeningsAttributesSchema = z.object({
  cognitive_intelligence: ScaleEvaluationSchema,
  emotional_intelligence: ScaleEvaluationSchema,
  spiritual_intelligence: ScaleEvaluationSchema,
  energy: ScaleEvaluationSchema,
  maturity: ScaleEvaluationSchema,
});

const ScreeningsCulturalMatchSchema = z.object({
  practical: ScaleEvaluationSchema,
  logical: ScaleEvaluationSchema,
  reflexive: ScaleEvaluationSchema,
  affective: ScaleEvaluationSchema,
});

const ScreeningsAssessmentSchema = ScreeningsAssessmentBaseSchema.merge(
  z.object({
    academic_backgrounds: z.record(z.string()).nullable().default({}),
    languages: z.record(z.string()).nullable().default({}),
    experiences: z.record(z.string()).nullable().default({}),
    achievements: z.record(z.string()).nullable().default({}),
    // adjust default
    attributes: ScreeningsAttributesSchema.nullable(),
    cultural_match: ScreeningsCulturalMatchSchema.nullable(),
    package: PackageSchema.nullable(),
    career: TurnOverFactorsSchema.nullable(),
    work_environment: TurnOverFactorsSchema.nullable(),
    personal_life: TurnOverFactorsSchema.nullable(),
    willing_to_relocate: WillingToRelocateEnumSchema.nullable().default(WillingToRelocateEnumSchema.Enum.EMPTY),
    work_model: WorkModelEnumSchema.nullable(),
  }),
).strict();

const ScreeningsAssessmentPatchSchema = ScreeningsAssessmentSchema.partial();

export type ScreeningsAssessmentPatch = z.infer<typeof ScreeningsAssessmentPatchSchema>;

export type ScreeningsAssessment = z.infer<typeof ScreeningsAssessmentSchema>;

const ScreeningAssesmentStripSchema = ScreeningsAssessmentSchema.strip();

export const ScreeningAssesmentSchemaDtoSchema = z.intersection(EntitySchema, ScreeningAssesmentStripSchema);

export type ScreeningAssesmentSchemaDto = z.infer<typeof ScreeningAssesmentSchemaDtoSchema>;

export const InterviewAssessmentSchema = ScreeningsAssessmentSchema.omit({
  activity_id: true,
})
  .merge(EntitySchema)
  .merge(
    z.object({
      academic_backgrounds: ScoreEvaluationSchema.optional().nullable(),
      languages: ScoreEvaluationSchema.optional().nullable(),
      experiences: ScoreEvaluationSchema.optional().nullable(),
      achievements: ScoreEvaluationSchema.optional().nullable(),
      calculated_scores: CalculatedScoresSchema.optional().nullable(),
    }),
  )
  .strict();
