<script setup lang="ts">
import {
  computed,
  ref,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import WeightInput from "./WeightInput.vue";
import Button from "@/components/button/Button2.vue";
import { SvgIcon } from "@/components/icon";
import type { ProjectWeights } from "@/schemas/project";

const props = defineProps<{
  weights: ProjectWeights
}>();

const emit = defineEmits<{
  (event: "update", payload: any): void
  (event: "cancel"): void
}>();

const { t } = useI18n({ useScope: "global" });

const initialWeights = ref(props.weights);

const weightsTemplate = ref({
  academic_formation: {
    key: "academic_formation",
    label: computed(() =>
      t("projects.details.constructionGuide.idealProfile.academicFormation")),
    icon: "project_academic",
    model: initialWeights.value?.academic_formation,
  },
  experiences: {
    key: "experiences",
    label: computed(() =>
      t("projects.details.constructionGuide.idealProfile.experienceShort")),
    icon: "project_experience_2",
    model: initialWeights.value?.experiences,
  },
  achievements: {
    key: "achievements",
    label: computed(() =>
      t("projects.details.constructionGuide.tabs.achievements")),
    icon: "project_realizations",
    model: initialWeights.value?.achievements,
  },
  attributes: {
    key: "attributes",
    label: computed(() =>
      t("projects.details.constructionGuide.idealProfile.attributes")),
    icon: "project_attributes",
    model: initialWeights.value?.attributes,
  },
  cultural_match: {
    key: "cultural_match",
    label: computed(() =>
      t("projects.details.constructionGuide.idealProfile.culturalMatch")),
    icon: "project_cultural_match",
    model: initialWeights.value?.cultural_match,
  },
  motivation: {
    key: "motivation",
    label: computed(() =>
      t("projects.details.constructionGuide.idealProfile.motivation")),
    icon: "project_motivation",
    model: initialWeights.value?.motivation,
  },
});

const weightsSum = computed(() => {
  return Object.values(weightsTemplate.value).reduce((acc, item) => {
    return acc + item.model;
  }, 0);
});

const weightsPayload = computed(() => Object.values(weightsTemplate.value)
  .reduce((acc, item) => ({
    ...acc,
    [item.key]: item.model,
  }), {}));

const correctWeights = ref(true);
const weightAnimation = ref(null);

function playAnimation() {
  weightAnimation.value?.play();
}

watch(weightsSum, (newVal) => {
  if (newVal !== 100) {
    correctWeights.value = false;
  }
  else {
    correctWeights.value = true;
    playAnimation();
  }
});
</script>

<template>
  <div>
    <div class="title-wrapper">
      <div class="title">
        {{
          t("projects.details.constructionGuide.ranking.weights")
        }}
      </div>
    </div>
    <div class="weights">
      <div v-for="weight in weightsTemplate" :key="weight.key" class="weight">
        <div class="icon">
          <SvgIcon :icon="weight.icon" width="4rem" height="4rem" margin="none" />
        </div>
        <div class="label">
          {{ weight.label }}
        </div>
        <div class="label-weight">
          {{
            t("projects.details.constructionGuide.ranking.weight")
          }}:
        </div>
        <WeightInput v-model="weight.model" :step="5" />
      </div>
      <div class="weight-total">
        <template v-if="!correctWeights">
          <div>{{ weightsSum }}%</div>
          <div>
            {{
              t("projects.details.constructionGuide.ranking.rankingWeightsSucessPart1")
            }}
          </div>
          <div>
            {{
              t("projects.details.constructionGuide.ranking.rankingWeightsSucessPart2")
            }}
          </div>
        </template>
        <template v-if="correctWeights">
          <div class="weight-total-animation">
            <!-- <Blottie
              ref="weightAnimation"
              :animation-data="WeightsSuccessAnimation"
              :autoplay="true"
              :loop="true"
              class="lottie-animation"
            /> -->
            <div>100%</div>
          </div>
        </template>
      </div>
    </div>
    <div class="actions">
      <Button
        variation="secondary"
        :disabled="weightsSum !== 100"
        @click="emit('update', weightsPayload)"
      >
        {{ t("projects.details.constructionGuide.ranking.saveWeights") }}
      </Button>
      <Button
        variation="light"
        filled
        @click="emit('cancel')"
      >
        {{ t("text.form.cancel") }}
      </Button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.title-wrapper {
  border-bottom: 2px solid #ececec;
  margin-block: 1rem 2rem;
  padding-block: 1rem;
}

.title {
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
}

.label {
  color: $gray-low;
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
}

.icon {
  color: $gray-low;
}

.label-weight {
  align-self: flex-start;
  padding: 0 1.5rem;
  color: $gray-low;
  font-size: 0.875rem;
}

.label {
  padding: 1rem 0 2rem;
}

.weights {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(4, 1fr);
}

.weight {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.5rem;
  background: #fff;
  box-shadow: 0 2px 4px #00000029;
  padding-block: 2rem;
}

.weight-total {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  background: #dedede36;
  grid-column: span 2;

  > div {
    color: $primary;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.5rem;
  }

  div:first-child {
    padding-bottom: 1.5rem;
    font-size: 2.5rem;
  }
}

.weight-total-animation {
  display: flex;
  width: 6rem;
  height: 6rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: transparent;

  > div:last-child {
    color: $green-medium;
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 1.5rem;
  }
}

.actions {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin: 2rem 0 4rem;

  button:first-child {
    margin-right: 1rem;
  }
}
</style>
