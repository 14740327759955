<script setup lang="ts">
import { Card } from "@/components/card";

// TODO: move to modelvalue
const props = defineProps<{
  value: string
}>();

defineEmits(["change", "update:value"]);

const opinion = useVModel(props, "value");
</script>

<template>
  <Card class="simple-list__opinion">
    <img
      class="simple-list__opinion--logo"
      src="@/assets/images/flow.png"
      alt="flow logo"
    >

    <TextArea
      v-model="opinion"
      :warning-chars="400"
      @change="$emit('change', $event.target.value)"
    />
  </Card>
</template>

<style scoped lang="scss">
.simple-list {

  &__opinion {
    position: relative;
    background-color: $white-background;
    line-height: 1.4;
    padding-inline: 2.75rem;

    &--logo {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-25%, -25%);
    }
  }
}
:deep(.text-area-wrapper) .counter-wrapper {
  display: flex;
  justify-content: flex-start;
}
</style>
