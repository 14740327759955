<script setup lang="ts">
import get from "lodash-es/get";
import { changeImageSize } from "@/utils/images/resize-images";
import type { ImageSizesType } from "@/schemas/enums/images";

interface PropsType {
  imageUrl: string
  name: string
  borderColor: string
  size: string
  imageSize?: ImageSizesType
}

const props = withDefaults(defineProps<PropsType>(), {
  size: "120px",
  imageSize: "large",
});

const name = toRef(props, "name") as Ref<string>;
const borderColor = toRef(props, "borderColor") as Ref<string>;
const imageUrl = toRef(props, "imageUrl") as Ref<string>;
const imgError = ref(false);

const colorClass = computed<string>(() => {
  const classes = {
    yellow: "border-yellow",
    gray: "border-gray",
    transparent: "no-border",
  };

  return get(classes, borderColor.value);
});

const placeholderText = computed<string>(() => {
  const allNames = name.value.split(/\s/).filter(i => i);

  if (allNames.length > 1) {
    const [firstLetter] = allNames[0]?.toUpperCase();
    const [secondLetter] = allNames.slice(-1)[0]?.toUpperCase();
    return `${firstLetter}${secondLetter}`;
  }

  return name.value.slice(0, 2).toUpperCase();
});

watch(imageUrl, () => {
  imgError.value = false;
});
</script>

<template>
  <div class="image-placeholder" :class="colorClass">
    <div
      v-if="!imgError && imageUrl"
      class="image-wrapper"
      :style="imageUrl ? { background: '#fff' } : { background: '#040404' }"
    >
      <img :src="changeImageSize(imageUrl, imageSize)" :alt="`${name}`" loading="lazy" @error="imgError = true">
    </div>
    <div v-else class="image-wrapper">
      <span>
        {{ placeholderText }}
      </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../styles/_colors";

.image-placeholder {
  display: flex;
  width: v-bind(size);
  height: v-bind(size);
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  border: 3px solid;
  border-radius: 100%;

  &.border-yellow {
    border-color: $yellow-light;
    background-color: #666;
  }

  &.border-gray {
    border-color: $black-variant;
    background-color: #666;
  }

  &.no-border {
    border: none;
    background-color: #666;
  }

  span {
    color: white;
    font-size: 100%;
    font-weight: 400;
  }

  .image-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 1px;
    aspect-ratio: 1;
    border-radius: 100%;
    text-transform: uppercase;
    user-select: none;

    img {
      width: 100%;
      border-radius: 100%;
    }
  }
}
</style>
