import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { isSaving } from "./useScreeningEvaluation";
import type { CandidateType, PatchApprovalCandidateType } from "@/common/services/candidates";
import { patchApprovalCandidate } from "@/common/services/candidates";
import { getPerson } from "@/http_services/entities/persons";

export function useFetchCandidate(project_id: string, person_id: string) {
  return useQuery({
    queryKey: ["candidate"],
    queryFn: () => getCandidate({ project_id, person_id }),
  });
}

export function useFetchCandidateByPersonId(person_id: string) {
  return useQuery({
    queryKey: ["candidate"],
    queryFn: () => getPerson(person_id),
  });
}

export function usePatchApprovalCandidate() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["candidates"],
    mutationFn: (data: { person_id: string; body: PatchApprovalCandidateType }) => patchApprovalCandidate(data.person_id, data.body),
    onMutate: async () => {
      isSaving.value = true;
    },
    onError: () => {
      isSaving.value = false;
    },
    onSuccess: async () => {
      isSaving.value = false;
      await queryClient.refetchQueries({ queryKey: ["candidates"] });
      await queryClient.refetchQueries({ queryKey: ["mapping_candidates"] });
      await queryClient.refetchQueries({ queryKey: ["interview_candidates"] });
    },
  });
}

export function usePatchCandidate() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["candidate"],
    mutationFn: (data: { candidate_id: string; candidate: CandidateType }) => patchCandidate(data.candidate_id, data.candidate),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["candidate"] });
      queryClient.invalidateQueries({ queryKey: ["candidates"] });
      queryClient.invalidateQueries({ queryKey: ["candidate", "list"] });
    },
  });
}
