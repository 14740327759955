<script lang="ts">
import type { Ref } from "vue";
import {
  computed,
  defineComponent,
  inject,
  ref,
} from "vue";
import { useI18n } from "vue-i18n";
import SectorSection from "../Sections/SectorSection.vue";
import DetailsSection from "../Sections/DetailsSection.vue";
import AboutSection from "../Sections/AboutSection.vue";
import HistoricSection from "../Sections/HistoricSection.vue";
import GovernanceSection from "../Sections/GovernanceSection.vue";
import StrategySection from "../Sections/StrategySection.vue";
import type { Corporation } from "@/entities/corporation";

export default defineComponent({
  name: "AboutPage",
  components: {
    SectorSection,
    DetailsSection,
    AboutSection,
    HistoricSection,
    GovernanceSection,
    StrategySection,
  },
  setup() {
    const { t } = useI18n();
    const editing = ref("");

    const submitCorporation = inject("submitCorporation") as
      (payload: Partial<Corporation>) => Promise<{ success: boolean }>;
    const corporation = inject("corporation") as Ref<Corporation>;

    const historic = computed(() => ({
      foundation_year: corporation.value.foundation_year,
      historic: corporation.value.historic,
    }));

    const submit = async (payload: Partial<Corporation>) => {
      const { success } = await submitCorporation(payload);
      if (success)
        editing.value = "";
    };

    return {
      t,
      editing,
      submit,
      corporation,
      historic,
    };
  },
});
</script>

<template>
  <div v-if="corporation" class="tab-container">
    <SectorSection
      v-model:businessCategories="corporation.business_categories"
      v-model:businessFunctions="corporation.business_function"
      class="tab-section --large-spacing"
      :is-editing="editing === 'sector'"
      @click:edit="editing = 'sector'"
      @click:editCancel="editing = ''"
      @submit:sector="submit"
    />

    <DetailsSection
      class="tab-section"
      :annual-revenue="corporation.revenues"
      :net-worth="corporation.patrimony"
      :employees-number="corporation.number_of_employees"
      :is-editing="editing === 'details'"
      @click:edit="editing = 'details'"
      @click:editCancel="editing = ''"
      @submit:details="submit"
    />

    <AboutSection
      class="tab-section"
      :description="corporation.description"
      :is-editing="editing === 'description'"
      @click:edit="editing = 'description'"
      @click:editCancel="editing = ''"
      @submit:description="submit"
    />

    <HistoricSection
      class="tab-section"
      :historic="historic"
      :is-editing="editing === 'historic'"
      @click:edit="editing = 'historic'"
      @click:editCancel="editing = ''"
      @submit:historic="submit"
    />

    <GovernanceSection
      class="tab-section"
      :governance="corporation.governance || {}"
      :is-editing="editing === 'governance'"
      @click:edit="editing = 'governance'"
      @click:editCancel="editing = ''"
      @submit:governance="submit"
    />

    <StrategySection
      :strategy="corporation.strategy || {}"
      class="tab-section"
      :is-editing="editing === 'strategy'"
      @click:edit="editing = 'strategy'"
      @click:editCancel="editing = ''"
      @submit:strategy="submit"
    />
  </div>
</template>

<style lang="scss" scoped>
.tab-section {
  margin-bottom: 2.5rem;

  &.--large-spacing {
    margin-bottom: 3rem;
  }
}
</style>
