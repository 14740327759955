<script setup lang="ts">
import { computed } from "vue";

const props = defineProps({
  mesage: {
    type: String,
    default: "",
  },
  show: {
    type: Boolean,
    default: false,
  },
  color: {
    type: String,
    default: "primary",
  },
  background: {
    type: String,
    default: "white-full",
  },
  width: {
    type: String,
    default: "75%",
  },
});
const rootClass = "tooltip-box";
const colorsClass = computed<string>(() =>
    `--color-${props.color} --bg-${props.background}`);
</script>

<template>
  <span v-show="props.show" :class="[rootClass, colorsClass]">
    <slot>{{ props.mesage }}</slot>
  </span>
</template>

<style lang="scss" scoped>
.tooltip-box {
  position: absolute;
  z-index: 99;
  width: var(--width);
  padding: 0.75rem;
  border-radius: 8px;
  margin-top: -4rem;
  margin-left: calc(var(--width) / 2);
  background: white;
  box-shadow: 0 2px 3px #0000003b;
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 1.06rem;
  text-align: center;
}

.tooltip-box::after {
  position: absolute;
  top: auto;
  right: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border: 8px solid white;
  margin-right: calc(50% - 8px);
  margin-bottom: -8px;
  box-shadow: 4px 4px 5px #0000001a;
  content: "";
  transform: rotate(45deg);
}

@mixin tooltip-change($colors-map) {
  @each $key,
    $value in $colors-map {
    .tooltip-box.--color-#{$key} {
      color: $value;
    }

    .tooltip-box.--bg-#{$key} {
      background-color: $value;
    }

    .tooltip-box:after.--bg-#{$key} {
      border-color: $value;
    }
  }
}

@include tooltip-change($all-colors);
</style>
