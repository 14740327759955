<script lang="ts">
import isNumber from "lodash-es/isNumber";
import { Card } from "@/components/card";
import { SvgIcon } from "@/components/icon";

const validIcons = [
  "construction_icon",
  "search_icon",
  "audio_icon",
  "forum_icon",
  "fact_check_icon",
  "handshake_icon",
  "star_icon",
];

export default defineComponent({
  name: "TimelineStage",
  components: {
    Card,
    SvgIcon,
  },
  props: {
    icon: {
      type: String,
      required: true,
      validator: (value: string): boolean => validIcons.includes(value),
    },
    status: {
      type: String,
    },
    iconSize: {
      type: [String, Number],
      default: 37,
    },
    cardSize: {
      type: [String, Number],
      default: null,
    },
    label: {
      type: [String, Number],
    },
    checked: {
      type: Boolean,
      default: false,
    },
    blocked: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const cardSizeComputed = computed(() => {
      const { cardSize } = props;
      if (!cardSize)
        return null;
      if (isNumber(cardSize))
        return `--card-size: ${cardSize}px`;
      return `--card-size: ${cardSize}${
        ["px", "rem"].includes(cardSize as string) ? "" : "px"
      }`;
    });

    return {
      cardSizeComputed,
    };
  },
});
</script>

<template>
  <Card
    class="timeline-stage"
    :class="[
      blocked ? 'timeline-stage--blocked' : '',
      status ? `timeline-stage--${status}` : '',
    ]"
    :style="cardSizeComputed"
  >
    <SvgIcon
      class="timeline-stage__icon"
      :width="iconSize"
      :height="iconSize"
      :icon="icon"
      margin="none"
    />
    <span class="timeline-stage__label" :class="[blocked ? 'timeline-stage__label--blocked' : '']">
      <template v-if="!checked">
        {{ label }}
      </template>

      <SvgIcon
        v-else
        width="12"
        height="12"
        icon="check_icon"
        margin="none"
      />
    </span>
  </Card>
</template>

<style lang="scss" scoped>
$font-size: 0.875rem;
$size: 81px;

.timeline-stage {
  position: relative;
  display: flex;
  width: var(--card-size, #{$size});
  height: var(--card-size, #{$size});
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-bottom: 0.25rem;
  color: $gray-medium;
  font-size: $font-size;

  &--blocked {
    cursor: not-allowed;
  }

  &--active {
    background-color: $blue-light;
    color: $white-full;

    &:hover {
      background-color: $blue-light-dark;
    }
  }

  &--suspended {
    background-color: #707070;
    color: $white-full;
  }

  &--cancelled {
    background-color: #a7a7a7;
    color: $white-full;
  }

  &--concluded {
    background-color: $green-medium;
    color: $white-full;

    &:hover {
      background-color: $green-medium-dark;
    }
  }

  &__icon {
    position: relative;
    top: -10%;
  }

  &__label {
    position: absolute;
    bottom: calc(#{$font-size} * 0.75);
    left: 50%;
    color: inherit;
    font-weight: bold;
    transform: translateX(-50%);

    &--blocked {
      cursor: not-allowed;
    }
  }
}
</style>
