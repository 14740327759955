<script lang="ts">
import { defineComponent } from "vue";

const variations = ["warning", "regular", "secondary", "gray-light", "light", "filled"];

export default defineComponent({
  name: "Button",
  props: {
    filled: Boolean,
    variation: {
      type: String,
      default: "warning",
      validator: (v: string) => variations.includes(v),
    },
    noBorder: Boolean,
    onClick: Function,
    text: String,
  },
});
</script>

<template>
  <button
    v-bind="{
      ...$attrs,
      class: {
        [$attrs.class || '']: true,
        'filled': filled,
        [variation]: true,
        'no-border': noBorder,
      },
    }"
    @click="onClick"
  >
    {{ text }}
  </button>
</template>

<style lang="scss" scoped>
$gray-regular: #e6e3e3;

button {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 5px 20px;
  border: solid 1px black;
  border-radius: 5px;
  background: none;
  cursor: pointer;
  font-family: "Open Sans";
  text-transform: uppercase;

  &.light {
    border: $white-lotion;
    background-color: $white-lotion;
    color: $gray;

    &:hover,
    &:focus {
      background-color: $white-hover;
    }
  }

  &.warning {
    border-color: $yellow-dark;
    color: $yellow-dark;

    &:hover,
    &:focus {
      background-color: $yellow-dark;
      color: #fff;
    }
  }

  &.orange {
    border-color: $orange-medium;
    color: $orange-medium;

    &:hover,
    &:focus {
      background-color: $orange-medium;
      color: $white-full;
    }
  }

  &.regular {
    border-color: $gray-regular;
    color: $gray-dark;
  }

  &.gray-light {
    border-color: $gray-dark;
    background-color: $white-background;
    color: $gray;

    &:hover {
      background-color: $gray-regular;
      color: $gray-dark;
    }
  }

  &.no-border {
    border: none;
  }

  &.secondary {
    border-color: transparent;
    background-color: #fafafa;
    color: $gray;

    &:hover,
    &:focus {
      background-color: #d4d4d4;
      color: $gray-dark;
    }
  }

  &.filled {
    border-color: $yellow-dark;
    background: $yellow-dark;
    color: #fff;

    &:hover,
    &:focus {
      background-color: $yellow-dark;
    }
  }
}
</style>
