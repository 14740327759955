<script setup lang="ts">
import { computed, ref, toRef } from "vue";
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
import get from "lodash-es/get";
import { searchPersons, searchRecruiters } from "@/http_services/entities/persons";
import { CalendarInput, SearchDropdown, Select } from "@/components/inputs/modalInputs";
import { Button2 as Button } from "@/components/button";
import type { Person } from "@/schemas/person";
import {
  ConstructionActivityDescriptionEnum,
  ScreeningActivityDescriptionEnum,
} from "@/schemas/enums/project";

const props = defineProps({
  options: Object,
  type: {
    type: String,
    validator: (s: string) => ["construction", "screening", "mapping", "interviews"].includes(s),
    required: true,
  },
  candidates: {
    type: Array,
    default: () => [],
  },
  recruiter: Object,
  projectId: String,
});

const emit = defineEmits(["cancel", "submit"]);

const { t } = useI18n();

const searchRequestor = async (params: any) => searchPersons(params, undefined, params.name);

async function searchCandidate(params: any) {
  const candidates = await searchCandidates({
    project_id: props.projectId,
    project_stage: "INTERVIEWS",
    ...params,
  });

  return { data: candidates };
}

const updateRequestorInput = (person: Person): string => `${person.first_name} ${person.last_name}`;
const updateCandidateInput = (person: Person): string => `${person.person_data.first_name} ${person.person_data.last_name}`;

const activityType = toRef(props, "type");
const recruiter = toRef(props, "recruiter");
const isRecruiter = computed(() => !(!recruiter.value));

const defaultDescription = computed(() => {
  const options = {
    construction: ConstructionActivityDescriptionEnum.CONSTRUCTION_MEETING,
    screening: ScreeningActivityDescriptionEnum.SCREENING_MEETING,
    interviews: "RECRUITER_MEETING",
  };
  return get(options, activityType.value);
});

function initialFormData() {
  return {
    activity_date: null,
    recruiter: null,
    description: defaultDescription.value,
    requester: null,
  };
}

function initialScreeningFormData() {
  return {
    activity_date: null,
    recruiter: null,
    description: defaultDescription.value,
    candidate: null,
  };
}

const payload = ref(
  (props.type === "screening" || props.type === "interviews") ? initialScreeningFormData() : initialFormData(),
) as any;

const isPayloadEmpty = computed(() => Object.values(payload.value).every(value => !value));

const rules = {
  activity_date: {
    required: helpers.withMessage(t("validator.required"), required),
  },
  recruiter: {
    required: helpers.withMessage(t("validator.required"), required),
  },
  description: {
    required: helpers.withMessage(t("validator.required"), required),
  },
  requester: {
    required: ["construction"].includes(props.type)
      ? helpers.withMessage(t("validator.required"), required)
      : false,
  },
  candidate: {
    required: ["interviews", "screening"].includes(props.type)
      ? helpers.withMessage(t("validator.required"), required)
      : false,
  },
};

const v$ = useVuelidate(rules, payload.value);

const activityDateError = computed(() => v$.value.activity_date.$errors[0]?.$message.toString());
const recruiterError = computed(() => v$.value.recruiter.$errors[0]?.$message.toString());
// const descriptionError = computed(() => v$.value.description.$errors[0]?.$message.toString());
const requesterError = computed(() => v$.value.requester.$errors[0]?.$message.toString());
const candidateError = computed(() => v$.value.candidate.$errors[0]?.$message.toString());

function selectRecruiter(data: any) {
  payload.value.recruiter = { person_id: data.person_id ?? data };
}

function selectCandidate(data: any) {
  payload.value.candidate = { person_id: data.person_id };
}

function cancelForm() {
  payload.value = props.type === "screening" ? initialScreeningFormData() : initialFormData();
  emit("cancel");
}

async function submit() {
  const isValid = await v$.value.$validate();
  if (isValid)
    emit("submit", payload.value);
}

const {
  first_name: recruiterFirstName,
  last_name: recruiterLastName,
  id: userId,
} = recruiter.value;
</script>

<template>
  <form class="activities-tab__form" @submit.prevent="submit()">
    <SectionTitle is-editing :title="t('projects.details.activities.title')" />

    <div class="form-group" mb-3>
      <label for="activity_date">{{ t("projects.details.activities.form.labels.startDate") }}:
      </label>
      <CalendarInput
        id="activity_date"
        v-model="payload.activity_date"
        :error-message="activityDateError"
      />
    </div>

    <div class="form-group">
      <label for="recruiter_person">
        {{ t("projects.details.activities.form.labels.consultantName") }}:
      </label>
      <SearchDropdown
        id="recruiter_person"
        :fetch-method="searchRecruiters"
        :select-item-callback="selectRecruiter"
        :update-input-model="
          ({ person_data }) => `${person_data.first_name} ${person_data.last_name}`
        "
        :initial-value="isRecruiter ? `${recruiterFirstName} ${recruiterLastName}` : ''"
        :recruiter-id="isRecruiter ? userId : null"
        :input-placeholder="t('text.searchProfile')"
        :search-not-found-label="t('text.searchProfileNotFound')"
        :search-error-label="t('text.searchProfileError')"
        :error="Boolean(recruiterError)"
        :error-message="recruiterError"
        @clear="payload.recruiter_person = null"
      >
        <template #list-item="{ item: { person_data } }">
          {{ `${person_data.first_name} ${person_data.last_name}` }}
        </template>
      </SearchDropdown>
    </div>

    <div class="form-group">
      <label for="activity">
        {{ t("projects.details.activities.form.labels.activityDescription") }}:
      </label>
      <Select
        id="activity"
        v-model="payload.description"
        :placeholder="t('text.select')"
        :options="options"
        disabled
      />
    </div>

    <div v-if="['screening', 'interviews'].includes(type)" class="form-group">
      <label for=""> {{ t("projects.details.activities.form.labels.candidateName") }}: </label>
      <SearchDropdown
        id="candidate_person"
        :fetch-method="searchCandidate"
        :select-item-callback="selectCandidate"
        :update-input-model="updateCandidateInput"
        :input-placeholder="t('projects.details.activities.form.labels.fullname')"
        :search-not-found-label="t('text.searchProfileNotFound')"
        :search-error-label="t('text.searchProfileError')"
        :error-message="candidateError"
        @clear="payload.candidate = null"
      >
        <template #list-item="{ item: person }">
          {{ `${person.person_data.first_name} ${person.person_data.last_name}` }}
        </template>
      </SearchDropdown>
    </div>

    <div v-else class="form-group">
      <label for="">
        {{ t("projects.details.activities.form.labels.participatingRequester") }}:
      </label>
      <SearchDropdown
        id="requester_person"
        :fetch-method="searchRequestor"
        :select-item-callback="(person) => (payload.requester = { person_id: person.id })"
        :update-input-model="updateRequestorInput"
        :input-placeholder="t('text.searchProfile')"
        :search-not-found-label="t('text.searchProfileNotFound')"
        :search-error-label="t('text.searchProfileError')"
        :error-message="requesterError"
        @clear="payload.requester = null"
      >
        <template #list-item="{ item: person }">
          {{ `${person.first_name} ${person.last_name}` }}
        </template>
      </SearchDropdown>
    </div>

    <div class="form-actions">
      <Button type="submit" :disabled="isPayloadEmpty">
        {{ t("text.form.submit") }}
      </Button>

      <Button variation="secondary" @click="cancelForm()">
        {{ t("text.form.cancel") }}
      </Button>
    </div>
  </form>
</template>

<style lang="scss" scoped>
.form-group {
  display: grid;
  margin-bottom: 0.5rem;
  gap: 1rem;
  grid-template-columns: 1fr 2fr;

  label {
    font-size: 0.875rem;
    line-height: 28px;
  }
}

.form-actions {
  display: flex;
  gap: 10%;
  margin-block: 2rem;
}
</style>
