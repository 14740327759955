import type { Module } from "vuex";
import type { Interview, OrganizationAndAssessmentSchema } from "@/schemas/project";

const InterviewStoreModule: Module<any, any> = {
  state() {
    const interviewGuide: Interview = null;
    const interviewEvaluate: OrganizationAndAssessmentSchema = null;

    return {
      interviewGuide,
      interviewEvaluate,
    };
  },
  getters: {
    interviewGuide: state => state.interviewGuide,
    interviewEvaluate: state => state.interviewEvaluate,
  },
  mutations: {
    SET_INTERVIEW_GUIDE: (state, data) => {
      state.interviewGuide = data;
    },
    SET_INTERVIEW_EVALUATE: (state, data) => {
      state.interviewEvaluate = data;
    },
  },
};

export default InterviewStoreModule;
