<script setup lang="ts">
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
import { debounce, get } from "lodash";
import { Button2 as Button } from "@/components/button";
import { FormInput } from "@/components/inputs/modalInputs";
import { Loading } from "@/components/loading";
import type { Corporation } from "@/schemas/corporation/";
import { appendAka, searchCorporationSimilar } from "@/http_services/entities/corporation";
import CorporationsList from "@/corporations/pages/corporations/components/CheckConflict/CorporationsList.vue";

const props = defineProps({
  corporationName: String,
});

const emit = defineEmits(["select", "create"]);

const { t } = useI18n();
const corporationName = ref(props.corporationName);
const corporationDomain = ref("");
const total = ref(0);
const skip = ref(0);
const corporations = ref<Corporation[]>([]);
const selectedCorporation = ref<Corporation | null>(null);
const searchedResult = ref(false);
const loading = ref(false);
const isAddingNewCorporation = ref(false);

const rules = computed(() => ({
  corporationName: {
    required: helpers.withMessage(t("validator.required"), required),
  },
}));

const v$ = useVuelidate(rules, {
  corporationName,
  corporationDomain,
});

const doSearch = debounce(async () => {
  if (!corporationName.value && !corporationDomain.value) {
    corporations.value = [];
    searchedResult.value = false;
    loading.value = false;
    return;
  }

  if (searchedResult.value && corporations.value.length === total.value) {
    loading.value = false;
    return;
  }

  const params = {
    limit: 10,
    skip: skip.value,
    name: corporationName.value,
    url: corporationDomain.value,
  };

  const { total: totalFound, data } = await searchCorporationSimilar(params);
  corporations.value = [...corporations.value, ...data];

  searchedResult.value = true;

  skip.value = corporations.value.length;
  total.value = totalFound;
  loading.value = false;
}, 1000);

function resetParams() {
  selectedCorporation.value = null;
  skip.value = 0;
  total.value = 0;
  corporations.value = [];
  loading.value = false;
  searchedResult.value = false;
}

function newSearch(event: KeyboardEvent) {
  const key = event.keyCode || event.charCode;
  if ((key >= 48 && key <= 90) || key === 8 || key === 13 || (key >= 96 && key <= 105)) {
    resetParams();
    loading.value = true;

    doSearch();
  }
}

function onCorporationClick(corporation: Corporation) {
  selectedCorporation.value = corporation;
}

async function selectCorporation() {
  v$.value.$reset();
  if (total.value > 0 && selectedCorporation.value) {
    await appendAka(selectedCorporation.value.id!, {
      also_known_as: [props.corporationName!],
    });

    emit("select", selectedCorporation.value);
    isAddingNewCorporation.value = false;
  }
}

async function createNewCorporation() {
  const isValid = await v$.value.$validate();
  if (isValid)
    emit("create", { name: corporationName.value, url: corporationDomain.value });
}

function checkNewCorporation() {
  isAddingNewCorporation.value = true;
  if (corporationName.value) {
    resetParams();

    loading.value = true;

    doSearch();
  }
}
</script>

<template>
  <div class="wrapper">
    <template v-if="isAddingNewCorporation">
      <h4>{{ t("corporations.checkConflict.addNewCompany") }}</h4>

      <div class="input-group">
        <div>
          <FormInput
            v-model="corporationName"
            :placeholder="t('corporations.name')"
            :on-key-down="newSearch"
            :error="get(v$, 'corporationName.$error', false)"
            :error-message="get(v$, 'corporationName.$errors.0.$message', '')"
          />
          <FormInput
            v-model="corporationDomain"
            :placeholder="t('corporations.domain')"
            :on-key-down="newSearch"
            :error="get(v$, 'corporationDomain.$error', false)"
            :error-message="get(v$, 'corporationDomain.$errors.0.$message', '')"
          />
        </div>

        <p v-if="total && searchedResult && !loading" class="response-text">
          {{ t("corporations.checkConflict.corporationsFound", total) }}
        </p>
      </div>

      <div class="list">
        <Loading v-if="loading" class="loader" :violet="true" />

        <div v-else class="results">
          <CorporationsList
            v-if="total"
            :corporations="corporations"
            :load-more-data="doSearch"
            @click:corporation="onCorporationClick($event)"
          />

          <div v-if="searchedResult" class="not-found">
            <p class="response-text">
              {{ t("corporations.checkConflict.corporationsFound", total) }}
            </p>

            <div class="actions">
              <Button
                :filled="false"
                :disabled="selectedCorporation ? false : true"
                @click="selectCorporation"
              >
                {{ t("corporations.checkConflict.linkCompany") }}
              </Button>

              <Button
                :filled="false"
                @click="createNewCorporation"
              >
                {{ t("corporations.checkConflict.saveNewProfile") }}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <small>{{ t("corporations.checkConflict.companyNotFound") }}</small>
      <Button :filled="false" variation="primary" @click="checkNewCorporation">
        {{ t("corporations.checkConflict.addNewProfile") }}
      </Button>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.wrapper {
  margin-bottom: 2rem;

  .list {
    display: flex;
    flex-direction: column;
    align-items: center;

    .results {
      width: 80%;
    }
  }

  .loader {
    display: flex;
    justify-content: center;
  }

  small {
    display: block;
    margin-bottom: 2rem;
    margin-left: 0.5rem;
    color: $primary;
    font-size: 0.625rem;
  }
}

h4 {
  margin-top: 2rem;
  color: $red-violet-medium;
  font-size: 0.875rem;
  font-weight: bold;
}

.rework-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 4rem;

  button:first-child {
    margin-right: 2rem;
  }
}

.actions {
  display: grid;
  gap: 1rem;
}

.input-group {
  min-width: 350px;
  margin-top: 2rem;

  div:first-child {
    margin-bottom: 1rem;
  }

  div:last-child {
    margin-bottom: 2rem;
  }

  .input {
    width: 100%;
  }
}

.response-text {
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: $gray-dark;
  font-size: 13px;
  text-align: center;
}

.not-found {
  .response-text {
    margin-top: 0;
    font-size: 10px;
  }
}

.not-found-action {
  width: 45%;
  margin-right: auto;
  margin-left: auto;
}
</style>
