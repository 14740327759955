import { z } from "zod";

export enum CountrySymbolsEnum {
  EMPTY = "EMPTY",
  AF = "AF",
  AX = "AX",
  AL = "AL",
  DZ = "DZ",
  AS = "AS",
  AD = "AD",
  AO = "AO",
  AI = "AI",
  AQ = "AQ",
  AG = "AG",
  AR = "AR",
  AM = "AM",
  AW = "AW",
  AU = "AU",
  AT = "AT",
  AZ = "AZ",
  BS = "BS",
  BH = "BH",
  BD = "BD",
  BB = "BB",
  BY = "BY",
  BE = "BE",
  BZ = "BZ",
  BJ = "BJ",
  BM = "BM",
  BT = "BT",
  BO = "BO",
  BQ = "BQ",
  BA = "BA",
  BW = "BW",
  BV = "BV",
  BR = "BR",
  IO = "IO",
  BN = "BN",
  BG = "BG",
  BF = "BF",
  BI = "BI",
  KH = "KH",
  CM = "CM",
  CA = "CA",
  CV = "CV",
  KY = "KY",
  CF = "CF",
  TD = "TD",
  CL = "CL",
  CN = "CN",
  CX = "CX",
  CC = "CC",
  CO = "CO",
  KM = "KM",
  CG = "CG",
  CD = "CD",
  CK = "CK",
  CR = "CR",
  CI = "CI",
  HR = "HR",
  CU = "CU",
  CW = "CW",
  CY = "CY",
  CZ = "CZ",
  DK = "DK",
  DJ = "DJ",
  DM = "DM",
  DO = "DO",
  EC = "EC",
  EG = "EG",
  SV = "SV",
  GQ = "GQ",
  ER = "ER",
  EE = "EE",
  ET = "ET",
  FK = "FK",
  FO = "FO",
  FJ = "FJ",
  FI = "FI",
  FR = "FR",
  GF = "GF",
  PF = "PF",
  TF = "TF",
  GA = "GA",
  GM = "GM",
  GE = "GE",
  DE = "DE",
  GH = "GH",
  GI = "GI",
  GR = "GR",
  GL = "GL",
  GD = "GD",
  GP = "GP",
  GU = "GU",
  GT = "GT",
  GG = "GG",
  GN = "GN",
  GW = "GW",
  GY = "GY",
  HT = "HT",
  HM = "HM",
  VA = "VA",
  HN = "HN",
  HK = "HK",
  HU = "HU",
  IS = "IS",
  IN = "IN",
  ID = "ID",
  IR = "IR",
  IQ = "IQ",
  IE = "IE",
  IM = "IM",
  IL = "IL",
  IT = "IT",
  JM = "JM",
  JP = "JP",
  JE = "JE",
  JO = "JO",
  KZ = "KZ",
  KE = "KE",
  KI = "KI",
  KR = "KR",
  KP = "KP",
  KW = "KW",
  KG = "KG",
  LA = "LA",
  LV = "LV",
  LB = "LB",
  LS = "LS",
  LR = "LR",
  LY = "LY",
  LI = "LI",
  LT = "LT",
  LU = "LU",
  MO = "MO",
  MK = "MK",
  MG = "MG",
  MW = "MW",
  MY = "MY",
  MV = "MV",
  ML = "ML",
  MT = "MT",
  MH = "MH",
  MQ = "MQ",
  MR = "MR",
  MU = "MU",
  YT = "YT",
  MX = "MX",
  FM = "FM",
  MD = "MD",
  MC = "MC",
  MN = "MN",
  ME = "ME",
  MS = "MS",
  MA = "MA",
  MZ = "MZ",
  MM = "MM",
  NA = "NA",
  NR = "NR",
  NP = "NP",
  NL = "NL",
  NC = "NC",
  NZ = "NZ",
  NI = "NI",
  NE = "NE",
  NG = "NG",
  NU = "NU",
  NF = "NF",
  MP = "MP",
  NO = "NO",
  OM = "OM",
  PK = "PK",
  PW = "PW",
  PS = "PS",
  PA = "PA",
  PG = "PG",
  PY = "PY",
  PE = "PE",
  PH = "PH",
  PN = "PN",
  PL = "PL",
  PT = "PT",
  PR = "PR",
  QA = "QA",
  RE = "RE",
  RO = "RO",
  RU = "RU",
  RW = "RW",
  BL = "BL",
  SH = "SH",
  KN = "KN",
  LC = "LC",
  MF = "MF",
  PM = "PM",
  VC = "VC",
  WS = "WS",
  SM = "SM",
  ST = "ST",
  SA = "SA",
  SN = "SN",
  RS = "RS",
  SC = "SC",
  SL = "SL",
  SG = "SG",
  SX = "SX",
  SK = "SK",
  SI = "SI",
  SB = "SB",
  SO = "SO",
  ZA = "ZA",
  GS = "GS",
  SS = "SS",
  ES = "ES",
  LK = "LK",
  SD = "SD",
  SR = "SR",
  SJ = "SJ",
  SZ = "SZ",
  SE = "SE",
  CH = "CH",
  SY = "SY",
  TW = "TW",
  TJ = "TJ",
  TZ = "TZ",
  TH = "TH",
  TL = "TL",
  TG = "TG",
  TK = "TK",
  TO = "TO",
  TT = "TT",
  TN = "TN",
  TR = "TR",
  TM = "TM",
  TC = "TC",
  TV = "TV",
  UG = "UG",
  UA = "UA",
  AE = "AE",
  GB = "GB",
  US = "US",
  UM = "UM",
  UY = "UY",
  UZ = "UZ",
  VU = "VU",
  VE = "VE",
  VN = "VN",
  VG = "VG",
  VI = "VI",
  WF = "WF",
  EH = "EH",
  YE = "YE",
  ZM = "ZM",
  ZW = "ZW",
}

export enum CountryLabelsEnum {
  AF = "Afgha,nistan",
  AX = "Aland,Islands",
  AL = "Albania",
  DZ = "Algeria",
  AS = "AmericanSamoa",
  AD = "Andorra",
  AO = "Angola",
  AI = "Anguilla",
  AQ = "Antarctica",
  AG = "AntiguaAndBarbuda",
  AR = "Argentina",
  AM = "Armenia",
  AW = "Aruba",
  AU = "Australia",
  AT = "Austria",
  AZ = "Azerbaijan",
  BS = "Bahamas",
  BH = "Bahrain",
  BD = "Bangladesh",
  BB = "Barbados",
  BY = "Belarus",
  BE = "Belgium",
  BZ = "Belize",
  BJ = "Benin",
  BM = "Bermuda",
  BT = "Bhutan",
  BO = "Bolivia",
  BQ = "BonaireSintEustatiusSaba",
  BA = "BosniaAndHerzegovina",
  BW = "Botswana",
  BV = "BouvetIsland",
  BR = "Brazil",
  IO = "BritishIndianOceanTerritory",
  BN = "BruneiDarussalam",
  BG = "Bulgaria",
  BF = "BurkinaFaso",
  BI = "Burundi",
  KH = "Cambodia",
  CM = "Cameroon",
  CA = "Canada",
  CV = "CapeVerde",
  KY = "CaymanIslands",
  CF = "CentralAfricanRepublic",
  TD = "Chad",
  CL = "Chile",
  CN = "China",
  CX = "ChristmasIsland",
  CC = "CocosKeelingIslands",
  CO = "Colombia",
  KM = "Comoros",
  CG = "Congo",
  CD = "CongoDemocraticRepublic",
  CK = "CookIslands",
  CR = "CostaRica",
  CI = "CoteDIvoire",
  HR = "Croatia",
  CU = "Cuba",
  CW = "Curaçao",
  CY = "Cyprus",
  CZ = "CzechRepublic",
  DK = "Denmark",
  DJ = "Djibouti",
  DM = "Dominica",
  DO = "DominicanRepublic",
  EC = "Ecuador",
  EG = "Egypt",
  SV = "ElSalvador",
  GQ = "EquatorialGuinea",
  ER = "Eritrea",
  EE = "Estonia",
  ET = "Ethiopia",
  FK = "FalklandIslands",
  FO = "FaroeIslands",
  FJ = "Fiji",
  FI = "Finland",
  FR = "France",
  GF = "FrenchGuiana",
  PF = "FrenchPolynesia",
  TF = "FrenchSouthernTerritories",
  GA = "Gabon",
  GM = "Gambia",
  GE = "Georgia",
  DE = "Germany",
  GH = "Ghana",
  GI = "Gibraltar",
  GR = "Greece",
  GL = "Greenland",
  GD = "Grenada",
  GP = "Guadeloupe",
  GU = "Guam",
  GT = "Guatemala",
  GG = "Guernsey",
  GN = "Guinea",
  GW = "GuineaBissau",
  GY = "Guyana",
  HT = "Haiti",
  HM = "HeardIslandMcdonaldIslands",
  VA = "HolySeeVaticanCityState",
  HN = "Honduras",
  HK = "HongKong",
  HU = "Hungary",
  IS = "Iceland",
  IN = "India",
  ID = "Indonesia",
  IR = "Iran",
  IQ = "Iraq",
  IE = "Ireland",
  IM = "IsleOfMan",
  IL = "Israel",
  IT = "Italy",
  JM = "Jamaica",
  JP = "Japan",
  JE = "Jersey",
  JO = "Jordan",
  KZ = "Kazakhstan",
  KE = "Kenya",
  KI = "Kiribati",
  KR = "Korea",
  KP = "KoreaDemocraticPeoplesRepublic",
  KW = "Kuwait",
  KG = "Kyrgyzstan",
  LA = "LaoPeoplesDemocraticRepublic",
  LV = "Latvia",
  LB = "Lebanon",
  LS = "Lesotho",
  LR = "Liberia",
  LY = "LibyanArabJamahiriya",
  LI = "Liechtenstein",
  LT = "Lithuania",
  LU = "Luxembourg",
  MO = "Macao",
  MK = "Macedonia",
  MG = "Madagascar",
  MW = "Malawi",
  MY = "Malaysia",
  MV = "Maldives",
  ML = "Mali",
  MT = "Malta",
  MH = "MarshallIslands",
  MQ = "Martinique",
  MR = "Mauritania",
  MU = "Mauritius",
  YT = "Mayotte",
  MX = "Mexico",
  FM = "Micronesia",
  MD = "Moldova",
  MC = "Monaco",
  MN = "Mongolia",
  ME = "Montenegro",
  MS = "Montserrat",
  MA = "Morocco",
  MZ = "Mozambique",
  MM = "Myanmar",
  NA = "Namibia",
  NR = "Nauru",
  NP = "Nepal",
  NL = "Netherlands",
  NC = "NewCaledonia",
  NZ = "NewZealand",
  NI = "Nicaragua",
  NE = "Niger",
  NG = "Nigeria",
  NU = "Niue",
  NF = "NorfolkIsland",
  MP = "NorthernMarianaIslands",
  NO = "Norway",
  OM = "Oman",
  PK = "Pakistan",
  PW = "Palau",
  PS = "PalestinianTerritory",
  PA = "Panama",
  PG = "PapuaNewGuinea",
  PY = "Paraguay",
  PE = "Peru",
  PH = "Philippines",
  PN = "Pitcairn",
  PL = "Poland",
  PT = "Portugal",
  PR = "PuertoRico",
  QA = "Qatar",
  RE = "Reunion",
  RO = "Romania",
  RU = "RussianFederation",
  RW = "Rwanda",
  BL = "SaintBarthelemy",
  SH = "SaintHelena",
  KN = "SaintKittsAndNevis",
  LC = "SaintLucia",
  MF = "SaintMartin",
  PM = "SaintPierreAndMiquelon",
  VC = "SaintVincentAndGrenadines",
  WS = "Samoa",
  SM = "SanMarino",
  ST = "SaoTomeAndPrincipe",
  SA = "SaudiArabia",
  SN = "Senegal",
  RS = "Serbia",
  SC = "Seychelles",
  SL = "SierraLeone",
  SG = "Singapore",
  SX = "SintMaarten",
  SK = "Slovakia",
  SI = "Slovenia",
  SB = "SolomonIslands",
  SO = "Somalia",
  ZA = "SouthAfrica",
  GS = "SouthGeorgiaAndSandwichIsl",
  SS = "SouthSudan",
  ES = "Spain",
  LK = "SriLanka",
  SD = "Sudan",
  SR = "Suriname",
  SJ = "SvalbardAndJanMayen",
  SZ = "Swaziland",
  SE = "Sweden",
  CH = "Switzerland",
  SY = "SyrianArabRepublic",
  TW = "Taiwan",
  TJ = "Tajikistan",
  TZ = "Tanzania",
  TH = "Thailand",
  TL = "TimorLeste",
  TG = "Togo",
  TK = "Tokelau",
  TO = "Tonga",
  TT = "TrinidadAndTobago",
  TN = "Tunisia",
  TR = "Turkey",
  TM = "Turkmenistan",
  TC = "TurksAndCaicosIslands",
  TV = "Tuvalu",
  UG = "Uganda",
  UA = "Ukraine",
  AE = "UnitedArabEmirates",
  GB = "UnitedKingdom",
  US = "UnitedStates",
  UM = "UnitedStatesOutlyingIslands",
  UY = "Uruguay",
  UZ = "Uzbekistan",
  VU = "Vanuatu",
  VE = "Venezuela",
  VN = "Vietnam",
  VG = "VirginIslandsBritish",
  VI = "VirginIslandsUS",
  WF = "WallisAndFutuna",
  EH = "WesternSahara",
  YE = "Yemen",
  ZM = "Zambia",
  ZW = "Zimbabwe",
}

export const CountryLabelsEnumSchema = z.nativeEnum(CountryLabelsEnum);
export const CountrySymbolsEnumSchema = z.nativeEnum(CountrySymbolsEnum);
