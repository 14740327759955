<script setup lang="ts">
import type { ComputedRef, Ref } from "vue";
import { inject, ref, shallowRef, watch } from "vue";
import { onClickOutside } from "@vueuse/core";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiCheck as checkIcon } from "@mdi/js";
import { useI18n } from "vue-i18n";
import { includes as include, uniqBy } from "lodash";
import { getProjectPerson } from "@/http_services/entities/persons";
import { Button2 as Button } from "@/components/button";
import { Loading } from "@/components/loading";
import { Modal } from "@/components/modal";
import type { Person } from "@/schemas/person";

interface AddProject {
  id: string
  is_added_in_mapping: boolean
  project_name: string
  serial: number
}

const props = defineProps<{
  selected: Person
}>();
const emit = defineEmits(["update:selected"]);

const { t } = useI18n({ useScope: "global" });

const isSidebarOpen = inject("isSidebarOpen") as ComputedRef<boolean>;
const collapsed = ref(false);
const searchRef = ref(null);
const termToSearch = ref("");
const projectsList = shallowRef([]) as Ref<AddProject[]>;
const selectedProjects = shallowRef([]) as Ref<AddProject[]>;
const totalFiltered = ref(0);
const emitted = ref(false);
const loading = ref(true);
const loadError = ref(false);
const emptySearch = ref(false);
const hasSubmition = ref(false);
const gettedProjects = ref([]) as Ref<AddProject[]>;
const select = ref([]) as Ref<AddProject[]>;

const {
  mutate: addCandidateToMapping,
} = useCandidateToMapping();
function handleCollapse() {
  collapsed.value = !collapsed.value;
}

function clearSelected() {
  selectedProjects.value = [];
  emit("update:selected", []);
}
function onSelect(id: never, project: AddProject) {
  const check = include(
    projectsList.value.filter(i => !i.is_added_in_mapping),
    project,
  );
  if (check) {
    project.is_added_in_mapping = !project.is_added_in_mapping;
    select.value = uniqBy([...select.value, project], e => e._id);
    if (selectedProjects.value.includes(id)) {
      selectedProjects.value = selectedProjects.value.filter(
        selected_id => selected_id !== id,
      );
      return;
    }
  }
  selectedProjects.value = selectedProjects.value.concat([id]);
}

function onConfirm() {
  emit("update:selected", props.selected.id);
  emitted.value = true;
  const length = ref(0);
  while (length.value < selectedProjects.value.length) {
    // postCandidate(selectedProjects.value[length.value].toString());
    addCandidateToMapping({
      candidate: {
        project_id: selectedProjects.value[length.value].toString(),
        person_id: props.selected.id,
      },
    });
    length.value++;
  }
  hasSubmition.value = false;
  collapsed.value = false;
  select.value = [];
}

const paginationParams = ref({
  limit: 10,
  skip: gettedProjects.value.length,
  search_ground: termToSearch.value,
});

async function getProjects() {
  try {
    emptySearch.value = false;
    loadError.value = false;
    loading.value = true;
    const { data: person, total } = await getProjectPerson(
      paginationParams.value,
      props.selected.id,
    );
    totalFiltered.value = total;
    gettedProjects.value = person;
    projectsList.value = uniqBy([...projectsList.value, ...person], e => e._id);
    if (projectsList.value.length === 0)
      emptySearch.value = true;
  }
  catch (error) {
    loadError.value = true;
  }
  finally {
    loading.value = false;
  }
}

async function handleScroll(event: Event) {
  const {
    scrollTop: hiddenHeight,
    scrollHeight: totalHeight,
    clientHeight: totalVisible,
  } = event.target as HTMLElement;
  const bottomReached = hiddenHeight + totalVisible + 1;
  if (Math.round(bottomReached) >= Math.round(totalHeight)) {
    paginationParams.value.skip = projectsList.value.length;
    await getProjects();
  }
}

function newSearch() {
  projectsList.value = [];
  emitted.value = false;
  paginationParams.value.search_ground = termToSearch.value;
  if (termToSearch.value.length > 0)
    getProjects();
}

onClickOutside(searchRef, () => {
  if (collapsed.value) {
    handleCollapse();
    if (!emitted.value) {
      emit("update:selected", selectedProjects.value);
      emitted.value = true;
    }
  }
  paginationParams.value.skip = 0;
});
watch(isSidebarOpen, () => {
  collapsed.value = false;
});

watch(collapsed, () => {
  if (collapsed.value === false) {
    projectsList.value = [];
    termToSearch.value = "";
  }
  else {
    getProjects();
  }
});
</script>

<template>
  <div>
    <div class="search-wrapper">
      <div class="d-flex-center">
        <Button
          class="person-card__info__header__button"
          variation="secondary"
          @click="handleCollapse"
        >
          {{ t("persons.addToProject") }}
        </Button>

        <span
          v-if="Object.keys(selected).length"
          :tooltip="t('persons.addToProjectBtn.remove')"
          max-content
          role="button"
          @click="clearSelected()"
        />
      </div>
      <transition ref="searchRef" name="collapse">
        <div v-show="collapsed" class="dropdown-wrapper" :class="{ collapsed }">
          <div class="item search">
            <input
              v-model="termToSearch"
              class="plain-input"
              :placeholder="`_${t('persons.searchProject').toUpperCase()}`"
              @keyup="newSearch"
            >
          </div>
          <div
            class="results"
            :style="{
              'overflow-y': totalFiltered > gettedProjects.length ? 'scroll' : 'auto',
            }"
            @scroll="handleScroll($event)"
          >
            <div
              v-for="(project, index) in projectsList"
              v-show="!loading"
              :key="index"
              class="item"
              :class="{
                selected: selectedProjects.includes(project.project_name),
              }"
              :title="project.project_name"
              @click="onSelect(project._id, project)"
            >
              <div class="ellipis-text">
                ({{ `${project.serial || ""}` }})
                {{ `${project.project_name || ""}` }}
              </div>

              <div v-if="project.is_added_in_mapping">
                <SvgIcon id="check-icon" type="mdi" :path="checkIcon" />
              </div>
            </div>
            <span v-if="loadError" class="message center">
              {{ t("persons.errorListProjects") }}
            </span>
            <span v-if="emptySearch" class="message center">
              {{ t("persons.notFoundProjectsSearch") }}
            </span>
            <transition name="fade">
              <div
                v-show="loading"
                class="loading"
                style="display: flex; justify-content: center"
              >
                <Loading :spin-time="2" />
              </div>
            </transition>
          </div>
          <button
            type="button"
            class="regular-button button-add"
            :disabled="!selectedProjects"
            @click="hasSubmition = !hasSubmition"
          >
            {{ t("persons.add") }}
          </button>
        </div>
      </transition>
    </div>
    <Modal
      v-if="hasSubmition && select.length"
      :title="t('persons.addToProjectBtn.title')"
      :button-text="t('persons.addToProjectBtn.confirm')"
      :secondary-button-text="t('projects.details.activities.form.labels.cancelRemove')"
      secondary-variation="light"
      :on-click="onConfirm"
      :on-close="() => ((hasSubmition = false), (select = []))"
      :secondary-on-click="() => ((hasSubmition = false), (select = []))"
    >
      <template #message>
        <div>
          <p v-for="(project, index) of select" :key="index" class="project-message">
            <span class="project-serial">- {{ t("header.project") }} {{ project.serial }}</span>
            <span class="project-name">{{ project.project_name }}</span>
          </p>
        </div>
        <p class="message">
          {{ t("persons.addToProjectBtn.message") }}
        </p>
      </template>
    </Modal>
  </div>
</template>

<style lang="scss" scoped>
$border-radius: 0.5rem;

* {
  font-family: "Open Sans", sans-serif;
}
.ellipis-text {
  line-height: 1.3;
}
:deep(.modal-container) .modal-content {
  .modal-title {
    font-size: 1.25rem;
    font-weight: bold;
  }

  .modal-message {
    line-height: 1.2;
    white-space: pre-wrap;

    & div {
      width: min-content;
      margin: 0 auto;
    }

    .project-message {
      width: max-content;
    }
  }
}

:deep(.vfm__content.modal-content) {
  width: 35vw;
  text-align: center;

  .buttons {
    gap: 8rem;
  }
}

.message {
  margin-block-start: 0.5rem;
  margin-inline: 1rem;
  text-align: center;
}

.project-serial {
  color: $red-violet;
  font-weight: bold;
  line-height: 1;
}

.project-name {
  &::before {
    display: inline-block;
    color: $red-violet;
    content: " - ";
  }
}

@keyframes slide {
  from {
    width: 100%;
  }

  to {
    width: 25rem;
  }
}

.center {
  text-align: center;
}

.collapse-enter-active,
.collapse-leave-active {
  animation-duration: 0.5s;
  animation-name: slide;
}

.collapse-leave-active {
  animation-direction: reverse;
}

.d-flex-center {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;

  svg {
    z-index: 100;
    color: $gray-low;
    vertical-align: middle;
  }
}

.search-wrapper {
  position: relative;
  width: 100%;
  transition: all linear 250ms;

  .button-add {
    z-index: 100;
    background: white;
    font-family: "Open Sans", sans-serif;
    font-size: 0.75rem;
  }

  .dropdown-wrapper {
    position: absolute;
    z-index: 100;
    top: 0;
    right: 0;
    width: 100%;
    border-radius: $border-radius;
    box-shadow: 0 3px 6px #00000029;
    &.collapsed {
      width: 25rem;
    }

    .item {
      display: flex;
      height: 2.5rem;
      max-height: 2.5rem;
      align-items: center;
      justify-content: space-between;
      padding-right: 0.9375rem;
      padding-left: 0.625rem;
      color: $gray-dark;
      font-size: 0.9375rem;

      .ellipis-text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &:hover,
      .ellipis-text:hover,
      &.selected {
        color: $red;
        cursor: pointer;
        font-weight: bold;
      }

      svg {
        color: $green;
      }
    }

    .search {
      height: 2.275rem;
      border-radius: $border-radius $border-radius 0 0;
      background-color: $yellow-dark;
      cursor: auto;
    }
  }
}

.person-card__info__header__button-restrict {
  z-index: 100;
  display: flex;
  width: 9.0625rem;
  min-width: 11.25rem;
  box-sizing: border-box;
  align-self: start;
  justify-content: center;
  padding: 0.7rem 1rem;
  border: 1px solid $gray-dark;
  border-radius: $border-radius;
  background: white;
  background-color: $gray-dark;
  color: #fff;
  font-size: 0.75rem;
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    background-color: #fff;
    color: $gray-dark;
  }
}

.plain-input {
  display: block;
  width: 100%;
  height: 2.275rem;
  padding: 0;
  border: solid transparent;
  border-width: 0;
  border-radius: $border-radius $border-radius 0 0;
  margin-bottom: 0;
  background-color: transparent;
  color: #fff;
  font-family: "Open Sans", sans-serif;

  &::-ms-input-placeholder {
    color: #fff;
  }

  &:focus-visible,
  &:focus,
  &:focus:not(:focus-visible) {
    outline: none;
  }

  &::placeholder {
    color: #fff;
  }
}

.results {
  z-index: 100;
  height: 8.125rem;
  background: white;
  overflow-x: hidden;
  overflow-y: auto;
  padding-block: 0;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 1.25rem;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    min-height: 3rem;
    border: 0.3125rem solid transparent;
    border-radius: 2rem;
    background: #888;
    background-clip: content-box;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
    background-clip: content-box;
  }
}

.regular-button.button-add {
  width: 100%;
  border-radius: 0 0 $border-radius $border-radius;

  &:hover {
    background-color: $yellow;
    color: #fff;
  }
}

.regular-button.button-add[disabled] {
  border-color: $gray-light;
  color: $gray-light;
  cursor: not-allowed;
}

.message {
  display: block;
  padding: 0.625rem 0;
}
</style>
