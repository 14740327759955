<script setup lang="ts">
import { useQueryClient } from "@tanstack/vue-query";
import { mdiChevronLeft } from "@mdi/js";
import ProjectHeaderForm from "@/common/components/ProjectHeaderForm.vue";
import ProjectCardEditable from "@/common/components/ProjectCardEditable.vue";
import { SvgIcon } from "@/components/icon";

const props = defineProps<{ id: string }>();
const router = useRouter();

const queryClient = useQueryClient();

queryClient.prefetchQuery({
  queryKey: ["projects", "construction_organization", props.id],
  queryFn: () => getConstructionOrganization(props.id),
});

const { data: project, isLoading: loading } = useProject(props.id);

const editing = ref(false);
const { t } = useI18n();

function goStage(stage: string) {
  router.push(`/projects/${props.id}/${stage}/activities`);
}

function goProjects() {
  router.push("/projects");
}
</script>

<template>
  <div v-if="project" class="stages-container">
    <div v-if="editing" style="margin-bottom: 2rem">
      <ProjectCardEditable @cancel-edit="editing = !editing" />
    </div>
    <div v-else>
      <div class="back-to-list" @click="goProjects">
        <SvgIcon type="mdi" :icon="mdiChevronLeft" width="16" height="16" />
        <span>{{ t("text.backToList") }}</span>
      </div>
      <ProjectHeaderForm @edit-project="editing = !editing" />
    </div>
    <Timeline
      icon-size="60"
      card-size="105"
      float-label
      :stages="project.stages"
      :active-stage="router.currentRoute.value.meta.stage"
      @click:stage="goStage($event)"
    />
  </div>
</template>

<style scoped lang="scss">
.stages-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-block-start: 6.875rem;
}

.project-stages {
  display: flex;
  gap: 2rem;
  margin: 2rem 0;

  >div {
    padding: 1rem;
    border: 1px solid;
  }
}

h3 {
  padding: 0;
  margin: 0;
}

.back-to-list {
  font-size: 0.625rem;
  font-weight: 600;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;

  span {
    vertical-align: super;
  }

  &:hover {
    span {
      color: lighten($gray-dark, 20%);
    }
  }
}
</style>
