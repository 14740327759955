<script setup lang="ts">
import * as slider from "@zag-js/slider";
import { normalizeProps, useMachine } from "@zag-js/vue";
import bson from "bson-objectid";

type valueMapKey = keyof typeof valueMap;

const props = withDefaults(defineProps<{
  modelValue: valueMapKey | undefined
  markerColor?: string // TODO: add option to change colors
  trackColor?: string
  backgroundColor?: string
  offsets?: any
  disabled?: boolean
}>(), {
  offsets: {
    0: 0,
    1: 5,
    2: 35,
    3: 65,
    4: 96.85,
  },
  markerColor: "#000",
  trackColor: "#a8a8a8",
  backgroundColor: "#666",
});

const emit = defineEmits(["update:modelValue"]);

const valueMapValues: { [key: number]: valueMapKey } = {
  0: "EMPTY",
  1: "LOW",
  2: "AVERAGE",
  3: "HIGH",
  4: "VERY_HIGH",
};
const valueMap = {
  EMPTY: 0,
  LOW: 1,
  AVERAGE: 2,
  HIGH: 3,
  VERY_HIGH: 4,
};

const [state, send] = useMachine(slider.machine({
  id: bson().toHexString(),
  min: 0,
  max: 4,
  step: 1,
  value: props.modelValue ? valueMap[props.modelValue] : 0,
  onChange,
}));

const api = computed(() => slider.connect(state.value, send, normalizeProps));

const currentValue = ref(props.modelValue ? valueMap[props.modelValue] : 0);
function onChange(v: { value: number }) {
  if (props.disabled)
    return;

  currentValue.value = v.value;
  emit("update:modelValue", valueMapValues[v.value]);
}

const currentOffset = computed(() => {
  if (currentValue.value === 4)
    return null;

  return props.offsets[currentValue.value];
});
</script>

<template>
  <div>
    <div v-bind="api.controlProps">
      <div v-bind="api.trackProps" :data-disabled="disabled">
        <div v-bind="api.rangeProps" :style="`width: ${(currentValue === 4) ? '100%' : `calc(${currentOffset}% + ${currentValue === 0 ? '0px' : '1.25rem'})`}`" />
      </div>
      <div v-bind="api.thumbProps" :data-disabled="disabled" :style="`background: white; left: ${(currentValue === 4) ? `calc(100% - 1.25rem)` : `${currentOffset}%`};`">
        <input v-bind="api.hiddenInputProps">
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
* {
  font-size: 0.875rem;
}

[data-part='thumb'] {
  background: blue;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 999px;
  border: 1px solid $gray-border;
  cursor: pointer;
  z-index: 2;
}

[data-part='thumb']:hover:not([data-disabled]),
[data-part='thumb']:hover,
[data-part='thumb']:focus-visible,
[data-part='thumb']:active {
  outline: 1px solid #2d2d2d;
  box-shadow: 0 3px 6px #00000029;
}

[data-part='range'] {
  background: v-bind("backgroundColor");
  height: 1.25rem;
  border-radius: 1.25rem;
}

[data-part='track'] {
  background: v-bind("trackColor");
  height: 1.25rem;
  flex: 1;
  border-radius: 1.25rem;
  cursor: pointer;
}

[data-part='control'] {
  position: relative;
  align-items: center;
  display: flex;
}

.custom-marker[data-part='marker-group'] {
  margin-top: -10px;
  color: black;
  z-index: 2;

  [data-part=marker] {
    color: #2d2d2d;
  }

}

.label-marker[data-part='marker-group'] {
  margin-top: 1.75rem;
}

[data-part='marker-group'] span {
  display: block;
}

[data-part='marker'] {
  color: #666;
}

[data-part=track][data-disabled="true"],
[data-part=range][data-disabled="true"],
[data-part=thumb][data-disabled="true"] {
  cursor: not-allowed;
}
</style>
