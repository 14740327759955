import debounce from "lodash/debounce";
import some from "lodash/some";
import uniqBy from "lodash/uniqBy";

import type { Router } from "vue-router";
import type { Corporation } from "@/entities/corporation";
import type { PaginationParams } from "@/http_services/types";
import { searchCorporation } from "@/http_services/entities/corporation";

type AsyncFunction<O> = () => Promise<O>;

interface requestMethodReturn {
  loading: Ref<boolean>
  corporations: Ref<Corporation[]>
  termToSearch: Ref<undefined | string>
  loadError: Ref<boolean>
  paginationParams: ComputedRef<PaginationParams>
  emptySearch: ComputedRef<boolean>
  fetchCompanies: AsyncFunction<void>
  resetPage: () => void
  fetchOnSort: (value: string | Array<string>) => void
  clearParams: () => void
  fetchOnScroll: (elementRef: Ref<HTMLElement | null>) => void
  fetchOnKeyPress: (searchTerm: string) => void
  fetchOnFilter: ([filterType, values]: [string, string[]]) => void
  filters: Ref<{ [key: string]: any }>
  searchTotal: Ref<number | null>
  allCorporationsLength: Ref<number>
  limitPagination: Ref<number | 0>
  searchText: Ref<string>
  isSearching: Ref<boolean>
  isWait: Ref<boolean>
}
export function provideCardClickEvents(router: Router): void {
  const onClickDetails = (corporation: Corporation) => {
    router.push({ name: "CorporationDetails", params: { id: corporation.id } });
  };
  const onClickEdit = (corporation: Corporation) => corporation;

  provide("onClickDetails", onClickDetails);
  provide("onClickEdit", onClickEdit);
}

export function corporationApiMethods(): requestMethodReturn {
  const loading = ref<boolean>(false);
  const corporations = ref<Corporation[]>([]);
  const termToSearch = ref<undefined | string>(undefined);
  const loadError = ref<boolean>(false);
  const emptySearch = computed(() => !corporations.value.length && !loading.value);
  const searchTotal = ref(0);
  const limitPagination = ref(10);
  const page = ref(0);
  const isWait = ref(false);
  const allCorporationsLength = ref(0);
  const isSearching = ref(false);
  const paginationParams = computed(() => ({
    limit: limitPagination.value,
    skip: corporations.value.length,
    sort: ["inserted_at"],
    search_ground: termToSearch.value,
  }));
  const filters: Ref<{ [key: string]: any }> = ref({});

  const searchText = ref("");
  const searchFinish = ref(false);

  const fetchCompanies = async () => {
    try {
      loadError.value = false;
      loading.value = true;
      paginationParams.value.search_ground = searchText.value;
      const { total: length } = await
      searchCorporation({ ...paginationParams.value });
      const response = await searchCorporation({ ...paginationParams.value, ...filters.value });
      const { data, total } = response;
      searchTotal.value = total;
      allCorporationsLength.value = length;

      corporations.value = uniqBy([...corporations.value, ...data], e => e.id);

      isSearching.value = searchText.value?.length > 0 || some(filters.value);

      if (data.length === 0)
        searchFinish.value = true;

      setTimeout(() => {
        if (data.length > 0) {
          page.value += 1;
          searchFinish.value = false;
        }
        isWait.value = false;
      }, 250);
    }
    catch (error) {
      loadError.value = true;
      isWait.value = false;
    }
    finally {
      loading.value = false;
      isWait.value = false;
    }
  };

  const debounceFetchCompanies = debounce(fetchCompanies, 500);

  const resetPage = () => {
    page.value = 0;
    paginationParams.value.skip = 0;
    corporations.value = [];
  };

  const fetchOnSort = (value) => {
    resetPage();
    paginationParams.value.sort = [value];
    fetchCompanies();
  };

  const clearParams = () => {
    paginationParams.value.sort = ["inserted_at"];
    paginationParams.value.limit = limitPagination.value;
    paginationParams.value.skip = 0;
    paginationParams.value.search_ground = "";
    fetchCompanies();
  };

  const fetchOnScroll = (elementRef: Ref<HTMLElement | null>) => {
    const { arrivedState } = useScroll(elementRef);
    const { bottom } = toRefs(arrivedState);

    watch(bottom, (value) => {
      if (value && !searchFinish.value) {
        page.value += 1;
        fetchCompanies();
      }
    });
  };

  const fetchOnKeyPress = (searchTerm: string) => {
    resetPage();
    searchText.value = searchTerm;
    filters.value.name = searchTerm;
    fetchCompanies();
  };

  const fetchOnFilter = ([filterType, values]: [string, string[]]): void => {
    if (filterType === "revenue")
      Object.keys(values).forEach(key => (filters.value[`${filterType}_${key}`] = values[key]));
    else
      filters.value[filterType] = Array.isArray(values) ? values.join(";") : values;

    loading.value = true;
    resetPage();
    debounceFetchCompanies();
  };

  return {
    loading,
    corporations,
    termToSearch,
    emptySearch,
    isWait,
    loadError,
    paginationParams,
    allCorporationsLength,
    fetchCompanies,
    fetchOnSort,
    clearParams,
    fetchOnScroll,
    fetchOnKeyPress,
    fetchOnFilter,
    resetPage,
    filters,
    searchTotal,
    limitPagination,
    searchText,
    isSearching,
  };
}
