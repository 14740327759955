<script lang="ts">
import type { Ref } from "vue";
import { defineComponent, inject } from "vue";
import { useI18n } from "vue-i18n";
import ExtraActivities from "../PersonDetail/ExtraActivities.vue";
import { SectionTitle } from "@/components/section_title";
import type { Person } from "@/http_services/entities/types";
import { SectionContent } from "@/components/section_content";

export default defineComponent({
  name: "ExtraActivitiesTab",
  components: {
    SectionTitle,
    SectionContent,
    ExtraActivities,
  },
  setup() {
    const { t } = useI18n();
    const person = inject("person") as Ref<Person>;

    return {
      t,
      person,
    };
  },
});
</script>

<template>
  <div v-if="person" class="tab-container">
    <SectionTitle :title="t('persons.details.extraActivities')" />
    <SectionContent>
      <ExtraActivities />
    </SectionContent>
  </div>
</template>

<style lang="scss" scoped>
.tab-section {
  margin-bottom: 2.5rem;

  &.--large-spacing {
    margin-bottom: 3rem;
  }
}
</style>
