<script lang="ts">
import type { Ref } from "vue";
import { computed, defineComponent, inject, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { mdiSquareEditOutline } from "@mdi/js";
import { Avatar } from "@/components/avatar";
import { Card } from "@/components/card";
import RefreshIcon from "@/assets/images/refresh_icon.svg";
import LinkedinIcon from "@/assets/images/linkedin_icon.svg";
import LinkedinIconBlue from "@/assets/images/linkedin_icon_blue.svg";
import FacebookIcon from "@/assets/images/facebook_icon.svg";
import FacebookIconBlue from "@/assets/images/facebook_icon_blue.svg";
import type { Corporation } from "@/entities/corporation";
import { completeDate } from "@/utils/dates";
import { parsePhone } from "@/utils/parsers";
import EditIcon from "@/assets/images/edit_icon.svg";

import type { baseEducationalOrganization } from "@/schemas/corporation";

export default defineComponent({
  name: "CorporationHeader",
  components: {
    Avatar,
    Card,
    RefreshIcon,
    LinkedinIcon,
    LinkedinIconBlue,
    EditIcon,
    FacebookIcon,
    FacebookIconBlue,
  },
  props: {
    EducationalOrganization: {
      type: Object as Ref<baseEducationalOrganization>,
      default: null,
    },
    isEducational: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["edit:corporation"],
  setup(props, { emit }) {
    const { t, tm } = useI18n();

    const corporation = inject("corporation") as Ref<Corporation>;

    const educationalOrganization = ref(null) as Ref<baseEducationalOrganization>;
    const isEducational = ref(false);

    const educationalOrganizationTypes = computed(() =>
      Object.entries(tm("corporations.enums.educationalOrganizationTypes")).map(
        ([value, label]) => ({ label, value }),
      ));

    const getEducationalOrganizationType = (type: string) => {
      const [result] = educationalOrganizationTypes.value.filter(data => data.value === type);
      return result?.label || "";
    };

    const hoveredLinkedin = ref(false);
    const hoveredFacebook = ref(false);

    const corporationInfo = computed(() => {
      const map = new Map();
      map.set(
        t("corporations.contact"),
        parsePhone(corporation.value.phone?.calling_code, corporation.value.phone?.number),
      );
      map.set(
        t("corporations.location"),
        [corporation.value?.address?.name].filter(v => Boolean(v)).join(" - "),
      );
      map.set(t("corporations.domain"), corporation.value.url);
      return map;
    });

    const socialNetworkClick = (url: string) => {
      window.open(url, "_blank");
    };
    const replaceUrlHttp = (url: string) => url.replace(/\/\/|.+\/\//, "");
    const editCorporation = () => {
      emit("edit:corporation");
    };

    watch(props, (value) => {
      educationalOrganization.value = value.EducationalOrganization as baseEducationalOrganization;
      isEducational.value = value.isEducational;
    });

    return {
      t,
      corporationInfo,
      edit: mdiSquareEditOutline,
      hoveredLinkedin,
      hoveredFacebook,
      socialNetworkClick,
      replaceUrlHttp,
      completeDate,
      corporation,
      editCorporation,
      isEducational,
      educationalOrganization,
      educationalOrganizationTypes,
      getEducationalOrganizationType,
    };
  },
});
</script>

<template>
  <Card class="company-card">
    <div class="company-card__avatar-section">
      <span class="avatar">
        <span role="button" class="avatar__brand">
          <Avatar
            :src="corporation.avatar_url"
            :alt="corporation.name"
            size="140px"
            placeholder="general-company"
            type="native"
            :has-alternative-icon="false"
          />
        </span>
      </span>
      <span class="updated">
        <span>
          <p class="company-name highlight">{{ corporation.name }}</p>
          <small>({{ corporation.serial }})</small>
        </span>
        <div class="last-updated">
          <RefreshIcon class="refresh-icon" />
          <span>{{ completeDate(corporation.updated_at || corporation.inserted_at) }}</span>
        </div>
      </span>
    </div>

    <div class="company-card__details-section">
      <div class="details-content">
        <span role="button" aria-label="edit">
          <EditIcon class="edit-icon" @click="editCorporation" />
        </span>
        <p v-for="[key, value] in corporationInfo" :key="key" class="company-info">
          <span class="highlight">{{ key }}:</span>&#160;
          <a
            v-if="value.match('http')"
            :key="key"
            :href="value"
            target="_blank"
          >{{ replaceUrlHttp(value) || value }}</a>
          <span v-else>{{ value }}</span>
        </p>
        <p class="company-info">
          <span class="highlight">{{ t("corporations.socialNetworks") }}:</span>
          <LinkedinIcon
            v-if="corporation.linkedin_url"
            v-show="!hoveredLinkedin"
            class="social-icon"
            @mouseenter="hoveredLinkedin = true"
          />
          <LinkedinIconBlue
            v-if="corporation.linkedin_url"
            v-show="hoveredLinkedin"
            class="social-icon"
            @mouseleave="hoveredLinkedin = false"
            @click="socialNetworkClick(corporation.linkedin_url)"
          />
          <FacebookIcon
            v-if="corporation.facebook_url"
            v-show="!hoveredFacebook"
            class="social-icon"
            @mouseenter="hoveredFacebook = true"
          />
          <FacebookIconBlue
            v-if="corporation.facebook_url"
            v-show="hoveredFacebook"
            class="social-icon"
            @mouseleave="hoveredFacebook = false"
            @click="socialNetworkClick(corporation.facebook_url)"
          />
        </p>
        <p v-if="isEducational" class="company-info">
          <span class="highlight">
            {{ t("corporations.isEducationalOrganization") }}:
          </span>
          <span>
            {{
              getEducationalOrganizationType(educationalOrganization?.corporation_type || "")
            }}
          </span>
        </p>
      </div>
    </div>
  </Card>
</template>

<style lang="scss" scoped>
.company-card {
  --size: 1.2em;

  display: flex;
  padding: 2rem;
  margin-bottom: 2rem;

  .highlight {
    font-weight: bold;
  }

  &__avatar-section {
    display: flex;
    flex: 1 1 0;
    align-items: center;

    .avatar {
      &__brand span {
        color: $gray-low;
      }
    }

    .updated {
      display: flex;
      height: 100px;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 1rem;
      color: $gray-dark;

      p {
        display: inline;
      }

      small {
        margin-left: 1ch;
        font-size: 0.75rem;
      }

      .company-name {
        font-size: 1.125rem;
      }

      .last-updated {
        display: flex;
        align-items: center;
        font-size: 0.75rem;
      }

      .refresh-icon {
        width: var(--size);
        height: var(--size);
        margin-right: 0.5rem;
        stroke: $gray-low;
      }
    }
  }

  &__details-section {
    position: relative;
    display: flex;
    flex: 1 1 0;
    color: $gray-dark;

    .edit-area {
      position: absolute;
      top: -10px;
      right: 0;
    }

    .details-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: 16px;

      .social-icon {
        position: relative;
        top: 3px;
        width: var(--size);
        height: var(--size);
        margin-left: 1ch;
        cursor: pointer;
      }
    }
  }

  .edit-icon {
    position: absolute;
    top: 0;
    right: 0;
    width: 14px;
    stroke: #ccc;
  }
}
</style>
