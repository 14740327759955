<script lang="ts" setup>
import SvgIcon from "@/components/icon/Icon.vue";

const props = defineProps({
  projectId: { type: String, required: true },
});
const i18n = useI18n();

const { data: project, isLoading } = useProject(String(props.projectId));

const projectIdUrl = computed(() => (`/projects/${props.projectId}/construction/activities`));
</script>

<template>
  <header v-if="!isLoading" class="header-validation">
    <div class="information">
      <div class="logo">
        <SvgIcon icon="imua_only" width="100" height="2rem" />
      </div>

      <div class="project">
        <PersonAvatar class="avatar" :name="project.project_type" size="2.5rem" />
        <strong v-text="project.position_name" />
        <small v-text="`(${project.serial})`" />
      </div>

      <div>
        <strong v-text="i18n.t('projects.validation.header.company').concat(': ')" />
        <span v-text="project.corporation.corporation_name" />
      </div>
    </div>
    <div class="actions">
      <RouterLink :to="projectIdUrl">
        <SvgIcon
          width="26"
          height="26"
          icon="square_x_icon"
          class="close-header"
          :title="i18n.t('projects.validation.header.close')"
        />
      </RouterLink>
    </div>
  </header>
</template>

<style scoped lang="scss">
.header-validation {
  position: relative;
  border-bottom: $gray-border solid 1px;
  z-index: 1;
  height: 106px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: $white-full;
}

.information {
  display: flex;
  align-items: center;
  gap: 5rem;

  :deep(.avatar) {
    background: $red-violet;

    span {
      color: $white-full;
    }
  }

  .logo {
    align-self: center;
    display: flex;
    color: $red-violet;
  }

  .project {
    display: flex;
    align-items: center;
    gap: .5rem;

    small {
      font-weight: lighter;
    }
  }
}

.actions {
  display: flex;
  gap: 1rem;

  button {
    padding: .75rem 1em;
    width: 8rem;
    font-size: .7rem;

    &.btn-apply {
      background: $red-violet;

      &:hover {
        background: $red-violet-medium;
      }

      span {
        color: $white-full;
      }
    }
  }
}

.close-header:hover {
  color: $red-violet;
}
</style>
