<script lang="ts">
import type { PropType } from "vue";
import { computed, defineComponent, ref, toRefs } from "vue";
import { useI18n } from "vue-i18n";
import { mdiChevronDown, mdiPlusCircleOutline } from "@mdi/js";
import SvgIcon from "@jamescoyle/vue-icon";
import SimpleMaskMoney from "simple-mask-money";
import { cloneDeep, toNumber } from "lodash";

import TrashIcon from "@/assets/images/trash_icon.svg";
import { SectionContent } from "@/components/section_content";
import { Button2, ButtonIcon } from "@/components/button";
import { FormInput, Select } from "@/components/inputs/modalInputs";
import EditIcon from "@/assets/images/edit_icon.svg";
import { formatCurrencyFromValue } from "@/utils/parsers";
import { moneyCurrencies } from "@/utils/adapters/form";
import type { Corporation } from "@/entities/corporation";
import { CURRENCY } from "@/entities/common";

export default defineComponent({
  name: "DetailsSection",
  components: {
    SectionContent,
    Select,
    FormInput,
    Button: Button2,
    ButtonIcon,
    EditIcon,
    TrashIcon,
    SvgIcon,
  },
  props: {
    employeesNumber: String,
    annualRevenue: {
      type: Object as PropType<Corporation["revenues"]>,
    },
    netWorth: {
      type: Object as PropType<Corporation["patrimony"]>,
    },
    isEditing: Boolean,
    noEdit: Boolean,
  },
  emits: ["click:edit", "click:editCancel", "submit:details"],
  setup(props, { emit }) {
    const { t, tm } = useI18n();
    const editing = ref("");
    const isOpen = ref(false);
    const { annualRevenue, employeesNumber } = toRefs(props);

    const employeesOptions = Object.entries(tm("corporations.employees.enum")).map(
      ([value, label]) => ({ label, value }),
    );

    const netWorthPayload = ref({
      amount: props.netWorth?.amount || 0,
      currency: props.netWorth?.currency || CURRENCY.BRL,
    });

    const annualRevenuePayload = ref(
      cloneDeep(annualRevenue.value) || [
        {
          year: `${new Date().getFullYear()}`,
          amount: 0,
          currency: CURRENCY.BRL,
        },
      ],
    );

    const employees = ref(employeesNumber.value);

    const openDropdown = () => {
      isOpen.value = !isOpen.value;
    };

    const revenuesFormatted = computed(() => {
      if (annualRevenue.value) {
        return annualRevenue.value.map(
          ({ currency, year, amount }) => `${
              year ? new Date("01/01/".concat(year)).getFullYear() : ""
            } - ${formatCurrencyFromValue(SimpleMaskMoney.formatToNumber(amount), currency)}`,
        );
      }
      return [];
    });

    const appendRevenue = () => {
      const year = annualRevenuePayload.value.at(-1)?.year;

      annualRevenuePayload.value.push({
        currency: CURRENCY.BRL,
        amount: 0,
        year: `${year ? toNumber(year) - 1 : new Date().getFullYear()}`,
      });
    };

    const removeRevenue = (index: number) => {
      annualRevenuePayload.value.splice(index, 1);
    };

    const parseRevenuePayload = () => ({
      revenues: annualRevenuePayload.value.map(revenue => ({
        ...revenue,
        amount: SimpleMaskMoney.formatToNumber(revenue.amount) as number,
      })),
    });

    const editSection = (section: string) => {
      isOpen.value = false;
      emit("click:edit");
      editing.value = section;
    };

    const parseNetWorthPayload = () => ({
      patrimony: {
        ...netWorthPayload.value,
        amount: SimpleMaskMoney.formatToNumber(netWorthPayload.value.amount),
      },
    });

    const submit = (payload: Partial<Corporation>) => emit("submit:details", payload);

    const cancelRevenue = () => {
      if (annualRevenue.value)
        annualRevenuePayload.value = cloneDeep(annualRevenue.value);
      emit("click:editCancel");
    };

    return {
      t,
      mdiChevronDown,
      isOpen,
      revenuesFormatted,
      editing,
      moneyCurrencies,
      netWorthPayload,
      annualRevenuePayload,
      addMore: mdiPlusCircleOutline,
      employees,
      employeesOptions,
      openDropdown,
      formatCurrencyFromValue,
      editSection,
      appendRevenue,
      removeRevenue,
      parseRevenuePayload,
      parseNetWorthPayload,
      cancelRevenue,
      submit,
    };
  },
});
</script>

<template>
  <section class="details-section">
    <div class="section-content">
      <SectionContent>
        <div class="details-list">
          <div class="details-item">
            <div v-show="!isEditing || editing !== 'employees'">
              <p class="highlight">
                {{ t("corporations.details.employeesNumber") }}
                <ButtonIcon v-if="!noEdit" class="edit-icon" @click="editSection('employees')">
                  <EditIcon />
                </ButtonIcon>
              </p>
              <p>
                {{ employeesNumber ? t(`corporations.employees.enum.${employeesNumber}`) : "" }}
              </p>
            </div>
            <div v-show="isEditing && editing === 'employees'">
              <form @submit.prevent="submit({ number_of_employees: employees })">
                <label class="form-label" for="">
                  {{ t("corporations.details.employeesNumber") }}
                </label>
                <div class="form-group form-group--grid form-group-employees">
                  <Select
                    v-model="employees"
                    :placeholder="t('corporations.details.forms.placeholders.employeesSelect')"
                    :options="employeesOptions"
                  />
                </div>

                <div class="form-actions">
                  <Button type="submit">
                    {{ t("text.form.submit") }}
                  </Button>

                  <Button
                    variation="light"
                    filled
                    @click="
                      employees = employeesNumber;
                      $emit('click:editCancel');
                    "
                  >
                    {{ t("text.form.cancel") }}
                  </Button>
                </div>
              </form>
            </div>
          </div>
          <div class="details-item">
            <p v-show="!isEditing || editing !== 'annualRevenue'" class="highlight">
              {{ t("corporations.details.annualRevenue") }}
              <ButtonIcon v-if="!noEdit" class="edit-icon" @click="editSection('annualRevenue')">
                <EditIcon />
              </ButtonIcon>
            </p>

            <div v-show="!isEditing || editing !== 'annualRevenue'" class="dropdown">
              <p v-if="revenuesFormatted.length && !isOpen">
                {{ revenuesFormatted[0] }}
                <SvgIcon
                  v-if="revenuesFormatted.length > 1"
                  type="mdi"
                  class="mdi-icon"
                  :path="mdiChevronDown"
                  size="24"
                  @click="openDropdown"
                />
              </p>
              <Transition>
                <div v-if="isOpen" class="dropdown-list">
                  <div
                    v-for="(value, index) in revenuesFormatted"
                    :key="value"
                    class="dropdown-list-item"
                  >
                    <div>{{ value }}</div>
                    <div>
                      <SvgIcon
                        v-if="index === 0 && revenuesFormatted.length > 1"
                        type="mdi"
                        class="mdi-icon"
                        :path="mdiChevronDown"
                        size="24"
                        @click="openDropdown"
                      />
                    </div>
                  </div>
                </div>
              </Transition>
            </div>
            <div v-show="isEditing && editing === 'annualRevenue'">
              <form @submit.prevent="submit(parseRevenuePayload())">
                <label class="form-label" for="">{{ t("corporations.details.annualRevenue") }}
                </label>
                <template v-for="(revenueItem, index) of annualRevenuePayload" :key="revenueItem">
                  <div class="form-group form-group--grid form-group-revenue">
                    <Select
                      v-model="revenueItem.currency"
                      :placeholder="t('corporations.details.forms.placeholders.currency')"
                      :options="moneyCurrencies"
                    />
                    <FormInput
                      v-model="revenueItem.amount"
                      :placeholder="t('corporations.details.forms.placeholders.netWorth')"
                      money
                    />
                    <FormInput
                      v-model="revenueItem.year"
                      mask="####"
                      :placeholder="t('corporations.details.forms.placeholders.year')"
                    />
                    <button
                      class="icon-button remove"
                      type="button"
                      aria-label="remove a revenue"
                      @click="removeRevenue(index)"
                    >
                      <TrashIcon />
                    </button>
                  </div>
                </template>
                <div class="button-wrapper add">
                  <button
                    class="icon-button"
                    type="button"
                    variation="regular"
                    @click="appendRevenue()"
                  >
                    <SvgIcon type="mdi" :path="addMore" size="16" />
                    {{ t("corporations.details.forms.placeholders.addYear") }}
                  </button>
                </div>
                <div class="button-wrapper">
                  <div class="form-actions">
                    <Button type="submit">
                      {{ t("text.form.submit") }}
                    </Button>

                    <Button variation="light" filled @click="cancelRevenue()">
                      {{ t("text.form.cancel") }}
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div class="details-item">
            <div v-show="!isEditing || editing !== 'netWorth'">
              <p class="highlight">
                {{ t("corporations.details.netWorth") }}
                <ButtonIcon v-if="!noEdit" class="edit-icon" @click="editSection('netWorth')">
                  <EditIcon />
                </ButtonIcon>
              </p>
              <p v-if="netWorth">
                {{ formatCurrencyFromValue(netWorth.amount, netWorth.currency) }}
              </p>
            </div>

            <div v-show="isEditing && editing === 'netWorth'">
              <form @submit.prevent="submit(parseNetWorthPayload())">
                <label class="form-label" for="">{{ t("corporations.details.netWorth") }}</label>
                <div class="form-group form-group--grid">
                  <Select
                    v-model="netWorthPayload.currency"
                    :placeholder="t('corporations.details.forms.placeholders.currency')"
                    :options="moneyCurrencies"
                  />
                  <FormInput
                    v-model="netWorthPayload.amount"
                    :placeholder="t('corporations.details.forms.placeholders.netWorth')"
                    money
                  />
                </div>

                <div class="form-actions">
                  <Button type="submit">
                    {{ t("text.form.submit") }}
                  </Button>

                  <Button variation="light" filled @click="$emit('click:editCancel')">
                    {{ t("text.form.cancel") }}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </SectionContent>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.details-list {
  display: flex;
  width: 100%;

  svg:not(.mdi-icon) {
    --size: 0.875rem;

    width: var(--size);
    height: var(--size);
    cursor: pointer;
    stroke: $gray-border;
  }

  .mdi-icon {
    position: absolute;
    display: inline-block;
    margin-top: -3px;
    cursor: pointer;
  }

  $remove-mb: 1rem;

  .button-wrapper {
    display: flex;
    justify-content: flex-end;

    &.add {
      margin-right: 2rem;
    }
  }

  .icon-button {
    padding: 0.25rem 0;
    border: none;
    margin-bottom: $remove-mb;
    background-color: transparent;
    color: $primary;
    cursor: pointer;
    font-size: 0.75rem;

    svg {
      margin-right: 0.25rem;
    }

    &.remove {
      margin-bottom: $remove-mb;
      color: $gray;
      cursor: pointer;

      svg {
        width: 16px;
        height: 16px;
        stroke: $gray-medium;
      }
    }
  }

  p {
    position: relative;
  }

  .edit-icon {
    position: absolute;
    right: 0;
  }

  .arrow-icon {
    margin-left: 0.5ch;
    float: right;
  }

  .details-item {
    width: 33.33%;
    font-size: 16px;
    text-align: center;

    .highlight {
      font-weight: bold;
    }
  }
}

.dropdown {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;

  .dropdown-list {
    position: absolute;
    z-index: 200;
    padding: 0.5rem 1rem 0;
    border-radius: 6px;
    background-color: #fff;
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
    text-align: start;

    .dropdown-list-item {
      display: grid;
      grid-gap: 0.5rem;
      grid-template-columns: 1fr 24px;

      > div:hover {
        color: $primary;
      }

      &:not(:first-child) {
        margin: 0.5rem 0;
      }
    }

    .arrow-icon {
      transform: rotate(-180deg);
    }
  }
}

.form-actions {
  display: grid;
  grid-column-gap: 1rem;
  grid-template-columns: repeat(2, 1fr);

  button {
    padding-block: 0.375rem;
  }
}

.form-label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.form-group {
  &--grid {
    display: grid;
    grid-column-gap: 0.5rem;
    grid-template-columns: 1fr 3fr;
  }

  &-employees {
    grid-template-columns: 1fr;
  }

  &-revenue {
    grid-template-columns: minmax(75px, 1fr) minmax(150px, 1fr) minmax(100px, 1fr) 1fr;

    :deep(.input-wrapper > input) {
      width: inherit;
    }
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
