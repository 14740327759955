<script lang="ts">
import type { Ref } from "vue";
import { useI18n } from "vue-i18n";
import ImageCard from "@/components/image_card/ImageCard.vue";
import { sortExperiences } from "@/utils/experiences-sorter";
import type { PersonWorkExperience } from "@/components/person_card/interfaces";
import type { Person } from "@/http_services/entities/types";

export default defineComponent({
  components: {
    ImageCard,
  },
  props: {
    persons: Array,
    onPersonClick: Function,
    loadMoreData: Function,
  },
  setup(props) {
    const { t } = useI18n();
    const activeId = ref(undefined);
    const onPersonClick = toRef(props, "onPersonClick") as Ref<(person: Person) => void>;
    const loadMoreData = toRef(props, "loadMoreData") as Ref<() => void>;

    const handleScroll = (event: Event) => {
      const {
        scrollTop: hiddenHeight,
        scrollHeight: totalHeight,
        clientHeight: totalVisible,
      } = event.target as HTMLElement;
      const bottomReached = hiddenHeight + totalVisible >= totalHeight;

      if (hiddenHeight && bottomReached)
        loadMoreData.value();
    };

    const lastExperience = (experiences: PersonWorkExperience[]) => sortExperiences(experiences)[0];

    const personClick = (person: Person) => {
      activeId.value = person.id;

      if (onPersonClick.value)
        onPersonClick.value(person);
    };

    return {
      t,
      lastExperience,
      personClick,
      activeId,
      handleScroll,
    };
  },
});
</script>

<template>
  <div class="list-wrapper" @scroll="handleScroll">
    <div
      v-for="person of persons"
      :key="`person-${person.id}`"
      class="card"
      :class="{ active: person.id === activeId }"
      @click="() => personClick(person)"
    >
      <ImageCard
        class="image-card"
        :image-url="person.avatar_url"
        :name="`${person.first_name} ${person.last_name}`"
        border-color="transparent"
      />
      <div class="description">
        <p class="name">
          {{ person.first_name }} {{ person.last_name }}
        </p>
        <p v-if="lastExperience(person.work_experience)" class="role">
          {{ lastExperience(person.work_experience).role_name }}
          {{ t('persons.checkConflict.where') }}
          {{ lastExperience(person.work_experience).company?.name }}
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped="true" lang="scss">
.list-wrapper {
  max-height: 360px;
  padding: 0 1rem;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border: solid 2px transparent;
    border-radius: 2rem;
    background: #888;
    background-clip: content-box;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    border: solid 2px transparent;
    background: #555;
    background-clip: content-box;
  }
}

.image-card {
  width: 90px;
  height: 90px;
}

.card {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 15px;
  border: solid 1px transparent;
  background: $white-background;
  cursor: pointer;

  &.active {
    border: solid 1px $red;
  }
}

.card:not(:first-child) {
  margin-top: 1rem;
}

.description {
  width: 100%;
  flex: 1;
  margin-left: 15px;
  color: $gray-dark;
  font-family: "Open Sans";

  .name {
    font-size: 16px;
    font-weight: bold;
  }

  .role {
    margin-top: 0.5rem;
    font-size: 14px;
  }
}
</style>
