<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "AddLink",
  props: {
    linkText: String,
    onClick: Function,
  },
});
</script>

<template>
  <div class="add-link">
    <a @click.prevent="onClick"><i class="gg-add" /> {{ linkText }}</a>
  </div>
</template>

<style lang="scss" scoped>
.add-link {
  position: relative;
  top: -15px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;

  a {
    display: flex;
    align-items: center;
    color: $primary;
    font-size: 13px;
    text-decoration: none;
  }
}

.gg-add {
  position: relative;
  display: block;
  width: 22px;
  height: 22px;
  box-sizing: border-box;
  border: 2px solid;
  border-radius: 22px;
  color: $primary;
  transform: scale(var(--ggs, 0.6));
}

.gg-add::after,
.gg-add::before {
  position: absolute;
  top: 8px;
  left: 4px;
  display: block;
  width: 10px;
  height: 2px;
  box-sizing: border-box;
  border-radius: 5px;
  background: currentcolor;
  content: "";
}

.gg-add::after {
  top: 4px;
  left: 8px;
  width: 2px;
  height: 10px;
}
</style>
