<script lang="ts">
import type { Ref } from "vue";
import { computed, defineComponent, onMounted, ref, shallowRef, watch } from "vue";
import { useI18n } from "vue-i18n";
import { mdiChevronLeft } from "@mdi/js";
import { some, uniqBy } from "lodash";
import type { PaginationParams } from "@/http_services/types";
import { breakpoints } from "@/utils/breakpoints";
import { DropdownFilter } from "@/components/dropdown_filter";
import { SearchInput } from "@/components/inputs/modalInputs";
import { ButtonIcon, Button as ButtonText } from "@/components/button";
import {
  BusinessFunctionFilter,
  MoneyFilter,
  SelectFilter,
  TagFilter,
} from "@/components/filters";
import FilterIcon from "@/assets/images/filter_icon.svg";
import { getMe, searchRecruiters } from "@/http_services/entities/persons";
import store from "@/store";
import type { Person } from "@/schemas/person";
import ProjectFilterDropdown from "@/common/components/ProjectFilterDropdown.vue";

export default defineComponent({
  name: "ProjectFilter",
  components: {
    DropdownFilter,
    SearchInput,
    ButtonIcon,
    FilterIcon,
    ButtonText,
    ProjectFilterDropdown,
  },
  emits: ["search:text", "filter"],
  setup(_, { emit }) {
    const { t, tm } = useI18n({ useScope: "global" });
    const filterOpen = ref<boolean>(true);
    const smallerThanLg = breakpoints.smaller("lg");
    const searchError = ref(false);
    const recruiterList = ref([]) as Ref<Person[]>;
    const changeFilter = () => (filterOpen.value = !filterOpen.value);
    const emitSearch = (searchTerm: string) => {
      searchError.value = false;
      emit("search:text", searchTerm);
    };
    const paginationParams = ref<PaginationParams>({
      limit: 10,
      skip: 0,
      sort: "name",
    });
    const totalRecruiters = ref(0);
    const selectedIndex = ref(null);

    const stopSearchRecruiters = ref(false);

    const validateSearchRecruiters = (recruiters: Person[]) => {
      if (
        !recruiters?.length
        && paginationParams.value?.skip
        && paginationParams.value.skip > 0
      )
        stopSearchRecruiters.value = true;
    };

    const addMyProjectsToRecruiterListIfNotExist = () => {
      const { id, ...userData } = store.getters.GET_USER_DATA;
      const myProject = {
        ...userData,
        person_id: id,
        person_data: {
          first_name: t("projects.filters.myProjects.0"),
          last_name: t("projects.filters.myProjects.1"),
        },
      };

      const recruiterExists = recruiterList.value.some(
        (recruiter: Person) => recruiter.person_id === myProject.person_id,
      );

      if (!recruiterExists)
        recruiterList.value = [myProject, ...recruiterList.value];
    };

    const getRecruiters = async () => {
      const { data: recruiters, total } = await searchRecruiters({
        ...paginationParams.value,
      });
      validateSearchRecruiters(recruiters);

      if (store.getters.GET_USER_DATA) {
        addMyProjectsToRecruiterListIfNotExist();

        const list: Person[] = [...recruiterList.value, ...recruiters] ?? [];

        recruiterList.value = uniqBy(list, (recruiter: Person) => recruiter.person_id);
      }
      else {
        recruiterList.value = uniqBy([...recruiterList.value], e => e.person_id);
      }
      totalRecruiters.value = total;
    };

    const shouldGetRecruiters = computed(
      () =>
        recruiterList.value.length < totalRecruiters.value && !stopSearchRecruiters.value,
    );

    watch(
      () => paginationParams.value.skip,
      async () => shouldGetRecruiters.value && (await getRecruiters()),
    );

    const getFilterRecruiter = (filterType: string, filterObject) => (
      values: [] | string,
    ) => {
      if (!values)
        filterObject.active.value = false;
      else
        filterObject.active.value = values?.length ? true : !!Object.keys(values).length;
      emit("filter", [filterType, values]);
    };
    const getFilterCallback = (filterType: string, filterObject) => (
      values: [] | string,
    ) => {
      if (!values)
        filterObject.active.value = false;
      else
        filterObject.active.value = values?.length ? true : !!Object.keys(values).length;
      emit("filter", [filterType, values]);
    };

    const moneyRangeCallback = (filterType: string, filterObject) => (values: []) => {
      filterObject.active.value = some(Object.values(values));
      emit("filter", [filterType, values]);
    };

    const projectStatusEnum = computed(() => tm("projects.filters.projectStatus.enum"));
    const currentFilter = ref("");

    const isClear = ref(false);

    const filters = computed(() => [
      {
        name: t("projects.filters.tagFilters.recruiter"),
        active: shallowRef(false),
        component: ProjectFilterDropdown,
        props: {
          clear: isClear.value,
          items: recruiterList.value,
          total: paginationParams.value.skip,
          filterLocaleRoot: "projects.filters",
          placeholderLocale: "projectStatus.placeholder",
          clearLocale: "clearFilter",
          selectedIndex: selectedIndex.value,
        },
        get listeners() {
          return {
            "filter:select": getFilterRecruiter("recruiters", this),
            "total": (event: number) => (paginationParams.value.skip = event),
          };
        },
      },
      {
        name: t("projects.filters.tagFilters.keywords.0"),
        active: shallowRef(false),
        component: TagFilter,
        props: {
          clear: false,
          filterName: "keywords",
          filterLocale: "projects.filters",
        },
        get listeners() {
          return {
            "filter:tag": getFilterCallback("keywords", this),
          };
        },
      },
      {
        name: t("projects.filters.tagFilters.role.0"),
        active: shallowRef(false),
        component: TagFilter,
        props: {
          clear: false,
          filterName: "role",
          filterLocale: "projects.filters",
        },
        get listeners() {
          return {
            "filter:tag": getFilterCallback("role", this),
          };
        },
      },
      {
        name: t("projects.filters.tagFilters.company.0"),
        active: shallowRef(false),
        component: TagFilter,
        props: {
          clear: false,
          filterName: "company",
          filterLocale: "projects.filters",
        },
        get listeners() {
          return {
            "filter:tag": getFilterCallback("corporation_name", this),
          };
        },
      },
      {
        name: t("projects.filters.tagFilters.sector.0"),
        active: shallowRef(false),
        component: BusinessFunctionFilter,
        props: {
          clear: false,
        },
        get listeners() {
          return {
            "filter:select": getFilterCallback("business_function", this),
          };
        },
      },
      {
        name: t("projects.filters.projectStatus.name"),
        active: shallowRef(false),
        component: SelectFilter,
        props: {
          clear: false,
          filterLocaleRoot: "projects.filters",
          placeholderLocale: "projectStatus.placeholder",
          clearLocale: "clearFilter",
          options: projectStatusEnum.value,
          searchable: false,
        },
        get listeners() {
          return {
            "filter:select": getFilterCallback("status", this),
          };
        },
      },
      {
        name: t("projects.filters.tagFilters.revenue.0"),
        active: shallowRef(false),
        component: MoneyFilter,
        props: { clear: false },
        get listeners() {
          return {
            "filter:money_range": moneyRangeCallback("salary", this),
          };
        },
      },
    ]);

    watch(smallerThanLg, value => (filterOpen.value = !value));

    const clearFilters = () => {
      currentFilter.value = "";
      isClear.value = true;
      const params = [
        ["recruiters", []],
        ["keywords", []],
        ["status", []],
        ["role", []],
        ["company", []],
        ["business_function", []],
        ["salary", []],
      ];
      params.forEach(param => emit("filter", param));
      filters.value.forEach((filter) => {
        filter.props.clear = !filter.props.clear;
        filter.active.value = false;
      });
    };

    onMounted(async () => {
      const me = await getMe();
      store.commit("SET_USER_DATA", toRaw(me));
      await getRecruiters();

      if (me?.is_recruiter) {
        currentFilter.value = t("projects.filters.tagFilters.recruiter");
        selectedIndex.value = 0;
      }
    });

    return {
      t,
      tm,
      smallerThanLg,
      filterOpen,
      changeFilter,
      emitSearch,
      getRecruiters,
      searchError,
      recruiterList,
      currentFilter,
      filters,
      chevron: mdiChevronLeft,
      clearFilters,
      paginationParams,
    };
  },
});
</script>

<template>
  <div class="search-group">
    <div class="search-input-wrapper">
      <SearchInput
        :placeholder="t('projects.filters.searchProject')"
        @search="emitSearch($event)"
        @search:error="searchError = true"
        @search:clear="searchError = false"
      />
      <span class="search-error">
        {{ searchError ? t("projects.filters.searchProjectError") : "" }}
      </span>
    </div>

    <div class="filters-wrapper">
      <header class="filter-header">
        <h2>
          <FilterIcon />
          <span
            :role="smallerThanLg ? 'button' : 'presentation'"
            @click="smallerThanLg ? changeFilter() : () => null"
          >
            {{ t("projects.filters.title") }}
          </span>
        </h2>

        <div v-if="smallerThanLg">
          <ButtonIcon
            :icon="chevron"
            :rotation="filterOpen ? -90 : 0"
            @click="changeFilter()"
          />
        </div>
      </header>

      <div class="clear-button">
        <ButtonText
          :text="t('projects.filters.clearFilters')"
          :no-border="true"
          variation="gray-light"
          @click="clearFilters"
        />
      </div>
      <transition name="slide-down">
        <div v-show="filterOpen">
          <DropdownFilter
            v-for="filter in filters"
            :key="filter.name"
            :name="filter.name"
            :filter-active="filter.active.value"
            :filter-open="currentFilter === filter.name"
            @filter:open="currentFilter = $event"
          >
            <template #filter>
              <component
                :is="filter.component"
                v-bind="filter.props"
                v-on="filter.listeners"
              />
            </template>
          </DropdownFilter>
        </div>
      </transition>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.search-group {
  .search-input-wrapper {
    height: 2.5rem;
    border-radius: 10px;
    background: white;
  }

  .search-error {
    display: inline-block;
    margin-top: 0.5rem;
    color: $red;
    font-size: 0.8rem;
    text-align: center;
  }
}

.filters-wrapper {
  margin-top: 3rem;

  svg {
    height: 1.5rem;
    margin-right: 10px;
    color: $primary;
  }

  .filter-header {
    --filter-header-color: #{$primary};

    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    color: var(--filter-header-color);
    fill: var(--filter-header-color);
    font-weight: 600;
    stroke: var(--filter-header-color);

    h2 {
      display: flex;
      align-items: center;
      letter-spacing: 2px;
    }
  }
}

.clear-button:deep {
  display: flex;
  justify-content: flex-end;

  button {
    width: 8.125rem;
    padding: 5px;
    background-color: #fff;
  }
}

.slide-down-enter-from,
.slide-down-leave-to {
  transform: scaleY(0);
}

.slide-down-enter-active,
.slide-down-leave-active {
  transform-origin: top;
  transition: transform 100ms ease-in-out;
}

.slide-down-enter-to,
.slide-down-leave-from {
  transform: scaleY(1);
}
</style>
