<script setup lang="ts">
import { mdiPlusCircleOutline as add, mdiClose } from "@mdi/js";
import uniqBy from "lodash-es/uniqBy";
import bson from "bson-objectid";
import flattenDeep from "lodash-es/flattenDeep";
import AddBusinessFunction from "../../components/AddBusinessFunction.vue";
import type { RelatedCorporation } from "@/schemas/common";
import { FormInput, SearchDropdown } from "@/components/inputs/modalInputs";
import { Card } from "@/components/card";
import { Avatar } from "@/components/avatar";
import { searchCorporation } from "@/http_services/entities/corporation";
import { Modal } from "@/components/modal";
import { SvgIcon } from "@/components/icon";
import { Button2 as Button } from "@/components/button";
import { Tag } from "@/components/tag";
import ScrollToTop from "@/components/scroll_to_top/ScrollToTop.vue";

interface BusinessFunction {
  tag: string
  label?: string
  label_nickname?: string | null
  corporations: RelatedCorporation[]
  project_id: string
  _id: string
}

const props = defineProps<{ id: string }>();
const router = useRouter();
const {
  stageTabs,
  goTo,
} = useTabsProject(props.id);

const { t, tm } = useI18n();

const currentRoute = computed(() => stageTabs.value.findIndex(tab => tab.key === router.currentRoute.value.meta.tab));
const conclaOptions = Object(tm("concla"));

const showModalRemove = ref(false);
const isEditingBusinessCorporations = ref(false);
const businessRestore = ref<BusinessFunction>();
const businessCorporationsPayload = ref<BusinessFunction>();
const corporationSearch = ref();

const businessNicknamePayload = ref<BusinessFunction>();
const isEditingBusinessNickname = ref(false);
const nicknamePayload = ref(""); // TODO: use only one ref

const {
  mutateAsync: removeCorporation,
} = useRemoveBusinessCorporation(String(props.id));

const { data: project } = await useProject(props.id);
const {
  data: businessFunctions,
} = useBusinessFunctionsQuery(props.id);

const {
  mutateAsync: patchBusinessFunction,
} = patchBusinessFunctionsQuery();

const {
  mutateAsync: updateBusinessFunction,
} = updateBusinessFunctionsQuery();

function sortByUpdatedAt(list: any[]) {
  if (!list)
    return;
  return list.sort((a, b) => {
    return new Date(a.inserted_at).getTime() - new Date(b.inserted_at).getTime();
  });
}

function clearHighlight() {
  const elements = document.querySelectorAll(".highlight");
  elements.forEach((element) => {
    element.classList.remove("highlight");
  });
}

const corporationNamesFiltered = computed(() => {
  clearHighlight();
  if (!businessFunctions.value || !corporationSearch.value)
    return [];

  const corporationNames = flattenDeep(Object.values(businessFunctions.value || []).map(item => item.corporations)).map(item => item.name);
  return (corporationNames || []).filter(item => item.toLowerCase().includes(corporationSearch.value.toLowerCase()));
});

function goFind(name: string) {
  clearHighlight();
  // looks on the dom and highlights the corporation name
  const element = document.querySelector(`[data-corporation-name="${name}"]`);
  if (element) {
    // add a class to the element
    element.classList.add("highlight");
    element.scrollIntoView({ behavior: "smooth", block: "center" });
  }
}

function editBusinessCorporations(business_function: BusinessFunction) {
  isEditingBusinessCorporations.value = true;
  businessCorporationsPayload.value = business_function;
}

function checkCorporationId(corp: any, item: BusinessFunction) {
  return item.corporations.some(corporation => corporation.corporation_id === corp.id);
}
function selectCorporation(corp: any, item: BusinessFunction) {
  if (checkCorporationId(corp, item))
    return;

  let currentCorporations = businessCorporationsPayload.value?.corporations || [];
  currentCorporations = currentCorporations.concat([
    {
      corporation_id: corp.id!,
      name: corp.name,
      avatar_url: corp.avatar_url!,
    },
  ]);

  uniqBy(currentCorporations, "corporation_id");

  businessCorporationsPayload.value = {
    ...businessCorporationsPayload.value!,
    corporations: currentCorporations,
  };
}
async function saveBusinessCorporations() {
  await updateBusinessFunction(businessCorporationsPayload.value, {
    onSuccess: () => {
      isEditingBusinessCorporations.value = false;
      businessCorporationsPayload.value = undefined;
    },
  });
}

function cancelBusinessCorporations() {
  isEditingBusinessCorporations.value = false;
  businessCorporationsPayload.value = undefined;
}

/** Removes corporation from the current editing business function */
function removeBusinessCorporation(corporation_id: string) {
  businessCorporationsPayload.value = {
    ...businessCorporationsPayload.value!,
    corporations: businessCorporationsPayload.value!.corporations.filter(item => item.corporation_id !== corporation_id),
  };
}

function editBusinessNickname(business_function: BusinessFunction) {
  isEditingBusinessNickname.value = true;
  businessNicknamePayload.value = business_function;
  nicknamePayload.value = business_function.label_nickname || "";
}

function showRestore(item: BusinessFunction) {
  businessRestore.value = item;
  showModalRemove.value = true;
}

async function saveNickname() {
  await patchBusinessFunction({
    ...businessNicknamePayload.value,
    label_nickname: nicknamePayload.value,
  }, {
    onSuccess: () => {
      isEditingBusinessNickname.value = false;
      businessNicknamePayload.value = undefined;
      nicknamePayload.value = "";
    },
  });
}

async function restoreNickname() {
  await patchBusinessFunction({
    ...businessRestore.value,
    label_nickname: null,
  }, {
    onSuccess: () => {
      showModalRemove.value = false;
      businessRestore.value = undefined;
      nicknamePayload.value = "";
    },
  });
}

const visibleScrollTop = ref(false);
// TODO: improve scroll behavior
const boundaryTopScroll = ref(0);

function handleScroll() {
  // enable scroll top button when scroll below search field groups
  const body = document.querySelector("body");
  const boundary = document.querySelector(".search-field-search");

  if (!body || !boundary)
    return;

  if (body!.scrollTop >= boundary.offsetHeight)
    visibleScrollTop.value = true;

  else visibleScrollTop.value = false;
}

onMounted(() => {
  const body = document.querySelector("body");
  if (body)
    body.addEventListener("scroll", () => handleScroll());
});
</script>

<template>
  <section class="project-activities">
    <div v-if="project" class="project-container">
      <ProjectStages :id="props.id" />
      <ProjectTabs :stage-tabs="stageTabs" :active="currentRoute" class="tabs" @change="goTo($event)" />
      <div class="project-wrapper">
        <article class="src-content">
          <div class="search-field-search">
            <AddBusinessFunction :id="props.id" :business-functions="businessFunctions" :project="project" />
            <div class="corporation-search">
              <div>
                <label for="searchCorporation" class="search-hint-label hidden">
                  {{ t("projects.construction.labels.searchCorporations") }}
                </label>
              </div>
              <FormInput
                v-model="corporationSearch"
                name="searchCorporation"
                :placeholder="t('projects.construction.labels.searchCorporation')"
              >
                <template #icon>
                  <SvgIcon
                    v-if="!corporationSearch"
                    class="company-search-icon"
                    icon="search_icon"
                    width="1.5rem"
                    height="1.5rem"
                  />
                  <SvgIcon
                    v-else
                    class="company-search-icon"
                    style="cursor: pointer"
                    type="mdi"
                    :icon="mdiClose"
                    width="1.5rem"
                    height="1.5rem"
                    @click="corporationSearch = null"
                  />
                </template>
              </FormInput>
              <div>
                <div
                  v-if="(corporationNamesFiltered || []).length"
                  style="margin-inline: 0.5rem; padding-block: 0.75rem 2rem;"
                >
                  {{ corporationNamesFiltered.length }} {{ t("projects.construction.labels.corporationsFound") }}
                </div>
                <template v-if="(corporationNamesFiltered || []).length">
                  <div
                    v-for="corpName in corporationNamesFiltered"
                    :key="`${corpName}-${bson().toHexString()}`"
                  >
                    <div class="corporation-name" @click="goFind(corpName)">
                      {{ corpName }}
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>

          <TransitionGroup name="business">
            <section v-for="(item, index) in sortByUpdatedAt(businessFunctions)" :key="index">
              <div
                v-if="isEditingBusinessNickname && businessNicknamePayload && businessNicknamePayload._id === item._id"
                class="editing-item"
              >
                <div class="form-input">
                  <FormInput
                    v-model="nicknamePayload"
                    class="input-content"
                    :placeholder="conclaOptions[item.tag] || item.name"
                  />
                  <Button @click="saveNickname()">
                    {{ t("text.form.change") }}
                  </Button>
                  <Button variation="light" filled @click="isEditingBusinessNickname = false">
                    {{ t("text.form.cancel") }}
                  </Button>
                </div>
                <div class="editing-info">
                  <span class="label-title"> {{ t("projects.construction.labels.originalTitle") }}: </span>
                  <span class="original-title">
                    {{ conclaOptions[item.tag] || item.name }}
                  </span>
                  <span class="original-restore" @click="showRestore(item)">
                    {{ t("projects.construction.labels.originalRestore") }}
                  </span>
                </div>
              </div>
              <div v-else class="sectionTitle">
                <div class="title">
                  <span>
                    {{ item.label_nickname || conclaOptions[item.tag] || item.name }}
                  </span>
                  <span class="edit-icon">
                    <SvgIcon icon="edit_icon" margin="none" @click="editBusinessNickname(item)" />
                  </span>
                </div>
                <div v-if="item.label_nickname" class="editing-info">
                  <span class="label-title"> {{ t("projects.construction.labels.originalTitle") }}: </span>
                  <span class="original-title">
                    {{ conclaOptions[item.tag] || item.name }}
                  </span>
                  <span class="original-restore" @click="showRestore(item)">
                    {{ t("projects.construction.labels.originalRestore") }}
                  </span>
                </div>
              </div>
              <div class="content">
                {{ item.corporations.length }} {{ t("projects.construction.labels.corporations") }}
              </div>
              <div class="content">
                <TransitionGroup name="list">
                  <Card
                    v-for="(itemList) in item.corporations"
                    :key="itemList.corporation_id"
                    :data-corporation-name="itemList.name"
                    class="card"
                  >
                    <Avatar
                      class="card-logo"
                      :src="itemList.avatar_url"
                      :alt="itemList.name"
                      placeholder="general-company"
                      type="native"
                      :has-alternative-icon="false"
                    />
                    <span>{{ itemList.name }}</span>
                    <span
                      class="card-delete"
                      @click="removeCorporation({ corporation_id: itemList.corporation_id, business_id: item._id })"
                    >
                      <SvgIcon class="trash-icon" icon="trash_icon" margin="none" />
                    </span>
                  </Card>
                </TransitionGroup>
              </div>
              <template v-if="isEditingBusinessCorporations && businessCorporationsPayload?._id === item._id">
                <div class="form-add">
                  <span class="add-corporation">
                    {{
                      t( // eslint-disable-next-line max-len
                        "projects.details.constructionGuide.searchField.forms.placeholders.buttonAddCompany",
                      )
                    }}
                  </span>
                  <div class="inputs">
                    <SearchDropdown
                      id="searchBusinessCorporation"
                      :fetch-method="searchCorporation"
                      :select-item-callback="(corporation: RelatedCorporation) => selectCorporation(corporation, item)"
                      :input-placeholder="t('text.searchCorporation')"
                      :search-not-found-label="t('text.searchCorporationNotFound')"
                      :search-error-label="t('text.searchCorporationError')"
                    >
                      <template #list-item="{ item: corporation }">
                        {{ corporation.name }}
                      </template>
                    </SearchDropdown>
                    <div v-if="businessCorporationsPayload?.corporations" class="tags">
                      <Tag
                        v-for="(corporation) in businessCorporationsPayload.corporations"
                        :key="corporation.corporation_id"
                        :tag="corporation.name!"
                        @click:close="removeBusinessCorporation(corporation.corporation_id)"
                      />
                    </div>
                  </div>
                  <div class="actions-buttons">
                    <div>
                      <Button type="submit" @click="saveBusinessCorporations()">
                        {{ t("corporations.create.save") }}
                      </Button>
                      <Button variation="light" filled @click="cancelBusinessCorporations()">
                        {{
                          t("text.form.cancel")
                        }}
                      </Button>
                    </div>
                  </div>
                </div>
              </template>
              <Button class="add" @click="editBusinessCorporations(item)">
                <SvgIcon type="mdi" :icon="add" />
                {{
                  // eslint-disable-next-line max-len
                  t("projects.details.constructionGuide.searchField.forms.placeholders.buttonAddCompany")
                }}
              </Button>
            </section>
          </TransitionGroup>
          <Modal
            v-if="showModalRemove"
            :title="t('projects.construction.labels.modalRestoreOriginalTitle').toUpperCase()"
            :button-text="t('projects.construction.buttons.confirmRestore')"
            :secondary-button-text="t('projects.construction.buttons.cancelRestore')"
            :on-click="restoreNickname"
            :secondary-on-click="() => (showModalRemove = false)"
            :on-close="() => (showModalRemove = false)"
            variation="warning"
            secondary-variation="light"
          >
            <template #titleIcon>
              <SvgIcon icon="alert_circle_icon" width="20" height="20" />
            </template>
            <template #message>
              <div class="modal-resume">
                {{ t("projects.construction.labels.modalRestoreOriginalResume") }}
              </div>
            </template>
          </Modal>
        </article>
      </div>
    </div>
    <ScrollToTop v-if="visibleScrollTop" class-name="body" :top="boundaryTopScroll" />
  </section>
</template>

<route lang="yaml">
meta:
  layout: project-stage
  stage: construction
  tab: search-field
</route>

<style scoped lang="scss">
.project-activities {
  min-height: 100vh;
}

.project-container {
  padding-top: 3.75rem;
}

.project-wrapper {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  padding-block-end: 2.5rem;
  background: $white-full;
}

.src-content {
  display: flex;
  min-width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 0 2rem;

  >* {
    transition: 200ms;
  }
}

.editing-item {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 2.5% 0;
  border-bottom: 2px solid #ececec;
  margin-bottom: 2.5%;

  .form-input {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    gap: 1rem;

    .input-content {
      display: flex;
      min-width: 50%;
      max-width: 50%;
      align-items: center;
      justify-content: center;
    }
  }
}

.editing-info {
  display: flex;
  width: 100%;
  flex-direction: row;
  padding-top: 1%;
  padding-left: 1%;
  font-weight: normal;
  gap: 1rem;

  .label-title {
    color: $yellow;
    font-size: 0.875rem;
  }

  .original-title {
    color: $gray-border;
    font-size: 0.875rem;
  }

  .original-restore {
    color: $violet;
    cursor: pointer;
    font-size: 0.875rem;
  }
}

.modal-resume {
  margin: 0 5%;
  line-height: 1.25rem;
  text-align: center;
}

.sectionTitle {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: flex-start;
  padding-block: 0.25rem;
  border-bottom: 2px solid #ececec;
  font-size: 1.5rem;
  font-weight: 700;
  margin-top: 1.25rem;

  .title {
    display: flex;
    width: 100%;
  }

  .edit-icon {
    display: flex;
    width: 3rem;
    align-items: center;
    justify-content: center;
    padding-top: 0.15rem;

    svg {
      width: 1rem;
      cursor: pointer;
      stroke: $gray-light;

      &:hover {
        stroke: $gray-dark;
      }
    }
  }
}

.content {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.5rem;
  gap: 2rem;

  .list-enter-active,
  .list-leave-active {
    display: flex;
    transition: all 0.5s ease;
  }

  .list-enter-from,
  .list-leave-to {
    opacity: 0;
    transform: translateX(2rem);
  }

  .form-enter-active,
  .form-leave-active {
    transition: all 200ms ease-out;
  }

  .form-enter-from,
  .form-leave-to {
    opacity: 0;
    transform: translateY(2rem);
  }

  .card {
    position: relative;
    display: flex;
    max-width: 20em;
    align-items: center;
    padding: 0.5rem;
    box-shadow: 0 4px 6px #00000029;
    font-weight: normal;
    gap: 15px;

    img {
      text-align: center;
    }

    span:nth-child(2) {
      padding-right: 1.5em;
      font-size: 1.13rem;
      line-height: 1.5rem;
      text-transform: capitalize;
      word-break: break-word;
    }

    &-logo {
      position: relative;
      display: grid;
      min-width: 3.59em;
      object-fit: cover;
      place-items: center;
      word-break: break-word;
    }

    &-delete {
      position: absolute;
      right: 1em;
      align-self: flex-start;
      cursor: pointer;

      svg {
        width: 1em;
        height: 1em;
        stroke: $gray-light;

        &:hover {
          stroke: $gray-dark;
        }
      }
    }
  }

  .highlight {
    border: 2px solid $red-violet;
  }
}

.form-add {
  display: grid;
  margin-top: 3.6rem;
  gap: 1rem;
  grid-template-columns: 1.2fr 0.5fr;

  .inputs {
    display: grid;
    gap: 1rem;

    .search-dropdown {
      width: 70%;
    }

  }

  .actions-buttons {
    display: grid;
    gap: 2rem;
    grid-template-columns: 0.1fr 1fr 0.1fr;

    div {
      display: flex;
      flex-flow: column wrap;
      gap: 2rem;
      grid-column: 2 / 3;
    }
  }

  .add-corporation {
    color: $primary;
    font-size: 1.125em;
    font-weight: bold;
    grid-column: 1 / 3;
    letter-spacing: 0;
    text-transform: uppercase;
  }
}

.tags {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  cursor: pointer;
  gap: 0.75rem;

  .tag span[role="button"] {
    display: none;
  }
}

.add {
  display: flex;
  align-items: center;
  align-self: flex-start;
  justify-content: center;
  padding: 1rem 0;
  border: none;
  background: transparent;
  color: $primary;

  &:hover {
    color: $red-violet-medium;
    background-color: transparent;
  }

  svg {
    width: 1.2em;
    height: 1.2em;
  }
}

.search-field-search {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4rem;

  :deep(.input-wrapper) {
    height: 40px;
  }

  :deep(.input-wrapper input) {
    font-size: 1rem;
  }
}

.search-hint-label {
  font-size: 0.75rem;
  display: block;
  padding-block: 0.875rem;
}

.hidden {
  visibility: hidden;
}

.corporation-name {
  color: $red-violet;
  padding-block: 0.25rem;
  margin-inline: 0.5rem;
  cursor: pointer;
}

.company-search-icon {
  color: $red-violet;
}
</style>
