import { z } from "zod";
import { BusinessLanguageProficiencyEnumSchema, LanguageEnumSchema } from "../enums/languages";
import { DegreeLevelEnumSchema, WorkModelSchema, YearsOfServiceEnumSchema } from "../enums/misc";
import {
  MonetaryAmountSchema,
  PersonInAggregationSchema,
  RelatedCorporationSchema,
  RelatedPersonSchema,
  SalaryMotivationSchema,
} from "../common/schemas";

import { RealizationSchema } from "../person/person";
import { ScreeningctivityDescriptionEnumSchema } from "../enums/project";

export const EarningsSchema = z
  .object({
    salary: SalaryMotivationSchema.nullable(),
    bonus: z.string(),
    annual_income: MonetaryAmountSchema.nullable(),
    open_to_relocation: z.boolean(),
  })
  .strict();

export const MotivationSchema = z
  .object({
    environment: z.string().nullable(),
    career: z.string().nullable(),
    personal: z.string().nullable(),
    earnings: EarningsSchema.nullable(),
    work_model: WorkModelSchema.nullable(),
  })
  .strict();

export const ProcessSchema = z
  .object({
    information: z.string().nullable(),
  })
  .strict();

export const ExperienceSchema = z
  .object({
    years_of_service: YearsOfServiceEnumSchema.nullable(),
    job_title: z.array(z.string()).nullable(),
    location: z.string().nullable(),
    company_context: z.string().nullable(),
    scope: z.string().nullable(),
    reports_to: z.string().nullable(),
    reported_by: z.string().nullable(),
    peers: z.string().nullable(),
    responsible: z.string().nullable(),
    not_responsible: z.string().nullable(),
    team_structure: z.string().nullable(),
    company: RelatedCorporationSchema.nullable(),
    realizations: z.array(RealizationSchema).nullable(),
  })
  .strict();

export const ScreeningWorkExperienceSchema = z
  .object({
    current_experience: ExperienceSchema.nullable(),
    previous_experience: z.array(ExperienceSchema).nullable(),
  })
  .strict();

export const SchoolingSchema = z
  .object({
    degree: DegreeLevelEnumSchema.nullable(),
    course: z.string().nullable(),
    institution: RelatedCorporationSchema.nullable(),
  })
  .strict();

export const LanguageSchema = z
  .object({
    language: LanguageEnumSchema.nullable(),
    proficiency: BusinessLanguageProficiencyEnumSchema.nullable(),
  })
  .strict();

export const SkillSchema = z
  .object({
    tag: z.string().nullable(),
    eliminatory: z.boolean().nullable(),
  })
  .strict();

export const EducationalBackgroundSchema = z
  .object({
    languages: z.array(LanguageSchema).nullable(),
    skills: z.array(SkillSchema).nullable(),
    schooling: z.array(SchoolingSchema).nullable(),
  })
  .strict();

export const ScreeningGuideSchema = z
  .object({
    motivation: MotivationSchema.nullable(),
    process: ProcessSchema.nullable(),
    experience: ScreeningWorkExperienceSchema.nullable(),
    educational_background: EducationalBackgroundSchema.nullable(),
  })
  .nullable();

export const ScreeningAnnotationsSchema = z
  .object({
    header: z.string(),
    current_moment: z.string(),
    activities_scope: z.string(),
    background: z.string(),
    experience: z.string(),
  })
  .strict();

export const EvaluationSchema = z
  .object({
    score: z.number().nullable(),
    note: z.string().optional().nullable(),
  })
  .nullable();

export const SchoolingEvaluation = z.object({
  ideal_profile_schooling_id: z.string(),
  candidate_schooling: z.string().nullish(),
  score: z.number().nullable(),
});

export const LanguagesEvaluation = z.object({
  ideal_profile_language_id: z.string(),
  candidate_language: z.string().nullish(),
  score: z.number().nullable(),
});

export const SchoolingPresentationEvaluation = z
  .object({
    ideal_profile_schooling_evaluation: z.array(SchoolingEvaluation).optional().nullish(),
    recruiter_observations: z.string().nullish(),
  })
  .strict();

export const LanguagesPresentationEvaluation = z
  .object({
    ideal_profile_languages_evaluation: z.array(LanguagesEvaluation).optional().nullish(),
    recruiter_observations: z.string().nullish(),
  })
  .strict();

export const ScreeningMotivationPresentationSchema = z
  .object({
    salary: z.string().nullish(),
    short_term_incentive: z.string().nullish(),
    long_term_incentive: z.string().nullish(),
    other_incentive: z.string().nullish(),
    total_cash: z.string().nullish(),
    score: z.number().nullish(),
    recruiter_observations: z.string().nullish(),
  })
  .strict();

export const ScreeningExperienceAssemblySchema = z.object({
  title: z.string().optional(),
  id: z.string().optional(),
  evaluation: EvaluationSchema.optional().nullable(),
});

export const ScreeningOrganizationAndAssemblySchema = z
  .object({
    educational_background: z
      .object({
        schooling_evaluation: EvaluationSchema,
        languages_evaluation: EvaluationSchema,
        schooling_presentation_evaluation: SchoolingPresentationEvaluation.optional(),
        languages_presentation_evaluation: LanguagesPresentationEvaluation.optional(),
        skills_evaluation: z.array(
          z.object({
            tag: z.string(),
            evaluation: z.number(),
          }),
        ),
      })
      .optional(),
    experience: z.array(ScreeningExperienceAssemblySchema).optional(),
    motivation: EvaluationSchema.optional().nullable(),
    motivation_presentation: ScreeningMotivationPresentationSchema.optional().nullish(),
  })
  .nullable();

export const ScreeningActivitySchema = z
  .object({
    id: z.string().optional(),
    activity_date: z.string().nullish(),
    annotations: ScreeningAnnotationsSchema,
    is_process: z.boolean(),
    guide: ScreeningGuideSchema.nullable(),
    recruiter: RelatedPersonSchema.nullable(),
    candidate: RelatedPersonSchema.nullable(),
    description: ScreeningctivityDescriptionEnumSchema.nullable(),
    organization_and_assessment: ScreeningOrganizationAndAssemblySchema.nullable(),
  })
  .nullable();

export const ScreeningCandidatesSchema = z
  .object({
    person_id: z.string(),
    is_favorite: z.boolean(),
    person_data: PersonInAggregationSchema.nullish(),
  })
  .strict();

export const ScreeningRankedCandidatesSchema = z
  .object({
    educational_background_score: z.number(),
    experience_score: z.number(),
    final_score: z.number(),
    motivation_score: z.number(),
  })
  .merge(RelatedPersonSchema)
  .strict();

export const presenceTypeSchema = z.object({
  presence: z.object({
    user_id: z.string(),
    project_id: z.string(),
    person: z.object({
      id: z.string(),
      first_name: z.string(),
      last_name: z.string(),
    }),
    id: z.string(),
  }),
  conflict: z.boolean(),
});
export const searchTagSchema = z.object({
  id: z.string(),
  inserted_at: z.string(),
  is_active: z.boolean(),
  serial: z.number(),
  text: z.string(),
  updated_at: z.string(),
  user_creator_id: z.string(),
});
