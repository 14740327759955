<script lang="ts">
import type { Ref } from "vue";
import { defineComponent, ref, toRef, watch } from "vue";
import { useI18n } from "vue-i18n";
import SimpleMaskMoney from "simple-mask-money";
import { CURRENCY } from "@/entities/common";
import { FormInput } from "@/components/inputs/modalInputs";

import SelectCurrency from "@/components/inputs/moneyInputs/SelectCurrency.vue";

export interface FilterType {
  salary_currency: string | null
  salary_min: string
  salary_max: string
}

export default defineComponent({
  name: "MoneyFilter",
  components: {
    FormInput,
    SelectCurrency,
  },
  props: {
    clear: {
      type: Boolean,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hasButton: {
      type: Boolean,
      default: true,
    },
    initialValues: {
      type: Object,
      default: () => ({}),
    },
    salaryCurrency: {
      type: Array<string>,
      default: [
        {
          value: CURRENCY.USD,
          label: "USD",
        },
        {
          value: CURRENCY.BRL,
          label: "R$",
        },

        {
          value: CURRENCY.EUR,
          label: "€",
        },
      ],
    },
  },
  emits: ["filter:money_range"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const clear = toRef(props, "clear");

    const salary: Ref<FilterType> = ref({
      salary_currency: null,
      salary_min: "",
      salary_max: "",
      ...props.initialValues,
    });
    const clearAll = () => {
      salary.value = { salary_currency: null, salary_min: "", salary_max: "" };
      emit("filter:money_range", { salary_currency: "", salary_min: "", salary_max: "" });
    };

    const emitSalaries = () => {
      const { salary_min, salary_max, salary_currency } = salary.value;

      const value: { salary_min?: number; salary_max?: number; salary_currency?: string } = {};

      if (salary_min)
        value.salary_min = SimpleMaskMoney.formatToNumber(salary_min);
      if (salary_max)
        value.salary_max = SimpleMaskMoney.formatToNumber(salary_max);
      if (salary_currency)
        value.salary_currency = salary_currency;
      emit("filter:money_range", {
        ...salary.value,
        ...value,
      });
    };

    watch(clear, () => {
      clearAll();
    });

    return {
      t,
      salary,
      clearAll,
      emitSalaries,
    };
  },
});
</script>

<template>
  <div class="tag-filter">
    <div class="tag-input-wrapper">
      <div class="text">
        {{ t("persons.from") }}
      </div>
      <SelectCurrency
        :model-value="salary.salary_currency"
        class="select-salary_currency"
        :placeholder="t('projects.details.edit.salary.placeholder2')"
        @change="salary.salary_currency = $event, emitSalaries()"
      />
      <FormInput
        v-model="salary.salary_min"
        class="input-salary"
        :disabled="disabled"
        :money="true"
        :placeholder="t('projects.details.edit.salary.placeholder3')"
        :tooltip-show="false"
        tooltip-color="red-dark"
        @input="emitSalaries()"
      />
      <div class="text">
        {{ t("persons.to") }}
      </div>
      <FormInput
        v-model="salary.salary_max"
        class="input-salary"
        :disabled="disabled"
        :money="true"
        :placeholder="t('projects.details.edit.salary.placeholder3')"
        :tooltip-show="false"
        tooltip-color="red-dark"
        @input="emitSalaries()"
      />
    </div>
    <div v-if="hasButton" class="filter-actions">
      <button v-if="!disabled" class="regular-button button-apply" @click="emitSalaries()">
        {{ t("persons.apply") }}
      </button>
      <small v-if="clearable" class="tag-filter-clear" role="button" @click="clearAll()">
        > {{ t("corporations.clearFilter") }}</small>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.tag-filter {
  margin-top: 1rem;

  .tag-input-wrapper {
    position: relative;
    display: flex;

    .tag-input {
      display: block;
      width: 100%;
      height: 30px;
      padding: 1em;
      border: 1px solid $gray-border;
      border-radius: 8px;
      font-size: 0.75rem;

      &:focus {
        outline: 1px solid darken($gray-border, 5%);
      }
    }

    .add-button {
      position: absolute;
      top: 50%;
      right: 2.5%;
      padding: 0;
      transform: translateY(-50%);

      svg {
        vertical-align: middle;
      }
    }
  }
}

.select-salary_currency {
  width: 4.0625rem;
  margin-right: 5px;
  background-color: white;
}

.input-salary:deep {
  width: 100px;
}

.text {
  padding: 0.25rem;
}

.button-apply {
  display: flex;
  height: 30px;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  margin: 0.5rem 0;

  &:hover {
    background-color: $yellow-dark;
    color: #fff;
  }
}

:deep(.p-inputnumber-input) {
  salary_max-width: 6rem;
}

.filter-actions {
  display: flex;
  justify-content: space-between;

  .tag-filter-clear {
    display: inline-flex;
    align-items: center;
    margin-left: 0.75rem;
    font-size: 0.875rem;

    &:hover {
      color: lighten($gray-dark, 25%);
    }
  }
}
</style>
