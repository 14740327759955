import type { I18nOptions } from "vue-i18n";
import { createI18n } from "vue-i18n";
import { messages } from "./languages";

const locales = {
  "pt": "pt-BR",
  "en": "en-US",
  "pt-PT": "pt-BR",
  "en-US": "en-US",
};

// invalid navigator locale language fallback
const fallbackLocale = "pt-BR";

const storageLocale = localStorage.getItem("imua-locale");

export const defaultLocale = locales[storageLocale]
  || locales[navigator.language]
  || fallbackLocale;

// moment.locale(defaultLocale);

const options: I18nOptions = {
  legacy: false,
  locale: defaultLocale,
  fallbackLocale,
  messages,
  silentTranslationWarn: true,
  silentFallbackWarn: true,
  numberFormats: {
    "en-US": {
      currency: {
        style: "currency",
        currency: "USD",
      },
    },
    "pt-BR": {
      currency: {
        style: "currency",
        currency: "BRL",
      },
    },
  },
};
export const i18n = createI18n<false, typeof options>(options);
