<script setup lang="ts">
import SimpleMaskMoney from "simple-mask-money";
import ExportPage from "./ExportPage.vue";
import TagsPreview from "./TagsPreview.vue";

const props = withDefaults(
  defineProps<{
    isExport: boolean
    corporationRevenues: RevenueModel[]
    employeesNumber: NumberOfEmployeesEnum
    governanceTags: Tag[]
    strategyTags: Tag[]
    executionConditionsTags: Tag[]
  }>(),
  {
    isExport: false,
  },
);

const { t, tm } = useI18n();

enum CURRENCY {
  BRL = "BRL",
  USD = "USD",
  EUR = "EUR",
}

const moneyArgs = {
  [CURRENCY.USD]: {
    fractionDigits: 2,
    decimalSeparator: ".",
    thousandsSeparator: ",",
  },
  [CURRENCY.BRL]: {
    fractionDigits: 2,
    decimalSeparator: ",",
    thousandsSeparator: ".",
  },
  [CURRENCY.EUR]: {
    fractionDigits: 2,
    decimalSeparator: ",",
    thousandsSeparator: ".",
  },
};

enum SALARYCURRENCY {
  BRL = "R$",
  USD = "US$",
  EUR = "€",
}

interface RevenueModel {
  amount: number
  year: string
  currency: string
  max_amount: number | null
}

enum NumberOfEmployeesEnum {
  // Corporation size by it' employee number range.

  ONE_TO_FIFTY = "1_TO_50",
  FIFTY_ONE_TO_HUNDRED = "51_TO_100",
  HUNDRED_ONE_TO_FIVE_HUNDRED = "101_TO_500",
  FIVE_HUNDRED_ONE_TO_THOUSAND = "501_TO_1000",
  THOUSAND_ONE_TO_FIVE_THOUSAND = "1001_TO_5000",
  FIVE_THOUSAND_ONE_TO_TEN_THOUSAND = "5001_TO_10000",
  MORE_THAN_TEN_THOUSAND = "MORE_THAN_10000",
}

interface Tag {
  text: string
  selected: boolean
}

const maxItems = 3;
const maxChars = 220;

const employeesOptions = Object.entries(tm("corporations.employees.enum")).map(
  ([value, label]) => ({ label, value }),
);

function limitText(text: string | null | undefined) {
  if (text === null || text === undefined)
    return "";

  return text.length >= maxChars ? `${text.substring(0, maxChars)}...` : text;
}

function formatFloatToReal(data: string, currency: string) {
  return SimpleMaskMoney.formatToMask(data, {
    allowNegative: false,
    negativeSignAfter: false,
    prefix: "",
    suffix: "",
    fixed: true,
    fractionDigits: currency ? moneyArgs[currency].fractionDigits : 2,
    decimalSeparator: currency ? moneyArgs[currency].decimalSeparator : ",",
    thousandsSeparator: currency ? moneyArgs[currency].thousandsSeparator : ".",
    cursor: "move",
  });
}

function getRevenueValueShow(data: RevenueModel) {
  return `${data.year} - ${SALARYCURRENCY[data.currency as never]} ${formatFloatToReal(
    Number.parseFloat(String(data.amount)).toFixed(2),
    data.currency,
  )}`;
}

function getRevenues() {
  if (props.corporationRevenues) {
    if (props.corporationRevenues[0])
      return getRevenueValueShow(props.corporationRevenues[0]);
  }
  return "";
}

function getEmployees() {
  if (props.employeesNumber) {
    const [option] = employeesOptions.filter(
      item => item.value === props.employeesNumber,
    );
    if (option?.label)
      return option.label;
  }
  return "";
}

function formatTags(tags: Tag[] | undefined) {
  if (!tags)
    return [];
  return tags.slice(0)
    .filter(item => item.selected)
    .filter((_, index) => index < maxItems)
    .map(item => limitText(item.text))
    .filter(item => item.length > 0);
}

const ListItems = computed(() => {
  return {
    left: [
      {
        id: "governance",
        icon: "project_governance",
        title: t("exportPDF.labels.governance"),
        items: formatTags(props.governanceTags),
        key: "governance",
      },
      {
        id: "conditions",
        icon: "project_conditions",
        title: t("exportPDF.labels.executionConditions"),
        items: formatTags(props.executionConditionsTags),
        key: "strategy",
      },
    ],
    right: [
      {
        id: "strategy",
        icon: "project_strategy",
        title: t("exportPDF.labels.strategy"),
        items: formatTags(props.strategyTags),
        key: "conditions",
      },
      {
        id: "company_size",
        icon: "project_company_size",
        title: t("projects.generic.companySize"),
        items: [
          getRevenues() && `${t("corporations.details.currentRevenue")}: ${getRevenues()}`,
          getEmployees() && `${t("corporations.details.employeesNumber")}: ${getEmployees()}`,
        ].filter(item => item.length > 0),
        key: "size",
      },
    ],
  };
});
</script>

<template>
  <ExportPage
    :is-export="props.isExport"
    :page-name="t('exportPDF.summaryConstructionBeta.companyProfile')"
  >
    <TagsPreview :list="ListItems" icon="project_company" col-height="18rem" />
  </ExportPage>
</template>

<style lang="scss" scoped>
.pdf-ideal-profile-tags {
  display: flex;
  min-height: 171mm;
  flex-direction: column;
}
</style>
