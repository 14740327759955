export const placeholders = {
  locality: "ex.: São Paulo, SP, Brasil",
  linkedin: "https://www.linkedin.com/in/usuario",
  facebook: "https://www.facebook.com/usuario",
  resume: "Resumo",
  company: {
    name: "ex.: LOFT Tecnologia",
    facebook: "ex.: https://www.facebook.com/empresa",
    linkedin: "ex.: https://www.linkedin.com/in/empresa",
  },
  url: "ex.: https://website.com",
  month: "Mês",
  year: "Ano",
  email: "ex.: mail{'@'}mail.com",
  money: "00.000,00",
};
