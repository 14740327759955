<script setup lang="ts">
import chunk from "lodash-es/chunk";
import cloneDeep from "lodash-es/cloneDeep";
import Avatar from "./Avatar.vue";
import ExportPage from "./ExportPage.vue";

const props = withDefaults(
  defineProps<{
    initialValue: any[]
    bullet?: boolean
    isExport: boolean
  }>(),
  {
    analyses: null,
    bullet: true,
    isExport: false,
  },
);

const { t, tm } = useI18n();

interface companyModel {
  name: string
  avatar_url: string | null
}

interface pageModel {
  tags: string[]
  labels: string[]
  grids: number[]
  cols: Array<companyModel[][]>
}

const { isExport, initialValue } = toRefs(props);

const initialValueDefault = ref(initialValue.value || []);
const conclaOptions = Object(tm("concla"));

const allPages = ref<pageModel[]>([]);
const maxCol = 4;
const maxItem = 10;

function formatAllPages() {
  allPages.value = [];

  let page = 0;
  let grid = 0;
  let grids = 0;
  let maxItemTemp = maxItem;

  allPages.value[page] = {
    tags: [],
    labels: [],
    grids: [],
    cols: [],
  };

  const checkPage = () => {
    if (!allPages.value[page]) {
      allPages.value.push({
        tags: [],
        labels: [],
        grids: [],
        cols: [],
      });
    }
    if (!allPages.value[page].cols[grid])
      allPages.value[page].cols[grid] = [];

    if (!allPages.value[page].grids[grids])
      allPages.value[page].grids[grids] = 0;
  };

  const newPage = () => {
    page += 1;
    grid = 0;
    grids = 0;
    checkPage();
  };

  const checkGrids = (_grid: number[]): number => {
    return _grid.reduce((total, num) => total + num, 0);
  };

  const setTagLabel = (_tag: string, _label: string) => {
    allPages.value[page].tags.push(cloneDeep(_tag));
    allPages.value[page].labels.push(cloneDeep(_label));
  };

  const addCols = (data: any, cols: any[], _tag: string, _label: string) => {
    if (allPages.value[page].tags.includes(data.tag)) {
      if (checkGrids(allPages.value[page].grids) >= maxCol) {
        newPage();
        setTagLabel(_tag, _label);
      }
      allPages.value[page].grids[grids] += 1;
      allPages.value[page].cols[grid].push(cols);
    }
    else {
      if (checkGrids(allPages.value[page].grids) >= maxCol) {
        newPage();
      }
      else {
        grid += 1;
        grids += 1;
        checkPage();
      }
      setTagLabel(data.tag, _label);
      allPages.value[page].grids[grids] += 1;
      allPages.value[page].cols[grid].push(cols);
    }
    checkPage();
  };
  initialValueDefault.value?.forEach((data, index) => {
    const label = data.label_nickname || conclaOptions[data.tag] || data.tag;
    const tag = data.tag;
    if (index === 0) {
      checkPage();
      setTagLabel(data.tag, label);
    }
    const checkLabel = label.length;
    maxItemTemp = checkLabel > 23 ? maxItem - 1 : maxItem;

    const chunked = chunk(data.corporations, maxItemTemp);
    if (data.corporations?.length === 0) {
      addCols(data, [], tag, label);
    }
    else {
      chunked.forEach((cols) => {
        addCols(data, cols, tag, label);
      });
    }
  });
}

function getTitleGrid(grid: number[]) {
  return grid?.map(num => `${num}fr`).join(" ");
}

watchEffect(() => {
  initialValueDefault.value = initialValue.value || [];
  formatAllPages();
});
</script>

<template>
  <ExportPage
    v-for="(page, index) in allPages"
    :key="index"
    :page-index="index"
    :is-export="isExport"
    :page-name="t('exportPDF.summaryConstructionBeta.searchField')"
    :opinion="null"
    v-bind="$attrs"
  >
    <div class="pdf-search-field" :style="{ gridTemplateColumns: getTitleGrid(page.grids) }">
      <div v-for="(grid, id_grid) in page.cols" :key="id_grid" class="col-item">
        <div class="item-title">
          {{ page.labels[id_grid]?.toUpperCase() }}
        </div>
        <div class="item-companys">
          <div v-for="(col, id_col) in grid" :key="id_col" class="cols">
            <div v-for="(company, id_comp) in col" :key="id_comp" class="company">
              <Avatar
                :lazy="false"
                :src="company.avatar_url"
                :alt="company.name"
                size="2.5rem"
                placeholder="general-company"
                type="native"
                image-size="small"
                :has-alternative-icon="false"
              />
              <div class="company-name">
                {{ company.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ExportPage>
</template>

<style lang="scss" scoped>
.pdf-search-field {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  .col-item {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  .item-title {
    margin-bottom: 1.5rem;
    color: $gray-dark;
    font-size: 1.125rem;
    font-weight: bold;
    line-height: 2rem;
  }

  .item-companys {
    display: flex;
    flex: 1 1 auto;
    gap: 2rem;

    .cols {
      display: flex;
      width: 100%;
      flex-direction: column;
      gap: 0.6rem;

      .company {
        display: flex;
        flex-direction: row;
        align-items: center;

        .imua-avatar {
          min-width: 2.5rem;
          min-height: 2.5rem;
        }

        .company-name {
          margin-left: 0.5625rem;
          color: $gray-dark;
          font-size: 0.875rem;
          line-height: 1.375rem;
        }
      }
    }
  }
}
</style>
