import { z } from "zod";
import { BasePersonSchema } from "../person/person";

export const CandidateShortlistSchema = z.object({
  candidate: z.object({
    person_id: z.string().nullish(),
    person_data: BasePersonSchema.nullish(),
  }),
  candidate_nickname: z.string().nullable(),
  current_role_nickname: z.string().nullable(),
  current_company_nickname: z.string().nullable(),
  consultant_opinion: z.string().nullable(),
});

export const ConsultantOpinionShortlistSchema = z.object({
  description: z.string().nullish(),
});

export const ShortlistBaseSchema = z.object({
  status: z.string().nullish(),
  count: z.string().nullish(),
  candidates: z.array(CandidateShortlistSchema).nullish(),
  person_details: z.string().nullish(),
  consultant_opinion: z.string().nullish(),
}).strict();
