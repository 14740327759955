<script setup lang="ts">
import { toTypedSchema } from "@vee-validate/zod";
import * as zod from "zod";

const props = defineProps<{
  description: string
  historic: string
  foundationYear: string
}>();

const emit = defineEmits(["save", "cancel"]);

const { t } = useI18n();

const validationSchema = toTypedSchema(
  zod.object({
    description: zod.string().nullable(),
    historic: zod.string().nullable(),
    foundationYear: zod.string().nullable(),
  }),
);

const { handleSubmit } = useForm({
  validationSchema,
  initialValues: {
    description: props.description,
    historic: props.historic,
    foundationYear: props.foundationYear,
  },
});

const { value: description } = useField<string>("description");
const { value: historic } = useField<string>("historic");
const { value: foundationYear } = useField<string>("foundationYear");

const onSubmit = handleSubmit((values) => {
  emit("save", values);
});
</script>

<template>
  <div class="form">
    <div class="form-inputs">
      <div class="item description">
        <TextArea
          id="description"
          v-model="description"
          :warning-chars="650"
          placeholder="Description"
          rows="10"
          @change="onSubmit"
        />
      </div>
      <div>
        <div class="item">
          <h4>
            {{ t("corporations.details.tabs.historic") }}
          </h4>
          <div class="foundation-year">
            <label for="foundationYear">
              {{ t("corporations.details.foundationYear") }}:
            </label>
            <FormInput
              id="foundationYear"
              v-model="foundationYear"
              :placeholder="new Date().getFullYear().toString()"
              @change="onSubmit"
            />
          </div>
          <TextArea
            id="historic"
            v-model="historic"
            class="textarea-component"
            :warning-chars="650"
            placeholder="Historic"
            rows="10"
            @change="onSubmit"
          />
        </div>
      </div>
    </div>
    <div class="form-actions">
      <Button type="submit" variation="secondary" @click="emit('cancel'), onSubmit">
        {{ t("text.form.conclude") }}
      </Button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.form-actions {
  display: flex;
  gap: 2rem;
  margin-block-start: 2rem;
}

.form {
  margin: 1.5rem;

  &-inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8.7rem;
    padding-inline: 0.5rem;
    h4 {
      color: $gray-dark;
      font-size: 1.125rem;
      font-weight: 600;
      line-height: 1.5rem;
      text-transform: uppercase;
    }
    .description {
      margin-block-start: 4.5rem;
    }
  }
  .foundation-year {
    display: flex;
    gap: 1rem;
    font-weight: bold;
    margin-block-end: 1rem;
    label {
      line-height: 1.625rem;
      font-size: 0.875rem;
    }
    .form-input--container {
      width: 4.5rem;
    }
  }
}
</style>
