<script lang="ts">
import { useI18n } from "vue-i18n";
import { onClickOutside, useScroll } from "@vueuse/core";
import debounce from "lodash-es/debounce";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiCheck, mdiClose } from "@mdi/js";
import { Loading } from "@/components/loading";
import { searchRecruiters } from "@/http_services/entities/persons";

export default defineComponent({
  name: "PersonCardButton",
  components: {
    SvgIcon,
    Loading,
  },
  props: {
    type: Boolean,
    selected: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  emits: ["update:selected"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const isSidebarOpen = inject("isSidebarOpen") as ComputedRef<boolean>;
    const collapsed = ref(false);
    const searchRef = ref(null);
    const resultsRef = ref(null);
    const termToSearch = ref("");
    const projectsList = shallowRef([]);
    const selectedProjects = shallowRef([]);
    const emptySearch = ref(false);
    const loadError = ref(false);
    const loading = ref(false);
    const totalFiltered = ref(0);
    const emitted = ref(false);
    const paginationParams = computed(() => ({
      limit: 4,
      skip: projectsList.value.length,
      sort: ["updated_at"],
    }));
    const handleCollapse = () => {
      collapsed.value = !collapsed.value;
    };

    const clearSelected = () => {
      selectedProjects.value = [];
      emit("update:selected", []);
    };

    const onSelect = (id) => {
      if (selectedProjects.value.includes(id)) {
        selectedProjects.value = selectedProjects.value.filter(selected_id => selected_id !== id);
        return;
      }
      selectedProjects.value = selectedProjects.value.concat([{ person_id: id }]);
    };

    const onConfirm = () => {
      emit("update:selected", selectedProjects.value);
      emitted.value = true;
      handleCollapse();
    };

    const getProjects = async () => {
      try {
        emptySearch.value = false;
        loadError.value = false;

        const { data: recruiters, total } = await searchRecruiters({
          ...paginationParams.value,
          name: termToSearch.value,
        });
        totalFiltered.value = total;
        projectsList.value = [...projectsList.value, ...recruiters];
        if (projectsList.value.length === 0)
          emptySearch.value = true;
      }
      catch (error) {
        loadError.value = true;
      }
      finally {
        loading.value = false;
      }
    };

    const fetchOnScroll = (elementRef: Ref<HTMLElement | null>) => {
      const { arrivedState } = useScroll(elementRef);
      const { bottom } = toRefs(arrivedState);
      watch(bottom, (_) => {
        setTimeout(() => {
          getProjects();
        }, 1000);
      });
    };
    fetchOnScroll(resultsRef);

    const newSearch = debounce(() => {
      projectsList.value = [];
      emitted.value = false;
      getProjects();
    }, 1000);

    onClickOutside(searchRef, () => {
      if (collapsed.value) {
        handleCollapse();
        if (!emitted.value) {
          emit("update:selected", selectedProjects.value);
          emitted.value = true;
        }
      }
    });
    watch(isSidebarOpen, () => {
      collapsed.value = false;
    });

    // reset state onclose
    watch(collapsed, () => {
      if (collapsed.value === false) {
        projectsList.value = [];
        // selectedProjects.value = [];
        termToSearch.value = "";
      }
      else {
        getProjects();
      }
    });

    onMounted(() => {
      if (props.selected?.length)
        props.selected.forEach((item: string) => onSelect(item));
    });

    return {
      collapsed,
      searchRef,
      resultsRef,
      selectedProjects,
      termToSearch,
      projectsList,
      checkIcon: mdiCheck,
      closeIcon: mdiClose,
      loading,
      totalFiltered,
      emptySearch,
      loadError,
      t,
      onSelect,
      newSearch,
      onConfirm,
      handleCollapse,
      clearSelected,
    };
  },
});
</script>

<template>
  <div>
    <a v-if="type" class="person-card__info__header__button-restrict" href="#">
      {{ t("personCard.restrictProfile") }}
    </a>

    <div v-else class="multiselect">
      <div class="d-flex-center">
        <a class="person-card__info__header__button" @click="handleCollapse">
          {{ t("text.selected", selected.length) }}
        </a>

        <span
          v-if="selected.length"
          tooltip="Remover selecionados"
          max-content
          role="button"
          @click="clearSelected()"
        >
          <SvgIcon type="mdi" :path="closeIcon" width="18px" height="18px" />
        </span>
      </div>
      <transition ref="searchRef" name="collapse">
        <div v-show="collapsed" class="dropdown-wrapper" :class="{ collapsed }">
          <div class="item search">
            <input
              v-model="termToSearch"
              class="plain-input"
              :placeholder="t('projects.create.basicData.allocatedTeam.Search').toUpperCase()"
              @keyup="newSearch"
            >
          </div>
          <div
            ref="resultsRef"
            class="results"
            :style="{ 'overflow-y': totalFiltered > projectsList.length ? 'scroll' : 'auto' }"
          >
            <div
              v-for="project in projectsList"
              v-show="!loading"
              :key="project"
              class="item"
              :class="{ selected: selectedProjects.includes(project.person_id) }"
              :title="project"
              @click="onSelect(project.person_id)"
            >
              <div class="ellipis-text">
                {{ `${project?.person_data?.first_name || ""} ${project?.person_data?.last_name || ""}` }}
              </div>
              <div v-show="selectedProjects.some(item => item.person_id === project.person_id)">
                <SvgIcon id="check-icon" type="mdi" :path="checkIcon" />
              </div>
            </div>
            <span v-if="loadError" class="message center">
              {{ t("persons.errorListProjects") }}
            </span>
            <span v-if="emptySearch" class="message center">
              {{ t("persons.profileNotFound") }}
            </span>
            <transition name="fade">
              <div v-show="loading" class="loading" style="display: flex; justify-content: center;">
                <Loading :spin-time="2" />
              </div>
            </transition>
          </div>
          <button
            type="button"
            class="regular-button button-add"
            :disabled="!selectedProjects"
            @click="onConfirm"
          >
            {{ t("persons.add") }}
          </button>
        </div>
      </transition>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@keyframes slide {
  from {
    width: 100%;
  }

  to {
    width: 400px;
  }
}

$border-radius: 8px;

.center {
  text-align: center;
}

.collapse-enter-active,
.collapse-leave-active {
  animation-duration: 0.5s;
  animation-name: slide;
}

.collapse-leave-active {
  animation-direction: reverse;
}

.d-flex-center {
  display: flex;
  width: 80%;
  align-items: center;
  justify-content: space-between;

  svg {
    z-index: 100;
    color: $gray-low;
    vertical-align: middle;
  }
}

.multiselect {
  position: relative;
  display: flex;
  width: 197px;
  min-height: 28px;
  justify-content: flex-start;
  padding: 0;
  border: 1px solid $gray-border;
  margin: 0;
  font-size: 14px;
  transition: all linear 250ms;

  &::before {
    position: relative;
    left: 88%;
    display: inline-block;

    /* By using an em scale, the arrows will size with the font */
    width: 0.5em;
    height: 0.5em;
    border-right: 0.15em solid #c0c0c0;
    border-bottom: 0.15em solid #c0c0c0;
    margin-bottom: 0.1em;

    /* top: 3pt; Uncomment this to lower the icons as requested in comments */
    content: "";
    transform: rotate(45deg);
  }

  &:focus::before {
    transform: rotate(-90deg);
  }

  .person-card__info__header__button {
    position: relative;
    width: 100%;
    color: $gray-low;
    opacity: 1;
    text-decoration: none;
  }

  .button-add {
    z-index: 100;
    background: white;
  }

  .dropdown-wrapper {
    position: absolute;
    z-index: 100;
    top: 0;
    right: 0;
    width: 100%;

    &.collapsed {
      width: 400px;
    }

    .item {
      display: flex;
      height: 40px;
      align-items: center;
      padding-left: 10px;
      color: $gray-dark;
      font-family: "Open Sans";
      font-size: 15px;

      &:hover,
      &.selected {
        color: $red;
        cursor: pointer;
        font-weight: bold;
      }
    }

    .search {
      height: 36.4px;
      border-radius: $border-radius $border-radius 0 0;
      background-color: $yellow-dark;
      cursor: auto;
    }
  }
}

.person-card__info__header__button-restrict {
  z-index: 100;
  display: flex;
  width: 145px;
  min-width: 180px;
  box-sizing: border-box;
  align-self: start;
  justify-content: center;
  padding: 0.7rem 1rem;
  border: 1px solid $gray-dark;
  border-radius: 8px;
  background: white;
  background-color: $gray-dark;
  color: #fff;
  font-size: 12px;
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    background-color: #fff;
    color: $gray-dark;
  }
}

.plain-input {
  display: block;
  width: 100%;
  height: 36.4px;
  padding: 0;
  border: solid transparent;
  border-width: 0;
  border-radius: $border-radius $border-radius 0 0;
  margin-bottom: 0;
  background-color: transparent;
  color: #fff;

  &:focus,
  &:focus:not(:focus-visible),
  &:focus-visible {
    outline: none;
  }

  &::placeholder {
    color: #fff;
  }

  &:input-placeholder,
  &::-ms-input-placeholder {
    color: #fff;
  }
}

.results {
  z-index: 100;
  height: 130px;
  background: white;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;

  .item {
    display: flex;
    justify-content: space-between;
    padding-right: 15px;

    svg {
      color: #2cc86b;
    }
  }

  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border: solid 2px transparent;
    border-radius: 2rem;
    background: #888;
    background-clip: content-box;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    border: solid 2px transparent;
    background: #555;
    background-clip: content-box;
  }
}

.regular-button.button-add {
  width: 100%;
  border-radius: 0 0 $border-radius $border-radius;
}

.regular-button.button-add[disabled] {
  border-color: $gray-light;
  color: $gray-light;
  cursor: not-allowed;
}

.message {
  display: block;
  padding: 10px 0;
}

.ellipis-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
