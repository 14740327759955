<script lang="ts">
import type { Ref } from "vue";
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import get from "lodash-es/get";
import set from "lodash-es/set";
import {
  CalendarInput,
  FormInput,
  SearchDropdown,
} from "@/components/inputs/modalInputs";
import CustomCheckBox from "@/components/checkbox/CustomCheckBox.vue";
import { getDateRef, watchDate } from "@/utils/dates";
import { searchEducationalCorporation } from "@/http_services/entities/corporation";
import type { Corporation } from "@/entities/corporation";
import type { CertificationPayload } from "@/entities/Person/form";

export default defineComponent({
  name: "CertificationForm",
  components: {
    FormInput,
    CalendarInput,
    SearchDropdown,
    CustomCheckBox,
  },
  props: {
    certification: Object,
    certificationEdit: Object,
    certificationAutoFilledFields: Object,
    certificationAutoFilledEditFields: Object,
  },
  setup(props) {
    const { t } = useI18n();
    const payload = toRef(props, "certification") as Ref<CertificationPayload>;
    const parsedPayload = toRef(props, "certificationEdit") as Ref<CertificationPayload>;
    const searchInput = ref<InstanceType<typeof SearchDropdown>>(null);

    const expeditionDate = getDateRef<CertificationPayload>("expedition_date", payload);
    watch(expeditionDate, watchDate<CertificationPayload>("expedition_date", payload));

    const expirationDate = getDateRef<CertificationPayload>("expiration_date", payload);
    watch(expirationDate, watchDate<CertificationPayload>("expiration_date", payload));

    watch(
      payload,
      (value) => {
        const { expedition_date, expiration_date, institution } = value;

        if (!expedition_date)
          expeditionDate.value = null;
        if (!expiration_date)
          expirationDate.value = null;

        if (!institution?.name && searchInput.value)
          searchInput.value.setSearchValue("");
      },
      { deep: true },
    );

    const onChangeCheckboxValue = (event: Event) => {
      payload.value.is_lifelong = (event.target as HTMLInputElement).checked;
      expirationDate.value = null;
    };

    const selectInstitution = (corporation: { corporation: Corporation }) => {
      payload.value.institution = {
        name: corporation.corporation_data.name,
        corporation_id: corporation.corporation_id,
      };
    };

    const rules = computed(() => ({
      payload: {},
    }));

    const v$ = useVuelidate(rules, { payload });

    const removeOption = (key: string) => {
      if (props.certificationAutoFilledEditFields)
        set(props.certificationAutoFilledEditFields, key, false);
    };

    return {
      t,
      payload,
      parsedPayload,
      onChangeCheckboxValue,
      expeditionDate,
      expirationDate,
      searchInput,
      searchEducationalCorporation,
      selectInstitution,
      v$,
      get,
      removeOption,
    };
  },
});
</script>

<template>
  <div class="container">
    <div class="certification-form">
      <div class="form-group">
        <label> {{ t("persons.certificationForm.name") }}: </label>
        <div class="input-group">
          <FormInput
            v-model="payload.name"
            :auto-fill="certificationAutoFilledFields?.name"
            placeholder="Ex.: Certificação de Scrum Master"
            :error="get(v$, 'payload.name.$error', false)"
            :error-message="get(v$, 'payload.name.$errors.0.$message', '')"
          />
          <FormInput
            v-if="certificationAutoFilledEditFields?.name"
            v-model="parsedPayload.name"
            svg-icon="trash_icon"
            show-icon
            :auto-fill="certificationAutoFilledEditFields?.name"
            placeholder="Ex.: Certificação de Scrum Master"
            :error="get(v$, 'payload.name.$error', false)"
            :error-message="get(v$, 'payload.name.$errors.0.$message', '')"
            @click:on-button-click="removeOption('name')"
          />
        </div>
      </div>

      <div class="form-group">
        <label>{{ t("persons.certificationForm.institution") }}:</label>
        <SearchDropdown
          id="institute"
          ref="searchInput"
          fetch-param-key="term"
          :auto-fill="certificationAutoFilledFields?.institution"
          :fetch-method="searchEducationalCorporation"
          :select-item-callback="selectInstitution"
          :update-input-model="(item) => item.corporation_data?.name"
          :initial-value="parsedPayload?.institution?.name || parsedPayload?.institution?.corporation_data?.name"
          :input-placeholder="t('persons.workExperienceForm.placeholders.company')"
          :search-not-found-label="t('text.searchCorporationNotFound')"
          :search-error-label="t('text.searchCorporationError')"
          data-test="institute"
        >
          <template #list-item="{ item }">
            {{ item.corporation_data?.name }}
          </template>
        </SearchDropdown>
      </div>

      <div class="form-group">
        <label>{{ t("persons.certificationForm.field") }}:</label>
        <FormInput
          v-if="certificationAutoFilledEditFields?.field"
          v-model="parsedPayload.field"
          svg-icon="trash_icon"
          show-icon
          :auto-fill="certificationAutoFilledEditFields?.field"
          @click:on-button-click="removeOption('field')"
        />
        <FormInput
          v-else
          v-model="payload.field"
          :auto-fill="certificationAutoFilledFields?.field"
        />
      </div>

      <div class="form-group">
        <label>{{ t("persons.certificationForm.expeditionDate") }}:</label>
        <CalendarInput
          v-model="expeditionDate"
          :auto-fill="certificationAutoFilledFields?.expedition_date"
          view="month"
        />
      </div>

      <div class="form-group">
        <label>{{ t("persons.certificationForm.expirationDate") }}:</label>
        <CalendarInput
          v-model="expirationDate"
          :auto-fill="certificationAutoFilledFields?.expiration_date"
          view="month"
          :disabled="payload.is_lifelong"
        />

        <div class="checkbox-input">
          <CustomCheckBox
            :checked="payload.is_lifelong"
            :on-change="onChangeCheckboxValue"
          />
          <span>{{ t("persons.certificationForm.withoutExpirationDate") }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.input-group {
  width: 100%;
}

.certification-form:deep {
  .form-group {
    display: flex;
    margin-bottom: 1rem;

    label {
      position: relative;
      bottom: 5px;
      display: flex;
      width: 20%;
      height: 28px;
      align-items: flex-end;
      color: $gray-dark;
      font-family: "Open Sans";
      font-size: 14px;
      letter-spacing: 0.5px;

      .required {
        color: $red;
      }
    }

    .search-dropdown {
      flex-grow: 1;
    }

    .component-input {
      flex-grow: 1;

      .input-wrapper {
        input {
          font-family: "Open Sans";

          &::placeholder {
            font-size: 13px;
          }
        }
      }
    }

    .location-input {
      width: 220px;
      flex-grow: 0;
    }

    .checkbox-input {
      position: relative;
      display: flex;
      flex-grow: 1;
      align-items: center;
      justify-content: center;
      color: $gray-dark;
      font-family: "Open Sans";
      font-size: 13px;

      span {
        position: relative;
        line-height: 20px;
      }
    }
  }
}
</style>
