<script setup lang="ts">
import { SvgIcon } from "@/components/icon";
import { Button2 as Button, ButtonIcon } from "@/components/button";
import ImageCard from "@/components/image_card/ImageCard.vue";
import type { Person, WorkExperience } from "@/schemas/person";
import type { Maybe } from "@/http_services/types";
import { Tooltip } from "@/components/tooltip";
import { Avatar } from "@/components/avatar";

interface Props {
  projectName: string
  corporationName: string
  candidate: Ref<Maybe<Person>>
  recruiter: Ref<Maybe<Person>>
  job?: Ref<WorkExperience>
  draft?: boolean
  disabled?: boolean
  loading?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  draft: false,
  loading: false,
});
const emit = defineEmits([
  "click:logo",
  "click:cancel",
  "click:save",
  "click:temp",
  "click:profile",
  "onSelectAddRequester",
]);
const { t } = useI18n();

const { projectName, corporationName, candidate } = toRefs(props);
</script>

<template>
  <div class="header">
    <ButtonIcon type="button" @click="emit('click:logo')">
      <SvgIcon icon="imua_logo" width="122px" height="50px" />
    </ButtonIcon>
    <div class="content">
      <div class="names">
        <p>
          <strong>{{ t("header.project") }}:</strong>
          {{ projectName }}
        </p>
        <p>
          <strong>{{ t("header.company").replace("s", "") }}:</strong>
          {{ corporationName }}
        </p>
      </div>
      <div class="header__avatar--divisor" />

      <div v-if="draft" class="header__avatar--draft">
        <div class="header__avatar--button-draft" @click="emit('onSelectAddRequester')">
          <div class="draft-icon">
            <SvgIcon icon="plus" width="2.125rem" height="2.125rem" margin="none" />
          </div>
          {{ t("projects.mapping.card.addCandidate") }}
        </div>
      </div>
      <div v-else class="profile">
        <div>
          <p class="name">
            {{ candidate?.first_name }} {{ candidate?.last_name }}
          </p>
          <p v-if="job && job.role_name">
            <strong>{{ job.role_name }}</strong>
            <span v-if="job.company?.corporation_data?.name"> -</span> {{ job.company?.corporation_data?.name }}
          </p>
        </div>

        <ImageCard
          v-if="candidate"
          class="image-card"
          :image-url="
            candidate?.avatar_url ? `${candidate?.avatar_url}?version=${candidate?._version}` : ''
          "
          :name="`${candidate?.first_name} ${candidate?.last_name}`"
          border-color="transparent"
          size="50px"
          @click="emit('click:profile')"
        />
        <div class="header__avatar--divisor" />
        <Tooltip
          v-if="recruiter"
          :tooltip-title="`${recruiter?.first_name} ${recruiter?.last_name}`"
          x-align="center"
          tooltip-width="max-content"
          @click="emit('click:profile')"
        >
          <Avatar
            class="tooltip-list__avatar"
            :src="
              recruiter?.avatar_url ? `${recruiter?.avatar_url}?version=${recruiter?._version}` : ''"
            placeholder="project_ideal_profile"
            type="native"
          />
        </Tooltip>
      </div>
    </div>
    <div class="buttons">
      <div class="loader-wrapper">
        <div v-show="loading" class="loader" />
      </div>
      <Button
        variation="light"
        filled
        size="large"
        class="cancel"
        @click="emit('click:cancel')"
      >
        {{ t("text.close") }}
      </Button>

      <Button
        :disabled="disabled || loading"
        variation="primary"
        filled
        size="large"
        @click="emit('click:save')"
      >
        {{ t("text.form.finalize") }}
      </Button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.header {
  display: flex;
  height: 6.875rem;
  align-items: center;
  justify-content: space-between;
  padding-inline: 1rem;
  &__avatar {
    &--container {
      display: flex;
      flex: 1;
      justify-content: center;
    }

    &--list {
      position: relative;
      display: inline-flex;
      gap: 0.5rem;
    }

    &--divisor {
      height: 4rem;
      border-left: 2px solid rgb(102 102 102 / 20%);
      margin: 0 1rem;
    }

    &--button-draft {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 1rem;
      color: $gray-text;
      font-size: 1.125rem;
      font-weight: 600;

      .draft-icon {
        display: flex;
        width: 3.125rem;
        height: 3.125rem;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: $gray-x18;

        svg {
          color: $gray-x19;
        }
      }
    }

    &--button-draft:hover {
      color: $gray-dark;

      .draft-icon {
        background: $gray-x19;

        svg {
          color: $gray-x18;
        }
      }
    }
  }

  .content {
    display: flex;
    flex-grow: 1;
    align-items: center;
    margin-left: 2rem;

    .profile {
      display: flex;
      align-items: center;
      padding-left: 1rem;

      p {
        margin-left: 1rem;
      }

      .image-card {
        cursor: pointer;
        margin-inline-start: 1.25rem;
      }

      .name {
        margin-bottom: 0.5rem;
        color: $primary;
        font-weight: bold;
      }

      img {
        width: 50px;
        height: 50px;
        border-radius: 100%;
      }
    }

    .names {
      padding-right: 1rem;

      p {
        &:first-child {
          margin-bottom: 0.5rem;
        }
      }
    }
  }

  .buttons {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
}

:deep(.tooltip-list__avatar) {
  border: 4px solid $primary;
  // box-sizing: content-box;
}
p {
  margin: 0;
}
.name {
  margin-inline-end: 5rem;
}

.loader-wrapper {
  width: 2rem;
  height: 2rem;
}
.loader {
  width: 2rem;
  height: 2rem;
  border: 5px solid;
  border-color: $yellow transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
