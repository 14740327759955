<script lang="ts">
import type { PropType } from "vue";
import { defineComponent, onMounted, ref, toRef, watch } from "vue";
import { useI18n } from "vue-i18n";
import { CustomCheckBox } from "@/components/checkbox";

interface Selection {
  [key: string]: boolean
}

export default defineComponent({
  name: "ChecklistFilter",
  components: {
    CustomCheckBox,
  },
  props: {
    options: {
      type: Array as PropType<{ label: string; value: string }[]>,
      required: true,
    },
    initialValues: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    variation: {
      type: String,
      default: "primary",
    },
  },
  emits: ["filter:check"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const displayableElementsCount = ref(5);
    const initialValues = toRef(props, "initialValues");

    const getInitialValues = () => {
      const values: Selection = {};

      return Object.values(initialValues.value || []).reduce((hash, next) => {
        hash[next] = true;
        return hash;
      }, values);
    };
    const selected = ref<Selection>(getInitialValues());

    const selectOption
      = (option: string) => ({ target }: { target: HTMLInputElement }) => {
        selected.value[option] = !!target.checked;
      };

    const clearAll = () => {
      selected.value = {};
      emit("filter:check", []);
    };

    watch(
      selected,
      (value) => {
        const filtered = Object.entries(value)
          .filter(([, bool]) => !!bool)
          .map(([key]) => key);

        emit("filter:check", filtered);
      },
      { deep: true },
    );
    onMounted(() => {
      clearAll();
    });
    return {
      t,
      selected,
      selectOption,
      clearAll,
      displayableElementsCount,
    };
  },
});
</script>

<template>
  <div class="filter-wrapper">
    <CustomCheckBox
      v-for="option in options.slice(0, displayableElementsCount)"
      :key="option.label"
      :label="option.label"
      :checked="selected[option.value]"
      :on-change="selectOption(option.value)"
    />
    <div v-show="displayableElementsCount < options.length" class="load-more">
      <span role="button" @click="displayableElementsCount += 5">
        <b>{{ t("text.filters.loadMore") }}</b>
      </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.filter-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  gap: 1rem;
}

.load-more {
  font-size: 0.875rem;

  span b {
    color: $yellow;
  }
}

:deep(.checkbox) {
  &:checked {
    border-color: $secondary;
    background-color: $secondary;
  }
}
</style>
