<script lang="ts">
import HeaderDetails from "./components/HeaderDetails.vue";
import HeaderEditable from "./components/HeaderEditable.vue";

export default defineComponent({
  components: {
    HeaderDetails,
    HeaderEditable,
  },
  emits: ["update:project"],
  setup(props, { emit }) {
    const editing = inject("editing");
    const toggleEdit: (a: string) => void = inject("toggleEdit");

    provide("onShowDetails", () => toggleEdit("header"));

    function emitUpdateProjectList() {
      emit("update:project");
    }
    return { editing, emitUpdateProjectList };
  },
});
</script>

<template>
  <div class="header-wrapper">
    <HeaderEditable v-if="editing === 'header'" />
    <HeaderDetails v-else @update:project="emitUpdateProjectList" />
  </div>
</template>

<style lang="scss" scoped>
.header-wrapper:deep {
  display: flex;
  width: 100%;

  .avatar-section {
    .image-card {
      width: 180px;
      height: 180px;
      font-size: 2.5rem;
    }

    .refresh-date {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
      margin-left: -15px;
      color: $gray;
      font-size: 12px;
      text-align: center;
    }

    .icon {
      margin-right: 10px;
    }

    .icon svg {
      width: 12px;
      margin-left: 15px;
      stroke: $gray;
    }
  }
}
</style>
