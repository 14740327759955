<script setup lang="ts">
import { format, parseISO } from "date-fns";
import type { Person } from "@/schemas/person";
import { SvgIcon } from "@/components/icon";
import PersonDetail from "@/persons/pages/persons/components/PersonDetail/PersonDetail.vue";
import { getPerson } from "@/http_services/entities/persons";
import { Tooltip } from "@/components/tooltip";
import { changeImageSize } from "@/utils/images/resize-images";
import InfoDescription from "@/components/person_card/components/InfoDescription/InfoDescription.vue";

const props = withDefaults(
  defineProps<{
    person: Person | null
    active?: boolean
    candidateRating?: string | null
    showDetails?: boolean
    ratingBlock?: boolean
  }>(),
  {
    person: null,
    active: false,
    showDetails: false,
    ratingBlock: false,
  },
);

const emit = defineEmits(["click:avatar", "go:activity", "change:rating"]);

const { t } = useI18n();

const { person, active, showDetails, ratingBlock, candidateRating } = toRefs(props);

const avatar = computed(() => changeImageSize(person.value?.avatar_url, "large"));
const personName = computed(() => `${person.value?.first_name} ${person.value?.last_name}`);
const personInitials = computed(() => {
  const first = person.value?.first_name?.substring(0, 1);
  const last = person.value?.last_name?.substring(0, 1);
  return `${first}${last}`;
});
const lastUpdated = computed(() => {
  try {
    const { inserted_at, updated_at } = person.value || {};
    const date = updated_at ? parseISO(updated_at) : parseISO(inserted_at || "");
    return format(date, "dd/MM/yyyy");
  }
  catch (_) {
    return "";
  }
});
const workExperience = computed(() => {
  const experiences = person.value?.work_experience || [];
  return experiences[0];
});
const backgroundColor = computed(() => (person.value?.avatar_url ? "#f4f4f4" : "#666"));

const ratingEdit = ref(false);
const rating = ref<string | null>(candidateRating?.value || null);
const ratingList = ref(["-", "IN", "A+", "A", "A-", "B", "C", "D", "F"]);

function editRating() {
  if (ratingBlock.value)
    return;
  ratingEdit.value = true;
}

function changeRating(value: string) {
  rating.value = value === "-" ? null : value;
  ratingEdit.value = false;
  emit("change:rating", { person_id: person.value?.id, rating: value });
}

function getYear(date: string | null | undefined) {
  if (!date)
    return "atual";
  return format(parseISO(date), "yyyy");
}

const showPersonDetail = ref(false);
const selectedPerson = ref(undefined);

async function onSelectRequester(id: string) {
  const data = await getPerson(id);
  if (data) {
    selectedPerson.value = toRaw(data);
    showPersonDetail.value = true;
  }
}
provide("onCloseDetails", () => {
  showPersonDetail.value = false;
  selectedPerson.value = undefined;
});

provide("selectedPerson", selectedPerson);
function getBorderStyle() {
  const isAddedInPlacement = person.value?.is_added_in_placement;
  const addedInMapping = person.value?.added_in_mapping;

  return isAddedInPlacement ? { border: "3px solid #040404" } : addedInMapping ? { border: "3px solid #fc0" } : {};
}
</script>

<template>
  <div>
    <div class="candidate-card">
      <div v-if="!ratingEdit" class="rating" :class="{ active }">
        <div v-if="!rating" @click="editRating()">
          <Tooltip class="tooltip" :tooltip-text="t('projects.details.activities.tabsCandidatesEnum.priorization')">
            <SvgIcon icon="icon_rating" margin="none" width="1.3125rem" height="1.3125rem" />
          </Tooltip>
        </div>
        <div v-else @click="editRating()">
          {{ rating }}
        </div>
      </div>
      <div v-else class="rating-edit" :class="{ active }">
        <div v-for="(item, index) in ratingList" :key="index" :class="{ center: item === '-' }" @click="changeRating(item)">
          {{ item }}
        </div>
      </div>
      <div class="person-data">
        <div class="person-data-avatar">
          <div
            class="avatar"
            :style="getBorderStyle()"
            @click="onSelectRequester(person?.id)"
          >
            <img
              v-if="person?.avatar_url"
              :src="avatar"
              :alt="personName"
              loading="lazy"
            >
            <p v-else class="initials">
              {{ personInitials }}
            </p>
          </div>
          <div class="last-update">
            <SvgIcon icon="refresh_icon" width="0.75rem" height="0.75rem" />{{ lastUpdated }}
          </div>
        </div>
        <div class="person-data-details">
          <div class="details-header">
            <div class="person-data-details-candidate">
              <span class="candidate-name">{{ personName }}</span>
              <span class="candidate-code">({{ person?.serial }})</span>
            </div>
            <div class="person-data-actions">
              <slot name="actions" />
            </div>
          </div>

          <InfoDescription
            :is-person-card="true"
            :person="person"
          />
        </div>
      </div>
    </div>
    <div v-if="showDetails" class="candidate-card-details">
      <slot name="details" />
    </div>
  </div>
  <PersonDetail :visible="showPersonDetail" />
</template>

<style lang="scss" scoped>
$avatar_size: 8.75rem;

.candidate-card {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  border-radius: 0.5rem;
  background: $white-full;
  outline: none;
  box-shadow: 0 2px 4px #0003;

  .rating {
    display: flex;
    flex-direction: column;
    width: 3rem;
    align-items: center;
    justify-content: center;
    border-right: 1px solid $gray-border;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    padding-block: 1.5rem;

    div {
      cursor: pointer;
      color: $primary;
      font-size: 1.125rem;
      font-weight: bold;

      svg {
        color: $gray-border;
      }
    }

    div:hover {
      svg {
        color: $gray-dark;
      }
    }
  }

  .rating-edit {
    display: flex;
    flex-direction: column;
    width: 3rem;
    align-items: flex-start;
    justify-content: flex-start;
    border-right: 1px solid $gray-border;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    padding-block: 1.5rem;
    gap: 0.875rem;
    overflow-x: hidden;
    overflow-y: auto;

    div {
      cursor: pointer;
      width: 1rem;
      height: 1rem;
      margin-left: 1rem;
      color: $gray-text;
      font-size: 0.875rem;
      text-align: left;
    }

    div:hover {
      color: $primary;
      font-weight: bold;
    }

    div.center {
      margin-left: 1.25rem;
    }
  }

  .rating-edit.active,
  .rating.active {
    background: $yellow;
    color: $white-full;

    svg {
      color: $white-full;
    }

    div {
      color: $white-full;
      svg {
        color: $white-full;
      }
    }
  }
  .person-data {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 2.5rem;
    padding-inline: 1.875rem;
    padding-block: 1.25rem;

    .details-header {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      gap: 0.5rem;
    }

    &-avatar {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: 0.625rem;

      .avatar {
        cursor: pointer;
        display: flex;
        width: $avatar_size;
        height: $avatar_size;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        background: v-bind("backgroundColor");
        box-sizing: unset;

        img {
          width: $avatar_size;
          height: $avatar_size;
          border-radius: 100%;
        }

        .initials {
          color: #fff;
          font-size: 2rem;
        }
      }

      .last-update {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.625rem;
      }
    }

    &-details {
      display: flex;
      flex-direction: column;
      flex: 1;
      gap: 0.5rem;

      &-candidate {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 1rem;

        .candidate-name {
          color: $primary;
          font-size: 1.125rem;
          text-transform: initial;
          letter-spacing: 0.63px;
        }

        .candidate-code {
          color: $gray-dark;
          padding-block-start: 0.35rem;
          font-size: 0.75rem;
        }
      }

      &-role {
        color: $gray-dark;
        font-size: 0.875rem;
        letter-spacing: 0.49px;
      }

      &-corporation {
        display: flex;
        flex-direction: row;

        span {
          font-size: 0.8125rem;
        }

        span.bold {
          font-weight: bold;
        }
      }
    }

    &-actions {
      display: flex;
      flex-direction: column;
      gap: 0.625rem;

      &-buttons {
        font-size: 0.75rem;
      }
    }
  }

  .rating-edit::-webkit-scrollbar {
    display: none;
  }
}

.candidate-card-details {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  padding: 1.56rem;
  margin-top: -0.5rem;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  background: $white-full;
  box-shadow: 0 2px 4px #0003;
}
.tooltip:deep {
  height: 26px;
  .tooltip-text {
    padding: 0.5rem;
    width: auto;
    font-weight: bold;
    text-transform: uppercase;

  }
}
:slotted(.tooltip) {
  top: 0;

  .tooltip-text {
    top: -4.375rem;
    left: 0;
    padding: 0.5rem;
    width: 16rem;
    font-weight: bold;
    white-space: nowrap;
    text-align: center;
    font-size: 1rem;
  }
}
</style>
