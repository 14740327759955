<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { ComingSoon } from "@/components/coming-soon";

const { t } = useI18n();
</script>

<template>
  <div class="coming-soon">
    <ComingSoon
      :height="250"
      :title="t('text.comingSoonTitle')"
      :paragraph="t('text.comingSoonParagraph')"
      class="center"
    />
  </div>
</template>

<style lang="scss" scoped>
.coming-soon {
  padding-block-start: 1rem;
  transform: scale(0.7);
}
</style>
