import { z } from "zod";

const ConstructionTagSchema = z.object({
  text: z.string(),
  selected: z.boolean().nullable().default(true),
});

enum ScaleEnum {
  EMPTY = "EMPTY",
  LOW = "LOW",
  AVERAGE = "AVERAGE",
  HIGH = "HIGH",
  VERY_HIGH = "VERY_HIGH",
}

const ScaleEnumSchema = z.nativeEnum(ScaleEnum);

const TurnOverFactorsSchema = z.object({
  attraction: z.string().optional().default(""),
  repulsion: z.string().optional().default(""),
  retention: z.string().optional().default(""),
});

const AssemblyPackageSchema = TurnOverFactorsSchema.extend({
  salary: z.string().optional().default(""),
  short_term_incentive: z.string().optional().default(""),
  long_term_incentive: z.string().optional().default(""),
  other_incentive: z.string().optional().default(""),
  total_cash: z.string().optional().default(""),
});

enum AttributesMapEnums {
  EMPTY = "EMPTY",
  COGNITIVE_INTELLIGENCE = "COGNITIVE_INTELLIGENCE",
  EMOTIONAL_INTELLIGENCE = "EMOTIONAL_INTELLIGENCE",
  SPIRITUAL_INTELLIGENCE = "SPIRITUAL_INTELLIGENCE",
  ENERGY = "ENERGY",
  MATURITY = "MATURITY",
  POWER = "POWER",
}

const AttributesSchema = z.object({
  cognitive_intelligence: ScaleEnumSchema.default(ScaleEnum.EMPTY),
  emotional_intelligence: ScaleEnumSchema.default(ScaleEnum.EMPTY),
  spiritual_intelligence: ScaleEnumSchema.default(ScaleEnum.EMPTY),
  energy: ScaleEnumSchema.default(ScaleEnum.EMPTY),
  maturity: ScaleEnumSchema.default(ScaleEnum.EMPTY),
});

const CulturalMatchSchema = z.object({
  practical: ScaleEnumSchema.default(ScaleEnum.EMPTY),
  logical: ScaleEnumSchema.default(ScaleEnum.EMPTY),
  reflexive: ScaleEnumSchema.default(ScaleEnum.EMPTY),
  affective: ScaleEnumSchema.default(ScaleEnum.EMPTY),
});

enum CulturalMatchMapEnums {
  EMPTY = "EMPTY",
  PRACTICAL = "PRACTICAL",
  LOGICAL = "LOGICAL",
  REFLEXIVE = "REFLEXIVE",
  AFFECTIVE = "AFFECTIVE",
}

const AchievementsSchema = z.object({
  description: z.string().default(""),
  desired_attribute: z.nativeEnum(AttributesMapEnums).default(AttributesMapEnums.EMPTY),
  desired_cultural_match: z.nativeEnum(CulturalMatchMapEnums).default(CulturalMatchMapEnums.EMPTY),
});

export const IdealProfileSchema = z.object({
  academic_backgrounds: z.record(z.string()).default({}),
  languages: z.record(z.string()).default({}),
  // todo: fix records types
  experiences: z.record(z.string()).default({}),
  experiences_tags: z.array(ConstructionTagSchema).nullable().default([]),
  achievements: z.record(AchievementsSchema).default({}),
  achievements_tags: z.array(ConstructionTagSchema).nullable().default([]),
  attributes: AttributesSchema.default({}),
  cultural_match: CulturalMatchSchema.default({}),
  package: AssemblyPackageSchema,
  career: z.record(z.string()).default({}),
  work_environment: z.record(z.string()).default({}),
  personal_life: z.record(z.string()).default({}),
  consultant_opinion: z.string().default(""),
}).strict();

export type IdealProfile = z.infer<typeof IdealProfileSchema>;

export const IdealProfilePatchSchema = IdealProfileSchema.partial();

export type IdealProfilePatch = z.infer<typeof IdealProfilePatchSchema>;
