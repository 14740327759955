export const errors = {
  titles: {
    error: "Erro!",
    ops: "Ops!",
  },
  buttons: {
    tryAgain: "Tentar novamente",
    close: "Fechar",
  },
  messages: {
    save: "Houve um erro ao tentar salvar o projeto!",
    dates: "Informação inválida, data de início não pode ser maior que a data de fim.",
  },
  pdf: {
    download: "Houve um erro ao tentar exportar o PDF!",
  },
};
