<script setup lang="ts">
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiPlusCircleOutline } from "@mdi/js";
import { cloneDeep } from "lodash";
import SimpleMaskMoney from "simple-mask-money";
import { onMounted, ref, toRefs } from "vue";
import { useI18n } from "vue-i18n";
import { moneyArgs } from "@/utils/adapters/form";
import { CURRENCY, SALARYCURRENCY } from "@/entities/common";
import SelectCurrency from "@/components/inputs/moneyInputs/SelectCurrency.vue";
import { FormInput } from "@/components/inputs/modalInputs";
import { Button2 as Button } from "@/components/button";
import TrashIcon from "@/assets/images/trash_icon.svg";

const props = defineProps<{
  revenues: RevenueModel[]
  editing?: boolean
  isCorporationProfile?: boolean
}>();

const emit = defineEmits(["submit:revenues", "update:revenues"]);

const { t } = useI18n();

interface RevenueModel {
  amount: number
  year: string
  currency: string
  max_amount: number
}

const { revenues, editing } = toRefs(props);

const opened = ref(false);
const edit = ref(editing.value || false);

const payload = ref(cloneDeep(revenues.value) || []);

function formatFloatToReal(data: number, currency: string) {
  return SimpleMaskMoney.formatToMask(Number.parseFloat(String(data)).toFixed(2), {
    allowNegative: false,
    negativeSignAfter: false,
    prefix: "",
    suffix: "",
    fixed: true,
    fractionDigits: currency ? moneyArgs[currency].fractionDigits : 2,
    decimalSeparator: currency ? moneyArgs[currency].decimalSeparator : ",",
    thousandsSeparator: currency ? moneyArgs[currency].thousandsSeparator : ".",
    cursor: "move",
  });
}

function getRevenueValueShow(data: RevenueModel) {
  return `${data.year} - ${SALARYCURRENCY[data.currency as never]} ${formatFloatToReal(
    data.amount,
    data.currency,
  )}`;
}

function toggleOpened() {
  opened.value = !opened.value;
}

function removeItem(index: number) {
  payload.value.splice(index, 1);
  emit("update:revenues");
}

function cancelEdit() {
  payload.value = cloneDeep(revenues.value);
  edit.value = false;
}

function addItem() {
  payload.value.push({
    amount: 0.0,
    year: "",
    currency: CURRENCY.BRL,
    max_amount: 0.0,
  });
}

function formatValueFloat(data: number) {
  const valueNumber = String(data).replace(/[^0-9]/g, "");
  if (valueNumber.length > 1) {
    const value = valueNumber.substring(0, valueNumber.length - 2);
    const centave = valueNumber.substring(valueNumber.length - 2, valueNumber.length);
    const valueFormat = Number.parseFloat(String(`${value}.${centave}`)).toFixed(2);
    return valueFormat;
  }
  else {
    return `0.0${valueNumber}`;
  }
}

function submitItem() {
  payload.value.map((item) => {
    item.amount = formatValueFloat(item.amount);
    return item;
  });
  emit("submit:revenues", payload.value);
  edit.value = false;
}

onMounted(() => {
  payload.value.map((item) => {
    item.amount = Number.parseFloat(String(item.amount)).toFixed(2);
    item.max_amount = Number.parseFloat(String(item.max_amount || 0)).toFixed(2);
    return item;
  });
  if (!payload.value?.length)
    addItem();
});
onUpdated(() => {
  if (props.isCorporationProfile) {
    payload.value.map((item) => {
      item.amount = formatValueFloat(item.amount);
      return item;
    });
    emit("submit:revenues", payload.value);
    edit.value = false;
    return item;
  }
  submitItem();
});
</script>

<template>
  <div>
    <div class="revenue-box editing">
      <div class="revenue-form">
        <div class="revenue-label">
          {{ t("corporations.details.currentRevenue") }}:
        </div>
        <div class="revenue-content">
          <div v-for="(item, index) in payload" :key="index" class="revenue-items">
            <SelectCurrency
              :model-value="item.currency"
              @change="(item.currency = $event, $emit('update:revenues'))"
            />
            <FormInput
              v-model="item.amount"
              :placeholder="t('corporations.details.forms.placeholders.netWorth')"
              :money="true"
              @change="$emit('update:revenues')"
            />
            <div class="form-row">
              <FormInput
                v-model="item.year"
                mask="####"
                :placeholder="t('corporations.details.forms.placeholders.year')"
                @change="$emit('update:revenues')"
              />
              <span>
                <TrashIcon @click="removeItem(index)" />
              </span>
            </div>
          </div>
          <div class="revenue-add">
            <span @click="addItem()">
              <SvgIcon type="mdi" :path="mdiPlusCircleOutline" size="16" />
              {{ t("corporations.details.forms.placeholders.addYear") }}
            </span>
          </div>
        </div>
      </div>
      <div v-show="!isCorporationProfile" class="revenue-actions">
        <Button @click="submitItem()">
          {{ t("text.form.submit") }}
        </Button>

        <Button variation="light" filled @click="cancelEdit()">
          {{ t("text.form.cancel") }}
        </Button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.revenue-box.editing {
  flex-direction: column;

  .revenue-form {
    display: flex;
    width: 100%;
    flex-direction: row;

    .revenue-content {
      border: none;

      .revenue-items {
        display: grid;
        gap: 0.5rem;
        grid-template-columns: 1fr 2fr 1fr;

        .form-row {
          display: flex;
          flex-direction: row;

          span {
            width: 1rem;

            svg {
              width: 1rem;
              margin-left: 0.5rem;
              cursor: pointer;
            }
          }
        }
      }

      .revenue-add {
        display: flex;
        justify-content: flex-end;

        span {
          width: inherit;
          color: $primary;
          font-size: 0.85rem;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 8px;
          cursor: pointer;
        }
      }
    }
  }

  .revenue-actions {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
    margin: 1.1rem 0;
    gap: 0.85rem;
  }
}

.revenue-box {
  display: flex;
  width: 100%;
  align-items: flex-start;

  .revenue-label {
    min-width: 8rem;
  }

  .revenue-content {
    display: flex;
    width: 100%;
    flex-direction: column;
    border: 1px solid $gray-light;
    border-radius: 8px;

    .revenue-items {
      display: flex;
      width: 100%;

      .item {
        display: flex;
        width: 100%;
        min-height: 2rem;
        align-items: center;
        justify-content: space-between;
        padding: 5px 10px;
        cursor: pointer;
      }
    }
  }

  .revenue-edit {
    display: flex;
    width: 3rem;
    align-items: center;
    justify-content: flex-end;

    svg {
      width: 1rem;
      cursor: pointer;
    }
  }
}
</style>
