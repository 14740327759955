<script setup lang="ts">
import { ref } from "vue";
import SvgIcon from "@/components/icon/Icon.vue";

const props = defineProps({
  href: { required: true, type: String },
});

const hover = ref(false);

const onHover = () => (hover.value = true);
const onLeave = () => (hover.value = false);

const svgAttrs = {
  width: 24,
  height: 24,
};
</script>

<template>
  <a :href="props.href" target="_blank" @mouseenter="onHover" @mouseleave="onLeave">
    <SvgIcon v-show="!hover" icon="linkedin_icon" v-bind="svgAttrs" />
    <SvgIcon v-show="hover" icon="linkedin_icon_blue" v-bind="svgAttrs" />
  </a>
</template>
