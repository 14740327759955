<script lang="ts">
import type { Ref } from "vue";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required, requiredIf, url as urlValidator } from "@vuelidate/validators";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import _ from "lodash";
import SimpleMaskMoney from "simple-mask-money";
import Dropdown from "primevue/dropdown";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiClose, mdiPlusCircleOutline } from "@mdi/js";
import { PageWrapper } from "@/components/page_wrapper";
import { ContentHeader } from "@/components/content_header";
import { Card } from "@/components/card";
import { SectionTitle } from "@/components/section_title";
import { SectionContent } from "@/components/section_content";
import { Button2, ButtonIcon } from "@/components/button";
import {
  FormInput,
  SearchDropdown,
  Select,
  SimpleAvatarInput,
  TelephoneInput,
  TextArea,
} from "@/components/inputs/modalInputs";
import { RadioButton, RadioSimple } from "@/components/radio";
import { CustomCheckBox } from "@/components/checkbox";
import { Modal } from "@/components/modal";
import type {
  Corporation,
  InvestorAndParentCompany,
  SubsidiaryAndInvestedCompany,
} from "@/entities/corporation";
import {
  createCorporation,
  searchCorporation,
  toEducationalOrganization,
  updateCorporation,
} from "@/http_services/entities/corporation";
import CompanyIcon from "@/assets/images/general-company.svg";
import WarningIcon from "@/assets/images/alert_circle_icon.svg";
import { marketRelationship } from "@/entities/corporation/sector";
import TrashIcon from "@/assets/images/trash_icon.svg";
import { registeredCountries } from "@/utils/adapters/form/consts";
import { ImagesTypesEnum } from "@/schemas/enums/images";
import { createAvatar } from "@/http_services/files/avatar";
import { moneyCurrencies } from "@/utils/adapters/form";
import type { RelatedCorporation, RelatedPerson } from "@/entities/common";
import { EducationalOrganizationTypesEnum } from "@/schemas/enums/corporation";

export default defineComponent({
  name: "CreateView",
  components: {
    Dropdown,
    PageWrapper,
    ContentHeader,
    Card,
    SectionTitle,
    SectionContent,
    Button: Button2,
    ButtonIcon,
    Modal,
    TextArea,
    FormInput,
    Select,
    TelephoneInput,
    SearchDropdown,
    RadioButton,
    RadioSimple,
    CustomCheckBox,
    SimpleAvatarInput,
    CompanyIcon,
    WarningIcon,
    TrashIcon,
    SvgIcon,
  },
  setup() {
    const { t, tm, locale } = useI18n();
    const route = useRoute();
    const localeLang = locale.value.split("-")[0];
    const hasSubmitionError = ref(false);
    const store = useStore();
    const router = useRouter();
    const clearConfirmation = ref(false);
    const submitStatus = ref(false);
    const initialFormData: Partial<Corporation> = {
      name: null,
      description: null,
      url: "https://",
      address: { name: null },
      phone: {
        number: "",
        calling_code: "+ 55",
      },
      facebook_url: null,
      linkedin_url: null,
      business_categories: [
        {
          sector: null,
          industry: null,
          is_main: true,
        },
      ],
      revenues: [
        {
          currency: "BRL",
          amount: 0,
          year: "",
        },
      ],
      governance: {
        headquarters: "BR",
        business_type: null,
        business_level: null,
        investor_and_parent_companies: [],
        subsidiaries_and_invested_companies: [],
        economic_group: [],
      },
      patrimony: {
        currency: "BRL",
        amount: 0,
      },
    };

    const avatarInput = ref<InstanceType<typeof SimpleAvatarInput> | null>(null);
    const avatarBase64 = ref(null);

    const changeAvatar = (base64) => {
      avatarBase64.value = base64;
    };

    const macroOptions = Object(tm("corporations.details.macroMarket.enum"));
    const allMicroOptions = Object(tm("corporations.details.microMarket.enum"));
    const microOptions = Object.entries(marketRelationship).reduce(
      (macroObj, [macroKey, macroValues]) => {
        macroObj[macroKey] = macroValues.reduce((microObj, microValue) => {
          microObj[microValue] = allMicroOptions[microValue];
          return microObj;
        }, {});
        return macroObj;
      },
      {},
    );

    const state = ref(_.cloneDeep(initialFormData));

    // revenues
    const appendRevenue = () => {
      state.value.revenues.push({
        currency: "BRL",
        amount: 0,
        year: "",
      });
    };

    const removeRevenue = (index) => {
      state.value.revenues.splice(index, 1);
    };

    // governance
    const selectedHeadquarters = ref(state.value.governance.headquarters);

    const businessHeadquarters = computed(() =>
      Object.entries(tm("corporations.details.headquarters.enum"))
        .filter(([key]) => key !== "OTHER_WITH_COUNTRY")
        .map(([value, label]) => ({ label, value })));

    const educationalOrganizationType = ref(EducationalOrganizationTypesEnum.PRIVATE);

    const isEducationalOrganization = ref("no");

    const educationalOrganizationEnum = [
      { label: t("text.form.no"), value: "no" },
      { label: t("text.form.yes"), value: "yes" },
    ];

    const educationalOrganizationTypes = computed(() =>
      Object.entries(tm("corporations.enums.educationalOrganizationTypes"))
        .map(([value, label]) => ({ label, value })));

    const countriesHeadquarters = computed(() =>
      Object.entries(registeredCountries.getNames(localeLang))
        .filter(([key]) => key !== "BR")
        .map(([value, label]) => ({ label, value })));

    watch(selectedHeadquarters, (value: string) => {
      state.value.governance.headquarters = value !== "OTHER" ? value : "";
    });

    const businessTypes = computed(() => tm("corporations.details.type.enum"));
    const businessLevels = computed(() => tm("corporations.details.level.enum"));

    const hasEconomicGroup = ref<boolean>(false);

    const selectEconomicGroupCorporation = (corporation: Corporation): void => {
      const { name, business_categories, id } = corporation;
      const { economic_group } = state.value.governance;

      if (economic_group?.length < 5) {
        (economic_group as RelatedCorporation[]).push({
          name,
          business_categories,
          corporation_id: id,
        });
      }
    };

    const removeCorporationFromEconomicGroup = (corporationIndex: number): void => {
      const { economic_group } = state.value.governance;
      economic_group.splice(corporationIndex, 1);
    };

    const updateCorporationInput = (corporation: Corporation): string => corporation.name;

    const selectInvestorAndParent = (corporation: Corporation, investorIndex: number): void => {
      const { name, business_categories, id } = corporation;
      const currentInvestor = state.value.governance.investor_and_parent_companies[
        investorIndex
      ] as InvestorAndParentCompany;

      state.value.governance.investor_and_parent_companies[investorIndex] = {
        ...currentInvestor,
        corporation_id: id,
        main_investor: false,
        business_equity_type: null,
        name,
      };
    };

    const removeInvestor = (investorIndex: number) => {
      if (state.value.governance.investor_and_parent_companies.length === 1) {
        state.value.governance.investor_and_parent_companies[0] = {
          corporation: { name: "" },
        };
      }
      else {
        state.value.governance.investor_and_parent_companies.splice(investorIndex, 1);
      }
    };

    const lastInvestor = computed(() => {
      const { investor_and_parent_companies: ipc } = state.value.governance;
      return ipc?.[ipc.length - 1];
    });

    const businessEquityInvestorTypes = computed(() =>
      tm("corporations.details.businessEquityType.enum.investor"));

    const selectSubsidiaryAndInvestedCompany = (
      corporation: Corporation,
      subsidiaryIndex: number,
    ): void => {
      const { name, business_categories, id } = corporation;
      const currentInvestorValues = state.value.governance.subsidiaries_and_invested_companies[
        subsidiaryIndex
      ] as SubsidiaryAndInvestedCompany;

      state.value.governance.subsidiaries_and_invested_companies[subsidiaryIndex] = {
        ...currentInvestorValues,
        main_investor: false,
        corporation_id: id,
        business_equity_type: null,
        name,
      };
    };

    const removeSubsidiary = (subsidiaryIndex: number) => {
      if (state.value.governance.subsidiaries_and_invested_companies.length === 1) {
        state.value.governance.subsidiaries_and_invested_companies[0] = {
          corporation: { name: "" },
        };
      }
      else {
        state.value.governance.subsidiaries_and_invested_companies.splice(subsidiaryIndex, 1);
      }
    };

    const lastSubsidiary = computed(() => {
      const { subsidiaries_and_invested_companies: sic } = state.value.governance;
      return sic?.[sic.length - 1];
    });

    const businessEquitySubsidiaryTypes = computed(() =>
      tm("corporations.details.businessEquityType.enum.subsidiary"));

    const openClearModal = () => {
      clearConfirmation.value = true;
    };

    const linkedinValidator = helpers.regex(
      /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile|company)/,
    );

    const faceBookValidator = helpers.regex(/^(https?:\/\/)?((w{3}\.)?)facebook.com\/.*/i);

    const containsHttp = (url: string): boolean => url?.includes("https://");

    const notRequired
      = validatorFunction => (value: string): boolean =>
        !helpers.req(value) || validatorFunction(value);

    const rules = computed(() => ({
      name: {
        required: helpers.withMessage(t("validator.required"), required),
      },
      url: {
        required: helpers.withMessage(t("validator.required"), required),
        containsHttp: helpers.withMessage(t("validator.containsHttp"), containsHttp),
        url: helpers.withMessage(t("validator.url"), urlValidator),
      },
      linkedin_url: {
        containsHttp: helpers.withMessage(t("validator.containsHttp"), notRequired(containsHttp)),
        url: helpers.withMessage(t("validator.url"), urlValidator),
        linkedin: helpers.withMessage(t("validator.linkedin"), linkedinValidator),
      },
      facebook_url: {
        containsHttp: helpers.withMessage(t("validator.containsHttp"), notRequired(containsHttp)),
        url: helpers.withMessage(t("validator.url"), urlValidator),
        facebook: helpers.withMessage(t("validator.facebook"), faceBookValidator),
      },
      business_categories: {
        $each: helpers.forEach({
          industry: {
            required: helpers.withMessage(
              t("validator.required"),
              requiredIf(() => _.isString(state.value.business_categories[0].sector)),
            ),
          },
        }),
      },
      governance: {
        headquarters: {
          required: helpers.withMessage(
            t("validator.required"),
            requiredIf(() => selectedHeadquarters.value === "OTHER"),
          ),
        },
      },
    }));

    const employeesOptions = computed(() => tm("corporations.employees.enum"));

    const v$ = useVuelidate(rules, state as Ref<Corporation>);

    const submitCorporation = async (payload: Partial<Corporation>) => {
      try {
        hasSubmitionError.value = false;
        store.commit("SET_FULL_PAGE_LOADING_MESSAGE", "Loading");
        const corporation = await createCorporation({
          corporation: payload,
        });
        return { success: true, corporation };
      }
      catch (error) {
        hasSubmitionError.value = true;
        return { success: false };
      }
      finally {
        store.commit("SET_FULL_PAGE_LOADING_MESSAGE");
      }
    };

    const goCorporationHome = () => {
      router.push("/corporations");
    };

    const save = async () => {
      const isValid = await v$.value.$validate();

      if (isValid) {
        const {
          phone,
          address,
          governance,
          linkedin_url,
          facebook_url,
          business_categories,
        } = state.value;

        const ceo = (governance.ceo as RelatedPerson)?.person_id;

        const economic_group_ids = (governance.economic_group as RelatedCorporation[])
          .map(({ corporation_id }) => corporation_id)
          .filter(id => !!id);

        const investor = (governance.investor_and_parent_companies as InvestorAndParentCompany[])
          .filter(item => item.corporation_id);

        const sub
          = (governance.subsidiaries_and_invested_companies as SubsidiaryAndInvestedCompany[])
            .filter(item => item.corporation_id);

        const payload = {
          ...state.value,
          phone: phone.calling_code && phone.number ? phone : null,
          business_categories:
            business_categories[0].industry && business_categories[0].sector
              ? business_categories
              : [],
          facebook_url: facebook_url || null,
          linkedin_url: linkedin_url || null,
          revenues: state.value.revenues
            .filter(({ amount, year }) => amount && year)
            .map(({ amount, ...rest }) => ({
              ...rest,
              amount: SimpleMaskMoney.formatToNumber(amount),
            })),
          patrimony: {
            ...state.value.patrimony,
            amount: SimpleMaskMoney.formatToNumber(state.value.patrimony.amount),
          },
          governance: {
            ...state.value.governance,
            investor_and_parent_companies: investor,
            subsidiaries_and_invested_companies: sub,
            economic_group: {
              corporations_data: "",
              corporations_ids: economic_group_ids,
            },
            ceo,
          },
          address: address.name ? address : null,
        };

        const { success, corporation } = await submitCorporation(payload);

        if (success) {
          if (avatarBase64.value) {
            const response = await createAvatar(avatarBase64.value, ImagesTypesEnum.corporations);
            // remove economic_group aggregation
            const economic_group = (corporation.governance.economic_group.corporations_ids || []).map(
              ({ corporation_id }) => corporation_id,
            ).filter(id => !!id);

            await updateCorporation(corporation.id, {
              corporation: {
                ...corporation,
                avatar_url: response.url,
                economic_group,
              },
            });
          }
          if (isEducationalOrganization.value === "yes") {
            await toEducationalOrganization({
              educational_organization: {
                corporation_id: corporation?.id || "",
                corporation_type: educationalOrganizationType.value,
                user_creator_id: corporation?.user_creator_id || "",
              },
            });
          }
        }
        submitStatus.value = success;
      }
    };

    const retrySubmitCorporation = async () => {
      await submitCorporation(state.value);
    };

    const clear = () => {
      state.value = _.cloneDeep(initialFormData);
      clearConfirmation.value = false;
      v$.value.$reset();
      avatarInput.value?.clear();
    };

    const getNameAndDomainFromQuery = (): void => {
      const {
        query: { name, domain },
      } = route;
      state.value.name = name as string;
      if (domain) {
        state.value.url = `https://${decodeURIComponent(domain as string)?.replace(
          /(https?:\/\/)/g,
          "",
        )}`;
      }
    };

    onMounted(() => {
      getNameAndDomainFromQuery();
    });

    return {
      t,
      tm,
      clear,
      save,
      retrySubmitCorporation,
      openClearModal,
      appendRevenue,
      removeRevenue,
      moneyCurrencies,
      selectedHeadquarters,
      businessHeadquarters,
      isEducationalOrganization,
      educationalOrganizationEnum,
      educationalOrganizationTypes,
      educationalOrganizationType,
      countriesHeadquarters,
      businessTypes,
      businessLevels,
      searchCorporation,
      hasEconomicGroup,
      selectEconomicGroupCorporation,
      removeCorporationFromEconomicGroup,
      updateCorporationInput,
      selectInvestorAndParent,
      removeInvestor,
      businessEquityInvestorTypes,
      lastInvestor,
      selectSubsidiaryAndInvestedCompany,
      removeSubsidiary,
      lastSubsidiary,
      businessEquitySubsidiaryTypes,
      goCorporationHome,
      employeesOptions,
      hasSubmitionError,
      clearConfirmation,
      submitStatus,
      v$,
      state,
      macroOptions,
      microOptions,
      initialFormData,
      isString: _.isString,
      close: mdiClose,
      addMore: mdiPlusCircleOutline,
      changeAvatar,
      avatarInput,
    };
  },
});
</script>

<template>
  <PageWrapper content-columns="1fr">
    <template #content>
      <ContentHeader :title="t('corporations.create.title')">
        <template #icon>
          <CompanyIcon />
        </template>
      </ContentHeader>
      <Card>
        <form @submit.prevent="save()">
          <SectionTitle
            tag="h1"
            :title="t('corporations.create.basicData.title')"
            :is-editing="true"
          />

          <SectionContent>
            <div class="form">
              <div class="form-section">
                <div class="form-item">
                  <label>{{ t("corporations.create.basicData.name") }}:<strong>*</strong></label>
                  <FormInput
                    v-model="state.name"
                    class="form-input"
                    :placeholder="t('placeholders.company.name')"
                    :error="v$.name.$error"
                    :error-message="v$.name.$errors[0] ? String(v$.name.$errors[0]?.$message) : null"
                  />
                </div>
                <div class="form-item">
                  <label>{{ t("corporations.create.basicData.macroMarket") }}:</label>
                  <Select
                    v-model="state.business_categories[0].sector"
                    :options="macroOptions"
                    :placeholder="t('text.select')"
                    class="form-input"
                  />
                </div>
                <div class="form-item">
                  <label>{{ t("corporations.create.basicData.microMarket") }}:</label>
                  <Select
                    v-model="state.business_categories[0].industry"
                    :options="microOptions[state.business_categories[0].sector]"
                    :placeholder="t('text.select')"
                    :disabled="!isString(state.business_categories[0].sector)"
                    class="form-input"
                    :no-feedback="false"
                    :error-message="
                      v$.business_categories.$errors[0]?.$response.$errors[0].industry[0].$message
                    "
                  />
                </div>
                <div class="form-item form-educational">
                  <label> {{ t("corporations.isEducationalOrganization") }}? : </label>
                  <div class="form-educational-form">
                    <RadioButton
                      v-for="(item, index) in educationalOrganizationEnum"
                      :id="item.label"
                      :key="index"
                      v-model="isEducationalOrganization"
                      name="educationOrganization"
                      :value="item.value"
                      :label="item.label"
                    />
                  </div>
                  <div v-if="isEducationalOrganization === 'yes'" class="form-educational-type">
                    <RadioButton
                      v-for="(item, index) in educationalOrganizationTypes"
                      :id="item.label"
                      :key="index"
                      v-model="educationalOrganizationType"
                      name="educationOrganizationType"
                      :value="item.value"
                      :label="item.label"
                    />
                  </div>
                </div>
                <div class="form-item">
                  <label>{{ t("corporations.details.employeesNumber") }}:</label>
                  <Select
                    v-model="state.number_of_employees"
                    class="form-input"
                    :placeholder="t('corporations.details.forms.placeholders.employeesSelect')"
                    :options="employeesOptions"
                  />
                </div>
                <div class="form-item-grid --cols-2">
                  <label>{{ t("corporations.details.annualRevenue") }}:</label>
                  <div v-show="state.revenues?.length">
                    <template v-for="revenue of state.revenues" :key="revenue">
                      <div class="form-item-revenue">
                        <Select
                          v-model="revenue.currency"
                          :placeholder="t('corporations.details.forms.placeholders.currency')"
                          :options="moneyCurrencies"
                        />
                        <FormInput
                          v-model="revenue.amount"
                          :placeholder="t('corporations.details.forms.placeholders.netWorth')"
                          money
                        />
                        <FormInput
                          v-model="revenue.year"
                          mask="####"
                          :placeholder="new Date().getFullYear()"
                        />
                        <button
                          class="icon-button remove"
                          type="button"
                          aria-label="remove a revenue"
                          @click="removeRevenue(index)"
                        >
                          <TrashIcon />
                        </button>
                      </div>
                    </template>
                  </div>
                  <div class="button-wrapper add justify-end">
                    <ButtonIcon
                      class="icon-button"
                      type="button"
                      variation="regular"
                      @click="appendRevenue()"
                    >
                      <SvgIcon type="mdi" :path="addMore" size="16" />
                      {{ t("corporations.details.forms.placeholders.addYear") }}
                    </ButtonIcon>
                  </div>
                </div>

                <div class="form-item-grid --cols-2">
                  <label class="form-label">{{ t("corporations.details.netWorth") }}:</label>
                  <div class="form-item-networth">
                    <Select
                      v-model="state.patrimony.currency"
                      :placeholder="t('corporations.details.forms.placeholders.currency')"
                      :options="moneyCurrencies"
                    />
                    <FormInput
                      v-model="state.patrimony.amount"
                      :placeholder="t('corporations.details.forms.placeholders.netWorth')"
                      money
                    />
                    <div />
                  </div>
                </div>

                <div class="form-item-about">
                  <label>{{ t("corporations.create.basicData.about") }}:</label>
                  <TextArea
                    v-model="state.description"
                    :warning-chars="650"
                    :placeholder="t('placeholders.resume')"
                    class="form-input"
                  />
                </div>
              </div>
              <div class="form-section">
                <div class="form-item">
                  <label>{{ t("corporations.create.basicData.domain") }}:<strong>*</strong></label>
                  <FormInput
                    v-model="state.url"
                    class="form-input"
                    :placeholder="t('placeholders.url')"
                    :error="v$.url.$error"
                    :error-message="v$.url.$errors[0] ? String(v$.url.$errors[0]?.$message) : null"
                  />
                </div>
                <div class="form-item">
                  <label>{{ t("corporations.create.basicData.locality") }}:</label>
                  <FormInput
                    v-model="state.address.name"
                    class="form-input"
                    :placeholder="t('placeholders.locality')"
                  />
                </div>
                <div class="form-item">
                  <label>{{ t("corporations.create.basicData.contact") }}:</label>
                  <TelephoneInput
                    v-model:phoneNumber="state.phone.number"
                    v-model:countryCode="state.phone.calling_code"
                    class="form-input phone-input"
                  />
                </div>
                <div class="form-item">
                  <label>{{ t("corporations.create.basicData.linkedin") }}:</label>
                  <FormInput
                    v-model="state.linkedin_url"
                    class="form-input"
                    :placeholder="t('placeholders.company.linkedin')"
                    :error="v$.linkedin_url.$error"
                    :error-message="
                      v$.linkedin_url.$errors[0]
                        ? String(v$.linkedin_url.$errors[0]?.$message)
                        : null
                    "
                  />
                </div>
                <div class="form-item">
                  <label>{{ t("corporations.create.basicData.facebook") }}:</label>
                  <FormInput
                    v-model="state.facebook_url"
                    class="form-input"
                    :placeholder="t('placeholders.company.facebook')"
                    :error="v$.facebook_url.$error"
                    :error-message="
                      v$.facebook_url.$errors[0]
                        ? String(v$.facebook_url.$errors[0]?.$message)
                        : null
                    "
                  />
                </div>
                <SectionTitle
                  tag="h1"
                  :title="t('corporations.create.avatar.title')"
                  :is-editing="true"
                />
                <div class="form-avatar">
                  <p>{{ t("corporations.create.avatar.helpText") }}</p>
                  <SimpleAvatarInput ref="avatarInput" @changeAvatar="changeAvatar" />
                </div>
              </div>
            </div>
          </SectionContent>

          <SectionTitle
            tag="h1"
            :title="t('corporations.create.governance.title')"
            :is-editing="true"
          />

          <SectionContent class="governance-form">
            <div class="form-section">
              <div class="form-group form-group--flex">
                <label> {{ t("corporations.details.headquarters.name") }}: </label>
                <RadioButton
                  v-for="nation in businessHeadquarters"
                  :id="nation.value"
                  :key="nation"
                  v-model="selectedHeadquarters"
                  name="headquarters"
                  :value="nation.value"
                  :label="nation.label"
                />

                <div class="flex-center">
                  <Dropdown
                    v-if="selectedHeadquarters === 'OTHER'"
                    v-model="state.governance.headquarters"
                    class="countries"
                    :class="[
                      { ['--not-selected']: v$.governance.headquarters.$error },
                    ]"
                    option-label="label"
                    option-value="value"
                    :options="countriesHeadquarters"
                    :placeholder="t('text.select')"
                  />
                  <small v-if="v$.governance.headquarters.$error" class="dropdown-error">
                    {{ v$.governance.headquarters.$errors[0].$message }}
                  </small>
                </div>
              </div>
              <div class="business-wrapper">
                <div class="form-group form-group--grid">
                  <label for="business_type">{{ t("corporations.details.type.name") }}:</label>
                  <Select
                    id="business_type"
                    v-model="state.governance.business_type"
                    no-feedback
                    :placeholder="t('text.select')"
                    :options="businessTypes"
                  />
                </div>
                <div class="form-group form-group--grid">
                  <label for="business_level">{{ t("corporations.details.level.name") }}</label>
                  <Select
                    id="business_level"
                    v-model="state.governance.business_level"
                    no-feedback
                    :placeholder="t('text.select')"
                    :options="businessLevels"
                  />
                </div>
              </div>

              <div class="form-group">
                <div class="economic-group">
                  <p>{{ t("corporations.create.governance.hasEconomicGroup") }}</p>

                  <RadioSimple
                    id="economic_group-no"
                    v-model="hasEconomicGroup"
                    name="economic_group"
                    :value="false"
                    :label="t('corporations.create.governance.no')"
                  />

                  <RadioSimple
                    id="economic_group-yes"
                    v-model="hasEconomicGroup"
                    name="economic_group"
                    :value="true"
                    :label="t('corporations.create.governance.yes')"
                  />
                </div>

                <div v-show="hasEconomicGroup">
                  <SearchDropdown
                    id="economic-group"
                    :fetch-method="searchCorporation"
                    :select-item-callback="selectEconomicGroupCorporation"
                    :update-input-model="() => ''"
                    :input-placeholder="t('text.searchCorporation')"
                    :search-not-found-label="t('text.searchCorporationNotFound')"
                    :search-error-label="t('text.searchCorporationError')"
                  >
                    <template #list-item="{ item: corporation }">
                      {{ corporation.name }}
                    </template>
                  </SearchDropdown>

                  <div>
                    <span
                      v-for="(group, i) in state.governance.economic_group"
                      :key="group"
                      class="tag"
                    >
                      {{ group.name }}

                      <span role="button" @click="removeCorporationFromEconomicGroup(i)">
                        <SvgIcon type="mdi" :path="close" size="16" />
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div class="form-container form-container--large-spacing">
                <div
                  v-for="(investor, index) in state.governance.investor_and_parent_companies"
                  :key="investor"
                  class="form-container"
                >
                  <div class="form-group form-group--grid form-group--small-cols">
                    <label class="form-label">
                      {{ t("corporations.details.linkInvestorAndParentCompanies") }}:
                    </label>

                    <SearchDropdown
                      :id="`investor-and-parent-${index}`"
                      :fetch-method="searchCorporation"
                      :select-item-callback="
                        (corporation) => selectInvestorAndParent(corporation, index)
                      "
                      :update-input-model="updateCorporationInput"
                      :initial-value="investor.name"
                      :input-placeholder="t('text.searchCorporation')"
                      :search-not-found-label="t('text.searchCorporationNotFound')"
                      :search-error-label="t('text.searchCorporationError')"
                    >
                      <template #list-item="{ item: corporation }">
                        {{ corporation.name }}
                      </template>
                    </SearchDropdown>
                  </div>

                  <template v-if="investor.name">
                    <div class="form-group">
                      <CustomCheckBox
                        :label="t('corporations.details.mainInvestor')"
                        :checked="investor.main_investor"
                        :on-change="(e) => (investor.main_investor = e.target.checked)"
                      />
                    </div>

                    <div class="form-group form-group--grid form-group--small-cols">
                      <label class="form-label" for="business_equity_type">
                        {{ t("corporations.details.businessEquityType.name") }}
                      </label>

                      <Select
                        id="business_equity_type"
                        v-model="investor.business_equity_type"
                        :placeholder="t('corporations.details.businessEquityType.name')"
                        no-feedback
                        :options="businessEquityInvestorTypes"
                      />
                    </div>

                    <div class="form-group">
                      <TextArea
                        v-model="investor.relationship_details"
                        :placeholder="t('corporations.details.placeholders.details')"
                        :warning-chars="650"
                      />
                    </div>
                  </template>

                  <ButtonIcon
                    v-if="investor.name"
                    class="remove"
                    type="button"
                    :title="t('corporations.details.forms.placeholders.removeInvestor')"
                    :aria-label="t('corporations.details.forms.placeholders.removeInvestor')"
                    @click="removeInvestor(index)"
                  >
                    <TrashIcon />
                  </ButtonIcon>
                </div>

                <div v-if="lastInvestor?.name" class="form-extra-actions">
                  <span
                    class="form-extra-actions__item add"
                    role="button"
                    @click="state.governance.investor_and_parent_companies.push({})"
                  >
                    <SvgIcon type="mdi" :path="addMore" size="16" />
                    {{ t("corporations.details.addRelationship") }}
                  </span>
                </div>
              </div>

              <div class="form-container form-container--large-spacing">
                <div
                  v-for="(subsidiary, index) in state.governance
                    .subsidiaries_and_invested_companies"
                  :key="subsidiary"
                  class="form-container"
                >
                  <div class="form-group form-group--grid form-group--small-cols">
                    <label class="form-label">
                      {{ t("corporations.details.linkSubsidiaryAndInvestedCompanies") }}:
                    </label>

                    <SearchDropdown
                      :id="`subsidiary-and-invested-${index}`"
                      :fetch-method="searchCorporation"
                      :select-item-callback="
                        (corporation) => selectSubsidiaryAndInvestedCompany(corporation, index)
                      "
                      :update-input-model="updateCorporationInput"
                      :initial-value="subsidiary.name"
                      :input-placeholder="t('text.searchCorporation')"
                      :search-not-found-label="t('text.searchCorporationNotFound')"
                      :search-error-label="t('text.searchCorporationError')"
                    >
                      <template #list-item="{ item: corporation }">
                        {{ corporation.name }}
                      </template>
                    </SearchDropdown>
                  </div>

                  <template v-if="subsidiary.name">
                    <div class="form-group">
                      <CustomCheckBox
                        :label="t('corporations.details.mainInvestor')"
                        :checked="subsidiary.main_investor"
                        :on-change="(e) => (subsidiary.main_investor = e.target.checked)"
                      />
                    </div>

                    <div class="form-group form-group--grid form-group--small-cols">
                      <label class="form-label" for="business_equity_type">
                        {{ t("corporations.details.businessEquityType.name") }}
                      </label>

                      <Select
                        id="business_equity_type"
                        v-model="subsidiary.business_equity_type"
                        :placeholder="t('corporations.details.businessEquityType.name')"
                        no-feedback
                        :options="businessEquitySubsidiaryTypes"
                      />
                    </div>

                    <div class="form-group">
                      <TextArea
                        v-model="subsidiary.relationship_details"
                        :placeholder="t('corporations.details.placeholders.details')"
                        :warning-chars="650"
                      />
                    </div>
                  </template>

                  <ButtonIcon
                    v-if="subsidiary.name"
                    class="remove"
                    type="button"
                    :title="t('corporations.details.forms.placeholders.removeInvestor')"
                    :aria-label="t('corporations.details.forms.placeholders.removeInvestor')"
                    @click="removeSubsidiary(index)"
                  >
                    <TrashIcon />
                  </ButtonIcon>
                </div>

                <div v-if="lastSubsidiary?.name" class="form-extra-actions">
                  <span
                    class="form-extra-actions__item add"
                    role="button"
                    @click="payload.subsidiaries_and_invested_companies.push({})"
                  >
                    <SvgIcon type="mdi" :path="addMore" size="16" />
                    {{ t("corporations.details.addRelationship") }}
                  </span>
                </div>
              </div>
            </div>
          </SectionContent>

          <div class="action-buttons">
            <Button type="submit">
              {{ t("corporations.create.save") }}
            </Button>
            <Button variation="light" filled @click="openClearModal">
              {{ t("corporations.create.clear") }}
            </Button>
          </div>
        </form>
      </Card>
    </template>
  </PageWrapper>

  <Modal
    v-if="hasSubmitionError"
    :title="t('text.form.error.modal.genericTitle')"
    :message="t('text.form.error.modal.genericMessage')"
    :button-text="t('text.form.error.modal.genericButtonMessage')"
    :on-click="retrySubmitCorporation"
    :on-close="() => (hasSubmitionError = false)"
    variation="warning"
    secondary-variation="light"
  >
    <template #titleIcon>
      <WarningIcon />
    </template>
  </Modal>

  <Modal
    v-if="clearConfirmation"
    :message="t('corporations.create.clearMessage')"
    :button-text="t('corporations.create.clean')"
    :on-click="clear"
    :on-close="() => (clearConfirmation = false)"
    variation="warning"
    :secondary-button-text="t('corporations.create.back')"
    :secondary-on-click="() => (clearConfirmation = false)"
    secondary-variation="light"
  />

  <Modal
    v-if="submitStatus"
    :message="t('corporations.create.successMessage')"
    :button-text="t('corporations.create.continue')"
    :on-click="goCorporationHome"
    :on-close="goCorporationHome"
    variation="warning"
    secondary-variation="light"
  >
    <template #titleIcon>
      <WarningIcon />
    </template>
  </Modal>
</template>

<route lang="yaml">
meta:
  layout: default-page
</route>

<style lang="scss" scoped>
.action-buttons {
  display: flex;
  width: 50%;
  justify-content: space-between;
}

.form-section {
  label {
    font-size: 0.875rem;
  }
}

.form-educational {
  display: flex;
  margin-bottom: 1.25rem;
}

.form-educational-form {
  display: flex;
  gap: 1rem;
}

.form-educational-type {
  display: flex;
  padding-left: 1rem;
  gap: 1rem;

  :deep(.imua-radiobutton__label) {
    &::before {
      background-color: $gray-dark;
    }
  }
}

.section-content {
  margin-bottom: 1.5rem;
}

.justify-end {
  // justify-self: end;
  grid-column: 2;
}

$remove-mb: 1rem;

.button-wrapper {
  display: flex;
  justify-content: flex-end;
}

.icon-button {
  padding: 0.25rem 0;
  border: none;
  margin-bottom: $remove-mb;
  background-color: transparent;
  color: $primary;
  cursor: pointer;
  font-size: 0.75rem;

  svg {
    margin-right: 0.25rem;
  }

  &.remove {
    margin-bottom: $remove-mb;
    color: $gray;
    cursor: pointer;

    svg {
      width: 16px;
      height: 16px;
      stroke: $gray-medium;
    }
  }
}

.form {
  display: flex;

  .form-section {
    width: 50%;

    &:first-child {
      flex-shrink: 0;
      margin-right: 5rem;
    }
  }

  .form-item {
    display: flex;
    margin-bottom: 1rem;

    label {
      width: 20%;

      strong {
        color: $red;
      }
    }

    .form-input {
      width: 80%;
      min-height: 40px;
    }

    :deep(.phone-input) {
      input {
        width: 100%;
      }
    }
  }

  .form-item-about {
    .form-input {
      margin-top: 0.5rem;
    }
  }

  .form-avatar {
    width: min-content;

    p {
      margin-bottom: 1rem;
      color: $gray-medium;
      font-size: 0.813rem;
    }
  }

  .form-item-revenue,
  .form-item-networth {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 2fr 1fr auto;

    :deep(input) {
      min-width: 0;
    }
  }

  .form-item-networth {
    grid-template-columns: 1fr 2fr 1.35fr;
  }

  .form-item-grid {
    display: grid;
    margin-bottom: 1rem;

    &.--cols-2 {
      grid-template-columns: 1fr 4fr;
    }
  }
}

.flex-center {
  display: flex;
  flex-direction: column;
}

.countries:deep {
  &.--not-selected {
    border: 1px solid $red;
  }

  .p-dropdown-label {
    padding-block: 0.375rem;
  }

  .p-dropdown-trigger-icon {
    color: $gray-border;
  }
}

.tag {
  display: inline-block;
  max-width: 100%;
  padding: 0.5rem 0.75rem;
  border-radius: 8px;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  background-color: $white-full;
  box-shadow: 0 1px 3px #0003;
  color: $gray-dark;
  font-size: 0.75rem;
  word-wrap: break-word;

  span[role="button"] {
    &:hover {
      color: lighten($gray-dark, 25%);
    }

    svg {
      vertical-align: text-top;
    }
  }
}

.dropdown-error {
  display: block;
  margin-left: 0.25rem;
  color: $red;
  font-size: 0.625rem;
}

.component-select:deep {
  min-height: auto;
}

$form-container-margin: 1rem;

.governance-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  .form-group {
    position: relative;
    align-items: center;
    margin-bottom: 1rem;

    .form-label {
      font-size: 0.875rem;
    }

    &--grid {
      display: grid;
      grid-template-columns: 1fr 4fr;
    }

    &--flex {
      display: flex;
      gap: 1rem;
    }
  }

  .form-container {
    margin-bottom: $form-container-margin;

    &--large-spacing {
      margin-bottom: calc(#{$form-container-margin} * 1.25);
    }
  }

  .business-wrapper {
    display: grid;
    gap: 5%;
    grid-template-columns: repeat(2, 1fr);
  }

  :deep(.economic-group) {
    display: flex;
    margin-bottom: 1rem;

    & > * {
      margin-right: 1rem;
    }

    p {
      font-size: 0.875rem;
    }

    .imua-radiosimple {
      display: inline-flex;
      align-items: center;
      margin-right: 1rem;

      label {
        padding-left: 0;
        font-size: 0.875rem;
      }
    }
  }

  .form-actions {
    display: grid;
    padding-right: 45%;
    margin-top: 2rem;
    grid-gap: 5rem;
    grid-template-columns: repeat(2, 1fr);
  }
}

.flex-center {
  display: flex;
  flex-direction: column;
}

.countries:deep {
  &.--not-selected {
    border: 1px solid $red;
  }

  .p-dropdown-label {
    padding-block: 0.375rem;
  }

  .p-dropdown-trigger-icon {
    color: $gray-border;
  }
}

.tag {
  display: inline-block;
  max-width: 100%;
  padding: 0.5rem 0.75rem;
  border-radius: 8px;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  background-color: $white-full;
  box-shadow: 0 1px 3px #0003;
  color: $gray-dark;
  font-size: 0.75rem;
  word-wrap: break-word;

  span[role="button"] {
    &:hover {
      color: lighten($gray-dark, 25%);
    }

    svg {
      vertical-align: text-top;
    }
  }
}

.dropdown-error {
  display: block;
  margin-left: 0.25rem;
  color: $red;
  font-size: 0.625rem;
}

.component-select:deep {
  min-height: auto;
}

.remove {
  color: $gray;

  svg {
    width: 16px;
    height: 16px;
    stroke: $gray-medium;
  }
}

.add {
  color: $primary;
  font-size: 0.875rem;

  svg {
    vertical-align: sub;
  }
}

$form-container-margin: 1rem;

.governance-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  .form-group {
    position: relative;
    align-items: center;
    margin-bottom: 1rem;

    .form-label {
      font-size: 0.875rem;
    }

    &--grid {
      display: grid;
      grid-template-columns: 1fr 4fr;
    }

    &--small-cols {
      gap: 0.75rem;
      grid-template-columns: 1fr 3fr;
    }

    &--flex {
      display: flex;
      gap: 1rem;
    }
  }

  .form-container {
    margin-bottom: $form-container-margin;

    &--large-spacing {
      margin-bottom: calc(#{$form-container-margin} * 1.25);
    }
  }

  .business-wrapper {
    display: grid;
    gap: 5%;
    grid-template-columns: repeat(2, 1fr);
  }

  .economic-group {
    display: flex;
    margin-bottom: 1rem;

    & > * {
      margin-right: 1rem;
    }
  }

  .form-actions {
    display: grid;
    padding-right: 45%;
    margin-top: 2rem;
    grid-gap: 5rem;
    grid-template-columns: repeat(2, 1fr);
  }

  .form-extra-actions {
    display: flex;
    height: 0;
    justify-content: flex-end;

    &__item {
      position: relative;
      bottom: calc(#{$form-container-margin} + 1rem);
    }
  }
}

.flex-center {
  display: flex;
  flex-direction: column;
}

.countries:deep {
  &.--not-selected {
    border: 1px solid $red;
  }

  .p-dropdown-label {
    padding-block: 0.375rem;
  }

  .p-dropdown-trigger-icon {
    color: $gray-border;
  }
}

.tag {
  display: inline-block;
  max-width: 100%;
  padding: 0.5rem 0.75rem;
  border-radius: 8px;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  background-color: $white-full;
  box-shadow: 0 1px 3px #0003;
  color: $gray-dark;
  font-size: 0.75rem;
  word-wrap: break-word;

  span[role="button"] {
    &:hover {
      color: lighten($gray-dark, 25%);
    }

    svg {
      vertical-align: text-top;
    }
  }
}

.dropdown-error {
  display: block;
  margin-left: 0.25rem;
  color: $red;
  font-size: 0.625rem;
}

.component-select:deep {
  min-height: auto;
}

$form-container-margin: 1rem;

.governance-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  .form-group {
    position: relative;
    align-items: center;
    margin-bottom: 1rem;

    .form-label {
      font-size: 0.875rem;
    }

    &--grid {
      display: grid;
      grid-template-columns: 1fr 4fr;
    }

    &--flex {
      display: flex;
      gap: 1rem;
    }
  }

  .form-container {
    margin-bottom: $form-container-margin;

    &--large-spacing {
      margin-bottom: calc(#{$form-container-margin} * 1.25);
    }
  }

  .business-wrapper {
    display: grid;
    gap: 5%;
    grid-template-columns: repeat(2, 1fr);
  }

  .economic-group {
    display: flex;
    margin-bottom: 1rem;

    & > * {
      margin-right: 1rem;
    }
  }

  .form-actions {
    display: grid;
    padding-right: 45%;
    margin-top: 2rem;
    grid-gap: 5rem;
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
