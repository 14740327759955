export const concla = {
  A: "Agricultura, Pecuária, Produção Florestal, Pesca E Aquicultura",
  A01: "Agricultura, Pecuária E Serviços Relacionados",
  A01_1: "Produção De Lavouras Temporárias",
  A01_11__3: "Cultivo De Cereais",
  A01_11__3_01: "Cultivo De Arroz",
  A01_11__3_02: "Cultivo De Milho",
  A01_11__3_03: "Cultivo De Trigo",
  A01_11__3_99: "Cultivo De Outros Cereais Não Especificados Anteriormente",
  A01_12__1: "Cultivo De Algodão Herbáceo E De Outras Fibras De Lavoura Temporária",
  A01_12__1_01: "Cultivo De Algodão Herbáceo",
  A01_12__1_02: "Cultivo De Juta",
  A01_12__1_99: "Cultivo De Outras Fibras De Lavoura Temporária Não Especificadas Anteriormente",
  A01_13__0: "Cultivo De Cana-De-Açúcar",
  A01_13__0_00: "Cultivo De Cana De Açúcar",
  A01_14__8: "Cultivo De Fumo",
  A01_15__6: "Cultivo De Soja",
  A01_16__4: "Cultivo De Oleaginosas De Lavoura Temporária, Exceto Soja",
  A01_16__4_01: "Cultivo De Amendoim",
  A01_16__4_02: "Cultivo De Girassol",
  A01_16__4_03: "Cultivo De Mamona",
  A01_16__4_99: "Cultivo De Outras Oleaginosas De Lavoura Temporária Não Especificadas Anteriormente",
  A01_19__9: "Cultivo De Plantas De Lavoura Temporária Não Especificadas Anteriormente",
  A01_19__9_01: "Cultivo De Abacaxi",
  A01_19__9_02: "Cultivo De Alho",
  A01_19__9_03: "Cultivo De Batata Inglesa",
  A01_19__9_04: "Cultivo De Cebola",
  A01_19__9_05: "Cultivo De Feijão",
  A01_19__9_06: "Cultivo De Mandioca",
  A01_19__9_07: "Cultivo De Melão",
  A01_19__9_08: "Cultivo De Melancia",
  A01_19__9_09: "Cultivo De Tomate Rasteiro",
  A01_19__9_99: "Cultivo De Outras Plantas De Lavoura Temporária Não Especificadas Anteriormente",
  A01_2: "Horticultura E Floricultura",
  A01_21__1: "Horticultura",
  A01_21__1_01: "Horticultura, Exceto Morango",
  A01_21__1_02: "Cultivo De Morango",
  A01_22__9: "Cultivo De Flores E Plantas Ornamentais",
  A01_3: "Produção De Lavouras Permanentes",
  A01_31__8: "Cultivo De Laranja",
  A01_32__6: "Cultivo De Uva",
  A01_33__4: "Cultivo De Frutas De Lavoura Permanente, Exceto Laranja E Uva",
  A01_33__4_06: "Cultivo De Guaraná",
  A01_33__4_03: "Cultivo De Caju",
  A01_33__4_04: "Cultivo De Cítricos, Exceto Laranja",
  A01_33__4_05: "Cultivo De Coco Da Baía",
  A01_33__4_01: "Cultivo De Açaí",
  A01_33__4_02: "Cultivo De Banana",
  A01_33__4_07: "Cultivo De Maçã",
  A01_33__4_08: "Cultivo De Mamão",
  A01_33__4_09: "Cultivo De Maracujá",
  A01_33__4_10: "Cultivo De Manga",
  A01_33__4_11: "Cultivo De Pêssego",
  A01_33__4_99: "Cultivo De Frutas De Lavoura Permanente Não Especificadas Anteriormente",
  A01_34__2: "Cultivo De Café",
  A01_35__1: "Cultivo De Cacau",
  A01_39__3: "Cultivo De Plantas De Lavoura Permanente Não Especificadas Anteriormente",
  A01_39__3_01: "Cultivo De Chá Da Índia",
  A01_39__3_02: "Cultivo De Erva Mate",
  A01_39__3_03: "Cultivo De Pimenta Do Reino",
  A01_39__3_04: "Cultivo De Plantas Para Condimento, Exceto Pimenta Do Reino",
  A01_39__3_05: "Cultivo De Dendê",
  A01_39__3_06: "Cultivo De Seringueira",
  A01_39__3_99: "Cultivo De Outras Plantas De Lavoura Permanente Não Especificadas Anteriormente",
  A01_4: "Produção De Sementes E Mudas Certificadas",
  A01_41__5: "Produção De Sementes Certificadas",
  A01_41__5_01: "Produção De Sementes Certificadas, Exceto De Forrageiras Para Pasto",
  A01_41__5_02: "Produção De Sementes Certificadas De Forrageiras Para Formação De Pasto",
  A01_42__3: "Produção De Mudas E Outras Formas De Propagação Vegetal, Certificadas",
  A01_5: "Pecuária",
  A01_51__2: "Criação De Bovinos",
  A01_51__2_03: "Criação De Bovinos, Exceto Para Corte E Leite",
  A01_51__2_01: "Criação De Bovinos Para Corte",
  A01_51__2_02: "Criação De Bovinos Para Leite",
  A01_52__1: "Criação De Outros Animais De Grande Porte",
  A01_52__1_03: "Criação De Asininos E Muares",
  A01_52__1_01: "Criação De Bufalinos",
  A01_52__1_02: "Criação De Equinos",
  A01_53__9: "Criação De Caprinos E Ovinos",
  A01_53__9_01: "Criação De Caprinos",
  A01_53__9_02: "Criação De Ovinos, Inclusive Para Produção De Lã",
  A01_54__7: "Criação De Suínos",
  A01_55__5: "Criação De Aves",
  A01_55__5_01: "Criação De Frangos Para Corte",
  A01_55__5_02: "Produção De Pintos De Um Dia",
  A01_55__5_03: "Criação De Outros Galináceos, Exceto Para Corte",
  A01_55__5_04: "Criação De Aves, Exceto Galináceos",
  A01_55__5_05: "Produção De Ovos",
  A01_59__8: "Criação De Animais Não Especificados Anteriormente",
  A01_59__8_01: "Apicultura",
  A01_59__8_02: "Criação De Animais De Estimação",
  A01_59__8_03: "Criação De Escargô",
  A01_59__8_04: "Criação De Bicho Da Seda",
  A01_59__8_99: "Criação De Outros Animais Não Especificados Anteriormente",
  A01_6: "Atividades De Apoio À Agricultura E À Pecuária; Atividades De Pós-Colheita",
  A01_61__0: "Atividades De Apoio À Agricultura",
  A01_61__0_02: "Serviço De Poda De Árvores Para Lavouras",
  A01_61__0_01: "Serviço De Pulverização E Controle De Pragas Agrícolas",
  A01_61__0_03: "Serviço De Preparação De Terreno, Cultivo E Colheita",
  A01_61__0_99: "Atividades De Apoio À Agricultura Não Especificadas Anteriormente",
  A01_62__8: "Atividades De Apoio À Pecuária",
  A01_62__8_01: "Serviço De Inseminação Artificial De Animais",
  A01_62__8_02: "Serviço De Tosquiamento De Ovinos",
  A01_62__8_03: "Serviço De Manejo De Animais",
  A01_62__8_99: "Atividades De Apoio À Pecuária Não Especificadas Anteriormente",
  A01_63__6: "Atividades De Pós-Colheita",
  A01_63__6_00: "Atividades De Pós Colheita",
  A01_7: "Caça E Serviços Relacionados",
  A02: "Produção Florestal",
  A02_1: "Produção Florestal - Florestas Plantadas",
  A02_10__1_06: "Cultivo De Mudas Em Viveiros Florestais",
  A02_10__1_07: "Extração De Madeira Em Florestas Plantadas",
  A02_10__1_09: "Produção De Casca De Acácia Negra - Florestas Plantadas",
  A02_10__1_01: "Cultivo De Eucalipto",
  A02_10__1_02: "Cultivo De Acácia Negra",
  A02_10__1_03: "Cultivo De Pinus",
  A02_10__1_04: "Cultivo De Teca",
  A02_10__1_05: "Cultivo De Espécies Madeireiras, Exceto Eucalipto, Acácia Negra, Pinus E Teca",
  A02_10__1_08: "Produção De Carvão Vegetal - Florestas Plantadas",
  A02_10__1_99: "Produção De Produtos Não Madeireiros Não Especificados Anteriormente Em Florestas Plantadas",
  A02_2: "Produção Florestal - Florestas Nativas",
  A02_20__9_01: "Extração De Madeira Em Florestas Nativas",
  A02_20__9_02: "Produção De Carvão Vegetal - Florestas Nativas",
  A02_20__9_03: "Coleta De Castanha Do Pará Em Florestas Nativas",
  A02_20__9_04: "Coleta De Látex Em Florestas Nativas",
  A02_20__9_05: "Coleta De Palmito Em Florestas Nativas",
  A02_20__9_06: "Conservação De Florestas Nativas",
  A02_20__9_99: "Coleta De Produtos Não Madeireiros Não Especificados Anteriormente Em Florestas Nativas",
  A02_3: "Atividades De Apoio À Produção Florestal",
  A03: "Pesca E Aquicultura",
  A03_1: "Pesca",
  A03_11__6: "Pesca Em Água Salgada",
  A03_11__6_01: "Pesca De Peixes Em Água Salgada",
  A03_11__6_02: "Pesca De Crustáceos E Moluscos Em Água Salgada",
  A03_11__6_03: "Coleta De Outros Produtos Marinhos",
  A03_11__6_04: "Atividades De Apoio À Pesca Em Água Salgada",
  A03_12__4: "Pesca Em Água Doce",
  A03_12__4_01: "Pesca De Peixes Em Água Doce",
  A03_12__4_02: "Pesca De Crustáceos E Moluscos Em Água Doce",
  A03_12__4_03: "Coleta De Outros Produtos Aquáticos De Água Doce",
  A03_12__4_04: "Atividades De Apoio À Pesca Em Água Doce",
  A03_2: "Aquicultura",
  A03_21__3: "Aquicultura Em Água Salgada E Salobra",
  A03_21__3_01: "Criação De Peixes Em Água Salgada E Salobra",
  A03_21__3_02: "Criação De Camarões Em Água Salgada E Salobra",
  A03_21__3_03: "Criação De Ostras E Mexilhões Em Água Salgada E Salobra",
  A03_21__3_04: "Criação De Peixes Ornamentais Em Água Salgada E Salobra",
  A03_21__3_05: "Atividades De Apoio À Aquicultura Em Água Salgada E Salobra",
  A03_21__3_99: "Cultivos E Semicultivos Da Aquicultura Em Água Salgada E Salobra Não Especificados Anteriormente",
  A03_22__1: "Aquicultura Em Água Doce",
  A03_22__1_01: "Criação De Peixes Em Água Doce",
  A03_22__1_02: "Criação De Camarões Em Água Doce",
  A03_22__1_03: "Criação De Ostras E Mexilhões Em Água Doce",
  A03_22__1_04: "Criação De Peixes Ornamentais Em Água Doce",
  A03_22__1_05: "Ranicultura",
  A03_22__1_06: "Criação De Jacaré",
  A03_22__1_07: "Atividades De Apoio À Aquicultura Em Água Doce",
  A03_22__1_99: "Cultivos E Semicultivos Da Aquicultura Em Água Doce Não Especificados Anteriormente",
  B: "Indústrias Extrativas",
  B05: "Extração De Carvão Mineral",
  B05_00__3_02: "Beneficiamento De Carvão Mineral",
  B06: "Extração De Petróleo E Gás Natural",
  B06_00__0_02: "Extração E Beneficiamento De Xisto",
  B06_00__0_03: "Extração E Beneficiamento De Areias Betuminosas",
  B07: "Extração De Minerais Metálicos",
  B07_1: "Extração De Minério De Ferro",
  B07_10__3_02: "Pelotização, Sinterização E Outros Beneficiamentos De Minério De Ferro",
  B07_2: "Extração De Minerais Metálicos Não-Ferrosos",
  B07_24__3: "Extração De Minério De Metais Preciosos",
  B07_24__3_02: "Beneficiamento De Minério De Metais Preciosos",
  B07_21__9: "Extração De Minério De Alumínio",
  B07_21__9_02: "Beneficiamento De Minério De Alumínio",
  B07_22__7: "Extração De Minério De Estanho",
  B07_22__7_02: "Beneficiamento De Minério De Estanho",
  B07_23__5: "Extração De Minério De Manganês",
  B07_23__5_02: "Beneficiamento De Minério De Manganês",
  B07_25__1: "Extração De Minerais Radioativos",
  B07_29__4: "Extração De Minerais Metálicos Não Ferrosos Não Especificados Anteriormente",
  B07_29__4_01: "Extração De Minérios De Nióbio E Titânio",
  B07_29__4_02: "Extração De Minério De Tungstênio",
  B07_29__4_03: "Extração De Minério De Níquel",
  B07_29__4_04: "Extração De Minérios De Cobre, Chumbo, Zinco E Outros Minerais Metálicos Não Ferrosos Não Especificados Anteriormente",
  B07_29__4_05: "Beneficiamento De Minérios De Cobre, Chumbo, Zinco E Outros Minerais Metálicos Não Ferrosos Não Especificados Anteriormente",
  B08: "Extração De Minerais Não-Metálicos",
  B08_1: "Extração De Pedra, Areia E Argila",
  B08_10__0_01: "Extração De Ardósia E Beneficiamento Associado",
  B08_10__0_02: "Extração De Granito E Beneficiamento Associado",
  B08_10__0_03: "Extração De Mármore E Beneficiamento Associado",
  B08_10__0_04: "Extração De Calcário E Dolomita E Beneficiamento Associado",
  B08_10__0_05: "Extração De Gesso E Caulim",
  B08_10__0_06: "Extração De Areia, Cascalho Ou Pedregulho E Beneficiamento Associado",
  B08_10__0_07: "Extração De Argila E Beneficiamento Associado",
  B08_10__0_08: "Extração De Saibro E Beneficiamento Associado",
  B08_10__0_09: "Extração De Basalto E Beneficiamento Associado",
  B08_10__0_10: "Beneficiamento De Gesso E Caulim Associado À Extração",
  B08_10__0_99: "Extração E Britamento De Pedras E Outros Materiais Para Construção E Beneficiamento Associado",
  B08_9: "Extração De Outros Minerais Não-Metálicos",
  B08_91__6: "Extração De Minerais Para Fabricação De Adubos, Fertilizantes E Outros Produtos Químicos",
  B08_92__4: "Extração E Refino De Sal Marinho E Sal-Gema",
  B08_92__4_01: "Extração De Sal Marinho",
  B08_92__4_02: "Extração De Sal Gema",
  B08_92__4_03: "Refino E Outros Tratamentos Do Sal",
  B08_93__2: "Extração De Gemas (Pedras Preciosas E Semipreciosas)",
  B08_99__1: "Extração De Minerais Não Metálicos Não Especificados Anteriormente",
  B08_99__1_01: "Extração De Grafita",
  B08_99__1_02: "Extração De Quartzo",
  B08_99__1_03: "Extração De Amianto",
  B08_99__1_99: "Extração De Outros Minerais Não Metálicos Não Especificados Anteriormente",
  B09: "Atividades De Apoio À Extração De Minerais",
  B09_1: "Atividades De Apoio À Extração De Petróleo E Gás Natural",
  B09_9: "Atividades De Apoio À Extração De Minerais, Exceto Petróleo E Gás Natural",
  B09_90__4_01: "Atividades De Apoio À Extração De Minério De Ferro",
  B09_90__4_02: "Atividades De Apoio À Extração De Minerais Metálicos Não Ferrosos",
  B09_90__4_03: "Atividades De Apoio À Extração De Minerais Não Metálicos",
  C: "Indústrias De Transformação",
  C10: "Fabricação De Produtos Alimentícios",
  C10_1: "Abate E Fabricação De Produtos De Carne",
  C10_11__2: "Abate De Reses, Exceto Suínos",
  C10_11__2_01: "Frigorífico - Abate De Bovinos",
  C10_11__2_02: "Frigorífico - Abate De Equinos",
  C10_11__2_03: "Frigorífico - Abate De Ovinos E Caprinos",
  C10_11__2_04: "Frigorífico - Abate De Bufalinos",
  C10_11__2_05: "Matadouro - Abate De Reses Sob Contrato - Exceto Abate De Suínos",
  C10_12__1: "Abate De Suínos, Aves E Outros Pequenos Animais",
  C10_12__1_02: "Abate De Pequenos Animais",
  C10_12__1_01: "Abate De Aves",
  C10_12__1_03: "Frigorífico - Abate De Suínos",
  C10_12__1_04: "Matadouro - Abate De Suínos Sob Contrato",
  C10_13__9: "Fabricação De Produtos De Carne",
  C10_13__9_02: "Preparação De Subprodutos Do Abate",
  C10_2: "Preservação Do Pescado E Fabricação De Produtos Do Pescado",
  C10_20__1_01: "Preservação De Peixes, Crustáceos E Moluscos",
  C10_20__1_02: "Fabricação De Conservas De Peixes, Crustáceos E Moluscos",
  C10_3: "Fabricação De Conservas De Frutas, Legumes E Outros Vegetais",
  C10_31__7: "Fabricação De Conservas De Frutas",
  C10_32__5: "Fabricação De Conservas De Legumes E Outros Vegetais",
  C10_32__5_01: "Fabricação De Conservas De Palmito",
  C10_32__5_99: "Fabricação De Conservas De Legumes E Outros Vegetais, Exceto Palmito",
  C10_33__3: "Fabricação De Sucos De Frutas, Hortaliças E Legumes",
  C10_33__3_01: "Fabricação De Sucos Concentrados De Frutas, Hortaliças E Legumes",
  C10_33__3_02: "Fabricação De Sucos De Frutas, Hortaliças E Legumes, Exceto Concentrados",
  C10_4: "Fabricação De Óleos E Gorduras Vegetais E Animais",
  C10_41__4: "Fabricação De Óleos Vegetais Em Bruto, Exceto Óleo De Milho",
  C10_42__2: "Fabricação De Óleos Vegetais Refinados, Exceto Óleo De Milho",
  C10_43__1: "Fabricação De Margarina E Outras Gorduras Vegetais E De Óleos Não Comestíveis De Animais",
  C10_5: "Laticínios",
  C10_53__8: "Fabricação De Sorvetes E Outros Gelados Comestíveis",
  C10_51__1: "Preparação Do Leite",
  C10_52__0: "Fabricação De Laticínios",
  C10_6: "Moagem, Fabricação De Produtos Amiláceos E De Alimentos Para Animais",
  C10_61__9: "Beneficiamento De Arroz E Fabricação De Produtos Do Arroz",
  C10_61__9_01: "Beneficiamento De Arroz",
  C10_61__9_02: "Fabricação De Produtos Do Arroz",
  C10_62__7: "Moagem De Trigo E Fabricação De Derivados",
  C10_63__5: "Fabricação De Farinha De Mandioca E Derivados",
  C10_64__3: "Fabricação De Farinha De Milho E Derivados, Exceto Óleos De Milho",
  C10_65__1: "Fabricação De Amidos E Féculas De Vegetais E De Óleos De Milho",
  C10_65__1_01: "Fabricação De Amidos E Féculas De Vegetais",
  C10_65__1_02: "Fabricação De Óleo De Milho Em Bruto",
  C10_65__1_03: "Fabricação De Óleo De Milho Refinado",
  C10_66__0: "Fabricação De Alimentos Para Animais",
  C10_69__4: "Moagem E Fabricação De Produtos De Origem Vegetal Não Especificados Anteriormente",
  C10_7: "Fabricação E Refino De Açúcar",
  C10_71__6: "Fabricação De Açúcar Em Bruto",
  C10_72__4: "Fabricação De Açúcar Refinado",
  C10_72__4_01: "Fabricação De Açúcar De Cana Refinado",
  C10_72__4_02: "Fabricação De Açúcar De Cereais (Dextrose) E De Beterraba",
  C10_8: "Torrefação E Moagem De Café",
  C10_82__1: "Fabricação De Produtos À Base De Café",
  C10_81__3_01: "Beneficiamento De Café",
  C10_9: "Fabricação De Outros Produtos Alimentícios",
  C10_91__1: "Fabricação De Produtos De Panificação",
  C10_91__1_01: "Fabricação De Produtos De Panificação Industrial",
  C10_91__1_02: "Fabricação De Produtos De Padaria E Confeitaria Com Predominância  De Produção Própria",
  C10_92__9: "Fabricação De Biscoitos E Bolachas",
  C10_93__7: "Fabricação De Produtos Derivados Do Cacau, De Chocolates E Confeitos",
  C10_93__7_01: "Fabricação De Produtos Derivados Do Cacau E De Chocolates",
  C10_93__7_02: "Fabricação De Frutas Cristalizadas, Balas E Semelhantes",
  C10_94__5: "Fabricação De Massas Alimentícias",
  C10_95__3: "Fabricação De Especiarias, Molhos, Temperos E Condimentos",
  C10_96__1: "Fabricação De Alimentos E Pratos Prontos",
  C10_99__6: "Fabricação De Produtos Alimentícios Não Especificados Anteriormente",
  C10_99__6_01: "Fabricação De Vinagres",
  C10_99__6_02: "Fabricação De Pós Alimentícios",
  C10_99__6_03: "Fabricação De Fermentos E Leveduras",
  C10_99__6_04: "Fabricação De Gelo Comum",
  C10_99__6_05: "Fabricação De Produtos Para Infusão (Chá, Mate, Etc.)",
  C10_99__6_06: "Fabricação De Adoçantes Naturais E Artificiais",
  C10_99__6_07: "Fabricação De Alimentos Dietéticos E Complementos Alimentares",
  C10_99__6_99: "Fabricação De Outros Produtos Alimentícios Não Especificados Anteriormente",
  C11: "Fabricação De Bebidas",
  C11_1: "Fabricação De Bebidas Alcoólicas",
  C11_11__9: "Fabricação De Aguardentes E Outras Bebidas Destiladas",
  C11_11__9_01: "Fabricação De Aguardente De Cana De Açúcar",
  C11_11__9_02: "Fabricação De Outras Aguardentes E Bebidas Destiladas",
  C11_12__7: "Fabricação De Vinho",
  C11_13__5: "Fabricação De Malte, Cervejas E Chopes",
  C11_13__5_01: "Fabricação De Malte, Inclusive Malte Uísque",
  C11_13__5_02: "Fabricação De Cervejas E Chopes",
  C11_2: "Fabricação De Bebidas Não-Alcoólicas",
  C11_21__6: "Fabricação De Águas Envasadas",
  C11_22__4: "Fabricação De Refrigerantes E De Outras Bebidas Não Alcoólicas",
  C11_22__4_01: "Fabricação De Refrigerantes",
  C11_22__4_02: "Fabricação De Chá Mate E Outros Chás Prontos Para Consumo",
  C11_22__4_03: "Fabricação De Refrescos, Xaropes E Pós Para Refrescos, Exceto Refrescos De Frutas",
  C11_22__4_04: "Fabricação De Bebidas Isotônicas",
  C11_22__4_99: "Fabricação De Outras Bebidas Não Alcoólicas Não Especificadas Anteriormente",
  C12: "Fabricação De Produtos Do Fumo",
  C12_1: "Processamento Industrial Do Fumo",
  C12_20__4_01: "Fabricação De Cigarros",
  C12_20__4_02: "Fabricação De Cigarrilhas E Charutos",
  C12_20__4_03: "Fabricação De Filtros Para Cigarros",
  C12_20__4_99: "Fabricação De Outros Produtos Do Fumo, Exceto Cigarros, Cigarrilhas E Charutos",
  C13: "Fabricação De Produtos Têxteis",
  C13_1: "Preparação E Fiação De Fibras Têxteis",
  C13_11__1: "Preparação E Fiação De Fibras De Algodão",
  C13_12__0: "Preparação E Fiação De Fibras Têxteis Naturais, Exceto Algodão",
  C13_13__8: "Fiação De Fibras Artificiais E Sintéticas",
  C13_14__6: "Fabricação De Linhas Para Costurar E Bordar",
  C13_2: "Tecelagem, Exceto Malha",
  C13_21__9: "Tecelagem De Fios De Algodão",
  C13_22__7: "Tecelagem De Fios De Fibras Têxteis Naturais, Exceto Algodão",
  C13_23__5: "Tecelagem De Fios De Fibras Artificiais E Sintéticas",
  C13_3: "Fabricação De Tecidos De Malha",
  C13_4: "Acabamentos Em Fios, Tecidos E Artefatos Têxteis",
  C13_40__5_01: "Estamparia E Texturização Em Fios, Tecidos, Artefatos Têxteis E Peças Do Vestuário",
  C13_40__5_02: "Alvejamento, Tingimento E Torção Em Fios, Tecidos, Artefatos Têxteis E Peças Do Vestuário",
  C13_40__5_99: "Outros Serviços De Acabamento Em Fios, Tecidos, Artefatos Têxteis E Peças Do Vestuário",
  C13_5: "Fabricação De Artefatos Têxteis, Exceto Vestuário",
  C13_51__1: "Fabricação De Artefatos Têxteis Para Uso Doméstico",
  C13_52__9: "Fabricação De Artefatos De Tapeçaria",
  C13_53__7: "Fabricação De Artefatos De Cordoaria",
  C13_54__5: "Fabricação De Tecidos Especiais, Inclusive Artefatos",
  C13_59__6: "Fabricação De Outros Produtos Têxteis Não Especificados Anteriormente",
  C14: "Confecção De Artigos Do Vestuário E Acessórios",
  C14_2: "Fabricação De Artigos De Malharia E Tricotagem",
  C14_21__5: "Fabricação De Meias",
  C14_22__3: "Fabricação De Artigos Do Vestuário, Produzidos Em Malharias E Tricotagens, Exceto Meias",
  C14_11__8: "Confecção De Roupas Íntimas",
  C14_11__8_02: "Facção De Roupas Íntimas",
  C14_12__6: "Confecção De Peças De Vestuário, Exceto Roupas Íntimas",
  C14_12__6_01: "Confecção De Peças De Vestuário, Exceto Roupas Íntimas E As Confeccionadas Sob Medida",
  C14_12__6_02: "Confecção, Sob Medida, De Peças Do Vestuário, Exceto Roupas Íntimas",
  C14_12__6_03: "Facção De Peças Do Vestuário, Exceto Roupas Íntimas",
  C14_13__4: "Confecção De Roupas Profissionais",
  C14_13__4_01: "Confecção De Roupas Profissionais, Exceto Sob Medida",
  C14_13__4_02: "Confecção, Sob Medida, De Roupas Profissionais",
  C14_13__4_03: "Facção De Roupas Profissionais",
  C14_14__2: "Fabricação De Acessórios Do Vestuário, Exceto Para Segurança E Proteção",
  C15: "Preparação De Couros E Fabricação De Artefatos De Couro, Artigos Para Viagem E Calçados",
  C15_1: "Curtimento E Outras Preparações De Couro",
  C15_2: "Fabricação De Artigos Para Viagem E De Artefatos Diversos De Couro",
  C15_21__1: "Fabricação De Artigos Para Viagem, Bolsas E Semelhantes De Qualquer Material",
  C15_29__7: "Fabricação De Artefatos De Couro Não Especificados Anteriormente",
  C15_3: "Fabricação De Calçados",
  C15_33__5: "Fabricação De Calçados De Material Sintético",
  C15_31__9: "Fabricação De Calçados De Couro",
  C15_31__9_02: "Acabamento De Calçados De Couro Sob Contrato",
  C15_32__7: "Fabricação De Tênis De Qualquer Material",
  C15_39__4: "Fabricação De Calçados De Materiais Não Especificados Anteriormente",
  C15_4: "Fabricação De Partes Para Calçados, De Qualquer Material",
  C16: "Fabricação De Produtos De Madeira",
  C16_1: "Desdobramento De Madeira",
  C16_10__2_04: "Serrarias Sem Desdobramento De Madeira Em Bruto - Resserragem",
  C16_10__2_03: "Serrarias Com Desdobramento De Madeira Em Bruto",
  C16_10__2_05: "Serviço De Tratamento De Madeira Realizado Sob Contrato",
  C16_2: "Fabricação De Produtos De Madeira, Cortiça E Material Trançado, Exceto Móveis",
  C16_21__8: "Fabricação De Madeira Laminada E De Chapas De Madeira Compensada, Prensada E Aglomerada",
  C16_22__6: "Fabricação De Estruturas De Madeira E De Artigos De Carpintaria Para Construção",
  C16_22__6_99: "Fabricação De Outros Artigos De Carpintaria Para Construção",
  C16_22__6_01: "Fabricação De Casas De Madeira Pré Fabricadas",
  C16_22__6_02: "Fabricação De Esquadrias De Madeira E De Peças De Madeira Para Instalações Industriais E Comerciais",
  C16_23__4: "Fabricação De Artefatos De Tanoaria E De Embalagens De Madeira",
  C16_29__3: "Fabricação De Artefatos De Madeira, Palha, Cortiça, Vime E Material Trançado Não Especificados Anteriormente, Exceto Móveis",
  C16_29__3_01: "Fabricação De Artefatos Diversos De Madeira, Exceto Móveis",
  C16_29__3_02: "Fabricação De Artefatos Diversos De Cortiça, Bambu, Palha, Vime E Outros Materiais Trançados, Exceto Móveis",
  C17: "Fabricação De Celulose, Papel E Produtos De Papel",
  C17_1: "Fabricação De Celulose E Outras Pastas Para A Fabricação De Papel",
  C17_2: "Fabricação De Papel, Cartolina E Papel-Cartão",
  C17_21__4: "Fabricação De Papel",
  C17_22__2: "Fabricação De Cartolina E Papel-Cartão",
  C17_22__2_00: "Fabricação De Cartolina E Papel Cartão",
  C17_3: "Fabricação De Embalagens De Papel, Cartolina, Papel-Cartão E Papelão Ondulado",
  C17_31__1: "Fabricação De Embalagens De Papel",
  C17_32__0: "Fabricação De Embalagens De Cartolina E Papel-Cartão",
  C17_32__0_00: "Fabricação De Embalagens De Cartolina E Papel Cartão",
  C17_33__8: "Fabricação De Chapas E De Embalagens De Papelão Ondulado",
  C17_4: "Fabricação De Produtos Diversos De Papel, Cartolina, Papel-Cartão E Papelão Ondulado",
  C17_41__9: "Fabricação De Produtos De Papel, Cartolina, Papel Cartão E Papelão Ondulado Para Uso Comercial E De Escritório",
  C17_41__9_01: "Fabricação De Formulários Contínuos",
  C17_42__7: "Fabricação De Produtos De Papel Para Usos Doméstico E Higiênico-Sanitário",
  C17_42__7_01: "Fabricação De Fraldas Descartáveis",
  C17_42__7_02: "Fabricação De Absorventes Higiênicos",
  C17_42__7_99: "Fabricação De Produtos De Papel Para Uso Doméstico E Higiênico Sanitário Não Especificados Anteriormente",
  C17_49__4: "Fabricação De Produtos De Pastas Celulósicas, Papel, Cartolina, Papel-Cartão E Papelão Ondulado Não Especificados Anteriormente",
  C17_49__4_00: "Fabricação De Produtos De Pastas Celulósicas, Papel, Cartolina, Papel Cartão E Papelão Ondulado Não Especificados Anteriormente",
  C18: "Impressão E Reprodução De Gravações",
  C18_1: "Atividade De Impressão",
  C18_11__3: "Impressão De Jornais, Livros, Revistas E Outras Publicações Periódicas",
  C18_11__3_01: "Impressão De Jornais",
  C18_11__3_02: "Impressão De Livros, Revistas E Outras Publicações Periódicas",
  C18_12__1: "Impressão De Material De Segurança",
  C18_13__0: "Impressão De Materiais Para Outros Usos",
  C18_13__0_01: "Impressão De Material Para Uso Publicitário",
  C18_13__0_99: "Impressão De Material Para Outros Usos",
  C18_2: "Serviços De Pré-Impressão E Acabamentos Gráficos",
  C18_21__1: "Serviços De Pré-Impressão",
  C18_21__1_00: "Serviços De Pré Impressão",
  C18_22__9: "Serviços De Acabamentos Gráficos",
  C18_22__9_01: "Serviços De Encadernação E Plastificação",
  C18_22__9_99: "Serviços De Acabamentos Gráficos, Exceto Encadernação E Plastificação",
  C18_3: "Reprodução De Materiais Gravados Em Qualquer Suporte",
  C18_30__0_01: "Reprodução De Som Em Qualquer Suporte",
  C18_30__0_02: "Reprodução De Vídeo Em Qualquer Suporte",
  C18_30__0_03: "Reprodução De Software Em Qualquer Suporte",
  C19: "Fabricação De Coque, De Produtos Derivados Do Petróleo E De Biocombustíveis",
  C19_1: "Coquerias",
  C19_2: "Fabricação De Produtos Derivados Do Petróleo",
  C19_21__7: "Fabricação De Produtos Do Refino De Petróleo",
  C19_22__5: "Fabricação De Produtos Derivados Do Petróleo, Exceto Produtos Do Refino",
  C19_22__5_99: "Fabricação De Outros Produtos Derivados Do Petróleo, Exceto Produtos Do Refino",
  C19_22__5_01: "Formulação De Combustíveis",
  C19_22__5_02: "Rerrefino De Óleos Lubrificantes",
  C19_3: "Fabricação De Biocombustíveis",
  C19_31__4: "Fabricação De Álcool",
  C19_32__2: "Fabricação De Biocombustíveis, Exceto Álcool",
  C29: "Fabricação De Veículos Automotores, Reboques E Carrocerias",
  C29_1: "Fabricação De Automóveis, Camionetas E Utilitários",
  C29_10__7_02: "Fabricação De Chassis Com Motor Para Automóveis, Camionetas E Utilitários",
  C29_10__7_03: "Fabricação De Motores Para Automóveis, Camionetas E Utilitários",
  C29_2: "Fabricação De Caminhões E Ônibus",
  C29_20__4_02: "Fabricação De Motores Para Caminhões E Ônibus",
  C29_3: "Fabricação De Cabines, Carrocerias E Reboques Para Veículos Automotores",
  C29_30__1_01: "Fabricação De Cabines, Carrocerias E Reboques Para Caminhões",
  C29_30__1_02: "Fabricação De Carrocerias Para Ônibus",
  C29_30__1_03: "Fabricação De Cabines, Carrocerias E Reboques Para Outros Veículos Automotores, Exceto Caminhões E Ônibus",
  C29_4: "Fabricação De Peças E Acessórios Para Veículos Automotores",
  C29_41__7: "Fabricação De Peças E Acessórios Para O Sistema Motor De Veículos Automotores",
  C29_42__5: "Fabricação De Peças E Acessórios Para Os Sistemas De Marcha E Transmissão De Veículos Automotores",
  C29_43__3: "Fabricação De Peças E Acessórios Para O Sistema De Freios De Veículos Automotores",
  C29_44__1: "Fabricação De Peças E Acessórios Para O Sistema De Direção E Suspensão De Veículos Automotores",
  C29_45__0: "Fabricação De Material Elétrico E Eletrônico Para Veículos Automotores, Exceto Baterias",
  C29_49__2: "Fabricação De Peças E Acessórios Para Veículos Automotores Não Especificados Anteriormente",
  C29_49__2_01: "Fabricação De Bancos E Estofados Para Veículos Automotores",
  C29_49__2_99: "Fabricação De Outras Peças E Acessórios Para Veículos Automotores Não Especificadas Anteriormente",
  C29_5: "Recondicionamento E Recuperação De Motores Para Veículos Automotores",
  C20: "Fabricação De Produtos Químicos",
  C20_1: "Fabricação De Produtos Químicos Inorgânicos",
  C20_11__8: "Fabricação De Cloro E Álcalis",
  C20_12__6: "Fabricação De Intermediários Para Fertilizantes",
  C20_13__4: "Fabricação De Adubos E Fertilizantes",
  C20_13__4_01: "Fabricação De Adubos E Fertilizantes Organominerais",
  C20_13__4_02: "Fabricação De Adubos E Fertilizantes, Exceto Organominerais",
  C20_14__2: "Fabricação De Gases Industriais",
  C20_19__3: "Fabricação De Produtos Químicos Inorgânicos Não Especificados Anteriormente",
  C20_19__3_01: "Elaboração De Combustíveis Nucleares",
  C20_19__3_99: "Fabricação De Outros Produtos Químicos Inorgânicos Não Especificados Anteriormente",
  C20_2: "Fabricação De Produtos Químicos Orgânicos",
  C20_21__5: "Fabricação De Produtos Petroquímicos Básicos",
  C20_22__3: "Fabricação De Intermediários Para Plastificantes, Resinas E Fibras",
  C20_29__1: "Fabricação De Produtos Químicos Orgânicos Não Especificados Anteriormente",
  C20_3: "Fabricação De Resinas E Elastômeros",
  C20_33__9: "Fabricação De Elastômeros",
  C20_31__2: "Fabricação De Resinas Termoplásticas",
  C20_32__1: "Fabricação De Resinas Termofixas",
  C20_4: "Fabricação De Fibras Artificiais E Sintéticas",
  C20_5: "Fabricação De Defensivos Agrícolas E Desinfestantes Domissanitários",
  C20_51__7: "Fabricação De Defensivos Agrícolas",
  C20_52__5: "Fabricação De Desinfestantes Domissanitários",
  C20_6: "Fabricação De Sabões, Detergentes, Produtos De Limpeza, Cosméticos, Produtos De Perfumaria E De Higiene Pessoal",
  C20_61__4: "Fabricação De Sabões E Detergentes Sintéticos",
  C20_62__2: "Fabricação De Produtos De Limpeza E Polimento",
  C20_63__1: "Fabricação De Cosméticos, Produtos De Perfumaria E De Higiene Pessoal",
  C20_7: "Fabricação De Tintas, Vernizes, Esmaltes, Lacas E Produtos Afins",
  C20_71__1: "Fabricação De Tintas, Vernizes, Esmaltes E Lacas",
  C20_72__0: "Fabricação De Tintas De Impressão",
  C20_73__8: "Fabricação De Impermeabilizantes, Solventes E Produtos Afins",
  C20_9: "Fabricação De Produtos E Preparados Químicos Diversos",
  C20_91__6: "Fabricação De Adesivos E Selantes",
  C20_92__4: "Fabricação De Explosivos",
  C20_92__4_01: "Fabricação De Pólvoras, Explosivos E Detonantes",
  C20_92__4_02: "Fabricação De Artigos Pirotécnicos",
  C20_92__4_03: "Fabricação De Fósforos De Segurança",
  C20_93__2: "Fabricação De Aditivos De Uso Industrial",
  C20_94__1: "Fabricação De Catalisadores",
  C20_99__1: "Fabricação De Produtos Químicos Não Especificados Anteriormente",
  C20_99__1_01: "Fabricação De Chapas, Filmes, Papéis E Outros Materiais E Produtos Químicos Para Fotografia",
  C20_99__1_99: "Fabricação De Outros Produtos Químicos Não Especificados Anteriormente",
  C21: "Fabricação De Produtos Farmoquímicos E Farmacêuticos",
  C21_1: "Fabricação De Produtos Farmoquímicos",
  C21_2: "Fabricação De Produtos Farmacêuticos",
  C21_21__1: "Fabricação De Medicamentos Para Uso Humano",
  C21_21__1_01: "Fabricação De Medicamentos Alopáticos Para Uso Humano",
  C21_21__1_02: "Fabricação De Medicamentos Homeopáticos Para Uso Humano",
  C21_21__1_03: "Fabricação De Medicamentos Fitoterápicos Para Uso Humano",
  C21_22__0: "Fabricação De Medicamentos Para Uso Veterinário",
  C21_23__8: "Fabricação De Preparações Farmacêuticas",
  C22: "Fabricação De Produtos De Borracha E De Material Plástico",
  C22_1: "Fabricação De Produtos De Borracha",
  C22_11__1: "Fabricação De Pneumáticos E De Câmaras-De-Ar",
  C22_11__1_00: "Fabricação De Pneumáticos E De Câmaras De Ar",
  C22_12__9: "Reforma De Pneumáticos Usados",
  C22_19__6: "Fabricação De Artefatos De Borracha Não Especificados Anteriormente",
  C22_2: "Fabricação De Produtos De Material Plástico",
  C22_21__8: "Fabricação De Laminados Planos E Tubulares De Material Plástico",
  C22_22__6: "Fabricação De Embalagens De Material Plástico",
  C22_23__4: "Fabricação De Tubos E Acessórios De Material Plástico Para Uso Na Construção",
  C22_29__3: "Fabricação De Artefatos De Material Plástico Não Especificados Anteriormente",
  C22_29__3_01: "Fabricação De Artefatos De Material Plástico Para Uso Pessoal E Doméstico",
  C22_29__3_02: "Fabricação De Artefatos De Material Plástico Para Usos Industriais",
  C22_29__3_03: "Fabricação De Artefatos De Material Plástico Para Uso Na Construção, Exceto Tubos E Acessórios",
  C22_29__3_99: "Fabricação De Artefatos De Material Plástico Para Outros Usos Não Especificados Anteriormente",
  C23: "Fabricação De Produtos De Minerais Não-Metálicos",
  C23_1: "Fabricação De Vidro E De Produtos Do Vidro",
  C23_12__5: "Fabricação De Embalagens De Vidro",
  C23_11__7: "Fabricação De Vidro Plano E De Segurança",
  C23_19__2: "Fabricação De Artigos De Vidro",
  C23_2: "Fabricação De Cimento",
  C23_3: "Fabricação De Artefatos De Concreto, Cimento, Fibrocimento, Gesso E Materiais Semelhantes",
  C23_30__3_01: "Fabricação De Estruturas Pré Moldadas De Concreto Armado, Em Série E Sob Encomenda",
  C23_30__3_02: "Fabricação De Artefatos De Cimento Para Uso Na Construção",
  C23_30__3_03: "Fabricação De Artefatos De Fibrocimento Para Uso Na Construção",
  C23_30__3_04: "Fabricação De Casas Pré Moldadas De Concreto",
  C23_30__3_05: "Preparação De Massa De Concreto E Argamassa Para Construção",
  C23_30__3_99: "Fabricação De Outros Artefatos E Produtos De Concreto, Cimento, Fibrocimento, Gesso E Materiais Semelhantes",
  C23_4: "Fabricação De Produtos Cerâmicos",
  C23_41__9: "Fabricação De Produtos Cerâmicos Refratários",
  C23_42__7: "Fabricação De Produtos Cerâmicos Não Refratários Para Uso Estrutural Na Construção",
  C23_42__7_01: "Fabricação De Azulejos E Pisos",
  C23_42__7_02: "Fabricação De Artefatos De Cerâmica E Barro Cozido Para Uso Na Construção, Exceto Azulejos E Pisos",
  C23_49__4: "Fabricação De Produtos Cerâmicos Não Refratários Não Especificados Anteriormente",
  C23_49__4_01: "Fabricação De Material Sanitário De Cerâmica",
  C23_9: "Aparelhamento De Pedras E Fabricação De Outros Produtos De Minerais Não-Metálicos",
  C23_91__5: "Aparelhamento E Outros Trabalhos Em Pedras",
  C23_91__5_01: "Britamento De Pedras, Exceto Associado À Extração",
  C23_91__5_02: "Aparelhamento De Pedras Para Construção, Exceto Associado À Extração",
  C23_91__5_03: "Aparelhamento De Placas E Execução De Trabalhos Em Mármore, Granito, Ardósia E Outras Pedras",
  C23_92__3: "Fabricação De Cal E Gesso",
  C23_99__1: "Fabricação De Produtos De Minerais Não Metálicos Não Especificados Anteriormente",
  C23_99__1_01: "Decoração, Lapidação, Gravação, Vitrificação E Outros Trabalhos Em Cerâmica, Louça, Vidro E Cristal",
  C23_99__1_02: "Fabricação De Abrasivos",
  C23_99__1_99: "Fabricação De Outros Produtos De Minerais Não Metálicos Não Especificados Anteriormente",
  C24: "Metalurgia",
  C24_1: "Produção De Ferro-Gusa E De Ferroligas",
  C24_11__3: "Produção De Ferro-Gusa",
  C24_11__3_00: "Produção De Ferro Gusa",
  C24_12__1: "Produção De Ferroligas",
  C24_2: "Siderurgia",
  C24_21__1: "Produção De Semiacabados De Aço",
  C24_21__1_00: "Produção De Semi Acabados De Aço",
  C24_22__9: "Produção De Laminados Planos De Aço",
  C24_22__9_01: "Produção De Laminados Planos De Aço Ao Carbono, Revestidos Ou Não",
  C24_22__9_02: "Produção De Laminados Planos De Aços Especiais",
  C24_23__7: "Produção De Laminados Longos De Aço",
  C24_23__7_01: "Produção De Tubos De Aço Sem Costura",
  C24_23__7_02: "Produção De Laminados Longos De Aço, Exceto Tubos",
  C24_24__5: "Produção De Relaminados, Trefilados E Perfilados De Aço",
  C24_24__5_02: "Produção De Relaminados, Trefilados E Perfilados De Aço, Exceto Arames",
  C24_24__5_01: "Produção De Arames De Aço",
  C24_3: "Produção De Tubos De Aço, Exceto Tubos Sem Costura",
  C24_31__8: "Produção De Tubos De Aço Com Costura",
  C24_39__3: "Produção De Outros Tubos De Ferro E Aço",
  C24_4: "Metalurgia Dos Metais Não-Ferrosos",
  C24_41__5: "Metalurgia Do Alumínio E Suas Ligas",
  C24_41__5_01: "Produção De Alumínio E Suas Ligas Em Formas Primárias",
  C24_41__5_02: "Produção De Laminados De Alumínio",
  C24_42__3: "Metalurgia Dos Metais Preciosos",
  C24_43__1: "Metalurgia Do Cobre",
  C24_49__1: "Metalurgia Dos Metais Não Ferrosos E Suas Ligas Não Especificados Anteriormente",
  C24_49__1_01: "Produção De Zinco Em Formas Primárias",
  C24_49__1_02: "Produção De Laminados De Zinco",
  C24_49__1_03: "Fabricação De Ânodos Para Galvanoplastia",
  C24_49__1_99: "Metalurgia De Outros Metais Não Ferrosos E Suas Ligas Não Especificados Anteriormente",
  C24_5: "Fundição",
  C24_51__2: "Fundição De Ferro E Aço",
  C24_52__1: "Fundição De Metais Não Ferrosos E Suas Ligas",
  C25: "Fabricação De Produtos De Metal, Exceto Máquinas  E Equipamentos",
  C25_1: "Fabricação De Estruturas Metálicas E Obras De Caldeiraria Pesada",
  C25_11__0: "Fabricação De Estruturas Metálicas",
  C25_12__8: "Fabricação De Esquadrias De Metal",
  C25_13__6: "Fabricação De Obras De Caldeiraria Pesada",
  C25_2: "Fabricação De Tanques, Reservatórios Metálicos E Caldeiras",
  C25_21__7: "Fabricação De Tanques, Reservatórios Metálicos E Caldeiras Para Aquecimento Central",
  C25_22__5: "Fabricação De Caldeiras Geradoras De Vapor, Exceto Para Aquecimento Central E Para Veículos",
  C25_3: "Forjaria, Estamparia, Metalurgia Do Pó E Serviços De Tratamento De Metais",
  C25_31__4: "Produção De Forjados De Aço E De Metais Não Ferrosos E Suas Ligas",
  C25_31__4_01: "Produção De Forjados De Aço",
  C25_31__4_02: "Produção De Forjados De Metais Não Ferrosos E Suas Ligas",
  C25_32__2: "Produção De Artefatos Estampados De Metal; Metalurgia Do Pó",
  C25_32__2_01: "Produção De Artefatos Estampados De Metal",
  C25_32__2_02: "Metalurgia Do Pó",
  C25_39__0: "Serviços De Usinagem, Solda, Tratamento E Revestimento Em Metais",
  C25_39__0_01: "Serviços De Usinagem, Tornearia E Solda",
  C25_39__0_02: "Serviços De Tratamento E Revestimento Em Metais",
  C25_4: "Fabricação De Artigos De Cutelaria, De Serralheria E Ferramentas",
  C25_41__1: "Fabricação De Artigos De Cutelaria",
  C25_42__0: "Fabricação De Artigos De Serralheria, Exceto Esquadrias",
  C25_43__8: "Fabricação De Ferramentas",
  C25_5: "Fabricação De Equipamento Bélico Pesado, Armas E Munições",
  C25_50__1_01: "Fabricação De Equipamento Bélico Pesado, Exceto Veículos Militares De Combate",
  C25_50__1_02: "Fabricação De Armas De Fogo, Outras Armas  E Munições",
  C25_9: "Fabricação De Produtos De Metal Não Especificados Anteriormente",
  C25_91__8: "Fabricação De Embalagens Metálicas",
  C25_92__6: "Fabricação De Produtos De Trefilados De Metal",
  C25_92__6_01: "Fabricação De Produtos De Trefilados De Metal Padronizados",
  C25_92__6_02: "Fabricação De Produtos De Trefilados De Metal, Exceto Padronizados",
  C25_93__4: "Fabricação De Artigos De Metal Para Uso Doméstico E Pessoal",
  C25_99__3_01: "Serviços De Confecção De Armações Metálicas Para A Construção",
  C25_99__3_02: "Serviço De Corte E Dobra De Metais",
  C25_99__3_99: "Fabricação De Outros Produtos De Metal Não Especificados Anteriormente",
  C26: "Fabricação De Equipamentos De Informática, Produtos Eletrônicos E Ópticos",
  C26_1: "Fabricação De Componentes Eletrônicos",
  C26_2: "Fabricação De Equipamentos De Informática E Periféricos",
  C26_21__3: "Fabricação De Equipamentos De Informática",
  C26_22__1: "Fabricação De Periféricos Para Equipamentos De Informática",
  C26_3: "Fabricação De Equipamentos De Comunicação",
  C26_31__1: "Fabricação De Equipamentos Transmissores De Comunicação",
  C26_31__1_00: "Fabricação De Equipamentos Transmissores De Comunicação, Peças E Acessórios",
  C26_32__9: "Fabricação De Aparelhos Telefônicos E De Outros Equipamentos De Comunicação",
  C26_32__9_00: "Fabricação De Aparelhos Telefônicos E De Outros Equipamentos De Comunicação, Peças E Acessórios",
  C26_4: "Fabricação De Aparelhos De Recepção, Reprodução, Gravação E Amplificação De Áudio E Vídeo",
  C26_5: "Fabricação De Aparelhos E Instrumentos De Medida, Teste E Controle; Cronômetros E Relógios",
  C26_51__5: "Fabricação De Aparelhos E Equipamentos De Medida, Teste E Controle",
  C26_52__3: "Fabricação De Cronômetros E Relógios",
  C26_6: "Fabricação De Aparelhos Eletromédicos E Eletroterapêuticos E Equipamentos De Irradiação",
  C26_7: "Fabricação De Equipamentos E Instrumentos Ópticos, Fotográficos E Cinematográficos",
  C26_70__1_02: "Fabricação De Aparelhos Fotográficos E Cinematográficos, Peças E Acessórios",
  C26_70__1_01: "Fabricação De Equipamentos E Instrumentos Ópticos, Peças E Acessórios",
  C26_8: "Fabricação De Mídias Virgens, Magnéticas E Ópticas",
  C27: "Fabricação De Máquinas, Aparelhos E Materiais Elétricos",
  C27_1: "Fabricação De Geradores, Transformadores E Motores Elétricos",
  C27_10__4_01: "Fabricação De Geradores De Corrente Contínua E Alternada, Peças E Acessórios",
  C27_10__4_02: "Fabricação De Transformadores, Indutores, Conversores, Sincronizadores E Semelhantes, Peças E Acessórios",
  C27_10__4_03: "Fabricação De Motores Elétricos, Peças E Acessórios",
  C27_2: "Fabricação De Pilhas, Baterias E Acumuladores Elétricos",
  C27_21__0: "Fabricação De Pilhas, Baterias E Acumuladores Elétricos, Exceto Para Veículos Automotores",
  C27_22__8: "Fabricação De Baterias E Acumuladores Para Veículos Automotores",
  C27_22__8_02: "Recondicionamento De Baterias E Acumuladores Para Veículos Automotores",
  C27_3: "Fabricação De Equipamentos Para Distribuição E Controle De Energia Elétrica",
  C27_31__7: "Fabricação De Aparelhos E Equipamentos Para Distribuição E Controle De Energia Elétrica",
  C27_32__5: "Fabricação De Material Elétrico Para Instalações Em Circuito De Consumo",
  C27_33__3: "Fabricação De Fios, Cabos E Condutores Elétricos Isolados",
  C27_4: "Fabricação De Lâmpadas E Outros Equipamentos De Iluminação",
  C27_40__6_01: "Fabricação De Lâmpadas",
  C27_40__6_02: "Fabricação De Luminárias E Outros Equipamentos De Iluminação",
  C27_5: "Fabricação De Eletrodomésticos",
  C27_51__1: "Fabricação De Fogões, Refrigeradores E Máquinas De Lavar E Secar Para Uso Doméstico",
  C27_51__1_00: "Fabricação De Fogões, Refrigeradores E Máquinas De Lavar E Secar Para Uso Doméstico, Peças E Acessórios",
  C27_59__7: "Fabricação De Aparelhos Eletrodomésticos Não Especificados Anteriormente",
  C27_59__7_01: "Fabricação De Aparelhos Elétricos De Uso Pessoal, Peças E Acessórios",
  C27_59__7_99: "Fabricação De Outros Aparelhos Eletrodomésticos Não Especificados Anteriormente, Peças E Acessórios",
  C27_9: "Fabricação De Equipamentos E Aparelhos Elétricos Não Especificados Anteriormente",
  C27_90__2_01: "Fabricação De Eletrodos, Contatos E Outros Artigos De Carvão E Grafita Para Uso Elétrico, Eletroímãs E Isoladores",
  C27_90__2_02: "Fabricação De Equipamentos Para Sinalização E Alarme",
  C27_90__2_99: "Fabricação De Outros Equipamentos E Aparelhos Elétricos Não Especificados Anteriormente",
  C28: "Fabricação De Máquinas E Equipamentos",
  C28_1: "Fabricação De Motores, Bombas, Compressores E Equipamentos De Transmissão",
  C28_11__9: "Fabricação De Motores E Turbinas, Exceto Para Aviões E Veículos Rodoviários",
  C28_11__9_00: "Fabricação De Motores E Turbinas, Peças E Acessórios, Exceto Para Aviões E Veículos Rodoviários",
  C28_12__7: "Fabricação De Equipamentos Hidráulicos E Pneumáticos, Exceto Válvulas",
  C28_12__7_00: "Fabricação De Equipamentos Hidráulicos E Pneumáticos, Peças E Acessórios, Exceto Válvulas",
  C28_13__5: "Fabricação De Válvulas, Registros E Dispositivos Semelhantes",
  C28_13__5_00: "Fabricação De Válvulas, Registros E Dispositivos Semelhantes, Peças E Acessórios",
  C28_14__3: "Fabricação De Compressores",
  C28_14__3_01: "Fabricação De Compressores Para Uso Industrial, Peças E Acessórios",
  C28_14__3_02: "Fabricação De Compressores Para Uso Não Industrial, Peças E Acessórios",
  C28_15__1: "Fabricação De Equipamentos De Transmissão Para Fins Industriais",
  C28_15__1_01: "Fabricação De Rolamentos Para Fins Industriais",
  C28_15__1_02: "Fabricação De Equipamentos De Transmissão Para Fins Industriais, Exceto Rolamentos",
  C28_2: "Fabricação De Máquinas E Equipamentos De Uso Geral",
  C28_21__6: "Fabricação De Aparelhos E Equipamentos Para Instalações Térmicas",
  C28_21__6_01: "Fabricação De Fornos Industriais, Aparelhos E Equipamentos Não Elétricos Para Instalações Térmicas, Peças E Acessórios",
  C28_21__6_02: "Fabricação De Estufas E Fornos Elétricos Para Fins Industriais, Peças E Acessórios",
  C28_22__4: "Fabricação De Máquinas, Equipamentos E Aparelhos Para Transporte E Elevação De Cargas E Pessoas",
  C28_22__4_01: "Fabricação De Máquinas, Equipamentos E Aparelhos Para Transporte E Elevação De Pessoas, Peças E Acessórios",
  C28_22__4_02: "Fabricação De Máquinas, Equipamentos E Aparelhos Para Transporte E Elevação De Cargas, Peças E Acessórios",
  C28_23__2: "Fabricação De Máquinas E Aparelhos De Refrigeração E Ventilação Para Uso Industrial E Comercial",
  C28_23__2_00: "Fabricação De Máquinas E Aparelhos De Refrigeração E Ventilação Para Uso Industrial E Comercial, Peças E Acessórios",
  C28_24__1: "Fabricação De Aparelhos E Equipamentos De Ar Condicionado",
  C28_24__1_02: "Fabricação De Aparelhos E Equipamentos De Ar Condicionado Para Uso Não Industrial",
  C28_24__1_01: "Fabricação De Aparelhos E Equipamentos De Ar Condicionado Para Uso Industrial",
  C28_25__9: "Fabricação De Máquinas E Equipamentos Para Saneamento Básico E Ambiental",
  C28_25__9_00: "Fabricação De Máquinas E Equipamentos Para Saneamento Básico E Ambiental, Peças E Acessórios",
  C28_29__1: "Fabricação De Máquinas E Equipamentos De Uso Geral Não Especificados Anteriormente",
  C28_29__1_01: "Fabricação De Máquinas De Escrever, Calcular E Outros Equipamentos Não Eletrônicos Para Escritório, Peças E Acessórios",
  C28_29__1_99: "Fabricação De Outras Máquinas E Equipamentos De Uso Geral Não Especificados Anteriormente, Peças E Acessórios",
  C28_3: "Fabricação De Tratores E De Máquinas E Equipamentos Para A Agricultura E Pecuária",
  C28_32__1: "Fabricação De Equipamentos Para Irrigação Agrícola",
  C28_32__1_00: "Fabricação De Equipamentos Para Irrigação Agrícola, Peças E Acessórios",
  C28_31__3: "Fabricação De Tratores Agrícolas",
  C28_31__3_00: "Fabricação De Tratores Agrícolas, Peças E Acessórios",
  C28_33__0: "Fabricação De Máquinas E Equipamentos Para A Agricultura E Pecuária, Exceto Para Irrigação",
  C28_33__0_00: "Fabricação De Máquinas E Equipamentos Para A Agricultura E Pecuária, Peças E Acessórios, Exceto Para Irrigação",
  C28_4: "Fabricação De Máquinas-Ferramenta",
  C28_40__2_00: "Fabricação De Máquinas Ferramenta, Peças E Acessórios",
  C28_5: "Fabricação De Máquinas E Equipamentos De Uso Na Extração Mineral E Na Construção",
  C28_51__8: "Fabricação De Máquinas E Equipamentos Para A Prospecção E Extração De Petróleo",
  C28_51__8_00: "Fabricação De Máquinas E Equipamentos Para A Prospecção E Extração De Petróleo, Peças E Acessórios",
  C28_52__6: "Fabricação De Outras Máquinas E Equipamentos Para Uso Na Extração Mineral, Exceto Na Extração De Petróleo",
  C28_52__6_00: "Fabricação De Outras Máquinas E Equipamentos Para Uso Na Extração Mineral, Peças E Acessórios, Exceto Na Extração De Petróleo",
  C28_53__4: "Fabricação De Tratores, Exceto Agrícolas",
  C28_53__4_00: "Fabricação De Tratores, Peças E Acessórios, Exceto Agrícolas",
  C28_54__2: "Fabricação De Máquinas E Equipamentos Para Terraplenagem, Pavimentação E Construção, Exceto Tratores",
  C28_54__2_00: "Fabricação De Máquinas E Equipamentos Para Terraplenagem, Pavimentação E Construção, Peças E Acessórios, Exceto Tratores",
  C28_6: "Fabricação De Máquinas E Equipamentos De Uso Industrial Específico",
  C28_61__5: "Fabricação De Máquinas Para A Indústria Metalúrgica, Exceto Máquinas-Ferramenta",
  C28_61__5_00: "Fabricação De Máquinas Para A Indústria Metalúrgica, Peças E Acessórios, Exceto Máquinas Ferramenta",
  C28_62__3: "Fabricação De Máquinas E Equipamentos Para As Indústrias De Alimentos, Bebidas E Fumo",
  C28_62__3_00: "Fabricação De Máquinas E Equipamentos Para As Indústrias De Alimentos, Bebidas E Fumo, Peças E Acessórios",
  C28_63__1: "Fabricação De Máquinas E Equipamentos Para A Indústria Têxtil",
  C28_63__1_00: "Fabricação De Máquinas E Equipamentos Para A Indústria Têxtil, Peças E Acessórios",
  C28_64__0: "Fabricação De Máquinas E Equipamentos Para As Indústrias Do Vestuário, Do Couro E De Calçados",
  C28_64__0_00: "Fabricação De Máquinas E Equipamentos Para As Indústrias Do Vestuário, Do Couro E De Calçados, Peças E Acessórios",
  C28_65__8: "Fabricação De Máquinas E Equipamentos Para As Indústrias De Celulose, Papel E Papelão E Artefatos",
  C28_65__8_00: "Fabricação De Máquinas E Equipamentos Para As Indústrias De Celulose, Papel E Papelão E Artefatos, Peças E Acessórios",
  C28_66__6: "Fabricação De Máquinas E Equipamentos Para A Indústria Do Plástico",
  C28_66__6_00: "Fabricação De Máquinas E Equipamentos Para A Indústria Do Plástico, Peças E Acessórios",
  C28_69__1: "Fabricação De Máquinas E Equipamentos Para Uso Industrial Específico Não Especificados Anteriormente",
  C28_69__1_00: "Fabricação De Máquinas E Equipamentos Para Uso Industrial Específico Não Especificados Anteriormente, Peças E Acessórios",
  C30: "Fabricação De Outros Equipamentos De Transporte, Exceto Veículos Automotores",
  C30_1: "Construção De Embarcações",
  C30_11__3: "Construção De Embarcações E Estruturas Flutuantes",
  C30_11__3_01: "Construção De Embarcações De Grande Porte",
  C30_11__3_02: "Construção De Embarcações Para Uso Comercial E Para Usos Especiais, Exceto De Grande Porte",
  C30_12__1: "Construção De Embarcações Para Esporte E Lazer",
  C30_3: "Fabricação De Veículos Ferroviários",
  C30_31__8: "Fabricação De Locomotivas, Vagões E Outros Materiais Rodantes",
  C30_32__6: "Fabricação De Peças E Acessórios Para Veículos Ferroviários",
  C30_4: "Fabricação De Aeronaves",
  C30_42__3: "Fabricação De Turbinas, Motores E Outros Componentes E Peças Para Aeronaves",
  C30_5: "Fabricação De Veículos Militares De Combate",
  C30_9: "Fabricação De Equipamentos De Transporte Não Especificados Anteriormente",
  C30_91__1: "Fabricação De Motocicletas",
  C30_91__1_02: "Fabricação De Peças E Acessórios Para Motocicletas",
  C30_92__0: "Fabricação De Bicicletas E Triciclos Não Motorizados",
  C30_92__0_00: "Fabricação De Bicicletas E Triciclos Não Motorizados, Peças E Acessórios",
  C31: "Fabricação De Móveis",
  C31_01__2: "Fabricação De Móveis Com Predominância De Madeira",
  C31_02__1: "Fabricação De Móveis Com Predominância De Metal",
  C31_03__9: "Fabricação De Móveis De Outros Materiais, Exceto Madeira E Metal",
  C31_04__7: "Fabricação De Colchões",
  C32: "Fabricação De Produtos Diversos",
  C32_1: "Fabricação De Artigos De Joalheria, Bijuteria E Semelhantes",
  C32_11__6: "Lapidação De Gemas E Fabricação De Artefatos De Ourivesaria E Joalheria",
  C32_11__6_01: "Lapidação De Gemas",
  C32_11__6_02: "Fabricação De Artefatos De Joalheria E Ourivesaria",
  C32_11__6_03: "Cunhagem De Moedas E Medalhas",
  C32_12__4: "Fabricação De Bijuterias E Artefatos Semelhantes",
  C32_2: "Fabricação De Instrumentos Musicais",
  C32_20__5_00: "Fabricação De Instrumentos Musicais, Peças E Acessórios",
  C32_3: "Fabricação De Artefatos Para Pesca E Esporte",
  C32_4: "Fabricação De Brinquedos E Jogos Recreativos",
  C32_40__0_01: "Fabricação De Jogos Eletrônicos",
  C32_40__0_02: "Fabricação De Mesas De Bilhar, De Sinuca E Acessórios Não Associada À Locação",
  C32_40__0_03: "Fabricação De Mesas De Bilhar, De Sinuca E Acessórios Associada À Locação",
  C32_40__0_99: "Fabricação De Outros Brinquedos E Jogos Recreativos Não Especificados Anteriormente",
  C32_5: "Fabricação De Instrumentos E Materiais Para Uso Médico E Odontológico E De Artigos Ópticos",
  C32_50__7_04: "Fabricação De Aparelhos E Utensílios Para Correção De Defeitos Físicos E Aparelhos Ortopédicos Em Geral, Exceto Sob Encomenda",
  C32_50__7_02: "Fabricação De Mobiliário Para Uso Médico, Cirúrgico, Odontológico E De Laboratório",
  C32_50__7_03: "Fabricação De Aparelhos E Utensílios Para Correção De Defeitos Físicos E Aparelhos Ortopédicos Em Geral Sob Encomenda",
  C32_50__7_01: "Fabricação De Instrumentos Não Eletrônicos E Utensílios Para Uso Médico, Cirúrgico, Odontológico E De Laboratório",
  C32_50__7_05: "Fabricação De Materiais Para Medicina E Odontologia",
  C32_50__7_06: "Serviços De Prótese Dentária",
  C32_50__7_07: "Fabricação De Artigos Ópticos",
  C32_50__7_09: "Serviço De Laboratório Óptico",
  C32_91__4: "Fabricação De Escovas, Pincéis E Vassouras",
  C32_92__2: "Fabricação De Equipamentos E Acessórios Para Segurança E Proteção Pessoal E Profissional",
  C32_92__2_01: "Fabricação De Roupas De Proteção E Segurança E Resistentes A Fogo",
  C32_92__2_02: "Fabricação De Equipamentos E Acessórios Para Segurança Pessoal E Profissional",
  C32_99__0: "Fabricação De Produtos Diversos Não Especificados Anteriormente",
  C32_99__0_04: "Fabricação De Painéis E Letreiros Luminosos",
  C32_99__0_05: "Fabricação De Aviamentos Para Costura",
  C32_99__0_06: "Fabricação De Velas, Inclusive Decorativas",
  C32_99__0_01: "Fabricação De Guarda Chuvas E Similares",
  C32_99__0_02: "Fabricação De Canetas, Lápis E Outros Artigos Para Escritório",
  C32_99__0_03: "Fabricação De Letras, Letreiros E Placas De Qualquer Material, Exceto Luminosos",
  C33: "Manutenção, Reparação E Instalação De Máquinas E Equipamentos",
  C33_1: "Manutenção E Reparação De Máquinas E Equipamentos",
  C33_11__2: "Manutenção E Reparação De Tanques, Reservatórios Metálicos E Caldeiras, Exceto Para Veículos",
  C33_12__1: "Manutenção E Reparação De Equipamentos Eletrônicos E Ópticos",
  C33_12__1_02: "Manutenção E Reparação De Aparelhos E Instrumentos De Medida, Teste E Controle",
  C33_12__1_03: "Manutenção E Reparação De Aparelhos Eletromédicos E Eletroterapêuticos E Equipamentos De Irradiação",
  C33_12__1_04: "Manutenção E Reparação De Equipamentos E Instrumentos Ópticos",
  C33_13__9: "Manutenção E Reparação De Máquinas E Equipamentos Elétricos",
  C33_13__9_01: "Manutenção E Reparação De Geradores, Transformadores E Motores Elétricos",
  C33_13__9_02: "Manutenção E Reparação De Baterias E Acumuladores Elétricos, Exceto Para Veículos",
  C33_13__9_99: "Manutenção E Reparação De Máquinas, Aparelhos E Materiais Elétricos Não Especificados Anteriormente",
  C33_14__7: "Manutenção E Reparação De Máquinas E Equipamentos Da Indústria Mecânica",
  C33_14__7_05: "Manutenção E Reparação De Equipamentos De Transmissão Para Fins Industriais",
  C33_14__7_06: "Manutenção E Reparação De Máquinas, Aparelhos E Equipamentos Para Instalações Térmicas",
  C33_14__7_03: "Manutenção E Reparação De Válvulas Industriais",
  C33_14__7_04: "Manutenção E Reparação De Compressores",
  C33_14__7_15: "Manutenção E Reparação De Máquinas E Equipamentos Para Uso Na Extração Mineral, Exceto Na Extração De Petróleo",
  C33_14__7_16: "Manutenção E Reparação De Tratores, Exceto Agrícolas",
  C33_14__7_01: "Manutenção E Reparação De Máquinas Motrizes Não Elétricas",
  C33_14__7_02: "Manutenção E Reparação De Equipamentos Hidráulicos E Pneumáticos, Exceto Válvulas",
  C33_14__7_07: "Manutenção E Reparação De Máquinas E Aparelhos De Refrigeração E Ventilação Para Uso Industrial E Comercial",
  C33_14__7_08: "Manutenção E Reparação De Máquinas, Equipamentos E Aparelhos Para Transporte E Elevação De Cargas",
  C33_14__7_09: "Manutenção E Reparação De Máquinas De Escrever, Calcular E De Outros Equipamentos Não Eletrônicos Para Escritório",
  C33_14__7_10: "Manutenção E Reparação De Máquinas E Equipamentos Para Uso Geral Não Especificados Anteriormente",
  C33_14__7_11: "Manutenção E Reparação De Máquinas E Equipamentos Para Agricultura E Pecuária",
  C33_14__7_12: "Manutenção E Reparação De Tratores Agrícolas",
  C33_14__7_13: "Manutenção E Reparação De Máquinas Ferramenta",
  C33_14__7_14: "Manutenção E Reparação De Máquinas E Equipamentos Para A Prospecção E Extração De Petróleo",
  C33_14__7_17: "Manutenção E Reparação De Máquinas E Equipamentos De Terraplenagem, Pavimentação E Construção, Exceto Tratores",
  C33_14__7_18: "Manutenção E Reparação De Máquinas Para A Indústria Metalúrgica, Exceto Máquinas Ferramenta",
  C33_14__7_19: "Manutenção E Reparação De Máquinas E Equipamentos Para As Indústrias De Alimentos, Bebidas E Fumo",
  C33_14__7_20: "Manutenção E Reparação De Máquinas E Equipamentos Para A Indústria Têxtil, Do Vestuário, Do Couro E Calçados",
  C33_14__7_21: "Manutenção E Reparação De Máquinas E Aparelhos Para A Indústria De Celulose, Papel E Papelão E Artefatos",
  C33_14__7_22: "Manutenção E Reparação De Máquinas E Aparelhos Para A Indústria Do Plástico",
  C33_14__7_99: "Manutenção E Reparação De Outras Máquinas E Equipamentos Para Usos Industriais Não Especificados Anteriormente",
  C33_15__5: "Manutenção E Reparação De Veículos Ferroviários",
  C33_16__3: "Manutenção E Reparação De Aeronaves",
  C33_16__3_01: "Manutenção E Reparação De Aeronaves, Exceto A Manutenção Na Pista",
  C33_16__3_02: "Manutenção De Aeronaves Na Pista",
  C33_17__1: "Manutenção E Reparação De Embarcações",
  C33_17__1_02: "Manutenção E Reparação De Embarcações Para Esporte E Lazer",
  C33_17__1_01: "Manutenção E Reparação De Embarcações E Estruturas Flutuantes",
  C33_19__8: "Manutenção E Reparação De Equipamentos E Produtos Não Especificados Anteriormente",
  C33_2: "Instalação De Máquinas E Equipamentos",
  C33_21__0: "Instalação De Máquinas E Equipamentos Industriais",
  C33_29__5: "Instalação De Equipamentos Não Especificados Anteriormente",
  C33_29__5_01: "Serviços De Montagem De Móveis De Qualquer Material",
  C33_29__5_99: "Instalação De Outros Equipamentos Não Especificados Anteriormente",
  D: "Eletricidade E Gás",
  D35: "Eletricidade, Gás E Outras Utilidades",
  D35_1: "Geração, Transmissão E Distribuição De Energia Elétrica",
  D35_11__5: "Geração De Energia Elétrica",
  D35_11__5_02: "Atividades De Coordenação E Controle Da Operação Da Geração E Transmissão De Energia Elétrica",
  D35_12__3: "Transmissão De Energia Elétrica",
  D35_13__1: "Comércio Atacadista De Energia Elétrica",
  D35_14__0: "Distribuição De Energia Elétrica",
  D35_2: "Produção E Distribuição De Combustíveis Gasosos Por Redes Urbanas",
  D35_20__4: "Produção De Gás; Processamento De Gás Natural; Distribuição De Combustíveis Gasosos Por Redes Urbanas",
  D35_20__4_01: "Produção De Gás; Processamento De Gás Natural",
  D35_20__4_02: "Distribuição De Combustíveis Gasosos Por Redes Urbanas",
  D35_3: "Produção E Distribuição De Vapor, Água Quente E Ar Condicionado",
  E: "Água, Esgoto, Atividades De Gestão De Resíduos E Descontaminação",
  E36: "Captação, Tratamento E Distribuição De Água",
  E36_00__6_02: "Distribuição De Água Por Caminhões",
  E37: "Esgoto E Atividades Relacionadas",
  E37_01__1: "Gestão De Redes De Esgoto",
  E37_02__9: "Atividades Relacionadas A Esgoto, Exceto A Gestão De Redes",
  E38: "Coleta, Tratamento E Disposição De Resíduos; Recuperação De Materiais",
  E38_1: "Coleta De Resíduos",
  E38_11__4: "Coleta De Resíduos Não Perigosos",
  E38_12__2: "Coleta De Resíduos Perigosos",
  E38_2: "Tratamento E Disposição De Resíduos",
  E38_21__1: "Tratamento E Disposição De Resíduos Não Perigosos",
  E38_22__0: "Tratamento E Disposição De Resíduos Perigosos",
  E38_3: "Recuperação De Materiais",
  E38_31__9: "Recuperação De Materiais Metálicos",
  E38_31__9_01: "Recuperação De Sucatas De Alumínio",
  E38_31__9_99: "Recuperação De Materiais Metálicos, Exceto Alumínio",
  E38_32__7: "Recuperação De Materiais Plásticos",
  E38_39__4: "Recuperação De Materiais Não Especificados Anteriormente",
  E38_39__4_01: "Usinas De Compostagem",
  E39: "Descontaminação E Outros Serviços De Gestão De Resíduos",
  F: "Construção",
  F41: "Construção De Edifícios",
  F41_1: "Incorporação De Empreendimentos Imobiliários",
  F42: "Obras De Infra-Estrutura",
  F42_1: "Construção De Rodovias, Ferrovias, Obras Urbanas E Obras-De-Arte Especiais",
  F42_11__1: "Construção De Rodovias E Ferrovias",
  F42_11__1_02: "Pintura Para Sinalização Em Pistas Rodoviárias E Aeroportos",
  F42_12__0: "Construção De Obras De Arte Especiais",
  F42_13__8: "Obras De Urbanização - Ruas, Praças E Calçadas",
  F42_2: "Obras De Infra-Estrutura Para Energia Elétrica, Telecomunicações, Água, Esgoto E Transporte Por Dutos",
  F42_23__5: "Construção De Redes De Transportes Por Dutos, Exceto Para Água E Esgoto",
  F42_21__9: "Obras Para Geração E Distribuição De Energia Elétrica E Para Telecomunicações",
  F42_21__9_05: "Manutenção De Estações E Redes De Telecomunicações",
  F42_21__9_01: "Construção De Barragens E Represas Para Geração De Energia Elétrica",
  F42_21__9_02: "Construção De Estações E Redes De Distribuição De Energia Elétrica",
  F42_21__9_03: "Manutenção De Redes De Distribuição De Energia Elétrica",
  F42_21__9_04: "Construção De Estações E Redes De Telecomunicações",
  F42_22__7: "Construção De Redes De Abastecimento De Água, Coleta De Esgoto E Construções Correlatas",
  F42_22__7_01: "Construção De Redes De Abastecimento De Água, Coleta De Esgoto E Construções Correlatas, Exceto Obras De Irrigação",
  F42_22__7_02: "Obras De Irrigação",
  F42_9: "Construção De Outras Obras De Infra-Estrutura",
  F42_91__0: "Obras Portuárias, Marítimas E Fluviais",
  F42_92__8: "Montagem De Instalações Industriais E De Estruturas Metálicas",
  F42_92__8_01: "Montagem De Estruturas Metálicas",
  F42_92__8_02: "Obras De Montagem Industrial",
  F42_99__5: "Obras De Engenharia Civil Não Especificadas Anteriormente",
  F42_99__5_01: "Construção De Instalações Esportivas E Recreativas",
  F42_99__5_99: "Outras Obras De Engenharia Civil Não Especificadas Anteriormente",
  F43: "Serviços Especializados Para Construção",
  F43_1: "Demolição E Preparação Do Terreno",
  F43_11__8: "Demolição E Preparação De Canteiros De Obras",
  F43_11__8_01: "Demolição De Edifícios E Outras Estruturas",
  F43_11__8_02: "Preparação De Canteiro E Limpeza De Terreno",
  F43_12__6: "Perfurações E Sondagens",
  F43_13__4: "Obras De Terraplenagem",
  F43_19__3: "Serviços De Preparação Do Terreno Não Especificados Anteriormente",
  F43_2: "Instalações Elétricas, Hidráulicas E Outras Instalações Em Construções",
  F43_21__5: "Instalações Elétricas",
  F43_21__5_00: "Instalação E Manutenção Elétrica",
  F43_22__3: "Instalações Hidráulicas, De Sistemas De Ventilação E Refrigeração",
  F43_22__3_01: "Instalações Hidráulicas, Sanitárias E De Gás",
  F43_22__3_02: "Instalação E Manutenção De Sistemas Centrais De Ar Condicionado, De Ventilação E Refrigeração",
  F43_22__3_03: "Instalações De Sistema De Prevenção Contra Incêndio",
  F43_29__1: "Obras De Instalações Em Construções Não Especificadas Anteriormente",
  F43_29__1_01: "Instalação De Painéis Publicitários",
  F43_29__1_02: "Instalação De Equipamentos Para Orientação À Navegação Marítima Fluvial E Lacustre",
  F43_29__1_03: "Instalação, Manutenção E Reparação De Elevadores, Escadas E Esteiras Rolantes",
  F43_29__1_04: "Montagem E Instalação De Sistemas E Equipamentos De Iluminação E Sinalização Em Vias Públicas, Portos E Aeroportos",
  F43_29__1_05: "Tratamentos Térmicos, Acústicos Ou De Vibração",
  F43_29__1_99: "Outras Obras De Instalações Em Construções Não Especificadas Anteriormente",
  F43_3: "Obras De Acabamento",
  F43_30__4_03: "Obras De Acabamento Em Gesso E Estuque",
  F43_30__4_04: "Serviços De Pintura De Edifícios Em Geral",
  F43_30__4_05: "Aplicação De Revestimentos E De Resinas Em Interiores E Exteriores",
  F43_30__4_01: "Impermeabilização Em Obras De Engenharia Civil",
  F43_30__4_02: "Instalação De Portas, Janelas, Tetos, Divisórias E Armários Embutidos De Qualquer Material",
  F43_30__4_99: "Outras Obras De Acabamento Da Construção",
  F43_9: "Outros Serviços Especializados Para Construção",
  F43_91__6: "Obras De Fundações",
  F43_99__1: "Serviços Especializados Para Construção Não Especificados Anteriormente",
  F43_99__1_01: "Administração De Obras",
  F43_99__1_02: "Montagem E Desmontagem De Andaimes E Outras Estruturas Temporárias",
  F43_99__1_03: "Obras De Alvenaria",
  F43_99__1_04: "Serviços De Operação E Fornecimento De Equipamentos Para Transporte E Elevação De Cargas E Pessoas Para Uso Em Obras",
  F43_99__1_05: "Perfuração E Construção De Poços De Água",
  G: "Comércio; Reparação De Veículos Automotores E Motocicletas",
  G45: "Comércio E Reparação De Veículos Automotores E Motocicletas",
  G45_1: "Comércio De Veículos Automotores",
  G45_11__1: "Comércio A Varejo E Por Atacado De Veículos Automotores",
  G45_11__1_01: "Comércio A Varejo De Automóveis, Camionetas E Utilitários Novos",
  G45_11__1_02: "Comércio A Varejo De Automóveis, Camionetas E Utilitários Usados",
  G45_11__1_03: "Comércio Por Atacado De Automóveis, Camionetas E Utilitários Novos E Usados",
  G45_11__1_04: "Comércio Por Atacado De Caminhões Novos E Usados",
  G45_11__1_05: "Comércio Por Atacado De Reboques E Semi Reboques Novos E Usados",
  G45_11__1_06: "Comércio Por Atacado De Ônibus E Microônibus Novos E Usados",
  G45_12__9: "Representantes Comerciais E Agentes Do Comércio De Veículos Automotores",
  G45_12__9_02: "Comércio Sob Consignação De Veículos Automotores",
  G45_2: "Manutenção E Reparação De Veículos Automotores",
  G45_20__0_06: "Serviços De Borracharia Para Veículos Automotores",
  G45_20__0_01: "Serviços De Manutenção E Reparação Mecânica De Veículos Automotores",
  G45_20__0_02: "Serviços De Lanternagem Ou Funilaria E Pintura De Veículos Automotores",
  G45_20__0_03: "Serviços De Manutenção E Reparação Elétrica De Veículos Automotores",
  G45_20__0_04: "Serviços De Alinhamento E Balanceamento De Veículos Automotores",
  G45_20__0_05: "Serviços De Lavagem, Lubrificação E Polimento De Veículos Automotores",
  G45_20__0_07: "Serviços De Instalação, Manutenção E Reparação De Acessórios Para Veículos Automotores",
  G45_20__0_08: "Serviços De Capotaria",
  G45_3: "Comércio De Peças E Acessórios Para Veículos Automotores",
  G45_30__7_01: "Comércio Por Atacado De Peças E Acessórios Novos Para Veículos Automotores",
  G45_30__7_02: "Comércio Por Atacado De Pneumáticos E Câmaras De Ar",
  G45_30__7_03: "Comércio A Varejo De Peças E Acessórios Novos Para Veículos Automotores",
  G45_30__7_04: "Comércio A Varejo De Peças E Acessórios Usados Para Veículos Automotores",
  G45_30__7_05: "Comércio A Varejo De Pneumáticos E Câmaras De Ar",
  G45_30__7_06: "Representantes Comerciais E Agentes Do Comércio De Peças E Acessórios Novos E Usados Para Veículos Automotores",
  G45_4: "Comércio, Manutenção E Reparação De Motocicletas, Peças E Acessórios",
  G45_41__2: "Comércio Por Atacado E A Varejo De Motocicletas, Peças E Acessórios",
  G45_41__2_01: "Comércio Por Atacado De Motocicletas E Motonetas",
  G45_41__2_02: "Comércio Por Atacado De Peças E Acessórios Para Motocicletas E Motonetas",
  G45_41__2_03: "Comércio A Varejo De Motocicletas E Motonetas Novas",
  G45_41__2_04: "Comércio A Varejo De Motocicletas E Motonetas Usadas",
  G45_41__2_06: "Comércio A Varejo De Peças E Acessórios Novos Para Motocicletas E Motonetas",
  G45_41__2_07: "Comércio A Varejo De Peças E Acessórios Usados Para Motocicletas E Motonetas",
  G45_42__1: "Representantes Comerciais E Agentes Do Comércio De Motocicletas, Peças E Acessórios",
  G45_42__1_01: "Representantes Comerciais E Agentes Do Comércio De Motocicletas E Motonetas, Peças E Acessórios",
  G45_42__1_02: "Comércio Sob Consignação De Motocicletas E Motonetas",
  G45_43__9: "Manutenção E Reparação De Motocicletas",
  G45_43__9_00: "Manutenção E Reparação De Motocicletas E Motonetas",
  G46: "Comércio Por Atacado, Exceto Veículos Automotores E Motocicletas",
  G46_1: "Representantes Comerciais E Agentes Do Comércio, Exceto De Veículos Automotores E Motocicletas",
  G46_11__7: "Representantes Comerciais E Agentes Do Comércio De Matérias-Primas Agrícolas E Animais Vivos",
  G46_11__7_00: "Representantes Comerciais E Agentes Do Comércio De Matérias Primas Agrícolas E Animais Vivos",
  G46_12__5: "Representantes Comerciais E Agentes Do Comércio De Combustíveis, Minerais, Produtos Siderúrgicos E Químicos",
  G46_13__3: "Representantes Comerciais E Agentes Do Comércio De Madeira, Material De Construção E Ferragens",
  G46_14__1: "Representantes Comerciais E Agentes Do Comércio De Máquinas, Equipamentos, Embarcações E Aeronaves",
  G46_15__0: "Representantes Comerciais E Agentes Do Comércio De Eletrodomésticos, Móveis E Artigos De Uso Doméstico",
  G46_16__8: "Representantes Comerciais E Agentes Do Comércio De Têxteis, Vestuário, Calçados E Artigos De Viagem",
  G46_17__6: "Representantes Comerciais E Agentes Do Comércio De Produtos Alimentícios, Bebidas E Fumo",
  G46_18__4: "Representantes Comerciais E Agentes Do Comércio Especializado Em Produtos Não Especificados Anteriormente",
  G46_18__4_01: "Representantes Comerciais E Agentes Do Comércio De Medicamentos, Cosméticos E Produtos De Perfumaria",
  G46_18__4_02: "Representantes Comerciais E Agentes Do Comércio De Instrumentos E Materiais Odonto Médico Hospitalares",
  G46_18__4_03: "Representantes Comerciais E Agentes Do Comércio De Jornais, Revistas E Outras Publicações",
  G46_18__4_99: "Outros Representantes Comerciais E Agentes Do Comércio Especializado Em Produtos Não Especificados Anteriormente",
  G46_19__2: "Representantes Comerciais E Agentes Do Comércio De Mercadorias Em Geral Não Especializado",
  G46_2: "Comércio Atacadista De Matérias-Primas Agrícolas E Animais Vivos",
  G46_21__4: "Comércio Atacadista De Café Em Grão",
  G46_22__2: "Comércio Atacadista De Soja",
  G46_23__1: "Comércio Atacadista De Animais Vivos, Alimentos Para Animais E Matérias-Primas Agrícolas, Exceto Café E Soja",
  G46_23__1_05: "Comércio Atacadista De Cacau",
  G46_23__1_06: "Comércio Atacadista De Sementes, Flores, Plantas E Gramas",
  G46_23__1_07: "Comércio Atacadista De Sisal",
  G46_23__1_02: "Comércio Atacadista De Couros, Lãs, Peles E Outros Subprodutos Não Comestíveis De Origem Animal",
  G46_23__1_03: "Comércio Atacadista De Algodão",
  G46_23__1_01: "Comércio Atacadista De Animais Vivos",
  G46_23__1_04: "Comércio Atacadista De Fumo Em Folha Não Beneficiado",
  G46_23__1_08: "Comércio Atacadista De Matérias Primas Agrícolas Com Atividade De Fracionamento E Acondicionamento Associada",
  G46_23__1_09: "Comércio Atacadista De Alimentos Para Animais",
  G46_23__1_99: "Comércio Atacadista De Matérias Primas Agrícolas Não Especificadas Anteriormente",
  G46_3: "Comércio Atacadista Especializado Em Produtos Alimentícios, Bebidas E Fumo",
  G46_31__1: "Comércio Atacadista De Leite E Laticínios",
  G46_32__0: "Comércio Atacadista De Cereais E Leguminosas Beneficiados, Farinhas, Amidos E Féculas",
  G46_32__0_01: "Comércio Atacadista De Cereais E Leguminosas Beneficiados",
  G46_32__0_02: "Comércio Atacadista De Farinhas, Amidos E Féculas",
  G46_32__0_03: "Comércio Atacadista De Cereais E Leguminosas Beneficiados, Farinhas, Amidos E Féculas, Com Atividade De Fracionamento E Acondicionamento Associada",
  G46_33__8: "Comércio Atacadista De Hortifrutigranjeiros",
  G46_33__8_03: "Comércio Atacadista De Coelhos E Outros Pequenos Animais Vivos Para Alimentação",
  G46_33__8_01: "Comércio Atacadista De Frutas, Verduras, Raízes, Tubérculos, Hortaliças E Legumes Frescos",
  G46_33__8_02: "Comércio Atacadista De Aves Vivas E Ovos",
  G46_34__6: "Comércio Atacadista De Carnes, Produtos Da Carne E Pescado",
  G46_34__6_01: "Comércio Atacadista De Carnes Bovinas E Suínas E Derivados",
  G46_34__6_02: "Comércio Atacadista De Aves Abatidas E Derivados",
  G46_34__6_03: "Comércio Atacadista De Pescados E Frutos Do Mar",
  G46_34__6_99: "Comércio Atacadista De Carnes E Derivados De Outros Animais",
  G46_35__4: "Comércio Atacadista De Bebidas",
  G46_35__4_01: "Comércio Atacadista De Água Mineral",
  G46_35__4_02: "Comércio Atacadista De Cerveja, Chope E Refrigerante",
  G46_35__4_03: "Comércio Atacadista De Bebidas Com Atividade De Fracionamento E Acondicionamento Associada",
  G46_35__4_99: "Comércio Atacadista De Bebidas Não Especificadas Anteriormente",
  G46_36__2: "Comércio Atacadista De Produtos Do Fumo",
  G46_36__2_01: "Comércio Atacadista De Fumo Beneficiado",
  G46_36__2_02: "Comércio Atacadista De Cigarros, Cigarrilhas E Charutos",
  G46_37__1: "Comércio Atacadista Especializado Em Produtos Alimentícios Não Especificados Anteriormente",
  G46_37__1_01: "Comércio Atacadista De Café Torrado, Moído E Solúvel",
  G46_37__1_02: "Comércio Atacadista De Açúcar",
  G46_37__1_03: "Comércio Atacadista De Óleos E Gorduras",
  G46_37__1_04: "Comércio Atacadista De Pães, Bolos, Biscoitos E Similares",
  G46_37__1_05: "Comércio Atacadista De Massas Alimentícias",
  G46_37__1_06: "Comércio Atacadista De Sorvetes",
  G46_37__1_07: "Comércio Atacadista De Chocolates, Confeitos, Balas, Bombons E Semelhantes",
  G46_37__1_99: "Comércio Atacadista Especializado Em Outros Produtos Alimentícios Não Especificados Anteriormente",
  G46_39__7: "Comércio Atacadista De Produtos Alimentícios Em Geral",
  G46_39__7_02: "Comércio Atacadista De Produtos Alimentícios Em Geral, Com Atividade De Fracionamento E Acondicionamento Associada",
  G46_4: "Comércio Atacadista De Produtos De Consumo Não-Alimentar",
  G46_46__0: "Comércio Atacadista De Cosméticos, Produtos De Perfumaria E De Higiene Pessoal",
  G46_46__0_01: "Comércio Atacadista De Cosméticos E Produtos De Perfumaria",
  G46_46__0_02: "Comércio Atacadista De Produtos De Higiene Pessoal",
  G46_45__1: "Comércio Atacadista De Instrumentos E Materiais Para Uso Médico, Cirúrgico, Ortopédico E Odontológico",
  G46_45__1_01: "Comércio Atacadista De Instrumentos E Materiais Para Uso Médico, Cirúrgico, Hospitalar E De Laboratórios",
  G46_45__1_02: "Comércio Atacadista De Próteses E Artigos De Ortopedia",
  G46_45__1_03: "Comércio Atacadista De Produtos Odontológicos",
  G46_41__9: "Comércio Atacadista De Tecidos, Artefatos De Tecidos E De Armarinho",
  G46_41__9_02: "Comércio Atacadista De Artigos De Cama, Mesa E Banho",
  G46_41__9_03: "Comércio Atacadista De Artigos De Armarinho",
  G46_41__9_01: "Comércio Atacadista De Tecidos",
  G46_42__7: "Comércio Atacadista De Artigos Do Vestuário E Acessórios",
  G46_42__7_01: "Comércio Atacadista De Artigos Do Vestuário E Acessórios, Exceto Profissionais E De Segurança",
  G46_42__7_02: "Comércio Atacadista De Roupas E Acessórios Para Uso Profissional E De Segurança Do Trabalho",
  G46_43__5: "Comércio Atacadista De Calçados E Artigos De Viagem",
  G46_43__5_01: "Comércio Atacadista De Calçados",
  G46_43__5_02: "Comércio Atacadista De Bolsas, Malas E Artigos De Viagem",
  G46_44__3: "Comércio Atacadista De Produtos Farmacêuticos Para Uso Humano E Veterinário",
  G46_44__3_01: "Comércio Atacadista De Medicamentos E Drogas De Uso Humano",
  G46_44__3_02: "Comércio Atacadista De Medicamentos E Drogas De Uso Veterinário",
  G46_47__8: "Comércio Atacadista De Artigos De Escritório E De Papelaria; Livros, Jornais E Outras Publicações",
  G46_47__8_01: "Comércio Atacadista De Artigos De Escritório E De Papelaria",
  G46_47__8_02: "Comércio Atacadista De Livros, Jornais E Outras Publicações",
  G46_49__4: "Comércio Atacadista De Equipamentos E Artigos De Uso Pessoal E Doméstico Não Especificados Anteriormente",
  G46_49__4_01: "Comércio Atacadista De Equipamentos Elétricos De Uso Pessoal E Doméstico",
  G46_49__4_02: "Comércio Atacadista De Aparelhos Eletrônicos De Uso Pessoal E Doméstico",
  G46_49__4_03: "Comércio Atacadista De Bicicletas, Triciclos E Outros Veículos Recreativos",
  G46_49__4_04: "Comércio Atacadista De Móveis E Artigos De Colchoaria",
  G46_49__4_05: "Comércio Atacadista De Artigos De Tapeçaria; Persianas E Cortinas",
  G46_49__4_06: "Comércio Atacadista De Lustres, Luminárias E Abajures",
  G46_49__4_07: "Comércio Atacadista De Filmes, Cds, Dvds, Fitas E Discos",
  G46_49__4_08: "Comércio Atacadista De Produtos De Higiene, Limpeza E Conservação Domiciliar",
  G46_49__4_09: "Comércio Atacadista De Produtos De Higiene, Limpeza E Conservação Domiciliar, Com Atividade De Fracionamento E Acondicionamento Associada",
  G46_49__4_10: "Comércio Atacadista De Jóias, Relógios E Bijuterias, Inclusive Pedras Preciosas E Semipreciosas Lapidadas",
  G46_49__4_99: "Comércio Atacadista De Outros Equipamentos E Artigos De Uso Pessoal E Doméstico Não Especificados Anteriormente",
  G46_5: "Comércio Atacadista De Equipamentos E Produtos De Tecnologias De Informação E Comunicação",
  G46_51__6: "Comércio Atacadista De Computadores, Periféricos E Suprimentos De Informática",
  G46_51__6_01: "Comércio Atacadista De Equipamentos De Informática",
  G46_51__6_02: "Comércio Atacadista De Suprimentos Para Informática",
  G46_52__4: "Comércio Atacadista De Componentes Eletrônicos E Equipamentos De Telefonia E Comunicação",
  G46_6: "Comércio Atacadista De Máquinas, Aparelhos E Equipamentos, Exceto De Tecnologias De Informação E Comunicação",
  G46_61__3: "Comércio Atacadista De Máquinas, Aparelhos E Equipamentos Para Uso Agropecuário; Partes E Peças",
  G46_62__1: "Comércio Atacadista De Máquinas, Equipamentos Para Terraplenagem, Mineração E Construção; Partes E Peças",
  G46_63__0: "Comércio Atacadista De Máquinas E Equipamentos Para Uso Industrial; Partes E Peças",
  G46_64__8: "Comércio Atacadista De Máquinas, Aparelhos E Equipamentos Para Uso Odonto-Médico-Hospitalar; Partes E Peças",
  G46_64__8_00: "Comércio Atacadista De Máquinas, Aparelhos E Equipamentos Para Uso Odonto Médico Hospitalar; Partes E Peças",
  G46_65__6: "Comércio Atacadista De Máquinas E Equipamentos Para Uso Comercial; Partes E Peças",
  G46_69__9: "Comércio Atacadista De Máquinas, Aparelhos E Equipamentos Não Especificados Anteriormente; Partes E Peças",
  G46_69__9_01: "Comércio Atacadista De Bombas E Compressores; Partes E Peças",
  G46_69__9_99: "Comércio Atacadista De Outras Máquinas E Equipamentos Não Especificados Anteriormente; Partes E Peças",
  G46_7: "Comércio Atacadista De Madeira, Ferragens, Ferramentas, Material Elétrico E Material De Construção",
  G46_71__1: "Comércio Atacadista De Madeira E Produtos Derivados",
  G46_72__9: "Comércio Atacadista De Ferragens E Ferramentas",
  G46_73__7: "Comércio Atacadista De Material Elétrico",
  G46_74__5: "Comércio Atacadista De Cimento",
  G46_79__6: "Comércio Atacadista Especializado De Materiais De Construção Não Especificados Anteriormente E De Materiais De Construção Em Geral",
  G46_79__6_99: "Comércio Atacadista De Materiais De Construção Em Geral",
  G46_79__6_01: "Comércio Atacadista De Tintas, Vernizes E Similares",
  G46_79__6_02: "Comércio Atacadista De Mármores E Granitos",
  G46_79__6_03: "Comércio Atacadista De Vidros, Espelhos E Vitrais",
  G46_79__6_04: "Comércio Atacadista Especializado De Materiais De Construção Não Especificados Anteriormente",
  G46_8: "Comércio Atacadista Especializado Em Outros Produtos",
  G46_81__8: "Comércio Atacadista De Combustíveis Sólidos, Líquidos E Gasosos, Exceto Gás Natural E Glp",
  G46_81__8_01: "Comércio Atacadista De Álcool Carburante, Biodiesel, Gasolina E Demais Derivados De Petróleo, Exceto Lubrificantes, Não Realizado Por Transportador Retalhista (T.R.R.)",
  G46_81__8_02: "Comércio Atacadista De Combustíveis Realizado Por Transportador Retalhista (T.R.R.)",
  G46_81__8_03: "Comércio Atacadista De Combustíveis De Origem Vegetal, Exceto Álcool Carburante",
  G46_81__8_04: "Comércio Atacadista De Combustíveis De Origem Mineral Em Bruto",
  G46_81__8_05: "Comércio Atacadista De Lubrificantes",
  G46_82__6: "Comércio Atacadista De Gás Liquefeito De Petróleo (Glp)",
  G46_83__4: "Comércio Atacadista De Defensivos Agrícolas, Adubos, Fertilizantes E Corretivos Do Solo",
  G46_84__2: "Comércio Atacadista De Produtos Químicos E Petroquímicos, Exceto Agroquímicos",
  G46_84__2_01: "Comércio Atacadista De Resinas E Elastômeros",
  G46_84__2_02: "Comércio Atacadista De Solventes",
  G46_84__2_99: "Comércio Atacadista De Outros Produtos Químicos E Petroquímicos Não Especificados Anteriormente",
  G46_85__1: "Comércio Atacadista De Produtos Siderúrgicos E Metalúrgicos, Exceto Para Construção",
  G46_86__9: "Comércio Atacadista De Papel E Papelão Em Bruto E De Embalagens",
  G46_86__9_01: "Comércio Atacadista De Papel E Papelão Em Bruto",
  G46_86__9_02: "Comércio Atacadista De Embalagens",
  G46_87__7: "Comércio Atacadista De Resíduos E Sucatas",
  G46_87__7_01: "Comércio Atacadista De Resíduos De Papel E Papelão",
  G46_87__7_02: "Comércio Atacadista De Resíduos E Sucatas Não Metálicos, Exceto De Papel E Papelão",
  G46_87__7_03: "Comércio Atacadista De Resíduos E Sucatas Metálicos",
  G46_89__3: "Comércio Atacadista Especializado De Outros Produtos Intermediários Não Especificados Anteriormente",
  G46_89__3_01: "Comércio Atacadista De Produtos Da Extração Mineral, Exceto Combustíveis",
  G46_89__3_02: "Comércio Atacadista De Fios E Fibras Beneficiados",
  G46_89__3_99: "Comércio Atacadista Especializado Em Outros Produtos Intermediários Não Especificados Anteriormente",
  G46_9: "Comércio Atacadista Não-Especializado",
  G46_91__5: "Comércio Atacadista De Mercadorias Em Geral, Com Predominância De Produtos Alimentícios",
  G46_92__3: "Comércio Atacadista De Mercadorias Em Geral, Com Predominância De Insumos Agropecuários",
  G46_93__1: "Comércio Atacadista De Mercadorias Em Geral, Sem Predominância De Alimentos Ou De Insumos Agropecuários",
  G47: "Comércio Varejista",
  G47_1: "Comércio Varejista Não-Especializado",
  G47_11__3: "Comércio Varejista De Mercadorias Em Geral, Com Predominância De Produtos Alimentícios - Hipermercados E Supermercados",
  G47_11__3_01: "Comércio Varejista De Mercadorias Em Geral, Com Predominância De Produtos Alimentícios   Hipermercados",
  G47_11__3_02: "Comércio Varejista De Mercadorias Em Geral, Com Predominância De Produtos Alimentícios - Supermercados",
  G47_12__1: "Comércio Varejista De Mercadorias Em Geral, Com Predominância De Produtos Alimentícios - Minimercados, Mercearias E Armazéns",
  G47_13__0: "Comércio Varejista De Mercadorias Em Geral, Sem Predominância De Produtos Alimentícios",
  G47_13__0_02: "Lojas De Variedades, Exceto Lojas De Departamentos Ou Magazines",
  G47_13__0_05: "Lojas Francas (Duty Free) De Aeroportos, Portos E Em Fronteiras Terrestres",
  G47_13__0_04: "Lojas De Departamentos Ou Magazines, Exceto Lojas Francas (Duty Free)",
  G47_2: "Comércio Varejista De Produtos Alimentícios, Bebidas E Fumo",
  G47_22__9: "Comércio Varejista De Carnes E Pescados - Açougues E Peixarias",
  G47_22__9_01: "Comércio Varejista De Carnes - Açougues",
  G47_22__9_02: "Peixaria",
  G47_23__7: "Comércio Varejista De Bebidas",
  G47_24__5: "Comércio Varejista De Hortifrutigranjeiros",
  G47_21__1: "Comércio Varejista De Produtos De Padaria, Laticínio, Doces, Balas E Semelhantes",
  G47_21__1_03: "Comércio Varejista De Laticínios E Frios",
  G47_21__1_02: "Padaria E Confeitaria Com Predominância De Revenda",
  G47_21__1_04: "Comércio Varejista De Doces, Balas, Bombons E Semelhantes",
  G47_29__6: "Comércio Varejista De Produtos Alimentícios Em Geral Ou Especializado Em Produtos Alimentícios Não Especificados Anteriormente; Produtos Do Fumo",
  G47_29__6_01: "Tabacaria",
  G47_29__6_02: "Comércio Varejista De Mercadorias Em Lojas De Conveniência",
  G47_29__6_99: "Comércio Varejista De Produtos Alimentícios Em Geral Ou Especializado Em Produtos Alimentícios Não Especificados Anteriormente",
  G47_3: "Comércio Varejista De Combustíveis Para Veículos Automotores",
  G47_32__6: "Comércio Varejista De Lubrificantes",
  G47_4: "Comércio Varejista De Material De Construção",
  G47_41__5: "Comércio Varejista De Tintas E Materiais Para Pintura",
  G47_42__3: "Comércio Varejista De Material Elétrico",
  G47_43__1: "Comércio Varejista De Vidros",
  G47_44__0: "Comércio Varejista De Ferragens, Madeira E Materiais De Construção",
  G47_44__0_01: "Comércio Varejista De Ferragens E Ferramentas",
  G47_44__0_02: "Comércio Varejista De Madeira E Artefatos",
  G47_44__0_03: "Comércio Varejista De Materiais Hidráulicos",
  G47_44__0_04: "Comércio Varejista De Cal, Areia, Pedra Britada, Tijolos E Telhas",
  G47_44__0_05: "Comércio Varejista De Materiais De Construção Não Especificados Anteriormente",
  G47_44__0_06: "Comércio Varejista De Pedras Para Revestimento",
  G47_44__0_99: "Comércio Varejista De Materiais De Construção Em Geral",
  G47_5: "Comércio Varejista De Equipamentos De Informática E Comunicação; Equipamentos E Artigos De Uso Doméstico",
  G47_57__1: "Comércio Varejista Especializado De Peças E Acessórios Para Aparelhos Eletroeletrônicos Para Uso Doméstico, Exceto Informática E Comunicação",
  G47_55__5: "Comércio Varejista Especializado De Tecidos E Artigos De Cama, Mesa E Banho",
  G47_55__5_01: "Comércio Varejista De Tecidos",
  G47_55__5_02: "Comercio Varejista De Artigos De Armarinho",
  G47_55__5_03: "Comercio Varejista De Artigos De Cama, Mesa E Banho",
  G47_56__3: "Comércio Varejista Especializado De Instrumentos Musicais E Acessórios",
  G47_51__2: "Comércio Varejista Especializado De Equipamentos E Suprimentos De Informática",
  G47_51__2_02: "Recarga De Cartuchos Para Equipamentos De Informática",
  G47_52__1: "Comércio Varejista Especializado De Equipamentos De Telefonia E Comunicação",
  G47_53__9: "Comércio Varejista Especializado De Eletrodomésticos E Equipamentos De Áudio E Vídeo",
  G47_54__7: "Comércio Varejista Especializado De Móveis, Colchoaria E Artigos De Iluminação",
  G47_54__7_01: "Comércio Varejista De Móveis",
  G47_54__7_02: "Comércio Varejista De Artigos De Colchoaria",
  G47_54__7_03: "Comércio Varejista De Artigos De Iluminação",
  G47_59__8: "Comércio Varejista De Artigos De Uso Doméstico Não Especificados Anteriormente",
  G47_59__8_01: "Comércio Varejista De Artigos De Tapeçaria, Cortinas E Persianas",
  G47_59__8_99: "Comércio Varejista De Outros Artigos De Uso Pessoal E Doméstico Não Especificados Anteriormente",
  G47_6: "Comércio Varejista De Artigos Culturais, Recreativos E Esportivos",
  G47_61__0: "Comércio Varejista De Livros, Jornais, Revistas E Papelaria",
  G47_61__0_01: "Comércio Varejista De Livros",
  G47_61__0_02: "Comércio Varejista De Jornais E Revistas",
  G47_61__0_03: "Comércio Varejista De Artigos De Papelaria",
  G47_62__8: "Comércio Varejista De Discos, Cds, Dvds E Fitas",
  G47_63__6: "Comércio Varejista De Artigos Recreativos E Esportivos",
  G47_63__6_03: "Comércio Varejista De Bicicletas E Triciclos; Peças E Acessórios",
  G47_63__6_04: "Comércio Varejista De Artigos De Caça, Pesca E Camping",
  G47_63__6_01: "Comércio Varejista De Brinquedos E Artigos Recreativos",
  G47_63__6_02: "Comércio Varejista De Artigos Esportivos",
  G47_63__6_05: "Comércio Varejista De Embarcações E Outros Veículos Recreativos; Peças E Acessórios",
  G47_7: "Comércio Varejista De Produtos Farmacêuticos, Perfumaria E Cosméticos E Artigos Médicos, Ópticos E Ortopédicos",
  G47_71__7: "Comércio Varejista De Produtos Farmacêuticos Para Uso Humano E Veterinário",
  G47_71__7_01: "Comércio Varejista De Produtos Farmacêuticos, Sem Manipulação De Fórmulas",
  G47_71__7_02: "Comércio Varejista De Produtos Farmacêuticos, Com Manipulação De Fórmulas",
  G47_71__7_03: "Comércio Varejista De Produtos Farmacêuticos Homeopáticos",
  G47_71__7_04: "Comércio Varejista De Medicamentos Veterinários",
  G47_72__5: "Comércio Varejista De Cosméticos, Produtos De Perfumaria E De Higiene Pessoal",
  G47_73__3: "Comércio Varejista De Artigos Médicos E Ortopédicos",
  G47_74__1: "Comércio Varejista De Artigos De Óptica",
  G47_8: "Comércio Varejista De Produtos Novos Não Especificados Anteriormente E De Produtos Usados",
  G47_81__4: "Comércio Varejista De Artigos Do Vestuário E Acessórios",
  G47_82__2: "Comércio Varejista De Calçados E Artigos De Viagem",
  G47_82__2_01: "Comércio Varejista De Calçados",
  G47_82__2_02: "Comércio Varejista De Artigos De Viagem",
  G47_83__1: "Comércio Varejista De Joias E Relógios",
  G47_83__1_01: "Comércio Varejista De Artigos De Joalheria",
  G47_83__1_02: "Comércio Varejista De Artigos De Relojoaria",
  G47_84__9: "Comércio Varejista De Gás Liquefeito De Petróleo (Glp)",
  G47_85__7: "Comércio Varejista De Artigos Usados",
  G47_85__7_01: "Comércio Varejista De Antiguidades",
  G47_85__7_99: "Comércio Varejista De Outros Artigos Usados",
  G47_89__0: "Comércio Varejista De Outros Produtos Novos Não Especificados Anteriormente",
  G47_89__0_01: "Comércio Varejista De Suvenires, Bijuterias E Artesanatos",
  G47_89__0_02: "Comércio Varejista De Plantas E Flores Naturais",
  G47_89__0_03: "Comércio Varejista De Objetos De Arte",
  G47_89__0_04: "Comércio Varejista De Animais Vivos E De Artigos E Alimentos Para Animais De Estimação",
  G47_89__0_05: "Comércio Varejista De Produtos Saneantes Domissanitários",
  G47_89__0_06: "Comércio Varejista De Fogos De Artifício E Artigos Pirotécnicos",
  G47_89__0_07: "Comércio Varejista De Equipamentos Para Escritório",
  G47_89__0_08: "Comércio Varejista De Artigos Fotográficos E Para Filmagem",
  G47_89__0_09: "Comércio Varejista De Armas E Munições",
  G47_89__0_99: "Comércio Varejista De Outros Produtos Não Especificados Anteriormente",
  G47_9: "Comércio Ambulante E Outros Tipos De Comércio Varejista",
  H: "Transporte, Armazenagem E Correio",
  H52: "Armazenamento E Atividades Auxiliares Dos Transportes",
  H52_1: "Armazenamento, Carga E Descarga",
  H52_11__7: "Armazenamento",
  H52_11__7_01: "Armazéns Gerais - Emissão De Warrant",
  H52_11__7_02: "Guarda Móveis",
  H52_11__7_99: "Depósitos De Mercadorias Para Terceiros, Exceto Armazéns Gerais E Guarda Móveis",
  H52_12__5: "Carga E Descarga",
  H52_2: "Atividades Auxiliares Dos Transportes Terrestres",
  H52_21__4: "Concessionárias De Rodovias, Pontes, Túneis E Serviços Relacionados",
  H52_22__2: "Terminais Rodoviários E Ferroviários",
  H52_23__1: "Estacionamento De Veículos",
  H52_29__0: "Atividades Auxiliares Dos Transportes Terrestres Não Especificadas Anteriormente",
  H52_29__0_01: "Serviços De Apoio Ao Transporte Por Táxi, Inclusive Centrais De Chamada",
  H52_29__0_02: "Serviços De Reboque De Veículos",
  H52_29__0_99: "Outras Atividades Auxiliares Dos Transportes Terrestres Não Especificadas Anteriormente",
  H52_3: "Atividades Auxiliares Dos Transportes Aquaviários",
  H52_31__1: "Gestão De Portos E Terminais",
  H52_31__1_01: "Administração Da Infraestrutura Portuária",
  H52_31__1_02: "Atividades Do Operador Portuário",
  H52_31__1_03: "Gestão De Terminais Aquaviários",
  H52_32__0: "Atividades De Agenciamento Marítimo",
  H52_39__7: "Atividades Auxiliares Dos Transportes Aquaviários Não Especificadas Anteriormente",
  H52_39__7_01: "Serviços De Praticagem",
  H52_4: "Atividades Auxiliares Dos Transportes Aéreos",
  H52_40__1_01: "Operação Dos Aeroportos E Campos De Aterrissagem",
  H52_40__1_99: "Atividades Auxiliares Dos Transportes Aéreos, Exceto Operação Dos Aeroportos E Campos De Aterrissagem",
  H52_5: "Atividades Relacionadas À Organização Do Transporte De Carga",
  H52_50__8_01: "Comissaria De Despachos",
  H52_50__8_02: "Atividades De Despachantes Aduaneiros",
  H52_50__8_03: "Agenciamento De Cargas, Exceto Para O Transporte Marítimo",
  H52_50__8_04: "Organização Logística Do Transporte De Carga",
  H52_50__8_05: "Operador De Transporte Multimodal - Otm",
  H53: "Correio E Outras Atividades De Entrega",
  H53_1: "Atividades De Correio",
  H53_10__5_01: "Atividades Do Correio Nacional",
  H53_10__5_02: "Atividades De Franqueadas Do Correio Nacional",
  H53_2: "Atividades De Malote E De Entrega",
  H53_20__2_01: "Serviços De Malote Não Realizados Pelo Correio Nacional",
  H53_20__2_02: "Serviços De Entrega Rápida",
  H49: "Transporte Terrestre",
  H49_1: "Transporte Ferroviário E Metroferroviário",
  H49_11__6: "Transporte Ferroviário De Carga",
  H49_12__4: "Transporte Metroferroviário De Passageiros",
  H49_12__4_01: "Transporte Ferroviário De Passageiros Intermunicipal E Interestadual",
  H49_12__4_02: "Transporte Ferroviário De Passageiros Municipal E Em Região Metropolitana",
  H49_12__4_03: "Transporte Metroviário",
  H49_2: "Transporte Rodoviário De Passageiros",
  H49_21__3: "Transporte Rodoviário Coletivo De Passageiros, Com Itinerário Fixo, Municipal E Em Região Metropolitana",
  H49_21__3_01: "Transporte Rodoviário Coletivo De Passageiros, Com Itinerário Fixo, Municipal",
  H49_21__3_02: "Transporte Rodoviário Coletivo De Passageiros, Com Itinerário Fixo, Intermunicipal Em Região Metropolitana",
  H49_22__1: "Transporte Rodoviário Coletivo De Passageiros, Com Itinerário Fixo, Intermunicipal, Interestadual E Internacional",
  H49_22__1_01: "Transporte Rodoviário Coletivo De Passageiros, Com Itinerário Fixo, Intermunicipal, Exceto Em Região Metropolitana",
  H49_22__1_02: "Transporte Rodoviário Coletivo De Passageiros, Com Itinerário Fixo, Interestadual",
  H49_22__1_03: "Transporte Rodoviário Coletivo De Passageiros, Com Itinerário Fixo, Internacional",
  H49_23__0: "Transporte Rodoviário De Táxi",
  H49_23__0_01: "Serviço De Táxi",
  H49_23__0_02: "Serviço De Transporte De Passageiros - Locação De Automóveis Com Motorista",
  H49_24__8: "Transporte Escolar",
  H49_29__9: "Transporte Rodoviário Coletivo De Passageiros, Sob Regime De Fretamento, E Outros Transportes Rodoviários Não Especificados Anteriormente",
  H49_29__9_02: "Transporte Rodoviário Coletivo De Passageiros, Sob Regime De Fretamento, Intermunicipal, Interestadual E Internacional",
  H49_29__9_01: "Transporte Rodoviário Coletivo De Passageiros, Sob Regime De Fretamento, Municipal",
  H49_29__9_03: "Organização De Excursões Em Veículos Rodoviários Próprios, Municipal",
  H49_29__9_04: "Organização De Excursões Em Veículos Rodoviários Próprios, Intermunicipal, Interestadual E Internacional",
  H49_29__9_99: "Outros Transportes Rodoviários De Passageiros Não Especificados Anteriormente",
  H49_3: "Transporte Rodoviário De Carga",
  H49_30__2_01: "Transporte Rodoviário De Carga, Exceto Produtos Perigosos E Mudanças, Municipal",
  H49_30__2_02: "Transporte Rodoviário De Carga, Exceto Produtos Perigosos E Mudanças, Intermunicipal, Interestadual E Internacional",
  H49_30__2_03: "Transporte Rodoviário De Produtos Perigosos",
  H49_30__2_04: "Transporte Rodoviário De Mudanças",
  H49_4: "Transporte Dutoviário",
  H49_5: "Trens Turísticos, Teleféricos E Similares",
  H50: "Transporte Aquaviário",
  H50_1: "Transporte Marítimo De Cabotagem E Longo Curso",
  H50_11__4: "Transporte Marítimo De Cabotagem",
  H50_11__4_01: "Transporte Marítimo De Cabotagem - Carga",
  H50_11__4_02: "Transporte Marítimo De Cabotagem - Passageiros",
  H50_12__2: "Transporte Marítimo De Longo Curso",
  H50_12__2_01: "Transporte Marítimo De Longo Curso - Carga",
  H50_12__2_02: "Transporte Marítimo De Longo Curso - Passageiros",
  H50_2: "Transporte Por Navegação Interior",
  H50_22__0: "Transporte Por Navegação Interior De Passageiros Em Linhas Regulares",
  H50_22__0_01: "Transporte Por Navegação Interior De Passageiros Em Linhas Regulares, Municipal, Exceto Travessia",
  H50_22__0_02: "Transporte Por Navegação Interior De Passageiros Em Linhas Regulares, Intermunicipal, Interestadual E Internacional, Exceto Travessia",
  H50_21__1: "Transporte Por Navegação Interior De Carga",
  H50_21__1_01: "Transporte Por Navegação Interior De Carga, Municipal, Exceto Travessia",
  H50_21__1_02: "Transporte Por Navegação Interior De Carga, Intermunicipal, Interestadual E Internacional, Exceto Travessia",
  H50_3: "Navegação De Apoio",
  H50_30__1_01: "Navegação De Apoio Marítimo",
  H50_30__1_02: "Navegação De Apoio Portuário",
  H50_30__1_03: "Serviço De Rebocadores E Empurradores",
  H50_9: "Outros Transportes Aquaviários",
  H50_91__2: "Transporte Por Navegação De Travessia",
  H50_91__2_01: "Transporte Por Navegação De Travessia, Municipal",
  H50_91__2_02: "Transporte Por Navegação De Travessia Intermunicipal, Interestadual E Internacional",
  H50_99__8: "Transportes Aquaviários Não Especificados Anteriormente",
  H50_99__8_01: "Transporte Aquaviário Para Passeios Turísticos",
  H50_99__8_99: "Outros Transportes Aquaviários Não Especificados Anteriormente",
  H51: "Transporte Aéreo",
  H51_1: "Transporte Aéreo De Passageiros",
  H51_11__1: "Transporte Aéreo De Passageiros Regular",
  H51_12__9: "Transporte Aéreo De Passageiros Não Regular",
  H51_12__9_01: "Serviço De Táxi Aéreo E Locação De Aeronaves Com Tripulação",
  H51_12__9_99: "Outros Serviços De Transporte Aéreo De Passageiros Não Regular",
  H51_2: "Transporte Aéreo De Carga",
  H51_3: "Transporte Espacial",
  I: "Alojamento E Alimentação",
  I55: "Alojamento",
  I55_1: "Hotéis E Similares",
  I55_10__8_01: "Hotéis",
  I55_10__8_02: "Apart Hotéis",
  I55_10__8_03: "Motéis",
  I55_9: "Outros Tipos De Alojamento Não Especificados Anteriormente",
  I55_90__6_01: "Albergues, Exceto Assistenciais",
  I55_90__6_02: "Campings",
  I55_90__6_03: "Pensões(Alojamento)",
  I55_90__6_99: "Outros Alojamentos Não Especificados Anteriormente",
  I56: "Alimentação",
  I56_1: "Restaurantes E Outros Serviços De Alimentação E Bebidas",
  I56_11__2: "Restaurantes E Outros Estabelecimentos De Serviços De Alimentação E Bebidas",
  I56_11__2_04: "Bares E Outros Estabelecimentos Especializados Em Servir Bebidas, Sem Entretenimento",
  I56_11__2_01: "Restaurantes E Similares",
  I56_11__2_03: "Lanchonetes, Casas De Chá, De Sucos E Similares",
  I56_11__2_05: "Bares E Outros Estabelecimentos Especializados Em Servir Bebidas, Com Entretenimento",
  I56_12__1: "Serviços Ambulantes De Alimentação",
  I56_2: "Serviços De Catering, Bufê E Outros Serviços De Comida Preparada",
  I56_20__1_02: "Serviços De Alimentação Para Eventos E Recepções - Bufê",
  I56_20__1_01: "Fornecimento De Alimentos Preparados Preponderantemente Para Empresas",
  I56_20__1_03: "Cantinas - Serviços De Alimentação Privativos",
  I56_20__1_04: "Fornecimento De Alimentos Preparados Preponderantemente Para Consumo Domiciliar",
  J: "Informação E Comunicação",
  J60: "Atividades De Rádio E De Televisão",
  J60_1: "Atividades De Rádio",
  J60_2: "Atividades De Televisão",
  J60_21__7: "Atividades De Televisão Aberta",
  J60_22__5: "Programadoras E Atividades Relacionadas À Televisão Por Assinatura",
  J60_22__5_01: "Programadoras",
  J60_22__5_02: "Atividades Relacionadas À Televisão Por Assinatura, Exceto Programadoras",
  J58: "Edição E Edição Integrada À Impressão",
  J58_1: "Edição De Livros, Jornais, Revistas E Outras Atividades De Edição",
  J58_11__5: "Edição De Livros",
  J58_12__3: "Edição De Jornais",
  J58_12__3_01: "Edição De Jornais Diários",
  J58_12__3_02: "Edição De Jornais Não Diários",
  J58_13__1: "Edição De Revistas",
  J58_19__1: "Edição De Cadastros, Listas E De Outros Produtos Gráficos",
  J58_2: "Edição Integrada À Impressão De Livros, Jornais, Revistas E Outras Publicações",
  J58_21__2: "Edição Integrada À Impressão De Livros",
  J58_22__1: "Edição Integrada À Impressão De Jornais",
  J58_22__1_01: "Edição Integrada À Impressão De Jornais Diários",
  J58_22__1_02: "Edição Integrada À Impressão De Jornais Não Diários",
  J58_23__9: "Edição Integrada À Impressão De Revistas",
  J58_29__8: "Edição Integrada À Impressão De Cadastros, Listas E De Outros Produtos Gráficos",
  J59: "Atividades Cinematográficas, Produção De Vídeos E De Programas De Televisão; Gravação De Som E Edição De Música",
  J59_1: "Atividades Cinematográficas, Produção De Vídeos E De Programas De Televisão",
  J59_11__1: "Atividades De Produção Cinematográfica, De Vídeos E De Programas De Televisão",
  J59_11__1_01: "Estúdios Cinematográficos",
  J59_11__1_02: "Produção De Filmes Para Publicidade",
  J59_11__1_99: "Atividades De Produção Cinematográfica, De Vídeos E De Programas De Televisão Não Especificadas Anteriormente",
  J59_12__0: "Atividades De Pós-Produção Cinematográfica, De Vídeos E De Programas De Televisão",
  J59_12__0_01: "Serviços De Dublagem",
  J59_12__0_02: "Serviços De Mixagem Sonora Em Produção Audiovisual",
  J59_12__0_99: "Atividades De Pós Produção Cinematográfica, De Vídeos E De Programas De Televisão Não Especificadas Anteriormente",
  J59_13__8: "Distribuição Cinematográfica, De Vídeo E De Programas De Televisão",
  J59_14__6: "Atividades De Exibição Cinematográfica",
  J59_2: "Atividades De Gravação De Som E De Edição De Música",
  J61: "Telecomunicações",
  J61_1: "Telecomunicações Por Fio",
  J61_10__8_03: "Serviços De Comunicação Multimídia - Scm",
  J61_10__8_99: "Serviços De Telecomunicações Por Fio Não Especificados Anteriormente",
  J61_10__8_01: "Serviços De Telefonia Fixa Comutada - Stfc",
  J61_10__8_02: "Serviços De Redes De Transportes De Telecomunicações - Srtt",
  J61_2: "Telecomunicações Sem Fio",
  J61_20__5_01: "Telefonia Móvel Celular",
  J61_20__5_02: "Serviço Móvel Especializado - Sme",
  J61_20__5_99: "Serviços De Telecomunicações Sem Fio Não Especificados Anteriormente",
  J61_3: "Telecomunicações Por Satélite",
  J61_4: "Operadoras De Televisão Por Assinatura",
  J61_41__8: "Operadoras De Televisão Por Assinatura Por Cabo",
  J61_42__6: "Operadoras De Televisão Por Assinatura Por Micro-Ondas",
  J61_42__6_00: "Operadoras De Televisão Por Assinatura Por Microondas",
  J61_43__4: "Operadoras De Televisão Por Assinatura Por Satélite",
  J61_9: "Outras Atividades De Telecomunicações",
  J61_90__6_01: "Provedores De Acesso Às Redes De Comunicações",
  J61_90__6_02: "Provedores De Voz Sobre Protocolo Internet - Voip",
  J61_90__6_99: "Outras Atividades De Telecomunicações Não Especificadas Anteriormente",
  J62: "Atividades Dos Serviços De Tecnologia Da Informação",
  J62_01__5: "Desenvolvimento De Programas De Computador Sob Encomenda",
  J62_01__5_02: "Web Design",
  J62_02__3: "Desenvolvimento E Licenciamento De Programas De Computador Customizáveis",
  J62_03__1: "Desenvolvimento E Licenciamento De Programas De Computador Não Customizáveis",
  J62_04__0: "Consultoria Em Tecnologia Da Informação",
  J62_09__1: "Suporte Técnico, Manutenção E Outros Serviços Em Tecnologia Da Informação",
  J63: "Atividades De Prestação De Serviços De Informação",
  J63_1: "Tratamento De Dados, Hospedagem Na Internet E Outras Atividades Relacionadas",
  J63_11__9: "Tratamento De Dados, Provedores De Serviços De Aplicação E Serviços De Hospedagem Na Internet",
  J63_19__4: "Portais, Provedores De Conteúdo E Outros Serviços De Informação Na Internet",
  J63_9: "Outras Atividades De Prestação De Serviços De Informação",
  J63_91__7: "Agências De Notícias",
  J63_99__2: "Outras Atividades De Prestação De Serviços De Informação Não Especificadas Anteriormente",
  K: "Atividades Financeiras, De Seguros E Serviços Relacionados",
  K64: "Atividades De Serviços Financeiros",
  K64_1: "Banco Central",
  K64_2: "Intermediação Monetária - Depósitos À Vista",
  K64_23__9: "Caixas Econômicas",
  K64_22__1: "Bancos Múltiplos, Com Carteira Comercial",
  K64_21__2: "Bancos Comerciais",
  K64_24__7: "Crédito Cooperativo",
  K64_24__7_01: "Bancos Cooperativos",
  K64_24__7_02: "Cooperativas Centrais De Crédito",
  K64_24__7_03: "Cooperativas De Crédito Mútuo",
  K64_24__7_04: "Cooperativas De Crédito Rural",
  K64_3: "Intermediação Não-Monetária - Outros Instrumentos De Captação",
  K64_31__0: "Bancos Múltiplos, Sem Carteira Comercial",
  K64_32__8: "Bancos De Investimento",
  K64_33__6: "Bancos De Desenvolvimento",
  K64_34__4: "Agências De Fomento",
  K64_35__2: "Crédito Imobiliário",
  K64_35__2_01: "Sociedades De Crédito Imobiliário",
  K64_35__2_02: "Associações De Poupança E Empréstimo",
  K64_35__2_03: "Companhias Hipotecárias",
  K64_36__1: "Sociedades De Crédito, Financiamento E Investimento - Financeiras",
  K64_37__9: "Sociedades De Crédito Ao Microempreendedor",
  K64_38__7: "Bancos De Cambio E Outras Instituições De Intermediação Não Monetária",
  K64_38__7_01: "Bancos De Câmbio",
  K64_38__7_99: "Outras Instituições De Intermediação Não Monetária",
  K64_4: "Arrendamento Mercantil",
  K64_5: "Sociedades De Capitalização",
  K64_6: "Atividades De Sociedades De Participação",
  K64_61__1: "Holdings De Instituições Financeiras",
  K64_62__0: "Holdings De Instituições Não Financeiras",
  K64_63__8: "Outras Sociedades De Participação, Exceto Holdings",
  K64_7: "Fundos De Investimento",
  K64_70__1_02: "Fundos De Investimento Previdenciários",
  K64_70__1_03: "Fundos De Investimento Imobiliários",
  K64_70__1_01: "Fundos De Investimento, Exceto Previdenciários E Imobiliários",
  K64_9: "Atividades De Serviços Financeiros Não Especificadas Anteriormente",
  K64_91__3: "Sociedades De Fomento Mercantil - Factoring",
  K64_92__1: "Securitização De Créditos",
  K64_93__0: "Administração De Consórcios Para Aquisição De Bens E Direitos",
  K64_99__9: "Outras Atividades De Serviços Financeiros Não Especificadas Anteriormente",
  K64_99__9_01: "Clubes De Investimento",
  K64_99__9_02: "Sociedades De Investimento",
  K64_99__9_03: "Fundo Garantidor De Crédito",
  K64_99__9_04: "Caixas De Financiamento De Corporações",
  K64_99__9_05: "Concessão De Crédito Pelas Oscip",
  K65: "Seguros, Resseguros, Previdência Complementar E Planos De Saúde",
  K65_1: "Seguros De Vida E Não-Vida",
  K65_11__1: "Seguros De Vida",
  K65_11__1_01: "Sociedade Seguradora De Seguros Vida",
  K65_11__1_02: "Planos De Auxílio Funeral",
  K65_12__0: "Seguros Não Vida",
  K65_12__0_00: "Sociedade Seguradora De Seguros Não Vida",
  K65_2: "Seguros-Saúde",
  K65_20__1_00: "Sociedade Seguradora De Seguros Saúde",
  K65_3: "Resseguros",
  K65_4: "Previdência Complementar",
  K65_41__3: "Previdência Complementar Fechada",
  K65_42__1: "Previdência Complementar Aberta",
  K65_5: "Planos De Saúde",
  K66: "Atividades Auxiliares Dos Serviços Financeiros, Seguros, Previdência Complementar E Planos De Saúde",
  K66_1: "Atividades Auxiliares Dos Serviços Financeiros",
  K66_11__8: "Administração De Bolsas E Mercados De Balcão Organizados",
  K66_11__8_02: "Bolsa De Mercadorias",
  K66_11__8_03: "Bolsa De Mercadorias E Futuros",
  K66_11__8_04: "Administração De Mercados De Balcão Organizados",
  K66_11__8_01: "Bolsa De Valores",
  K66_12__6: "Atividades De Intermediários Em Transações De Títulos, Valores Mobiliários E Mercadorias",
  K66_12__6_02: "Distribuidoras De Títulos E Valores Mobiliários",
  K66_12__6_03: "Corretoras De Câmbio",
  K66_12__6_04: "Corretoras De Contratos De Mercadorias",
  K66_12__6_01: "Corretoras De Títulos E Valores Mobiliários",
  K66_12__6_05: "Agentes De Investimentos Em Aplicações Financeiras",
  K66_13__4: "Administração De Cartões De Crédito",
  K66_19__3: "Atividades Auxiliares Dos Serviços Financeiros Não Especificadas Anteriormente",
  K66_19__3_04: "Caixas Eletrônicos",
  K66_19__3_05: "Operadoras De Cartões De Débito",
  K66_19__3_99: "Outras Atividades Auxiliares Dos Serviços Financeiros Não Especificadas Anteriormente",
  K66_19__3_01: "Serviços De Liquidação E Custódia",
  K66_19__3_02: "Correspondentes De Instituições Financeiras",
  K66_19__3_03: "Representações De Bancos Estrangeiros",
  K66_2: "Atividades Auxiliares Dos Seguros, Da Previdência Complementar E Dos Planos De Saúde",
  K66_21__5: "Avaliação De Riscos E Perdas",
  K66_21__5_02: "Auditoria E Consultoria Atuarial",
  K66_21__5_01: "Peritos E Avaliadores De Seguros",
  K66_22__3: "Corretores E Agentes De Seguros, De Planos De Previdência Complementar E De Saúde",
  K66_29__1: "Atividades Auxiliares Dos Seguros, Da Previdência Complementar E Dos Planos De Saúde Não Especificadas Anteriormente",
  K66_3: "Atividades De Administração De Fundos Por Contrato Ou Comissão",
  L: "Atividades Imobiliárias",
  L68_1: "Atividades Imobiliárias De Imóveis Próprios",
  L68_10__2_01: "Compra E Venda De Imóveis Próprios",
  L68_10__2_02: "Aluguel De Imóveis Próprios",
  L68_10__2_03: "Loteamento De Imóveis Próprios",
  L68_2: "Atividades Imobiliárias Por Contrato Ou Comissão",
  L68_21__8: "Intermediação Na Compra, Venda E Aluguel De Imóveis",
  L68_21__8_01: "Corretagem Na Compra E Venda E Avaliação De Imóveis",
  L68_21__8_02: "Corretagem No Aluguel De Imóveis",
  L68_22__6: "Gestão E Administração Da Propriedade Imobiliária",
  L68_22__6_00: "Gestão E Administração Da Propriedade Imobiliaria",
  M: "Atividades Profissionais, Científicas E Técnicas",
  M71: "Serviços De Arquitetura E Engenharia; Testes E Análises Técnicas",
  M71_1: "Serviços De Arquitetura E Engenharia E Atividades Técnicas Relacionadas",
  M71_11__1: "Serviços De Arquitetura",
  M71_12__0: "Serviços De Engenharia",
  M71_19__7: "Atividades Técnicas Relacionadas À Arquitetura E Engenharia",
  M71_19__7_01: "Serviços De Cartografia, Topografia E Geodésia",
  M71_19__7_02: "Atividades De Estudos Geológicos",
  M71_19__7_03: "Serviços De Desenho Técnico Relacionados À Arquitetura E Engenharia",
  M71_19__7_04: "Serviços De Perícia Técnica Relacionados À Segurança Do Trabalho",
  M71_19__7_99: "Atividades Técnicas Relacionadas À Engenharia E Arquitetura Não Especificadas Anteriormente",
  M71_2: "Testes E Análises Técnicas",
  M69: "Atividades Jurídicas, De Contabilidade E De Auditoria",
  M69_1: "Atividades Jurídicas",
  M69_11__7: "Atividades Jurídicas, Exceto Cartórios",
  M69_11__7_01: "Serviços Advocatícios",
  M69_11__7_03: "Agente De Propriedade Industrial",
  M69_11__7_02: "Atividades Auxiliares Da Justiça",
  M69_12__5: "Cartórios",
  M69_2: "Atividades De Contabilidade, Consultoria E Auditoria Contábil E Tributária",
  M69_20__6_01: "Atividades De Contabilidade",
  M69_20__6_02: "Atividades De Consultoria E Auditoria Contábil E Tributária",
  M70: "Atividades De Sedes De Empresas E De Consultoria Em Gestão Empresarial",
  M70_1: "Sedes De Empresas E Unidades Administrativas Locais",
  M70_2: "Atividades De Consultoria Em Gestão Empresarial",
  M70_20__4_00: "Atividades De Consultoria Em Gestão Empresarial, Exceto Consultoria Técnica Específica",
  M72: "Pesquisa E Desenvolvimento Científico",
  M72_1: "Pesquisa E Desenvolvimento Experimental Em Ciências Físicas E Naturais",
  M72_2: "Pesquisa E Desenvolvimento Experimental Em Ciências Sociais E Humanas",
  M73: "Publicidade E Pesquisa De Mercado",
  M73_1: "Publicidade",
  M73_11__4: "Agências De Publicidade",
  M73_12__2: "Agenciamento De Espaços Para Publicidade, Exceto Em Veículos De Comunicação",
  M73_19__0: "Atividades De Publicidade Não Especificadas Anteriormente",
  M73_19__0_01: "Criação Estandes Para Feiras E Exposições",
  M73_19__0_02: "Promoção De Vendas",
  M73_19__0_03: "Marketing Direto",
  M73_19__0_04: "Consultoria Em Publicidade",
  M73_19__0_99: "Outras Atividades De Publicidade Não Especificadas Anteriormente",
  M73_2: "Pesquisas De Mercado E De Opinião Pública",
  M74: "Outras Atividades Profissionais, Científicas E Técnicas",
  M74_1: "Design E Decoração De Interiores",
  M74_10__2_02: "Design De Interiores",
  M74_10__2_03: "Design De Produto",
  M74_10__2_99: "Atividades De Design Não Especificadas Anteriormente",
  M74_2: "Atividades Fotográficas E Similares",
  M74_20__0_01: "Atividades De Produção De Fotografias, Exceto Aérea E Submarina",
  M74_20__0_02: "Atividades De Produção De Fotografias Aéreas E Submarinas",
  M74_20__0_03: "Laboratórios Fotográficos",
  M74_20__0_04: "Filmagem De Festas E Eventos",
  M74_20__0_05: "Serviços De Microfilmagem",
  M74_9: "Atividades Profissionais, Científicas E Técnicas Não Especificadas Anteriormente",
  M74_90__1_02: "Escafandria E Mergulho",
  M74_90__1_01: "Serviços De Tradução, Interpretação E Similares",
  M74_90__1_04: "Atividades De Intermediação E Agenciamento De Serviços E Negócios Em Geral, Exceto Imobiliários",
  M74_90__1_05: "Agenciamento De Profissionais Para Atividades Esportivas, Culturais E Artísticas",
  M74_90__1_99: "Outras Atividades Profissionais, Científicas E Técnicas Não Especificadas Anteriormente",
  M74_90__1_03: "Serviços De Agronomia E De Consultoria Às Atividades Agrícolas E Pecuárias",
  M75: "Atividades Veterinárias",
  N: "Atividades Administrativas E Serviços Complementares",
  N77: "Aluguéis Não-Imobiliários E Gestão De Ativos Intangíveis Não-Financeiros",
  N77_1: "Locação De Meios De Transporte Sem Condutor",
  N77_11__0: "Locação De Automóveis Sem Condutor",
  N77_19__5: "Locação De Meios De Transporte, Exceto Automóveis, Sem Condutor",
  N77_19__5_01: "Locação De Embarcações Sem Tripulação, Exceto Para Fins Recreativos",
  N77_19__5_02: "Locação De Aeronaves Sem Tripulação",
  N77_19__5_99: "Locação De Outros Meios De Transporte Não Especificados Anteriormente, Sem Condutor",
  N77_2: "Aluguel De Objetos Pessoais E Domésticos",
  N77_21__7: "Aluguel De Equipamentos Recreativos E Esportivos",
  N77_22__5: "Aluguel De Fitas De Vídeo, Dvds E Similares",
  N77_23__3: "Aluguel De Objetos Do Vestuário, Joias E Acessórios",
  N77_23__3_00: "Aluguel De Objetos Do Vestuário, Jóias E Acessórios",
  N77_29__2: "Aluguel De Objetos Pessoais E Domésticos Não Especificados Anteriormente",
  N77_29__2_01: "Aluguel De Aparelhos De Jogos Eletrônicos",
  N77_29__2_02: "Aluguel De Móveis, Utensílios E Aparelhos De Uso Doméstico E Pessoal; Instrumentos Musicais",
  N77_29__2_03: "Aluguel De Material Médico",
  N77_29__2_99: "Aluguel De Outros Objetos Pessoais E Domésticos Não Especificados Anteriormente",
  N77_3: "Aluguel De Máquinas E Equipamentos Sem Operador",
  N77_31__4: "Aluguel De Máquinas E Equipamentos Agrícolas Sem Operador",
  N77_32__2: "Aluguel De Máquinas E Equipamentos Para Construção Sem Operador",
  N77_32__2_01: "Aluguel De Máquinas E Equipamentos Para Construção Sem Operador, Exceto Andaimes",
  N77_32__2_02: "Aluguel De Andaimes",
  N77_33__1: "Aluguel De Máquinas E Equipamentos Para Escritórios",
  N77_39__0: "Aluguel De Máquinas E Equipamentos Não Especificados Anteriormente",
  N77_39__0_01: "Aluguel De Máquinas E Equipamentos Para Extração De Minérios E Petróleo, Sem Operador",
  N77_39__0_02: "Aluguel De Equipamentos Científicos, Médicos E Hospitalares, Sem Operador",
  N77_39__0_03: "Aluguel De Palcos, Coberturas E Outras Estruturas De Uso Temporário, Exceto Andaimes",
  N77_39__0_99: "Aluguel De Outras Máquinas E Equipamentos Comerciais E Industriais Não Especificados Anteriormente, Sem Operador",
  N77_4: "Gestão De Ativos Intangíveis Não-Financeiros",
  N77_40__3: "Gestão De Ativos Intangíveis Não Financeiros",
  N78: "Seleção, Agenciamento E Locação De Mão-De-Obra",
  N78_1: "Seleção E Agenciamento De Mão-De-Obra",
  N78_10__8: "Seleção E Agenciamento De Mão De Obra",
  N78_2: "Locação De Mão-De-Obra Temporária",
  N78_20__5: "Locação De Mão De Obra Temporária",
  N78_3: "Fornecimento E Gestão De Recursos Humanos Para Terceiros",
  N79: "Agências De Viagens, Operadores Turísticos E Serviços De Reservas",
  N79_1: "Agências De Viagens E Operadores Turísticos",
  N79_11__2: "Agências De Viagens",
  N79_12__1: "Operadores Turísticos",
  N79_9: "Serviços De Reservas E Outros Serviços De Turismo Não Especificados Anteriormente",
  N80: "Atividades De Vigilância, Segurança E Investigação",
  N80_1: "Atividades De Vigilância, Segurança Privada E Transporte De Valores",
  N80_11__1: "Atividades De Vigilância E Segurança Privada",
  N80_11__1_02: "Serviços De Adestramento De Cães De Guarda",
  N80_12__9: "Atividades De Transporte De Valores",
  N80_2: "Atividades De Monitoramento De Sistemas De Segurança",
  N80_20__0_01: "Atividades De Monitoramento De Sistemas De Segurança Eletrônico",
  N80_20__0_02: "Outras Atividades De Serviços De Segurança",
  N80_3: "Atividades De Investigação Particular",
  N81: "Serviços Para Edifícios E Atividades Paisagísticas",
  N81_1: "Serviços Combinados Para Apoio A Edifícios",
  N81_11__7: "Serviços Combinados Para Apoio A Edifícios, Exceto Condomínios Prediais",
  N81_12__5: "Condomínios Prediais",
  N81_2: "Atividades De Limpeza",
  N81_21__4: "Limpeza Em Prédios E Em Domicílios",
  N81_22__2: "Imunização E Controle De Pragas Urbanas",
  N81_29__0: "Atividades De Limpeza Não Especificadas Anteriormente",
  N81_3: "Atividades Paisagísticas",
  N82: "Serviços De Escritório, De Apoio Administrativo E Outros Serviços Prestados Principalmente Às Empresas",
  N82_1: "Serviços De Escritório E Apoio Administrativo",
  N82_11__3: "Serviços Combinados De Escritório E Apoio Administrativo",
  N82_19__9: "Fotocópias, Preparação De Documentos E Outros Serviços Especializados De Apoio Administrativo",
  N82_19__9_01: "Fotocópias",
  N82_19__9_99: "Preparação De Documentos E Serviços Especializados De Apoio Administrativo Não Especificados Anteriormente",
  N82_2: "Atividades De Teleatendimento",
  N82_3: "Atividades De Organização De Eventos, Exceto Culturais E Esportivos",
  N82_30__0_02: "Casas De Festas E Eventos",
  N82_30__0_01: "Serviços De Organização De Feiras, Congressos, Exposições E Festas",
  N82_9: "Outras Atividades De Serviços Prestados Principalmente Às Empresas",
  N82_91__1: "Atividades De Cobranças E Informações Cadastrais",
  N82_92__0: "Envasamento E Empacotamento Sob Contrato",
  N82_99__7: "Atividades De Serviços Prestados Principalmente Às Empresas Não Especificadas Anteriormente",
  N82_99__7_01: "Medição De Consumo De Energia Elétrica, Gás E Água",
  N82_99__7_02: "Emissão De Vales Alimentação, Vales Transporte E Similares",
  N82_99__7_03: "Serviços De Gravação De Carimbos, Exceto Confecção",
  N82_99__7_04: "Leiloeiros Independentes",
  N82_99__7_05: "Serviços De Levantamento De Fundos Sob Contrato",
  N82_99__7_06: "Casas Lotéricas",
  N82_99__7_07: "Salas De Acesso À Internet",
  N82_99__7_99: "Outras Atividades De Serviços Prestados Principalmente Às Empresas Não Especificadas Anteriormente",
  O: "Administração Pública, Defesa E Seguridade Social",
  O84_1: "Administração Do Estado E Da Política Econômica E Social",
  O84_11__6: "Administração Pública Em Geral",
  O84_12__4: "Regulação Das Atividades De Saúde, Educação, Serviços Culturais E Outros Serviços Sociais",
  O84_13__2: "Regulação Das Atividades Econômicas",
  O84_2: "Serviços Coletivos Prestados Pela Administração Pública",
  O84_21__3: "Relações Exteriores",
  O84_22__1: "Defesa",
  O84_23__0: "Justiça",
  O84_24__8: "Segurança E Ordem Pública",
  O84_25__6: "Defesa Civil",
  O84_3: "Seguridade Social Obrigatória",
  P: "Educação",
  P85_1: "Educação Infantil E Ensino Fundamental",
  P85_11__2: "Educação Infantil - Creche",
  P85_12__1: "Educação Infantil - Pré-Escola",
  P85_12__1_00: "Educação Infantil - Préescola",
  P85_13__9: "Ensino Fundamental",
  P85_2: "Ensino Médio",
  P85_3: "Educação Superior",
  P85_33__3: "Educação Superior - Pós-Graduação E Extensão",
  P85_33__3_00: "Educação Superior - Pós Graduação E Extensão",
  P85_31__7: "Educação Superior - Graduação",
  P85_32__5: "Educação Superior - Graduação E Pós-Graduação",
  P85_32__5_00: "Educação Superior - Graduação E Pós Graduação",
  P85_4: "Educação Profissional De Nível Técnico E Tecnológico",
  P85_41__4: "Educação Profissional De Nível Técnico",
  P85_42__2: "Educação Profissional De Nível Tecnológico",
  P85_5: "Atividades De Apoio À Educação",
  P85_50__3_01: "Administração De Caixas Escolares",
  P85_50__3_02: "Atividades De Apoio À Educação, Exceto Caixas Escolares",
  P85_9: "Outras Atividades De Ensino",
  P85_91__1: "Ensino De Esportes",
  P85_92__9: "Ensino De Arte E Cultura",
  P85_92__9_01: "Ensino De Dança",
  P85_92__9_02: "Ensino De Artes Cênicas, Exceto Dança",
  P85_92__9_03: "Ensino De Música",
  P85_92__9_99: "Ensino De Arte E Cultura Não Especificado Anteriormente",
  P85_93__7: "Ensino De Idiomas",
  P85_99__6: "Atividades De Ensino Não Especificadas Anteriormente",
  P85_99__6_01: "Formação De Condutores",
  P85_99__6_02: "Cursos De Pilotagem",
  P85_99__6_03: "Treinamento Em Informática",
  P85_99__6_04: "Treinamento Em Desenvolvimento Profissional E Gerencial",
  P85_99__6_05: "Cursos Preparatórios Para Concursos",
  P85_99__6_99: "Outras Atividades De Ensino Não Especificadas Anteriormente",
  Q: "Saúde Humana E Serviços Sociais",
  Q86: "Atividades De Atenção À Saúde Humana",
  Q86_1: "Atividades De Atendimento Hospitalar",
  Q86_10__1_01: "Atividades De Atendimento Hospitalar, Exceto Pronto Socorro E Unidades Para Atendimento A Urgências",
  Q86_10__1_02: "Atividades De Atendimento Em Pronto Socorro E Unidades Hospitalares Para Atendimento A Urgências",
  Q86_2: "Serviços Móveis De Atendimento A Urgências E De Remoção De Pacientes",
  Q86_21__6: "Serviços Móveis De Atendimento A Urgências",
  Q86_21__6_01: "Uti Móvel",
  Q86_21__6_02: "Serviços Móveis De Atendimento A Urgências, Exceto Por Uti Móvel",
  Q86_22__4: "Serviços De Remoção De Pacientes, Exceto Os Serviços Móveis De Atendimento A Urgências",
  Q86_3: "Atividades De Atenção Ambulatorial Executadas Por Médicos E Odontólogos",
  Q86_30__5_99: "Atividades De Atenção Ambulatorial Não Especificadas Anteriormente",
  Q86_30__5_01: "Atividade Médica Ambulatorial Com Recursos Para Realização De Procedimentos Cirúrgicos",
  Q86_30__5_02: "Atividade Médica Ambulatorial Com Recursos Para Realização De Exames Complementares",
  Q86_30__5_03: "Atividade Médica Ambulatorial Restrita A Consultas",
  Q86_30__5_04: "Atividade Odontológica",
  Q86_30__5_06: "Serviços De Vacinação E Imunização Humana",
  Q86_30__5_07: "Atividades De Reprodução Humana Assistida",
  Q86_4: "Atividades De Serviços De Complementação Diagnóstica E Terapêutica",
  Q86_40__2_01: "Laboratórios De Anatomia Patológica E Citológica",
  Q86_40__2_02: "Laboratórios Clínicos",
  Q86_40__2_03: "Serviços De Diálise E Nefrologia",
  Q86_40__2_04: "Serviços De Tomografia",
  Q86_40__2_05: "Serviços De Diagnóstico Por Imagem Com Uso De Radiação Ionizante, Exceto Tomografia",
  Q86_40__2_06: "Serviços De Ressonância Magnética",
  Q86_40__2_07: "Serviços De Diagnóstico Por Imagem Sem Uso De Radiação Ionizante, Exceto Ressonância Magnética",
  Q86_40__2_08: "Serviços De Diagnóstico Por Registro Gráfico - Ecg, Eeg E Outros Exames Análogos",
  Q86_40__2_09: "Serviços De Diagnóstico Por Métodos Ópticos - Endoscopia E Outros Exames Análogos",
  Q86_40__2_10: "Serviços De Quimioterapia",
  Q86_40__2_11: "Serviços De Radioterapia",
  Q86_40__2_12: "Serviços De Hemoterapia",
  Q86_40__2_13: "Serviços De Litotripcia",
  Q86_40__2_14: "Serviços De Bancos De Células E Tecidos Humanos",
  Q86_40__2_99: "Atividades De Serviços De Complementação Diagnóstica E Terapêutica Não Especificadas Anteriormente",
  Q86_5: "Atividades De Profissionais Da Área De Saúde, Exceto Médicos E Odontólogos",
  Q86_50__0_06: "Atividades De Fonoaudiologia",
  Q86_50__0_04: "Atividades De Fisioterapia",
  Q86_50__0_01: "Atividades De Enfermagem",
  Q86_50__0_02: "Atividades De Profissionais Da Nutrição",
  Q86_50__0_03: "Atividades De Psicologia E Psicanálise",
  Q86_50__0_07: "Atividades De Terapia De Nutrição Enteral E Parenteral",
  Q86_50__0_99: "Atividades De Profissionais Da Área De Saúde Não Especificadas Anteriormente",
  Q86_50__0_05: "Atividades De Terapia Ocupacional",
  Q86_6: "Atividades De Apoio À Gestão De Saúde",
  Q86_9: "Atividades De Atenção À Saúde Humana Não Especificadas Anteriormente",
  Q86_90__9_01: "Atividades De Práticas Integrativas E Complementares Em Saúde Humana",
  Q86_90__9_02: "Atividades De Banco De Leite Humano",
  Q86_90__9_03: "Atividades De Acupuntura",
  Q86_90__9_04: "Atividades De Podologia",
  Q86_90__9_99: "Outras Atividades De Atenção À Saúde Humana Não Especificadas Anteriormente",
  Q87: "Atividades De Atenção À Saúde Humana Integradas Com Assistência Social, Prestadas Em Residências Coletivas E Particulares",
  Q87_1: "Atividades De Assistência A Idosos, Deficientes Físicos, Imunodeprimidos E Convalescentes, E De Infra-Estrutura E Apoio A Pacientes Prestadas Em Residências Coletivas E Particulares",
  Q87_11__5: "Atividades De Assistência A Idosos, Deficientes Físicos, Imunodeprimidos E Convalescentes Prestadas Em Residências Coletivas E Particulares",
  Q87_11__5_01: "Clínicas E Residências Geriátricas",
  Q87_11__5_02: "Instituições De Longa Permanência Para Idosos",
  Q87_11__5_03: "Atividades De Assistência A Deficientes Físicos, Imunodeprimidos E Convalescentes",
  Q87_11__5_04: "Centros De Apoio A Pacientes Com Câncer E Com Aids",
  Q87_11__5_05: "Condomínios Residenciais Para Idosos",
  Q87_12__3: "Atividades De Fornecimento De Infraestrutura De Apoio E Assistência A Paciente No Domicílio",
  Q87_2: "Atividades De Assistência Psicossocial E À Saúde A Portadores De Distúrbios Psíquicos, Deficiência Mental E Dependência Química",
  Q87_20__4_01: "Atividades De Centros De Assistência Psicossocial",
  Q87_20__4_99: "Atividades De Assistência Psicossocial E À Saúde A Portadores De Distúrbios Psíquicos, Deficiência Mental E Dependência Química E Grupos Similares Não Especificadas Anteriormente",
  Q87_3: "Atividades De Assistência Social Prestadas Em Residências Coletivas E Particulares",
  Q87_30__1_01: "Orfanatos",
  Q87_30__1_02: "Albergues Assistenciais",
  Q87_30__1_99: "Atividades De Assistência Social Prestadas Em Residências Coletivas E Particulares Não Especificadas Anteriormente",
  Q88: "Serviços De Assistência Social Sem Alojamento",
  R: "Artes, Cultura, Esporte E Recreação",
  R91: "Atividades Ligadas Ao Patrimônio Cultural E Ambiental",
  R91_01__5: "Atividades De Bibliotecas E Arquivos",
  R91_02__3: "Atividades De Museus E De Exploração, Restauração Artística E Conservação De Lugares E Prédios Históricos E Atrações Similares",
  R91_02__3_01: "Atividades De Museus E De Exploração De Lugares E Prédios Históricos E Atrações Similares",
  R91_02__3_02: "Restauração E Conservação De Lugares E Prédios Históricos",
  R91_03__1: "Atividades De Jardins Botânicos, Zoológicos, Parques Nacionais, Reservas Ecológicas E Áreas De Proteção Ambiental",
  R92: "Atividades De Exploração De Jogos De Azar E Apostas",
  R92_00__3_01: "Casas De Bingo",
  R92_00__3_02: "Exploração De Apostas Em Corridas De Cavalos",
  R92_00__3_99: "Exploração De Jogos De Azar E Apostas Não Especificados Anteriormente",
  R90: "Atividades Artísticas, Criativas E De Espetáculos",
  R90_01__9: "Artes Cênicas, Espetáculos E Atividades Complementares",
  R90_01__9_01: "Produção Teatral",
  R90_01__9_02: "Produção Musical",
  R90_01__9_03: "Produção De Espetáculos De Dança",
  R90_01__9_04: "Produção De Espetáculos Circenses, De Marionetes E Similares",
  R90_01__9_05: "Produção De Espetáculos De Rodeios, Vaquejadas E Similares",
  R90_01__9_06: "Atividades De Sonorização E De Iluminação",
  R90_01__9_99: "Artes Cênicas, Espetáculos E Atividades Complementares Não Especificadas Anteriormente",
  R90_02__7: "Criação Artística",
  R90_02__7_02: "Restauração De Obras De Arte",
  R90_02__7_01: "Atividades De Artistas Plásticos, Jornalistas Independentes E Escritores",
  R90_03__5: "Gestão De Espaços Para Artes Cênicas, Espetáculos E Outras Atividades Artísticas",
  R93: "Atividades Esportivas E De Recreação E Lazer",
  R93_1: "Atividades Esportivas",
  R93_11__5: "Gestão De Instalações De Esportes",
  R93_12__3: "Clubes Sociais, Esportivos E Similares",
  R93_13__1: "Atividades De Condicionamento Físico",
  R93_19__1: "Atividades Esportivas Não Especificadas Anteriormente",
  R93_19__1_01: "Produção E Promoção De Eventos Esportivos",
  R93_19__1_99: "Outras Atividades Esportivas Não Especificadas Anteriormente",
  R93_2: "Atividades De Recreação E Lazer",
  R93_21__2: "Parques De Diversão E Parques Temáticos",
  R93_29__8: "Atividades De Recreação E Lazer Não Especificadas Anteriormente",
  R93_29__8_01: "Discotecas, Danceterias, Salões De Dança E Similares",
  R93_29__8_02: "Exploração De Boliches",
  R93_29__8_03: "Exploração De Jogos De Sinuca, Bilhar E Similares",
  R93_29__8_04: "Exploração De Jogos Eletrônicos Recreativos",
  R93_29__8_99: "Outras Atividades De Recreação E Lazer Não Especificadas Anteriormente",
  S: "Outras Atividades De Serviços",
  S94: "Atividades De Organizações Associativas",
  S94_1: "Atividades De Organizações Associativas Patronais, Empresariais E Profissionais",
  S94_11__1: "Atividades De Organizações Associativas Patronais E Empresariais",
  S94_12__0: "Atividades De Organizações Associativas Profissionais",
  S94_12__0_01: "Atividades De Fiscalização Profissional",
  S94_12__0_99: "Outras Atividades Associativas Profissionais",
  S94_2: "Atividades De Organizações Sindicais",
  S94_3: "Atividades De Associações De Defesa De Direitos Sociais",
  S94_9: "Atividades De Organizações Associativas Não Especificadas Anteriormente",
  S94_91__0: "Atividades De Organizações Religiosas",
  S94_91__0_00: "Atividades De Organizações Religiosas Ou Filosóficas",
  S94_92__8: "Atividades De Organizações Políticas",
  S94_93__6: "Atividades De Organizações Associativas Ligadas À Cultura E À Arte",
  S94_99__5: "Atividades Associativas Não Especificadas Anteriormente",
  S95: "Reparação E Manutenção De Equipamentos De Informática E Comunicação E De Objetos Pessoais E Domésticos",
  S95_1: "Reparação E Manutenção De Equipamentos De Informática E Comunicação",
  S95_11__8: "Reparação E Manutenção De Computadores E De Equipamentos Periféricos",
  S95_12__6: "Reparação E Manutenção De Equipamentos De Comunicação",
  S95_2: "Reparação E Manutenção De Objetos E Equipamentos Pessoais E Domésticos",
  S95_21__5: "Reparação E Manutenção De Equipamentos Eletroeletrônicos De Uso Pessoal E Doméstico",
  S95_29__1: "Reparação E Manutenção De Objetos E Equipamentos Pessoais E Domésticos Não Especificados Anteriormente",
  S95_29__1_03: "Reparação De Relógios",
  S95_29__1_01: "Reparação De Calçados, De Bolsas E Artigos De Viagem",
  S95_29__1_02: "Chaveiros",
  S95_29__1_04: "Reparação De Bicicletas, Triciclos E Outros Veículos Não Motorizados",
  S95_29__1_05: "Reparação De Artigos Do Mobiliário",
  S95_29__1_06: "Reparação De Jóias",
  S95_29__1_99: "Reparação E Manutenção De Outros Objetos E Equipamentos Pessoais E Domésticos Não Especificados Anteriormente",
  S96: "Outras Atividades De Serviços Pessoais",
  S96_01__7: "Lavanderias, Tinturarias E Toalheiros",
  S96_01__7_01: "Lavanderias",
  S96_01__7_02: "Tinturarias",
  S96_01__7_03: "Toalheiros",
  S96_02__5: "Cabeleireiros E Outras Atividades De Tratamento De Beleza",
  S96_02__5_02: "Atividades De Estética E Outros Serviços De Cuidados Com A Beleza",
  S96_02__5_01: "Cabeleireiros, Manicure E Pedicure",
  S96_03__3: "Atividades Funerárias E Serviços Relacionados",
  S96_03__3_02: "Serviços De Cremação",
  S96_03__3_03: "Serviços De Sepultamento",
  S96_03__3_04: "Serviços De Funerárias",
  S96_03__3_05: "Serviços De Somatoconservação",
  S96_03__3_99: "Atividades Funerárias E Serviços Relacionados Não Especificados Anteriormente",
  S96_03__3_01: "Gestão E Manutenção De Cemitérios",
  S96_09__2: "Atividades De Serviços Pessoais Não Especificadas Anteriormente",
  S96_09__2_02: "Agências Matrimoniais",
  S96_09__2_04: "Exploração De Máquinas De Serviços Pessoais Acionadas Por Moeda",
  S96_09__2_05: "Atividades De Sauna E Banhos",
  S96_09__2_06: "Serviços De Tatuagem E Colocação De Piercing",
  S96_09__2_07: "Alojamento De Animais Domésticos",
  S96_09__2_08: "Higiene E Embelezamento De Animais Domésticos",
  S96_09__2_99: "Outras Atividades De Serviços Pessoais Não Especificadas Anteriormente",
  T: "Serviços Domésticos",
  U: "Organismos Internacionais E Outras Instituições Extraterritoriais",
};
