<script lang="ts">
import type { PropType } from "vue";
import {
  computed,
  defineComponent,
  ref,
} from "vue";
import { useI18n } from "vue-i18n";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required, requiredIf } from "@vuelidate/validators";
import { isNumber } from "lodash";
import moment from "moment";
import type { TransformationEvent } from "@/entities/corporation";
import { Button2 } from "@/components/button";
import { CustomCheckBox } from "@/components/checkbox";
import { Select, TextArea } from "@/components/inputs/modalInputs";
import { genMonthsArray } from "@/utils/dates";

export default defineComponent({
  name: "TransformationEventForm",
  components: {
    Button: Button2,
    CustomCheckBox,
    Select,
    TextArea,
  },
  props: {
    transformationEvent: {
      type: Object as PropType<TransformationEvent>,
      default: () => ({}),
    },
    eventIndex: {
      type: Number as PropType<number>,
      required: true,
    },
  },
  emits: ["submit", "submit:cancel"],
  setup(props, { emit }) {
    const { t, tm } = useI18n();

    const getMonthAndYear = (date: string) => ({
      month: date ? moment(date).month() : null,
      year: date ? moment(date).year() : null,
    });

    const payload = ref({
      ...props.transformationEvent as TransformationEvent,
      startDate: getMonthAndYear((props.transformationEvent as TransformationEvent).start_date),
      endDate: getMonthAndYear((props.transformationEvent as TransformationEvent).end_date),
    });

    const months = computed(() =>
      genMonthsArray().map((month, index) => ({ label: month, value: index })));

    const ecmaStartYear = 1970;
    const years = Array.from({ length: new Date().getFullYear() - ecmaStartYear + 1 }, (_, index) => new Date(`01/01/${ecmaStartYear + index}`).getFullYear()).reverse();

    const ongoingEvent = ref<boolean>(
      payload.value.start_date
      && moment(payload.value.start_date).valueOf() < Date.now()
      && moment(payload.value.end_date).valueOf() > Date.now(),
    );

    const setOngoingEvent = (event) => {
      ongoingEvent.value = event.target.checked;

      if (ongoingEvent.value)
        payload.value.endDate = { month: null, year: null };
    };

    const transformationEventTypes = computed(
      () => tm("corporations.details.transformationEventType.enum"),
    );

    const rules = computed(() => ({
      event_type: {
        required: helpers.withMessage(t("validator.required"), required),
      },
      startDate: {
        month: {
          required: helpers.withMessage(t("validator.required"), required),
        },
        year: {
          required: helpers.withMessage(t("validator.required"), required),
        },
      },
      endDate: {
        year: {
          required: helpers.withMessage(
            t("validator.required"),
            requiredIf(!ongoingEvent.value && isNumber(payload.value.endDate.month)),
          ),
        },
      },
    }));

    const v$ = useVuelidate(rules, payload);

    const cancel = () => {
      v$.value.$reset();
      emit("submit:cancel");
    };

    const submit = async () => {
      const isValid = await v$.value.$validate();

      if (isValid) {
        const {
          startDate: sd,
          endDate: ed,
          action,
          goal,
          event_type,
        } = payload.value;

        emit("submit", {
          transformationEvent: {
            event_type,
            start_date: moment(new Date(sd.year, sd.month, 1)).format("YYYY-MM-DD"),
            end_date: ed.month && ed.year
              ? moment(new Date(ed.year, ed.month, 1)).format("YYYY-MM-DD")
              : null,
            action,
            goal,
          },
          eventIndex: props.eventIndex,
        });
      }
    };

    return {
      t,
      payload,
      months,
      years,
      ongoingEvent,
      setOngoingEvent,
      transformationEventTypes,
      v$,
      cancel,
      submit,
    };
  },
});
</script>

<template>
  <form class="transformation-event-form" @submit.prevent="submit()">
    <div class="form-section">
      <div class="form-group">
        <label for="event-type">
          {{ t("corporations.details.transformationEventType.name") }}
        </label>
        <Select
          id="event-type"
          v-model="payload.event_type"
          :placeholder="t('text.select')"
          :options="transformationEventTypes"
          :error="v$.event_type.$error"
          :error-message="v$.event_type.$errors[0]?.$message"
        />
      </div>
      <div class="form-group">
        <label for="action">{{ t("corporations.details.action") }}</label>
        <TextArea
          id="action"
          v-model="payload.action"
          :placeholder="t('corporations.details.forms.placeholders.eventActionDescription')"
        />
      </div>
      <div class="form-group">
        <label for="goal">{{ t("corporations.details.goal") }}</label>
        <TextArea
          id="goal"
          v-model="payload.goal"
          :placeholder="t('corporations.details.forms.placeholders.eventGoalDescription')"
        />
      </div>

      <div class="form-group">
        <label for="start-date">{{ t("text.startDate") }}</label>

        <div class="grid cols-2 gap-1">
          <Select
            id="start-date-month"
            v-model="payload.startDate.month"
            :options="months"
            :placeholder="t('placeholders.month')"
            :error="v$.startDate.month.$error"
            :error-message="v$.startDate.month.$errors[0]?.$message"
          />
          <Select
            id="start-date-year"
            v-model="payload.startDate.year"
            :options="years"
            :placeholder="t('placeholders.year')"
            :error="v$.startDate.year.$error"
            :error-message="v$.startDate.year.$errors[0]?.$message"
          />
        </div>
      </div>

      <div class="form-group">
        <CustomCheckBox
          :label="t('corporations.details.forms.placeholders.ongoingEvent')"
          :checked="ongoingEvent"
          :on-change="setOngoingEvent"
        />
      </div>

      <div class="form-group" :class="[{ '--disabled': ongoingEvent }]">
        <label for="end-date">{{ t("text.endDate") }}</label>

        <div class="grid cols-2 gap-1">
          <Select
            id="end-date-month"
            v-model="payload.endDate.month"
            :disabled="ongoingEvent"
            :options="months"
            :placeholder="t('placeholders.month')"
          />
          <Select
            id="end-date-year"
            v-model="payload.endDate.year"
            :disabled="ongoingEvent"
            :options="years"
            :placeholder="t('placeholders.year')"
            :error="v$.endDate.year.$error"
            :error-message="v$.endDate.year.$errors[0]?.$message"
          />
        </div>
      </div>
    </div>

    <div class="form-section">
      <div class="form-actions">
        <Button type="submit">
          {{ t("text.form.submit") }}
        </Button>

        <Button
          variation="light"
          filled
          @click="cancel()"
        >
          {{ t("text.form.cancel") }}
        </Button>
      </div>
    </div>
  </form>
</template>

<style lang="scss" scoped>
.grid {
  display: grid;

  &.cols-2 {
    grid-template-columns: repeat(2, 1fr);
  }

  &.gap-1 {
    gap: 1rem;
  }
}

.transformation-event-form {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(2, 1fr);

  .form-group {
    display: grid;
    margin-bottom: 1rem;
    grid-template-columns: 1fr 3fr;

    &.--disabled {
      opacity: 0.7;
      pointer-events: none;
    }
  }

  .form-actions {
    display: grid;
    padding-right: 10%;
    gap: 2rem;
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
