import {
  ACTIVITY,
  ATTRIBUTE,
  CULTURAL,
  DEGREE,
  EXTENSION_TYPE,
  INSTITUTION,
  INTERVIEWS_ACTIVITY,
  PROJECT_STAGE,
  SCREENING_ACTIVITY,
} from "@/entities/project";
import {
  ATTRIBUTES_TITLES,
  BOARD_TYPES,
  COMPANY_CHARTS,
  COMPANY_MOMENTS,
  CULTURAL_MATCH_TITLES,
  EXECUTION_CONDITIONS_TITLES,
  FUNCTIONAL_SCOPE_TITLES,
  GOVERNANCE_INSTANCE,
  GOVERNANCE_TITLES,
  POSITION_STRATEGY_TITLES,
  PROFILE_TITLES,
  PROJECT_STAGES,
  REALIZATIONS_TITLES,
  SCALE,
  STRATEGY_STATUSES,
  STRATEGY_TITLES,
} from "@/entities/project/construction-guide";
import { ConstructionEnum, ShortlistEnum, ShortlistPersonEnum } from "@/schemas/project/pdf";

import { ANALYSIS_TYPES } from "@/entities/project/organization-and-assembly";

const projectTypes = {
  BOARD: "Board",
  CEO_AND_EXECUTIVE_OFFICERS: "CEO & Executive",
  LEADERSHIP_SUCCESSION: "Sucessão de Liderança",
  MAPPING: "Mapeamento",
  ASSESSMENT: "Assessment",
  SCOPE_DESIGN: "Scope Design",
  SECOND_OPINION: "Second Opinion",
};

const sourceTypes = {
  NEW_CLIENT: "Cliente Novo",
  ACTIVE_RECURRENCE: "Recorrência Ativa",
  PASSIVE_RECURRENCE: "Recorrência Passiva",
  REACTIVATION: "Reativação",
};

const allocatedTeam = [
  "Ana Paula Ramos",
  "André Behs",
  "Bernardo Cavour",
  "Brunna Baggio",
  "Camila Junqueira",
  "Igor Schultz",
  "Katyanna Salvador",
  "Luiz Gustavo Mariano",
  "Thiago Pimenta",
  "Leandro Pedrosa",
  "Saulo Ferreira",
  "IsadoraMi tteldorf",
  "Leon Affonso",
  "Gabriel Teixeira",
  "Prince Achugbu",
  "Ingrid Caetano",
  "Natalia Soares",
  "Marcela Dantas",
];

const projectStatus = {
  ACTIVE: "Em andamento",
  DRAFT: "Rascunho",
  CONCLUDED: "Concluído",
  SUSPENDED: "Suspenso",
  CANCELLED: "Cancelado",
};

const activities = {
  [ACTIVITY.CONSTRUCTION_MEETING]: "Reunião de construção",
  [ACTIVITY.VALIDATION_MEETING]: "Reunião de validação",
  [ACTIVITY.INTERVIEWS]: "Entrevistas",
  [ACTIVITY.RESEARCH_AND_PICKING]: "Pesquisa e Seleção",
};

const screening_activities = {
  [SCREENING_ACTIVITY.SCREENING_MEETING]: "Prévia",
};

const interviews_activities = {
  [INTERVIEWS_ACTIVITY.RECRUITER_MEETING]: "Entrevista",
};

const stagesTooltip = {
  [PROJECT_STAGE.CONSTRUCTION]: "Planejamento com o Cliente e Construção do Perfil Ideal.",
  [PROJECT_STAGE.MAPPING]: "Busca de perfis de pessoas compatíveis com o Perfil Ideal.",
  [PROJECT_STAGE.SCREENINGS]: "Triagem de candidatos para aderência ao projeto de vaga.",
  [PROJECT_STAGE.INTERVIEWS]: "Entrevistas detalhadas com candidatos triados.",
  [PROJECT_STAGE.SHORTLIST]: "Seleção e apresentação de lista de candidatos finalistas.",
  [PROJECT_STAGE.UNDER_OFFER]: "Negociação entre empresa e candidatos mais aderente.",
  [PROJECT_STAGE.PLACEMENT]: "Contratação e finalização do processo.",
};

const scale = {
  [SCALE.EMPTY]: "",
  [SCALE.LOW]: "Baixo",
  [SCALE.AVERAGE]: "Médio",
  [SCALE.HIGH]: "Alto",
  [SCALE.VERY_HIGH]: "Muito Alto",
};

const changeJobOptions = {
  BUSINESS_FUNCTION: "Linha de negócio",
  PEOPLE_PROFILE: "Perfil das pessoas",
  COMPANY_CULTURE: "Cultura da empresa",
};

const careerPathOptions = {
  GREATER_POSITION: "Cadeira Maior",
  GREATER_CAREER: "Carreira Maior",
  WORK_ABROAD: "Oportunidade no Exterior",
  WORKPLACE: "Local de Trabalho",
};

const reasonsOptions = {
  EARNINGS: "Pacote",
  REMUNERATION_SYSTEM: "Sistema de Remuneração",
  CAREER_LIMITATIONS: "Limitações da Carreira",
  LACK_OF_RELOCATION_OPPORTUNITIES: "Falta de Oportunidade de Realocação",
  BUSINESS_TYPE: "Tipo do Negócio",
  FAMILY_SITUATION: "Situação Familiar",
  BOSS_RELATIONSHIP: "Relacionamento com chefia",
  PEERS: "Pares",
  COMPANY_CULTURE: "Cultura da empresa",
};

const validationPanel = {
  title: "Painel validação Geral",
  filters: {
    applyFilter: "Aplicar filtro",
    ascending: "Crescente",
    clearFilter: "Limpar filtro",
    descending: "Decrescente",
    selectAll: "Selecionar Tudo",
    title: "Filtro",
  },
  header: {
    close: "Fechar",
    company: "Empresa",
  },
  simpleSearch: {
    next: "Próximo",
    prev: "Anterior",
    results: "Foram encontrados {count} resultados",
    title: "O que deseja encontrar?",
    tooltip: "Localizar",
  },
  column: {
    candidate: "Candidato",
    company: "Empresa",
    past_experiences: "Experiencias Anteriores",
    phone: "Telefone",
    priority: "Priorização",
    role: "Cargo",
    salary: "Remuneração Fixa",
    salaryShort: "remuneração",
  },
  extra: {
    caution: "Atenção",
    noPriority: "Sem Priorização",
    nonEditable: "Só é permitido editar {name} no Perfil da Pessoa.",
    nonEditableSalary: "Para adicionar {remuneration} é preciso agregar experiência profissional no perfil da pessoa.",
    searchField: "Campo de Busca",
    salaryRange: "Faixa Salarial",
    salaryRangeFrom: "de",
    salaryRangeTo: "a",
  },
};

export const projects = {
  filters: {
    title: "FILTROS",
    searchProject: "Buscar projeto",
    searchProjectError: "informe pelo menos 2 caracteres para fazer a busca",
    clearFilters: "limpar filtros",
    clearFilter: "Limpar filtro",
    tagFilter: "Digite {tagFilter}",
    tagFilterClear: "Limpar filtro",
    tagFilters: {
      recruiter: "Consultor",
      keywords: ["Palavras chave", "a palavra-chave"],
      role: ["Cargo", "o cargo"],
      company: ["Empresa", "a empresa"],
      sector: ["L. de Negócios", "linha de negócio"],
      revenue: ["Salário", "o salário"],
      realizations: ["Realizações", "a realização"],
      executions: ["Condição de execução", "a condição de execução"],
      position: ["Posição", "posição"],
    },
    errorListProject: "Houve um erro ao carregar os projetos. Tente novamente.",
    notFoundSearch: "Não foi possível achar projeto algum com essa busca.",
    loadMore: "Carregar mais...",
    myProjects: ["Meus", "Projetos"],
    projectStatus: {
      name: "Status",
      enum: projectStatus,
      placeholder: "Todos",
    },
  },
  sort: {
    inserted_at: "Data de entrada",
    status: "Status",
  },
  generic: {
    company: "Empresa",
    sector: "Linha de negócio",
    status: "Status",
    salary: "Salário",
    position: "Posição",
    about: "Sobre",
    location: "Local",
    class: "Classe",
    level: "Nível",
    bonus: "Bônus",
    annualPackage: "Pacote anual",
    requester: "Requisitante",
    requestOrigin: "Origem",
    details: "Ver detalhes",
    confidential: "Projeto confidencial",
    edit: "Editar projeto",
    expand: "Expandir",
    placement: "Contratação",
    idealProfile: "Perfil ideal",
    stages: {
      enum: {
        [PROJECT_STAGE.CONSTRUCTION]: "Construção",
        [PROJECT_STAGE.MAPPING]: "Mapeamento",
        [PROJECT_STAGE.SCREENINGS]: "Prévias",
        [PROJECT_STAGE.INTERVIEWS]: "Entrevistas",
        [PROJECT_STAGE.SHORTLIST]: "Shortlist",
        [PROJECT_STAGE.UNDER_OFFER]: "Negociação",
        [PROJECT_STAGE.PLACEMENT]: "Contratação",
      },
      tooltip: stagesTooltip,
    },
    scale: {
      enum: scale,
    },
    companySize: "Tamanho",
  },
  modal: {
    errorSave: {
      title: "Não foi possível salvar projeto!",
      message: "Verifique as informações inseridas e tente novamente.",
      close: "Fechar Aviso",
    },
    cantSave: {
      title: "Atenção!",
      message: "Você tem certeza que deseja finalizar sem preencher toda as notas do candidato? O ranking só será gerado quando todas as notas forem atribuídas.",
      primaryBtn: "Voltar e Avaliar",
      secundaryBtn: "Salvar e finalizar",
    },
  },
  details: {
    tabs: {
      activities: "Atividades",
      corporation: "Empresa",
      market: "Mercado",
      profession: "Profissão",
      requester: "Requisitante",
      constructionGuide: "Guia de construção",
      idealProfile: "Perfil ideal",
      searchField: "C. de busca",
      screening: "Atividades",
      mapping: "Mapeamento",
      screeningGuide: "Guia de prévia",
      ranking: "Ranking",
      candidates: "Candidatos",
      interviewGuide: "Guia de Entrevista",
      placement: "Contratação",
    },
    edit: {
      save: "Salvar Alterações",
      cancel: "Cancelar",
      projectName: {
        label: "Nome do projeto",
        placeholder: "Nome do projeto",
      },
      position: {
        label: "Posição",
        placeholder: "Gerente de Gente & Gestão",
      },
      projectType: {
        label: "Tipo de Projeto",
        placeholder: "",
        enum: projectTypes,
      },
      status: {
        label: "Status",
        tooltipText: "Item não editável.",
        enum: projectStatus,
      },
      local: {
        label: "Local",
        placeholder: "São Paulo, SP, Brasil",
      },
      class: {
        label: "Classe",
        placeholder: "",
        enum: {
          CLASS_A: "A",
          CLASS_B: "B",
          CLASS_C: "C",
        },
      },
      level: {
        label: "Nível",
        placeholder: "",
        enum: ["+1", "0", "-1", "-2", "-3", "-4"],
      },
      about: {
        label: "Sobre",
        placeholder: "Resumo",
      },
      company: {
        label: "Empresa",
        placeholder: "LOFT Tecnologia",
      },
      salary: {
        label: "Salário",
        enum: {
          CLT: "CLT",
          PJ: "PJ",
        },
        from: "de",
        to: "a",
        range: "Faixa Salarial",
        placeholder1: "CLT",
        placeholder2: "R$",
        placeholder3: "0,0",
        helptext: "Preencha todos os campos",
      },
      bonus: {
        label: "Bônus",
        placeholder: "R$ 80.000,00 - 12",
      },
      annualPackage: {
        label: "Pacote Anual",
        placeholder: "R$",
        helptext: "Preencha todos os campos",
      },
      requester: {
        label: "Requisitante",
        placeholder: "Alexandre Santoro",
      },
      origin: {
        label: "Origem",
        placeholder: "",
        enum: sourceTypes,
      },
      confidentialPlacement: {
        label: "Vaga Confidencial?",
        placeholder: "",
      },
    },
    activities: {
      title: "Dados da atividade",
      add: "Adicionar",
      meetings: "Reuniões",
      processes: "Processos",
      activityData: "Dados da atividade",
      constructionMeeting: "Reunião de Construção",
      goToMeeting: "Ir para reunião",
      organizeAndAssemble: "Organizar e montar",
      organizationAndAssembling: "Organização e montagem",
      organizeAndAnalyze: "Organizar e avaliar",
      goToInterview: "Ir para entrevista",
      mappingCandidates: "Candidatos mapeados",
      schedule: {
        title: "Cronograma",
        create: "Criar cronograma",
        edit: "Editar cronograma",
        save: "Salvar cronograma",
        cancel: "Cancelar",
        duration: "Duração da Etapa",
        checkpoint: "Checkpoint",
        overdue: "Em atraso",
      },
      organizationAndAssemblingForm: {
        addFormation: "Adicionar Formação",
        addLanguages: "Adicionar Idioma",
        addExperience: "Adicionar Experiência Profissional",
        addCarreer: "Adicionar Carreira",
        addAchievement: "Adicionar Realização",
        addAmbient: "Adicionar Ambiente",
        addFamily: "Adicionar Familia",
        addPersonal: "Adicionar Pessoal",
        addRealization: "Adicionar Realização",
      },
      enum: activities,
      screenings_enum: screening_activities,
      interviews_enum: interviews_activities,
      tabsCandidatesEnum: {
        activities: "Atividades",
        candidates: "Candidatos",
        interviewGuide: "Guia de Entrevista",
        screeningGuide: "Guia da Prévia",
        priorization: "Priorização",
      },
      form: {
        labels: {
          startDate: "Data",
          consultantName: "Nome do consultor",
          activityDescription: "Descrição da atividade",
          participatingRequester: "Requisitante participante",
          candidateName: "Nome do candidato",
          fullname: "Nome completo",
          removeActivityTitle: "Remover atividade",
          removeActivityMessage: "Você tem certeza que deseja excluir esta atividade?",
          removeProcessTitle: "Remover processo",
          removeProcessMessage: "Você tem certeza que deseja excluir este processo?",
          confirmRemove: "Confirmar exclusão",
          cancelRemove: "Cancelar",
          saveInterview: "Salvar reunião",
          saveInfo: "Salvar informações",
          saveConstruction: "Salvar Montagem",
          you: "Você",
          participatingRequestor: "requisitante",
        },
      },
      screening: {
        title: "Dados da prévia",
        add: "Adicionar",
        screening: "Prévia",
        screenings: "Prévias",
        processes: "Processos",
        goToScreening: "Ir para prévia",
        enum: activities,
        checkpoint: "Checkpoint",
        evaluation: "Avaliação",
        reason: "Motivo",
        disapproved: "Reprovado",
        approvalStatusEnum: {
          YES: "Sim",
          NO: "Não",
          ON_HOLD: "On Hold",
        },
        disapprovalReasonEnum: {
          DECLINED: "Declinou",
          LOCATION: "Localização",
          UNDER_QUALIFIED: "Muito júnior",
          OVER_QUALIFIED: "Muito sênior",
          ON_HOLD: "On Hold",
          HIGH_COMPENSATION: "Salário alto",
          NO_FIT: "Sem fit",
          NO_INTEREST: "Sem interesse",
        },
        motivationEnum: {
          salary: "Salário",
          attraction: "Fatores de Atração",
          repulsion: "Fatores de Expulsão",
          retention: "Fatores de Retenção",
          short_term_incentive: "ICP (Incentivo de Curto Prazo)",
          long_term_incentive: "ILP (Incentivo de Longo Prazo)",
          other_incentive: "Outros",
          total_cash: "Total Cash",
        },
        willingToRelocateEnum: {
          YES: "Sim",
          NO: "Não",
        },
        workModelEnum: {
          REMOTE: "Remoto",
          ONSITE: "Presencial",
          HYBRID: "Híbrido",
        },
        tabs: {
          screeningGuide: {
            form: {
              labels: {
                environment: "Ambiente",
                career: "Carreira",
                personal: "Pessoal",
                earnings: {
                  title: "Pacote",
                  salary: "Salário",
                  bonus: "Bônus",
                  package: "Pacote anual",
                },
                openToRelocation: "Possibilidade de mudança?",
                placeholders: {
                  addDescription: "Adicionar descrição",
                  selectDegree: "Selecionar Curso",
                  selectSkill: "Adicionar Skill",
                },
              },
            },
          },
        },
        placeholders: {
          addPosition: "Adicionar Posição",
          addLocation: "Adicionar Localização",
          createQuestion: "Crie sua pergunta",
          select: "Selecione",
        },
        form: {
          titles: {
            currentExperience: "Experiência atual",
            realizations: "PRINCIPAIS ENTREGAS DA POSIÇÃO (REALIZAÇÕES):",
            previousExperience: "Experiência anterior relevante",
          },
          labels: {
            startDate: "Data",
            consultantName: "Nome do consultor",
            candidateName: "Nome do candidato",
            screeningsDescription: "Descrição da préevia",
            participatingRequester: "Requisitante participante",
            removeScreeningsTitle: "Remover prévia",
            removeScreeningsMessage: "Você tem certeza que deseja excluir esta atividade?",
            confirmRemove: "Confirmar exclusão",
            cancelRemove: "Cancelar",
            saveInterview: "Salvar prévia",
            saveInfo: "Salvar informações",
            you: "Você",
            selectProfile: "Selecione",
            fullName: "Nome Completo",
            submit: "Salvar atividade",
            currentMoment: "Momento atual",
            scopeofActivities: "Escopo de atividade",
            graduation: "Formação",
            experience: "Experiência",
            addExperience: "Adicionar Experiência",
            addRealization: "Adicionar Realização",
            editRealization: "Editar Realização",
            tooltipMesage: "VOCÊ EDITARÁ ESTE CONTEÚDO NO PAINEL DE PRÉVIA DE CADA CANDIDATO",
            company: "Empresa",
            yearsWorking: "Tempo de casa",
            position: "Posição",
            positionButton: "posição",
            localization: "Localização",
            realizationButton: "Realização",
            question: "Pergunta",
            years: "anos",
          },
          textareas: {
            company_context: "Qual é o contexto da empresa?",
            scope: "Qual é o escopo de seu trabalho?",
            reports_to: "Para quem reporta?",
            reported_by: "Quem reporta para esta pessoa?",
            peers: "Quem são os pares?",
            not_responsible: "Quais processos não estão sobre a sua responsabilidade?",
            responsible: "Quais processos estão sobre a sua responsabilidade?",
            team_structure: "Como se estrutura sua equipe de trabalho? Tamanho / Nível / Funções.",
          },
        },
      },
      checkpoint: {
        placeholders: {
          buttonComplete: "Concluir checkpoint",
          buttonCompleted: "Checkpoint concluido",
        },
        modal: {
          title: "Confirmar Checkpoint",
          sub: "Você tem certeza que deseja Confirmar este checkpoint?",
          confirm: "Confirmar",
          cancel: "cancelar",
        },
      },
      table: {
        date: "Data",
        recruiter: "Nome",
        activity: "Atividade",
      },
      meetingsPanel: {
        notes: "Anotações",
        projectDetails: "Detalhes do projeto",
        modal: {
          title: "VOCÊ TEM CERTEZA QUE DESEJA SAIR SEM SALVAR?",
          message:
            "Você poderá acompanhar suas atividades e rever suas anotações ao salvar seu processo",
          confirm: "Continuar editando",
          cancel: "Sair mesmo assim ",
        },
      },
      constructionPanel: {
        analysisTypes: {
          [ANALYSIS_TYPES.GOVERNANCE]: "Governança",
          [ANALYSIS_TYPES.GOVERNANCE_HISTORIC_AND_COMPOSITION]: "Sobre o Histórico e Composição",
          [ANALYSIS_TYPES.GOVERNANCE_DECISION_STRUCTURE]: "Estrutura decisória",
          [ANALYSIS_TYPES.GOVERNANCE_ORGANIZATIONAL_CHART]: "Organograma",
          [ANALYSIS_TYPES.GOVERNANCE_CULTURE]: "Cultura / Modelo de trabalho e insentivos",
          [ANALYSIS_TYPES.STRATEGY]: "Estratégia",
          [ANALYSIS_TYPES.STRATEGY_BUSINESS]: "Negócio da Empresa",
          [ANALYSIS_TYPES.STRATEGY_CORPORATION_MOMENT]: "Momento da Empresa",
          [ANALYSIS_TYPES.STRATEGY_MOMENT]: "Momento da Estratégia",
          [ANALYSIS_TYPES.EXECUTION_CONDITIONS]: "Condições de Execução",
          [ANALYSIS_TYPES.EXECUTION_CONDITIONS_TEAM_MATURITY]: "Maturidade da Equipe",
          [ANALYSIS_TYPES.EXECUTION_CONDITIONS_POSITION_MANDATE]: "Poder e clareza de mandato da posição",
          [ANALYSIS_TYPES.EXECUTION_CONDITIONS_PROCESSES_MATURITY]: "Maturidade de processos e sistemas",
          [ANALYSIS_TYPES.EXECUTION_CONDITIONS_BUDGET_AVAILABILITY]: "Disponibilidade de orçamentos",
          [ANALYSIS_TYPES.IDEAL_PROFILE]: "Perfil Ideal",
        },
        analysisTypesTooltips: {
          [ANALYSIS_TYPES.GOVERNANCE_HISTORIC_AND_COMPOSITION]: "Investigue a origem da<br/>empresa. Qual é o tipo de corporação?<br/> Quem são os donos?<br/> Qual é o perfil dos acionistas.",
          [ANALYSIS_TYPES.GOVERNANCE_DECISION_STRUCTURE]: "Há conselho? Se há, defina. Quais são os reportes de estruturas? Existem estruturas de conselhos e comitês Liste os tipos de estruturas de empresa. No caso de ser uma empresa multinacional, defina: estruturas regionais e globais de report. Em caso de empresa familiar = CEO dono, defina a estrutura hierárquica.",
          [ANALYSIS_TYPES.GOVERNANCE_ORGANIZATIONAL_CHART]: "Defina o organograma básico da empresa.<br/>Defina o organograma da área da posição.<br/>Entorno da Posição (Ex.: dois Ns para cima e todos os pares)",
          [ANALYSIS_TYPES.GOVERNANCE_CULTURE]: "<strong>Investigue e defina se a empresa é:</strong><br/>Orientada a resultados<br/>Orientada a métodos e processo<br/>Orientada a inovação e projetos<br/>Orientada a relações políticas.<br/><strong>Incentivos:</strong><br/><strong>Invertigue e defina se os incentivos da empresa são:</strong><br/>Mais orientados a remuneração fixa<br/>Mais orientados a remuneração variável.<br/>Mais orientados a carreira.<br/><strong>Modelos de trabalho</strong><br/>Flexibilidade / híbrido / remoto / presencial",
          [ANALYSIS_TYPES.STRATEGY_BUSINESS]: "Defina o setor / Quais são os principais produtos ou serviços? / Há um mix de receita? / Quais são os mercados clientes? / Qual é o perfil do público consumidor? / Investigue os processos internos / Quais são os fornecedores? / Quais são os concorrentes? / Faça uma análise da cadeia de valor",
          [ANALYSIS_TYPES.STRATEGY_CORPORATION_MOMENT]: "Posicionamento (qual é o foco da empresa e como ela é vista no mercado?) Momento no mercado (cresce? se mantêm?) / Quais os riscos de mercado? / Quais os diferenciais? / Quais as tendências? / Quais são os eventos de transformação?",
          [ANALYSIS_TYPES.STRATEGY_MOMENT]: "O que acontece de mais relevante na empresa? Está mudando de estratégia? Vai mudar? Qual é o nível de e maturidade (já mudou / está mudando / vai mudar)? Quais são os principais projetos? Quais são as prioridades? Existem interdependências? Quais?",
          [ANALYSIS_TYPES.EXECUTION_CONDITIONS_TEAM_MATURITY]: "São as estruturas, autoridades e recursos disponíveis. É toda condição estrutural que a empresa possui e o será disponibilizado para o novo executivo. As Condições de Execução podem ser divididas em 4 grandes pilares: Maturidade da Equipe Entenda o tamanho da equipe disponível. Qual o nível de qualificação dos profissionais? Quais são os reports diretos? Exemplo de análise: Empresa tem um nível de qualificação e de engajamento da equipe acima da média. A equipe da área será pequena, contando inicialmente com uma posição de Coordenador e uma outra de Analista. Reportará direto ao Comitê Executivo.",
          [ANALYSIS_TYPES.EXECUTION_CONDITIONS_POSITION_MANDATE]: "Quais são as interfaces principais da posição? Existem barreiras políticas e culturais? Existem estruturas de apoio? / Defina-as.",
          [ANALYSIS_TYPES.EXECUTION_CONDITIONS_PROCESSES_MATURITY]: "Os processos da área já estão consolidados? Terá apoio de outras áreas para realização de suas atribuições? Possui sistemas instituídos? Precisa modificar algum processo? Os processos estão maduros o suficiente?",
          [ANALYSIS_TYPES.EXECUTION_CONDITIONS_BUDGET_AVAILABILITY]: "Qaul é o cenário financeiro da equipe que o profissional vai encontrar?",
        },
        tabLabels: {
          governance: "São os rituais e limites de atuação da empresa. É a maneira como a empresaé dirigida. São as definições de regras, processos, políticas que são os direcionadores para o alcance dos objetivos e negócios.",
          strategy: "É o conjunto de ações que são planejadas com objetivos específicos dentro da organização. Estratégia é tudo o que descreve o negócio e o mercado que a empresa atua.",
          execution_conditions: "São as estruturas, autoridades e recursos disponíveis. É toda condição estrutural que a empresa possui e o será disponibilizado para o novo executivo.",
        },
        forms: {
          extensions: "Extensões",
          field: "Campo de Estudo",
          professionalExperience: "Experiência Profissional",
          placeholders: {
            professionalExperience: "ex.: Experiência em empresas nacionais de capital fechado",
            field: "ex.: Administração",
            language: "ex.: Português nativo ou fluente",
          },
          tags: {
            idealProfile: {
              placeholder: "Adicione tags de {Title}",
              titles: "Tags de {Title}",
              titlePath: ["Experiência Profissional", "Realização"],
              titleDiv: "Tags de {Title}",
              tagDesciption: ["Clique em", "para ocultar ou exibir a tag na apresentação.", "pra excluir a tag."],
            },
          },
          enums: {
            academic: {
              degree: {
                [DEGREE.EMPTY]: "",
                [DEGREE.BACHELOR]: "Graduação",
                [DEGREE.POST_BACHELOR]: "Pós graduação",
                [DEGREE.MBA]: "MBA",
                [DEGREE.MASTER]: "Mestrado",
                [DEGREE.DOCTORATE]: "Doutorado",
                [DEGREE.POST_DOCTORATE]: "Pós Doutorado",
                [DEGREE.EXTRA_CURRICULAR_COURSE]: "Certificações",
              },
              institution: {
                [INSTITUTION.EMPTY]: "",
                [INSTITUTION.INDIFFERENT]: "Indiferente",
                [INSTITUTION.PREFERENTIALLY_FIRST_LINE]: "Preferencialmente em instituição de primeira linha",
                [INSTITUTION.MANDATORILY_FIRST_LINE]: "Obrigatoriamente em instituição de primeira linha",
              },
              // extension: {
              //   [EXTENSION_TYPE.EMPTY]: "&nbsp;",
              //   [EXTENSION_TYPE.POST_BACHELOR]: "Pós graduação",
              //   [EXTENSION_TYPE.MBA]: "MBA",
              // },
            },
          },
        },
        tabs: {
          governance: "Governança",
          strategy: "Estratégia",
          executionConditions: "Condições de execução",
          idealProfile: "Perfil ideal",
          lineOfBusiness: "Campo de busca",
        },
        consultantOpinion: "Parecer do consultor",
        addAnalysis: "adicionar análise",
        editInsight: "Editar insight",
        editAnalysis: "Editar análise",
        removeAnalysis: "Remover análise",
        editorPlaceholder: "TEXTO_",
        addLineOfBusiness: "adicionar",
        career: "Carreira:",
        workEnvironment: "Ambiente:",
        family: "Família:",
        personal: "Pessoal:",
        addRealization: "adicionar realização",
        salary: {
          package: "Pacote",
          packageAnnual: "Pacote anual",
          bonus: "Bônus",
          title: "Salário",
        },
        profileForm: {
          placeholders: {
            career: "ex.: Experiência na área comercial",
            workEnvironment: "ex.: Trabalhar em ambiente empreendedor",
            family: "ex.:  Residir em Belo Horizonte",
            personal: "ex.: Disponibilidade para viagens frequentes",
          },
        },
        modal: {
          title: "VOCÊ TEM CERTEZA QUE DESEJA SUSPENDER ESTE PROJETO?",
          message:
            "Você pode reverter esta suspensão em até 60 dias. Após esta data, o projeto será automaticamente cancelado e não poderá mais ser desenvolvido.",
          confirm: "CONFIRMAR SUSPENSÃO",
          cancel: "CANCELAR",
        },
      },
    },
    constructionGuide: {
      form: {
        labels: {
          yes: "Sim",
          no: "Não",
          saveAll: "Salvar todas as alterações",
          addQuestion: "adicionar nova pergunta",
          answer: "Responder questão",
          edit: "Editar título da questão",
        },
        placeholders: {
          typeAnswer: "Digite sua resposta aqui",
          position: "ex.: Gerente de Gente & Gestão",
          newQuestion: "Cria sua pergunta",
        },
      },
      tabs: {
        company: "Empresa",
        position: "Posição",
        strategy: "Estratégia",
        governance: "Governança",
        profile: "Perfil",
        executionConditions: "Condições de execução",
        achievements: "Realizações",
        functionalScope: "Escopo funcional",
      },
      aboutProject: "Sobre o projeto",
      aboutMarket: " Sobre o mercado",
      aboutProfession: "Sobre a profissão",
      aboutCompany: "Sobre a empresa",
      description: "Descrição",
      tooltips: {
        [ATTRIBUTES_TITLES.COGNITIVE_INTELLIGENCE]:
          "analítico, lógico, curioso, aprende rápido, reconhece causa e efeito e etc.",
        [ATTRIBUTES_TITLES.EMOTIONAL_INTELLIGENCE]:
          "sensível, afetivo, habilidoso, orientado a pessoas, sensato, calmo, auto conhecimento, adaptável e etc.",
        [ATTRIBUTES_TITLES.SPIRITUAL_INTELLIGENCE]:
          "reflexivo, visionário, inspirador, sonhador, legado, engajador e etc.",
        [ATTRIBUTES_TITLES.ENERGY]:
          "Fazedor, implementador, pragmático, faz acontecer, multitarefa, auto volume, intensidade, frequência e etc.",
        [ATTRIBUTES_TITLES.MATURITY]:
          "Experiente, resiliente, sensível, sabedoria, vivência, equilibrado, paciente e etc.",
        [ATTRIBUTES_TITLES.POWER]: "Pronto, grande, completo e etc",
        [CULTURAL_MATCH_TITLES.PRACTICAL]:
          "Tem grande capacidade de execução, muita energia física e prefere a ação ao debate. É um implementador, aprende fazendo e não gosta muito de reuniões longas e expositivas. É ativo, rápido, impulsivo e pode ser visto como agressivo. É orientado a resultado e sempre competitivo. Quando exacerbado tende a ser “trator” e pouco sensível com as pessoas.Sente-se bem em situações e ambientes onde existam metas, desafios e competição, tanto no plano pessoal quanto de mercado.",
        [CULTURAL_MATCH_TITLES.LOGICAL]:
          "Tem grande capacidade de organização. Sabe trabalhar com métodos e processos e gosta de ter todas informações necessárias para fazer sua análise e depois tirar suas conclusões. É constante, preciso, conservador, disciplinador e pode ser percebido como arbitrário.Quando exacerbado pode ser pouco criativo e pensar “dentro da caixa”. Tende a gostar de ambientes estruturados e hierárquicos.",
        [CULTURAL_MATCH_TITLES.REFLEXIVE]:
          "Possui grande poder de abstração sendo capaz de diagnosticar e propor soluções complexas fora do senso comum. Gosta de pensar no futuro e trabalhar na construção de novos cenários. É planejador, estrategista, disruptivo e pode ser visto como distante. Quando exacerbado pode “viajar para o mundo da lua” e perder o contato com o cotidiano. Gosta de ambientes de transformação e estruturação onde haja espaço para projetos inovadores e criativos.",
        [CULTURAL_MATCH_TITLES.AFFECTIVE]:
          "Possui grande habilidade para construir e cuidar de seus times e conexões. Baseia suas ações e decisões fortemente influenciado pelas suas relações pessoais. “Com quem” fazer algo, é tão ou mais importante do que “o que”, “o por que” ou “o como” fazer. É entusiasmado, amistoso e diplomático. Quando exacerbado pode ter dificuldade de tomar decisões se estas afetarem pessoas do seu entorno. Gosta de ambientes onde a amizade, a familiaridade e a cumplicidade são valorizadas.",
      },
      enums: {
        [STRATEGY_TITLES.COMPANY_MOMENT]: {
          [COMPANY_MOMENTS.GROWING]: "Crescendo",
          [COMPANY_MOMENTS.SHRINKING]: "Reduzindo",
          [COMPANY_MOMENTS.INVESTING]: "Investindo",
          [COMPANY_MOMENTS.STABLE]: "Estável",
        },
        [STRATEGY_TITLES.STRATEGY_STATUS]: {
          [STRATEGY_STATUSES.ACTIVE]: "Ativa",
          [STRATEGY_STATUSES.INATIVE]: "Inativa",
        },
        [GOVERNANCE_TITLES.GOVERNANCE_INSTANCES]: {
          [GOVERNANCE_INSTANCE.COMMITTEE]: "Comitês",
          [GOVERNANCE_INSTANCE.ADVISORY_COUNCIL]: "Conselhos consultivos",
          [GOVERNANCE_INSTANCE.FAMILY_COUNCIL]: "Conselho familiar",
        },
        [GOVERNANCE_TITLES.COMPANY_CHART]: {
          [COMPANY_CHARTS.COUNCILS]: "Conselhos",
          [COMPANY_CHARTS.COMMITTEES]: "Comitês",
          [COMPANY_CHARTS.CEO]: "CEO",
          [COMPANY_CHARTS.C_LEVEL]: "C-level",
          [COMPANY_CHARTS.DOWN_LEVEL]: "Nível abaixo",
        },
        [GOVERNANCE_TITLES.BOARD_TYPE]: {
          [BOARD_TYPES.CONTROLLER]: "Controlador",
          [BOARD_TYPES.CAPITAL]: "Capital pulverizado",
        },
        [ATTRIBUTES_TITLES.COGNITIVE_INTELLIGENCE]: scale,
        [ATTRIBUTES_TITLES.EMOTIONAL_INTELLIGENCE]: scale,
        [ATTRIBUTES_TITLES.SPIRITUAL_INTELLIGENCE]: scale,
        [ATTRIBUTES_TITLES.ENERGY]: scale,
        [ATTRIBUTES_TITLES.MATURITY]: scale,
        [ATTRIBUTES_TITLES.POWER]: scale,
        [CULTURAL_MATCH_TITLES.PRACTICAL]: scale,
        [CULTURAL_MATCH_TITLES.LOGICAL]: scale,
        [CULTURAL_MATCH_TITLES.REFLEXIVE]: scale,
        [CULTURAL_MATCH_TITLES.AFFECTIVE]: scale,
        [EXECUTION_CONDITIONS_TITLES.PROJECT_STAGE]: {
          [PROJECT_STAGES.DIAGNOSTICS_WITH_CONSULTING]: "No Diagnóstico com consultoria",
          [PROJECT_STAGES.DIAGNOSTICS_WITHOUT_CONSULTING]: "No Diagnóstico sem Consultoria",
          [PROJECT_STAGES.EXPLANATION]: "No Convencimento/ Explicação",
          [PROJECT_STAGES.IMPLEMENTATION_WITH_CONSULTING]: "Em Implementação sem Consultoria",
          [PROJECT_STAGES.IMPLEMENTATION_WITHOUT_CONSULTING]: "Em Implementação sem Consultoria",
          [PROJECT_STAGES.FAILED_IMPLEMENTATION]: "Em Implementação com falhas",
        },
      },
      questions: {
        EMPTY: "",
        [STRATEGY_TITLES.REVENUES_AND_INCOMINGS_SOURCES]:
          "Quais são as formas de receita e como o faturamento é composto?",
        [STRATEGY_TITLES.MAIN_PRODUCT]: "Qual é o principal produto?",
        [STRATEGY_TITLES.SECONDARY_PRODUCT]: "Quais são os produtos secundários?",
        [STRATEGY_TITLES.GEOGRAPHIC_PRESENCE]: "Qual é a presença geográfica?",
        [STRATEGY_TITLES.COMPANY_MOMENT]: "Qual é o momento da empresa?",
        [STRATEGY_TITLES.BUSINESS_PLAN]: "Qual é o plano de negócio?",
        [STRATEGY_TITLES.STRUCTURING_PROJECTS]: "Existem projetos estruturantes?",
        [STRATEGY_TITLES.ACQUISITION_PROJECTS]: "Existem projetos de aquisições?",
        [STRATEGY_TITLES.ASSET_SALES_PROJECTS]: "Existem projetos de vendas de ativos?",
        [STRATEGY_TITLES.MARKET_SCENARIO]: "Qual é o cenário atual do mercado?",
        [STRATEGY_TITLES.DEFINED_STRATEGY]:
          "A estratégia já foi definida e é de conhecimento de todos?",
        [STRATEGY_TITLES.STRATEGY_STATUS]: "Qual é o status da estratégia?",
        [GOVERNANCE_TITLES.PARTNERSHIP_STRUCTURE]: "Qual é o quadro societário da empresa?",
        [GOVERNANCE_TITLES.ADMINISTRATIVE_BOARD]: "Existe um conselho administrativo?",
        [GOVERNANCE_TITLES.MEMBERS_BOARD]: "Quem são os membros?",
        [GOVERNANCE_TITLES.BOARD_TYPE]: "O conselho administrativo se apresenta de que forma?",
        [GOVERNANCE_TITLES.INDEPENDENT_MEMBERS]: "Possui membros independentes no conselho?",
        [GOVERNANCE_TITLES.GOVERNANCE_INSTANCES]: "Quais são as instâncias de governança?",
        [GOVERNANCE_TITLES.COMPANY_CHART]: "Desenhe o organograma da empresa.",
        [GOVERNANCE_TITLES.MANAGEMENT_MODEL]: "Qual é o modelo de Gestão?",
        [GOVERNANCE_TITLES.PROCESS_INTEGRATION]:
          "Existe integração na gestão dos processos e das pessoas (Gestão Integrada de Processos e Pessoas)?",
        [GOVERNANCE_TITLES.POLICIES_AND_PROCESSES]:
          "Quais são as políticas, processos e escopos de gestão da empresa?",
        [GOVERNANCE_TITLES.ROLES_AND_RESPONSIBILITIES]:
          "Os papéis e responsabilidades dos escopos de liderança estão definidos e claros para todos?",

        [PROFILE_TITLES.POSITION_NAME]: "Nome da Posição:",
        [PROFILE_TITLES.ACADEMIC_BACKGROUND]: "Formação Acadêmica desejada:",
        [PROFILE_TITLES.WORK_EXPERIENCE]: "Experiência Profissional / Tempo de experiência:",
        [PROFILE_TITLES.MOTIVATION_PACKAGE]: "Motivação - Pacote:",
        [PROFILE_TITLES.COMPANY_POLICY]:
          "Qual é a política da empresa vs. mercado (análise de remuneração)?",
        [PROFILE_TITLES.ATTRIBUTES]: "Atributos:",
        [PROFILE_TITLES.CULTURAL_MATCH]: "Match Cultural",
        [PROFILE_TITLES.REALIZATIONS]: "Realizações da pessoa",
        [ATTRIBUTES_TITLES.COGNITIVE_INTELLIGENCE]: "Inteligência Cognitiva",
        [ATTRIBUTES_TITLES.EMOTIONAL_INTELLIGENCE]: "Inteligência Emocional",
        [ATTRIBUTES_TITLES.SPIRITUAL_INTELLIGENCE]: "Inteligência Espiritual",
        [ATTRIBUTES_TITLES.ENERGY]: "Energia",
        [ATTRIBUTES_TITLES.MATURITY]: "Maturidade",
        [ATTRIBUTES_TITLES.POWER]: "Potência",
        [CULTURAL_MATCH_TITLES.PRACTICAL]: "Prático",
        [CULTURAL_MATCH_TITLES.LOGICAL]: "Lógico",
        [CULTURAL_MATCH_TITLES.REFLEXIVE]: "Reflexivo",
        [CULTURAL_MATCH_TITLES.AFFECTIVE]: "Afetivo",
        [EXECUTION_CONDITIONS_TITLES.PROJECT_STAGE]: "Em que fase está o projeto?",
        [EXECUTION_CONDITIONS_TITLES.PROJECT_IS_KNOWN]:
          "O projeto é conhecido (e está claro) por todos os principais stakeholders?",
        [EXECUTION_CONDITIONS_TITLES.COMPANY_IMPACT]:
          "Como a entrega se conecta ou impacta na estratégia e resultado da empresa no médio prazo (2, 3 anos)?",

        [POSITION_STRATEGY_TITLES.TIME_ALLOCATION]:
          "Como você enxerga a distribuição de tempo do executivo nas 3 agendas (ciclo do diagnóstico - conhecimento - implementação)?",
        [POSITION_STRATEGY_TITLES.EXECUTIVE_PEERS_LEVEL]:
          "Sobre a governança da empresa. Quais são os pares e o nível dos pares do executivo?",
        [POSITION_STRATEGY_TITLES.EXECUTIVE_REPORTING]: "Para quem o executivo reporta?",
        [POSITION_STRATEGY_TITLES.MANAGER_PROFILE]:
          "Qual é o perfil do gestor (dono, executivo com tempo de empresa, recém chegado de mercado, qual a origem, como veio)?",
        [POSITION_STRATEGY_TITLES.BUDGET]: "Qual é o orçamento/verba para realizar o trabalho?",
        [POSITION_STRATEGY_TITLES.APPROVAL_AUTHORITY]:
          "Qual é a alçada/autonomia de aprovação (Ex.: Executivo quer aprovar um investimento relevante (R$ 50 milhões).)?",
        [POSITION_STRATEGY_TITLES.APPROVAL_CYCLE]:
          "Qual é o ciclo de aprovações na governança na qual ele deverá transitar?",
        [REALIZATIONS_TITLES.MAIN_DELIVERY]:
          "Qual a principal entrega que o executivo deverá efetuar em X tempo (meses, ano..) de casa?",
        [REALIZATIONS_TITLES.BUSINESS_DEVELOPMENTS]:
          "Quais são os possíveis desdobramentos no negócio derivados deste projeto?",
        [REALIZATIONS_TITLES.STRUCTURE_DEVELOPMENTS]:
          "Quais são os possíveis desdobramentos na estrutura organizacional e de governança derivados deste projeto?",
        [REALIZATIONS_TITLES.NEED_ADAPTATION]:
          "Existe necessidade de adequação da estrutura do executivo para o projeto seja entregue? (pessoas, qualificação, sistemas, governança, escopo, etc.)",
        [FUNCTIONAL_SCOPE_TITLES.POSITION_ACTIVITIES]:
          "Quais são as atividades /processos da posição?",
        [FUNCTIONAL_SCOPE_TITLES.POSITION_REPORT]: "Quem reporta para esta vaga?",
        [FUNCTIONAL_SCOPE_TITLES.OPERATIONAL_ROUTINES]:
          "Quais as principais rotinas operacionais que fazem parte do dia a dia do executivo?",
        [FUNCTIONAL_SCOPE_TITLES.ROUTINES_NEED_ADJUSTMENT]:
          "Essas rotinas funcionam ou precisam de algum ajuste?",
        [FUNCTIONAL_SCOPE_TITLES.RELEVANT_DELIVERIES]:
          "Quais outras entregas são consideradas relevantes para o primeiro ano (atividades essenciais para validação de perfil executivo)?",
        [FUNCTIONAL_SCOPE_TITLES.REPORTER_POSITION]: "Qual é o nível de quem reporta (cargo)?",
        [FUNCTIONAL_SCOPE_TITLES.TEAM_SIZE]: "Qual é o tamanho do time?",
        [FUNCTIONAL_SCOPE_TITLES.TEAM_TRANSFORMATION_AGENDA]:
          "Tem que mudar alguém (agenda de transformação de time)?",
        [FUNCTIONAL_SCOPE_TITLES.PROCESS_TRANSFORMATION_AGENDA]:
          "Tem que mudar processos e/ou sistemas ( agenda estruturante e/ou de transformação)?",
        [FUNCTIONAL_SCOPE_TITLES.OPEN_VACANCIES]: "Tem vaga aberta?",
        [FUNCTIONAL_SCOPE_TITLES.RELEVANT_STRUCTURE]: "Qual é a estrutura relevante?",
        [FUNCTIONAL_SCOPE_TITLES.TEAM_PROFILE]: "Qual é o perfil do time?",
      },
      idealProfile: {
        realization: "Realização",
        realizations: {
          title: "Realizações",
          desiredAttribute: "Atributo Esperado",
          desiredCulturalMatch: "Match Esperado",
        },
        experiencePlaceholders: {
          0: "Experiência em empresas nacionais de capital fechado com governança centralizada.",
          1: "Experiência em empresas que passara por eventos de liquidez.",
          2: "Desenvolvimento de agendas de transformação e estruturante",
        },
        realizationsPlaceholders: {
          0: "Ter realizado com sucesso o projeto de crescimento acelerado de empresa.",
          1: "Ter resultados satisfatórios em processo de profissionalização da companhia.",
          2: "Liderança em evento de liquidez dentro da empresa.",
        },
        headquartersLocation: "Localização da sede",
        companySize: "Tamanho",
        consultantOpinion: "Parecer do consultor",
        companyProfile: "Perfil de empresa",
        attributes: "Atributos",
        culturalMatch: "Match Cultural",
        motivation: "Motivação - Fatores de atração",
        profile: "Perfil ideal - Persona",
        tabNext: "Próximo",
        tabPrevious: "Anterior",
        academicFormation: "Formação Acadêmica",
        experience: "Experiência Profissional",
        experienceShort: "Experiência",
        candidate: "Candidato",
        tooltip: {
          achievements: "Realizações",
          attributes: "Atributos",
          company: "A empresa",
          companyProfile: "Perfil de empresa",
          culturalMatch: "Match Cultural",
          executionConditions: "Condições de Execução",
          governance: "Governança",
          motivation: "Motivação",
          profile: "Perfil Ideal",
          strategy: "Estratégia",
          export: "Exportar",
          exportVersionIMUA: "Exportar Versão IMUA",
          exportVersionImuaBeta: "Exportar IMUA Beta",
          exportVersionBullhorn: "Exportar Versão Antiga",
          shouldShowMore: "Ver mais...",
        },
        salary: "Salário (target ideal):",
        bonus: "Bônus:",
        package: "Pacote",
        attributesKeys: {
          cognitiveIntelligence: "Inteligência Cognitiva",
          emotionalIntelligence: "Inteligência Emocional",
          spiritualIntelligence: "Inteligência Espiritual",
          energy: "Energia",
          maturity: "Maturidade",
        },
        culturalMatchKeys: {
          affective: "afetivo",
          logical: "lógico",
          practical: "prático",
          reflexive: "reflexivo",
        },
        addSubItem: "Adicionar {user}",
      },
      ranking: {
        weights: "Pesos",
        weight: "Peso",
        rankingWeightsSucessPart1: "A soma dos pesos",
        rankingWeightsSucessPart2: "deve totalizar 100%",
        saveWeights: "Salvar pesos",
        saveRanking: "Salvar ranking",
        addToInterviews: "Aprovar para entrevistas",
        removeFromInterviews: "Remover das entrevistas",
        addToShortlist: "Aprovar para shortlist",
        approvedToInterviews: "Aprovado para entrevista?",
        approvedReason: "Motivo",
        selectPriorityClassification: "Selecione ou modifique a classificação de prioridade do candidato",
        removeFromShortlist: "Remover da shortlist",
        openInterviewDetails: "Detalhes da entrevista",
        openScreeningDetails: "Detalhes da prévia",
        hideDetails: "Ocultar detalhes",
        screeningInfo: "Informações da prévia",
        removerCandidate: "Remover",
        reassess: "Reavaliar",
        requirementsMet: "Atende ao perfil ideal?",
        recruiterObservations: "Obsevações do Consultor",
        emptyObservations: "Sem observações",
        empty: "Não informado",
        salaryRange: "Faixa Salarial",
        bonus: "Bônus",
        annualPackage: "Pacote Anual",
        addDescription: "Adicionar descrição",
        language: "Idioma",
        proficiency: "Proficiência",
        experience: "Experiência",
        relocationAvailability: "Possibilidade de mudança?",
        contractType: "Modelo de trabalho",
        candidateNotFound: "Nenhum candidato encontrado nesta busca",
        details: {
          title: "detalhes da entrevista",
          realizations: "Realizações",
          attibutes: "Atributos",
          culturalMatch: "Match Cultural",
          motivation: "Motivação",
          consultantOpinion: "Parecer do consultor:",
          candidateAvaliation: "Avaliação geral do candidato",
          another: "Outros",
          leaveCompany: "O que te faria sair de sua empresa atual?",
          stayInCompany: "O que faria o candidato permanecer na empresa atual?",
          companyAttraction: "O que faria o candidato ir para uma nova empresa?",
        },
      },
      searchField: {
        title: "Linha de Negócio",
        forms: {
          placeholders: {
            buttonAddLine: "adicionar linha de negócio",
            buttonAddCompany: "adicionar empresa",
          },
        },
      },
    },
    searchField: {
      addBusinessLine: "Adicionar Linha de Negócio",
      searchFieldInputHint: "Insira palavras-chave que representem o mercado ou produto que você busca",
      termInputPlaceholer: "Palavras chave",
      termNotFound: "não foi encontrado termos para a busca",
      search: "Pesquisar",
      hideTermsList: "Ocultar lista",
      showTermsList: "Exibir lista",
    },
    options: {
      suspendProject: "Suspender projeto",
      activateProject: "Ativar projeto",
      cancelProject: "Cancelar projeto",
      modalSuspend: {
        title: "Você tem certeza que deseja suspender este projeto?",
        resume: "Você pode reverter esta suspensão em até 60 dias. Após esta data, o projeto será automaticamente cancelado e não poderá mais ser desenvolvido.",
        buttons: {
          confirm: "Confirmar suspensão",
          cancel: "Cancelar",
        },
      },
      modalCancel: {
        title: "Você tem certeza que deseja cancelar este projeto?",
        resume: "Esta ação é definitiva e não poderá ser revertida.",
        buttons: {
          confirm: "Confirmar cancelamento",
          cancel: "Cancelar",
        },
      },
    },
    modalPresence: {
      title: "Outro usuário está editando este arquivo",
      messageBegin: "Espere",
      messageEnd: "fechar o arquivo e tente novamente mais tarde",
    },
  },
  construction: {
    titles: {
      construction: "Construção",
      academic: "Formação Acadêmica",
      language: "Idioma",
      experience: "Experiências Profissionais",
      realizations: "Realizações",
      attributes: "Atributos",
      culturalMatch: "Match Cultural",
      motivation: "Motivação",
      package: "Pacote",
      career: "Carreira",
      environment: "Ambiente",
      personal: "Pessoal",
      meetProfile: "Atende ao perfil ideal",
      screening: "Prévia",
      interview: "Entrevista",
    },
    labels: {
      originalTitle: "Título original",
      originalRestore: "Restaurar original",
      modalRestoreOriginalTitle: "Atenção!",
      modalRestoreOriginalResume: "Você realmente deseja restaurar o nome para sua versão original?",
      modalClearTermsTitle: "Você tem certeza que deseja limpar seu campo de busca?",
      modalClearTermsContent: "Esta ação excluirá todos as linhas de negócio",
      modalClearTermsContentComplement: "listadas em sua busca.",
      searchingRelatedTerms: "Buscando linhas de negócio relacionadas...",
      defined: "Definido",
      toDefine: "A definir",
      salary: "Salário",
      icp: "ICP (Incentivo de Curto Prazo)",
      icpShort: "ICP",
      ilp: "ILP (Incentivo de Longo Prazo)",
      ilpShort: "ILP",
      others: "Outros",
      otherIncentives: "Outros incentivos",
      totalCash: "Total Cash",
      realization: "Realização",
      description: "Descrição",
      searchCorporations: "Buscar empresas",
      searchCorporation: "Buscar empresa",
      corporationsFound: "empresas encontradas",
      corporations: "empresas",
    },
    placeholders: {
      salary: "Ex: CLT R$ 45.000,00",
      icp: "Ex: Bônus trimestral, oportunidade de crescimento profissional e comissões por projetos específicos.",
      ilp: "EX: Participação nos lucros da empresa e Stock options",
      others: "Ex: Bônus trimestral e comissões por projetos específicos:",
      totalCash: "EX: R$ 45.000,00",
    },
    fill: {
      empty: "Não preenchido",
      editing: "Parcialmente preenchido",
      complete: "Preenchido",
    },
    modal: {
      titles: {
        warning: "Atenção",
        wantToSave: "Tem certeza que deseja alterar?",
        wantToFinish: "Tem certeza que deseja finalizar?",
      },
      messages: {
        errorAcademic: "Você precisa adicionar uma Formação Acadêmica ou Idioma para conseguir fazer a avaliação na prévia",
        errorExperience: "Você precisa adicionar uma Experiência para conseguir fazer a avaliação na prévia",
        constructionAfterSave: "Lembre-se: ao alterar, inserir ou excluir campos na construção, você afetará a avaliação das etapas seguintes.",
        constructionAfterFinish: "Lembre-se: ao alterar, inserir ou excluir campos na construção, você afetará a avaliação das seguintes etapas: {screening}{interview}.",
      },
      buttons: {
        closeAndExit: "Fechar e Sair",
        continueEditing: "Continuar Editando",
        save: "Salvar",
        continue: "Continuar",
        finish: "Finalizar",
        cancel: "Cancelar",
      },
      fillOut: {
        title: "Preencha o perfil ideal",
        message: "Para criar todos os parâmetros de avaliação, é fundamental preencher todos os campos do perfil ideal.",
        buttons: {
          primary: "Continuar editando",
          secondary: "Finalizar mesmo assim",
        },
      },
    },
    buttons: {
      confirmRestore: "Confirmar",
      cancelRestore: "Cancelar",
      clearAll: "Limpar tudo",
    },
    pdf: {
      [ConstructionEnum.FRONT_COVER]: "Capa Flow",
      [ConstructionEnum.SUMMARY]: "Índice",
      [ConstructionEnum.COMPANY]: "Sobre a Empresa",
      [ConstructionEnum.GOVERNANCE]: "Governança",
      [ConstructionEnum.STRATEGY]: "Estratégia",
      [ConstructionEnum.EXECUTION_CONDITIONS]: "Condições de Execução",
      [ConstructionEnum.REALIZATIONS]: "Realizações",
      [ConstructionEnum.IDEAL_PROFILE_TAGS]: "Perfil Ideal - Persona",
      [ConstructionEnum.IDEAL_PROFILE]: "Perfil Ideal - Persona",
      [ConstructionEnum.COMPANY_PROFILE]: "Perfil de Empresa",
      [ConstructionEnum.ATTRIBUTES]: "Atributos",
      [ConstructionEnum.CULTURAL_MATCH]: "Match Cultural",
      [ConstructionEnum.MOTIVATION]: "Motivação",
      [ConstructionEnum.SEARCH_FIELD]: "Campo de Busca",
      [ConstructionEnum.TIMELINE]: "Cronograma",
      [ConstructionEnum.BACK_COVER]: "Contra Capa Flow",
      [ShortlistEnum.ADHERENCE_TABLE]: "Tabela de aderência",
      [ShortlistEnum.PROCESS_FUNNEL]: "Funil do processo",
      [ShortlistPersonEnum.USER_CARD_PERSON]: "Análise dos candidatos",
      [ShortlistPersonEnum.USER_ABOUT]: "Trajetória Profissional",
      [ShortlistPersonEnum.USER_ACADEMIC]: "Formação acadêmica",
      [ShortlistPersonEnum.USER_EXPERIENCE]: "Experiência",
      [ShortlistPersonEnum.USER_REALIZATIONS]: "Realizações",
      [ShortlistPersonEnum.USER_ATTRIBUTES]: "Atributos",
      [ShortlistPersonEnum.USER_CULTURAL_MATCH]: "Match cultural",
      [ShortlistPersonEnum.USER_MOTIVATION]: "Motivação",
      [ShortlistPersonEnum.USER_EVALUATION]: "Avaliação",
      [ShortlistPersonEnum.USER_EVALUATION_FINAL]: "Avaliação",
    },
  },
  mapping: {
    saveCandidates: "Salvar lista de candidatos",
    drag: "Arraste ou faça upload da lista de candidatos",
    displayAll: "Exibir todos",
    displaySelectedCandidates: "Exibir apenas selecionados",
    filters: {
      schooling: "Formação",
      salaryRange: "Faixa salarial",
    },
    list: {
      select: {
        placeholder: "Ação em massa",
        options: {
          addToProject: "Adicionar a projeto",
        },
      },
      sort: {
        placeholder: "Ordenar por",
        options: {
          priority: "Priorização",
          name: "Ordem alfabética",
          updated_at: "Data de atualização",
        },
      },
      total: {
        candidate: "Nenhum candidato | 1 candidato | {count} candidatos",
      },
    },
    card: {
      addCandidate: "Adicionar candidato",
      added: "Adicionado",
      priority: "Priorizar candidato",
      remove: "Remover",
      excludeCandidate: "Excluir candidato",
      tooltip: "Prévia/Entrevista realizadas",
      placementCandidate: "Candidato Placement",
    },
    modal: {
      title: {
        uploaded: "Você fez upload da lista de candidatos",
        candidatesAdded: "{amount} candidatos adicionados ao projeto",
        candidatesNotAdded: "Você não adicionou candidatos ao projeto",
      },
      buttons: {
        ok: "Ok",
        goToScreening: "Ir para prévia",
        continueMapping: "Continuar mapeamento",
      },
      message: {
        accompanyCandidates: "Acompanhe a lista de candidatos mapeados em Prévia",
        backToListMapping: "Volte para lista de mapeamento e adicione os candidatos ao projeto.",
        hasAdded: "Foram adicionadas {response} pessoas ao projeto:",
        updated: [
          "{response} pessoas já cadastradas no IMUA.",
          "{response} pessoa já cadastrada no IMUA.",
        ],
        created: [
          "{response} novas pessoas criadas e adicionadas.",
          "{response} nova pessoa criada e adicionada.",
        ],
      },
      error: ["Lista de candidatos com", "ERRO"],
      errorMessage: "Seu arquivo de estar no formato CSV ou XLSX.",
    },
  },
  screening: {
    guide: {
      accordeons: {
        educationalBackground: "Formação acadêmica",
        experience: "Experiência",
        motivation: "Motivação",
        realizations: "Realizações",
        culturalMatch: "Match Cultural",
        attributes: "Atributos",
        approval: "Aprovar e priorizar",
        candidateOrientations: "Orientações ao consultor",
        candidateQuestions: "Perguntas para o candidato",
        candidateRealizations: "Realizações do candidato",
        vacancyProcess: "Processo da vaga\n(instrução ao consultor)",
      },
      institution: "Instituição",
      level: "Nível",
      course: "Área/Curso",
      languages: "Idiomas",
      skill: "Skill Obrigatória",
      isRequiredSkill: "Esta skill é obrigatória?",
      isEliminatorySkill: "Esta skill é eliminatória?",
      add: {
        formation: "Formação",
        language: "Idioma",
        skill: "Skill",
      },
      vacancyProcess:
        "O profissional para esta vaga deve ter conduzido diagnósticos para entendimento das principais alavancas do negócio, elaborou e implementou novo modelo de remuneração e de gestão de desempenho com ênfase na meritocracia, identificando oportunidades para maior geração de valor para a companhia (mudança de processos e cultural).",
      panels: {
        screeningGuide: "Guia de prévia",
        anotations: "Anotações",
        profile: "Perfil",
        organizationAndEvaluation: "Organização e Avaliação",
        save: "Salvar prévia",
        saveEvaluation: "Salvar Avaliação",
      },
      PostScreening: {
        jobModel: "Modelo de trabalho:",
        warning: "Avaliação necessária para definir o Ranking",
        workType: {
          REMOTE: "Remoto",
          ONSITE: "Presencial",
          HYBRID: "Híbrido",
        },
        motivation: {
          consultantSeem: "Parecer do Consultor:",
          percentIdealProfile: "Atende ao perfil ideal?",
          addDescription: "Adicionar descrição",
          relevantExperience: "Experiência Anterior Relevante",
          actualExperience: "Experiência Anterior Relevante",
        },
        educationBackground: {
          languages: "Idiomas do candidato",
          requiredSkill: "Skill obrigatória",
          skill: "Skill",
          idealProfile: "Perfil Ideal",
          candidateFormation: "Formação do candidato",
          fluent: "Ingles fluênte",
        },
        onConstructionNotFound: "Não existem parâmetros de avaliação para a {section}. Retorne a CONSTRUÇÃO e defina os critérios.",
      },
    },
    list: {
      select: {
        placeholder: "Ação em massa",
        options: {
          addToProject: "Aprovar para entrevistas",
        },
      },
    },
    labels: {
      motivation: "Motivação",
      saveScreening: "Salvar Prévia",
      saveEvaluation: "Salvar Avaliação",
      gotToScreening: "Ir para a prévia",
      scheduleScreening: "Agendar Prévia",
      screeningDate: "Data da prévia",
      ofCandidate: "do candidato",
      candidate: "Candidato",
      linkedCandidateInScreening: "Candidato vinculado a prévia",
      screenings: "Prévias",
      newScreening: "Criar nova prévia",
    },
    tooltip: {
      failedScreening: "Reprovado na prévia",
      screeningCompleted: "Prévia concluída",
      pendingScreening: "Prévia pendente",
    },
    modal: {
      title: {
        linkedCandidate: "Candidato já vinculado ao projeto",
        nonLinkedCandidate: "Adicione um candidato",
        approvalCandidate: "Você tem certeza que deseja aprovar o candidato?",
      },
      message: {
        linkedCandidate: "Já existe uma atividade de prévia vinculada ao candidato neste projeto.",
        nonLinkedCandidate: "Você precisa atribuir esta prévia a um candidato antes de finalizar a atividade.",
        nonLinkedCandidateApproval: "Você precisa atribuir esta prévia a um candidato antes de <strong>aprovar</strong> e <strong>priorizar</strong>.",
        approvalCandidate: "Essa ação cria automaticamente a atividade de entrevista e é irreversível.",
      },
      buttons: {
        confirm: "Confirmar",
        cancel: "Cancelar",
        screeningActivities: "Atividades de Prévia",
        goBackAndAdd: "Voltar e adicionar",
      },
    },
  },
  interview: {
    tooltipMesage: "Você editará este conteúdo no painel de reunião de cada dandidato",
    guide: {
      consultantGuidelinesOptions: [
        "Fazer uma breve introdução sobre o método do projeto.",
        "Trazer informações da prévia.",
        "Estudar itens de realizações do perfil ideal e estruturar perguntas que te levem a compreender a real contribuição do executivo nas rotinas da área e da empresa (agenda operacional e participação em projetos e seu protagonismo.",
        "Trazer as realizações da construção (A entrevista deve ser feita com base nessas realizações).",
      ],
      consultantGuidelines: "Orientações ao Consultor",
      questionsToCandidate: "Perguntas para o Candidato",
      candidateRealizations: "Realizações do Candidato",
      motivation: "Motivação",
      ambient: "Ambiente",
      career: "Carreira",
      family: "Família",
      personal: "Pessoal",
      package: "Pacote",
      bonus: "Bônus",
      expulsion: "Fatores de expulsão da empresa atual:",
      retention: "Fatores de retenção da empresa atual:",
      attraction: "Fatores de atração da empresa atual:",
      orientation: "Orientações sobre motivação para o candidato:",
    },
    labels: {
      company: "Empresa",
      candidateUnderstanding: "O que o candidato entendeu sobre a vaga?",
      realizationsPlace:
        "Onde o candidato realizou as atividades das atribuições da vaga (Realizações)?",
      companyName: "Nome da empresa",
      project: "Projeto",
      goal: "Objetivo do Projeto:",
      diagnosis: "De onde partiu o diagnóstico?",
      decisionMakingModel:
        "Qual é o modelo de tomada de decisão da empresa? Analítico / Racional / Emocional",
      environmentPreference:
        "Em qual ambiente você prefere atuar? Analítico / Racional / Emocional",
      whoWasInvolved: "Quem estava envolvido?",
      involvedProtagonism: "Qual o nível de protagonismo dos envolvidos?",
      implementationLeadership: "Quem liderou a implementação?",
      governanceAndExposition:
        "Quais os níveis de exposição e governança que você teve no projeto?",
      howWasMade: "Como foi feito?",
      resistance: "Houve resistência (s)?",
      dealResistance: "Como você lidou com essas resistências?",
      changeNegativeBehavior: "Teve que mudar um comportamento negativo de alguém? Como?",
      results:
        "Quais foram os resultados (financeiro / tempo / produtividade / cursos / eficiência operacional)?",
      careerPath: "Qual é sua projeção de carreira?",
      talkAbout: "Discorrer sobre:",
      realization: "Realização",
      consultantOpnion: "Parecer do consultor",
      meetsIdealProfile: "Atende ao perfil ideal",
      expectedPackage: "Qual o pacore de remuneração esperado?",
      salary: "Salário",
      bonus: "Bônus",
      annualPackage: "Pacote anual",
      environmentQuestion: "Motivação para ir para uma nova empresa?",
      careerQuestion: "Qual a projeção de carreira do candidato?",
      leaveCompany: "O que te faria sair de sua empresa atual?",
      stayCompany: "O que faria o candidato permanecer na empresa atual?",
      companyAttraction: "O que faria o candidato ir para uma nova empresa?",
      talkAboutMotivation: "Discorrer sobre os motivos:",
      information: "Capturou alguma informação de motivação durante a entrevista?",
      reasonToLeave: "O que te faria sair da sua empresa atual?",
      counteroffer: "Orientar o candidato sobre os fatores de retenção / Chance de Contraoferta:",
      newQuestion: "Nova pergunta",
      editQuestion: "Editar pergunta",
      addQuestionInfo: "Adicionar pergunta para entrevista com este candidato (Opcional)",
      interviewDate: "Data da Entrevista:",
      infoBy: "Informações por:",
      noteBy: "Anotações por:",
      errorCreateMessage: "Não é possível criar mais de uma atividade por candidato.",
    },
    titles: {
      customizeInterview: "Personalizar entrevista",
      screeningInformation: "Informações da prévia",
      educationalBackground: "Formação acadêmica",
      professionalExperience: "Experiência profissional",
      motivation: "Motivação",
      caution: "Atenção!",
      attraction: "Fatores de atração",
      attractionShort: "Atração",
      retention: "Fatores de retenção",
      retentionShort: "Retenção",
      repulsion: "Fatores de expulsão",
      repulsionShort: "Expulsão",
    },
    buttons: {
      reviewAndSchedule: "Revisar e agendar",
      scheduleInterview: "Agendar entrevista",
      goToInterview: "Ir para entrevista",
      approveInterview: "Aprovar para entrevista",
      screeningDetails: "Ver detalhes",
      hideDetails: "Ocultar detalhes",
      back: "Voltar",
    },
    selects: {
      businesFunction: "Linha de Negócio",
      peopleProfile: "Perfil de Pessoas",
      companyCulture: "Cultura da Empresa",
      earnings: "Pacote",
      remunerationSystem: "Sistema de Remuneração",
      careerLimitations: "Limitações de Carreira",
      lackOfRealocationOpportunities: "Falta de Oportunidade de Realocação",
      businessType: "Tipo de Negócio",
      familySituation: "Situação Familiar",
      bossRelationship: "Relacionamento com chefia",
      peers: "Pares",
      greaterPosition: "Cadeira Maior",
      greaterCareer: "Cadeira Menor",
      workAbroad: "Oportunidade no Exterior",
      workplace: "Local de Trabalho",
    },
    placeholders: {
      select: "Selecione",
      addDescription: "Adicionar descrição",
      addCompany: "Adicionar empresa",
      addProject: "Adicionar Projeto",
    },
    panels: {
      interviewGuide: "Guia de Entrevista",
      annotations: "Anotações",
      idealProfile: "Perfil Ideal",
      organizeAndEvaluate: "Organização e avaliação",
    },
    accordeons: {
      educationalBackground: "Formação acadêmica",
      experience: "Experiência",
      motivation: "Motivação",
      realizations: "Realizações",
      culturalMatch: "Match Cultural",
      attributes: "Atributos",
      approval: "Aprovar e priorizar",
      candidateOrientations: "Orientações ao consultor",
      candidateQuestions: "Perguntas para o candidato",
      candidateRealizations: "Realizações do candidato",
    },
    enums: {
      changeJob: changeJobOptions,
      careerPath: careerPathOptions,
      reasons: reasonsOptions,
    },
    oritentations: {
      package:
        "Sistema de remuneração oferecido pela empresa: fixo, bônus, ILP, ações, CLT, PJ, etc...",
      careerSituation:
        "Orientar o candidato para discutir com a chefia a sua situação de carreira antes de seguir na shortlist.",
      careerResignation: "Fazer uma simulação do pedido de demissão.",
      careerChange: "Explorar oportunidade mudança de área ou transferência de local.",
      careerPromotion: "Explorar oportunidade de promoção.",
      familyDiscuss: "Discutir com a família.",
      personalPerformanceEvaluation: "Revisitar a última avaliação de desempenho.",
      personalEmotionalBonds: "Explorar razões / vínculos emocionais com o ambiente atual.",
    },
    tooltip: {
      interviewCompleted: "Entrevista concluída",
      pendingInterview: "Entrevista pendente",
    },
  },
  shortlist: {
    about: {
      about: "Sobre",
      historic: "Trajetória profissional",
      current: "Atual",
      label: "Desmarque a trajetória Indesejada para apresentação",
      tooltip: "A trajetória profissional só pode ser alterado no detalhe de pessoa",
      tableDescription: "Apresentação da shortlist",
    },
    labels: {
      area: "Área",
      level: "Nível",
      fieldOfStudy: "Campo de estudo",
      languages: "Idiomas",
      academicAbbreviated: "Form. Acadêmica",
      experienceAbbreviated: "Exp. Profissional",
      realizations: "Realizações",
      culturalMatch: "Match Cultural",
      attributes: "Atributos",
      motivation: "Motivação",
      finalScore: "Nota final",
      editOpnion: "Editar opnião",
      salary: "Salário (target ideal):",
      bonus: "Bônus:",
      career: "Motivação de Carreira:",
      motivationEnvironment: "Motivação de Ambiente:",
      leaveCompany: "O que faria NAME_CANDIDATE sair de sua empresa atual?",
      stayInCompany: "O que faria NAME_CANDIDATE ficar na empresa atual?",
      companyAttraction: "O que faria NAME_CANDIDATE ir para a nova empresa?",
      consultantOpnion: "Parecer do consultor:",
      formation: "Formação",
      institution: "Instituição",
      company: "Empresa",
      role: "Cargo",
      name: "Nome",
    },
    titles: {
      package: "Pacote",
      career: "Carreira",
      motivation: "Motivação",
      personal: "Pessoal",
      environment: "Ambiente",
      leaveCompany: "FATORES DE EXPULSÃO DA EMPRESA ATUAL:",
      stayInCompany: "FATORES DE RETENÇÃO DA EMPRESA ATUAL:",
      companyAttraction: "FATORES DE ATRAÇÃO DE NOVA EMPRESA:",
      aboutCandidate: "Sobre o candidato",
      academicEducation: "Formação Acadêmica",
      experience: "Experiência",
      achievements: "Realizações",
      realizations: "Realizações",
      attributes: "Atributos",
      culturalMatch: "Match Cultural",
      evaluation: "Avaliação geral do candidato",
      evaluationAbbreviated: "Avaliação",
      finalists: "Os finalistas",
    },
    funnel: {
      title: "Funil",
      originalNumbers: "Números originais",
      originalDescriptions: "Descrições originais",
      addDescription: "Adicionar descrição",
    },
    pdf: {
      [ShortlistEnum.FRONT_COVER]: "Capa Flow",
      [ShortlistEnum.SUMMARY]: "Índice",
      [ShortlistEnum.COMPANY]: "Sobre a Empresa",
      [ShortlistEnum.IDEAL_PROFILE]: "Perfil Ideal - Persona",
      [ShortlistEnum.IDEAL_PROFILE_TAGS]: "Perfil Ideal - Persona",
      [ShortlistEnum.REALIZATIONS]: "Realizações",
      [ShortlistEnum.MOTIVATION]: "Motivação",
      [ShortlistEnum.SEARCH_FIELD]: "Campo de Busca",
      [ShortlistEnum.PROCESS_FUNNEL]: "Funil de Processo",
      [ShortlistEnum.ADHERENCE_TABLE]: "Tabela de Aderência",
      [ShortlistEnum.ADHERENCE_TABLE_FINAL]: "Tabela de Aderência",
      [ShortlistEnum.BACK_COVER]: "Contra Capa Flow",
      [ShortlistEnum.COMPANY_PROFILE_TAGS]: "Peril de Empresa",
      [ShortlistEnum.ATTRIBUTES]: "Atributos",
      [ShortlistEnum.CULTURAL_MATCH]: "Match Cultural",
      [ShortlistPersonEnum.USER_CARD_PERSON]: "{user}",
      [ShortlistPersonEnum.USER_ABOUT]: "Sobre {user}",
      [ShortlistPersonEnum.USER_ACADEMIC]: "{user} - Formação Acadêmica",
      [ShortlistPersonEnum.USER_EXPERIENCE]: "{user} - Experiência",
      [ShortlistPersonEnum.USER_REALIZATIONS]: "{user} - Realizações",
      [ShortlistPersonEnum.USER_ATTRIBUTES]: "{user} - Atributos",
      [ShortlistPersonEnum.USER_CULTURAL_MATCH]: "{user} - Match Cultural",
      [ShortlistPersonEnum.USER_MOTIVATION]: "{user} - Motivação",
      [ShortlistPersonEnum.USER_MOTIVATION_ONE]: "{user} - Motivação",
      [ShortlistPersonEnum.USER_MOTIVATION_TWO]: "{user} - Motivação",
      [ShortlistPersonEnum.USER_MOTIVATION_FACTORS]: "{user} - Motivação",
      [ShortlistPersonEnum.USER_EVALUATION]: "{user} - Avaliação",
      [ShortlistPersonEnum.USER_EVALUATION_FINAL]: "{user} - Avaliação",
    },
    enums: {
      degree: {
        [DEGREE.EMPTY]: "",
        [DEGREE.BACHELOR]: "Graduação",
        [DEGREE.POST_BACHELOR]: "Pós graduação",
        [DEGREE.MBA]: "MBA",
        [DEGREE.MASTER]: "Mestrado",
        [DEGREE.DOCTORATE]: "Doutorado",
        [DEGREE.POST_DOCTORATE]: "Pós Doutorado",
        [DEGREE.INDIFFERENT]: "Indiferente",
        [DEGREE.EXTRA_CURRICULAR_COURSE]: "Curso extra curricular",
      },
      institution: {
        [INSTITUTION.EMPTY]: "",
        [INSTITUTION.INDIFFERENT]: "Indiferente",
        [INSTITUTION.FIRST_LINE]: "Instituições de Primeira Linha",
        [INSTITUTION.PREFERENTIALLY_FIRST_LINE]: "Preferencialmente em instituição de primeira linha",
        [INSTITUTION.MANDATORILY_FIRST_LINE]: "Obrigatoriamente em instituição de primeira linha",
      },
      extension: {
        [EXTENSION_TYPE.POST_BACHELOR]: "Pós graduação",
        [EXTENSION_TYPE.MBA]: "MBA",
        [EXTENSION_TYPE.EMPTY]: "",
      },
      careerPathOptions: {
        EMPTY: "",
        GREATER_POSITION: "Cadeira Maior",
        GREATER_CAREER: "Carreira Maior",
        WORK_ABROAD: "Oportunidade no Exterior",
        WORKPLACE: "Local de Trabalho",
      },
      changeJobOptions: {
        EMPTY: "",
        BUSINESS_FUNCTION: "Linha de negócio",
        PEOPLE_PROFILE: "Perfil das pessoas",
        COMPANY_CULTURE: "Cultura da empresa",
      },
      reasonsOptions: {
        EARNINGS: "Pacote",
        REMUNERATION_SYSTEM: "Sistema de Remuneração",
        CAREER_LIMITATIONS: "Limitações da Carreira",
        LACK_OF_RELOCATION_OPPORTUNITIES: "Falta de Oportunidade de Realocação",
        BUSINESS_TYPE: "Tipo do Negócio",
        FAMILY_SITUATION: "Situação Familiar",
        BOSS_RELATIONSHIP: "Relacionamento com chefia",
        PEERS: "Pares",
        COMPANY_CULTURE: "Cultura da empresa",
        EMPTY: "",
      },
      LanguageProficiencyEnum: {
        EMPTY: "",
        BASIC: "básico",
        BASIC_INT: "básico a intermediário",
        INTERMEDIATE: "intermediário",
        ADVANCED: "avançado",
        FLUENT: "fluente ou nativo",
        ELEMENTARY: "Básico para conversação",
        LIMITED_WORKING: "Intermediário, limitado para negócio",
        PROFESSIONAL_WORKING: "Domínio para negócio",
        NATIVE: "Proficiência nativa ou bilíngue",
      },
      attributes: {
        [ATTRIBUTE.COGNITIVE_INTELLIGENCE]: "Capacidade de observar, entender, diagnosticar e conectar as pessoas, coisas, ideias e fatos, identificando, propondo e resolvendo problemas e oportunidades.",
        [ATTRIBUTE.EMOTIONAL_INTELLIGENCE]: "Habilidade de reconhecer e lidar com os sentimentos próprios e dos outros.",
        [ATTRIBUTE.SPIRITUAL_INTELLIGENCE]: "Visão elevada para construir um propósito que agregue sentido a vida, adotando valores éticos e morais, de forma a promover o engajamento de outros.",
        [ATTRIBUTE.ENERGY]: "Capacidade de atuar e interagir em ambientes e situações complexas e intensas.",
        [ATTRIBUTE.MATURITY]: "Capacidade para entender o “tempo” e a “natureza” das coisas, das situações e das pessoas, conhecendo os “gatilhos” que as fazem funcionar.",
      },
      cultural: {
        [CULTURAL.PRACTICAL]: "Tem muita energia e prefere a ação ao debate. É competitivo e orientado a resultado.",
        [CULTURAL.LOGICAL]: "É organizado e metódico. Gosta de ter todas as informações necessárias para tomar decisões.",
        [CULTURAL.REFLEXIVE]: "Gosta de pensar o futuro e construir novos cenários.",
        [CULTURAL.AFFECTIVE]: "Baseia suas ações e decisões influenciado pelas relações pessoais.",
      },
    },
  },
  create: {
    title: "Criar Projeto",
    clear: "Limpar Perfil",
    save: "Salvar Projeto",
    clean: "Limpar",
    continue: "Continuar",
    back: "Voltar",
    successMessage: "Projeto cadastrado com sucesso!",
    clearMessage:
      "Ao limpar, todos os dados preenchidos serão perdidos, deseja continuar com a ação?",
    basicData: {
      title: "Dados Básicos",
      projectName: "Nome do Projeto",
      positionName: "Nome da Posição",
      projectType: {
        label: "Tipo de Projeto",
        enum: projectTypes,
      },
      corporation: "Empresa",
      requester: "Requisitante",
      source: {
        label: "Origem",
        enum: sourceTypes,
      },
      allocatedTeam: {
        label: "Time Alocado",
        enum: allocatedTeam,
        noFound: "Nenhum resultado encontrado",
        selected: "Selecionado",
        Search: "_ Buscar Pessoa",
      },
      confidentialVacancy: "Vaga Confidencial?",
      yes: "sim",
      no: "não",
    },
    positionData: {
      title: "Dados da posição",
      locality: "Local",
      class: {
        label: "Classe",
        enum: {
          CLASS_A: "A",
          CLASS_B: "B",
          CLASS_C: "C",
        },
      },
      level: {
        label: "Nível",
      },
      salary: {
        label: "Salário",
        enum: {
          CLT: "CLT",
          PJ: "PJ",
        },
        from: "de",
        to: "a",
      },
      bonus: "Bônus",
      annualPackage: "Pacote Anual",
      about: "Sobre",
    },
    placeholders: {
      projectName: "Título do projeto",
      positionName: "ex.: Gerente de Gente & Gestão",
      corporation: "ex.: LOFT Tecnologia",
      requester: "ex.: Nome do requisitante",
      select: "Selecione",
      locality: "ex.: São Paulo, SP, Brasil",
      about: "Resumo",
      netWorth: "00.000,00",
      level: "ex.: +1",
    },
  },
  placement: {
    label: "Placement",
    card: {
      btnPlacement: "Definir Placement",
      title: "Projeto Finalizado",
      position: "Posição",
      company: "Empresa",
      salary: "Salário",
      bonus: "Bônus",
      totalPackage: "Pacote total",
      placementStartDate: "Data de início",
      placementUpdateWarning: "Atualizar os dados de experiência profissional do candidato",
      shortTermIncentive: "Incentivo de Curto Prazo",
      longTermIncentive: "Incentivo de Longo Prazo",
      otherIncentive: "Outros incentivos",
      definePlacement: "você confirma o placement de",
    },
    confirm: "Você confirma o placement de",
    confirmText: "confirmar",
    cancelText: "cancelar",
  },
  validation: validationPanel,
};
