<script setup lang="ts">
import { cloneDeep } from "lodash";
import { addPaginatePages, formatBreakLines, paginateTexts, paragraphToArray } from "@/utils/text";
import Resume from "@/common/components/Export/Resume.vue";

const props = withDefaults(
  defineProps<{
    description?: string | null
    foundationYear?: string | null
    historic?: string
    bullet?: boolean
    isExport: boolean
  }>(),
  {
    description: null,
    foundationYear: null,
    historic: "",
    bullet: true,
    isExport: false,
  },
);

const { t } = useI18n({ useScope: "global" });

interface PagesType {
  description: string | null
  historic: string | null
}

const { description, foundationYear, historic, bullet, isExport } = toRefs(props);

const pages = ref<PagesType[]>([]);

const emptyValue = {
  description: null,
  historic: null,
};

function formatHistoric(data: any | null) {
  if (!data)
    return [];
  return (paragraphToArray(data.text) || [])?.map((item, index) => ({
    break: !data.break || (index > 0 && data.break),
    value: item,
  }));
}

function paginatePages() {
  pages.value[0] = cloneDeep(emptyValue);

  const paginateDescription = paginateTexts(description.value || "", "14px", 350, 30);
  const paginateHistoric = paginateTexts(historic.value || "", "14px", 499, 26);

  addPaginatePages(
    pages.value,
    "description",
    cloneDeep(paginateDescription || []).map(item => item.text),
    emptyValue,
  );

  addPaginatePages(pages.value, "historic", paginateHistoric, emptyValue);
}

watch(props, () => {
  pages.value = [];
  paginatePages();
});

watchEffect(() => {
  paginatePages();
});
</script>

<template>
  <ExportExportPage
    v-for="(page, index) in pages"
    :key="index"
    :page-index="index"
    :is-export="isExport"
    :page-name="t('exportPDF.summaryConstructionBeta.aboutCompany')"
    v-bind="$attrs"
  >
    <div class="pdf-company-about">
      <div class="pdf-company-about-left">
        <span class="description" v-html="formatBreakLines(page.description)" />
      </div>
      <div class="pdf-company-about-right">
        <div class="col-title">
          {{ t("corporations.details.historic") }}
        </div>
        <div v-if="foundationYear && index === 0" class="fundation">
          <strong> {{ t("corporations.details.foundationYear") }}: </strong>
          <span v-html="foundationYear" />
        </div>
        <Resume
          :show-title="false"
          :show-label="false"
          :show-circle="bullet"
          font-size="0.875rem"
          line-height="1.25rem"
          :items="formatHistoric(page.historic)"
        />
      </div>
    </div>
  </ExportExportPage>
</template>

<style lang="scss" scoped>
.pdf-company-about {
  display: grid;
  grid-template-columns: 22rem auto;
  gap: 8.7rem;

  &-left {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-top: 1.125rem;

    span {
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }

  &-right {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 1.5rem;

    .col-title {
      color: $gray-dark;
      font-size: 1.125rem;
      font-weight: bold;
      line-height: 1.5;
      text-transform: uppercase;
    }

    span {
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }
}
</style>
