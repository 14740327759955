<script lang="ts">
import {
  computed,
  defineComponent,
} from "vue";
import InputText from "primevue/inputtext";
import SvgIcon from "@jamescoyle/vue-icon";

export default defineComponent({
  name: "AuthInput",
  components: {
    InputText,
    SvgIcon,
  },
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      required: true,
    },
    error: {
      type: String,
      default: null,
    },
    afterIcon: {
      type: String,
      default: null,
    },
  },
  emits: ["update:modelValue", "click:afterIcon"],
  setup(props, { emit }) {
    const inputValue = computed({
      get: () => props.modelValue,
      set: newValue => emit("update:modelValue", newValue),
    });

    return {
      inputValue,
    };
  },
});
</script>

<template>
  <label
    :for="$attrs.id"
    class="form-group"
    :class="[($attrs.class || '')]"
    :data-test="`${$attrs['data-test']}-label`"
  >
    <span class="label">{{ label }}</span>
    <div :class="{ 'icon-wrapper': afterIcon }">
      <InputText
        :id="$attrs.id"
        v-model="inputValue"
        :data-test="`${$attrs['data-test']}-input`"
        :placeholder="$attrs.placeholder"
        :type="$attrs.type"
        :class="{
          'p-invalid': error || $attrs.invalid,
        }"
      />
      <SvgIcon
        v-if="afterIcon"
        role="button"
        type="mdi"
        class="input-icon"
        :path="afterIcon"
        @click="$emit('click:afterIcon')"
      />
    </div>
    <small class="error-message">
      {{ error }}
    </small>
  </label>
</template>

<style lang="scss" scoped>
.form-group {
  display: block;
  margin-bottom: 1.5rem;

  .label {
    display: block;
    margin-bottom: 1rem;
    color: black;
    font-size: 0.875rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  .p-input-icon-right:deep {
    display: block;
  }

  .icon-wrapper {
    position: relative;

    input {
      padding-right: 2.5rem;
    }

    .input-icon {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(-50%, -50%);
    }
  }

  input {
    display: inherit;
    width: 100%;
    border-radius: 8px;

    &:focus,
    &:hover {
      border-color: $gray-dark;
      box-shadow: none;
    }
  }
}

.error-message {
  display: inline-block;
  height: 1em;
  color: $red-imua;
  font-size: 0.75rem;
}
</style>
