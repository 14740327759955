<script lang="ts">
import type { ComputedRef, Ref } from "vue";

// TODO: remove moment
import moment from "moment";
import { useI18n } from "vue-i18n";
import type { WorkExperience } from "@/entities/Person/work-experience";
import type { Person } from "@/http_services/entities/types";
import { Card } from "@/components/card";
import { ImageCard } from "@/components/image_card";
import { completeDate } from "@/utils/dates";
import { sortExperiences } from "@/utils/experiences-sorter";
import { formatCurrencyFromValue, parsePhones, yearFor } from "@/utils/parsers";
import RefreshIcon from "@/assets/images/refresh_icon.svg";
import LinkedinIcon from "@/assets/images/linkedin_icon.svg";
import LinkedinIconBlue from "@/assets/images/linkedin_icon_blue.svg";

export default defineComponent({
  name: "PersonCard",
  components: {
    Card,
    ImageCard,
    RefreshIcon,
    LinkedinIcon,
    LinkedinIconBlue,
  },
  setup() {
    const { t } = useI18n();
    const person = inject("person") as Ref<Person>;

    const hoveredLinkedin = ref(false);
    const hoveredFacebook = ref(false);

    const fullName = computed(() => `${person.value.first_name} ${person.value.last_name}`);

    const socialNetworkClick = (url: string) => {
      window.open(url, "_blank");
    };

    const sortedExperiences = computed(() => sortExperiences(person.value.work_experience));

    const lastExperience = computed(
      () => sortedExperiences.value[0] || {},
    ) as ComputedRef<WorkExperience>;

    const lastExperienceDate = computed(() => {
      const start = lastExperience.value?.start_date;
      const end = lastExperience.value?.end_date;
      if (start)
        return `${yearFor(start)}/${yearFor(end)}`;
      return "";
    });

    const yearsFromNow = date => moment().diff(date, "years");

    const lastSalary = computed(() =>
      formatCurrencyFromValue(
        lastExperience.value.salary?.amount,
        lastExperience.value.salary?.currency,
      ));

    const personInfo = computed(() => {
      const map = new Map();
      map.set(t("persons.details.lastSalary"), lastSalary.value);
      map.set(t("persons.details.phone"), parsePhones(person.value.phones.slice(0, 2)));
      map.set(
        t("persons.details.email"),
        (person.value.email_addresses.find(m => m.is_main) || {}).email_address,
      );
      map.set(
        t("persons.details.age"),
        person.value.birth_date
          ? `${yearsFromNow(person.value.birth_date)} ${t("persons.details.years")}`
          : "",
      );
      map.set(t("persons.details.location"), "");

      return map;
    });

    return {
      t,
      person,
      fullName,
      personInfo,
      hoveredLinkedin,
      hoveredFacebook,
      lastExperienceDate,
      lastExperience,
      socialNetworkClick,
      completeDate,
    };
  },
});
</script>

<template>
  <Card v-if="person" class="person-card">
    <div class="person-card__section --flex">
      <div class="avatar-section">
        <ImageCard
          class="image-card"
          :name="fullName"
          :image-url="person.avatar_url"
          border-color="transparent"
        />
      </div>

      <div>
        <h2>
          {{ person.first_name }} {{ person.last_name }}
          <span>({{ person.serial }})</span>
        </h2>

        <h3>{{ lastExperience.role_name }}</h3>

        <p class="last-updated">
          <RefreshIcon class="refresh-icon" />
          <span>{{ completeDate(person.updated_at || person.inserted_at) }}</span>
        </p>

        <p>
          <span class="highlight">{{ t("corporations.socialNetworks") }}:</span>
          <template v-if="person.linkedin_url">
            <LinkedinIcon
              v-show="!hoveredLinkedin"
              class="social-icon"
              @mouseenter="hoveredLinkedin = true"
            />
            <LinkedinIconBlue
              v-show="hoveredLinkedin"
              class="social-icon"
              @mouseleave="hoveredLinkedin = false"
              @click="socialNetworkClick(person.linkedin_url)"
            />
          </template>
        </p>
      </div>
    </div>

    <div class="person-card__section">
      <p v-if="lastExperience.company?.name" class="person-info">
        {{ lastExperience.company?.name }} -
        <span class="highlight">{{ lastExperienceDate }}</span>
      </p>

      <p v-for="[key, value] in personInfo" :key="key" class="person-info">
        <template v-if="key && value">
          <span class="highlight">{{ key }}</span>
          {{ value }}
        </template>
      </p>
    </div>
  </Card>
</template>

<style lang="scss" scoped>
.person-card {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  &__section {
    &.--flex {
      display: flex;
      gap: 1.5rem;
    }
  }

  h2,
  h3,
  p {
    line-height: 1.2;
  }

  h2 {
    display: flex;
    align-items: center;
    margin-bottom: 0.25rem;
    font-size: 1.125rem;

    span {
      margin-left: 1rem;
      color: $primary;
      font-size: 0.75rem;
      font-weight: normal;
    }
  }

  h3 {
    font-weight: normal;
  }

  .image-card {
    width: 135px;
    height: 135px;
  }

  .person-info {
    margin-bottom: 0.5rem;
  }

  .highlight {
    font-weight: bold;
  }

  .last-updated {
    font-size: 0.75rem;
    margin-block: 1rem;

    .refresh-icon {
      width: 1.2em;
      height: 1.2em;
      margin-right: 0.3rem;
      stroke: $gray-low;
      vertical-align: bottom;
    }
  }

  .social-icon {
    margin-left: 0.5rem;
    vertical-align: sub;
  }
}
</style>
