<script setup lang="ts">
import { vOnClickOutside } from "@vueuse/components";
import Calendar from "primevue/calendar";
import { SvgIcon } from "@/components/icon";

const props = defineProps<{
  modelValue: string | null
}>();
const emit = defineEmits<{
  (event: "submit", value: Date): void
}>();
const { t } = useI18n();

const presentationDate = ref(props.modelValue);
const datesRef: Ref<HTMLInputElement | null> = ref(null);
const editingDate: Ref<boolean> = ref(false);

const onClickOutsideHandler: [() => void, { ignore: (Ref<HTMLDivElement | null> | string)[] }] = [
  () => {
    editingDate.value = false;
  },
  {
    ignore: [
      datesRef,
      ".p-datepicker",
    ],
  },
];
function fixCalendarFocusTrap(): void {
  if (!datesRef.value)
    return;

  datesRef.value.updateFocus = () => {
    const inputEl: HTMLInputElement | null = datesRef.value.input;

    if (inputEl)
      inputEl.focus();
  };
}

watch(datesRef, () => fixCalendarFocusTrap(), {
  immediate: true,
});

watchEffect(() => {
  presentationDate.value = props.modelValue;
});
</script>

<template>
  <div v-on-click-outside.bubble="onClickOutsideHandler" class="dates" @keydown.esc="editingDate = false">
    <template v-if="editingDate">
      <Calendar
        id="activity_date"
        ref="datesRef"
        v-model="presentationDate"
        v-maska="'##/##/####'"
        date-format="dd/mm/yy"
        :manual-input="true"
        :placeholder="t('text.emptyDate')"
        @update:model-value="emit('submit', $event)"
        @keyup.enter="editingDate = !editingDate"
      />
      <SvgIcon
        class="icon-edit"
        icon="edit_icon"
        margin="none"
        width="1.1rem"
        height="1.1rem"
        @click="editingDate = !editingDate"
      />
    </template>
    <template v-else>
      <span v-if="presentationDate">
        {{ presentationDate }}
      </span>
      <span v-else>
        {{ t("text.emptyDate") }}
      </span>
      <SvgIcon
        class="icon-edit"
        icon="edit_icon"
        margin="none"
        width="1.1rem"
        height="1.1rem"
        @click="editingDate = !editingDate"
      />
    </template>
  </div>
</template>

<style scoped lang="scss">
  .dates {
    display: grid;
    grid-template-columns: 1fr auto;
    place-items: center;
    gap: 1.5rem;
    width: 9.125rem;
    height: 2rem;
    span {
      line-height: 2rem;
    }
  :deep(.p-inputtext.p-component) {
    font-size: 1rem;
    border: 1px solid $gray-lower;
    text-align: center;
    padding: 0.3125rem;
    border-radius: 5px;
    &:hover {
      border-color: $gray-lower;
    }
    &::placeholder {
      color: $gray-border;
    }
  }
  svg {
    cursor: pointer;
    &:hover {
      stroke: $red-violet;
    }
  }
}
</style>
