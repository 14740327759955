import { isInputEmpty } from "@/utils/validators/value-validators";

interface SortByKeyArray {
  [key: string]: number | string
}

interface ObjectArray {
  [key: string]: any
}

export function sortByKey(key: string) {
  return (a: SortByKeyArray, b: SortByKeyArray): number => {
    if (typeof a[key] === "string")
      return (a[key] as string).localeCompare(b[key] as string);

    return (a[key] as number) - (b[key] as number);
  };
}

export const range = (length: number) => [...Array(length).keys()];

export function filterEmptyObjects(arr: ObjectArray[]): any {
  return arr.filter(item => Object.values(item).some(value => !isInputEmpty(value)));
}
