export enum Proficiency {
  BASIC = "básico",
  BASIC_INT = "básico a intermediário",
  INTERMEDIATE = "intermediário",
  ADVANCED = "avançado",
  FLUENT = "fluente ou nativo",
}

export interface Language {
  language?: string
  proficiency?: Proficiency
}
