<script lang="ts">
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { TrashButton } from "@/components/trash-button";
import { TooltipBox } from "@/components/tooltip";

export default defineComponent({
  components: {
    TooltipBox,
    TrashButton,
  },
  props: {
    id: String,
    placeholder: String,
    modelValue: String,
    warningChars: Number,
    warningMessage: String,
    enforceChars: Boolean,
    autoFill: Boolean,
    shortText: Boolean,
    tooltipShow: {
      type: Boolean,
      default: false,
    },
    tooltipMesage: String,
    tooltipColor: String,
    tooltipBackground: String,
    svgIcon: String,
    showIcon: {
      type: Boolean,
      default: false,
    },
    rezise: {
      type: String,
      default: "none",
      required: false,
    },
  },
  emits: ["update:modelValue", "click:onButtonClick"],
  setup(props, { emit }) {
    const showTooltip = ref(false);
    const { t } = useI18n();

    const onClick = () => {
      emit("click:onButtonClick");
    };

    return {
      t,
      showTooltip,
      onClick,
    };
  },
});
</script>

<template>
  <div class="text-area-wrapper" @mouseover="showTooltip = true" @mouseout="showTooltip = false">
    <TooltipBox
      :show="tooltipShow && showTooltip"
      :color="tooltipColor"
      :background="tooltipBackground"
      :mesage="tooltipMesage"
    />
    <div class="textarea-container">
      <textarea
        :id="id"
        class="textarea-component"
        :class="{ auto_fill: autoFill, short: shortText }"
        data-test="text-area"
        :rows="`${$attrs.rows || 4}`"
        :value="modelValue"
        :placeholder="placeholder"
        :disabled="$attrs.disabled"
        :maxlength="enforceChars ? warningChars : null"
        wrap="hard"
        @input="$emit('update:modelValue', $event.target.value)"
      />
      <TrashButton :show-icon="showIcon" :svg-icon="svgIcon" @click="onClick" />
    </div>
    <div v-if="warningChars" class="counter-wrapper">
      <div class="counter">
        {{ modelValue?.length || 0 }}/{{ warningChars }}
      </div>
      <div v-if="(modelValue?.length || 0) > warningChars" class="warning">
        {{ warningMessage || t("text.textArea.defaultWarning", { count: warningChars }) }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.textarea-container {
  display: flex;

  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    color: inherit;
  }

  button {
    min-width: 2rem;
    height: 1.8rem;
    border: 1px solid $gray-border;
    border-radius: 6px;
    margin-left: 0.563rem;

    &:hover {
      border: 1px solid $red-violet-medium;
      background-color: $red-violet-medium;

      svg {
        color: $orange-medium;
      }
    }
  }
}

.text-area-wrapper {
  width: 100%;

  .textarea-component {
    width: 100%;
    padding-block: 1rem;
    padding-inline: 0.5rem 1rem;
    border: 1px solid $gray-border;
    border-radius: 6px;
    color: gray;
    font-family: "Open Sans";
    font-size: 1rem;
    outline: none;
    overflow-wrap: break-word;
    resize: v-bind("rezise");
    scrollbar-color: #888 transparent;
    white-space: pre-wrap;

    &.short {
      padding: 0.25rem 0.5rem;
    }

    /* Works on Firefox */
    scrollbar-width: thin;

    &::placeholder {
      color: $gray-medium;
    }

    /* Works on Chrome, Edge, and Safari */
    &::-webkit-scrollbar {
      width: 2rem;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      min-height: 4rem;
      box-sizing: padding-box;
      border: 0.92rem solid transparent;
      border-radius: 1rem;
      background-clip: padding-box;
      background-color: #c6c6c6;
    }
  }

  .auto_fill {
    border: 2px solid $red-violet;
  }

  .counter-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 5px;
    font-size: 13px;

    .warning {
      color: $red-warning;
    }

    .counter {
      padding-right: 0.5rem;
      color: $secondary;
    }
  }
}
</style>
