<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  ref,
} from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import SvgIcon from "@jamescoyle/vue-icon";
import {
  mdiAlertCircleOutline,
  mdiCheckCircle,
  mdiCloseCircle,
  mdiEye,
  mdiEyeOff,
} from "@mdi/js";
import { AxiosError } from "axios";
import {
  AuthButton,
  AuthCard,
  AuthInput,
  AuthTemplate,
} from "../components";
import { refreshToken, updatePassword } from "@/http_services/auth";
import { getMe } from "@/http_services/entities/persons";

const verifyToken = async (token: string): Promise<boolean> => token === "valid_token";

export default defineComponent({
  name: "DefinePassword",
  components: {
    AuthTemplate,
    AuthCard,
    AuthInput,
    AuthButton,
    SvgIcon,
  },
  props: {
    passwordToken: String,
  },
  setup(props) {
    const router = useRouter();
    const { t } = useI18n();
    const payload = ref({ newPassword: "", confirmPassword: "" });
    const passwordHidden = ref({ newPassword: true, confirmPassword: true });
    const userId = ref("");
    const requestError = ref("");

    const passwordValidation = computed(() => ({
      charactersQuantity: { value: payload.value?.newPassword?.length >= 8, qty: 8 },
      letterQuantity: { value: !!payload.value?.newPassword?.match(/[A-Za-z]/), qty: 1 },
      numberQuantity: { value: !!payload.value?.newPassword?.match(/[0-9]/), qty: 1 },
      specialCharQuantity: { value: !!payload.value?.newPassword?.match(/[\W_]/), qty: 1 },
    }));

    const requirementsMet = computed<boolean>(() =>
      Object.values(passwordValidation.value).every(validation => validation.value));
    const passwordsMatch = computed<boolean>(() => (
      payload.value.newPassword === payload.value.confirmPassword
    ));

    const expiredToken = ref<boolean>(false);

    const goToLogin = (passwordDefined = false) => {
      router.push(`/login/${passwordDefined ? "?passwordDefined=true" : ""}`);
    };

    const submitNewPassword = async (): Promise<void> => {
      requestError.value = "";

      try {
        await updatePassword(
          userId.value,
          { user: { password: payload.value.confirmPassword } },
        );

        goToLogin(true);
      }
      catch (error) {
        requestError.value = t("login.definePassword.requestError");
      }
    };

    onMounted(async () => {
      if (!router.currentRoute.value.query.passwordToken)
        goToLogin();

      try {
        const { data: token } = await refreshToken({
          login: {
            refresh_token: String(router.currentRoute.value.query.passwordToken),
          },
        });
        localStorage.setItem("access_token", token.access_token);

        const user = await getMe();
        userId.value = user.user_id;
      }
      catch (error) {
        if (error instanceof AxiosError)
          console.error(error.message);
      }
    });

    return {
      t,
      payload,
      passwordHidden,
      passwordValidation,
      requirementsMet,
      passwordsMatch,
      expiredToken,
      check: mdiCheckCircle,
      close: mdiCloseCircle,
      eye: mdiEye,
      eyeOff: mdiEyeOff,
      alertIcon: mdiAlertCircleOutline,
      validationIconSize: "20",
      goToLogin,
      submitNewPassword,
      requestError,
    };
  },
});
</script>

<template>
  <AuthTemplate>
    <AuthCard :title="t('login.definePassword.title')">
      <template v-if="expiredToken">
        <div id="password-defined">
          <div id="message">
            <SvgIcon id="alert-icon" type="mdi" :path="alertIcon" />
            <span>{{ t('login.definePassword.passwordAlreadyDefined') }}</span>
          </div>
          <div id="button-wrapper">
            <AuthButton :text="t('login.submitButton')" :on-click="goToLogin" visible />
          </div>
        </div>
      </template>

      <template v-else>
        <form
          data-test="auth-form"
          @submit.prevent="submitNewPassword()"
        >
          <AuthInput
            id="new-password"
            v-model="payload.newPassword"
            class="mb-0"
            data-test="new-password"
            :type="passwordHidden.newPassword ? 'password' : 'text'"
            :label="t('login.passwordNew')"
            :placeholder="t('input.placeholder.password')"
            :error="requestError"
            :after-icon="!passwordHidden.newPassword ? eye : eyeOff"
            @click:after-icon="passwordHidden.newPassword = !passwordHidden.newPassword"
          />

          <ul class="password-validation">
            <li
              v-for="[key, validation] in Object.entries(passwordValidation)"
              :key="key"
              class="validation"
              :class="[{ valid: validation.value }]"
              :data-test="key"
            >
              <span
                class="validation-icon"
                :style="`--validation-icon-size: ${validationIconSize}px`"
              >
                <SvgIcon
                  type="mdi"
                  data-test="icon"
                  :path="validation.value ? check : close"
                  :size="validationIconSize"
                />
              </span>

              <span class="validation-label">
                {{ t(`login.${key}`, { quantity: validation.qty }) }}
              </span>
            </li>
          </ul>

          <AuthInput
            id="confirm-password"
            v-model.lazy="payload.confirmPassword"
            data-test="confirm-password"
            :type="passwordHidden.confirmPassword ? 'password' : 'text'"
            :label="t('login.passwordConfirmation')"
            :placeholder="t('input.placeholder.password')"
            :error="
              !passwordsMatch && payload.confirmPassword ? t('login.definePassword.matchError') : ''
            "
            :after-icon="!passwordHidden.confirmPassword ? eye : eyeOff"
            @click:after-icon="passwordHidden.confirmPassword = !passwordHidden.confirmPassword"
          />

          <AuthButton
            :label="t('input.confirm')"
            :disabled="!requirementsMet || !passwordsMatch"
          />
        </form>
      </template>
    </AuthCard>
  </AuthTemplate>
</template>

<style lang="scss" scoped>
.mb-0 {
  margin-bottom: 0;
}

.password-validation {
  margin-top: 0.75rem;
  margin-bottom: 2rem;

  .validation {
    display: flex;
    align-items: center;
    margin-bottom: 0.25rem;
    color: $gray;
    font-size: 0.75rem;
    font-weight: bold;
    text-transform: uppercase;

    .validation-label {
      margin-left: 0.25rem;
      color: currentcolor;
      letter-spacing: 1px;
    }

    .validation-icon:deep {
      position: relative;
      display: inline-block;
      width: var(--validation-icon-size, 20px);
      height: var(--validation-icon-size, 20px);

      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: 75%;
        height: 75%;
        border-radius: 100%;
        background-color: $gray-dark;
        content: "";
        transform: translate(-50%, -50%);
      }

      path {
        fill: white;
      }
    }

    &.valid {
      color: black;

      .validation-icon:deep {
        &::before {
          background-color: white;
        }

        path {
          fill: $yellow;
        }
      }
    }
  }
}
</style>
