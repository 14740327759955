import {
  exportBullhornShortlistMigrate,
  exportConstructionBullhorn,
  exportConstructionMigrate,
  exportShortlistMigrate,
} from "@/http_services/entities/project";
import { ShortlistEnum } from "@/schemas/project/pdf";
import store from "@/store";
import type { ExportPDF } from "@/types/export";
import { ConstructionEnum } from "@/types/export";

export function useExport(id: string, type: "construction" | "shortlist" = "construction") {
  const { t, locale } = useI18n();

  const {
    data: corporation,
    mutate: getCorporation,
  } = useCorporation();

  const {
    data: project,
    mutate: useProject,
  } = useProjectMutation(id);

  const {
    capture,
  } = useTracking();

  useProject(id, {
    onSuccess(data) {
      getCorporation(String(data.corporation?.corporation_id));
    },
  });
  const {
    data: constructionOrganization,
  } = useConstructionOrganizationQuery(id);

  const {
    data: counters,
  } = useProjectCounters(id);

  // TODO: use a non paginated version
  const {
    data: candidates,
  } = useShortlistCandidates({
    project_id: id,
    project_stage: "SHORTLIST",
    sort: ["ranking:desc"],
    skip: 0,
    limit: 30,
  });

  const {
    data: interviews,
  } = useInterviewsEvaluationQuery(id);

  const {
    data: businessFunctions,
  } = useBusinessFunctionsQuery(id);

  const exportData: Ref<ExportPDF | undefined> = ref();

  function pagesInitial() {
    return type === "construction" ? pagesInitialShow() : pagesShortlistInitialShow();
  }

  function pagesInitialShow() {
    const pagesFormatted: string[] = [];

    Object.values(ConstructionEnum).forEach((item: ConstructionEnum) => {
      pagesFormatted.push(item);
    });

    return pagesFormatted;
  }

  function pagesShortlistInitialShow() {
    const pagesFormatted: string[] = [];

    Object.values(ShortlistEnum).forEach((item: ShortlistEnum) => {
      pagesFormatted.push(item);
    });

    return pagesFormatted;
  }

  const pages: Ref<string[]> = ref([...pagesInitial()]);

  watchEffect(() => {
    if (!project.value || !corporation.value || !type || !constructionOrganization.value || !counters.value)
      return;
    exportData.value = {
      project: {
        title: project.value.position_name,
        companyName: project.value.corporation?.corporation_name,
        positionName: project.value.position_name,
        company_historic: project.value.corporation?.historic,
        company_description: project.value.corporation?.description,
        company_foundation_year: project.value.corporation?.foundation_year,
        counters: counters.value,
        confidential_placement: project.value.confidential_placement,
        process_funnel: project.value?.process_funnel,
      },
      ideal_profile: constructionOrganization.value.ideal_profile,
      stages: Object.keys(project.value.stages).reduce((acc: any, stage: any) => {
        acc[stage] = project.value.stages[stage].status;
        return acc;
      }, {}),
      schedules: project.value.schedules,
      corporation: {
        number_of_employees: corporation.value.number_of_employees,
        revenues: corporation.value.revenues,
        foundation_year: corporation.value?.foundation_year,
        description: corporation.value?.description,
        historic: corporation.value?.historic,
      },
      governance: constructionOrganization.value.governance,
      strategy: constructionOrganization.value.strategy,
      execution_conditions: constructionOrganization.value.execution_conditions,

      governance_tags: constructionOrganization.value.governance.tags,
      strategy_tags: constructionOrganization.value.strategy.tags,
      execution_conditions_tags: constructionOrganization.value.execution_conditions.tags,

      candidates: candidates.value,
      interviews: interviews.value,

      business_functions: businessFunctions.value,

      list_hidde_pages: [],
      language: locale.value,
    };
  });

  const constructionTitle = computed(() => t("projects.construction.titles.construction"));
  const typeTitle = type === "construction" ? constructionTitle.value : "Shortlist";
  const filename = computed(() => `${project?.value?.corporation?.corporation_name}${project?.value?.corporation?.corporation_name ? ` - ${project?.value?.position_name}` : ""} - ${typeTitle}.pdf`);
  const loadingTitle = computed(() => t("text.loading"));

  async function execute(listHiddePages: string[] = [], type: string) {
    if (!exportData.value)
      return;

    exportData.value.list_hidde_pages = listHiddePages;
    exportData.value.type = type;
    store.commit("SET_FULL_PAGE_LOADING_MESSAGE", loadingTitle.value);
    capture("imua-export-pdf-construction", { project_id: id });

    await exportConstructionMigrate(exportData.value)
      .then((result) => {
        if (result.success) {
          // For other browsers:
          // Create a link pointing to the ObjectURL containing the blob.
          const data = window.URL.createObjectURL(result.blob);

          const link = document.createElement("a");
          link.href = data;

          link.download = filename.value;
          // this is necessary as link.click() does not work on the latest firefox
          link.dispatchEvent(
            new MouseEvent("click", {
              bubbles: true,
              cancelable: true,
              view: window,
            }),
          );

          setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
            link.remove();
          }, 100);
        }
        else {
          store.commit("SET_FULL_PAGE_LOADING_MESSAGE", undefined);
        }
      })
      .catch(() => {
        store.commit("SET_FULL_PAGE_LOADING_MESSAGE", undefined);
      })
      .finally(() => {
        store.commit("SET_FULL_PAGE_LOADING_MESSAGE", undefined);
      });
  }

  async function executeBullhorn() {
    if (!exportData.value)
      return;

    store.commit("SET_FULL_PAGE_LOADING_MESSAGE", loadingTitle.value);
    capture("imua-export-pdf-bullhorn-construction", { project_id: id });

    await exportConstructionBullhorn(exportData.value)
      .then((result) => {
        if (result.success) {
          // For other browsers:
          // Create a link pointing to the ObjectURL containing the blob.
          const data = window.URL.createObjectURL(result.blob);

          const link = document.createElement("a");
          link.href = data;
          link.download = filename.value;
          // this is necessary as link.click() does not work on the latest firefox
          link.dispatchEvent(
            new MouseEvent("click", {
              bubbles: true,
              cancelable: true,
              view: window,
            }),
          );

          setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
            link.remove();
          }, 100);
        }
        else {
          store.commit("SET_FULL_PAGE_LOADING_MESSAGE", undefined);
        }
      })
      .catch(() => {
        store.commit("SET_FULL_PAGE_LOADING_MESSAGE", undefined);
      })
      .finally(() => {
        store.commit("SET_FULL_PAGE_LOADING_MESSAGE", undefined);
      });
  }

  async function executeShortlist(listHiddePages: string[] = [], type: string) {
    if (!exportData.value)
      return;

    exportData.value.list_hidde_pages = listHiddePages;
    exportData.value.type = type;
    store.commit("SET_FULL_PAGE_LOADING_MESSAGE", loadingTitle.value);
    capture("imua-export-pdf-shortlist", { project_id: id });
    await exportShortlistMigrate(exportData.value)
      .then((result) => {
        if (result.success) {
          // For other browsers:
          // Create a link pointing to the ObjectURL containing the blob.
          const data = window.URL.createObjectURL(result.blob);

          const link = document.createElement("a");
          link.href = data;
          link.download = filename.value;
          // this is necessary as link.click() does not work on the latest firefox
          link.dispatchEvent(
            new MouseEvent("click", {
              bubbles: true,
              cancelable: true,
              view: window,
            }),
          );

          setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
            link.remove();
          }, 100);
        }
        else {
          store.commit("SET_FULL_PAGE_LOADING_MESSAGE", undefined);
        }
      })
      .catch(() => {
        store.commit("SET_FULL_PAGE_LOADING_MESSAGE", undefined);
      })
      .finally(() => {
        store.commit("SET_FULL_PAGE_LOADING_MESSAGE", undefined);
      });
  }

  async function executeBullhornShortlist() {
    if (!exportData.value)
      return;

    store.commit("SET_FULL_PAGE_LOADING_MESSAGE", loadingTitle.value);
    capture("imua-export-pdf-bullhorn-shortlist", { project_id: id });
    await exportBullhornShortlistMigrate(exportData.value)
      .then((result) => {
        if (result.success) {
          // For other browsers:
          // Create a link pointing to the ObjectURL containing the blob.
          const data = window.URL.createObjectURL(result.blob);

          const link = document.createElement("a");
          link.href = data;
          link.download = filename.value;
          // this is necessary as link.click() does not work on the latest firefox
          link.dispatchEvent(
            new MouseEvent("click", {
              bubbles: true,
              cancelable: true,
              view: window,
            }),
          );

          setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
            link.remove();
          }, 100);
        }
        else {
          store.commit("SET_FULL_PAGE_LOADING_MESSAGE", undefined);
        }
      })
      .catch(() => {
        store.commit("SET_FULL_PAGE_LOADING_MESSAGE", undefined);
      })
      .finally(() => {
        store.commit("SET_FULL_PAGE_LOADING_MESSAGE", undefined);
      });
  }

  return {
    data: toRaw(exportData),
    pages,
    execute,
    executeShortlist,
    executeBullhorn,
    executeBullhornShortlist,
  };
}
