<script setup lang="ts">
import { computed, inject, ref, toRef } from "vue";
import { Icon } from "@iconify/vue";
import { useI18n } from "vue-i18n";
import SearchProject from "./components/SearchProject.vue";
import { SvgIcon } from "@/components/icon";
import type { Person } from "@/schemas/person";
import type { IPersonContent } from "@/components/person_card/components";
import { Button2 as Button } from "@/components/button";

const props = withDefaults(
  defineProps<{
    person: Person
    personContent?: IPersonContent | null
    hasSearchProject?: boolean
    canBeRestrictedProfile?: boolean
  }>(),
  {
    hasSearchProject: false,
    canBeRestrictedProfile: false,
    personContent: null,
  },
);

const { t } = useI18n();

const onShowDetails = inject("onShowDetails", null) as ((person: Person) => void) | null;

const isHovered = ref(false);

const person = toRef(props, "person");

const fullName = computed(() => {
  return `${person.value.first_name} ${person.value.last_name}`;
});

const nameColor = computed(() => {
  return person.value.restricted_profile || person.value.is_added_in_placement ? "#666" : "#c23584";
});
function goToEditPerson(id: string) {
  return `/persons/create?id=${id}&edit=${true}`;
}
</script>

<template>
  <div class="person-card-header">
    <div class="inf-container">
      <div class="info">
        <h6 v-if="personContent?.name">
          {{ personContent.name }}
        </h6>
        <h6 v-else>
          {{ fullName }}
        </h6>
        <p>({{ person.serial }})</p>
        <slot name="actions">
          <div class="icons">
            <a :href="person.linkedin_url" target="_blank">
              <SvgIcon
                v-show="!isHovered"
                icon="linkedin_icon"
                margin="none"
                color="#ccc"
                width="0.875rem"
                height="0.875rem"
                type="native"
                @mouseenter="isHovered = true"
              />
              <SvgIcon
                v-show="isHovered"
                icon="linkedin_icon_blue"
                margin="none"
                color="#ccc"
                width="0.875rem"
                height="0.875rem"
                @mouseleave="isHovered = false"
              />
            </a>
            <router-link
              class="chain-icon"
              :text-tooltip="t('text.viewProfileInNewPage')"
              target="_blank"
              :to="`/persons/${person.id}`"
            >
              <Icon icon="akar-icons:link-chain" color="#666" width="0.875rem" height="0.875rem" />
            </router-link>
            <SvgIcon
              class="edit-icon"
              icon="edit_icon"
              margin="none"
              width="0.875rem"
              height="0.875rem"
              @click="onShowDetails ? onShowDetails(person) : null"
            />
          </div>
          <div class="btn">
            <Button v-if="person.is_added_in_placement && !canBeRestrictedProfile" class="pl-1" variation="dark" filled>
              {{ t("persons.restrictedProfile") }}
            </Button>
            <SearchProject v-else-if="hasSearchProject" :selected="person" class="pl-1" />
            <router-link :to="goToEditPerson(String(person.id))" style="text-decoration: none">
              <Button v-if="hasSearchProject && person.id" class="header-button" variation="primary">
                <SvgIcon icon="parser_edit" margin="none" width="12" height="12" />
                {{ t("persons.details.editWithParser") }}
              </Button>
            </router-link>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
:deep(.imua-button) {
  padding: 0.375rem 0.75rem;
}
.person-card-header {
  display: flex;
  justify-content: space-between;

  .inf-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 1rem;

    .btn {
      flex-shrink: 0;
      margin-inline: auto 0;
      display: flex;
      flex-flow: column;
      gap: 1rem;

      :deep(.person-card__info__header__button) {
        width: 100%;
      }

      .header-button {
        display: flex;
        align-items: center;
        gap: 0.25rem;
      }
    }

    .info *:not(.btn, .pl-1, svg, a) {
      padding-top: 0.5rem;
      margin: 0;
    }

    .info {
      display: flex;
      width: 100%;
      flex-shrink: 1;
      align-items: flex-start;
      gap: 1rem;

      p {
        color: v-bind(nameColor);
        font-size: 0.75rem;
      }

      h6 {
        color: v-bind(nameColor);
        font-size: 1.125rem;
        text-transform: initial;
      }
    }
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  svg {
    cursor: pointer;
  }
}

.icons {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}

.chain-icon {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    svg {
      color: $primary !important;
    }

    &::before {
      position: absolute;
      z-index: 100;
      top: 1.5rem;
      left: 0;
      display: inline-block;
      width: 12ch;
      padding: 0.75rem;
      border-radius: 8px;
      background-color: $white-full;
      box-shadow: 0 2px 4px #0003;
      color: $primary;
      content: attr(text-tooltip);
      font-size: 0.75rem;
      font-weight: 600;
    }
  }
}

svg.edit-icon:hover {
  color: $primary;
}

.pl-1.imua-button--dark-filled {
  background-color: $gray-dark;
  color: white;
  cursor: auto;
}
</style>
