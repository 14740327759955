import { attributes } from "./attributes";
import { corporations } from "./corporations";
import { cultural_match } from "./cultural_match";
import { date } from "./date";
import { filters } from "./filters";
import { footer } from "./footer";
import { header } from "./header";
import { input } from "./input";
import { languages } from "./languages";
import { login } from "./login";
import { persons } from "./persons";
import { placeholders } from "./placeholders";
import { privacy } from "./privacy";
import { projects } from "./projects";
import { terms } from "./terms";
import { text } from "./text";
import { validator } from "./validator";
import { concla } from "./concla";
import { exportPDF } from "./export";
import { errors } from "./errors";
import { modals } from "./modals";

export const messages = {
  pt: {
    translations: {
      header,
      footer,
      input,
      login,
      validator,
      date,
      persons,
      corporations,
      text,
      languages,
      filters,
      terms,
      privacy,
      placeholders,
      projects,
      attributes,
      cultural_match,
      concla,
      exportPDF,
      errors,
      modals,
    },
  },
};
