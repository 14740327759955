export const header = {
  persons: "People",
  createPerson: "Create new profile",
  editPerson: "Edit profile with parser",
  companies: "Companies",
  company: "Company",
  positions: "Positions",
  projects: "Projects",
  project: "Project",
};
