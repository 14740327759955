import { activitiesService } from "@/http_services/client";
import type {
  BaseOrganizationAndAssembly,
  OrganizationAndAssembly,
  OrganizationAndAssemblySchemaDto,
} from "@/types/construction-organization";
import { OrganizationAndAssemblySchemaDtoSchema } from "@/types/construction-organization";
import type { IdealProfilePatch } from "@/types/ideal-profile";

export async function getConstructionOrganization(project_id: string): Promise<OrganizationAndAssemblySchemaDto> {
  return activitiesService.get(`constructions/organization-and-assembly/project/${project_id}`)
    .then(res => res.data?.data)
    .then((data) => {
      const result = OrganizationAndAssemblySchemaDtoSchema.safeParse(data);
      if (!result.success)
        console.warn("[construction-organization]", project_id, result.error);
      return data;
    });
}

export async function addConstructionOrganization(organization_and_assembly: BaseOrganizationAndAssembly) {
  return activitiesService.post("constructions/organization-and-assembly", { organization_and_assembly })
    .then(res => res.data?.data);
}

export async function patchConstructionOrganization(
  id: string,
  // dont use to update ideal profile
  organization_and_assembly: Omit<Partial<OrganizationAndAssembly>, "ideal_profile">,
) {
  return activitiesService.patch(`constructions/organization-and-assembly/${id}`, { organization_and_assembly })
    .then(res => res.data?.data);
}

export async function patchIdealProfile(id: string, ideal_profile: IdealProfilePatch) {
  return activitiesService.patch(`constructions/organization-and-assembly/${id}/ideal-profile`, { ideal_profile })
    .then(res => res.data?.data);
}
