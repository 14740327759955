<script lang="ts">
import type { Ref } from "vue";
import {
  computed,
  defineComponent,
  toRef,
} from "vue";
import type { Person } from "@/http_services/entities/types";
import type { Corporation } from "@/entities/corporation";
import { Card } from "@/components/card";
import { Avatar } from "@/components/avatar";

export default defineComponent({
  name: "ChartPersonCard",
  components: {
    Card,
    Avatar,
  },
  props: {
    employee: {
      type: Object,
      required: true,
    },
    corporation: {
      type: Object,
      required: true,
    },
  },
  emits: ["click:person"],
  setup(props) {
    const employee = toRef(props, "employee") as Ref<Person>;
    const corporation = toRef(props, "corporation") as Ref<Corporation>;

    const currentCompany = computed(() => {
      const { work_experience } = employee.value;
      const { name } = corporation.value;
      return work_experience?.find(
        ({ is_current, company: { name: workName } }) => is_current && workName === name,
      ) || {};
    });

    const employeeInitials = computed(() => {
      const { first_name, last_name } = employee.value;
      return `${first_name?.charAt(0)}${last_name?.charAt(0)}`;
    });

    return {
      currentCompany,
      employeeInitials,
    };
  },
});
</script>

<template>
  <Card
    class="chart-person-card"
    :class="[
      { 'chart-person-card--first': currentCompany.role_name?.toLowerCase() === 'ceo' },
    ]"
  >
    <Avatar
      class="chart-person-card__avatar"
      data-test="chart-person-avatar"
      :src="employee.avatar_url"
      :alt="employeeInitials"
      :has-alternative-icon="false"
    />

    <p
      role="button"
      data-test="chart-person-name"
      class="chart-person-card__person-name"
      @click="$emit('click:person', employee)"
    >
      {{ `${employee.first_name} ${employee.last_name}` }}
    </p>

    <p
      class="chart-person-card__person-job"
      data-test="chart-person-role"
    >
      {{ currentCompany.role_name }}
    </p>
  </Card>
</template>

<style lang="scss" scoped>
  .chart-person-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &--first {
      order: -1;
    }

    &__avatar {
      margin-bottom: 0.875rem;
    }

    &__person-name {
      margin-bottom: 0.25rem;
      color: $primary;
      font-weight: bold;
      text-decoration: none;

      &:hover {
        color: lighten($red-dark, 10%);
      }
    }

    &__person-job {
      font-size: 0.875rem;
      font-weight: bold;
    }
  }
</style>
