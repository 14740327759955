import { computed, markRaw, onMounted, provide, ref, watch } from "vue";
import type { RouteLocationNormalizedLoaded, Router } from "vue-router";
import get from "lodash-es/get";
import type { Store } from "vuex";
import {
  AboutTab,
  OrganizationChartTab,
  ProjectTab,
  TransformationEventTab,
} from "../components/Tabs";
import type { Corporation } from "@/entities/corporation";
import { ComingSoon } from "@/components/coming-soon";
import { getCorporation, updateCorporation } from "@/http_services/entities/corporation";
import { searchPersons } from "@/http_services/entities/persons";
import type { Person } from "@/entities/Person/person";
import PersonDetail from "@/persons/pages/persons/components/PersonDetail/PersonDetail.vue";

const corporation = ref<Corporation | null>(null);
const corporationPayload = ref<Corporation | null>(null);

export type SubmitCorporation = (payload: Corporation) => Promise<{
  success: boolean
}>;
export type SelectPerson = (person: Person) => void;

export function useTabs(t: (key: string) => string) {
  const currentTab = ref("about");
  const tabs = computed(() => [
    {
      id: "about",
      header: t("corporations.details.tabs.about"),
      props: {},
      events: {},
      component: markRaw(AboutTab),
    },
    {
      id: "transformation-events",
      header: t("corporations.details.tabs.transformationEvents"),
      props: {},
      events: {},
      component: markRaw(TransformationEventTab),
    },
    {
      id: "organization",
      header: t("corporations.details.tabs.chart"),
      props: {},
      events: {},
      component: markRaw(OrganizationChartTab),
    },
    {
      id: "position",
      header: t("corporations.details.tabs.position"),
      props: {
        corporation: corporation.value,
      },
      events: {},
      component: markRaw(ProjectTab),
    },
    {
      id: "history",
      header: t("corporations.details.tabs.historic"),
      props: {
        title: t("text.comingSoonTitle"),
        paragraph: t("text.comingSoonParagraph"),
        style: "transform: scale(0.75)",
      },
      events: {},
      component: markRaw(ComingSoon),
    },
    {
      id: "relationship",
      header: t("corporations.details.tabs.relationship"),
      props: {
        title: t("text.comingSoonTitle"),
        paragraph: t("text.comingSoonParagraph"),
        style: "transform: scale(0.75)",
      },
      events: {},
      component: markRaw(ComingSoon),
    },
    {
      id: "insights",
      header: t("corporations.details.tabs.insights"),
      props: {
        title: t("text.comingSoonTitle"),
        paragraph: t("text.comingSoonParagraph"),
        style: "transform: scale(0.75)",
      },
      events: {},
      component: markRaw(ComingSoon),
    },
  ]);

  const availableTabs = computed(() => {
    const tabsMap: { [key: string]: boolean } = {};

    return tabs.value.reduce((map, next) => {
      map[next.id] = true;
      return map;
    }, tabsMap);
  });

  watch(currentTab, (value) => {
    if (availableTabs.value[value] && window)
      window.location.hash = `#${value}`;
  });

  onMounted(() => {
    if (window && window.location.hash)
      currentTab.value = window.location.hash.replace("#", "");
  });

  return {
    currentTab,
    tabs,
  };
}

export function useCorporation({
  router,
  route,
  store,
}: {
  router: Router
  route: RouteLocationNormalizedLoaded
  store: Store<{ fullPageLoadingMessage: string | null }>
}) {
  const loading = ref(false);
  const fetchError = ref();
  const submitionError = ref();
  const hasSubmitionError = computed({
    get: () => !!submitionError.value,
    set: (value) => {
      submitionError.value = value || null;
    },
  });

  const fetchCorporation = async (corporationId?: string) => {
    const id = corporationId || String(route.params.id);

    try {
      loading.value = true;
      corporation.value = await getCorporation(id);
    }
    catch (error) {
      fetchError.value = error;
      router.push("/corporations");
    }
    finally {
      loading.value = false;
    }
  };

  const submitCorporation = async (payload: Corporation) => {
    try {
      submitionError.value = null;
      store.commit("SET_FULL_PAGE_LOADING_MESSAGE", "Loading");

      if (corporation.value?.id) {
        const updated_corporation = await updateCorporation(corporation.value.id, {
          corporation: { ...corporation.value, ...payload },
        });
        corporation.value = updated_corporation;
      }

      return { success: true };
    }
    catch (error) {
      submitionError.value = error;
      return { success: false };
    }
    finally {
      store.commit("SET_FULL_PAGE_LOADING_MESSAGE");
    }
  };

  const provideCorporation = () => {
    provide("corporation", corporation);
    provide("corporationPayload", corporationPayload);
    provide("submitCorporation", submitCorporation);
  };

  return {
    corporation,
    corporationPayload,
    loading,
    fetchError,
    submitionError,
    hasSubmitionError,
    fetchCorporation,
    submitCorporation,
    provideCorporation,
  };
}

export function useEmployees() {
  const employees = ref<Person[]>([]);
  const loading = ref(false);
  const fetchError = ref();
  const paginationParams = ref({ limit: 30, skip: 0 });

  const fetchEmployees = async (corporationName: string) => {
    try {
      loading.value = true;
      const { data: allEmployees } = await searchPersons({
        ...paginationParams.value,
        companies: corporationName,
      });

      employees.value = allEmployees.filter((person) => {
        const { work_experience } = person;
        return work_experience.some(
          ({ company, is_current }) => get(company, "corporation.name", "") === corporationName && is_current,
        );
      });
    }
    catch (error) {
      fetchError.value = error;
    }
    finally {
      loading.value = false;
    }
  };

  const provideEmployees = () => {
    provide("employees", employees);
  };

  return {
    employees,
    loading,
    fetchError,
    fetchEmployees,
    provideEmployees,
  };
}

export function usePersonDisplay() {
  const showPersonDetails = ref(false);
  const selectedPerson = ref<Person | null>(null);

  const selectPerson = (person: Person) => {
    selectedPerson.value = person;
    showPersonDetails.value = true;
  };

  const closePersonDetails = () => {
    selectedPerson.value = null;
    showPersonDetails.value = false;
  };

  const provideDisplay = () => {
    provide("selectedPerson", selectedPerson);
    provide("selectPerson", selectPerson);
    provide("onCloseDetails", closePersonDetails);
  };

  const PersonComponent = markRaw(PersonDetail);

  return {
    showPersonDetails,
    selectedPerson,
    selectPerson,
    closePersonDetails,
    provideDisplay,
    PersonComponent,
  };
}
