import type {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import Axios from "axios";
import { secondsToMilliseconds } from "../utils/auth";
import { BadClientRequestError, BaseError, NotFoundError } from "./errors";

import { AuthenticationError } from "./errors/authentication";
import type { Error } from "./types";
import type { RefreshTokenResponse } from "./auth/types";
import { env } from "@/env";

function makeService<T, U>(client: AxiosInstance) {
  return {
    async get(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse> {
      return client.get(url, config);
    },
    async post(url: string, data: T, config?: AxiosRequestConfig): Promise<AxiosResponse> {
      return client.post(url, data, config);
    },
    async put(url: string, data: U): Promise<AxiosResponse> {
      return client.put(url, data);
    },
    async del(url: string): Promise<AxiosResponse> {
      return client.delete(url);
    },
    async patch(url: string, data: U): Promise<AxiosResponse> {
      return client.patch(url, data);
    },
    client(): AxiosInstance {
      return client;
    },
  };
}

// Create Axios instances
// const authAxios = Axios.create({
//   baseURL: env.VITE_APP_AUTH_URL,
//   headers: {
//     "Content-Type": "application/json",
//   },
// });

const authAxios = Axios.create({
  baseURL: env.VITE_APP_AUTH_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const resumeAxios = Axios.create({
  baseURL: env.VITE_APP_RESUME_PARSER_URL,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
});

const importCandidatesAxios = Axios.create({
  baseURL: env.VITE_APP_IMPORT_CSV_URL,
  headers: {
    "Content-Type": "multipart/form-data",
    "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
    "authorized-user": env.VITE_APP_AUTHORIZED_USER,
  },
});

function authenticatedFactoryAxios(url: string) {
  const headers = {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${localStorage.getItem("access_token")}`,
    "authorized-user": env.VITE_APP_AUTHORIZED_USER,
  };

  const headersEntries = Object.entries(headers).filter(([, value]) => value);

  const validHeaders = Object.fromEntries(headersEntries);

  return Axios.create({
    baseURL: url,
    headers: validHeaders,
  });
}

const responseHandler = (response: AxiosResponse<unknown>) => response;

async function errorHandler(url: string, error: AxiosError) {
  // Handlers for client errors.
  const handlers = {
    400: ({ data, statusText, status }) => new BadClientRequestError<Error<string>[]>(data.errors, statusText, status),
    422: ({ data, statusText, status }) => new BadClientRequestError<Error<string>[]>(data.errors, statusText, status),
    404: ({ data, statusText, status }) => new NotFoundError<Error<string>[]>(data.errors, statusText, status),
    401: ({ data, statusText, status }) => {
      if (localStorage.getItem("refresh_token")) {
        return authAxios
          .post("identity/exchange", {
            login: {
              refresh_token: localStorage.getItem("refresh_token"),
            },
          })
          .then((res: { data: RefreshTokenResponse }) => {
            const exchangeData = res.data.data;
            localStorage.setItem("access_token", exchangeData.access_token);
            localStorage.setItem(
              "access_expiry",
              String(secondsToMilliseconds(exchangeData.access_expiry)),
            );

            // update authenticated axios instances
            error.config.headers.Authorization = `Bearer ${exchangeData.access_token}`;
            return authenticatedFactoryAxios(url)(error.config);
          })
          .catch(() => {
            const authKeys = ["access_token", "access_expiry", "refresh_token", "refresh_expiry"];

            Object.keys(authKeys).forEach(key => localStorage.removeItem(key));
            return new AuthenticationError<Error<string>[]>(data.errors, statusText, status);
          });
      }

      return new AuthenticationError<Error<string>[]>(data.errors, statusText, status);
    },
    default: ({ statusText, status, data }) => new BaseError(statusText, status, data),
  };

  if (error.response === undefined) {
    // If there is no response, something went wrong with the connection
    // or the server. Either way, let it crash and fix the bug instead of
    // swallowing the error.
    return Promise.reject(error);
  }
  const handle = handlers[error.response.status] || handlers.default;
  const result = await handle(error.response);

  // if the error was handled and the status has changed
  if (error.response.status !== result.status) {
    return new Promise((resolve, reject) => {
      result.status.toString().includes("20") ? resolve(result) : reject(result);
    });
  }
  return Promise.reject(result);
}

async function resumeAuthenticator({ data, statusText, status, error }) {
  const body = new FormData();

  body.append("username", env.VITE_APP_RESUME_PARSER_USER);
  body.append("password", env.VITE_APP_RESUME_PARSER_PASSWORD);

  return resumeAxios
    .post("login/access-token", body)
    .then((response) => {
      error.config.headers.Authorization = `Bearer ${response.data.access_token}`;

      localStorage.setItem("imua_parser_acess_token", response.data.access_token);

      return (resumeAxios)(error.config);
    })
    .catch(() => {
      return new AuthenticationError<Error<string>[]>(data.errors, statusText, status);
    });
}

async function resumeErrorHandler(url: string, error: AxiosError) {
  const handlers = {
    401: ({ data, statusText, status }) => {
      return resumeAuthenticator({ data, statusText, status, error });
    },
    403: ({ data, statusText, status }) => {
      return resumeAuthenticator({ data, statusText, status, error });
    },
    default: ({ statusText, status }) => new BaseError(statusText, status),
  };

  const handle = handlers[error.response.status] || handlers.default;
  const result = await handle(error.response);

  if (error.response.status !== result.status) {
    return new Promise((resolve, reject) => {
      result.status.toString().includes("20") ? resolve(result) : reject(result);
    });
  }
  return Promise.reject(result);
}

export const entitiesAxios = authenticatedFactoryAxios(env.VITE_APP_ENTITIES_URL);
const projectsAxios = authenticatedFactoryAxios(env.VITE_APP_PROJECTS_URL);
const activitiesAxios = authenticatedFactoryAxios(env.VITE_APP_ACTIVITIES_URL);
const candidatesAxios = authenticatedFactoryAxios(env.VITE_APP_CANDIDATES_URL);
const filesAxios = authenticatedFactoryAxios(env.VITE_APP_FILE_URL);
const searchFieldAxios = authenticatedFactoryAxios(env.VITE_APP_SEARCH_FIELD_URL);

function requestHandler(request: AxiosRequestConfig) {
  request.headers.Authorization = `Bearer ${localStorage.getItem("access_token")}`;
  return request;
}

function resumeRequestHandler(request: AxiosRequestConfig) {
  request.headers.Authorization = `Bearer ${localStorage.getItem("imua_parser_acess_token")}`;
  return request;
}

// Registers Axios interceptors
entitiesAxios.interceptors.response.use(
  response => responseHandler(response),
  error => errorHandler(env.VITE_APP_ENTITIES_URL, error),
);
entitiesAxios.interceptors.request.use(
  request => requestHandler(request),
  error => errorHandler(env.VITE_APP_ENTITIES_URL, error),
);

projectsAxios.interceptors.response.use(
  response => responseHandler(response),
  error => errorHandler(env.VITE_APP_ENTITIES_URL, error),
);
projectsAxios.interceptors.request.use(
  request => requestHandler(request),
  error => errorHandler(env.VITE_APP_ENTITIES_URL, error),
);

activitiesAxios.interceptors.response.use(
  response => responseHandler(response),
  error => errorHandler(env.VITE_APP_ACTIVITIES_URL, error),
);
activitiesAxios.interceptors.request.use(
  request => requestHandler(request),
  error => errorHandler(env.VITE_APP_ACTIVITIES_URL, error),
);

// Registers Resume Axios interceptors
resumeAxios.interceptors.response.use(
  response => responseHandler(response),
  error => resumeErrorHandler(env.VITE_APP_RESUME_PARSER_URL, error),
);

resumeAxios.interceptors.request.use(
  request => resumeRequestHandler(request),
  error => resumeErrorHandler(env.VITE_APP_RESUME_PARSER_URL, error),
);

// Registers Candidate Axios interceptors
candidatesAxios.interceptors.response.use(
  response => responseHandler(response),
  error => errorHandler(env.VITE_APP_CANDIDATES_URL, error),
);
candidatesAxios.interceptors.request.use(
  request => requestHandler(request),
  error => errorHandler(env.VITE_APP_CANDIDATES_URL, error),
);
searchFieldAxios.interceptors.request.use(
  request => requestHandler(request),
  error => errorHandler(env.VITE_APP_SEARCH_FIELD_URL, error),
);

importCandidatesAxios.interceptors.response.use(
  response => responseHandler(response),
  error => errorHandler(env.VITE_APP_IMPORT_CSV_URL, error),
);
importCandidatesAxios.interceptors.request.use(
  request => requestHandler(request),
  error => errorHandler(env.VITE_APP_IMPORT_CSV_URL, error),
);

filesAxios.interceptors.response.use(
  response => responseHandler(response),
  error => errorHandler(env.VITE_APP_FILE_URL, error),
);
filesAxios.interceptors.request.use(
  request => requestHandler(request),
  error => errorHandler(env.VITE_APP_FILE_URL, error),
);

// Create services
export const authService = makeService(authAxios);
export const entitiesService = makeService(entitiesAxios);
export const activitiesService = makeService(activitiesAxios);
export const projectsService = makeService(projectsAxios);
export const filesService = makeService(filesAxios);
export const resumeService = makeService(resumeAxios);
export const importCSVService = makeService(importCandidatesAxios);
export const candidatesService = makeService(candidatesAxios);
export const searchFieldService = makeService(searchFieldAxios);
