<script setup lang="ts">
import Calendar from "primevue/calendar";
import { format, parseISO } from "date-fns";
import { vOnClickOutside } from "@vueuse/components";
import { SvgIcon } from "@/components/icon";
import type { ScreeningActivity } from "@/schemas/project";

const props = defineProps<{
  activity: ScreeningActivity
  modelValue: string | null
}>();
const emit = defineEmits<{
  (event: "submit", value: string): void
}>();
const { t } = useI18n();

const presentationDate = ref(props.modelValue);
const datesRef: Ref<HTMLInputElement | null> = ref(null);
const editingDate = ref(false);

const onClickOutsideHandler: [() => void, { ignore: (Ref<HTMLDivElement | null> | string)[] }] = [
  () => {
    editingDate.value = false;
  },
  {
    ignore: [
      datesRef,
      ".p-datepicker",
    ],
  },
];

function fixCalendarFocusTrap(): void {
  if (!datesRef.value)
    return;

  datesRef.value.updateFocus = () => {
    const inputEl: HTMLInputElement | null = datesRef.value.input;

    if (inputEl)
      inputEl.focus();
  };
}

watch(datesRef, () => fixCalendarFocusTrap(), {
  immediate: true,
});

watchEffect(() => {
  presentationDate.value = props.modelValue;
});
function checkShowEditDate(id: string) {
  return editingDate.value;
}
function formatDate(date: string | null | undefined) {
  if (!date)
    return "";
  return format(parseISO(date), "dd/MM/yyyy");
}
</script>

<template>
  <div v-on-click-outside.bubble="onClickOutsideHandler" class="dates">
    <div
      v-if="!activity?.candidate && !activity?.activity_date || activity?.candidate && !activity.activity_date"
    >
      <span>
        {{ t("text.toBeDefined") }}
      </span>
    </div>
    <div v-if="checkShowEditDate(activity?._id) && activity?.candidate && activity?.activity_date">
      <Calendar
        id="activity_date"
        ref="datesRef"
        v-model="presentationDate"
        v-maska="'##/##/####'"
        :placeholder="t('text.emptyDate')"
        date-format="dd/mm/yy"
        @update:model-value="emit('submit', $event)"
        @keyup.enter="editingDate = !editingDate"
      />
      <SvgIcon
        class="icon-edit"
        icon="edit_icon"
        margin="none"
        width="1.1rem"
        height="1.1rem"
        @click="editingDate = !editingDate"
      />
    </div>
    <div v-if="!checkShowEditDate(activity?._id) && activity?.activity_date">
      <span>
        {{ formatDate(activity?.activity_date) }}
      </span>
      <SvgIcon
        class="icon-edit"
        icon="edit_icon"
        margin="none"
        width="1.1rem"
        height="1.1rem"
        @click="editingDate = !editingDate"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
  .dates div {

    display: grid;
    grid-template-columns: 1fr auto;
    place-items: center;
    gap: 1.5rem;
    width: 9.125rem;
    height: 2rem;
    span {
      line-height: 2rem;
      width: 100%;
    }
  :deep(.p-inputtext.p-component) {
    font-size: 1rem;
    border: 1px solid $gray-lower;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
    &:hover {
      border-color: $gray-lower;
    }
    &::placeholder {
      color: $gray-border;
    }
  }
  svg {
    cursor: pointer;
    &:hover {
      stroke: $red-violet;
    }
  }
}
</style>
