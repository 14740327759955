<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { SvgIcon } from "@/components/icon";

const props = withDefaults(
  defineProps<{
    linebreak: boolean
    asExport?: boolean
    opinion: string
    ellipsis?: any
    showEllipisisDots?: boolean
    fontSize?: string
  }>(),
  {
    opinion: "",
    fontSize: "1rem",
    asExport: false,
  },
);

const emit = defineEmits(["onEdit"]);

const { t } = useI18n();

function formatBreakLines(text: string | null | undefined) {
  if (!text)
    return text;
  return text.replaceAll("\n", "<br />");
}
</script>

<template>
  <div class="opinion__content">
    <div class="opinion__content--edit-btn" :title="t('projects.shortlist.labels.editOpinion')">
      <SvgIcon
        v-show="asExport === false"
        icon="edit_icon"
        type="native"
        width="1.2rem"
        height="1.2rem"
        margin="0.25rem"
        @click="emit('onEdit')"
      />
    </div>
    <!-- <div
      v-if="linebreak && ellipsis" v-ellipsis="{
        maxLength: ellipsis.maxLength,
        showEllipsis: ellipsis.showEllipsis,
      }" v-linebreak class="opinion__content--paragraph"
    >
      <span v-html="formatBreakLines(opinion)" />
    </div>
    <div v-else-if="linebreak" v-linebreak class="opinion__content--paragraph">
      <span v-html="formatBreakLines(opinion)" />
    </div>
    <div
      v-else-if="ellipsis" v-ellipsis="{
        maxLength: ellipsis.maxLength,
        showEllipsis: ellipsis.showEllipsis,
      }" class="opinion__content--paragraph" v-html="formatBreakLines(opinion)"
    /> -->
    <div class="opinion__content--paragraph" v-html="formatBreakLines(opinion)" />
  </div>
</template>

<style lang="scss" scoped>
.opinion {
  &__content {
    &--edit-btn {
      position: absolute;
      top: 1.75rem;
      right: 1.75rem;
      cursor: pointer;
    }

    &--paragraph {
      padding: 0 1.5rem;
      font-size: v-bind("fontSize");
      line-height: 1.25rem;
      word-break: break-word;
    }
  }
}
</style>
