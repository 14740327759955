<script lang="ts">
import type { Ref } from "vue";
import { onMounted, ref, toRef, watch } from "vue";
import DisplayCard from "@/assets/images/display_card_icon.svg";

// import DisplayBlocks from "@/assets/images/display_blocks_icon.svg";
// import DisplayList from "@/assets/images/display_list_icon.svg";

export default {
  components: {
    DisplayCard,
    // DisplayBlocks,
    // DisplayList,
  },
  props: {
    selectedType: String,
    onViewModeChange: Function,
  },
  setup(props) {
    const selectedIcon = ref("");

    const selectedType = toRef(props, "selectedType") as Ref<string>;
    watch(selectedType, (value) => {
      selectedIcon.value = value;
    });
    const onViewModeChange = toRef(props, "onViewModeChange") as Ref<(arg: any) => void>;

    const isActive = (type: string) => selectedIcon.value === type;
    const onClick = (type: string) => {
      selectedIcon.value = type;
      if (onViewModeChange.value)
        onViewModeChange.value(type);
    };

    onMounted(() => {
      selectedIcon.value = selectedType.value;
    });

    return { selectedIcon, isActive, onClick };
  },
};
</script>

<template>
  <div class="icons-wrapper">
    <DisplayCard
      class="icon"
      :class="{ active: isActive('card') }"
      @click="() => onClick('card')"
    />
    <!-- <DisplayBlocks
      @click="() => onClick('block')"
      class="icon"
      :class="{ active: isActive('block') }"
    />
    <DisplayList
      @click="() => onClick('list')"
      class="icon decrease-size"
      :class="{ active: isActive('list') }"
    /> -->
  </div>
</template>

<style scoped lang="scss">
@import "../../styles/colors";

.icons-wrapper {
  display: flex;
  width: 70px;
  justify-content: flex-end;
  margin-right: 30px;

  .icon {
    width: 20px;
    height: 20px;
    margin-top: 0.2rem;
    cursor: pointer;

    &.active {
      fill: $primary;
      stroke: $primary;
    }

    &.decrease-size {
      height: 17px;
      margin-top: 1.5px;
    }
  }
}
</style>
