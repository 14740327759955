import type { Corporation } from "../common";

export enum DegreeLevel {
  BACHELOR = "BACHELOR",
  POST_BACHELOR = "POST_BACHELOR",
  MBA = "MBA",
  MASTER = "MASTER",
  DOCTORATE = "DOCTORATE",
  POST_DOCTORATE = "POST_DOCTORATE",
  EXTRA_CURRICULAR_COURSE = "EXTRA_CURRICULAR_COURSE",
}

export interface Education {
  institution?: Corporation
  degree_level?: DegreeLevel
  course_title?: string
  start_date?: string
  end_date?: string
}
