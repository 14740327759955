export enum ANALYSIS_TYPES {
  GOVERNANCE = "GOVERNANCE",
  GOVERNANCE_HISTORIC_AND_COMPOSITION = "GOVERNANCE_HISTORIC_AND_COMPOSITION",
  GOVERNANCE_DECISION_STRUCTURE = "GOVERNANCE_DECISION_STRUCTURE",
  GOVERNANCE_ORGANIZATIONAL_CHART = "GOVERNANCE_ORGANIZATIONAL_CHART",
  GOVERNANCE_CULTURE = "GOVERNANCE_CULTURE",
  STRATEGY = "STRATEGY",
  STRATEGY_BUSINESS = "STRATEGY_BUSINESS",
  STRATEGY_CORPORATION_MOMENT = "STRATEGY_CORPORATION_MOMENT",
  STRATEGY_MOMENT = "STRATEGY_MOMENT",
  EXECUTION_CONDITIONS = "EXECUTION_CONDITIONS",
  EXECUTION_CONDITIONS_TEAM_MATURITY = "EXECUTION_CONDITIONS_TEAM_MATURITY",
  EXECUTION_CONDITIONS_POSITION_MANDATE = "EXECUTION_CONDITIONS_POSITION_MANDATE",
  EXECUTION_CONDITIONS_PROCESSES_MATURITY = "EXECUTION_CONDITIONS_PROCESSES_MATURITY",
  EXECUTION_CONDITIONS_BUDGET_AVAILABILITY = "EXECUTION_CONDITIONS_BUDGET_AVAILABILITY",
  IDEAL_PROFILE = "IDEAL_PROFILE",
  REALIZATIONS = "REALIZATIONS",
}
