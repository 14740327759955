import { z } from "zod";

export enum ProjectTypeEnum {
  BOARD = "BOARD",
  CEO_AND_EXECUTIVE_OFFICERS = "CEO_AND_EXECUTIVE_OFFICERS",
  LEADERSHIP_SUCCESSION = "LEADERSHIP_SUCCESSION",
  MAPPING = "MAPPING",
  ASSESSMENT = "ASSESSMENT",
  SCOPE_DESIGN = "SCOPE_DESIGN",
}

export enum PositionClassEnum {
  CLASS_A = "CLASS_A",
  CLASS_B = "CLASS_B",
  CLASS_C = "CLASS_C",
}

export enum PlacementTypeEnum {
  NEW_CLIENT = "NEW_CLIENT",
  ACTIVE_RECURRENCE = "ACTIVE_RECURRENCE",
  PASSIVE_RECURRENCE = "PASSIVE_RECURRENCE",
  REACTIVATION = "REACTIVATION",
}

export enum ConstructionActivityDescriptionEnum {
  CONSTRUCTION_MEETING = "CONSTRUCTION_MEETING",
  VALIDATION_MEETING = "VALIDATION_MEETING",
  INTERVIEWS = "INTERVIEWS",
  RESEARCH_AND_PICKING = "RESEARCH_AND_PICKING",
}

export enum ScreeningActivityDescriptionEnum {
  SCREENING_MEETING = "SCREENING_MEETING",
}

export enum InterviewActivityDescriptionEnum {
  RECRUITER_MEETING = "RECRUITER_MEETING",
}

export enum AnalysisTypesEnum {
  STRATEGY = "STRATEGY",
  GOVERNANCE = "GOVERNANCE",
  EXECUTION_CONDITIONS = "EXECUTION_CONDITIONS",
  IDEAL_PROFILE = "IDEAL_PROFILE",
  REALIZATIONS = "REALIZATIONS",
}

export enum EducationDegreeEnum {
  BACHELOR = "BACHELOR",
  POST_BACHELOR = "POST_BACHELOR",
  MBA = "MBA",
  MASTER = "MASTER",
  DOCTORATE = "DOCTORATE",
  POST_DOCTORATE = "POST_DOCTORATE",
  EXTRA_CURRICULAR_COURSE = "EXTRA_CURRICULAR_COURSE",
  EMPTY = "EMPTY",
}

export enum InstitutionTypeEnum {
  EMPTY = "EMPTY",
  MANDATORILY_FIRST_LINE = "MANDATORILY_FIRST_LINE",
  PREFERENTIALLY_FIRST_LINE = "PREFERENTIALLY_FIRST_LINE",
  INDIFFERENT = "INDIFFERENT",
}

export enum ExtensionTypeEnum {
  EMPTY = "EMPTY",
  POST_BACHELOR = "POST_BACHELOR",
  MBA = "MBA",
}

export enum ReasonsToLeaveEnum {
  EARNINGS = "EARNINGS",
  REMUNERATION_SYSTEM = "REMUNERATION_SYSTEM",
  CAREER_LIMITATIONS = "CAREER_LIMITATIONS",
  LACK_OF_RELOCATION_OPPORTUNITIES = "LACK_OF_RELOCATION_OPPORTUNITIES",
  BUSINESS_TYPE = "BUSINESS_TYPE",
  FAMILY_SITUATION = "FAMILY_SITUATION",
  BOSS_RELATIONSHIP = "BOSS_RELATIONSHIP",
  PEERS = "PEERS",
  COMPANY_CULTURE = "COMPANY_CULTURE",
}

export enum ChangeJobEnum {
  BUSINESS_FUNCTION = "BUSINESS_FUNCTION",
  PEOPLE_PROFILE = "PEOPLE_PROFILE",
  COMPANY_CULTURE = "COMPANY_CULTURE",
}

export enum CareerPathEnum {
  GREATER_POSITION = "GREATER_POSITION",
  GREATER_CAREER = "GREATER_CAREER",
  WORK_ABROAD = "WORK_ABROAD",
  WORKPLACE = "WORKPLACE",
}

export const ProjectTypeEnumSchema = z.nativeEnum(ProjectTypeEnum);
export const PositionClassEnumSchema = z.nativeEnum(PositionClassEnum);
export const PlacementTypeEnumSchema = z.nativeEnum(PlacementTypeEnum);
export const ConstructionActivityDescriptionEnumSchema = z.nativeEnum(
  ConstructionActivityDescriptionEnum,
);
export const ScreeningctivityDescriptionEnumSchema = z.nativeEnum(
  ScreeningActivityDescriptionEnum,
);
export const AnalysisTypesEnumSchema = z.nativeEnum(AnalysisTypesEnum);
export const EducationDegreeEnumSchema = z.nativeEnum(EducationDegreeEnum);
export const InstitutionTypeEnumSchema = z.nativeEnum(InstitutionTypeEnum);
export const ExtensionTypeEnumSchema = z.nativeEnum(ExtensionTypeEnum);
export const ReasonsToLeaveEnumSchema = z.nativeEnum(ReasonsToLeaveEnum);
export const ChangeJobEnumSchema = z.nativeEnum(ChangeJobEnum);
