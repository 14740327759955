<script setup lang="ts">
import { paginateTexts } from "@/utils/text";
import { formatScore } from "@/utils/numbers";
import ExportPage from "@/common/components/Export/ExportPage.vue";

const props = withDefaults(
  defineProps<{
    isExport?: boolean
    opinion?: string | null
    personName?: string | null
    scores: {
      academic_formation_mean: number | null
      experiences_mean: number | null
      achievements_mean: number | null
      attributes_mean: number | null
      cultural_match_mean: number | null
      motivation_mean: number | null
      final_mean: number | null
    } | null
  }>(),
  {
    personName: null,
    opinion: null,
    isExport: false,
  },
);

const { t } = useI18n();

interface PagesType {
  opinion: string | null
}

const pages = ref<PagesType[]>([
  { opinion: null },
]);

function paginatePages() {
  const paginateOpinion = paginateTexts(props.opinion || "", "16px", 930, 17);
  paginateOpinion.forEach((item, index) => {
    if (!pages.value[index])
      pages.value.push({ opinion: null });
    pages.value[index].opinion = item.text;
  });
}

function getScore(value: any) {
  const newScore = formatScore(value || 0);
  return `${newScore}%`;
}

watchEffect(() => {
  pages.value = [{ opinion: null }];
  paginatePages();
});
</script>

<template>
  <ExportPage
    v-for="(page, index) in pages"
    :key="index"
    :page-index="index"
    :is-export="isExport"
    :person-name="personName"
    :page-name="t('exportPDF.evaluation')"
    :opinion="page.opinion"
  >
    <div class="pdf-user-evaluation">
      <div class="box-item adherence">
        <div class="item-title">
          {{ t("exportPDF.labels.adherenceIdealProfile") }}
        </div>
        <div class="item-value">
          {{ getScore(scores?.final_mean) }}
        </div>
      </div>
      <div class="box-notes">
        <div class="box-item">
          <div class="item-title">
            {{ t("exportPDF.labels.schooling") }}
          </div>
          <div class="item-value">
            {{ getScore(scores?.academic_formation_mean) }}
          </div>
        </div>
        <div class="box-item">
          <div class="item-title">
            {{ t("exportPDF.labels.experienceProfessional") }}
          </div>
          <div class="item-value">
            {{ getScore(scores?.experiences_mean) }}
          </div>
        </div>
        <div class="box-item">
          <div class="item-title">
            {{ t("exportPDF.labels.realizations") }}
          </div>
          <div class="item-value">
            {{ getScore(scores?.achievements_mean) }}
          </div>
        </div>
        <div class="box-item">
          <div class="item-title">
            {{ t("exportPDF.labels.attributes") }}
          </div>
          <div class="item-value">
            {{ getScore(scores?.attributes_mean) }}
          </div>
        </div>
        <div class="box-item">
          <div class="item-title">
            {{ t("exportPDF.labels.culturalMatch") }}
          </div>
          <div class="item-value">
            {{ getScore(scores?.cultural_match_mean) }}
          </div>
        </div>
        <div class="box-item">
          <div class="item-title">
            {{ t("exportPDF.labels.motivation") }}
          </div>
          <div class="item-value">
            {{ getScore(scores?.motivation_mean) }}
          </div>
        </div>
      </div>
    </div>
  </ExportPage>
</template>

<style lang="scss" scoped>
.pdf-user-evaluation {
  display: flex;
  flex-direction: row;
  padding-top: 2rem;
  gap: 3.875rem;

  .box-item {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 1rem;

    .item-title {
      width: 100%;
      height: 3.25rem;
      font-size: 0.875rem;
      font-weight: bold;
      line-height: 1.5;
      text-align: center;
      text-transform: uppercase;
    }

    .item-value {
      display: flex;
      width: 100%;
      height: 3.25rem;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 1px solid $gray-border;
      border-radius: 3px;
      font-size: 1.125rem;
      font-weight: bold;
    }
  }

  .box-notes {
    display: flex;
    flex: 4;
    flex-direction: row;
    gap: 1.1875rem;

    .item-value {
      font-weight: normal;
    }
  }

  .adherence {
    min-width: 13.5rem;
    flex: 1;

    .item-title {
      padding-top: 0.8rem;
      color: $yellow;
    }

    .item-value {
      border-color: $gray-dark;
      background: $gray-dark;
      color: $yellow;
    }
  }
}
</style>
