<script setup lang="ts">
import ShortlistBody from "./components/ShortlistBody.vue";
import type { SearchCandidate } from "@/common/composables/useShortlist";

enum MotivationMode {
  text = "text",
  attraction = "attraction",
  repulsion = "repulsion",
  retention = "retention",
}

const route = useRoute();
const { t } = useI18n();

const projectId = computed(() => String(route.params.id));
const personID = computed(() => String(route.params.person));

const { data: project, error } = await useProject(String(route.params.id));

const person = ref<SearchCandidate | null>(null);

const {
  data: construction,
} = useConstructionOrganizationQuery(String(projectId.value));

const {
  data: interview,
} = useInterviewEvaluationQuery(String(projectId.value), String(personID.value));

const {
  mutateAsync: patchInterview,
} = usePatchInterviewEvaluationQuery();

const idealProfile = computed(() => construction.value?.ideal_profile);
const interviewId = computed(() => {
  return interview.value?._id;
});

function getMotivation(key: string) {
  const data = interview.value ? interview.value[key] : {};

  return {
    attraction: {
      name: t("projects.interview.titles.attractionShort"),
      type: MotivationMode.attraction,
      value: data?.attraction,
      key,
      canRenderOnEdit: false,
    },
    repulsion: {
      name: t("projects.interview.titles.repulsionShort"),
      type: MotivationMode.repulsion,
      value: data?.repulsion,
      key,
      canRenderOnEdit: false,
    },
    retention: {
      name: t("projects.interview.titles.retentionShort"),
      type: MotivationMode.retention,
      value: data?.retention,
      key,
      canRenderOnEdit: false,
    },
  };
}

const motivations = computed(() => {
  const getI18nString = (strings: TemplateStringsArray) => `projects.construction.titles.${strings[0]}`;
  return [
    {
      title: t(getI18nString`package`),
      score: interview.value?.package?.score,
      items: {
        salary: {
          name: t("projects.construction.labels.salary"),
          type: MotivationMode.text,
          value: idealProfile.value?.package?.salary,
          key: "salary",
          canRenderOnEdit: true,
        },
        short_term_incentive: {
          name: t("projects.construction.labels.icp"),
          type: MotivationMode.text,
          value: idealProfile.value?.package?.short_term_incentive,
          key: "short_term_incentive",
          canRenderOnEdit: true,

        },
        long_term_incentive: {
          name: t("projects.construction.labels.ilp"),
          type: MotivationMode.text,
          value: idealProfile.value?.package?.long_term_incentive,
          key: "long_term_incentive",
          canRenderOnEdit: true,

        },
        other_incentive: {
          name: t("projects.construction.labels.others"),
          type: MotivationMode.text,
          value: idealProfile.value?.package?.other_incentive,
          key: "other_incentive",
          canRenderOnEdit: true,

        },
        total_cash: {
          name: t("projects.construction.labels.totalCash"),
          type: MotivationMode.text,
          value: idealProfile.value?.package?.total_cash,
          key: "total_cash",
          canRenderOnEdit: true,

        },
        ...getMotivation("package"),
      },
    },
    {
      title: t(getI18nString`career`),
      score: interview.value?.career?.score,
      key: "career",
      items: {
        ...Object.entries(idealProfile.value?.career || {})?.map(([_, value]) => ({
          name: null,
          type: MotivationMode.text,
          value,
          canRenderOnEdit: true,
        })),
        ...getMotivation("career"),
      },
    },
    {
      title: t(getI18nString`environment`),
      score: interview.value?.work_environment?.score,
      key: "work_environment",

      items: {
        ...Object.entries(idealProfile.value?.work_environment || {})?.map(([_, value]) => ({
          name: null,
          type: MotivationMode.text,
          value,
          canRenderOnEdit: true,

        })),
        ...getMotivation("work_environment"),
      },
    },
    {
      title: t(getI18nString`personal`),
      score: interview.value?.personal_life?.score,
      key: "personal_life",
      items: {
        ...Object.entries(idealProfile.value?.personal_life || {})?.map(([_, value]) => ({
          name: null,
          type: MotivationMode.text,
          value,
          canRenderOnEdit: true,
        })),
        ...getMotivation("personal_life"),
      },
    },
  ];
});

function formatScore(score: number | null | undefined) {
  try {
    if (!score)
      return 0;
    return (
      (!Number.isInteger(score) ? score?.toFixed(2).replace(".", ",") : score?.toFixed(0)) ?? 0
    );
  }
  catch (error) {
    return 0;
  }
}

async function fetchCandidate() {
  if (!route.params.person)
    return;
  const response = await searchCandidates({
    project_id: String(route.params.id),
    person_id: String(route.params.person),
    project_stage: "SHORTLIST",
    skip: 0,
    limit: 1,
  });
  person.value = (response && response[0]) ? response[0] : null;
}

watchEffect(async () => {
  await fetchCandidate();
});
const isEditing = ref();
interface MotivationType<T> {
  package: T
  career: T
  work_environment: T
  personal_life: T
}

interface ReactiveType {
  attraction: string
  repulsion: string
  retention: string
}

const motivationReactive = reactive<MotivationType<ReactiveType>>({
  package: {
    attraction: "",
    repulsion: "",
    retention: "",
  },
  career: {
    attraction: "",
    repulsion: "",
    retention: "",
  },
  work_environment: {
    attraction: "",
    repulsion: "",
    retention: "",
  },
  personal_life: {
    attraction: "",
    repulsion: "",
    retention: "",
  },
});

type MotivationKeys = keyof typeof motivationReactive;
const interviewKeys = Object.keys(motivationReactive);

const warningLength = 650;

function update(key: MotivationKeys, param_Key: keyof ReactiveType) {
  patchInterview({
    _id: interviewId.value,
    [key]: {
      ...interview.value[key],
      [param_Key]: motivationReactive[key][param_Key],
    },
  });
}

watchEffect(() => {
  if (interview.value) {
    interviewKeys.forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(motivationReactive, key)) {
        motivationReactive[key as MotivationKeys] = {
          attraction: interview.value[key].attraction,
          repulsion: interview.value[key].repulsion,
          retention: interview.value[key].retention,
        };
      }
    });
  }
});
</script>

<template>
  <ShortlistBody
    :project="project"
    :candidate="person"
    :error="error"
    :score="person?.calculated_scores?.motivation_mean"
    :is-editing="isEditing"
    :title="t('projects.shortlist.titles.motivation')"
    icon="icon_shortlist_motivation_1"
    @edit="isEditing = !isEditing"
    @change:person="fetchCandidate"
  >
    <div class="motivation">
      <div class="motivation__content">
        <div v-for="(motivation, index) in motivations" :key="index" class="motivation__content--item">
          <div class="items-column">
            <div class="item-title">
              {{ motivation?.title }}
            </div>
            <div v-for="item in motivation.items" :key="item.name" class="item-body" :class="{ hidden: (!item?.value && !isEditing) }">
              <div v-if="!isEditing && item.type === MotivationMode.text && item.value" class="marker" />
              <div v-if="!isEditing && item.value" class="item-value" :class="item.type">
                <strong v-if="item.name">{{ item.name }}: </strong>
                <label v-html="item.value" />
              </div>
              <!-- canRenderOnEdit -->
              <div v-if="(isEditing && item.canRenderOnEdit) && item.type === MotivationMode.text && item.value" class="marker" />
              <div v-if="(isEditing && item.canRenderOnEdit) && item.value" class="item-value" :class="item.type">
                <strong v-if="item.name">{{ item.name }}: </strong>
                <label v-html="item.value" />
              </div>
              <div>
                <div v-if="isEditing && motivationReactive[item.key as MotivationKeys]" class="item-value" :class="item.type">
                  <strong v-if="item.name">{{ item.name.toUpperCase() }}: </strong>
                  <TextArea
                    v-model="motivationReactive[item.key as MotivationKeys][item.type as keyof ReactiveType]"
                    rows="5"
                    class="item-input"
                    :name="`motivation-${index}`"
                    :placeholder="t('projects.shortlist.funnel.addDescription')"
                    @change="update(item.key as MotivationKeys, item.type as keyof ReactiveType)"
                  />
                </div>
                <div v-if="!item.canRenderOnEdit && isEditing" class="counter-wrapper">
                  <div class="counter">
                    {{ motivationReactive[item.key as MotivationKeys][item.type as keyof ReactiveType]?.length || 0 }}/{{ warningLength }}
                  </div>
                  <div v-if="(motivationReactive[item.key as MotivationKeys][item.type as keyof ReactiveType]?.length || 0) >= warningLength" class="warning">
                    {{ t("text.textArea.secondaryWarning", { count: warningLength }) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!isEditing" class="item-score">
            {{ formatScore(motivation?.score) }}%
          </div>
        </div>
      </div>
    </div>
    <Button
      v-if="isEditing"
      class="conclude-button"
      type="submit"
      variation="secondary"
      @click="isEditing = !isEditing"
    >
      {{ t("text.form.conclude") }}
    </Button>
  </ShortlistBody>
</template>

<route lang="yaml">
meta:
  layout: project-stage
  stage: shortlist
  tab: motivation
</route>

<style lang="scss" scoped>
.motivation {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;
  padding-block: 2rem;
  padding-inline: 1rem;

  &__content {
    display: grid;
    gap: 2.8rem;
    grid-template-columns: repeat(4, 1fr);

    &--item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .items-column {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .item-body {
          display: flex;
          flex-direction: row;
        }

        .item-body.hidden {
          display: none;
        }

        .item-title {
          margin-bottom: 1rem;
          color: $gray-dark;
          font-size: 1.25rem;
          font-weight: 700;
          text-transform: uppercase;
        }
        .counter-wrapper {
          display: flex;
          gap: 1rem;
          font-weight: 400;
          padding-inline: 0.25rem;
          .counter {
            color: $orange-medium;
            font-size: 0.625rem;
          }
          .warning {
            color: $red-warning;
            font-size: 0.625rem;
          }
        }

        .item-value {
          color: $gray-dark;
          font-size: 0.875rem;
          line-height: 1.4;

          label {
            font-size: 0.875rem;
            line-height: 1.4;
            word-break: normal;
          }
        }

        .item-value.attraction,
        .item-value.repulsion,
        .item-value.retention {
          width: 100%;
          padding: 0.875rem;
          border-radius: 0.5rem;
          margin: 0.5rem 0;
          font-size: 0.875rem;
          line-height: 1.2rem;
        }

        @each $item-value in (attraction, repulsion, retention) {
          .item-value.#{$item-value} {
            :deep(.item-input) {
              position: relative;

              .textarea-container textarea {
                background: $white-full;
                border: none;
                color: $gray-dark;
                margin-block-start: 0.35rem;
                resize: none;
              }

              .counter-wrapper {
                position: absolute;
                bottom: -2.85rem;
                left: -0.625rem;

                @if $item-value == retention {
                  width: 11.25rem;

                }
              }
            }
            strong {
              text-transform: uppercase;
            }

            @if $item-value == attraction {
              background: $yellow;
              strong,
              label {
                color: $gray-dark;
              }
            } @else if $item-value == repulsion {
              background: $gray-medium-dark;
              color: $white-full;
              strong,
              label {
                color: $white-full;
              }
            } @else if $item-value == retention {
              background: $gray-x17;
              color: $gray-dark;
            }
          }
        }

        .marker {
          width: 0.5rem;
          min-width: 0.5rem;
          height: 0.5rem;
          margin-top: 0.35rem;
          margin-right: 0.625rem;
          background: $yellow;
          border-radius: 50%;
        }
      }

      .item-score {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $gray-border;
        background: $white-full;
        color: $gray-text;
        font-size: 1.25rem;
        font-weight: 700;
        padding: 0.875rem;
        border-radius: 0.5rem;
        margin: 0.75rem 0;
        line-height: 1.2rem;
      }
    }
  }
}
</style>
