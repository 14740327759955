<script setup lang="ts">
import moment from "moment";
import { chunk, findLastIndex } from "lodash-es";
import { getDateDif } from "@/utils/dates";

// schemas
import type { Person } from "@/schemas/person";
import ExportPage from "@/common/components/Export/ExportPage.vue";

const props = withDefaults(
  defineProps<{
    personName?: string | null
    isExport?: boolean
    person?: Person | null
    about?: WorkExperienceType[]
    hideExperiences?: string[]
  }>(),
  {
    person: null,
    positionName: null,
    isExport: false,
  },
);

const { t, locale } = useI18n();

interface PeriodType {
  init?: string | null
  end?: string | null
  current: boolean
}
interface WorkExperienceType {
  id?: string | null
  corporation_id: string | null
  corporation_name: string
  description: string | null
  start_date: string | null
  end_date: string | null
  is_current: boolean
  role_name: string | null
  salary: {
    amount: number | null
    contract_type: string | null
    currency: string | null
    payment_frequency: string | null
  }
}

interface CompanyListType {
  company_name: string
  company_id: string
  init_date?: string | null
  end_date?: string | null
  period?: string | null
  experiences: ExperiencesListType[]
}
interface ExperiencesListType {
  id: string
  is_current: boolean
  role_name: string
  start_date: string
  end_date: string
}

const { isExport, personName } = toRefs(props);

const maxExperienceColumn = ref(6);

const months = computed(() => {
  const data = JSON.parse(t("date.months")) || [];
  return data.map((item: string) => `${item.substring(0, 3)}.`);
});

function getDate(date: string): string {
  if (!date || date === "")
    return "";

  const dateFormat = moment(date);
  const year = dateFormat.format("YYYY");
  const month = Number.parseInt(dateFormat.format("M")) - 1;
  const dateIn = t("date.dateIn");

  return locale.value === "pt-BR"
    ? `${months.value[month]} ${dateIn} ${year}`
    : `${year} ${dateIn} ${months.value[month]}`;
}

function sortExperiencies() {
  const workExperience: WorkExperienceType[] = [];

  (props.about || []).forEach((item) => {
    if (!props.hideExperiences?.includes(item.id))
      workExperience.push(item);
  });

  const current = workExperience
    .filter(item => (item.is_current && item.start_date))
    .sort((a, b) => (b.start_date ?? "").localeCompare(a.start_date ?? ""));

  const previous = workExperience
    .filter(item => (!item.is_current && item.start_date))
    .sort((a, b) => (a.start_date ?? "").localeCompare(b.start_date ?? ""))
    .reverse();

  const dateEmpty = workExperience
    .filter(item => (!item.start_date))
    .sort((a, b) => (a.corporation_name ?? "").localeCompare(b.corporation_name ?? ""));

  return current.concat(previous, dateEmpty);
}

function paginateExperiences() {
  const paginated: CompanyListType[][][] = [[]];

  const experiencesTotal = sortExperiencies();
  const chunked = chunk(experiencesTotal, maxExperienceColumn.value);

  let page = 0;
  let col = 0;

  paginated[page] = [];
  paginated[page][col] = [];

  chunked?.forEach((experiences, chunked_index) => {
    experiences.forEach((item) => {
      const company_name = item?.corporation_name || "";
      const company_id = item?.corporation_id || "";
      const experience: ExperiencesListType = {
        id: item?.id || "",
        is_current: item.is_current,
        role_name: item.role_name || "",
        start_date: item.start_date || "",
        end_date: item.end_date || "",
      };

      const lastIndexCol = findLastIndex(paginated[page][col]);

      if (lastIndexCol < 0) {
        paginated[page][col]?.push({
          company_name,
          company_id,
          experiences: [experience],
        });
      }
      else {
        const companyData = paginated[page][col][lastIndexCol];
        if (company_name !== companyData?.company_name) {
          paginated[page][col]?.push({
            company_name,
            company_id,
            experiences: [experience],
          });
        }
        else {
          paginated[page][col][lastIndexCol].experiences.push(experience);
        }
      }
    });

    col++;
    if (col > 1 && chunked_index + 1 < chunked.length) {
      col = 0;
      page++;
      paginated[page] = [];
    }
    paginated[page][col] = [];
  });

  paginated.map(cols => cols.map((items) => {
    return items.map((item) => {
      const period: PeriodType[] = item.experiences?.reduce((acc: PeriodType[], exp) => {
        acc.push({
          init: exp.start_date,
          end: exp.end_date,
          current: exp.is_current,
        });
        return acc;
      }, []);

      item.period = getDateDif(period);
      if (item.experiences.length > 1) {
        item.init_date = item.experiences[item.experiences.length - 1]?.start_date;
        item.end_date = item.experiences[0]?.end_date;
      }
      return item;
    });
  }));

  return paginated;
}

const pages = computed(() => paginateExperiences());

function isHiddeAllExperiences(data: CompanyListType): boolean {
  if (!props.hideExperiences)
    return false;

  return data.experiences.every(item => props.hideExperiences?.includes(item.id));
}

function getCellStyle(item: CompanyListType) {
  if (isHiddeAllExperiences(item))
    return { paddingBlockEnd: "0.5rem" };

  return {};
}
</script>

<template>
  <ExportPage
    v-for="(page, page_index) in pages"
    :key="page_index"
    :is-export="isExport"
    :person-name="personName"
    :page-name="`${t('exportPDF.labels.professionalTrajectory')}`"
    :opinion="null"
  >
    <div class="pdf-user-about">
      <div class="about-head">
        <span class="about-title">
          {{ t("exportPDF.labels.professionalTrajectory").toUpperCase() }}
        </span>
      </div>
      <div class="about-columns">
        <div v-for="(experience, index) in page" :key="index" class="column">
          <div v-for="(item, ia) in experience" :key="ia" class="col-item" :style="getCellStyle(item)">
            <div class="item-name" :class="{ unic: item.experiences.length === 1 }">
              <div class="circles yellow" />
              <span class="text-title bold">
                {{ item.company_name }}
              </span>
            </div>
            <div v-if="item.experiences.length > 1" class="item-duration">
              {{ item.period }}
            </div>
            <div>
              <div
                v-for="(exp, exp_index) in item.experiences"
                :key="exp_index"
                class="item-experience"
                :class="{ unic: item.experiences.length === 1 }"
              >
                <div class="history">
                  <div class="circles gray" />
                  <div v-if="exp_index + 1 < item.experiences.length" class="horizontal-line" />
                </div>
                <div class="experience-data">
                  <span class="text bold">
                    {{ exp.role_name }}
                  </span>
                  <span class="text text-info">
                    <span>
                      {{ getDate(exp.start_date) }}
                    </span>
                    <span v-if="getDate(exp.end_date) !== ''"> - {{ getDate(exp.end_date) }} </span>
                    <span v-if="exp.is_current"> - {{ t("projects.shortlist.about.current") }} </span>
                    <span>
                      - {{ getDateDif([{ init: exp.start_date, end: exp.end_date, current: exp.is_current }]) }}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ExportPage>
</template>

<style lang="scss" scoped>
.pdf-user-about {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-block: 1rem;

  .about-head {
    display: flex;
    width: 100%;
    margin-bottom: 1.87rem;
  }

  .about-title {
    color: $gray-dark;
    font-size: 1.125rem;
    font-weight: bold;
    line-height: 1.25rem;
  }

  .icon-tooltip svg {
    margin-left: 1rem;
    color: $gray-lower;
  }

  .text-title {
    width: inherit;
    color: $gray-dark;
    font-size: 1rem;
  }

  .text {
    width: inherit;
    color: $gray-dark;
    font-size: 0.875rem;
  }

  .text.bold,
  .text-title.bold {
    font-weight: bold;
  }

  .text-info,
  .text-info span {
    font-size: 0.875rem;
  }

  .about-columns {
    display: grid;
    width: 100%;
    gap: 4rem;
    grid-template-columns: 1fr 1fr;

    .column {
      display: flex;
      width: 100%;
      flex-direction: column;
    }

    .col-item {
      display: flex;
      width: 100%;
      flex-direction: column;
      padding-bottom: 0.5rem;

      .item-name.unic {
        padding-bottom: 0.75rem;
      }

      .item-name {
        display: flex;
        width: 100%;
        font-size: 1rem;
        padding-bottom: 0.2rem;
      }

      .item-duration {
        display: flex;
        width: 100%;
        font-size: 0.875rem;
        padding-inline-start: 1.4rem;
        padding-block-end: 1rem;
      }

      .item-experience.unic {
        padding-inline-start: 1.4rem;

        .history {
          display: none;
        }

        .experience-data {
          padding-bottom: 0.75rem;
        }
      }

      .item-experience {
        display: flex;
        flex-direction: row;
        margin-top: -5px;
        padding-left: 1px;

        .history {
          display: flex;
          width: 0.9rem;
          flex-direction: column;
          margin-right: 0.5rem;
        }

        .experience-data {
          display: flex;
          width: 100%;
          flex-direction: column;
          padding-bottom: 1.2rem;
          gap: 0.3rem;
        }
      }
    }

    .circles.gray {
      width: 0.5rem;
      min-width: 0.5rem;
      height: 0.5rem;
      min-height: 0.5rem;
    }

    .circles {
      width: 0.65rem;
      min-width: 0.65rem;
      height: 0.65rem;
      min-height: 0.65rem;
      margin-top: 0.3rem;
      margin-right: 0.75rem;
      border-radius: 50%;
    }

    .horizontal-line {
      position: relative;
      width: 1.5px;
      height: 100%;
      margin-left: 0.20rem;
      background: #c0c0c0;
    }

    .yellow {
      background: $yellow;
    }

    .gray {
      background: $gray-border;
    }
  }
}
</style>
