<script setup lang="ts">
import { mdiPlusCircleOutline } from "@mdi/js";
import bson from "bson-objectid";
import { ButtonIcon } from "@/components/button";
import { SvgIcon } from "@/components/icon";

withDefaults(defineProps<{
  icon?: string
  editing?: boolean
  companyProfile?: any
  corporation?: any
}>(), {
  icon: "project_company_profile",
  editing: false,
  companyProfile: {},
  corporation: {},
});

defineEmits(["submit:project", "submit:corporation", "submit:group", "cancel"]);

const { t } = useI18n();

type valueMapKey = keyof typeof valueMap;
const valueMap = {
  EMPTY: 0,
  LOW: 1,
  AVERAGE: 2,
  HIGH: 3,
  VERY_HIGH: 4,
};

type Tags = { text: string; selected: boolean }[];

interface AttributesRadio { cognitive_intelligence: valueMapKey; emotional_intelligence: valueMapKey; spiritual_intelligence: valueMapKey; energy: valueMapKey; maturity: valueMapKey }
interface CulturalMatchRadio { practical: valueMapKey; logical: valueMapKey; reflexive: valueMapKey; affective: valueMapKey }

const attributes = ref({
  cognitive_intelligence: "EMPTY",
  emotional_intelligence: "EMPTY",
  spiritual_intelligence: "EMPTY",
  energy: "EMPTY",
  maturity: "EMPTY",
} as AttributesRadio);
const culturalMatch = ref({
  practical: "EMPTY",
  logical: "EMPTY",
  reflexive: "EMPTY",
  affective: "EMPTY",
} as CulturalMatchRadio);

const motivationPackage = ref({
  salary: "",
  short_term_incentive: "",
  long_term_incentive: "",
  other_incentive: "",
  total_cash: "",
});

interface UniqueIdMap { [key: string]: string }

const academicBackgrounds: Ref<UniqueIdMap> = ref({});
const languages: Ref<UniqueIdMap> = ref({});
const achievementsTags: Ref<Tags> = ref([]);
const experiencesTags: Ref<Tags> = ref([]);

const route = useRoute();
const {
  data: idealProfile,
} = useConstructionIdealProfile(String(route.params.id));

const {
  data: construction,
} = useConstructionOrganizationQuery(String(route.params.id));

const {
  mutate: patchIdealProfile,
} = usePatchIdealProfileQuery();

const constructionId = computed(() => {
  return construction.value?._id;
});

type IdealProfileKey = keyof typeof profileReferenceMap;

const profileReferenceMap = {
  languages,
  academic_backgrounds: academicBackgrounds,
  attributes,
  cultural_match: culturalMatch,
  package: motivationPackage,
  achievements_tags: achievementsTags,
  experiences_tags: experiencesTags,
};

function profileAdd(key: IdealProfileKey) {
  let tmp = { ...profileReferenceMap[key].value };
  tmp = tmp || {};
  const newid = bson().toHexString();

  // @ts-expect-error missing map type infer from tmp
  tmp[newid] = "";
  profileReferenceMap[key].value = tmp;
}

function removeProfile(key: IdealProfileKey, index: string | number) {
  const tmp = { ...profileReferenceMap[key].value };

  // @ts-expect-error missing map type infer from tmp
  delete tmp[index];
  profileReferenceMap[key].value = tmp;

  saveProfile(key);
}

function saveProfile(key: IdealProfileKey) {
  patchIdealProfile({
    _id: String(constructionId.value),
    [key]: profileReferenceMap[key].value,
  });
}

watchEffect(() => {
  if (idealProfile.value) {
    academicBackgrounds.value = { ...idealProfile.value.academic_backgrounds };
    languages.value = { ...idealProfile.value.languages };
    experiencesTags.value = toRaw(idealProfile.value.experiences_tags);
    achievementsTags.value = toRaw(idealProfile.value.achievements_tags);
    motivationPackage.value = toRaw(idealProfile.value.package);
    attributes.value = { ...idealProfile.value.attributes };
    culturalMatch.value = { ...idealProfile.value.cultural_match };
  }
});
</script>

<template>
  <div v-if="idealProfile" class="form">
    <div class="form-column">
      <div class="heading">
        {{ t("projects.details.constructionGuide.idealProfile.academicFormation") }}
      </div>
      <div class="form-item">
        <div v-for="(_, key) of academicBackgrounds" :key="`academic-background-${key}`" class="base-item">
          <TextArea
            v-model="academicBackgrounds[key]"
            class="item-input"
            :name="`academic-backgrounds-${key}`"
            :warning-chars="250"
            rows="5"
            placeholder="Academic Background"
            @change="saveProfile('academic_backgrounds')"
          />
          <ButtonIcon @click="removeProfile('academic_backgrounds', key)">
            <SvgIcon icon="trash_icon" width="20" height="20" margin="none" />
          </ButtonIcon>
        </div>
        <ButtonIcon class="panel-button add" @click="profileAdd('academic_backgrounds')">
          <SvgIcon
            :icon="mdiPlusCircleOutline"
            type="mdi"
            width="20"
            height="20"
            margin="none"
          />
          <span>adicionar formação</span>
        </ButtonIcon>
      </div>

      <div class="heading">
        {{ t("languages.language") }}
      </div>
      <div class="form-item">
        <div v-for="(_, key) of languages" :key="`language-${key}`" class="base-item">
          <TextArea
            v-model="languages[key]"
            class="item-input"
            :name="`languages-${key}`"
            :warning-chars="250"
            rows="5"
            placeholder="Language"
            @change="saveProfile('languages')"
          />
          <ButtonIcon @click="removeProfile('languages', key)">
            <SvgIcon icon="trash_icon" width="20" height="20" margin="none" />
          </ButtonIcon>
        </div>
        <ButtonIcon class="panel-button add" @click="profileAdd('languages')">
          <SvgIcon
            :icon="mdiPlusCircleOutline"
            type="mdi"
            width="20"
            height="20"
            margin="none"
          />
          <span>adicionar idioma</span>
        </ButtonIcon>
      </div>

      <div class="section">
        <TagsSearch
          is-corporation-profile
          :placeholder="
            t('projects.details.activities.constructionPanel.forms.tags.idealProfile.titlePath[0]')
          "
          :title="
            t('projects.details.activities.constructionPanel.forms.tags.idealProfile.titleDiv', {
              Title: t(
                'projects.details.activities.constructionPanel.forms.tags.idealProfile.titlePath[0]',
              ),
            })
          "
          :tags="experiencesTags"
          @update-tag-list="((experiencesTags = $event), saveProfile('experiences_tags'))"
        />
      </div>

      <div class="section">
        <TagsSearch
          is-corporation-profile
          :placeholder="
            t('projects.details.activities.constructionPanel.forms.tags.idealProfile.titlePath[1]')
          "
          :title="
            t('projects.details.activities.constructionPanel.forms.tags.idealProfile.titleDiv', {
              Title: t(
                'projects.details.activities.constructionPanel.forms.tags.idealProfile.titlePath[1]',
              ),
            })
          "
          :tags="achievementsTags"
          @update-tag-list="((achievementsTags = $event), saveProfile('achievements_tags'))"
        />
      </div>

      <div class="section">
        <div class="heading">
          {{ t("projects.construction.titles.motivation") }}
        </div>

        <div class="motivation-items">
          <div class="item-label">
            {{ t("projects.construction.labels.salary") }}
          </div>
          <TextArea
            v-model.trim="motivationPackage.salary"
            rows="5"
            :placeholder="t('projects.construction.labels.salary')"
            @change="saveProfile('package')"
          />
          <div class="item-label">
            {{ t("projects.construction.labels.totalCash") }}
          </div>
          <TextArea
            v-model.trim="motivationPackage.total_cash"
            rows="5"
            :placeholder="t('projects.construction.labels.totalCash')"
            @change="saveProfile('package')"
          />
        </div>
      </div>
    </div>
    <div class="form-column">
      <div class="section">
        <div class="heading">
          {{ t("projects.details.constructionGuide.idealProfile.attributes") }}
        </div>
        <div v-for="(attribute, attribute_key) of attributes" :key="`attribute-${attribute_key}`" class="enum-group">
          <!-- TODO: add clamp feature -->
          <div class="enum-title">
            {{ t(`attributes.${attribute_key.toUpperCase()}`) }}
          </div>
          <div :id="attribute_key" class="enum-tooltip">
            {{ t(`projects.shortlist.enums.attributes.${attribute_key.toUpperCase()}`) }}
          </div>
          <!-- <span class="enum-tooltip-show-more" @click="showMore(attribute_key)">
            {{ t("projects.details.constructionGuide.idealProfile.tooltip.shouldShowMore") }}
          </span> -->
          <SliderKeyed background-color="#A8A8A8" :model-value="attribute" @update:model-value="attributes[attribute_key] = $event, saveProfile('attributes')" />
        </div>
      </div>
      <div class="section">
        <div class="heading">
          {{ t("projects.details.constructionGuide.idealProfile.culturalMatch") }}
        </div>
        <div v-for="(match, cultural_key) of culturalMatch" :key="`attribute-${cultural_key}`" class="enum-group">
          <div class="enum-title">
            {{ t(`cultural_match.${cultural_key.toUpperCase()}`) }}
          </div>
          <div :id="cultural_key" class="enum-tooltip">
            {{ t(`projects.shortlist.enums.cultural.${cultural_key.toUpperCase()}`) }}
          </div>
          <!-- <span class="enum-tooltip-show-more" @click="showMore(cultural_key)">
            {{ t("projects.details.constructionGuide.idealProfile.tooltip.shouldShowMore") }}
          </span> -->
          <SliderKeyed background-color="#F8D272" :model-value="match" @update:model-value="culturalMatch[cultural_key] = $event, saveProfile('cultural_match')" />
        </div>
      </div>
    </div>

    <div class="actions">
      <Button type="submit" variation="secondary" @click="$emit('cancel')">
        <!-- $emit('submit:project') -->
        {{ t("text.form.conclude") }}
      </Button>
      <!-- <Button variation="light" filled @click="$emit('cancel')">
        {{ t("text.form.cancel") }}
      </Button> -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
:deep(.package-component-salary) .box-input {
  display: block;

  .box-label {
    padding-block-end: 0.5rem;
  }

  .box-items {
    width: 100%;
    max-height: 2.5rem;
  }
}

.form-languages {
  margin-bottom: 1.5rem;
}

.form {
  display: grid;
  margin-top: 2rem;
  gap: 2rem 8rem;
  grid-template-columns: repeat(2, 1fr);
  margin: 1.5rem;
  padding-bottom: 1.5rem;

  label {
    display: block;
    font-size: 0.875rem;
    padding-block: 0.5rem;
  }

  .heading {
    // TODO: fix this
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 1.6875rem;
    margin-block-end: 0.3563rem;
    text-transform: uppercase;
  }

  .experience,
  .realizations {
    :deep(.text-counter-wrapper) {
      padding-block: 0.5rem;
    }
  }

  .salary-fields {
    display: grid;
    gap: 0.5rem;
    grid-template-columns: 1.25fr 1.25fr 4fr;
  }

  .annual-package-fields {
    display: grid;
    gap: 0.5rem;
    grid-template-columns: 1.25fr 4fr;
  }

  .actions {
    display: flex;
    max-height: 2.5rem;
    gap: 2rem;
  }
}

.enum-group {
  padding-block: 0.5rem 1.5rem;
  margin: 0 1.5rem 2rem 0.25rem;
}
.enum-title {
  font-size: 0.875rem;
  padding-block: 0.5rem;
  font-weight: 600;
  user-select: none;
}
.enum-tooltip {
  font-size: 0.75rem;
  padding-block: 0.5rem;
  line-height: 1rem;
}
.enum-tooltip-show-more {
  color: $yellow;
  display: flex;
  justify-content: end;
  font-size: 0.875rem;
  margin-block: 0.625rem 1.25rem;
  cursor: pointer;
}

.radio-question {
  margin-right: 1rem;
}

.radio-question-wrapper {
  display: grid;
  margin-bottom: 1rem;
  grid-template-columns: 1fr 3fr;

  svg {
    color: $red;
    vertical-align: top;
  }
}
:deep(.text-area-wrapper) {
  margin: 0.5rem 0;
}
.inline-radio {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.form-column {
  display: flex;
  flex-direction: column;

  > div {
    margin-bottom: 1.5rem;
  }
}
.base-item {
  display: flex;
  align-items: baseline;
  gap: 0.25rem;
  margin-block: 0.5rem 1.5rem;

  .item-input {
    width: 100%;
  }
}
button.panel-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  margin: 0.5rem 0 1.5rem 0;
  margin-inline-start: auto;

  &:focus,
  &:hover {

    &.add,
    &.add>span {
      color: $red-violet-medium;

      svg {
        vertical-align: bottom;
      }
    }
  }

  &.add,
  &.add>span {
    color: $red-violet;
    font-size: 0.75rem;
    letter-spacing: 0;
    font-weight: 600;

    svg {
      vertical-align: bottom;
    }
  }
}
.section {
  margin-bottom: 1.5rem;
}
</style>
