<script lang="ts">
import { computed, defineComponent, ref, toRef, watch } from "vue";
import { useI18n } from "vue-i18n";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiChevronRight, mdiClose } from "@mdi/js";
import { Button2 } from "@/components/button";

type Tags = Array<string>;

export default defineComponent({
  name: "TagFilter",
  components: {
    Button: Button2,
    SvgIcon,
  },
  props: {
    tagLimit: {
      type: Number,
      default: 5,
    },
    filterLocale: {
      type: String,
      default: "persons",
    },
    filterName: {
      type: String,
      required: true,
    },
    clear: {
      type: Boolean,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    variation: {
      type: String,
      default: "primary",
    },
  },
  emits: ["filter:tag"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const tags = ref<Tags>([]);
    const inputTagValue = ref<string>("");
    const clear = toRef(props, "clear");

    const addTag = (): void => {
      if (tags.value.length >= props.tagLimit)
        return;

      if (inputTagValue.value) {
        tags.value.push(inputTagValue.value);
        emit("filter:tag", tags.value);
        inputTagValue.value = "";
      }
    };

    const removeTag = (tagIndex: number): void => {
      tags.value.splice(tagIndex, 1);
      emit("filter:tag", tags.value);
    };

    const disabled = computed(() => tags.value.length >= props.tagLimit);

    const clearAll = () => {
      tags.value = [];
      emit("filter:tag", tags.value);
    };

    watch(clear, () => {
      clearAll();
    });

    return {
      t,
      tags,
      inputTagValue,
      addTag,
      removeTag,
      clearAll,
      disabled,
      chevron: mdiChevronRight,
      close: mdiClose,
    };
  },
});
</script>

<template>
  <div class="tag-filter">
    <div>
      <div class="tag-input-wrapper">
        <input
          v-model="inputTagValue"
          :disabled="disabled"
          class="tag-input"
          type="text"
          :placeholder="
            t(`${filterLocale}.tagFilter`, {
              tagFilter: t(`${filterLocale}.tagFilters.${filterName}.1`),
            })
          "
          @keyup.enter="addTag()"
        >
        <Button class="add-button" :variation="variation" :disabled="disabled" @click="addTag()">
          <SvgIcon type="mdi" :path="chevron" size="26" />
        </Button>
      </div>

      <small v-if="clearable" class="tag-filter-clear" role="button" @click="clearAll()">
        > {{ t(`${filterLocale}.tagFilterClear`) }}
      </small>
    </div>
    <div v-show="tags.length" class="tags">
      <TransitionGroup>
        <span v-for="(tag, index) in tags" :key="tag" class="tag">
          {{ tag }}

          <span role="button" @click="removeTag(index)">
            <SvgIcon type="mdi" :path="close" size="16" />
          </span>
        </span>
      </TransitionGroup>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.tag-filter {
  margin-top: 1rem;

  .tags {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;

    .tag {
      display: inline-block;
      max-width: 100%;
      padding: 0.5rem 0.75rem;
      border-radius: 8px;
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
      background-color: $white-full;
      box-shadow: 0 1px 3px #0003;
      color: $gray-dark;
      font-size: 0.75rem;
      word-wrap: break-word;

      span[role="button"] {
        &:hover {
          color: lighten($gray-dark, 25%);
        }

        svg {
          vertical-align: text-top;
        }
      }
    }
  }

  .tag-input-wrapper {
    position: relative;
    margin-bottom: 0.25rem;

    .tag-input {
      display: block;
      width: 100%;
      padding: 1em;
      border: 1px solid $gray-border;
      border-radius: 8px;
      font-size: 0.75rem;

      &:focus {
        outline: 1px solid darken($gray-border, 5%);
      }
    }

    .add-button {
      position: absolute;
      top: 50%;
      right: 2.5%;
      padding: 0;
      transform: translateY(-50%);

      svg {
        vertical-align: middle;
      }
    }
  }

  .tag-filter-clear {
    display: inline-flex;
    align-items: center;
    margin-left: 0.75rem;
    font-size: 0.875rem;

    &:hover {
      color: lighten($gray-dark, 25%);
    }
  }
}
.v-enter-active,
.v-leave-active {
  transition: opacity 400ms ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
