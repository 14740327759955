<script setup lang="ts">
import { uniqBy } from "lodash-es";
import FilterMapping from "./components/FilterMapping.vue";
import CandidateCard from "./components/CandidateCard.vue";
import ProjectStages from "@/common/components/ProjectStages.vue";
import { submitCsv } from "@/http_services/entities/project";
import { searchCandidatesMapping } from "@/http_services/entities/common";
import { FileDropInput, Select } from "@/components/inputs/modalInputs";
import type { Person } from "@/schemas/person";
import {
  useCandidateToMapping,
  useDeclineToMapping,
  useListMappingCandidates,
  useListScreeningActivity,
  useSaveListMapping,
} from "@/common/composables/useMapping";
import { Modal } from "@/components/modal";
import ScrollToTop from "@/components/scroll_to_top/ScrollToTop.vue";

interface UploadResponseType {
  created: number
  updated: number
  uploadPercentage: null | number
}

interface ListMappingType {
  person_id: string
  priority_classification: string | null
}

const props = defineProps<{ id: string }>();
const route = useRoute();
const router = useRouter();

const { t, tm } = useI18n();

const projectId = computed(() => String(route.params.id));

const {
  data: construction,
} = useConstructionOrganizationQuery(String(route.params.id));

const { data: project, error, isLoading, refetch: refetchProject } = await useProject(projectId.value);

const degreeOptions = computed(() =>
  Object(tm("projects.details.activities.constructionPanel.forms.enums.academic.degree")));

const institutionOptions = computed(() =>
  Object(tm("projects.details.activities.constructionPanel.forms.enums.academic.institution")));

const educationCheckBox = ref({
  booleans: {
    bachelor: { label: degreeOptions.value.BACHELOR, value: false },
    fistLine: { label: institutionOptions.value.PREFERENTIALLY_FIRST_LINE, value: false },
  },
});

const {
  data: listMapCandidates,
  refetch,
} = useListMappingCandidates(projectId.value);

const {
  data: listScreeningCandidates,
  refetch: refetchList,
} = useListScreeningActivity(projectId.value);

const {
  mutate: addCandidateToMapping,
} = useCandidateToMapping();

const {
  mutate: declineCandidateToMapping,
} = useDeclineToMapping();

const {
  mutateAsync: saveListMapping,
} = useSaveListMapping();

const {
  mutateAsync: createScreeningActivity,
} = useCreateScreeningActivity();

const {
  mutateAsync: finishMapping,
} = useFinishMapping();

const {
  mutate: patchApprovalCandidate,
} = usePatchApprovalCandidate();

const {
  data: recruiter,
} = await usePersonMe();

const {
  capture,
} = useTracking();

const isSavingMapping = ref(false);
const filterParams = ref({});
const paginationParams = ref({
  limit: 10,
  skip: 0,
  sort: "priority",
  saved_candidates: false,
});
const listSort = computed(() => ({
  placeholder: t("projects.mapping.list.sort.placeholder"),
  options: Object(tm("projects.mapping.list.sort.options")),
}));

const creatingActivity = ref(false);
const sortSelected = ref("priority");
const loadingEvent = ref(false);
const fetchLoading = ref(true);
const visibleScrollTop = ref(false);
const fetchError = ref();
const listCandidates = ref<Person[]>([]);
const totalCandidates = ref(0);

const listMapping = ref<string[]>([]);
const listScreeningActivitys = ref<string[]>([]);
const listScreeningRating = ref<{ [key: string]: string | null }>({});

const fileName = ref("");
const uploadResponse = ref<UploadResponseType>({
  created: 0,
  updated: 0,
  uploadPercentage: 0,
});
const responseTotal = ref(0);
const wasImputed = ref(false);
const wasImputedError = ref(false);

const totalCandidatesAdded = ref(0);
const modalType = ref<string | null>(null);
const modalConfig = computed(() => ({
  save_mapping: {
    title: totalCandidatesAdded.value === 0
      ? t("projects.mapping.modal.title.candidatesNotAdded")
      : t("projects.mapping.modal.title.candidatesAdded", { amount: totalCandidatesAdded.value }),
    message: totalCandidatesAdded.value === 0
      ? t("projects.mapping.modal.message.backToListMapping")
      : t("projects.mapping.modal.message.accompanyCandidates"),
    button_text: {
      primary: t("projects.mapping.modal.buttons.goToScreening"),
      secondary: t("projects.mapping.modal.buttons.continueMapping"),
    },
    click_primary: () => router.push(`/projects/${projectId.value}/screenings/activities`),
    click_secondary: () => closeModal(),
    click_close: () => closeModal(),
  },
}));

async function createActivity() {
  creatingActivity.value = true;
  if (!recruiter.value)
    return;

  const candidates = listScreeningCandidates.value.length === 0
    ? listMapCandidates.value
    : listMapCandidates.value.filter((data: ListMappingType) => !listScreeningCandidates.value.includes(data.person_id));

  const candidatesScreeningsFuture = candidates.map((data: ListMappingType) => createScreeningActivity({
    description: "SCREENING_MEETING",
    activity_date: null,
    project_id: projectId.value,
    recruiter: {
      person_id: recruiter.value?.data.id,
    },
    candidate: {
      person_id: data.person_id,
    },
  }));

  await Promise.all(candidatesScreeningsFuture);

  totalCandidatesAdded.value = candidates.length;
  modalType.value = "save_mapping";
  creatingActivity.value = false;
}

function closeModal() {
  totalCandidatesAdded.value = 0;
  modalType.value = null;
}

async function saveMapping() {
  isSavingMapping.value = true;
  if (project.value?.stages?.mapping?.status === "ACTIVE") {
    await finishMapping(projectId.value, {
      onSuccess: async () => {
        capture("imua-project-stage-updated", {
          project_id: projectId.value,
          from: "mapping",
          to: "screening",
        });
      },
    });
  }

  await saveListMapping(projectId.value, {
    onSuccess: async () => {
      if (!creatingActivity.value)
        await createActivity();

      await refetchList();
    },
    onSettled: async () => {
      await refetchList();
    },
  });
  isSavingMapping.value = false;
}

function addToMapping(person_id: string | undefined) {
  if (!person_id)
    return;

  addCandidateToMapping({
    candidate: {
      project_id: projectId.value,
      person_id,
    },
  });
}

function declineToMapping(person_id: string | undefined) {
  if (!person_id)
    return;
  declineCandidateToMapping({
    candidate: {
      project_id: projectId.value,
      person_id,
    },
  });
}

function resetListMapping() {
  listCandidates.value = [];
  totalCandidates.value = 0;
  paginationParams.value.skip = 0;
}

function refreshListMapping() {
  listScreeningActivitys.value = listScreeningCandidates.value;
  listMapping.value = [...listMapCandidates.value].map(item => item.person_id);
  listScreeningRating.value = [...listMapCandidates.value].reduce((obj, item: ListMappingType) => {
    obj[item.person_id] = item.priority_classification;
    return obj;
  }, {});
}

async function searchPersons() {
  fetchLoading.value = true;
  try {
    const { data, total } = await searchCandidatesMapping(
      {
        ...paginationParams.value,
        ...filterParams.value,
      },
      projectId.value,
    );
    listCandidates.value = uniqBy([...listCandidates.value, ...data], e => e.id);
    totalCandidates.value = total;
    paginationParams.value.skip = listCandidates.value?.length;
    refreshListMapping();
    fetchLoading.value = false;
  }
  catch (error) {
    fetchError.value = error;
  }
}

async function handleScroll(event: Event) {
  const {
    scrollTop: hiddenHeight,
    scrollHeight: totalHeight,
    clientHeight: totalVisible,
  } = event.target as HTMLElement;
  const bottomReached = hiddenHeight + totalVisible + 1 >= totalHeight;

  hiddenHeight >= 800
    ? (visibleScrollTop.value = true)
    : (visibleScrollTop.value = false);

  if (hiddenHeight && bottomReached && !fetchLoading.value) {
    fetchLoading.value = true;
    await searchPersons();
  }
}

async function updateFilters(filters: any) {
  filterParams.value = filters;
  resetListMapping();
  await searchPersons();
}

async function selectedCandidate(selected: boolean) {
  if (selected)
    paginationParams.value.saved_candidates = false;
  else
    paginationParams.value.saved_candidates = true;

  resetListMapping();
  await searchPersons();
}

async function submitFile(file: File) {
  try {
    loadingEvent.value = true;
    capture("imua-mapping-upload-csv", {
      project_id: projectId.value,
    });
    const { data } = await submitCsv(projectId.value, file);
    uploadResponse.value = data;
    responseTotal.value = uploadResponse.value.created + uploadResponse.value.updated;
    wasImputedError.value = data.status >= 0;
    wasImputed.value = !wasImputedError.value;
    fileName.value = file.name;
    return data;
  }
  catch (error) {
  }
  finally {
    loadingEvent.value = false;
    resetListMapping();
    refetchProject();
    await searchPersons();
    refetch();
  }
}

async function changeRating(data: { person_id: string; rating: string }) {
  const candidate = await getCandidateIdFromPersonId(projectId.value, data.person_id);

  if (candidate?._id) {
    patchApprovalCandidate({
      person_id: candidate._id,
      body: {
        approval: {
          approved_for_interview: null,
          reason: null,
          priority_classification: data.rating,
        },
      },
    });
  }
}

function responseUpdated({ data, is }: { data: number; is: string }) {
  return data
    ? h("span", { data, is }, t(data > 1 ? `projects.mapping.modal.message.${is}[0]` : `projects.mapping.modal.message.${is}[1]`, {
      response: data,
    }))
    : null;
}

async function onSort(value: string) {
  resetListMapping();
  switch (value) {
    case "priority":
      paginationParams.value.sort = "priority";
      paginationParams.value.skip = 0;
      break;
    case "name":
      paginationParams.value.sort = "name";
      paginationParams.value.skip = 0;
      break;
    default:
      paginationParams.value.sort = "updated_at";
      paginationParams.value.skip = 0;
      break;
  }
  await searchPersons();
}

onMounted(async () => {
  const mainContent = document.getElementById("main-content");
  mainContent?.addEventListener("scroll", async (event: Event) => {
    await handleScroll(event);
  });
});

watchEffect(() => {
  if (listMapCandidates.value)
    refreshListMapping();
});
</script>

<template>
  <section class="project-activities">
    <div v-if="error">
      could not load
    </div>
    <div v-if="project" class="project-container">
      <ProjectStages :id="props.id" />
      <div class="project-wrapper">
        <div class="mapping-filter">
          <FilterMapping
            v-if="project && construction"
            :project="project"
            :ideal-profile="construction.ideal_profile"
            @update:filter="updateFilters($event)"
            @selected:candidate="selectedCandidate($event)"
          />
        </div>
        <div class="mapping-candidates">
          <div class="mapping-candidates-actions">
            <div class="parser">
              <FileDropInput
                :placeholder="t('projects.mapping.drag')"
                :loading="loadingEvent"
                @update:file="submitFile"
              />
            </div>
            <div class="save">
              <Button
                class="button-pluss"
                variation="primary"
                :disabled="isSavingMapping"
                @click.prevent="isSavingMapping = true, saveMapping()"
              >
                {{ t("projects.mapping.saveCandidates") }}
              </Button>
            </div>
          </div>
          <div class="mapping-candidates-filter">
            <div class="amount">
              <span v-if="totalCandidates > 0">
                {{ totalCandidates }} {{ t("projects.details.tabs.candidates") }}
              </span>
            </div>
            <div class="order">
              <Select
                v-model="sortSelected"
                no-feedback
                :placeholder="listSort.placeholder"
                :options="listSort.options"
                :searchable="false"
                @update:model-value="onSort($event)"
              />
            </div>
          </div>
          <div class="mapping-candidates-list">
            <template v-if="!fetchLoading && totalCandidates === 0">
              <div class="empty-persons">
                <span>{{ t("persons.notFoundSearch") }}</span>
              </div>
            </template>
            <template v-else-if="fetchLoading">
              <div class="box-loading">
                <span>{{ t("persons.mauiMapping") }}</span>
                <div class="loader-wrapper">
                  <div class="loader" />
                </div>
              </div>
            </template>
            <template v-else>
              <CandidateCard
                v-for="(candidate, index) in listCandidates"
                :key="index"
                :person="candidate"
                :candidate-rating="listScreeningRating[candidate.id]"
                :rating-block="!listMapping.includes(candidate.id || '')"
                @change:rating="changeRating($event)"
              >
                <template #actions>
                  <BaseTooltip
                    v-if="candidate.id ? listMapping.includes(candidate.id) : false"
                    position-x="center"
                    position-y="top"
                  >
                    <Button
                      class="mapping-candidates-list-buttons"
                      variation="secondary"
                      :disabled="listScreeningActivitys.includes(candidate.id || '')"
                      @click="declineToMapping(candidate.id)"
                    >
                      {{ t("projects.mapping.card.excludeCandidate") }}
                    </Button>
                    <template #message>
                      <div class="tooltip-message">
                        {{ t('projects.mapping.card.tooltip').toLocaleUpperCase() }}
                      </div>
                    </template>
                  </BaseTooltip>
                  <BaseTooltip v-else-if="candidate.is_added_in_placement" position-x="center" position-y="top">
                    <Button
                      class="mapping-candidates-list-buttons"
                      variation="secondary"
                      :disabled="candidate.is_added_in_placement"
                      @click="addToMapping(candidate.id)"
                    >
                      {{ t("projects.mapping.card.addCandidate") }}
                    </Button>
                    <template v-if="candidate.is_added_in_placement" #message>
                      <div class="tooltip-message">
                        {{ t('projects.mapping.card.placementCandidate').toLocaleUpperCase() }}
                      </div>
                    </template>
                  </BaseTooltip>
                  <Button
                    v-else
                    class="mapping-candidates-list-buttons"
                    variation="secondary"
                    :disabled="candidate.is_added_in_placement"
                    @click="addToMapping(candidate.id)"
                  >
                    {{ t("projects.mapping.card.addCandidate") }}
                  </Button>
                </template>
              </CandidateCard>
            </template>
          </div>
        </div>
      </div>
      <div v-if="isLoading">
        ... loading
      </div>
    </div>
    <ScrollToTop v-if="visibleScrollTop" class-name=".scrollable-content-full" :top="380" />
  </section>
  <Modal
    v-if="modalType !== null"
    class="modal-confirm"
    :title="modalConfig[modalType].title"
    :message="modalConfig[modalType].message"
    message-align="center"
    :button-text="modalConfig[modalType].button_text.primary"
    :secondary-button-text="modalConfig[modalType].button_text.secondary"
    :on-close="modalConfig[modalType].click_close"
    :on-click="modalConfig[modalType].click_primary"
    :secondary-on-click="modalConfig[modalType].click_secondary"
    attach="body"
    variation="warning"
    primary-variation=""
    secondary-variation="light"
    title-class="modal-title"
    message-class="modal-message"
    wrapper-class="content-wrapper"
    confirm-button-class="confirm-button"
    cancel-button-class="cancel-button"
    buttons-wrapper-class="buttons-wrapper"
  />
  <Modal
    v-if="wasImputed"
    :title="t('projects.mapping.modal.title.uploaded')"
    :button-text="t('projects.mapping.modal.buttons.ok')"
    :on-click="() => (wasImputed = false)"
    :on-close="() => (wasImputed = false)"
  >
    <template #message>
      <div class="center-list">
        <br>
        <span
          :style="{
            background: '#f4f4f4',
            color: '#c23584',
            fontWeight: 'bold',
            padding: '0.25rem 0.67rem',
            borderRadius: '0.3125rem',
          }"
        >{{ fileName }}</span>
        <span
          v-if="responseTotal > 0"
          :style="{
            fontWeight: 'bold',
            marginBlockStart: '1rem',
          }"
        >
          {{
            t(`projects.mapping.modal.message.hasAdded`, {
              response: !isNaN(responseTotal) ? responseTotal : 0,
            })
          }}
        </span>
        <responseUpdated is="updated" v-if="uploadResponse.updated > 0" :data="uploadResponse.updated" />
        <responseUpdated is="created" v-if="uploadResponse.created > 0" :data="uploadResponse.created" />
      </div>
    </template>
  </Modal>
</template>

<route lang="yaml">
meta:
  layout: default-full-page
  stage: mapping
</route>

<style scoped lang="scss">
.project-activities {
  min-height: 100vh;
}

.project-container {
  padding-top: 3.75rem;
}

.project-wrapper {
  display: flex;
  flex-direction: row;
  padding-inline: 1.5rem;
  padding-bottom: 2.5rem;
  gap: 4.125rem;
  margin-top: 5rem;
  padding-top: 0;
  background: transparent;

  .empty-persons {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-block: 5rem;
  }

  .mapping-filter {
    display: flex;
    min-width: 15rem;
    flex-direction: column;
  }

  .mapping-candidates {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 1rem;

    &-actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 2rem;
      padding-block-end: 2rem;

      .parser {
        background: $white-full;

        .dropzone {
          width: 22.125rem;
          height: 5.5rem;
        }
      }
    }

    &-filter {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .amount {
        font-weight: bold;
      }

      .order {
        width: 14rem;
      }
    }

    &-list {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      // overflow: auto;
      // &::-webkit-scrollbar {
      //   display: none;
      // }

      &-buttons {
        min-width: max-content;
        font-size: 0.75rem;
      }
    }
  }
}

.center-list {
  display: flex;
  flex-direction: column;
  line-height: 1.5;
  padding-inline: 2rem;
  text-align: left;
}

:deep(.vfm__content.modal-content) .modal-header {
  font-size: 1.25rem;
}

:deep(.modal-container) .buttons {
  gap: 4rem;
}

.modal-confirm:deep {
  .modal-title {
    font-size: 1.25rem;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
  }

  .modal-message {
    font-size: 1.25rem;
    font-weight: normal;
    line-height: 1.4;
    padding-inline: 1rem;
  }

  .content-wrapper {
    width: 45rem;
    min-width: 340px;
    padding: 3rem 3.25rem;
  }

  .buttons-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }

  .confirm-button {
    border-color: $secondary;
    background-color: $secondary;
    color: $white-full;
  }

  .cancel-button {
    border: 1px solid;
    border-color: $secondary;
    background-color: $white-full;
    color: $secondary;

    &:hover {
      background-color: $secondary;
      color: $white-full;
    }
  }
}

.tooltip-message {
  text-align: center;
  font-size: 0.75rem;
  font-weight: bold;
  color: #707070;
  text-transform: uppercase;
}

.box-loading {
  display: flex;
  flex-direction: column;
  padding-block: 5rem;
  align-items: center;
}

.loader-wrapper {
  width: 100%;
  height: 2rem;
  padding-block: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  width: 2rem;
  height: 2rem;
  border: 5px solid;
  border-color: $yellow transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
</style>
