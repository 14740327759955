<script lang="ts">
import type { Ref } from "vue";
import { computed, defineComponent, provide, ref, toRef, watch } from "vue";
import { useI18n } from "vue-i18n";
import type { RouterLinkProps } from "vue-router";
import cloneDeep from "lodash-es/cloneDeep";
import get from "lodash-es/get";
import Dropdown from "primevue/dropdown";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiClose, mdiPlusCircleOutline } from "@mdi/js";
import { computedAsync } from "@vueuse/core";
import type {
  Ceo,
  Corporation,
  Governance,
  InvestorAndParentCompany,
  SubsidiaryAndInvestedCompany,
} from "@/entities/corporation";
import type { Person } from "@/http_services/entities/types";
import { SectionTitle } from "@/components/section_title";
import { SectionContent } from "@/components/section_content";
import { CustomCheckBox } from "@/components/checkbox";
import { SearchDropdown, Select, TextArea } from "@/components/inputs/modalInputs";
import { RadioButton } from "@/components/radio";
import { Button2, ButtonIcon } from "@/components/button";
import TrashIcon from "@/assets/images/trash_icon.svg";
import { getPerson, searchPersons } from "@/http_services/entities/persons";
import { searchCorporation } from "@/http_services/entities/corporation";
import { registeredCountries } from "@/utils/adapters/form/consts";
import type { RelatedCorporation } from "@/entities/common";
import PersonDetail from "@/persons/pages/persons/components/PersonDetail/PersonDetail.vue";

interface GovernanceLabel {
  label: string
  value: string | number
  link?: RouterLinkProps
  extraClass?: string
}

type InvestorOrSubsidiary = InvestorAndParentCompany | SubsidiaryAndInvestedCompany;

type CompanyRelationship = "investor" | "subsidiary";

export default defineComponent({
  name: "GovernanceSection",
  components: {
    Dropdown,
    SectionTitle,
    SectionContent,
    ButtonIcon,
    TextArea,
    Select,
    CustomCheckBox,
    SearchDropdown,
    RadioButton,
    PersonDetail,
    Button: Button2,
    SvgIcon,
    TrashIcon,
  },
  props: {
    governance: {
      type: Object,
      required: true,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    noEdit: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["click:edit", "click:editCancel", "submit:governance"],
  setup(props, { emit }) {
    const { t, tm, locale } = useI18n();
    const localeLang = locale.value.split("-")[0];

    const governance = toRef(props, "governance") as Ref<Governance>;

    const generatePayload = (newGovernance: Governance): Governance => {
      const {
        economic_group: eg,
        investor_and_parent_companies: ipc,
        subsidiaries_and_invested_companies: sic,
      } = newGovernance as Governance;

      return {
        ...(newGovernance || {}),
        economic_group: eg?.corporations_data
          ? eg
          : { corporations_ids: [], corporations_data: [] },
        investor_and_parent_companies: ipc?.length ? ipc : [{}],
        subsidiaries_and_invested_companies: sic?.length ? sic : [{}],
        headquarters: newGovernance?.headquarters || "BR",
      };
    };

    const payload = ref(generatePayload(cloneDeep(governance.value))) as Ref<Governance>;

    watch(
      governance,
      (newGovernance) => {
        if (newGovernance)
          payload.value = generatePayload(cloneDeep(newGovernance) as Governance);
      },
      { deep: true },
    );

    const reduceInvestorsAndSubsidiaries
      = (companyRelationship: CompanyRelationship) => (
        a: GovernanceLabel[],
        next: InvestorOrSubsidiary,
        index: number,
        array: InvestorOrSubsidiary[],
      ): GovernanceLabel[] => {
        const { corporation_data, relationship_details, business_equity_type: bet } = next;

        const businessEquityType = bet
          ? `corporations.details.businessEquityType.enum.${companyRelationship}.${bet}`
          : "";

        const showNumber = array.length > 1 ? ` ${index + 1}` : "";

        return [
          ...a,
          {
            label: `${t(`corporations.details.${companyRelationship}`)}${showNumber}`,
            value: get(corporation_data, "name", ""),
          },
          {
            label: t("corporations.details.companyRole"),
            value: t(businessEquityType),
          },
          {
            label: t("corporations.details.description"),
            value: relationship_details,
          },
        ];
      };
    const governanceMap = computed(() => {
      const { headquarters, business_type, business_level, economic_group, ceo, details }
        = governance.value;

      const headquartersLabel = headquarters === "BR" ? "BR" : "OTHER_WITH_COUNTRY";

      const investors = [
        ...get(governance.value, "investor_and_parent_companies", []),
      ] as InvestorAndParentCompany[];

      const subsidiaries = [
        ...get(governance.value, "subsidiaries_and_invested_companies", []),
      ] as SubsidiaryAndInvestedCompany[];

      if (!investors.length)
        investors.push({ corporation: "" });
      if (!subsidiaries.length)
        subsidiaries.push({ corporation: "" });

      const ceoAsCeo = ceo as Ceo;
      const egCorporations = economic_group?.corporations_data;

      return [
        {
          label: t("corporations.details.headquarters.name"),
          value: headquarters
            ? t(`corporations.details.headquarters.enum.${headquartersLabel}`, {
              country: registeredCountries.getName(headquarters, localeLang),
            })
            : "",
        },
        {
          label: t("corporations.details.type.name"),
          value: t(`corporations.details.type.enum.${business_type}`, " "),
        },
        {
          label: t("corporations.details.level.name"),
          value: t(`corporations.details.level.enum.${business_level}`, " "),
        },
        {
          label: t("corporations.details.economicGroup"),
          value: egCorporations ? egCorporations.map(item => item.name).join(" / ") : "",
        },
        ...investors.reduce(reduceInvestorsAndSubsidiaries("investor"), []),
        ...subsidiaries.reduce(reduceInvestorsAndSubsidiaries("subsidiary"), []),
        {
          label: t("corporations.details.ceo"),
          extraClass: "highlight",
          id: ceoAsCeo?.person_id,
          link: ceoAsCeo?.person_id
            ? { to: { name: "PersonDetails", params: { id: ceoAsCeo.person_id } } }
            : null,
          value: ceoAsCeo?.person_id
            ? `${ceoAsCeo.person_data.first_name} ${ceoAsCeo.person_data.last_name}`
            : "",
        },
        {
          label: t("corporations.details.details"),
          value: details,
        },
      ];
    });

    const isVisible = ref(false);

    const ceoId = computed(() => {
      return governance.value.ceo?.person_id;
    });

    const ceo = computedAsync(async () => {
      if (ceoId.value)
        return await getPerson(ceoId.value);
    }, null);

    const onCloseDetails = () => {
      isVisible.value = false;
    };

    provide("selectedPerson", ceo);
    provide("onCloseDetails", onCloseDetails);

    const updateCorporationInput = (corporation: Corporation): string => corporation.name;

    const businessTypes = Object.entries(tm("corporations.details.type.enum")).map(
      ([value, label]) => ({ label, value }),
    );

    const businessLevels = Object.entries(tm("corporations.details.level.enum")).map(
      ([value, label]) => ({ label, value }),
    );

    const selectedHeadquarters = ref(
      payload.value.headquarters === "BR" ? payload.value.headquarters : "OTHER",
    );

    const businessHeadquarters = Object.entries(tm("corporations.details.headquarters.enum"))
      .filter(([key]) => key !== "OTHER_WITH_COUNTRY")
      .map(([value, label]) => ({ label, value }));

    const countriesHeadquarters = computed(() =>
      Object.entries(registeredCountries.getNames(localeLang))
        .filter(([key]) => key !== "BR")
        .map(([value, label]) => ({ label, value })));

    watch(selectedHeadquarters, (value: string) => {
      payload.value.headquarters = value !== "OTHER" ? value : "";
    });

    const searchCeo = async params => searchPersons(params, undefined, params.name);

    const selectCeo = (person: Person): void => {
      const { id } = person;
      payload.value.ceo = { person_id: id, person_data: person };
    };

    const updateCeoSearchInput = (person: Person): string =>
      `${person.first_name} ${person.last_name}`;

    const selectCorporation = (corporation: Corporation): void => {
      const { name, business_categories, id } = corporation;

      if (payload.value.economic_group.corporations_data.length < 5) {
        (payload.value.economic_group.corporations_data as RelatedCorporation[]).push({
          _id: id,
          name,
        });
      }
    };

    const removeCorporation = (corporationIndex: number): void => {
      payload.value.economic_group.corporations_data.splice(corporationIndex, 1);
    };

    const selectInvestorAndParent = (corporation: Corporation, investorIndex: number): void => {
      const { name, business_categories, id } = corporation;
      const currentInvestorValues = payload.value.investor_and_parent_companies[
        investorIndex
      ] as InvestorAndParentCompany;

      payload.value.investor_and_parent_companies[investorIndex] = {
        ...currentInvestorValues,
        corporation_id: id,
        corporation_data: { name },
      };
    };

    const removeInvestor = (investorIndex: number) => {
      if (payload.value.investor_and_parent_companies.length === 1)
        payload.value.investor_and_parent_companies[0] = { corporation_data: { name: "" } };
      else
        payload.value.investor_and_parent_companies.splice(investorIndex, 1);
    };

    const lastInvestor = computed(() => {
      const { investor_and_parent_companies: ipc } = payload.value;
      return ipc?.[ipc.length - 1];
    });

    const businessEquityInvestorTypes = Object.entries(
      tm("corporations.details.businessEquityType.enum.investor"),
    ).map(([value, label]) => ({ label, value }));

    const selectSubsidiaryAndInvestedCompany = (
      corporation: Corporation,
      subsidiaryIndex: number,
    ): void => {
      const { name, business_categories, id } = corporation;
      const currentInvestorValues = payload.value.subsidiaries_and_invested_companies[
        subsidiaryIndex
      ] as SubsidiaryAndInvestedCompany;

      payload.value.subsidiaries_and_invested_companies[subsidiaryIndex] = {
        ...currentInvestorValues,
        corporation_id: id,
        corporation_data: { name },
      };
    };

    const removeSubsidiary = (subsidiaryIndex: number) => {
      if (payload.value.subsidiaries_and_invested_companies.length === 1)
        payload.value.subsidiaries_and_invested_companies[0] = { corporation_data: { name: "" } };
      else
        payload.value.subsidiaries_and_invested_companies.splice(subsidiaryIndex, 1);
    };

    const lastSubsidiary = computed(() => {
      const { subsidiaries_and_invested_companies: sic } = payload.value;
      return sic?.[sic.length - 1];
    });

    const businessEquitySubsidiaryTypes = Object.entries(
      tm("corporations.details.businessEquityType.enum.subsidiary"),
    ).map(([value, label]) => ({ label, value }));

    const validations = computed(() => {
      const investor = payload.value.investor_and_parent_companies as InvestorAndParentCompany[];
      const sub = payload.value
        .subsidiaries_and_invested_companies as SubsidiaryAndInvestedCompany[];

      return {
        investor_and_parent_companies: investor.map(({ relationship_details }) => ({
          relationship_details: {
            valid: !relationship_details || relationship_details?.length <= 650,
          },
        })),
        subsidiaries_and_invested_companies: sub.map(({ relationship_details }) => ({
          relationship_details: {
            valid: !relationship_details || relationship_details?.length <= 650,
          },
        })),
      };
    });

    const isValid = computed(() => {
      const { investor_and_parent_companies, subsidiaries_and_invested_companies }
        = validations.value;
      const investorValid = investor_and_parent_companies.every(
        ({ relationship_details }) => relationship_details.valid,
      );
      const subsidiariesValid = subsidiaries_and_invested_companies.every(
        ({ relationship_details }) => relationship_details.valid,
      );
      return [investorValid, subsidiariesValid].every(validation => validation);
    });

    const submit = () => {
      const eg_ids = payload.value.economic_group.corporations_data.map(item => item._id);

      const investor = payload.value.investor_and_parent_companies?.filter(
        item => item.corporation_id,
      );
      const sub = payload.value.subsidiaries_and_invested_companies.filter(
        item => item.corporation_id,
      );

      emit("submit:governance", {
        governance: {
          ...payload.value,
          economic_group: {
            corporations_ids: eg_ids,
          },
          investor_and_parent_companies: investor,
          subsidiaries_and_invested_companies: sub,
        },
      });
    };

    const cancel = () => {
      payload.value = cloneDeep(governance.value);
      emit("click:editCancel");
    };

    return {
      t,
      payload,
      governanceMap,
      updateCorporationInput,
      businessTypes,
      businessLevels,
      selectedHeadquarters,
      businessHeadquarters,
      countriesHeadquarters,
      searchCeo,
      updateCeoSearchInput,
      selectCeo,
      searchCorporation,
      selectCorporation,
      removeCorporation,
      selectInvestorAndParent,
      removeInvestor,
      lastInvestor,
      businessEquityInvestorTypes,
      selectSubsidiaryAndInvestedCompany,
      removeSubsidiary,
      lastSubsidiary,
      businessEquitySubsidiaryTypes,
      validations,
      isValid,
      submit,
      cancel,
      close: mdiClose,
      addMore: mdiPlusCircleOutline,
      ceo,
      isVisible,
      ceoId,
    };
  },
});
</script>

<template>
  <section class="governance-section">
    <SectionTitle
      :title="t('corporations.details.governance')"
      :is-editing="noEdit || isEditing"
      @click="$emit('click:edit')"
    />

    <div class="section-content">
      <template v-if="!isEditing">
        <SectionContent>
          <p
            v-for="item in governanceMap"
            :key="item"
            class="governance-item"
            :class="[item.extraClass]"
          >
            <b>{{ item.label }}:</b>&nbsp;

            <template v-if="item.link">
              <a class="redirect-link" @click="isVisible = !isVisible">
                {{ item.value }}
              </a>
            </template>

            <template v-else>
              {{ item.value }}
            </template>
          </p>
        </SectionContent>
      </template>

      <template v-else>
        <form class="governance-form" @submit.prevent="submit()">
          <div class="form-group form-group--flex">
            <label> {{ t("corporations.details.headquarters.name") }}: </label>

            <RadioButton
              v-for="nation in businessHeadquarters"
              :id="nation.value"
              :key="nation"
              v-model="selectedHeadquarters"
              name="headquarters"
              :value="nation.value"
              :label="nation.label"
            />
            <Dropdown
              v-if="selectedHeadquarters === 'OTHER' || selectedHeadquarters === 'EMPTY'"
              v-model="payload.headquarters"
              class="countries"
              :options="countriesHeadquarters"
              option-label="label"
              option-value="value"
              :placeholder="t('text.select')"
            />
          </div>

          <div class="business-wrapper">
            <div class="form-group form-group--grid">
              <label for="business_type">{{ t("corporations.details.type.name") }}:</label>
              <Select
                id="business_type"
                v-model="payload.business_type"
                :placeholder="t('text.select')"
                no-feedback
                :options="businessTypes"
              />
            </div>

            <div class="form-group form-group--grid">
              <label for="business_level">{{ t("corporations.details.level.name") }}</label>
              <Select
                id="business_level"
                v-model="payload.business_level"
                :placeholder="t('text.select')"
                no-feedback
                :options="businessLevels"
              />
            </div>
          </div>

          <div class="form-group form-group--grid">
            <label class="form-label" for="ceo">
              {{ t("corporations.details.economicGroup") }}:
            </label>

            <div>
              <SearchDropdown
                id="economic-group"
                :fetch-method="searchCorporation"
                :select-item-callback="selectCorporation"
                :update-input-model="() => ''"
                :input-placeholder="t('text.searchCorporation')"
                :search-not-found-label="t('text.searchCorporationNotFound')"
                :search-error-label="t('text.searchCorporationError')"
              >
                <template #list-item="{ item: corporation }">
                  {{ corporation.name }}
                </template>
              </SearchDropdown>

              <div>
                <span
                  v-for="(economicGroup, i) in payload.economic_group.corporations_data"
                  :key="economicGroup"
                  class="tag"
                >
                  {{ economicGroup?.name }}

                  <span role="button" @click="removeCorporation(i)">
                    <SvgIcon type="mdi" :path="close" size="16" />
                  </span>
                </span>
              </div>
            </div>
          </div>

          <div class="form-container form-container--large-spacing">
            <div
              v-for="(investor, index) in payload.investor_and_parent_companies"
              :key="investor"
              class="form-container"
            >
              <div class="form-group form-group--grid">
                <label class="form-label">
                  {{ t("corporations.details.linkInvestorAndParentCompanies") }}:
                </label>

                <SearchDropdown
                  :id="`investor-and-parent-${index}`"
                  :fetch-method="searchCorporation"
                  :select-item-callback="
                    (corporation) => selectInvestorAndParent(corporation, index)
                  "
                  :update-input-model="updateCorporationInput"
                  :initial-value="investor.corporation_data?.name"
                  :input-placeholder="t('text.searchCorporation')"
                  :search-not-found-label="t('text.searchCorporationNotFound')"
                  :search-error-label="t('text.searchCorporationError')"
                >
                  <template #list-item="{ item: corporation }">
                    {{ corporation.name }}
                  </template>
                </SearchDropdown>
              </div>

              <template v-if="investor.corporation_data?.name">
                <div class="form-group">
                  <CustomCheckBox
                    :label="t('corporations.details.mainInvestor')"
                    :checked="investor.main_investor"
                    :on-change="(e) => (investor.main_investor = e.target.checked)"
                  />
                </div>

                <div class="form-group form-group--grid">
                  <label class="form-label" for="business_equity_type">
                    {{ t("corporations.details.businessEquityType.name") }}
                  </label>

                  <Select
                    id="business_equity_type"
                    v-model="investor.business_equity_type"
                    :placeholder="t('corporations.details.businessEquityType.name')"
                    no-feedback
                    :options="businessEquityInvestorTypes"
                  />
                </div>

                <div class="form-group">
                  <TextArea
                    v-model="investor.relationship_details"
                    :placeholder="t('corporations.details.placeholders.details')"
                    :warning-chars="650"
                  />
                </div>
              </template>

              <ButtonIcon
                v-if="investor.corporation_data?.name"
                class="remove"
                type="button"
                :title="t('corporations.details.forms.placeholders.removeInvestor')"
                :aria-label="t('corporations.details.forms.placeholders.removeInvestor')"
                @click="removeInvestor(index)"
              >
                <TrashIcon />
              </ButtonIcon>
            </div>

            <div v-if="lastInvestor?.corporation_data?.name" class="form-extra-actions">
              <span
                class="form-extra-actions__item add"
                role="button"
                @click="payload.investor_and_parent_companies.push({})"
              >
                <SvgIcon type="mdi" :path="addMore" size="16" />
                {{ t("corporations.details.addRelationship") }}
              </span>
            </div>
          </div>

          <div class="form-container form-container--large-spacing">
            <div
              v-for="(subsidiary, index) in payload.subsidiaries_and_invested_companies"
              :key="subsidiary"
              class="form-container"
            >
              <div class="form-group form-group--grid">
                <label class="form-label">
                  {{ t("corporations.details.linkSubsidiaryAndInvestedCompanies") }}:
                </label>

                <SearchDropdown
                  :id="`subsidiary-and-invested-${index}`"
                  :fetch-method="searchCorporation"
                  :select-item-callback="
                    (corporation) => selectSubsidiaryAndInvestedCompany(corporation, index)
                  "
                  :update-input-model="updateCorporationInput"
                  :initial-value="subsidiary.corporation_data?.name"
                  :input-placeholder="t('text.searchCorporation')"
                  :search-not-found-label="t('text.searchCorporationNotFound')"
                  :search-error-label="t('text.searchCorporationError')"
                >
                  <template #list-item="{ item: corporation }">
                    {{ corporation.name }}
                  </template>
                </SearchDropdown>
              </div>

              <template v-if="subsidiary.corporation_data?.name">
                <div class="form-group">
                  <CustomCheckBox
                    :label="t('corporations.details.mainInvestor')"
                    :checked="subsidiary.main_investor"
                    :on-change="(e) => (subsidiary.main_investor = e.target.checked)"
                  />
                </div>

                <div class="form-group form-group--grid">
                  <label class="form-label" for="business_equity_type">
                    {{ t("corporations.details.businessEquityType.name") }}
                  </label>

                  <Select
                    id="business_equity_type"
                    v-model="subsidiary.business_equity_type"
                    :placeholder="t('corporations.details.businessEquityType.name')"
                    no-feedback
                    :options="businessEquitySubsidiaryTypes"
                  />
                </div>

                <div class="form-group">
                  <TextArea
                    v-model="subsidiary.relationship_details"
                    :placeholder="t('corporations.details.placeholders.details')"
                    :warning-chars="650"
                  />
                </div>
              </template>

              <ButtonIcon
                v-if="subsidiary.corporation_data?.name"
                class="remove"
                type="button"
                :title="t('corporations.details.forms.placeholders.removeInvestor')"
                :aria-label="t('corporations.details.forms.placeholders.removeInvestor')"
                @click="removeSubsidiary(index)"
              >
                <TrashIcon />
              </ButtonIcon>
            </div>

            <div v-if="lastSubsidiary?.corporation_data?.name" class="form-extra-actions">
              <span
                class="form-extra-actions__item add"
                role="button"
                @click="payload.subsidiaries_and_invested_companies.push({})"
              >
                <SvgIcon type="mdi" :path="addMore" size="16" />
                {{ t("corporations.details.addRelationship") }}
              </span>
            </div>
          </div>

          <div class="form-group form-group--grid">
            <label class="form-label" for="ceo"> {{ t("corporations.details.ceo") }}: </label>

            <SearchDropdown
              id="ceo"
              :fetch-method="searchCeo"
              :select-item-callback="selectCeo"
              :update-input-model="updateCeoSearchInput"
              :initial-value="
                payload.ceo
                  ? `${payload.ceo.person_data.first_name} ${payload.ceo.person_data.last_name}`
                  : ''
              "
              :input-placeholder="t('text.searchProfile')"
              :search-not-found-label="t('text.searchProfileNotFound')"
              :search-error-label="t('text.searchProfileError')"
              @clear="payload.ceo = null"
            >
              <template #list-item="{ item: person }">
                {{ `${person.first_name} ${person.last_name}` }}
              </template>
            </SearchDropdown>
          </div>

          <div class="form-group">
            <TextArea
              v-model="payload.details"
              :placeholder="t('corporations.details.placeholders.details')"
              :warning-chars="650"
            />
          </div>

          <div class="form-actions">
            <Button type="submit" :disabled="!isValid">
              {{ t("text.form.submit") }}
            </Button>

            <Button variation="light" filled @click="cancel()">
              {{ t("text.form.cancel") }}
            </Button>
          </div>
        </form>
      </template>
    </div>
  </section>
  <PersonDetail :visible="isVisible" />
</template>

<style lang="scss" scoped>
.position-relative {
  position: relative;
}

.remove {
  color: $gray;

  svg {
    width: 16px;
    height: 16px;
    stroke: $gray-medium;
  }
}

.add {
  color: $primary;
  font-size: 0.875rem;

  svg {
    vertical-align: sub;
  }
}

.countries:deep {
  .p-dropdown-label {
    padding-block: 0.375rem;
  }

  .p-dropdown-trigger-icon {
    color: $gray-border;
  }
}

.component-select:deep {
  min-height: auto;
}

.tag {
  display: inline-block;
  max-width: 100%;
  padding: 0.5rem 0.75rem;
  border-radius: 8px;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  background-color: $white-full;
  box-shadow: 0 1px 3px #0003;
  color: $gray-dark;
  font-size: 0.75rem;
  word-wrap: break-word;

  span[role="button"] {
    &:hover {
      color: lighten($gray-dark, 25%);
    }

    svg {
      vertical-align: text-top;
    }
  }
}

.governance-section {
  margin-bottom: 2.5rem;

  .governance-item {
    margin-bottom: 0.5rem;

    &.highlight {
      .redirect-link {
        color: $primary;
        cursor: pointer;
        font-weight: bold;
        text-decoration: none;
      }
    }
  }
}

$form-container-margin: 1rem;

.governance-form {
  .form-group {
    position: relative;
    align-items: flex-start;
    margin-bottom: 1rem;

    .form-label {
      display: flex;
      height: 28px;
      align-items: center;
      font-size: 0.875rem;
    }

    &--grid {
      display: grid;
      grid-template-columns: 1fr 4fr;
    }

    &--flex {
      display: flex;
      gap: 1rem;
    }
  }

  .form-container {
    margin-bottom: $form-container-margin;

    &--large-spacing {
      margin-bottom: calc(#{$form-container-margin} * 1.25);
    }
  }

  .business-wrapper {
    display: grid;
    padding-right: 25%;
    gap: 15%;
    grid-template-columns: 1fr 1fr;
  }

  .form-actions {
    display: grid;
    padding-right: 45%;
    margin-top: 2rem;
    grid-gap: 5rem;
    grid-template-columns: repeat(2, 1fr);
  }

  .form-extra-actions {
    display: flex;
    height: 0;
    justify-content: flex-end;

    &__item {
      position: relative;
      bottom: calc(#{$form-container-margin} + 1rem);
    }
  }
}
</style>
