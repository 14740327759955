import { ATTRIBUTES_TITLES } from "@/entities/project/construction-guide";

export const attributes = {
  [ATTRIBUTES_TITLES.COGNITIVE_INTELLIGENCE]: "Inteligência Cognitiva",
  [ATTRIBUTES_TITLES.EMOTIONAL_INTELLIGENCE]: "Inteligência Emocional",
  [ATTRIBUTES_TITLES.SPIRITUAL_INTELLIGENCE]: "Inteligência Espiritual",
  [ATTRIBUTES_TITLES.ENERGY]: "Energia",
  [ATTRIBUTES_TITLES.MATURITY]: "Maturidade",
  [ATTRIBUTES_TITLES.POWER]: "Potência",
};
