<script setup lang="ts">
import { get, isEmpty } from "lodash";
import { getOr } from "lodash/fp";
import moment from "moment";
import { withDefaults } from "vue";
import { useI18n } from "vue-i18n";
import AvatarList from "../AvatarList.vue";
import { SvgIcon } from "@/components/icon";
import type { IPersonContent } from "@/components/person_card/components";
import type { Person } from "@/entities/Person/person";
import { PAYMENT_FREQUENCY } from "@/entities/Person/work-experience";
import type { RelatedCorporation, RelatedRecruiter } from "@/schemas/common";
import type { WorkExperience } from "@/schemas/person";
import { sortExperiences } from "@/utils/experiences-sorter";
import { formatCurrencyFromValue, parsePhones } from "@/utils/parsers";

const props = withDefaults(
  defineProps<{
    person: Person
    personContent?: IPersonContent | null | undefined
    isRow?: boolean
    hasTimeline?: boolean
    isPersonCard?: boolean
  }>(),
  {
    isRow: false,
    hasTimeline: true,
    personContent: null,
  },
);

const { t } = useI18n();

const fullNameLength: number | undefined
  = (props.person
    ? `${props.person.first_name} ${props.person.last_name}`.length
    : props.personContent?.name.length) ?? "".length;

function yearFor(date?: WorkExperience["start_date"] | string | null) {
  if (!date)
    return t("persons.personCard.currentYear");

  return moment(date).year();
}

const sortedExperiences = sortExperiences(props.person?.work_experience ?? []);

const job = !isEmpty(sortedExperiences) ? sortedExperiences[0] : null;

const company = ref(job ? job.company?.corporation_data : null) as Ref<RelatedCorporation | null>;

const sector = get(job, "company.corporation_data.business_categories[0].sector", "");

const recruiters = getOr([], "related_recruiters", props.person).map((item: RelatedRecruiter) => {
  return item.person_data;
});

function paymentFrequencies(value: PAYMENT_FREQUENCY) {
  const data: Record<PAYMENT_FREQUENCY, string> = {
    [PAYMENT_FREQUENCY.MONTHLY]: t(
      "persons.workExperienceForm.placeholders.paymentFrequencies.monthly",
    ),
    [PAYMENT_FREQUENCY.ANNUALLY]: t(
      "persons.workExperienceForm.placeholders.paymentFrequencies.yearly",
    ),
  };
  return data[value] ? ` / ${data[value]}` : "";
}
</script>

<template>
  <div class="description" :class="{ row: isRow }">
    <div v-if="personContent || job || sector" class="division">
      <div v-if="!isPersonCard" class="line">
        <span v-if="personContent?.company">
          {{ personContent?.company }}
        </span>
        <span v-else-if="company">
          {{ company.name }}
        </span>
        <span v-if="job && isPersonCard">
          <span v-if="job.start_date">
            &nbsp;- {{ yearFor(job.start_date) }} / {{ yearFor(job.end_date) }}
          </span>
        </span>
      </div>
      <div v-if="personContent?.role || job?.role_name" class="line">
        <span v-if="personContent?.role">
          {{ personContent.role }}
        </span>
        <span v-else-if="job?.role_name">
          {{ job.role_name }}&nbsp;
        </span>
        <span v-if="sector && !isPersonCard">
          &nbsp;{{ t(`corporations.sector.enum.${sector}`) }}
        </span>
      </div>
      <div v-if="isPersonCard" class="line">
        <span v-if="personContent?.company">
          {{ personContent.company }}
        </span>
        <span v-else-if="company || job?.company?.name">
          {{ company?.name || job?.company?.name }}&nbsp;-&nbsp;
        </span>
        <span v-if="company && isPersonCard || job?.company?.name">
          <span v-if="sector && isPersonCard">
            &nbsp;{{ t(`corporations.sector.enum.${sector}`) }}
          </span>
          <span v-if="job?.start_date">
            {{ yearFor(job?.start_date) }} / {{ yearFor(job?.end_date) }}
          </span>
        </span>
      </div>
      <div class="line">
        <span>
          <SvgIcon icon="cash_icon" width="0.625rem" height="0.875rem" />
          <span v-if="job?.salary?.amount">
            {{ formatCurrencyFromValue(job.salary.amount, job.salary.currency) }}
            {{ paymentFrequencies(job?.salary?.payment_frequency) }}
          </span>
        </span>
      </div>
    </div>
    <div class="division">
      <div class="line">
        <span>
          <SvgIcon icon="phone_icon" width="0.875rem" height="0.875rem" />
          <span v-if="person?.phones?.length > 0">
            {{ `${parsePhones(person.phones.slice(0, 2))}` }}
          </span>
        </span>
      </div>
      <div class="line">
        <span>
          <SvgIcon icon="mail_icon" width="0.875rem" height="0.875rem" />
          <span v-if="person.email_addresses?.length > 0">
            {{ person?.email_addresses[0]?.email_address }}
          </span>
        </span>
      </div>
      <div class="line space-between">
        <span>
          <SvgIcon icon="map_pin_icon" width="0.875rem" height="0.875rem" />
          <span v-if="person.address">
            {{ person.address.name }}
            <span v-if="person.address.region">
              &nbsp;- {{ person.address.region }}
            </span>
          </span>
        </span>
        <span v-if="recruiters">
          <AvatarList :people="recruiters" />
        </span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.description {
  display: flex;
  flex-direction: column;
  margin-block-start: 0.75rem;
  gap: 1.5rem;

  .division {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 0.5rem;

    .line {
      display: flex;
      flex-wrap: wrap;
      font-size: 0.8125rem;
      color: $gray-dark;

      span {
        display: flex;
        align-items: center;
      }
    }

    .space-between {
      display: flex;
      justify-content: space-between;
    }

    .flex-end {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.bold {
  font-size: 0.8125rem;
  font-weight: bold;
}
</style>
