<script setup lang="ts">
import { normalizeProps, useMachine } from "@zag-js/vue";
import { machine } from "@zag-js/slider";
import bson from "bson-objectid";

import * as slider from "@zag-js/slider";

type ValueMapKey = keyof typeof valueMap;
interface OptionsSchema {
  label: string
  value: string | number | null
}
const props = withDefaults(defineProps<{
  options: OptionsSchema[] | null
  modelValue: ValueMapKey | undefined | null
  controlValue?: string | number | null
  markerColor?: string
  trackColor?: string
  backgroundColor?: string
  scoreMarker?: ValueMapKey
  disabled?: boolean
  controlColor?: string
  constructionValueColor: "#F8D272" | "#a8a8a8"

}>(), {
  trackColor: "#F4F4F4",
  backgroundColor: "#666",
  markerColor: "#fcaf45",
  controlColor: "yellow-jasmine",
  constructionValueColor: "#a8a8a8",
});

const emit = defineEmits(["update:modelValue"]);

const valueMap = {
  EMPTY: 0,
  LOW: 1,
  AVERAGE: 2,
  HIGH: 3,
  VERY_HIGH: 4,
};

const valueMapValues: { [key: number]: ValueMapKey } = {
  0: "EMPTY",
  1: "LOW",
  2: "AVERAGE",
  3: "HIGH",
  4: "VERY_HIGH",
};

const currentValue = ref(valueMap[toRaw(props.modelValue) ?? "EMPTY"]);
function onChange(v: { value: number }) {
  if (props.disabled)
    return;

  currentValue.value = v.value;
  emit("update:modelValue", valueMapValues[v.value]);
}

const [state, send] = useMachine(machine({
  id: bson().toHexString(),
  min: 0,
  max: 4,
  step: 1,
  value: currentValue.value,
  onChange,
}));

const api = computed(() => slider.connect(state.value, send, normalizeProps));

const offsetMap: { [key: number]: number } = {
  0: 0,
  1: 25,
  2: 50,
  3: 75,
  4: 100,
};
function getKeyNumber(key: string): number {
  switch (key) {
    case "EMPTY":
      return 0;
    case "LOW":
      return 1;
    case "AVERAGE":
      return 2;
    case "HIGH":
      return 3;
    case "VERY_HIGH":
      return 4;
    default:
      throw new Error("Invalid key");
  }
}

function setRemarker(scoreMarker: keyof typeof valueMap | string) {
  const keyNumber = getKeyNumber(scoreMarker);
  return offsetMap[keyNumber];
};

const maxLevel = props.options?.length || 1;
const percent = 100 / maxLevel;
const colEmpty = percent / 2;

function checkPosition(index: number, marker: boolean): number {
  const isMaxLevel = index + 1 === maxLevel;
  return marker ? (isMaxLevel ? 0.7 : colEmpty) : isMaxLevel ? 0 : colEmpty;
}

const currentOffset: ComputedRef<number | null | undefined> = computed(() => {
  if (currentValue.value === 4)
    return null;

  return offsetMap[currentValue.value];
});

function getOption(value: any, maker = false): { progress: string; data?: any } {
  const option = props.options?.find(item => item.value === value);

  if (option && props.options) {
    const index = props.options?.indexOf(option);
    const progress = `${percent * (index + 1) - checkPosition(index, maker)}`;
    return { progress, data: option };
  }

  return { progress: "0" };
}

const controlValueWidth = computed(() => {
  const progress = Number.parseFloat(getOption(props.controlValue)?.progress) || 0;
  return {
    width: `${progress}%`,
  };
});
</script>

<template>
  <div>
    <div v-bind="api.controlProps">
      <div v-bind="api.trackProps" :data-disabled="disabled">
        <div
          v-bind="api.rangeProps"
          :style="`width: ${
            currentValue === 4 ? '100%' : `calc(${currentOffset}% + ${currentValue === 0 ? '0px' : '1rem'})`
          }`"
        />
      </div>
      <div
        v-bind="api.thumbProps"
        :data-disabled="disabled"
        :style="`background: white; left: ${
          currentValue === 4 ? `calc(100% - 1rem)` : `${currentOffset}%`
        };`"
      >
        <input v-bind="api.hiddenInputProps">
      </div>
      <div
        class="control-value"
        :class="[`--bg-${constructionValueColor}`, `${setRemarker(scoreMarker as string) === 100 ? 'complete' : ''}`]"
        :style="[controlValueWidth, `width: calc(${setRemarker(scoreMarker as string)}% + 0.5rem)`]"
      />
    </div>
    <div v-bind="api.markerGroupProps" class="custom-marker">
      <span
        v-for="value in [1, 2, 3, 4]"
        :key="value"
        v-bind="api.getMarkerProps({ value })"
        :style="`left: calc(${offsetMap[value]}% + 0.5rem ${offsetMap[value] === 100 ? '- 0.7%' : ''})`"
        :data-score-marker="$props.scoreMarker ? valueMap[$props.scoreMarker] === value : null"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
[data-part='thumb'] {
  width: 1.4375rem;
  height: 1.4375rem;
  border-radius: 100%;
  cursor: pointer;
  z-index: 3;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.25);
  transform: 100ms;
}

[data-part='thumb']:hover:not([data-disabled]),
[data-part='thumb']:focus-visible,
[data-part='thumb']:active {
  outline: none;
  transform: 100ms;
  box-shadow: inset 0px 3px 6px #0000000B, 0px 3px 6px #00000029;
}

[data-part='range'] {
  background: v-bind("backgroundColor");
  height: 1.25rem;
  border-radius: 1rem;
  transition: 50ms;
  z-index: 2;

}

[data-part='track'] {
  background: v-bind("trackColor");
  height: 1.25rem;
  flex: 1;
  border-radius: 1rem;
  cursor: pointer;
}

[data-part='control'] {
  position: relative;
  align-items: center;
  display: flex;

}

.custom-marker[data-part='marker-group'] {
  margin-top: -0.9rem;

  z-index: 2;

  [data-part=marker] {
    width: 2px;
    display: flex;
    justify-content: center;
    height: 1rem;
    margin-top: 0.25rem;
  }

}

.label-marker[data-part='marker-group'] {
  margin-top: 1.75rem;
  height: 1.5rem;
}

[data-part='marker-group'] span {
  display: block;
}

[data-part='marker'] {
  color: $gray-dark;
  font-size: 0.75rem;
}

.custom-marker[data-part=marker-group] [data-part=marker][data-score-marker=true] {
  background: v-bind("markerColor");
  margin-top: -0.55rem;
  height: 1.7rem;
  width: 0.25rem;
}

.score-marker-legend {
  display: flex;
  gap: 1rem;
  padding-block: 1rem 0.5rem;
}
.title-marker {
  width: 0.25rem;
  height: .875rem;
  background: $gray-dark;
  display: inline-block;
  margin-right: 0.5rem;

  &-score {
    background: $orange-medium;
  }
}

[data-part=track][data-disabled="true"],
[data-part=range][data-disabled="true"],
[data-part=thumb][data-disabled="true"] {
  cursor: not-allowed;
}
.control-value {
  background-color: v-bind("constructionValueColor");
  position: absolute;
  height: 1.25rem;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  z-index: 1;
}

.control-value.complete {
  border-radius: 10px;
}
</style>
