import { z } from "zod";
import { BasePersonInActivitySchema, PersonInActivitySchema } from "@/types/activity";
import { EntitySchema } from "@/types/entity";

const ScreeningAnnotationsSchema = z.object({
  header: z.string().nullable().default(""),
  current_moment: z.string().nullable().default(""),
  activities_scope: z.string().nullable().default(""),
  background: z.string().nullable().default(""),
  experience: z.string().nullable().default(""),
});

const ScreeningAnnotationsPatchSchema = ScreeningAnnotationsSchema.partial();

export type ScreeningAnnotationsPatch = z.infer<typeof ScreeningAnnotationsPatchSchema>;

const ScreeningActivityDescriptionEnum = z.enum(["SCREENING_MEETING"]);

const BaseScreeningsActivitySchema = z.object({
  project_id: z.string().optional(),
  description: ScreeningActivityDescriptionEnum,
  recruiter: BasePersonInActivitySchema.optional(),
  candidate: BasePersonInActivitySchema.optional(),
});

export type BaseScreeningsActivity = z.infer<typeof BaseScreeningsActivitySchema>;

const ScreeningsActivitySchema = BaseScreeningsActivitySchema.merge(
  z.object({
    candidate: PersonInActivitySchema.optional(),
    annotations: ScreeningAnnotationsSchema.optional(),
  }),
).strict();

export type ScreeningsActivity = z.infer<typeof ScreeningsActivitySchema>;

const ActivityStripSchema = ScreeningsActivitySchema.strip();

export const ScreeningsActivityDtoSchema = z.intersection(EntitySchema, ActivityStripSchema);

export type ScreeningsActivityDto = z.infer<typeof ScreeningsActivityDtoSchema>;
