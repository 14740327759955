<script setup lang="ts">
import UserEvaluation from "@/common/components/Export/UserEvaluation.vue";
import { CustomCheckBox as Checkbox } from "@/components/checkbox";
import { ShortlistEnum, ShortlistPersonEnum } from "@/schemas/project/pdf";

defineEmits(["cancel"]);
const { t } = useI18n();
const route = useRoute();

const {
  data,
  executeShortlist: exportShortlistImua,
} = useExport(String(route.params.id), "shortlist");

const searchFieldPageLength = ref(1);
const listShowPages = ref<string[]>([]);
const listHiddePages = ref<string[]>([]);

function downloadPDF() {
  exportShortlistImua(listHiddePages.value, "shortlist");
}

function togglePage(page: any) {
  if (listHiddePages.value.includes(page))
    listHiddePages.value.splice(listHiddePages.value.indexOf(page), 1);
  else
    listHiddePages.value.push(page);
}

function KeepPage({ label }: { label: string }) {
  return h("div", { class: "box-checkbox" }, [
    h(Checkbox, {
      variation: "primary",
      class: "keep-page",
      label: t(`projects.shortlist.pdf.${label}`),
      checked: !listHiddePages.value.includes(label),
      onOnCheck: _ => togglePage(label as Partial<ShortlistEnum>),
    }),
  ]);
}

function KeepPersonPage({ label, index, name }: { label: string; index: number; name: string }) {
  return h("div", { class: "box-checkbox" }, [
    h(Checkbox, {
      variation: "primary",
      class: "keep-page",
      label: t(`projects.shortlist.pdf.${label}`, { user: name }),
      checked: !listHiddePages.value.includes(`${label}${index}`),
      onOnCheck: _ => togglePage(`${label}${index}`),
    }),
  ]);
}

const candidateInterviews = computed(() => {
  return data.value?.candidates?.reduce((acc, candidate) => {
    const cand_interview = (data.value?.interviews || []).find(
      interview => interview.person_id === candidate.person_id,
    );
    if (cand_interview)
      acc[candidate.person_id] = cand_interview;

    return acc;
  }, {});
});

const positionName = computed(() => {
  return data.value?.project?.confidential_placement
    ? t("exportPDF.confidentialProject")
    : data.value?.project?.positionName;
});

function sortCandidates(candidates: any) {
  if (!candidates)
    return;
  return [...candidates]
    .sort((a, b) => (b.calculated_scores?.final_mean || 0) - (a.calculated_scores?.final_mean || 0));
}

function formatPersonName(person: any): string {
  return person?.mini_cv?.nickname ?? `${person?.person_data?.first_name} ${person?.person_data?.last_name}`;
}

function formatCompanyName(person: any): string {
  return person?.mini_cv?.current_company_nickname ?? person.person_data?.work_experience[0]?.corporation_name;
}

function formatRoleName(person: any): string {
  return person?.mini_cv?.current_role_nickname ?? person.person_data?.work_experience[0]?.role_name;
}
</script>

<template>
  <div>
    <div class="flex" :style="{ 'padding-bottom': '2rem' }">
      <div>
        <div class="headline uppercase bold">
          {{ t("exportPDF.shortlistTitle") }}
        </div>
        <div class="headline-secondary">
          {{ t("exportPDF.previewSubtitle", { complement: t("exportPDF.inactive") }) }}
        </div>
      </div>
      <div class="actions">
        <Button id="downloadPdfImua" variation="secondary" @click="downloadPDF()">
          {{ t("exportPDF.exportDocument") }}
        </Button>
      </div>
    </div>
    <KeepPage :label="ShortlistEnum.FRONT_COVER" />
    <ExportFrontCover
      :id="ShortlistEnum.FRONT_COVER"
      class="pages"
      :title="t('exportPDF.shortlist')"
      :company-name="data?.project?.companyName"
      :position-name="positionName"
      :is-export="false"
    />

    <KeepPage :label="ShortlistEnum.SUMMARY" />
    <ExportExportShortlistSummary
      :id="ShortlistEnum.SUMMARY"
      class="pages"
      :is-export="false"
      :company-name="data?.project?.companyName || ''"
      :list-hidde-pages="listHiddePages"
    />

    <KeepPage :label="ShortlistEnum.COMPANY" />
    <ExportCompanyAbout
      :id="ShortlistEnum.COMPANY"
      class="pages"
      :is-export="false"
      :description="data?.project?.company_description"
      :foundation-year="data?.project?.company_foundation_year"
      :historic="data?.project?.company_historic"
    />

    <KeepPage :label="ShortlistEnum.IDEAL_PROFILE_TAGS" />
    <ExportExportIdealProfile
      :id="ShortlistEnum.IDEAL_PROFILE_TAGS"
      :profile="data?.ideal_profile"
    />

    <KeepPage :label="ShortlistEnum.IDEAL_PROFILE" />
    <ExportIdealProfileList
      :id="ShortlistEnum.IDEAL_PROFILE"
      class="pages"
      :ideal-profile="data?.ideal_profile"
    />

    <KeepPage :label="ShortlistEnum.ATTRIBUTES" />
    <ExportExportAttributes
      :id="ShortlistEnum.ATTRIBUTES"
      class="pages"
      :attributes="data?.ideal_profile?.attributes"
    />

    <KeepPage :label="ShortlistEnum.CULTURAL_MATCH" />
    <ExportExportCulturalMatch
      :id="ShortlistEnum.CULTURAL_MATCH"
      class="pages"
      :cultural-match="data?.ideal_profile?.cultural_match"
    />

    <KeepPage :label="ShortlistEnum.MOTIVATION" />
    <ExportExportMotivation
      :id="ShortlistEnum.MOTIVATION"
      class="pages"
      :initial-value="data?.ideal_profile?.consultant_opinion"
      :ideal-profile="data?.ideal_profile"
    />

    <KeepPage :label="ShortlistEnum.SEARCH_FIELD" />
    <ExportSearchField
      :id="ShortlistEnum.SEARCH_FIELD"
      :initial-value="data?.business_functions"
      :is-export="false"
    />

    <KeepPage :label="ShortlistEnum.PROCESS_FUNNEL" />
    <ExportFunnel
      v-if="data?.project?.counters"
      :id="ShortlistEnum.PROCESS_FUNNEL"
      :funnel-default="data.project.counters"
      :process-funnel="data.project.process_funnel"
    />

    <KeepPage :label="ShortlistEnum.ADHERENCE_TABLE" />
    <ExportAdherenceTable
      :id="ShortlistEnum.ADHERENCE_TABLE"
      :candidates="data?.candidates"
      :interviews="data?.interviews"
    />

    <!-- for every person in shortlist -->
    <div :id="ShortlistPersonEnum.USERS" />
    <div v-for="(candidate, index) in sortCandidates(data?.candidates)" :key="candidate.person_id">
      <KeepPersonPage :label="ShortlistPersonEnum.USER_CARD_PERSON" :index="index" :name="candidate.person_data.first_name" />
      <ExportUserCardPerson
        :id="`${ShortlistPersonEnum.USER_CARD_PERSON}${index}`"
        :person-name="formatPersonName(candidate)"
        :company-name="formatCompanyName(candidate)"
        :position-name="formatRoleName(candidate)"
      />

      <KeepPersonPage :label="ShortlistPersonEnum.USER_EVALUATION" :index="index" :name="candidate.person_data.first_name" />
      <UserEvaluation
        :person-name="formatPersonName(candidate)"
        :opinion="candidate.mini_cv?.recruiter_opinion"
        :scores="candidate.calculated_scores"
      />

      <KeepPersonPage :label="ShortlistPersonEnum.USER_ABOUT" :index="index" :name="candidate.person_data.first_name" />
      <ExportUserAbout
        class="pages"
        :about="candidate.person_data.work_experience"
        :person-name="formatPersonName(candidate)"
        :hide-experiences="candidate.mini_cv?.hide_experiences_ids"
      />

      <KeepPersonPage :label="ShortlistPersonEnum.USER_ACADEMIC" :index="index" :name="candidate.person_data.first_name" />
      <ExportUserAcademic
        :person-first-name="candidate.person_data.first_name"
        :person-name="formatPersonName(candidate)"
        :ideal-profile-academic="data?.ideal_profile?.academic_backgrounds"
        :ideal-profile-language="data?.ideal_profile?.languages"
        :interview-academic="candidateInterviews[candidate.person_id]?.academic_backgrounds"
        :interview-language="candidateInterviews[candidate.person_id]?.languages"
        :academic-score="candidateInterviews[candidate.person_id]?.calculated_scores?.academic_formation_mean"
      />

      <KeepPersonPage :label="ShortlistPersonEnum.USER_EXPERIENCE" :index="index" :name="candidate.person_data.first_name" />
      <ExportUserExperiences
        :person-first-name="candidate.person_data.first_name"
        :person-name="formatPersonName(candidate)"
        :ideal-profile-experiences="data?.ideal_profile?.experiences"
        :interview-experiences="candidateInterviews[candidate.person_id]?.experiences"
        :experience-score="candidateInterviews[candidate.person_id]?.calculated_scores?.experiences_mean"
      />

      <KeepPersonPage :label="ShortlistPersonEnum.USER_REALIZATIONS" :index="index" :name="candidate.person_data.first_name" />
      <ExportUserRealizations
        :person-first-name="candidate.person_data.first_name"
        :person-name="formatPersonName(candidate)"
        :ideal-profile-achievements="data?.ideal_profile?.achievements"
        :interview-achievements="candidateInterviews[candidate.person_id]?.achievements"
        :achievement-score="candidateInterviews[candidate.person_id]?.calculated_scores?.achievements_mean"
      />

      <KeepPersonPage :label="ShortlistPersonEnum.USER_ATTRIBUTES" :index="index" :name="candidate.person_data.first_name" />
      <ExportExportAttributes
        :id="ShortlistEnum.ATTRIBUTES"
        class="pages"
        :score="candidateInterviews[candidate.person_id]?.calculated_scores?.attributes_mean"
        :person-name="formatPersonName(candidate)"
        :rows="data?.ideal_profile?.attributes"
        :attributes="candidateInterviews[candidate.person_id]?.attributes"
        :show-legend="true"
      />

      <KeepPersonPage :label="ShortlistPersonEnum.USER_CULTURAL_MATCH" :index="index" :name="candidate.person_data.first_name" />
      <ExportExportCulturalMatch
        :id="ShortlistEnum.CULTURAL_MATCH"
        class="pages"
        :score="candidateInterviews[candidate.person_id]?.calculated_scores?.cultural_match_mean"
        :person-name="formatPersonName(candidate)"
        :rows="data?.ideal_profile?.cultural_match"
        :cultural-match="candidateInterviews[candidate.person_id]?.cultural_match"
        :show-legend="true"
      />

      <KeepPersonPage :label="ShortlistPersonEnum.USER_MOTIVATION" :index="index" :name="candidate.person_data.first_name" />
      <ExportUserMotivation
        :person-name="formatPersonName(candidate)"
        :score="candidateInterviews[candidate.person_id]?.calculated_scores?.motivation_mean"
        :interview="candidateInterviews[candidate.person_id]"
        :ideal-profile="data?.ideal_profile"
      />

      <KeepPersonPage :label="ShortlistPersonEnum.USER_EVALUATION_FINAL" :index="index" :name="candidate.person_data.first_name" />
      <UserEvaluation
        :person-name="formatPersonName(candidate)"
        :opinion="candidate.mini_cv?.recruiter_opinion"
        :scores="candidate.calculated_scores"
      />
    </div>

    <KeepPage :label="ShortlistEnum.ADHERENCE_TABLE_FINAL" />
    <ExportAdherenceTable :candidates="data?.candidates" :interviews="data?.interviews" />

    <KeepPage :label="ShortlistEnum.BACK_COVER" />
    <ExportBackCover />

    <div class="actions-end">
      <Button variation="secondary" @click="downloadPDF()">
        {{ t("exportPDF.exportDocument") }}
      </Button>
      <Button variation="light" filled @click="$emit('cancel')">
        {{ t("text.form.cancel") }}
      </Button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
:global(:root) {
  --pdf-width: 297mm;
  --pdf-height: 209.5mm;
}

  .pages {
    display: flex;
    width: var(--pdf-width);
    flex-direction: column;

    &-show {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: flex-end;
    }
  }

.box-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 2rem;

  &-title {
    font-size: 1.75rem;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1.5;
    text-transform: uppercase;
  }

  &-subtitle {
    padding-top: 0.5rem;
    font-size: 20px;
  }

  &-actions {
    display: flex;
    height: fit-content;
    flex-wrap: wrap;
    gap: 3.06rem;
  }
}

.flex {
  display: flex;
  justify-content: space-between;
}

.headline {
  font-size: 1.75rem;
  letter-spacing: 0.8px;
  line-height: 1.5rem;
}

.headline-secondary {
  padding-top: 0.5rem;
  font-size: 20px;
  letter-spacing: 0.5;
}

.uppercase {
  text-transform: uppercase;
}

.bold {
  font-weight: 700;
}

.actions,
.actions-end {
  display: flex;
  height: fit-content;
  flex-wrap: wrap;
  gap: 3.06rem;
}

.actions-end {
  justify-content: flex-end;
  margin-top: 2rem;
}

.box-checkbox {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  padding: 3rem 0 1rem;
}
</style>
