export const date = {
  month: "Month",
  year: "Year",
  weekdays: JSON.stringify(["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]),
  months: JSON.stringify([
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]),
  and: "and",
  dateIn: "",
  dateFormat: "mm/dd/yy",
  daySingular: "day",
  dayPlural: "days",
  monthSingular: "month",
  monthPlural: "months",
  yearSingular: "year",
  yearPlural: "years",
};
