<script setup lang="ts">
withDefaults(defineProps<{
  width?: string
  positionX?: string
  positionY?: string
}>(), {
  width: "max-content",
  positionX: "center",
  positionY: "top",
});

const tooltipShow = ref(false);
</script>

<template>
  <div class="base-tooltip" :class="[positionX, positionY]" @mouseover="tooltipShow = true" @mouseout="tooltipShow = false">
    <slot />
    <div v-if="tooltipShow" class="tooltip">
      <slot name="message" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.base-tooltip.start {
    align-items: flex-start;
}

.base-tooltip.center {
    align-items: center;
}

.base-tooltip.end {
    align-items: flex-end;
}

.base-tooltip.top {

    .tooltip {
        margin-top: -3rem;
    }
}

.base-tooltip.bottom {

    .tooltip {
        margin-top: 3rem;
        box-shadow: 0 -2px 4px #00000048;
    }

    .tooltip::after {
        margin-bottom: auto;
        top: -6px;
        box-shadow: -3px -3px 4px #00000048;
    }
}

.base-tooltip {
    display: flex;
    flex-direction: column;
    background: transparent;

    .tooltip {
        position: absolute;
        width: v-bind(width);
        height: max-content;
        padding-inline: 0.75rem;
        padding-block: 0.4rem;
        background: $white-full;
        border-radius: 0.5rem;
        box-shadow: 0 3px 5px #00000048;
    }

    .tooltip::after {
        content: "";
        position: absolute;
        z-index: 1;
        width: 0;
        height: 0;
        top: auto;
        right: 0;
        transform: rotate(45deg);
        margin-right: calc(50% - 8px);
        margin-bottom: -6px;
        bottom: 0;
        border: 7px solid white;
        box-shadow: 2px 2px 3px #00000048;
    }
}
</style>
