import { defineAsyncComponent } from "vue";

import { Loading } from "@/components/loading";

function createAsyncComponent(loader: any) {
  return defineAsyncComponent({
    loader,
    loadingComponent: Loading,
    delay: 100,
  });
}

export const AboutTab = createAsyncComponent(() => import("./About.vue"));
export const OrganizationChartTab = createAsyncComponent(() => import("./OrganizationChart.vue"));
export const TransformationEventTab = createAsyncComponent(
  () => import("./TransformationEvents.vue"),
);

export const ProjectTab = createAsyncComponent(() => import("./Project.vue"));
