<script setup lang="ts">
import { uniqBy } from "lodash-es";
import debounce from "lodash-es/debounce";
import CandidateCard from "./components/CandidateCard.vue";
import ProjectStages from "@/common/components/ProjectStages.vue";
import { Select } from "@/components/inputs/modalInputs";
import SearchLogo from "@/assets/images/search_icon.svg";
import type { SearchCandidate } from "@/common/composables/useShortlist";
import ScrollToTop from "@/components/scroll_to_top/ScrollToTop.vue";

const props = defineProps<{ id: string }>();
const router = useRouter();
const route = useRoute();
const { t, tm } = useI18n();

const projectId = computed(() => String(route.params.id));
const searchInput = ref("");
const sortInput = ref("");
const listAmount = ref(0);
const loading = ref(false);
const emptyList = ref(false);
const visibleScrollTop = ref(false);

const paginationParams = reactive({
  name: searchInput.value,
  project_id: projectId.value,
  saved_candidates: false,
  project_stage: "SCREENINGS",
  sort: ["screenings_approval_priority"],
  limit: 10,
  skip: 0,
});

const {
  error,
  mutateAsync: useFullCandidates,
  isPending,
} = useScreeningsFullCandidates();

const candidates = ref<SearchCandidate[]>([]);

function resetList() {
  paginationParams.name = "";
  listAmount.value = 0;
  candidates.value = [];
  paginationParams.skip = 0;
}

const {
  mutate: patchApprovalCandidate,
} = usePatchApprovalCandidate();

const {
  tabs,
  goTo,
} = useScreeningTabs(props.id);

const currentRoute = computed(() =>
  tabs.value.findIndex(tab => tab.key === router.currentRoute.value.meta.tab));

const listSort = computed(() => ({
  placeholder: t("projects.mapping.list.sort.placeholder"),
  options: Object(tm("projects.mapping.list.sort.options")),
}));

async function changeRating(data: { candidate_id: string; approval: any }) {
  patchApprovalCandidate({
    person_id: data.candidate_id,
    body: {
      approval: {
        ...data.approval,
      },
    },
  }, {
    onSuccess: () => {
      resetList();
      fetchCandidates();
    },
  });
}

async function goToActivity(person_id: string) {
  const activity = await getScreeningActivities({ project_id: props.id, candidate_person_id: person_id });
  if (activity[0])
    router.push(`/projects/${props.id}/screenings/person/${person_id}/activity/${activity[0]?._id}`);
}

async function onSearch() {
  resetList();
  loading.value = true;
  paginationParams.name = searchInput.value;
  fetchCandidates();
}

async function onSort() {
  console.log("sortInput", sortInput.value);
}

async function nextPage() {
  try {
    loading.value = true;
    paginationParams.skip = candidates.value?.length;
    await fetchCandidates();
  }
  catch (error) {
    loading.value = false;
    console.log("error", error);
  }
}

const bodyHeight = ref(0);
function scrollToTop() {
  const body = document.querySelector("body");
  if (body?.scrollTop >= 100)
    body.scrollTop = bodyHeight.value - 150;
}

async function handleScroll(event: Event) {
  const {
    scrollTop: hiddenHeight,
    scrollHeight: totalHeight,
    clientHeight: totalVisible,
  } = event.target as HTMLElement;
  const bottomReached = hiddenHeight + totalVisible + 20 >= totalHeight;

  (hiddenHeight >= 700) ? (visibleScrollTop.value = true) : (visibleScrollTop.value = false);

  if (hiddenHeight && bottomReached && !loading.value && !emptyList.value) {
    bodyHeight.value = hiddenHeight;
    await nextPage();
  }
}

async function fetchCandidates() {
  await useFullCandidates(paginationParams, {
    onSuccess: (result) => {
      listAmount.value = result.count;
      candidates.value = uniqBy([...candidates.value, ...result.data], e => e._id);
      emptyList.value = result?.data?.length === 0;
      loading.value = false;
      scrollToTop();
    },
    onError: () => {
      loading.value = false;
    },
  });
}

const debounceSearch = debounce(onSearch, 300);

onMounted(() => {
  fetchCandidates();
  const body = document.querySelector("body");
  body?.addEventListener("scroll", (event: Event) => handleScroll(event));
});
</script>

<template>
  <section class="project-activities">
    <div v-if="error">
      could not load
    </div>

    <div data-testid="project-candidates">
      <div class="project-container">
        <ProjectStages :id="props.id" />
        <ProjectTabs :stage-tabs="tabs" :active="currentRoute" class="tabs" @change="goTo($event)" />
        <div class="project-wrapper">
          <div class="project-header">
            <div class="title">
              {{ t("projects.details.activities.mappingCandidates") }}
            </div>
            <div class="actions">
              <div class="form-search">
                <input
                  v-model="searchInput"
                  name="search"
                  :placeholder="t('persons.searchPerson')"
                  autocomplete="off"
                  @input="debounceSearch()"
                >
                <SearchLogo class="search-icon" width="1.25rem" height="1.25rem" />
              </div>
            </div>
          </div>
          <div class="project-body">
            <div class="list-header">
              <div class="list-header-amount">
                {{ listAmount }} {{ t("projects.details.tabs.candidates") }}
              </div>
              <div class="list-header-order">
                <Select
                  v-model="sortInput"
                  no-feedback
                  :placeholder="listSort.placeholder"
                  :options="listSort.options"
                  @update:model-value="onSort()"
                />
              </div>
            </div>
            <div v-if="candidates?.length" class="list-candidates">
              <CandidateCard
                v-for="(person, index) in candidates"
                :key="index"
                :person="person"
                :disapproved="person.approval_and_priority?.approved_for_interview === 'NO'"
                @change:rating="changeRating($event)"
              >
                <template #actions>
                  <Button class="person-data-actions-buttons" variation="secondary" @click="goToActivity(person.person_id)">
                    {{ t("projects.details.activities.screening.goToScreening") }}
                  </Button>
                </template>
              </CandidateCard>
            </div>
            <p v-if="!candidates.length && !isPending">
              {{
                t("projects.details.constructionGuide.ranking.candidateNotFound")
              }}
            </p>
            <div v-if="loading" class="loader-wrapper">
              <div class="loader" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <ScrollToTop v-if="visibleScrollTop" class-name="body" :top="350" />
  </section>
</template>

<route lang="yaml">
meta:
  layout: project-stage
  stage: screenings
  tab: candidates
</route>

<style scoped lang="scss">
.project-activities {
  min-height: 100vh;
}
.project-container {
  padding-top: 3.75rem;
}
.project-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 32rem;
  padding-inline: 4rem;
  padding-block: 2.5rem;
  background: $white-full;

  .project-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-block: 1.3125rem;
    margin-block-end: 1.3125rem;

    .title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: $gray-dark;
      font-size: 1.125rem;
      font-weight: bold;
      text-transform: uppercase;
    }

    .actions {
      display: flex;
      flex-direction: column;

      .form-search {
        display: flex;
        width: 22.5rem;
        flex-direction: row;
        padding-block: 0.7rem;
        padding-inline: 0.875rem;
        border: 1px solid $gray-border;
        border-radius: 0.625rem;

        input {
          flex: 1;
          font-size: 0.875rem;
          outline: none;
          border: none;
        }
        .search-icon {
          color: $primary;
        }
      }
    }

    .button-pluss {
      display: flex;
      flex-direction: row;
      gap: 0.25rem;
    }
  }

  .project-body {
    display: flex;
    flex-direction: column;
    padding-block-end: 1rem;

    .list-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding-block-end: 1.5rem;

      .list-header-amount {
        font-size: 1.125rem;
        font-weight: bold;
      }

      .list-header-order {
        width: 13rem;
        height: 2.5rem;
      }
    }

    .list-candidates {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }
}

.loader-wrapper {
  width: 100%;
  height: 2rem;
  padding-block: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader {
  width: 2rem;
  height: 2rem;
  border: 5px solid;
  border-color: $yellow transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
