<script setup lang="ts">
import { defineEmits, ref, withDefaults } from "vue";
import { useI18n } from "vue-i18n";
import { Button2 as Button } from "@/components/button";
import { TextArea } from "@/components/inputs/modalInputs";

const props = withDefaults(
  defineProps<{
    opinion: string
    rows: number
    warningChars: number
  }>(),
  {
    opinion: "",
    rows: 6,
    warningChars: 650,
  },
);

const emit = defineEmits(["onSave", "onCancel"]);

const { t } = useI18n({ useScope: "global" });

const opinion = ref(props.opinion);
</script>

<template>
  <div class="opinion__content">
    <TextArea
      v-model="opinion"
      :rows="rows"
      :warning-chars="warningChars"
      :warning-message="t('text.textArea.shorlistWarning', { count: 650 })"
    />
    <div class="opinion__action">
      <div class="buttons">
        <Button
          variation="secondary"
          class="opinion__action--save"
          @click="emit('onSave', opinion)"
        >
          {{ t("persons.details.submit") }}
        </Button>
        <Button
          variation="light"
          filled
          class="opinion__action--cancel"
          @click="emit('onCancel')"
        >
          {{ t("persons.details.cancel") }}
        </Button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.opinion {
  &__action {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;

    &--save {
      padding-right: 1rem;
      padding-left: 1rem;
      margin-right: 0.5rem;
      white-space: nowrap;
    }

    &--cancel {
      margin-left: 0.5rem;
      background-color: #c4c4c4;
      color: #666;
      opacity: 0.6;
    }
  }
}
</style>
