<script lang="ts">
import type { Ref } from "vue";
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { get, isNumber } from "lodash";
import CustomCheckBox from "@/components/checkbox/CustomCheckBox.vue";
import { FormInput, Select, TextArea } from "@/components/inputs/modalInputs";
import { months, parseAndGetDates, watchDatesAndSetInObj, years } from "@/utils/dates";
import type { ExtraActivitiesVolunteer } from "@/entities/Person/extra-activities";

export default defineComponent({
  name: "VolunteeringForm",
  components: {
    FormInput,
    Select,
    TextArea,
    CustomCheckBox,
  },
  props: {
    volunteering: Object,
  },
  setup(props) {
    const { t } = useI18n();
    const payload = toRef(props, "volunteering") as Ref<ExtraActivitiesVolunteer>;

    const { startMonth, startYear } = parseAndGetDates<ExtraActivitiesVolunteer>(
      "start_date",
      payload,
    )();
    watch(
      [startMonth, startYear],
      watchDatesAndSetInObj<ExtraActivitiesVolunteer>("start_date", payload),
    );

    const { endMonth, endYear } = parseAndGetDates<ExtraActivitiesVolunteer>("end_date", payload)();
    watch(
      [endMonth, endYear],
      watchDatesAndSetInObj<ExtraActivitiesVolunteer>("end_date", payload),
    );

    watch(
      payload,
      (value) => {
        const { start_date, end_date } = value;

        if (!start_date && !isNumber(startMonth.value) && !isNumber(startYear.value)) {
          startMonth.value = null;
          startYear.value = null;
        }

        if (!end_date && !isNumber(endMonth.value) && !isNumber(endYear.value)) {
          endMonth.value = null;
          endYear.value = null;
        }
      },
      { deep: true },
    );

    const changeCheckboxValue = (event) => {
      payload.value.is_current = event.target.checked;
      if (event.target.checked) {
        endMonth.value = null;
        endYear.value = null;
      }
    };

    const rules = computed(() => ({
      payload: {},
    }));

    const v$ = useVuelidate(rules, {
      payload,
    });

    return {
      t,
      payload,
      startMonth,
      startYear,
      endMonth,
      endYear,
      months,
      years,
      changeCheckboxValue,
      v$,
      get,
    };
  },
});
</script>

<template>
  <div class="extra-activities project">
    <div class="title-wrapper">
      <span>{{ t("persons.volunteer") }}</span>
      <slot name="icon" />
    </div>

    <form action="" class="project-data-form">
      <div class="form-group title">
        <label>
          {{ t("persons.volunteerForm.title") }}:
        </label>
        <FormInput
          v-model="payload.title"
          :placeholder="t('persons.projectForm.placeholders.title')"
          :error="get(v$, 'payload.title.$error', false)"
          :error-message="get(v$, 'payload.title.$errors.0.$message', '')"
        />
      </div>

      <div class="form-group startDate">
        <label>
          {{ t("persons.volunteerForm.startDate") }}:
        </label>
        <div class="select-group">
          <Select
            v-model="startMonth"
            class="select-list"
            :options="months"
            :placeholder="t('persons.volunteerForm.placeholders.month')"
            :error="!!get(v$, 'startMonth.$error', false)"
            :error-message="get(v$, 'startMonth.$errors.0.$message', '')"
          />
          <Select
            v-model="startYear"
            class="select-list"
            :options="years"
            :placeholder="t('persons.volunteerForm.placeholders.year')"
            :error="!!get(v$, 'startYear.$error', false)"
            :error-message="get(v$, 'startYear.$errors.0.$message', '')"
          />
        </div>
      </div>

      <div class="form-group current">
        <CustomCheckBox
          :checked="payload.is_current"
          :label="t('persons.volunteerForm.current')"
          :on-change="changeCheckboxValue"
        />
      </div>

      <div class="form-group endDate">
        <label>
          {{ t("persons.volunteerForm.endDate") }}:
        </label>
        <div class="select-group">
          <Select
            v-model="endMonth"
            class="select-list"
            :options="months"
            :disabled="payload.is_current"
            :placeholder="t('persons.volunteerForm.placeholders.month')"
            :error="!!get(v$, 'endMonth.$error', false)"
            :error-message="get(v$, 'endMonth.$errors.0.$message', '')"
          />
          <Select
            v-model="endYear"
            class="select-list"
            :options="years"
            :disabled="payload.is_current"
            :placeholder="t('persons.volunteerForm.placeholders.year')"
            :error="!!get(v$, 'endYear.$error', false)"
            :error-message="get(v$, 'endYear.$errors.0.$message', '')"
          />
        </div>
      </div>

      <div class="form-group textarea-wrapper">
        <TextArea
          v-model="payload.description"
          rows="4"
          :placeholder="t('persons.volunteerForm.description')"
        />
      </div>
    </form>
  </div>
</template>

<style lang="scss" scoped>
.project-data-form:deep {
  .form-group {
    display: flex;
    margin-bottom: 0.75rem;

    label {
      position: relative;
      bottom: 5px;
      display: flex;
      width: 20%;
      min-width: 114px;
      height: 28px;
      align-items: flex-end;
      color: $gray-dark;
      font-family: "Open Sans";
      font-size: 14px;
      letter-spacing: 0.5px;
      white-space: pre;

      .required {
        color: $red;
      }
    }

    .component-input {
      width: 100%;
      flex-grow: 1;

      .input-wrapper {
        input {
          font-family: "Open Sans";

          &::placeholder {
            font-size: 13px;
          }
        }
      }
    }
  }

  .textarea-abstract {
    width: 100%;
    padding: 1rem;
    border: 1px solid $gray-border;
    border-radius: 6px;
    color: gray;
    font-family: "Open Sans";
    outline: none;
    resize: none;

    &::placeholder {
      color: $gray-medium;
    }
  }

  .select-group {
    display: flex;
    width: 100%;

    .select-list {
      width: 100%;

      .multiselect {
        min-height: 30px;

        .multiselect-dropdown {
          overflow-y: scroll;
        }
      }
    }

    .select-list:nth-of-type(2) {
      margin-left: 2rem;
    }
  }
}

.title-wrapper {
  display: flex;
  height: 35px;
  justify-content: space-between;

  span {
    color: $primary;
    font-family: "Open Sans";
    font-size: 1rem;
  }
}
</style>
