<script setup lang="ts">
import { Sidebar } from "@/components/sidebar";
import { SvgIcon } from "@/components/icon";

const route = useRoute();
const showOptions = ref(false);
const showPreview = ref(false);
const { t } = useI18n();

const {
  executeBullhornShortlist,
} = useExport(String(route.params.id), "shortlist");

async function downloadBullhornExport() {
  showOptions.value = false;
  await executeBullhornShortlist();
}
function exportButton() {
  return h("div", {
    class: "tab export",
    role: "button",
    onClick: () => { showOptions.value = !showOptions.value; },
  }, [
    h(SvgIcon, { icon: "project_export", width: "1rem", height: "1rem", margin: "none" }),
    h("div", { class: ["tab-tooltip", { hide: showOptions }] }, t("projects.details.constructionGuide.idealProfile.tooltip.export")),
    showOptions.value
      ? h("div", { class: "options-dropdown" }, [
        h("div", {
          role: "button",
          class: "item",
          onClick: () => { showPreview.value = true; },
        }, t("projects.details.constructionGuide.idealProfile.tooltip.exportVersionImuaBeta")),
        h("div", {
          role: "button",
          class: "item",
          onClick: downloadBullhornExport,
        }, t("projects.details.constructionGuide.idealProfile.tooltip.exportVersionBullhorn")),
      ])
      : null,
  ]);
}
</script>

<template>
  <exportButton />
  <Sidebar class="sidebar-preview" :visible="showPreview === true" :on-close="() => (showPreview = false)">
    <ExportExportShortlist @cancel="showPreview = false" />
  </Sidebar>
</template>

<style scoped lang="scss">
.overlay.sidebar-preview {
  background-color: #242424;
  > :first-child {
    width: 1211px;
  }
}
.tab {
  position: relative;
  display: flex;
  height: 2.5rem;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  box-shadow: 0 2px 4px #0d0c0c29;
  color: #ccc;
  font-size: 1rem;
  border: 0;
  background: #fff;
  margin-inline-start: auto;
  margin-block: 1.625rem 1.5rem;

  &:hover {
    border: 2px solid $gray-shade-dark;
  }

  &.export {
    width: 2.5rem;
    border: 1px solid #c0c0c0;
    color: #9b9b9b;
    grid-column-start: -1;

    &:hover {
      border: 1px solid transparent;
      background-color: #f7f7f7;
      box-shadow: none;
      color: $primary;
    }
  }

  &:hover,
  &.checked {
    background: $yellow;
    color: white;
  }

  &:hover {
    .tab-tooltip {
      visibility: visible;
    }
  }

  .tab-tooltip {
    position: absolute;
    bottom: 0;
    padding: 0.25rem;
    border-radius: 0.25rem;
    margin-bottom: -1.5rem;
    box-shadow: 0 2px 4px #0d0c0c29;
    color: $gray-dark;
    font-size: 0.75rem;
    visibility: hidden;
    white-space: nowrap;

    &.hide {
      visibility: hidden;
    }
  }
}

.options-dropdown {
  position: absolute;
  z-index: 99;
  top: 112%;
  right: 0%;
  display: flex;
  flex-direction: column;
  padding: 0.25rem 0;
  background: #fff;
  border-radius: 6px;
  box-shadow: $box-shadow-card;

  .item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0.875rem 0.5rem;
    color: $gray-medium-dark;
    cursor: pointer;
    white-space: nowrap;

    &:hover {
      color: $red-violet;
    }
  }
}
</style>
