<script lang="ts">
import type { PropType } from "vue";
import { computed, defineComponent, inject } from "vue";
import { useI18n } from "vue-i18n";
import CorporationCard from "../CorporationCard/CorporationCard.vue";
import type { Corporation } from "@/entities/corporation";
import { Loading } from "@/components/loading";
import { ListType } from "@/components/list_type";
import { Select } from "@/components/inputs/modalInputs";

export default defineComponent({
  name: "CorporationList",
  components: {
    ListType,
    Select,
    CorporationCard,
    Loading,
  },
  props: {
    corporations: {
      type: Array as PropType<Corporation[]>,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isSearching: Boolean,
    searchTotal: Number,
  },
  emits: ["sort"],
  setup() {
    const { t } = useI18n();

    const onClickDetails = inject("onClickDetails");
    const onClickEdit = inject("onClickEdit");
    const onClickRefresh = inject("onClickRefresh");
    const sortOptions = computed(() => [
      { label: t("corporations.relevants"), value: "relevant" },
      { label: t("corporations.recents"), value: "updated_at" },
    ]);
    return {
      t,
      sortOptions,
      onClickDetails,
      onClickEdit,
      onClickRefresh,
    };
  },
});
</script>

<template>
  <section class="list-persons">
    <header class="header">
      <div class="left-items" />

      <div class="right-items">
        <ListType selected-type="card" />

        <Select
          no-feedback
          :width="150"
          :searchable="false"
          :placeholder="t('corporations.sortBy')"
          :options="sortOptions"
          @change:value="$emit('sort', $event)"
        />
      </div>
    </header>

    <div class="list">
      <p v-if="!loading && isSearching && searchTotal" class="search-count">
        {{ t("text.searchText", searchTotal) }}
      </p>
      <CorporationCard
        v-for="corporation in corporations"
        :key="corporation.id"
        :corporation="corporation"
        data-test="corporation-card"
        @click:details="onClickDetails(corporation)"
        @click:edit="onClickEdit(corporation)"
        @click:refresh="onClickRefresh(corporation)"
      />

      <transition name="fade">
        <div class="loading">
          <Loading v-show="loading" :spin-time="2" />
        </div>
      </transition>

      <slot name="tail" />
    </div>
  </section>
</template>

<style scoped="true" lang="scss">
.header {
  display: flex;
  padding-right: 0.5rem;
  padding-left: 1.2rem;
  margin-bottom: 2rem;

  .left-items,
  .right-items {
    display: flex;
    flex: 1;
  }

  .left-items {
    justify-content: left;

    > div {
      margin-right: 1rem;
    }
  }

  .right-items {
    justify-content: right;
  }

  .component-select:deep {
    min-height: auto;

    .error-wrapper {
      height: 0;
    }
  }
}

.list {
  position: relative;

  .search-count {
    margin-bottom: 1.5rem;
    margin-left: 0.5rem;
    font-size: 1.563rem;
    font-weight: bolder;
  }
}

.loading {
  z-index: 9;
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: #fff;
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity ease-in-out 2ms;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
