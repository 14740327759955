<script lang="ts">
import { defineComponent } from "vue";
import { Card } from "@/components/card";

export default defineComponent({
  name: "ImuaDropdown",
  components: {
    Card,
  },
  inheritAttrs: false,
  props: {
    align: {
      type: String,
      default: "left",
      validator: (value: string): boolean => ["left", "right", "center"].includes(value),
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<template>
  <Card
    :id="$attrs.id"
    class="imua-dropdown"
    :class="[
      `imua-dropdown--${align}`,
      { ['imua-dropdown--active']: active },
    ]"
    tabindex="0"
    @blur="$attrs.onBlur"
  >
    <slot />
  </Card>
</template>

<style lang="scss" scoped>
.imua-dropdown {
  position: absolute;
  z-index: -1;
  bottom: 0;
  width: var(--dropdown-width, 100%);
  max-height: 150px;
  opacity: 0;
  overflow-y: auto;
  transform: translateY(100%) scaleY(0);
  transition: opacity 50ms ease-out;

  &--active {
    z-index: 100;
    opacity: 1;
    transform: translateY(100%) scaleY(1);
    outline: 1px solid #cecece;
  }

  &--left { left: 0; }
  &--right { right: 0; }

  &--center {
    left: 50%;
    transform: translate(-50%, 100%);
  }
}
</style>
