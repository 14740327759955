<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";

type tag = "div" | "section" | "main" | "article" | "aside";

export default defineComponent({
  name: "SectionContent",
  props: {
    tag: {
      type: String as PropType<tag>,
      default: "div",
    },
  },
});
</script>

<template>
  <div class="section-content">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.section-content {
  color: $gray-dark;
  font-size: 1rem;
  line-height: 1.25rem;
}
</style>
