<script setup lang="ts">
import { mdiPlusCircleOutline } from "@mdi/js";
import { projectFetchMethodsQuery, provideCardClickEvents } from "./services";
import List from "./components/List.vue";
import ProjectFilters from "@/common/components/ProjectFilters.vue";
import ScrollToTop from "@/components/scroll_to_top/ScrollToTop.vue";
import ContentHeader from "@/components/content_header/ContentHeader.vue";
import PageWrapper from "@/components/page_wrapper/PageWrapper.vue";
import { SvgIcon } from "@/components/icon";

const {
  fetchOnFilter,
  fetchOnKeyPress,
  projects,
  listProjects,
  fetchOnSort,
  searchTotal,
  projectsLength,
  isSearching,
  searchFinish,
  loadError,
  loading,
  isWait,
  emptySearch,
  filters,
} = projectFetchMethodsQuery();
const allProjects = ref();
const router = useRouter();

const { t } = useI18n();
const projectsHome = ref(null);
const visibleScrollTop = ref(false);

provideCardClickEvents(router);
const goToCreate = () => router.push("/projects/create");

// TODO: remove this
function checkEnvShowButton() {
  return import.meta.env.MODE === "production"
    ? []
    : [
        {
          type: "primary",
          text: t("projects.create.title"),
          onClick: goToCreate,
          buttonIcon: mdiPlusCircleOutline,
        },
      ];
}

watchEffect(() => {
  allProjects.value = projectsLength.value;
});

async function handleScroll(event: Event) {
  const {
    scrollTop: hiddenHeight,
    scrollHeight: totalHeight,
    clientHeight: totalVisible,
  } = event.target as HTMLElement;
  const bottomReached = hiddenHeight + totalVisible + 10 >= totalHeight;
  const filtersWrapper = document.querySelector(".filters-wrapper");

  if (filtersWrapper) {
    const { offsetHeight } = filtersWrapper as HTMLElement;

    offsetHeight >= hiddenHeight
      ? (visibleScrollTop.value = false)
      : (visibleScrollTop.value = true);
  }

  if (hiddenHeight && bottomReached && !searchFinish.value && !isWait.value) {
    isWait.value = true;
    await listProjects();
  }
}

onMounted(async () => {
  await listProjects();
});
</script>

<template>
  <PageWrapper ref="projectsHome" class="projects-home" @scroll="handleScroll($event)">
    <template #header>
      <ContentHeader
        :title="t('header.projects')"
        :buttons="checkEnvShowButton()"
        :search-total="searchTotal"
        :all-projets="allProjects"
      >
        <template #icon>
          <SvgIcon icon="briefcase_icon" width="28" height="28" />
        </template>
      </ContentHeader>
    </template>

    <template #content>
      <ProjectFilters @search:text="fetchOnKeyPress" @filter="fetchOnFilter($event)" />
      <List
        data-test="list-component"
        :projects="projects"
        :loading="loading"
        :is-searching="isSearching"
        :search-total="searchTotal"
        @sort="fetchOnSort"
      >
        <template #tail>
          <div v-if="loadError" class="messsage">
            <span>{{ t("projects.filters.errorListProject") }}</span>
          </div>

          <div v-if="emptySearch && Object.values(filters).length > 0" class="messsage">
            <span>{{ t("projects.filters.notFoundSearch") }}</span>
          </div>
        </template>
      </List>
      <ScrollToTop v-if="visibleScrollTop" />
    </template>
  </PageWrapper>
</template>

<route lang="yaml">
meta:
  layout: listpage
</route>

<style scoped lang="scss">
.projects-home {
  display: flex;
  justify-content: center;
  padding-block: 2rem;
  width: 100vw;
  padding-inline: auto;
}
</style>
