import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { removeSearch, removeSearchField } from "@/common/services/searchField";

// export function useCreateBusinessFunctionQuery(project_id: string) {
//   const queryClient = useQueryClient();
//   return useMutation({
//     mutationKey: ["projects", project_id, "business_functions"],
//     mutationFn: (business_function: any) => addBusinessFunction(business_function),
//     onSuccess: () => {
//       queryClient.invalidateQueries({ queryKey: ["projects", project_id, "business_functions"] });
//     },
//   });
// }

export function useSearchField(id: string) {
  return useQuery({
    queryKey: ["search-field", id],
    queryFn: () => getSearchField(id),
  });
}

// export function useRemoveBusinessFunctionQuery(project_id: string) {
//   const queryClient = useQueryClient();
//   return useMutation({
//     mutationKey: ["projects", project_id, "business_functions"],
//     mutationFn: (business_function: any) => removeBusinessFunction(business_function),
//     onSuccess: () => {
//       queryClient.invalidateQueries({ queryKey: ["projects", project_id, "business_functions"] });
//     },
//   });
// }

export function useSearchFieldMutation(project_id: string) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["search-field", project_id],
    mutationFn: (term: string) => addSearchField(project_id, term),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["search-field", project_id] });
      queryClient.invalidateQueries({ queryKey: ["projects", project_id, "business_functions"] });
    },
  });
}

export function removeSearchFieldMutation(project_id: string) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["search-field", project_id],
    mutationFn: (term: string) => removeSearchField(project_id, term),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["search-field", project_id] });
      queryClient.invalidateQueries({ queryKey: ["projects", project_id, "business_functions"] });
    },
  });
}

export function removeSearchMutation(project_id: string) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["search-field", project_id],
    mutationFn: (term: { search: string | null; term: string | null }) => removeSearch(project_id, term),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["search-field", project_id] });
      queryClient.invalidateQueries({ queryKey: ["projects", project_id, "business_functions"] });
    },
  });
}
