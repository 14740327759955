import type {
  ComputedRef,
  Ref,
} from "vue";
import type { Router } from "vue-router";
import debounce from "lodash/debounce";
import omit from "lodash-es/omit";
import some from "lodash-es/some";
import uniqBy from "lodash-es/uniqBy";
import sortBy from "lodash-es/sortBy";
import { useScroll } from "@vueuse/core";
import moment from "moment";
import type { Project } from "@/entities/project";
import type { WorkExperience } from "@/schemas/person";
import type { PaginationParams } from "@/http_services/types";

type AsyncFunction<O> = () => Promise<O>;

interface requestMethodReturn {
  loading: Ref<boolean>
  projects: Ref<Project[]>
  termToSearch: Ref<undefined | string>
  loadError: Ref<boolean>
  paginationParams: Ref<PaginationParams>
  emptySearch: ComputedRef<boolean>
  fetchProjects: AsyncFunction<void>
  listProjects: AsyncFunction<void>
  fetchOnSort: (value: string | Array<string>) => void
  clearParams: () => void
  fetchOnScroll: (elementRef: Ref<HTMLElement | null>) => void
  fetchOnKeyPress: (searchTerm: string) => void
  fetchOnFilter: ([filterType, values]: [string, string[]]) => void
  formatExperiences: () => WorkExperience | null
  filters: Ref<{ [key: string]: any }>
  searchTotal: Ref<number | null>
  projectsLength: Ref<number>
  isSearching: Ref<boolean>
  isWait: Ref<boolean>
  searchFinish: Ref<boolean | false>
}

export function provideCardClickEvents(router: Router): void {
  const onClickDetails = (project: Project) => {
    router.push({ name: "ProjectDetailsHome", params: { id: project.id } });
  };
  const onClickMeeting = (project: Project) => {
    router.push({ name: "MeetingPanel", params: { id: project.id } });
  };
  const onClickEdit = (project: Project) => project;
  provide("onClickDetails", onClickDetails);
  provide("MeetingPanel", onClickMeeting);
  provide("onClickEdit", onClickEdit);
}

export function projectFetchMethodsQuery(): requestMethodReturn {
  const loading = ref<boolean>(false);
  const projects = shallowRef<Project[]>([]);
  const termToSearch = ref<undefined | string>(undefined);
  const loadError = ref<boolean>(false);
  const emptySearch = computed(() => !projects.value.length && !loading.value);
  const limitPagination = ref(10);
  const page = ref(0);
  const paginationParams = ref({
    limit: limitPagination.value,
    skip: projects.value.length,
    sort: ["updated_at:desc"],
    search_ground: termToSearch.value,
  });
  const filters = ref<any>({});
  const searchTotal = ref(0);
  const projectsLength = ref(0);
  const searchFinish = ref(false);
  const isWait = ref(false);

  const fetchProjects = async () => {
    try {
      loadError.value = false;
      loading.value = true;
      const { total: projects_length } = await searchProjects({ ...paginationParams.value });
      const response = await searchProjects({ ...paginationParams.value, ...filters.value });
      const { data, total } = response;
      searchTotal.value = total;
      projectsLength.value = projects_length;
      projects.value = uniqBy([...projects.value, ...data], e => e._id);
      if (data.length === 0)
        searchFinish.value = true;

      setTimeout(() => {
        if (data.length > 0) {
          page.value += 1;
          searchFinish.value = false;
        }
        isWait.value = false;
      }, 250);
    }
    catch (error) {
      loadError.value = true;
      isWait.value = false;
    }
    finally {
      loading.value = false;
      isWait.value = false;
    }
  };

  const listProjects = async (): Promise<void> => {
    try {
      loadError.value = false;
      loading.value = true;
      // TODO: use query here
      const { data, count: projects_length } = await searchProjects({ ...paginationParams.value, ...filters.value });
      paginationParams.value.skip = paginationParams.value.skip + 10;
      searchTotal.value = projects_length;
      projectsLength.value = projects_length;
      projects.value = uniqBy([...projects.value, ...data], e => e._id);
      if (data.length === 0)
        searchFinish.value = true;

      if (data.length > 0) {
        page.value += 1;
        searchFinish.value = false;
      }
      isWait.value = false;
    }
    catch (error) {
      loadError.value = true;
      isWait.value = false;
    }
    finally {
      loading.value = false;
      isWait.value = false;
    }
  };

  const debounceFetchProjects = debounce(listProjects, 300);

  const fetchOnSort = (value) => {
    projects.value = [];
    paginationParams.value.skip = 0;
    paginationParams.value.limit = 10;
    paginationParams.value.sort = [value];
    listProjects();
  };

  const clearParams = () => {
    paginationParams.value.sort = ["inserted_at"];
    paginationParams.value.limit = 10;
    paginationParams.value.skip = 0;
    paginationParams.value.search_ground = "";
    listProjects();
  };

  const resetPage = () => {
    paginationParams.value.skip = 0;
    projects.value = [];
    page.value = 0;
    searchFinish.value = false;
  };

  const fetchOnScroll = (elementRef: Ref<HTMLElement | null>) => {
    const { arrivedState } = useScroll(elementRef);
    const { bottom } = toRefs(arrivedState);

    watch(bottom, (value) => {
      if (value) {
        isWait.value = true;
        listProjects();
      }
    });
  };

  const fetchOnKeyPress = (searchTerm: string) => {
    resetPage();
    filters.value.search_ground = searchTerm;
    listProjects();
  };

  const fetchOnFilter = ([filterType, values]: [string, string[]]): void => {
    if (filterType === "salary")
      Object.keys(values).forEach(key => (filters.value[`${filterType}_${key}`] = values[key]));
    else
      filters.value[filterType] = Array.isArray(values) ? values.join(";") : values;

    if (filterType === "recruiters")
      Object.keys(values).forEach(() => filters.value[filterType] = values);
    if (!values?.length)
      filters.value = omit(filters.value, [filterType]);

    loading.value = true;
    resetPage();
    debounceFetchProjects();
  };

  const isSearching = computed(() => some(filters.value) || !!termToSearch.value);

  const formatExperiences = (experiences: WorkExperience[]): WorkExperience | null => {
    const sorted = sortBy(experiences, (exp) => {
      const checkDate = !exp.end_date ? moment().format("YYYY-MM-DD") : exp.end_date;
      if (checkDate)
        return -new Date(checkDate);
    });
    return sorted[0] ? sorted[0] : null;
  };

  return {
    loading,
    isWait,
    projects,
    termToSearch,
    emptySearch,
    loadError,
    paginationParams,
    fetchProjects,
    listProjects,
    fetchOnSort,
    clearParams,
    fetchOnScroll,
    fetchOnKeyPress,
    fetchOnFilter,
    formatExperiences,
    filters,
    searchTotal,
    projectsLength,
    searchFinish,
    isSearching,
  };
}
