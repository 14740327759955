export const header = {
  persons: "Pessoas",
  createPerson: "Criar novo perfil",
  editPerson: "Editar perfil com parser",
  companies: "Empresas",
  company: "Empresa",
  positions: "Posições",
  projects: "Projetos",
  project: "Projeto",
};
