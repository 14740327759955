<script setup lang="ts">
import { chunk, concat, map, uniqBy } from "lodash-es";
import type { CURRENCY } from "@/entities/common";
import { ComingSoon } from "@/components/coming-soon";
import { DropdownFilter } from "@/components/dropdown_filter";
import CustomCheckBox from "@/components/checkbox/CustomCheckBox.vue";
import { ChecklistFilter, MoneyFilter } from "@/components/filters";
import { SearchInput } from "@/components/inputs/modalInputs";
import Tag from "@/components/filters/Tag.vue";
import type { Corporation } from "@/schemas/corporation";
import type { IdealProfile, Project } from "@/schemas/project";

interface Option {
  label: string
  value: string
}

interface CheckboxType {
  isChecked?: boolean
  name: string
  id?: string
}

interface CorporationCheckbox extends Corporation {
  isChecked?: boolean
  id?: string
}

interface SearchFieldCorporations {
  avatar_url: string | null
  corporation_id: string
  name: string
}

const props = defineProps<{ project: Project; idealProfile: IdealProfile }>();
const emit = defineEmits(["update:filter", "selected:candidate"]);
const route = useRoute();

const { project, idealProfile } = toRefs(props);

const { t, tm } = useI18n();

// const conclaOptions = Object(tm("concla"));
const degreeOptions = computed(() =>
  Object(tm("projects.details.activities.constructionPanel.forms.enums.academic.degree")));
const institutionOptions = computed(() =>
  Object(tm("projects.details.activities.constructionPanel.forms.enums.academic.institution")));
const extensionOptions = computed(() =>
  Object(tm("projects.details.activities.constructionPanel.forms.enums.academic.extension")));

const { data: searchField, refetch: fetchSearchField } = useBusinessFunctionsQuery(String(route.params.id));

const selectedCandidate = ref(true);

const filters = ref({
  position: true,
  corporations: true,
  functions: true,
  education: true,
  salary: true,
  realizations: false,
  executions: false,
});

const educationCheckBox = reactive({
  booleans: {
    graduation_complete: { label: degreeOptions.value.BACHELOR, value: false },
    education_first_line: { label: institutionOptions.value.PREFERENTIALLY_FIRST_LINE, value: false },
  },
});

const educationOptions = computed(() => {
  if (!project.value)
    return [];

  const { schooling } = idealProfile.value || { schooling: [] };
  const values = schooling?.flatMap(education => [
    {
      extension: {
        label: extensionOptions.value[String(education.extension_type)]!,
        value: String(education.extension_type)!,
      },
      field: { label: String(education.field)!, value: String(education.field)! },
    },
  ]);

  return uniqBy(values, "label");
});

const selectedRange = reactive({
  salary_currency: null,
  salary_min: "",
  salary_max: "",
});

const filterParams = ref({
  roles: [] as string[],
  corporation_id: "",
  business_function: "",
  realizations: "",
  execution_conditions: "",
  salary_currency: selectedRange.salary_currency as CURRENCY | null,
  salary_min: selectedRange.salary_min,
  salary_max: selectedRange.salary_max,
  graduation_complete: educationCheckBox.booleans.graduation_complete.value,
  education_first_line: educationCheckBox.booleans.education_first_line.value,
  education_extension: null,
  education: "",
  search_ground: "",
  companies: [] as string[],
});

const educationOptionsRef = ref(educationOptions.value);
const initialField = ref(map(educationOptionsRef.value, "field.value"));

// const businessFunctions = computed<BusinessFunction[]>(() => {
// //   if (project?.value) {
// //     const { organization_and_assembly } = project.value.stages.construction;
// //     const { business_functions } = organization_and_assembly || {};
// //     return business_functions || {};
// //   }
//   return [];
// });

// const businessFunctionCorporations = computed<Option[]>(() => {
//   const values = businessFunctions.value?.flatMap(({ corporations_data }) => {
//     const corps = corporations_data as RelatedCorporation[];
//     return corps
//       ? corps.map(({ name, _id }) => ({
//         label: name,
//         value: _id || "",
//       }))
//       : [];
//   });
//   return uniqBy(values, "label");
// });

// const businessFunctionTags = computed<Option[]>(() => {
//   const values = businessFunctions.value?.map(({ tag }) => ({
//     label: conclaOptions[tag],
//     value: tag,
//   }));
//   return uniqBy(values, "label");
// });

const corporationPositions = computed(() => [
  {
    label: project.value.position_name,
    value: project.value.position_name,
  },
]);

function getInitialValues(values: Option[]): string[] {
  return map(values, "value");
}

const selectedPositions = ref<string[]>(getInitialValues(corporationPositions.value));
// const selectedFunctions = ref<string[]>(getInitialValues(businessFunctionTags.value));
// const selectedCorporations = ref<string[]>(getInitialValues(businessFunctionCorporations.value));

function emitFilter(key: string, value: any) {
  filterParams.value = {
    ...filterParams.value,
    [key]: value,
  };
  emit("update:filter", filterParams.value);
}

const selectedPosition = ref<string[]>([]);
const selectedPositionTags = ref<string[]>([]);

function toggleFilterRole(values: string[], tag = false) {
  if (tag)
    selectedPositionTags.value = values;
  else
    selectedPosition.value = values;

  filterParams.value.roles = uniqBy(concat(selectedPosition.value, selectedPositionTags.value), item => item);

  emit("update:filter", filterParams.value);
}

function emitSearch(term: string) {
  emitFilter("search_ground", term);
}
function searchEducation(key: string, param: { value: boolean; label: string }) {
  filterParams.value = {
    ...filterParams.value,
    [key]: param.value,
  };
  emit("update:filter", filterParams.value);
}
function emitSelectedCandidate() {
  selectedCandidate.value = !selectedCandidate.value;
  emit("selected:candidate", selectedCandidate.value);
}

function toggleField(index: number, event: Event) {
  const isChecked = computed(() => (event.target as HTMLInputElement).checked);

  const educationOptionsArray = educationOptionsRef.value;

  if (educationOptionsArray[index]) {
    const field = educationOptionsArray[index]?.field;
    if (field)
      field.value = isChecked.value ? String(initialField.value)! : null!;
  }
}
function salaryFilter(event: {
  salary_currency: CURRENCY | null
  salary_min: string
  salary_max: string
}) {
  filterParams.value.salary_currency = event.salary_currency;
  filterParams.value.salary_min = event.salary_min;
  filterParams.value.salary_max = event.salary_max;
  emit("update:filter", filterParams.value);
};

const allCorporations = ref<CorporationCheckbox[]>([]);
const corporations = ref<CorporationCheckbox[]>([]);
const corporationsParams = reactive({
  limit: 5,
  skip: 0,
});

const loadedCorporationIds = ref<string[]>([]);

const filterSelectAllCorporations = ref<CheckboxType>({
  name: t("projects.validation.filters.selectAll"),
  id: "select_all_corporations",
  isChecked: false,
});

function formatSearchFildCorporations() {
  const data = searchField.value[0].corporations || [];
  const dataFormat = data.reduce((acc: CheckboxType[], item: SearchFieldCorporations) => {
    acc.push({
      name: item.name,
      id: item.corporation_id,
      isChecked: !!loadedCorporationIds.value.includes(String(item.corporation_id)),
    });
    return acc;
  }, []);

  allCorporations.value = dataFormat;
  chunkCorporations();
}

function chunkCorporations() {
  corporations.value = [];
  const skip = corporationsParams.skip === 0 ? corporationsParams.limit : corporationsParams.skip;
  corporations.value = chunk(allCorporations.value, skip)[0];
  corporationsParams.skip = corporations.value.length;
}

async function getCorporations(): Promise<void> {
  await fetchSearchField();
  corporations.value = [];
  formatSearchFildCorporations();
}

function selectAllCorporations() {
  allCorporations.value.map((corporation) => {
    corporation.isChecked = true;
    if (corporation.id)
      loadedCorporationIds.value.push(corporation.id);

    filterParams.value.companies.push(corporation.name);
    return corporation;
  });

  chunkCorporations();

  emit("update:filter", filterParams.value);
}

function removeAllCorporations() {
  filterParams.value.companies = [];
  loadedCorporationIds.value = [];

  allCorporations.value.map((corporation) => {
    corporation.isChecked = false;
    return corporation;
  });

  chunkCorporations();

  emit("update:filter", filterParams.value);
}

function handleAllCorporations(data: any) {
  const checked = data.target.checked;
  filterSelectAllCorporations.value.isChecked = checked;
  if (checked)
    selectAllCorporations();
  else
    removeAllCorporations();
}

function handleCorporationChange(corporation: CorporationCheckbox) {
  corporation.isChecked = !corporation.isChecked;
  if (corporation.isChecked) {
    filterParams.value.companies.push(corporation.name);
    if (corporation.id)
      loadedCorporationIds.value.push(corporation.id);
  }
  else {
    const companyIndex = filterParams.value.companies.indexOf(corporation.name);
    if (companyIndex !== -1)
      filterParams.value.companies.splice(companyIndex, 1);
  }
  emit("update:filter", filterParams.value);
}

async function loadMoreCorporation() {
  corporationsParams.skip = corporationsParams.skip + 5;
  chunkCorporations();
}

onMounted(() => {
  emit("update:filter", filterParams.value);
  getCorporations();
});
</script>

<template>
  <div class="filters">
    <div class="search-input-wrapper">
      <SearchInput :placeholder="t('persons.searchPerson')" @search="emitSearch($event)" />
    </div>
    <h2 class="filters__title">
      <SvgIcon icon="filter_icon" />
      {{ t("projects.filters.title") }}
    </h2>
    <div>
      <CustomCheckBox
        :label="t('projects.mapping.displaySelectedCandidates')"
        :style="{ textTransform: 'uppercase' }"
        :on-change="emitSelectedCandidate"
        variation="#ffb600"
        class="filters__filter"
      />
      <DropdownFilter
        :name="t('projects.filters.tagFilters.role.0')"
        :filter-open="filters.functions"
        @filter:open="filters.functions = filters.functions ? false : true"
      >
        <template #filter>
          <div class="filters__roles">
            <ChecklistFilter
              class="filters__filter"
              :options="corporationPositions"
              :initial-values="selectedPositions"
              variation="#ffb600"
              @filter:check="toggleFilterRole($event)"
            />
            <div class="filters__roles__tags">
              <Tag variation="secondary" filter-name="job" :clear="false" @filter:tag="toggleFilterRole($event, true)" />
            </div>
          </div>
        </template>
      </DropdownFilter>
      <DropdownFilter
        :name="t('projects.filters.tagFilters.company.0')"
        :filter-open="filters.corporations"
        @filter:open="filters.corporations = filters.corporations ? false : true"
      >
        <template #filter>
          <TransitionGroup>
            <CustomCheckBox
              v-if="corporations.length > 0"
              :checked="filterSelectAllCorporations.isChecked"
              :label="filterSelectAllCorporations.name"
              class="filters__filter"
              variation="#ffb600"
              @change="handleAllCorporations($event)"
            />
            <CustomCheckBox
              v-for="(corporation, index) of corporations"
              :key="index"
              :checked="corporation.isChecked"
              :label="corporation.name"
              class="filters__filter"
              variation="#ffb600"
              @change="() => handleCorporationChange(corporation)"
            />
          </TransitionGroup>
          <p
            v-if="allCorporations.length > corporationsParams.skip"
            class="load-more-corporations"
            role="button"
            @click="loadMoreCorporation()"
          >
            {{ t("projects.filters.loadMore") }}
          </p>
        </template>
      </DropdownFilter>
      <DropdownFilter
        :name="t('projects.mapping.filters.schooling')"
        :filter-open="filters.education"
        @filter:open="filters.education = filters.education ? false : true"
      >
        <template #filter>
          <CustomCheckBox
            v-for="(educations, key, index) of educationCheckBox.booleans"
            :key="index"
            :checked="educations.value"
            :label="educations.label"
            variation="#ffb600"
            class="filters__filter"
            @change="() => (educations.value = !educations.value, searchEducation(key, educations))"
          />
          <div v-for="(extensions, index) in educationOptionsRef" :key="index">
            <CustomCheckBox
              v-if="extensions.field.label !== ''"
              :checked="false"
              :label="extensions.field.label"
              class="filters__filter"
              variation="#ffb600"
              @change="toggleField(index, $event)"
            />
          </div>
        </template>
      </DropdownFilter>
      <DropdownFilter
        class="filter__salay"
        :name="t('projects.mapping.filters.salaryRange')"
        :filter-open="filters.salary"
        @filter:open="filters.salary = filters.salary ? false : true"
      >
        <template #filter>
          <MoneyFilter
            :has-button="false"
            :clearable="false"
            :initial-values="selectedRange"
            :style="{ marginInlineStart: '1.5rem' }"
            @filter:money_range="salaryFilter($event)"
          />
        </template>
      </DropdownFilter>
      <DropdownFilter
        :name="t('projects.filters.tagFilters.realizations.0')"
        :filter-open="filters.realizations"
        @filter:open="filters.realizations = filters.realizations ? false : true"
      >
        <template #filter>
          <div class="vertical-coming-soon">
            <ComingSoon :title="t('text.comingSoonTitle')" :paragraph="t('text.comingSoonParagraph')" class="center" />
          </div>
        </template>
      </DropdownFilter>
      <DropdownFilter
        :name="t('projects.filters.tagFilters.executions.0')"
        :filter-open="filters.executions"
        @filter:open="filters.executions = filters.executions ? false : true"
      >
        <template #filter>
          <div class="vertical-coming-soon">
            <ComingSoon :title="t('text.comingSoonTitle')" :paragraph="t('text.comingSoonParagraph')" class="center" />
          </div>
        </template>
      </DropdownFilter>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.filters {
  display: flex;
  flex-direction: column;

  &__title {
    display: flex;
    align-items: center;
    color: $primary;
    font-size: 1.25rem;
    font-weight: normal;
    letter-spacing: 2px;
  }

  &__filter {
    padding-left: 1.5rem;
    margin-block: 1rem;
    max-width: 21rem;
  }

  &__roles {
    display: flex;
    flex-direction: column;

    &__tags {
      padding-inline-start: 1.5rem;

      :deep(.tag-filter) {
        margin-top: 0;
      }
    }
  }

  :deep(.filter__salay) .tag-input-wrapper {
    input {
      padding-inline: 0.5rem;
    }

    .component-select .multiselect .multiselect-single-label {
      padding-inline-start: 0.5rem;
    }
  }

  .vertical-coming-soon {
    .coming-soon {
      margin: -2rem;
      padding-block-start: 2rem;
      transform: scale(0.6);

      :deep(svg) {
        display: none;
      }
    }
  }

  :deep(.dropdown.dropdown--open) {
    .dropdown__button {
      color: inherit;
    }
  }

  .search-input-wrapper {
    width: 18.0625rem;
    height: 2.5rem;
    flex-shrink: 0;
    border-radius: 0.625rem;
    background: white;
    margin-block-end: 2rem;
  }
}

.load-more-corporations {
  font-size: 0.875rem;
  color: $yellow;
  font-weight: 700;
  padding-inline-start: 1.5rem;
  letter-spacing: 0.13rem;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 400ms ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
