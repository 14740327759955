import { messages as portugueseMessages } from "./pt";
import { messages as englishMessages } from "./en";

const messages = {
  "pt-BR": {
    ...portugueseMessages.pt.translations,
  },
  "en-US": {
    ...englishMessages.en.translations,
  },
};

export { messages };
