<script lang="ts">
import { defineComponent, ref } from "vue";
import { range } from "@/utils/array";
import AddLink from "@/components/add-link/AddLink.vue";

export default defineComponent({
  name: "ListableInputForm",
  components: {
    AddLink,
  },
  props: {
    linkText: String,
  },
  setup() {
    const items = ref([0]);
    const forms = ref([]);

    const appendItem = () => {
      items.value.push(Date.now());
    };

    const removeItem = (id: number) => {
      const itemIndex = items.value.indexOf(id);
      items.value.splice(itemIndex, 1);
    };

    return {
      forms,
      items,
      range,
      appendItem,
      removeItem,
    };
  },
});
</script>

<template>
  <div class="listable-form-wrapper">
    <div
      v-for="(item, index) in items"
      :key="item"
      :ref="
        (el) => {
          if (el) forms[item] = el;
        }
      "
      class="form-item"
    >
      <slot :index="index" />
    </div>
    <div v-show="false" class="button-wrapper">
      <AddLink :link-text="linkText" :on-click="appendItem" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.listable-form-wrapper {
  width: 100%;

  .form-item {
    width: 100%;

    .remove-wrapper {
      display: flex;
      height: 35px;
      justify-content: flex-end;

      svg {
        height: 18px;
        stroke: $gray-light;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .button-wrapper {
    width: 100%;
  }
}
</style>
