<script setup lang="ts">
import IdealProfileTabs from "./components/IdealProfileTabs.vue";
import type { AttributesMapEnums, CulturalMatchMapEnums } from "@/schemas/enums/construction_guide";

const props = defineProps<{ id: string }>();

const AsyncAchievementsForm = defineAsyncComponent(() =>
  import("./components/forms/AchievementsForm.vue"));

const route = useRoute();
const router = useRouter();
const { t } = useI18n();
const { data: project, error } = await useProject(props.id);

const {
  stageTabs,
} = useTabsProject(props.id);

const currentRoute = computed(() => stageTabs.value.findIndex(tab => tab.key === router.currentRoute.value.meta.tab));

const {
  data: construction,
} = useConstructionOrganizationQuery(String(route.params.id));

const constructionId = computed(() => {
  return construction.value?._id;
});

const {
  data: idealProfile,
} = useConstructionIdealProfile(props.id);

function goTo(tabIndex: number) {
  if (!stageTabs.value || !stageTabs.value[tabIndex])
    return;

  router.push(stageTabs.value[tabIndex].path);
}
const editing = ref(false);
function checkBullet(value: boolean | undefined) {
  if (value === undefined)
    return true;
  return value;
}
const idealProfileValue = ref();

const idealProfileList: {
  academic_backgrounds_and_languages: null | Record<string, string>
  experiences: null | Record<string, string>
  achievements: null | Record<string, { description: string; desired_attribute: AttributesMapEnums; desired_cultural_match: CulturalMatchMapEnums }>
} = reactive({
  academic_backgrounds_and_languages: null,
  experiences: null,
  achievements: null,
});

watchEffect(() => {
  if (idealProfile.value) {
    idealProfileValue.value = idealProfile.value;
    idealProfileList.academic_backgrounds_and_languages = { academic_backgrounds: idealProfile.value.academic_backgrounds, languages: idealProfile.value.languages };
    idealProfileList.achievements = idealProfile.value.achievements;
    idealProfileList.experiences = idealProfile.value.experiences;
  }
});
const sectionsTitles = computed(() => {
  return {
    academic_backgrounds: t("projects.shortlist.titles.academicEducation"),
    experiences: t("projects.shortlist.titles.experience"),
    achievements: t("projects.shortlist.titles.achievements"),
    languages: t("projects.screening.guide.languages"),
  };
});
</script>

<template>
  <section class="project-section">
    <div v-if="error">
      could not load
    </div>
    <div class="project-container">
      <ProjectStages :id="props.id" />
      <div v-if="project && idealProfile" class="project-wrapper">
        <ProjectTabs :stage-tabs="stageTabs" :active="currentRoute" class="tabs" @change="goTo($event)" />
        <IdealProfileTabs :editing="editing" :project-id="project._id" :stages="project.stages" @edit="editing = true" />
        <div v-if="editing" class="project-content">
          <AsyncAchievementsForm
            :academic-backgrounds="idealProfile.academic_backgrounds"
            :languages="idealProfile.languages"
            :achievements="idealProfile.achievements"
            :experiences="idealProfile.experiences"
            :construction-id="constructionId"
            @cancel="editing = false"
          />
        </div>
        <div v-else class="project-content">
          <div class="resume-component">
            <div class="profile-data">
              <div v-for="(ach, item, index) of idealProfileList" :key="index" class="data-item">
                <h3 v-if="item !== 'academic_backgrounds_and_languages'" class="resume-subtitle">
                  {{ sectionsTitles[item] }}
                </h3>
                <template v-if="item === 'academic_backgrounds_and_languages'">
                  <div v-for="(value, key, valueIndex) of ach" :key="valueIndex">
                    <h3 class="resume-subtitle">
                      {{ sectionsTitles[key] }}
                    </h3>
                    <div v-for="sec in value" :key="sec" class="item">
                      <div class="circle" :class="{ 'hide-bullet': !checkBullet(true) }" />
                      {{ sec }}
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div v-for="(value, idx) of ach" :key="idx" class="item">
                    <div class="circle" :class="{ 'hide-bullet': !checkBullet(true) }" />
                    <div class="content">
                      <span v-if="value?.description">{{ value?.description }}</span>
                      <span v-else>{{ value }}</span>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<route lang="yaml">
meta:
  layout: project-stage
  stage: construction
  tab: ideal-profile
  ideal-profile: ideal-profile
</route>

<style scoped lang="scss">
.project-container {
  padding-top: 3.75rem;
}

.project-wrapper {
  background: white;
  min-height: 100vh;

}

.resume-component {
  display: flex;
  flex-direction: column;
  margin-inline: 2.5rem;
}

.resume-subtitle {
  font-size: 1.125rem;
  color: #666666;
  font-weight: 600;
  text-transform: uppercase;
}

.profile-data {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0.75rem 0;
  gap: 2.2rem;

  .content {
    flex: 1;
  }

  .data-item {
    display: flex;
    flex-direction: column;
    width: 33%;
    div:nth-child(2n) .resume-subtitle {
      margin-block-start: 1.875rem;
    }

    .item {
      display: flex;
      margin-block-end: 0.75rem;
      font-size: 0.875rem;
      line-height: 1.5rem;

      &.list {
        flex-direction: column;
      }

      .circle.hide-bullet {
        background: transparent;
      }

      .circle {
        width: 0.5rem;
        min-width: 0.5rem;
        height: 0.5rem;
        margin-top: calc(0.875rem / 1.75);
        margin-right: 0.625rem;
        background: $yellow;
        border-radius: 50%;
      }

      .data-title {
        padding-right: 0.7rem;
        color: $gray-dark;
        font-weight: bold;
        line-height: 1.4;
        word-break: normal;
      }

      .data-value {
        color: $gray-dark;
        font-size: 0.875rem;
        line-height: 1.5rem;
        word-break: normal;
      }
    }
  }
}
</style>
