<script lang="ts">
import { defineComponent, onBeforeUnmount, ref, toRefs } from "vue";
import { BubbleMenu, Editor, EditorContent } from "@tiptap/vue-3";
import TextAlign from "@tiptap/extension-text-align";
import Document from "@tiptap/extension-document";
import Heading from "@tiptap/extension-heading";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import Italic from "@tiptap/extension-italic";
import Bold from "@tiptap/extension-bold";
import Strike from "@tiptap/extension-strike";
import { Color } from "@tiptap/extension-color";
import TextStyle from "@tiptap/extension-text-style";
import Underline from "@tiptap/extension-underline";
import BulletList from "@tiptap/extension-bullet-list";
import ListItem from "@tiptap/extension-list-item";
import History from "@tiptap/extension-history";
import Placeholder from "@tiptap/extension-placeholder";
import { watchOnce } from "@vueuse/core";
import { FontSize } from "./FontSizeExtension";
import { SvgIcon } from "@/components/icon";

export default defineComponent({
  name: "Editor",
  components: {
    EditorContent,
    SvgIcon,
    BubbleMenu,
  },
  props: {
    modelValue: {
      type: String,
      default: null,
    },
    outlineFocus: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  emits: ["update:modelValue", "blur", "focus"],
  setup(props, { emit }) {
    const selectionActive = ref(false);
    const editorFocused = ref(false);

    const controls = ref({
      size: false,
      heading: false,
      align: false,
      color: false,
    });

    const switchControl = (key: "size" | "heading" | "color " | "align") => {
      const newControls = { ...controls.value };
      Object.keys(newControls).forEach(control => (newControls[control] = false));
      newControls[key] = true;
      controls.value = newControls;
    };
    const { modelValue, placeholder } = toRefs(props);
    const editor = new Editor({
      extensions: [
        Bold,
        BulletList,
        Color,
        Document,
        FontSize,
        Heading.configure({
          levels: [1, 2, 3],
        }),
        History.configure({
          newGroupDelay: 200,
        }),
        Italic,
        ListItem,
        Paragraph,
        Placeholder.configure({
          placeholder: placeholder.value,
        }),
        Strike,
        Text,
        TextAlign.configure({
          types: ["heading", "paragraph"],
        }),
        TextStyle,
        Underline,
      ],
      content: modelValue.value,
      onUpdate: ({ editor: editorInstance }) => {
        emit("update:modelValue", editorInstance.getHTML());
      },
      onTransaction({ transaction, editor: editorInstance }) {
        selectionActive.value = editorInstance?.isFocused || editorInstance.isCapturingTransaction;
      },
      onBlur() {
        emit("blur");
      },
      onFocus() {
        emit("focus");
      },
    });

    onBeforeUnmount(() => {
      if (editor)
        editor.destroy();
    });

    watchOnce(modelValue, (value) => {
      if (editor)
        editor.commands.setContent(value);
    });
    return {
      editor,
      selectionActive,
      editorFocused,
      controls,
      switchControl,
    };
  },
  watch: {
    modelValue(value) {
      const isSame = this.editor.getHTML() === value;
      if (isSame)
        return;

      this.editor.commands.setContent(value, false);
    },
  },
});
</script>

<template>
  <div v-if="editor" class="editor-container">
    <EditorContent :editor="editor" class="editor" :class="{ outlined: outlineFocus }" />
    <BubbleMenu class="editor-menu" :editor="editor" @click.prevent="editor.commands.focus()">
      <button class="relative" @click="switchControl('heading')">
        <SvgIcon icon="icon-font" />
        <div v-if="controls.heading" class="menu-picker">
          <button
            class="menu-picker-option"
            :class="{
              'is-active': editor.isActive('para', { level: 4 }),
            }"
            @click="editor.chain().focus().setParagraph().run()"
          >
            Text
          </button>
          <button
            class="menu-picker-option"
            :class="{
              'is-active': editor.isActive('heading', { level: 1 }),
            }"
            @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
          >
            H1 Headline
          </button>
          <button
            class="menu-picker-option"
            :class="{
              'is-active': editor.isActive('heading', { level: 2 }),
            }"
            @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
          >
            H2 Headline
          </button>
          <button
            class="menu-picker-option"
            :class="{
              'is-active': editor.isActive('heading', { level: 3 }),
            }"
            @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
          >
            H3 Headline
          </button>
        </div>
      </button>
      <button
        :class="{ 'is-active': editor.isActive('bold') }"
        @click="editor.chain().toggleBold().run()"
      >
        <SvgIcon icon="icon-bold" />
      </button>
      <button class="relative" @click="switchControl('size')">
        <SvgIcon icon="icon-text" />
        <div v-if="controls.size" class="menu-picker">
          <button
            :class="{
              'is-active': editor.isActive({ fontSize: '10rem' }),
            }"
            class="menu-picker-option menu-picker-option"
            @click="editor.chain().focus().setFontSize('10rem').run()"
          >
            Big
          </button>
          <button
            :class="{
              'is-active': editor.isActive({ fontSize: '5rem' }),
            }"
            class="menu-picker-option menu-picker-option"
            @click="editor.chain().focus().setFontSize('5rem').run()"
          >
            Medium
          </button>

          <button
            :class="{
              'is-active': editor.isActive({ fontSize: '1rem' }),
            }"
            class="menu-picker-option menu-picker-option"
            @click="editor.chain().focus().setFontSize('1rem').run()"
          >
            small
          </button>
        </div>
      </button>
      <button
        class="menu-picker-option"
        :class="{
          'is-active': editor.isActive('italic'),
        }"
        @click="editor.chain().focus().toggleItalic().run()"
      >
        <SvgIcon icon="icon-italic" />
      </button>
      <button
        class="menu-picker-option"
        :class="{
          'is-active': editor.isActive('underline'),
        }"
        @click="editor.chain().focus().toggleUnderline().run()"
      >
        <SvgIcon icon="icon-underline" />
      </button>
      <button
        class="menu-picker-option"
        :class="{
          'is-active': editor.isActive('strike'),
        }"
        @click="editor.chain().focus().toggleStrike().run()"
      >
        <SvgIcon icon="icon-strikethrough" />
      </button>
      <button class="relative" @click="switchControl('color')">
        <SvgIcon icon="icon-color" />
        <div v-if="controls.color" class="menu-picker menu-color-picker">
          <button
            :class="{
              'is-active': editor.isActive({ textStyle: { color: '#000000' } }),
            }"
            class="menu-picker-option color-option menu-picker-option"
            :style="{ backgroundColor: '#000000' }"
            @click="editor.chain().focus().setColor('#000000').run()"
          />
          <button
            :class="{
              'is-active': editor.isActive({ textStyle: { color: '#9510AC' } }),
            }"
            class="menu-picker-option color-option menu-picker-option"
            :style="{ backgroundColor: '#9510AC' }"
            @click="editor.chain().focus().setColor('#9510AC').run()"
          />
          <button
            :class="{
              'is-active': editor.isActive({ textStyle: { color: '#E50003' } }),
            }"
            class="menu-picker-option color-option menu-picker-option"
            :style="{ backgroundColor: '#E50003' }"
            @click="editor.chain().focus().setColor('#E50003').run()"
          />
          <button
            :class="{
              'is-active': editor.isActive({ textStyle: { color: '#FF8200' } }),
            }"
            class="menu-picker-option color-option menu-picker-option"
            :style="{ backgroundColor: '#FF8200' }"
            @click="editor.chain().focus().setColor('#FF8200').run()"
          />
          <button
            :class="{
              'is-active': editor.isActive({ textStyle: { color: '#FFD500' } }),
            }"
            class="menu-picker-option color-option menu-picker-option"
            :style="{ backgroundColor: '#FFD500' }"
            @click="editor.chain().focus().setColor('#FFD500').run()"
          />
          <button
            :class="{
              'is-active': editor.isActive({ textStyle: { color: '#0466A2' } }),
            }"
            class="menu-picker-option color-option menu-picker-option"
            :style="{ backgroundColor: '#0466A2' }"
            @click="editor.chain().focus().setColor('#0466A2').run()"
          />
          <button
            :class="{
              'is-active': editor.isActive({ textStyle: { color: '#45A256' } }),
            }"
            class="menu-picker-option color-option menu-picker-option"
            :style="{ backgroundColor: '#45A256' }"
            @click="editor.chain().focus().setColor('#45A256').run()"
          />
        </div>
      </button>
      <button class="relative" @click="switchControl('align')">
        <SvgIcon icon="format-align-left" />
        <div v-if="controls.align" class="menu-picker">
          <button
            class="menu-picker-option"
            :class="{
              'is-active': editor.isActive({ textAlign: 'left' }),
            }"
            @click="editor.chain().focus().setTextAlign('left').run()"
          >
            <SvgIcon icon="format-align-left" />
          </button>
          <button
            class="menu-picker-option"
            :class="{
              'is-active': editor.isActive({ textAlign: 'center' }),
            }"
            @click="editor.chain().focus().setTextAlign('center').run()"
          >
            <SvgIcon icon="format-align-center" />
          </button>
          <button
            class="menu-picker-option"
            :class="{
              'is-active': editor.isActive({ textAlign: 'right' }),
            }"
            @click="editor.chain().focus().setTextAlign('right').run()"
          >
            <SvgIcon icon="format-align-right" />
          </button>
          <button
            class="menu-picker-option"
            :class="{
              'is-active': editor.isActive({ textAlign: 'justify' }),
            }"
            @click="editor.chain().focus().setTextAlign('justify').run()"
          >
            <SvgIcon icon="format-align-justify" />
          </button>
        </div>
      </button>
      <button
        class="menu-picker-option"
        :class="{
          'is-active': editor.isActive('bulletList'),
        }"
        @click="editor.chain().focus().toggleBulletList().run()"
      >
        <SvgIcon icon="icon-list" />
      </button>
    </BubbleMenu>
  </div>
</template>

<style scoped lang="scss">
.editor-container,
.editor {
  position: relative;
  min-height: 21rem;
  font-size: 1rem;

  :deep(p) {
    padding: 0.25rem 0;
    color: #000;
  }
  :deep(ul) {
    padding-left: 1.25rem;
  }
}

.editor:hover {
  cursor: text;
}

:deep(h1) {
  font-size: 6rem;
}

:deep(h2) {
  font-size: 4rem;
}

:deep(h3) {
  font-size: 2rem;
}

:deep(ul) {
  padding: 0 1rem;
  list-style: disc;
}

:deep(.ProseMirror) {
  width: 100%;
  min-height: 21rem;
  outline: none;

  & p.is-editor-empty:first-child::before {
    height: 0;
    color: #666;
    content: attr(data-placeholder);
    float: left;
    font-size: 1rem;
    opacity: 0.5;
    pointer-events: none;
  }
}

.editor.outlined {
  padding: 1px;
  border-radius: 0.25rem;

  &:focus-within {
    outline: 1px inset #666;
  }
}

:deep(div[data-tippy-root]) {
  position: absolute;
  width: auto;
  height: auto;
  inset: 0 !important;
  transform: none !important;
}

.editor-menu {
  position: absolute;
  top: 1rem;
  right: 0;
  display: flex;
  flex-direction: column;
  border: 0;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 2px 4px #00000029;

  button {
    padding: 0.5rem;
    border: none;
    background: none;
    color: #666;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;

    &:hover,
    &.is-active {
      background: #f5f5f7;
      color: #333;
      cursor: pointer;
    }
  }
}

:deep(svg) {
  width: 1em;
  height: 1em;
}

.menu-picker {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 100%;
  border-radius: 0.125rem;
  margin-right: 1rem;
  background: #fff;
  box-shadow: 0 2px 4px #00000029;

  &.menu-color-picker {
    display: flex;
    width: 2.25rem;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    padding: 0.25rem 0;
    border-radius: 0.5rem;
    background: none;
    box-shadow: 0 2px 4px #00000029;
  }
}

.menu-picker .menu-picker-option {
  width: 100%;
  padding: 0.125rem 0.25rem;
  text-align: left;
  white-space: nowrap;

  &.color-option {
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 6px;
    margin: 0.25rem;

    &:hover,
    &.is-active {
      outline: 1px inset #333;
    }
  }
}

.relative {
  position: relative;
}

:deep(.m-right) {
  margin-right: 0;
}
</style>
