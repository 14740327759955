import cloneDeep from "lodash-es/cloneDeep";
import findLastIndex from "lodash-es/findLastIndex";
import set from "lodash-es/set";
import get from "lodash-es/get";
import { sortByStartDate } from "@/utils/dates";

export interface textPageArrayType {
  score?: number
  break: boolean
  text: string
}

export interface textPageArrayObjectIdType {
  id: string
  texts: textPageArrayType[]
}

export interface textPageTwoArrayObjectIdType {
  id: string
  list_one: textPageArrayType[]
  list_two: textPageArrayType[]
}

export interface paginateTextPageType {
  lines: number
  break: boolean
  text: string
}

export interface paginateTextArrayType {
  lines: number
  texts: textPageArrayType[]
}

export function addDottedLine(className?: string) {
  const target = document.querySelector(
    className ? `.${className}` : ".line-brake-doted",
  ) as HTMLParagraphElement;
  if (!target)
    return;
  const lines = target.textContent?.split("\n").map((str) => {
    const div = document.createElement("p");
    div.classList.add("line");
    div.textContent = str;
    return div;
  });
  target.textContent = "";
  lines?.forEach(line => target.appendChild(line));
}

export function formatBreakLines(text: string | null | undefined): string {
  if (!text)
    return "";
  else return text?.replaceAll(/\n/g, "<br />");
}

export function paragraphToArray(text: string | null | undefined): any[] {
  return text ? text.split("<br />") : [text];
}

export function getSizeCharacter(font: string, fontSize: string) {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  context.font = `${fontSize} ${font}`;
  const size = context?.measureText("C");
  return size?.width || 0;
}

export function getTextSize(text: string, font: string, fontSize: string, letterSpacing = "normal") {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  context.font = `${fontSize} ${font}`;
  context.letterSpacing = letterSpacing;
  const size = context?.measureText(text);
  return size?.width || 0;
}

export function estimatedTextLines(text: string, font_size = "16px", boxWidth: number, initialLines = 1, font = "Open Sans") {
  if (!text)
    return 0;

  const charWidth = getSizeCharacter(font_size, font);
  const maxChar = Math.ceil(boxWidth / charWidth);

  let lines = initialLines;
  let currentLine = "";

  const lineBreaked = text.split(/\n/g);
  lineBreaked?.forEach((item) => {
    item += "\n";
    lines++;
    const textSplit = item.split(" ");
    textSplit?.forEach((data) => {
      if (currentLine.length + data.length + 1 > maxChar) {
        currentLine = "";
        lines++;
      }
      currentLine += `${data} `;
    });
  });
  return lines;
}

export function paginateTexts(text: string, fontSize = "16px", boxWidth: number, maxLines: number, initialLines = 0, intialChars = "", font = "Open Sans", breakLines = "\n", letterSpacing = "normal"): paginateTextPageType[] {
  const pagesInfo: paginateTextPageType[] = [];
  let paragraphs: string[] = [];

  let lines = initialLines;
  let currentLine = "";

  if (text.trim() === "")
    return pagesInfo;

  const lineBreaked = text?.split(/\n/g);

  lineBreaked?.forEach((item) => {
    if (item !== "") {
      const textSplited = item.split(" ");

      textSplited?.forEach((data, index) => {
        const checkTextWidth = getTextSize(
          (index === 0 && intialChars.length > 0) ? `${intialChars} ${data} ` : `${currentLine} ${data} `,
          font,
          fontSize,
          letterSpacing,
        );

        if (Math.floor(checkTextWidth) >= boxWidth) {
          paragraphs.push(cloneDeep(currentLine));
          currentLine = "";
        }

        if (paragraphs.length + lines >= maxLines) {
          pagesInfo.push({
            lines: paragraphs.length,
            break: pagesInfo.length >= 1,
            text: paragraphs.join(breakLines),
          });
          lines = 0;
          paragraphs = [];
        }

        currentLine += `${data} `;
      });

      if (currentLine.length > 0) {
        paragraphs.push(currentLine);
        currentLine = "";
      }
    }
    else {
      paragraphs.push("");
    }
  });
  if (paragraphs.length > 0 || currentLine.length > 0) {
    if (currentLine.length > 0)
      paragraphs.push(currentLine);

    pagesInfo.push({
      lines: paragraphs.length,
      break: pagesInfo.length >= 1,
      text: paragraphs.join(breakLines),
    });
  }
  return pagesInfo;
}

export function paginateTextList(texts: string[], font_size = "16px", boxWidth: number, maxLines: number, initialLines = 0, font = "Open Sans", letterSpacing = "normal") {
  const pages: paginateTextArrayType[] = [];

  let page = 0;
  let itemIndex = 0;
  let lines = initialLines;

  const _newPage = () => {
    lines = 0;
    itemIndex = 0;
    pages[page] = { lines, texts: [] };
    pages[page].texts[itemIndex] = { break: false, text: "" };
  };

  texts?.forEach((text, indexText) => {
    const textPaginated = paginateTexts(text, font_size, boxWidth, maxLines, lines, "", font, "\n", letterSpacing);

    textPaginated?.forEach((item, index) => {
      if (!pages[page])
        _newPage();

      if (!pages[page].texts[itemIndex])
        pages[page].texts[itemIndex] = { break: false, text: "" };

      if (index === 0) {
        lines += item.lines;
        pages[page].lines = lines;
        pages[page].texts[itemIndex].text = item.text;
      }
      else {
        page++;
        _newPage();
        lines += item.lines;
        pages[page].lines = lines;
        pages[page].texts[itemIndex].break = true;
        pages[page].texts[itemIndex].text = item.text;
      }
    });
    if (indexText + 1 < texts.length) {
      itemIndex++;
      lines++;
    }
  });

  return pages;
}

export function paginateArrayText(texts: string[], font_size = "16px", boxWidth: number, maxLines: number, initialLines = 0, font = "Open Sans") {
  const pages: textPageArrayType[][] = [];

  let page = 0;
  let itemIndex = 0;
  let lines = initialLines;

  texts.forEach((text, idText) => {
    if (!pages[page])
      pages[page] = [];
    pages[page][itemIndex] = { break: false, text: "" };

    const paginate: paginateTextPageType[] = paginateTextPage(
      text,
      font_size,
      boxWidth,
      maxLines,
      lines,
      true,
      font,
    );

    paginate.forEach((item: paginateTextPageType, index) => {
      if (paginate.length > 1 && index === 0)
        lines = 0;
      else if ((paginate.length > 1 && index > 0) || paginate.length === 1)
        lines += item.lines;

      if (index === 0) {
        pages[page][itemIndex].text = item.text;
        pages[page][itemIndex].break = false;
      }
      else {
        page++;
        itemIndex = 0;
        if (!pages[page])
          pages[page] = [];
        pages[page][itemIndex] = { break: true, text: item.text };
      }
      if (lines >= maxLines) {
        page++;
        lines = 0;
        itemIndex = 0;
        pages[page] = [];
        pages[page][itemIndex] = { break: false, text: "" };
      }
    });
    if (idText + 1 < texts.length)
      itemIndex++;
  });
  return pages;
}

export function paginateTextPage(text: string, font_size = "16px", boxWidth: number, maxLines: number, initialLines = 0, font = "Open Sans") {
  const charWidth = getSizeCharacter(font_size, font);
  const maxChar = Math.ceil(boxWidth / charWidth);
  const pages: string[] = [];

  let lines = initialLines;
  let currentLine = "";
  let currentParagraph = "";

  const lineBreaked = text.split(/\n/g);

  lineBreaked?.forEach((item) => {
    item += "\n";
    lines++;
    const textSplit = item.split(" ");
    textSplit?.forEach((data) => {
      if (currentLine.length + data.length + 1 > maxChar) {
        currentParagraph += currentLine;
        currentLine = "";
        lines++;
      }
      if (lines > maxLines) {
        currentParagraph += currentLine;
        pages.push(cloneDeep(currentParagraph.trim()));
        currentParagraph = "";
        currentLine = "";
        lines = 0;
      }
      currentLine += `${data} `;
    });
  });
  currentParagraph += currentLine;
  pages.push(currentParagraph.trim());
  return pages;
}

export function paginateArrayTextPage(texts: string[], font_size = "16px", boxWidth: number, maxLines: number, initialLines = 0, font = "Open Sans") {
  const charWidth = getSizeCharacter(font_size, font);
  const maxChar = Math.ceil(boxWidth / charWidth);

  const pages: textPageArrayType[][] = [];

  let lines = initialLines;
  let page = 0;
  let index = 0;
  let currentLine = "";
  let currentParagraph = "";
  let breaked = false;

  pages[page] = [];

  texts.forEach((text) => {
    pages[page][index] = { break: false, text: "" };
    const lineBreaked = text.split(/\n/g);
    lineBreaked?.forEach((item) => {
      item += "\n";
      lines++;
      const textSplit = item.split(" ");
      textSplit?.forEach((data) => {
        if (currentLine.length + data.length + 1 > maxChar) {
          pages[page][index].text += currentLine;
          currentLine = "";
          lines++;
          breaked = true;
        }
        if (lines > maxLines) {
          page++;
          index = 0;
          pages[page] = [];
          currentParagraph += currentLine;
          pages[page][index] = { break: breaked, text: currentParagraph };
          currentParagraph = "";
          currentLine = "";
          lines = 0;
        }
        currentLine += `${data} `;
      });
    });
    currentParagraph += currentLine;
    pages[page][index].text += currentParagraph;
    currentLine = "";
    currentParagraph = "";
    index++;
    breaked = false;
  });

  return pages;
}

export function paginateArrayObjectIdTextPage(texts: any[], idName = "id", textName = "text", font_size = "16px", boxWidth: number, maxLines: number, initialLines = 0, font = "Open Sans") {
  const charWidth = getSizeCharacter(font_size, font);
  const maxChar = Math.ceil(boxWidth / charWidth);

  const pages: textPageArrayObjectIdType[][] = [];

  let lines = initialLines;
  let page = 0;
  let index = 0;
  let currentLine = "";
  let currentParagraph = "";
  let breaked = false;

  pages[page] = [];

  texts.forEach((textData, indexItem) => {
    const _id = get(textData, idName, null);
    const _text = get(textData, textName, "");

    pages[page][indexItem] = { id: _id, texts: [{ break: breaked, text: "" }] };

    const lineBreaked = _text.split(/\n/g);
    lineBreaked?.forEach((item: string) => {
      item += "\n";
      lines++;
      const textSplit = item.split(" ");
      textSplit?.forEach((data) => {
        if (currentLine.length + data.length + 1 > maxChar) {
          pages[page][indexItem].texts[index].text += currentLine;
          currentLine = "";
          lines++;
          breaked = true;
        }
        if (lines > maxLines) {
          page++;
          index = 0;
          currentParagraph += currentLine;
          pages[page] = [];
          pages[page][indexItem] = { id: _id, texts: [{ break: breaked, text: currentParagraph }] };
          currentParagraph = "";
          currentLine = "";
          lines = 0;
        }
        currentLine += `${data} `;
      });
    });
    currentParagraph += currentLine;
    pages[page][indexItem].texts[index].text += currentParagraph;
    currentLine = "";
    currentParagraph = "";
    breaked = false;
  });

  return pages;
}

export interface PackageType {
  label: string | null
  value: string
  bullet: boolean
  type: string
  score?: string | number | null
}

export enum MotivationTypes {
  text = "text",
  attraction = "attraction",
  repulsion = "repulsion",
  retention = "retention",
}

export function addPaginatePages(pages: any[], path: string, data: any[], emptyValue: any, sequential = false) {
  if (sequential) {
    data.forEach((item, index) => {
      if (index === 0) {
        const lastPageIndex = findLastIndex(pages, () => true);
        set(pages[lastPageIndex], path, item);
      }
      else {
        const newValue = cloneDeep(emptyValue);
        set(newValue, path, item);

        pages.push(newValue);
      }
    });
  }
  else {
    data.forEach((item, index) => {
      if (!pages[index]) {
        const newValue = cloneDeep(emptyValue);
        set(newValue, path, item);

        pages.push(newValue);
      }
      else {
        set(pages[index], path, item);
      }
    });
  }
}

export function getLinesLastPage(lastPage: any, empty_data: any, path: string, box_width: number, font_size: string) {
  let _totalLines = 0;
  if (!empty_data)
    return 0;

  Object.keys(empty_data || {})?.forEach((key) => {
    const _texts = get(lastPage, key, []);
    const _text = _texts
      ?.map((_item) => {
        if (Array.isArray(_item))
          return _item?.map(_item_b => get(_item_b, path, ""));
        else
          return get(_item, path, "");
      })
      ?.join("");
    const _lines = estimatedTextLines(_text || "", font_size, box_width);
    if (_lines > _totalLines)
      _totalLines = _lines;
  });
  return _totalLines;
}

export function removeAccents(str: string): string {
  // eslint-disable-next-line unicorn/escape-case
  return str.trim().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export function normalizeSearch(str: string): string {
  return removeAccents(str.trim()).toLowerCase();
}
export function limitChars(text: string | null | undefined, char: number) {
  if (!text)
    return text;

  return text.length <= char ? text : `${text.substring(0, char)}...`;
}
export function formatPersonName(person: any, limitCharName: number) {
  const personName = person?.mini_cv?.nickname ?? `${person?.person_data?.first_name} ${person?.person_data?.last_name}`;
  return limitChars(personName, limitCharName);
}
export function formatCompanyName(person, limitCharName) {
  const experience = computed(() => person.person_data?.work_experience.sort(sortByStartDate));
  const companyName = person?.mini_cv?.current_company_nickname ?? experience.value[0]?.corporation_name;
  return limitChars(companyName, limitCharName); // Assuming limitChars is correctly defined
}

export function formatRoleName(person: any, limitCharName: number) {
  const experience = computed(() => person.person_data?.work_experience.sort(sortByStartDate));
  const roleName = person?.mini_cv?.current_role_nickname ?? experience.value[0]?.role_name;
  return limitChars(roleName, limitCharName);
}
export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
