<script lang="ts">
import type { Ref } from "vue";
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import get from "lodash-es/get";
import { CalendarInput, FormInput } from "@/components/inputs/modalInputs";
import { getDateRef, watchDate } from "@/utils/dates";
import type { ExtraActivitiesPublications } from "@/entities/Person/extra-activities";

export default defineComponent({
  name: "PublicationDataForm",
  components: {
    FormInput,
    CalendarInput,
  },
  props: {
    publication: Object,
  },
  setup(props) {
    const { t } = useI18n();
    const payload = toRef(props, "publication") as Ref<ExtraActivitiesPublications>;

    const publishedDate = getDateRef<ExtraActivitiesPublications>("published_date", payload);
    watch(publishedDate, watchDate<ExtraActivitiesPublications>("published_date", payload));

    const rules = computed(() => ({
      payload: {},
    }));

    const v$ = useVuelidate(rules, {
      payload,
    });

    return {
      t,
      payload,
      publishedDate,
      v$,
      get,
    };
  },
});
</script>

<template>
  <div class="extra-activites publication">
    <div class="title-wrapper">
      <span>{{ t("persons.publication") }}</span>
      <slot name="icon" />
    </div>

    <form action="" class="publication-data-form">
      <div class="form-group headline">
        <label>
          {{ t("persons.publicationForm.headline") }}:
        </label>
        <FormInput
          v-model="payload.headline"
          :placeholder="t('persons.publicationForm.placeholders.headline')"
          :error="get(v$, 'payload.headline.$error', false)"
          :error-message="get(v$, 'payload.headline.$errors.0.$message', '')"
        />
      </div>

      <div class="form-group publisher">
        <label>{{ t("persons.publicationForm.publisher") }}:</label>
        <FormInput
          v-model="payload.publisher"
          :placeholder="t('persons.publicationForm.placeholders.publisher')"
        />
      </div>

      <div class="form-group datePublished">
        <label>
          {{ t("persons.publicationForm.datePublished") }}:
        </label>
        <CalendarInput
          v-model="publishedDate"
          :error-message="get(v$, 'publishedDate.$errors.0.$message', '')"
        />
      </div>
    </form>
  </div>
</template>

<style lang="scss" scoped>
:deep(.publication-data-form) {
  .form-group {
    display: flex;
    margin-bottom: 1rem;

    label {
      position: relative;
      bottom: 5px;
      display: flex;
      width: 20%;
      min-width: 114px;
      height: 28px;
      align-items: flex-end;
      color: $gray-dark;
      font-family: "Open Sans";
      font-size: 14px;
      letter-spacing: 0.5px;

      .required {
        color: $red;
      }
    }

    .component-input {
      flex-grow: 1;

      .input-wrapper {
        input {
          font-family: "Open Sans";

          &::placeholder {
            font-size: 13px;
          }
        }
      }
    }

    .calendar-input {
      flex-grow: 1;
    }
  }
}

.title-wrapper {
  display: flex;
  height: 35px;
  justify-content: space-between;

  span {
    color: $primary;
    font-family: "Open Sans";
    font-size: 1rem;
  }
}
</style>
