import { searchFieldService } from "@/http_services/client";

interface ProjectSearchField {

}

// export async function addBusinessFunction(business_function: any) {
//   return activitiesService.post("constructions/business-functions", { business_function })
//     .then(res => res.data?.data);
// }

export async function getSearchField(project_id: string): Promise<{ [key: string]: string[] }> {
  return searchFieldService.get(`search-field/project/${project_id}`)
    .then(res => res.data?.data);
}

// export async function patchBusinessFunctions(business_function: any) {
//   return activitiesService.patch(`constructions/business-functions/${business_function._id}`, { business_function })
//     .then(res => res.data?.data);
// }
// export async function removeBusinessFunction(id: string) {
//   return activitiesService.del(`constructions/business-functions/${id}`)
//     .then(res => res.data?.data);
// }

export async function addSearchField(project_id: string, term: string) {
  return searchFieldService.post(`search-field/${project_id}?term=${term}`, {})
    .then(res => res.data?.data);
}

export async function removeSearchField(project_id: string, term: string) {
  return searchFieldService.post(`search-field/${project_id}/remove?term=${term}`, {})
    .then(res => res.data?.data);
}

export async function removeSearch(project_id: string, data: { term: string | null; search: string | null }) {
  return searchFieldService.post(`search-field/${project_id}/remove`, data)
    .then(res => res.data?.data);
}
