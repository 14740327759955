<script lang="ts">
import type { Ref } from "vue";
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import get from "lodash-es/get";
import { FormInput, Select, TextArea } from "@/components/inputs/modalInputs";
import type { ExtraActivitiesAwards } from "@/entities/Person/extra-activities";
import { months, parseAndGetDates, watchDatesAndSetInObj, years } from "@/utils/dates";

export default defineComponent({
  name: "AwardDataForm",
  components: {
    FormInput,
    Select,
    TextArea,
  },
  props: {
    award: Object,
  },
  setup(props) {
    const { t } = useI18n();
    const payload = toRef(props, "award") as Ref<ExtraActivitiesAwards>;

    const { awardMonth, awardYear } = parseAndGetDates<ExtraActivitiesAwards>(
      "award_date",
      payload,
    )();
    watch(
      [awardMonth, awardYear],
      watchDatesAndSetInObj<ExtraActivitiesAwards>("award_date", payload),
    );

    const rules = computed(() => ({
      payload: {},
    }));

    const v$ = useVuelidate(rules, {
      payload,
    });

    return {
      t,
      payload,
      awardMonth,
      awardYear,
      months,
      years,
      v$,
      get,
    };
  },
});
</script>

<template>
  <div class="extra-activities award">
    <div class="title-wrapper">
      <span>{{ t("persons.award") }}</span>
      <slot name="icon" />
    </div>

    <form action="" class="award-data-form">
      <div class="form-group title">
        <label>
          {{ t("persons.awardForm.title") }}:
        </label>
        <FormInput
          v-model="payload.title"
          :placeholder="t('persons.awardForm.placeholders.title')"
          :error="get(v$, 'payload.title.$error', false)"
          :error-message="get(v$, 'payload.title.$errors.0.$message', '')"
        />
      </div>

      <div class="form-group relatedPosition">
        <label>{{ t("persons.awardForm.relatedPosition") }}:</label>
        <FormInput
          v-model="payload.related_position"
          :placeholder="t('persons.awardForm.placeholders.relatedPosition')"
        />
      </div>

      <div class="form-group awardDate">
        <label>
          {{ t("persons.awardForm.awardDate") }}:
        </label>

        <div class="select-group">
          <Select
            v-model="awardMonth"
            :options="months"
            class="select-list"
            :placeholder="t('persons.awardForm.placeholders.month')"
            :error="get(v$, 'awardMonth.$error', false)"
            :error-message="get(v$, 'awardMonth.$errors.0.$message', '')"
          />
          <Select
            v-model="awardYear"
            class="select-list"
            :options="years"
            :placeholder="t('persons.awardForm.placeholders.year')"
            :error="get(v$, 'awardYear.$error', false)"
            :error-message="get(v$, 'awardYear.$errors.0.$message', '')"
          />
        </div>
      </div>

      <div class="form-group textarea-wrapper">
        <TextArea
          v-model="payload.description"
          rows="4"
          :placeholder="t('persons.awardForm.placeholders.description')"
        />
      </div>
    </form>
  </div>
</template>

<style lang="scss" scoped>
.award-data-form:deep {
  .form-group {
    display: flex;

    label {
      position: relative;
      bottom: 5px;
      display: flex;
      width: 20%;
      min-width: 114px;
      height: 28px;
      align-items: flex-end;
      color: $gray-dark;
      font-family: "Open Sans";
      font-size: 14px;
      letter-spacing: 0.5px;
      white-space: pre;

      .required {
        color: $red;
      }
    }

    .component-input {
      width: 100%;
      flex-grow: 1;

      .input-wrapper {
        input {
          font-family: "Open Sans";

          &::placeholder {
            font-size: 13px;
          }
        }
      }
    }
  }

  .textarea-abstract {
    width: 100%;
    padding: 1rem;
    border: 1px solid $gray-border;
    border-radius: 6px;
    color: gray;
    font-family: "Open Sans";
    outline: none;
    resize: none;

    &::placeholder {
      color: $gray-medium;
    }
  }

  .select-group {
    display: flex;
    width: 100%;

    .select-list {
      width: 100%;

      .multiselect {
        min-height: 30px;

        .multiselect-dropdown {
          overflow-y: scroll;
        }
      }
    }

    .select-list:nth-of-type(2) {
      margin-left: 2rem;
    }
  }
}

.title-wrapper {
  display: flex;
  height: 35px;
  justify-content: space-between;

  span {
    color: $primary;
    font-family: "Open Sans";
    font-size: 1rem;
  }
}
</style>
