<script setup lang="ts">
import { useStore } from "vuex";
import { Loading } from "@/components/loading";

const store = useStore();
const loadingMessage = computed(() => store.getters.GET_FULL_PAGE_LOADING_MESSAGE);
</script>

<template>
  <main id="default-layout">
    <header>header</header>
    <Suspense>
      <RouterView />
    </Suspense>
    <footer>default layout</footer>
    <div v-if="loadingMessage" class="loading-wrapper">
      <div class="loading-content">
        <Loading :warning="true" class="loader" />
        <p class="loading-message">
          {{ loadingMessage }}
        </p>
      </div>
    </div>
  </main>
</template>

<style scoped lang="scss">
main {
  background: $white-background;
}
footer {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 80px;
    outline: 1px solid #ccc;
}
header {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 110px;
    outline: 1px solid #ccc;
    z-index: 2;
}

.loading-wrapper {
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(0 0 0 / 70%);

  .loader {
    svg {
      width: 60px;
      height: 60px;
    }
  }

  @keyframes blink {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0.4;
    }
  }

  .loading-message {
    margin-top: 25px;
    animation: blink 800ms infinite alternate linear;
    color: #ffbd00;
  }

  .loading-content {
    text-align: center;
  }
}
</style>
