<script lang="ts">
import type { PropType } from "vue";
import {
  computed,
  defineComponent,
  ref,
} from "vue";
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { helpers, maxLength } from "@vuelidate/validators";
import { SectionTitle } from "@/components/section_title";
import { SectionContent } from "@/components/section_content";
import { FormInput, TextArea } from "@/components/inputs/modalInputs";
import { Button2 } from "@/components/button";

interface historic {
  foundation_year: string
  historic: string
}

const mustHave4Numbers = (value: string): boolean => !helpers.req(value) || /^\d{4}$/g.test(value);

export default defineComponent({
  name: "HistoricSection",
  components: {
    SectionTitle,
    SectionContent,
    FormInput,
    TextArea,
    Button: Button2,
  },
  props: {
    historic: {
      type: Object as PropType<historic>,
      required: true,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    noEdit: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["submit:historic", "click:edit"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const payload = ref({ ...(props.historic as historic) });

    const rules = {
      foundation_year: {
        mustHave4Numbers: helpers.withMessage(
          t("corporations.details.validations.yearWith4Numbers"),
          mustHave4Numbers,
        ),
      },
      historic: {
        maxLength: maxLength(650),
      },
    };

    const validator = useVuelidate(rules, payload);

    const errors = computed(() => ({
      foundation_year: validator.value.foundation_year.$errors,
      historic: validator.value.historic.$errors,
    }));

    const submit = () => emit("submit:historic", payload.value);

    return {
      t,
      validator,
      errors,
      submit,
    };
  },
});
</script>

<template>
  <section class="history-section">
    <SectionTitle
      :title="t('corporations.details.historic')"
      :is-editing="noEdit || isEditing"
      @click:edit="$emit('click:edit')"
    />

    <div class="section-content">
      <template v-if="!isEditing">
        <SectionContent>
          <p class="format">
            <b>{{ t("corporations.details.foundationYear") }}: </b>
            {{ historic.foundation_year }}
            <br>
            {{ historic.historic }}
          </p>
        </SectionContent>
      </template>

      <template v-else>
        <SectionContent>
          <form @submit.prevent="submit()">
            <div class="form-group input">
              <b>{{ t("corporations.details.foundationYear") }}: </b>
              <FormInput
                v-model="validator.foundation_year.$model"
                :placeholder="new Date().getFullYear()"
                :error-message="
                  errors.foundation_year.length ? errors.foundation_year[0].$message : ''
                "
              />
            </div>

            <div class="form-group">
              <TextArea
                v-model="validator.historic.$model"
                :placeholder="t('corporations.details.placeholders.history')"
                :warning-chars="650"
              />
            </div>

            <div class="form-actions">
              <Button type="submit" :disabled="validator.$invalid">
                {{ t("corporations.details.submit") }}
              </Button>

              <Button variation="secondary" no-border @click="$emit('click:editCancel')">
                {{ t("corporations.details.cancel") }}
              </Button>
            </div>
          </form>
        </SectionContent>
      </template>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.history-section {
  .form-group {
    margin-bottom: 0.5rem;

    &.input:deep {
      display: inline-grid;
      align-items: center;
      margin-bottom: 1rem;
      grid-gap: 0.5rem;
      grid-template-columns: repeat(2, auto);

      .component-input {
        margin-bottom: 0;
      }
    }
  }

  .form-actions {
    display: grid;
    padding-right: 45%;
    margin-top: 2rem;
    grid-gap: 5rem;
    grid-template-columns: repeat(2, 1fr);
  }

  .format {
    line-height: 1.35rem;
    white-space: pre-line;
  }
}
</style>
