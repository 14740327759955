import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import type { MaybeRef } from "@vueuse/core";
import type { Project } from "@/types/project";

export function useProject(id: MaybeRef<string>) {
  return useQuery({
    queryKey: ["projects", unref(id)],
    queryFn: () => getProject(unref(id)),
  });
}

export function useProjectsAllQuery() {
  return useQuery({ queryKey: ["projects"], queryFn: () => getProjects() });
}
export function useProjectMutation(id: string) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["projects", unref(id)],
    mutationFn: (id: string) => getProject(unref(id)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["projects", unref(id)] });
    },
  });
}

export function updateProjectMutation(id: string) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["projects", id],
    mutationFn: (project: Project) => updateProject(id, project),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["projects", id] });
    },
  });
}

export function useProjectsSearchAllQuery(params: any) {
  return useQuery({
    queryKey: ["projects", params],
    queryFn: () => (searchProjects(params)),
  });
}

export function useProjectStageFinishConstruction(id: string) {
  const queryClient = useQueryClient();

  return useMutation({
    // TODO: unref if type is string, or type is wrong or unref is uncessary
    mutationKey: ["projects", unref(id)],
    mutationFn: (id: string) => stageFinishConstruction(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["projects", unref(id)] });
    },
  });
}

export function useProjectStageConstructionToMapping(id: string) {
  const queryClient = useQueryClient();

  return useMutation({
    // TODO: unref if type is string, or type is wrong or unref is uncessary
    mutationKey: ["projects", unref(id)],
    mutationFn: (id: string) => stageConstructionToMapping(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["projects", unref(id)] });
    },
  });
}

export function useProjectStageMappingToScreening(id: string) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["projects", unref(id)],
    mutationFn: (id: string) => stageMappingToScreening(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["projects", unref(id)] });
    },
  });
}

export function useProjectStageScreeningToInterview(id: string) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["projects", unref(id)],
    mutationFn: (id: string) => stageScreeningToInterview(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["projects", unref(id)] });
    },
  });
}

export function useProjectInterviewToShortlist(id: string) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["projects", unref(id)],
    mutationFn: (id: string) => stageInterviewToShortlist(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["projects", unref(id)] });
    },
  });
}

export function useProjectShortlistToUnderoffer(id: string) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["projects", unref(id)],
    mutationFn: (id: string) => stageShortlistToUnderoffer(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["projects", unref(id)] });
    },
  });
}

export function useProjectUnderofferToPlacement(id: string) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["projects", unref(id)],
    mutationFn: (id: string) => stageUnderofferToPlacement(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["projects", unref(id)] });
    },
  });
}

export function useProjectCounters(id: string) {
  return useQuery({
    queryKey: ["projects", id, "counters"],
    queryFn: () => getProject(id),
    select: data => Object.keys(data.stages).reduce((acc: any, stage: any) => {
      acc[stage] = data.stages[stage].count;
      return acc;
    }, {}),
  });
}

export function useFinishProject(id: string) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["projects", unref(id)],
    mutationFn: (id: string) => stageFinishProject(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["projects", unref(id)] });
    },
  });
}
