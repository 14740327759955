export function formatUrlParams(query: { [key: string]: any }) {
  const params = new URLSearchParams();
  Object.entries(query).forEach(([key, value]) => {
    if (Array.isArray(value))
      value?.forEach((item: string) => params.append(key, item));
    else if (value !== undefined)
      params.append(key, value);
  });
  return params;
}
