<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import debounce from "lodash-es/debounce";
import SearchLogo from "@/assets/images/search_icon.svg";

export default defineComponent({
  name: "SearchInput",
  components: {
    SearchLogo,
  },
  props: {
    placeholder: String,
  },
  emits: ["search", "search:error", "search:clear"],
  setup(_, context) {
    const { t } = useI18n();
    const content = ref("");

    // TODO: refact this to use rxjs buffercount
    const search = (value) => {
      context.emit("search:clear");

      if (!value?.length || value?.length >= 2)
        context.emit("search", value);
      else if (value?.length)
        context.emit("search:error");
    };

    const debouncedSearch = debounce(search, 1000);

    watch(content, (value) => {
      debouncedSearch(value);
    });

    return { t, content };
  },
});
</script>

<template>
  <div class="search">
    <input
      v-model="content"
      type="text"
      name="search"
      autocomplete="off"
      :placeholder="placeholder || t('input.placeholder.findPeopleAndCompany')"
    >
    <SearchLogo class="search-icon" />
  </div>
</template>

<style lang="scss" scoped>
.search {
  display: flex;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  align-items: center;
  border: 1px solid $gray-light;
  border-radius: 10px;

  input {
    height: 100%;
    flex-grow: 1;
    border: none;
    margin-left: 10px;
    background: transparent;
    color: $gray-dark;
    font-size: 14px;
    outline: none;

    &::placeholder {
      color: $gray-medium;
      opacity: 1;
    }
  }

  .search-icon {
    color: $primary;
  }

  svg {
    &:hover {
      cursor: pointer;
    }

    margin-right: 5px;
  }
}
</style>
