<script setup lang="ts">
import { Card } from "@/components/card";

defineProps<{
  analyses: string[]
  opinion?: string
}>();

function formatBreakLines(text: any) {
  if (!text)
    return text;
  return text.replaceAll("\n", "<br />");
}
</script>

<template>
  <div>
    <ListContent :list="analyses" />
    <Card v-if="opinion && opinion.trim() !== ''" class="simple-list__opinion">
      <img
        class="simple-list__opinion--logo"
        src="@/assets/images/flow.png"
        alt="flow logo"
      >
      <p v-html="formatBreakLines(opinion)" />
    </Card>
  </div>
</template>

<style scoped lang="scss">
.simple-list {
  $li-padding: 1rem;
  $half-padding: calc($li-padding / 2);

  padding-bottom: 1.5rem;

  &__list {
    padding-bottom: 0;
    margin-block-end: 3rem;

    li {
      position: relative;
      overflow: hidden;
      max-height: 3.6em;
      margin-bottom: 1rem;
      color: $gray-lower;
      letter-spacing: 0.56px;
      line-height: 1.2em;
      padding-inline-start: $li-padding;
      text-indent: $li-padding;
      word-wrap: break-word;

      &::before {
        position: absolute;
        top: 0;
        left: $li-padding;
        display: inline-block;
        width: $half-padding;
        height: $half-padding;
        border-radius: 50%;
        background-color: $yellow;
        content: "";
        transform: translate(0, 50%);
      }
    }
  }

  &__opinion {
    position: relative;
    background-color: $white-background;
    line-height: 1.4;
    padding-inline: 2.75rem;
    line-break: anywhere;

    &--logo {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-25%, -25%);
    }
  }
}
</style>
