<script lang="ts">
import type { Ref } from "vue";
import { defineComponent, inject, ref, toRef } from "vue";
import { useI18n } from "vue-i18n";
import { debounce, head, uniq } from "lodash";
import { useRouter } from "vue-router";
import ProfileList from "./ProfileList.vue";
import { FormInput } from "@/components/inputs";
import { Sidebar } from "@/components/sidebar";
import { Button as ButtonText } from "@/components/button";
import { searchPersons } from "@/http_services/entities/persons";
import { Loading } from "@/components/loading";
import type { Person } from "@/http_services/entities/types";

export default defineComponent({
  components: {
    Sidebar,
    ProfileList,
    ButtonText,
    FormInput,
    Loading,
  },
  props: {
    visible: Boolean,
    onClose: Function,
  },
  setup(props) {
    const { t } = useI18n({ useScope: "global" });
    const onClose = toRef(props, "onClose") as Ref<() => void>;
    const selectedId = ref();
    const persons = ref([]) as Ref<Person[]>;
    const total = ref();
    const skip = ref(0);
    const router = useRouter();
    const searchedResult = ref(false);
    const loading = ref(false);
    const mailNoExist = ref(true);
    const onShowDetails: any = inject("onShowDetails");

    const nameInput = ref<InstanceType<typeof FormInput>>(null);
    const nameContent = ref("");

    const emailInput = ref<InstanceType<typeof FormInput>>(null);
    const emailContent = ref("");

    const makeRequest = (email: string, name: string) => searchPersons(
      {
        skip: skip.value,
      },
      email,
      name,
    );

    const doSearch = debounce(async () => {
      if (emailContent.value !== "" || nameContent.value !== "") {
        let response = [];

        if (emailContent.value !== "") {
          const { data } = await makeRequest(emailContent.value, undefined);
          response = data;
        }

        if (nameContent.value !== "" && response.length === 0) {
          const { data } = await makeRequest(undefined, nameContent.value);
          response = data;
        }

        persons.value = uniq([...persons.value, ...response]);
        for (const item of persons.value) {
          if (emailContent.value === head(item.email_addresses)?.email_address)
            mailNoExist.value = false;
          else mailNoExist.value = true;
        }

        searchedResult.value = true;
      }
      else {
        searchedResult.value = false;
        persons.value = [];
      }

      skip.value = persons.value.length;
      total.value = persons.value.length;
      loading.value = false;
    }, 500);

    const resetParams = () => {
      selectedId.value = undefined;
      skip.value = 0;
      persons.value = [];
      loading.value = false;
    };
    const newSearch = (event: KeyboardEvent) => {
      const key = event.keyCode || event.charCode;

      // 48 ... 90 - letters and numbers
      // 96 ... 105 - numbers in numpad
      // 8 - backspace
      // 13 - enter
      if ((key >= 48 && key <= 90) || key === 8 || key === 13 || (key >= 96 && key <= 105)) {
        resetParams();
        loading.value = true;

        doSearch();
      }
    };

    const onPersonClick = (person: Person) => {
      selectedId.value = person.id;
    };

    const editPerson = () => {
      const selectedPerson = persons.value.find((person: Person) => person.id === selectedId.value);
      if (selectedPerson)
        onShowDetails(selectedPerson);
    };

    const newAnalysis = () => {
      persons.value = [];
      selectedId.value = undefined;
      total.value = persons.value.length;
      skip.value = persons.value.length;
      searchedResult.value = false;
      nameInput.value.setContent("");
      emailInput.value.setContent("");
    };
    const createNewProfile = () => {
      let queryParams = "?";
      if (emailContent.value)
        queryParams += `email=${emailContent.value}`;

      if (nameContent.value) {
        if (queryParams.length > 1)
          queryParams += "&";

        queryParams += `name=${nameContent.value}`;
      }
      router.push(`/persons/create${queryParams}`);
    };

    const resetAndClose = () => {
      resetParams();
      onClose.value();
    };

    return {
      t,
      editPerson,
      onShowDetails,
      newAnalysis,
      createNewProfile,
      onPersonClick,
      selectedId,
      persons,
      nameInput,
      emailInput,
      doSearch,
      newSearch,
      searchedResult,
      total,
      loading,
      mailNoExist,
      resetAndClose,
      nameContent,
      emailContent,
    };
  },
});
</script>

<template>
  <Sidebar class="sidebar" :on-close="resetAndClose" :visible="visible">
    <h2>{{ t("persons.checkConflict.createPerson") }}</h2>

    <div class="input-group">
      <FormInput
        v-model="nameContent"
        class="input"
        :placeholder="t('persons.checkConflict.fullName')"
        :on-key-down="newSearch"
      />
      <FormInput
        v-model="emailContent"
        class="input"
        :placeholder="t('persons.checkConflict.email')"
        :on-key-down="newSearch"
      />

      <p v-if="total && searchedResult && !loading" class="response-text">
        {{ total }} {{ t("persons.checkConflict.profilesFound") }}
      </p>
    </div>

    <Loading v-if="loading" class="loader" />

    <template v-else>
      <ProfileList v-if="total" :persons="persons" :on-person-click="onPersonClick" :load-more-data="doSearch" />

      <div v-if="selectedId" class="action-group">
        <div class="rework-actions">
          <ButtonText
            :text="t('persons.checkConflict.linkAndEdit')"
            :filled="false"
            variation="warning"
            :on-click="editPerson"
          />
          <ButtonText
            :text="t('persons.checkConflict.newAnalysis')"
            :filled="false"
            variation="regular"
            :on-click="newAnalysis"
          />
        </div>
      </div>

      <div v-if="total" class="continue-action">
        <ButtonText
          v-show="mailNoExist"
          :text="t('persons.checkConflict.createNewProfile')"
          :filled="false"
          variation="warning"
          :on-click="createNewProfile"
        />
      </div>

      <div v-else-if="searchedResult" class="not-found">
        <p class="response-text">
          {{ t("persons.checkConflict.notFound") }}
        </p>

        <div class="continue-action">
          <ButtonText
            :text="t('persons.checkConflict.createNewProfile')"
            :filled="false"
            variation="warning"
            :on-click="createNewProfile"
          />
        </div>
      </div>
    </template>
  </Sidebar>
</template>

<style scoped="true" lang="scss">
.sidebar {
  &:deep .sidebar-wrapper {
    width: 500px;
    min-width: initial;
  }
}

.check-content {
  width: 400px;
}

h2 {
  color: $primary;
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}

.rework-actions {
  display: flex;
  justify-content: space-between;

  button:first-child {
    margin-right: 2rem;
  }
}

.rework-actions {
  margin-top: 4rem;
}

.continue-action {
  margin-top: 1rem;
}

.input-group {
  min-width: 350px;
  margin-top: 2rem;

  .input {
    width: 100%;
  }
}

.response-text {
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: $gray-dark;
  font-size: 13px;
  text-align: center;
}

.not-found {
  .response-text {
    margin-top: 0;
    font-size: 10px;
  }
}

.not-found-action {
  width: 45%;
  margin-right: auto;
  margin-left: auto;
}

.loader {
  display: flex;
  align-self: center;
}
</style>
