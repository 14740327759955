<script setup lang="ts">
interface OptionsSchema {
  label: string
  value: string | number | null
}

const props = withDefaults(
  defineProps<{
    options: OptionsSchema[] | null
    showLabels?: boolean
    description?: string
    controlValue?: string | number | null
    controlTitle?: string
    selectedValue?: string | number | null
    selectedTitle?: string
    background?: string
    selectedColor?: string
    selectedMarkerColor?: string
    showSelectedMarker?: boolean
    showControlMarker?: boolean
    controlColor?: string
    controlMarkerColor?: string
  }>(),
  {
    options: null,
    showLabels: true,
    controlValue: null,
    selectedValue: null,
    background: "white-background",
    selectedColor: "yellow",
    selectedMarkerColor: "yellow-shadow-dark",
    controlColor: "yellow-jasmine",
    controlMarkerColor: "gray-shade-dark",
    showSelectedMarker: false,
    showControlMarker: true,
  },
);

const {
  options,
  showLabels,
  controlValue,
  controlTitle,
  selectedValue,
  selectedTitle,
  background,
  selectedColor,
  selectedMarkerColor,
  controlColor,
  controlMarkerColor,
  description,
  showSelectedMarker,
  showControlMarker,
} = toRefs(props);

const maxLevel = options.value?.length || 1;
const percent = 100 / maxLevel;
const colEmpty = percent / 2;

function checkPosition(index: number, marker: boolean): number {
  const isMaxLevel = index + 1 === maxLevel;
  return marker ? (isMaxLevel ? 0.7 : colEmpty) : isMaxLevel ? 0 : colEmpty;
}

function getOption(value: any, maker = false): { progress: string; data?: any } {
  let data = { progress: "0" };
  options.value?.forEach((item, index) => {
    if (item.value === value) {
      data = {
        progress: `${percent * (index + 1) - checkPosition(index, maker)}`,
        data: item,
      };
    }
  });
  return data;
}

const labelValueWidth = computed(() => {
  return {
    width: `${percent}%`,
  };
});

const selectedValueWidth = computed(() => {
  const progress = Number.parseFloat(getOption(selectedValue?.value)?.progress) || 0;
  return {
    width: `${progress}%`,
  };
});

const selectValueMarginLeft = computed(() => ({
  "margin-left": `${Number.parseFloat(getOption(selectedValue?.value, true)?.progress || "0")}%`,
}));

const controlValueWidth = computed(() => {
  const progress = Number.parseFloat(getOption(controlValue?.value)?.progress) || 0;
  return {
    width: `${progress}%`,
  };
});

const controlValueMarginLeft = computed(() => ({
  "margin-left": `${Number.parseFloat(getOption(controlValue?.value, true)?.progress || "0")}%`,
}));
</script>

<template>
  <div class="slider-view">
    <div v-if="showLabels" class="box-labels">
      <div v-for="(item, index) in options" :key="index" :style="labelValueWidth" class="item">
        {{ item.label }}
      </div>
    </div>
    <div class="box-values">
      <div
        class="selected-value"
        :class="[`--bg-${selectedColor}`]"
        :title="selectedTitle"
        :style="selectedValueWidth"
      />
      <div class="control-value" :class="[`--bg-${controlColor}`]" :style="controlValueWidth" />
      <div
        v-if="showSelectedMarker && selectedValueWidth.width !== '0%'"
        class="selected-marker"
        :class="[`--bg-${selectedMarkerColor}`]"
        :style="selectValueMarginLeft"
        :title="selectedTitle"
      />
      <div
        v-if="showControlMarker && controlValueWidth.width !== '0%'"
        class="control-marker"
        :class="[`--bg-${controlMarkerColor}`]"
        :style="controlValueMarginLeft"
        :title="controlTitle"
      />
      <div class="bg" :class="[`--bg-${background}`]" />
    </div>
    <div v-if="description" class="box-description">
      {{ description }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.slider-view {
  width: 100%;

  .box-labels {
    display: flex;
    flex-direction: row;

    .item {
      display: flex;
      justify-content: center;
      color: $gray-lower;
      font-size: 1rem;
    }

    .item.end {
      justify-content: flex-end;
      padding-right: 10px;
    }
  }

  .box-values {
    display: flex;
    width: 100%;
    height: 25px;
    flex-direction: column;
    justify-content: center;
    margin-top: 17px;

    .bg,
    .selected-value,
    .control-value {
      position: relative;
      height: 20px;
      margin-top: -20px;
      border-bottom-left-radius: 10px;
      border-top-left-radius: 10px;
    }

    .selected-value {
      z-index: 2;
    }

    .control-value {
      position: relative;
      z-index: 1;
    }

    .bg {
      position: relative;
      z-index: 0;
      width: 100%;
      border-radius: 10px;
    }

    .control-marker,
    .selected-marker {
      position: relative;
      z-index: 3;
      width: 5px;
      height: 25px;
      margin-top: -23px;
      margin-bottom: -2px;
      background: $gray-shade-dark;
    }

    .control-marker {
      background: $gray-shade-dark;
    }

    .selected-marker {
      background: $yellow;
    }
  }

  .box-description {
    margin-left: 10px;
    font-size: 0.75rem;
    line-height: 1.5;
  }
}

@mixin slider-change($colors-map) {
  @each $key, $value in $colors-map {
    .slider-view {
      .box-values {
        .bg.--bg-#{$key} {
          background: $value;
        }

        .control-value.--bg-#{$key} {
          background: $value;
        }

        .control-marker.--bg-#{$key} {
          background: $value;
        }

        .selected-value.--bg-#{$key} {
          background: $value;
        }

        .selected-marker.--bg-#{$key} {
          background: $value;
        }
      }
    }
  }
}

@include slider-change($all-colors);
</style>
