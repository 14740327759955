export const login = {
  welcome: "Welcome",
  passwordConfirmation: "Confirm password",
  passwordNew: "Password",
  charactersQuantity: "{quantity} characters",
  letterQuantity: "{quantity} letter",
  numberQuantity: "{quantity} number",
  specialCharQuantity: "{quantity} special character (#&*)",
  submitButton: "Login",
  loginError: "Invalid e-mail or password",
  forgotPassword: "Forgot password?",
  definePassword: {
    title: "Define your password",
    createSuccess: "Your password was defined!",
    matchError: "Password and confirmation should be the same",
    passwordAlreadyDefined: "Password already defined",
    requestError: "An error occurred. Try again later.",
    requestTokenError: "An error occurred. Try again later.",
  },
  logout: "Logout",
};
