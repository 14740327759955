<script setup lang="ts">
import { format, parseISO } from "date-fns";
import { mdiPlusBox } from "@mdi/js";
import { SvgIcon } from "@/components/icon";
import ActivitiesSchedule from "@/common/components/Export/ActivitiesSchedule.vue";
import { ButtonSpan } from "@/components/button";
import { Avatar } from "@/components/avatar";

const props = defineProps<{ id: string }>();
const router = useRouter();
const creating = ref(false);
const { t, tm } = useI18n();

const activitiesEnum = computed(() => tm("projects.details.activities.enum"));

const {
  data: project,
} = useProject(props.id);

const {
  data: activities,
  error,
  isLoading: loading,
  isFetched,
  refetch: fetchActivities,
} = useProjectActivitiesQuery({ project_id: props.id });

const {
  data: recruiter,
} = await usePersonMe();

const {
  mutate: createProjectActivities,
} = useCreateConstructionActivitiesQuery();

const {
  mutate: createConstructionOrganization,
} = useCreateConstructionOrganizationQuery();

const {
  setBlocked,
  stageTabs,
} = useTabsProject(props.id);

const currentRoute = computed(() => stageTabs.value.findIndex(tab => tab.key === router.currentRoute.value.meta.tab));

const {
  capture,
} = useTracking();

function createActivity(data: any) {
  try {
    createProjectActivities({
      ...data,
      project_id: props.id,
    }, {
      onSuccess: () => {
        capture("imua-project-stage-updated", {
          project_id: props.id,
          from: "",
          to: "construction",
        });
      },
    });

    toggleCreate();
  }
  catch (errorCreateActivity) {
    console.log("error-create-activity", errorCreateActivity);
  }
}

function goToStage(tabIndex: number) {
  if (!stageTabs.value || !stageTabs.value[tabIndex])
    return;

  router.push(stageTabs.value[tabIndex].path);
}

function goToActivity(id: string) {
  router.push(`/projects/${props.id}/construction/activities/${id}`);
}

function toggleCreate() {
  creating.value = !creating.value;
}

function formatDate(date: string | null | undefined) {
  if (!date)
    return "";
  const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/;
  if (dateRegex.test(date))
    return date;

  return format(parseISO(date), "dd/MM/yyyy");
}

const route = useRoute();

const {
  mutate: updateProject,
} = updateProjectMutation(String(route.params.id));

const creatingSchedule = ref(false);
const dropdown = ref({ active: false });

const hasSchedule = computed(() => {
  if (!project.value?.schedules || !Object.values(project.value?.schedules).filter(Boolean).length)
    return false;
  const scheduleKeys = Object.values(project.value?.schedules || {});
  return scheduleKeys.filter(data => Object.values(data).filter(Boolean)).length > 0;
});

function submitSchedule(schedules) {
  updateProject({
    ...project.value,
    schedules,
  });
  creatingSchedule.value = false;
}

onMounted(() => {
  fetchActivities();
});

const editingDate = ref<{ [id: string]: boolean }>({});
const constructionPresentationDate = ref();

function formatLocale(date: Date | string | null | undefined) {
  return date ? new Date(date).toLocaleDateString() : null;
}

watchEffect(() => {
  if (activities.value)
    setBlocked((activities.value?.length === 0));
  if (project.value)
    constructionPresentationDate.value = project.value?.construction_presentation_date ? formatLocale(project.value?.construction_presentation_date) : null;
});

function submitPresentationDate(date: Date) {
  updateProject({
    ...project.value,
    construction_presentation_date:
    date
      ? new Date(date).toISOString()
      : null,
  });
  constructionPresentationDate.value = formatLocale(date);
  Object.keys(editingDate.value).forEach((id) => {
    editingDate.value[id] = false;
  });
}
</script>

<template>
  <section class="project-activities">
    <div v-if="error">
      could not load
    </div>
    <div v-if="isFetched" class="project-container" data-testid="project-activities">
      <ProjectStages :id="props.id" />
      <ProjectTabs :stage-tabs="stageTabs" :active="currentRoute" class="tabs" @change="goToStage($event)" />
      <div class="project-wrapper">
        <div v-if="!creating" class="project-header">
          <div class="title">
            {{ t("projects.construction.titles.construction") }}
          </div>
          <div class="actions">
            <Button class="button-pluss" variation="primary" @click="toggleCreate()">
              <SvgIcon icon="plus" margin="none" width="1.25rem" height="1.25rem" />
              {{ t("text.form.addElement") }}
            </Button>
          </div>
        </div>
        <div v-else class="project-form">
          <div class="project-form-title">
            {{ t("projects.details.activities.activityData") }}
          </div>
          <CreateActivity
            :options="activitiesEnum"
            :recruiter="recruiter?.data"
            type="construction"
            @submit="createActivity"
            @cancel="creating = false"
          />
        </div>
        <div class="project-body">
          <div v-if="activities" class="list-activities">
            <div class="list-activities-labels">
              <div>
                {{ t("text.date") }}
              </div>
              <div>
                {{ t("text.name") }}
              </div>
              <div>
                {{ t("text.activitie") }}
              </div>
              <div class="status">
                {{ t("text.presentationDate") }}
              </div>
              <div class="actions" />
            </div>
            <div v-for="activity in activities" :key="activity._id" class="list-activities-candidate">
              <div>
                {{ formatDate(activity?.activity_date) }}
              </div>
              <div style="display: flex; align-content: center; gap: 1rem">
                <Avatar :src="activity?.recruiter?.avatar_url" size="30px" image-size="small" />
                {{ activity?.recruiter?.first_name }} {{ activity?.recruiter?.last_name }}
              </div>
              <div>
                {{ t(`projects.details.activities.enum.${activity.description}`) }} ({{
                  `${activity?.requester?.first_name} ${activity?.requester?.last_name}` }})
              </div>
              <PresentationInputDateEditable
                :model-value="formatDate(constructionPresentationDate)"
                @submit="submitPresentationDate($event)"
              />
              <div class="actions">
                <Button variation="secondary" @click.self="goToActivity(activity?._id)">
                  {{ t("projects.details.activities.goToMeeting") }}
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div class="activities-tab__schedule-header">
          <div class="subtitle">
            {{ t("projects.details.activities.schedule.title") }}
          </div>

          <div v-if="project" class="position-relative">
            <Button
              class="align-center px-1"
              :variation="dropdown.active ? 'primary' : 'secondary'"
              @click="dropdown.active = !dropdown.active"
            >
              <SvgIcon icon="calendar2_icon" width="20" height="20" margin="none" />
              <SvgIcon
                icon="chevron_icon"
                width="20"
                height="20"
                margin="none"
                :rotate="dropdown.active ? '180deg' : '0'"
              />
            </Button>

            <div v-show="dropdown.active" class="dropdown">
              <ButtonSpan
                :icon="{ type: 'mdi', path: mdiPlusBox }"
                :label="t('projects.details.activities.schedule.create')"
                variation="default"
                class="add-schedule"
                :disabled="hasSchedule"
                @click="(creatingSchedule = true), (dropdown.active = false)"
              />

              <ButtonSpan
                icon="edit2_icon"
                :label="t('projects.details.activities.schedule.edit')"
                variation="default"
                class="add-schedule"
                :disabled="!hasSchedule"
                @click="(creatingSchedule = true), (dropdown.active = false)"
              />
            </div>
          </div>
        </div>

        <div v-if="project">
          <Transition name="slide-fade">
            <ActitivieScheduleForm
              v-if="creatingSchedule"
              :schedules="project.schedules"
              @cancel="creatingSchedule = false"
              @submit="submitSchedule"
            />
          </Transition>
          <ActivitiesSchedule
            v-if="!creatingSchedule && hasSchedule"
            :schedules="project.schedules"
            :stages="project.stages"
            stage="construction"
          />
          <Checkpoint v-if="hasSchedule" :schedules="project.schedules" :stages="project.stages" stage="construction" />
        </div>
      </div>
    </div>
  </section>
</template>

<route lang="yaml">
meta:
  layout: project-stage
  stage: construction
  tab: activities
</route>

<style scoped lang="scss">
.project-activities {
  min-height: 100vh;
}

.project-container {
  padding-top: 3.75rem;
}

.project-wrapper {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  padding-block-end: 2.5rem;
  background: $white-full;

  .project-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid $gray-border;
    padding-block: 1.3125rem;
    margin-block-end: 1.3125rem;

    .title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: $gray-dark;
      font-size: 1.125rem;
      font-weight: bold;
      text-transform: uppercase;
    }

    .button-pluss {
      display: flex;
      flex-direction: row;
      gap: 0.25rem;
    }
  }

  .project-form {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid $gray-border;
    padding-block-end: 1.3125rem;
    margin-block-end: 1.3125rem;
    padding-inline-end: 35%;

    &-title {
      padding-block: 1rem;
      color: $primary;
      font-size: 1.125rem;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 0.63px;
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    &-actions {
      display: flex;
      flex-direction: row;
      gap: 1rem;
    }
  }

  .project-body {
    display: flex;
    flex-direction: column;
    padding-block-end: 1rem;

    .list-activities {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .status {
        display: flex;
        width: 20rem;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
      }

      .actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
      }

      &-title {
        color: $gray-dark;
        font-size: 1rem;
        font-weight: bold;
        text-transform: capitalize;
        letter-spacing: 1.42px;
        border-bottom: 1px solid $gray-border;
        padding-block: 1.3125rem;
        margin-block-end: 1.3125rem;
        text-transform: uppercase;
      }

      &-labels {
        display: grid;
        grid-template-columns: 1fr 2fr 2fr auto auto;
        gap: 1rem;

        div {
          color: $primary;
          font-size: 0.75rem;
          font-weight: bold;
          text-transform: uppercase;
        }
      }

      &-candidate {
        display: grid;
        grid-template-columns: 1fr 2fr 2fr auto auto;
        align-items: center;
        gap: 1rem;
      }
    }
  }
}

.icon-checked {
  color: $green-medium;
}
</style>

<style lang="scss" scoped>
.align-center {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.justify-end {
  justify-content: flex-end;
}

.trash-icon {
  color: $gray-medium;
}

.check-icon {
  color: $green;
}

.px-1 {
  padding-inline: 0.5rem;
}

.position-relative {
  position: relative;
}

.add-schedule {
  color: $gray-low;

  &:hover {
    color: $primary;
    font-weight: bold;
  }
}

.dropdown {
  position: absolute;
  z-index: 10;
  top: 100%;
  right: 50%;
  display: flex;
  min-width: max-content;
  flex-direction: column;
  padding: 0.75rem;
  border-radius: 6px;
  background-color: $white-full;
  box-shadow: 0 2px 4px #00000029;
  gap: 0.5rem;
}

.activities-tab {
  overflow: hidden;

  &__header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
    gap: 1rem;
  }

  &__schedule-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e9ecef;
    margin: 1rem 0;
    padding-block: 1rem;
    margin-block-end: 3.5rem;
  }

  &__form {
    width: 50%;
  }
}

@media screen and (max-width: 1024px) {
  .activities-tab {
    &__form {
      width: auto;
    }
  }
}

.subtitle {
  color: #666;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 700;
}

.slide-fade {
  &-enter-active {
    transition: all 400ms ease-out;
  }

  &-leave-active {
    transition: all 200ms cubic-bezier(1, 0.5, 0.3, 1);
  }

  &-enter-from,
  &-leave-to {
    opacity: 0;
    transform: translateY(-1.5rem);
  }
}
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
</style>
