export enum PriorityOptions {
  "IN" = "IN",
  "A+" = "A+",
  "A" = "A",
  "A-" = "A-",
  "B" = "B",
  "C" = "C",
  "D" = "D",
  "F" = "F",
}

export interface WorkExperience {
  corporation_id: string
  corporation_name: string
  in_search_field: boolean
}

export interface PhoneItem {
  number: string
  calling_code: string
}

export interface ICandidate {
  added_in_mapping?: boolean
  avatar_url?: string
  candidate_id: string
  current_corporation_id?: string
  current_corporation_in_search_field: boolean
  current_corporation_name?: string
  current_role_name?: string
  current_salary?: {
    amount: number
    contract_type: string
    currency: string
    payment_frequency: string
  }
  final_mean?: number
  first_name: string
  is_added_in_placement?: boolean
  last_name: string
  linkedin_url?: string
  person_id: string
  phones: PhoneItem[]
  picture?: string
  priority_classification: PriorityOptions | null
  project_stage: "INTERVIEWS" | "SCREENINGS" | "SHORTLIST"
  work_experience: WorkExperience[]
}
