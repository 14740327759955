import { compareDesc } from "date-fns";
import type { WorkExperience } from "@/schemas/person";

export function sortExperiences<T extends WorkExperience>(experiences: T[]): T[] {
  return experiences?.sort((a, b) => {
    if (a.end_date === null || b.end_date === null)
      return 1;

    return compareDesc(new Date(a.start_date), new Date(b.end_date));
  });
}
