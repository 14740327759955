<script lang="ts">
import { computed, defineComponent } from "vue";
import { has } from "lodash";
import RadioButton from "primevue/radiobutton";

export default defineComponent({
  name: "ImuaRadioSimple",
  components: {
    RadioButton,
  },
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number, Boolean],
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: [String, Number],
      required: true,
    },
    unSelect: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [String, Number, Boolean],
      default: null,
    },
    fontSize: {
      type: String,
      default: "1rem",
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const model = computed({
      get: () => props.modelValue,
      set: (newValue) => {
        if (props.modelValue === props.value && props.unSelect)
          emit("update:modelValue", null);
        else
          emit("update:modelValue", newValue);
      },
    });

    return {
      model,
      has,
    };
  },
});
</script>

<template>
  <div
    class="imua-radiosimple"
    :class="[
      $attrs['wrapper-class'] || '',
      { 'imua-radiosimple--block': has($attrs, 'block') },
      { disabled },
    ]"
  >
    <RadioButton
      :id="id"
      v-model="model"
      class="imua-radiosimple__radio"
      :class="[$attrs['input-class'] || '']"
      :name="name"
      :value="value"
      :disabled="disabled"
    />
    <label :for="id" class="imua-radiosimple__label" :class="[$attrs['label-class'] || '']">
      {{ label }}
    </label>
  </div>
</template>

<style lang="scss" scoped>
.imua-radiosimple {
  display: inline-block;

  &__label {
    padding-left: 0.25rem;
    cursor: pointer;
    font-size: v-bind(fontSize);
    user-select: none;
  }

  &:deep {
    .p-radiobutton {
      transform: scale(0.6);

      .p-radiobutton-box {
        border-color: $gray-dark;

        &:hover {
          border-color: $yellow;
        }

        &.p-focus {
          box-shadow: 0 0 0 0.1rem $yellow;
        }
      }

      .p-radiobutton-icon {
        transform: scale(0.6);
      }

      &-checked {
        .p-radiobutton-box {
          border-color: $yellow;
          background-color: transparent;

          &:hover {
            background-color: transparent;
          }
        }

        .p-radiobutton-icon {
          background-color: $yellow;
        }
      }
    }
  }
}

.imua-radiosimple.disabled {
  label {
    cursor: default;
    color: $gray-border;
  }
}
</style>
