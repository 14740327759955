<script setup lang="ts">
// TODO: VERIFICAR COMO FAZER
// type StageTabsKeyType =  typeof stageTabsKeys;

const props = defineProps<{ id: string }>();
const router = useRouter();
const { data: project, error, isLoading: loading } = await useProject(props.id);
const {
  stageTabs,
  goTo,
} = useTabsProject(props.id);
const currentRoute = computed(() => stageTabs.value.findIndex(tab => tab.key === router.currentRoute.value.meta.tab));
</script>

<template>
  <div v-if="error">
    could not load
  </div>
  <section class="project-activities">
    <div v-if="project" class="project-container">
      <ProjectStages :id="props.id" />
      <ProjectTabs :stage-tabs="stageTabs" :active="currentRoute" class="tabs" @change="goTo($event)" />
      <div>
        <CorporationViewTab v-if="project?.corporation?.corporation_id" :id="project.corporation.corporation_id" />
      </div>
    </div>
  </section>
</template>

<route lang="yaml">
meta:
  layout: project-stage
  stage: construction
  tab: corporation
</route>

<style scoped lang="scss">
.project-activities {
  min-height: 100vh;
}
.project-container {
  padding-top: 3.75rem;
}
</style>
