<script lang="ts">
import { computed, defineComponent, shallowRef } from "vue";

const numberValidation = value => /^(\d+\.?\d*)(%|px|rem)?$/g.test(value);

export default defineComponent({
  name: "SvgIcon",
  props: {
    width: {
      type: [String, Number],
      default: "24",
      validator: numberValidation,
    },
    height: {
      type: [String, Number],
      default: "24",
      validator: numberValidation,
    },
    icon: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "native",
      validator: (value: string) => ["native", "mdi"].includes(value),
    },
    margin: {
      type: String,
      default: "right",
      validator: (value: string) => ["right", "left", "both", "none"].includes(value),
    },
    rotate: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const rotation = computed(() => `rotate(${props.rotate || 0})`);
    const svg = shallowRef(null);
    return { rotation, svg };
  },
  async created() {
    if (this.type === "native")
      this.svg = await import(`../../assets/images/${this.icon}.svg`);
  },
});
</script>

<template>
  <component
    :is="svg"
    v-if="type === 'native'"
    class="svg-icon"
    :class="[`m-${margin}`]"
    :width="width"
    :height="height"
  />

  <svg
    v-else
    xmlns="http://www.w3.org/2000/svg"
    class="svg-icon"
    :class="[`m-${margin}`]"
    viewBox="0 0 24 24"
    :width="width"
    :height="height"
  >
    <path fill="currentColor" :d="icon" />
  </svg>
</template>

<style lang="scss" scoped>
$spacing: 0.5rem;

.svg-icon {
  transform: v-bind(rotation);
  transition: transform 100ms ease-in-out;
}

.m-left {
  margin-left: $spacing;
}

.m-right {
  margin-right: $spacing;
}

.m-both {
  margin: 0 $spacing;
}

.m-none {
  margin: 0;
}
</style>
