import { z } from "zod";
import { IdealProfileSchema } from "@/types/ideal-profile";
import { EntitySchema } from "@/types/entity";

const BaseOrganizationAndAssemblySchema = z.object({
  project_id: z.string(),
});

export type BaseOrganizationAndAssembly = z.infer<typeof BaseOrganizationAndAssemblySchema>;

const AnalysisTypesEnum = z.enum([
  "STRATEGY",
  "GOVERNANCE",
  "EXECUTION_CONDITIONS",
  "IDEAL_PROFILE",
  "REALIZATIONS",
]);

const AnalysisSchema = z.object({
  analysis_type: AnalysisTypesEnum,
  description: z.string(),
});

const ConstructionTagSchema = z.object({
  text: z.string(),
  selected: z.boolean().nullable().default(true),
});

const CategorySchema = z.object({
  analyses: z.array(AnalysisSchema).default([]),
  tags: z.array(ConstructionTagSchema).nullable().default([]),
});

const OrganizationAndAssemblySchema = BaseOrganizationAndAssemblySchema.extend({
  governance: CategorySchema,
  strategy: CategorySchema,
  execution_conditions: CategorySchema,
  ideal_profile: IdealProfileSchema,
}).strict();

export type OrganizationAndAssembly = z.infer<typeof OrganizationAndAssemblySchema>;

const OrganizationAndAssemblySchemaStripSchema = OrganizationAndAssemblySchema.strip();

export const OrganizationAndAssemblySchemaDtoSchema = z.intersection(EntitySchema, OrganizationAndAssemblySchemaStripSchema);

export type OrganizationAndAssemblySchemaDto = z.infer<typeof OrganizationAndAssemblySchemaDtoSchema>;
