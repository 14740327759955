

import __layout_0 from '/opt/buildhome/repo/src/common/layouts/default.vue'
export const layouts = {
'activity': () => import('/opt/buildhome/repo/src/common/layouts/activity.vue'),
'default-full-page': () => import('/opt/buildhome/repo/src/common/layouts/default-full-page.vue'),
'default-page': () => import('/opt/buildhome/repo/src/common/layouts/default-page.vue'),
'default': __layout_0,
'home': () => import('/opt/buildhome/repo/src/common/layouts/home.vue'),
'listpage': () => import('/opt/buildhome/repo/src/common/layouts/listpage.vue'),
'login': () => import('/opt/buildhome/repo/src/common/layouts/login.vue'),
'person-details': () => import('/opt/buildhome/repo/src/common/layouts/person-details.vue'),
'persons': () => import('/opt/buildhome/repo/src/common/layouts/persons.vue'),
'project-stage': () => import('/opt/buildhome/repo/src/common/layouts/project-stage.vue'),
'validation': () => import('/opt/buildhome/repo/src/common/layouts/validation.vue'),
}

export function setupLayouts(routes) {
  return routes.map(route => {
    return { 
      path: route.path,
      meta: route.meta,
      component: layouts[route.meta?.layout || 'default'],
      children: route.path === '/' ? [route] : [{...route, path: ''}]
    }
  })
}
