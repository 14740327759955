<script setup lang="ts">
import { chunk } from "lodash-es";
import { SvgIcon } from "@/components/icon";
import { Button2 as Button, ButtonIcon } from "@/components/button";
import type { Person, WorkExperience } from "@/schemas/person";
import type { Maybe } from "@/http_services/types";

interface RecruitersTypes {
  avatar_url: string | null
  first_name: string
  last_name: string
  person_id: string
  activity_id: string
}

interface Props {
  projectId?: string
  activityId?: string
  candidateId?: string
  projectName: string
  corporationName: string
  candidate: Ref<Maybe<Person>>
  recruiters: RecruitersTypes[]
  job?: Ref<WorkExperience>
  draft?: boolean
  disabled?: boolean
  loading?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  draft: false,
  loading: false,
});

const emit = defineEmits([
  "click:logo",
  "click:cancel",
  "click:save",
  "click:temp",
  "click:profile",
  "onSelectAddRequester",
  "change:recruiter",
]);

const router = useRouter();

const { t } = useI18n();

const {
  projectName,
  corporationName,
  candidate,
  recruiters,
  projectId,
  candidateId,
} = toRefs(props);

const maxChars = 30;

const showHidden = ref(false);
const recruiterList = computed(() => chunk(recruiters.value, 3));
const recruitersView = computed(() => recruiterList.value[0]);
const recruitersHidden = computed(() => recruiterList.value.slice(1).reduce((acc, item) => acc.concat(item), []));

function shortenText(text: string | null | undefined) {
  if (!text)
    return text;
  return text.substring(0, maxChars);
}

function formatPersonName(candidate?: Person | RecruitersTypes | null) {
  if (!candidate)
    return "";
  return `${candidate?.first_name || ""} ${candidate?.last_name || ""}`;
}

function changeRecruiter(activity_id: string) {
  showHidden.value = false;
  router.push(`/projects/${projectId.value}/interviews/person/${candidateId.value}/activity/${activity_id}`);
  setTimeout(() => emit("change:recruiter"), 350);
}
</script>

<template>
  <div class="header">
    <ButtonIcon type="button" @click="emit('click:logo')">
      <SvgIcon icon="imua_logo" width="122px" height="50px" />
    </ButtonIcon>
    <div class="content">
      <div class="names">
        <p>
          <strong>{{ t("header.project") }}:</strong>
          {{ shortenText(projectName) }}
        </p>
        <p>
          <strong>{{ t("header.company").replace("s", "") }}:</strong>
          {{ shortenText(corporationName) }}
        </p>
      </div>

      <div v-if="draft" class="header__avatar--draft">
        <div class="header__avatar--button-draft" @click="emit('onSelectAddRequester')">
          <div class="draft-icon">
            <SvgIcon icon="plus" width="2.125rem" height="2.125rem" margin="none" />
          </div>
          {{ t("projects.mapping.card.addCandidate") }}
        </div>
      </div>
      <div v-else class="profile">
        <div class="profile__candidate">
          <div class="profile__candidate__name">
            <div class="profile__candidate__name-name">
              {{ candidate?.first_name }} {{ candidate?.last_name }}
            </div>
            <div v-if="job && job.role_name" class="profile__candidate__name-role">
              <strong>{{ job.role_name }}</strong>
              <span v-if="job.company?.corporation_data?.name"> -</span> {{ job.company?.corporation_data?.name }}
            </div>
          </div>
          <PersonAvatar
            :tooltip="formatPersonName(candidate)"
            :name="formatPersonName(candidate)"
            :url="candidate?.avatar_url ? `${candidate?.avatar_url}?version=${candidate?._version}` : null"
            @click="emit('click:profile')"
          />
        </div>

        <div class="header__avatar--divisor" />

        <div class="profile__recruiters noselect">
          <div
            v-for="(recruiter, index) in recruitersView"
            :key="index"
            class="profile__recruiters-avatars"
            :class="{ active: recruiter?.activity_id === activityId }"
            @click="changeRecruiter(recruiter.activity_id)"
          >
            <PersonAvatar
              :active="recruiter?.activity_id === activityId"
              :tooltip="formatPersonName(recruiter)"
              :name="formatPersonName(recruiter)"
              :url="recruiter?.avatar_url ? `${recruiter?.avatar_url}?version=${recruiter?._version}` : null"
            />
          </div>
          <div v-if="recruitersHidden.length > 0" class="circle-amount" @click="showHidden = !showHidden">
            +{{ recruitersHidden.length }}
          </div>
          <div v-if="showHidden" class="profile__recruiters-hidden">
            <div
              v-for="(recruiter, index) in recruitersHidden"
              :key="index"
              class="avatars"
              @click="changeRecruiter(recruiter.activity_id)"
            >
              <PersonAvatar
                :name="formatPersonName(recruiter)"
                :url="recruiter?.avatar_url ? `${recruiter?.avatar_url}?version=${recruiter?._version}` : null"
              />
              <span class="person-name">
                {{ formatPersonName(recruiter) }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="buttons">
      <div class="loader-wrapper">
        <div v-show="loading" class="loader" />
      </div>
      <Button
        variation="light"
        filled
        size="large"
        class="cancel"
        @click="emit('click:cancel')"
      >
        {{ t("text.close") }}
      </Button>

      <Button
        :disabled="disabled"
        variation="primary"
        filled
        size="large"
        @click="emit('click:save')"
      >
        {{ t("text.form.finalize") }}
      </Button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.header {
  display: flex;
  height: 6.875rem;
  align-items: center;
  justify-content: space-between;
  padding-inline: 1rem;

  &__avatar {
    &--container {
      display: flex;
      flex: 1;
      justify-content: center;
    }

    &--list {
      position: relative;
      display: inline-flex;
      gap: 0.5rem;
    }

    &--divisor {
      height: 4rem;
      border-left: 2px solid rgb(102 102 102 / 20%);
      margin: 0 1rem;
    }

    &--button-draft {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 1rem;
      color: $gray-text;
      font-size: 1.125rem;
      font-weight: 600;

      .draft-icon {
        display: flex;
        width: 3.125rem;
        height: 3.125rem;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: $gray-x18;

        svg {
          color: $gray-x19;
        }
      }
    }

    &--button-draft:hover {
      color: $gray-dark;

      .draft-icon {
        background: $gray-x19;

        svg {
          color: $gray-x18;
        }
      }
    }
  }

  .content {
    display: flex;
    flex-grow: 1;
    align-items: center;
    margin-left: 2rem;

    .profile {
      display: flex;
      align-items: center;
      padding-left: 1rem;

      &__candidate {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 5rem;
        gap: 1rem;

        &__name {
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          &-name {
            color: $primary;
            font-weight: bold;
            text-align: end;
          }

          &-role {
            text-align: end;
          }
        }
      }

      &__recruiters {
        display: flex;
        flex-direction: row;
        padding-inline-start: 1rem;

        &-avatars {
          position: relative;
          margin-left: -1rem;
        }

        @for $i from 1 through 50 {
          &-avatars:nth-child(#{$i}) {
            z-index: 50 - $i;
          }
        }

        &-avatars:hover {
          z-index: 100;
        }

        &-avatars.active {
          z-index: 99;
        }

        &-hidden {
          position: absolute;
          z-index: 80;
          display: flex;
          flex-direction: column;
          padding: 0.5rem;
          margin-block-start: 3rem;
          margin-inline-start: 10rem;
          background: white;
          border-radius: 0.3125rem;
          box-shadow: 0px 4px 6px #00000029;
          border: 1px solid #ECECEC;

          .avatars {
            cursor: pointer;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 0.5rem;

            .person-name {
              font-size: 0.875rem;
              font-weight: bold;
              color: $primary;
            }
          }
        }

        .circle-amount:hover {
          background: $red-violet-medium;
        }

        .circle-amount {
          cursor: pointer;
          display: flex;
          width: 3.25rem;
          height: 3.25rem;
          margin-inline: 0.75rem;
          flex-direction:  column;
          align-items: center;
          justify-content: center;
          color: $white-full;
          background: $primary;
          border-radius: 50%;
          font-size: 1.3125rem;
          font-weight: bold;
        }
      }

      .image-card {
        cursor: pointer;
        margin-inline-start: 1.25rem;
      }

      img {
        width: 50px;
        height: 50px;
        border-radius: 100%;
      }
    }

    .names {
      padding-right: 1rem;

      p {
        &:first-child {
          margin-bottom: 0.5rem;
        }
      }
    }
  }

  .buttons {
    display: flex;
    gap: 1rem;
  }
}

:deep(.tooltip-list__avatar) {
  // border: 4px solid $primary;
  // box-sizing: content-box;
}
p {
  margin: 0;
}
.name {
  margin-inline-end: 5rem;
}
.loader-wrapper {
  width: 2rem;
  height: 2rem;
}
.loader {
  width: 2rem;
  height: 2rem;
  border: 5px solid;
  border-color: $yellow transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
