<script setup lang="ts">
import posthog from "posthog-js";
import { hasExpired, hasToken } from "@/utils/auth";

const router = useRouter();
router.beforeEach((to, from, next) => {
  const publicRoutes = [
    "login",
    "define-password",
    "SmokePage",
  ];

  nextTick(() => {
    posthog.capture("$pageview", {
      $current_url: to.fullPath,
    });
  });

  if (publicRoutes.includes(to.name as string)) {
    next();
    return;
  }

  else if ((!hasToken() || hasExpired())) {
    next("/login");
    return;
  }

  if (from.fullPath === "/" && to.path === "/")
    next("/projects");
  else next();
});
</script>

<template>
  <router-view />
</template>

<style src="@vueform/multiselect/themes/default.css"></style>

<style lang="scss">
@font-face {
  font-display: auto;
  font-family: Montserrat;
  font-weight: 400;
  src:
    local("Montserrat"),
    url("./assets/fonts/montserrat/Montserrat-Regular.ttf") format("truetype"),
    url("./assets/fonts/montserrat/Montserrat-Regular.woff") format("woff"),
    url("./assets/fonts/montserrat/Montserrat-Regular.woff2") format("woff2");
}

@font-face {
  font-display: auto;
  font-family: Montserrat;
  font-weight: 700;
  src: local("Montserrat"), url("./assets/fonts/montserrat/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
  font-display: auto;
  font-family: "Open Sans";
  font-weight: 400;
  src:
    url("./assets/fonts/open_sans/OpenSans-Regular.ttf") format("truetype"),
    url("./assets/fonts/open_sans/OpenSans-Regular.woff") format("woff"),
    url("./assets/fonts/open_sans/OpenSans-Regular.woff2") format("woff2");
}

@font-face {
  font-display: auto;
  font-family: "Open Sans";
  font-weight: 500;
  src:
    url("./assets/fonts/open_sans/OpenSans-Medium.ttf") format("truetype"),
    url("./assets/fonts/open_sans/OpenSans-Medium.woff") format("woff"),
    url("./assets/fonts/open_sans/OpenSans-Medium.woff2") format("woff2");
}

@font-face {
  font-display: auto;
  font-family: "Open Sans";
  font-weight: 600;
  src:
    url("./assets/fonts/open_sans/OpenSans-SemiBold.ttf") format("truetype"),
    url("./assets/fonts/open_sans/OpenSans-SemiBold.woff") format("woff"),
    url("./assets/fonts/open_sans/OpenSans-SemiBold.woff2") format("woff2");
}

@font-face {
  font-display: auto;
  font-family: "Open Sans";
  font-weight: 700;
  src:
    url("./assets/fonts/open_sans/OpenSans-Bold.ttf") format("truetype"),
    url("./assets/fonts/open_sans/OpenSans-Bold.woff") format("woff"),
    url("./assets/fonts/open_sans/OpenSans-Bold.woff2") format("woff2");
}

@font-face {
  font-display: auto;
  font-family: "Open Sans";
  font-weight: 900;
  src:
    url("./assets/fonts/open_sans/OpenSans-ExtraBold.ttf") format("truetype"),
    url("./assets/fonts/open_sans/OpenSans-ExtraBold.woff") format("woff"),
    url("./assets/fonts/open_sans/OpenSans-ExtraBold.woff2") format("woff2");
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

.phoneinput-panel {
  width: 130px;

  .p-inputtext {
    height: 28px;
    border-radius: 6px;
    color: $gray-dark;
    font-size: 13px;

    &:enabled:focus {
      border-color: $gray-border;
      box-shadow: none;
    }
  }
}
</style>
