<script setup lang="ts">
import { mdiCheck as checkIcon, mdiChevronRight as chevron, mdiClose as close } from "@mdi/js";
import { onClickOutside } from "@vueuse/core";
import { useI18n } from "vue-i18n";
import type { Person } from "@/schemas/person";
import { SvgIcon } from "@/components/icon";

const props = withDefaults(
  defineProps<{
    items: Array<Person>
    total: number
    selectedIndex: number | null
    clear: boolean
  }>(),
  {
    clear: false,
  },
);

const emit = defineEmits(["filter:select", "total"]);

const { t } = useI18n();

const { items, total, selectedIndex, clear } = toRefs(props);

const selectedValues = ref([]);
const selectedItem = ref(null);
const isOpen = ref(false);
const isSelectd = ref(false);
const resultsRef = ref(null);
const dropdownContainer = ref(null);
const skip = ref(total.value);

function selectItem(item) {
  selectedItem.value = item;
  selectedValues.value = [...selectedValues.value, selectedItem.value];
  emit(
    "filter:select",
    selectedValues.value.map(e => e?.person_id).filter(e => e),
  );
  isOpen.value = false;
  isSelectd.value = !isSelectd.value;
}
async function removeTag(index: number) {
  selectedValues.value.splice(index, 1);
  emit(
    "filter:select",
    selectedValues.value.map(e => e?.person_id).filter(e => e),
  );
}

function handleScroll(event: Event) {
  const {
    scrollTop: hiddenHeight,
    scrollHeight: totalHeight,
    clientHeight: totalVisible,
  } = event.target as HTMLElement;
  const bottomReached = Math.round(hiddenHeight) + Math.round(totalVisible) + 1 >= totalHeight;
  if (bottomReached) {
    skip.value = skip.value + 10;
    emit("total", skip.value);
  }
}
onClickOutside(dropdownContainer, () => {
  if (isOpen.value)
    isOpen.value = false;
});

watch(selectedIndex, (value) => {
  selectItem(props.items[value]);
});
watch(clear, () => {
  selectedValues.value = [];
});
</script>

<template>
  <div>
    <div
      ref="dropdownContainer"
      class="dropdown"
      :class="[{ 'no-border': isOpen }]"
      @click="isOpen = !isOpen"
    >
      <span v-if="selectedValues && selectedValues[0]" :style="{ 'max-width': '9rem' }">
        {{ selectedValues[0].person_data.first_name }}
        {{ selectedValues[0].person_data.last_name }}
        <span v-if="selectedValues.length > 1">{{ "+" + `${selectedValues.length - 1}` }} </span>
      </span>
      <span v-else :style="{ color: isOpen ? '#666666' : '#c0c0c0' }">
        {{ t("projects.filters.projectStatus.placeholder") }}
      </span>
      <i class="arrow" :class="{ up: isOpen }">
        <SvgIcon
          type="mdi"
          :icon="chevron"
          rotate="90deg"
          :style="{ color: isOpen ? '#666666' : '#c0c0c0' }"
        />
      </i>
    </div>
    <transition name="slide">
      <ul v-show="isOpen" ref="resultsRef" class="dropdown-menu" @scroll="handleScroll">
        <li v-for="(item, index) in items" :key="index" @click="selectItem(item)">
          {{ item?.person_data?.first_name }} {{ item?.person_data?.last_name }}
          <SvgIcon
            v-if="selectedValues.includes(item)"
            id="check-icon"
            type="mdi"
            :icon="checkIcon"
            size="1.375rem"
          />
        </li>
      </ul>
    </transition>
  </div>
  <div v-if="selectedValues" class="tag-container">
    <transition-group name="slide-fade">
      <div v-for="(selected, index) in selectedValues" :key="index" class="selected-tag">
        <span>
          {{ selected?.person_data?.first_name }}
          {{ selected?.person_data?.last_name }}
        </span>
        <SvgIcon
          type="mdi"
          :icon="close"
          width="16px"
          height="16px"
          @click="removeTag(index)"
        />
      </div>
    </transition-group>
  </div>
</template>

<style lang="scss" scoped>
.tag-container {
  display: flex;
  flex-flow: row wrap;
  gap: 0 0.625rem;

  .selected-tag {
    display: flex;
    width: fit-content;
    align-items: center;
    justify-content: space-between;
    padding: 0.4375rem 1rem;
    border-radius: 0.5rem;
    background-color: white;
    box-shadow: 0 2px 4px #00000029;
    font-size: 1rem;
    gap: 0.5rem;
    margin-block-start: 1rem;

    svg {
      margin: 0;
      cursor: pointer;
    }
  }
}

.dropdown {
  position: relative;
  display: flex;
  width: 12rem;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #c0c0c0;
  border-radius: 0.5rem;
  background-color: white;
  box-shadow: 0 2px 4px #00000029;
  cursor: pointer;
  font-size: 1rem;
  margin-block-start: 1rem;
  padding-block: 0.7rem;
  padding-inline: 1rem 0.75rem;

  .arrow {
    position: relative;

    svg {
      position: absolute;
      top: 50%;
      right: -0.375rem;
      width: 32px;
      height: 32px;
      margin: 0;
      transform: translateY(-50%) rotate(90deg);
    }
  }

  &-menu {
    position: relative;
    z-index: 20;
    overflow: hidden;
    width: 12rem;
    max-height: 12rem;
    background-color: white;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    box-shadow: 0 2px 4px #00000029;
    cursor: pointer;
    margin: 0;
    padding: 0;

    &:hover {
      overflow-y: scroll;
    }

    &::-webkit-scrollbar {
      width: 1rem;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      min-height: 4rem;
      box-sizing: padding-box;
      border: 0.4rem solid transparent;
      border-radius: 1rem;
      background-clip: padding-box;
      background-color: #c6c6c6;
    }

    li {
      display: flex;
      width: 12rem;
      align-items: center;
      justify-content: space-between;
      padding: 1rem;

      #check-icon {
        color: $green;
      }
    }
  }
}

.no-border {
  border: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.slide-enter-active {
  -moz-transition-duration: 200ms;
  transition-duration: 200ms;
  -moz-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  max-height: 12rem;
  -moz-transition-duration: 200ms;
  transition-duration: 200ms;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

.slide-fade-enter-active {
  transition: all 300ms ease-out;
}

.slide-fade-leave-active {
  transition: all 500ms cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateX(1.25rem);
}
</style>
