export const input = {
  placeholder: {
    username: "Usuário",
    findPeopleAndCompany: "buscar pessoa, empresa ou posição",
    password: "Senha",
    email: "E-mail",
    submitButton: "Entrar",
  },
  confirm: "Confirmar",
  login: "Login",
  avatarInput: {
    defineImage: "Definir Imagem",
    cancel: "Cancelar",
    change: "Alterar",
  },
  validations: {
    required: "Campo não preenchido",
    email: "E-mail inválido",
    minLength: "Deve haver no mínimo {length} caractere(s)",
  },
};
