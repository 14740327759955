<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import bson from "bson-objectid";

import cloneDeep from "lodash-es/cloneDeep";
import concat from "lodash-es/concat";
import findIndex from "lodash-es/findIndex";
import forEach from "lodash-es/forEach";
import get from "lodash-es/get";
import isEmpty from "lodash-es/isEmpty";
import map from "lodash-es/map";
import orderBy from "lodash-es/orderBy";
import remove from "lodash-es/remove";
import set from "lodash-es/set";
import update from "lodash-es/update";

import pipe from "lodash/fp/pipe";

import PersonalDataForm from "./components/forms/PersonalDataForm/PersonalDataForm.vue";
import CertificationForm from "./components/forms/CertificationForm/CertificationForm.vue";
import AcademicForm from "./components/forms/AcademicForm/AcademicForm.vue";
import LanguageForm from "./components/forms/LanguageForm/LanguageForm.vue";
import ExtraActivitiesForm from "./components/forms/ExtraActivities/ExtraActivitiesForm.vue";
import WorkExperienceForm from "./components/forms/WorkExperienceForm/WorkExperienceForm.vue";
import AvatarForm from "./components/forms/AvatarForm/AvatarForm.vue";
import UploadCVForm from "./components/forms/UploadCVForm/UploadCVForm.vue";

import Modal from "@/components/modal/modal.vue";
import WarningIcon from "@/assets/images/alert_circle_icon.svg";
import PersonIcon from "@/assets/images/person_icon.svg";
import { ContentHeader } from "@/components/content_header";
import { createPerson, getPerson, updatePerson } from "@/http_services/entities/persons";
import { resumeParse } from "@/http_services/entities/resume";
import { ImagesTypesEnum } from "@/schemas/enums/images";
import { createAvatar } from "@/http_services/files/avatar";
import { CURRENCY } from "@/entities/common";
import type { PersonPayload } from "@/entities/Person/form";
import { Loading } from "@/components/loading";
import { Sidebar } from "@/components/sidebar";
import type { Person, WorkExperience } from "@/schemas/person";
import { BaseError } from "@/http_services/errors";

type ExtraActivities = "projects" | "volunteering" | "publications" | "awards";

const datesKeys = ["start_date", "end_date"];

const { t } = useI18n();
const store = useStore();
const router = useRouter();
const route = useRoute();
const query = route.query;
const [firstName, ...surname] = (get(query, "name", "") as string).split(" ");
const defaultPhoneCode = "+ 55";

const personalPayload: Ref<Partial<PersonPayload>> = ref({});
const workExperiencePayload: Ref<PersonPayload["work_experience"]> = ref([]);
const academicPayload: Ref<PersonPayload["education"]> = ref([]);
const certificationPayload: Ref<PersonPayload["certifications"]> = ref([]);
const languagePayload: Ref<PersonPayload["languages"]> = ref([]);
const extraActivitiesPayload: Ref<Partial<PersonPayload>> = ref({});

const personalEditPayload: Ref<Partial<PersonPayload>> = ref({});
const workExperienceEditPayload: Ref<PersonPayload["work_experience"]> = ref([]);
const academicEditPayload: Ref<PersonPayload["education"]> = ref([]);
const certificationEditPayload: Ref<PersonPayload["certifications"]> = ref([]);
const languageEditPayload: Ref<PersonPayload["languages"]> = ref([]);

const personalAutoFilledFields = ref({});
const workExperienceAutoFilledFields = ref({});
const academicAutoFilledFields = ref([]);
const certificationAutoFilledFields = ref([]);
const languageAutoFilledFields = ref([]);

const personalAutoFilledEditFields = ref({});
const workExperienceAutoFilledEditFields = ref({});
const academicAutoFilledEditFields = ref([]);
const certificationAutoFilledEditFields = ref([]);
const languageAutoFilledEditFields = ref([]);

const isLoading = ref(false);
const avatarFile = ref();
const cvData = ref();
const autoFilledFields = ref();

const hasSubmitionError = ref<boolean>(false);
const error = ref(false);
const conflictModal = ref(false);
const conflictType = ref<"linkedin" | "email" | null>(null);
const conflictData = ref<{ field: string; link: string }>();

function cantSave(value) {
  error.value = value;
}

const isEditing = computed(() => "edit" in route.query && route.query.edit === "true");
const selectedPerson = computedAsync(async () => {
  let id = null;

  id = route.params.id || query.id;

  if (typeof id === "string")
    return await getPerson(id);
});

function setupExtraActivitiesPayloadByPersonData(editPerson: PersonPayload) {
  if (editPerson?.projects?.length)
    set(extraActivitiesPayload.value, "projects", [...editPerson.projects]);
  if (editPerson?.publications?.length)
    set(extraActivitiesPayload.value, "publications", [...editPerson.publications]);
  if (editPerson?.volunteering?.length)
    set(extraActivitiesPayload.value, "volunteering", [...editPerson.volunteering]);
  if (editPerson?.awards?.length)
    set(extraActivitiesPayload.value, "awards", [...editPerson.awards]);

  return editPerson;
}

function setupLanguagePayloadByPersonData(editPerson: PersonPayload) {
  if (editPerson?.languages?.length)
    languagePayload.value = [...editPerson.languages];

  return editPerson;
}

function setupCertificationPayloadByPersonData(editPerson: PersonPayload) {
  if (editPerson?.certifications?.length)
    certificationPayload.value = [...editPerson.certifications];

  return editPerson;
}

function setupEducationPayloadByPersonData(editPerson: PersonPayload) {
  if (editPerson?.education?.length)
    academicPayload.value = [...editPerson?.education];

  return editPerson;
}

function setupWorkExperiencePayloadByPersonData(editPerson: PersonPayload) {
  if (editPerson?.work_experience?.length) {
    workExperiencePayload.value = editPerson.work_experience?.map(exp => ({
      ...exp,
      id: bson().toHexString(),
    })) || [];
  }
  orderWorkExperience();

  return editPerson;
}

function setupPersonalPayloadByPersonData(editPerson: PersonPayload) {
  const {
    first_name,
    last_name,
    email_addresses,
    birth_date,
    address,
    phones,
    linkedin_url,
    description,
  } = editPerson;

  personalPayload.value = {
    first_name,
    last_name,
    email_addresses,
    birth_date,
    address: address?.name ? { ...address } : { name: "" },
    phones: phones?.length
      ? phones
      : [{ calling_code: defaultPhoneCode, number: "" }],
    linkedin_url,
    description,
  };

  return editPerson;
}

watch(selectedPerson, (value) => {
  pipe(
    setupPersonalPayloadByPersonData,
    setupWorkExperiencePayloadByPersonData,
    setupEducationPayloadByPersonData,
    setupCertificationPayloadByPersonData,
    setupLanguagePayloadByPersonData,
    setupExtraActivitiesPayloadByPersonData,
  )(value);
});

const pageTitle = computed(() =>
  isEditing.value ? t("header.editPerson") : t("header.createPerson"),
);

function addPersonalInfo() {
  personalPayload.value = {
    first_name: firstName,
    last_name: surname.join(" "),
    email_addresses: [
      { email_address: get(query, "email", "") as string, is_main: true },
    ],
    birth_date: null,
    address: { name: "" },
    phones: [{ calling_code: defaultPhoneCode, number: "" }],
    linkedin_url: undefined,
    description: undefined,
  };
}

function appendWorkExperience() {
  workExperiencePayload.value.push({
    role_name: "",
    company: { name: "", corporation: "" },
    salary: { amount: 0, currency: CURRENCY.BRL, payment_frequency: null },
    incentive_compensation: "",
    job_location: "",
    is_current: false,
    start_date: null,
    end_date: null,
    description: "",
    linkedin_url: "",
    realizations: [],
    conditions: "",
  });
}

function appendEducation() {
  academicPayload.value.push({
    institution: { name: "", corporation: "" },
  });
}

function appendCertification() {
  certificationPayload.value.push({
    name: "",
    institution: { name: "", corporation: "" },
  });
}

function appendLanguage() {
  languagePayload.value.push({
    language: null,
  });
}

function appendExtraActivity(activityName: ExtraActivities) {
  if (!extraActivitiesPayload.value[activityName])
    extraActivitiesPayload.value[activityName] = [];

  switch (activityName) {
    case "projects":
    case "volunteering":
      get(extraActivitiesPayload, `value.${activityName}`).push({
        title: "",
        start_date: null,
        is_current: false,
        end_date: null,
        description: "",
      });
      break;
    case "publications":
      get(extraActivitiesPayload, `value.${activityName}`).push({
        headline: "",
        publisher: "",
        published_date: null,
      });
      break;
    case "awards":
      get(extraActivitiesPayload, `value.${activityName}`).push({
        title: "",
        related_position: "",
        award_date: null,
        description: "",
      });
      break;
    default:
      break;
  }
}

function addOneOfEach() {
  addPersonalInfo();
}

addOneOfEach();

const lists = {
  work_experience: workExperiencePayload,
  academic: academicPayload,
  certification: certificationPayload,
  language: languagePayload,
};

const editLists = {
  work_experience: workExperienceEditPayload,
  academic: academicEditPayload,
  certification: certificationEditPayload,
  language: languageEditPayload,
};

function removeFromList(listName: "work_experience" | "academic" | "certification" | "language", index: number) {
  lists[listName]?.value?.splice(index, 1);
}

function removeFromEditList(listName: "work_experience" | "academic" | "certification" | "language", index: number) {
  editLists[listName]?.value?.splice(index, 1);
}

function removeExtraActivity([listName, index]: [ExtraActivities, number]) {
  get(extraActivitiesPayload, `value.${listName}`).splice(index, 1);
}

const statusMessage = ref("");

function clearForm() {
  personalPayload.value = {};
  workExperiencePayload.value = [];
  academicPayload.value = [];
  certificationPayload.value = [];
  languagePayload.value = [];
  extraActivitiesPayload.value = {};

  addOneOfEach();
}

const v = useVuelidate();

function getPersonalParserChoiceEditKeys() {
  return Object.keys(personalAutoFilledEditFields.value)?.filter(
    (key: string) => personalAutoFilledEditFields.value[key],
  ) || [];
}

function getCertificationParserChoiceEditKeys() {
  return certificationAutoFilledEditFields.value?.map(item =>
    Object.keys(item)?.filter(key => item[key]),
  );
}

function addSelectedPersonalEditPayload(payload) {
  const choicesPersonalKeys = getPersonalParserChoiceEditKeys();

  if (choicesPersonalKeys?.length) {
    choicesPersonalKeys.forEach((key) => {
      set(payload, key, personalEditPayload.value[key]);
    });
  }

  return payload;
}

function addSelectedWorkExperienceEditPayload(payload) {
  set(payload, "work_experience", [
    ...workExperiencePayload.value,
    ...workExperienceEditPayload.value?.map(exp => ({ ...exp, id: bson().toHexString() })) || [],
  ]);

  return payload;
}

function addSelectedAcademicEditPayload(payload) {
  set(payload, "education", [...academicPayload.value, ...academicEditPayload.value]);

  return payload;
}

function addSelectedLanguageEditPayload(payload) {
  if (languageEditPayload.value?.length) {
    set(payload, "languages", [
      ...languagePayload.value,
      ...languageEditPayload.value,
    ]);
  }

  return payload;
}

function addSelectedCertificationEditPayload(payload) {
  const choicesCertificationKeys = getCertificationParserChoiceEditKeys();

  if (choicesCertificationKeys?.length) {
    choicesCertificationKeys.forEach((keys, index) => {
      keys.forEach((key) => {
        if (index <= certificationPayload.value?.length - 1) {
          certificationPayload.value[index][key]
            = certificationEditPayload.value[index][key];
        }
      });
    });
  }

  set(payload, "certifications", certificationPayload.value);

  return payload;
}

async function handlePersonUpdate() {
  if (!selectedPerson.value?.id)
    return;

  const payload = cloneDeep(personalPayload.value);

  pipe(
    addSelectedPersonalEditPayload,
    addSelectedWorkExperienceEditPayload,
    addSelectedAcademicEditPayload,
    addSelectedLanguageEditPayload,
    addSelectedCertificationEditPayload,
    filterPersonalEmailAddress,
  )(payload);

  store.commit("SET_FULL_PAGE_LOADING_MESSAGE", "Loading");

  await updatePerson(selectedPerson.value, {
    person: {
      ...selectedPerson.value,
      ...payload,
    },
  });

  handleFinishSubmit();
}

function filterCertificationInstitution(payload) {
  const certifications
    = certificationPayload.value
      ?.map(certification => ({
        ...certification,
        institution:
          certification?.institution?.corporation !== ""
            ? certification.institution
            : null,
      }))
      ?.filter(certification => certification.id) || [];

  set(payload, "certifications", certifications);

  return payload;
}

function filterEducationInstitution(payload) {
  const educations
    = academicPayload.value?.map(education => ({
      ...education,
      institution:
        education?.institution?.corporation !== "" ? education.institution : null,
    })) || [];

  set(payload, "education", educations);

  return payload;
}

function filterPersonalAddress(payload) {
  update(payload, "address", el => (el.name ? el : null));

  return payload;
}

function filterPersonalEmailAddress(payload) {
  remove(payload.email_addresses, { is_main: false, email_address: "" });

  const email_addresses
    = payload.email_addresses?.filter(({ email_address }) => !!email_address) || [];

  set(payload, "email_addresses", email_addresses);

  return payload;
}

function filterPersonalPhones(payload) {
  remove(payload.phones, { calling_code: "+ 55", number: "" });

  return payload;
}

function filterWorkExperience(payload) {
  set(payload, "work_experience", workExperiencePayload.value);

  return payload;
}

function filterLanguages(payload) {
  const languages
    = languagePayload.value?.filter(language => language.language) || [];

  set(payload, "languages", languages);

  return payload;
}

async function handlePersonAvatar({ person, payload }) {
  if (!person?.id || !avatarFile.value)
    return;

  const response = await createAvatar(avatarFile.value, ImagesTypesEnum.persons);

  await updatePerson(person, {
    person: {
      ...person,
      ...payload,
      avatar_url: response.url,
    },
  });
}

function handleFinishSubmit() {
  statusMessage.value = t("persons.createPersonForm.submitMessages.success");
  clearForm();
  router.push("/persons");
}

async function handlePersonCreate() {
  const payload = cloneDeep(personalPayload.value);

  pipe(
    filterCertificationInstitution,
    filterEducationInstitution,
    filterPersonalAddress,
    filterPersonalEmailAddress,
    filterPersonalPhones,
    filterWorkExperience,
    filterLanguages,
  )(payload);

  store.commit("SET_FULL_PAGE_LOADING_MESSAGE", "Loading");

  try {
    const person = await createPerson({ person: payload });
    await handlePersonAvatar({ person, payload });
    handleFinishSubmit();
  }
  catch (createError) {
    if (createError instanceof BaseError && createError.status === 409) {
      const duplicatesFound = get(createError, "data.errors[0].detail.duplicates", []);

      if (duplicatesFound.length) {
        const duplicatedLinkedins = duplicatesFound.map(({ linkedin_url }: { linkedin_url: string | null }) => linkedin_url);
        const duplicatedEmails = duplicatesFound.map(({ email_addresses }) => (email_addresses || [])[0].email_address);

        const isConflictLinkedin = duplicatedLinkedins.includes(payload.linkedin_url);
        const isConflictEmail = duplicatedEmails.includes(get(payload, "email_addresses[0].email_address", null));

        const conflict = isConflictLinkedin ? "linkedin" : isConflictEmail ? "email" : undefined;
        let id = null;
        switch (conflict) {
          case "linkedin":
            conflictType.value = "linkedin";
            id = duplicatesFound.find(({ linkedin_url }: { linkedin_url: string | null }) => linkedin_url === payload.linkedin_url)?.id;
            conflictData.value = {
              field: payload.linkedin_url!,
              link: `/persons/${id}`,
            };
            conflictModal.value = true;
            break;
          case "email":
            conflictType.value = "email";
            conflictModal.value = true;
            id = duplicatesFound.find(({ email_addresses }) => (email_addresses || [])[0].email_address === get(payload, "email_addresses[0].email_address", null))?.id;
            conflictData.value = {
              field: payload.email_addresses![0]!.email_address,
              link: `/persons/${id}`,
            };
            break;
          default:
            conflictModal.value = true;
            break;
        }
      }
    }
  }
}

async function onSubmit() {
  try {
    const isValid = await v.value.$validate();

    if (isValid) {
      if (isEditing.value)
        handlePersonUpdate();
      else
        handlePersonCreate();
    }
    else {
      const errors = document.querySelectorAll(".error");

      await nextTick();

      if (errors && errors[0]) {
        errors[0].scrollIntoView({
          behavior: "smooth",
          inline: "start",
        });
      }
    }
  }
  catch (error) {
    hasSubmitionError.value = true;
  }
  finally {
    store.commit("SET_FULL_PAGE_LOADING_MESSAGE");
  }
}

const sidebarVisible = ref(false);

function onCloseSidebar() {
  cvData.value.file = null;
  cvData.value.url = null;
  sidebarVisible.value = false;
}

function addParsedLanguages(data: Partial<PersonPayload>) {
  const { languages } = get(data, "person");

  languages.forEach((lang: Partial<PersonPayload["languages"]>, index: number) => {
    forEach(lang, (value, key) => {
      languageAutoFilledFields.value[index] = {
        ...languageAutoFilledFields.value[index],
        [key]: !isEmpty(value) && !isEditing.value,
      };

      if (isEditing.value) {
        languageAutoFilledEditFields.value[index] = {
          ...languageAutoFilledEditFields.value[index],
          [key]: !isEmpty(value),
        };
      }
    });
  });

  if (!isEditing.value)
    languagePayload.value = [...languages];

  if (isEditing.value)
    languageEditPayload.value = [...languages];

  return data;
}

function addParsedCertification(data: Partial<PersonPayload>) {
  const { certifications } = get(data, "person");

  certifications.forEach(
    (cert: Partial<PersonPayload["certifications"]>, index: number) => {
      forEach(cert, (value, key) => {
        certificationAutoFilledFields.value[index] = {
          ...certificationAutoFilledFields.value[index],
          [key]: !isEmpty(value) && !isEditing.value,
        };

        if (isEditing.value) {
          certificationAutoFilledEditFields.value[index] = {
            ...certificationAutoFilledEditFields.value[index],
            [key]: !isEmpty(value),
          };
        }
      });
    },
  );

  if (!isEditing.value)
    certificationPayload.value = [...certifications];

  if (isEditing.value)
    certificationEditPayload.value = [...certifications];

  return data;
}

function addParsedAcademic(data: Partial<PersonPayload>) {
  const { education } = get(data, "person");

  education.forEach((edu: Partial<PersonPayload["education"]>, index: number) => {
    forEach(edu, (value, key) => {
      if (isEditing.value) {
        if (datesKeys.includes(key.toString())) {
          if (key.toString() === "start_date") {
            academicAutoFilledEditFields.value[index] = {
              ...academicAutoFilledEditFields.value[index],
              start_year: !isEmpty(value),
              start_month: !isEmpty(value),
            };
          }
          else {
            academicAutoFilledEditFields.value[index] = {
              ...academicAutoFilledEditFields.value[index],
              end_year: !isEmpty(value),
              end_month: !isEmpty(value),
            };
          }
        }
        else {
          academicAutoFilledEditFields.value[index] = {
            ...academicAutoFilledEditFields.value[index],
            [key]: !isEmpty(value),
          };
        }
      }
      else {
        if (datesKeys.includes(key.toString())) {
          if (key.toString() === "start_date") {
            academicAutoFilledFields.value[index] = {
              ...academicAutoFilledFields.value[index],
              start_year: !isEmpty(value) && !isEditing.value,
              start_month: !isEmpty(value) && !isEditing.value,
            };
          }
          else {
            academicAutoFilledFields.value[index] = {
              ...academicAutoFilledFields.value[index],
              end_year: !isEmpty(value) && !isEditing.value,
              end_month: !isEmpty(value) && !isEditing.value,
            };
          }
        }
        else {
          academicAutoFilledFields.value[index] = {
            ...academicAutoFilledFields.value[index],
            [key]: !isEmpty(value) && !isEditing.value,
          };
        }
      }
    });
  });

  if (!isEditing.value)
    academicPayload.value = [...education];

  if (isEditing.value)
    academicEditPayload.value = [...education];

  return data;
}

function setupWorkExperienceFromCreatePerson(workExperiences: Person["work_experience"]) {
  return concat<Person["work_experience"]>(workExperiencePayload.value, workExperiences);
}

function flagParsedWorkExperience(workExperience: WorkExperience) {
  return {
    ...workExperience,
    is_parsed: true,
  };
}

function setupWorkExperienceFromEditPerson(workExperiences: Person["work_experience"]) {
  return concat<Person["work_experience"]>(
    workExperiencePayload.value,
    map(workExperiences, flagParsedWorkExperience),
  );
}

function getParsedWorkExperiencesIndex(workExperience: WorkExperience) {
  return findIndex(workExperiencePayload.value, workExperience);
}

function setupWorkExperienceAutoFilledFields(workExperiences: Person["work_experience"]) {
  workExperiences
    && workExperiences.forEach((workExperienceItem: WorkExperience) => {
      const indexKey = getParsedWorkExperiencesIndex(workExperienceItem);
      forEach(workExperienceItem, (value, key) => {
        if (datesKeys.includes(key.toString())) {
          if (key.toString() === "start_date") {
            set(workExperienceAutoFilledFields.value, indexKey, {
              ...get(workExperienceAutoFilledFields.value, indexKey),
              start_year: !isEmpty(value),
              start_month: !isEmpty(value),
            });
          }
          else {
            set(workExperienceAutoFilledFields.value, indexKey, {
              ...get(workExperienceAutoFilledFields.value, indexKey),
              end_year: !isEmpty(value),
              end_month: !isEmpty(value),
            });
          }
        }
        else {
          set(workExperienceAutoFilledFields.value, indexKey, {
            ...get(workExperienceAutoFilledFields.value, indexKey),
            [key]: !isEmpty(value),
          });
        }
      });
    });
}

function orderWorkExperience() {
  workExperiencePayload.value = orderBy(
    workExperiencePayload.value,
    ["is_current", "end_date"],
    ["desc", "desc"],
  );
}

function addParsedWorkExperience(data: Partial<Person>) {
  const { work_experience } = get(data, "person");

  workExperiencePayload.value = isEditing.value
    ? setupWorkExperienceFromEditPerson(work_experience)
    : setupWorkExperienceFromCreatePerson(work_experience);

  orderWorkExperience();

  setupWorkExperienceAutoFilledFields(work_experience);

  return data;
}

function addParsedPersonalInfo(data: Partial<PersonPayload>) {
  const person = get(data, "person");

  forEach(personalPayload.value, (value, key) => {
    if (!isEditing.value && !isEmpty(person[key])) {
      if (key === "address" && isEmpty(person[key].name))
        return;

      personalPayload.value = {
        ...personalPayload.value,
        [key]: person[key],
      };
    }

    if (isEditing.value) {
      personalEditPayload.value = {
        ...personalEditPayload.value,
        [key]: person[key],
      };

      personalAutoFilledEditFields.value = {
        ...personalAutoFilledEditFields.value,
        [key]: !isEmpty(person[key]),
      };
    }

    personalAutoFilledFields.value = {
      ...personalAutoFilledFields.value,
      [key]: !isEmpty(person[key]) && !isEditing.value,
    };
  });

  return data;
}

const hasUploadError = ref(false);
const autoFilled = ref(false);
const personalBeforeParseState = ref();
const experienceBeforeParseState = ref();
const academicBeforeParseState = ref();
const certificationsBeforeParseState = ref();
const languagesBeforeParseState = ref();

async function onResumeParse() {
  isLoading.value = true;
  hasUploadError.value = false;
  personalBeforeParseState.value = cloneDeep(personalPayload.value);
  experienceBeforeParseState.value = cloneDeep(workExperiencePayload.value);
  academicBeforeParseState.value = cloneDeep(academicPayload.value);
  certificationsBeforeParseState.value = cloneDeep(certificationPayload.value);
  languagesBeforeParseState.value = cloneDeep(languagePayload.value);
  try {
    const data = await resumeParse(cvData.value.file);
    pipe(
      addParsedPersonalInfo,
      addParsedWorkExperience,
      addParsedAcademic,
      addParsedCertification,
      addParsedLanguages,
    )(data);
    isLoading.value = false;
    autoFilled.value = true;
  }
  catch (error) {
    hasUploadError.value = true;
    isLoading.value = false;
  }
}

function clearParseAutocomplete() {
  personalAutoFilledFields.value = {};
  workExperienceAutoFilledFields.value = [];
  academicAutoFilledFields.value = [];
  certificationAutoFilledFields.value = [];
  languageAutoFilledFields.value = [];

  personalAutoFilledEditFields.value = {};
  workExperienceAutoFilledEditFields.value = [];
  academicAutoFilledEditFields.value = [];
  certificationAutoFilledEditFields.value = [];
  languageAutoFilledEditFields.value = [];

  personalEditPayload.value = {};
  workExperienceEditPayload.value = [];
  academicEditPayload.value = [];
  certificationEditPayload.value = [];
  languageEditPayload.value = [];

  personalPayload.value = personalBeforeParseState.value;
  workExperiencePayload.value = experienceBeforeParseState.value;
  academicPayload.value = academicBeforeParseState.value;
  certificationPayload.value = certificationsBeforeParseState.value;
  languagePayload.value = languagesBeforeParseState.value;
  autoFilled.value = false;
}
</script>

<template>
  <div class="main-wrapper-box">
    <div class="main-wrapper-content">
      <div id="create-person-wrapper">
        <ContentHeader :title="pageTitle">
          <template #icon>
            <PersonIcon />
          </template>
        </ContentHeader>

        <div class="wrapper-form">
          <div class="form-section">
            <div class="first-section">
              <PersonalDataForm
                :personal="personalPayload"
                :personal-edit="personalEditPayload"
                :auto-filled-fields="personalAutoFilledFields"
                :auto-filled-edit-fields="personalAutoFilledEditFields"
                space-end="separate"
                @hasEmail="cantSave($event)"
              />

              <WorkExperienceForm
                :work-experiences="workExperiencePayload"
                :auto-filled-fields="workExperienceAutoFilledFields"
                space-end="separate"
                class="form"
                @click:add="appendWorkExperience()"
                @click:remove="removeFromList('work_experience', $event)"
              />

              <AcademicForm
                :academic="academicPayload"
                :academic-edit="academicEditPayload"
                :auto-filled-fields="academicAutoFilledFields"
                :auto-filled-edit-fields="academicAutoFilledEditFields"
                class="form"
                data-test="academic-form-group"
                @click:add="appendEducation()"
                @click:remove="removeFromList('academic', $event)"
                @click:removeEdit="removeFromEditList('academic', $event)"
              />

              <LanguageForm
                :languages="languagePayload"
                :languages-edit="languageEditPayload"
                :auto-filled-fields="languageAutoFilledFields"
                :auto-filled-edit-fields="languageAutoFilledEditFields"
                class="form"
                data-test="language-form-group"
                @click:add="appendLanguage()"
                @click:remove="removeFromList('language', $event)"
                @click:removeEdit="removeFromEditList('language', $event)"
              />

              <CertificationForm
                :certifications="certificationPayload"
                :certifications-edit="certificationEditPayload"
                :auto-filled-fields="certificationAutoFilledFields"
                :auto-filled-edit-fields="certificationAutoFilledEditFields"
                space-end="separate"
                class="form"
                @click:add="appendCertification()"
                @click:remove="removeFromList('certification', $event)"
                @click:removeEdit="removeFromEditList('certification', $event)"
              />

              <ExtraActivitiesForm
                :extra-activities="extraActivitiesPayload"
                space-end="separate"
                class="form"
                @click:add="appendExtraActivity($event)"
                @click:remove="removeExtraActivity($event)"
              />
            </div>

            <div class="second-section">
              <AvatarForm v-model:data="avatarFile" />
              <UploadCVForm v-model="cvData" />
            </div>
          </div>

          <div class="button-wrapper">
            <Button class="color" :disabled="error" @click="onSubmit">
              {{ t("persons.createPersonForm.buttons.submit") }}
            </Button>

            <Button variation="light" filled @click="clearForm">
              {{ t("persons.createPersonForm.buttons.clear") }}
            </Button>
          </div>

          <p>{{ statusMessage }}</p>
        </div>
      </div>

      <Sidebar
        class="sidebar__custom"
        :visible="cvData?.url ? true : false"
        :resize="true"
        :on-close="onCloseSidebar"
        :prevent-close-outside="true"
      >
        <div class="sidebar-content">
          <h1>{{ cvData.file?.name }}</h1>
          <div class="sidebar-content__actions">
            <transition name="fade">
              <div class="loading">
                <Loading v-show="isLoading" :spin-time="2" />
              </div>
            </transition>
            <Button variation="secondary" :disabled="autoFilled" @click="onResumeParse()">
              {{ t("persons.forms.resume.importData") }}
            </Button>
            <Button :disabled="!autoFilled" filled @click="clearParseAutocomplete">
              {{ t("persons.forms.resume.discardData") }}
            </Button>
          </div>
          <iframe :src="cvData?.url" width="100%" height="100%" frameborder="0" />
        </div>
      </Sidebar>

      <Modal
        v-if="hasSubmitionError"
        :title="t('persons.createPersonForm.modal.submitFail.title')"
        :message="t('persons.createPersonForm.modal.submitFail.message')"
        :button-text="t('persons.createPersonForm.modal.submitFail.button')"
        :on-click="onSubmit"
        :on-close="() => (hasSubmitionError = false)"
        variation="warning"
        secondary-variation="light"
      >
        <template #titleIcon>
          <WarningIcon />
        </template>
      </Modal>

      <Modal
        v-if="conflictModal"
        :title="t('persons.createPersonForm.modal.submitFail.titleConflict')"
        :button-text="t('persons.createPersonForm.modal.submitFail.buttonConflict')"
        :on-click="() => (conflictModal = false)"
        :on-close="() => (conflictModal = false)"
        variation="warning"
        secondary-variation="light"
        message-class="modal-conflict-message"
        title-class="modal-conflict-title"
      >
        <template #message>
          <div>
            {{ t(`persons.createPersonForm.modal.submitFail.${conflictType || 'generic'}`) }}
          </div>
          <a v-if="conflictData" class="conflict-data" :href="conflictData.link" target="_blank">
            {{ conflictData.field }}
          </a>
        </template>
      </Modal>

      <Modal
        v-if="hasUploadError"
        :title="t('persons.createPersonForm.modal.uploadFail.title')"
        :message="t('persons.createPersonForm.modal.uploadFail.message')"
        :button-text="t('persons.createPersonForm.modal.uploadFail.button')"
        :on-click="onResumeParse"
        :on-close="() => (hasUploadError = false)"
        variation="warning"
        secondary-variation="light"
      >
        <template #titleIcon>
          <WarningIcon />
        </template>
      </Modal>
    </div>
  </div>
</template>

<route lang="yaml">
meta:
  layout: default-page
</route>

<style lang="scss" scoped>
.loading {
  z-index: 9;
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: #fff;
}

.main-wrapper-box {
  height: 100%;
  overflow-y: auto;

  #create-person-wrapper {
    width: 100%;
    margin-block-start: 6.875rem;

    .wrapper-form {
      display: flex;
      flex-direction: column;
      padding-top: 50px;
      padding-left: 20px;
      border-radius: 6px;
      background-color: white;

      .form {
        width: 570px;
        padding-top: 30px;
      }

      .form-section {
        display: flex;

        .second-section {
          display: flex;
          width: 100%;
          flex-direction: column;
          margin: 0 3rem;
          gap: 3rem;
        }
      }

      .button-wrapper {
        display: flex;
        max-width: 570px;
        justify-content: space-between;
        padding-top: 50px;
        padding-bottom: 50px;
      }

      .regular-button:nth-of-type(2) {
        margin-left: 10px;
      }
    }
  }

  .multiselect {
    min-height: 30px;

    .multiselect-dropdown {
      overflow-y: scroll;
    }
  }

  .remove-wrapper {
    display: flex;
    height: 35px;
    justify-content: flex-end;

    svg {
      height: 18px;
      stroke: $gray-light;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .button-wrapper {
    width: 100%;
    padding: 20px 0;

    button {
      width: 35%;
      border-color: $yellow-dark;
    }

    .color {
      color: $yellow-dark;
    }
  }
}

.form[space-end="separate"] {
  padding-bottom: 30px;
  border-bottom: 1px solid $gray-light;
}

.title-wrapper {
  display: flex;
  width: 100%;
  height: 50px;

  span {
    color: $primary;
    font-family: "Open Sans";
    font-size: 25px;
  }
}

.modal-conflict-message {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .conflict-data {
    border: 2px solid $gray-border;
    border-radius: 0.25rem;
    padding-inline: 1rem;
    margin-block: 0.5rem;
    display: block;
    text-align: center;
    text-decoration: none;

    &:hover {
      cursor: pointer;
      border-color: $gray-dark;
    }
  }
}

:deep(.modal-conflict-title) {
  font-size: 1.25rem;
  text-transform: uppercase;
  font-weight: 700;
}

.modal-icon {
  stroke: $red;
}

.modal-title {
  margin-left: 10px;
  color: $red;
}

.sidebar__custom-wrapper {
  position: relative;
}

.sidebar__custom {
  top: 6.875rem;
  right: 0;
  left: auto !important;
  width: 45.063rem;
  height: 100%;

  @media only screen and (max-width: 1921px) {
    width: 49.063rem;
  }

  @media only screen and (max-width: 1441px) {
    width: 42.063rem;
  }

  @media only screen and (max-width: 1281px) {
    width: 38.063rem;
  }

  @media only screen and (max-width: 1025px) {
    width: 25.063rem;
  }

  :deep(.sidebar-wrapper) {
    width: inherit;
    overflow-x: unset;
    overflow-y: unset;
  }
}

.sidebar-content {
  h1 {
    margin-bottom: 1rem;
  }

  height: 95%;

  &__actions {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;

    &> :first-child {
      margin-right: 15px;
    }

    &> :nth-child(2) {
      margin-right: 15px;
    }
  }
}

:deep(.overlay) {
  background-color: transparent;
}
</style>
