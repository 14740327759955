<script setup lang="ts" generic="T extends any">
import type { IDatagridFilter } from "@/components/datagrid/DataGridFilter.vue";
import DataGridFilter from "@/components/datagrid/DataGridFilter.vue";

export interface DataGridHeader<I = unknown> {
  disableFilter?: boolean
  disableSort?: boolean
  beforeApplyFilters?: (filter: IDatagridFilter) => void
  filterFn?: (value: string | undefined | number | null, item: I, isForInternalFilter?: boolean) => boolean
  id?: string
  minSize?: number
  name: string
  size?: number
  filterItems?: { value: string | undefined | null | number; label: string }[]
  sortingFn?: (itemA: I, itemB: I) => -1 | 0 | 1
  value(item: I): string | undefined | number | null
  forInitialValue?(item: I): string | undefined | number | null
}

export interface InitialSorter {
  column: string
  order: string | null
}

export type DataGridHeaderObject<T = unknown> = Record<string, DataGridHeader<T>>;

export interface DataGridRow<I> { item: I; value: string };

interface Props {
  loading?: boolean
  items: Array<T>
  headers: DataGridHeaderObject<T>
  initialSort: Array<InitialSorter> | null
}

const props = withDefaults(defineProps<Props>(), {
  loading: false,
  items: [] as any,
  initialSort: null,
});

const emit = defineEmits(["clear-filter", "change-criteria"]);

const route = useRoute();

const filteredItems = computed(() => {
  let allItems = [...props.items];
  const { sort, column, search, extra } = route.query;
  const header = (props.headers)[column];
  const parsedExtra = JSON.parse(extra?.toString() ?? "{}");

  if (extra?.length && !!parsedExtra) {
    allItems = allItems.filter((item) => {
      const value = header.value(item);
      return header.filterFn?.(value, item, false);
    });
  }

  if (search?.length && !!parsedExtra) {
    allItems = allItems.filter((item) => {
      const value = header.value(item);
      return search.includes(String(value));
    });
  }

  if (sort) {
    allItems.sort((itemA, itemB) => {
      const valueA = String(header.value(itemA)).toLowerCase();
      const valueB = String(header.value(itemB)).toLowerCase();

      if (header.sortingFn)
        return header.sortingFn(itemA, itemB);

      if ((valueA === "" || valueA === "0") && !!valueB)
        return 1;

      if ((valueB === "" || valueB === "0") && !!valueA)
        return -1;

      if (valueA > valueB)
        return 1;

      if (valueA < valueB)
        return -1;

      return 0;
    });

    if (sort === "desc")
      allItems.reverse();
  }
  else if (props.initialSort?.length) {
    allItems.sort((itemA, itemB) => {
      for (const {
        column,
        order,
      } of props.initialSort) {
        const header = (props.headers)[column];
        const aValue = header.forInitialValue ? header.forInitialValue(itemA) : header.value(itemA);
        const bValue = header.forInitialValue ? header.forInitialValue(itemB) : header.value(itemB);

        if ((aValue === "" || aValue === "0") && !!bValue)
          return order === "asc" ? 1 : -1;

        if ((bValue === "" || bValue === "0") && !!aValue)
          return order === "asc" ? -1 : 1;

        // Compare based on the sorting order
        if (aValue < bValue)
          return order === "asc" ? -1 : 1;
        if (aValue > bValue)
          return order === "asc" ? 1 : -1;
      }

      // If all keys are the same, return 0
      return 0;
    });
  }

  return allItems;
});
</script>

<template>
  <div class="wrapper">
    <table>
      <thead>
        <tr>
          <th class="counter" />
          <template v-for="(header, key) in headers" :key="key">
            <th :style="`min-width: ${header.minSize}px; width: ${header.size}px;`">
              <div class="head-cell">
                <div class="title">
                  <slot :name="`head[${key}]`">
                    <span v-text="header.id || header.name || key" />
                  </slot>
                </div>

                <template v-if="!header.disableSort">
                  <DataGridFilter
                    :header="header"
                    :items="props.items"
                    :name="key"
                    @change-criteria="data => emit('change-criteria', data)"
                    @clear="column => emit('clear-filter', column)"
                  >
                    <template #head="{ item, value }">
                      <slot :name="`filter-head[${key}]`" v-bind="{ item, value }" />
                    </template>

                    <template #item="{ item, value }">
                      <slot :name="`filter[${key}]`" v-bind="{ item, value }" />
                    </template>
                  </DataGridFilter>
                </template>
              </div>
            </th>
          </template>
        </tr>
      </thead>
      <tbody>
        <tr style="height: 3px" />
        <template v-for="(item, key) in filteredItems" :key="key">
          <tr>
            <td class="counter" v-text="key + 1" />

            <template v-for="(header, hKey) in headers" :key="`body-${hKey}`">
              <td>
                <slot :name="`row[${hKey}]`" v-bind="{ item, value: header.value(item) }">
                  <span v-text="header.value(item)" />
                </slot>
              </td>
            </template>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-flow: column;
  background: white;
  overflow-x: auto;
  height: calc(100vh - 106px - 83px);
}

table {
  background-color: $white-disabled;
  border-spacing: 1px;
  border-collapse: separate;

  thead {
    position: sticky;
    top: 0;
    z-index: 1;
    height: 3.56rem;
    background-color: $white-lotion;
    box-shadow: 0 3px 6px #00000029;

    th {
      min-width: 1em;
      padding: 0 1em;

      &.counter {
        border-right-width: 1px;
        border-right-style: solid;
        border-right-color: rgba(190, 190, 190, 0.61);

        position: relative;
        width: 25px;
      }

      .head-cell {
        position: relative;
        display: flex;
        justify-content: space-between;
        width: 100%;

        .title {
          flex: auto;
          text-wrap: nowrap;
        }
      }
    }
  }

  tbody {
    background-color: $white-full;

    tr {
      height: 4.1875rem;

      td {
        text-align: center;
        position: relative;
        height: inherit;

        &.counter {
          background: $gray-x13 0 0 no-repeat padding-box;
          border: 1px solid $gray-x13;
        }
      }
    }
  }

}
</style>
