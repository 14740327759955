<script setup lang="ts">
import { defineEmits, defineProps, ref } from "vue";
import { useI18n } from "vue-i18n";
import { ButtonIcon } from "@/components/button";
import { Tooltip } from "@/components/tooltip";
import { SvgIcon } from "@/components/icon";

defineProps({
  svgIcon: String,
  showIcon: {
    type: Boolean,
    default: false,
  },
});

defineEmits(["click"]);

const { t } = useI18n();

const showTooltip = ref(false);
</script>

<template>
  <Tooltip v-show="showTooltip" :tooltip-text="t('text.tooltip')" x-align="center">
    <ButtonIcon
      v-if="showIcon && svgIcon"
      @click.prevent="$emit('click')"
      @mouseenter="showTooltip = true"
      @mouseleave="showTooltip = false"
    >
      <div class="icon-wrapper">
        <SvgIcon :icon="svgIcon" width="20" height="20" margin="none" />
      </div>
    </ButtonIcon>
  </Tooltip>
</template>

<style lang="scss" scoped>
  button {
    min-width: 2rem;
    height: 1.8rem;
    border: 1px solid $gray-border;
    border-radius: 6px;
    margin-left: 0.563rem;

    &:hover {
      border: 1px solid $red-violet-medium;
      margin-left: 0.125rem;
      background-color: $red-violet-medium;

      svg {
        color: $orange-medium;
      }
    }
  }

  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    color: inherit;
  }
</style>
