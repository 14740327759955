<script lang="ts">
import {
  computed,
  defineComponent,
  ref,
  watchEffect,
} from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "TextExpand",
  props: {
    text: String,
    expandButtonLabel: String,
    maxLines: {
      required: false,
      type: Number,
      default: 3,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const textview = ref<HTMLParagraphElement>(null);
    const expanded = ref(true);
    const toggleExpand = () => {
      expanded.value = !expanded.value;
    };
    const pStyle = ref({});
    const pLineHeight = 16;
    const lines = computed(() => textview.value.offsetHeight / pLineHeight);

    watchEffect(
      () => {
        if (lines.value > props.maxLines && expanded.value)
          pStyle.value["max-height"] = `${props.maxLines}rem`;
        else if (lines.value <= props.maxLines)
          expanded.value = false;
        else pStyle.value["max-height"] = `${lines.value}rem`;
      },
      {
        flush: "post",
      },
    );

    return {
      toggleExpand,
      expanded,
      t,
      textview,
      pStyle,
    };
  },
});
</script>

<template>
  <div class="text-expand">
    <p ref="textview" :style="pStyle" v-html="text?.replaceAll('\n', '<br />')" />
    <span>
      <a v-if="expanded" @click="toggleExpand">
        {{ expandButtonLabel || t("text.textExpand.expandButton") }}
      </a>
    </span>
  </div>
</template>

<style scoped lang="scss">
.text-expand {
  position: relative;
  display: inline-block;
  width: 100%;

  p {
    line-height: 16px;
    overflow-y: hidden;
    transition: all ease-in-out 300ms;
  }

  span {
    a {
      position: absolute;
      right: 0;
      bottom: -20px;
      justify-content: center;
      color: $yellow-dark;
      cursor: pointer;
    }
  }
}
</style>
