import type {
  ComputedRef,
  Ref,
} from "vue";
import {
  computed,
  ref,
  watch,
} from "vue";
import type { Router } from "vue-router";
import type { Person } from "@/entities/Person/person";
import { getPerson } from "@/http_services/entities/persons";
import { AboutTab, AcademicFormationTab, ExtraActivitiesTab, WorkExperienceTab } from "@/persons/pages/persons/components/Tabs";

type translator = (string) => string;
interface tabGenerator {
  currentTab: Ref<string>
  tabs: ComputedRef
  availableTabs: ComputedRef<{ [key: string]: string }>
};

export function generateTabs(t: translator): tabGenerator {
  const currentTab = ref("about");
  const tabs = computed(() => [
    {
      id: "about",
      header: t("persons.details.tabs.about"),
      component: AboutTab,
    },
    {
      id: "work-experience",
      header: t("persons.details.tabs.workExperience"),
      component: WorkExperienceTab,
    },
    {
      id: "academic-formation",
      header: t("persons.details.tabs.academicFormation"),
      component: AcademicFormationTab,
    },
    {
      id: "extra-activities",
      header: t("persons.details.tabs.extraActivities"),
      component: ExtraActivitiesTab,
    },
  ]);
  const availableTabs = computed(() => tabs.value.reduce((map, next) => {
    map[next.id] = true;
    return map;
  }, {}));

  watch(currentTab, (value) => {
    if (availableTabs.value[value] && window)
      window.location.hash = `#${value}`;
  });

  return {
    currentTab,
    tabs,
    availableTabs,
  };
}

export function generatePersonApiMethods(router: Router) {
  const person = ref<Person>(null);

  const fetchPerson = async (personId: string) => {
    try {
      person.value = await getPerson(personId);
    }
    catch (error) {
      if (error?.status === 400)
        router.push({ name: "404" });
      else
        console.log(error);
    }
  };

  return {
    person,
    fetchPerson,
  };
}
