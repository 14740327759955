import type { App } from "vue";

declare global {
  interface Window {
    dataLayer: unknown[]
    gtag: (...args: unknown[]) => void
  }
}

interface GTAG {
  gtagId: string
}

export default {
  install: (app: App, options: GTAG): void => {
    (function () {
      const script = document.createElement("script");
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=${options?.gtagId}}`;
      document.head.appendChild(script);
    })();

    window.dataLayer = window.dataLayer || [];
    window.gtag = function (...args: unknown[]): void {
      window.dataLayer.push(args);
    };

    if (options?.gtagId)
      window.gtag("config", options.gtagId);

    app.config.globalProperties.$gtag = window.gtag;
  },
};
