<script setup lang="ts">
import { defineProps, ref } from "vue";
import { mdiChevronDown } from "@mdi/js";
import { SvgIcon } from "@/components/icon";

const props = defineProps<{ isEdditing: boolean }>();
const hovered = ref(false);

const chevronStyle = computed(() => {
  return {
    transform: props.isEdditing ? "rotate(180deg)" : "",
    color: props.isEdditing ? "#ffb600" : "#c0c0c0",
    borderColor: props.isEdditing ? "#ffb600" : "#c0c0c0",
  };
});
</script>

<template>
  <div
    class="weight-button"
    :style="{ borderColor: chevronStyle.borderColor }"
    @mouseenter="hovered = true"
    @mouseleave="hovered = false"
  >
    <div>
      <SvgIcon
        :style="{ color: chevronStyle.color }"
        icon="ranking"
        width="1.5rem"
        height="1.5rem"
        margin="none"
      />
    </div>
    <SvgIcon
      :style="{ color: chevronStyle.color, transform: chevronStyle.transform }"
      class="chevron"
      type="mdi"
      :icon="mdiChevronDown"
      width="1.5rem"
      height="1.5rem"
      margin="none"
    />
  </div>
</template>

<style scoped lang="scss">
.weight-button {
  display: flex;
  height: 2.5rem;
  align-items: center;
  justify-content: center;
  padding-left: 0.125rem;
  border: 1px solid $gray-border;
  border-radius: 0.375rem;
  color: $gray-border;
  cursor: pointer;
  transition: 200ms;

  svg {
    color: $gray-border;

  }

  &:hover {
    border-color: $secondary;
    color: $secondary;

    svg {
      color: $secondary;
    }
  }
}
</style>
