import { activitiesService } from "@/http_services/client";
import { parseParams } from "@/utils/parsers";

export interface ActivitiesSearchType {
  project_id: string
  candidate_person_id?: string
  recruiter_person_id?: string
}

export async function getInterviewsActivities(query: ActivitiesSearchType) {
  const params = parseParams(query);
  return activitiesService.get("interviews/activities/search", { params })
    .then(res => res.data?.data);
}

export async function addInterviewsActivity(activity: any) {
  return activitiesService.post("interviews/activities", { activity })
    .then(res => res.data?.data);
}

export async function getInterviewActivity(id: string) {
  return activitiesService.get(`interviews/activities/${id}`)
    .then(res => res.data?.data);
}

export async function updateInterviewActvity(id: string, activity: any) {
  return activitiesService.put(`interviews/activities/${id}`, { activity })
    .then(res => res.data?.data);
}

export async function patchInterviewAnnotations(id: string, annotations: any) {
  return activitiesService.patch(`interviews/activities/${id}/annotations`, { annotations })
    .then(res => res.data?.data);
}
