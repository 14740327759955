<script lang="ts">
import type { Ref } from "vue";
import { useI18n } from "vue-i18n";
import { getOr } from "lodash/fp";
import type { Person } from "@/schemas/person";
import { Avatar } from "@/components/avatar";
import SchoolIcon from "@/assets/images/school.svg";
import { formatMonthAndYear } from "@/utils/dates";

export default defineComponent({
  name: "Certifications",
  components: {
    Avatar,
    SchoolIcon,
  },
  setup() {
    const { t, rt } = useI18n();
    const person = (inject("selectedPerson") || inject("person")) as Ref<Person>;

    const certifications = getOr([], "certifications", person.value);

    certifications.sort((a, b) => {
      if (a.expiration_date && b.expiration_date)
        return a.expiration_date >= b.expiration_date ? -1 : 1;

      if (a.expiration_date)
        return -1;
      if (b.expiration_date)
        return 1;

      if (a.expedition_date && b.expedition_date)
        return a.expedition_date >= b.expedition_date ? -1 : 1;

      if (a.expedition_date)
        return -1;
      if (b.expedition_date)
        return 1;

      return 0;
    });

    return {
      certifications,
      t,
      rt,
      formatDate: formatMonthAndYear,
    };
  },
});
</script>

<template>
  <div v-if="certifications?.length" class="group-wrapper">
    <h4>
      {{ t("persons.certifications", { count: certifications.length }) }}
    </h4>
    <div
      v-for="(certification, index) of certifications"
      :key="`certification-${index}`"
      class="certification-wrapper"
    >
      <div class="avatar-icon">
        <Avatar
          v-if="certification?.institution?.corporation_data"
          :src="certification.institution.corporation_data.avatar_url"
          :alt="certification.institution.corporation_data.avatar_url"
          size="71px"
        />
        <SchoolIcon v-else class="icon" />
      </div>
      <div class="content">
        <p>
          {{ certification.name }}
        </p>
        <p v-if="certification.institution">
          {{ certification.institution.name || certification.institution?.corporation_data?.name }}
        </p>
        <p>{{ certification.field }}</p>
        <p>
          {{ t("persons.certificationForm.expeditionDate") }}
          :
          <span v-if="certification.expedition_date">
            {{ formatDate(certification.expedition_date) }}
          </span>
        </p>
        <p>
          {{ t("persons.certificationForm.expirationDate") }}
          :
          <span v-if="certification.expiration_date">
            {{ formatDate(certification.expiration_date) }}
          </span>
          <span v-if="certification.is_lifelong">
            {{ t("persons.certificationForm.withoutExpirationDate") }}
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped="true" lang="scss">
.group-wrapper {
  margin-bottom: 1rem;

  h4 {
    margin-top: 2rem;
    margin-bottom: 2rem;
    color: $yellow-dark;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
  }
}

.certification-wrapper {
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-top: 30px;
  }

  .avatar-icon {
    display: flex;
    width: 71px;
    height: 71px;
    align-items: center;
    justify-content: center;
    border-radius: 150px;
    background: #cecfd0;
  }

  .content {
    position: relative;
    flex: 1;
    margin-left: 20px;

    &::after {
      position: absolute;
      bottom: -20px;
      width: 100%;
      height: 1px;
      background: #e9e5e5;
      content: " ";
    }

    p {
      margin-top: 5px;
      color: $gray-dark;
      font-size: 14px;
      font-weight: bold;
    }
  }
}
</style>
