export const login = {
  welcome: "Bem-vindo",
  passwordConfirmation: "Confirmar senha",
  passwordNew: "Senha",
  charactersQuantity: "{quantity} caracteres",
  letterQuantity: "{quantity} letra",
  numberQuantity: "{quantity} número",
  specialCharQuantity: "{quantity} caractere especial (#&*)",
  submitButton: "Entrar",
  loginError: "E-mail ou senha inválidos",
  forgotPassword: "Esqueceu a senha?",
  definePassword: {
    title: "Defina sua senha",
    createSuccess: "Sua senha foi definida!",
    matchError: "A senha e a confirmação de senha devem ser iguais",
    passwordAlreadyDefined: "Você já definiu sua senha",
    requestError: "Ocorreu um erro. Tente novamente mais tarde",
    requestTokenError: "Ocorreu um erro. Tente novamente mais tarde",
  },
  logout: "Sair",
};
