<script setup lang="ts">
import { RadioSimple } from "@/components/radio";
import AlertIcon from "@/assets/images/alert_exclamation_icon.svg";
import InterviewSliderEvaluation from "@/projects/pages/projects/[id]/interviews/components/InterviewSliderEvaluation.vue";

const props = defineProps<{
  showWarningSlider: boolean
  accordionOpened: string | null
}>();

const emit = defineEmits(["pending"]);
const route = useRoute();

interface AttributesEvaluation { cognitive_intelligence: EvaluationType; emotional_intelligence: EvaluationType; spiritual_intelligence: EvaluationType; energy: EvaluationType; maturity: EvaluationType }
interface CulturalMatchEvaluation { practical: EvaluationType; logical: EvaluationType; reflexive: EvaluationType; affective: EvaluationType }

interface AttributesRadio { cognitive_intelligence: valueMapKey; emotional_intelligence: valueMapKey; spiritual_intelligence: valueMapKey; energy: valueMapKey; maturity: valueMapKey }
interface CulturalMatchRadio { practical: valueMapKey; logical: valueMapKey; reflexive: valueMapKey; affective: valueMapKey }

interface EvaluationType {
  observations: string
  level: valueMapKey | null
  score: number | null
}
enum WorkModelEnum {
  REMOTE = "REMOTE",
  ONSITE = "ONSITE",
  HYBRID = "HYBRID",
}
enum WillingToRelocateEnum {
  YES = "YES",
  NO = "NO",
}

type InterviewKey = keyof typeof interviewMap;

interface PackageType {
  salary: string
  long_term_incentive: string
  short_term_incentive: string
  other_incentive: string
  total_cash: string
  score: number | null
  observations: string
  attraction: string
  retention: string
  repulsion: string
}
// TODO: short this to reuse
type valueMapKey = keyof typeof valueMap | null;
const valueMap = {
  EMPTY: 0,
  LOW: 1,
  AVERAGE: 2,
  HIGH: 3,
  VERY_HIGH: 4,
};

interface AttributesRadio { cognitive_intelligence: valueMapKey; emotional_intelligence: valueMapKey; spiritual_intelligence: valueMapKey; energy: valueMapKey; maturity: valueMapKey }
interface CulturalMatchRadio { practical: valueMapKey; logical: valueMapKey; reflexive: valueMapKey; affective: valueMapKey }
interface MotivationType { score: number; observations: string; attraction: string; retention: string; repulsion: string }
interface AttributesType { [ key: string ]: { score: number | null; observations: string; level: string } }
interface UniqueIdMap { [ key: string ]: { score: number | null; observations: string } }

const accordions = ref<Record<string, boolean>>({
  academic_backgrounds: false,
  languages: false,
  experiences: false,
  achievements: false,
  attributes: false,
  cultural_match: false,
  motivation: false,
});

const {
  data: construction,
} = useConstructionOrganizationQuery(String(route.params.id));

const {
  data: idealProfile,
  refetch: refetchIdealProfile,
} = useConstructionIdealProfile(String(route.params.id));

const {
  data: interview,
  refetch: refetchInterview,
} = useInterviewEvaluationQuery(String(route.params.id), String(route.params.person));

const {
  mutate: patchInterview,
} = usePatchInterviewEvaluationQuery();

const willingToRelocateValue = WillingToRelocateEnum;
const workModelType = WorkModelEnum;

const { t, tm } = useI18n();

const workModelIntl = computed(() => tm("projects.details.activities.screening.workModelEnum"));
const willingToRelocateIntl = computed(() => tm("projects.details.activities.screening.willingToRelocateEnum"));

const interviewId = computed(() => {
  return interview.value?._id;
});

const motivationMap = {
  attraction: "",
  repulsion: "",
  retention: "",
};

const packageMap = {
  salary: "",
  short_term_incentive: "",
  long_term_incentive: "",
  other_incentive: "",
  total_cash: "",
  ...motivationMap,
};

const constructionAttributes = ref({
  cognitive_intelligence: null,
  emotional_intelligence: null,
  spiritual_intelligence: null,
  energy: null,
  maturity: null,
} as AttributesRadio);

const constructionCulturalMatch = ref({
  practical: null,
  logical: null,
  reflexive: null,
  affective: null,
} as CulturalMatchRadio);

const academicBackground: Ref<UniqueIdMap | undefined> = ref();
const languageEvaluation: Ref<UniqueIdMap | undefined> = ref();
const experienceEvaluation: Ref<UniqueIdMap | undefined> = ref();
const achievementsEvaluation: Ref<UniqueIdMap | undefined> = ref();
const constructionEvaluations: any = ref();

const attributesEvaluation = ref({
  cognitive_intelligence: {
    observations: "",
    level: null,
    score: null,
  },
  emotional_intelligence: {
    observations: "",
    level: null,
    score: null,
  },
  spiritual_intelligence: {
    observations: "",
    level: null,
    score: null,
  },
  energy: {
    observations: "",
    level: null,
    score: null,
  },
  maturity: {
    observations: "",
    level: null,
    score: null,
  },
} as AttributesEvaluation);

const culturalMatchEvaluation = ref({
  practical: {
    observations: "",
    level: null,
    score: null,
  },
  logical: {
    observations: "",
    level: null,
    score: null,
  },
  reflexive: {
    observations: "",
    level: null,
    score: null,
  },
  affective: {
    observations: "",
    level: null,
    score: null,
  },
} as CulturalMatchEvaluation);

const motivationEvaluation: Ref<PackageType> = ref({
  ...packageMap,
  score: 0,
  observations: "",
});
const careerEvaluation: Ref<MotivationType> = ref({
  ...motivationMap,
  score: 0,
  observations: "",
});
const environmentEvaluation: Ref<MotivationType> = ref({
  ...motivationMap,
  score: 0,
  observations: "",
});
const personalEvaluation: Ref<MotivationType> = ref({
  ...motivationMap,
  score: 0,
  observations: "",
});

const workModelTypeValue: Ref<string | undefined> = ref();
const willingToRelocate: Ref<WillingToRelocateEnum> = ref(willingToRelocateValue.NO);

const interviewMap = {
  academic_backgrounds: academicBackground,
  languages: languageEvaluation,
  experiences: experienceEvaluation,
  achievements: achievementsEvaluation,
  attributes: attributesEvaluation,
  cultural_match: culturalMatchEvaluation,
  package: motivationEvaluation,
  career: careerEvaluation,
  work_environment: environmentEvaluation,
  personal_life: personalEvaluation,
  willing_to_relocate: willingToRelocate,
  work_model: workModelTypeValue,
};

const showWarning = ref();

function updateAttribute(key: keyof AttributesEvaluation, value: Partial<EvaluationType>) {
  attributesEvaluation.value = {
    ...attributesEvaluation.value,
    [key]: {
      ...attributesEvaluation.value[key],
      ...value,
    },
  };
  patchInterview({
    _id: interviewId.value,
    attributes: attributesEvaluation.value,
  });
}

function updateCulturalMatch(key: keyof CulturalMatchEvaluation, value: Partial<EvaluationType>) {
  culturalMatchEvaluation.value = {
    ...culturalMatchEvaluation.value,
    [key]: {
      ...culturalMatchEvaluation.value[key],
      ...value,
    },
  };
  patchInterview({
    _id: interviewId.value,
    cultural_match: culturalMatchEvaluation.value,
  });
}

// TODO: remove conditions, use separate saves
function update(key: InterviewKey) {
  if (key === "willing_to_relocate") {
    patchInterview({
      _id: interviewId.value,
      [key]: willingToRelocate.value ?? "EMPTY",
    });
    return;
  }
  if (key === "work_model") {
    patchInterview({
      _id: interviewId.value,
      [key]: workModelTypeValue.value ?? "EMPTY",
    });
    return;
  }
  if (key === "cultural_match" || key === "attributes") {
    const attrKeyMap = {
      attributes: attributesEvaluation.value,
      cultural_match: culturalMatchEvaluation.value,
    };
    patchInterview({
      _id: interviewId.value,
      [key]: attrKeyMap[key],
    });
    return;
  }
  patchInterview({
    _id: interviewId.value,
    [key]: interviewMap[key].value,
  });
}

function focusAccordeon(key: string) {
  setTimeout(() => {
    const divFocus = document.getElementById(key);
    if (divFocus)
      divFocus?.scrollIntoView({ behavior: "smooth" });
  }, 250);
}

function toggle(key: string) {
  accordions.value = {
    ...accordions.value,
    [key]: !accordions.value[key],
  };
  refetchInterview();
  refetchIdealProfile();

  if (accordions.value[key])
    focusAccordeon(key);
}

function closeAll() {
  accordions.value = {};
}

// TODO: remove this
function filteredEmptyData(acc: { [key: string]: string }) {
  return Object.fromEntries(
    Object.entries(acc)
      .filter(([_, value]) => value !== "EMPTY"),
  );
}

function showBeforeUnloadModal(event) {
  event.preventDefault();
  event.returnValue = "";
}

window.addEventListener("beforeunload", showBeforeUnloadModal);

onBeforeUnmount(() => {
  window.removeEventListener("beforeunload", showBeforeUnloadModal);
});

// TODO: move to reactQuery
watchEffect(() => {
  if (idealProfile.value) {
    constructionAttributes.value = filteredEmptyData(idealProfile.value.attributes);
    constructionCulturalMatch.value = filteredEmptyData(idealProfile.value.cultural_match);
  }

  if (interview.value) {
    // TODO: remove this
    attributesEvaluation.value = { ...interview.value.attributes };
    // TODO: remove this
    culturalMatchEvaluation.value = { ...interview.value.cultural_match };

    academicBackground.value = toRaw(interview.value.academic_backgrounds);
    languageEvaluation.value = toRaw(interview.value.languages);
    experienceEvaluation.value = toRaw(interview.value.experiences);
    achievementsEvaluation.value = toRaw(interview.value.achievements);
    motivationEvaluation.value = toRaw(interview.value.package);
    careerEvaluation.value = toRaw(interview.value.career);
    environmentEvaluation.value = toRaw(interview.value.work_environment);
    personalEvaluation.value = toRaw(interview.value.personal_life);
    willingToRelocate.value = interview.value.willing_to_relocate;
    workModelTypeValue.value = interview.value.work_model;
  }
  if (construction.value)
    constructionEvaluations.value = construction.value?.ideal_profile;

  if (props.accordionOpened)
    accordions.value[props.accordionOpened] = true;
});

function isEvaluationPresent(key: string) {
  if (constructionEvaluations.value) {
    const evaluationValues = constructionEvaluations.value[key];
    switch (key) {
      case "attributes":
      case "cultural_match":
        return !Object.values(evaluationValues).every(value => value === "EMPTY");
      case "package":
        return Object.values(evaluationValues).some(value => value !== "");
      default:
        return Object.keys(evaluationValues)?.length;
    }
  }
}
function renderWarning({ keys }: { keys: { key: string; i18nSectionKey: string } }) {
  return !isEvaluationPresent(keys.key)
    ? h("p", { class: "warning-return" }, [
      h(AlertIcon, { class: "icon-warning" }),
      t("projects.screening.guide.PostScreening.onConstructionNotFound", { section: t(`projects.screening.guide.accordeons.${keys.i18nSectionKey}`) }),
    ])
    : null;
}

defineExpose({
  interview,
});
</script>

<template>
  <div v-if="construction && interview && idealProfile" class="organization-wrapper">
    <BoxAccordion
      id="academic_background"
      :title="t('projects.screening.guide.accordeons.educationalBackground')"
      icon="project_academic"
      :open="accordions.academic_background ?? false"
      @selected="toggle('academic_background')"
      @close-all="closeAll"
      @vue:mounted="refetchInterview(), refetchIdealProfile()"
    >
      <div v-if="academicBackground">
        <div v-for="(_, academic_key, index) in academicBackground" :key="`academic-${academic_key}`">
          <div class="evaluation-heading">
            {{ t("projects.details.activities.screening.form.labels.graduation") }}
            {{ index + 1 }} -
            {{ t("projects.screening.guide.PostScreening.educationBackground.idealProfile") }}:
          </div>
          <p>
            {{ construction.ideal_profile.academic_backgrounds[academic_key] }}
          </p>
          <div class="label-title">
            {{
              t("projects.screening.guide.PostScreening.educationBackground.candidateFormation")
            }}:
          </div>
          <TextArea
            v-model="academicBackground[academic_key].observations"
            rows="5"
            class="item-input"
            :name="`academic-${index}`"
            :warning-chars="250"
            :placeholder="t('projects.details.activities.constructionPanel.forms.placeholders.field')"
            @change="update('academic_backgrounds')"
            @focus="refetchInterview(), refetchIdealProfile()"
          />
          <div class="enum-title">
            {{ t('projects.details.constructionGuide.ranking.requirementsMet') }}
          </div>
          <InterviewSliderEvaluation
            v-model="academicBackground[academic_key].score"
            :is-in-interview="showWarningSlider"
            @update:model-value="update('academic_backgrounds')"
          >
            <template #warning="{ isEmpty }">
              <span v-if="isEmpty" class="warning">
                {{ t("projects.screening.guide.PostScreening.warning") }}
              </span>
            </template>
          </InterviewSliderEvaluation>
        </div>
      </div>
      <div class="separate" />
      <div v-if="languageEvaluation">
        <div v-for="(_, language_key, index) in languageEvaluation" :key="`academic-${language_key}`">
          <div class="evaluation-heading">
            {{ t("languages.language") }}
            {{ index + 1 }} -
            {{ t("projects.screening.guide.PostScreening.educationBackground.idealProfile") }}:
          </div>
          <p>
            {{ construction.ideal_profile.languages[language_key] }}
          </p>
          <div class="label-title">
            {{
              t("projects.screening.guide.PostScreening.educationBackground.languages")
            }}:
          </div>
          <TextArea
            v-model="languageEvaluation[language_key].observations"
            rows="5"
            class="item-input"
            :name="`language-${index}`"
            :warning-chars="250"
            :placeholder="t('projects.details.activities.constructionPanel.forms.placeholders.field')"
            @change="update('languages')"
            @focus="refetchInterview(), refetchIdealProfile()"
          />
          <div class="enum-title">
            {{ t('projects.details.constructionGuide.ranking.requirementsMet') }}
          </div>
          <InterviewSliderEvaluation
            v-model="languageEvaluation[language_key].score"
            :is-in-interview="showWarningSlider"
            @update:model-value="update('languages')"
          >
            <template #warning="{ isEmpty }">
              <span v-if="isEmpty" class="warning">
                {{ t("projects.screening.guide.PostScreening.warning") }}
              </span>
            </template>
          </InterviewSliderEvaluation>
        </div>
      </div>
      <renderWarning :keys="{ key: 'academic_backgrounds', i18nSectionKey: 'educationalBackground' }" />
    </BoxAccordion>

    <BoxAccordion
      id="experiences"
      :title="t('projects.details.constructionGuide.idealProfile.experience')"
      icon="briefcase_icon_new"
      :open="accordions.experiences ?? false"
      @selected="toggle('experiences')"
      @close-all="closeAll"
    >
      <div v-if="experienceEvaluation">
        <div v-for="(_, data_key, index) in experienceEvaluation" :key="`academic-${data_key}`">
          <div class="evaluation-heading">
            {{ t("projects.details.constructionGuide.idealProfile.experienceShort") }}
            {{ index + 1 }}
          </div>
          <p>
            {{ idealProfile.experiences[data_key] }}
          </p>
          <div class="label-title">
            {{ t('projects.details.constructionGuide.idealProfile.consultantOpinion') }}
          </div>
          <TextArea
            v-if="experienceEvaluation"
            v-model="experienceEvaluation[data_key].observations"
            rows="5"
            class="item-input"
            :name="`experience-${index}`"
            :warning-chars="250"
            :placeholder="t('projects.details.constructionGuide.ranking.addDescription')"
            @change="update('experiences')"
            @focus="refetchInterview(), refetchIdealProfile()"
          />
          <div class="enum-title">
            {{}}
          </div>
          <InterviewSliderEvaluation
            v-if="experienceEvaluation"
            v-model="experienceEvaluation[data_key].score"
            :is-in-interview="showWarningSlider"
            @update:model-value="update('experiences')"
          >
            <template #warning="{ isEmpty }">
              <span v-if="isEmpty" class="warning">
                {{ t("projects.screening.guide.PostScreening.warning") }}
              </span>
            </template>
          </InterviewSliderEvaluation>
        </div>
      </div>
      <renderWarning :keys="{ key: 'experiences', i18nSectionKey: 'experience' }" />
    </BoxAccordion>

    <BoxAccordion
      id="achievements"
      icon="project_realizations"
      :title="t('projects.interview.accordeons.realizations')"
      :open="accordions.achievements ?? false"
      @selected="toggle('achievements')"
      @close-all="closeAll"
    >
      <div v-if="achievementsEvaluation">
        <div v-for="(_, data_key, index) in achievementsEvaluation" :key="`achievements-${data_key}`">
          <div class="evaluation-heading">
            {{ t("projects.interview.labels.realization") }}
            {{ index + 1 }} -
            {{ t("projects.screening.guide.PostScreening.educationBackground.idealProfile") }}:
          </div>
          <p>
            {{ idealProfile.achievements[data_key]?.description }}
          </p>
          <div class="label-title">
            {{ t('projects.details.constructionGuide.idealProfile.consultantOpinion') }}
          </div>
          <TextArea
            v-model="achievementsEvaluation[data_key].observations"
            rows="5"
            class="item-input"
            :name="`achievements-${index}`"
            :warning-chars="250"
            placeholder="ex.: Administração"
            @change="update('achievements')"
            @focus="refetchInterview(), refetchIdealProfile()"
          />
          <InterviewSliderEvaluation
            v-model="achievementsEvaluation[data_key].score"
            :is-in-interview="showWarningSlider"
            @update:model-value="update('achievements')"
          >
            <template #warning="{ isEmpty }">
              <span v-if="isEmpty" class="warning">
                {{ t("projects.screening.guide.PostScreening.warning") }}
              </span>
            </template>
          </InterviewSliderEvaluation>
        </div>
      </div>
      <renderWarning :keys="{ key: 'achievements', i18nSectionKey: 'realizations' }" />
    </BoxAccordion>

    <BoxAccordion
      id="attributes"
      icon="project_attributes"
      :title="t('projects.interview.accordeons.attributes')"
      :open="accordions.attributes ?? false"
      @selected="toggle('attributes')"
      @close-all="closeAll"
    >
      <div v-for="(attribute_value, attribute) of constructionAttributes" :key="`attribute-${attribute}`" class="enum-group">
        <!-- TODO: add clamp feature -->
        <div class="enum-title">
          {{ t(`attributes.${attribute.toUpperCase()}`) }}
        </div>
        <div class="enum-tooltip">
          {{ t(`projects.shortlist.enums.attributes.${attribute.toUpperCase()}`) }}
        </div>
        <SliderKeyed
          :model-value="attributesEvaluation[attribute].level"
          :score-marker="attribute_value"
          background-color="#434343"
          marker-score-color="#fcaf45"
          title-marker-color="#666666"
          @update:model-value="updateAttribute(attribute, { level: $event })"
        />
        <TextArea
          :model-value="attributesEvaluation[attribute].observations"
          class="item-input"
          :warning-chars="250"
          rows="5"
          placeholder="adicionar descrição"
          @change="updateAttribute(attribute, { observations: $event.target.value })"
          @focus="refetchInterview(), refetchIdealProfile()"
        />
        <div class="enum-title">
          {{}}
        </div>
        <InterviewSliderEvaluation
          :model-value="attributesEvaluation[attribute].score"
          :is-in-interview="showWarningSlider"
          @update:model-value="updateAttribute(attribute, { score: $event })"
        >
          <template #warning="{ isEmpty }">
            <span v-if="isEmpty" class="warning">
              {{ t("projects.screening.guide.PostScreening.warning") }}
            </span>
          </template>
        </InterviewSliderEvaluation>
      </div>
      <renderWarning :keys="{ key: 'attributes', i18nSectionKey: 'attributes' }" />
    </BoxAccordion>

    <BoxAccordion
      id="cultural_match"
      icon="project_cultural_match"
      :title="t('projects.interview.accordeons.culturalMatch')"
      :open="accordions.cultural_match ?? false"
      @selected="toggle('cultural_match')"
      @close-all="closeAll"
    >
      <div v-for="(cultural_value, cultural) of constructionCulturalMatch" :key="`cultural-${cultural}`" class="enum-group">
        <!-- TODO: add clamp feature -->
        <div class="enum-title">
          {{ t(`cultural_match.${cultural.toUpperCase()}`) }}
        </div>
        <div class="enum-tooltip">
          {{ t(`projects.shortlist.enums.cultural.${cultural.toUpperCase()}`) }}
        </div>
        <SliderKeyed
          :model-value="culturalMatchEvaluation[cultural].level"
          :score-marker="cultural_value"
          background-color="#FCAF45"
          construction-value-color="#F8D272"
          marker-color="#434343"
          @update:model-value="updateCulturalMatch(cultural, { level: $event })"
        />
        <TextArea
          :model-value="culturalMatchEvaluation[cultural].observations"
          class="item-input"
          :warning-chars="250"
          rows="5"
          placeholder="adicionar descrição"
          @change="updateCulturalMatch(cultural, { observations: $event.target.value })"
          @focus="refetchInterview(), refetchIdealProfile()"
        />
        <div class="enum-title">
          {{}}
        </div>
        <InterviewSliderEvaluation
          :model-value="culturalMatchEvaluation[cultural].score"
          :is-in-interview="showWarningSlider"
          @update:model-value="updateCulturalMatch(cultural, { score: $event })"
        >
          <template #warning="{ isEmpty }">
            <span v-if="isEmpty" class="warning">
              {{ t("projects.screening.guide.PostScreening.warning") }}
            </span>
          </template>
        </InterviewSliderEvaluation>
      </div>
      <renderWarning :keys="{ key: 'cultural_match', i18nSectionKey: 'culturalMatch' }" />
    </BoxAccordion>

    <BoxAccordion
      id="motivation"
      icon="project_motivation_slim"
      :title="t('projects.interview.accordeons.motivation')"
      :open="accordions.motivation ?? false"
      @selected="toggle('motivation')"
      @close-all="closeAll"
    >
      <div class="evaluation-heading">
        {{ t("projects.construction.titles.package") }} - {{ t("projects.screening.guide.PostScreening.educationBackground.idealProfile") }}:
      </div>
      <div v-for="(motivation, key, index) of idealProfile.package" :key="index">
        <div v-if="motivation">
          <div class="enum-title">
            {{ t(`projects.details.activities.screening.motivationEnum.${key}`) }}: <span style="font-weight: 400">{{ motivation }}</span>
          </div>
        </div>
      </div>
      <template v-if="motivationEvaluation">
        <div v-for="(_, motivation_key) of motivationEvaluation" :key="`motivation-${motivation_key}`">
          <div v-if="motivation_key !== 'score'" class="enum-title">
            {{ t(`projects.details.activities.screening.motivationEnum.${motivation_key}`) }}<span v-if="!['attraction', 'retention', 'repulsion'].includes(motivation_key)">&nbsp;{{ t('text.fromCandidate') }}</span>:
          </div>
          <TextArea
            v-if="motivation_key !== 'score'"
            v-model="motivationEvaluation[motivation_key]"
            class="item-input"
            :warning-chars="250"
            rows="5"
            :placeholder="t('projects.details.constructionGuide.ranking.addDescription')"
            @change="update('package')"
            @focus="refetchInterview(), refetchIdealProfile()"
          />
        </div>
        <div class="enum-title">
          {{}}
        </div>
        <InterviewSliderEvaluation
          v-model="motivationEvaluation.score"
          style="margin-right: 0.25rem;"
          :is-in-interview="showWarningSlider"
          @update:model-value="update('package')"
        >
          <template #warning="{ isEmpty }">
            <span v-if="isEmpty" class="warning">
              {{ t("projects.screening.guide.PostScreening.warning") }}
            </span>
          </template>
        </InterviewSliderEvaluation>
      </template>
      <template v-if="careerEvaluation">
        <div class="evaluation-heading">
          {{ t("projects.construction.titles.career") }} - {{ t("projects.screening.guide.PostScreening.educationBackground.idealProfile") }}:
        </div>
        <template v-if="idealProfile.career">
          <p v-for="(ideal_career, ideal_career_key) of idealProfile.career" :key="`carrer-${ideal_career_key}`">
            {{ ideal_career }}
          </p>
        </template>
        <div v-for="(_, career_key) of careerEvaluation" :key="`carrer-${career_key}`">
          <div
            v-if="career_key !== 'score'"
            class="enum-title"
          >
            {{ t(`projects.details.activities.screening.motivationEnum.${career_key}`) }}<span v-if="!['attraction', 'retention', 'repulsion'].includes(career_key)">&nbsp;{{ t('text.fromCandidate') }}</span>:
          </div>
          <TextArea
            v-if="career_key !== 'score'"
            v-model="careerEvaluation[career_key]"
            class="item-input"
            :warning-chars="250"
            rows="5"
            :placeholder="t('projects.details.constructionGuide.ranking.addDescription')"
            @change="update('career')"
            @focus="refetchInterview(), refetchIdealProfile()"
          />
        </div>
        <div class="enum-title">
          {{}}
        </div>
        <InterviewSliderEvaluation
          v-model="careerEvaluation.score"
          style="margin-right: 0.25rem;"
          :is-in-interview="showWarningSlider"
          @update:model-value="update('career')"
        >
          <template #warning="{ isEmpty }">
            <span v-if="isEmpty" class="warning">
              {{ t("projects.screening.guide.PostScreening.warning") }}
            </span>
          </template>
        </InterviewSliderEvaluation>
      </template>
      <template v-if="environmentEvaluation">
        <div class="evaluation-heading">
          {{ t("projects.construction.titles.environment") }} - {{ t("projects.screening.guide.PostScreening.educationBackground.idealProfile") }}:
        </div>
        <template v-if="idealProfile.work_environment">
          <p v-for="(ideal_work_environment, ideal_work_environment_key) of idealProfile.work_environment" :key="`carrer-${ideal_work_environment_key}`">
            {{ ideal_work_environment }}
          </p>
        </template>
        <div v-for="(_, environment_key) of environmentEvaluation" :key="`environment-${environment_key}`">
          <div
            v-if="environment_key !== 'score'"
            class="enum-title"
          >
            {{ t(`projects.details.activities.screening.motivationEnum.${environment_key}`) }}<span v-if="!['attraction', 'retention', 'repulsion'].includes(environment_key)">&nbsp;{{ t('text.fromCandidate') }}</span>:
          </div>
          <TextArea
            v-if="environment_key !== 'score'"
            v-model="environmentEvaluation[environment_key]"
            class="item-input"
            :warning-chars="250"
            rows="5"
            :placeholder="t('projects.details.constructionGuide.ranking.addDescription')"
            @change="update('work_environment')"
            @focus="refetchInterview(), refetchIdealProfile()"
          />
        </div>
        <div class="enum-title">
          {{}}
        </div>
        <InterviewSliderEvaluation
          v-model="environmentEvaluation.score"
          :is-in-interview="showWarningSlider"
          style="margin-right: 0.25rem;"
          @update:model-value="update('work_environment')"
        >
          <template #warning="{ isEmpty }">
            <span v-if="isEmpty" class="warning">
              {{ t("projects.screening.guide.PostScreening.warning") }}
            </span>
          </template>
        </InterviewSliderEvaluation>
      </template>
      <template v-if="personalEvaluation">
        <div class="evaluation-heading">
          {{ t("projects.construction.titles.personal") }} - {{ t("projects.screening.guide.PostScreening.educationBackground.idealProfile") }}:
        </div>
        <template v-if="idealProfile.personal_life">
          <p v-for="(ideal_personal_life, ideal_personal_life_key) of idealProfile.personal_life" :key="`carrer-${ideal_personal_life_key}`">
            {{ ideal_personal_life }}
          </p>
        </template>
        <div v-for="(_, personal_key) of personalEvaluation" :key="`mot-${personal_key}`">
          <div v-if="personal_key !== 'score'" class="enum-title">
            {{ t(`projects.details.activities.screening.motivationEnum.${personal_key}`) }}<span v-if="!['attraction', 'retention', 'repulsion'].includes(personal_key)">&nbsp;{{ t('text.fromCandidate') }}</span>:
          </div>
          <TextArea
            v-if="personal_key !== 'score'"
            v-model="personalEvaluation[personal_key]"
            class="item-input"
            :warning-chars="250"
            rows="5"
            :placeholder="t('projects.details.constructionGuide.ranking.addDescription')"
            @change="update('personal_life')"
            @focus="refetchInterview(), refetchIdealProfile()"
          />
        </div>
        <div class="enum-title">
          {{}}
        </div>
        <InterviewSliderEvaluation
          v-model="personalEvaluation.score"
          style="margin-right: 0.25rem;"
          :is-in-interview="showWarningSlider"
          @update:model-value="update('personal_life')"
        >
          <template #warning="{ isEmpty }">
            <span v-if="isEmpty" class="warning">
              {{ t("projects.screening.guide.PostScreening.warning") }}
            </span>
          </template>
        </InterviewSliderEvaluation>
      </template>
      <div>
        <div class="enum-title">
          {{ t("projects.details.constructionGuide.ranking.relocationAvailability") }}
        </div>
        <RadioSimple
          v-for="option in willingToRelocateValue"
          :id="`relocate-${option}`"
          :key="`relocate-${option}`"
          v-model="willingToRelocate"
          class="panel-radio-simple"
          :value="option"
          :label="willingToRelocateIntl[option]"
          :name="`relocate-${option}`"
          @update:model-value="update('willing_to_relocate')"
        />
      </div>
      <div>
        <div class="enum-title">
          {{ t("projects.screening.guide.PostScreening.jobModel") }}
        </div>
        <RadioSimple
          v-for="(item, index) of workModelType"
          :id="`work_model-${item}`"
          :key="index"
          v-model="workModelTypeValue"
          :value="item"
          :label="workModelIntl[item]"
          :name="`work_model-${item}`"
          class="panel-radio-simple"
          @update:model-value="update('work_model')"
        />
      </div>
      <renderWarning :keys="{ key: 'package', i18nSectionKey: 'motivation' }" />
    </BoxAccordion>
  </div>
</template>

<style lang="scss" scoped>
.organization-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 1.5rem;
}

.inline {
  display: flex;
  // prevent parent
  margin: 0.5rem !important;
  justify-content: center;
  gap: 1.25rem;

  // prevent parent
  >div {
    margin: 0 !important;
  }
}

label {
  text-transform: capitalize;
}

.panel {
  margin: 0.5rem;
  border-radius: 8px;
  background: #fff 0% 0% no-repeat padding-box;
  box-shadow: 0 3px 4px #00000029;
  padding: 0.5rem;
}

.title {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-inline: 0.5rem;
  gap: 1rem;
  letter-spacing: 0.77px;

  span {
    color: $red-violet;
    font-weight: 600;
    font-size: 1.25rem;
    text-transform: uppercase;
    letter-spacing: inherit;
  }
}

.separate {
  width: 100%;
  height: 1px;
  background: $gray-border;
  margin-block-start: 4rem;
  margin-block-end: 2rem;
}

.center {
  justify-content: center;
}

.icon-wrapper {
  color: inherit;
}

.base-item {
  display: flex;
  align-items: baseline;
  gap: 0.25rem;
  margin-block: 0.5rem 1.5rem;

  .item-input {
    width: 100%;
  }
}

.item-label {
  color: $gray-dark;
  font-size: .875rem;
  font-weight: 700;
  margin: 1.25rem 0 0.25rem 0;
}

.item-label-lg {
  font-size: 1rem;
  text-transform: uppercase;
}

.motivation-items {
  display: flex;
  flex-direction: column;
}

button.panel-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  margin: 0.5rem 0 1.5rem 0;

  &:focus,
  &:hover {

    &.add,
    &.add>span {
      color: $red-violet-medium;

      svg {
        vertical-align: bottom;
      }
    }
  }

  &.add,
  &.add>span {
    color: $gray-dark;
    font-size: 0.75rem;
    letter-spacing: 0;
    font-weight: 600;

    svg {
      vertical-align: bottom;
    }
  }
}

.ideal-profile-accordeon:deep(.box-accordeon) {
  margin-left: 2rem;

  .header {
    border-bottom: 1px solid $gray-x14;
    margin-bottom: 1rem;
  }

  .base-item {
    margin-inline: 0;
    gap: 1rem;
  }
}

.base-item-input {
  width: 100%;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 1.5rem;
  gap: 0.75rem;

  .add-tag {
    color: $primary;
    font-size: 0.75rem;
    letter-spacing: 0;

    &:hover {
      color: $red;
    }

    svg {
      vertical-align: sub;
    }
  }
}

datalist {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

option {
  padding: 0;
}

input[type="range"] {
  width: 100%;
  margin: 0;
}
.enum-group {
  padding-block: 0.5rem 1.5rem;
  margin: 0 1.5rem 0 0.125rem;
}
.enum-title,
.label-title {
  font-size: 0.875rem;
  padding-block: 0.5rem;
  font-weight: 600;
  user-select: none;
}
.enum-title {
  font-size: 1rem;
}
.enum-tooltip {
  font-size: 0.75rem;
  padding-block: 0.5rem;
  user-select: none;
}

.evaluation-heading {
  color: $red-violet;
  font-size: 0.875rem;
  padding-block: 0.5rem;
  font-weight: 700;
}
.evaluation-heading + p {
  margin-right: 0.5rem;
  word-break: break-word;
  line-height: 1.25rem;
  white-space: pre-wrap;
  font-size: 0.875rem;
}

.evaluation-heading + p ~ div:last-child {
  margin-bottom: 1rem;
}

.panel-radio-simple {
  font-size: 0.875rem;
  letter-spacing: 0;
  color: $gray-dark;
  font-weight: 600;
  margin: 0 0.5rem 0 0;
}
:deep(.imua-radiosimple .imua-radiosimple__label) {
  font-size: 0.875rem;
  padding-right: 0.875rem;
}
.warning-return {
  display: flex;
  font-size: 0.875rem;
  gap: 0.25rem;
  word-break: break-word;
  align-items: flex-start;
  margin-inline-start: 1.875rem;
  margin-block-start: 0;

  :deep(.icon-warning) {
    width: 0.875rem;
    aspect-ratio: 1;
    flex-shrink: 0;
    margin-block-start: 2px;
  }
}
</style>
