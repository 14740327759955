<script lang="ts">
import { useI18n } from "vue-i18n";
import SummaryDetails from "./components/SummaryDetails.vue";
import SummaryEditable from "./components/SummaryEditable.vue";
import EditIcon from "@/assets/images/edit_icon.svg";

export default {
  components: {
    SummaryDetails,
    SummaryEditable,
    EditIcon,
  },
  setup() {
    const { t } = useI18n();

    const toggleEdit = inject("toggleEdit");
    const onSubmitPerson = inject("onSubmitPerson");
    const editing = inject("editing");

    return {
      t,
      toggleEdit,
      onSubmitPerson,
      editing,
    };
  },
};
</script>

<template>
  <h2 class="section-title">
    {{ t("persons.details.summary") }}
    <a href="#">
      <EditIcon
        class="edit-icon"
        @click="
          () => {
            toggleEdit('summary');
          }
        "
      />
    </a>
  </h2>
  <SummaryDetails v-if="editing !== 'summary'" />
  <SummaryEditable
    v-if="editing === 'summary'"
    :on-cancel="() => toggleEdit(null)"
    :on-submit="onSubmitPerson"
  />
</template>
