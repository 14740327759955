import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { addInterviewEvaluation, getInterviewEvaluation, getInterviewsEvaluation, patchInterviewEvaluation } from "@/common/services/interviewsEvaluation";

export function useCreateInterviewEvaluationQuery() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["projects", "interviews_evaluation"],
    mutationFn: (organization_and_assessment: any) => addInterviewEvaluation(organization_and_assessment),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["projects", "interviews_evaluation"] });
    },
  });
}

export function useInterviewEvaluationQuery(project_id: string, person_id: string) {
  return useQuery({
    queryKey: ["projects", "interviews_evaluation", project_id, "person", person_id],
    queryFn: () => getInterviewEvaluation(project_id, person_id),
  });
}

export function useInterviewEvaluationMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["projects", "interviews_evaluation"],
    mutationFn: (data: { project_id: string; person_id: string }) => getInterviewEvaluation(data.project_id, data.person_id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["projects", "interviews_evaluation"] });
    },
  });
}

export const isSaving = ref(false);

export function usePatchInterviewEvaluationQuery() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["projects", "interviews_evaluation"],
    mutationFn: (interview: any) => patchInterviewEvaluation(interview._id, interview),
    onMutate: async () => {
      isSaving.value = true;
    },
    onError: () => {
      isSaving.value = false;
    },
    onSuccess: () => {
      isSaving.value = false;
      queryClient.invalidateQueries({ queryKey: ["candidate"] });
      queryClient.invalidateQueries({ queryKey: ["projects", "interviews_evaluation"] });
    },
  });
}

export function useInterviewsEvaluationQuery(project_id: string) {
  return useQuery({
    queryKey: ["projects", "interviews_evaluation"],
    queryFn: () => getInterviewsEvaluation(project_id),
  });
}
