import type { IntlNumberFormat } from "vue-i18n";
import type { CurrencyEnum } from "@/schemas/enums/currency";

type formatFunction = (number: number) => string;

export function formatCurrency(currency: CurrencyEnum, locale = "pt-BR", options: IntlNumberFormat = {}): formatFunction {
  const formater = new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
    ...options,
  });

  return (value: number): string => formater.format(value);
}

export function formatValueFloat(value: number): string {
  const valueNumber = String(value).replace(/[^0-9]/g, "");
  const valueFormat
    = valueNumber.length > 1
      ? `${valueNumber.substring(0, valueNumber.length - 2)}.${valueNumber.substring(
          valueNumber.length - 2,
          valueNumber.length,
        )}`
      : `0.0${valueNumber}`;
  return valueFormat;
}
export function formatScore(score: number): string | number {
  try {
    return (
      (!Number.isInteger(score) ? score.toFixed(2).replace(".", ",") : score.toFixed(0)) ?? 0
    );
  }
  catch (error) {
    return 0;
  }
}
