<script lang="ts">
import type { Ref } from "vue";
import { useI18n } from "vue-i18n";
import { set } from "lodash";
import { Select } from "@/components/inputs/modalInputs";
import { languages } from "@/utils/data-content/language";
import type { Language } from "@/entities/Person/language";
import { Proficiency } from "@/entities/Person/language";

export default defineComponent({
  name: "LanguageForm",
  components: {
    Select,
  },
  props: {
    language: Object,
    languageEdit: Object,
    languageAutoFilledFields: Object,
    autoFilledEditFields: Object,
    onRemove: Function,
  },
  setup(props) {
    const { t } = useI18n();
    const payload = toRef(props, "language") as Ref<Language>;
    const parsedPayload = toRef(props, "languageEdit") as Ref<Language>;

    const languageOptions = computed(() =>
      languages.map(code => ({
        label: t(`languages.name.${code}.label`),
        value: code.toUpperCase(),
      })));

    const proficiencyOptions = computed(() =>
      Object.entries(Proficiency).map(([k]) => ({
        label: t(`languages.proficiency.${k}`),
        value: k,
      })));

    const removeOption = (key: string) => {
      if (props.autoFilledEditFields)
        set(props.autoFilledEditFields, key, false);
    };
    return {
      t,
      payload,
      parsedPayload,
      languageOptions,
      proficiencyOptions,
      removeOption,
    };
  },
});
</script>

<template>
  <div class="person-form-wrapper" data-test="language-form">
    <div class="input-group">
      <Select
        v-if="autoFilledEditFields?.language"
        v-model="parsedPayload.language"
        :placeholder="t('persons.forms.language.placeholder.language')"
        data-test="language"
        :auto-fill="autoFilledEditFields?.language"
        :options="languageOptions"
        @click:on-button-click="removeOption('language')"
      />
      <Select
        v-else
        v-model="payload.language"
        :placeholder="t('persons.forms.language.placeholder.language')"
        data-test="language"
        :auto-fill="languageAutoFilledFields?.language"
        :options="languageOptions"
      />
    </div>
    <div class="input-group">
      <Select
        v-if="autoFilledEditFields?.proficiency"
        v-model="parsedPayload.proficiency"
        :placeholder="t('persons.forms.language.placeholder.proficiency')"
        data-test="proficiency"
        :auto-fill="autoFilledEditFields?.proficiency"
        :options="proficiencyOptions"
        @click:on-button-click="removeOption('proficiency')"
      />
      <Select
        v-else
        v-model="payload.proficiency"
        :placeholder="t('persons.forms.language.placeholder.proficiency')"
        data-test="proficiency"
        :auto-fill="languageAutoFilledFields?.proficiency"
        :options="proficiencyOptions"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.input-group {
  width: 100%;
}

.person-form-wrapper:deep {
  .form-input-wrapper {
    display: grid;
    width: 100%;
    grid-template-columns: 150px 1fr;

    span {
      margin-right: 10px;
    }

    .component-input {
      width: 100%;
    }

    .form-date-wrapper {
      display: grid;
      column-gap: 30px;
      grid-template-columns: 1fr 1fr;
    }
  }

  .multiselect {
    min-height: 30px;

    .multiselect-dropdown {
      overflow-y: scroll;
    }
  }
}
</style>
