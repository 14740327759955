<script lang="ts">
import {
  defineComponent,
  onMounted,
  ref,
  watch,
} from "vue";
import { random } from "lodash";
import { SvgIcon } from "@/components/icon";
import { breakpoints } from "@/utils/breakpoints";

const videos = ["beach", "clouds", "fog", "horizon", "rocks", "stones", "stream", "sun", "waves"];

export default defineComponent({
  name: "AuthTemplate",
  components: {
    SvgIcon,
  },
  props: {
    minimize: Boolean,
    hideFooterBar: Boolean,
    isPasswordDefinition: Boolean,
    logoClass: String,
  },
  setup() {
    const srcUrl = "https://storage.googleapis.com/imua.work";
    const videoName = videos[random(videos.length - 1)];
    const bgVideo = ref(null);
    const bgWrapper = ref(null);
    const smallerThanLg = breakpoints.smaller("lg");
    const videoLoadError = ref(false);

    onMounted(() => {
      if (bgVideo.value && bgWrapper.value) {
        (bgVideo.value as HTMLVideoElement).addEventListener("error", () => {
          bgVideo.value.style.display = "none";
          videoLoadError.value = true;
          bgWrapper.value.style.backgroundImage = `url("${srcUrl}/backgrounds/${videoName}.jpg")`;
        }, true);
      }
    });

    watch(smallerThanLg, (value) => {
      if (bgWrapper.value) {
        if (value)
          bgWrapper.value.style.backgroundImage = `url("${srcUrl}/backgrounds/${videoName}.jpg")`;
        else
          bgWrapper.value.style.backgroundImage = "";
      }
    }, { immediate: true });

    return {
      bgWrapper,
      bgVideo,
      srcUrl,
      videoName,
    };
  },
});
</script>

<template>
  <div
    ref="bgWrapper"
    class="background-wrapper"
  >
    <video
      id="bg-video"
      autoplay
      muted
      loop
      :src="`${srcUrl}/background-videos/${videoName}.mp4`"
    />

    <div class="content-wrapper">
      <div class="logo">
        <SvgIcon icon="imua_by_flow" width="100%" height="auto" />
      </div>

      <div class="content">
        <slot />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.background-wrapper {
  position: relative;
  display: grid;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(6, 1fr);

  #bg-video {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .content-wrapper {
    display: grid;
    align-items: center;
    padding: 0 5%;
    grid-column: 3 / 11;
    grid-gap: 5%;
    grid-row: 2 / 6;
    grid-template-columns: 1fr 1fr;
  }

  .logo {
    text-align: center;

    svg {
      width: 100%;
    }
  }
}

@media (max-width: 1024px) {
  .background-wrapper {
    background-color: black;

    #bg-video {
      display: none;
    }

    .content-wrapper {
      grid-column: 2 / -2;

      // grid-row: 1 / -1;
      grid-template-columns: 1fr;
    }
  }
}
</style>
