import { projectsService } from "@/http_services/client";
import type { CurrencyEnum } from "@/schemas/enums/currency";
import type { Person } from "@/common/schemas/person";
import type { Project, ProjectDto } from "@/types/project";
import { ProjectDtoSchema } from "@/types/project";
import { formatUrlParams } from "@/utils/parsers/url";

// TODO: move to zod
interface SearchParams {
  search_ground?: string | null
  keywords?: string | null
  role?: string | null
  company?: string | null
  sector?: string | null
  salary_currency?: CurrencyEnum | null
  salary_min?: number | null
  salary_max?: number | null
  recruiters?: Person[] | null
  sort?: string | null
}
interface Stages {
  suspend: "suspend"
  canceled: "canceled"
  finish: "finish"
}

export async function createProject(payload: any) {
  return projectsService.post("/projects", payload)
    .then(res => res.data);
}

export async function getProject(id: string): Promise<ProjectDto> {
  return projectsService.get(`/projects/${id}`)
    .then(res => res.data?.data)
    .then((data) => {
      const result = ProjectDtoSchema.safeParse(data);
      if (!result.success)
        console.warn("[project]", id, result.error);
      return data;
    });
}

export async function updateProject(id: string, project: Project): Promise<Project> {
  return projectsService.put(`/projects/${id}`, { project })
    .then(res => res.data?.data);
}

export async function getProjects(query: SearchParams): Promise<ProjectDto[]> {
  const params = formatUrlParams(query);
  return projectsService.get("/projects", { params })
    .then(res => res.data?.data);
}

export async function searchProjects(query: SearchParams = {}): Promise<ProjectDto[]> {
  const params = formatUrlParams(query);
  return projectsService.get("/projects/search", { params })
    .then(res => res.data);
}
interface Stages {
  suspend: "suspend"
  canceled: "canceled"
  finish: "finish"
}

export async function editProjectStatus(_id: string, stage: Stages): Promise<Project[]> {
  return projectsService.post(`/projects/${_id}/${stage}`, { _id })
    .then(res => res.data?.data);
}

export async function stageFinishConstruction(id: string): Promise<ProjectDto> {
  return projectsService.post(`/projects/${id}/finish-construction`, {})
    .then(res => res.data?.data);
}

export function finishMapping(project_id: string) {
  return projectsService.post(`projects/${project_id}/finish-mapping`, {})
    .then(res => res.data?.data);
}

export async function stageConstructionToMapping(id: string): Promise<ProjectDto> {
  return projectsService.post(`/projects/${id}/update-stages/construction-to-mapping`, {})
    .then(res => res.data?.data);
}

export async function stageMappingToScreening(id: string): Promise<ProjectDto> {
  return projectsService.post(`/projects/${id}/update-stages/mapping-to-screenings`, {})
    .then(res => res.data?.data);
}

export async function stageScreeningToInterview(id: string): Promise<ProjectDto> {
  return projectsService.post(`/projects/${id}/update-stages/screenings-to-interviews`, {})
    .then(res => res.data?.data);
}

export async function stageInterviewToShortlist(id: string): Promise<ProjectDto> {
  return projectsService.post(`/projects/${id}/update-stages/interviews-to-shortlist`, {})
    .then(res => res.data?.data);
}

export async function stageShortlistToUnderoffer(id: string): Promise<ProjectDto> {
  return projectsService.post(`/projects/${id}/update-stages/shortlist-to-underoffer`, {})
    .then(res => res.data?.data);
}

export async function stageUnderofferToPlacement(id: string): Promise<ProjectDto> {
  return projectsService.post(`/projects/${id}/update-stages/underoffer-to-placement`, {})
    .then(res => res.data?.data);
}

export async function stageFinishProject(id: string): Promise<ProjectDto> {
  return projectsService.post(`/projects/${id}/finish`, {})
    .then(res => res.data?.data);
}
