<script setup lang="ts">
import bson from "bson-objectid";
import { RadioSimple } from "@/components/radio";
import { Select } from "@/components/inputs/modalInputs";
import AlertIcon from "@/assets/images/alert_exclamation_icon.svg";

interface AttributesEvaluation { cognitive_intelligence: EvaluationType; emotional_intelligence: EvaluationType; spiritual_intelligence: EvaluationType; energy: EvaluationType; maturity: EvaluationType }
interface CulturalMatchEvaluation { practical: EvaluationType; logical: EvaluationType; reflexive: EvaluationType; affective: EvaluationType }

interface AttributesRadio { cognitive_intelligence: valueMapKey; emotional_intelligence: valueMapKey; spiritual_intelligence: valueMapKey; energy: valueMapKey; maturity: valueMapKey }
interface CulturalMatchRadio { practical: valueMapKey; logical: valueMapKey; reflexive: valueMapKey; affective: valueMapKey }

interface ApprovalType { approved_for_interview: string | null; reason: string | null; priority_classification: string | null }

// TODO: short this to reuse
type valueMapKey = keyof typeof valueMap | null;

const props = defineProps<{ id: string; stages: any; consultant: any }>();

const emit = defineEmits(["error:draft", "candidate:approval"]);

const valueMap = {
  EMPTY: 0,
  LOW: 1,
  AVERAGE: 2,
  HIGH: 3,
  VERY_HIGH: 4,
};

interface EvaluationType { observations: string | null; level: valueMapKey | null }

const route = useRoute();

type ScreeningKey = keyof typeof screeningMap;

interface UniqueIdMap { [ key: string ]: string }

enum WorkModelEnum {
  REMOTE = "REMOTE",
  ONSITE = "ONSITE",
  HYBRID = "HYBRID",
}
enum WillingToRelocateEnum {
  YES = "YES",
  NO = "NO",
}
enum CandidateApprovalStatusEnum {
  YES = "YES",
  NO = "NO",
  ON_HOLD = "ON_HOLD",
}
enum DisapprovalReasonEnum {
  DECLINED = "DECLINED",
  LOCATION = "LOCATION",
  UNDER_QUALIFIED = "UNDER_QUALIFIED",
  OVER_QUALIFIED = "OVER_QUALIFIED",
  ON_HOLD = "ON_HOLD",
  HIGH_COMPENSATION = "HIGH_COMPENSATION",
  NO_FIT = "NO_FIT",
  NO_INTEREST = "NO_INTEREST",
}
enum PriorityClassificationEnum {
  "IN" = "IN",
  "A+" = "A+",
  "A" = "A",
  "A-" = "A-",
  "B" = "B",
  "C" = "C",
  "D" = "D",
  "F" = "F",
}

const workModelType = WorkModelEnum;
const willingToRelocateValue = WillingToRelocateEnum;
const candidateApprovalStatus = CandidateApprovalStatusEnum;
const disapprovalReason = DisapprovalReasonEnum;
const priorityClassification = PriorityClassificationEnum;

const { t, tm } = useI18n();

const approvalStatusEnum = computed(() => tm("projects.details.activities.screening.approvalStatusEnum"));
const disapprovalReasonEnum = computed(() => tm("projects.details.activities.screening.disapprovalReasonEnum"));
const workModelIntl = computed(() => tm("projects.details.activities.screening.workModelEnum"));
const willingToRelocateIntl = computed(() => tm("projects.details.activities.screening.willingToRelocateEnum"));

const personId = computed(() => route.params.person || "") as Ref<string>;
const draft = computed(() => personId.value === "draft");

const {
  data: idealProfile,
  refetch: refetchIdealProfile,
} = useConstructionIdealProfile(String(route.params.id));

const {
  data: screening,
  refetch: refetchScreening,
} = draft.value
  ? useScreeeningEvaluationByActivityQuery(String(route.params.activity))
  : useScreeeningEvaluationQuery(String(route.params.id), String(route.params.person));

const {
  data: construction,
} = useConstructionOrganizationQuery(String(route.params.id));

const screeningId = computed(() => {
  return screening.value?._id;
});

const {
  mutate: patchScreening,
} = usePatchScreeeningEvaluationQuery();

const {
  mutate: patchApprovalCandidate,
} = usePatchApprovalCandidate();

const {
  mutate: screeningToInterview,
} = useProjectStageScreeningToInterview(props.id);

const {
  data: fetchCandidateData,
  refetch: refetchFetchCandidateData,
} = useGetCandidateIdFromPersonId(props.id, String(route.params.person));

const emptyApproval = {
  approved_for_interview: null,
  reason: null,
  priority_classification: null,
};

const academicBackground: Ref<UniqueIdMap | undefined> = ref();
const languageEvaluation: Ref<UniqueIdMap | undefined> = ref();
const experienceEvaluation: Ref<UniqueIdMap | undefined> = ref();
const motivationEvaluation: Ref<UniqueIdMap | undefined> = ref();
const careerEvaluation: Ref<UniqueIdMap | undefined> = ref();
const environmentEvaluation: Ref<UniqueIdMap | undefined> = ref();
const personalEvaluation: Ref<UniqueIdMap | undefined> = ref();
const workModelTypeValue: Ref<string | undefined> = ref();
const willingToRelocate: Ref<WillingToRelocateEnum> = ref(willingToRelocateValue.NO);
const approvalAndPriority: Ref<string | undefined> = ref();
const achievementEvaluation: Ref<UniqueIdMap | undefined> = ref({
  [bson().toHexString()]: "",
});
const constructionEvaluations: any = ref();

const candidateId = ref("");
const candidateApproval = ref<ApprovalType>(emptyApproval);

const attributesEvaluation = ref({
  cognitive_intelligence: {
    observations: "",
    level: null,
  },
  emotional_intelligence: {
    observations: "",
    level: null,
  },
  spiritual_intelligence: {
    observations: "",
    level: null,
  },
  energy: {
    observations: "",
    level: null,
  },
  maturity: {
    observations: "",
    level: null,
  },
} as AttributesEvaluation);

const culturalMatchEvaluation = ref({
  practical: {
    observations: "",
    level: null,
  },
  logical: {
    observations: "",
    level: null,
  },
  reflexive: {
    observations: "",
    level: null,
  },
  affective: {
    observations: "",
    level: null,
  },
} as CulturalMatchEvaluation);

// TODO: add key type
const accordeons = ref<Record<string, boolean>>({});

function focusAccordeon(key: string) {
  setTimeout(() => {
    const divFocus = document.getElementById(key);
    if (divFocus)
      divFocus?.scrollIntoView({ behavior: "smooth" });
  }, 250);
}

function toggle(key: string) {
  accordeons.value = {
    ...accordeons.value,
    [key]: !accordeons.value[key],
  };
  refetchScreening();
  refetchIdealProfile();

  if (accordeons.value[key])
    focusAccordeon(key);
}

function closeAll() {
  accordeons.value = {};
}

const constructionAttributes = ref({
  cognitive_intelligence: null,
  emotional_intelligence: null,
  spiritual_intelligence: null,
  energy: null,
  maturity: null,
} as AttributesRadio);

const constructionCulturalMatch = ref({
  practical: null,
  logical: null,
  reflexive: null,
  affective: null,
} as CulturalMatchRadio);

// TODO: will remove
// TODO: fix types
function filteredEmptyData(acc) {
  return Object.fromEntries(
    Object.entries(acc)
      .filter(([_, value]) => value !== "EMPTY"),
  );
}
const screeningMap = {
  academic_backgrounds: academicBackground,
  languages: languageEvaluation,
  attributes: attributesEvaluation,
  cultural_match: culturalMatchEvaluation,
  experiences: experienceEvaluation,
  achievements: achievementEvaluation,
  package: motivationEvaluation,
  career: careerEvaluation,
  work_environment: environmentEvaluation,
  personal_life: personalEvaluation,
  willing_to_relocate: willingToRelocate,
  work_model: workModelTypeValue,
  approval_and_priority: approvalAndPriority,
};

function updateAttribute(key: keyof AttributesEvaluation, value: Partial<EvaluationType>) {
  attributesEvaluation.value = {
    ...attributesEvaluation.value,
    [key]: {
      ...attributesEvaluation.value[key],
      ...value,
    },
  };
  patchScreening({
    _id: screeningId.value,
    attributes: attributesEvaluation.value,
  });
}

function updateCulturalMatch(key: keyof CulturalMatchEvaluation, value: Partial<EvaluationType>) {
  culturalMatchEvaluation.value = {
    ...culturalMatchEvaluation.value,
    [key]: {
      ...culturalMatchEvaluation.value[key],
      ...value,
    },
  };
  patchScreening({
    _id: screeningId.value,
    cultural_match: culturalMatchEvaluation.value,
  });
}

// TODO: remove conditions, use differen saves
function update(key: ScreeningKey) {
  if (key === "experiences") {
    patchScreening({
      _id: screeningId.value,
      [key]: screeningMap[key].value,
    });
    return;
  }
  if (key === "cultural_match" || key === "attributes") {
    const attrKeyMap = {
      attributes: attributesEvaluation.value,
      cultural_match: culturalMatchEvaluation.value,
    };
    patchScreening({
      _id: screeningId.value,
      [key]: attrKeyMap[key],
    });
  }

  patchScreening({
    _id: screeningId.value,
    [key]: screeningMap[key].value,
  });
}

function patchCandidate() {
  patchApprovalCandidate({
    person_id: candidateId.value,
    body: {
      approval: candidateApproval.value,
    },
  }, {
    onSuccess: () => {
      refetchFetchCandidateData();
      if (candidateApproval.value.approved_for_interview === "YES") {
        if (props.stages.interviews.status === "DRAFT")
          screeningToInterview(props.id);
      }
    },
  });
}

function updateApproval(key: string, value: string) {
  if (draft.value) {
    candidateApproval.value.approved_for_interview = null;
    emit("error:draft");
    return;
  }

  if (key === "approved_for_interview" && value === "YES") {
    emit("candidate:approval", candidateApproval.value);
    candidateApproval.value.reason = null;
    return;
  }

  candidateApproval.value = {
    ...candidateApproval.value,
    [key]: value,
  };

  patchCandidate();
}

function showBeforeUnloadModal(event) {
  event.preventDefault();
  event.returnValue = "";
}

window.addEventListener("beforeunload", showBeforeUnloadModal);

onBeforeUnmount(() => {
  window.removeEventListener("beforeunload", showBeforeUnloadModal);
});

watchEffect(() => {
  if (idealProfile.value) {
    constructionAttributes.value = filteredEmptyData(idealProfile.value.attributes);
    constructionCulturalMatch.value = filteredEmptyData(idealProfile.value.cultural_match);
  }
  if (screening.value) {
    academicBackground.value = { ...screening.value.academic_backgrounds };
    languageEvaluation.value = { ...screening.value.languages };
    experienceEvaluation.value = { ...screening.value.experiences };
    achievementEvaluation.value = { ...screening.value.achievements };
    attributesEvaluation.value = { ...screening.value.attributes! };
    culturalMatchEvaluation.value = { ...screening.value.cultural_match! };
    motivationEvaluation.value = toRaw(screening.value.package);
    careerEvaluation.value = toRaw(screening.value.career);
    environmentEvaluation.value = toRaw(screening.value.work_environment);
    personalEvaluation.value = toRaw(screening.value.personal_life);
    willingToRelocate.value = screening.value.willing_to_relocate;
    workModelTypeValue.value = screening.value.work_model;
  }
  if (!draft.value) {
    refetchFetchCandidateData().then(() => {
      candidateId.value = fetchCandidateData.value._id;
      candidateApproval.value = toRaw(fetchCandidateData.value.approval_and_priority) ?? emptyApproval;
    });
  }
  if (construction.value)
    constructionEvaluations.value = construction.value?.ideal_profile;
});
function isEvaluationPresent(key: string) {
  if (constructionEvaluations.value) {
    const evaluationValues = constructionEvaluations.value[key];
    switch (key) {
      case "attributes":
      case "cultural_match":
        return !Object.values(evaluationValues).every(value => value === "EMPTY");
      case "package":
        return Object.values(evaluationValues).some(value => value !== "");
      default:
        return Object.keys(evaluationValues)?.length;
    }
  }
}
function renderWarning({ keys }: { keys: { key: string; i18nSectionKey: string } }) {
  if (!isEvaluationPresent(keys.key)) {
    return h("p", { class: "warning-return" }, [
      h(AlertIcon, { class: "icon-warning" }),
      t("projects.screening.guide.PostScreening.onConstructionNotFound", { section: t(`projects.screening.guide.accordeons.${keys.i18nSectionKey}`) }),
    ]);
  }
  else {
    return null;
  }
}
</script>

<template>
  <div class="organization-wrapper">
    <div v-if="screening && idealProfile">
      <div v-if="academicBackground">
        <BoxAccordion
          id="academic_background"
          :title="t('projects.screening.guide.accordeons.educationalBackground')"
          icon="project_academic"
          :open="accordeons.academic_background ?? false"
          @selected="toggle('academic_background')"
          @close-all="closeAll"
        >
          <div v-for="(_, academic_key, index) in academicBackground" :key="`academic-${academic_key}`">
            <div class="evaluation-heading">
              {{ t("projects.details.activities.screening.form.labels.graduation") }}
              {{ index + 1 }} -
              {{ t("projects.screening.guide.PostScreening.educationBackground.idealProfile") }}:
            </div>
            <p>
              {{ idealProfile.academic_backgrounds[academic_key] }}
            </p>
            <div class="label-title">
              {{
                t("projects.screening.guide.PostScreening.educationBackground.candidateFormation")
              }}:
            </div>
            <div />
            <TextArea
              v-model="academicBackground[academic_key]"
              rows="5"
              class="item-input"
              :name="`academic-${index}`"
              :warning-chars="250"
              :placeholder="t('projects.details.activities.constructionPanel.forms.placeholders.field')"
              @change="update('academic_backgrounds')"
              @focus="refetchScreening(), refetchIdealProfile()"
            />
          </div>
          <div v-if="Object.entries(languageEvaluation)?.length" class="separator" />
          <div v-if="languageEvaluation">
            <div v-for="(_, language_key, index) of languageEvaluation" :key="`lang-${language_key}`">
              <div class="evaluation-heading">
                {{ t("languages.language") }} {{ index + 1 }} -
                {{ t("projects.screening.guide.PostScreening.educationBackground.idealProfile") }}:
              </div>
              <p>
                {{ idealProfile.languages[language_key] }}
              </p>
              <div class="label-title">
                {{ t("projects.screening.guide.PostScreening.educationBackground.languages") }}:
              </div>
              <TextArea
                v-model="languageEvaluation[language_key]"
                rows="5"
                class="item-input"
                :name="`language-${index}`"
                :warning-chars="250"
                :placeholder="t('projects.details.activities.constructionPanel.forms.placeholders.language')"
                @change="update('languages')"
                @focus="refetchScreening(), refetchIdealProfile()"
              />
            </div>
          </div>
          <renderWarning :keys="{ key: 'academic_backgrounds', i18nSectionKey: 'educationalBackground' }" />
        </BoxAccordion>
      </div>

      <BoxAccordion
        id="work_experience"
        :title="t('projects.details.constructionGuide.idealProfile.experience')"
        icon="briefcase_icon_new"
        :open="accordeons.work_experience ?? false"
        @selected="toggle('work_experience')"
        @close-all="closeAll"
      >
        <div v-if="experienceEvaluation">
          <div v-for="(_, experience_key, index) of experienceEvaluation" :key="`experience-${experience_key}`">
            <div class="evaluation-heading">
              {{ t("projects.details.constructionGuide.idealProfile.experienceShort") }}
              {{ index + 1 }}
            </div>
            <p>
              {{ idealProfile.experiences[experience_key] }}
            </p>
            <div class="label-title">
              {{ t('projects.details.constructionGuide.idealProfile.consultantOpinion') }}
            </div>
            <TextArea
              v-model="experienceEvaluation[experience_key]"
              class="item-input"
              :warning-chars="250"
              rows="5"
              :placeholder="t('projects.details.constructionGuide.ranking.addDescription')"
              @change="update('experiences')"
              @focus="refetchScreening(), refetchIdealProfile()"
            />
            <div v-if="index < Object.entries(experienceEvaluation)?.length - 1" class="separator" />
          </div>
        </div>
        <renderWarning :keys="{ key: 'experiences', i18nSectionKey: 'experience' }" />
      </BoxAccordion>

      <BoxAccordion
        id="achievement"
        icon="project_realizations"
        :title="t('projects.interview.accordeons.realizations')"
        :open="accordeons.achievement ?? false"
        @selected="toggle('achievement')"
        @close-all="closeAll"
      >
        <div v-if="achievementEvaluation">
          <div v-for="(_, achievement_key, index) of achievementEvaluation" :key="`achievements-${achievement_key}`">
            <div class="evaluation-heading first">
              {{ t("projects.interview.labels.realization") }}
              {{ index + 1 }} -
              {{ t("projects.screening.guide.PostScreening.educationBackground.idealProfile") }}:
            </div>
            <p>
              {{ idealProfile.achievements[achievement_key]?.description }}
            </p>
            <div class="label-title">
              {{ t('projects.details.constructionGuide.idealProfile.consultantOpinion') }}
            </div>
            <TextArea
              v-model="achievementEvaluation[achievement_key]"
              class="item-input"
              :warning-chars="250"
              rows="5"
              :placeholder="t('projects.details.constructionGuide.ranking.addDescription')"
              @change="update('achievements')"
              @focus="refetchScreening(), refetchIdealProfile()"
            />
            <br>
            <div v-if="index < Object.entries(achievementEvaluation)?.length - 1" class="separator" />
          </div>
        </div>
        <renderWarning :keys="{ key: 'achievements', i18nSectionKey: 'realizations' }" />
      </BoxAccordion>

      <BoxAccordion
        id="attributes"
        icon="project_attributes"
        :title="t('projects.interview.accordeons.attributes')"
        :open="accordeons.attributes ?? false"
        @selected="toggle('attributes')"
        @close-all="closeAll"
      >
        <template v-if="constructionAttributes && attributesEvaluation">
          <div v-for="(attribute_value, attribute, index) of constructionAttributes" :key="`attibute-${attribute}`" class="enum-group">
            <!-- TODO: add clamp feature -->
            <div class="form-group">
              <div>
                <div class="enum-title">
                  {{ t(`attributes.${attribute.toUpperCase()}`) }}
                </div>
                <div class="enum-tooltip">
                  {{ t(`projects.shortlist.enums.attributes.${attribute.toUpperCase()}`) }}
                </div>
              </div>
              <SliderKeyed
                :model-value="attributesEvaluation[attribute].level"
                :score-marker="attribute_value"
                background-color="#434343"
                @update:model-value="updateAttribute(attribute, { level: $event })"
              />
            </div>
            <div>
              <div class="label-title">
                {{ t('projects.details.constructionGuide.idealProfile.consultantOpinion') }}
              </div>
              <TextArea
                :model-value="attributesEvaluation[attribute].observations"
                class="item-input"
                :warning-chars="250"
                rows="5"
                :placeholder="t('projects.details.constructionGuide.ranking.addDescription')"
                @change="updateAttribute(attribute, { observations: $event.target.value })"
                @focus="refetchScreening(), refetchIdealProfile()"
              />
            </div>
            <div v-if="index < Object.entries(constructionAttributes)?.length - 1" class="separator" />
          </div>
        </template>
        <renderWarning :keys="{ key: 'attributes', i18nSectionKey: 'attributes' }" />
      </BoxAccordion>

      <BoxAccordion
        id="cultural_match"
        icon="project_cultural_match"
        :title="t('projects.interview.accordeons.culturalMatch')"
        :open="accordeons.cultural_match ?? false"
        @selected="toggle('cultural_match')"
        @close-all="closeAll"
      >
        <template v-if="constructionCulturalMatch && culturalMatchEvaluation">
          <div v-for="(cultural_value, cultural, index) of constructionCulturalMatch" :key="`cultural-${cultural}`" class="enum-group">
            <!-- TODO: add clamp feature -->
            <div class="form-group">
              <div>
                <div class="enum-title">
                  {{ t(`cultural_match.${cultural.toUpperCase()}`) }}
                </div>
                <div class="enum-tooltip">
                  {{ t(`projects.shortlist.enums.cultural.${cultural.toUpperCase()}`) }}
                </div>
              </div>
              <SliderKeyed
                :model-value="culturalMatchEvaluation[cultural].level"
                :score-marker="cultural_value"
                background-color="#FCAF45"
                construction-value-color="#F8D272"
                @update:model-value="updateCulturalMatch(cultural, { level: $event })"
              />
            </div>
            <div>
              <div class="label-title">
                {{ t('projects.details.constructionGuide.idealProfile.consultantOpinion') }}
              </div>
              <TextArea
                :model-value="culturalMatchEvaluation[cultural].observations"
                class="item-input"
                :warning-chars="250"
                rows="5"
                :placeholder="t('projects.details.constructionGuide.ranking.addDescription')"
                @change="updateCulturalMatch(cultural, { observations: $event.target.value })"
                @focus="refetchScreening(), refetchIdealProfile()"
              />
            </div>
            <div v-if="index < Object.entries(constructionCulturalMatch)?.length - 1" class="separator" />
          </div>
        </template>
        <renderWarning :keys="{ key: 'cultural_match', i18nSectionKey: 'culturalMatch' }" />
      </BoxAccordion>

      <BoxAccordion
        id="motivation"
        icon="project_motivation_slim"
        :title="t('projects.interview.accordeons.motivation')"
        :open="accordeons.motivation ?? false"
        @selected="toggle('motivation')"
        @close-all="closeAll"
      >
        <div class="evaluation-heading first">
          {{ t("projects.construction.titles.package") }} - {{ t("projects.screening.guide.PostScreening.educationBackground.idealProfile") }}:
        </div>
        <div v-for="(motivation, key, index) of idealProfile.package" :key="index">
          <div v-if="motivation">
            <div class="enum-title">
              {{ t(`projects.details.activities.screening.motivationEnum.${key}`) }}: <span style="font-weight: 400">{{ motivation }}</span>
            </div>
          </div>
        </div>
        <template v-if="motivationEvaluation">
          <div class="form-group">
            <div v-for="(_, motivation_key) of motivationEvaluation" :key="`motivation-${motivation_key}`">
              <div class="enum-title">
                {{ t(`projects.details.activities.screening.motivationEnum.${motivation_key}`) }}<span v-if="!['attraction', 'retention', 'repulsion'].includes(motivation_key)">&nbsp;{{ t('text.fromCandidate') }}</span>:
              </div>
              <TextArea
                v-model="motivationEvaluation[motivation_key]"
                class="item-input"
                :warning-chars="250"
                rows="5"
                :placeholder="t('projects.details.constructionGuide.ranking.addDescription')"
                @change="update('package')"
                @focus="refetchScreening(), refetchIdealProfile()"
              />
            </div>
          </div>
        </template>
        <div>
          <template v-if="careerEvaluation">
            <div class="separator" />
            <div class="form-group">
              <div class="evaluation-heading">
                {{ t("projects.construction.titles.career") }} - {{ t("projects.screening.guide.PostScreening.educationBackground.idealProfile") }}:
              </div>
              <template v-if="idealProfile.career">
                <p v-for="(ideal_career, ideal_career_key) of idealProfile.career" :key="`carrer-${ideal_career_key}`">
                  {{ ideal_career }}
                </p>
              </template>
              <div v-for="(_, career_key) of careerEvaluation" :key="`carrer-${career_key}`">
                <div class="enum-title">
                  {{ t(`projects.details.activities.screening.motivationEnum.${career_key}`) }}<span v-if="!['attraction', 'retention', 'repulsion'].includes(career_key)">&nbsp;{{ t('text.fromCandidate') }}</span>:
                </div>
                <TextArea
                  v-model="careerEvaluation[career_key]"
                  class="item-input"
                  :warning-chars="250"
                  rows="5"
                  :placeholder="t('projects.details.constructionGuide.ranking.addDescription')"
                  @change="update('career')"
                  @focus="refetchScreening(), refetchIdealProfile()"
                />
              </div>
            </div>
          </template>
          <template v-if="environmentEvaluation">
            <div class="separator" />
            <div class="form-group">
              <div class="evaluation-heading">
                {{ t("projects.construction.titles.environment") }} - {{ t("projects.screening.guide.PostScreening.educationBackground.idealProfile") }}:
              </div>
              <template v-if="idealProfile.work_environment">
                <p v-for="(ideal_work_environment, ideal_work_environment_key) of idealProfile.work_environment" :key="`carrer-${ideal_work_environment_key}`">
                  {{ ideal_work_environment }}
                </p>
              </template>
              <div v-for="(_, environment_key) of environmentEvaluation" :key="`environment-${environment_key}`">
                <div class="enum-title">
                  {{ t(`projects.details.activities.screening.motivationEnum.${environment_key}`) }}<span v-if="!['attraction', 'retention', 'repulsion'].includes(environment_key)">&nbsp;{{ t('text.fromCandidate') }}</span>:
                </div>
                <TextArea
                  v-model="environmentEvaluation[environment_key]"
                  class="item-input"
                  :warning-chars="250"
                  rows="5"
                  :placeholder="t('projects.details.constructionGuide.ranking.addDescription')"
                  @change="update('work_environment')"
                  @focus="refetchScreening(), refetchIdealProfile()"
                />
              </div>
            </div>
          </template>
          <template v-if="personalEvaluation">
            <div class="separator" />
            <div class="form-group">
              <div class="evaluation-heading">
                {{ t("projects.construction.titles.personal") }} - {{ t("projects.screening.guide.PostScreening.educationBackground.idealProfile") }}:
              </div>
              <template v-if="idealProfile.personal_life">
                <p v-for="(ideal_personal_life, ideal_personal_life_key) of idealProfile.personal_life" :key="`carrer-${ideal_personal_life_key}`">
                  {{ ideal_personal_life }}
                </p>
              </template>
              <div v-for="(_, personal_key) of personalEvaluation" :key="`mot-${personal_key}`">
                <div class="enum-title">
                  {{ t(`projects.details.activities.screening.motivationEnum.${personal_key}`) }}<span v-if="!['attraction', 'retention', 'repulsion'].includes(personal_key)">&nbsp;{{ t('text.fromCandidate') }}</span>:
                </div>
                <TextArea
                  v-model="personalEvaluation[personal_key]"
                  class="item-input"
                  :warning-chars="250"
                  rows="5"
                  :placeholder="t('projects.details.constructionGuide.ranking.addDescription')"
                  @change="update('personal_life')"
                  @focus="refetchScreening(), refetchIdealProfile()"
                />
              </div>
            </div>
          </template>
          <div class="form-group">
            <div>
              <div class="enum-title">
                {{ t("projects.details.constructionGuide.ranking.relocationAvailability") }}
              </div>
              <RadioSimple
                v-for="option in willingToRelocateValue"
                :id="`relocate-${option}`"
                :key="`relocate-${option}`"
                v-model="willingToRelocate"
                class="panel-radio-simple"
                :value="option"
                :label="willingToRelocateIntl[option]"
                :name="`relocate-${option}`"
                @update:model-value="update('willing_to_relocate')"
              />
            </div>
            <div>
              <div class="enum-title">
                {{ t("projects.screening.guide.PostScreening.jobModel") }}
              </div>
              <RadioSimple
                v-for="(item, index) of workModelType"
                :id="`work_model-${item}`"
                :key="index"
                v-model="workModelTypeValue"
                :value="item"
                :label="workModelIntl[item]"
                :name="`work_model-${item}`"
                class="panel-radio-simple"
                @update:model-value="update('work_model')"
              />
            </div>
          </div>
        </div>
        <renderWarning :keys="{ key: 'package', i18nSectionKey: 'motivation' }" />
      </BoxAccordion>

      <BoxAccordion
        id="approval"
        icon="project_priorityze"
        :title="t('projects.interview.accordeons.approval')"
        :open="accordeons.approval ?? true"
        @selected="toggle('approval')"
        @close-all="closeAll"
      >
        <div>
          <div class="enum-title">
            {{ t("projects.details.constructionGuide.ranking.approvedToInterviews") }}
          </div>
          <span v-for="(item, index) of candidateApprovalStatus" :key="index">
            <RadioSimple
              :id="`candidate-approval-${item}`"
              v-model="candidateApproval.approved_for_interview"
              type="radio"
              :value="item"
              :label="`${approvalStatusEnum[item.toUpperCase()]}`"
              :name="`candidate-approval-${item}`"
              :disabled="candidateApproval.approved_for_interview === 'YES'"
              @update:model-value="updateApproval('approved_for_interview', $event)"
            />
          </span>
        </div>
        <div v-if="candidateApproval.approved_for_interview !== 'YES'">
          <div class="enum-title">
            {{ t("projects.details.constructionGuide.ranking.approvedReason") }}:
          </div>
          <Select
            :model-value="candidateApproval.reason"
            name="disapproval-reason"
            :options="disapprovalReasonEnum"
            :disabled="draft || candidateApproval.approved_for_interview === 'YES' || candidateApproval.approved_for_interview === null"
            :placeholder="t('projects.create.placeholders.select')"
            :searchable="false"
            @update:model-value="updateApproval('reason', $event)"
          />
        </div>
        <div>
          <div class="enum-title">
            {{ t("projects.details.constructionGuide.ranking.selectPriorityClassification") }}:
          </div>
          <Select
            id="priority-classification"
            :model-value="candidateApproval.priority_classification"
            name="priority-classification"
            :options="priorityClassification"
            :disabled="draft"
            :placeholder="t('projects.create.placeholders.select')"
            :searchable="false"
            @update:model-value="updateApproval('priority_classification', $event)"
          />
        </div>
      </BoxAccordion>
    </div>
  </div>
</template>

<style scoped lang="scss">
.organization-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.inline {
  display: flex;
  // prevent parent
  margin: 0.5rem !important;
  justify-content: center;
  gap: 1.25rem;

  // prevent parent
  > div {
    margin: 0 !important;
  }
}

label {
  text-transform: capitalize;
}

.panel {
  margin: 0.5rem;
  border-radius: 8px;
  background: #fff 0% 0% no-repeat padding-box;
  box-shadow: 0 3px 4px #00000029;
  padding: 0.5rem;
}

.title {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-inline: 0.5rem;
  gap: 1rem;
  letter-spacing: 0.77px;

  span {
    color: $red-violet;
    font-weight: 600;
    font-size: 1.25rem;
    text-transform: uppercase;
    letter-spacing: inherit;
  }
}

.center {
  justify-content: center;
}

.icon-wrapper {
  color: inherit;
}

.base-item {
  display: flex;
  align-items: baseline;
  gap: 0.25rem;
  margin-block: 0.5rem 1.5rem;

  .item-input {
    width: 100%;
  }
}

.item-label {
  color: $gray-dark;
  font-size: .875rem;
  font-weight: 700;
  margin: 1.25rem 0 0.25rem 0;
}

.item-label-lg {
  font-size: 1rem;
  text-transform: uppercase;
}

.motivation-items {
  display: flex;
  flex-direction: column;
}

button.panel-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  margin: 0.5rem 0 1.5rem 0;

  &:focus,
  &:hover {

    &.add,
    &.add > span {
      color: $red-violet-medium;

      svg {
        vertical-align: bottom;
      }
    }
  }

  &.add,
  &.add > span {
    color: $gray-dark;
    font-size: 0.75rem;
    letter-spacing: 0;
    font-weight: 600;

    svg {
      vertical-align: bottom;
    }
  }
}

// TODO: remove this deep
.ideal-profile-accordeon:deep(.box-accordeon) {
  margin-left: 2rem;

  .header {
    border-bottom: 1px solid $gray-x14;
    margin-bottom: 1rem;
  }

  .base-item {
    margin-inline: 0;
    gap: 1rem;
  }
}

.base-item-input {
  width: 100%;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 1.5rem;
  gap: 0.75rem;

  .add-tag {
    color: $primary;
    font-size: 0.75rem;
    letter-spacing: 0;

    &:hover {
      color: $red;
    }

    svg {
      vertical-align: sub;
    }
  }
}

.separator {
  width: 100%;
  border-bottom: 1px solid $gray-border;
  margin-block: 1rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-block: 1rem;
}

.enum-group {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-block: 0.5rem 1.5rem;
  margin: 0 1.5rem 0 0.125rem;
}
.enum-title,
.label-title {
  font-size: 0.875rem;
  padding-block: 0.5rem;
  font-weight: 600;
  user-select: none;
}
.enum-tooltip {
  font-size: 0.75rem;
  padding-block: 0.5rem;
  user-select: none;
}

.panel-radio-simple {
  font-size: 0.875rem;
  letter-spacing: 0;
  color: $gray-dark;
  font-weight: 600;
  margin: 0 0.5rem 0 0;
}
:deep(.imua-radiosimple .imua-radiosimple__label) {
  font-size: 0.875rem;
  padding-right: 0.875rem;
}
.evaluation-heading {
  color: $red-violet;
  font-size: 0.875rem;
  padding-block: 0.5rem;
  font-weight: 700;
}
.evaluation-heading + p {
  margin-right: 0.5rem;
  word-break: break-word;
  line-height: 1.25rem;
  white-space: pre-wrap;
  font-size: 0.875rem;
}

.evaluation-heading + p ~ div:last-child {
  margin-bottom: 1rem;
}
.warning-return {
  display: flex;
  font-size: 0.875rem;
  gap: 0.25rem;
  word-break: break-word;
  align-items: flex-start;
  margin-inline-start: 1.875rem;
  margin-block-start: 0;

  :deep(.icon-warning) {
    width: 0.875rem;
    aspect-ratio: 1;
    flex-shrink: 0;
    margin-block-start: 2px;
  }
}
</style>
