<script setup lang="ts">
import max from "lodash-es/max";
import findLastIndex from "lodash-es/findLastIndex";
import cloneDeep from "lodash-es/cloneDeep";

import { paginateTexts } from "@/utils/text";

// schemas
import type {
  IdealProfile,
  InterviewActivities,
  InterviewRankedCandidatesSchema,
} from "@/schemas/project";
import ExportPage from "@/common/components/Export/ExportPage.vue";
import Resume from "@/common/components/Export/Resume.vue";
import { formatScore } from "@/utils/numbers";

const props = withDefaults(
  defineProps<{
    bullet?: boolean
    isExport: boolean
    personName: string | null
    personFirstName: string | null
    idealProfile: IdealProfile
    interviewActivitie: InterviewActivities
    interviewRanked: InterviewRankedCandidatesSchema
    idealProfileAchievements: any
    achievementScore: number | null
    interviewAchievements: any
  }>(),
  {
    bullet: true,
    isExport: false,
    personName: null,
    personFirstName: null,
  },
);

const { t } = useI18n();

interface PageValueType {
  id?: string | null
  score?: string | number | null
  bullet?: boolean
  value: string
}

interface PageItemsType {
  id?: string | null
  score?: string | number | null
  ideal_profile: PageValueType[]
  evaluated: PageValueType[]
}

interface PagesTypes {
  score?: string | number | null
  items: PageItemsType[]
}

const {
  isExport,
  bullet,
  personName,
  personFirstName,
} = toRefs(props);

const emptyPage = {
  score: null,
  items: [],
} as PagesTypes;

const emptyItem = {
  id: "",
  score: null,
  ideal_profile: [],
  evaluated: [],
} as PageItemsType;

const pages = ref<PagesTypes[]>([]);

const boxWidthA = 390;
const boxWidthB = 394;
const maxLines = 25;
const fontSize = "14px";

function paginatePages() {
  const achievements = props.interviewAchievements;

  const newPage: PagesTypes[] = [];

  newPage[0] = cloneDeep(emptyPage);

  let lines = 0;
  let items = 0;

  const list_items = props.idealProfileAchievements;

  Object.entries(list_items).forEach(([key, value], index) => {
    const new_empty_page = cloneDeep(emptyPage);
    new_empty_page.score = props.achievementScore || 0;

    if (index === 0)
      newPage[0].score = new_empty_page.score;
    else
      lines += 1;

    const list_b = achievements[key];

    const new_empty_item = cloneDeep(emptyItem);
    new_empty_item.id = key;
    new_empty_item.score = list_b?.score || null;

    if (index === 0)
      newPage[0].items[items] = cloneDeep(new_empty_item);

    if (lines >= maxLines) {
      lines = 0;
      items = 0;
      newPage.push(cloneDeep(new_empty_page));
    }

    const last_page_index = findLastIndex(newPage);

    if (!newPage[last_page_index].items[items])
      newPage[last_page_index].items[items] = cloneDeep(new_empty_item);

    const list_a_texts = paginateTexts(
      value.description || "",
      fontSize,
      boxWidthA,
      maxLines,
      lines,
    );

    const list_b_texts = paginateTexts(
      list_b?.observations || "",
      fontSize,
      boxWidthB,
      maxLines,
      lines,
    );

    const texts_length = max([list_a_texts?.length, list_b_texts?.length]) || 0;

    let new_page_index = last_page_index;

    for (let i = 0; i < texts_length; i++) {
      const a_value = list_a_texts[i];
      const b_value = list_b_texts[i];

      const list_lines = max([a_value?.lines, b_value?.lines]) || 0;

      lines += list_lines;

      if (!newPage[new_page_index].items[items]) {
        newPage[new_page_index].items = [cloneDeep(new_empty_item)];
        items = 0;
      }

      if (a_value) {
        newPage[new_page_index].items[items].ideal_profile.push({
          id: key,
          value: a_value.text,
          bullet: !(i > 0),
        });
      }

      if (b_value) {
        newPage[new_page_index].items[items].score
          = i + 1 === texts_length ? list_b?.score || 0 : null;

        newPage[new_page_index].items[items].evaluated.push({
          id: key,
          value: b_value.text,
          bullet: !(i > 0),
        });
      }

      if (texts_length > 1 && i + 1 < texts_length) {
        newPage.push(cloneDeep(new_empty_page));
        new_page_index++;
        items = 0;
        lines = 0;
      }
    }
    items++;
  });

  pages.value = newPage;
}

function checkIsValid(value: any) {
  return !(value === null || value === undefined);
}

watchEffect(() => {
  pages.value = [];
  paginatePages();
});
</script>

<template>
  <ExportPage
    v-for="(page, index) in pages"
    :key="index"
    :is-export="isExport"
    :page-index="index"
    :page-name="t('exportPDF.labels.realizations')"
    :person-name="personName"
  >
    <div class="pdf-realizations">
      <div class="box-grid">
        <div class="titles titles-ideal">
          {{ t("exportPDF.labels.idealProfile") }}
        </div>
        <div class="titles titles-person">
          {{ personFirstName }}
        </div>
        <div class="box-item-score">
          <div class="box-score total">
            {{ formatScore(achievementScore || 0) }}%
          </div>
        </div>
      </div>
      <div
        v-for="(item, item_idx) in page.items"
        :key="item_idx"
        class="box-grid"
        :class="{
          'box-border': item_idx > 0,
        }"
      >
        <div class="box-item-ideal">
          <Resume
            :show-title="false"
            :show-label="false"
            :show-circle="bullet"
            font-size="0.875rem"
            line-height="1.25rem"
            :items="
              item?.ideal_profile?.map((text) => ({
                bullet: text.bullet,
                value: text.value,
              }))
            "
          />
        </div>
        <div class="box-item-persona">
          <Resume
            :show-title="false"
            :show-label="false"
            :show-circle="bullet"
            font-size="0.875rem"
            line-height="1.25rem"
            :items="
              item?.evaluated?.map((text) => ({
                bullet: text.bullet,
                value: text.value,
              }))
            "
          />
        </div>
        <div class="box-item-score">
          <div v-if="checkIsValid(item?.score)" class="box-score">
            {{ formatScore(item?.score || 0) }}%
          </div>
        </div>
      </div>
    </div>
  </ExportPage>
</template>

<style lang="scss" scoped>
.pdf-realizations {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .separator {
    height: 1px;
    border-top: 1px solid $gray-border;
    margin: 1rem 0;
  }

  .box-grid {
    display: flex;
    flex-direction: row;
    gap: 3.25rem;
  }

  .box-grid.box-border {
    border-top: 1px solid $gray-border;
    padding-block-start: 0.875rem;
  }

  .titles {
    display: flex;
    flex: 1;
    align-items: flex-end;
    font-size: 1.125rem;
    font-weight: bold;
    line-height: 1.5;
    text-transform: uppercase;

    &-ideal {
      color: $gray-dark;
    }

    &-person {
      color: $yellow;
    }
  }

  .box-item-score {
    display: flex;
    min-width: 5rem;
    max-width: 5rem;
    flex: 1;
    align-items: flex-end;

    .box-score.total {
      border-color: $yellow;
      background: $yellow;
      color: $white-full;
    }

    .box-score {
      display: flex;
      width: 5rem;
      height: 2.625rem;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 1px solid $gray-text;
      border-radius: 6px;
      color: $gray-text;
      font-size: 1.25rem;
      font-weight: bold;
    }
  }

  .box-item-ideal,
  .box-item-persona {
    display: flex;
    flex: 1;
  }
}
</style>
