<script setup lang="ts">
import type { ProjectsParticipated } from "@/entities/Person/activity";

const props = defineProps({
  projects: {
    type: Array as () => ProjectsParticipated[],
    required: true,
  },
});
const projectsData = toRef(props.projects);

watch(props, () => {
  projectsData.value = props.projects;
});
</script>

<template>
  <li v-for="project in projectsData" :key="project.id">
    <div class="item">
      <div class="link">
        <router-link :to="`/projects/${project.id}/${project.project_stage}/activities`" target="_blank">
          <b>{{ project.project_identifier }}:</b>
          {{ project.project_name }} -
          <b>&nbsp;{{ project.corporation_name }}</b>&nbsp;-
          {{ project.stage }}
        </router-link>
      </div>
    </div>
  </li>
</template>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
ul {
  max-height: 6.8rem;
  overflow-y: scroll;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 7px;
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgb(0 0 0 / 50%);
    box-shadow: 0 0 1px rgb(255 255 255 / 50%);
  }
}

ul li .item {
  color: $gray-dark;
  font-family: "Open Sans", sans-serif;
  font-size: 0.813rem;
  display: flex;
}

ul li {
  margin-bottom: 0.938rem;
}

.item {
  .link {
    &:hover a,
    &:hover b {
      cursor: pointer;
      color: $primary;
    }
  }
}
</style>
