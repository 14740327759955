<script lang="ts">
import { useI18n } from "vue-i18n";
import type {
  ComputedRef,
  Ref,
} from "vue";
import { mdiPlusCircleOutline } from "@mdi/js";
import useVuelidate from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
import { completeDate } from "@/utils/dates";
import { sortExperiences } from "@/utils/experiences-sorter";
import type { Person } from "@/entities/Person/person";
import type { WorkExperience } from "@/entities/Person/work-experience";
import WarningIcon from "@/assets/images/alert_circle_icon.svg";
import RefreshIcon from "@/assets/images/refresh_icon.svg";
import AvatarInput from "@/components/inputs/AvatarInput/AvatarInput.vue";
import Tooltip from "@/components/tooltip/Tooltip.vue";
import { formatCurrencyFromValue, yearFor } from "@/utils/parsers";
import { ImagesTypesEnum } from "@/schemas/enums/images";
import { createAvatar } from "@/http_services/files/avatar";
import CalendarInput from "@/components/inputs/modalInputs/CalendarInput.vue";
import TextInput from "@/components/inputs/modalInputs/TextInput.vue";
import TelephoneInput from "@/components/inputs/modalInputs/TelephoneInput.vue";
import { SvgIcon } from "@/components/icon";
import TrashIcon from "@/assets/images/trash_icon.svg";

interface extraPersonProps { restricted_profile: boolean; included_in_project: boolean }

export default defineComponent({
  name: "HeaderEditable",
  components: {
    WarningIcon,
    RefreshIcon,
    TelephoneInput,
    CalendarInput,
    AvatarInput,
    Tooltip,
    TextInput,
    SvgIcon,
    TrashIcon,
  },
  setup() {
    const { t } = useI18n();

    const avatarFile = ref(null);
    const defaultPhoneCode = "+ 55";

    const person = inject("selectedPerson") as Ref<Person & extraPersonProps>;
    provide("person", person);

    const avatarUrl = ref(person.value.avatar_url || "");
    const toggleEdit: (a: string) => void = inject("toggleEdit");
    const onSubmit: (a: Partial<Person>) => void = inject("onSubmitPerson");

    const [year, month, day] = person.value.birth_date
      ? person.value.birth_date.split("-")
      : new Date().toISOString().split("T")[0];

    const state = reactive({
      fullName: `${person.value.first_name} ${person.value.last_name}`,
      emails: person.value.email_addresses,
      phones: person.value.phones,
      date: person.value.birth_date ? new Date(Number(year), Number(month) - 1, Number(day)) : null,
      linkedin: person.value.linkedin_url,
      location: person.value.address?.name,
    });

    if (person.value.email_addresses.length === 0)
      state.emails.push({ email_address: "", is_main: true });

    if (person.value.phones.length === 0)
      state.phones.push({ calling_code: "+ 55", number: "" });

    const lastExperience = computed(
      () => sortExperiences<WorkExperience>(person.value.work_experience)[0],
    ) as ComputedRef<WorkExperience>;

    const lastExperienceDate = computed(() => {
      const start = lastExperience.value?.start_date;
      const end = lastExperience.value?.end_date;
      if (start)
        return `- ${yearFor(start)}/${yearFor(end)}`;
      return "";
    });

    const borderColor = computed(() =>
      (person.value.restricted_profile
        ? "gray"
        : person.value.included_in_project
          ? "yellow"
          : "transparent"));

    const changeAvatar = (value: Blob) => {
      avatarFile.value = value;
    };

    const removeAvatar = () => {
      avatarUrl.value = "";
      avatarFile.value = null;
    };

    const payload = computed(() => {
      const name = state.fullName;
      const [first_name, ...last_name] = name.split(" ");

      return {
        first_name,
        last_name: last_name.join(" "),
        linkedin_url: state.linkedin ? state.linkedin : null,
        email_addresses: state.emails.filter(mail => mail.email_address),
        phones: state.phones.filter(phone => phone.calling_code && phone.number),
        birth_date: state.date ? state.date.toISOString().split("T")[0] : null,
        address: state.location ? { name: state.location } : null,
        avatar_url: avatarUrl.value,
      };
    });

    const isFullName = () => {
      if (state.fullName.trim().split(" ").length > 1)
        return true;

      return false;
    };

    const linkedinIsValid = () => {
      const pattern = /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)/;
      return !helpers.req(state.linkedin) || pattern.test(state.linkedin);
    };

    const rules = {
      fullName: {
        required: helpers.withMessage(t("validator.required"), required),
        isFullName: helpers.withMessage(t("persons.editablePersonForm.errorName"), isFullName),
      },
      linkedin: {
        likedinIsValid: helpers.withMessage(
          t("persons.editablePersonForm.linkedinError"),
          linkedinIsValid,
        ),
      },
    };

    const v$ = useVuelidate(rules, state);
    const fullNameError = computed(() => v$.value.fullName.$errors[0]?.$message.toString());
    const linkedinError = computed(() => v$.value.linkedin.$errors[0]?.$message.toString());

    const emailErrors = ref([]);

    const validateEmails = () => {
      emailErrors.value = [];
      const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      state.emails.forEach((el, i) => {
        if (i !== 0) {
          emailErrors.value[i] = {
            message: pattern.test(el.email_address)
              ? ""
              : t("persons.editablePersonForm.emailError"),
            error: !pattern.test(el.email_address),
          };
        }
      });

      const emailsIsValid = !emailErrors.value.some(el => el.error);
      return emailsIsValid;
    };

    const phoneErrors = ref([]);

    const validatePhones = () => {
      phoneErrors.value = [];
      const pattern = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;
      state.phones.forEach((el, i) => {
        if (i !== 0) {
          phoneErrors.value[i] = {
            message: pattern.test(el.number) ? "" : t("persons.editablePersonForm.phoneError"),
            error: !pattern.test(el.number),
          };
        }
      });

      const phonesIsValid = !phoneErrors.value.some(el => el.error);
      return phonesIsValid;
    };

    const submitPerson = async () => {
      if (avatarFile.value) {
        const data = await createAvatar(avatarFile.value, ImagesTypesEnum.persons);
        avatarUrl.value = data.url;
      }

      if ([await v$.value.$validate(), validateEmails(), validatePhones()].every(el => el))
        onSubmit(payload.value);
    };

    return {
      t,
      completeDate,
      person,
      lastExperience,
      lastExperienceDate,
      toggleEdit,
      formatCurrencyFromValue,
      changeAvatar,
      submitPerson,
      defaultPhoneCode,
      removeAvatar,
      addMore: mdiPlusCircleOutline,
      ...toRefs(state),
      emailErrors,
      phoneErrors,
      linkedinError,
      fullNameError,
      validateEmails,
      borderColor,
      payload,
      avatarFile,
      avatarUrl,
    };
  },
});
</script>

<template>
  <section class="header-wrapper">
    <div class="avatar-section">
      <AvatarInput
        v-model="avatarFile"
        :avatar-url="avatarUrl ? `${avatarUrl}?version=${person._version}` : null"
        :name="`${person.first_name.charAt(0)} ${person.last_name}`"
        :border-color="borderColor"
        entity="person"
      />
      <div class="edit-actions remove-avatar">
        <button class="cancel-button regular-button transparent" @click="removeAvatar">
          {{ t("text.form.removeAvatar") }}
        </button>
      </div>
      <p class="refresh-date">
        <span class="icon">
          <RefreshIcon />
        </span>
        {{ completeDate(person.updated_at || person.inserted_at) }}
      </p>
    </div>
    <div class="person-section">
      <div class="professional-data">
        <div class="data-wrapper">
          <TextInput
            v-model="fullName"
            :placeholder="t('persons.editablePersonForm.name')"
            class="name-input"
            :error-message="fullNameError"
          />

          <div v-if="lastExperience?.role_name" class="info-wrapper">
            <span class="highlight"> {{ lastExperience.role_name }}</span>
            <Tooltip :tooltip-text="t('persons.editablePersonForm.tooltipText')">
              <WarningIcon class="warning-icon" />
            </Tooltip>
          </div>
          <div v-if="lastExperience?.company?.name" class="info-wrapper info-small">
            <span class="highlight">
              {{ lastExperience?.company?.name }}
              {{ lastExperienceDate }}
            </span>
            <Tooltip :tooltip-text="t('persons.editablePersonForm.tooltipText')">
              <WarningIcon class="warning-icon" />
            </Tooltip>
          </div>
          <div v-if="lastExperience?.salary_currency" class="info-wrapper info-small">
            <span>
              <span class="highlight">{{ t("persons.details.lastSalary") }} </span>
              <span class="last-salary">
                {{
                  formatCurrencyFromValue(
                    lastExperience.base_salary,
                    lastExperience.salary_currency,
                  )
                }}
              </span>
            </span>
            <Tooltip :tooltip-text="t('persons.editablePersonForm.tooltipText')">
              <WarningIcon class="warning-icon" />
            </Tooltip>
          </div>
          <TextInput
            v-model="linkedin"
            :placeholder="t('persons.editablePersonForm.linkedin')"
            class="linkedin-input"
            :error-message="linkedinError"
          />
        </div>
        <div class="edit-actions">
          <button class="regular-button" @click="submitPerson">
            {{ t("persons.details.submit") }}
          </button>
          <button class="cancel-button regular-button transparent" @click="toggleEdit('')">
            {{ t("persons.details.cancel") }}
          </button>
        </div>
      </div>
      <div class="personal-data">
        <div class="phone-input-group">
          <div v-for="(phone, index) in phones" :key="index" class="phone-input">
            <TelephoneInput
              v-model:countryCode="phone.calling_code"
              v-model:phoneNumber="phone.number"
              :default-code="defaultPhoneCode"
              :error-message="phoneErrors[index]?.message"
            />
            <a
              v-if="index === phones.length - 1"
              class="plus-icon"
              @click="phones.push({ calling_code: '+ 55', number: '' })"
            >
              <SvgIcon type="mdi" :icon="addMore" width="16" height="16" />
            </a>
            <a v-if="index !== 0" @click="phones.splice(index, 1)">
              <TrashIcon class="trash-icon" />
            </a>
          </div>
        </div>
        <div v-for="(email, index) in emails" :key="index" class="email-input-group">
          <TextInput
            v-model.trim.lazy="email.email_address"
            placeholder="Email"
            class="email-input"
            :error-message="emailErrors[index]?.message"
            @focusout="validateEmails"
          />
          <a
            v-if="index === emails.length - 1"
            class="plus-icon"
            @click="emails.push({ email_address: '', is_main: false })"
          >
            <SvgIcon type="mdi" :icon="addMore" width="16" height="16" />
          </a>
          <a v-if="index !== 0" class="trash-icon" @click="emails.splice(index, 1)">
            <TrashIcon class="trash-icon" />
          </a>
        </div>
        <div class="info-wrapper">
          <CalendarInput
            v-model="date"
            class="calendar"
            :placeholder="t('persons.editablePersonForm.birth_date')"
            :max-date="new Date()"
          />
          <TextInput
            v-model="location"
            class="location-input"
            :placeholder="t('persons.editablePersonForm.location')"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.header-wrapper:deep {
  .component-input {
    height: 28px;
  }

  .experience-date {
    span {
      display: contents;
    }
  }
}

.highlight {
  color: $gray-dark;
  font-weight: bold;
}

.info-small {
  font-size: 14px;
}

.warning-icon {
  width: 20px;
}

.professional-data {
  display: grid;
  grid-template-columns: 1fr auto;

  .data-wrapper {
    & > .component-input {
      margin-top: 10px;
    }

    .info-wrapper {
      display: flex;
      align-items: center;

      &:nth-child(2) {
        margin-bottom: 10px;
      }

      span + span {
        margin-left: 10px;
      }

      svg {
        stroke: $red;
      }
    }
  }

  .buttons-wrapper:deep {
    button + button {
      margin-top: 20px;
    }
  }
}

.personal-data:deep {
  .info-wrapper {
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    &.phone-inputs {
      margin-top: 0.5rem;

      .telephone-input-wrapper {
        margin-right: 1rem;

        &:last-child {
          margin-bottom: 0.5rem;
        }
      }
    }
  }

  & > .component-input {
    margin-top: 0.5rem;
  }
}

.header-wrapper {
  display: flex;
}

.person-section {
  width: 100%;
  margin-left: 2rem;

  .last-salary {
    color: $gray-dark;
    font-size: 14px;
  }
}

.edit-actions {
  display: flex;
  flex-direction: column;

  .regular-button {
    margin-bottom: 15px;

    &:hover {
      background: $yellow-dark;
      color: #fff;
    }
  }

  .regular-button.cancel-button {
    background: $gray-lighter;
    color: $gray-dark;
    opacity: 0.6;
  }
}

.name-input {
  width: 220px;
  margin-bottom: 0.5rem;
}

.linkedin-input {
  width: 220px;
  margin-bottom: 1rem;
}

.location-input {
  margin-left: 1rem;
}

.remove-avatar {
  margin-top: 1rem;
}

.email-input-group {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;

  .email-input {
    width: 220px;
    margin-right: 0.5rem;
  }
}

.plus-icon {
  color: $primary;
  cursor: pointer;
}

.trash-icon {
  width: 16px;
  cursor: pointer;
  stroke: #c6c6c6;
}

.phone-input-group:deep {
  display: grid;
  grid-column-gap: 0;
  grid-row-gap: 0;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;

  .phone-input {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    input {
      margin-right: 0.5rem;
    }
  }
}

@media (max-width: 1750px) {
  .phone-input-group {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
