<script setup lang="ts">
import type { Ref } from "vue";
import { debounce } from "lodash-es";
import { FormInput } from "@/components/inputs/modalInputs";
import { Dropdown } from "@/components/dropdown";
import { Loading } from "@/components/loading";
import { TooltipBox } from "@/components/tooltip";

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  fetchMethod: {
    type: Function,
    required: true,
  },
  selectItemCallback: {
    type: Function,
    required: true,
  },
  minWordLength: {
    type: Number,
    default: 1,
  },
  fetchParamKey: {
    type: String,
    default: "name",
  },
  responseDataKey: {
    type: String,
    default: "data",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  autoFill: {
    type: Boolean,
    default: false,
  },
  autoFillColor: {
    type: String,
    required: false,
  },
  tooltipShow: {
    type: Boolean,
    default: false,
  },
  tooltipMesage: String,
  tooltipColor: String,
  tooltipBackground: String,
  updateInputModel: Function,
  initialValue: String,
  recruiterId: {
    type: String,
    default: null,
  },
  inputPlaceholder: String,
  searchNotFoundLabel: String,
  searchErrorLabel: String,
  error: Boolean,
  errorMessage: String,
  svgIcon: String,
  showIcon: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["clear", "click:on-button-click", "select-item"]);

const showTooltip = ref(false);
const searchInput = ref((props.initialValue as string) || "");
const ready = ref(false);
const fetchError = ref(false);
const loading = ref(false);
const noFetch = ref(false);
const dropdownActive = ref(false);
const data = ref([]);
const { fetchParamKey, responseDataKey, initialValue, recruiterId } = toRefs(props);
const fetchMethod = toRef(props, "fetchMethod") as Ref<(params) => Promise<any>>;
const selectItemCallback = toRef(props, "selectItemCallback") as Ref<(item) => void>;
const updateInputModel = toRef(props, "updateInputModel") as Ref<(item) => string>;

function onInputFocus() {
  if (ready.value)
    dropdownActive.value = true;
}

function onInputBlur(e) {
  if (e?.relatedTarget?.id !== `${props.id}-list`)
    dropdownActive.value = false;
}

async function fetchData(searchTerm: string): Promise<void> {
  try {
    dropdownActive.value = true;
    loading.value = true;
    ready.value = false;
    fetchError.value = false;

    const response = await fetchMethod.value({
      limit: 10,
      skip: 0,
      [fetchParamKey.value as string]: searchTerm,
    });
    data.value = response[responseDataKey.value as string];
    if (fetchMethod.value.name === "searchCandidate")
      data.value = data.value.filter(e => !e.is_added_in_placement);

    ready.value = true;
  }
  catch (err) {
    fetchError.value = true;
  }
  finally {
    loading.value = false;
  }
}

const debouncedFetchData = debounce(fetchData, 300);

watch(searchInput, (value, oldValue) => {
  if (value && value.length >= props.minWordLength) {
    if (noFetch.value)
      noFetch.value = false;
    else
      debouncedFetchData(value);
  }

  if (oldValue && !value)
    emit("clear");
});

watch(initialValue, (value = "") => {
  noFetch.value = true;
  searchInput.value = value;
});

function selectItem(item) {
  noFetch.value = true;
  dropdownActive.value = false;

  if (updateInputModel.value)
    searchInput.value = updateInputModel.value(item);
  else searchInput.value = "";

  emit("select-item");
  selectItemCallback.value(item);
}
onMounted(() => {
  if (recruiterId.value !== null)
    selectItemCallback.value(recruiterId.value);
});

function setSearchValue(value: string): void {
  noFetch.value = true;
  searchInput.value = value;
}

function onButtonClick() {
  emit("click:on-button-click");
}
</script>

<template>
  <div class="search-dropdown" @mouseover="showTooltip = true" @mouseout="showTooltip = false">
    <TooltipBox
      :show="tooltipShow && showTooltip"
      :color="tooltipColor"
      :background="tooltipBackground"
      :mesage="tooltipMesage"
    />
    <FormInput
      :id="`${id}-search`"
      v-model="searchInput"
      :name="`${id}-search`"
      :svg-icon="svgIcon"
      :show-icon="showIcon"
      :auto-fill="autoFill"
      :auto-fill-color="autoFillColor"
      :placeholder="inputPlaceholder"
      autocomplete="off"
      :on-focus="onInputFocus"
      :on-blur="onInputBlur"
      :error="error"
      :error-message="errorMessage"
      :disabled="disabled"
      @click:on-button-click="onButtonClick"
    />

    <Dropdown :id="`${id}-list`" :active="dropdownActive" @blur="dropdownActive = false">
      <div v-if="loading" class="search-dropdown-loading">
        <Loading />
      </div>

      <ul v-else-if="ready" class="search-dropdown-list">
        <li
          v-for="item in data"
          :key="item"
          class="search-dropdown-list__item"
          role="button"
          @click="selectItem(item)"
        >
          <slot name="list-item" v-bind="{ item }" />
        </li>

        <li
          v-if="!data?.length"
          class="search-dropdown-list__item search-dropdown-list__item--not-found"
        >
          {{ searchNotFoundLabel }}
        </li>
      </ul>

      <span v-if="fetchError" class="search-dropdown-list__item search-dropdown-list__item--error">
        {{ searchErrorLabel }}
      </span>
    </Dropdown>
  </div>
</template>

<style lang="scss" scoped>
.search-dropdown {
  position: relative;

  &-loading {
    text-align: center;
  }

  &-list {
    &__item {
      --list-item-gutters: 1.5rem;

      display: block;
      margin-inline: calc(var(--list-item-gutters) * -1);
      padding-block: 1rem;
      padding-inline: var(--list-item-gutters);

      &:hover {
        background-color: darken($white-full, 5%);
      }

      &--not-found,
      &--error {
        &:hover {
          background-color: $white-full;
        }
      }

      &--error {
        color: $primary;
      }
    }
  }
}
</style>
