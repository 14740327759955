<script lang="ts">
import { defineComponent } from "vue";
import { Button2 } from "@/components/button";

export default defineComponent({
  name: "AuthButton",
  components: {
    Button: Button2,
  },
  props: {
    label: String,
  },
});
</script>

<template>
  <Button
    type="submit"
    class="auth-button"
    data-test="auth-submit-button"
    filled
    :disabled="$attrs.disabled"
  >
    {{ label }}
  </Button>
</template>

<style scoped lang="scss">
.auth-button {
  width: 100%;
  font-weight: bold;
  padding-block: 1rem;
}
</style>
