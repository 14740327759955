export const privacy = {
  title: "Política de Privacidade",
  content: [
    "POLÍTICA DE PRIVACIDADE DE INFORMAÇÃO E TERMO DE CONSENTIMENTO RESPONSÁVEL",
    [
      "1.",
      "A FLOW EXECUTIVE FINDERS “FLOW\"",
      "é uma empresa comprometida em manter seguras, protegidas e confidenciais as informações que são recebidas por meio de sua plataforma IMUA (www.imua.com.br), primando por atender as normas legais em vigor, especialmente a Lei 12.965 de 23 de abril de 2014 que estabeleceu o Marco Legal da Internet. ",
    ],
    [
      "2. Por essa razão, se você é um(a) potencial candidato(a) (“Potencial Candidato(a))” a oportunidades de vagas que podem vir a ser oferecidas por clientes da FLOW (“Potenciais Empregadores(as))”, pedimos que leia com atenção a presente Política de Privacidade de Informação e Termo de Consentimento Responsável (“Política de Privacidade”), a qual será, automaticamente e independentemente de qualquer outra formalidade, aplicável às informações, aos dados e aos documentos que venham a ser recebidos pela FLOW, mediante transmissão por meio deste website.",
      "3. Utilizando qualquer um de nossos serviços, por meio deste website, o(a) Potencial Candidato(a) considera aceita e aplicável a presente Política de Privacidade. Caso você não esteja de acordo com os termos de nossa Política de Privacidade pedimos que não nos transmita nenhuma informação. Se o fizer, estará concordando com nossos termos de uso. ",
      "4. Esclarecemos que a sua simples visita e navegação em nosso website está protegida e não gerará nenhuma vinculação aos termos da Política de Privacidade.  ",
      "5. A FLOW é uma consultora de seleção de executivos. Portanto, caso seja de seu interesse nos transmitir, pela Internet, voluntariamente as suas informações, para fins de seleção e oportunidades de vagas no mercado, nós da FLOW poderemos utilizar essas informações e dados de acordo com os nossos princípios e nossas regras de privacidade. ",
      "6. A privacidade das informações recebidas é um fator primordial à nossa atividade, motivo pelo qual os dados coletados são tidos por sigilosos e confidenciais, sendo que a FLOW tem a obrigação legal de não revelá-los a terceiros, salvo (i) para a utilização, ora autorizada e consentida, em processos seletivos a \"Potenciais Empregadores(as)\" ou (ii) mediante ordem judicial. ",
      "7. Os dados e informações coletados têm o propósito de gerar oportunidades profissionais àqueles(as) que realizarem a transmissão dos dados à FLOW, sendo que esses dados e informações ficarão armazenados e arquivados aos cuidados da FLOW, que buscará preservá-las mediante sistemas de proteção. Observamos que a FLOW procura proteger esses dados segundo os meios disponíveis no mercado, porém não há como garantir 100% de segurança da informação, na medida em que invasões são possíveis mesmo nos sistemas mais avançados e existentes no mundo. ",
      "8. A transmissão de informações deverão ter em vista a possibilidade de geração de oportunidades profissionais aos (as) Potenciais Candidatos(as), motivo pelo qual a FLOW precisará receber dados que incluirão, mas não se limitarão, a: nome completo, endereço, conta de e-mail, telefone e demais dados pessoais (RG, CPF, profissão, estado civil).  Ainda, poderá ser necessária a transmissão de outros dados, a exemplo de: data de nascimento, fotografia, formação escolar, experiências prévias, histórico profissional, interesses, indicações de referência e curriculum vitae.",
      "9. O envio desses dados e documentos passarão a poder ser utilizados pela FLOW, a seu critério, dada a concordância de sua utilização para os fins exclusivamente descritos neste termo. ",
      "10. A veracidade das informações serão sempre de única e exclusiva responsabilidade, civil e criminal, do(a) Potencial Candidato(a), ou seja, da pessoa que fizer a transmissão dos dados à FLOW, a qual se isenta de qualquer responsabilidade pelo recebimento de informações inverídicas, falsas ou incompletas.",
      "11. Caso não haja mais o interesse por parte do(a) Potencial Candidato(a) de manter seus dados e informações com a FLOW, solicitamos que nos informe para que os dados sejam apagados do sistema do banco de dados (acesse aqui).",
      "12. É importante esclarecer que o envio de dados e informações à FLOW não  pode ser considerado como uma garantia de participação em processos de seleção, na medida em que a FLOW se reserva o direito de analisar a pertinência das participações nos processos de seleção que promove. Nenhum(a) Potencial  Candidato(a) poderá reclamar ou reivindicar a participação em processos de seleção, ou requerer obrigatoriamente qualquer explicação, caso não seja convidado a participar de processos seletivos.",
      "13. Caso o(a) Potencial Candidato(a) informe referências para checagem de seu histórico pessoal e profissional, caberá a ele(a) se assegurar que a FLOW poderá entrar em contato. ",
      "14. Este website e todo o seu conteúdo, sem qualquer exceção, são de propriedade exclusiva da FLOW, sendo protegidos por Lei. ",
      "15. A FLOW se isenta da responsabilidade por quaisquer ações ou informações oriundas de fontes externas a este website, ainda que provenientes de acessos originados a partir de links de nosso website.",
      "16. A FLOW poderá manter os dados em seus bancos de dados por tempo indeterminado. Solicitamos que a cada 6 (seis) meses atualize suas informações.",
      "17. Caso você possua alguma dúvida ou deseje algum esclarecimento adicional em relação à nossa Política de Privacidade, pedimos que entre em contato com contato{'@'}flowef.com, pois estaremos à disposição para ajudá-lo(a) no que for possível.",
      "18. O presente termo de uso será regido e interpretado em conformidade com as Leis da República Federativa do Brasil. Qualquer conflito que surja em virtude do uso deste website será submetido ao Foro Central da Comarca da Capital do Estado de São Paulo.",
    ],
  ],
};
