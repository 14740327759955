<script setup lang="ts">
import { format, parseISO } from "date-fns";
import { mdiPlusBox } from "@mdi/js";
import ProjectStages from "@/common/components/ProjectStages.vue";
import { SvgIcon } from "@/components/icon";
import { usePersonMe } from "@/common/composables/usePerson";
import { Avatar } from "@/components/avatar";
import ActivitiesSchedule from "@/common/components/Export/ActivitiesSchedule.vue";
import { ButtonSpan } from "@/components/button";
import { SearchInput } from "@/components/inputs/modalInputs";
import type { Activity } from "@/types/activity";

const props = defineProps<{ id: string }>();
const router = useRouter();
const route = useRoute();
const { t } = useI18n();

const {
  error,
  data: activities,
  refetch: fetchScreening,
} = useScreeningsActivitiesQuery({
  project_id: String(route.params.id),
  sort: ["activity_status"],
});
const activitiesData = ref();

const {
  data: recruiter,
} = await usePersonMe();

const newActivity = ref({
  description: "SCREENING_MEETING",
  activity_date: null,
  project_id: props.id,
  recruiter: {
    person_id: recruiter.value?.data.id,
  },
  candidate: null,
});

const {
  tabs,
  goTo,
} = useScreeningTabs(props.id);

const currentRoute = computed(() => tabs.value.findIndex(tab => tab.key === router.currentRoute.value.meta.tab));

async function createEmptyActivity() {
  if (!recruiter.value) {
    console.log("error: recruiter not found");
    return;
  }
  const draftActivity = await addScreeningActivity(newActivity.value);
  if (draftActivity)
    goToActivity(undefined, draftActivity);
}

function goToActivity(person_id: string | undefined, id: string) {
  const personId = person_id || "draft";
  router.push(`/projects/${props.id}/screenings/person/${personId}/activity/${id}`);
}

function formatDate(date: string | null | undefined) {
  if (!date)
    return "";
  return format(parseISO(date), "dd/MM/yyyy");
}

const {
  data: project,
} = useProject(props.id);

const {
  mutate: updateProject,
} = updateProjectMutation(String(route.params.id));

const {
  mutate: patchScreeningActivity,
} = useScreeningActivityMutation();

const creatingSchedule = ref(false);
const dropdown = ref({ active: false });

const hasSchedule = computed(() => {
  if (!project.value?.schedules || !Object.values(project.value?.schedules).filter(Boolean).length)
    return false;
  const scheduleKeys = Object.values(project.value?.schedules || {});
  return scheduleKeys.filter(data => Object.values(data).filter(Boolean)).length > 0;
});

function updateDateActivity(id: string, date: string) {
  const [screening] = activities.value.filter((item: { _id: string }) => item._id === id);
  patchScreeningActivity({
    project_id: id,
    activity: {
      ...screening,
      activity_date: date,
    },
  });
};

function submitSchedule(schedules) {
  updateProject({
    ...project.value,
    schedules,
  });
  creatingSchedule.value = false;
}

function onInputSearch(value: string) {
  const filteredActivities = computed(() => {
    const searchTermLowerCase = value.toLowerCase().trim();
    return activities.value.filter((activity: Activity) => {
      const fullName = `${activity.candidate?.first_name} ${activity.candidate?.last_name}`;
      return fullName.toLowerCase().trim().includes(searchTermLowerCase);
    });
  });
  if (!filteredActivities.value)
    return;

  activitiesData.value = filteredActivities.value;
}
onMounted(fetchScreening);
watchEffect(() => {
  if (recruiter.value)
    newActivity.value.recruiter.person_id = recruiter.value?.data.id;
  if (activities.value)
    activitiesData.value = activities.value;
});
</script>

<template>
  <section class="project-activities">
    <div v-if="error">
      could not load
    </div>

    <div class="project-container" data-testid="project-activities">
      <ProjectStages :id="props.id" />
      <ProjectTabs :stage-tabs="tabs" :active="currentRoute" class="tabs" @change="goTo($event)" />
      <div class="project-wrapper">
        <div class="search-container">
          <SearchInput :placeholder="t('persons.searchPerson')" @search="onInputSearch($event)" />
        </div>
        <div class="project-header">
          <div class="title">
            {{ t("projects.screening.labels.screenings") }}
          </div>
          <div class="actions">
            <Button class="button-pluss" variation="primary" @click="createEmptyActivity()">
              <SvgIcon icon="plus" margin="none" width="1.25rem" height="1.25rem" />
              {{ t("projects.screening.labels.newScreening") }}
            </Button>
          </div>
        </div>
        <div class="project-body">
          <div v-if="activities" class="list-activities">
            <div class="list-activities-titles">
              <div>
                {{ t("text.date") }}
              </div>
              <div>
                {{ t("text.name") }}
              </div>
              <div>
                {{ t("text.activitie") }}
              </div>
              <div class="status">
                {{ t("text.status") }}
              </div>
              <div class="actions" />
              <div />
            </div>
            <TransitionGroup>
              <div
                v-for="activity in activitiesData"
                :key="activity._id"
                class="list-activities-candidate"
                :class="{ draft: !activity?.candidate }"
              >
                <InputDateEditable
                  :activity="activity"
                  :model-value="formatDate(activity.activity_date)"
                  @submit="updateDateActivity(activity._id, $event)"
                />
                <div class="avatars" style="display: flex; align-content: center; gap: 1rem">
                  <Avatar :src="activity?.recruiter?.avatar_url" size="30px" image-size="mini" />
                  {{ activity?.recruiter?.first_name }} {{ activity?.recruiter?.last_name }}
                </div>
                <div>
                  <span v-if="activity?.candidate">
                    {{ t("projects.details.activities.screening.screening") }} - {{ `${activity?.candidate?.first_name} ${activity?.candidate?.last_name}` }}
                  </span>
                  <span v-else>
                    {{ t("projects.details.activities.screening.screening") }} - {{ t("text.draft") }}
                  </span>
                </div>
                <div class="status">
                  <BaseTooltip
                    v-if="activity?.candidate && activity.activity_date"
                    position-x="center"
                    position-y="top"
                  >
                    <SvgIcon
                      class="icon-checked"
                      icon="icon_checked"
                      margin="none"
                      width="1.875rem"
                      height="1.875rem"
                    />

                    <template #message>
                      <div class="tooltip-message">
                        {{ t("projects.screening.tooltip.screeningCompleted") }}
                      </div>
                    </template>
                  </BaseTooltip>

                  <BaseTooltip v-if="!activity?.candidate && !activity.activity_date" position-x="center" position-y="top">
                    <SvgIcon icon="pending" margin="none" width="1.875rem" height="1.875rem" />
                    <template #message>
                      <div class="tooltip-message">
                        {{ t("projects.screening.tooltip.pendingScreening") }}
                      </div>
                    </template>
                  </BaseTooltip>
                </div>
                <div class="actions">
                  <Button variation="secondary" @click.self="goToActivity(activity?.candidate?.person_id, activity._id)">
                    {{ t("projects.details.activities.organizeAndAnalyze") }}
                  </Button>
                </div>
              </div>
            </TransitionGroup>
          </div>
          <div class="activities-tab__schedule-header">
            <div class="subtitle">
              {{ t("projects.details.activities.schedule.title") }}
            </div>

            <div v-if="project" class="position-relative">
              <Button
                class="align-center px-1"
                :variation="dropdown.active ? 'primary' : 'secondary'"
                @click="dropdown.active = !dropdown.active"
              >
                <SvgIcon icon="calendar2_icon" width="20" height="20" margin="none" />
                <SvgIcon
                  icon="chevron_icon"
                  width="20"
                  height="20"
                  margin="none"
                  :rotate="dropdown.active ? '180deg' : '0'"
                />
              </Button>

              <div v-show="dropdown.active" class="dropdown">
                <ButtonSpan
                  :icon="{ type: 'mdi', path: mdiPlusBox }"
                  :label="t('projects.details.activities.schedule.create')"
                  variation="default"
                  class="add-schedule"
                  :disabled="hasSchedule"
                  @click="(creatingSchedule = true), (dropdown.active = false)"
                />

                <ButtonSpan
                  icon="edit2_icon"
                  :label="t('projects.details.activities.schedule.edit')"
                  variation="default"
                  class="add-schedule"
                  :disabled="!hasSchedule"
                  @click="(creatingSchedule = true), (dropdown.active = false)"
                />
              </div>
            </div>
          </div>

          <div v-if="project">
            <Transition name="slide-fade">
              <ActitivieScheduleForm
                v-if="creatingSchedule"
                :schedules="project.schedules"
                @cancel="creatingSchedule = false"
                @submit="submitSchedule"
              />
            </Transition>
            <ActivitiesSchedule
              v-if="!creatingSchedule && hasSchedule"
              :schedules="project.schedules"
              :stages="project.stages"
              stage="construction"
            />
            <Checkpoint v-if="hasSchedule" :schedules="project.schedules" :stages="project.stages" stage="construction" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<route lang="yaml">
meta:
  layout: project-stage
  stage: screenings
  tab: activities
</route>

<style scoped lang="scss">
.project-activities {
  min-height: 100vh;
}

.project-container {
  padding-top: 3.75rem;
}

.project-wrapper {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  padding-block-end: 2.5rem;
  background: $white-full;

  .project-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid $gray-border;
    padding-block: 1.3125rem;
    margin-block-end: 1.3125rem;

    .title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: $gray-dark;
      font-size: 1.125rem;
      font-weight: bold;
      text-transform: uppercase;
    }

    .button-pluss {
      display: flex;
      flex-direction: row;
      gap: 0.25rem;
    }
  }

  .project-body {
    display: flex;
    flex-direction: column;
    padding-block-end: 1rem;

    .list-activities {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .status {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }

      .actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
      }

      .icon-checked {
        color: $green-medium;
      }

      &-titles {
        display: grid;
        grid-template-columns: 2fr 2fr 2fr 1fr 2fr;
        gap: 1rem;

        div {
          color: $primary;
          font-size: 0.75rem;
          font-weight: bold;
          text-transform: uppercase;
        }
      }

      &-candidate {
        display: grid;
        grid-template-columns: 2fr 2fr 2fr 1fr 2fr;
        align-items: center;
        gap: 1rem;

        .icon-edit {
          cursor: pointer;
        }
      }

      &-candidate.draft {

        div,
        span {
          color: $gray-border;
        }

        .avatars {
          img {
            opacity: 0.39;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.align-center {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.justify-end {
  justify-content: flex-end;
}

.trash-icon {
  color: $gray-medium;
}

.check-icon {
  color: $green;
}

.px-1 {
  padding-inline: 0.5rem;
}

.position-relative {
  position: relative;
}

.add-schedule {
  color: $gray-low;

  &:hover {
    color: $primary;
    font-weight: bold;
  }
}

.dropdown {
  position: absolute;
  z-index: 10;
  top: 100%;
  right: 50%;
  display: flex;
  min-width: max-content;
  flex-direction: column;
  padding: 0.75rem;
  border-radius: 6px;
  background-color: $white-full;
  box-shadow: 0 2px 4px #00000029;
  gap: 0.5rem;
}

.activities-tab {
  overflow: hidden;

  &__header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
    gap: 1rem;
  }

  &__schedule-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e9ecef;
    margin: 1rem 0;
    padding-block: 1rem;
    margin-block-end: 3.5rem;
  }

  &__form {
    width: 50%;
  }
}

@media screen and (max-width: 1024px) {
  .activities-tab {
    &__form {
      width: auto;
    }
  }
}

.subtitle {
  color: #666;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 700;
}

.slide-fade {
  &-enter-active {
    transition: all 400ms ease-out;
  }

  &-leave-active {
    transition: all 200ms cubic-bezier(1, 0.5, 0.3, 1);
  }

  &-enter-from,
  &-leave-to {
    opacity: 0;
    transform: translateY(-1.5rem);
  }
}

.base-tooltip .tooltip-message {
  text-align: center;
  font-size: 0.75rem;
  font-weight: bold;
  text-transform: uppercase;
}
.project-body .list-activities .list-activities-candidate .status {
  :deep(.base-tooltip) .tooltip .tooltip-message {
    color: $gray-lower;
  }
}
.v-enter-active,
.v-leave-active {
  transition: opacity 400ms ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
.search-container {
  display: flex;
  justify-content: flex-end;
  .search {
    max-width: 20rem;
    padding-block: 0.375rem;
  }
}
</style>
