<script setup lang="ts">
import IdealProfileTabs from "./components/IdealProfileTabs.vue";
import { addCompanyProfileLines } from "./utils/companyProfileSvgLines";
import { getCorporation } from "@/http_services/entities/corporation";
import type { Corporation } from "@/schemas/corporation";
import { SvgIcon } from "@/components/icon";

type CountryEnumKey = keyof typeof CountryEnum;

const props = defineProps<{ id: string }>();

enum CountryEnum {
  "BRL" = "pt-BR",
  "USD" = "en-US",
  "EUR" = "",
}

const AsyncCompanyProfileForm = defineAsyncComponent(() =>
  import("./components/forms/CompanyProfileForm.vue"));

const router = useRouter();
const { t } = useI18n();
const { data: project, error, isLoading: loading } = await useProject(props.id);

const corporation = ref<Corporation | null>(null);

const textSizeWidth = ref(500);
const editing = ref(false);

const {
  stageTabs,
} = useTabsProject(props.id);

const currentRoute = computed(() => stageTabs.value.findIndex(tab => tab.key === router.currentRoute.value.meta.tab));

const {
  data: organization,
} = useConstructionOrganizationQuery(props.id);

const employees = computed(() => {
  if (!corporation.value)
    return "";

  return t(`corporations.employees.enum.${corporation.value?.number_of_employees}`);
});

const currentRevenue = computed(() => {
  try {
    if (!corporation.value)
      return "";

    const revenues = (corporation.value?.revenues || []).sort((a, b) => b.year - a.year);

    const amount = revenues[0]?.amount || "0.0";
    const currency = revenues[0]?.currency || "BRL";

    const formattedAmount = new Intl.NumberFormat(CountryEnum[currency], {
      style: "currency",
      currency,
    }).format(Number.parseFloat(amount));

    return formattedAmount;
  }
  catch (_) {
    return "";
  }
});

const profileData = computed(() => ([
  {
    title: t("projects.details.activities.constructionPanel.analysisTypes.GOVERNANCE"),
    icon: "project_governance",
    items: (organization.value?.governance?.tags || [])
      .filter(tag => tag.selected)
      .map(tag => tag.text),
    key: "governance",
  },
  {
    title: t(
      "projects.details.activities.constructionPanel.analysisTypes.EXECUTION_CONDITIONS",
    ),
    icon: "project_conditions",
    items: (organization.value?.execution_conditions?.tags || [])
      .filter(tag => tag.selected)
      .map(tag => tag.text),
    key: "conditions",

  },
  {
    title: t("projects.details.activities.constructionPanel.analysisTypes.STRATEGY"),
    icon: "project_strategy",
    items: (organization.value?.strategy?.tags || [])
      .filter(tag => tag.selected)
      .map(tag => tag.text),
    key: "strategy",
  },
  {
    title: t("projects.details.constructionGuide.idealProfile.companySize"),
    icon: "project_company_size",
    items: [
      // TODO: add corporation data here
      `${t("corporations.details.currentRevenue")}: ${currentRevenue.value}`,
      `${t("corporations.details.employeesNumber")}: ${employees.value}`,
    ],
    key: "size",
  },
]));

function goTo(tabIndex: number) {
  if (!stageTabs.value || !stageTabs.value[tabIndex])
    return;

  router.push(stageTabs.value[tabIndex].path);
}

async function fetchCorporation() {
  if (project.value)
    corporation.value = await getCorporation(project.value.corporation.corporation_id);
}

watchEffect(async () => {
  await fetchCorporation();
});

const containerSvg = ref<HTMLElement | null>(null);
const governanceUnderline = ref<HTMLElement | null>(null);
const strategyUnderline = ref<HTMLElement | null>(null);
const strategyLineSvg = ref<HTMLElement | null>(null);
const governanceLineSvg = ref<HTMLElement | null>(null);
const executionConditionsLineSvg = ref<HTMLElement | null>(null);
const companySizeLineSvg = ref<HTMLElement | null>(null);
const executionConditionsUnderline = ref<HTMLElement | null>(null);
const companySizeUnderline = ref<HTMLElement | null>(null);

const debouncedFn = useDebounceFn(() => {
  addCompanyProfileLines({
    containerSvg,
    strategyUnderline,
    strategyLineSvg,
    governanceLineSvg,
    governanceUnderline,
    executionConditionsUnderline,
    companySizeUnderline,
    companySizeLineSvg,
    executionConditionsLineSvg,
  });
}, 50);
onMounted(() => debouncedFn());
</script>

<template>
  <section class="project-section" @vue:updated="debouncedFn()">
    <div v-if="error">
      could not load
    </div>
    <div class="project-container">
      <ProjectStages :id="props.id" />
      <div v-if="project" class="project-wrapper">
        <ProjectTabs :stage-tabs="stageTabs" :active="currentRoute" class="tabs" @change="goTo($event)" />
        <IdealProfileTabs :editing="editing" :project-id="project._id" :stages="project.stages" @edit="editing = true" />
        <div v-if="!editing" class="project-content">
          <div id="profile-container" class="profile-container">
            <div class="column-1">
              <div
                v-for="(row) in profileData.slice(0, 2)"
                :id="row.key"
                :key="row.title"
                class="content col1"
                :style="{ 'max-width': textSizeWidth }"
              >
                <div class="title">
                  <div class="icon">
                    <SvgIcon
                      :icon="row.icon"
                      margin="none"
                      color="#FFB600"
                      width="1.125rem"
                      height="1.125rem"
                    />
                  </div>
                  <div class="content-title">
                    {{ row.title }}
                  </div>
                </div>
                <ul>
                  <li v-for="item in row.items" :key="item" v-ellipsis="150">
                    <template v-if="item.length > 150">
                      <p class="clamp">
                        {{ item }}
                      </p>
                    </template>
                    <template v-else>
                      {{ item }}
                    </template>
                  </li>
                </ul>
              </div>
            </div>
            <div class="column-2">
              <div ref="containerSvg" class="icon">
                <SvgIcon
                  icon="project_company"
                  margin="none"
                  color="#FFB600"
                  width="5rem"
                  height="5rem"
                  class="svgicon"
                />
              </div>
            </div>
            <div class="column-3">
              <div
                v-for="(row) in profileData.slice(2)"
                :id="row.key"
                :key="row.title"
                class="content"
                :style="{ 'max-width': textSizeWidth }"
              >
                <div class="title">
                  <div class="icon">
                    <SvgIcon
                      :icon="row.icon"
                      margin="none"
                      color="#FFB600"
                      width="1.125rem"
                      height="1.125rem"
                    />
                  </div>
                  <div class="content-title">
                    {{ row.title }}
                  </div>
                </div>
                <ul>
                  <li v-for="item in row.items" :key="item" v-ellipsis="150">
                    <template v-if="item.length > 150">
                      <p class="clamp">
                        {{ item }}
                      </p>
                    </template>
                    <template v-else>
                      {{ item }}
                    </template>
                  </li>
                </ul>
              </div>
            </div>
            <hr ref="governanceUnderline" class="svg-connection governance">
            <hr ref="strategyUnderline" class="svg-connection governance">
            <hr ref="companySizeUnderline" class="svg-connection">
            <hr ref="executionConditionsUnderline" class="svg-connection">
            <svg id="svg">
              <line ref="strategyLineSvg" class="line" />
              <line ref="governanceLineSvg" class="line" />
              <line ref="executionConditionsLineSvg" class="line" />
              <line ref="companySizeLineSvg" class="line" />
            </svg>
          </div>
        </div>
        <div v-else class="project-content">
          <AsyncCompanyProfileForm
            :project="project"
            :corporation="corporation"
            @cancel="editing = false"
            @submit:corporation="fetchCorporation()"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<route lang="yaml">
meta:
  layout: project-stage
  stage: construction
  tab: ideal-profile
  ideal-profile: company-profile
</route>

<style scoped lang="scss">
#svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

}
.line {
  stroke-width: 1px;
  stroke: $gray-border;
  z-index: 1;
}

.svg-connection {
  position: absolute;
  width: 18.75rem;
  color: transparent;
  border: 0;
  height: 1px;
  background: $gray-border;
  &.governance {
    width: 22rem;
  }
}

.project-section {
  min-height: 100vh;
}
.project-container {
  padding-top: 3.75rem;
}
.project-wrapper {
  background: white;
}
.project-content {
  margin-inline: 2.5rem;
}
</style>

<style scoped lang="scss">
.profile-container {
  position: relative;
  display: grid;
  font-size: 0.875rem;
  grid-template-columns: repeat(3, 1fr);
  letter-spacing: 0.49px;
  line-height: 1.375rem;
  padding-bottom: 2.5rem;
  // margin-inline-start: 6.25rem;

  .container-svg {
    position: absolute;
    top: 0;
    display: block;

    > line {
      stroke: $gray-border;
      stroke-width: 1;
    }
  }

  .content-center {
    position: absolute;
    z-index: 2;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;

    .icon {
      display: flex;
      width: 9rem;
      height: 9rem;
      align-items: center;
      justify-content: center;
      border-radius: 9rem;
      background-color: $white-background;
      box-shadow: 0 2px 4px #00000029;
    }
  }

  .content {
    padding-bottom: 1.5rem;

    .title {
      position: relative;
      display: flex;
      align-items: center;
      font-size: 1.25rem;
      gap: 0.5rem;
      text-transform: uppercase;
      white-space: nowrap;

      .icon {
        display: flex;
        width: 2rem;
        height: 2rem;
        align-items: center;
        justify-content: center;
        border-radius: 2rem;
        background-color: $white-background;
        box-shadow: 0 2px 4px #00000029;
      }
    }

    ul {
      padding: 0.5rem;
      padding-inline-start: 2rem;
      padding-bottom: 0;
      line-height: 1.25rem;
      list-style: disc;

      li {
        color: $gray-lower;
        word-break: break-word;

        &::marker {
          color: $yellow;
          font-size: 1rem;
        }
      }
    }
  }
  .column-2 {
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      display: flex;
      width: 9rem;
      height: 9rem;
      align-items: center;
      justify-content: center;
      border-radius: 9rem;
      background-color: $white-background;
      box-shadow: 0 2px 4px #00000029;
    }
  }

  .column-1,
  .column-3 {
    display: grid;
    flex-direction: column;
    z-index: 2;
  }
}

.content-title {
  position: relative;
  width: fit-content;
  padding-bottom: 0.125rem;
}

.clamp {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  word-break: break-word;
}
.column-2 .icon {
  z-index: 2;
}
</style>

<style global>
.export .clamp {
  display: initial;
}
</style>
