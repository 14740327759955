<script setup lang="ts">
import type { PropType } from "vue";
import { ref } from "vue";
import { SvgIcon } from "@/components/icon";
import CustomCheckbox from "@/components/checkbox/CustomCheckBox.vue";
import type { DataGridHeader } from "@/components/datagrid/DataGrid.vue";

export interface IDatagridFilter {
  sort?: "asc" | "desc"
  column?: string
  search: string[]
  extra?: string
}

const props = defineProps({
  header: { type: Object as PropType<DataGridHeader>, required: true },
  items: { type: Array, default: () => ([]) },
  name: { type: String, required: true },
});

const emit = defineEmits(["clear", "change-criteria"]);
const i18n = useI18n();
const router = useRouter();

const query = ref("");
const filter = ref<IDatagridFilter>({
  search: [],
  sort: undefined,
  column: props.name,
  extra: undefined,
});

const filterItems = computed<any[]>(() => {
  if (props.header?.filterItems)
    return props.header.filterItems;

  if (props.items?.length) {
    return props.items
      .filter(item => !!(props.header?.value(item)) && (props.header?.filterFn ? props.header?.filterFn(null, item, true) : true))
      .map(item => ({
        value: String(props.header?.value(item)),
        label: String(props.header?.value(item)),
        item,
      }));
  }

  return [];
});

const filterItemsInSearch = computed<any>(() => {
  const uniqueItems = [];
  let optionItems = [];

  if (!query.value)
    optionItems = filterItems.value;

  if (query.value)
    optionItems = filterItems.value.filter(item => item.label.toLowerCase().includes(query.value.toLowerCase()));

  optionItems.forEach((item) => {
    const exist = uniqueItems.some(unique => item.value === unique.value);

    !exist && uniqueItems.push(item);
  });

  return uniqueItems;
});

function changeFiltersCriteria(option: any, state: boolean) {
  if (!state) {
    const index = filter.value.search.indexOf(option.value);

    filter.value.search.splice(index, 1);
  }

  state && filter.value.search.push(option.value);

  emit("change-criteria", { value: filter.value.search, column: filter.value.column });
}

function applyFilters(close?: () => void) {
  close?.();
  props.header.beforeApplyFilters?.(filter.value);
  router.push({ query: { ...filter.value } });
}

function clearFilters(close?: () => void) {
  emit("clear", filter.value.column);

  filter.value.sort = undefined;
  filter.value.extra = undefined;
  filter.value.search = [];
  query.value = String();

  close?.();

  router.push({ query: {} });
}

function checkAll(event: Event) {
  const { checked } = event.target as HTMLInputElement;

  if (props.header?.filterItems) {
    return filter.value.search = checked
      ? [...props.header?.filterItems.map(({ value }) => value)]
      : [];
  }

  return filter.value.search = checked
    ? props.items?.map(item => props.header?.value(item)) as string[]
    : [];
}
</script>

<template>
  <Popover>
    <template #trigger>
      <SvgIcon
        class="sort-row"
        rotate="90deg"
        icon="right_chevron_icon"
        width="1rem"
        height="1rem"
        margin="none"
      />
    </template>

    <template #content="{ close }">
      <div class="card">
        <div class="card-header">
          <span class="card-title" v-text="props.header?.name" />
        </div>

        <div class="card-body">
          <div class="sort">
            <label :class="{ active: filter.sort === 'asc' }" class="sort-btn" @click="filter.sort = 'asc'">
              <input type="radio" name="sort-direction">
              <span v-text="i18n.t('projects.validation.filters.ascending')" />
            </label>

            <label :class="{ active: filter.sort === 'desc' }" class="sort-btn" @click="filter.sort = 'desc'">
              <input type="radio" name="sort-direction">
              <span v-text="i18n.t('projects.validation.filters.descending')" />
            </label>
          </div>

          <br>

          <div>
            <div class="head">
              <slot name="head">
                <span v-text="i18n.t('projects.validation.filters.title')" />
              </slot>
            </div>

            <div class="card-filter">
              <div class="search-form">
                <SvgIcon icon="search_icon" width="18" height="18" />
                <input v-model="query" type="text">
              </div>

              <div class="filter-content">
                <CustomCheckbox
                  :label="i18n.t('projects.validation.filters.selectAll')"
                  :on-change="checkAll"
                  variation="warning"
                />
                <div class="slot">
                  <slot name="content">
                    <template v-for="option in filterItemsInSearch" :key="option.value">
                      <CustomCheckbox
                        :label="option.label"
                        :checked="!!filter.search.find(item => item.toString() === option.value)"
                        variation="warning"
                        @on-check="(state) => changeFiltersCriteria(option, state)"
                      >
                        <slot name="item" v-bind="option" />
                      </CustomCheckbox>
                    </template>
                  </slot>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card-footer">
          <button class="yellow" @click="applyFilters(close)">
            <span v-text="i18n.t('projects.validation.filters.applyFilter')" />
          </button>

          <button class="grey" @click="clearFilters(close)">
            <span v-text="i18n.t('projects.validation.filters.clearFilter')" />
          </button>
        </div>
      </div>
    </template>
  </Popover>
</template>

<style scoped lang="scss">
.card {
  min-width: 25.625rem;
  padding: 1rem;

  .card-header {
    display: flex;
    justify-content: space-between;

    .card-title {
      font-weight: 600;
      font-size: 1.25rem;
      color: $gray-dark;
    }
  }

  .card-body {
    padding: 1rem 0;

    .head {
      font-weight: bold;
    }

    .card-filter {
      border: 1px solid $white-background;
      border-radius: .375rem;
      margin-top: 1em;
      background: $white-full 0 0 no-repeat padding-box;
      box-shadow: 0 3px 10px #0000001A;

      .search-form {
        border-radius: .25rem;
        height: 2.375rem;
        display: flex;
        align-items: center;
        padding: 0 .5rem;

        &:hover {
          outline: 1px solid $gray-dark;
        }

        input {
          height: 100%;
          width: 100%;
          border: none;
          outline: unset;
        }
      }

      .filter-content {
        border-top: 1px solid #E8E8E8;
        padding: .5rem;
        display: flex;
        flex-direction: column;
        gap: .75rem;
        white-space: nowrap;
        width: 24rem;

        .slot {
          height: 15.625rem;
          overflow-y: auto;
          display: flex;
          flex-direction: column;
          gap: .75rem;
        }
      }
    }
  }

  .card-footer {
    display: flex;
    justify-content: center;
    gap: 1rem;

    button {
      border: unset;
      padding: 1rem;
      text-transform: uppercase;
      border-radius: .75rem;

      span {
        font-size: .875rem;
        color: white;
      }

      &.yellow {
        background: $yellow 0 0 no-repeat padding-box;
      }

      &.grey {
        background: $white-lotion 0 0 no-repeat padding-box;

        span {
          color: $gray-x11;
        }

        &:hover {
          background-color: $white-hover;
        }
      }
    }
  }
}

.sort-row {
  cursor: pointer;
  display: flex;
  align-self: center;
}

.sort {
  display: flex;

  label.sort-btn {
    user-select: none;
    margin-right: 2rem;
    padding: .6rem 1rem;
    text-transform: uppercase;
    border-radius: .5rem;
    background: $white-full 0 0 no-repeat padding-box;
    border: 1px solid $red-violet;
    cursor: pointer;

    span {
      color: $red-violet !important;
    }

    &.active {
      background-color: $red-violet;

      span {
        color: $white-full !important;
      }
    }

    input[type='radio'] {
      position: absolute;
      clip: rect(0, 0, 0, 0);
      pointer-events: none;
    }
  }
}
</style>
