import { z } from "zod";

enum ConclaEnum {
  A01_11__3 = "Cultivo de cereais",
  A01_12__1 = "Cultivo de algodão herbáceo e de outras fibras de lavoura temporária",
  A01_13__0 = "Cultivo de cana de açúcar",
  A01_14__8 = "Cultivo de fumo",
  A01_15__6 = "Cultivo de soja",
  A01_16__4 = "Cultivo de oleaginosas de lavoura temporária exceto soja",
  A01_19__9 = "Cultivo de plantas de lavoura temporária não especificadas anteriormente",
  A01_21__1 = "Horticultura",
  A01_22__9 = "Cultivo de flores e plantas ornamentais",
  A01_31__8 = "Cultivo de laranja",
  A01_32__6 = "Cultivo de uva",
  A01_33__4 = "Cultivo de frutas de lavoura permanente exceto laranja e uva",
  A01_34__2 = "Cultivo de café",
  A01_35__1 = "Cultivo de cacau",
  A01_39__3 = "Cultivo de plantas de lavoura permanente não especificadas anteriormente",
  A01_41__5 = "Produção de sementes certificadas",
  A01_42__3 = "Produção de mudas e outras formas de propagação vegetal certificadas",
  A01_51__2 = "Criação de bovinos",
  A01_52__1 = "Criação de outros animais de grande porte",
  A01_53__9 = "Criação de caprinos e ovinos",
  A01_54__7 = "Criação de suínos",
  A01_55__5 = "Criação de aves",
  A01_59__8 = "Criação de animais não especificados anteriormente",
  A01_61__0 = "Atividades de apoio à agricultura",
  A01_62__8 = "Atividades de apoio à pecuária",
  A01_63__6 = "Atividades de pós colheita",
  A01_70__9 = "Caça e serviços relacionados",
  A02_10__1 = "Produção florestal florestas plantadas",
  A02_20__9 = "Produção florestal florestas nativas",
  A02_30__6 = "Atividades de apoio à produção florestal",
  A03_11__6 = "Pesca em água salgada",
  A03_12__4 = "Pesca em água doce",
  A03_21__3 = "Aquicultura em água salgada e salobra",
  A03_22__1 = "Aquicultura em água doce",
  B05_00__3 = "Extração de carvão mineral",
  B06_00__0 = "Extração de petróleo e gás natural",
  B07_10__3 = "Extração de minério de ferro",
  B07_21__9 = "Extração de minério de alumínio",
  B07_22__7 = "Extração de minério de estanho",
  B07_23__5 = "Extração de minério de manganês",
  B07_24__3 = "Extração de minério de metais preciosos",
  B07_25__1 = "Extração de minerais radioativos",
  B07_29__4 = "Extração de minerais metálicos não ferrosos não especificados anteriormente",
  B08_10__0 = "Extração de pedra areia e argila",
  B08_91__6 = "Extração de minerais para fabricação de adubos fertilizantes e outros produtos químicos",
  B08_92__4 = "Extração e refino de sal marinho e sal gema",
  B08_93__2 = "Extração de gemas (pedras preciosas e semipreciosas)",
  B08_99__1 = "Extração de minerais não metálicos não especificados anteriormente",
  B09_10__6 = "Atividades de apoio à extração de petróleo e gás natural",
  B09_90__4 = "Atividades de apoio à extração de minerais exceto petróleo e gás natural",
  C10_11__2 = "Abate de reses exceto suínos",
  C10_12__1 = "Abate de suínos aves e outros pequenos animais",
  C10_13__9 = "Fabricação de produtos de carne",
  C10_20__1 = "Preservação do pescado e fabricação de produtos do pescado",
  C10_31__7 = "Fabricação de conservas de frutas",
  C10_32__5 = "Fabricação de conservas de legumes e outros vegetais",
  C10_33__3 = "Fabricação de sucos de frutas hortaliças e legumes",
  C10_41__4 = "Fabricação de óleos vegetais em bruto exceto óleo de milho",
  C10_42__2 = "Fabricação de óleos vegetais refinados exceto óleo de milho",
  C10_43__1 = "Fabricação de margarina e outras gorduras vegetais e de óleos não comestíveis de animais",
  C10_51__1 = "Preparação do leite",
  C10_52__0 = "Fabricação de laticínios",
  C10_53__8 = "Fabricação de sorvetes e outros gelados comestíveis",
  C10_61__9 = "Beneficiamento de arroz e fabricação de produtos do arroz",
  C10_62__7 = "Moagem de trigo e fabricação de derivados",
  C10_63__5 = "Fabricação de farinha de mandioca e derivados",
  C10_64__3 = "Fabricação de farinha de milho e derivados exceto óleos de milho",
  C10_65__1 = "Fabricação de amidos e féculas de vegetais e de óleos de milho",
  C10_66__0 = "Fabricação de alimentos para animais",
  C10_69__4 = "Moagem e fabricação de produtos de origem vegetal não especificados anteriormente",
  C10_71__6 = "Fabricação de açúcar em bruto",
  C10_72__4 = "Fabricação de açúcar refinado",
  C10_81__3 = "Torrefação e moagem de café",
  C10_82__1 = "Fabricação de produtos à base de café",
  C10_91__1 = "Fabricação de produtos de panificação",
  C10_92__9 = "Fabricação de biscoitos e bolachas",
  C10_93__7 = "Fabricação de produtos derivados do cacau de chocolates e confeitos",
  C10_94__5 = "Fabricação de massas alimentícias",
  C10_95__3 = "Fabricação de especiarias molhos temperos e condimentos",
  C10_96__1 = "Fabricação de alimentos e pratos prontos",
  C10_99__6 = "Fabricação de produtos alimentícios não especificados anteriormente",
  C11_11__9 = "Fabricação de aguardentes e outras bebidas destiladas",
  C11_12__7 = "Fabricação de vinho",
  C11_13__5 = "Fabricação de malte cervejas e chopes",
  C11_21__6 = "Fabricação de águas envasadas",
  C11_22__4 = "Fabricação de refrigerantes e de outras bebidas não alcoólicas",
  C12_10__7 = "Processamento industrial do fumo",
  C12_20__4 = "Fabricação de produtos do fumo",
  C13_11__1 = "Preparação e fiação de fibras de algodão",
  C13_12__0 = "Preparação e fiação de fibras têxteis naturais exceto algodão",
  C13_13__8 = "Fiação de fibras artificiais e sintéticas",
  C13_14__6 = "Fabricação de linhas para costurar e bordar",
  C13_21__9 = "Tecelagem de fios de algodão",
  C13_22__7 = "Tecelagem de fios de fibras têxteis naturais exceto algodão",
  C13_23__5 = "Tecelagem de fios de fibras artificiais e sintéticas",
  C13_30__8 = "Fabricação de tecidos de malha",
  C13_40__5 = "Acabamentos em fios tecidos e artefatos têxteis",
  C13_51__1 = "Fabricação de artefatos têxteis para uso doméstico",
  C13_52__9 = "Fabricação de artefatos de tapeçaria",
  C13_53__7 = "Fabricação de artefatos de cordoaria",
  C13_54__5 = "Fabricação de tecidos especiais inclusive artefatos",
  C13_59__6 = "Fabricação de outros produtos têxteis não especificados anteriormente",
  C14_11__8 = "Confecção de roupas íntimas",
  C14_12__6 = "Confecção de peças de vestuário exceto roupas íntimas",
  C14_13__4 = "Confecção de roupas profissionais",
  C14_14__2 = "Fabricação de acessórios do vestuário exceto para segurança e proteção",
  C14_21__5 = "Fabricação de meias",
  C14_22__3 = "Fabricação de artigos do vestuário produzidos em malharias e tricotagens exceto meias",
  C15_10__6 = "Curtimento e outras preparações de couro",
  C15_21__1 = "Fabricação de artigos para viagem bolsas e semelhantes de qualquer material",
  C15_29__7 = "Fabricação de artefatos de couro não especificados anteriormente",
  C15_31__9 = "Fabricação de calçados de couro",
  C15_32__7 = "Fabricação de tênis de qualquer material",
  C15_33__5 = "Fabricação de calçados de material sintético",
  C15_39__4 = "Fabricação de calçados de materiais não especificados anteriormente",
  C15_40__8 = "Fabricação de partes para calçados de qualquer material",
  C16_10__2 = "Desdobramento de madeira",
  C16_21__8 = "Fabricação de madeira laminada e de chapas de madeira compensada prensada e aglomerada",
  C16_22__6 = "Fabricação de estruturas de madeira e de artigos de carpintaria para construção",
  C16_23__4 = "Fabricação de artefatos de tanoaria e de embalagens de madeira",
  C16_29__3 = "Fabricação de artefatos de madeira palha cortiça vime e material trançado não especificados anteriormente exceto móveis",
  C17_10__9 = "Fabricação de celulose e outras pastas para a fabricação de papel",
  C17_21__4 = "Fabricação de papel",
  C17_22__2 = "Fabricação de cartolina e papel cartão",
  C17_31__1 = "Fabricação de embalagens de papel",
  C17_32__0 = "Fabricação de embalagens de cartolina e papel cartão",
  C17_33__8 = "Fabricação de chapas e de embalagens de papelão ondulado",
  C17_41__9 = "Fabricação de produtos de papel cartolina papel cartão e papelão ondulado para uso comercial e de escritório",
  C17_42__7 = "Fabricação de produtos de papel para usos doméstico e higiênico sanitário",
  C17_49__4 = "Fabricação de produtos de pastas celulósicas papel cartolina papel cartão e papelão ondulado não especificados anteriormente",
  C18_11__3 = "Impressão de jornais livros revistas e outras publicações periódicas",
  C18_12__1 = "Impressão de material de segurança",
  C18_13__0 = "Impressão de materiais para outros usos",
  C18_21__1 = "Serviços de pré impressão",
  C18_22__9 = "Serviços de acabamentos gráficos",
  C18_30__0 = "Reprodução de materiais gravados em qualquer suporte",
  C19_10__1 = "Coquerias",
  C19_21__7 = "Fabricação de produtos do refino de petróleo",
  C19_22__5 = "Fabricação de produtos derivados do petróleo exceto produtos do refino",
  C19_31__4 = "Fabricação de álcool",
  C19_32__2 = "Fabricação de biocombustíveis exceto álcool",
  C20_11__8 = "Fabricação de cloro e álcalis",
  C20_12__6 = "Fabricação de intermediários para fertilizantes",
  C20_13__4 = "Fabricação de adubos e fertilizantes",
  C20_14__2 = "Fabricação de gases industriais",
  C20_19__3 = "Fabricação de produtos químicos inorgânicos não especificados anteriormente",
  C20_21__5 = "Fabricação de produtos petroquímicos básicos",
  C20_22__3 = "Fabricação de intermediários para plastificantes resinas e fibras",
  C20_29__1 = "Fabricação de produtos químicos orgânicos não especificados anteriormente",
  C20_31__2 = "Fabricação de resinas termoplásticas",
  C20_32__1 = "Fabricação de resinas termofixas",
  C20_33__9 = "Fabricação de elastômeros",
  C20_40__1 = "Fabricação de fibras artificiais e sintéticas",
  C20_51__7 = "Fabricação de defensivos agrícolas",
  C20_52__5 = "Fabricação de desinfestantes domissanitários",
  C20_61__4 = "Fabricação de sabões e detergentes sintéticos",
  C20_62__2 = "Fabricação de produtos de limpeza e polimento",
  C20_63__1 = "Fabricação de cosméticos produtos de perfumaria e de higiene pessoal",
  C20_71__1 = "Fabricação de tintas vernizes esmaltes e lacas",
  C20_72__0 = "Fabricação de tintas de impressão",
  C20_73__8 = "Fabricação de impermeabilizantes solventes e produtos afins",
  C20_91__6 = "Fabricação de adesivos e selantes",
  C20_92__4 = "Fabricação de explosivos",
  C20_93__2 = "Fabricação de aditivos de uso industrial",
  C20_94__1 = "Fabricação de catalisadores",
  C20_99__1 = "Fabricação de produtos químicos não especificados anteriormente",
  C21_10__6 = "Fabricação de produtos farmoquímicos",
  C21_21__1 = "Fabricação de medicamentos para uso humano",
  C21_22__0 = "Fabricação de medicamentos para uso veterinário",
  C21_23__8 = "Fabricação de preparações farmacêuticas",
  C22_11__1 = "Fabricação de pneumáticos e de câmaras de ar",
  C22_12__9 = "Reforma de pneumáticos usados",
  C22_19__6 = "Fabricação de artefatos de borracha não especificados anteriormente",
  C22_21__8 = "Fabricação de laminados planos e tubulares de material plástico",
  C22_22__6 = "Fabricação de embalagens de material plástico",
  C22_23__4 = "Fabricação de tubos e acessórios de material plástico para uso na construção",
  C22_29__3 = "Fabricação de artefatos de material plástico não especificados anteriormente",
  C23_11__7 = "Fabricação de vidro plano e de segurança",
  C23_12__5 = "Fabricação de embalagens de vidro",
  C23_19__2 = "Fabricação de artigos de vidro",
  C23_20__6 = "Fabricação de cimento",
  C23_30__3 = "Fabricação de artefatos de concreto cimento fibrocimento gesso e materiais semelhantes",
  C23_41__9 = "Fabricação de produtos cerâmicos refratários",
  C23_42__7 = "Fabricação de produtos cerâmicos não refratários para uso estrutural na construção",
  C23_49__4 = "Fabricação de produtos cerâmicos não refratários não especificados anteriormente",
  C23_91__5 = "Aparelhamento e outros trabalhos em pedras",
  C23_92__3 = "Fabricação de cal e gesso",
  C23_99__1 = "Fabricação de produtos de minerais não metálicos não especificados anteriormente",
  C24_11__3 = "Produção de ferro gusa",
  C24_12__1 = "Produção de ferroligas",
  C24_21__1 = "Produção de semiacabados de aço",
  C24_22__9 = "Produção de laminados planos de aço",
  C24_23__7 = "Produção de laminados longos de aço",
  C24_24__5 = "Produção de relaminados trefilados e perfilados de aço",
  C24_31__8 = "Produção de tubos de aço com costura",
  C24_39__3 = "Produção de outros tubos de ferro e aço",
  C24_41__5 = "Metalurgia do alumínio e suas ligas",
  C24_42__3 = "Metalurgia dos metais preciosos",
  C24_43__1 = "Metalurgia do cobre",
  C24_49__1 = "Metalurgia dos metais não ferrosos e suas ligas não especificados anteriormente",
  C24_51__2 = "Fundição de ferro e aço",
  C24_52__1 = "Fundição de metais não ferrosos e suas ligas",
  C25_11__0 = "Fabricação de estruturas metálicas",
  C25_12__8 = "Fabricação de esquadrias de metal",
  C25_13__6 = "Fabricação de obras de caldeiraria pesada",
  C25_21__7 = "Fabricação de tanques reservatórios metálicos e caldeiras para aquecimento central",
  C25_22__5 = "Fabricação de caldeiras geradoras de vapor exceto para aquecimento central e para veículos",
  C25_31__4 = "Produção de forjados de aço e de metais não ferrosos e suas ligas",
  C25_32__2 = "Produção de artefatos estampados de metal; metalurgia do pó",
  C25_39__0 = "Serviços de usinagem solda tratamento e revestimento em metais",
  C25_41__1 = "Fabricação de artigos de cutelaria",
  C25_42__0 = "Fabricação de artigos de serralheria exceto esquadrias",
  C25_43__8 = "Fabricação de ferramentas",
  C25_50__1 = "Fabricação de equipamento bélico pesado armas e munições",
  C25_91__8 = "Fabricação de embalagens metálicas",
  C25_92__6 = "Fabricação de produtos de trefilados de metal",
  C25_93__4 = "Fabricação de artigos de metal para uso doméstico e pessoal",
  C25_99__3 = "Fabricação de produtos de metal não especificados anteriormente",
  C26_10__8 = "Fabricação de componentes eletrônicos",
  C26_21__3 = "Fabricação de equipamentos de informática",
  C26_22__1 = "Fabricação de periféricos para equipamentos de informática",
  C26_31__1 = "Fabricação de equipamentos transmissores de comunicação",
  C26_32__9 = "Fabricação de aparelhos telefônicos e de outros equipamentos de comunicação",
  C26_40__0 = "Fabricação de aparelhos de recepção reprodução gravação e amplificação de áudio e vídeo",
  C26_51__5 = "Fabricação de aparelhos e equipamentos de medida teste e controle",
  C26_52__3 = "Fabricação de cronômetros e relógios",
  C26_60__4 = "Fabricação de aparelhos eletromédicos e eletroterapêuticos e equipamentos de irradiação",
  C26_70__1 = "Fabricação de equipamentos e instrumentos ópticos fotográficos e cinematográficos",
  C26_80__9 = "Fabricação de mídias virgens magnéticas e ópticas",
  C27_10__4 = "Fabricação de geradores transformadores e motores elétricos",
  C27_21__0 = "Fabricação de pilhas baterias e acumuladores elétricos exceto para veículos automotores",
  C27_22__8 = "Fabricação de baterias e acumuladores para veículos automotores",
  C27_31__7 = "Fabricação de aparelhos e equipamentos para distribuição e controle de energia elétrica",
  C27_32__5 = "Fabricação de material elétrico para instalações em circuito de consumo",
  C27_33__3 = "Fabricação de fios cabos e condutores elétricos isolados",
  C27_40__6 = "Fabricação de lâmpadas e outros equipamentos de iluminação",
  C27_51__1 = "Fabricação de fogões refrigeradores e máquinas de lavar e secar para uso doméstico",
  C27_59__7 = "Fabricação de aparelhos eletrodomésticos não especificados anteriormente",
  C27_90__2 = "Fabricação de equipamentos e aparelhos elétricos não especificados anteriormente",
  C28_11__9 = "Fabricação de motores e turbinas exceto para aviões e veículos rodoviários",
  C28_12__7 = "Fabricação de equipamentos hidráulicos e pneumáticos exceto válvulas",
  C28_13__5 = "Fabricação de válvulas registros e dispositivos semelhantes",
  C28_14__3 = "Fabricação de compressores",
  C28_15__1 = "Fabricação de equipamentos de transmissão para fins industriais",
  C28_21__6 = "Fabricação de aparelhos e equipamentos para instalações térmicas",
  C28_22__4 = "Fabricação de máquinas equipamentos e aparelhos para transporte e elevação de cargas e pessoas",
  C28_23__2 = "Fabricação de máquinas e aparelhos de refrigeração e ventilação para uso industrial e comercial",
  C28_24__1 = "Fabricação de aparelhos e equipamentos de ar condicionado",
  C28_25__9 = "Fabricação de máquinas e equipamentos para saneamento básico e ambiental",
  C28_29__1 = "Fabricação de máquinas e equipamentos de uso geral não especificados anteriormente",
  C28_31__3 = "Fabricação de tratores agrícolas",
  C28_32__1 = "Fabricação de equipamentos para irrigação agrícola",
  C28_33__0 = "Fabricação de máquinas e equipamentos para a agricultura e pecuária exceto para irrigação",
  C28_40__2 = "Fabricação de máquinas ferramenta",
  C28_51__8 = "Fabricação de máquinas e equipamentos para a prospecção e extração de petróleo",
  C28_52__6 = "Fabricação de outras máquinas e equipamentos para uso na extração mineral exceto na extração de petróleo",
  C28_53__4 = "Fabricação de tratores exceto agrícolas",
  C28_54__2 = "Fabricação de máquinas e equipamentos para terraplenagem pavimentação e construção exceto tratores",
  C28_61__5 = "Fabricação de máquinas para a indústria metalúrgica exceto máquinas ferramenta",
  C28_62__3 = "Fabricação de máquinas e equipamentos para as indústrias de alimentos bebidas e fumo",
  C28_63__1 = "Fabricação de máquinas e equipamentos para a indústria têxtil",
  C28_64__0 = "Fabricação de máquinas e equipamentos para as indústrias do vestuário do couro e de calçados",
  C28_65__8 = "Fabricação de máquinas e equipamentos para as indústrias de celulose papel e papelão e artefatos",
  C28_66__6 = "Fabricação de máquinas e equipamentos para a indústria do plástico",
  C28_69__1 = "Fabricação de máquinas e equipamentos para uso industrial específico não especificados anteriormente",
  C29_10__7 = "Fabricação de automóveis camionetas e utilitários",
  C29_20__4 = "Fabricação de caminhões e ônibus",
  C29_30__1 = "Fabricação de cabines carrocerias e reboques para veículos automotores",
  C29_41__7 = "Fabricação de peças e acessórios para o sistema motor de veículos automotores",
  C29_42__5 = "Fabricação de peças e acessórios para os sistemas de marcha e transmissão de veículos automotores",
  C29_43__3 = "Fabricação de peças e acessórios para o sistema de freios de veículos automotores",
  C29_44__1 = "Fabricação de peças e acessórios para o sistema de direção e suspensão de veículos automotores",
  C29_45__0 = "Fabricação de material elétrico e eletrônico para veículos automotores exceto baterias",
  C29_49__2 = "Fabricação de peças e acessórios para veículos automotores não especificados anteriormente",
  C29_50__6 = "Recondicionamento e recuperação de motores para veículos automotores",
  C30_11__3 = "Construção de embarcações e estruturas flutuantes",
  C30_12__1 = "Construção de embarcações para esporte e lazer",
  C30_31__8 = "Fabricação de locomotivas vagões e outros materiais rodantes",
  C30_32__6 = "Fabricação de peças e acessórios para veículos ferroviários",
  C30_41__5 = "Fabricação de aeronaves",
  C30_42__3 = "Fabricação de turbinas motores e outros componentes e peças para aeronaves",
  C30_50__4 = "Fabricação de veículos militares de combate",
  C30_91__1 = "Fabricação de motocicletas",
  C30_92__0 = "Fabricação de bicicletas e triciclos não motorizados",
  C30_99__7 = "Fabricação de equipamentos de transporte não especificados anteriormente",
  C31_01__2 = "Fabricação de móveis com predominância de madeira",
  C31_02__1 = "Fabricação de móveis com predominância de metal",
  C31_03__9 = "Fabricação de móveis de outros materiais exceto madeira e metal",
  C31_04__7 = "Fabricação de colchões",
  C32_11__6 = "Lapidação de gemas e fabricação de artefatos de ourivesaria e joalheria",
  C32_12__4 = "Fabricação de bijuterias e artefatos semelhantes",
  C32_20__5 = "Fabricação de instrumentos musicais",
  C32_30__2 = "Fabricação de artefatos para pesca e esporte",
  C32_40__0 = "Fabricação de brinquedos e jogos recreativos",
  C32_50__7 = "Fabricação de instrumentos e materiais para uso médico e odontológico e de artigos ópticos",
  C32_91__4 = "Fabricação de escovas pincéis e vassouras",
  C32_92__2 = "Fabricação de equipamentos e acessórios para segurança e proteção pessoal e profissional",
  C32_99__0 = "Fabricação de produtos diversos não especificados anteriormente",
  C33_11__2 = "Manutenção e reparação de tanques reservatórios metálicos e caldeiras exceto para veículos",
  C33_12__1 = "Manutenção e reparação de equipamentos eletrônicos e ópticos",
  C33_13__9 = "Manutenção e reparação de máquinas e equipamentos elétricos",
  C33_14__7 = "Manutenção e reparação de máquinas e equipamentos da indústria mecânica",
  C33_15__5 = "Manutenção e reparação de veículos ferroviários",
  C33_16__3 = "Manutenção e reparação de aeronaves",
  C33_17__1 = "Manutenção e reparação de embarcações",
  C33_19__8 = "Manutenção e reparação de equipamentos e produtos não especificados anteriormente",
  C33_21__0 = "Instalação de máquinas e equipamentos industriais",
  C33_29__5 = "Instalação de equipamentos não especificados anteriormente",
  D35_11__5 = "Geração de energia elétrica",
  D35_12__3 = "Transmissão de energia elétrica",
  D35_13__1 = "Comércio atacadista de energia elétrica",
  D35_14__0 = "Distribuição de energia elétrica",
  D35_20__4 = "Produção de gás; processamento de gás natural; distribuição de combustíveis gasosos por redes urbanas",
  D35_30__1 = "Produção e distribuição de vapor água quente e ar condicionado",
  E36_00__6 = "Captação tratamento e distribuição de água",
  E37_01__1 = "Gestão de redes de esgoto",
  E37_02__9 = "Atividades relacionadas a esgoto exceto a gestão de redes",
  E38_11__4 = "Coleta de resíduos não perigosos",
  E38_12__2 = "Coleta de resíduos perigosos",
  E38_21__1 = "Tratamento e disposição de resíduos não perigosos",
  E38_22__0 = "Tratamento e disposição de resíduos perigosos",
  E38_31__9 = "Recuperação de materiais metálicos",
  E38_32__7 = "Recuperação de materiais plásticos",
  E38_39__4 = "Recuperação de materiais não especificados anteriormente",
  E39_00__5 = "Descontaminação e outros serviços de gestão de resíduos",
  F41_10__7 = "Incorporação de empreendimentos imobiliários",
  F41_20__4 = "Construção de edifícios",
  F42_11__1 = "Construção de rodovias e ferrovias",
  F42_12__0 = "Construção de obras de arte especiais",
  F42_13__8 = "Obras de urbanização ruas praças e calçadas",
  F42_21__9 = "Obras para geração e distribuição de energia elétrica e para telecomunicações",
  F42_22__7 = "Construção de redes de abastecimento de água coleta de esgoto e construções correlatas",
  F42_23__5 = "Construção de redes de transportes por dutos exceto para água e esgoto",
  F42_91__0 = "Obras portuárias marítimas e fluviais",
  F42_92__8 = "Montagem de instalações industriais e de estruturas metálicas",
  F42_99__5 = "Obras de engenharia civil não especificadas anteriormente",
  F43_11__8 = "Demolição e preparação de canteiros de obras",
  F43_12__6 = "Perfurações e sondagens",
  F43_13__4 = "Obras de terraplenagem",
  F43_19__3 = "Serviços de preparação do terreno não especificados anteriormente",
  F43_21__5 = "Instalações elétricas",
  F43_22__3 = "Instalações hidráulicas de sistemas de ventilação e refrigeração",
  F43_29__1 = "Obras de instalações em construções não especificadas anteriormente",
  F43_30__4 = "Obras de acabamento",
  F43_91__6 = "Obras de fundações",
  F43_99__1 = "Serviços especializados para construção não especificados anteriormente",
  G45_11__1 = "Comércio a varejo e por atacado de veículos automotores",
  G45_12__9 = "Representantes comerciais e agentes do comércio de veículos automotores",
  G45_20__0 = "Manutenção e reparação de veículos automotores",
  G45_30__7 = "Comércio de peças e acessórios para veículos automotores",
  G45_41__2 = "Comércio por atacado e a varejo de motocicletas peças e acessórios",
  G45_42__1 = "Representantes comerciais e agentes do comércio de motocicletas peças e acessórios",
  G45_43__9 = "Manutenção e reparação de motocicletas",
  G46_11__7 = "Representantes comerciais e agentes do comércio de matérias primas agrícolas e animais vivos",
  G46_12__5 = "Representantes comerciais e agentes do comércio de combustíveis minerais produtos siderúrgicos e químicos",
  G46_13__3 = "Representantes comerciais e agentes do comércio de madeira material de construção e ferragens",
  G46_14__1 = "Representantes comerciais e agentes do comércio de máquinas equipamentos embarcações e aeronaves",
  G46_15__0 = "Representantes comerciais e agentes do comércio de eletrodomésticos móveis e artigos de uso doméstico",
  G46_16__8 = "Representantes comerciais e agentes do comércio de têxteis vestuário calçados e artigos de viagem",
  G46_17__6 = "Representantes comerciais e agentes do comércio de produtos alimentícios bebidas e fumo",
  G46_18__4 = "Representantes comerciais e agentes do comércio especializado em produtos não especificados anteriormente",
  G46_19__2 = "Representantes comerciais e agentes do comércio de mercadorias em geral não especializado",
  G46_21__4 = "Comércio atacadista de café em grão",
  G46_22__2 = "Comércio atacadista de soja",
  G46_23__1 = "Comércio atacadista de animais vivos alimentos para animais e matérias primas agrícolas exceto café e soja",
  G46_31__1 = "Comércio atacadista de leite e laticínios",
  G46_32__0 = "Comércio atacadista de cereais e leguminosas beneficiados farinhas amidos e féculas",
  G46_33__8 = "Comércio atacadista de hortifrutigranjeiros",
  G46_34__6 = "Comércio atacadista de carnes produtos da carne e pescado",
  G46_35__4 = "Comércio atacadista de bebidas",
  G46_36__2 = "Comércio atacadista de produtos do fumo",
  G46_37__1 = "Comércio atacadista especializado em produtos alimentícios não especificados anteriormente",
  G46_39__7 = "Comércio atacadista de produtos alimentícios em geral",
  G46_41__9 = "Comércio atacadista de tecidos artefatos de tecidos e de armarinho",
  G46_42__7 = "Comércio atacadista de artigos do vestuário e acessórios",
  G46_43__5 = "Comércio atacadista de calçados e artigos de viagem",
  G46_44__3 = "Comércio atacadista de produtos farmacêuticos para uso humano e veterinário",
  G46_45__1 = "Comércio atacadista de instrumentos e materiais para uso médico cirúrgico ortopédico e odontológico",
  G46_46__0 = "Comércio atacadista de cosméticos produtos de perfumaria e de higiene pessoal",
  G46_47__8 = "Comércio atacadista de artigos de escritório e de papelaria; livros jornais e outras publicações",
  G46_49__4 = "Comércio atacadista de equipamentos e artigos de uso pessoal e doméstico não especificados anteriormente",
  G46_51__6 = "Comércio atacadista de computadores periféricos e suprimentos de informática",
  G46_52__4 = "Comércio atacadista de componentes eletrônicos e equipamentos de telefonia e comunicação",
  G46_61__3 = "Comércio atacadista de máquinas aparelhos e equipamentos para uso agropecuário; partes e peças",
  G46_62__1 = "Comércio atacadista de máquinas equipamentos para terraplenagem mineração e construção; partes e peças",
  G46_63__0 = "Comércio atacadista de máquinas e equipamentos para uso industrial; partes e peças",
  G46_64__8 = "Comércio atacadista de máquinas aparelhos e equipamentos para uso odonto médico hospitalar; partes e peças",
  G46_65__6 = "Comércio atacadista de máquinas e equipamentos para uso comercial; partes e peças",
  G46_69__9 = "Comércio atacadista de máquinas aparelhos e equipamentos não especificados anteriormente; partes e peças",
  G46_71__1 = "Comércio atacadista de madeira e produtos derivados",
  G46_72__9 = "Comércio atacadista de ferragens e ferramentas",
  G46_73__7 = "Comércio atacadista de material elétrico",
  G46_74__5 = "Comércio atacadista de cimento",
  G46_79__6 = "Comércio atacadista especializado de materiais de construção não especificados anteriormente e de materiais de construção em geral",
  G46_81__8 = "Comércio atacadista de combustíveis sólidos líquidos e gasosos exceto gás natural e glp",
  G46_82__6 = "Comércio atacadista de gás liquefeito de petróleo (glp)",
  G46_83__4 = "Comércio atacadista de defensivos agrícolas adubos fertilizantes e corretivos do solo",
  G46_84__2 = "Comércio atacadista de produtos químicos e petroquímicos exceto agroquímicos",
  G46_85__1 = "Comércio atacadista de produtos siderúrgicos e metalúrgicos exceto para construção",
  G46_86__9 = "Comércio atacadista de papel e papelão em bruto e de embalagens",
  G46_87__7 = "Comércio atacadista de resíduos e sucatas",
  G46_89__3 = "Comércio atacadista especializado de outros produtos intermediários não especificados anteriormente",
  G46_91__5 = "Comércio atacadista de mercadorias em geral com predominância de produtos alimentícios",
  G46_92__3 = "Comércio atacadista de mercadorias em geral com predominância de insumos agropecuários",
  G46_93__1 = "Comércio atacadista de mercadorias em geral sem predominância de alimentos ou de insumos agropecuários",
  G47_11__3 = "Comércio varejista de mercadorias em geral com predominância de produtos alimentícios hipermercados e supermercados",
  G47_12__1 = "Comércio varejista de mercadorias em geral com predominância de produtos alimentícios minimercados mercearias e armazéns",
  G47_13__0 = "Comércio varejista de mercadorias em geral sem predominância de produtos alimentícios",
  G47_21__1 = "Comércio varejista de produtos de padaria laticínio doces balas e semelhantes",
  G47_22__9 = "Comércio varejista de carnes e pescados açougues e peixarias",
  G47_23__7 = "Comércio varejista de bebidas",
  G47_24__5 = "Comércio varejista de hortifrutigranjeiros",
  G47_29__6 = "Comércio varejista de produtos alimentícios em geral ou especializado em produtos alimentícios não especificados anteriormente; produtos do fumo",
  G47_31__8 = "Comércio varejista de combustíveis para veículos automotores",
  G47_32__6 = "Comércio varejista de lubrificantes",
  G47_41__5 = "Comércio varejista de tintas e materiais para pintura",
  G47_42__3 = "Comércio varejista de material elétrico",
  G47_43__1 = "Comércio varejista de vidros",
  G47_44__0 = "Comércio varejista de ferragens madeira e materiais de construção",
  G47_51__2 = "Comércio varejista especializado de equipamentos e suprimentos de informática",
  G47_52__1 = "Comércio varejista especializado de equipamentos de telefonia e comunicação",
  G47_53__9 = "Comércio varejista especializado de eletrodomésticos e equipamentos de áudio e vídeo",
  G47_54__7 = "Comércio varejista especializado de móveis colchoaria e artigos de iluminação",
  G47_55__5 = "Comércio varejista especializado de tecidos e artigos de cama mesa e banho",
  G47_56__3 = "Comércio varejista especializado de instrumentos musicais e acessórios",
  G47_57__1 = "Comércio varejista especializado de peças e acessórios para aparelhos eletroeletrônicos para uso doméstico exceto informática e comunicação",
  G47_59__8 = "Comércio varejista de artigos de uso doméstico não especificados anteriormente",
  G47_61__0 = "Comércio varejista de livros jornais revistas e papelaria",
  G47_62__8 = "Comércio varejista de discos cds dvds e fitas",
  G47_63__6 = "Comércio varejista de artigos recreativos e esportivos",
  G47_71__7 = "Comércio varejista de produtos farmacêuticos para uso humano e veterinário",
  G47_72__5 = "Comércio varejista de cosméticos produtos de perfumaria e de higiene pessoal",
  G47_73__3 = "Comércio varejista de artigos médicos e ortopédicos",
  G47_74__1 = "Comércio varejista de artigos de óptica",
  G47_81__4 = "Comércio varejista de artigos do vestuário e acessórios",
  G47_82__2 = "Comércio varejista de calçados e artigos de viagem",
  G47_83__1 = "Comércio varejista de joias e relógios",
  G47_84__9 = "Comércio varejista de gás liquefeito de petróleo (glp)",
  G47_85__7 = "Comércio varejista de artigos usados",
  G47_89__0 = "Comércio varejista de outros produtos novos não especificados anteriormente",
  G47_90__3 = "Comércio ambulante e outros tipos de comércio varejista",
  H49_11__6 = "Transporte ferroviário de carga",
  H49_12__4 = "Transporte metroferroviário de passageiros",
  H49_21__3 = "Transporte rodoviário coletivo de passageiros com itinerário fixo municipal e em região metropolitana",
  H49_22__1 = "Transporte rodoviário coletivo de passageiros com itinerário fixo intermunicipal interestadual e internacional",
  H49_23__0 = "Transporte rodoviário de táxi",
  H49_24__8 = "Transporte escolar",
  H49_29__9 = "Transporte rodoviário coletivo de passageiros sob regime de fretamento e outros transportes rodoviários não especificados anteriormente",
  H49_30__2 = "Transporte rodoviário de carga",
  H49_40__0 = "Transporte dutoviário",
  H49_50__7 = "Trens turísticos teleféricos e similares",
  H50_11__4 = "Transporte marítimo de cabotagem",
  H50_12__2 = "Transporte marítimo de longo curso",
  H50_21__1 = "Transporte por navegação interior de carga",
  H50_22__0 = "Transporte por navegação interior de passageiros em linhas regulares",
  H50_30__1 = "Navegação de apoio",
  H50_91__2 = "Transporte por navegação de travessia",
  H50_99__8 = "Transportes aquaviários não especificados anteriormente",
  H51_11__1 = "Transporte aéreo de passageiros regular",
  H51_12__9 = "Transporte aéreo de passageiros não regular",
  H51_20__0 = "Transporte aéreo de carga",
  H51_30__7 = "Transporte espacial",
  H52_11__7 = "Armazenamento",
  H52_12__5 = "Carga e descarga",
  H52_21__4 = "Concessionárias de rodovias pontes túneis e serviços relacionados",
  H52_22__2 = "Terminais rodoviários e ferroviários",
  H52_23__1 = "Estacionamento de veículos",
  H52_29__0 = "Atividades auxiliares dos transportes terrestres não especificadas anteriormente",
  H52_31__1 = "Gestão de portos e terminais",
  H52_32__0 = "Atividades de agenciamento marítimo",
  H52_39__7 = "Atividades auxiliares dos transportes aquaviários não especificadas anteriormente",
  H52_40__1 = "Atividades auxiliares dos transportes aéreos",
  H52_50__8 = "Atividades relacionadas à organização do transporte de carga",
  H53_10__5 = "Atividades de correio",
  H53_20__2 = "Atividades de malote e de entrega",
  I55_10__8 = "Hotéis e similares",
  I55_90__6 = "Outros tipos de alojamento não especificados anteriormente",
  I56_11__2 = "Restaurantes e outros estabelecimentos de serviços de alimentação e bebidas",
  I56_12__1 = "Serviços ambulantes de alimentação",
  I56_20__1 = "Serviços de catering bufê e outros serviços de comida preparada",
  J58_11__5 = "Edição de livros",
  J58_12__3 = "Edição de jornais",
  J58_13__1 = "Edição de revistas",
  J58_19__1 = "Edição de cadastros listas e de outros produtos gráficos",
  J58_21__2 = "Edição integrada à impressão de livros",
  J58_22__1 = "Edição integrada à impressão de jornais",
  J58_23__9 = "Edição integrada à impressão de revistas",
  J58_29__8 = "Edição integrada à impressão de cadastros listas e de outros produtos gráficos",
  J59_11__1 = "Atividades de produção cinematográfica de vídeos e de programas de televisão",
  J59_12__0 = "Atividades de pós produção cinematográfica de vídeos e de programas de televisão",
  J59_13__8 = "Distribuição cinematográfica de vídeo e de programas de televisão",
  J59_14__6 = "Atividades de exibição cinematográfica",
  J59_20__1 = "Atividades de gravação de som e de edição de música",
  J60_10__1 = "Atividades de rádio",
  J60_21__7 = "Atividades de televisão aberta",
  J60_22__5 = "Programadoras e atividades relacionadas à televisão por assinatura",
  J61_10__8 = "Telecomunicações por fio",
  J61_20__5 = "Telecomunicações sem fio",
  J61_30__2 = "Telecomunicações por satélite",
  J61_41__8 = "Operadoras de televisão por assinatura por cabo",
  J61_42__6 = "Operadoras de televisão por assinatura por micro ondas",
  J61_43__4 = "Operadoras de televisão por assinatura por satélite",
  J61_90__6 = "Outras atividades de telecomunicações",
  J62_01__5 = "Desenvolvimento de programas de computador sob encomenda",
  J62_02__3 = "Desenvolvimento e licenciamento de programas de computador customizáveis",
  J62_03__1 = "Desenvolvimento e licenciamento de programas de computador não customizáveis",
  J62_04__0 = "Consultoria em tecnologia da informação",
  J62_09__1 = "Suporte técnico manutenção e outros serviços em tecnologia da informação",
  J63_11__9 = "Tratamento de dados provedores de serviços de aplicação e serviços de hospedagem na internet",
  J63_19__4 = "Portais provedores de conteúdo e outros serviços de informação na internet",
  J63_91__7 = "Agências de notícias",
  J63_99__2 = "Outras atividades de prestação de serviços de informação não especificadas anteriormente",
  K64_10__7 = "Banco central",
  K64_21__2 = "Bancos comerciais",
  K64_22__1 = "Bancos múltiplos com carteira comercial",
  K64_23__9 = "Caixas econômicas",
  K64_24__7 = "Crédito cooperativo",
  K64_31__0 = "Bancos múltiplos sem carteira comercial",
  K64_32__8 = "Bancos de investimento",
  K64_33__6 = "Bancos de desenvolvimento",
  K64_34__4 = "Agências de fomento",
  K64_35__2 = "Crédito imobiliário",
  K64_36__1 = "Sociedades de crédito financiamento e investimento financeiras",
  K64_37__9 = "Sociedades de crédito ao microempreendedor",
  K64_38__7 = "Bancos de cambio e outras instituições de intermediação não monetária",
  K64_40__9 = "Arrendamento mercantil",
  K64_50__6 = "Sociedades de capitalização",
  K64_61__1 = "Holdings de instituições financeiras",
  K64_62__0 = "Holdings de instituições não financeiras",
  K64_63__8 = "Outras sociedades de participação exceto holdings",
  K64_70__1 = "Fundos de investimento",
  K64_91__3 = "Sociedades de fomento mercantil factoring",
  K64_92__1 = "Securitização de créditos",
  K64_93__0 = "Administração de consórcios para aquisição de bens e direitos",
  K64_99__9 = "Outras atividades de serviços financeiros não especificadas anteriormente",
  K65_11__1 = "Seguros de vida",
  K65_12__0 = "Seguros não vida",
  K65_20__1 = "Seguros saúde",
  K65_30__8 = "Resseguros",
  K65_41__3 = "Previdência complementar fechada",
  K65_42__1 = "Previdência complementar aberta",
  K65_50__2 = "Planos de saúde",
  K66_11__8 = "Administração de bolsas e mercados de balcão organizados",
  K66_12__6 = "Atividades de intermediários em transações de títulos valores mobiliários e mercadorias",
  K66_13__4 = "Administração de cartões de crédito",
  K66_19__3 = "Atividades auxiliares dos serviços financeiros não especificadas anteriormente",
  K66_21__5 = "Avaliação de riscos e perdas",
  K66_22__3 = "Corretores e agentes de seguros de planos de previdência complementar e de saúde",
  K66_29__1 = "Atividades auxiliares dos seguros da previdência complementar e dos planos de saúde não especificadas anteriormente",
  K66_30__4 = "Atividades de administração de fundos por contrato ou comissão",
  L68_10__2 = "Atividades imobiliárias de imóveis próprios",
  L68_21__8 = "Intermediação na compra venda e aluguel de imóveis",
  L68_22__6 = "Gestão e administração da propriedade imobiliária",
  M69_11__7 = "Atividades jurídicas exceto cartórios",
  M69_12__5 = "Cartórios",
  M69_20__6 = "Atividades de contabilidade consultoria e auditoria contábil e tributária",
  M70_10__7 = "Sedes de empresas e unidades administrativas locais",
  M70_20__4 = "Atividades de consultoria em gestão empresarial",
  M71_11__1 = "Serviços de arquitetura",
  M71_12__0 = "Serviços de engenharia",
  M71_19__7 = "Atividades técnicas relacionadas à arquitetura e engenharia",
  M71_20__1 = "Testes e análises técnicas",
  M72_10__0 = "Pesquisa e desenvolvimento experimental em ciências físicas e naturais",
  M72_20__7 = "Pesquisa e desenvolvimento experimental em ciências sociais e humanas",
  M73_11__4 = "Agências de publicidade",
  M73_12__2 = "Agenciamento de espaços para publicidade exceto em veículos de comunicação",
  M73_19__0 = "Atividades de publicidade não especificadas anteriormente",
  M73_20__3 = "Pesquisas de mercado e de opinião pública",
  M74_10__2 = "Design e decoração de interiores",
  M74_20__0 = "Atividades fotográficas e similares",
  M74_90__1 = "Atividades profissionais científicas e técnicas não especificadas anteriormente",
  M75_00__1 = "Atividades veterinárias",
  N77_11__0 = "Locação de automóveis sem condutor",
  N77_19__5 = "Locação de meios de transporte exceto automóveis sem condutor",
  N77_21__7 = "Aluguel de equipamentos recreativos e esportivos",
  N77_22__5 = "Aluguel de fitas de vídeo dvds e similares",
  N77_23__3 = "Aluguel de objetos do vestuário joias e acessórios",
  N77_29__2 = "Aluguel de objetos pessoais e domésticos não especificados anteriormente",
  N77_31__4 = "Aluguel de máquinas e equipamentos agrícolas sem operador",
  N77_32__2 = "Aluguel de máquinas e equipamentos para construção sem operador",
  N77_33__1 = "Aluguel de máquinas e equipamentos para escritórios",
  N77_39__0 = "Aluguel de máquinas e equipamentos não especificados anteriormente",
  N77_40__3 = "Gestão de ativos intangíveis não financeiros",
  N78_10__8 = "Seleção e agenciamento de mão de obra",
  N78_20__5 = "Locação de mão de obra temporária",
  N78_30__2 = "Fornecimento e gestão de recursos humanos para terceiros",
  N79_11__2 = "Agências de viagens",
  N79_12__1 = "Operadores turísticos",
  N79_90__2 = "Serviços de reservas e outros serviços de turismo não especificados anteriormente",
  N80_11__1 = "Atividades de vigilância e segurança privada",
  N80_12__9 = "Atividades de transporte de valores",
  N80_20__0 = "Atividades de monitoramento de sistemas de segurança",
  N80_30__7 = "Atividades de investigação particular",
  N81_11__7 = "Serviços combinados para apoio a edifícios exceto condomínios prediais",
  N81_12__5 = "Condomínios prediais",
  N81_21__4 = "Limpeza em prédios e em domicílios",
  N81_22__2 = "Imunização e controle de pragas urbanas",
  N81_29__0 = "Atividades de limpeza não especificadas anteriormente",
  N81_30__3 = "Atividades paisagísticas",
  N82_11__3 = "Serviços combinados de escritório e apoio administrativo",
  N82_19__9 = "Fotocópias preparação de documentos e outros serviços especializados de apoio administrativo",
  N82_20__2 = "Atividades de teleatendimento",
  N82_30__0 = "Atividades de organização de eventos exceto culturais e esportivos",
  N82_91__1 = "Atividades de cobranças e informações cadastrais",
  N82_92__0 = "Envasamento e empacotamento sob contrato",
  N82_99__7 = "Atividades de serviços prestados principalmente às empresas não especificadas anteriormente",
  O84_11__6 = "Administração pública em geral",
  O84_12__4 = "Regulação das atividades de saúde educação serviços culturais e outros serviços sociais",
  O84_13__2 = "Regulação das atividades econômicas",
  O84_21__3 = "Relações exteriores",
  O84_22__1 = "Defesa",
  O84_23__0 = "Justiça",
  O84_24__8 = "Segurança e ordem pública",
  O84_25__6 = "Defesa civil",
  O84_30__2 = "Seguridade social obrigatória",
  P85_11__2 = "Educação infantil creche",
  P85_12__1 = "Educação infantil pré escola",
  P85_13__9 = "Ensino fundamental",
  P85_20__1 = "Ensino médio",
  P85_31__7 = "Educação superior graduação",
  P85_32__5 = "Educação superior graduação e pós graduação",
  P85_33__3 = "Educação superior pós graduação e extensão",
  P85_41__4 = "Educação profissional de nível técnico",
  P85_42__2 = "Educação profissional de nível tecnológico",
  P85_50__3 = "Atividades de apoio à educação",
  P85_91__1 = "Ensino de esportes",
  P85_92__9 = "Ensino de arte e cultura",
  P85_93__7 = "Ensino de idiomas",
  P85_99__6 = "Atividades de ensino não especificadas anteriormente",
  Q86_10__1 = "Atividades de atendimento hospitalar",
  Q86_21__6 = "Serviços móveis de atendimento a urgências",
  Q86_22__4 = "Serviços de remoção de pacientes exceto os serviços móveis de atendimento a urgências",
  Q86_30__5 = "Atividades de atenção ambulatorial executadas por médicos e odontólogos",
  Q86_40__2 = "Atividades de serviços de complementação diagnóstica e terapêutica",
  Q86_50__0 = "Atividades de profissionais da área de saúde exceto médicos e odontólogos",
  Q86_60__7 = "Atividades de apoio à gestão de saúde",
  Q86_90__9 = "Atividades de atenção à saúde humana não especificadas anteriormente",
  Q87_11__5 = "Atividades de assistência a idosos deficientes físicos imunodeprimidos e convalescentes prestadas em residências coletivas e particulares",
  Q87_12__3 = "Atividades de fornecimento de infraestrutura de apoio e assistência a paciente no domicílio",
  Q87_20__4 = "Atividades de assistência psicossocial e à saúde a portadores de distúrbios psíquicos deficiência mental e dependência química",
  Q87_30__1 = "Atividades de assistência social prestadas em residências coletivas e particulares",
  Q88_00__6 = "Serviços de assistência social sem alojamento",
  R90_01__9 = "Artes cênicas espetáculos e atividades complementares",
  R90_02__7 = "Criação artística",
  R90_03__5 = "Gestão de espaços para artes cênicas espetáculos e outras atividades artísticas",
  R91_01__5 = "Atividades de bibliotecas e arquivos",
  R91_02__3 = "Atividades de museus e de exploração restauração artística e conservação de lugares e prédios históricos e atrações similares",
  R91_03__1 = "Atividades de jardins botânicos zoológicos parques nacionais reservas ecológicas e áreas de proteção ambiental",
  R92_00__3 = "Atividades de exploração de jogos de azar e apostas",
  R93_11__5 = "Gestão de instalações de esportes",
  R93_12__3 = "Clubes sociais esportivos e similares",
  R93_13__1 = "Atividades de condicionamento físico",
  R93_19__1 = "Atividades esportivas não especificadas anteriormente",
  R93_21__2 = "Parques de diversão e parques temáticos",
  R93_29__8 = "Atividades de recreação e lazer não especificadas anteriormente",
  S94_11__1 = "Atividades de organizações associativas patronais e empresariais",
  S94_12__0 = "Atividades de organizações associativas profissionais",
  S94_20__1 = "Atividades de organizações sindicais",
  S94_30__8 = "Atividades de associações de defesa de direitos sociais",
  S94_91__0 = "Atividades de organizações religiosas",
  S94_92__8 = "Atividades de organizações políticas",
  S94_93__6 = "Atividades de organizações associativas ligadas à cultura e à arte",
  S94_99__5 = "Atividades associativas não especificadas anteriormente",
  S95_11__8 = "Reparação e manutenção de computadores e de equipamentos periféricos",
  S95_12__6 = "Reparação e manutenção de equipamentos de comunicação",
  S95_21__5 = "Reparação e manutenção de equipamentos eletroeletrônicos de uso pessoal e doméstico",
  S95_29__1 = "Reparação e manutenção de objetos e equipamentos pessoais e domésticos não especificados anteriormente",
  S96_01__7 = "Lavanderias tinturarias e toalheiros",
  S96_02__5 = "Cabeleireiros e outras atividades de tratamento de beleza",
  S96_03__3 = "Atividades funerárias e serviços relacionados",
  S96_09__2 = "Atividades de serviços pessoais não especificadas anteriormente",
  T97_00__5 = "Serviços domésticos",
  U99_00__8 = "Organismos internacionais e outras instituições extraterritoriais",

}

export const ConclaSchema = z.nativeEnum(ConclaEnum);

type ConclaKeys = keyof typeof ConclaEnum;

// @ts-expect-error dont know how to fix yet
export const ConclaEnumArraySchema = z.array(z.nativeEnum<ConclaKeys>(Object.keys(ConclaEnum)));
