<script setup lang="ts">
import mergeWith from "lodash-es/mergeWith";
import some from "lodash-es/some";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
import moment from "moment";
import Card from "@/components/card/Card.vue";
import { BaseSelect, FormInput, SearchDropdown, TextArea } from "@/components/inputs/modalInputs";
import Tooltip from "@/components/tooltip/Tooltip.vue";
import WarningIcon from "@/assets/images/alert_circle_icon.svg";
import { moneyCurrencies } from "@/utils/adapters/form";
import { searchCorporation } from "@/http_services/entities/corporation";
import { searchPersonDropdown } from "@/http_services/entities/persons";
import type { Corporation } from "@/http_services/entities/types";
import { CURRENCY } from "@/entities/common";
import type { Person } from "@/schemas/person";
import PersonSearch from "@/common/components/PersonSearch.vue";
import SelectCurrency from "@/components/inputs/moneyInputs/SelectCurrency.vue";
import { RadioSimple } from "@/components/radio";

const emit = defineEmits(["cancel-edit"]);
const { t, tm } = useI18n();
const route = useRoute();

const { data: project, isSuccess: success } = useProject(String(route.params.id));
const { inserted_at, finished_at } = project.value;
const {
  mutate: updateProject,
} = updateProjectMutation(String(route.params.id));

const updatePublishedByInput = (person: Person) => `${person.first_name} ${person.last_name}`;

const updateCorporationInput = (corporation: Corporation) => corporation.name;

const fullName = computed(() => {
  return `${project.value.published_by.first_name} ${project.value.published_by.last_name}`;
});

const formState = ref(
  mergeWith(
    {
      project_name: null,
      position_name: null,
      project_type: null,
      corporation: null,
      published_by: {
        first_name: null,
        last_name: null,
      },
      placement_type: null,
      confidential_placement: false,
      participants: [],
      position_details: {
        address: {
          name: null,
        },
        position_class: null,
        level: null,
        salary_range: {
          currency: CURRENCY.BRL,
          min_value: 0,
          max_value: 0,
          contract_type: null,
        },
        bonus: null,
        annual_package: {
          currency: CURRENCY.BRL,
          min_value: 0,
          max_value: 0,
        },
        about: null,
      },
    },
    project.value,
    (first_key, second_key) => (second_key === null ? first_key : undefined),
  ),
);
function selectCorporation(corporation: Corporation) {
  formState.value.corporation = {
    corporation_id: corporation.id,
    corporation_name: corporation.name,
  };
}

function selectPublishedBy(person: any) {
  formState.value.published_by = {
    person_id: person.id,
    first_name: person.first_name,
    last_name: person.last_name,
  };
}

const validateAllInputs = (...errors: boolean[]) => some(errors);

const rules = computed(() => ({
  project_name: {
    required: helpers.withMessage(t("validator.required"), required),
  },
  position_name: {
    required: helpers.withMessage(t("validator.required"), required),
  },
  project_type: {
    required: helpers.withMessage(t("validator.required"), required),
  },
  corporation: {
    required: helpers.withMessage(t("validator.required"), required),
  },
  published_by: {
    required: helpers.withMessage(t("validator.required"), required),
  },
  position_details: {
    salary_range: {
      contract_type: {},
      currency: {},
      max_value: {},
      min_value: {},
    },
    annual_package: {
      currency: {},
      max_value: {},
      min_value: {},
    },
  },
}));

const v$ = useVuelidate(rules, formState);

const salaryAndAnnualPackageError = computed(() => {
  const { salary_range, annual_package } = v$.value.position_details;
  const validateInputs = (inputs: any[]) => inputs.some(input => input.$error);
  return {
    salary_range: validateInputs([
      salary_range.contract_type,
      salary_range.currency,
      salary_range.min_value,
      salary_range.max_value,
    ]),
    annual_package: validateInputs([
      annual_package.currency,
      annual_package.min_value,
      annual_package.max_value,
    ]),
  };
});

const contractType = [
  {
    value: "CLT",
    label: "CLT",
  },
  {
    value: "PJ",
    label: "PJ",
  },
];
const form = computed(() => ({
  projectName: {
    label: t("projects.details.edit.projectName.label"),
    placeholder: t("projects.details.edit.projectName.placeholder"),
    error: v$.value.project_name.$errors[0]?.$message as string,
  },
  position: {
    label: t("projects.details.edit.position.label"),
    placeholder: t("projects.details.edit.position.placeholder"),
    error: v$.value.position_name.$errors[0]?.$message as string,
  },
  projectType: {
    label: t("projects.details.edit.projectType.label"),
    options: Object.entries(tm("projects.details.edit.projectType.enum")).map(([key, value]) => ({ value: key, label: value })),
    error: v$.value.project_type.$errors[0]?.$message as string,
  },
  status: {
    label: t("projects.details.edit.status.label"),
    tooltipText: t("projects.details.edit.status.tooltipText"),
    options: Object(tm("projects.details.edit.status.enum")),
  },
  local: {
    label: t("projects.details.edit.local.label"),
    placeholder: t("projects.details.edit.local.placeholder"),
  },
  class: {
    label: t("projects.details.edit.class.label"),
    options: Object.entries(tm("projects.details.edit.class.enum")).map(([key, value]) => ({ value: key, label: value })),
  },
  level: {
    label: t("projects.details.edit.level.label"),
    options: Object.entries(tm("projects.details.edit.level.enum")).map(([_, value]) => ({ value, label: value })),
  },
  about: {
    label: t("projects.details.edit.about.label"),
    placeholder: t("projects.details.edit.about.placeholder"),
  },
  company: {
    label: t("projects.details.edit.company.label"),
    placeholder: t("projects.create.placeholders.corporation"),
    error: v$.value.corporation.$errors[0]?.$message as string,
    clear: () => (formState.value.corporation = null),
  },
  salary: {
    label: t("projects.details.edit.salary.label"),
    placeholder: t("projects.create.placeholders.netWorth"),
    types: contractType,
    currency: moneyCurrencies,
  },
  bonus: {
    label: t("projects.details.edit.bonus.label"),
    placeholder: t("projects.details.edit.bonus.placeholder"),
  },
  annualPackage: {
    label: t("projects.details.edit.annualPackage.label"),
    placeholder: t("projects.create.placeholders.netWorth"),
    currency: moneyCurrencies,
  },
  requester: {
    label: t("projects.details.edit.requester.label"),
    error: v$.value.published_by.$errors[0]?.$message as string,
    clear: () => (formState.value.published_by = null),
  },
  origin: {
    label: t("projects.details.edit.origin.label"),
    options: Object(tm("projects.details.edit.origin.enum")),
  },
  confidentialPlacement: {
    label: t("projects.details.edit.confidentialPlacement.label"),
  },
}));
function formaterToFloat(value: any) {
  let formatt = value;
  if (value.toString().includes(","))
    formatt = value.toString().replaceAll(".", "").replaceAll(",", ".");

  return Number.parseFloat(formatt);
}

function formatMoney() {
  const salaryRange = formState.value.position_details.salary_range;
  const annualPackage = formState.value.position_details.annual_package;
  formState.value.position_details.salary_range.min_value = formatToFixed(
    salaryRange.min_value,
  );
  formState.value.position_details.salary_range.max_value = formatToFixed(
    salaryRange.max_value,
  );
  formState.value.position_details.annual_package.min_value = formatToFixed(
    annualPackage.min_value,
  );
  formState.value.position_details.annual_package.max_value = formatToFixed(
    annualPackage.max_value,
  );
}

function formatToFixed(value: any): any {
  return Number.parseFloat(value).toFixed(2);
}

async function submit() {
  const isValid = await v$.value.$validate();

  const { address, annual_package, salary_range, bonus, about } = formState.value.position_details;

  const payload = {
    ...formState.value,
    position_details: {
      ...formState.value.position_details,
      address: address || null,
      annual_package: {
        ...annual_package,
        min_value: formaterToFloat(annual_package.min_value),
        max_value: formaterToFloat(annual_package.max_value),
      },
      salary_range: {
        ...salary_range,
        min_value: formaterToFloat(salary_range.min_value),
        max_value: formaterToFloat(salary_range.max_value),
      },
      bonus: bonus ?? null,
      about: about ?? null,
    },
  };
  if (isValid) {
    updateProject({ ...payload });
    if (success)
      emit("cancel-edit");
  }
}

function onInputValue(event: KeyboardEvent): void {
  const { key } = event;
  if (/^[0-9]/.test(key) || ["Backspace", "Enter", "Tab"].includes(key))
    return;

  event.preventDefault();
}
onMounted(() => {
  formatMoney();
});
const startDate = ref<string>("");
const endDate = ref<string>("");
const diffDate = ref<string>("");

function getDate(date: string | null): string {
  if (!date)
    return "";

  return moment(date).format("DD/MM/YYYY");
}

function addParticipant(participants) {
  const mapped = (participants || []).map((participant) => {
    if (typeof participant.person_id !== "string") {
      return {
        person_id: participant.person_id.person_id,
      };
    }
    return {
      person_id: participant.person_id,
    };
  });
  formState.value.participants = mapped;
}

function getDateDif(init: string, end: string): string {
  if (!init)
    return "";

  const formatInit = moment(init);
  const formatEnd = end ? moment(end) : moment();

  const allDays = formatEnd.diff(formatInit, "days");

  const year = Math.floor(allDays / 365);

  const remainingDays = allDays - year * 365;

  const month = Math.floor(remainingDays / 30);

  const day = remainingDays - month * 30;

  const titleYear = year > 1 ? "years" : "year";
  const titleMonth = month > 1 ? "months" : "month";
  const titleDay = day > 1 ? "days" : "day";

  if (year > 0 && month > 0)
    return `${year} ${titleYear} ${month} ${titleMonth}`;
  else if (year > 0 && month === 0)
    return `${year} ${titleYear}`;
  else if (year === 0 && month > 0)
    return `${month} ${titleMonth} ${day} ${titleDay}`;
  else if (year === 0 && month === 0)
    return `${day} ${titleDay}`;
  else return "";
}

onMounted(() => {
  startDate.value = getDate(inserted_at);
  endDate.value = getDate(finished_at);
  diffDate.value = endDate.value
    ? getDateDif(inserted_at, finished_at)
    : getDateDif(inserted_at, moment().toISOString());
});
</script>

<template>
  <Card>
    <form class="form" @submit.prevent="submit">
      <div class="form__section">
        <div class="form__field">
          <div class="form__label">
            <label for="">{{ form.projectName.label }}:<span>&#11044;</span></label>
          </div>
          <!-- TODO: why form state is being used as placeholder??? -->
          <FormInput
            v-model="formState.project_name"
            class="form__input"
            :placeholder="form.projectName.placeholder"
            :error="!!form.projectName.error"
            :error-message="form.projectName.error"
          />
        </div>
        <div class="form__field">
          <div class="form__label">
            <label for="">{{ form.position.label }}:<span>&#11044;</span></label>
          </div>
          <FormInput
            v-model="formState.position_name"
            class="form__input"
            :placeholder="form.position.placeholder"
            :error="!!form.position.error"
            :error-message="form.position.error"
          />
        </div>
        <div class="form__field">
          <div class="form__label">
            <label for="">{{ form.projectType.label }}:<span>&#11044;</span></label>
          </div>
          <BaseSelect
            :model-value="formState.project_type"
            :initial-value="formState.project_type"
            :options="form.projectType.options"
            :placeholder="t('text.select')"
            @change="formState.project_type = $event"
          />
        </div>
        <div class="form__field">
          <div class="form__label">
            <label for="">{{ form.status.label }}:</label>
          </div>
          <div class="form__control--status">
            <span>{{ form.status.options.IN_PROGRESS }}</span>
            <Tooltip class="tooltip" :tooltip-text="form.status.tooltipText">
              <WarningIcon class="tooltip__icon" />
            </Tooltip>
          </div>
        </div>
        <div class="form__field">
          <div class="form__label">
            <label for="">{{ form.local.label }}:</label>
          </div>
          <FormInput
            v-model="formState.position_details.address.name"
            class="form__input"
            :placeholder="form.local.placeholder"
          />
        </div>
        <div class="form__field">
          <div class="form__label">
            <label for="">{{ form.class.label }}:</label>
          </div>
          <BaseSelect
            :style="{ width: '50%' }"
            :model-value="formState.position_details.position_class"
            :initial-value="formState.position_details.position_class"
            :options="form.class.options"
            :placeholder="t('text.select')"
            @change="formState.position_details.position_class = $event"
          />
        </div>
        <div class="form__field">
          <div class="form__label">
            <label for="">{{ form.level.label }}</label>
          </div>
          <BaseSelect
            :style="{ width: '50%' }"
            :model-value="formState.position_details.level"
            :initial-value="formState.position_details.level"
            :options="form.level.options"
            :placeholder="t('text.select')"
            @change="formState.position_details.level = $event"
          />
        </div>
        <div class="form__field form__field--radio">
          <div class="form__label">
            <label for="">{{ form.confidentialPlacement.label }}</label>
          </div>
          <div class="form-control--radio radio-inputs">
            <RadioSimple
              id="economic_group-no"
              v-model="formState.confidential_placement"
              name="economic_group"
              :value="false"
              :label="t('projects.create.basicData.no')"
            />
            <RadioSimple
              id="economic_group-yes"
              v-model="formState.confidential_placement"
              name="economic_group"
              :value="true"
              :label="t('projects.create.basicData.yes')"
            />
          </div>
        </div>
      </div>
      <div class="form__section">
        <div class="form__field">
          <div class="form__label">
            <label for="">{{ form.company.label }}:<span>&#11044;</span></label>
          </div>
          <SearchDropdown
            v-if="project"
            id="economic-group"
            class="form__input"
            :fetch-method="searchCorporation"
            :select-item-callback="selectCorporation"
            :update-input-model="updateCorporationInput"
            :initial-value="project.corporation.corporation_name"
            :input-placeholder="form.company.placeholder"
            :search-not-found-label="t('text.searchCorporationNotFound')"
            :search-error-label="t('text.searchCorporationError')"
            :error="!!form.company.error"
            :error-message="form.company.error"
            @clear="form.company.clear"
          >
            <template #list-item="{ item: corporation }">
              {{ corporation.name }}
            </template>
          </SearchDropdown>
        </div>
        <div class="form__field">
          <div class="form__label">
            <label for="">{{ form.salary.label }}:</label>
          </div>
          <div class="form__control--salary">
            <BaseSelect
              :model-value="formState.position_details.salary_range.contract_type"
              :initial-value="formState.position_details.salary_range.contract_type"
              :options="form.salary.types"
              @change="formState.position_details.salary_range.contract_type = $event"
            />
            <span>{{ t("projects.create.positionData.salary.from") }}</span>
            <SelectCurrency
              :model-value="formState.position_details.salary_range.currency"
              @change="formState.position_details.salary_range.currency = $event"
            />
            <FormInput
              v-model="formState.position_details.salary_range.min_value"
              class="input"
              money
              :placeholder="form.salary.placeholder"
              :on-key-down="onInputValue"
              :error="v$.position_details.salary_range.min_value.$error"
            />
            <span>{{ t("projects.create.positionData.salary.to") }}</span>
            <FormInput
              v-model="formState.position_details.salary_range.max_value"
              class="input"
              money
              :placeholder="form.salary.placeholder"
              :on-key-down="onInputValue"
              :error="v$.position_details.salary_range.max_value.$error"
            />
            <p v-if="salaryAndAnnualPackageError.salary_range" class="help-text">
              {{ t("projects.details.edit.salary.helptext") }}
            </p>
          </div>
        </div>
        <div class="form__field">
          <div class="form__label">
            <label for="">{{ form.bonus.label }}:</label>
          </div>
          <FormInput
            v-model="formState.position_details.bonus"
            class="form__input"
            :placeholder="form.bonus.placeholder"
          />
        </div>
        <div class="form__field">
          <div class="form__label">
            <label for="">{{ form.annualPackage.label }}:</label>
          </div>
          <div class="form__control--annual-package">
            <span>{{ t("projects.create.positionData.salary.from") }}</span>
            <SelectCurrency
              :model-value="formState.position_details.annual_package.currency"
              @change="formState.position_details.annual_package.currency = $event"
            />
            <FormInput
              v-model="formState.position_details.annual_package.min_value"
              class="input"
              money
              :placeholder="form.annualPackage.placeholder"
              :on-key-down="onInputValue"
              :error="v$.position_details.annual_package.min_value.$error"
            />
            <span>{{ t("projects.create.positionData.salary.to") }}</span>
            <FormInput
              v-model="formState.position_details.annual_package.max_value"
              class="input"
              money
              :placeholder="form.annualPackage.placeholder"
              :on-key-down="onInputValue"
              :error="v$.position_details.annual_package.max_value.$error"
            />
            <p v-if="salaryAndAnnualPackageError.annual_package" class="help-text">
              {{ t("projects.details.edit.annualPackage.helptext") }}
            </p>
          </div>
        </div>
        <div class="form__field">
          <div class="form__label">
            <label for="">{{ form.requester.label }}:</label>
          </div>
          <SearchDropdown
            v-if="project"
            id="requester-input"
            class="form__input"
            :fetch-method="searchPersonDropdown"
            :select-item-callback="selectPublishedBy"
            :update-input-model="updatePublishedByInput"
            :initial-value="fullName"
            :input-placeholder="t('projects.create.placeholders.requester')"
            :search-not-found-label="t('text.searchProfileNotFound')"
            :search-error-label="t('text.searchProfileError')"
            :error="!!form.requester.error"
            :error-message="form.requester.error"
            @clear="form.requester.clear"
          >
            <template #list-item="{ item: person }">
              {{ person.first_name }} {{ person.last_name }}
            </template>
          </SearchDropdown>
        </div>
        <div class="form__field">
          <label class="form__label">
            {{ t("projects.create.basicData.allocatedTeam.label") }}:
          </label>
          <PersonSearch :selected="formState.participants" class="pl-1" @update:selected="addParticipant" />
        </div>
        <div class="form__field">
          <div class="form__label">
            <span>{{ t('text.start') }}:</span>
          </div>
          {{ startDate }}
        </div>
        <div class="form__field">
          <div class="form__label">
            <span>{{ t('text.end') }}:</span>
          </div>
          <span>{{ endDate }} {{ `(${diffDate})` }}</span>
        </div>
      </div>
      <div class="form__section--about">
        <div class="form__field--about">
          <label for="">{{ form.about.label }}:</label>
          <TextArea
            v-model="formState.position_details.about"
            :placeholder="form.about.placeholder"
          />
        </div>
      </div>
      <div class="form__section--buttons">
        <Button type="submit">
          {{ t("projects.details.edit.save") }}
        </Button>
        <Button variation="light" filled @click="$emit('cancel-edit')">
          {{ t("projects.details.edit.cancel") }}
        </Button>
      </div>
    </form>
  </Card>
</template>

  <style lang="scss" scoped>
  .form {
    display: grid;
    column-gap: 4rem;
    grid-template-columns: 1fr;
    row-gap: 1rem;

    &__section {
      &--buttons {
        display: flex;
        justify-content: space-between;
      }

      &--about {
        margin-bottom: 1rem;
      }
    }

    &__control {
      &--status {
        display: flex;
        gap: 0.5rem;

        p {
          font-size: 14px;
          line-height: 26px;
          margin: 0;
          vertical-align: center;
        }

        .tooltip:deep {
          height: 26px;

          &__icon {
            width: 20px;
          }

          .tooltip-text {
            padding: 0.5rem;
            text-transform: uppercase;
          }

          svg {
            stroke: $red;
          }
        }
      }

      &--salary {
        display: grid;
        min-height: 45px;
        margin-bottom: 0.5rem;
        column-gap: 0.5rem;
        grid-template-columns: auto min-content auto;
        row-gap: 0.5rem;

        span {
          max-height: 28px;
          font-size: 14px;
          line-height: 28px;
          text-align: center;
        }

        p.help-text {
          position: relative;
          top: -0.25rem;
          padding-left: 0.3rem;
          color: $red;
          font-size: 10px;
          font-weight: 500;
          grid-column: span 3;
          line-height: 10px;
          text-align: start;
        }

        :deep(.input) {
          height: 28px;
          min-height: min-content;
        }
      }

      &--annual-package {
        display: grid;
        min-height: 45px;
        margin-bottom: 0.5rem;
        column-gap: 0.5rem;
        grid-template-columns: min-content auto auto;
        row-gap: 0.5rem;

        span {
          max-height: 28px;
          font-size: 14px;
          line-height: 28px;
          text-align: center;
        }

        p.help-text {
          position: relative;
          top: -0.25rem;
          padding-left: 0.3rem;
          color: $red;
          font-size: 10px;
          font-weight: 500;
          grid-column: span 3;
          line-height: 10px;
          text-align: start;
        }

        :deep(.input) {
          height: 28px;
          min-height: min-content;
        }
      }
    }
    .form__label {
      font-size: 0.875rem;
    }
    &__label {
      margin-bottom: 0.5rem;
    label, span {
      margin-bottom: 0.5rem;
      font-size: 0.875rem;
    }
      label{

        span {
          color: $red;
          font-size: 5px;
          line-height: 5px;
          vertical-align: top;
        }
      }
    }

    &__field {
      display: grid;
      min-height: 50px;
      grid-template-columns: 1fr;
      font-size: 0.875rem;

      :deep(.radio-inputs) {
        display: flex;
        align-items: flex-start;
        margin-left: 1.5625rem;

        .imua-radiosimple {
          display: inline-flex;
          align-items: center;
          padding-top: 0.25rem;

          label {
            padding-left: 0;
            font-size: 0.875rem;
          }
        }
      }

      &--about {
        label {
          display: block;
          margin-bottom: 0.5rem;
          font-size: 14px;
        }
      }
    }

    &__input {
      min-height: 40px;

      &--status {
        width: 60%;
      }
    }

    &__select {
      width: 100%;
    }
  }

  @media only screen and (min-width: 1024px) {
    .form {
      grid-template-columns: 1fr 1fr;

      &__section {
        &--about {
          grid-column: 1 / 3;
        }
      }

      &__control {
        &--salary {
          display: grid;
          margin-bottom: 0;
          grid-template-columns: 16% auto 16% auto auto auto;
          row-gap: 0;

          p {
            text-align: center;
          }
        }

        &--annual-package {
          display: grid;
          width: 80%;
          margin-bottom: 0;
          grid-template-columns: auto 20% auto auto auto;
          row-gap: 0;

          p {
            text-align: center;
          }
        }
      }

      &__label {
        display: flex;
        height: 28px;
        align-items: center;

        label {
          margin: 0;
        }
      }

      &__field {
        display: grid;
        grid-template-columns: 1.5fr 5fr;
      }

      :deep(.radio-inputs) {
        .imua-radiosimple {
          display: inline-flex;
          align-items: center;
          margin-right: 1rem;

          .p-radiobutton-box {
            margin-top: auto;
          }

          font-size: 0.875rem;

          label {
            padding-left: 0;
          }
        }
      }

      &__select {
        width: 50%;
      }
    }
  }
</style>
