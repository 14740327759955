<script setup lang="ts">
import { computed } from "vue";
import { capitalize } from "lodash";
import { useI18n } from "vue-i18n";
import type { PersonForRecruiter } from "@/schemas/common";
import { changeImageSize } from "@/utils/images/resize-images";
import type { ImageSizesType } from "@/schemas/enums/images";

interface PropsType {
  people: PersonForRecruiter[]
  imageSize?: ImageSizesType
}

const props = withDefaults(defineProps<PropsType>(), {
  imageSize: "mini",
});

const { t } = useI18n();

const tooltip = computed(() => {
  const names = props.people.map(p => capitalize(p?.first_name));
  if (props.people.length > 3)
    return `${names[0]}, ${names[1]} ${t("text.conjunctions.and")} ${t("text.others")}`;

  return names.join(", ").replace(/,(?!.*,)/gim, `${t("text.conjunctions.and")}`);
});
</script>

<template>
  <div v-if="people.length > 3" class="avatars" :text-tooltip="tooltip">
    <div v-for="(person, index) in people" :key="`person-${index}`" class="avatars__item">
      <div v-if="index < 2" class="avatars__image">
        <img
          v-if="person?.avatar_url"
          class="avatar__image"
          :src="changeImageSize(person?.avatar_url, imageSize)"
          :alt="`${person?.first_name} ${person?.last_name}`"
        >
        <p v-else>
          {{ person?.first_name }}
        </p>
      </div>
      <div v-if="index === 3" class="avatars__image">
        <p>+{{ people.length - 2 }}</p>
      </div>
    </div>
  </div>
  <div v-if="people.length <= 3" class="avatars" :text-tooltip="tooltip">
    <div v-for="(person, index) in people" :key="`person-${index}`" class="avatars__item">
      <div class="avatars__image">
        <img
          v-if="person?.avatar_url"
          class="avatar__image"
          :src="changeImageSize(person?.avatar_url, imageSize)"
          :alt="`${person?.first_name} ${person?.last_name}`"
        >
        <p v-else>
          {{ person?.first_name.charAt(0).toUpperCase()
          }}{{ person?.last_name.charAt(0).toUpperCase() }}
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.avatars {
  position: relative;
  display: flex;
  cursor: pointer;

  &:hover {
    &::before {
      position: absolute;
      z-index: 100;
      top: -1.5rem;
      left: -2px;
      display: inline-block;
      width: max-content;
      padding: 0.3rem;
      border-radius: 0.25rem;
      background-color: $primary;
      box-shadow: 0 0.125rem 0.25rem #0003;
      color: #fff;
      content: attr(text-tooltip);
      font-size: 0.75rem;
      font-weight: 500;
    }
  }

  &__item {
    margin-left: -2px;

    &:nth-of-type(1) {
      z-index: 2;
    }

    &:nth-of-type(2) {
      z-index: 1;
    }
  }

  &__image {
    display: flex;
    width: 1.375rem;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0 0 0 1px #fff;

    img {
      width: 1.375rem;
      border-radius: 50%;
    }

    p {
      width: 1.375rem;
      height: 1.375rem;
      border-radius: 50%;
      background-color: $primary;
      color: white;
      font-size: 0.563rem;
      font-weight: 400;
      line-height: 1.375rem;
      text-align: center;
      margin: 0;
      padding: 0;
    }
  }
}
</style>
