import type { Directive, DirectiveBinding } from "vue";

interface IValue {
  maxLength: number
  showEllipsis: boolean
}

function setVEllipsisDirective(el: Element, binding: DirectiveBinding<string | number | IValue>) {
  const { value } = binding;
  let maxNumber = 0;
  let showEllipsis = true;

  if (typeof value === "number")
    maxNumber = value;

  if (typeof value === "object") {
    maxNumber = value?.maxLength;
    showEllipsis = value?.showEllipsis;
  }

  const MAX_LENGTH = maxNumber || 350;

  if (el?.textContent && el?.textContent?.length >= MAX_LENGTH) {
    el.textContent = el?.textContent?.length > MAX_LENGTH && showEllipsis
      ? el?.textContent?.slice(0, MAX_LENGTH)?.concat("...")
      : el?.textContent?.slice(0, MAX_LENGTH);
  }
}

export interface IEllipsis {
  maxLength: number
  showEllipsis: boolean
}

export const vEllipsis: Directive<HTMLElement, string | number | IValue> = {
  mounted(el: Element, binding) {
    setVEllipsisDirective(el, binding);
  },
  updated(el: Element, binding) {
    setVEllipsisDirective(el, binding);
  },
};

function setVlinebreakDirective(el: Element) {
  if (el?.textContent)
    el.innerHTML = el.textContent.replace(/\n/g, "<br>");
}

export const vLinebreak: Directive<HTMLElement, string> = {
  mounted(el: Element) {
    setVlinebreakDirective(el);
  },
  updated(el: Element) {
    setVlinebreakDirective(el);
  },
};

function removeEmptyElement(element: Element): void {
  if (!element.textContent?.trim())
    element.parentNode?.removeChild(element);
}

export const vRemoveEmpty: Directive<HTMLElement, string> = {
  mounted(el: Element) {
    removeEmptyElement(el);
  },
  updated(el: Element) {
    removeEmptyElement(el);
  },
};
