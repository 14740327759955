<script setup lang="ts">
import get from "lodash-es/get";
import findLastIndex from "lodash-es/findLastIndex";
import cloneDeep from "lodash-es/cloneDeep";
import Resume from "./Resume.vue";
import ExportPage from "./ExportPage.vue";

import { estimatedTextLines, paginateTexts } from "@/utils/text";

const props = withDefaults(
  defineProps<{
    idealProfile: any
    isExport: boolean
  }>(),
  {
    isExport: false,
    interviewActivitie: null,
  },
);

function getLinesLastPage(lastPage: any, empty_data: any, path: string, box_width: number, font_size: string) {
  let _totalLines = 0;
  if (!empty_data)
    return 0;

  Object.keys(empty_data || {})?.forEach((key) => {
    const _texts = get(lastPage, key, []);
    const _text = _texts
      ?.map((_item) => {
        if (Array.isArray(_item))
          return _item?.map(_item_b => get(_item_b, path, ""));
        else
          return get(_item, path, "");
      })
      ?.join("");
    const _lines = estimatedTextLines(_text || "", font_size, box_width);
    if (_lines > _totalLines)
      _totalLines = _lines;
  });
  return _totalLines;
}

enum MotivationTypes {
  text = "text",
  attraction = "attraction",
  repulsion = "repulsion",
  retention = "retention",
}
interface PackageType {
  label: string | null
  value: string
  bullet: boolean
  type: string
  score?: string | number | null
  lines?: number | null
}

const { t } = useI18n();

interface PagesTypes {
  package: PackageType[]
  career: PackageType[]
  environment: PackageType[]
  personal: PackageType[]
  opinion: string | null
}

const { isExport, idealProfile } = toRefs(props);

const emptyValue = {
  package: [],
  career: [],
  environment: [],
  personal: [],
  opinion: null,
} as PagesTypes;

const pages = ref<PagesTypes[]>([]);

const boxWidth = 200;
const fontSize = "14px";
const font = "Open Sans";
const maxLines = 30;
const page = ref(0);
const line = ref(0);

function addInPage(data: any[], path: string, title: string, type = MotivationTypes.text) {
  data?.forEach((item) => {
    if (line.value >= maxLines) {
      page.value += 1;
      line.value = 0;
    }
    if (!pages.value[page.value])
      pages.value[page.value] = cloneDeep(emptyValue);

    pages.value[page.value][path].push({
      label: !item.break ? title : null,
      type,
      bullet: !item.break,
      value: item.text,
      lines: item.lines,
    });

    line.value += item.lines;
  });
}

function paginatePage() {
  pages.value = [];
  page.value = 0;
  line.value = 0;

  pages.value[page.value] = cloneDeep(emptyValue);

  const motivation_package_presentation = props.idealProfile?.package || {};

  const career = Object.values(props.idealProfile?.career || {}).filter(v => v.length);

  const work_environment = Object.values(props.idealProfile?.work_environment || {}).filter(v => v.length);

  const personal_life = Object.values(props.idealProfile?.personal_life || {}).filter(v => v.length);

  const consultant_opinion = props.idealProfile?.consultant_opinion;

  const packageTitles = {
    salary: "salary",
    short_term_incentive: "icp",
    long_term_incentive: "ilp",
    other_incentive: "others",
    total_cash: "totalCash",
  };

  const motivationTitles = {
    ...packageTitles,
    career: "career",
    environment: "environment",
    personal: "personal",
  };

  pages.value[page.value] = cloneDeep(emptyValue);

  const resetPage = () => {
    page.value = 0;
    line.value = 0;
  };

  const paginate = (text: string, title?: string, breakLines = "\n") => {
    return paginateTexts(
      text,
      fontSize,
      boxWidth,
      maxLines,
      line.value,
      title ? String(`${title}: `) : "",
      font,
      breakLines,
    );
  };

  // package
  Object.keys(packageTitles)?.forEach((key) => {
    const text = get(motivation_package_presentation, key, "");
    const title = t(`exportPDF.labels.${get(motivationTitles, key)}`);
    addInPage(paginate(text, title, ""), "package", title);
    line.value += 1;
  });

  // career
  resetPage();
  career?.forEach((item) => {
    const title = t("exportPDF.labels.career");
    addInPage(paginate(item), "career", title, MotivationTypes.text);
    line.value += 1;
  });

  // work_environment
  resetPage();
  work_environment?.forEach((item) => {
    const title = t("exportPDF.labels.environment");
    addInPage(paginate(item), "environment", title, MotivationTypes.text);
    line.value += 1;
  });

  // personal_life
  resetPage();
  personal_life?.forEach((item) => {
    const title = t("exportPDF.labels.personal");
    addInPage(paginate(item), "personal", title, MotivationTypes.text);
    line.value += 1;
  });

  const lastPageIndex = findLastIndex(pages.value);
  const lastPage = pages.value[lastPageIndex];

  let lastLines = 0;

  if (lastPage) {
    const allLines = {
      career: lastPage.career?.reduce((accLines, item) => {
        return accLines += item?.lines || 0;
      }, 0),
      environment: lastPage.environment?.reduce((accLines, item) => {
        return accLines += item?.lines || 0;
      }, 0),
      package: lastPage.package?.reduce((accLines, item) => {
        return accLines += item?.lines || 0;
      }, 0),
      personal: lastPage.personal?.reduce((accLines, item) => {
        return accLines += item?.lines || 0;
      }, 0),
    };

    const totalLines = Object.values(allLines);
    lastLines = Math.max(...totalLines);
  }

  const paginateOpinion = paginateTexts(
    consultant_opinion || "",
    fontSize,
    903,
    25,
    lastLines,
  );

  paginateOpinion?.forEach((item, index) => {
    if ((paginateOpinion.length > 0 && index === 0) || paginateOpinion.length === 1) {
      pages.value[lastPageIndex].opinion = item.text;
    }
    else {
      const _new_page = cloneDeep(emptyValue);
      _new_page.opinion = item.text;
      pages.value.push(_new_page);
    }
  });
}

watchEffect(() => {
  paginatePage();
});
</script>

<template>
  <ExportPage
    v-for="(page, index) in pages"
    :key="index"
    :is-export="false"
    :page-index="index"
    :page-name="t('exportPDF.labels.motivationFactors')"
    :opinion="page.opinion"
    v-bind="$attrs"
  >
    <div class="pdf-motivation">
      <div class="box">
        <div v-if="(page.package || []).length > 0">
          <div class="pdf-title">
            {{ t("exportPDF.labels.package") }}
          </div>
          <Resume
            :show-title="false"
            :show-label="true"
            :show-circle="true"
            font-size="0.875rem"
            line-height="1.25rem"
            :items="page.package"
          />
        </div>
      </div>
      <div class="box">
        <div v-if="(page.career || []).length > 0">
          <div class="pdf-title">
            {{ t("exportPDF.labels.career") }}
          </div>
          <Resume
            :show-title="false"
            :show-label="false"
            :show-circle="true"
            font-size="0.875rem"
            line-height="1.25rem"
            :items="page.career"
          />
        </div>
      </div>
      <div class="box">
        <div v-if="(page.environment || []).length > 0">
          <div class="pdf-title">
            {{ t("exportPDF.labels.environment") }}
          </div>
          <Resume
            :show-title="false"
            :show-label="false"
            :show-circle="true"
            font-size="0.875rem"
            line-height="1.25rem"
            :items="page.environment"
          />
        </div>
      </div>
      <div class="box">
        <div v-if="(page.personal || []).length > 0">
          <div class="pdf-title">
            {{ t("exportPDF.labels.personal") }}
          </div>
          <Resume
            :show-title="false"
            :show-label="false"
            :show-circle="true"
            font-size="0.875rem"
            line-height="1.25rem"
            :items="page.personal"
          />
        </div>
      </div>
    </div>
  </ExportPage>
</template>

<style lang="scss" scoped>
.pdf-motivation {
  display: flex;
  height: 171rem;
  flex-direction: row;
  gap: 2rem;

  .box {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 1.75rem;

    .pdf-title {
      margin-bottom: 0.75rem;
      color: gray;
      font-size: 1.125rem;
      font-weight: bold;
      line-height: 1.5rem;
      text-transform: uppercase;
    }
  }
}
</style>
