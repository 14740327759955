<script setup lang="ts">
import { useI18n } from "vue-i18n";
import format from "date-fns/format";
import ptLocale from "date-fns/locale/pt";
import enLocale from "date-fns/locale/en-US";
import ExportPage from "./ExportPage.vue";
import FlowIcon from "@/assets/images/flow_ef_yellow.svg?component";

const props = withDefaults(
  defineProps<{
    title: string
    companyName?: string | null
    positionName?: string | null
    language: string
    isExport: boolean
  }>(),
  {
    companyName: "",
    positionName: "",
  },
);

const { t, locale } = useI18n();

const getLocale = (): Locale => locale.value === "en-US" ? enLocale : ptLocale;

const currentMonth = format(new Date(), "MMMM", { locale: getLocale() });
const currentYear = new Date().getFullYear();
</script>

<template>
  <ExportPage :full-page="true" :is-export="props.isExport">
    <div class="pdf-front-cover">
      <div class="pdf-front-cover-header">
        <span class="header-date">{{ currentMonth }} - {{ currentYear }}</span>
        <FlowIcon width="6.75rem" height="4.0625rem" />
      </div>
      <div class="pdf-front-cover-content">
        <div class="data-infos">
          <span class="company-name">
            {{ props.companyName }}
          </span>
          <span class="stage-title">
            {{ props.title }}
          </span>
          <span class="position-name">
            {{ props.positionName }}
          </span>
        </div>
        <div class="confidential">
          {{ t("exportPDF.confidential") }}
        </div>
      </div>
    </div>
  </ExportPage>
</template>

<style lang="scss" scoped>
.pdf-front-cover {
  display: flex;
  width: var(--pdf-width);
  height: var(--pdf-height);
  flex-direction: column;
  justify-content: space-between;
  padding-top: 1.875rem;
  padding-inline: 2.8125rem;

  &-header {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;

    .header-date {
      color: $gray-dark;
      font-size: 1.125rem;
      letter-spacing: 0.63px;
      text-transform: uppercase;
    }
  }

  &-content {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    padding-bottom: 4.75rem;

    .confidential {
      color: $gray-border;
      font-size: 0.875rem;
      letter-spacing: 0.49px;
      text-transform: uppercase;
    }

    .data-infos {
      display: flex;
      flex-direction: column;
      gap: 1.875rem;

      .company-name,
      .position-name {
        color: $gray-dark;
        font-size: 1.875rem;
        letter-spacing: 1.05px;
        text-transform: uppercase;
      }

      .position-name {
        font-weight: bold;
      }

      .stage-title {
        max-width: 22rem;
        color: $yellow;
        font-size: 2.5rem;
        letter-spacing: 1.4px;
        line-height: 3.4375rem;
        text-transform: uppercase;
      }
    }
  }
}
</style>
