<script setup lang="ts">
import type { Ref } from "vue";
import { useI18n } from "vue-i18n";
import { pipe } from "lodash/fp";
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import moment from "moment";
import Header from "./Header/Header.vue";
import ExtraActivities from "./ExtraActivities.vue";
import ExtraActivitiesEditable from "./ExtraActivitiesEditable.vue";
import WorkExperiences from "./WorkExperiences/WorkExperiences.vue";
import WorkExperienceEditable from "./WorkExperiences/WorkExperienceEditable.vue";
import Language from "./Language.vue";
import AcademicFormation from "./AcademicFormation.vue";
import AcademicFormationEditable from "./AcademicFormationEditable.vue";
import Certifications from "./Certifications.vue";
import Summary from "./Summary.vue";
import SummaryEditable from "./SummaryEditable.vue";
import WarningIcon from "@/assets/images/alert_circle_icon.svg";
import Modal from "@/components/modal/modal.vue";
import type {
  ActivityProjectsParticipated,
  ProjectsParticipated,
} from "@/entities/Person/activity";
import type { Person } from "@/entities/Person/person";
import {
  getPersonProjectsParticipated,
  updatePerson,
} from "@/http_services/entities/persons";
import EditIcon from "@/assets/images/edit_icon.svg";
import { PersonActivity } from "@/components/person_activity";
import { Sidebar } from "@/components/sidebar";
import type { PaginationParams } from "@/http_services/types";
import type { PersonPayload } from "@/entities/Person/form";

interface PropsType {
  visible: boolean
}

const props = defineProps<PropsType>();
const emit = defineEmits(["close:deails"]);

const { t } = useI18n();
const store = useStore();
const onCloseDetails: any = inject("onCloseDetails");
const person = inject("selectedPerson") as Ref<Person>;
const candidates = inject("candidates") as Ref<Person[]>;
const visible = toRef(props, "visible");
const editing = ref("");
const hasSubmitionError = ref(false);

const paginationParams = ref<PaginationParams>({
  limit: 30,
  skip: 0,
  sort: "updated_at:desc",
});

const totalProjectsParticipated = ref(0);

const participatedProjects = ref<ProjectsParticipated[]>([]);

const stopFetchProjectsParticipated = ref(false);

const personPayload: Ref<PersonPayload | null> = ref(null);
watch(
  person,
  (value) => {
    if (value) {
      personPayload.value = value;
      !participatedProjects.value.length && fetchPersonProjectsParticipated(value.id);
    }
  },
  { immediate: true },
);
provide("personPayload", personPayload);

function sidebarClose() {
  onCloseDetails();
  resetPagination();
  editing.value = "";
  participatedProjects.value = [];
  stopFetchProjectsParticipated.value = false;
}

function toggleEdit(type: string) {
  editing.value = type;
}

function updateCandidates(updated_person: Person) {
  if (candidates?.value) {
    candidates.value = candidates.value.map(el =>
      el.id === person.value.id ? { ...updated_person } : el,
    );
  }
}

const v = useVuelidate();

async function onSubmitPerson(data: PersonPayload) {
  hasSubmitionError.value = false;
  const isValid = await v.value.$validate();
  if (isValid) {
    try {
      personPayload.value = { ...personPayload.value, ...data };
      store.commit("SET_FULL_PAGE_LOADING_MESSAGE", "Loading");
      const updated_person = await updatePerson(person.value, {
        person: personPayload.value,
      });
      person.value = updated_person;
      updateCandidates(person.value);
      editing.value = "";
    }
    catch (error) {
      hasSubmitionError.value = true;
    }
    finally {
      store.commit("SET_FULL_PAGE_LOADING_MESSAGE");
    }
  }
  else {
    const errors = document.querySelectorAll(".error");

    await nextTick();

    if (errors && errors[0]) {
      errors[0].scrollIntoView({
        behavior: "smooth",
        inline: "start",
      });
    }
  }
}

async function retry() {
  if (personPayload.value)
    await onSubmitPerson(personPayload.value);
}

const isFilled = computed(() => {
  const {
    description,
    work_experience,
    languages,
    education,
    certifications,
    projects,
    publications,
    volunteering,
  } = person.value;

  const summary = !!description;
  const workExperience = !!work_experience.length;
  const learning = !!(languages.length || education.length || certifications.length);
  const extraActivities = !!(
    projects.length
        || publications.length
        || volunteering.length
  );

  return {
    summary,
    workExperience,
    learning,
    extraActivities,
  };
});

function yearFor(date: string) {
  if (!date)
    return t("persons.personCard.currentYear");

  return moment(date).year();
}

provide("toggleEdit", toggleEdit);
provide("onSubmitPerson", onSubmitPerson);
provide("editing", editing);
provide("yearFor", yearFor);
provide("visible", visible);

function mapPersonProjectsParticipated(activityProjectsParticipated: ActivityProjectsParticipated[]): ProjectsParticipated[] {
  if (!activityProjectsParticipated)
    return [];

  return activityProjectsParticipated.map(activity => ({
    id: activity._id,
    project_identifier: "projeto".toUpperCase().concat(` ${activity.serial}`),
    project_name: activity.project_name,
    corporation_id: activity.corporation_id,
    corporation_name: activity.corporation_name,
    stage: t(`projects.generic.stages.enum.${activity.candidate_project_stage.toLowerCase()}`),
    project_stage: activity.candidate_project_stage.toLowerCase(),
  }));
}

function setPersonProjectsParticipated(projectsParticipated: ProjectsParticipated[]) {
  if (!participatedProjects.value.length) {
    participatedProjects.value.push(...projectsParticipated);
  }
  else {
    const uniqParticipatedProjects = uniqBy(
      [...participatedProjects.value, ...projectsParticipated],
      "_id",
    );
    participatedProjects.value = uniqParticipatedProjects;
  }
}

async function fetchPersonProjectsParticipated(person_id: string) {
  try {
    if (
      personPayload.value?.id === undefined
          || stopFetchProjectsParticipated.value
    )
      return;

    const { data, total } = await getPersonProjectsParticipated(
      paginationParams.value,
      person_id,
    );

    validateFetchPersonProjectsParticipated(data);

    totalProjectsParticipated.value = total;

    pipe(mapPersonProjectsParticipated, setPersonProjectsParticipated)(data);
  }
  catch (error) {}
}

function hanldeOnMore() {
  if (paginationParams.value.skip === undefined)
    return;

  paginationParams.value.skip += paginationParams.value.limit ?? 0;
  fetchPersonProjectsParticipated(person.value.id);
}

function validateFetchPersonProjectsParticipated(activityProjectsParticipated: ActivityProjectsParticipated[]) {
  const isPersonMissing = personPayload.value?.id === undefined;

  const hasNoActivityProjectsParticipated = !activityProjectsParticipated?.length;
  const shouldSkipPagination
        = paginationParams.value.skip && paginationParams.value.skip > 0;

  if (
    isPersonMissing
        || (!hasNoActivityProjectsParticipated && !shouldSkipPagination)
  )
    return;

  stopFetchProjectsParticipated.value = true;
}

function resetPagination() {
  paginationParams.value = {
    limit: 4,
    skip: 0,
    sort: [],
  };
}

async function getPersonProjectsOnAddProject() {
  const { data } = await getPersonProjectsParticipated(
    paginationParams.value,
    person.value.id,
  );
  participatedProjects.value = mapPersonProjectsParticipated(data);
}
const debounceFetchProjects = useDebounceFn(() => getPersonProjectsOnAddProject(), 200);
</script>

<template>
  <Sidebar
    :on-close="() => { sidebarClose(); $emit('close:deails') }"
    :visible="visible"
    :prevent-close-outside="hasSubmitionError || Boolean(editing)"
  >
    <!-- TODO: import editable async -->
    <div class="sidebar-content">
      <Header class="header" @update:project="debounceFetchProjects()" />

      <div class="person-activity__container">
        <PersonActivity :projects="participatedProjects" @on-more="hanldeOnMore" />
      </div>

      <h2 class="section-title">
        {{ t("persons.details.summary") }}
        <a href="#">
          <EditIcon
            v-show="editing !== 'summary' && isFilled.summary"
            class="edit-icon"
            @click="
              () => {
                toggleEdit('summary');
              }
            "
          />
        </a>
      </h2>

      <template v-if="editing !== 'summary'">
        <p v-if="!isFilled.summary" class="attribute-empty">
          {{ t("persons.attributeEmpty") }}
          <span role="button" @click="toggleEdit('summary')">
            {{ t("persons.addAttribute") }}
          </span>
        </p>

        <Summary v-else />
      </template>

      <SummaryEditable
        v-if="editing === 'summary'"
        :on-cancel="() => toggleEdit(null)"
        :on-submit="onSubmitPerson"
      />

      <h2 class="section-title">
        {{ t("persons.details.workExperience") }}
        <EditIcon
          v-show="editing !== 'work-experience' && isFilled.workExperience"
          class="edit-icon"
          @click="() => toggleEdit('work-experience')"
        />
      </h2>

      <template v-if="editing !== 'work-experience'">
        <p v-if="!isFilled.workExperience" class="attribute-empty">
          {{ t("persons.attributeEmpty") }}
          <span role="button" @click="toggleEdit('work-experience')">
            {{ t("persons.addAttribute") }}
          </span>
        </p>

        <WorkExperiences v-else :work-experiences="person.work_experience" />
      </template>

      <WorkExperienceEditable
        v-if="editing === 'work-experience'"
        :on-submit="onSubmitPerson"
        :on-cancel="() => toggleEdit(null)"
      />

      <h2 class="section-title">
        {{ t("persons.details.academicFormation") }}
        <EditIcon
          v-show="editing !== 'academic-formation' && isFilled.learning"
          class="edit-icon"
          @click="() => toggleEdit('academic-formation')"
        />
      </h2>

      <template v-if="editing !== 'academic-formation'">
        <p v-if="!isFilled.learning" class="attribute-empty">
          {{ t("persons.attributeEmpty") }}
          <span role="button" @click="toggleEdit('academic-formation')">
            {{ t("persons.addAttribute") }}
          </span>
        </p>

        <template v-else>
          <AcademicFormation />
          <Certifications />
          <Language />
        </template>
      </template>

      <AcademicFormationEditable
        v-if="editing === 'academic-formation'"
        :on-submit="onSubmitPerson"
        :on-cancel="() => toggleEdit(null)"
      />

      <h2 class="section-title">
        {{ t("persons.details.extraActivities") }}
        <EditIcon
          v-show="editing !== 'extra-activities' && isFilled.extraActivities"
          class="edit-icon"
          @click="() => toggleEdit('extra-activities')"
        />
      </h2>

      <template v-if="editing !== 'extra-activities'">
        <p v-if="!isFilled.extraActivities" class="attribute-empty">
          {{ t("persons.attributeEmpty") }}
          <span role="button" @click="toggleEdit('extra-activities')">
            {{ t("persons.addAttribute") }}
          </span>
        </p>

        <ExtraActivities v-else />
      </template>

      <ExtraActivitiesEditable
        v-if="editing === 'extra-activities'"
        :on-submit="onSubmitPerson"
        :on-cancel="() => toggleEdit(null)"
      />
    </div>

    <Modal
      v-if="hasSubmitionError"
      :title="t('persons.createPersonForm.modal.submitFail.title')"
      :message="t('persons.createPersonForm.modal.submitFail.message')"
      :button-text="t('persons.createPersonForm.modal.submitFail.button')"
      :on-click="retry"
      :on-close="() => (hasSubmitionError = false)"
      variation="warning"
      secondary-variation="light"
    >
      <template #titleIcon>
        <WarningIcon />
      </template>
    </Modal>
  </Sidebar>
</template>

<style scoped="true" lang="scss">
.person-activity__container {
  max-width: 50rem;
  margin-top: 2rem;

  @media (min-width: 1920px) {
    max-width: 100%;
  }
}

.sidebar-content {
  max-width: 100%;
  height: max-content;

  .section-title {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    margin-bottom: 2rem;
    color: $primary;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .header {
    .icon {
      margin-top: initial;
    }
  }

  .edit-icon {
    position: relative;
    top: 4px;
    width: 14px;
    margin-left: 0.3rem;
    cursor: pointer;
    stroke: $gray-border;
  }
}

.container {
  width: 570px;
}

.extra-activities:last-child {
  margin-bottom: 5px;
}

.extra-activities-group {
  display: flex;

  .extra-activities {
    padding: 15px 0;
  }

  .editable-forms {
    margin-right: auto;
  }

  .edit-actions {
    display: flex;
    flex-direction: column;

    button:nth-of-type(1) {
      &:hover {
        background: $yellow-dark;
        color: #fff;
      }
    }
  }

  .remove-wrapper {
    display: flex;
    height: 35px;
    justify-content: flex-end;

    svg {
      height: 18px;
      stroke: $gray-light;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.edit-icon {
  position: relative;
  top: 4px;
  width: 14px;
  margin-left: 0.3rem;
  stroke: $gray-border;
}

.attribute-empty {
  color: #707070;
  font-size: 0.875rem;

  span {
    color: $primary;
    cursor: pointer;
    font-weight: bold;
  }
}
</style>
