export const modals = {
  personRestore: {
    title: "Attention",
    description: "Do you want to restore the original version?",
  },
  conflictDate: {
    title: "Attention",
    message: "The end date cannot be earlier than the start date!",
    button: "Close",
  },
};
