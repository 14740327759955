<script scoped lang="ts">
import type { Ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { debounce, find } from "lodash";
import CorporationsList from "./CorporationsList.vue";
import { Sidebar } from "@/components/sidebar";
import { FormInput } from "@/components/inputs/modalInputs";
import { Button as ButtonText } from "@/components/button";
import { Loading } from "@/components/loading";
import { searchCorporation } from "@/http_services/entities/corporation";
import type { Corporation } from "@/entities/corporation";

export default defineComponent({
  name: "CheckConflict",
  components: {
    Sidebar,
    ButtonText,
    FormInput,
    Loading,
    CorporationsList,
  },
  props: {
    visible: Boolean,
    onClose: Function,
  },
  emits: ["close"],
  setup(props) {
    const { t } = useI18n();
    const router = useRouter();
    const corporationName = ref("");
    const corporationDomain = ref("");
    const total = ref(0);
    const skip = ref(0);
    const exist = ref(true);
    const corporations = ref([]) as Ref<Corporation[]>;
    const selectedCorporation = ref<Corporation | null>(null);
    const searchedResult = ref(false);
    const loading = ref(false);
    const onClose = toRef(props, "onClose") as Ref<() => void>;
    const onClickDetails = inject("onClickDetails") as (corporation: Corporation) => void;

    const doSearch = debounce(async () => {
      if (!corporationName.value && !corporationDomain.value) {
        corporations.value = [];
        searchedResult.value = false;
        loading.value = false;
        return;
      }

      if (searchedResult.value && corporations.value.length === total.value) {
        loading.value = false;
        return;
      }

      const replaceUrlHttp = (value: string) => {
        if (value)
          return value.replace(/.+\/\/|www.|\..+/g, "");
      };
      corporationName.value = corporationName.value.trim();
      const params = {
        limit: 10,
        skip: skip.value,
        name: corporationName.value,
        url: replaceUrlHttp(corporationDomain.value),
      };
      const paramsURL = {
        skip: skip.value,
        url: replaceUrlHttp(corporationDomain.value),
      };
      const { total: totalFound, data } = await searchCorporation(params);
      const { data: SearchingUrl } = await searchCorporation(paramsURL);
      corporations.value = [...corporations.value, ...data];

      const allCorporationByUrl = SearchingUrl.map(e => replaceUrlHttp(e.url));
      function compareWithList() {
        for (const item of allCorporationByUrl) {
          if (item === replaceUrlHttp(corporationDomain.value))
            return true;
        }
        return false;
      }
      searchedResult.value = true;

      if (
        corporationName.value.length > 0
        && corporationName.value === find(corporations.value, { name: corporationName.value })?.name
      )
        exist.value = false;
      else if (corporationDomain.value.length > 0 && compareWithList())
        exist.value = false;
      else exist.value = true;

      skip.value = corporations.value.length;
      total.value = totalFound;
      loading.value = false;
    }, 1000);

    const resetParams = () => {
      selectedCorporation.value = null;
      skip.value = 0;
      total.value = 0;
      corporations.value = [];
      loading.value = false;
      searchedResult.value = false;
    };

    const newSearch = (event: KeyboardEvent) => {
      const key = event.keyCode || event.charCode;

      // 48 ... 90 - letters and numbers
      // 96 ... 105 - numbers in numpad
      // 8 - backspace
      // 13 - enter
      if ((key >= 48 && key <= 90) || key === 8 || key === 13 || (key >= 96 && key <= 105)) {
        resetParams();
        loading.value = true;

        doSearch();
      }
    };

    const onCorporationClick = (corporation: Corporation) => {
      selectedCorporation.value = corporation;
    };

    const editCorporation = () => {
      if (selectedCorporation.value)
        onClickDetails(selectedCorporation.value);
    };

    const newAnalysis = () => {
      corporations.value = [];
      selectedCorporation.value = null;
      total.value = corporations.value.length;
      skip.value = corporations.value.length;
      searchedResult.value = false;
      corporationName.value = "";
      corporationDomain.value = "";
    };

    const encondeURI = (str: string) => encodeURIComponent(str).replace(/[!'()*]/g, char => `%${char.charCodeAt(0).toString(16)}`);

    const createNewCorporation = () => {
      const query: { name?: string; domain?: string } = {};

      let queryParams = "?";
      if (corporationName.value)
        queryParams += `name=${corporationName.value}`;

      if (corporationDomain.value) {
        if (queryParams.length > 1)
          queryParams += "&";

        queryParams += `domain=${corporationDomain.value}`;
      }

      resetAndClose();

      router.push(`/corporations/create${queryParams}`);
    };

    function resetAndClose() {
      resetParams();
      onClose.value();
    };

    return {
      t,
      corporationName,
      corporationDomain,
      corporations,
      selectedCorporation,
      total,
      searchedResult,
      loading,
      doSearch,
      exist,
      newSearch,
      newAnalysis,
      onCorporationClick,
      editCorporation,
      createNewCorporation,
      resetAndClose,
    };
  },
});
</script>

<template>
  <Sidebar class="sidebar" :on-close="resetAndClose" :visible="visible">
    <h2>{{ t("corporations.checkConflict.createCorporation") }}</h2>

    <div class="input-group">
      <FormInput
        v-model="corporationName"
        :placeholder="t('corporations.name')"
        :on-key-down="newSearch"
      />
      <FormInput
        v-model="corporationDomain"
        :placeholder="t('corporations.domain')"
        :on-key-down="newSearch"
      />

      <p v-if="total && searchedResult && !loading" class="response-text">
        {{ t("corporations.checkConflict.corporationsFound", total) }}
      </p>
    </div>

    <Loading v-if="loading" class="loader" />

    <template v-else>
      <CorporationsList
        v-if="total"
        :corporations="corporations"
        :load-more-data="doSearch"
        @click:corporation="onCorporationClick($event)"
      />

      <div v-if="selectedCorporation" class="action-group">
        <div class="rework-actions">
          <ButtonText
            :text="t('corporations.checkConflict.linkAndEdit')"
            :filled="false"
            variation="warning"
            :on-click="editCorporation"
          />
          <ButtonText
            :text="t('corporations.checkConflict.newAnalysis')"
            :filled="false"
            variation="regular"
            :on-click="newAnalysis"
          />
        </div>
      </div>

      <div v-if="total" class="continue-action">
        <ButtonText
          v-if="exist"
          :text="t('corporations.checkConflict.createNewCorporation')"
          :filled="false"
          variation="warning"
          :on-click="createNewCorporation"
        />
      </div>

      <div v-else-if="searchedResult" class="not-found">
        <p class="response-text">
          {{ t("corporations.checkConflict.corporationsFound", total) }}
        </p>

        <div class="continue-action">
          <ButtonText
            v-if="exist"
            :text="t('corporations.checkConflict.createNewCorporation')"
            :filled="false"
            variation="warning"
            :on-click="createNewCorporation"
          />
        </div>
      </div>
    </template>
  </Sidebar>
</template>

<style lang="scss" scoped>
.sidebar {
  &:deep .sidebar-wrapper {
    width: 500px;
    min-width: initial;
  }
}

.check-content {
  width: 400px;
}

h2 {
  color: $primary;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}

.rework-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 4rem;

  button:first-child {
    margin-right: 2rem;
  }
}

.continue-action {
  margin-top: 1rem;

  .exist {
    display: grid;
    place-items: center;
  }
}

.input-group {
  min-width: 350px;
  margin-top: 2rem;

  .input {
    width: 100%;
  }
}

.response-text {
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: $gray-dark;
  font-size: 13px;
  text-align: center;
}

.not-found {
  .response-text {
    margin-top: 0;
    font-size: 10px;
  }
}

.not-found-action {
  width: 45%;
  margin-right: auto;
  margin-left: auto;
}

.loader {
  display: flex;
  align-self: center;
}
</style>
