<script lang="ts">
import type { Ref } from "vue";
import { useI18n } from "vue-i18n";
import type { Person } from "@/components/person_card/interfaces";
import { TextExpand } from "@/components/text";

export default defineComponent({
  components: {
    TextExpand,
  },
  props: {
    editing: String,
    onSubmit: Function,
    onCancel: Function,
  },
  setup() {
    const { t } = useI18n();
    const person = inject("selectedPerson") as Ref<Person>;

    return {
      t,
      person,
    };
  },
});
</script>

<template>
  <div class="summary-content">
    <TextExpand class="expandable" :text="person.description" />
  </div>
</template>

<style scoped="true" lang="scss">
.summary-content {
  :deep(.expandable) {
    p {
      color: $gray;
      font-size: 0.9rem;
      line-height: 1rem;
    }
  }
}
</style>
