import { createApp } from "vue";
import PrimeVue from "primevue/config";
import Maska from "maska";
import "primevue/resources/primevue.min.css";
import "primevue/resources/themes/saga-blue/theme.css";
import "primeicons/primeicons.css";
import "virtual:uno.css";

import { createHead } from "@vueuse/head";
import { createRouter, createWebHistory } from "vue-router";
import type { VueQueryPluginOptions } from "@tanstack/vue-query";
import { VueQueryPlugin } from "@tanstack/vue-query";
import generatedRoutes from "virtual:generated-pages";
import { setupLayouts } from "virtual:generated-layouts";
import { vfmPlugin } from "vue-final-modal";
import { BrowserTracing } from "@sentry/browser";
import posthog from "posthog-js";
import * as Sentry from "@sentry/vue";
import { getLocale } from "./locales/primevue";
import { i18n } from "./locales/i18n";
import store from "./store";
import App from "./App.vue";
import GTAG from "./plugins/gtag";
import { env } from "./env";

const vueQueryPluginOptions: VueQueryPluginOptions = {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: true,
        retry: 1,
        // staleTime: 1000 * 30, // mininum amount of time before a query is considered stale in ms
        staleTime: 1000 * 10,
      },
    },
  },
};
const app = createApp(App);

// setup up pages with layouts
const routes = setupLayouts(generatedRoutes);
const router = createRouter({ history: createWebHistory(), routes });
const head = createHead();

// entrypoint mounts
app
  .use(store)
  .use(i18n)
  .use(head)
  .use(router)
  .use(VueQueryPlugin, vueQueryPluginOptions)
  .use(vfmPlugin({
    key: "$vfm",
    componentName: "VueFinalModal",
    dynamicContainerName: "ModalsContainer",
  }))
  .use(PrimeVue, { locale: getLocale(i18n.global.locale.value) })
  .use(Maska)
  .use(GTAG, { gtagId: env.VITE_APP_GTAG_ID! })
  .mount("#app");

/** Monitoring */
function sentrySetup() {
  if (env.VITE_APP_SENTRY_DSN) {
    Sentry.init({
      app,
      dsn: env.VITE_APP_SENTRY_DSN,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: ["localhost", /^\//],
        }),
      ],
      beforeBreadcrumb(crumb) {
        if (crumb.category === "console") {
          // hint.input === raw arguments passed to console calls
          // do anything to them, parse, stringify, whatever

          // Clearing data for now because it can be too large.
          crumb.data = null;
        }
        return crumb;
      },
      debug: false,
      environment: import.meta.env.MODE ?? "development",
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.1,
      trackComponents: true,
      attachProps: true,
    });
  }
}

function posthogSetup() {
  if (env.VITE_APP_POSTHOG_API_KEY)
    posthog.init(env.VITE_APP_POSTHOG_API_KEY, { api_host: "https://app.posthog.com" });
}

function setup() {
  sentrySetup();
  posthogSetup();
}

setup();
