<script setup lang="ts">
import ActivitiesSchedule from "./ActivitiesSchedule.vue";

const props = withDefaults(
  defineProps<{
    schedules: any
    stages: any
    bullet?: boolean
    isExport: boolean
  }>(),
  {
    bullet: true,
    isExport: false,
  },
);

const { t } = useI18n();

const { isExport, schedules, stages } = toRefs(props);

const showSchedule = ref(false);

watchEffect(() => {
  if (schedules.value)
    showSchedule.value = true;
});
</script>

<template>
  <ExportExportPage
    :is-export="isExport"
    :page-name="t('exportPDF.summaryConstructionBeta.schedule')"
    :opinion="null"
  >
    <div class="pdf-timeline">
      <ActivitiesSchedule
        v-if="showSchedule"
        is-export
        :schedules="schedules"
        :stages="stages"
        stage="construction"
      />
    </div>
  </ExportExportPage>
</template>

<style lang="scss" scoped>
:deep(.activitie-schedule) {
  padding: 0;

  .schedules {
    padding-bottom: 0.35rem;

    .stages {
      height: 4.6rem;
      margin-bottom: 0.7rem;
    }
  }
}

.pdf-timeline {
  display: flex;
  height: 171mm;
  flex-direction: column;
}
</style>
