<script setup lang="ts">
import { TransitionGroup, useCssModule } from "vue";
import { mdiClose as close } from "@mdi/js";
import { SvgIcon } from "@/components/icon";
import ListItem from "@/components/text/ListItem.vue";
import type { searchTagSchema } from "@/schemas/project";

const props = defineProps<{
  tags: { text: string; selected: boolean; _id: string }[]
  title: string
  isCorporationProfile?: boolean
  placeholder?: string
}>();
const emit = defineEmits(["update-tag-list"]);
const styles = useCssModule();
interface Tag {
  text: string
  selected: boolean
}
const tags: Ref<Tag[]> = ref(props.tags);
const newTag: Ref<EventTarget | string> = ref("");
const showDropdown = ref(false);
const listTags: Ref<searchTagSchema[]> = ref([]);
const optionsRef = ref();
watchEffect(() => tags.value = props.tags);
const { t } = useI18n();

function updateValue() {
  emit("update-tag-list", tags.value);
}
const paginationParams = ref({
  tags_text: newTag ?? "",
  skip: 0,
  limit: 10,
}) as Ref<{ tags_text: string; skip: number; limit: number }>;

async function searchTags() {
  const data = await getTagsSearchQuery(paginationParams.value);
  listTags.value = data;
  return listTags.value;
}
async function onClickInput() {
  await searchTags();
  showDropdown.value = true;
}
const tagDescriptions = computed(() => {
  return {
    decs: t(
      "projects.details.activities.constructionPanel.forms.tags.idealProfile.tagDesciption[0]",
    ),
    add: t(
      "projects.details.activities.constructionPanel.forms.tags.idealProfile.tagDesciption[1]",
    ),
    remove: t(
      "projects.details.activities.constructionPanel.forms.tags.idealProfile.tagDesciption[2]",
    ),
  };
});
const placeholder = computed(() =>
  t("projects.details.activities.constructionPanel.forms.tags.idealProfile.placeholder", {
    Title: props.placeholder ?? "",
  }));
function Title() {
  return h(props.isCorporationProfile ? "h4" : "h3", props.title);
}
function TagTitle() {
  return h(SvgIcon, {
    class: "margin-icon",
    icon: "feather_tag",
    width: "0.8125rem",
    height: "0.8125rem",
    margin: "none",
  });
}
function closeTitle() {
  return h(SvgIcon, {
    class: "margin-icon",
    type: "mdi",
    icon: close,
    width: "0.8125rem",
    height: "0.8125rem",
    margin: "none",
  });
}
function renderTag(tag: Tag, index: number): VNode {
  const tagClass: string[] = [String(styles.tag), !tag.selected ? String(styles.selected) : ""];
  const iconTagClass: string[] = [String(styles["icon-tag"]), !tag.selected ? String(styles["icon-selected"]) : ""];
  const closeClass = "close";

  const onTagClick = () => {
    tag.selected = !tag.selected;
  };

  const onCloseClick = (event: MouseEvent) => {
    event.stopPropagation();
    tags.value.splice(index, 1);
  };

  return h("div", { class: tagClass, key: index, onClick: onTagClick }, [
    h(SvgIcon, {
      class: iconTagClass,
      icon: "feather_tag",
      width: "0.8125rem",
      height: "0.8125rem",
    }),
    h("span", {}, tag.text),
    h(SvgIcon, {
      class: closeClass,
      type: "mdi",
      icon: close,
      width: "1rem",
      height: "1rem",
      margin: "none",
      onClick: onCloseClick,
    }),
  ]);
}

function GroupTags(): VNode {
  return h("div", { class: styles.tags }, [
    h(
      TransitionGroup,
      {
        name: "tag",
        onBeforeEnter: async () => await searchTags(),
        onVnodeUpdated: () => updateValue(),
      },
      () => tags.value.map((tag, index) => renderTag(tag, index)),
    ),
  ]);
}

function addTag(event: string) {
  tags.value
    = [
      ...tags.value,
      {
        text: event,
        selected: true,
      },
    ];
  newTag.value = "";
  showDropdown.value = false;
}

async function handleScroll(event: Event) {
  const {
    scrollTop: hiddenHeight,
    scrollHeight: totalHeight,
    clientHeight: totalVisible,
  } = event.target as HTMLElement;
  const bottomReached = Math.round(hiddenHeight) + Math.round(totalVisible) + 1 >= totalHeight;
  if (bottomReached) {
    paginationParams.value.skip = paginationParams.value.skip + 10;
    await searchTags();
  }
}
onMounted(() => tags.value = toRaw(props.tags));
onClickOutside(optionsRef, () => (showDropdown.value = false));
</script>

<template>
  <div class="tagContainer">
    <Title />
    <ListItem mb="0 5px" font-size="0.875rem">
      <span class="tag-title"> {{ tagDescriptions.decs }}
        <TagTitle /> {{ tagDescriptions.add }}
      </span>
    </ListItem>
    <ListItem font-size="0.875rem">
      <span class="tag-title">{{ tagDescriptions.decs }}
        <closeTitle /> {{ tagDescriptions.remove }}
      </span>
    </ListItem>
    <div ref="optionsRef" class="containerInput">
      <input
        v-model="newTag"
        :class="[{ 'no-border': showDropdown }]"
        type="text"
        :placeholder="placeholder"
        @keyup="searchTags()"
        @keyup.enter="addTag(newTag as string)"
        @click="onClickInput"
      >
      <div v-show="showDropdown" class="dropdown-menu-tag" @scroll="handleScroll">
        <TransitionGroup name="list">
          <div
            v-for="(option, index) in listTags"
            :key="index"
            class="dropdown-item"
            @click="addTag(option.text), (showDropdown = false)"
          >
            {{ option.text }}
          </div>
        </TransitionGroup>
      </div>
    </div>
    <GroupTags />
  </div>
</template>

<style scoped lang="scss">
.tagContainer {
  display: flex;
  flex-direction: column;
  line-height: 1.2;

  h3 {
    margin-block-end: 1rem;
    margin-block-start: 1rem;
  }

  h4 {
    font-weight: bold;
    margin-block-end: 0.37rem;
  }

.tag-title {
  display: flex;
  gap: 0.25rem;
  line-height: 1.5rem;
}
  .margin-icon {
    margin-inline: 0.125rem;
    margin-block-start: 0.25rem;
  }

  .containerInput {
    position: relative;
    width: 100%;
  }

  input {
    position: relative;
    z-index: 13;
    width: 100%;
    padding: 0.3438rem 0.7638rem;
    border: 1px solid #c0c0c0;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    line-height: 1.1875rem;
    margin-block: 1.25rem 0;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #c6c6c6;
      opacity: 1;
    }
  }
}

.tag-enter-active .tag-leave-active,
.list-enter-active,
.list-leave-active {
  transition: all 200ms ease-in-out;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateY(-2rem);
}

.tag-enter-from,
.tag-leave-to {
  opacity: 0;
  transform: translateX(2rem);
}

.dropdown-menu-tag {
  position: absolute;
  z-index: 12;
  top: 2.875rem;
  overflow: hidden;
  width: 100%;
  max-height: 12rem;
  background-color: white;
  border-block-end: 20px white solid;
  border-radius: 0.375rem;
  box-shadow: 0 2px 4px #00000029;
  cursor: pointer;

  &:hover {
    overflow-y: scroll;
  }

  &::-webkit-scrollbar {
    width: 1rem;
    background-color: transparent;
    border-block-start: 20px;
  }

  &::-webkit-scrollbar-thumb {
    min-height: 4rem;
    box-sizing: padding-box;
    border: 0.4rem solid transparent;
    background-clip: padding-box;
    background-color: #c6c6c6;
    border-radius: 1rem;
  }

  &::-webkit-scrollbar-track {
    margin-block: 4px;
  }

  .dropdown-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #c6c6c6;
    font-size: 0.875rem;
    line-height: 1.1875rem;
    padding-block: 0.3125rem;
    padding-inline: 1rem;
    transition: 100ms;

    &:hover {
      color: $red-violet;
    }

    &.dropdown-item:first-child {
      padding-block-start: calc(0.5rem + 0.3125rem);
    }
  }
}
</style>

<style lang="scss" module>
  .tags {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    gap: 0.7712rem;
    margin-block-start: 0.625rem;

    .tag {
      display: flex;
      background: #fff;
      border-radius: 0.375rem;
      box-shadow: 0 0.125rem 0.25rem #00000029;
      cursor: pointer;
      gap: 0.23rem;
      padding-block: 0.3375rem;
      padding-inline: 0.5225rem;
      transition: 300ms;
      height: fit-content;

      span {
        max-width: 30.5rem;
        line-break: anywhere;
      }

      &.selected {
        background: #dedede;
        color: #818181;
      }

      .close {
        cursor: pointer;
      }

      svg,
      .close,
      .icon-selected {
        flex-shrink: 0;
        margin-block-start: 0.15rem;
      }

      .icon-selected g path {
        stroke: #818181;
      }
    }
  }
</style>
