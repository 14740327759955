<script setup lang="ts">
import { SvgIcon } from "@/components/icon";

const props = defineProps<{
  open: boolean
  closed?: boolean
  clickable?: boolean
  title?: string
  mode?: string
  id?: string
  icon?: string | null
}>();

const emit = defineEmits(["close-all", "selected"]);

const defaultClickable = ref(props.clickable || true);
const defaultOpen = ref(props.open || false);
// const defaultMode = ref(props.mode || "horizontal");

const opened = ref(defaultOpen.value);

watch(
  () => props.closed,
  () => {
    if (props.closed) {
      opened.value = props.open;
      emit("close-all", false);
    }
  },
);
watch(
  () => props.open,
  () => {
    opened.value = props.open;
  },
);

function toggleAccordeon() {
  emit("close-all", true);
  if (defaultClickable.value) {
    opened.value = !opened.value;
    emit("selected", opened.value);
  }
}
</script>

<template>
  <div class="box-accordeon">
    <div :id="id" class="header" @click="toggleAccordeon()">
      <div class="title" :class="{ active: opened }">
        <div v-if="icon" class="icon">
          <SvgIcon :icon="icon" width="1.5rem" height="1.5rem" />
        </div>
        <div>
          <p v-html="props.title" />
        </div>
      </div>
      <div class="icon-container">
        <SvgIcon
          icon="chevron_icon"
          margin="none"
          width="20"
          height="20"
          :rotate="opened ? '0deg' : '-180deg'"
        />
      </div>
    </div>
    <Transition>
      <KeepAlive>
        <div v-if="opened" class="body">
          <slot />
        </div>
      </KeepAlive>
    </Transition>
  </div>
</template>

<style lang="scss" scoped>
.box-accordeon {
  display: grid;

  .header {
    display: flex;
    justify-content: space-between;
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 1.75rem;
    text-transform: uppercase;
    align-items: center;
    cursor: pointer;
  }

  .body {
    padding-right: 0;
    padding-inline-start: 0;
    word-break: break-all;
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 100ms ease-out;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.title > p {
  color: $gray-dark;
  user-select: none;
}
.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon {
  display: flex;
  margin-right: 0.25rem;
}
.title {
  display: flex;
  align-items: center;
}
</style>
