<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import BusinessTag from "./BusinessTag.vue";
import { Modal } from "@/components/modal";
import { SearchDropdown } from "@/components/inputs/modalInputs";
import { Tag } from "@/components/tag";
import { searchBusinessFunctions } from "@/http_services/entities/common";
import type { RelatedCorporation } from "@/entities/common";
import store from "@/store";

interface BusinessFunction {
  tag: string
  label?: string
  label_nickname?: string | null
  corporations: RelatedCorporation[]
  _id: string
}
const props = defineProps<{ id?: string; project?: object; businessFunctions: object | undefined }>();
const { capture } = useTracking();

const {
  mutate: createBusinessFunction,
} = useCreateBusinessFunctionQuery(String(props.id));

const {
  mutate: deleteBusinessFunction,
} = useRemoveBusinessFunctionQuery();
const {
  data: initialBusinessFunctions,
  // refetch: refetchBusinessFunctions,
} = useBusinessFunctionsQuery(String(props.id));
const { t, tm } = useI18n();
const tagSearcher = ref(null);
const conclaOptions = Object(tm("concla"));

const isAddingBusinessFunction = ref(false);
// TODO: remove this variable
const localBusinessFunctions = ref<BusinessFunction[]>();

const { data } = useSearchField(String(String(props.id)));

watchEffect(() => {
  localBusinessFunctions.value = (initialBusinessFunctions.value || [])?.map((item: BusinessFunction) => {
    return {
      tag: item.tag,
      label: item.label_nickname || conclaOptions[item.tag] || item.tag,
      label_nickname: item.label_nickname,
      corporations: item.corporations || [],
      _id: item._id,
    };
  });
});

function removeBusiness(item: BusinessFunction, index: number) {
  const itemIndex = localBusinessFunctions.value.findIndex((item, i) => i === index);
  if (itemIndex !== -1)
    localBusinessFunctions.value.splice(itemIndex, 1);
  deleteBusinessFunction(item._id);
}

function onCancel() {
  isAddingBusinessFunction.value = false;
  localBusinessFunctions.value = props.businessFunctions;
}

const tempBusinessFunction = ref<any[]>([]);
async function onSave() {
  await Promise.all(tempBusinessFunction.value.map((item) => {
    return createBusinessFunction({
      project_id: props.id,
      tag: item.tag,
    });
  }));
  isAddingBusinessFunction.value = false;
}
async function selectBusiness(item: any) {
  // dont add duplicates
  if (localBusinessFunctions.value.findIndex(i => i.tag === item.key) !== -1)
    return;

  tempBusinessFunction.value = tempBusinessFunction.value.concat([{
    tag: item.key,
    label: item.label,
  }]);

  localBusinessFunctions.value = localBusinessFunctions.value.concat({
    tag: item.key,
    label: item.label,
  });
}

const currentTerm = ref("");
const currentTermResults = ref();
const termsOpen = ref(true);
const showClearTerms = ref(false);
const loadingText = computed(() => t("projects.construction.labels.searchingRelatedTerms"));
const {
  mutateAsync: addSearchField,
} = useSearchFieldMutation(String(props.id));

const LOADING_ANIMATE_TIME = 1500;
function resetLoader() {
  setTimeout(() => {
    currentTerm.value = "";
    store.commit("SET_FULL_PAGE_LOADING_MESSAGE", null);
  }, LOADING_ANIMATE_TIME);
}
async function updateBusinessFunctions() {
  store.commit("SET_FULL_PAGE_LOADING_MESSAGE", loadingText.value);

  await addSearchField(currentTerm.value, {
    onSuccess: (searchResults) => {
      currentTermResults.value = searchResults.value;
      capture("imua-business-function-created", { property: currentTerm.value });
      resetLoader();
    },
    onSettled: () => {
      resetLoader();
    },
  });
}

const {
  mutateAsync: removeSearch,
} = removeSearchMutation(String(props.id));

async function clearAllTerms() {
  showClearTerms.value = false;
  await removeSearch({
    search: null,
    term: null,
  }, {
    onSuccess: () => {
      showClearTerms.value = false;
      capture("imua-business-function-cleared-all", { project_id: props.id });
    },
  });
}
</script>

<template>
  <section class="top">
    <div v-if="isAddingBusinessFunction" class="editing-section">
      <h2 class="subtitle">
        {{ t("projects.details.searchField.addBusinessLine") }}
      </h2>
      <div class="form">
        <div class="search-control">
          <SearchDropdown
            id="tag-searcher"
            ref="tagSearcher"
            fetch-param-key="term"
            :fetch-method="searchBusinessFunctions"
            :select-item-callback="selectBusiness"
            :min-word-length="1"
            :update-input-model="() => ''"
            :input-placeholder="t('text.searchBusinessFunction')"
            :search-not-found-label="t('text.searchBusinessFunctionNotFound')"
            :search-error-label="t('text.searchBusinessFunctionError')"
          >
            <template #list-item="{ item }">
              {{ item.label }}
            </template>
          </SearchDropdown>
        </div>
        <div class="tags">
          <Tag
            v-for="(item, index) in localBusinessFunctions"
            :key="index"
            :tag="conclaOptions[item.tag]"
            @click:close="removeBusiness(item, index)"
          />
        </div>
      </div>
      <div class="actions">
        <div>
          <Button :block="true" variation="secondary" @click="onSave">
            {{
              t("text.form.submit")
            }}
          </Button>

          <Button
            class="cancel-button"
            variation="light"
            filled
            :block="true"
            @click="onCancel"
          >
            {{
              t("text.form.cancel")
            }}
          </Button>
        </div>
      </div>
    </div>
    <div>
      <div>
        <label for="search" class="search-hint-label">
          <!-- TODO: add intl for en -->
          {{ t("projects.details.searchField.searchFieldInputHint") }}
        </label>
      </div>
      <FormInput
        v-model="currentTerm"
        :placeholder="t('projects.details.searchField.termInputPlaceholer')"
        name="search"
        @enter="updateBusinessFunctions()"
      />

      <div v-if="currentTermResults && (currentTermResults.inference_results?.length)">
        {{ t('projects.details.searchField.termNotFound') }}
      </div>
      <div class="search-actions">
        <Button variation="secondary" @click="updateBusinessFunctions()">
          {{ t('projects.details.searchField.search') }}
        </Button>
        <Button variation="gray-light" filled @click="showClearTerms = true">
          {{ t('projects.construction.buttons.clearAll') }}
        </Button>
      </div>

      <div v-if="data" class="search-field-tags inline">
        <BusinessTag
          v-for="(item, index) in Object.keys(data)"
          :key="index"
          :tag="item"
          @click:close="removeSearch({ search: item })"
        />
      </div>
      <BoxAccordion
        v-if="Object.values(data || {}).length"
        :title="termsOpen ? t('projects.details.searchField.hideTermsList') : t('projects.details.searchField.showTermsList')"
        :open="termsOpen"
        class="search-field-accordion"
        @selected="() => termsOpen = !termsOpen"
      >
        <div class="search-field-tags">
          <template v-for="(terms, query) of data">
            <BusinessTag
              v-for="(item) in terms"
              :key="item"
              :tag="conclaOptions[item]"
              :initials="String(query)"
              @click:close="removeSearch({ term: item })"
            />
          </template>
        </div>
      </BoxAccordion>
      <Modal
        v-if="showClearTerms"
        :button-text="t('projects.construction.buttons.confirmRestore')"
        :secondary-button-text="t('projects.construction.buttons.cancelRestore')"
        :on-click="clearAllTerms"
        :secondary-on-click="() => (showClearTerms = false)"
        :on-close="() => (showClearTerms = false)"
        variation="warning"
        secondary-variation="light"
        wrapper-class="clear-modal"
      >
        <template #title>
          <div style="font-size: 1rem; font-weight: 700; text-align: center;">
            {{ t('projects.construction.labels.modalClearTermsTitle').toUpperCase() }}
          </div>
        </template>
        <template #message>
          <div style="text-align: center;">
            <div>{{ t("projects.construction.labels.modalClearTermsContent") }}</div>
            <div>{{ t("projects.construction.labels.modalClearTermsContentComplement") }}</div>
          </div>
        </template>
      </Modal>
    </div>
  </section>
</template>

<style scoped lang="scss">
.top {
  .editing-section {
    display: grid;
    padding-block: 1.5rem;
    grid-template-columns: 1fr 1fr;

    .subtitle {
      color: $primary;
      font-size: 20px;
      grid-column: 1 / 3;
      text-transform: uppercase;
    }

    .form {
      .search-control {
        margin-bottom: 1rem;
      }

      .tags {
        display: flex;
        flex-flow: row wrap;

        .tag {
          margin-right: 0.5rem;
          margin-bottom: 0.5rem;
        }
      }
    }

    .actions {
      display: grid;
      grid-template-columns: 1fr 1.1fr 1fr;

      div {
        grid-column: 2 / 3;

        button:first-child {
          margin-bottom: 1rem;
        }
      }
    }
  }

  .add-section {
    display: flex;
    justify-content: flex-end;
    padding-block: 1.5rem;
  }
}

.button-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<style scoped lang="scss">
.search-field-tags {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1rem 0;
}

:deep(.input-wrapper) {
  height: 40px;
}

:deep(.input-wrapper input) {
  font-size: 1rem;
}

.inline {
  flex-direction: row;
  flex-wrap: wrap;
}

.search-hint-label {
  font-size: 0.75rem;
  display: block;
  padding-block: 0.875rem;
}

.search-actions {
  display: flex;
  justify-content: space-between;
  padding-block: 0.5rem;
  margin-bottom: 2.5rem;

  :deep(.imua-button--gray-light-filled:hover) {
    color: $white-full !important;
  }
}

:deep(.search-field-accordion .header) {
  justify-content: flex-end;
  gap: 1rem;
  border-bottom: 1px solid $gray-border;
}

:deep(.search-field-accordion p) {
  font-size: 1rem;
}

:deep(.modal-container) .modal-content {
  width: 600px;
}
</style>
