<script setup lang="ts">
import FrontConstruction from "@/common/components/Export/FrontConstruction.vue";
import Timeline from "@/common/components/Export/Timeline.vue";
import { CustomCheckBox as Checkbox } from "@/components/checkbox";
import { ConstructionEnum } from "@/types/export";

defineEmits(["cancel"]);
const { t } = useI18n();
const route = useRoute();

const {
  data,
  execute: exportImua,
} = useExport(String(route.params.id));

const searchFieldPageLength = ref(1);
const listHiddePages = ref<string[]>([]);

const positionName = computed(() => {
  return data.value?.project?.confidential_placement
    ? t("exportPDF.confidentialProject")
    : data.value?.project?.positionName;
});

function togglePage(page: ConstructionEnum) {
  if (listHiddePages.value.includes(page))
    listHiddePages.value.splice(listHiddePages.value.indexOf(page), 1);
  else
    listHiddePages.value.push(page);
}

function downloadPDF() {
  exportImua(listHiddePages.value, "construction");
}

function KeepPage({ label }: { label: string }) {
  return h("div", { class: "box-checkbox" }, [
    h(Checkbox, {
      variation: "primary",
      class: "keep-page",
      label: t(`projects.construction.pdf.${label}`),
      checked: !listHiddePages.value.includes(label),
      onOnCheck: _ => togglePage(label as Partial<ConstructionEnum>),
    }),
  ]);
}
</script>

<template>
  <div>
    <div class="flex" :style="{ 'padding-bottom': '2rem' }">
      <div>
        <div class="headline uppercase bold">
          {{ t("exportPDF.constructionTitle") }}
        </div>
        <div class="headline-secondary">
          {{ t("exportPDF.previewSubtitle", { complement: t("exportPDF.inactive") }) }}
        </div>
      </div>
      <div class="actions">
        <Button id="downloadPdfImua" variation="secondary" @click="downloadPDF()">
          {{ t("exportPDF.exportDocument") }}
        </Button>
      </div>
    </div>
    <KeepPage :label="ConstructionEnum.FRONT_COVER" />
    <FrontConstruction
      :id="ConstructionEnum.FRONT_COVER"
      class="page"
      :title="positionName"
      :company-name="data?.project?.companyName"
    />

    <KeepPage :label="ConstructionEnum.SUMMARY" />
    <ExportExportSummary
      class="pages"
      :is-export="false"
      :company_name="data?.project?.companyName"
      :list-hidde-pages="listHiddePages"
    />

    <KeepPage :label="ConstructionEnum.COMPANY" />
    <ExportExportCompany
      :id="ConstructionEnum.COMPANY"
      class="pages"
      :description="data?.project?.company_description"
      :foundation-year="data?.project?.company_foundation_year ?? data?.corporation?.foundation_year"
      :historic="data?.project?.company_historic ?? data?.corporation?.historic"
    />

    <KeepPage :label="ConstructionEnum.GOVERNANCE" />
    <ExportExportAnalysis
      :id="ConstructionEnum.GOVERNANCE"
      class="pages"
      :opinion="data?.governance?.consultant_opinion"
      :analyses="data?.governance?.analyses"
      :page-name="t('exportPDF.summaryConstructionBeta.governance')"
      :log="true"
    />

    <KeepPage :label="ConstructionEnum.STRATEGY" />
    <ExportExportAnalysis
      :id="ConstructionEnum.STRATEGY"
      class="pages"
      :opinion="data?.strategy?.consultant_opinion"
      :analyses="data?.strategy?.analyses"
      :page-name="t('exportPDF.summaryConstructionBeta.strategy')"
    />

    <KeepPage :label="ConstructionEnum.EXECUTION_CONDITIONS" />
    <ExportExportAnalysis
      :id="ConstructionEnum.EXECUTION_CONDITIONS"
      class="pages"
      :opinion="data?.execution_conditions?.consultant_opinion"
      :analyses="data?.execution_conditions?.analyses"
      :page-name="t('exportPDF.summaryConstructionBeta.executionConditions')"
    />

    <KeepPage :label="ConstructionEnum.COMPANY_PROFILE" />
    <ExportExportCompanyProfile
      :id="ConstructionEnum.COMPANY_PROFILE"
      class="pages"
      :is-export="false"
      :corporation-revenues="data?.corporation?.revenues"
      :employees-number="data?.corporation?.number_of_employees"
      :governance-tags="data?.governance_tags"
      :strategy-tags="data?.strategy_tags"
      :execution-conditions-tags="data?.execution_conditions_tags"
    />

    <KeepPage :label="ConstructionEnum.IDEAL_PROFILE_TAGS" />
    <ExportExportIdealProfile
      :id="ConstructionEnum.IDEAL_PROFILE_TAGS"
      :profile="data?.ideal_profile"
    />

    <KeepPage :label="ConstructionEnum.IDEAL_PROFILE" />
    <ExportIdealProfileList
      :id="ConstructionEnum.IDEAL_PROFILE"
      class="pages"
      :ideal-profile="data?.ideal_profile"
    />

    <KeepPage :label="ConstructionEnum.ATTRIBUTES" />
    <ExportExportAttributes
      :id="ConstructionEnum.ATTRIBUTES"
      class="pages"
      :attributes="data?.ideal_profile?.attributes"
    />

    <KeepPage :label="ConstructionEnum.CULTURAL_MATCH" />
    <ExportExportCulturalMatch
      :id="ConstructionEnum.CULTURAL_MATCH"
      class="pages"
      :cultural-match="data?.ideal_profile?.cultural_match"
    />

    <KeepPage :label="ConstructionEnum.MOTIVATION" />
    <ExportExportMotivation
      :id="ConstructionEnum.MOTIVATION"
      class="pages"
      :initial-value="data?.ideal_profile?.consultant_opinion"
      :ideal-profile="data?.ideal_profile"
    />

    <KeepPage :label="ConstructionEnum.SEARCH_FIELD" />
    <ExportSearchField
      :id="ConstructionEnum.SEARCH_FIELD"
      :initial-value="data?.business_functions"
      @load-pages="searchFieldPageLength = $event"
    />

    <KeepPage :label="ConstructionEnum.TIMELINE" />
    <Timeline
      :id="ConstructionEnum.TIMELINE"
      :is-export="false"
      :schedules="data?.schedules"
      :stages="data?.stages"
    />

    <KeepPage :label="ConstructionEnum.BACK_COVER" />
    <ExportBackCover />

    <div class="actions-end">
      <Button variation="secondary" @click="downloadPDF()">
        {{ t("exportPDF.exportDocument") }}
      </Button>
      <Button variation="light" filled @click="$emit('cancel')">
        {{ t("text.form.cancel") }}
      </Button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
// TODO: this can be removed
.export-mode {
  :deep(.timeline-tooltip-icon) {
    display: none;
  }

  :deep(.calendar) * {
    font-size: 1rem;
  }

  :deep(.page) {
    min-width: 297mm;
    max-width: 297mm;
    min-height: 209.5mm;
    max-height: 209.5mm;
    border: none;
  }

  .page {
    width: 100vw;
    aspect-ratio: initial;
  }

  .page + .page {
    margin-top: 0;
  }
}

.page + .page {
  margin-top: 4rem;
}

:deep(.page) {
  width: 100%;
  height: 215mm;
  padding: 2rem;
  border: 1px solid #c0c0c0;
  aspect-ratio: 1.74;

  &.page-borderless {
    padding: 0;
  }
}

@page {
  overflow: hidden;
  min-width: 297mm;
  max-height: 210mm;
  margin: 0;
  size: 297mm 210mm;
}

@media print {
  body {
    overflow: hidden;
    padding: 0;
    border: solid 0 transparent;
    margin: 0;
  }
}

@media print and (color) {
  * {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}

:global(:root) {
  --pdf-width: 297mm;
  --pdf-height: 209.5mm;
}

.export-construction {
  display: flex;
  flex-direction: column;

  .pages {
    display: flex;
    width: var(--pdf-width);
    flex-direction: column;

    &-show {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: flex-end;
    }
  }

  .box-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 2rem;

    &-title {
      font-size: 1.75rem;
      font-weight: 700;
      letter-spacing: 0.8px;
      line-height: 1.5;
      text-transform: uppercase;
    }

    &-subtitle {
      padding-top: 0.5rem;
      font-size: 20px;
    }

    &-actions {
      display: flex;
      height: fit-content;
      flex-wrap: wrap;
      gap: 3.06rem;
    }
  }
}

.flex {
  display: flex;
  justify-content: space-between;
}

.headline {
  font-size: 1.75rem;
  letter-spacing: 0.8px;
  line-height: 1.5rem;
}

.headline-secondary {
  padding-top: 0.5rem;
  font-size: 20px;
  letter-spacing: 0.5;
}

.uppercase {
  text-transform: uppercase;
}

.bold {
  font-weight: 700;
}

.actions,
.actions-end {
  display: flex;
  height: fit-content;
  flex-wrap: wrap;
  gap: 3.06rem;
}

.actions-end {
  justify-content: flex-end;
  margin-top: 2rem;
}

.box-checkbox {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  padding: 3rem 0 1rem;
}
</style>
