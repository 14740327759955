<script lang="ts">
import type { ComponentPublicInstance } from "vue";
import {
  defineComponent,
  ref,
} from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import {
  email,
  helpers,
  minLength,
  required,
} from "@vuelidate/validators";
import { mdiEye, mdiEyeOff } from "@mdi/js";
import {
  AuthButton,
  AuthCard,
  AuthInput,
  AuthTemplate,
} from "../components";
import { login } from "@/http_services/auth";
import { authenticate } from "@/utils/auth";
import { entitiesAxios } from "@/http_services/client";

export default defineComponent({
  name: "Login",
  components: {
    AuthTemplate,
    AuthCard,
    AuthInput,
    AuthButton,
  },
  beforeRouteEnter(to, from, next) {
    if (from.name === "DefinePassword" && to.query.passwordDefined) {
      next((vm: ComponentPublicInstance<{ cardTitle: string; t: (string) => string }>) => {
        vm.cardTitle = vm.t("login.definePassword.createSuccess");
      });
    }
    else {
      next();
    }
  },
  props: {
    passwordDefined: Boolean,
  },
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const passwordHidden = ref(true);
    const responseError = ref(null);
    const cardTitle = ref(t("login.welcome"));

    const payload = ref({
      email: "",
      password: "",
    });

    const rules = {
      email: {
        required: helpers.withMessage(
          t("input.validations.required"),
          required,
        ),
        email: helpers.withMessage(
          t("input.validations.email"),
          email,
        ),
      },
      password: {
        required: helpers.withMessage(
          t("input.validations.required"),
          required,
        ),
        minLength: helpers.withMessage(
          t("input.validations.minLength", { length: 8 }),
          minLength(8),
        ),
      },
    };

    const validator = useVuelidate(rules, payload);

    const submitLogin = async (): Promise<void> => {
      try {
        responseError.value = null;
        const response = await login({ login: payload.value });
        authenticate(response.data);
        entitiesAxios.defaults.headers.Authorization = `Bearer ${response.data.access_token}`;
        router.push("/projects");
      }
      catch (e) {
        responseError.value = t("login.loginError");
      }
    };

    return {
      t,
      cardTitle,
      passwordHidden,
      submitLogin,
      validator,
      responseError,
      eye: mdiEye,
      eyeOff: mdiEyeOff,
    };
  },
});
</script>

<template>
  <AuthTemplate id="login-wrapper">
    <AuthCard :title="cardTitle" class="login-card">
      <form data-test="login-form" @submit.prevent="submitLogin()">
        <AuthInput
          id="username"
          v-model="validator.email.$model"
          data-test="email"
          :label="t('input.placeholder.username')"
          :placeholder="t('input.placeholder.email')"
          :invalid="validator.email.$invalid && validator.email.$dirty"
          :error="validator.email.$errors[0]?.$message || responseError"
        />

        <AuthInput
          id="password"
          v-model="validator.password.$model"
          data-test="password"
          :type="passwordHidden ? 'password' : 'text'"
          :label="t('input.placeholder.password')"
          :placeholder="t('input.placeholder.password')"
          :invalid="validator.password.$invalid && validator.password.$dirty"
          :error="validator.password.$errors[0]?.$message"
          :after-icon="!passwordHidden ? eye : eyeOff"
          @click:afterIcon="passwordHidden = !passwordHidden"
        />

        <div class="submit-wrapper">
          <AuthButton :disabled="validator.$invalid" :label="t('login.submitButton')" />
        </div>
      </form>

      <div class="forgot-wrapper">
        <div class="login-forgot" to="/">
          {{ t("login.forgotPassword") }}
        </div>
      </div>
    </AuthCard>
  </AuthTemplate>
</template>

<style lang="scss" scoped>
.login-card {
  .login-forgot {
    color: $white-full;
    text-decoration: none;

    &:hover {
      color: darken($white-full, 20%);
    }
  }

  .submit-wrapper {
    margin-bottom: 1.5rem;
  }

  .forgot-wrapper {
    text-align: center;
  }
}
</style>
