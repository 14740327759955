import countries from "i18n-iso-countries";
import enCountries from "i18n-iso-countries/langs/en.json";
import ptCountries from "i18n-iso-countries/langs/pt.json";
import { CURRENCY } from "@/entities/common";

countries.registerLocale(enCountries);
countries.registerLocale(ptCountries);

export const registeredCountries = countries;

export const moneyCurrencies = {
  [CURRENCY.USD]: "US$",
  [CURRENCY.BRL]: "R$",
  [CURRENCY.EUR]: "€",
};

export const moneyLocales = {
  [CURRENCY.USD]: "en-US",
  [CURRENCY.BRL]: "pt-BR",
  [CURRENCY.EUR]: "de-DE",
};

export const moneyArgs = {
  [CURRENCY.USD]: {
    fractionDigits: 2,
    decimalSeparator: ".",
    thousandsSeparator: ",",
  },
  [CURRENCY.BRL]: {
    fractionDigits: 2,
    decimalSeparator: ",",
    thousandsSeparator: ".",
  },
  [CURRENCY.EUR]: {
    fractionDigits: 2,
    decimalSeparator: ",",
    thousandsSeparator: ".",
  },
};
