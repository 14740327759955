<script lang="ts">
import type { ComputedRef } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import debounce from "lodash/debounce";
import some from "lodash/some";
import uniqBy from "lodash/uniqBy";
import { mdiPlusCircleOutline } from "@mdi/js";
import Filter from "./components/Filter.vue";
import List from "./components/List.vue";
import type { Person } from "@/entities/Person/person";
import ScrollToTop from "@/components/scroll_to_top/ScrollToTop.vue";
import ContentHeader from "@/components/content_header/ContentHeader.vue";
import { searchPersons } from "@/http_services/entities/persons";
import PersonIcon from "@/assets/images/person_icon.svg";
import type { PaginationParams } from "@/http_services/types";
import PageWrapper from "@/components/page_wrapper/PageWrapper.vue";

import PersonDetail from "@/persons/pages/persons/components/PersonDetail/PersonDetail.vue";
import { CheckConflict } from "@/persons/pages/persons/components";

export default defineComponent({
  name: "Person",
  components: {
    List,
    Filter,
    ContentHeader,
    ScrollToTop,
    PersonIcon,
    CheckConflict,
    PersonDetail,
    PageWrapper,
  },
  setup() {
    const { t } = useI18n();
    const listComponent = ref(null);
    const visibleScrollTop = ref(false);
    const limitVisible = ref(10);
    const showDetails = ref(false);
    const selectedPerson = ref(undefined);
    const candidates = ref<Person[]>([]);
    const loading = ref(false);
    const router = useRouter();
    const checkConflictVisible = ref(false);
    const termToSearch = ref(undefined);
    const allPersons = ref(0);
    const loadError = ref(false);
    const emptySearch = ref(false);
    const searchText = ref("");
    const paginationParams = computed(() => ({
      limit: 10,
      skip: candidates.value.length,
      sort: ["updated_at"],
      search_ground: searchText.value,
      name: null,
    })) as ComputedRef<PaginationParams>;
    const isSidebarOpen = computed(() => showDetails.value || checkConflictVisible.value);
    const filters = ref({});
    const searchTotal = ref(null);
    const searchFinish = ref(false);

    const getCandidates = async () => {
      try {
        emptySearch.value = false;
        loadError.value = false;
        loading.value = true;
        const { total: allpersonsLenght } = await searchPersons({ ...paginationParams.value.skip });
        allPersons.value = allpersonsLenght;
        const response = await searchPersons({ ...paginationParams.value, ...filters.value });
        const { data, total } = response;
        searchTotal.value = total;
        candidates.value = uniqBy([...candidates.value, ...data], e => e.id);

        if (candidates.value.length === 0)
          emptySearch.value = true;

        if (data.length === 0)
          searchFinish.value = true;
        else
          searchFinish.value = false;
      }
      catch {
        loadError.value = true;
      }
      finally {
        loading.value = false;
      }
    };

    const debounceGetCandidates = debounce(getCandidates, 500);

    provide("onShowDetails", (person) => {
      showDetails.value = true;
      selectedPerson.value = person;
    });

    provide("selectedPerson", selectedPerson);

    provide("candidates", candidates);

    provide("onCloseDetails", () => {
      showDetails.value = false;
      selectedPerson.value = undefined;
    });

    provide("onSidebarRedirect", () => {
      showDetails.value = false;
    });

    provide("isSidebarOpen", isSidebarOpen);

    const resetPage = () => {
      paginationParams.value.skip = 0;
      candidates.value = [];
    };
    const doSearch = async (searchTerm: string) => {
      resetPage();
      searchText.value = searchTerm;
      paginationParams.value.search_ground = searchTerm;
      // candidates.value = [];
      getCandidates();
    };

    const handleScroll = (event: Event) => {
      const {
        scrollTop: hiddenHeight,
        scrollHeight: totalHeight,
        clientHeight: totalVisible,
      } = event.target as HTMLElement;
      const bottomReached = hiddenHeight + totalVisible + 10 >= totalHeight;
      const filtersWrapper = document.querySelector(".filters-wrapper");

      if (filtersWrapper) {
        const { offsetHeight } = filtersWrapper as HTMLElement;

        offsetHeight >= hiddenHeight
          ? (visibleScrollTop.value = false)
          : (visibleScrollTop.value = true);
      }

      if (hiddenHeight && bottomReached && !searchFinish.value)
        getCandidates();
    };

    const goToCreate = () => router.push("persons/create");

    const toggleConflictVisible = () => (checkConflictVisible.value = !checkConflictVisible.value);

    const filterPersons = ([filterType, values]: [string, string[]]) => {
      if (filterType === "salary")
        Object.keys(values).forEach(key => (filters.value[`${filterType}_${key}`] = values[key]));
      else
        filters.value[filterType] = Array.isArray(values) ? values.join(";") : values;

      loading.value = true;
      resetPage();
      debounceGetCandidates();
    };

    onMounted(() => {
      getCandidates();
    });

    const isSearching = computed(() => some(filters.value) || !!searchText.value);

    return {
      t,
      candidates,
      checkConflictVisible,
      showDetails,
      loading,
      handleScroll,
      loadError,
      doSearch,
      emptySearch,
      allPersons,
      goToCreate,
      toggleConflictVisible,
      filterPersons,
      plus: mdiPlusCircleOutline,
      searchTotal,
      filters,
      isSearching,
      listComponent,
      visibleScrollTop,
    };
  },
});
</script>

<template>
  <PageWrapper class="persons-home" @scroll="handleScroll($event)">
    <template #header>
      <ContentHeader
        :title="t('header.persons')"
        :buttons="[
          {
            type: 'primary',
            text: t('persons.createPerson'),
            onClick: toggleConflictVisible,
            buttonIcon: plus,
          },
        ]"
        :search-total="searchTotal"
        :all-projets="allPersons"
      >
        <template #icon>
          <PersonIcon />
        </template>
      </ContentHeader>
    </template>
    <template #content>
      <Filter @search:text="doSearch($event)" @filter="filterPersons($event)" />
      <List
        ref="listComponent"
        data-test="list-component"
        :candidates="candidates"
        :loading="loading"
        :is-searching="isSearching"
        :search-total="searchTotal"
      >
        <template #tail>
          <div v-if="loadError" class="messsage">
            <span>{{ t("persons.errorListPerson") }}</span>
          </div>
          <div v-if="emptySearch" class="messsage">
            <span>{{ t("persons.notFoundSearch") }}</span>
          </div>
        </template>
      </List>
      <CheckConflict :visible="checkConflictVisible" :on-close="toggleConflictVisible" />
      <PersonDetail :visible="showDetails" />
      <ScrollToTop v-if="visibleScrollTop" />
    </template>
  </PageWrapper>
</template>

<route lang="yaml">
meta:
  layout: listpage
  </route>

<style scoped lang="scss">
.persons-home {
  display: flex;
  justify-content: center;
  padding-block: 2rem;
  padding-inline: auto;
  width: 100vw;
}
</style>

<style lang="scss" scoped>
#person-wrapper {
  height: 100%;
  flex-grow: 1;
  overflow-y: auto;

  .content {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: minmax(370px, 1fr) 3fr;

    .search-group {
      .search-input-wrapper {
        height: 2.5rem;
        border-radius: 10px;
        background: white;
      }

      .search-error {
        display: inline-block;
        margin-top: 0.5rem;
        color: $red;
        font-size: 0.8rem;
        text-align: center;
      }
    }

    .filters-wrapper {
      margin-top: 3rem;

      svg {
        height: 1.5rem;
        margin-right: 10px;
      }

      .filter-header {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        color: $red;
        fill: $red;
        font-weight: 600;
        letter-spacing: 2px;
        stroke: $red;

        h2 {
          font-family: "Open Sans";
        }
      }
    }

    .messsage {
      display: flex;
      justify-content: center;
      color: $gray;
    }
  }
}
</style>
