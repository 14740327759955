<script lang="ts" setup>
import ExportButton from "@/projects/pages/projects/[id]/shortlist/funnel/components/ExportButton.vue";
import { formatScore } from "@/utils/numbers";
import { formatCompanyName, formatPersonName, formatRoleName } from "@/utils/text";
import type { CandidateRank, RankingWeights, RankingWeightsKeysConverted } from "@/projects/pages/projects/[id]/shortlist/adherence-table/utils";
import { calculateShortlistCandidatesRank, calculateWeightsByOrder, getSortedShortlistCandidates } from "@/projects/pages/projects/[id]/shortlist/adherence-table/utils";
import type { InterviewCandidates } from "@/types/candidates";
import { ShortlistEnum } from "@/schemas/project/pdf";

const route = useRoute();
const router = useRouter();
const projectId = computed(() => String(route.params.id));

const { t } = useI18n();

const { data: project, error } = await useProject(projectId);

const {
  data: listCandidates,
} = useGetCandidatesFromShortlist(String(route.params.id));

const {
  stageTabs,
  currentRoute,
  goTo,
} = useShortListTabs(String(route.params.id));

const {
  mutate: candidateInterviewsActivities,
} = useCandidateInterviewsActivitiesWithBody(String(route.params.id));

const idealProfileValue = "100%";

const limitCharName = 25;

const rankedOrder: (keyof RankingWeightsKeysConverted)[] = [
  "schooling",
  "professionalExperience",
  "realizations",
  "attributes",
  "cultural_match",
  "motivation",
];

const weightsByOrder = ref<RankingWeightsKeysConverted>();

const candidatesRank = ref<CandidateRank[]>();

const rankingWeights = ref<RankingWeights>();

watchEffect(() => {
  if (project.value)
    rankingWeights.value = project.value.ranking_weights;

  if (listCandidates.value) {
    stageTabs.value = getSortedShortlistCandidates(String(route.params.id), listCandidates.value, stageTabs.value);
    weightsByOrder.value = calculateWeightsByOrder(rankingWeights.value).value;
    candidatesRank.value = calculateShortlistCandidatesRank(listCandidates.value).value;
  }
  const routeIndex = stageTabs.value.findIndex(tab => tab.key === String(route.params.person));
  currentRoute.value = routeIndex;
});

async function goToActivity(person_id: string) {
  candidateInterviewsActivities(
    {
      project_id: String(route.params.id),
      candidate_person_id: person_id,
    },
    {
      onSuccess: (data: InterviewCandidates[]) => {
        if (data && data.length > 0) {
          const firstItemIdx = (data || [])[0]?._id;
          if (!firstItemIdx)
            return;
          router.push(
            `/projects/${route.params.id}/interviews/person/${person_id}/activity/${firstItemIdx}?redirect_source=adherence-table`,
          );
        }
      },
    },
  );
}
</script>

<template>
  <section class="project-activities">
    <div v-if="error">
      could not load
    </div>
    <div v-if="project" class="project-container">
      <ProjectStages :id="projectId" />
      <div v-if="project" class="project-wrapper">
        <ProjectTabs :stage-tabs="stageTabs" :active="2" class="tabs" @change="goTo($event)" />
        <div class="project-content">
          <div class="header">
            <ExportButton />
          </div>
          <h4>{{ t(`projects.shortlist.pdf.${ShortlistEnum.ADHERENCE_TABLE}`).toLocaleUpperCase() }}</h4>
          <table v-if="candidatesRank" class="pdf-users-tables" :class="[candidatesRank?.length > 6 ? 'scrollable' : '']">
            <thead class="box-header">
              <tr class="box-ideal-profile">
                <th v-for="(title, index) in [t('exportPDF.labels.weights'), '', t('exportPDF.labels.ideal')]" :key="index" class="box-title">
                  {{ title }}
                </th>
              </tr>
              <tr class="box-persons">
                <th v-for="(person, index) in candidatesRank" :key="index" class="box-person">
                  <div class="box-title">
                    {{ formatPersonName(person, limitCharName) }}
                  </div>
                  <div class="box-company">
                    {{ formatCompanyName(person, limitCharName) }}
                  </div>
                  <div class="box-role">
                    {{ formatRoleName(person, limitCharName) }}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="box-content">
                <td class="box-profile">
                  <div v-for="(item, index) in rankedOrder" :key="index" class="box-ideal-profile">
                    <div v-if="item" class="box-value">
                      {{ weightsByOrder[item] }}%
                    </div>
                    <div class="box-label">
                      <span>{{ t(`exportPDF.labels.${item}`) }}</span>
                    </div>
                    <div class="box-value">
                      {{ idealProfileValue }}
                    </div>
                  </div>
                </td>
                <td class="box-persons">
                  <div v-for="(person, index) in candidatesRank" :key="index" class="box-person">
                    <div v-for="(rankType, idx) in rankedOrder" :key="idx" class="box-value">
                      {{ formatScore(person.scores[rankType]) }}%
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr class="box-footer">
                <td class="box-ideal-profile">
                  <div class="box-title">
                    {{ idealProfileValue }}
                  </div>
                  <div class="box-label">
                    {{ t("exportPDF.labels.finalRate") }}
                  </div>
                  <div class="box-value">
                    {{ idealProfileValue }}
                  </div>
                </td>
                <td class="box-persons result">
                  <div v-for="(person, index) in candidatesRank" :key="index" class="box-result">
                    <span v-if="person.calculated_scores?.final_mean">{{ formatScore(person.calculated_scores?.final_mean)
                    }}%</span>
                  </div>
                </td>
              </tr>
              <tr class="box-paginate">
                <td class="box-ideal-profile" />
                <td class="box-persons">
                  <div v-for="(person, index) in candidatesRank" :key="index" class="box-index">
                    <span>{{ index + 1 }}</span>
                    <Button
                      class="person-data-actions-buttons"
                      variation="primary"
                      @click="goToActivity(person.person_id)"
                    >
                      {{ t("projects.details.constructionGuide.ranking.reassess") }}
                    </Button>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </section>
</template>

<route lang="yaml">
meta:
  layout: project-stage
  stage: shortlist
  tab: adherence-table
</route>

<style lang="scss" scoped>
$ideal-profile-width: 22rem;

.pdf-users-tables {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  padding-block-end: 2rem;

  .box-header,
  .box-content,
  .box-footer,
  .box-paginate {
    display: flex;
    flex: 1;
    flex-direction: row;
    gap: 0.5625rem;
  }

  .box-value,
  .box-label {
    display: flex;
    height: 3.25rem;
    align-items: center;
    justify-content: center;
    border: 1px solid $white-background;
    border-radius: 3px;
    text-align: center;
    margin-inline-end: 5px;
  }

  .box-value {
    color: $gray-dark;
    font-weight: 500;
  }

  .box-title,
  .box-result {
    max-width: 6.875rem;
    color: $yellow;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.5;
  }

  .box-ideal-profile {
    display: grid;
    min-width: 15.7rem;
    flex: 1;
    gap: 0.5rem;
    grid-template-columns: 1fr 3fr 1fr;

    .box-title {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      text-align: right;
      text-transform: uppercase;
    }

    .box-label {
      min-width: 7rem;
      justify-content: flex-start;
      font-size: 0.75rem;
      font-weight: bold;
      text-align: left;
      text-transform: uppercase;
      padding-inline: 0.75rem;
    }

    .box-value {
      color: $yellow;
      font-size: 1.125rem;
      font-weight: bold;
      border: none;
    }
  }

  .box-persons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    // gap: 0.5625rem;
    gap: 1.5625rem;

    .box-person {
      display: flex;
      min-width: 6.875rem;
      max-width: 6.875rem;
      flex-direction: column;
      gap: 0.65rem;
      text-align: left;

      .box-company,
      .box-role {
        color: $gray-dark;
        font-size: 0.6875rem;
        font-weight: 600;
      }

      .box-company {
        font-weight: bold;
      }
    }
  }

  .box-header {
    .box-person {
      gap: 0.5rem;
    }
  }

  .box-content {
    .box-profile {
      display: flex;
      flex: 1;
      flex-direction: column;
      gap: 0.65rem;
      min-width: 21rem;

      .box-ideal-profile {
        .box-value {
          background-color: $white-background;
          color: $gray-dark;
          padding-inline: 0.625rem;
        }
      }
    }
  }

  .box-footer {
    background: $gray-dark;
    margin-block-start: 2.2rem;

    .box-result {
      display: flex;
      min-width: 6.875rem;
      max-width: none;
      align-items: center;
      justify-content: center;
      font-size: 1.125rem;
      background: $gray-dark;

      span {
        color: $yellow;
      }
    }

    .box-title {
      font-size: 1.125rem;
    }

    .box-ideal-profile {
      display: grid;
      min-width: 15.7rem;
      flex: 1;
      gap: 0.5rem;
      grid-template-columns: 1fr 3fr 1fr;
    }

    .box-title,
    .box-label {
      align-items: center;
      border: none;
      color: $white-full;
      line-height: 1.5;
    }
  }

  .box-paginate {
    .box-index {
      display: flex;
      flex-flow: column;
      min-width: 6.875rem;
      max-width: 6.875rem;
      align-items: center;
      justify-content: center;
      color: $gray-dark;
      font-size: 1.125rem;
      font-weight: bold;

      span {
        margin-block: 1.25rem 1.6rem;
      }
      button {
        margin-inline-end: 5px;
      }
    }
  }
}

.scrollable {
  overflow-x: scroll;

  &::-webkit-scrollbar {
    height: 2.5rem;
    background-color: #f4f4f4;
    border-radius: 2px;
  }
  &::-webkit-scrollbar-button {
    width: 100%;
  }
  &::-webkit-scrollbar-thumb {
    background: #999999;
    border: 1rem solid #f4f4f4;
    border-radius: 1.25rem;

  }
  &::-webkit-scrollbar-track {
    border-radius: 0.625rem;
    width: 100%;
  }
}
.result {
  background: $gray-dark;
}

.project-content.scrollable-content {
  overflow: hidden;
}
.header {
  display: flex;
  align-items: center;
}
h4 {
  font-size: 1.25rem;
  line-height: 1.7rem;
}
.box-header .box-ideal-profile,
.pdf-users-tables .box-footer .box-ideal-profile,
.pdf-users-tables .box-paginate .box-ideal-profile {
  min-width: 21rem;
}

.project-activities {
  min-height: 100vh;
}

.project-container {
  padding-top: 3.75rem;
}

.project-wrapper {
  background: $white-full;
  padding-bottom: 1.5rem;
}

.project-content {
  margin-inline: 1.5rem;
}

.box-title {
  scroll-snap-align: end;
}

.imua-button.person-data-actions-buttons {
  padding: 0.3125rem 1.2rem;
}

.box-ideal-profile, .box-profile, .box-ideal-profile {
   position: sticky;
   left: 0;
   background-color: $white-full;
 }
 .pdf-users-tables .box-footer .box-ideal-profile {
   position: sticky;
   left: 0;
   background-color: $gray-dark;
 }
</style>
