import { z } from "zod";

export enum LanguageEnum {
  AFR = "AFR",
  AII = "AII",
  AIN = "AIN",
  AKK = "AKK",
  ALB = "ALB",
  AMH = "AMH",
  ARA = "ARA",
  ASM = "ASM",
  AST = "AST",
  AYM = "AYM",
  AZE = "AZE",
  BAQ = "BAQ",
  BEL = "BEL",
  BEN = "BEN",
  BER = "BER",
  BOS = "BOS",
  BRE = "BRE",
  BUL = "BUL",
  CAT = "CAT",
  CHR = "CHR",
  CHU = "CHU",
  CMN = "CMN",
  COR = "COR",
  COS = "COS",
  CZE = "CZE",
  DAK = "DAK",
  DAN = "DAN",
  DEU = "DEU",
  EGY = "EGY",
  ENG = "ENG",
  EPO = "EPO",
  EST = "EST",
  EUS = "EUS",
  FAO = "FAO",
  FAS = "FAS",
  FIJ = "FIJ",
  FIL = "FIL",
  FIN = "FIN",
  FRE = "FRE",
  FRS = "FRS",
  FRY = "FRY",
  FUR = "FUR",
  GLA = "GLA",
  GLE = "GLE",
  GLG = "GLG",
  GLV = "GLV",
  GRC = "GRC",
  GRE = "GRE",
  GRN = "GRN",
  GUJ = "GUJ",
  HAT = "HAT",
  HAU = "HAU",
  HAW = "HAW",
  HEB = "HEB",
  HIN = "HIN",
  HMN = "HMN",
  HRV = "HRV",
  HUN = "HUN",
  HYE = "HYE",
  IDO = "IDO",
  IND = "IND",
  INH = "INH",
  ISL = "ISL",
  ITA = "ITA",
  JPN = "JPN",
  KAL = "KAL",
  KAN = "KAN",
  KAS = "KAS",
  KAT = "KAT",
  KAZ = "KAZ",
  KHM = "KHM",
  KIR = "KIR",
  KOK = "KOK",
  KOR = "KOR",
  KUR = "KUR",
  LAD = "LAD",
  LAO = "LAO",
  LAT = "LAT",
  LAV = "LAV",
  LIT = "LIT",
  LTZ = "LTZ",
  MAL = "MAL",
  MAR = "MAR",
  MKD = "MKD",
  MNI = "MNI",
  MON = "MON",
  MRI = "MRI",
  MSA = "MSA",
  MYA = "MYA",
  NAN = "NAN",
  NEP = "NEP",
  NLD = "NLD",
  NOR = "NOR",
  NOV = "NOV",
  NYA = "NYA",
  OCI = "OCI",
  OJI = "OJI",
  ORI = "ORI",
  PAN = "PAN",
  POL = "POL",
  POR = "POR",
  PRA = "PRA",
  PTB = "PTB",
  PUS = "PUS",
  QUE = "QUE",
  ROH = "ROH",
  ROM = "ROM",
  RON = "RON",
  RUS = "RUS",
  SAN = "SAN",
  SCE = "SCE",
  SCN = "SCN",
  SCO = "SCO",
  SIN = "SIN",
  SLK = "SLK",
  SLV = "SLV",
  SMI = "SMI",
  SMO = "SMO",
  SND = "SND",
  SPA = "SPA",
  SRP = "SRP",
  SWA = "SWA",
  SWE = "SWE",
  TAM = "TAM",
  TEL = "TEL",
  THA = "THA",
  TIB = "TIB",
  TON = "TON",
  TPI = "TPI",
  TUR = "TUR",
  UKR = "UKR",
  UND = "UND",
  URD = "URD",
  UZB = "UZB",
  VIE = "VIE",
  WEL = "WEL",
  WUU = "WUU",
  XHO = "XHO",
  YID = "YID",
  YOR = "YOR",
  YUE = "YUE",
  ZHO = "ZHO",
  ZUL = "ZUL",
}

export enum LanguageLabelsEnum {
  AFR = "Africanse",
  AII = "Assuri",
  AIN = "Ainu",
  AKK = "Acadiano",
  ALB = "Albanês",
  AMH = "Amárico",
  ARA = "Árabe",
  ASM = "Assamês",
  AST = "Asturiano",
  AYM = "Aimará",
  AZE = "Azeri",
  BAQ = "Basque",
  BEL = "Bielorrusso",
  BEN = "Bengali",
  BER = "Berbere",
  BOS = "Bósnio",
  BRE = "Bretão",
  BUL = "Búlgaro",
  CAT = "Catalão",
  CHR = "Cheroqui",
  CHU = "Eslavo Eclesiástico",
  CMN = "Chinês - Mandarin",
  COR = "Córnico",
  COS = "Corso",
  CZE = "Tcheco",
  DAK = "Dakota",
  DAN = "Dinarmaquês",
  DEU = "Alemão",
  EGY = "Egípcio",
  ENG = "Inglês",
  EPO = "Esperanto",
  EST = "Estoniano",
  EUS = "Basca",
  FAO = "Feroês",
  FAS = "Persa",
  FIJ = "Fijiano",
  FIL = "Filipino",
  FIN = "Finlanês",
  FRE = "Francês",
  FRS = "Baixo Saxão",
  FRY = "Frísio",
  FUR = "Friulano",
  GLA = "Escocês Gaelico",
  GLE = "Irlandês",
  GLG = "Galego",
  GLV = "Manês",
  GRC = "Grego Antigo",
  GRE = "Grego",
  GRN = "Guarani",
  GUJ = "Gujarati",
  HAT = "Haitiano",
  HAU = "Hauçá",
  HAW = "Havaiano",
  HEB = "Hebraico",
  HIN = "Hindi",
  HMN = "Hmong",
  HRV = "Croata",
  HUN = "Húngaro",
  HYE = "Armênio",
  IDO = "Ido",
  IND = "Indonésio",
  INH = "Ingush",
  ISL = "Islandês",
  ITA = "Italiano",
  JPN = "Japonês",
  KAL = "Groenlandês",
  KAN = "Kanarês",
  KAS = "Caxemiriano",
  KAT = "Georgiano",
  KAZ = "Cazaque",
  KHM = "Cambojano",
  KIR = "Quirguistanês",
  KOK = "Concani",
  KOR = "Coreano",
  KUR = "Curdo",
  LAD = "Ladino",
  LAO = "Laociano",
  LAT = "Latim",
  LAV = "Letão",
  LIT = "Lituano",
  LTZ = "Luxemburguês",
  MAL = "Malaiala",
  MAR = "Marati",
  MKD = "Macedônio",
  MNI = "Manipuri",
  MON = "Mongol",
  MRI = "Maori",
  MSA = "Malaio",
  MYA = "Birmanês",
  NAN = "Chinês - Taiwan",
  NEP = "Nepalês",
  NLD = "Holandês",
  NOR = "Norueguês",
  NOV = "Novial",
  NYA = "Nianja",
  OCI = "Occitânico",
  OJI = "Ojibua",
  ORI = "Oriá",
  PAN = "Panjabi",
  POL = "Polonês",
  POR = "Português",
  PRA = "Prakrit",
  PTB = "Português Brasileiro",
  PUS = "Afegão",
  QUE = "Quíchua",
  ROH = "Reto Romano",
  ROM = "Romani",
  RON = "Romeno",
  RUS = "Russo",
  SAN = "Sânscrito",
  SCE = "Santa",
  SCN = "Siciliano",
  SCO = "Escocês",
  SIN = "Cingalês",
  SLK = "Eslovaco",
  SLV = "Eslovêno",
  SMI = "Sámi",
  SMO = "Samoano",
  SND = "Sindi",
  SPA = "Espanhol",
  SRP = "Sérvio",
  SWA = "Suaíli",
  SWE = "Sueco",
  TAM = "Tâmil",
  TEL = "Telugu",
  THA = "Tailandês",
  TIB = "Tibetano",
  TON = "Tonganês",
  TPI = "Tok Pisin",
  TUR = "Turco",
  UKR = "Ucraniano",
  UND = "Outros",
  URD = "Urdu",
  UZB = "Usbeque",
  VIE = "Vietinamita",
  WEL = "Galês",
  WUU = "Chinês Shangai",
  XHO = "Xosa",
  YID = "Ídiche",
  YOR = "Iorubá",
  YUE = "Chinês - Cantonese",
  ZHO = "Chinês",
  ZUL = "Zulu",
}

export enum LanguageProficiencyEnum {
  BASIC = "BASIC",
  BASIC_INT = "BASIC_INT",
  INTERMEDIATE = "INTERMEDIATE",
  ADVANCED = "ADVANCED",
  FLUENT = "FLUENT",
}

export enum BusinessLanguageProficiencyEnum {
  ELEMENTARY = "ELEMENTARY",
  LIMITED_WORKING = "LIMITED_WORKING",
  PROFESSIONAL_WORKING = "PROFESSIONAL_WORKING",
  NATIVE = "NATIVE",
}

export const LanguageEnumSchema = z.nativeEnum(LanguageEnum);
export const LanguageLabelsEnumSchema = z.nativeEnum(LanguageLabelsEnum);
export const LanguageProficiencyEnumSchema = z.nativeEnum(LanguageProficiencyEnum);
export const BusinessLanguageProficiencyEnumSchema = z.nativeEnum(BusinessLanguageProficiencyEnum);
