<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { normalizeProps, useMachine } from "@zag-js/vue";
import { machine } from "@zag-js/slider";
import bson from "bson-objectid";

import * as slider from "@zag-js/slider";
import { SCALE } from "@/entities/project/construction-guide";

type ValueMapKey = keyof typeof valueMap;

const props = withDefaults(defineProps<{
  modelValue: ValueMapKey | undefined | null
  markerColor?: string
  trackColor?: string
  backgroundColor?: string
  scoreMarker?: ValueMapKey | null
  disabled?: boolean
  constructionValueColor: "#F8D272" | "#a8a8a8"
  markerScoreColor?: "#666666" | "#fcaf45"
  titleMarkerColor?: "#666666" | "#fcaf45"
}>(), {
  trackColor: "#F4F4F4",
  markerScoreColor: "#666666",
  titleMarkerColor: "#fcaf45",
  markerColor: "#fcaf45",
  backgroundColor: "#666",
  constructionValueColor: "#a8a8a8",
});

const emit = defineEmits(["update:modelValue"]);

const valueMap = {
  EMPTY: 0,
  LOW: 1,
  AVERAGE: 2,
  HIGH: 3,
  VERY_HIGH: 4,
};

const valueMapValues: { [key: number]: ValueMapKey } = {
  0: "EMPTY",
  1: "LOW",
  2: "AVERAGE",
  3: "HIGH",
  4: "VERY_HIGH",
};

const { t } = useI18n();

const currentValue = ref(valueMap[toRaw(props.modelValue) ?? "EMPTY"]);
function onChange(v: { value: number }) {
  if (props.disabled)
    return;

  currentValue.value = v.value;
  emit("update:modelValue", valueMapValues[v.value]);
}

const [state, send] = useMachine(machine({
  id: bson().toHexString(),
  min: 0,
  max: 4,
  step: 1,
  value: currentValue.value,
  onChange,
}));

const api = computed(() => slider.connect(state.value, send, normalizeProps));

const offsetMap: { [key: number]: number } = {
  0: 0,
  1: 15,
  2: 45,
  3: 70,
  4: 96.85,
};

const currentOffset: ComputedRef<number | null | undefined> = computed(() => {
  if (currentValue.value === 4)
    return null;

  return offsetMap[currentValue.value];
});

const exampleRef = ref<HTMLElement | undefined>();
const exampleRef2 = ref<HTMLElement | undefined>();
const exampleRef3 = ref<HTMLElement | undefined>();
const exampleRef4 = ref<HTMLElement | undefined>();

function getOffset(ref: Ref<HTMLElement | undefined>) {
  return computed(() => {
    return ref.value?.offsetWidth ? (ref.value.offsetWidth / 2) - 8 : 0;
  });
}

const offsetExample = getOffset(exampleRef);
const offsetExample2 = getOffset(exampleRef2);
const offsetExample3 = getOffset(exampleRef3);
const offsetExample4 = getOffset(exampleRef4);

const KeyNumbers = {
  [SCALE.EMPTY]: 0,
  [SCALE.LOW]: 1,
  [SCALE.AVERAGE]: 2,
  [SCALE.HIGH]: 3,
  [SCALE.VERY_HIGH]: 4,
};

function setRemarker(scoreMarker: keyof typeof valueMap | string) {
  const keyNumber = KeyNumbers[scoreMarker as SCALE];

  if (!keyNumber)
    return 0;

  return offsetMap[keyNumber];
}
</script>

<template>
  <div>
    <div v-bind="api.controlProps">
      <div v-bind="api.trackProps" :data-disabled="disabled">
        <div v-bind="api.rangeProps" :style="`width: ${(currentValue === 4) ? '100%' : `calc(${currentOffset}% + ${currentValue === 0 ? '0px' : '0.5rem'})`}`" />
      </div>
      <div v-bind="api.thumbProps" :data-disabled="disabled" :style="`background: white; left: ${(currentValue === 4) ? `calc(100% - 1rem)` : `${currentOffset}%`};`">
        <input v-bind="api.hiddenInputProps">
      </div>
      <div
        class="control-value"
        :class="[`--bg-${constructionValueColor}`, `${setRemarker(scoreMarker as string) === 100 ? 'complete' : ''}`]"
        :style="[`width: calc(${setRemarker(scoreMarker as string)}% + 0.5rem)`]"
      />
    </div>
    <div v-bind="api.markerGroupProps" class="custom-marker">
      <span
        v-bind="api.getMarkerProps({ value: 1 })"
        :style="`left: calc(${offsetMap[1]}% + 0.5rem)`"
        :data-score-marker="$props.scoreMarker ? valueMap[$props.scoreMarker] === 1 : null"
      />
      <span
        v-bind="api.getMarkerProps({ value: 2 })"
        :style="`left: calc(${offsetMap[2]}% + 0.5rem)`"
        :data-score-marker="$props.scoreMarker ? valueMap[$props.scoreMarker] === 2 : null"
      />
      <span
        v-bind="api.getMarkerProps({ value: 3 })"
        class="score-marker"
        :style="`left: calc(${offsetMap[3]}% + 0.5rem)`"
        :data-score-marker="$props.scoreMarker ? valueMap[$props.scoreMarker] === 3 : null"
      />
      <span
        v-bind="api.getMarkerProps({ value: 4 })"
        :style="`left: calc(${offsetMap[4]}% + 0.5rem)`"
        :data-score-marker="$props.scoreMarker ? valueMap[$props.scoreMarker] === 4 : null"
      />
    </div>
    <div v-bind="api.markerGroupProps" class="label-marker">
      <span
        v-bind="api.getMarkerProps({ value: 0 })"
        style="left: 0"
      />
      <span
        v-bind="api.getMarkerProps({ value: 1 })"
        :ref="(ref: HTMLElement) => exampleRef = ref"
        :style="`left: calc(${offsetMap[1]}% - ${offsetExample}px)`"
      >
        {{ t('projects.generic.scale.enum.LOW') }}
      </span>
      <span
        v-bind="api.getMarkerProps({ value: 2 })"
        :ref="(ref: HTMLElement) => exampleRef2 = ref"
        :style="`left: calc(${offsetMap[2]}% - ${offsetExample2}px)`"
      >
        {{ t('projects.generic.scale.enum.AVERAGE') }}
      </span>
      <span
        v-bind="api.getMarkerProps({ value: 3 })"
        :ref="(ref: HTMLElement) => exampleRef3 = ref"
        :style="`left: calc(${offsetMap[3]}% - ${offsetExample3}px)`"
      >
        {{ t('projects.generic.scale.enum.HIGH') }}
      </span>
      <span
        v-bind="api.getMarkerProps({ value: 4 })"
        :ref="(ref: HTMLElement) => exampleRef4 = ref"
        :style="`left: calc(100% - ${Math.max(64, offsetExample4)}px)`"
      >
        {{ t('projects.generic.scale.enum.VERY_HIGH') }}
      </span>
    </div>
    <div v-if="props.scoreMarker" class="score-marker-legend">
      <div>
        <span class="title-marker title-marker-score" />
        {{ t("projects.generic.idealProfile") }}
      </div>
      <div>
        <span class="title-marker sized" />
        {{ t("projects.details.constructionGuide.idealProfile.candidate") }}
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
[data-part='thumb'] {
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 999px;
  border: 1px solid $gray-dark;
  cursor: pointer;
  z-index: 3;
}

[data-part='thumb']:hover:not([data-disabled]),
[data-part='thumb']:focus-visible,
[data-part='thumb']:active {
  outline: 1px solid #2d2d2d;
  box-shadow: inset 0px 3px 6px #0000000B, 0px 3px 6px #00000029;
}

[data-part='range'] {
  background: v-bind("backgroundColor");
  height: 1rem;
  border-radius: 1rem;
  z-index: 2;
}

[data-part='track'] {
  background: v-bind("trackColor");
  height: 1rem;
  flex: 1;
  border-radius: 1rem;
  cursor: pointer;
}

[data-part='control'] {
  position: relative;
  align-items: center;
  display: flex;

}

.custom-marker[data-part='marker-group'] {
  margin-top: -0.625rem;
  z-index: 2;

  [data-part=marker] {
    width: 2px;
    background: $gray-dark;
    display: flex;
    justify-content: center;
    border-radius: 0.875rem;
    height: 0.75rem;
    margin-top: 0.25rem;
  }

}

.label-marker[data-part='marker-group'] {
  margin-top: 1.75rem;
  height: 1.5rem;
}

[data-part='marker-group'] span {
  display: block;
}

[data-part='marker'] {
  color: $gray-dark;
  font-size: 0.75rem;
}

.custom-marker[data-part=marker-group] [data-part=marker][data-score-marker=true] {
  background: v-bind(markerColor);
  margin-top: -0.625rem;
  height: 1.5rem;
  width: 0.25rem;
}

.score-marker-legend {
  display: flex;
  gap: 1rem;
  padding-block: 1rem 0.5rem;
}
.title-marker {
  width: 0.25rem;
  height: .875rem;
  background: v-bind(titleMarkerColor);
  display: inline-block;
  margin-right: 0.5rem;
  &.sized {
    width: 0.45rem;
  }

  &-score {
    background: v-bind(markerScoreColor);
  }
}

[data-part=track][data-disabled="true"],
[data-part=range][data-disabled="true"],
[data-part=thumb][data-disabled="true"] {
  cursor: not-allowed;
}
.control-value {
  background-color: v-bind("constructionValueColor");
  position: absolute;
  height: 1rem;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  z-index: 1;
}

.control-value.complete {
  border-radius: 10px;
}
</style>
