<script setup lang="ts">
import { Tab, TabGroup, TabList } from "@headlessui/vue";
import { mdiChevronLeft as chevron } from "@mdi/js";
import SvgIcon from "@jamescoyle/vue-icon";

defineProps<{
  active: number
  stageTabs: { key: string; name: string; path: string; active?: boolean; icon?: string; label?: string }[]
}>();

defineEmits(["change"]);

function checkDisabled(active: boolean | undefined) {
  return active === undefined ? false : !active;
}
const hasLeftArrow = ref(false);
const hasRightArrow = ref(false);

function scrollContainer(direction: "left" | "right") {
  const content = document.querySelector(".wrapper-box") as HTMLElement;
  const scrollDirection = direction === "right" ? -550 : 550;

  if (!content)
    return;

  content.scrollTo({
    left: content.scrollLeft + scrollDirection,
    behavior: "smooth",
  });
}

function checkScrollPosition() {
  const content = document.querySelector(".wrapper-box") as HTMLElement;
  const firstTab = document.querySelector(".first-tab") as HTMLElement;

  if (!content)
    return;

  const scrollThreshold = content.scrollWidth <= 1400 ? firstTab.clientWidth : 150;
  const isAtStart = content.scrollLeft <= scrollThreshold;
  const isAtEnd
    = content.scrollLeft + content.clientWidth
    >= content.scrollWidth - scrollThreshold;

  hasLeftArrow.value = !isAtStart;
  hasRightArrow.value = !isAtEnd;
}

const debouncedFn = useDebounceFn(checkScrollPosition, 200);

onUpdated(debouncedFn);
</script>

<template>
  <TabGroup as="imua-tab" :selected-index="active" v-bind="$attrs" @change="$emit('change', $event)">
    <TabList class="wrapper-box" @scroll="debouncedFn()">
      <SvgIcon
        v-show="hasLeftArrow"
        role="button"
        type="mdi"
        :path="chevron"
        size="24"
        class="absolute-left"
        @click="scrollContainer('right')"
      />
      <Tab
        v-for="(tab, index) in stageTabs"
        :key="`tab-${tab.name}`"
        :disabled="checkDisabled(tab.active)"
        class="box-scroll"
        :class="{ 'last-tab': index === stageTabs.length - 1, 'first-tab': index === 0 }"
      >
        {{ tab.name }}
      </Tab>
      <SvgIcon
        v-show="hasRightArrow"
        role="button"
        type="mdi"
        :path="chevron"
        size="24"
        class="absolute-right rotate"
        @click="scrollContainer('left')"
      />
    </TabList>
  </TabGroup>
</template>

<style scoped lang="scss">
* {
  font-family: 'Open Sans';
}
.tabs div.wrapper-box {
  position: relative;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}
.absolute {
  &-right, &-left {
  position: sticky;
  background-color: $white-full;
  width: 2.625rem;
  height: 2.75rem;
  flex-shrink: 0;
  stroke: transparent;
  color: $gray-a8;

    &:hover {
      color: $red-violet;
    }
  }

  &-left {
    left: -1px;
    border-top-left-radius: 0.2rem;
    border-top-right-radius: 0.2rem;
  }

  &-right {
    right: -1px;
    border-bottom-left-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
    &.rotate {
      transform: rotate(180deg);
    }
  }
}

button {
  padding: 0.75rem 1.5rem;
  border: none;
  background-color: $gray-gainsboro;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-size: .875rem;
  font-weight: 700;
  text-transform: uppercase;
  color: $gray-dark;
}

button[aria-selected=true] {
  color: $red-violet;
  background: white;
}

button:focus-visible {
  outline: 0;
}

button[disabled] {
  color: $gray-text;
  background: $white-hover;
}

div[role=tablist] {
  display: flex;
  gap: 0.75rem;
  margin-top: 5rem;
  overflow: auto;
  white-space: nowrap;
  background: $white-background;
}
</style>
