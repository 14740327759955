<script setup lang="ts">
import { computed, defineEmits, defineProps, ref } from "vue";
import SimpleMaskMoney from "simple-mask-money";
import { useI18n } from "vue-i18n";
import Input from "./Input.vue";
import { TrashButton } from "@/components/trash-button";
import { moneyArgs } from "@/utils/adapters/form";

const props = withDefaults(defineProps<{
  id?: string
  svgIcon?: string
  showIcon?: boolean
  autoFill?: boolean
  autoFillColor?: string
  error?: boolean
  errorMessage?: string | null
  placeholder?: string
  autocomplete?: string
  onFocus?: () => void
  onBlur?: () => void
  onEnter?: () => void
  onKeyDown?: () => void
  mask?: string
  money?: boolean
  currency?: string
  modelValue?: string | number | null
  disabled?: boolean
  tooltipShow?: boolean
  tooltipMesage?: string
  tooltipColor?: string
  tooltipBackground?: string
}>(), {
  showIcon: false,
  errorMessage: null,
  money: false,
});
const emit = defineEmits(["update:modelValue", "click:onButtonClick"]);
const currency = computed(() => props.currency);
const args = computed(() => {
  return {
    allowNegative: false,
    negativeSignAfter: false,
    prefix: "",
    suffix: "",
    fixed: true,
    fractionDigits: currency.value ? moneyArgs[currency.value].fractionDigits : 2,
    decimalSeparator: currency.value
      ? moneyArgs[currency.value].decimalSeparator
      : ",",
    thousandsSeparator: currency.value
      ? moneyArgs[currency.value].thousandsSeparator
      : ".",
    cursor: "move",
  };
});
const value = computed({
  get: () => {
    if (props.money && props.modelValue && props.modelValue !== "")
      return SimpleMaskMoney.formatToMask(props.modelValue, args.value);
    else
      return props.modelValue;
  },
  set: (newValue) => {
    if (props.money) {
      const formatt = SimpleMaskMoney.formatToMask(newValue, args.value);
      emit("update:modelValue", newValue ? formatt : newValue);
    }
    else {
      emit("update:modelValue", newValue);
    }
  },
});

const showTooltip = ref(false);

const { t } = useI18n();

function onClick() {
  emit("click:onButtonClick");
}
</script>

<template>
  <div class="form-input--container">
    <Input
      v-if="mask"
      :id="id"
      v-model="value"
      v-maska="mask"
      :auto-fill="autoFill"
      :auto-fill-color="autoFillColor"
      :error="error"
      :error-message="errorMessage"
      :placeholder="placeholder"
      :autocomplete="autocomplete"
      :on-focus="onFocus"
      :on-blur="onBlur"
      :on-enter="onEnter"
      :on-key-down="onKeyDown"
      :tooltip-show="tooltipShow"
      :tooltip-mesage="tooltipMesage"
      :tooltip-color="tooltipColor"
      :tooltip-background="tooltipBackground"
    />
    <Input
      v-else
      :id="id"
      v-model="value"
      :auto-fill="autoFill"
      :auto-fill-color="autoFillColor"
      :error="error"
      :error-message="errorMessage"
      :placeholder="placeholder"
      :autocomplete="autocomplete"
      :on-focus="onFocus"
      :on-blur="onBlur"
      :on-enter="onEnter"
      :on-key-down="onKeyDown"
      :disabled="disabled"
      :tooltip-show="tooltipShow"
      :tooltip-mesage="tooltipMesage"
      :tooltip-color="tooltipColor"
      :tooltip-background="tooltipBackground"
      :icon="svgIcon"
    >
      <template #icon>
        <slot name="icon" />
      </template>
    </Input>
    <TrashButton :show-icon="showIcon" :svg-icon="svgIcon" @click="onClick" />
  </div>
</template>

<style lang="scss" scoped>
.form-input--container {
  display: flex;
  width: 100%;

  .component-input:deep {
    width: 100%;
  }
}

.component-input:deep {
  margin-bottom: 0.3rem;

  .input-wrapper {
    height: 28px;
    border: 1px solid $gray-border;
    border-radius: 6px;
  }

  .error-wrapper {
    justify-content: start;

    span {
      padding-top: 2px;
      margin-left: 5px;
    }
  }
}
</style>
