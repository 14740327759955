<script lang="ts">
import { useI18n } from "vue-i18n";
import type { Ref } from "vue";
import { mdiPlusCircleOutline } from "@mdi/js";
import SvgIcon from "@jamescoyle/vue-icon";
import type { Realization } from "@/entities/Person/work-experience";
import { FormInput, TextArea } from "@/components/inputs/modalInputs";
import TrashIcon from "@/assets/images/trash_icon.svg";

export default defineComponent({
  name: "Realizations",
  components: {
    FormInput,
    TextArea,
    SvgIcon,
    TrashIcon,
  },
  props: {
    realizations: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const { t } = useI18n();
    const content = toRef(props, "realizations") as Ref<Realization[]>;

    const appendRealization = () => {
      content.value.push({
        title: "",
        description: "",
      });
    };

    const removeRealization = (index) => {
      content.value.splice(index, 1);
    };

    onMounted(() => {
      if (!content.value.length)
        appendRealization();
    });

    return {
      t,
      content,
      appendRealization,
      removeRealization,
      addMore: mdiPlusCircleOutline,
    };
  },
});
</script>

<template>
  <div class="realizations">
    <template v-for="(realization, index) in content" :key="realization">
      <div class="form-group">
        <label for="realization">{{ t("persons.workExperienceForm.realization") }}:</label>
        <FormInput
          id="realization"
          v-model="realization.title"
          data-test="realization"
          :placeholder="t('persons.workExperienceForm.placeholders.description')"
        />
      </div>

      <div class="form-group">
        <label for="realization-description">
          {{ t('persons.workExperienceForm.realizationDescription') }}:
        </label>

        <TextArea
          id="realization-description"
          v-model="realization.description"
          data-test="realization-description"
          :warning-chars="650"
          :placeholder="t('persons.workExperienceForm.placeholders.description')"
        />
      </div>

      <div class="remove-wrapper">
        <div />

        <div>
          <button
            class="remove"
            type="button"
            aria-label="remove a realization"
            @click="removeRealization(index)"
          >
            <TrashIcon />
          </button>
        </div>
      </div>
    </template>

    <div class="realizations__actions">
      <button
        class="add"
        :class="{ absolute: !!content.length }"
        type="button"
        variation="regular"
        @click="appendRealization()"
      >
        <SvgIcon type="mdi" :path="addMore" size="16" />
        {{ t('persons.workExperienceForm.addRealization') }}
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$remove-mb: 1rem;

.realizations {
  position: relative;

  .remove-wrapper {
    display: grid;
    grid-template-columns: minmax(90px, 1fr) 4fr;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
  }

  button {
    display: flex;
    align-items: center;
    padding: 0.25rem 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 0.75rem;

    &.add {
      margin-bottom: $remove-mb;
      color: $primary;

      &.absolute {
        position: absolute;
        right: 0;
        bottom: $remove-mb;
        margin-bottom: 0;
      }

      svg {
        margin-right: 0.25rem;
      }
    }

    &.remove {
      margin-bottom: $remove-mb;
      color: $gray;

      svg {
        width: 16px;
        height: 16px;
        stroke: $gray-medium;
      }
    }
  }
}
</style>
