<script setup lang="ts">
import { SvgIcon } from "@/components/icon";
import { Card } from "@/components/card";
import { Button2 as Button } from "@/components/button";

// TODO: move to modelvalue
const props = defineProps<{
  value: string
}>();

const emit = defineEmits(["on-save", "update:value"]);

const { t } = useI18n();

const editing = ref(false);

const opinion = useVModel(props, "value");

function breakLines(text: string | null) {
  return !text ? "" : text.replaceAll("\n", "<br>");
}

function onSave() {
  editing.value = false;
  emit("on-save", opinion);
}
</script>

<template>
  <Card class="simple-list__opinion">
    <img
      class="simple-list__opinion--logo"
      src="@/assets/images/flow.png"
      alt="flow logo"
    >

    <div class="simple-list__opinion-content">
      <div class="simple-list__opinion-content-text">
        <TextArea
          v-if="editing"
          v-model="opinion"
          class="text-area"
          :warning-chars="400"
        />
        <span v-else v-html="breakLines(value)" />
      </div>
      <div class="simple-list__opinion-content-edit">
        <SvgIcon
          v-if="!editing"
          icon="edit_icon"
          margin="none"
          width="1rem"
          height="1rem"
          @click="editing = true"
        />
      </div>
    </div>

    <div v-if="editing" class="simple-list__opinion-actions">
      <Button
        variation="secondary"
        class="opinion__action--save"
        @click="onSave()"
      >
        {{ t("persons.details.submit") }}
      </Button>
      <Button
        variation="light"
        filled
        class="opinion__action--cancel"
        @click="editing = false"
      >
        {{ t("persons.details.cancel") }}
      </Button>
    </div>
  </Card>
</template>

<style scoped lang="scss">
.simple-list {

  &__opinion {
    position: relative;
    background-color: $white-background;
    line-height: 1.4;
    padding-inline-start: 2.75rem;
    gap: 1rem;

    &--logo {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-25%, -25%);
    }

    &-content {
      display: flex;
      flex-direction: row;
      gap: 1rem;

      &-text {
        display: flex;
        flex: auto;

        .text-area {
          width: 100%;
        }
      }

      &-edit {
        cursor: pointer;
        display: flex;
        width: 1.2rem;
        height: 1.2rem;
      }
    }

    &-actions {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      margin-block-start: 1rem;
    }
  }
}
</style>
