<script setup lang="ts">
import type { Directive } from "vue";

const props = withDefaults(
  defineProps<{
    items: itemsSchema[] | null
    title?: string
    showTitle?: boolean
    showLabel?: boolean
    showCircle?: boolean
    fontSize?: string
    lineHeight?: string
  }>(),
  {
    title: "",
    items: null,
    showTitle: true,
    showLabel: true,
    showCircle: false,
    fontSize: "1rem",
    lineHeight: "1.5rem",
  },
);

function removeEmptyElement(element: Element): void {
  if (!element.textContent?.trim())
    element.parentNode?.removeChild(element);
}

const vRemoveEmpty: Directive<HTMLElement, string> = {
  mounted(el: Element) {
    removeEmptyElement(el);
  },
  updated(el: Element) {
    removeEmptyElement(el);
  },
};

function formatBreakLines(text: string | null | undefined): string {
  if (!text)
    return "";
  else return text?.replaceAll(/\n/g, "<br />");
}

interface itemsSchema {
  label?: string | null
  bullet?: boolean
  value: any
}

const { title, showTitle, items, showCircle, showLabel, fontSize, lineHeight } = toRefs(props);

function checkBullet(value: boolean | undefined) {
  if (value === undefined)
    return true;
  return value;
}
</script>

<template>
  <div v-if="items" class="resume-component">
    <div v-if="showTitle" class="resume-subtitle">
      {{ title }}
    </div>
    <div v-if="items.length" class="profile-data">
      <div v-for="(item, index) in items" :key="index" v-remove-empty class="data-item">
        <div v-if="item?.value && item.value !== ''" class="item">
          <div
            v-if="showCircle"
            class="circle"
            :class="{ 'hide-bullet': !checkBullet(item.bullet) }"
          />
          <div class="content">
            <span>
              <span v-if="showLabel && item.label" class="data-title">{{ item.label }}:</span>
              <span class="data-value" v-html="formatBreakLines(item.value)" />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.resume-component {
  display: flex;
  flex-direction: column;

  .resume-subtitle {
    margin-bottom: 0.5rem;
    color: $red-violet;
    font-size: 0.875rem;
    font-weight: 700;
  }

  .profile-data {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .content {
      flex: 1;
      font-size: v-bind("fontSize");
      line-height: v-bind("lineHeight");
    }

    .data-item {
      display: flex;
      flex-direction: column;

      .item {
        display: flex;
        flex-direction: row;

        .circle.hide-bullet {
          background: transparent;
        }

        .circle {
          width: 0.5rem;
          min-width: 0.5rem;
          height: 0.5rem;
          margin-top: calc(v-bind("fontSize") / 1.75);
          margin-right: 0.625rem;
          background: $yellow;
          border-radius: 50%;
        }

        .data-title {
          padding-right: 0.7rem;
          color: $gray-dark;
          font-weight: bold;
          line-height: 1.4;
          word-break: normal;
          white-space: normal;
        }

        .data-value {
          color: $gray-dark;
          line-height: 1.4;
          word-break: normal;
          white-space: normal;
        }
      }
    }
  }
}
</style>
