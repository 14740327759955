import { EducationalOrganizationTypesEnum } from "@/schemas/enums/corporation";
import { PROJECT_STATUS } from "@/entities/project";

const macroMarket = {
  ADM_PUBLIC: "Administração Pública",
  AGRIBUSINESS: "Agronegócio",
  CONSTRUCTION: "Construção",
  CONSUMER_GOODS: "Bens de Consumo",
  EDUCATION: "Educação",
  FINANCIAL_SERVICES: "Financeiro",
  HEALTH_SERVICES: "Saúde",
  INDUSTRY: "Indústria",
  INSURANCE: "Seguros",
  LOGISTICS_TRANSPORT: "Logística",
  MINING_STEEL: "Mineração",
  OIL_GAS: "Óleo e Gás",
  PHARMACEUTICAL: "Farmaceutica",
  PRIVATE_EQUITY: "Fundo Privado",
  RETAIL: "Varejo",
  SERVICES: "Serviços",
  TELEMARKETING: "Telemarketing",
  UTILITIES: "Utilidade Pública",
  NOT_AVAILABLE: "",
};

const microMarket = {
  ADM_PUBLIC: "Administração pública",
  ADM_STOCKS_MARKETS: "Administração de bolsas e mercados",
  AGRICULTURE: "Agricultura",
  ANIMAL_HEALTH: "Saúde Animal",
  ASSOCIATION_FEDERATION: "Associação e Federação",
  AUTOMOTIVE_ASSEMBLY: "Automotivo montadora",
  AUTOMOTIVE_DEALERSHIP: "Automotivo revendedora",
  AUTOMOTIVE_PARTS: "Automotivo peças",
  AVIATION_AEROPLANES: "Aviação aeronaves",
  AVIATION_AEROPORTS: "Aviação aeroportos",
  AVIATION_SERVICES: "Aviação serviço",
  BANKING: "Banco",
  BEAUTY: "Beleza e Estética",
  BIOTECHNOLOGY: "Biotecnologia",
  BROKERAGE: "Corretoras",
  BROKERAGE_FUNDS: "Corretoras e Fundos",
  BROKERAGE_REAL_ESTATE: "Corretora imobiliária",
  CHARGING: "Cobrança",
  CHEMICALS_SPECIALTIES: "Especialidades químicas",
  CHEMICAL_FIBERS: "Fibras químicas",
  CLINIC: "Clínica",
  CLOTHING: "Vestuário e Calçado",
  CONCESSION_MANAGEMENT: "Concessão e Gestão",
  COSMETICS: "Cosmético",
  CREDIT: "Crédito",
  DIAGNOSTICS: "Diagnóstico",
  DISTRIBUTOR: "Distribuidora",
  DISTRIBUTOR_AGRICULTURE: "Distribuidora de insumos agrícolas",
  DISTRIBUTOR_AUTO_PARTS: "Distribuidora de peças automotivas",
  DISTRIBUTOR_CAPITAL_GOODS: "Distribuidora de bens de capital",
  DISTRIBUTOR_FUEL: "Distribuidora de combustível",
  DISTRIBUTOR_QUIMICAL_PRODUCTS: "Distribuidora de produtos químicos",
  DISTRIBUTOR_TECHNOLOGY: "Distribuidora de tecnologia e eletrônicos",
  DURABLE_GOODS: "Bens duráveis",
  ECOMMERCE: "ECommerce/Marketplace",
  EDUCATION: "Educação",
  ELECTRONICS: "Eletrônico",
  ENERGY: "Energia",
  ENTERTAINMENT: "Entretenimento lazer esporte e turismo",
  EQUIPMENT: "Equipamento",
  EXCHANGE: "Câmbio",
  EXTRACTION: "Extração",
  FAMILY_OFFICE: "Family Office",
  FERTILIZERS_DEFENSIVES: "Fertilizantes e Defensivos",
  FISHING_AQUACULTURE: "Pesca e Aquicultura",
  FOOD: "Alimentação",
  FOOD_DRINKS: "Alimentos e Bebidas",
  FOOD_INGREDIENTS: "Ingredientes para alimentos",
  FURNITURE: "Móveis",
  FURNITURE_UTENSILS: "Móveis e Utensílios",
  GAS: "Gás",
  HIGHWAYS: "Rodoviário",
  HOSPITAL: "Hospital",
  HOSPITAL_SUPPLY: "Suprimento hospitalar",
  HOTELS_BUSINESS: "Hotelaria",
  IMPORT_EXPORT: "Importação e Exportação",
  INCORPORATION: "Incorporação",
  INDUSTRY: "Industrial",
  INFRASTRUCTURE: "Infraestrutura",
  INSTALLATION_MAINTENANCE: "Instalação e Manutenção",
  INSURANCE: "Seguradoras",
  INVESTMENTS: "Investimento",
  INVESTMENT_FUNDS_REAL_STATE: "Fundos de investimento e Gestão imobiliária",
  LEASE: "Locação",
  LIVESTOCK: "Pecuária",
  LOGISTICS: "Logística",
  LUXURY_ITEMS: "Artigos de luxo",
  MACHINERY: "Maquinário",
  MACHINERY_EQUIPMENTS: "Máquinas e Equipamentos",
  MARITIME: "Marítimo",
  MATERIALS_CONSTRUCTION: "Materiais de construção",
  MATERIALS_ELECTRONICS: "Materiais elétricos",
  MEDIA: "Mídia (veículos)",
  MEDICINE: "Medicamentos",
  MINING_STEEL: "Mineração & Siderurgia",
  MOBILITY: "Mobilidade",
  NON_METALLIC: "Não metálicos",
  OPTICS: "Ópticos",
  OTHERS: "Outros",
  OUTSOURCING: "Terceirização de mão de obra",
  PACKAGING: "Embalagem",
  PAPER: "Papel e Celulose",
  PARTS: "Peças e Equipamentos",
  PAYMENTS: "Meios de pagamento/Benefício",
  PETROCHEMICALS: "Petroquímico",
  PHARMACEUTICALS: "Farmacêutico",
  PHARMACOCHEMICAL: "Farmoquímico",
  PLASTICS: "Plásticos resinas e elastômeros",
  PRINTING: "Impressão e Reprodução",
  PRIVATE_EQUITY: "Fundo privado",
  PRODUCTION: "Produção",
  PRODUCTS_HYGIENE: "Produtos de higiene e Limpeza",
  PRODUCTS_MEDICAL: "Produtos médicos",
  RAILROADS: "Ferroviário",
  RECREATIONAL: "Recreativo e Esportivo",
  REFUSE: "Lixo",
  REINSURER: "Resseguradora",
  RESEARCH: "Pesquisa",
  RESEARCH_CLINIC: "Pesquisa clínica",
  RESIDENTIAL_CORPORATE: "Residencial e Corporativo",
  SANITATION: "Água e Saneamento",
  SERVICES: "Serviço",
  SERVICES_MANAGEMENT: "Serviços e Gerenciamento",
  SERVICES_PROFESSIONAL: "Serviços profissionais",
  SERVICES_PROVIDERS: "Prestadores de serviços",
  SERVICES_SYSTEMS: "Sistemas e Serviços",
  SHOPPING_CENTERS: "Shopping centers",
  SOCIAL_ASSISTANCE: "Assistência social",
  STORAGE: "Armazenamento",
  SUGAR_ETHANOL: "Açúcar e Etanol",
  TELECOMMUNICATIONS: "Telefonia e Internet",
  TELEVISION: "Televisão",
  THIRD_SECTOR: "Terceiro setor",
  TOBACCO: "Fumo",
  TOOLS: "Ferramentas",
  TRADING: "Trading",
  UNSKILLED: "Não especializado",
  UTENSILS: "Utensílios",
  VENTURE_CAPITAL: "Venture Capital",
  VET: "Veterinário",
  WELFARE: "Previdência",
  WELFARE_FOUNDATION: "Fundação de previdência",
};

const businessTypes = {
  PUBLICLY_TRADED: "Capital Aberto",
  FAMILY_CONTROL: "Controle Familiar",
  BRAZILIAN_MULTINATIONAL: "Multinacional Brasileira",
  STARTUP: "Startup",
  STARTUP_WITH_PARTNER_FUND_PE_OR_VC: "Startup + Sócio/Fundo (PE ou VC)",
  STRATEGIC_PARTNER_JV: "Sócio estratégico",
};

const businessLevels = {
  CEO_OWNER: "CEO Dono",
  FAMILY_BUSINESS: "Família na Operação",
  PROFESSIONAL_MANAGEMENT: "Profissionalizada",
};

const businessHeadquarters = {
  BR: "Brasileira",
  EMPTY: "Multinacional",
  OTHER_WITH_COUNTRY: "Multinacional ({country})",
};

const businessEquityTypes = {
  investor: {
    INVESTOR_FUND_PE: "Investidora / Fundo / PE",
    HOLDING_CONTROLLER: "Holding / Controladora",
  },
  subsidiary: {
    SUBSIDIARY: "Subsidiária",
    BUSINESS_UNITY: "Unidade de negócio",
    FUND_INVESTED: "Investida de fundo",
  },
};

const numberOfEmployees = {
  "1_TO_50": "De 1 a 50 funcionários",
  "51_TO_100": "De 51 a 100 funcionários",
  "101_TO_500": "De 101 a 500 funcionários",
  "501_TO_1000": "De 501 a 1.000 funcionários",
  "1001_TO_5000": "De 1.001 a 5.000 funcionários",
  "5001_TO_10000": "De 5.001 a 10.000 funcionários",
  "MORE_THAN_10000": "Mais de 10.000 funcionários",
};

const strategyStatus = ["ATIVO", "INATIVO"];

const transformationEventTypes = {
  IPO: "IPO",
  FUSION_AND_ACQUISITION: "Fusão e Aquisição ( M&A )",
  CHANGE_OF_CONTROL_OR_SELL: "Troca de controle/Venda",
  PROFESSIONALIZATION_FAMILY_OUT: "Profissionalização ( Família saiu da operação )",
  PROFESSIONALIZATION_BOARDS_AND_COMMITTEES: "Profissionalização ( Constituição de Conselhos e Comitê )",
  ACCELERATED_GROWTH_OR_INVESTMENT_ROUNDS: "Crescimento Acelerado / Rodadas de Investimento",
  DOWNSIZING: "Downsizing",
  JUDICIAL_RECOVERY: "Recuperação Judicial",
  INTERNATIONALIZATION: "Internacionalização",
  ORGANIC_GROWTH: "Crescimento Orgânico",
  UNIT_CLOSURE: "Fechamento de Unidade",
  COMPANY_CLOSING: "Fechamento de Empresa",
  SYSTEM_IMPLEMENTATION: "Implementação de sistema",
  DIGITAL_TRANSFORMATION: "Transformação Digital",
  CHANNEL_TRANSFORMATION: "Transformação de Canal",
  PRODUCT_TRANSFORMATION: "Transformação de produto",
  PROCESS_TRANSFORMATION: "Transformação de Processo",
  BRAND_TRANSFORMATION: "Transformação de Marca",
  AREA_MERGE: "Merge de Área",
  CHAIR_MERGE: "Merge de Cadeira",
};

export const corporations = {
  checkConflict: {
    createCorporation: "Criar nova empresa",
    createNewCorporation: "Continuar e criar nova empresa",
    corporationsFound: "Nenhuma empresa encontrada | 1 empresa encontrada | {n} empresas encontradas",
    linkAndEdit: "Ver e Editar",
    newAnalysis: "Fazer nova análise",
    where: "na",
    continue: "Continuar",
    linkCompany: "Vincular empresa",
    saveNewProfile: "Salvar novo perfil de empresa",
    addNewProfile: "Adicionar perfil de empresa",
    addNewCompany: "Adicionar Nova Empresa",
    companyNotFound: "nome de empresa não encontrado na base",
  },
  filters: "FILTROS",
  isEducationalOrganization: "Instituição de ensino",
  createCompany: "Criar empresa",
  searchCorporation: "buscar empresas",
  searchCorporationError: "informe pelo menos 2 caracteres para fazer a busca",
  errorListCorporations: "Houve um erro ao carregar as empresas. Tente novamente.",
  notFoundSearch: "Não foi possível achar empresa alguma com essa busca.",
  relevants: "Relevantes",
  recents: "Recentes",
  tagFilter: "Digite {tagFilter}",
  tagFilterClear: "Limpar filtro",
  tagFilters: {
    keywords: ["Palavras chave", "a palavra-chave"],
    market: ["Mercado", "o mercado"],
    sector: ["Linha de negócio", "linha de negócio"],
    consultant: ["Consultor", "o consultor"],
    governance: ["Governança", "a governança"],
    strategy: ["Estratégia", "a estratégia"],
    transformationEvents: ["Ev. de transformação", "o evento de transformação"],
    openVacancies: ["Vagas em aberto", "a vaga"],
    revenue: ["Faturamento", "o faturamento"],
  },
  sortBy: "Ordernar por",
  sector: {
    name: "Mercado Macro",
    enum: macroMarket,
  },
  employees: {
    number: "N° de funcionários",
    enum: numberOfEmployees,
  },
  enums: {
    educationalOrganizationTypes: {
      [EducationalOrganizationTypesEnum.PRIVATE]: "Privada",
      [EducationalOrganizationTypesEnum.PUBLIC]: "Pública",
    },
  },
  yearlyIncome: "Faturamento anual",
  jobVacancies: "Vagas em aberto",
  placements: "Placements",
  phone: "Telefone",
  contact: "Contato",
  location: "Local",
  name: "Nome",
  domain: "Domínio",
  socialNetworks: "Redes Sociais",
  corporationCard: {
    refreshed: "Última vez que foi atualizado",
    edit: "Editar",
    employeesAlt: "Funcionário da empresa",
    details: "Ver detalhes",
  },
  details: {
    submit: "Salvar Alterações",
    cancel: "Cancelar",
    validations: {
      yearWith4Numbers: "O ano deve ter 4 números",
    },
    placeholders: {
      history: "Histórico da empresa",
      details: "Detalhes",
      noTransformationEvent: "Ainda não foram adicionados eventos de transformação",
    },
    backToList: "Voltar para a lista",
    tabs: {
      about: "Sobre",
      transformationEvents: "Eventos de transformação",
      chart: "Organograma",
      position: "Projetos",
      historic: "Histórico",
      relationship: "Relacionamento",
      insights: "Insights",
    },
    about: "Sobre",
    historic: "Histórico",
    foundationYear: "Ano de fundação",
    governance: "Governança",
    headquarters: {
      name: "Matriz",
      enum: businessHeadquarters,
    },
    type: {
      name: "Tipo",
      enum: businessTypes,
    },
    level: {
      name: "Nível",
      enum: businessLevels,
    },
    economicGroup: "Grupo Econômico",
    investor: "Investidora",
    companyRole: "Papel da Empresa",
    description: "Descrição",
    subsidiary: "Subsidiária",
    ceo: "CEO",
    details: "Detalhes",
    employeesNumber: "Numero de funcionários",
    annualRevenue: "Faturamento Anual",
    currentRevenue: "Faturamento",
    netWorth: "Patrimônio Líquido",
    macroMarket: {
      name: "Mercado Macro",
      enum: macroMarket,
    },
    microMarket: {
      name: "Mercado Micro",
      enum: microMarket,
    },
    sector: "Linha de Negócio",
    strategy: "Estratégia",
    mainProduct: "Principal produto",
    secondaryProducts: "Produtos secundários",
    geographicalPresence: "Presença geográfica",
    corporationMoment: "Momento da empresa",
    businessPlans: "Planos de negócio",
    structuringProjects: "Projetos estruturantes",
    aquisitionProjects: "Projetos de aquisições",
    assetDisposalProjects: "Projetos de vendas de ativos",
    marketCurrentScenario: "Cenário atual do mercado",
    strategyStatus: {
      name: "Status da estratégia",
      enum: strategyStatus,
    },
    mainInvestor: "Principal investidor",
    linkInvestorAndParentCompanies: "Vincular a Investidora e Controladoras",
    linkSubsidiaryAndInvestedCompanies: "Subsidiárias e Empresas Investidas",
    businessEquityType: {
      name: "Papel da empresa",
      enum: businessEquityTypes,
    },
    forms: {
      placeholders: {
        netWorth: "100.000.000,00",
        currency: "R$",
        year: "ano",
        addYear: "adicionar ano",
        employeesSelect: "ex.: 800",
        removeInvestor: "Remover investidor",
        eventActionDescription: "Descrição das ações do evento",
        eventGoalDescription: "Descrição dos objetivos do evento",
        ongoingEvent: "Evento em andamento",
      },
    },
    name: "Nome",
    locality: "Localização",
    domain: "Domínio",
    phone: "Telefone",
    facebook: "Facebook",
    linkedin: "Linkedin",
    addRelationship: "adicionar relacionamento",
    forecast: "previsão",
    transformationEventType: {
      name: "Tipo do evento",
      enum: transformationEventTypes,
    },
    action: "Ação",
    goal: "Objetivo",
    addTransformationEvent: "Adicionar evento",
  },
  mockData: {
    filters: [
      {
        name: "Palavras-Chave",
        options: [],
      },
      {
        name: "Linha de negócio",
        options: ["R&S", "Alimentício", "Transportes"],
      },
      {
        name: "Proprietário",
        options: ["Ana Paula Ramos", "Bernardo Cavour", "Luiz Gustavo Mariano"],
      },
      {
        name: "N° de funcionários",
        options: [],
      },
    ],
  },
  clearFilters: "limpar filtros",
  clearFilter: "Limpar filtro",
  create: {
    title: "Criar nova empresa",
    basicData: {
      title: "Dados Básicos",
      name: "Nome",
      about: "Sobre",
      domain: "Domínio",
      locality: "Localização",
      contact: "Contato",
      linkedin: "Linkedin",
      facebook: "Facebook",
      microMarket: "Merc. Micro",
      macroMarket: "Merc. Macro",
    },
    avatar: {
      title: "Avatar",
      helpText: "A imagem deve ter tamanho mínimo de 200px X 200px e ser to tipo JPG ou PNG",
    },
    governance: {
      title: "Governança",
      hasEconomicGroup: "Faz parte de um grupo econômico?",
      yes: "sim",
      no: "não",
    },
    save: "Salvar empresa",
    clear: "Limpar perfil",
    clean: "Limpar",
    continue: "Continuar",
    back: "Voltar",
    successMessage: "Empresa cadastrada com sucesso!",
    clearMessage:
      "Ao limpar, todos os dados preenchidos serão perdidos, deseja continuar com a ação?",
  },
  project: {
    table: {
      field: {
        type: "Tipo",
        project: "Projeto",
        period: "Duração",
        participants: "Responsáveis",
        status: "Status",
      },
    },
    cell: {
      status: {
        [PROJECT_STATUS.ACTIVE]: "Em andamento",
        [PROJECT_STATUS.SUSPENDED]: "Suspenso",
        [PROJECT_STATUS.CANCELLED]: "Cancelado",
        [PROJECT_STATUS.DRAFT]: "Rascunho",
        data_unavailable: "Dado indisponível",
      },
    },
  },
};
