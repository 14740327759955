import { z } from "zod";
import { CandidatesSchema } from "@/types/candidates";
import { InterviewAssessmentSchema } from "@/types/interviews";

export interface pageSchema {
  id?: string
  page?: number
  show?: boolean
}

export enum ConstructionEnum {
  FRONT_COVER = "FRONT_COVER",
  SUMMARY = "SUMMARY",
  COMPANY = "COMPANY",
  GOVERNANCE = "GOVERNANCE",
  STRATEGY = "STRATEGY",
  EXECUTION_CONDITIONS = "EXECUTION_CONDITIONS",
  COMPANY_PROFILE = "COMPANY_PROFILE",
  IDEAL_PROFILE_TAGS = "IDEAL_PROFILE_TAGS",
  IDEAL_PROFILE = "IDEAL_PROFILE",
  ATTRIBUTES = "ATTRIBUTES",
  CULTURAL_MATCH = "CULTURAL_MATCH",
  MOTIVATION = "MOTIVATION",
  SEARCH_FIELD = "SEARCH_FIELD",
  TIMELINE = "TIMELINE",
  BACK_COVER = "BACK_COVER",
}

enum ShortlistEnum {
  FRONT_COVER = "FRONT_COVER",
  SUMMARY = "SUMMARY",
  COMPANY = "COMPANY",
  COMPANY_PROFILE = "COMPANY_PROFILE",
  IDEAL_PROFILE_TAGS = "IDEAL_PROFILE_TAGS",
  IDEAL_PROFILE = "IDEAL_PROFILE",
  REALIZATIONS = "REALIZATIONS",
  ATTRIBUTES = "ATTRIBUTES",
  CULTURAL_MATCH = "CULTURAL_MATCH",
  MOTIVATION = "MOTIVATION",
  SEARCH_FIELD = "SEARCH_FIELD",
  PROCESS_FUNNEL = "PROCESS_FUNNEL",
  ADHERENCE_TABLE = "ADHERENCE_TABLE",
  ADHERENCE_TABLE_FINAL = "ADHERENCE_TABLE_FINAL",
  LAST_ADHERENCE_TABLE = "LAST_ADHERENCE_TABLE",
  USERS = "USERS",
  TIMELINE = "TIMELINE",
  BACK_COVER = "BACK_COVER",
}

enum ShortlistPersonEnum {
  USER_CARD_PERSON = "USER_CARD_PERSON",
  USER_ABOUT = "USER_ABOUT",
  USER_ACADEMIC = "USER_ACADEMIC",
  USER_EXPERIENCE = "USER_EXPERIENCE",
  USER_REALIZATIONS = "USER_REALIZATIONS",
  USER_ATTRIBUTES = "USER_ATTRIBUTES",
  USER_CULTURAL_MATCH = "USER_CULTURAL_MATCH",
  USER_MOTIVATION = "USER_MOTIVATION",
  USER_MOTIVATION_ONE = "USER_MOTIVATION_ONE",
  USER_MOTIVATION_TWO = "USER_MOTIVATION_TWO",
  USER_MOTIVATION_FACTORS = "USER_MOTIVATION_FACTORS",
  USER_EVALUATION = "USER_EVALUATION",
  USER_EVALUATION_FINAL = "USER_EVALUATION_FINAL",
}

enum ShortlistSumaryI18nEnum {
  IDEAL_PROFILE = "idealProfile",
  SEARCH_FIELD = "searchField",
  PROCESS_FUNNEL = "processFunnel",
  ADHERENCE_TABLE = "adherenceTable",
  CANDIDATES_ANALYSIS = "candidatesAnalysis",
}

export interface ShortlistPages {
  [ShortlistEnum.FRONT_COVER]: pageSchema
  [ShortlistEnum.SUMMARY]: pageSchema
  [ShortlistEnum.COMPANY]: pageSchema
  [ShortlistEnum.IDEAL_PROFILE]: pageSchema
  [ShortlistEnum.REALIZATIONS]: pageSchema
  [ShortlistEnum.MOTIVATION]: pageSchema
  [ShortlistEnum.SEARCH_FIELD]: pageSchema
  [ShortlistEnum.PROCESS_FUNNEL]: pageSchema
  [ShortlistEnum.ADHERENCE_TABLE]: pageSchema
  [ShortlistEnum.USERS]: ShortlistPersonPages[]
  [ShortlistEnum.BACK_COVER]: pageSchema
}

export interface ShortlistPersonPages {
  [ShortlistPersonEnum.USER_CARD_PERSON]: pageSchema
  [ShortlistPersonEnum.USER_ABOUT]: pageSchema
  [ShortlistPersonEnum.USER_ACADEMIC]: pageSchema
  [ShortlistPersonEnum.USER_EXPERIENCE]: pageSchema
  [ShortlistPersonEnum.USER_REALIZATIONS]: pageSchema
  [ShortlistPersonEnum.USER_ATTRIBUTES]: pageSchema
  [ShortlistPersonEnum.USER_CULTURAL_MATCH]: pageSchema
  [ShortlistPersonEnum.USER_MOTIVATION]: pageSchema
  [ShortlistPersonEnum.USER_MOTIVATION_ONE]: pageSchema
  [ShortlistPersonEnum.USER_MOTIVATION_TWO]: pageSchema
  [ShortlistPersonEnum.USER_MOTIVATION_FACTORS]: pageSchema
  [ShortlistPersonEnum.USER_EVALUATION]: pageSchema
}

export interface ConstructionPages {
  [ConstructionEnum.FRONT_COVER]: pageSchema
  [ConstructionEnum.SUMMARY]: pageSchema
  [ConstructionEnum.COMPANY]: pageSchema
  [ConstructionEnum.GOVERNANCE]: pageSchema
  [ConstructionEnum.STRATEGY]: pageSchema
  [ConstructionEnum.EXECUTION_CONDITIONS]: pageSchema
  [ConstructionEnum.REALIZATIONS]: pageSchema
  [ConstructionEnum.IDEAL_PROFILE]: pageSchema
  [ConstructionEnum.COMPANY_PROFILE]: pageSchema
  [ConstructionEnum.ATTRIBUTES]: pageSchema
  [ConstructionEnum.CULTURAL_MATCH]: pageSchema
  [ConstructionEnum.MOTIVATION]: pageSchema
  [ConstructionEnum.SEARCH_FIELD]: pageSchema
  [ConstructionEnum.TIMELINE]: pageSchema
  [ConstructionEnum.BACK_COVER]: pageSchema
}

enum NumberOfEmployeesEnum {
  // Corporation size by it' employee number range.

  ONE_TO_FIFTY = "1_TO_50",
  FIFTY_ONE_TO_HUNDRED = "51_TO_100",
  HUNDRED_ONE_TO_FIVE_HUNDRED = "101_TO_500",
  FIVE_HUNDRED_ONE_TO_THOUSAND = "501_TO_1000",
  THOUSAND_ONE_TO_FIVE_THOUSAND = "1001_TO_5000",
  FIVE_THOUSAND_ONE_TO_TEN_THOUSAND = "5001_TO_10000",
  MORE_THAN_TEN_THOUSAND = "MORE_THAN_10000",
}

const Schedule = z.object({
  start_date: z.coerce.date().nullable().optional(),
  end_date: z.coerce.date().nullable().optional(),
  checkpoint_date: z.coerce.date().nullable().optional(),
  checkpoint_done_date: z.coerce.date().nullable().optional(),
});

const ProjectSchedules = z.object({
  construction: Schedule.optional(),
  mapping: Schedule.optional(),
  screenings: Schedule.optional(),
  interviews: Schedule.optional(),
  shortlist: Schedule.optional(),
  under_offer: Schedule.optional(),
  placement: Schedule.optional(),
});

export const ProjectCountersSchema = z.object({
  mapping: z.number(),
  screenings: z.number(),
  interviews: z.number(),
  shortlist: z.number(),
  under_offer: z.number(),
  placement: z.number(),
}).strip();

export type ProjectCounters = z.infer<typeof ProjectCountersSchema>;

export const ProjectSchema = z
  .object({
    title: z.string().optional(),
    companyName: z.string().optional(),
    positionName: z.string().optional(),
    counters: ProjectCountersSchema.optional(),
    company_historic: z.string().optional().nullable(),
    company_description: z.string().optional().nullable(),
    company_foundation_year: z.string().optional().nullable(),
    confidential_placement: z.boolean().optional().nullable(),
  })
  .strict();

export const CorporationSchema = z
  .object({
    revenues: z.array(
      z.object({
        amount: z.number(),
        year: z.string(),
        currency: z.string(),
        max_amount: z.number().nullable().optional(),
      }).strict(),
    ).optional(),
    number_of_employees: z.nativeEnum(NumberOfEmployeesEnum).nullable(),
    description: z.string(),
    foundation_year: z.string().optional().nullable(),
    historic: z.string(),
  })
  .strict();

const AnalysisTypesEnum = z.enum([
  "STRATEGY",
  "GOVERNANCE",
  "EXECUTION_CONDITIONS",
  "IDEAL_PROFILE",
  "REALIZATIONS",
]);

const AnalysisSchema = z.object({
  analysis_type: AnalysisTypesEnum,
  description: z.string(),
  insight: z.string().nullable(),
});

const ConstructionTagSchema = z.object({
  text: z.string(),
  selected: z.boolean().nullable().default(true),
});

const CategorySchema = z.object({
  analyses: z.array(AnalysisSchema).default([]),
  consultant_opinion: z.string().default(""),
  tags: z.array(ConstructionTagSchema).nullable().default([]),
});

enum StatusEnum {
  DRAFT = "DRAFT",
  ACTIVE = "ACTIVE",
  CONCLUDED = "CONCLUDED",
  SUSPENDED = "SUSPENDED",
  CANCELLED = "CANCELLED",
}

const ProjectStages = z.object({
  construction: z.nativeEnum(StatusEnum).default(StatusEnum.ACTIVE),
  mapping: z.nativeEnum(StatusEnum).default(StatusEnum.DRAFT),
  screenings: z.nativeEnum(StatusEnum).default(StatusEnum.DRAFT),
  interviews: z.nativeEnum(StatusEnum).default(StatusEnum.DRAFT),
  shortlist: z.nativeEnum(StatusEnum).default(StatusEnum.DRAFT),
  under_offer: z.nativeEnum(StatusEnum).default(StatusEnum.DRAFT),
  placement: z.nativeEnum(StatusEnum).default(StatusEnum.DRAFT),
});

enum ScaleEnum {
  EMPTY = "EMPTY",
  LOW = "LOW",
  AVERAGE = "AVERAGE",
  HIGH = "HIGH",
  VERY_HIGH = "VERY_HIGH",
}

const ScaleEnumSchema = z.nativeEnum(ScaleEnum);

const TurnOverFactorsSchema = z.object({
  attraction: z.string().optional().default(""),
  repulsion: z.string().optional().default(""),
  retention: z.string().optional().default(""),
});

const AssemblyPackageSchema = TurnOverFactorsSchema.extend({
  salary: z.string().optional().default(""),
  short_term_incentive: z.string().optional().default(""),
  long_term_incentive: z.string().optional().default(""),
  other_incentive: z.string().optional().default(""),
  total_cash: z.string().optional().default(""),
});

enum AttributesMapEnums {
  EMPTY = "EMPTY",
  COGNITIVE_INTELLIGENCE = "COGNITIVE_INTELLIGENCE",
  EMOTIONAL_INTELLIGENCE = "EMOTIONAL_INTELLIGENCE",
  SPIRITUAL_INTELLIGENCE = "SPIRITUAL_INTELLIGENCE",
  ENERGY = "ENERGY",
  MATURITY = "MATURITY",
  POWER = "POWER",
}

const AttributesSchema = z.object({
  cognitive_intelligence: ScaleEnumSchema.default(ScaleEnum.EMPTY),
  emotional_intelligence: ScaleEnumSchema.default(ScaleEnum.EMPTY),
  spiritual_intelligence: ScaleEnumSchema.default(ScaleEnum.EMPTY),
  energy: ScaleEnumSchema.default(ScaleEnum.EMPTY),
  maturity: ScaleEnumSchema.default(ScaleEnum.EMPTY),
});

const CulturalMatchSchema = z.object({
  practical: ScaleEnumSchema.default(ScaleEnum.EMPTY),
  logical: ScaleEnumSchema.default(ScaleEnum.EMPTY),
  reflexive: ScaleEnumSchema.default(ScaleEnum.EMPTY),
  affective: ScaleEnumSchema.default(ScaleEnum.EMPTY),
});

enum CulturalMatchMapEnums {
  EMPTY = "EMPTY",
  PRACTICAL = "PRACTICAL",
  LOGICAL = "LOGICAL",
  REFLEXIVE = "REFLEXIVE",
  AFFECTIVE = "AFFECTIVE",
}

const AchievementsSchema = z.object({
  description: z.string().default(""),
  desired_attribute: z.nativeEnum(AttributesMapEnums).default(AttributesMapEnums.EMPTY),
  desired_cultural_match: z.nativeEnum(CulturalMatchMapEnums).default(CulturalMatchMapEnums.EMPTY),
});

export const IdealProfileSchema = z
  .object({
    academic_backgrounds: z.record(z.string()).default({}),
    languages: z.record(z.string()).default({}),
    // todo: fix records types
    experiences: z.record(z.string()).default({}),
    experiences_tags: z.array(ConstructionTagSchema).nullable().default([]),
    achievements: z.record(AchievementsSchema).default({}),
    achievements_tags: z.array(ConstructionTagSchema).nullable().default([]),
    attributes: AttributesSchema.default({}),
    cultural_match: CulturalMatchSchema.default({}),
    package: AssemblyPackageSchema,
    career: z.record(z.string()).default({}),
    work_environment: z.record(z.string()).default({}),
    personal_life: z.record(z.string()).default({}),
    consultant_opinion: z.string().default(""),
  })
  .strict();

export type IdealProfile = z.infer<typeof IdealProfileSchema>;

export const BusinessFunctionSchema = z
  .object({
    tag: z.string(),
    label_nickname: z.string().nullable(),
    corporations_ids: z.array(z.string()),
    corporations_data: z.array(
      z.object({
        _id: z.string(),
        name: z.string(),
        url: z.string(),
        avatar_url: z.string(),
        business_categories: z.array(
          z.object({
            sector: z.string(),
            industry: z.string(),
            is_main: z.boolean(),
          }),
        ),
        business_function: z.array(z.string()),
      }),
    ),
  })
  .strict();
const ProcessFunnel = z.object({
  presentation_counters: z.object({
    show_default: z.boolean().optional(),
    mapping: z.number().optional(),
    screenings: z.number().optional(),
    interviews: z.number().optional(),
    shortlist: z.number().optional(),
    finalists: z.number().optional(),
  }).optional(),
  presentation_descriptions: z.object({
    show_default: z.boolean().optional(),
    mapping: z.string().optional(),
    screenings: z.string().optional(),
    interviews: z.string().optional(),
    shortlist: z.string().optional(),
    finalists: z.string().optional(),
  }).optional(),
});
export const ExportSchema = z
  .object({
    project: ProjectSchema.nullable().optional(),
    corporation: CorporationSchema.nullable().optional(),
    governance: CategorySchema.nullable().optional(),
    strategy: CategorySchema.nullable().optional(),
    execution_conditions: CategorySchema.nullable().optional(),
    ideal_profile: IdealProfileSchema.nullable().optional(),
    governance_tags: z.array(ConstructionTagSchema).optional().nullable().default([]),
    strategy_tags: z.array(ConstructionTagSchema).optional().nullable().default([]),
    execution_conditions_tags: z.array(ConstructionTagSchema).optional().nullable().default([]),
    business_functions: z.array(BusinessFunctionSchema).optional().nullable(),
    stages: ProjectStages.optional().nullable(),
    schedules: ProjectSchedules.optional().nullable(),
    list_hidde_pages: z.array(z.string()),
    interviews: z.array(InterviewAssessmentSchema).optional().nullable(),
    candidates: z.array(CandidatesSchema).optional().nullable(),
    language: z.string().default("pt-BR"),
    type: z.string().optional().nullable(),
    process_funnel: ProcessFunnel.nullable().optional(),
  })
  .strict();

export type ExportPDF = z.infer<typeof ExportSchema>;
