<script lang="ts">
import type { Ref } from "vue";
import get from "lodash-es/get";
import head from "lodash-es/head";
import isNumber from "lodash-es/isNumber";
import set from "lodash-es/set";
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { email, helpers, required, requiredIf, url } from "@vuelidate/validators";
import { mdiPlusCircleOutline } from "@mdi/js";
import {
  CalendarInput,
  FormInput,
  TelephoneInput,
  TextArea,
} from "@/components/inputs/modalInputs";
import { getDateRef, watchDate } from "@/utils/dates";
import type { Person } from "@/schemas/person";
import { SvgIcon } from "@/components/icon";
import { ButtonIcon } from "@/components/button";
import { isInputEmpty } from "@/utils/validators/value-validators";
import { searchPersons } from "@/http_services/entities/persons";

export default defineComponent({
  name: "PersonalDataForm",
  components: {
    FormInput,
    CalendarInput,
    TextArea,
    TelephoneInput,
    SvgIcon,
    ButtonIcon,
  },
  props: {
    personal: Object,
    personalEdit: Object,
    autoFilledFields: Object,
    autoFilledEditFields: Object,
  },
  emits: ["hasEmail"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const payload = toRef(props, "personal") as Ref<Person>;
    const payloadEdit = toRef(props, "personalEdit") as Ref<Person>;

    const birthDate = getDateRef<Partial<Person>>("birth_date", payload);
    watch(birthDate, watchDate<Partial<Person>>("birth_date", payload));

    watch(
      payload,
      (value) => {
        const { birth_date } = value;

        if (!birth_date)
          birthDate.value = null;
      },
      { deep: true },
    );

    const linkedinValidator = helpers.regex(
      /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile|company)/,
    );

    const rules = computed(() => ({
      payload: {
        first_name: {
          required: helpers.withMessage(t("validator.required"), required),
        },
        last_name: {
          required: helpers.withMessage(t("validator.required"), required),
        },
        cellphones: {
          required: helpers.withMessage(
            t("validator.requiredFields"),
            requiredIf(() =>
              isInputEmpty(
                payload.value.linkedin_url
                  || head(payload.value.email_addresses)?.email_address
                  || head(payload.value.phones)?.number,
              )),
          ),
        },
        email_addresses: {
          $each: helpers.forEach({
            email_address: {
              email: helpers.withMessage(t("validator.email"), email),
              required: helpers.withMessage(
                t("validator.required"),
                requiredIf(() =>
                  isInputEmpty(
                    payload.value.linkedin_url
                      || head(payload.value.email_addresses)?.email_address
                      || head(payload.value.phones)?.number,
                  )),
              ),
            },
          }),
        },
        linkedin_url: {
          url: helpers.withMessage(t("validator.url"), url),
          linkedin: helpers.withMessage(t("validator.linkedin"), linkedinValidator),
          required: helpers.withMessage(
            t("validator.required"),
            requiredIf(() =>
              isInputEmpty(
                payload.value.linkedin_url
                  || head(payload.value.email_addresses)?.email_address
                  || head(payload.value.phones)?.number,
              )),
          ),
        },
      },
    }));

    const v$ = useVuelidate(rules, {
      payload,
    });

    const getPath = (
      rootName: string,
      objName: string | null,
      index: number | null = null,
    ): string => {
      if (isNumber(index))
        return `payload.${rootName}.$errors.0.$response.$errors.${index}.${objName}`;

      return `payload.${rootName}.$error`;
    };

    const addPhone = () => {
      payload.value.phones?.push({
        calling_code: "+ 55",
        number: "",
      });
    };

    const addEmail = () => {
      payload.value.email_addresses?.push({
        email_address: "",
        is_main: false,
      });
    };
    const skip = ref(0);
    const hasEmail = ref(false);
    const checkConflict = async (email) => {
      if (email.includes("@")) {
        const { data } = await searchPersons(
          {
            skip: skip.value,
          },
          email,
          undefined,
        );

        data.length >= 1 ? (hasEmail.value = true) : (hasEmail.value = false);
        emit("hasEmail", hasEmail.value);
      }
    };

    const removePhone = (index: number) => {
      payload.value.phones?.splice(index, 1);
    };

    const removeEmail = (index: number) => {
      payload.value.email_addresses?.splice(index, 1);
    };

    const removeOption = (key: string) => {
      if (props.autoFilledEditFields)
        set(props.autoFilledEditFields, key, false);
    };

    return {
      t,
      payload,
      payloadEdit,
      birthDate,
      checkConflict,
      hasEmail,
      v$,
      get,
      getPath,
      addMore: mdiPlusCircleOutline,
      addPhone,
      addEmail,
      removeEmail,
      removePhone,
      removeOption,
    };
  },
});
</script>

<template>
  <div class="container" data-test="personal-form">
    <h1>{{ t("persons.createPersonForm.personalData") }}</h1>
    <form action="" class="personal-data-form">
      <div class="form-group name">
        <label>
          {{ t("persons.createPersonForm.name") }}:<strong class="required">*</strong>
        </label>
        <div class="form-input--wrapper">
          <FormInput
            v-model="payload.first_name"
            data-test="name"
            :auto-fill="autoFilledFields?.first_name"
            :error="get(v$, 'payload.first_name.$error', false)"
            :error-message="get(v$, 'payload.first_name.$errors.0.$message', '')"
          />
          <FormInput
            v-if="autoFilledEditFields?.first_name"
            v-model="payloadEdit.first_name"
            svg-icon="trash_icon"
            show-icon
            data-test="name"
            :auto-fill="autoFilledEditFields?.first_name"
            :error="get(v$, 'payload.first_name.$error', false)"
            :error-message="get(v$, 'payload.first_name.$errors.0.$message', '')"
            @click:on-button-click="removeOption('first_name')"
          />
        </div>
      </div>

      <div class="form-group surname">
        <label>
          {{ t("persons.createPersonForm.surname") }}:<strong class="required">*</strong>
        </label>
        <div class="form-input--wrapper">
          <FormInput
            v-model="payload.last_name"
            data-test="surname"
            :auto-fill="autoFilledFields?.last_name"
            :error="get(v$, 'payload.last_name.$error', false)"
            :error-message="get(v$, 'payload.last_name.$errors.0.$message', '')"
          />
          <FormInput
            v-if="autoFilledEditFields?.last_name"
            v-model="payloadEdit.last_name"
            svg-icon="trash_icon"
            show-icon
            data-test="surname"
            :icon-click="removeOption"
            :auto-fill="autoFilledEditFields?.last_name"
            :error="get(v$, 'payload.last_name.$error', false)"
            :error-message="get(v$, 'payload.last_name.$errors.0.$message', '')"
            @click:on-button-click="removeOption('last_name')"
          />
        </div>
      </div>

      <div class="form-group">
        <label>{{ t("persons.createPersonForm.birth_date") }}:</label>
        <div class="birth-date">
          <CalendarInput v-model="birthDate" :auto-fill="autoFilledFields?.birthdate" />
        </div>
      </div>

      <div class="form-group location">
        <label>{{ t("persons.createPersonForm.location") }}:</label>
        <div class="form-input--wrapper">
          <FormInput
            v-model="payload.address.name"
            class="location-input"
            data-test="location"
            :auto-fill="autoFilledFields?.address"
            placeholder="ex.: São Paulo, SP, Brasil"
          />
          <FormInput
            v-if="autoFilledEditFields?.address"
            v-model="payloadEdit.address.name"
            svg-icon="trash_icon"
            show-icon
            class="location-input"
            data-test="location"
            :auto-fill="autoFilledEditFields?.address"
            placeholder="ex.: São Paulo, SP, Brasil"
            @click:on-button-click="removeOption('address')"
          />
        </div>
      </div>

      <div class="form-group">
        <label>{{ t("persons.createPersonForm.phone") }} :</label>

        <div class="multiple-inputs">
          <div
            v-for="(telephone, index) in payload.phones"
            :key="`telephone-${index}`"
            class="control"
          >
            <div class="form-input--wrapper">
              <TelephoneInput
                v-model:countryCode="telephone.calling_code"
                v-model:phoneNumber="telephone.number"
                :auto-fill="autoFilledFields?.phones"
                :error="v$.payload.cellphones.required.$error"
                :error-message="v$.payload.cellphones.required.$message"
              />
              <TelephoneInput
                v-if="autoFilledEditFields?.phones && payloadEdit?.phones?.length"
                v-model:countryCode="payloadEdit.phones[index].calling_code"
                v-model:phoneNumber="payloadEdit.phones[index].number"
                :auto-fill="autoFilledEditFields?.phones"
                :error="v$.payload.cellphones.required.$error"
                :error-message="v$.payload.cellphones.required.$message"
                svg-icon="trash_icon"
                show-icon
                @click:on-button-click="removeOption('phones')"
              />
            </div>
            <ButtonIcon v-if="index > 0" class="remove-button" @click.prevent="removePhone(index)">
              <SvgIcon icon="trash_icon" width="20" height="20" margin="none" />
            </ButtonIcon>
          </div>
          <a class="add" @click="addPhone">
            <SvgIcon
              type="mdi"
              :icon="addMore"
              margin="right"
              width="16px"
              height="16px"
            />
            <span>{{ t("persons.createPersonForm.addPhone") }}</span>
          </a>
        </div>
      </div>

      <div class="form-group">
        <label>{{ t("persons.createPersonForm.email") }}:</label>

        <div class="multiple-inputs">
          <!-- eslint-disable max-len -->
          <div
            v-for="(email, index) in payload.email_addresses"
            :key="`email-${index}`"
            class="control"
          >
            <div class="form-input--wrapper">
              <FormInput
                v-model="email.email_address"
                class="input"
                :auto-fill="autoFilledFields?.email_addresses"
                :placeholder="t('placeholders.email')"
                :error="
                  !!get(v$, `${getPath('email_addresses', 'email_address', index)}.length`, 0)
                "
                :error-message="
                  get(v$, `${getPath('email_addresses', 'email_address', index)}.0.$message`, '')
                "
                @update:model-value="checkConflict(email.email_address)"
              />
              <FormInput
                v-if="autoFilledEditFields?.email_addresses && payloadEdit?.email_addresses?.length"
                v-model="payloadEdit.email_addresses[index].email_address"
                svg-icon="trash_icon"
                show-icon
                class="input"
                :auto-fill="autoFilledEditFields?.email_addresses"
                :placeholder="t('placeholders.email')"
                :error="
                  !!get(v$, `${getPath('email_addresses', 'email_address', index)}.length`, 0)
                "
                :error-message="
                  get(v$, `${getPath('email_addresses', 'email_address', index)}.0.$message`, '')
                "
                @click:on-button-click="removeOption('email_addresses')"
              />
              <span v-show="hasEmail" class="error-wrapper">
                {{ t("persons.details.emailExist") }}</span>
            </div>
            <ButtonIcon v-if="index > 0" class="remove-button" @click.prevent="removeEmail(index)">
              <SvgIcon icon="trash_icon" width="20" height="20" margin="none" />
            </ButtonIcon>
          </div>
          <a class="add" @click="addEmail">
            <SvgIcon
              type="mdi"
              :icon="addMore"
              margin="right"
              width="16px"
              height="16px"
            />
            <span>{{ t("persons.createPersonForm.addEmail") }}</span>
          </a>
        </div>
      </div>

      <div class="form-group linkedin">
        <label>{{ t("persons.createPersonForm.linkedin") }}:</label>
        <div class="form-input--wrapper">
          <FormInput
            v-model="payload.linkedin_url"
            data-test="linkedin"
            :auto-fill="autoFilledFields?.linkedin_url"
            placeholder="ex.: https://www.linkedin.com/in/usuario"
            :error="get(v$, 'payload.linkedin_url.$error', false)"
            :error-message="get(v$, 'payload.linkedin_url.$errors.0.$message', '')"
          />
          <FormInput
            v-if="autoFilledEditFields?.linkedin_url"
            v-model="payloadEdit.linkedin_url"
            svg-icon="trash_icon"
            show-icon
            data-test="linkedin"
            :auto-fill="autoFilledEditFields?.linkedin_url"
            placeholder="ex.: https://www.linkedin.com/in/usuario"
            :error="get(v$, 'payload.linkedin_url.$error', false)"
            :error-message="get(v$, 'payload.linkedin_url.$errors.0.$message', '')"
            @click:on-button-click="removeOption('linkedin_url')"
          />
        </div>
      </div>

      <div class="form-group textarea-wrapper">
        <div class="textarea-input--wrapper">
          <TextArea
            v-model="payload.description"
            class="description"
            data-test="description"
            :auto-fill="autoFilledFields?.description"
            :warning-chars="650"
            :placeholder="t('persons.createPersonForm.description')"
          />
          <TextArea
            v-if="autoFilledEditFields?.description"
            v-model="payloadEdit.description"
            class="description"
            svg-icon="trash_icon"
            show-icon
            data-test="description"
            :auto-fill="autoFilledEditFields?.description"
            :warning-chars="650"
            :placeholder="t('persons.createPersonForm.description')"
            @click:on-button-click="removeOption('description')"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<style lang="scss" scoped>
.form-input--wrapper {
  width: 80%;
}

.textarea-input--wrapper {
  width: 100%;
}

.container {
  width: 100%;

  h1 {
    margin-bottom: 1rem;
    color: $primary;
    font-size: 1.125rem;
    text-transform: uppercase;
  }
}

.personal-data-form {
  .form-group {
    display: flex;
    margin-bottom: 0.5rem;

    label {
      position: relative;
      bottom: 5px;
      display: flex;
      width: 20%;
      height: 28px;
      align-items: flex-end;
      color: $gray-dark;
      font-family: "Open Sans";
      font-size: 14px;
      letter-spacing: 0.5px;

      .required {
        color: $red;
      }
    }

    .component-input {
      flex-grow: 1;

      .input-wrapper {
        input {
          font-family: "Open Sans";

          &::placeholder {
            font-size: 13px;
          }
        }
      }
    }

    .location-input {
      width: 40%;
      flex-grow: 0;
    }

    .multiple-inputs {
      width: 80%;

      .control {
        display: grid;
        margin-bottom: 0.5rem;
        grid-template-columns: 9fr 1fr;
      }

      .add {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-right: 10%;
        color: $primary;
        cursor: pointer;
        font-size: 0.75rem;

        span {
          color: $primary;
        }
      }
    }
  }
}

.error-wrapper {
  display: flex;
  width: 100%;
  height: 15px;
  justify-content: flex-start;
  padding-top: 2px;
  margin-right: 5%;
  margin-left: 5px;
  color: $red-dark;
  font-size: 10px;
  font-weight: 500;
}
</style>
