<script lang="ts">
import { defineComponent, ref } from "vue";
import Multiselect from "@vueform/multiselect";

export default defineComponent({
  name: "CustomSelect",
  components: {
    Multiselect,
  },
  props: {
    placeholder: String,
    width: Number,
    onOptionChange: Function,
    options: Array,
    errorMessage: String,
    error: Boolean,
    disabled: Boolean,
    valueProp: String,
  },
  setup() {
    const selectComponent = ref(null);

    const content = ref(null);

    const clear = () => {
      selectComponent.value.clear();
    };

    const setValue = (value: string | string[]) => {
      content.value = value;
    };

    return {
      content,
      clear,
      setValue,
      selectComponent,
    };
  },
});
</script>

<template>
  <div class="component-select" :style="{ width: `${width}px` }">
    <Multiselect
      ref="selectComponent"
      v-model="content"
      :options="options"
      :clear-on-select="false"
      :can-clear="false"
      :placeholder="placeholder"
      :class="{ error: error || errorMessage }"
      :disabled="disabled"
      :value-prop="valueProp"
      :searchable="true"
      @change="onOptionChange"
    />
    <div class="error-wrapper" data-test="message-error">
      <span>{{ errorMessage }}</span>
    </div>
  </div>
</template>

<style scoped lang="scss">
.component-select {
  min-height: 40px;

  .multiselect {
    min-height: 28px;
    border: solid var(--ms-border-width) #c0c0c0;
    border-radius: 6px;
    font-size: 14px;
    transition: all linear 250ms;

    &.error {
      border: var(--ms-border-width) solid $red;
    }

    &.is-open {
      min-height: 28px !important;
      border: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: var(--ms-radius, 4px);
      border-top-right-radius: var(--ms-radius, 4px);
      box-shadow: 0 3px 6px #00000029;

      .multiselect-dropdown {
        border: none;
        box-shadow: 0 3px 6px #00000029;
      }
    }

    .multiselect-single-label,
    .multiselect-option {
      color: #666;
    }

    // .multiselect-dropdown {
    //   overflow-y: scroll;
    // }

    .multiselect-placeholder {
      padding-right: calc(var(--ms-px, 0.875rem) *3);
    }

    .multiselect-caret {
      background-color: transparent;
      -webkit-mask-image: none;
      mask-image: none;

      &::before {
        position: relative;
        display: inline-block;

        /* By using an em scale, the arrows will size with the font */
        width: 0.5em;
        height: 0.5em;
        border-right: 0.15em solid #c0c0c0;
        border-bottom: 0.15em solid #c0c0c0;
        margin-bottom: 0.1em;

        /* top: 3pt; Uncomment this to lower the icons as requested in comments */
        content: "";
        transform: rotate(45deg);
      }
    }
  }

  .error-wrapper {
    display: flex;
    width: 100%;
    height: 15px;
    justify-content: flex-start;

    span {
      padding-top: 2px;
      margin-right: 5%;
      margin-left: 5px;
      color: $red;
      font-size: 10px;
      font-weight: 500;
    }
  }
}

:root {
  --ms-border-color: #c0c0c0;
  --ms-ring-color: #000029;
  --ms-ring-width: 1px;
  --ms-tag-bg: #fff;
  --ms-option-bg-selected: #fff;
  --ms-option-bg-selected-pointed: #fff;
  --ms-option-color-selected: #313131;
  --ms-option-color-selected-pointed: #666;
  --ms-border-width: 1px;
}
</style>

<style global lang="scss">
:root {
  --ms-option-bg-selected-pointed: #fff;
  --ms-option-bg-selected: #fff;
}
</style>
