export const persons = {
  activity: {
    tabs: {
      historic: "Historic",
      projects: "Projects",
      activities: "Activities",
      relationship: "Relashionship",
    },
  },
  add: "add",
  addToProject: "Add to project",
  seeDetails: "See details",
  bulkAction: "Bulk action",
  relevants: "Relevants",
  recents: "Recents",
  sortBy: "Sort by",
  filters: "FILTERS",
  clearFilters: "Clear Filters",
  searchPerson: "search person",
  searchProject: "search project",
  searchPersonError: "type at least 2 characters to do the search",
  createPerson: "CREATE PERSON",
  errorListPerson: "An erro occurred while loading person. Try again later",
  notFoundSearch: "Cannot find any person with this search",
  mauiMapping: "Maui is mapping the best profiles",
  errorListProjects: "An erro occurred while loading person. Try again later",
  notFoundProjectsSearch: "Cannot find any project with this search",
  profileNotFound: "We were unable to find any results for this search",
  attributeEmpty: "There is no information about this attribute yet.",
  addAttribute: "ADD",
  restrictedProfile: "Restricted profile",
  tagFilter: "Insert the {tagFilter}",
  tagFilterClear: "Clear filter",
  tagFilters: {
    keywords: ["keywords", "keywords"],
    job: ["Role", "job"],
    corporation: ["Company", "corporation"],
    sector: ["Business liness", "business line"],
    location: ["Location", "location"],
    governance: ["Governance", "governance"],
    graduation: ["Education", "graduation"],
    realizations: ["Achievements", "realization"],
    conditions: ["Execution Conditions", "condition"],
    salary: ["Salary range", "salary"],
  },
  forms: {
    academic: {
      title: "Education",
      addEducation: "Add Educational Background",
      label: {
        institute: "Institute",
        degreeLevel: "Degree Level",
        field: "Field",
        startDate: "Start date",
        endDate: "End date",
      },
      degree: {
        BACHELOR: "Bachelor",
        POST_BACHELOR: "Post Bachelor",
        MBA: "MBA",
        MASTER: "Master",
        DOCTORATE: "Doctorate",
        POST_DOCTORATE: "Post doctorate",
        EXTRA_CURRICULAR_COURSE: "Extra curricular course",
      },
      error: {
        endDate: "Invalid end date",
      },
    },
    language: {
      title: "Language",
      addLanguage: "Add Language",
      placeholder: {
        language: "Choose language",
        proficiency: "Proficiency",
      },
    },
    workExperience: {
      title: "Professional Experience",
      professionalData: "Professional Data",
      addWorkExperience: "Add Professional Experience",
      removeWorkExperience: "Remove Professional Experience",
    },
    avatar: {
      title: "Avatar",
      helpText: "The image must have a minimum size of 200px X 200px and be of JPG or PNG type",
      search: "Search",
    },
    resume: {
      description: "Drag or upload resume.",
      importData: "Data import",
      discardData: "Clear fields",
      importDocument: "Document import",
    },
  },
  addToProjectBtn: {
    title: "Do you want to add the candidate to the selected project(s)?",
    message: "You won't be able to undo this action around here, only in the added project mapping list.",
    confirm: "Confirm",
    cancel: "Cancel",
    remove: "Remove selected",
  },
  createPersonForm: {
    personalData: "Personal data",
    name: "Name",
    surname: "Last Name",
    birth_date: "Birth Date",
    location: "Localization",
    phone: "Phone",
    email: "E-mail",
    linkedin: "Linkedin",
    responsible: "Responsible",
    description: "Description",
    extraActivities: "Extra Activities",
    addExtraActivities: "Add activity",
    selectPlaceholder: "Select",
    selectExtraActivitiesError: "Select an option",
    extraActivitiesTypes: {
      projects: "Projects",
      awards: "Awards",
      publications: "Publications",
      volunteer: "Volunteering",
    },
    validations: {
      date: "Invalid date",
      linkedin: "Invalid URL",
    },
    buttons: {
      submit: "submit",
      clear: "clear profile",
    },
    submitMessages: {
      success: "Registration succeded",
    },
    addPhone: "add phone",
    addEmail: "add email",
    modal: {
      submitFail: {
        title: "ERRO!",
        titleConflict: "Error!",
        message: "An error has occurred, try again",
        button: "TRY AGAIN",
        buttonConflict: "Back",
        linkedin: "There is already a profile registered with this Linkedin.",
        email: "There is already a profile registered with this email.",
        generic: "There is already a profile registered",
      },
      uploadFail: {
        title: "ERROR!",
        message: "Something went wrong when loading the data",
        button: "TRY AGAIN",
      },
    },
  },
  personCard: {
    currentYear: "Current year",
  },
  mockData: {
    filters: [
      {
        name: "Keywords",
        options: ["Keyword 1", "Keyword 2", "Keyword 3"],
      },
      {
        name: "Role",
        options: ["CFO", "CTO", "CEO"],
      },
      {
        name: "Company",
        options: ["Flow", "Wolf", "MC Donalds"],
      },
      {
        name: "Sectors",
        options: ["R&S", "Alimentício", "Transportes"],
      },
    ],
  },
  checkConflict: {
    where: "in",
    profilesFound: "profiles found with data",
    notFound: "There is no profiles with searched data",
    linkAndEdit: "Details and Edit",
    newAnalysis: "New Analysis",
    createNewProfile: "Continue and create new profile",
    continue: "Continue",
    email: "E-mail",
    fullName: "Full Name",
    createPerson: "Create new profile",
  },
  details: {
    years: "years old",
    lastSalary: "Last Salary: ",
    workExperience: "Professional experience and achievements",
    phone: "Phone: ",
    rightNow: "current",
    email: "E-mail: ",
    emailExist: "Email exists",
    age: "Age: ",
    location: "Location: ",
    projects: "Projects",
    project: "Project",
    addProject: "Add Project",
    awards: "Awards",
    award: "Award",
    addAward: "Add Award",
    publications: "Publications",
    publication: "Publication",
    addPublication: "Add Publication",
    volunteerings: "Volunteerings",
    volunteering: "Volunteer",
    addVolunteering: "Add Volunteer",
    extraActivities: "Extra Activities",
    academicFormation: "Academic Formation",
    language: "Language",
    languages: "Languages",
    in: "in",
    of: "of",
    summary: "Summary",
    submit: "Save changes",
    cancel: "Cancel",
    editWithParser: "Edit with parser",
    tabs: {
      about: "About",
      workExperience: "Professional Experience",
      academicFormation: "Education",
      extraActivities: "Extra Activities",
    },
    placeholders: {
      emptyAttribute: "There is no information about this attribute yet",
    },
  },
  current: "Current",
  from: "from",
  to: "to",
  apply: "apply",
  publicationForm: {
    headline: "Title",
    publisher: "Publication",
    datePublished: "Public. date",
    placeholders: {
      headline: "Ex.: International Economy Compared",
      publisher: "Ex.: Harvard Business Review",
    },
    validations: {
      linkedin: "URL inválida",
    },
  },
  projectForm: {
    title: "Title",
    startDate: "Start date",
    endDate: "End date",
    description: "Description",
    current: "Current project",
    placeholders: {
      year: "Year",
      month: "Month",
    },
  },
  volunteerForm: {
    title: "Title",
    startDate: "Start date",
    endDate: "End date",
    description: "Description",
    current: "Current project",
    placeholders: {
      title: "Project X",
      year: "Year",
      month: "Month",
    },
  },
  awardForm: {
    title: "Title",
    relatedPosition: "Related Area",
    awardDate: "Date",
    placeholders: {
      title: "Ex.: Employee of the Year - 2020",
      relatedPosition: "Ex.: Controller, Academic research of the Economics course",
      month: "Month",
      year: "Year",
      description: "Description",
    },
  },
  certificationForm: {
    name: "Name",
    institution: "Institution",
    field: "Field",
    expeditionDate: "Expedition date",
    expirationDate: "Expiration date",
    withoutExpirationDate: "Without expiration date",
    addCertification: "Add Certification",
  },
  workExperienceForm: {
    title: "Professional Experience",
    addWorkExperience: "Add experience",
    roleName: "Title",
    company: "Company",
    salary: "Salary",
    incentiveCompensation: "Bônus",
    jobLocation: "Location",
    isCurrent: "Current position",
    startDate: "Start Date",
    endDate: "End Date",
    realization: "Achievement",
    realizationDescription: "Achievement's description",
    addRealization: "add achievement",
    conditions: "Execution conditions",
    noCompaniesFound: "No company found",
    placeholders: {
      roleName: "ex: Operations Menager, CFO, etc.",
      company: "ex: Wallmart, Oracle, etc.",
      baseSalary: "ex: 45.000,00",
      description: "Description",
      incentiveCompensation: "ex: 4x salary, profit sharing, etc.",
      jobLocation: "ex: San Diego, CA, USA",
      month: "Month",
      year: "Year",
      paymentFrequencies: {
        month: "Month",
        year: "Year",
        monthly: "Monthly",
        yearly: "Yearly",
      },
    },
    corporationAliasHint: {
      title: "Our System has identified",
      as: "as",
    },
  },
  project: "Project",
  publication: "Publication",
  award: "Award",
  volunteer: "Volunteer",
  certifications: "Certification | Certifications | {count} Certifications",
  editablePersonForm: {
    name: "Name",
    errorName: "Input full name",
    linkedin: "Linkedin",
    linkedinError: "Invalid URL",
    email: "E-mail",
    emailError: "Invalid E-mail",
    location: "Location",
    birth_date: "Birth date",
    tooltipText: "Edit work experience to edit this field",
    phoneError: "Invalid Phone",
    removeAvatar: "Remove Avatar",
  },
};
