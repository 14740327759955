<script lang="ts">
import { defineComponent } from "vue";
import ComingSoonIcon from "@/assets/images/coming_soon_icon.svg";

export default defineComponent({
  name: "ComingSoon",
  components: {
    ComingSoonIcon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    paragraph: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      default: 263,
    },
    height: {
      type: Number,
      default: 199,
    },
  },
});
</script>

<template>
  <div class="coming-soon">
    <div
      class="coming-soon__icon"
      data-test="coming-soon-icon"
    >
      <ComingSoonIcon :style="{ width, height }" />
    </div>

    <div class="coming-soon__label">
      <h1
        class="coming-soon__label-title"
        data-test="coming-soon-title"
      >
        {{ title }}
      </h1>

      <p
        class="coming-soon__label-paragraph"
        data-test="coming-soon-paragraph"
      >
        {{ paragraph }}
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.coming-soon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  &__icon {
    margin-bottom: 2rem;
  }

  &__label {
    &-title {
      margin-bottom: 1rem;
      font-size: 1.5rem;
      font-weight: bold;
      text-transform: uppercase;
    }

    &-paragraph {
      font-size: 1.25rem;
    }
  }
}
</style>
