<script setup lang="ts">
import { useI18n } from "vue-i18n";
import type { Ref } from "vue";
import moment from "moment";
import { Table } from "@/components/table";
import { Button2 as Button } from "@/components/button";
import type { ProjectSchedules, ProjectStages, Schedule } from "@/schemas/project";
import { SvgIcon } from "@/components/icon";
import { Modal } from "@/components/modal";

const props = defineProps<{
  stage: ScheduleStagesKeys
  stages: any
  schedules: any
}>();

const { t } = useI18n();

type ScheduleStagesKeys =
  | "construction"
  | "mapping"
  | "screenings"
  | "interviews"
  | "shortlist"
  | "under_offer"
  | "placement";

const showRemoveModal = ref(false);
const checkpointData = computed(() => checkStages(props.schedules as ProjectSchedules));
const scheduleCheck = computed(() => props.stages as ProjectStages);
const checkpointList = ref({}) as Ref<ProjectSchedules>;

const route = useRoute();

const {
  data: project,
} = useProject(String(route.params.id));

const {
  mutate: updateProject,
} = updateProjectMutation(String(route.params.id));

function initialFormData() {
  return {
    activity_date: null,
    recruiter: null,
    candidate: null,
    ready_to_checkpoint: false,
    is_process: false,
  };
}
const payload = ref(initialFormData());

const checkpointCols = computed(() => [
  { field: "checkpoint_date", header: t("projects.details.activities.table.date") },
  { field: "", header: t("projects.details.activities.table.activity") },
  { field: "", header: "" },
]);

function cancelForm() {
  payload.value = initialFormData();
  showRemoveModal.value = false;
}

function scheduleFilter(index: keyof ProjectSchedules) {
  return scheduleCheck.value[index]?.status;
}

const checkpointDoneIndex = ref();

function setCheckpointIndex(index) {
  // this table has a bug, the index concats 0 left
  const indexWithoutZero = index.replace(0, "");
  showRemoveModal.value = true;
  checkpointDoneIndex.value = indexWithoutZero;
}

function checkStageStatus(data: Schedule): boolean {
  return Boolean(data.checkpoint_done_date);
}

function getIndexStage(stageName: ScheduleStagesKeys) {
  let stageIndex = -1;
  Object.entries(props.schedules || {}).forEach(([key], index) => {
    if (stageName === key)
      stageIndex = index;
  });
  return stageIndex;
}

function checkStages(stagesData: ProjectSchedules) {
  checkpointList.value = {};
  const stageIndex = getIndexStage(props.stage);
  Object.entries(stagesData).forEach(([key, data], index) => {
    const stageStatus = checkStageStatus(data as Schedule);
    if (
      data?.checkpoint_date != null
      && (
        (index > stageIndex)
        || props.stage === key
        || (!stageStatus && props.stage !== key)
      )
    )
      checkpointList.value[key as ScheduleStagesKeys] = data;
  });
  return checkpointList.value;
}

function getDatePtBr(date: string | null) {
  if (!date)
    return "";
  else
    return moment(date).format("DD/MM/YYYY");
}

async function submitCheckpointDone() {
  // TODO: move to patch
  const updatedSchedules = {
    ...props.schedules,
    ...{
      [checkpointDoneIndex.value]: {
        ...props.schedules[checkpointDoneIndex.value],
        ...{
          checkpoint_done_date: new Date().toISOString(),
        },
      },
    },
  };
  updateProject({
    ...project.value,
    schedules: updatedSchedules,
  }, {
    onSuccess: () => {
      cancelForm();
      showRemoveModal.value = false;
    },
  });
}
</script>

<template>
  <Table
    v-if="checkpointData"
    :columns="checkpointCols"
    :data="checkpointData"
    :style="{ marginTop: '2.7rem' }"
  >
    <template #table-header>
      {{ t("projects.details.activities.screening.checkpoint") }}
    </template>
    <template #column-body-0="{ data }">
      {{ getDatePtBr(data.checkpoint_date) }}
    </template>
    <template #column-body-1="{ index }">
      {{ t("projects.details.activities.screening.checkpoint") }}
      - {{ t(`projects.generic.stages.enum.${index.replace(0, "")}`) }}
    </template>
    <template #column-body-2="{ data, index }">
      <div v-if="scheduleFilter(index) !== 'DRAFT'" class="justify-end">
        <Button v-if="data.checkpoint_done_date === null" @click="setCheckpointIndex(index)">
          {{ t("projects.details.activities.checkpoint.placeholders.buttonComplete") }}
        </Button>
        <Button
          v-else-if="data.checkpoint_done_date !== null"
          disabled
          :style="{ background: '#FFB600', color: 'white' }"
        >
          {{ t("projects.details.activities.checkpoint.placeholders.buttonCompleted") }}
        </Button>
      </div>
      <div v-else-if="scheduleFilter(index) === 'DRAFT'" class="justify-end">
        <Button
          disabled
          :style="{
            background: 'transparent',
            border: '1px solid rgba(255, 182, 0, 0.5)',
            color: 'rgba(255, 182, 0, 0.5)',
          }"
        >
          {{ t("projects.details.activities.checkpoint.placeholders.buttonComplete") }}
        </Button>
      </div>
    </template>
  </Table>
  <Modal
    v-if="showRemoveModal"
    :title="t('projects.details.activities.checkpoint.modal.title')"
    :message="t('projects.details.activities.checkpoint.modal.sub')"
    :button-text="t('projects.details.activities.checkpoint.modal.confirm')"
    :secondary-button-text="t('projects.details.activities.checkpoint.modal.cancel')"
    secondary-variation="regular"
    :on-click="submitCheckpointDone"
    :secondary-on-click="() => (showRemoveModal = false)"
    :on-close="() => (showRemoveModal = false)"
    variation="warning"
  >
    <template #titleIcon>
      <SvgIcon icon="alert_circle_icon" width="20" height="20" />
    </template>
  </Modal>
</template>

<style lang="scss" scoped>
.justify-end {
  display: flex;
  justify-content: flex-end;
}
</style>
