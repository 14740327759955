<script lang="ts">
import { defineComponent, onMounted, ref, toRef, watch } from "vue";
import Multiselect from "@vueform/multiselect";
import { TrashButton } from "@/components/trash-button";
import { TooltipBox } from "@/components/tooltip";

export default defineComponent({
  name: "CustomSelect",
  components: {
    Multiselect,
    TooltipBox,
    TrashButton,
  },
  props: {
    modelValue: [String, Number],
    placeholder: String,
    width: Number,
    onOptionChange: Function,
    options: Object,
    errorMessage: String,
    error: Boolean,
    disabled: Boolean,
    valueProp: String,
    noFeedback: Boolean,
    initialValue: String,
    autoFill: Boolean,
    clearValue: {
      type: Boolean,
      default: false,
    },
    tooltipShow: {
      type: Boolean,
      default: false,
    },
    tooltipMesage: String,
    tooltipColor: String,
    tooltipBackground: String,
    svgIcon: String,
    showIcon: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["change:value", "update:modelValue", "click:onButtonClick"],
  setup(props, { emit }) {
    const showTooltip = ref(false);
    const selectComponent = ref(null);
    const options = toRef(props, "options");

    const clear = () => {
      selectComponent.value.clear();
    };

    onMounted(() => {
      if (props.clearValue)
        selectComponent.value.clear();
    });

    watch(options, () => {
      clear();
    });

    const onClick = () => {
      emit("click:onButtonClick");
    };

    return {
      selectComponent,
      clear,
      showTooltip,
      onClick,
    };
  },
});
</script>

<template>
  <div
    class="component-select"
    :style="{ width: `${width}px` }"
    @mouseover="showTooltip = true"
    @mouseout="showTooltip = false"
  >
    <TooltipBox
      :show="tooltipShow && showTooltip"
      :color="tooltipColor"
      :background="tooltipBackground"
      :mesage="tooltipMesage"
    />
    <div class="select-wrapper">
      <Multiselect
        ref="selectComponent"
        data-test="custom-select"
        :options="options"
        :clear-on-select="false"
        :can-clear="false"
        :placeholder="placeholder"
        :class="{ error: error || errorMessage, auto_fill: autoFill }"
        :disabled="disabled"
        :value-prop="valueProp"
        :searchable="searchable"
        :model-value="modelValue"
        @update:modelValue="$emit('update:modelValue', $event)"
        @change="$emit('change:value', $event)"
      />
      <TrashButton :show-icon="showIcon" :svg-icon="svgIcon" @click="onClick" />
    </div>
    <div v-show="!noFeedback" class="error-wrapper" data-test="message-error">
      <span>{{ errorMessage }}</span>
    </div>
  </div>
</template>

<style scoped lang="scss">
.select-wrapper {
  display: flex;

  button {
    min-width: 2rem;
    height: 1.8rem;
    border: 1px solid $gray-border !important;
    border-radius: 6px;
    margin-left: 0.563rem !important;

    &:hover {
      border: 1px solid $red-violet-medium;
      background-color: $red-violet-medium;

      svg {
        color: $orange-medium;
      }
    }
  }

  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    color: inherit;
  }
}

.component-select {
  width: 100%;
  min-height: 40px;

  .multiselect {
    min-height: 28px;
    border: solid var(--ms-border-width) #c0c0c0;
    border-radius: 6px;
    font-size: 14px;
    transition: all linear 250ms;

    &.auto_fill {
      border: 2px solid $red-violet;
    }

    &.error {
      border: var(--ms-border-width) solid $red;
    }

    &.is-open {
      min-height:
        calc(
          var(--ms-border-width, 1px) * 2 + var(--ms-font-size, 1rem) * var(--ms-line-height, 1.375) +
          var(--ms-py, 0.5rem) * 2
        );
      border: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: var(--ms-radius, 4px);
      border-top-right-radius: var(--ms-radius, 4px);
      box-shadow: 0 3px 6px #00000029;

      .multiselect-dropdown {
        border: none;
        box-shadow: 0 3px 6px #00000029;
      }
    }

    .multiselect-single-label {
      padding-right: 20%;
    }

    .multiselect-single-label,
    .multiselect-option {
      color: #666;
    }

    .multiselect-dropdown {
      overflow-y: auto;
    }

    .multiselect-caret {
      background-color: transparent;
      -webkit-mask-image: none;
      mask-image: none;

      &::before {
        position: relative;
        display: inline-block;

        /* By using an em scale, the arrows will size with the font */
        width: 0.5em;
        height: 0.5em;
        border-right: 0.15em solid #c0c0c0;
        border-bottom: 0.15em solid #c0c0c0;
        margin-bottom: 0.1em;

        /* top: 3pt; Uncomment this to lower the icons as requested in comments */
        content: "";
        transform: rotate(45deg);
      }
    }
  }

  .error-wrapper {
    display: flex;
    width: 100%;
    height: 15px;
    justify-content: flex-start;

    span {
      padding-top: 2px;
      margin-right: 5%;
      margin-left: 5px;
      color: $red;
      font-size: 10px;
      font-weight: 500;
    }
  }
}

:root {
  --ms-border-color: #c0c0c0;
  --ms-ring-color: #000029;
  --ms-ring-width: 1px;
  --ms-tag-bg: #fff;
  --ms-option-bg-selected: #fff;
  --ms-option-bg-selected-pointed: #fff;
  --ms-option-color-selected: #313131;
  --ms-option-color-selected-pointed: #666;
  --ms-border-width: 1px;
}
</style>

<style global lang="scss">
:root {
  --ms-option-bg-selected-pointed: #fff;
  --ms-option-bg-selected: #fff;
}
</style>
