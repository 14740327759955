import { z } from "zod";
import { CalculatedScoresSchema } from "./interviews";

const MiniCVSchema = z.object({
  candidate_feedbacks: z.array(z.string()),
  nickname: z.null(),
  client_feedbacks: z.array(z.string()),
  recruiter_opinion: z.null(),
  current_company_nickname: z.null(),
  current_role_nickname: z.null(),
  hide_experiences_ids: z.array(z.string()),
});

export const CandidatesSchema = z.object({
  person_id: z.string(),
  mini_cv: MiniCVSchema.optional().nullable(),
  calculated_scores: CalculatedScoresSchema.optional().nullable(),
  _id: z.string().optional(),
  person_data: z.object({
    first_name: z.string(),
    last_name: z.string(),
    work_experience: z.array(z.object({
      corporation_id: z.string().nullable(),
      corporation_name: z.string().nullable(),
      role_name: z.string().nullable(),
      start_date: z.string().nullable(),
      is_current: z.boolean().nullable(),
      end_date: z.string().nullable(),
    }).partial()),
  }).partial(),
});

export type InterviewCandidates = z.infer<typeof CandidatesSchema>;
