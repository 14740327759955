<script lang="ts">
import type { PropType } from "vue";
import { useI18n } from "vue-i18n";
import ProjectCard from "./CardList.vue";
import type { Project } from "@/entities/project";
import { Loading } from "@/components/loading";
import { ListType } from "@/components/list_type";
import { Select } from "@/components/inputs/modalInputs";

export default defineComponent({
  name: "ProjectsList",
  components: {
    ListType,
    Select,
    Loading,
    ProjectCard,
  },
  props: {
    projects: {
      type: Array as PropType<Project[]>,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isSearching: Boolean,
    searchTotal: Number,
  },
  emits: ["sort"],
  setup(_, { emit }) {
    const { t, tm } = useI18n();

    const sortOptions = computed(() => tm("projects.sort"));

    const selected = ref(null);

    watch(selected, () => {
      emit("sort", selected.value);
    });

    return {
      t,
      sortOptions,
      selected,
    };
  },
});
</script>

<template>
  <section class="list-persons">
    <header class="header">
      <div class="left-items" />

      <div class="right-items">
        <ListType selected-type="card" />

        <Select
          v-model="selected"
          no-feedback
          :width="150"
          :placeholder="t('corporations.sortBy')"
          :options="sortOptions"
          :searchable="false"
        />
      </div>
    </header>

    <div class="list">
      <p v-if="!loading && isSearching && searchTotal > 0" class="search-count">
        {{ t("text.searchText", searchTotal) }}
      </p>

      <ProjectCard v-for="project in projects" :key="project.id" :project="project" />

      <transition name="fade">
        <div class="loading">
          <Loading v-show="loading" :spin-time="2" />
        </div>
      </transition>

      <slot name="tail" />
    </div>
  </section>
</template>

<style scoped="true" lang="scss">
.header {
  display: flex;
  padding-right: 0.5rem;
  padding-left: 1.2rem;
  margin-bottom: 2rem;

  .left-items,
  .right-items {
    display: flex;
    flex: 1;
  }

  .left-items {
    justify-content: left;

    > div {
      margin-right: 1rem;
    }
  }

  .right-items {
    justify-content: right;
  }

  .component-select:deep {
    min-height: auto;
    .multiselect-placeholder {
      padding-inline: 10px;
    }

    .error-wrapper {
      height: 0;
    }
  }
}

.list {
  position: relative;

  .search-count {
    margin-bottom: 1.5rem;
    margin-left: 0.5rem;
    font-size: 1.563rem;
    font-weight: bolder;
  }
}

.loading {
  z-index: 9;
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: #fff;
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity ease-in-out 2ms;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
