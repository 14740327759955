import { createEnv } from "@t3-oss/env-core";
import { z } from "zod";

const apiEnv = {
  /**  API URLs  */
  VITE_APP_AUTH_URL: z.string().min(1)
    .describe("ms-auth"),

  VITE_APP_CANDIDATES_URL: z.string().min(1)
    .describe("ms-candidates"),

  VITE_APP_ENTITIES_URL: z.string().min(1)
    .describe("ms-entities"),

  VITE_APP_ACTIVITIES_URL: z.string().min(1)
    .describe("ms-activities"),

  VITE_APP_FILE_URL: z.string().min(1)
    .describe("ms-files"),

  VITE_APP_PROJECTS_URL: z.string().min(1)
    .describe("ms-projects"),

  VITE_APP_SEARCH_FIELD_URL: z.string().min(1)
    .describe("ms-search-field"),

  VITE_APP_IMPORT_CSV_URL: z.string().min(1)
    .describe("ms-import-csv"),
};

const pdfEnv = {
  /**  PDF Export  */
  VITE_APP_EXPORT_CONSTRUCTION_URL: z.string().min(1)
    .describe("export-construction-url"),

  VITE_APP_EXPORT_CONSTRUCTION_PATH: z.string().min(1)
    .describe("export-construction-path")
    .default("api/export/construction"),

  VITE_APP_EXPORT_SHORTLIST_PATH: z.string().min(1)
    .describe("export-shortlist-path")
    .default("api/export/shortlist"),

  VITE_APP_EXPORT_BULLHORN_CONSTRUCTION_PATH: z.string().min(1)
    .describe("export-bullhorn-construction-path")
    .default("api/export/bullhorn/construction"),

  VITE_APP_EXPORT_BULLHORN_SHORTLIST_PATH: z.string().min(1)
    .describe("export-bullhorn-shortlist-path")
    .default("api/export/bullhorn/shortlist"),

};

const thirdPartyEnv = {
  /**  Third Party URLs  */
  VITE_APP_RESUME_PARSER_URL: z.string().optional()
    .describe("resume-parser-url"),

  VITE_APP_RESUME_PARSER_PASSWORD: z.string().optional()
    .describe("resume-parser-pwd")
    .default("ms-parser@12345"),

  VITE_APP_RESUME_PARSER_USER: z.string().optional()
    .describe("resume-parser-user")
    .default("admin@ms-parser.app"),

  VITE_APP_HOTJAR_ID: z.string().optional(),

  VITE_APP_NETSUITE_FATURAMENTO_URL: z.string().optional().describe("integrations-netsuite-faturamento"),

  VITE_APP_SENTRY_DSN: z.string().optional().describe("sentry"),

  VITE_APP_POSTHOG_API_KEY: z.string().optional().describe("posthog").optional(),

  VITE_APP_GTAG_ID: z.string().optional().describe("gtag"),
};

function isAuthLocal() {
  return !z.string().startsWith("https").safeParse(import.meta.env.VITE_APP_AUTH_URL).success;
}

function authorizedUser() {
  if (isAuthLocal())
    return z.string().optional().default("632868e71bb22d0001725e9a");

  // when uses auth from cloud uses k8s user header
  return z.void()
    .describe("should not have authorized-user");
}

export const env = createEnv({
  /**
   * Specify what prefix the client-side variables must have.
   * This is enforced both on type-level and at runtime.
   */
  clientPrefix: "VITE_APP",
  client: {

    ...apiEnv,

    ...pdfEnv,

    ...thirdPartyEnv,

    /** Others */
    VITE_APP_AUTHORIZED_USER: authorizedUser(),

  },
  /**
   * What object holds the environment variables at runtime.
   * Often `process.env` or `import.meta.env`
   */
  runtimeEnv: import.meta.env,
});
