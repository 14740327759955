<script setup lang="ts">
import chunk from "lodash-es/chunk";
import { formatScore } from "@/utils/numbers";
import ExportPage from "@/common/components/Export/ExportPage.vue";
import type { InterviewCandidates } from "@/types/candidates";

const props = withDefaults(
  defineProps<{
    isExport?: boolean
    itemsInPage?: number
    rankingWeights?: any
    interviews?: any
    candidates: InterviewCandidates[]
  }>(),
  {
    persons: null,
    shortlist: null,
    isExport: false,
    itemsInPage: 6,
  },
);

const { t } = useI18n();

interface PersonRankedType {
  experience: string | number
  realizations: string | number
  attributes: string | number
  schooling: string | number
  cultural_match: string | number
  motivation: string | number
  final_score: string | number
}

const idealProfileValue = "100%";
const limitCharName = 25;
const limitChar = 35;

const rankedOrder = [
  "schooling",
  "experience",
  "realizations",
  "attributes",
  "cultural_match",
  "motivation",
];

const weightsByOrder = computed(() => {
  const project_weights = {
    academic_formation: 10,
    experiences: 20,
    achievements: 20,
    attributes: 20,
    cultural_match: 15,
    motivation: 15,
  };

  return {
    schooling: project_weights.academic_formation,
    experience: project_weights.experiences,
    realizations: project_weights.achievements,
    attributes: project_weights.attributes,
    cultural_match: project_weights.cultural_match,
    motivation: project_weights.motivation,
  };
});

type CandidateRank = InterviewCandidates & { scores: any };

const candidatesRank: ComputedRef<CandidateRank[]> = computed(() => {
  if (!props.candidates)
    return [];
  return [...props.candidates]
    .map(candidate => ({
      ...candidate,
      scores: {
        schooling: candidate.calculated_scores?.academic_formation_mean,
        experience: candidate.calculated_scores?.experiences_mean,
        realizations: candidate.calculated_scores?.achievements_mean,
        attributes: candidate.calculated_scores?.attributes_mean,
        cultural_match: candidate.calculated_scores?.cultural_match_mean,
        motivation: candidate.calculated_scores?.motivation_mean,
      },
    }));
});

const pages = computed(() =>
  chunk(candidatesRank.value, props.itemsInPage)
    .map((item: CandidateRank[]) => ({ persons: item })));

function limitChars(text: string | null | undefined, char: number) {
  if (!text)
    return text;

  return text.length <= char ? text : `${text.substring(0, char)}...`;
}

function formatPersonName(person: any) {
  const personName = person?.mini_cv?.nickname ?? `${person?.person_data?.first_name} ${person?.person_data?.last_name}`;
  return limitChars(personName, limitCharName);
}

function formatCompanyName(person: any) {
  const companyName = person?.mini_cv?.current_company_nickname ?? person.person_data?.work_experience[0]?.corporation_name;
  return limitChars(companyName, limitCharName);
}

function formatRoleName(person: any) {
  const roleName = person?.mini_cv?.current_role_nickname ?? person.person_data?.work_experience[0]?.role_name;
  return limitChars(roleName, limitCharName);
}
</script>

<template>
  <ExportPage
    v-for="(page, pageIndex) in pages"
    :key="pageIndex"
    :page-index="pageIndex"
    :is-export="false"
    :page-name="t('exportPDF.summaryShortlistBeta.adherenceTable')"
    v-bind="$attrs"
  >
    <div class="pdf-adherence-tables">
      <div class="box-header">
        <div class="box-ideal-profile">
          <div class="box-title">
            {{ t("exportPDF.labels.weights") }}
          </div>
          <div />
          <div class="box-title">
            {{ t("exportPDF.labels.ideal") }}
          </div>
        </div>
        <div class="box-persons">
          <div v-for="(person, index) in page.persons" :key="index" class="box-person">
            <div class="box-title">
              {{ formatPersonName(person) }}
            </div>
            <div class="box-company">
              <!-- TODO: get the last experience -->
              {{ formatCompanyName(person) }}
            </div>
            <div class="box-role">
              {{ formatRoleName(person) }}
            </div>
          </div>
        </div>
      </div>
      <div class="box-content">
        <div class="box-profile">
          <div v-for="(item, index) in rankedOrder" :key="index" class="box-ideal-profile">
            <div class="box-value">
              {{ weightsByOrder[item] }}%
            </div>
            <div class="box-label">
              {{ t(`exportPDF.labels.${item}`) }}
            </div>
            <div class="box-value">
              {{ idealProfileValue }}
            </div>
          </div>
        </div>
        <div class="box-persons">
          <div v-for="(person, index) in page.persons" :key="index" class="box-person">
            <div v-for="(rankType, idx) in rankedOrder" :key="idx" class="box-value">
              <!-- {{ getDataByPersonId(person).ranked[rankedId as never] }}% -->
              {{ formatScore(person.scores[rankType]) }}%
            </div>
          </div>
        </div>
      </div>
      <div class="box-footer">
        <div class="box-ideal-profile">
          <div class="box-title">
            {{ idealProfileValue }}
          </div>
          <div class="box-label">
            {{ t("exportPDF.labels.finalRate") }}
          </div>
          <div class="box-result">
            {{ idealProfileValue }}
          </div>
        </div>
        <div class="box-persons">
          <div v-for="(person, index) in page.persons" :key="index" class="box-result">
            {{ formatScore(person.calculated_scores.final_mean) }}%
          </div>
        </div>
      </div>
      <div class="box-paginate">
        <div class="box-ideal-profile" />
        <div class="box-persons">
          <div v-for="(_, index) in page.persons" :key="index" class="box-index">
            {{ (pageIndex * itemsInPage) + index + 1 }}
          </div>
        </div>
      </div>
    </div>
  </ExportPage>
</template>

<style lang="scss" scoped>
$ideal-profile-width: 22rem;

.pdf-adherence-tables {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .box-header,
  .box-content,
  .box-footer,
  .box-paginate {
    display: flex;
    flex: 1;
    flex-direction: row;
    gap: 0.5625rem;
  }

  .box-value,
  .box-label {
    display: flex;
    height: 3.25rem;
    align-items: center;
    justify-content: center;
    border: 1px solid $white-background;
    border-radius: 3px;
    text-align: center;
  }

  .box-value {
    color: $gray-dark;
    font-weight: 500;
    padding-inline: 0.75rem;
  }

  .box-title,
  .box-result {
    max-width: 6.875rem;
    color: $yellow;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.5;
  }

  .box-ideal-profile {
    display: grid;
    min-width: 15.7rem;
    flex: 1;
    gap: 0.5rem;
    grid-template-columns: 1fr 3fr 1fr;

    .box-title {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      text-align: center;
      text-transform: uppercase;
    }

    .box-label {
      min-width: 7rem;
      justify-content: flex-start;
      font-size: 0.75rem;
      font-weight: bold;
      padding-inline: 0.5rem;
      text-align: left;
      text-transform: uppercase;
    }

    .box-value {
      background: $white-background;
      color: $gray-dark;
      font-size: 1.125rem;
      font-weight: bold;
    }
  }

  .box-persons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 0.5625rem;

    .box-person {
      display: flex;
      min-width: 6.875rem;
      max-width: 6.875rem;
      flex-direction: column;
      gap: 0.65rem;

      .box-company,
      .box-role {
        color: $gray-dark;
        font-size: 0.6875rem;
        font-weight: 600;
      }

      .box-company {
        font-weight: bold;
      }
    }
  }

  .box-header {
    .box-person {
      gap: 0.5rem;
    }
  }

  .box-content {
    .box-profile {
      display: flex;
      flex: 1;
      flex-direction: column;
      gap: 0.65rem;
    }
  }

  .box-footer {
    background: $gray-dark;

    .box-result {
      display: flex;
      min-width: 6.875rem;
      max-width: none;
      align-items: center;
      justify-content: center;
      font-size: 1.125rem;
    }

    .box-title {
      font-size: 1.125rem;
    }

    .box-ideal-profile {
      display: grid;
      min-width: 15.7rem;
      flex: 1;
      gap: 0.5rem;
      grid-template-columns: 1fr 3fr 1fr;
    }

    .box-title,
    .box-label {
      align-items: center;
      border: none;
      color: $white-full;
      line-height: 1.5;
      padding-inline: 0.75rem;
    }
  }

  .box-paginate {
    .box-index {
      display: flex;
      min-width: 6.875rem;
      max-width: 6.875rem;
      align-items: center;
      justify-content: center;
      color: $gray-dark;
      font-size: 1.125rem;
      font-weight: bold;
    }
  }
}
</style>
