export const text = {
  close: "Close",
  tooltip: "Discard Change",
  textExpand: {
    expandButton: "See more",
  },
  textArea: {
    defaultWarning: "To improve your performance, we suggest a maximum of {count} characters.",
    secondaryWarning: "We suggest a maximum of {count} characters.",
    shorlistWarning: "Maximum of {count} characters.",
    funneltWarning: "Allowed in a maximum of {count} characters.",

  },
  inputCounter: {
    defaultWarning: "Maximum of {count} chars.",
  },
  form: {
    restore_original: "Restore original",
    submit: "Save changes",
    submitAvaliation: "Save avaliation",
    saveQuestion: "Save question",
    save: "Save",
    finish: "Save and Finish",
    finalize: "Finalizar",
    conclude: "Conclude",
    change: "Change",
    cancel: "Cancel",
    addElement: "Add {complement}",
    editElement: "Edit {complement}",
    answerElement: "Answer {complement}",
    removeElement: "Remove {complement}",
    yes: "Yes",
    no: "No",
    error: {
      modal: {
        genericTitle: "ERROR!",
        genericMessage: "An error has occurred, try again",
        genericButtonMessage: "TRY AGAIN",
      },
    },
    removeAvatar: "Remove Avatar",
    maxChars: "Max of {count} characters.",
  },
  filters: {
    loadMore: "Load more ...",
  },
  viewProfileInNewPage: "View profile in new tab.",
  comingSoonTitle: "Coming soon!",
  comingSoonParagraph: "This content is not available yet.",
  pageNotFoundTitle: "Page not found",
  pageNotFoundParagraph:
    "It wasn't possible to find what you were looking for.<br>But don't worry, you can keep browsing smoothly.",
  pageNotFoundRedirection: "Back to home page",
  paymentFrequency: {
    MONTHLY: ["Monthly", "month"],
    ANNUALLY: ["Annualy", "year"],
  },
  start: "Start",
  end: "End",
  addRelationship: "add relationship",
  select: "Select",
  selected: "Select | {n} selected",
  searchProfile: "type to search a profile",
  searchProfileNotFound: "No profile found",
  searchProfileError: "Error fetching profiles. Try again",
  searchCorporation: "digite para buscar uma empresa",
  searchBusinessFunction: "digite para buscar uma linha de negócio",
  searchBusinessFunctionNotFound: "Nenhuma linha de negócio encontrada",
  searchCorporationNotFound: "Nenhuma empresa encontrada",
  searchBusinessFunctionError: "Error ao buscar linha de negócio. Tente novamente",
  searchCorporationError: "Error ao buscar as empresas. Tente novamente",
  startDate: "Start date",
  endDate: "End date",
  presentationDate: "Presentation Date",
  emptyDate: "dd/mm/yyyy",
  toBeDefined: "Not defined",
  presentationDate: "Presentation date",
  emptyDate: "dd/mm/aaaa",
  draft: "Draft",
  searchText:
    "no results for the search performed | 1 result for the search performed | {count} results for the search performed",
  conjunctions: {
    of: "of",
    and: " and ",
  },
  backToList: "Back to list",
  numberRegistration: "1 register | {count} register",
  others: "others",
  date: "Date",
  name: "Name",
  activitie: "Activity",
  status: "Status",
  fromCandidate: "- from candidate",
  loading: "Loading",
  text: "Text",
};
