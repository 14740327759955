<script setup lang="ts">
import IdealProfileTabs from "./components/IdealProfileTabs.vue";
import { Card } from "@/components/card";
import Resume from "@/common/components/Export/Resume.vue";

const props = defineProps<{ id: string }>();

const AsyncMotivationForm = defineAsyncComponent(() =>
  import("./components/forms/MotivationForm.vue"));

const route = useRoute();
const router = useRouter();
const { t } = useI18n();

const { data: project, error } = await useProject(props.id);

const {
  data: idealProfile,
} = useConstructionIdealProfile(props.id);

const {
  data: construction,
} = useConstructionOrganizationQuery(String(route.params.id));

const {
  mutate: patchIdealProfile,
} = usePatchIdealProfileQuery();

const constructionId = computed(() => {
  return construction.value?._id;
});

const formattedMotivation = computed(() => ([
  {
    label: t("projects.construction.labels.salary"),
    value: idealProfile.value?.package?.salary,
  },
  {
    label: t("projects.construction.labels.icp"),
    value: idealProfile.value?.package?.short_term_incentive,
  },
  {
    label: t("projects.construction.labels.ilp"),
    value: idealProfile.value?.package?.long_term_incentive,
  },
  {
    label: t("projects.construction.labels.others"),
    value: idealProfile.value?.package?.other_incentive,
  },
  {
    label: t("projects.construction.labels.totalCash"),
    value: idealProfile.value?.package?.total_cash,
  },
]));

const motivations = computed(() => {
  const getI18nString = (strings: TemplateStringsArray) => `projects.details.activities.constructionPanel.${strings[0]}`;

  return [
    {
      name: t(getI18nString`career`),
      list: Object.values(idealProfile.value?.career || {})
        .map(item => ({ label: null, value: item })),
    },
    {
      name: t(getI18nString`workEnvironment`),
      list: Object.values(idealProfile.value?.work_environment || {})
        .map(item => ({ label: null, value: item })),
    },
    {
      name: t(getI18nString`personal`),
      list: Object.values(idealProfile.value?.personal_life || {})
        .map(item => ({ label: null, value: item })),
    },
  ];
});

const {
  stageTabs,
} = useTabsProject(props.id);

const currentRoute = computed(() => stageTabs.value.findIndex(tab => tab.key === router.currentRoute.value.meta.tab));

function goTo(tabIndex: number) {
  if (!stageTabs.value || !stageTabs.value[tabIndex])
    return;

  router.push(stageTabs.value[tabIndex].path);
}

const editing = ref(false);

function formatBreakLines(value: string | null | undefined) {
  if (!value)
    return value;

  return value.replaceAll("\n", "<br>");
}

function saveProfile(data: any) {
  patchIdealProfile({
    _id: String(constructionId.value),
    ...data,
  });
}
</script>

<template>
  <section class="project-section">
    <div v-if="error">
      could not load
    </div>
    <div v-if="idealProfile" class="project-container">
      <ProjectStages :id="props.id" />
      <div v-if="project" class="project-wrapper">
        <ProjectTabs :stage-tabs="stageTabs" :active="currentRoute" class="tabs" @change="goTo($event)" />
        <IdealProfileTabs :editing="editing" :project-id="project._id" :stages="project.stages" @edit="editing = true" />
        <div v-if="!editing" class="project-content">
          <div class="motivations">
            <div class="motivations__content">
              <div class="motivations__content--item">
                <h4>
                  {{ t("projects.details.constructionGuide.idealProfile.package") }}
                </h4>

                <Resume
                  :show-title="false"
                  :show-circle="true"
                  :items="formattedMotivation"
                  font-size="0.875rem"
                  line-height="1.5rem"
                />
              </div>

              <div v-for="motivation_item in motivations" :key="motivation_item.name">
                <div class="motivations__content--item">
                  <h4>
                    {{ motivation_item.name }}
                  </h4>

                  <Resume
                    :show-title="false"
                    :show-circle="true"
                    :items="motivation_item.list"
                    font-size="0.875rem"
                    line-height="1.5rem"
                  />
                </div>
              </div>
            </div>

            <div class="motivations__opinion">
              <Card class="motivations__opinion--card">
                <img
                  class="motivations__opinion--logo"
                  src="@/assets/images/flow.png"
                  alt="flow logo"
                >
                <p v-html="formatBreakLines(idealProfile.consultant_opinion)" />
              </Card>
            </div>
          </div>
        </div>
        <div v-else class="project-content">
          <AsyncMotivationForm
            :career="idealProfile.career"
            :work-environment="idealProfile.work_environment"
            :personal-life="idealProfile.personal_life"
            :consultant-opinion="idealProfile.consultant_opinion"
            :motivation-package="idealProfile.package"
            @cancel="editing = false"
            @update="saveProfile($event)"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<route lang="yaml">
meta:
  layout: project-stage
  stage: construction
  tab: ideal-profile
  ideal-profile: motivation
</route>

<style scoped lang="scss">
.project-section {
  min-height: 100vh;
}
.project-container {
  padding-top: 3.75rem;
}

.project-wrapper {
  background: white;
  padding-bottom: 2.5rem;
  margin-bottom: 2.5rem;
}
.project-content {
  margin-inline: 2.5rem;
}
</style>

<style lang="scss" scoped>
.motivations-content {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;
  padding-block: 2rem;
  padding-inline: 1rem;

  &__realization {
    display: flex;
    flex-direction: column;

    h4 {
      margin-bottom: 1rem;
      color: $gray-dark;
      text-transform: uppercase;
    }

    ul {
      padding-left: 1rem;
    }

    li {
      margin-bottom: 1rem;
      line-height: 1.4;
      list-style-type: disc;
      word-break: break-word;

      &::marker {
        color: $yellow;
      }
    }
  }
}

.motivations {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;
  padding-block: 2rem;
  padding-inline: 1rem;

  &__content {
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr;

    &--item {
      display: flex;
      flex-direction: column;

      h4 {
        margin-bottom: 1rem;
        color: $gray-dark;
        text-transform: uppercase;
      }

      ul {
        padding-left: 1rem;
      }

      li {
        margin-bottom: 0.75rem;
        line-height: 1.5rem;
        list-style-type: disc;
        word-break: break-word;
        font-size: 0.875rem;

        &::marker {
          width: 0.5rem;
          height: 0.5rem;
          color: $yellow;
        }
      }
    }
  }

  &__opinion {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr;
    padding-block: 2rem;
    padding-inline: 1rem;

    &--card {
      position: relative;
      background-color: $white-background;
      line-height: 1.4;
      padding-inline: 2.75rem;
      line-break: anywhere;
    }

    &--logo {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-25%, -25%);
    }
  }
}

.salary-form {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  label {
    font-size: 0.875rem;
  }

  .text-area-wrapper {
    margin-bottom: 0.5rem;
  }
}

.salary {
  label {
    grid-column: 1 / -1;
  }

  & > div {
    display: grid;
    margin-bottom: 0.75rem;
    gap: 1rem;
    grid-template-columns: 1fr 1fr 1.5fr;
  }

  & > div:first-child {
    margin-bottom: 0.25rem;
  }

  & > div:nth-child(2) > div:last-child {
    grid-column: 1 / -1;
  }

  & > div:last-child > div:last-child {
    grid-column: 2 / -1;
  }
}
.form-group {
  display: grid;
  align-items: center;
  margin-bottom: 0.5rem;
  grid-template-columns: 1fr auto;
}

:deep(.motivations-content__realization) {
  .textarea-component {
    height: 20rem;
  }

  .counter-wrapper {
    gap: 1rem;
  }
}

.form-actions {
  display: flex;
  gap: 2rem;
  grid-column: 1 / -1;
}

@media (min-width: 780px) {
  .motivations__content {
    grid-template-columns: repeat(4, 1fr);
  }
}
</style>
