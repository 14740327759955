import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import type { CandidateToMappingType } from "@/common/services/candidates";
import {
  candidateToMapping,
  declineToMapping,
  getListMappingCandidates,
  saveListMapping,
} from "@/common/services/candidates";
import {
  getScreeningListActivity,
} from "@/common/services/screening";

export function useCandidateToMapping() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["mapping_candidates"],
    mutationFn: (body: CandidateToMappingType) => candidateToMapping(body),
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({ queryKey: ["mapping_candidates"] });
      await queryClient.refetchQueries({
        queryKey: ["projects", variables.candidate.project_id],
      });
    },
  });
}

export function useDeclineToMapping() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["mapping_candidates"],
    mutationFn: (body: CandidateToMappingType) => declineToMapping(body),
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({ queryKey: ["mapping_candidates"] });
      await queryClient.refetchQueries({
        queryKey: ["projects", variables.candidate.project_id],
      });
    },
  });
}

export function useFinishMapping() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["mapping_candidates"],
    mutationFn: (project_id: string) => finishMapping(project_id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["mapping_candidates"] });
      queryClient.invalidateQueries({ queryKey: ["projects"] });
    },
  });
}

export function useSaveListMapping() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["mapping_candidates"],
    mutationFn: (project_id: string) => saveListMapping(project_id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["mapping_candidates"] });
    },
  });
}

export function useListMappingCandidates(project_id: string) {
  return useQuery({
    queryKey: ["mapping_candidates", "project", project_id],
    queryFn: () => getListMappingCandidates(project_id),
  });
}

export function useListScreeningActivity(project_id: string) {
  return useQuery({
    queryKey: ["mapping_candidates", "project", project_id, "list_screening"],
    queryFn: () => getScreeningListActivity(project_id),
  });
}
