<script setup lang="ts">
import { ref } from "vue";
import Button from "primevue/button";

const props = defineProps<{
  modelValue: number
  step: number
}>();

const emit = defineEmits<{
  (e: "update:modelValue", value: number): void
}>();

const weight = ref(props.modelValue);

function increase() {
  weight.value = (weight.value || 0) + props.step;
  emit("update:modelValue", weight.value);
}
function decrease() {
  if (weight.value === 0)
    return;
  weight.value = (weight.value || 0) - props.step;
  emit("update:modelValue", weight.value);
}
</script>

<template>
  <div class="weight-input-wrapper">
    <div class="w-input">
      <Button icon="pi pi-minus" @click="decrease()" />
      <div class="w-input-value">
        {{ weight }}%
      </div>
      <Button icon="pi pi-plus" @click="increase()" />
    </div>
  </div>
</template>

<style scoped lang="scss">
  .weight-input-wrapper {
    padding: 0.5rem 1rem;

    :deep(.p-button:enabled) {
      border: 0;
      background: transparent;
    }

    :deep(.p-button-icon) {
      color: $gray-border;

      &:hover {
        color: $gray;
      }
    }

    :deep(.p-button.p-button-icon-only) {
      height: 1.875rem;
      padding: 0;
    }

    .w-input {
      display: flex;
      min-width: 10rem;
      height: 1.875rem;
      align-items: center;
      justify-content: space-between;
      border: 1px solid $gray-border;
      border-radius: 0.375rem;
      color: $gray-border;

      .w-input-value {
        color: inherit;
        font-size: 1.25rem;
        font-weight: 700;
      }
    }
  }
</style>
