<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import Input from "@/components/inputs/Input.vue";

export default defineComponent({
  name: "FormInput",
  components: {
    Input,
  },
  props: {
    error: Boolean,
    errorMessage: String,
    placeholder: String,
    onFocus: Function,
    onBlur: Function,
    onEnter: Function,
    onKeyDown: Function,
    mask: String,
  },
  setup() {
    const input = ref<InstanceType<typeof Input>>(null);
    const content = computed<string>(() => input.value?.content || "");
    const clear = () => input.value?.clear();
    const setContent = (value: string) => (input.value.content = value);

    return {
      input,
      content,
      clear,
      setContent,
    };
  },
});
</script>

<template>
  <Input
    v-if="mask"
    ref="input"
    v-maska="mask"
    :error="error"
    :error-message="errorMessage"
    :placeholder="placeholder"
    :on-focus="onFocus"
    :on-blur="onBlur"
    :on-enter="onEnter"
    :on-key-down="onKeyDown"
  />
  <Input
    v-else
    ref="input"
    :error="error"
    :error-message="errorMessage"
    :placeholder="placeholder"
    :on-focus="onFocus"
    :on-blur="onBlur"
    :on-enter="onEnter"
    :on-key-down="onKeyDown"
  />
</template>

<style lang="scss" scoped>
.component-input:deep {
  margin-bottom: 0.3rem;

  .input-wrapper {
    height: 28px;
    border: 1px solid $gray-border;
    border-radius: 6px;
  }

  .error-wrapper {
    justify-content: start;

    span {
      padding-top: 2px;
      margin-left: 5px;
    }
  }
}
</style>
