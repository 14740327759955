<script lang="ts">
import type { PropType } from "vue";
import { computed, defineComponent } from "vue";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import { breakpoints } from "@/utils/breakpoints";

interface Tab {
  id: string
}

export default defineComponent({
  name: "ImuaTabs",
  components: {
    TabView,
    TabPanel,
  },
  props: {
    tabs: {
      type: Array as PropType<Tab[]>,
      required: true,
    },
    activeTab: {
      type: String as PropType<string>,
      default: "",
    },
    color: {
      type: String,
      default: "primary",
    },
  },
  emits: ["update:activeTab"],
  setup(props, { emit }) {
    const tabsMap = computed(() =>
      (props.tabs as Tab[]).reduce((map, next, index) => {
        map[next.id] = index;
        map[index] = next.id;
        return map;
      }, {}));

    const activeTabIndex = computed({
      get: () => tabsMap.value[props.activeTab as string],
      set: newTab => emit("update:activeTab", tabsMap.value[newTab]),
    });

    const smallerThanXL = breakpoints.smaller("xl");

    return {
      tabsMap,
      activeTabIndex,
      smallerThanXL,
    };
  },
});
</script>

<template>
  <TabView
    v-model:activeIndex="activeTabIndex"
    class="imua-tabview"
    lazy
    :scrollable="smallerThanXL || tabs?.length >= 4"
    :class="color"
  >
    <TabPanel v-for="tab in tabs" :key="tab.id" :header="tab.header" :disabled="tab.disabled">
      <slot name="tab-content" :props="tab.props" :events="tab.events" :component="tab.component" />
    </TabPanel>
  </TabView>
</template>

<style lang="scss" scoped>
.imua-tabview:deep {
  &.primary .p-highlight .p-tabview-nav-link .p-tabview-title {
    color: $primary;
  }

  &.secondary .p-highlight .p-tabview-nav-link .p-tabview-title {
    color: $secondary;
  }
}

.imua-tabview.p-tabview:deep {
  overflow-x: auto;

  .p-tabview-nav {
    border: none;
    background-color: transparent;

    li {
      margin-right: 0.75rem;

      .p-tabview-nav-link {
        padding: 0.75rem 1.5rem;
        border: none;
        background-color: $gray-gainsboro;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        font-size: 0.875rem;
        font-weight: bold;
        text-transform: uppercase;

        &:focus {
          box-shadow: none;
        }
      }

      &.p-highlight {
        .p-tabview-nav-link {
          background-color: $white-full;

          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }
}
</style>
