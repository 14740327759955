<script setup lang="ts">
// GroupSliderEvaluation.vue
import { debounce } from "lodash";
import SliderKeyed from "./SliderKeyedShortlist.vue";
import { SvgIcon } from "@/components/icon";
import { ATTRIBUTES_TITLES, CULTURAL_MATCH_TITLES } from "@/entities/project/construction-guide";

import type { SearchCandidate } from "@/common/composables/useShortlist";
import { ScaleEnum } from "@/schemas/enums/construction_guide";

type TabType = "attributes" | "cultural_match";
interface AttributesEvaluation { cognitive_intelligence: EvaluationType; emotional_intelligence: EvaluationType; spiritual_intelligence: EvaluationType; energy: EvaluationType; maturity: EvaluationType }
interface CulturalMatchEvaluation { practical: EvaluationType; logical: EvaluationType; reflexive: EvaluationType; affective: EvaluationType }

interface EvaluationTypeMap {
  attributes: AttributesEvaluation
  cultural_match: CulturalMatchEvaluation
}

type EvaluationTypeKey = keyof EvaluationTypeMap;

type EvaluationByType<T extends EvaluationTypeKey> = T extends keyof EvaluationTypeMap
  ? EvaluationTypeMap[T]
  : never;

const props = withDefaults(defineProps<{
  trackColor?: string
  backgroundColor?: string
  markerColor?: string
  iconColor?: string
  tab: TabType
  constructionValueColor?: "#F8D272" | "#a8a8a8"
}>(), {
  backgroundColor: "#666666",
  markerColor: "#fcaf45",
  iconColor: "#000",
});

const emit = defineEmits<{
  (event: "edit"): void
}>();
const route = useRoute();
const { t } = useI18n();
const projectId = computed(() => String(route.params.id));
const personID = computed(() => String(route.params.person));
const {
  data: construction,
} = useConstructionOrganizationQuery(String(projectId.value));

const {
  data: interview,
} = useInterviewEvaluationQuery(String(projectId.value), String(personID.value));

const {
  mutateAsync: patchInterview,
} = usePatchInterviewEvaluationQuery();

const person = ref<SearchCandidate | null>(null);

const interviewId = computed(() => {
  return interview.value?._id;
});
const sliderDefaults = {
  showLegend: true,
  showScore: true,
  showControlMarker: true,
  background: "white-background",
  selectedColor: "gray-slider",
  selectedMarkerColor: "black",
  controlColor: "gray-a8",
  controlMarkerColor: "yellow",
  showSelectedMarker: false,
};

const iconsAttributes = {
  [ATTRIBUTES_TITLES.COGNITIVE_INTELLIGENCE]: "cognitive_icon",
  [ATTRIBUTES_TITLES.EMOTIONAL_INTELLIGENCE]: "emotional_icon",
  [ATTRIBUTES_TITLES.SPIRITUAL_INTELLIGENCE]: "spiritual_icon",
  [ATTRIBUTES_TITLES.ENERGY]: "bolt_icon",
  [ATTRIBUTES_TITLES.MATURITY]: "maturity_icon",
};
const iconsCulturalMatch = {
  [CULTURAL_MATCH_TITLES.AFFECTIVE]: "heart_icon",
  [CULTURAL_MATCH_TITLES.LOGICAL]: "brain_icon",
  [CULTURAL_MATCH_TITLES.PRACTICAL]: "gears_icon",
  [CULTURAL_MATCH_TITLES.REFLEXIVE]: "feather_icon",
};
const icons = props.tab === "attributes" ? iconsAttributes : iconsCulturalMatch;

const options = computed(() => Object.keys(construction.value?.ideal_profile[props.tab] as keyof EvaluationByType<typeof props.tab> || []).map(attr => ({
  key: attr,
  title: t(`${props.tab}.${attr.toUpperCase()}`),
  tooltip: attr.toUpperCase(),
  answer: construction.value?.ideal_profile[props.tab][attr as keyof EvaluationByType<typeof props.tab>],
  selected: interview.value?.[props.tab][attr].level,
})));

function checkActive(value: any) {
  return !value || value === "EMPTY" ? "" : "active";
}

async function fetchCandidate() {
  const response = await searchCandidates({
    project_id: String(route.params.id),
    person_id: String(route.params.person),
    project_stage: "SHORTLIST",
    skip: 0,
    limit: 1,
  });

  person.value = response && response.length > 0 ? response[0] : null;
}

interface EvaluationType {
  observations: string
  level: valueMapKey | null
  score: number | null
}
type valueMapKey = keyof typeof valueMap | null;

const valueMap = {
  EMPTY: 0,
  LOW: 1,
  AVERAGE: 2,
  HIGH: 3,
  VERY_HIGH: 4,
};

const attributesEvaluation = ref<AttributesEvaluation>({
  cognitive_intelligence: {
    observations: "",
    level: null,
    score: null,
  },
  emotional_intelligence: {
    observations: "",
    level: null,
    score: null,
  },
  spiritual_intelligence: {
    observations: "",
    level: null,
    score: null,
  },
  energy: {
    observations: "",
    level: null,
    score: null,
  },
  maturity: {
    observations: "",
    level: null,
    score: null,
  },
});

const culturalMatchEvaluation = ref<CulturalMatchEvaluation>({
  practical: {
    observations: "",
    level: null,
    score: null,
  },
  logical: {
    observations: "",
    level: null,
    score: null,
  },
  reflexive: {
    observations: "",
    level: null,
    score: null,
  },
  affective: {
    observations: "",
    level: null,
    score: null,
  },
});

const evaluationValue = props.tab === "attributes"
  ? { ...attributesEvaluation.value }
  : { ...culturalMatchEvaluation.value };

const evaluation = ref(evaluationValue) as Ref<EvaluationByType<typeof props.tab>>;
const debouncedUpdateAttribute = debounce(updateAttribute, 300);

function debouncedUpdateAndPatch(key: keyof EvaluationByType<typeof props.tab>, value: Partial<EvaluationType>) {
  debouncedUpdateAttribute(key, value);
}

function updateAttribute(key: keyof EvaluationByType<typeof props.tab>, value: Partial<EvaluationType>) {
  evaluation.value = {
    ...evaluation.value,
    [key]: {
      ...evaluation.value[key] as EvaluationByType<typeof props.tab>,
      ...value,
    },
  };
  patchInterview({
    _id: interviewId.value,
    [props.tab]: evaluation.value,
  });
}

const attributesMax = ref(2);

const blocked = ref({
  attributes: false,
  cultural_match: false,
});

const choices = computed(() => ([
  "EMPTY",
  "LOW",
  "AVERAGE",
  "HIGH",
  "VERY_HIGH",
]
  .map(data => ({
    label: t(`projects.generic.scale.enum.${data}`).toUpperCase(),
    value: data,
  }))
));

const constructionAttributes = ref();

watchEffect(() => {
  if (interview.value)
    evaluation.value = { ...interview.value[props.tab] };
  if (construction.value) {
    blocked.value[props.tab] = checkBlocked(construction.value?.ideal_profile[props.tab]);
    constructionAttributes.value = construction.value?.ideal_profile[props.tab];
  }
});

watchEffect(async () => {
  await fetchCandidate();
});

function checkBlocked(data: any) {
  const total = Object.keys(data).filter(key => data[key] !== "EMPTY");

  return total?.length >= attributesMax.value;
}
</script>

<template>
  <div class="attributes-component">
    <div v-for="(item, index) of options" :key="`attribute-${item}`" class="content">
      <div class="data">
        <span class="data-icon" :class="[checkActive(item.answer)]">
          <SvgIcon :icon="icons[item.key.toUpperCase() as never]" margin="none" />
        </span>
        <span class="data-label">
          {{ item.title }}
        </span>
      </div>
      <div class="item-evaluate">
        <div class="item-slider">
          <div v-if="index === 0" class="attributes-keys">
            <label
              v-for="(key, level_idx) in ScaleEnum"
              :key="level_idx"
            >
              {{ t(`projects.generic.scale.enum.${key.toUpperCase()}`) }}
            </label>
          </div>
          <SliderKeyed
            :model-value="(evaluation[item.key as keyof EvaluationByType<typeof props.tab>] as Partial<EvaluationType>).level"
            :options="choices"
            :control-value="item.answer"
            class="slider"
            :score-marker="item.answer"
            :track-color="trackColor"
            :background-color="backgroundColor"
            :marker-color="markerColor"
            :disabled="constructionAttributes[item.key] === 'EMPTY'"
            :control-color="sliderDefaults.controlColor"
            :construction-value-color="constructionValueColor"
            @update:model-value="debouncedUpdateAndPatch(item.key as keyof EvaluationByType<typeof props.tab>, { level: $event })"
          />
          <div class="box-descriptions">
            {{ t(`projects.shortlist.enums.${props.tab === 'attributes' ? 'attributes' : 'cultural'}.${item.key.toUpperCase()}`) }}
          </div>
        </div>
        <div class="item-score">
          <div class="score" />
        </div>
      </div>
    </div>
    <div v-if="sliderDefaults.showLegend" class="legends">
      <div class="item">
        <div class="marker marker-a" :class="[`--bg-${sliderDefaults.controlMarkerColor}`]" />
        <div class="marker-title">
          {{ t("projects.screening.guide.PostScreening.educationBackground.idealProfile") }}
        </div>
      </div>
      <div class="item">
        <div class="marker marker-b" :class="[`--bg-${sliderDefaults.selectedMarkerColor}`]" />
        <div class="marker-title">
          {{ t("projects.screening.labels.candidate") }}
        </div>
      </div>
    </div>
  </div>
  <Button class="conclude-button" type="submit" variation="secondary" @click="emit('edit')">
    {{ t("text.form.conclude") }}
  </Button>
</template>

<route lang="yaml">
meta:
  layout: project-stage
  stage: shortlist
  tab: attributes
</route>

<style lang="scss" scoped>
@mixin label-styles($index) {
  label:nth-child(#{$index}) {
    left: calc(#{$index} * 25% - 5rem);
  }
}

.attributes-keys {
  position: relative;
  display: flex;
  width: 100%;

  label {
    top: -2rem;
    position: absolute;
    width: 25%;
    display: flex;
    justify-content: center;
    color: #707070;
    &:last-child {
      justify-content: flex-start;
    }
  }

  @for $i from 1 through 4 {
    @include label-styles($i);
  }
}
.attributes-component {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1.5rem;
  .content {
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr 4fr;

    .item-radios {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0 1rem 0 2rem;
    }
  }

  .data {
    display: flex;
    min-width: 13rem;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    height: 3.75rem;

    .data-icon {
      display: flex;
      width: 55px;
      min-width: 55px;
      height: 55px;
      min-height: 55px;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      box-shadow: 0 2px 4px #00000029;
      color: $gray-border;
    }

    .data-icon.active {
      background-color: $white-background;
      color: v-bind("iconColor");
    }

    .data-label {
      width: -webkit-fill-available;
      line-height: 1.3;
      text-transform: uppercase;
    }

    .help {
      text-transform: none;

      svg {
        color: $gray-shade-dark;
      }
    }
  }

  .legends {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 3rem;
    gap: 4rem;
    margin-inline-start: 16rem;

    .item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;

      .marker {
        width: 7px;
        height: 20px;
        background: $gray-shade-dark;
      }

      .marker-a {
        width: 5px;
        height: 26px;
      }

      .marker-b {
        width: 11px;
        height: 20px;
      }

      .marker-title {
        font-size: 0.75rem;
      }
    }
  }

  .form-actions {
    display: flex;
    margin: 2rem 0;
    gap: 2rem;
    grid-column: 1 / -1;
  }

  .item-evaluate {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    gap: 1.5rem;

    .item-slider {
      width: calc(100% - 3rem);
    }
    .item-score {
      display: flex;
      min-width: 5.5rem;
      align-items: center;

      .score {
        display: flex;
        min-width: 3.5rem;
        height: fit-content;
        align-items: center;
        justify-content: center;
        padding: 0.4375rem 1.18rem;
      }
    }
  }
}
.box-descriptions {
  margin-top: 1.5rem;
  margin-left: 0.625rem;
  font-size: 0.75rem;
  line-height: 1.5;
}

@mixin colors-change($colors-map) {
  @each $key, $value in $colors-map {
    .attributes-component {
      .legends {
        .item {
          .marker.--bg-#{$key} {
            background: $value;
          }
        }
      }
    }
  }
}

@include colors-change($all-colors);
</style>
