import { entitiesService, projectsService } from "../client";
import { NotFoundError } from "../errors";
import type { Maybe, PaginationParams, SuccessPaged } from "../types";
import type { Phone } from "@/common/schemas/person";
import type { Address } from "@/schemas/common";
import type {
  Corporation,
  baseEducationalOrganization,
  educationalOrganization,
  educationalOrganizationPut,
} from "@/schemas/corporation";
import { CorporationSchema } from "@/schemas/corporation/corporation";
import { parseParams } from "@/utils/parsers";

const url = "/corporations";
const buildIdUrl = (id: string) => `${url}/${id}`;
const buildCorporationUrl = (corporation_id: string) => `${url}/${corporation_id}`;

// TODO move to zod
interface useCorporationHeaderPatch { corporation_header_patch: CorporationHeaderPatch }
export interface CorporationHeaderPatch {
  name?: string
  address?: Address
  url?: string
  phone?: Phone | null
  linkedin_url?: string | null
  facebook_url?: string | null
  is_educational?: boolean
  educational_patch?: EducationalPatch | null
}

interface EducationalPatch {
  abbreviation?: string
  corporation_type?: string
  first_line?: boolean
}

export async function listCorporation(params: PaginationParams = {}): Promise<SuccessPaged<Corporation[]>> {
  const parsedParams = parseParams(params);
  const { data } = await entitiesService.get(url, { params: { ...parsedParams } });
  return data;
}

export async function searchCorporation(params: PaginationParams = {}): Promise<SuccessPaged<Corporation[]>> {
  const parsedParams = parseParams(params);
  const { data } = await entitiesService.get("/search/corporations", {
    params: { ...parsedParams },
  });
  return data;
}

export async function searchCorporationSimilar(params: {
  limit?: number
  skip?: number
  sort?: string[] | string
  name?: string
  url?: string
}): Promise<SuccessPaged<Corporation[]>> {
  const parsedParams = parseParams(params);
  const { data } = await entitiesService.get("/search/corporations/similar", {
    params: { ...parsedParams },
  });
  return data;
}

export async function searchCorporationDetailsProjects(corporation_id: string, params: PaginationParams = {}) {
  const parsedParams = parseParams(params);
  const { data } = await projectsService.get(
    `/projects/search?corporation_id=${corporation_id}`,
    { params: { ...parsedParams } },
  );
  return data;
}

export async function searchEducationalCorporation(params: PaginationParams = {}): Promise<SuccessPaged<Corporation[]>> {
  const parsedParams = parseParams(params);
  const { data } = await entitiesService.get("/search/organizations/educational", {
    params: { ...parsedParams },
  });
  return data;
}

export async function getCorporation(id: string): Promise<Maybe<Corporation>> {
  try {
    const {
      data: { data },
    } = await entitiesService.get(buildIdUrl(id));

    const validation = CorporationSchema.safeParse(data);
    return data;
  }
  catch (error) {
    if (error instanceof NotFoundError)
      return null;

    throw error;
  }
}

export async function updateCorporation(corporation_id: string, payload: { corporation: Corporation }): Promise<Corporation> {
  const {
    data: { data },
  } = await entitiesService.put(buildCorporationUrl(corporation_id), payload);
  return data;
}

export async function createCorporation(payload: {
  corporation: Partial<Corporation>
}): Promise<Corporation> {
  const {
    data: { data },
  } = await entitiesService.post(url, payload);
  return data;
}

export async function getEducationalOrganization(corporation_id: string): Promise<baseEducationalOrganization[]> {
  const {
    data: { data },
  } = await entitiesService.get(
    `search/organizations/educational?corporation_id=${corporation_id}&skip=0&limit=10`,
  );
  return data;
}

export async function putEducationalOrganization(corporation_id: string, payload: educationalOrganizationPut): Promise<Corporation> {
  const {
    data: { data },
  } = await entitiesService.put(`/organizations/educational/${corporation_id}`, payload);
  return data;
}
export async function patchEducationalOrganization(corporation_id: string, payload: educationalOrganizationPut): Promise<Corporation> {
  return await entitiesService.patch(`/organizations/educational/corporation_id/${corporation_id}`, { educational_organization: payload.educational_organization })
    .then(res => res.data?.data);
}
export async function toEducationalOrganization(payload: educationalOrganization): Promise<Corporation> {
  const {
    data: { data },
  } = await entitiesService.post("/organizations/educational", payload);
  return data;
}
export async function patchCorporationHeader(corporation_id: string, payload: useCorporationHeaderPatch): Promise<Corporation> {
  return await entitiesService.patch(`/corporations/patch-corporation-header/${corporation_id}`, { corporation_header_patch: payload.corporation_header_patch })
    .then(res => res.data?.data);
}

export async function appendAka(corporation_id: string, payload: { also_known_as: string[] }): Promise<Corporation> {
  const {
    data: { data },
  } = await entitiesService.put(`/corporations/append-aka/${corporation_id}`, payload);
  return data;
}
