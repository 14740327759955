<script setup lang="ts">
import { mdiPlusBox } from "@mdi/js";
import { format, parseISO } from "date-fns";
import type { SearchCandidate } from "@/common/composables/useShortlist";
import ActivitiesSchedule from "@/common/components/Export/ActivitiesSchedule.vue";
import { ButtonSpan } from "@/components/button";
import { SvgIcon } from "@/components/icon";
import { ShortlistEnum } from "@/schemas/project/pdf";
import { getSortedShortlistCandidates } from "@/projects/pages/projects/[id]/shortlist/adherence-table/utils";
import { Avatar } from "@/components/avatar";
import PresentationInputDateEditable from "@/common/components/PresentationInputDateEditable.vue";

interface StageTabsType { key: string; name: string; path: string }

const route = useRoute();
const router = useRouter();
const { t } = useI18n();

const projectId = computed(() => String(route.params.id));

const { data: project, error, isLoading: loading } = await useProject(projectId);
const { mutateAsync: getShortlistCandidates } = mutateShortlistCandidates();

const activitiesTab = computed(() => ({
  key: "activities",
  name: t("projects.details.activities.tabsCandidatesEnum.activities"),
  path: `/projects/${route.params.id}/shortlist/activities`,
}));

const funnelTab = computed(() => ({
  key: "funnel",
  name: t("projects.shortlist.funnel.title"),
  path: `/projects/${route.params.id}/shortlist/funnel`,
}));

const adherenceTableTab = computed(() => ({
  key: "adherenceTable",
  name: t(`projects.shortlist.pdf.${ShortlistEnum.ADHERENCE_TABLE}`),
  path: `/projects/${route.params.id}/shortlist/adherence-table`,
}));

const stageTabs = ref<StageTabsType[]>([activitiesTab.value, funnelTab.value, adherenceTableTab.value]);

const currentRoute = ref(0);

function goTo(tabIndex: number) {
  if (tabIndex >= 0 && stageTabs.value)
    router.push(stageTabs.value[tabIndex]?.path || "#");
}

const {
  mutate: updateProject,
} = updateProjectMutation(String(route.params.id));

const creatingSchedule = ref(false);
const dropdown = ref({ active: false });

const hasSchedule = computed(() => {
  if (!project.value?.schedules || !Object.values(project.value?.schedules).filter(Boolean).length)
    return false;
  const scheduleKeys = Object.values(project.value?.schedules || {});
  return scheduleKeys.filter(data => Object.values(data).filter(Boolean)).length > 0;
});

function submitSchedule(schedules: ProjectSchedulesType) {
  updateProject({
    ...project.value,
    schedules,
  });
  creatingSchedule.value = false;
}
const firstShortlistDate = ref();
const firstShortlistRecruiterId = ref();
const firstShortlistRecruiter = ref();

getShortlistCandidates(String(route.params.id), {
  onSuccess: async (candidateList: SearchCandidate[]) => {
    if (candidateList)
      stageTabs.value = getSortedShortlistCandidates(String(route.params.id), candidateList, stageTabs.value);
    await onSelectRequester();
  },
});
const datesRef = ref(null) satisfies Ref<HTMLHeadElement | null>;
const editingDate = ref(false);
const shortlistPresentationDate = ref();

watchEffect(() => {
  const routeIndex = stageTabs.value.findIndex(tab => tab.key === String(route.params.person));
  currentRoute.value = routeIndex;
  if (project.value) {
    shortlistPresentationDate.value = formatLocale(project.value?.shortlist_presentation_date);
    firstShortlistDate.value = project.value?.stages.shortlist.first_shortlist_date;
    firstShortlistRecruiterId.value = project.value?.stages.shortlist.first_shortlist_recruiter;
  }
});
async function onSelectRequester() {
  if (!firstShortlistRecruiterId.value)
    return;
  const data = await getByUserId(
    firstShortlistRecruiterId.value,
  );
  firstShortlistRecruiter.value = data;
}
function formatDate(date: string | null | undefined) {
  if (!date)
    return "";
  const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/;
  if (dateRegex.test(date))
    return date;

  return format(parseISO(date), "dd/MM/yyyy");
}
function submitPresentationDate(date: Date) {
  updateProject({
    ...project.value,
    shortlist_presentation_date:
      date
        ? new Date(date).toISOString()
        : null,
  });
  shortlistPresentationDate.value = formatLocale(date);
  editingDate.value = false;
}

function formatLocale(date: Date | string | null | undefined) {
  return date ? new Date(date).toLocaleDateString() : null;
}
</script>

<template>
  <section class="project-activities">
    <div v-if="error">
      could not load
    </div>
    <div v-if="project" class="project-container">
      <ProjectStages :id="projectId" />
      <div v-if="project" class="project-wrapper">
        <ProjectTabs :stage-tabs="stageTabs" :active="currentRoute" class="tabs" @change="goTo($event)" />
        <div class="project-content">
          <div class="project-body">
            <h4 class="activities-tab__schedule-header">
              {{ t("projects.generic.stages.enum.shortlist").toUpperCase() }}
            </h4>
            <div class="list-activities">
              <div class="list-activities-labels">
                <div>
                  {{ t("text.date") }}
                </div>
                <div>
                  {{ t("text.name") }}
                </div>
                <div class="status">
                  {{ t("text.activitie") }}
                </div>
                <div v-if="firstShortlistRecruiter" class="actions" />
                <div class="status">
                  {{ t("text.presentationDate") }}
                </div>
              </div>
              <div class="list-activities-candidate">
                <div>
                  <span v-if="firstShortlistDate">
                    {{ formatDate(firstShortlistDate) }}
                  </span>
                </div>
                <div class="recruiter-content">
                  <div v-if="firstShortlistRecruiter" class="recruiter">
                    <Avatar :src="firstShortlistRecruiter.avatar_url" size="1.875rem" />
                    {{ firstShortlistRecruiter.first_name }} {{ firstShortlistRecruiter.last_name }}
                  </div>
                </div>
                <div style="text-align: center;">
                  {{ t("projects.shortlist.about.tableDescription") }}
                </div>
                <PresentationInputDateEditable
                  :model-value="formatDate(shortlistPresentationDate)"
                  @submit="submitPresentationDate($event)"
                />
              </div>
            </div>
          </div>
          <div class="activities-tab__schedule-header">
            <div class="subtitle">
              {{ t("projects.details.activities.schedule.title") }}
            </div>

            <div v-if="project" class="position-relative">
              <Button
                class="align-center px-1"
                :variation="dropdown.active ? 'primary' : 'secondary'"
                @click="dropdown.active = !dropdown.active"
              >
                <SvgIcon icon="calendar2_icon" width="20" height="20" margin="none" />
                <SvgIcon
                  icon="chevron_icon"
                  width="20"
                  height="20"
                  margin="none"
                  :rotate="dropdown.active ? '180deg' : '0'"
                />
              </Button>

              <div v-show="dropdown.active" class="dropdown">
                <ButtonSpan
                  :icon="{ type: 'mdi', path: mdiPlusBox }"
                  :label="t('projects.details.activities.schedule.create')"
                  variation="default"
                  class="add-schedule"
                  :disabled="hasSchedule"
                  @click="(creatingSchedule = true), (dropdown.active = false)"
                />

                <ButtonSpan
                  icon="edit2_icon"
                  :label="t('projects.details.activities.schedule.edit')"
                  variation="default"
                  class="add-schedule"
                  :disabled="!hasSchedule"
                  @click="(creatingSchedule = true), (dropdown.active = false)"
                />
              </div>
            </div>
          </div>

          <div v-if="project">
            <Transition name="slide-fade">
              <ActitivieScheduleForm
                v-if="creatingSchedule"
                :schedules="project.schedules"
                @cancel="creatingSchedule = false"
                @submit="submitSchedule"
              />
            </Transition>
            <ActivitiesSchedule
              v-if="!creatingSchedule && hasSchedule"
              :schedules="project.schedules"
              :stages="project.stages"
              stage="construction"
            />
            <Checkpoint v-if="hasSchedule" :schedules="project.schedules" :stages="project.stages" stage="construction" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<route lang="yaml">
meta:
  layout: project-stage
  stage: shortlist
  tab: activities
</route>

<style lang="scss" scoped>
.project-activities {
  min-height: 100vh;
}

.project-container {
  padding-top: 3.75rem;
}

.project-wrapper {
  background: white;
  padding-bottom: 2.5rem;
  margin-bottom: 2.5rem;
}

.project-content {
  margin-inline: 1.5rem;
}
</style>

<style lang="scss" scoped>
.align-center {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.justify-end {
  justify-content: flex-end;
}

.trash-icon {
  color: $gray-medium;
}

.check-icon {
  color: $green;
}

.px-1 {
  padding-inline: 0.5rem;
}

.position-relative {
  position: relative;
}

.add-schedule {
  color: $gray-low;

  &:hover {
    color: $primary;
    font-weight: bold;
  }
}

.dropdown {
  position: absolute;
  z-index: 10;
  top: 100%;
  right: 50%;
  display: flex;
  min-width: max-content;
  flex-direction: column;
  padding: 0.75rem;
  border-radius: 6px;
  background-color: $white-full;
  box-shadow: 0 2px 4px #00000029;
  gap: 0.5rem;
}

.activities-tab {
  overflow: hidden;

  &__header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
    gap: 1rem;
  }

  &__schedule-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e9ecef;
    margin: 1rem 0;
    padding-block: 1rem;
    margin-block-end: 3.5rem;
  }

  &__form {
    width: 50%;
  }
}

@media screen and (max-width: 1024px) {
  .activities-tab {
    &__form {
      width: auto;
    }
  }
}

.subtitle {
  color: #666;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 700;
}

.slide-fade {
  &-enter-active {
    transition: all 400ms ease-out;
  }

  &-leave-active {
    transition: all 200ms cubic-bezier(1, 0.5, 0.3, 1);
  }

  &-enter-from,
  &-leave-to {
    opacity: 0;
    transform: translateY(-1.5rem);
  }
}

.list-activities {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-inline-end: 4rem;

  .status {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
  }

  .actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  &-title {
    color: $gray-dark;
    font-size: 1rem;
    font-weight: bold;
    text-transform: capitalize;
    letter-spacing: 1.42px;
    border-bottom: 1px solid $gray-border;
    padding-block: 1.3125rem;
    margin-block-end: 1.3125rem;
    text-transform: uppercase;
  }

  &-labels {
    display: grid;
    grid-template-columns: 1fr 21rem 2fr auto auto;
    padding-inline: 1rem;
    gap: 1rem;

    div {
      color: $red-violet;
      font-size: 0.75rem;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  &-candidate {
    display: grid;
    grid-template-columns: 1fr 2fr 2fr auto auto;
    align-items: center;
    gap: 1rem;
    background: $white-alabaster;
    padding-block: 0.75rem;
    padding-inline: 1rem;
  }
}
.recruiter-content {
  display: flex;
  align-content: center;
  gap: 1rem;
}
.recruiter {
  display: grid;
  place-items: center;
  grid-template-columns: auto 1fr;
  gap: 0.5rem;
}
</style>
