import { ShortlistEnum } from "@/schemas/project/pdf";

export function useTabsProject(id: string) {
  const { t } = useI18n();
  const router = useRouter();

  const isBlocked = ref(false);

  function setBlocked(value: boolean) {
    isBlocked.value = value;
  }
  const stageTabs = computed(() => ([
    {
      key: "activities",
      name: t("projects.details.tabs.activities"),
      path: `/projects/${id}/construction/activities`,
    },
    {
      key: "corporation",
      name: t("projects.details.tabs.corporation"),
      path: `/projects/${id}/construction/corporation`,
    },
    {
      key: "market",
      name: t("projects.details.tabs.market"),
      path: `/projects/${id}/construction/market`,
    },
    {
      key: "profession",
      name: t("projects.details.tabs.profession"),
      path: `/projects/${id}/construction/profession`,
    },
    {
      key: "requester",
      name: t("projects.details.tabs.requester"),
      path: `/projects/${id}/construction/requester`,
    },
    {
      key: "construction-guide",
      name: t("projects.details.tabs.constructionGuide"),
      path: `/projects/${id}/construction/construction-guide`,
    },
    {
      key: "search-field",
      name: t("projects.details.tabs.searchField"),
      path: `/projects/${id}/construction/search-field`,
      active: !isBlocked.value,
    },
    {
      key: "ideal-profile",
      name: t("projects.details.tabs.idealProfile"),
      path: `/projects/${id}/construction/ideal-profile/about`,
      active: !isBlocked.value,
    },
  ]));

  function goTo(tabIndex: number) {
    if (!stageTabs || !stageTabs.value[tabIndex])
      return;

    router.push(stageTabs.value[tabIndex].path);
  }

  return {
    setBlocked,
    goTo,
    stageTabs,
  };
}

export function useScreeningTabs(id: string) {
  const { t } = useI18n();
  const router = useRouter();

  const isBlocked = ref(false);

  function setBlocked(value: boolean) {
    isBlocked.value = value;
  }

  const tabs = computed(() => [
    {
      key: "activities",
      name: t("projects.details.activities.tabsCandidatesEnum.activities"),
      path: `/projects/${id}/screenings/activities`,
    },
    {
      key: "candidates",
      name: t("projects.details.activities.tabsCandidatesEnum.candidates"),
      path: `/projects/${id}/screenings/candidates`,
    },
    {
      key: "guide",
      name: t("projects.details.activities.tabsCandidatesEnum.screeningGuide"),
      path: `/projects/${id}/screenings/activities`,
    },
    {
      key: "prioritization",
      name: t("projects.details.activities.tabsCandidatesEnum.priorization"),
      path: `/projects/${id}/screenings/prioritization`,
    },
  ]);

  function goTo(tabIndex: number) {
    if (!tabs || !tabs.value[tabIndex])
      return;

    router.push(tabs.value[tabIndex].path);
  }

  return {
    setBlocked,
    goTo,
    tabs,
  };
}

export function useInterviewTabs(id: string) {
  const { t } = useI18n();
  const router = useRouter();

  const isBlocked = ref(false);

  function setBlocked(value: boolean) {
    isBlocked.value = value;
  }

  const tabs = computed(() => {
    return [
      {
        key: "activities",
        name: t("projects.details.activities.tabsCandidatesEnum.activities"),
        path: `/projects/${id}/interviews/activities`,
      },
      {
        key: "candidates",
        name: t("projects.details.activities.tabsCandidatesEnum.candidates"),
        path: `/projects/${id}/interviews/candidates`,
      },
      {
        key: "guide",
        name: t("projects.details.activities.tabsCandidatesEnum.interviewGuide"),
        path: `/projects/${id}/interviews/activities`,
      },
      {
        key: "ranking",
        name: "Ranking",
        path: `/projects/${id}/interviews/ranking`,
      },
    ];
  });

  function goTo(tabIndex: number) {
    if (!tabs || !tabs.value[tabIndex])
      return;

    router.push(tabs.value[tabIndex].path);
  }

  return {
    setBlocked,
    goTo,
    tabs,
  };
}

export function useShortListTabs(id: string) {
  interface StageTabsType { key?: string; name?: string; path?: string; icon?: string; label?: string }
  const { t } = useI18n();
  const currentRoute = ref(2);
  const router = useRouter();
  const stageTabs = ref<StageTabsType[]>([
    {
      key: "activities",
      name: t("projects.details.activities.tabsCandidatesEnum.activities"),
      path: `/projects/${id}/shortlist/activities`,
    },
    {
      key: "funnel",
      name: t("projects.shortlist.funnel.title"),
      path: `/projects/${id}/shortlist/funnel`,
    },
    {
      key: "adherenceTable",
      name: t(`projects.shortlist.pdf.${ShortlistEnum.ADHERENCE_TABLE}`),
      path: `/projects/${id}/shortlist/adherence-table`,
    },
  ]);
  function goTo(tabIndex: number) {
    if (tabIndex >= 0 && stageTabs.value)
      router.push(stageTabs.value[tabIndex]?.path || "#");
  }
  return {
    stageTabs,
    currentRoute,
    goTo,
  };
}
