<script setup lang="ts">
import { Tab, TabGroup, TabList, TabPanels } from "@headlessui/vue";
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";
import { SvgIcon } from "@/components/icon";
import Button from "@/components/button/Button2.vue";
import { Sidebar } from "@/components/sidebar";

defineEmits(["edit"]);

const router = useRouter();
const route = useRoute();
const { t } = useI18n();

const tabsDefs = computed(() => ({
  "evaluation": {
    key: "evaluation",
    label: t("projects.shortlist.titles.evaluationAbbreviated"),
    tooltip: t("projects.shortlist.titles.evaluationAbbreviated"),
    icon: "icon_shortlist_evaluation",
  },
  "about": {
    key: "about",
    label: t("projects.shortlist.titles.aboutCandidate"),
    tooltip: t("projects.shortlist.titles.aboutCandidate"),
    icon: "icon_shortlist_about",
  },
  "academic": {
    key: "academic",
    label: t("projects.shortlist.titles.academicEducation"),
    tooltip: t("projects.shortlist.titles.academicEducation"),
    icon: "icon_shortlist_school",
  },
  "experiences": {
    key: "experiences",
    label: t("projects.shortlist.titles.experience"),
    tooltip: t("projects.shortlist.titles.experience"),
    icon: "icon_shortlist_experience",
  },
  "achievements": {
    key: "achievements",
    label: t("projects.shortlist.titles.achievements"),
    tooltip: t("projects.shortlist.titles.achievements"),
    icon: "icon_shortlist_realizations",
  },
  "attributes": {
    key: "attributes",
    label: t("projects.shortlist.titles.attributes"),
    tooltip: t("projects.shortlist.titles.attributes"),
    icon: "icon_shortlist_attributes",
  },
  "cultural-match": {
    key: "cultural-match",
    label: t("projects.shortlist.titles.culturalMatch"),
    tooltip: t("projects.shortlist.titles.culturalMatch"),
    icon: "icon_shortlist_cultural_match",
  },
  "motivation": {
    key: "motivation",
    label: t("projects.shortlist.titles.motivation"),
    tooltip: t("projects.shortlist.titles.motivation"),
    icon: "icon_shortlist_motivation_1",
  },
}));

const tabKeys = Object.keys(tabsDefs.value);
const activeTab = computed(() => router.currentRoute.value.meta.tab as string);
const active = computed(() => tabKeys.indexOf(activeTab.value));
const showOptions = ref(false);
const showPreview = ref(false);

function goTab(index: number) {
  router.push(`/projects/${route.params.id}/shortlist/${route.params.person}/${tabKeys[index]}`);
}

function tabNext() {
  goTab(active.value + 1);
}

function tabPrev() {
  goTab(active.value - 1);
}

const pdfDownloadType = ref("");
const pdfErrorDownload = ref(false);
// TODO: add export error modal
const pdfModal = computed(() => ({
  title: t("errors.titles.error").toUpperCase(),
  message: t("errors.pdf.download"),
  buttonText: t("errors.buttons.tryAgain").toUpperCase(),
}));

function openPdfError(type: string) {
  pdfDownloadType.value = type;
  pdfErrorDownload.value = true;
}

const {
  executeBullhornShortlist,
} = useExport(String(route.params.id), "shortlist");

async function downloadBullhornExport() {
  showOptions.value = false;
  await executeBullhornShortlist();
}

onMounted(() => {
  // TODO: improve this
  const tabs = document.querySelectorAll<HTMLButtonElement>(".tab-container .tab.checked");
  try {
    if (tabs && tabs.length > 0 && tabs[tabs.length - 1])
      tabs[tabs.length - 1]!.focus();
  }
  catch (e) {
    console.error(e);
  }
});
</script>

<template>
  <div>
    <TabGroup :selected-index="active" @change="goTab($event)">
      <TabList class="tab-container">
        <template v-for="tab in tabsDefs" :key="tab.key">
          <!-- TODO: keep state focus on reload page -->
          <Tab
            class="tab"
            :class="{
              checked: tabKeys.indexOf(tab.key) <= tabKeys.indexOf(activeTab),
            }"
          >
            <SvgIcon :icon="tab.icon" width="1.5rem" height="1.5rem" margin="none" />
            <div class="tab-tooltip">
              {{ tab.tooltip }}
            </div>
          </Tab>
          <div
            class="line-wrapper"
            :style="{
              visibility:
                tabKeys.indexOf(tab.key) < tabKeys.indexOf(activeTab) ? 'visible' : 'hidden',
            }"
          >
            <div class="line" />
          </div>
        </template>
        <div class="tab export" role="button" @click="showOptions = !showOptions">
          <SvgIcon icon="project_export" width="1rem" height="1rem" margin="none" />
          <div class="tab-tooltip" :class="{ hide: showOptions }">
            {{ t("projects.details.constructionGuide.idealProfile.tooltip.export") }}
          </div>
          <div v-if="showOptions" class="options-dropwdown">
            <div role="button" class="item" @click="() => (showPreview = true)">
              {{ t("projects.details.constructionGuide.idealProfile.tooltip.exportVersionImuaBeta") }}
            </div>
            <div role="button" class="item" @click="downloadBullhornExport()">
              {{ t("projects.details.constructionGuide.idealProfile.tooltip.exportVersionBullhorn") }}
            </div>
          </div>
        </div>
      </TabList>
      <TabPanels>
        <div class="heading-container">
          <div class="heading">
            <div class="actions">
              <!-- Keep state when navigate -->
              <Button v-if="activeTab !== tabKeys.at(0)" direction="ltr" variation="secondary" @click="tabPrev()">
                <SvgIcon type="mdi" size="24" margin="none" :icon="mdiChevronLeft" />
                {{ t("projects.details.constructionGuide.idealProfile.tabPrevious") }}
              </Button>
              <Button v-if="activeTab !== tabKeys.at(-1)" variation="secondary" @click="tabNext()">
                {{ t("projects.details.constructionGuide.idealProfile.tabNext") }}
                <SvgIcon type="mdi" size="24" margin="none" :icon="mdiChevronRight" />
              </Button>
            </div>
          </div>
        </div>
      </TabPanels>
    </TabGroup>
    <Sidebar class="sidebar-preview" :visible="showPreview === true" :on-close="() => (showPreview = false)">
      <ExportExportShortlist @cancel="showPreview = false" />
    </Sidebar>
  </div>
</template>

<style scoped lang="scss">
.sidebar-preview {
  :deep(.sidebar-wrapper) {
    width: 1211px;
  }
}
.tab-container {
  display: grid;
  width: 100%;
  grid-auto-flow: row;
  grid-row-gap: 1rem;
  grid-template-columns: repeat(auto-fill, 2.5rem);
  margin-block: 1.5rem;

  .line-wrapper {
    display: flex;

    .line {
      width: 100%;
      height: 0.5rem;
      align-self: center;
      background: $yellow;
    }
  }

  .tab {
    position: relative;
    display: flex;
    height: 2.5rem;
    align-items: center;
    justify-content: center;
    border-radius: 0.25rem;
    box-shadow: 0 2px 4px #0d0c0c29;
    color: #ccc;
    font-size: 1rem;
    border: 0;
    background: #fff;

    &:hover {
      border: 2px solid $gray-shade-dark;
    }

    &.export {
      width: 2.5rem;
      border: 1px solid #c0c0c0;
      color: #9b9b9b;
      grid-column-start: -2;

      &:hover {
        border: 1px solid transparent;
        background-color: #f7f7f7;
        box-shadow: none;
        color: $primary;
      }
    }

    &:hover,
    &.checked {
      background: $yellow;
      color: white;
    }

    &:hover {
      .tab-tooltip {
        visibility: visible;
      }
    }

    .tab-tooltip {
      position: absolute;
      bottom: 0;
      padding: 0.25rem;
      border-radius: 0.25rem;
      margin-bottom: -1.5rem;
      box-shadow: 0 2px 4px #0d0c0c29;
      color: $gray-dark;
      font-size: 0.75rem;
      visibility: hidden;
      white-space: nowrap;

      &.hide {
        visibility: hidden;
      }
    }
  }
}

.heading-container {
  margin-block: 2rem;
}

.heading {
  display: flex;
  align-items: center;
  padding-bottom: 0.5rem;
  color: $gray-dark;

  .title {
    padding: 0 1rem;
    font-size: 2rem;
    font-weight: 700;
    text-transform: uppercase;
    white-space: nowrap;
  }

  >button>svg {
    color: #ccc;

    &:hover {
      color: $gray-dark;
    }
  }

  .icon {
    display: grid;
    width: 3.25rem;
    height: 3.25rem;
    border-radius: 50%;
    background: $gray-border;
    place-items: center;
  }

  .actions {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    gap: 1rem;

    button {
      display: flex;
      height: 1.875rem;
      align-items: center;
      padding: 0 0.5rem 0 1rem;
      font-size: 0.75rem;

      &[direction="ltr"] {
        padding: 0 1rem 0 0.5rem;
      }
    }
  }
}
.options-dropwdown {
  position: absolute;
  z-index: 99;
  top: 112%;
  right: 0%;
  display: flex;
  flex-direction: column;
  padding: 0.25rem 0;
  background: #fff;
  border-radius: 6px;
  box-shadow: $box-shadow-card;

  .item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0.875rem 0.5rem;
    color: $gray-medium-dark;
    cursor: pointer;
    white-space: nowrap;

    &:hover {
      color: $red-violet;
    }
  }
}
</style>
