import { z } from "zod";

export enum DegreeLevelEnum {
  BACHELOR = "BACHELOR",
  POST_BACHELOR = "POST_BACHELOR",
  MBA = "MBA",
  MASTER = "MASTER",
  DOCTORATE = "DOCTORATE",
  POST_DOCTORATE = "POST_DOCTORATE",
  EXTRA_CURRICULAR_COURSE = "EXTRA_CURRICULAR_COURSE",
}

export enum YearsOfServiceEnum {
  ONE_YEAR = "ONE_YEAR",
  TWO_YEARS = "TWO_YEARS",
  THREE_YEARS = "THREE_YEARS",
  FOUR_YEARS = "FOUR_YEARS",
  FIVE_YEARS = "FIVE_YEARS",
  SIX_YEARS = "SIX_YEARS",
  SEVEN_YEARS = "SEVEN_YEARS",
  EIGHT_YEARS = "EIGHT_YEARS",
  NINE_YEARS = "NINE_YEARS",
  TEN_YEARS = "TEN_YEARS",
  ELEVEN_YEARS = "ELEVEN_YEARS",
  TWELVE_YEARS = "TWELVE_YEARS",
  THIRTEEN_YEARS = "THIRTEEN_YEARS",
  FOURTEEN_YEARS = "FOURTEEN_YEARS",
  FIFTEEN_YEARS = "FIFTEEN_YEARS",
  SIXTEEN_YEARS = "SIXTEEN_YEARS",
  SEVENTEEN_YEARS = "SEVENTEEN_YEARS",
  EIGHTEEN_YEARS = "EIGHTEEN_YEARS",
  NINETEEN_YEARS = "NINETEEN_YEARS",
  TWENTY_YEARS = "TWENTY_YEARS",
}

export enum PaymentFrequencyEnum {
  MONTHLY = "MONTHLY",
  ANNUALLY = "ANNUALLY",
}

export enum ContractTypeEnum {
  CLT = "CLT",
  PJ = "PJ",
}

export enum ContractTypeMotivationEnum {
  CLT = "CLT",
  PJ = "PJ",
  PARTNER = "PARTNER",
  STATUTORY = "STATUTORY",
}

export enum RoleEnum {
  PARTNER = "PARTNER",
  RECRUITER = "RECRUITER",
}

export enum StatusEnum {
  DRAFT = "DRAFT",
  ACTIVE = "ACTIVE",
  CONCLUDED = "CONCLUDED",
  SUSPENDED = "SUSPENDED",
  CANCELLED = "CANCELLED",
}

export enum WorkModelEnum {
  REMOTE = "REMOTE",
  ONSITE = "ONSITE",
  HYBRID = "HYBRID",
}

export const DegreeLevelEnumSchema = z.nativeEnum(DegreeLevelEnum);
export const YearsOfServiceEnumSchema = z.nativeEnum(YearsOfServiceEnum);
export const PaymentFrequencyEnumSchema = z.nativeEnum(PaymentFrequencyEnum);
export const ContractTypeEnumSchema = z.nativeEnum(ContractTypeEnum);
export const ContractTypeMotivationEnumSchema = z.nativeEnum(ContractTypeMotivationEnum);
export const RoleEnumSchema = z.nativeEnum(RoleEnum);
export const StatusEnumSchema = z.nativeEnum(StatusEnum);
export const WorkModelSchema = z.nativeEnum(WorkModelEnum);
