<script lang="ts">
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "PageWrapper",
  props: {
    contentColumns: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const gridColumns = computed(() => {
      if (props.contentColumns)
        return `--columns: ${props.contentColumns}`;

      return "";
    });

    return { gridColumns };
  },
});
</script>

<template>
  <section class="main-wrapper-box page-wrapper">
    <div class="main-wrapper-content">
      <slot name="header" />

      <div class="content" :style="gridColumns">
        <slot name="content" />
      </div>
      <slot name="footer" />
    </div>
  </section>
</template>

<style lang="scss" scoped>
.page-wrapper {
  height: 100%;
  overflow-y: auto;
  padding-inline: 1rem;
  margin-block-start: 6.875rem;

  .content {
    max-width: 1200px;
    --columns: 1fr;

    display: grid;
    grid-template-columns: var(--columns);
  }
}

@media screen and (min-width: 1024px) {
  .page-wrapper {
    .content {
      --columns: minmax(370px, 1fr) 3fr;

      grid-gap: 2rem;
    }
  }
}

:slotted(.footer-actions) {
  display: flex;
  width: 70%;
  justify-content: space-between;
  margin-block-start: 2rem;
  margin-inline: auto 0;

  .dropzone {
    width: 22.125rem;
    height: 5.5rem;
    margin-inline-start: 2.7437rem;
  }

  .imua-button.imua-button--primary {
    max-height: 2.5rem;
  }
}
</style>
