import { z } from "zod";
import { RelatedPersonSchema } from "../common/schemas";
import { BasePersonSchema } from "../person/person";

export const MotivationCareer = z.object({
  career_path: z.string().nullish(),
  description: z.string().nullish(),
}).strict();

export const CompanyAttraction = z.object({
  reason_to_leave: z.string().nullish(),
  description: z.string().nullish(),
}).strict();

export const Salary = z.object({
  currency: z.string().nullish(),
  amount: z.number().nullish(),
  contract_type: z.string().nullish(),
}).strict();

export const annualIncome = z.object({
  currency: z.string().nullish(),
  amount: z.number().nullish(),
}).strict();

export const Earnings = z.object({
  salary: Salary.nullish(),
  bonus: z.string().nullish(),
  annual_income: annualIncome.nullish(),
}).strict();

export const Environment = z.object({
  change_job: z.string().nullish(),
  description: z.string().nullish(),
}).strict();

export const MotivationGuidelineCareer = z.object({
  discuss_career: z.string().nullish(),
  dismissal_simulation: z.string().nullish(),
  explore_change: z.string().nullish(),
  explore_promotion: z.string().nullish(),
}).strict();

export const EarningsClass = z.object({
  description: z.string().nullish(),
}).strict();

export const Family = z.object({
  discuss_with_family: z.string().nullish(),
}).strict();

export const Personal = z.object({
  performance_evaluation: z.string().nullish(),
  emotional_bonds: z.string().nullish(),
}).strict();

export const MotivationGuideline = z.object({
  earnings: EarningsClass.nullish(),
  career: MotivationGuidelineCareer.nullish(),
  family: Family.nullish(),
  personal: Personal.nullish(),
}).strict();

export const QuestionsToCandidateSchema = z.object({
  title: z.string().nullish(),
  tooltip: z.string().nullish(),
  answer: z.string().nullish(),
  question_type: z.string().nullish(),
}).strict();

export const RecruiterGuidelinesSchema = z.object({
  introduction: z.string().nullish(),
  screenings_information: z.string().nullish(),
  structure_questions: z.string().nullish(),
  construction_realizations: z.string().nullish(),
}).strict();

export const InterviewMotivationSchema = z.object({
  environment: Environment.nullish(),
  career: MotivationCareer.nullish(),
  personal: EarningsClass.nullish(),
  earnings: Earnings.nullish(),
  information: z.string().nullish(),
  leave_company: CompanyAttraction.nullish(),
  stay_in_company: CompanyAttraction.nullish(),
  company_attraction: CompanyAttraction.nullish(),
  // motivation_guideline: MotivationGuideline.nullish(),
}).strict();

export const WhoWasInvolved = z.object({
  person_id: z.string().nullish(),
  name: z.string().nullish(),
}).strict();

export const InterviewCompanyProjects = z.object({
  name: z.string().nullish(),
  goal: z.string().nullish(),
  diagnosis: z.string().nullish(),
  decision_making_model: z.string().nullish(),
  environment_preference: z.string().nullish(),
  involved_protagonism: z.string().nullish(),
  implementation_leadership: z.string().nullish(),
  governance_and_exposition: z.string().nullish(),
  how_was_made: z.string().nullish(),
  resistance: z.string().nullish(),
  deal_resistance: z.string().nullish(),
  change_negative_behavior: z.string().nullish(),
  results: z.string().nullish(),
  who_was_involved: z.array(WhoWasInvolved).nullish(),
}).strict();

export const InterviewCompanySchema = z.object({
  name: z.string().nullish(),
  projects: z.array(InterviewCompanyProjects).nullish(),
}).strict();

export const CandidateRealizationsSchema = z.object({
  candidate_understanding: z.string().nullish(),
  realizations_place: z.string().nullish(),
  companies: z.array(InterviewCompanySchema).nullish(),
}).strict();

export const InterviewSchema = z.object({
  candidate_realizations: CandidateRealizationsSchema.nullish(),
  recruiter_guidelines: RecruiterGuidelinesSchema.nullish(),
  questions_to_candidate: z.array(QuestionsToCandidateSchema).nullish(),
  motivation: InterviewMotivationSchema.nullish(),
}).strict().nullish();

export const AnnotationsSchema = z.object({
  header: z.string().nullish(),
  current_moment: z.string().nullish(),
  activities_scope: z.string().nullish(),
  background: z.string().nullish(),
  experience: z.string().nullish(),
}).strict();

export const EvaluationOrganizationAndAssessment = z.object({
  score: z.number().nullish(),
  level: z.string().nullish().optional(),
  note: z.string().nullish(),
});

export const RealizationsOrganizationAndAssessment = z.object({
  id: z.string().nullish(),
  evaluation: EvaluationOrganizationAndAssessment.nullish(),
});

export const AtributesOrganizationAndAssessment = z.object({
  attribute: z.string().nullish(),
  evaluation: EvaluationOrganizationAndAssessment.nullish(),
});

export const CulturalMatchOrganizationAndAssessment = z.object({
  title: z.string().nullish(),
  evaluation: EvaluationOrganizationAndAssessment.nullish(),
});

export const ScoreAndNoteMotivation = z.object({
  score: z.string().nullish(),
  note: z.string().nullish(),
  attraction: z.string().nullish(),
  retention: z.string().nullish(),
  repulsion: z.string().nullish(),
});

export const consultantOpinionMotivation = z.object({
  consultant_opinion: z.string().nullish(),
});

export const MotivationOrganizationAndAssessment = z.object({
  environment: ScoreAndNoteMotivation.nullish(),
  career: ScoreAndNoteMotivation.nullish(),
  personal: ScoreAndNoteMotivation.nullish(),
  package: ScoreAndNoteMotivation.nullish(),
  leave_company: consultantOpinionMotivation.nullish(),
  stay_in_company: consultantOpinionMotivation.nullish(),
  company_attraction: consultantOpinionMotivation.nullish(),
});

export const OrganizationAndAssessment = z.object({
  realizations: z.array(RealizationsOrganizationAndAssessment).nullish(),
  attributes: AtributesOrganizationAndAssessment.nullish(),
  cultural_match: CulturalMatchOrganizationAndAssessment.nullish(),
  motivation: MotivationOrganizationAndAssessment.nullish(),
}).nullish();

export const InterviewActivitiesSchema = z.object({
  id: z.string().nullish(),
  activity_date: z.string().nullish(),
  annotations: AnnotationsSchema.nullish(),
  is_process: z.boolean(),
  description: z.string().nullish(),
  recruiter: RelatedPersonSchema.nullable(),
  candidate: RelatedPersonSchema.nullable(),
  guide: InterviewSchema,
  organization_and_assessment: OrganizationAndAssessment,
}).strict();

export const EmailAddresses = z.object({
  email_address: z.string().nullish(),
  is_main: z.string().nullish(),
}).strict();

export const SalaryExperience = z.object({
  currency: z.string().nullish(),
  amount: z.number().nullish(),
  contract_type: z.string().nullish(),
  payment_frequency: z.string().nullish(),
}).strict();

export const RealizationsExperience = z.object({
  title: z.string().nullish(),
  description: z.string().nullish(),
}).strict();

export const CompanyExperience = z.object({
  corporation_id: z.string().nullish(),
  name: z.string().nullish(),
}).strict();

export const WorkExperience = z.object({
  role_name: z.string().nullish(),
  start_date: z.string().nullish(),
  is_current: z.boolean(),
  end_date: z.string().nullish(),
  description: z.string().nullish(),
  job_location: z.string().nullish(),
  salary: SalaryExperience.nullish(),
  incentive_compensation: z.string().nullish(),
  conditions: z.string().nullish(),
  realizations: z.array(RealizationsExperience).nullish(),
  company: CompanyExperience.nullish(),
}).strict();

export const RelatedRecruiters = z.object({
  person_id: z.string().nullish(),
  person_data: BasePersonSchema.nullish(),
});

export const PersonData = z.object({
  _id: z.string().nullish(),
  first_name: z.string().nullish(),
  last_name: z.string().nullish(),
  email_addresses: z.array(EmailAddresses).nullish(),
  avatar_url: z.string().nullish(),
  linkedin_url: z.string().nullish(),
  work_experience: z.array(WorkExperience).nullish(),
  related_recruiters: RelatedRecruiters.nullish(),
}).strict();

export const CandidatesSchema = z.object({
  person_id: z.string().nullish(),
  questions: z.array(QuestionsToCandidateSchema).nullish(),
  person_data: PersonData.nullish(),
}).strict();

export const RankedCandidatesSchema = z.object({
  person_id: z.string().nullish(),
  educational_background_score: z.number().nullish(),
  experience_score: z.number().nullish(),
  motivation_score: z.number().nullish(),
  realizations_score: z.number().nullish(),
  attributes_score: z.number().nullish(),
  cultural_match_score: z.number().nullish(),
  final_score: z.number().nullish(),
  person_data: PersonData.nullish(),
}).strict();

export const InterviewBaseSchema = z.object({
  status: z.string().nullish(),
  count: z.number(),
  saved_candidates: z.array(z.string()).nullish(),
  activities: z.array(InterviewActivitiesSchema).nullish(),
  candidates: z.array(CandidatesSchema).nullish(),
  guide: InterviewSchema.nullish(),
  ranked_candidates: z.array(RankedCandidatesSchema).nullish(),
}).strict();
