<script lang="ts">
import { useI18n } from "vue-i18n";
import TelephoneInput from "@/components/inputs/modalInputs/TelephoneInput.vue";

export default defineComponent({
  components: {
    TelephoneInput,
  },
  setup() {
    const { t } = useI18n();

    const defaultPhoneCode = "+ 55";

    const emptyTelephone = {
      calling_code: defaultPhoneCode,
      number: "",
    };

    const telephonePrimary = ref({
      ...emptyTelephone,
    });
    const telephoneSecondary = ref({
      ...emptyTelephone,
    });

    const clear = () => {
      telephonePrimary.value = { ...emptyTelephone };
      telephoneSecondary.value = { ...emptyTelephone };
    };

    const content = computed(() => [
      telephonePrimary.value,
      telephoneSecondary.value,
    ]);

    return {
      t,
      content,
      telephonePrimary,
      telephoneSecondary,
      defaultPhoneCode,
      clear,
    };
  },
});
</script>

<template>
  <div class="form-group phone">
    <label>{{ t("persons.createPersonForm.phone") }} :</label>
    <div class="phone-inputs">
      <TelephoneInput
        v-model:countryCode="telephonePrimary.calling_code"
        v-model:phoneNumber="telephonePrimary.number"
        data-test="telephone-primary"
        :default-code="defaultPhoneCode"
      />
      <TelephoneInput
        v-model:countryCode="telephoneSecondary.calling_code"
        v-model:phoneNumber="telephoneSecondary.number"
        data-test="telephone-secondary"
        :default-code="defaultPhoneCode"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.phone-inputs {
  flex-grow: 1;
}

.telephone-input-wrapper:deep {
  flex-grow: 1;
  justify-content: space-between;
  margin-bottom: 1rem;

  .tel-select {
    margin-right: 10px;
  }

  .component-input {
    flex-grow: 1;

    .input-wrapper {
      margin-left: 0;
    }
  }

  .error-wrapper {
    position: relative;
    justify-content: start;

    span {
      padding-top: 2px;
      margin-left: 5px;
    }
  }
}
</style>
