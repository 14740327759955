<script setup lang="ts">
import { useI18n } from "vue-i18n";
import SliderLevelView from "./SliderLevelView.vue";
import { SvgIcon } from "@/components/icon";

const props = withDefaults(
  defineProps<{
    editing?: boolean
    construction?: any
    activitie?: any
    showLegend?: boolean
    showScore?: boolean
    controlColor?: string
    controlMarkerColor?: string
    selectedColor?: string
    selectedMarkerColor?: string
    showSelectedMarker?: boolean
    showControlMarker?: boolean
    culturalMatch: any
  }>(),
  {
    editing: false,
    showLegend: true,
    showScore: true,
    showSelectedMarker: true,
    showControlMarker: true,
  },
);
const { t } = useI18n();
function checkActive(value: any) {
  return !value || value === "EMPTY" ? "" : "active";
}
const sliderDefaults = {
  showLegend: false,
  showScore: false,
  showControlMarker: true,
  background: "white-background",
  selectedColor: "yellow",
  selectedMarkerColor: "yellow",
  controlColor: "yellow-slider",
  controlMarkerColor: "gray-shade-dark",
  showSelectedMarker: false,
};

const choices = computed(() => ([
  "LOW",
  "AVERAGE",
  "HIGH",
  "VERY_HIGH",
]
  .map(data => ({
    label: t(`projects.generic.scale.enum.${data}`).toUpperCase(),
    value: data,
  }))
));

enum CULTURAL_MATCH_TITLES {
  EMPTY = "EMPTY",
  PRACTICAL = "PRACTICAL",
  LOGICAL = "LOGICAL",
  REFLEXIVE = "REFLEXIVE",
  AFFECTIVE = "AFFECTIVE",
}

const icons = {
  [CULTURAL_MATCH_TITLES.AFFECTIVE]: "heart_icon",
  [CULTURAL_MATCH_TITLES.LOGICAL]: "brain_icon",
  [CULTURAL_MATCH_TITLES.PRACTICAL]: "gears_icon",
  [CULTURAL_MATCH_TITLES.REFLEXIVE]: "feather_icon",
};

const payload = computed(() => Object.keys(props.culturalMatch || []).map(attr => ({
  key: attr.toUpperCase(),
  title: t(`cultural_match.${attr.toUpperCase()}`),
  tooltip: attr.toUpperCase(),
  answer: props.culturalMatch[attr].level,
  controlValue: props.construction ? props.construction[attr] : props.culturalMatch[attr],
  score: props.culturalMatch[attr].score,
})));

const titles = computed(() => ({
  control: t("projects.generic.idealProfile"),
  candidate: t("projects.screening.labels.candidate"),
}));
</script>

<template>
  <div class="attributes-component">
    <div v-for="(item, index) in payload" :key="index" class="attributes-content">
      <div class="attributes-data">
        <span class="attributes-data-icon" :class="[checkActive(item.answer || item.controlValue)]">
          <SvgIcon :icon="icons[item.key as never]" margin="none" />
        </span>
        <span class="attributes-data-label">
          {{ item.title }}
        </span>
      </div>
      <div class="item-evaluate">
        <div class="item-slider">
          <SliderLevelView
            :options="choices"
            :selected-value="item.answer"
            :description="t(`projects.shortlist.enums.cultural.${item.key}`)"
            :show-labels="index === 0"
            :show-selected-marker="sliderDefaults.showSelectedMarker"
            :show-control-marker="sliderDefaults.showControlMarker"
            :control-value="item.controlValue"
            :control-color="sliderDefaults.controlColor"
            :control-marker-color="sliderDefaults.controlMarkerColor"
            :control-title="titles.control"
            :selected-color="sliderDefaults.selectedColor"
            :selected-marker-color="sliderDefaults.selectedMarkerColor"
            :selected-title="titles.candidate"
          />
        </div>
        <div v-if="showScore" class="item-score">
          <div v-if="item.score" class="score">
            {{ item.score }}%
          </div>
        </div>
      </div>
    </div>
    <div class="attributes-content">
      <div v-if="showLegend" class="attributes-legends">
        <div class="attributes-item">
          <div class="marker marker-a" :class="[`--bg-${sliderDefaults.controlMarkerColor}`]" />
          <div class="marker-title">
            {{ titles.control }}
          </div>
        </div>
        <div class="attributes-item">
          <div class="marker marker-b" :class="[`--bg-${sliderDefaults.selectedMarkerColor}`]" />
          <div class="marker-title">
            {{ titles.candidate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.attributes-component {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1.5rem;
  padding-block: 2.5rem;
  padding-inline: 0.5rem 0;

  .attributes-content {
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr 4fr;

    .item-radios {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0 1rem 0 2rem;
    }
  }

  .attributes-data {
    display: flex;
    min-width: 13rem;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    .attributes-data-icon {
      display: flex;
      width: 55px;
      min-width: 55px;
      height: 55px;
      min-height: 55px;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      box-shadow: 0 2px 4px #00000029;
      color: $gray-border;
    }

    .attributes-data-icon.active {
      background-color: $white-background;
      color: $yellow;
    }

    .attributes-data-label {
      width: -webkit-fill-available;
      line-height: 1.3;
      text-transform: uppercase;
    }

    .help {
      text-transform: none;

      svg {
        color: $gray-shade-dark;
      }
    }
  }

  .attributes-legends {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 15.2rem;
    margin-top: 2rem;
    gap: 4rem;

    .attributes-item {
      display: flex;
      min-width: 5rem;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;

      .marker {
        width: 7px;
        height: 20px;
        background: $gray-shade-dark;
      }

      .marker.marker-a {
        width: 5px;
        height: 26px;
      }

      .marker.marker-b {
        width: 11px;
        height: 20px;
      }

      .marker-title {
        font-size: 0.75rem;
      }
    }
  }

  .form-actions {
    display: flex;
    margin: 2rem 0;
    gap: 2rem;
    grid-column: 1 / -1;
  }

  .item-evaluate {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    gap: 1.5rem;

    .item-slider {
      width: inherit;
    }

    .item-score {
      display: flex;
      width: 5rem;
      align-items: center;

      .score {
        display: flex;
        min-width: 5rem;
        height: fit-content;
        align-items: center;
        justify-content: center;
        padding: 0.4375rem 1.18rem;
        border: 1px solid $gray-text;
        border-radius: 6px;
        background: $white-full;
        color: $gray-text;
        font-size: 1.25rem;
        font-weight: bold;
      }
    }
  }
}

@mixin colors-change($colors-map) {
  @each $key, $value in $colors-map {
    .attributes-component {
      .attributes-legends {
        .attributes-item {
          .marker.--bg-#{$key} {
            background: $value;
          }
        }
      }
    }
  }
}

@include colors-change($all-colors);
</style>
