import { z } from "zod";
import {
  AddressSchema,
  GenericSchema,
  MonetaryAmountRangeSchema,
  RelatedCorporationSchema,
  RelatedPersonSchema,
  RelatedPersonsListSchema,
  SalaryRangeSchema,
} from "../common/schemas";
import { StatusEnumSchema } from "../enums/misc";
import {
  PlacementTypeEnumSchema,
  PositionClassEnumSchema,
  ProjectTypeEnumSchema,
} from "../enums/project";
import {
  ConstructionActivitySchema,
  ConstructionGuideSchema,
  OrganizationAndAssemblySchema,
} from "./construction";
import {
  ScreeningActivitySchema,
  ScreeningCandidatesSchema,
  ScreeningGuideSchema,
  ScreeningRankedCandidatesSchema,
} from "./screening";
import { InterviewBaseSchema } from "./interview";

import { ShortlistBaseSchema } from "./shortlist";

export const ScheduleSchema = z
  .object({
    start_date: z.string().nullable(),
    end_date: z.string().nullable(),
    checkpoint_date: z.string().nullable(),
    checkpoint_done_date: z.string().nullable(),
  })
  .strict();

export const ProjectSchedulesSchema = z
  .object({
    construction: ScheduleSchema.nullish(),
    mapping: ScheduleSchema.nullish(),
    screenings: ScheduleSchema.nullish(),
    interviews: ScheduleSchema.nullish(),
    shortlist: ScheduleSchema.nullish(),
    under_offer: ScheduleSchema.nullish(),
    placement: ScheduleSchema.nullish(),
  })
  .strict();

export const PositionDetailsSchema = z
  .object({
    address: AddressSchema.nullish(),
    position_class: PositionClassEnumSchema.nullish(),
    level: z.string().nullish(),
    salary_range: SalaryRangeSchema.nullish(),
    bonus: z.string().nullish(),
    annual_package: MonetaryAmountRangeSchema.nullish(),
    about: z.string().nullish(),
  })
  .strict();

export const StageSchema = z
  .object({
    status: StatusEnumSchema,
    count: z.number(),
  })
  .strict();

export const ConstructionStageSchema = StageSchema.extend({
  guide: ConstructionGuideSchema,
  activities: z.array(ConstructionActivitySchema),
  organization_and_assembly: OrganizationAndAssemblySchema,
}).strict();

export const MappingStageSchema = StageSchema.extend({
  favorite_candidates: z.array(z.string()),
  saved_candidates: z.array(z.string()),
}).strict();

export const ScreeningStageSchema = StageSchema.extend({
  guide: ScreeningGuideSchema,
  activities: z.array(ScreeningActivitySchema).nullish(),
  candidates: z.array(ScreeningCandidatesSchema).nullish(),
  ranked_candidates: z.array(ScreeningRankedCandidatesSchema),
  saved_candidates: z.array(z.string()).nullish(),
}).nullable();

export const ProjectStagesSchema = z
  .object({
    construction: ConstructionStageSchema,
    mapping: MappingStageSchema,
    screenings: ScreeningStageSchema.nullable(),
    interviews: InterviewBaseSchema,
    shortlist: ShortlistBaseSchema,
    under_offer: StageSchema,
    placement: StageSchema,
  })
  .strict();

export const ProjectWeightsSchema = z
  .object({
    experiences: z.number(),
    achievements: z.number(),
    attributes: z.number(),
    academic_formation: z.number(),
    cultural_match: z.number(),
    motivation: z.number(),
  })
  .strict();

export const ExternalIdSchema = z
  .object({ entity_id: z.string().nullable(), record_id: z.number().nullable() })
  .strict();

export const ProjectExternalIdsSchema = z
  .object({
    bullhorn_id: ExternalIdSchema.nullable(),
    netsuite_id: ExternalIdSchema.nullable(),
    hubspot_id: ExternalIdSchema.nullable(),
  })
  .strict();

export const ProjectCorporationsDetails = z
  .object({
    description: z.string().nullable(),
    historic: z.string().nullable(),
  })
  .strict();

export const PresentationCounters = z
  .object({
    show_default: z.boolean(),
    mapping: z.string(),
    screenings: z.number(),
    interviews: z.number(),
    shortlist: z.number(),
    finalists: z.number(),
  })
  .strict();

export const PresentationDescriptions = z
  .object({
    show_default: z.boolean(),
    mapping: z.string(),
    screenings: z.string(),
    interviews: z.string(),
    shortlist: z.string(),
    finalists: z.string(),
  })
  .strict();

export const ProcessFunnel = z
  .object({
    presentation_counters: PresentationCounters,
    presentation_descriptions: PresentationDescriptions,
  })
  .strict();

export const BaseProjectSchema = z
  .object({
    project_name: z.string(),
    position_name: z.string(),
    process_funnel: ProcessFunnel,
    project_type: ProjectTypeEnumSchema,
    position_details: PositionDetailsSchema.nullish(),
    placement_type: PlacementTypeEnumSchema.nullish(),
    confidential_placement: z.boolean(),
    schedules: ProjectSchedulesSchema.nullish(),
    status: StatusEnumSchema.optional(),
    corporation: RelatedCorporationSchema.nullish(),
    published_by: RelatedPersonSchema,
    participants: RelatedPersonsListSchema,
    stages: ProjectStagesSchema.nullish(),
    ranking_weights: ProjectWeightsSchema,
    external_ids: ProjectExternalIdsSchema.nullable(),
    corporations_details: ProjectCorporationsDetails.nullable(),
  })
  .strict();

export const ProjectSchema = BaseProjectSchema.merge(GenericSchema).strict();
