import type { PaginationParams, SuccessPaged } from "../types";
import { entitiesService } from "../client";
import type { SearchParams } from "@/entities/project";
import type { Person } from "@/schemas/person";
import { i18n } from "@/locales/i18n";
import { parseParams } from "@/utils/parsers";

export async function searchBusinessFunctions(params: PaginationParams & SearchParams = {}): Promise<{ data: string[] }> {
  const parsedParams = parseParams(params);
  const { data: businessFunctions } = await entitiesService.get("/search/business_functions", {
    params: { ...parsedParams },
  });

  return { data: businessFunctions };
}

export async function searchCandidates(params: PaginationParams & { [key: string]: string | number | string[] } = {}, project_id?: string, stage?: string): Promise<SuccessPaged<Person[]>> {
  const parsedParams = parseParams(params);
  const { data } = await entitiesService.get(`/search/candidates/${stage}`, {
    params: { ...parsedParams, ...{ project_id } },
  });

  return data;
}

export async function searchCandidatesMapping(params: PaginationParams & { [key: string]: string | number | string[] } = {}, project_id?: string): Promise<SuccessPaged<Person[]>> {
  const parsedParams = parseParams(params);
  const { data } = await entitiesService.get("/search/candidates/mapping-using-ms-candidates-data", {
    params: { ...parsedParams, ...{ project_id } },
  });

  return data;
}
export async function candidatesSort(project_id?: string, sort?: string[], params: PaginationParams & { [key: string]: string | number | string[] } = {}): Promise<SuccessPaged<Person[]>> {
  const parsedParams = parseParams(params);
  const { data } = await entitiesService
    .get(`/search/candidates/screenings?sort_by=${sort}&project_id=${project_id}`, {
      params: { ...parsedParams },
    });
  return data;
}

export async function searchMarket(params: PaginationParams & SearchParams = {}): Promise<{ data: string[] }> {
  const parsedParams = parseParams(params);
  const { locale } = i18n.global;
  const { data } = await entitiesService.get("/search/market", {
    params: { ...parsedParams, language: locale.value },
  });

  return { data };
}
