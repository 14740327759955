<script setup lang="ts">
import * as slider from "@zag-js/slider";
import { useMachine } from "@zag-js/vue";
import bson from "bson-objectid";

const props = defineProps<{
  modelValue: number | undefined | null
  isInInterview?: boolean
}>();
const emit = defineEmits(["update:modelValue"]);

const [state, send] = useMachine(slider.machine({
  id: bson().toHexString(),
  min: 0,
  max: 100,
  step: 5,
  value: props.modelValue ? props.modelValue : 0,
  onChange,
}));

const currentValue = ref(props.modelValue);
function onChange(v: { value: number }) {
  currentValue.value = v.value;
}
const isEmpty = computed(() => props.isInInterview && (props.modelValue === 0 || !props.modelValue));
</script>

<template>
  <div :class="isEmpty ? 'warning-red' : ''">
    <SliderEvaluation :model-value="modelValue" @update:model-value="emit('update:modelValue', $event)" />
  </div>
  <div class="margin-warning">
    <slot v-bind="{ isEmpty }" name="warning" />
  </div>
</template>

<style scoped lang="scss">
.warning-red {
  border: 1px solid $red-warning;
  border-radius: 6px;
  padding-inline: 2px;
}

.margin-warning {
  margin-block: 0.625rem 1.875rem;
}
:slotted(.warning) {
  color: $red-warning;
  font-size: 0.875rem;
  line-height: 1.0625rem;
}
</style>
