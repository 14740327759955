<script lang="ts">
import { useI18n } from "vue-i18n";
import type { Ref } from "vue";
import { getOr } from "lodash/fp";
import type { Person } from "@/schemas/person";
import { Avatar } from "@/components/avatar";
import { formatMonthAndYear } from "@/utils/dates";
import SchoolIcon from "@/assets/images/school.svg";

export default defineComponent({
  name: "AcademicFormation",
  components: {
    Avatar,
    SchoolIcon,
  },
  setup() {
    const { t } = useI18n();
    const person = (inject("selectedPerson") || inject("person")) as Ref<Person>;

    const educations = getOr([], "education", person.value);

    educations.sort((a, b) => {
      if (a.end_date && b.end_date)
        return a.end_date >= b.end_date ? -1 : 1;

      if (a.end_date)
        return -1;
      if (b.end_date)
        return 1;

      if (a.start_date && b.start_date)
        return a.start_date >= b.start_date ? -1 : 1;

      if (a.start_date)
        return -1;
      if (b.start_date)
        return 1;

      return 0;
    });

    return {
      educations,
      t,
      formatDate: formatMonthAndYear,
    };
  },
});
</script>

<template>
  <div class="group-wrapper">
    <div
      v-for="(education, index) of educations"
      :key="`education-${index}`"
      class="course-wrapper"
    >
      <div class="avatar-icon">
        <Avatar
          v-if="education?.institution?.corporation_data"
          :src="education.institution.corporation_data.avatar_url"
          :alt="education.institution.corporation_data.avatar_url"
          size="71px"
        />
        <SchoolIcon v-else class="icon" />
      </div>
      <div class="content">
        <p>
          <span v-if="education.degree_level">
            {{ t(`persons.forms.academic.degree.${education.degree_level}`) }}
            {{ t("persons.details.in") }}
          </span>
          {{ education.course_title }}
        </p>
        <p>
          {{ education.institution.corporation_data?.name || education.institution.name }}
        </p>
        <p v-if="education.start_date && education.end_date">
          {{ formatDate(education.start_date) }}
          -
          {{ formatDate(education.end_date) }}
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped="true" lang="scss">
.group-wrapper {
  margin-bottom: 1rem;
}

.course-wrapper {
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-top: 30px;
  }

  .avatar-icon {
    display: flex;
    width: 71px;
    height: 71px;
    align-items: center;
    justify-content: center;
    border-radius: 150px;
    background: #cecfd0;
  }

  .content {
    position: relative;
    flex: 1;
    margin-left: 20px;

    &::after {
      position: absolute;
      bottom: -20px;
      width: 100%;
      height: 1px;
      background: #e9e5e5;
      content: " ";
    }

    p {
      margin-top: 5px;
      color: $gray-dark;
      font-size: 14px;
      font-weight: bold;
    }
  }
}
</style>
