import { ATTRIBUTES_TITLES } from "@/entities/project/construction-guide";

export const attributes = {
  [ATTRIBUTES_TITLES.COGNITIVE_INTELLIGENCE]: "Cognitive Intelligence",
  [ATTRIBUTES_TITLES.EMOTIONAL_INTELLIGENCE]: "Emotional Intelligence",
  [ATTRIBUTES_TITLES.SPIRITUAL_INTELLIGENCE]: "Spiritual Intelligence",
  [ATTRIBUTES_TITLES.ENERGY]: "Energy",
  [ATTRIBUTES_TITLES.MATURITY]: "Maturity",
  [ATTRIBUTES_TITLES.POWER]: "Power",
};
