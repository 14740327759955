import { BaseError } from "./base";

export class AuthenticationError<T> extends BaseError {
  public constructor(
    public readonly detail: T,
    public readonly msg: string,
    public readonly status: 400,
  ) {
    super(msg, status);
    this.detail = detail;
  }
}
