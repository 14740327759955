<script setup lang="ts">
import { computed, defineEmits, defineProps, ref, withDefaults } from "vue";
import { changeImageSize } from "@/utils/images/resize-images";
import type { ImageSizesType } from "@/schemas/enums/images";

import { Card } from "@/components/card";
import { Tooltip } from "@/components/tooltip";
import { Avatar } from "@/components/avatar";

export interface IList {
  id: string
  alt: string
  src: string
}

interface Props {
  list?: Array<IList>
  counter?: boolean
  markRecruiter?: boolean
  maxCounter?: number
  avatarSize?: string
  counterSize?: string
  imageSize?: ImageSizesType
}

const props = withDefaults(defineProps<Props>(), {
  counter: true,
  markRecruiter: false,
  maxCounter: 3,
  avatarSize: "3.75rem",
  counterSize: "3.75rem",
  imageSize: "mini",
});

const emit = defineEmits(["click:avatar"]);

const cardRef = ref<HTMLDivElement | null>(null);

const showExtra = ref<boolean>(false);

const showCounter = computed<boolean>(
  () => props.counter && props.list?.length > props.maxCounter,
);

const counterAvatars = computed<number>(() => props.list?.length - props.maxCounter);

const computedList = computed<IList[]>(() => {
  return props?.list?.slice(0, props?.maxCounter);
});

const extraAvatars = computed(() => {
  if (showCounter.value)
    return props.list.slice(props.maxCounter, props.list?.length);

  return [];
});

function focusExtra() {
  showExtra.value = true;
}

function blurExtra(event: MouseEvent) {
  if (cardRef.value && event.relatedTarget === cardRef.value.$el)
    cardRef.value.focus();
  else
    showExtra.value = false;
}
</script>

<template>
  <div class="avatar-list__container">
    <Tooltip
      v-for="(item, index) in computedList"
      :key="`tooltip-${item.id}`"
      :tooltip-title="item.alt"
      x-align="center"
      tooltip-width="max-content"
      @click="emit('click:avatar', item.id)"
    >
      <Avatar
        class="tooltip-list__avatar"
        :class="[
          { 'tooltip-list__avatar--active': !index && markRecruiter },
        ]"
        :src="changeImageSize(item.src, imageSize)"
        image-size="mini"
        placeholder="project_ideal_profile"
        type="native"
        :size="avatarSize"
      />
    </Tooltip>
    <span
      v-if="showCounter"
      class="avatar-list__counter"
      :style="{ width: counterSize, minWidth: counterSize, height: counterSize, lineHeight: counterSize }"
      role="button"
      tabindex="0"
      @mouseover="focusExtra"
      @mouseout="blurExtra($event)"
    >
      +{{ counterAvatars }}
    </span>
    <Card
      v-show="showExtra"
      ref="cardRef"
      tabindex="0"
      class="extra__list--card-container"
      @mouseover="showExtra = true"
      @mouseout="showExtra = false"
    >
      <div
        v-for="extra in extraAvatars"
        :key="extra.id"
        class="extra__list--card"
        role="button"
        @click="emit('click:avatar', extra.id)"
      >
        <Avatar :class="[]" :src="changeImageSize(extra.src, imageSize)" :size="avatarSize" />
        <p>{{ extra.alt }}</p>
      </div>
    </Card>
  </div>
</template>

<style lang="scss" scoped>
.avatar-list {
  &__container {
    position: relative;
    display: inline-flex;
    gap: 0.5rem;
  }

  &__avatar {
    background-color: $primary;
  }

  &__counter {
    display: inline-block;
    border-radius: 50%;
    background-color: $primary;
    color: $white-full;
    font-weight: bold;
    letter-spacing: 0.7px;
    text-align: center;
  }
}

.extra {
  &__list--card-container {
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 35%;
    width: max-content;
    padding: 0.2rem 0.5rem;

    &:focus {
      border: 1px solid $primary;
    }
  }

  &__list--card {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    border-bottom: 1px solid rgba($color: #666, $alpha: 20%);
    margin: 0.5rem 0;
    background-color: $white-full;

    p {
      margin-left: 0.5rem;
      font-weight: 400;
    }

    &:last-child {
      border-bottom: 0;
    }

    &:hover {
      background: rgba($color: $primary, $alpha: 5%);

      p {
        color: $primary;
      }
    }
  }

  &__list--card-item {
    display: flex;
    align-items: center;
    color: $red;
    font-size: 0.875rem;
    font-weight: bold;
    gap: 1rem;
    letter-spacing: 0.49px;
    margin-block-end: 0.5rem;
  }
}

:deep(.tooltip-list__avatar--active) {
  border: 4px solid $primary;
}
</style>
