export const validator = {
  required: "Campo obrigatório",
  email: "E-mail inválido",
  requiredName: "Insira um nome",
  requiredDomain: "Insira um domínio",
  containsHttp: "A URL deve começar com https://",
  url: "Insira uma URL válida",
  linkedin: "Linkedin URL inválida",
  facebook: "Facebook URL inválida",
  dateGreaterThanToday: "A data deve ser maior que hoje",
  requiredFields: "Complete ao menos um dos campos: telefone, email ou Linkedin",
};
