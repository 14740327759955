export enum SectorsEnum {
  ADM_PUBLIC = "ADM_PUBLIC",
  AGRIBUSINESS = "AGRIBUSINESS",
  CONSTRUCTION = "CONSTRUCTION",
  CONSUMER_GOODS = "CONSUMER_GOODS",
  EDUCATION = "EDUCATION",
  FINANCIAL_SERVICES = "FINANCIAL_SERVICES",
  HEALTH_SERVICES = "HEALTH_SERVICES",
  INDUSTRY = "INDUSTRY",
  INSURANCE = "INSURANCE",
  LOGISTICS_TRANSPORT = "LOGISTICS_TRANSPORT",
  MINING_STEEL = "MINING_STEEL",
  OIL_GAS = "OIL_GAS",
  PHARMACEUTICAL = "PHARMACEUTICAL",
  PRIVATE_EQUITY = "PRIVATE_EQUITY",
  RETAIL = "RETAIL",
  SERVICES = "SERVICES",
  TELEMARKETING = "TELEMARKETING",
  UTILITIES = "UTILITIES",
}

export enum IndustriesEnum {
  ADM_PUBLIC = "ADM_PUBLIC",
  ADM_STOCKS_MARKETS = "ADM_STOCKS_MARKETS",
  AGRICULTURE = "AGRICULTURE",
  ANIMAL_HEALTH = "ANIMAL_HEALTH",
  ASSOCIATION_FEDERATION = "ASSOCIATION_FEDERATION",
  AUTOMOTIVE_ASSEMBLY = "AUTOMOTIVE_ASSEMBLY",
  AUTOMOTIVE_DEALERSHIP = "AUTOMOTIVE_DEALERSHIP",
  AUTOMOTIVE_PARTS = "AUTOMOTIVE_PARTS",
  AVIATION_AEROPLANES = "AVIATION_AEROPLANES",
  AVIATION_AEROPORTS = "AVIATION_AEROPORTS",
  AVIATION_SERVICES = "AVIATION_SERVICES",
  BANKING = "BANKING",
  BEAUTY = "BEAUTY",
  BIOTECHNOLOGY = "BIOTECHNOLOGY",
  BROKERAGE = "BROKERAGE",
  BROKERAGE_FUNDS = "BROKERAGE_FUNDS",
  BROKERAGE_REAL_ESTATE = "BROKERAGE_REAL_ESTATE",
  CHARGING = "CHARGING",
  CHEMICALS_SPECIALTIES = "CHEMICALS_SPECIALTIES",
  CHEMICAL_FIBERS = "CHEMICAL_FIBERS",
  CLINIC = "CLINIC",
  CLOTHING = "CLOTHING",
  CONCESSION_MANAGEMENT = "CONCESSION_MANAGEMENT",
  COSMETICS = "COSMETICS",
  CREDIT = "CREDIT",
  DIAGNOSTICS = "DIAGNOSTICS",
  DISTRIBUTOR = "DISTRIBUTOR",
  DISTRIBUTOR_AGRICULTURE = "DISTRIBUTOR_AGRICULTURE",
  DISTRIBUTOR_AUTO_PARTS = "DISTRIBUTOR_AUTO_PARTS",
  DISTRIBUTOR_CAPITAL_GOODS = "DISTRIBUTOR_CAPITAL_GOODS",
  DISTRIBUTOR_FUEL = "DISTRIBUTOR_FUEL",
  DISTRIBUTOR_QUIMICAL_PRODUCTS = "DISTRIBUTOR_QUIMICAL_PRODUCTS",
  DISTRIBUTOR_TECHNOLOGY = "DISTRIBUTOR_TECHNOLOGY",
  DURABLE_GOODS = "DURABLE_GOODS",
  ECOMMERCE = "ECOMMERCE",
  EDUCATION = "EDUCATION",
  ELECTRONICS = "ELECTRONICS",
  ENERGY = "ENERGY",
  ENTERTAINMENT = "ENTERTAINMENT",
  EQUIPMENT = "EQUIPMENT",
  EXCHANGE = "EXCHANGE",
  EXTRACTION = "EXTRACTION",
  FAMILY_OFFICE = "FAMILY_OFFICE",
  FERTILIZERS_DEFENSIVES = "FERTILIZERS_DEFENSIVES",
  FISHING_AQUACULTURE = "FISHING_AQUACULTURE",
  FOOD = "FOOD",
  FOOD_DRINKS = "FOOD_DRINKS",
  FOOD_INGREDIENTS = "FOOD_INGREDIENTS",
  FURNITURE = "FURNITURE",
  FURNITURE_UTENSILS = "FURNITURE_UTENSILS",
  GAS = "GAS",
  HIGHWAYS = "HIGHWAYS",
  HOSPITAL = "HOSPITAL",
  HOSPITAL_SUPPLY = "HOSPITAL_SUPPLY",
  HOTELS_BUSINESS = "HOTELS_BUSINESS",
  IMPORT_EXPORT = "IMPORT_EXPORT",
  INCORPORATION = "INCORPORATION",
  INDUSTRY = "INDUSTRY",
  INFRASTRUCTURE = "INFRASTRUCTURE",
  INSTALLATION_MAINTENANCE = "INSTALLATION_MAINTENANCE",
  INSURANCE = "INSURANCE",
  INVESTMENTS = "INVESTMENTS",
  INVESTMENT_FUNDS_REAL_STATE = "INVESTMENT_FUNDS_REAL_STATE",
  LEASE = "LEASE",
  LIVESTOCK = "LIVESTOCK",
  LOGISTICS = "LOGISTICS",
  LUXURY_ITEMS = "LUXURY_ITEMS",
  MACHINERY = "MACHINERY",
  MACHINERY_EQUIPMENTS = "MACHINERY_EQUIPMENTS",
  MARITIME = "MARITIME",
  MATERIALS_CONSTRUCTION = "MATERIALS_CONSTRUCTION",
  MATERIALS_ELECTRONICS = "MATERIALS_ELECTRONICS",
  MEDIA = "MEDIA",
  MEDICINE = "MEDICINE",
  MINING_STEEL = "MINING_STEEL",
  MOBILITY = "MOBILITY",
  NON_METALLIC = "NON_METALLIC",
  OPTICS = "OPTICS",
  OTHERS = "OTHERS",
  OUTSOURCING = "OUTSOURCING",
  PACKAGING = "PACKAGING",
  PAPER = "PAPER",
  PARTS = "PARTS",
  PAYMENTS = "PAYMENTS",
  PETROCHEMICALS = "PETROCHEMICALS",
  PHARMACEUTICALS = "PHARMACEUTICALS",
  PHARMACOCHEMICAL = "PHARMACOCHEMICAL",
  PLASTICS = "PLASTICS",
  PRINTING = "PRINTING",
  PRIVATE_EQUITY = "PRIVATE_EQUITY",
  PRODUCTION = "PRODUCTION",
  PRODUCTS_HYGIENE = "PRODUCTS_HYGIENE",
  PRODUCTS_MEDICAL = "PRODUCTS_MEDICAL",
  RAILROADS = "RAILROADS",
  RECREATIONAL = "RECREATIONAL",
  REFUSE = "REFUSE",
  REINSURER = "REINSURER",
  RESEARCH = "RESEARCH",
  RESEARCH_CLINIC = "RESEARCH_CLINIC",
  RESIDENTIAL_CORPORATE = "RESIDENTIAL_CORPORATE",
  SANITATION = "SANITATION",
  SERVICES = "SERVICES",
  SERVICES_MANAGEMENT = "SERVICES_MANAGEMENT",
  SERVICES_PROFESSIONAL = "SERVICES_PROFESSIONAL",
  SERVICES_PROVIDERS = "SERVICES_PROVIDERS",
  SERVICES_SYSTEMS = "SERVICES_SYSTEMS",
  SHOPPING_CENTERS = "SHOPPING_CENTERS",
  SOCIAL_ASSISTANCE = "SOCIAL_ASSISTANCE",
  STORAGE = "STORAGE",
  SUGAR_ETHANOL = "SUGAR_ETHANOL",
  TELECOMMUNICATIONS = "TELECOMMUNICATIONS",
  TELEVISION = "TELEVISION",
  THIRD_SECTOR = "THIRD_SECTOR",
  TOBACCO = "TOBACCO",
  TOOLS = "TOOLS",
  TRADING = "TRADING",
  UNSKILLED = "UNSKILLED",
  UTENSILS = "UTENSILS",
  VENTURE_CAPITAL = "VENTURE_CAPITAL",
  VET = "VET",
  WELFARE = "WELFARE",
  WELFARE_FOUNDATION = "WELFARE_FOUNDATION",
}

export const marketRelationship = {
  [SectorsEnum.ADM_PUBLIC]: [
    IndustriesEnum.ADM_PUBLIC,
    IndustriesEnum.ASSOCIATION_FEDERATION,
    IndustriesEnum.THIRD_SECTOR,
  ],
  [SectorsEnum.AGRIBUSINESS]: [
    IndustriesEnum.SUGAR_ETHANOL,
    IndustriesEnum.AGRICULTURE,
    IndustriesEnum.ANIMAL_HEALTH,
    IndustriesEnum.DISTRIBUTOR_AGRICULTURE,
    IndustriesEnum.FERTILIZERS_DEFENSIVES,
    IndustriesEnum.FOOD_INGREDIENTS,
    IndustriesEnum.LIVESTOCK,
    IndustriesEnum.FERTILIZERS_DEFENSIVES,
    IndustriesEnum.TRADING,
  ],
  [SectorsEnum.CONSTRUCTION]: [
    IndustriesEnum.STORAGE,
    IndustriesEnum.CONCESSION_MANAGEMENT,
    IndustriesEnum.BROKERAGE_REAL_ESTATE,
    IndustriesEnum.INVESTMENT_FUNDS_REAL_STATE,
    IndustriesEnum.INCORPORATION,
    IndustriesEnum.INDUSTRY,
    IndustriesEnum.INFRASTRUCTURE,
    IndustriesEnum.SERVICES_PROVIDERS,
    IndustriesEnum.RESIDENTIAL_CORPORATE,
    IndustriesEnum.SERVICES_MANAGEMENT,
  ],
  [SectorsEnum.CONSUMER_GOODS]: [
    IndustriesEnum.FOOD_DRINKS,
    IndustriesEnum.DURABLE_GOODS,
    IndustriesEnum.COSMETICS,
    IndustriesEnum.TOBACCO,
    IndustriesEnum.PRODUCTS_HYGIENE,
    IndustriesEnum.UTENSILS,
    IndustriesEnum.CLOTHING,
    IndustriesEnum.VET,
  ],
  [SectorsEnum.EDUCATION]: [IndustriesEnum.EDUCATION],
  [SectorsEnum.FINANCIAL_SERVICES]: [
    IndustriesEnum.ADM_STOCKS_MARKETS,
    IndustriesEnum.BANKING,
    IndustriesEnum.EXCHANGE,
    IndustriesEnum.CHARGING,
    IndustriesEnum.BROKERAGE_FUNDS,
    IndustriesEnum.CREDIT,
    IndustriesEnum.FAMILY_OFFICE,
    IndustriesEnum.WELFARE_FOUNDATION,
    IndustriesEnum.INVESTMENTS,
    IndustriesEnum.PAYMENTS,
    IndustriesEnum.WELFARE,
  ],
  [SectorsEnum.HEALTH_SERVICES]: [
    IndustriesEnum.SOCIAL_ASSISTANCE,
    IndustriesEnum.CLINIC,
    IndustriesEnum.DIAGNOSTICS,
    IndustriesEnum.EQUIPMENT,
    IndustriesEnum.HOSPITAL,
    IndustriesEnum.SERVICES_PROVIDERS,
  ],
  [SectorsEnum.INDUSTRY]: [
    IndustriesEnum.AUTOMOTIVE_ASSEMBLY,
    IndustriesEnum.AUTOMOTIVE_DEALERSHIP,
    IndustriesEnum.AUTOMOTIVE_PARTS,
    IndustriesEnum.DURABLE_GOODS,
    IndustriesEnum.DISTRIBUTOR_CAPITAL_GOODS,
    IndustriesEnum.DISTRIBUTOR_AUTO_PARTS,
    IndustriesEnum.DISTRIBUTOR_QUIMICAL_PRODUCTS,
    IndustriesEnum.PACKAGING,
    IndustriesEnum.CHEMICALS_SPECIALTIES,
    IndustriesEnum.PHARMACOCHEMICAL,
    IndustriesEnum.TOOLS,
    IndustriesEnum.CHEMICAL_FIBERS,
    IndustriesEnum.MACHINERY_EQUIPMENTS,
    IndustriesEnum.MATERIALS_CONSTRUCTION,
    IndustriesEnum.MATERIALS_ELECTRONICS,
    IndustriesEnum.FURNITURE_UTENSILS,
    IndustriesEnum.NON_METALLIC,
    IndustriesEnum.OPTICS,
    IndustriesEnum.PAPER,
    IndustriesEnum.PETROCHEMICALS,
    IndustriesEnum.PETROCHEMICALS,
  ],
  [SectorsEnum.INSURANCE]: [
    IndustriesEnum.BROKERAGE,
    IndustriesEnum.REINSURER,
    IndustriesEnum.INSURANCE,
  ],
  [SectorsEnum.LOGISTICS_TRANSPORT]: [
    IndustriesEnum.AVIATION_AEROPLANES,
    IndustriesEnum.AVIATION_AEROPORTS,
    IndustriesEnum.AVIATION_SERVICES,
    IndustriesEnum.RAILROADS,
    IndustriesEnum.LOGISTICS,
    IndustriesEnum.MARITIME,
    IndustriesEnum.MOBILITY,
    IndustriesEnum.HIGHWAYS,
  ],
  [SectorsEnum.MINING_STEEL]: [IndustriesEnum.MINING_STEEL],
  [SectorsEnum.OIL_GAS]: [
    IndustriesEnum.DISTRIBUTOR_FUEL,
    IndustriesEnum.EXTRACTION,
    IndustriesEnum.PRODUCTION,
    IndustriesEnum.SERVICES,
  ],
  [SectorsEnum.PHARMACEUTICAL]: [
    IndustriesEnum.BIOTECHNOLOGY,
    IndustriesEnum.DISTRIBUTOR,
    IndustriesEnum.MEDICINE,
    IndustriesEnum.RESEARCH_CLINIC,
    IndustriesEnum.PRODUCTS_MEDICAL,
    IndustriesEnum.HOSPITAL_SUPPLY,
  ],
  [SectorsEnum.PRIVATE_EQUITY]: [IndustriesEnum.PRIVATE_EQUITY, IndustriesEnum.VENTURE_CAPITAL],
  [SectorsEnum.RETAIL]: [
    IndustriesEnum.FOOD_DRINKS,
    IndustriesEnum.LUXURY_ITEMS,
    IndustriesEnum.COSMETICS,
    IndustriesEnum.DISTRIBUTOR,
    IndustriesEnum.ECOMMERCE,
    IndustriesEnum.ELECTRONICS,
    IndustriesEnum.PHARMACEUTICALS,
    IndustriesEnum.MACHINERY,
    IndustriesEnum.MATERIALS_CONSTRUCTION,
    IndustriesEnum.FURNITURE_UTENSILS,
    IndustriesEnum.UNSKILLED,
    IndustriesEnum.RECREATIONAL,
    IndustriesEnum.SHOPPING_CENTERS,
    IndustriesEnum.CLOTHING,
    IndustriesEnum.VET,
  ],
  [SectorsEnum.SERVICES]: [
    IndustriesEnum.FOOD,
    IndustriesEnum.BEAUTY,
    IndustriesEnum.ENTERTAINMENT,
    IndustriesEnum.HOTELS_BUSINESS,
    IndustriesEnum.IMPORT_EXPORT,
    IndustriesEnum.PRINTING,
    IndustriesEnum.INSTALLATION_MAINTENANCE,
    IndustriesEnum.LEASE,
    IndustriesEnum.OTHERS,
    IndustriesEnum.RESEARCH,
    IndustriesEnum.SERVICES_PROFESSIONAL,
    IndustriesEnum.OUTSOURCING,
    IndustriesEnum.VET,
  ],
  [SectorsEnum.TELEMARKETING]: [
    IndustriesEnum.DISTRIBUTOR_TECHNOLOGY,
    IndustriesEnum.MEDIA,
    IndustriesEnum.PARTS,
    IndustriesEnum.SERVICES_PROVIDERS,
    IndustriesEnum.SERVICES_SYSTEMS,
    IndustriesEnum.TELECOMMUNICATIONS,
    IndustriesEnum.TELEVISION,
  ],
  [SectorsEnum.UTILITIES]: [
    IndustriesEnum.SANITATION,
    IndustriesEnum.ENERGY,
    IndustriesEnum.GAS,
    IndustriesEnum.REFUSE,
    IndustriesEnum.SERVICES_PROVIDERS,
  ],
};
