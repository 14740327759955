import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { addConstructionActivities, getConstructionActivity, getProjectActivities, patchConstructionAnnotations } from "@/common/services/construction";
import type { ActivitiesSearchType } from "@/common/services/construction";

export interface Notes {
  executions_conditions: string
  header: string
  ideal_profile: string
  governance: string
  strategy: string
}

export type NotesKeys = keyof Notes;

export function useProjectActivitiesQuery(params: ActivitiesSearchType) {
  return useQuery({
    queryKey: ["projects", "construction_activities"],
    queryFn: () => getProjectActivities(params),
  });
}

export function useProjectActivitiesMutate() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["projects", "construction_activities"],
    mutationFn: (params: ActivitiesSearchType) => getProjectActivities(params),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["projects", "construction_activities"] });
    },
  });
}

export function useConstructionActivityMutate() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["projects", "construction_activities"],
    mutationFn: (id: string) => getConstructionActivity(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["projects", "construction_activity"] });
    },
  });
}

export function useConstructionActivityQuery(id: string) {
  return useQuery({
    queryKey: ["projects", "construction_activity", id],
    queryFn: () => getConstructionActivity(id),
  });
}

export function useConstruction(id: string) {
  return useQuery({
    queryKey: ["projects", "construction_activity", id],
    queryFn: () => getConstructionActivity(id),
  });
}

export function useConstructionNotes(id: string) {
  return useQuery({
    queryKey: ["projects", "construction_activity", id],
    queryFn: () => getConstructionActivity(id),
    select: data => toRaw(data?.annotations) as Notes,
  });
}

export function useCreateConstructionActivitiesQuery() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["projects", "construction_activities"],
    mutationFn: (activity: any) => addConstructionActivities(activity),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["projects", "construction_activities"] });
    },
  });
}

export const isNotesSaving = ref(false);

export function usePatchConstructionAnnotationsMutation() {
  const queryClient = useQueryClient();
  let id = "";
  return useMutation({
    mutationFn: (body: { id: string; annotations: any }) => {
      id = body.id;
      return patchConstructionAnnotations(body.id, body.annotations);
    },
    onMutate: async (newAnnotations) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      isNotesSaving.value = true;
      await queryClient.cancelQueries({ queryKey: ["projects", "construction_activity", id] });

      // Snapshot the previous value
      const previousTodos = queryClient.getQueryData(["projects", "construction_activity", id]);
      // Optimistically update to the new value
      queryClient.setQueryData(["projects", "construction_activity", id], (old: any) => {
        return {
          ...old,
          annotations: {
            ...old?.annotations,
            ...newAnnotations,
          },
        };
      });

      // Return a context object with the snapshotted value
      return { previousTodos };
    },
    onError: () => {
      isNotesSaving.value = false;
    },
    onSettled: async () => {
      isNotesSaving.value = false;
      await queryClient.invalidateQueries({ queryKey: ["projects", "construction_activity", id] });
    },
  });
}
