<script setup lang="ts">
import last from "lodash-es/last";
import max from "lodash-es/max";
import cloneDeep from "lodash-es/cloneDeep";
import ExportPage from "./ExportPage.vue";
import { paginateTextList } from "@/utils/text";
import Resume from "@/common/components/Export/Resume.vue";

export interface textPageArrayType {
  score?: number
  break: boolean
  text: string
}

const props = withDefaults(
  defineProps<{
    isExport: boolean
    bullet?: boolean
    idealProfile: any
    schoolings: any[] | null
    languages: any[] | null
    realizations: any[] | null
    experiences: any[] | null
  }>(),
  {
    isExport: false,
    bullet: true,
    schoolings: null,
    languages: null,
    realizations: null,
    experiences: null,
  },
);

const { t } = useI18n();

interface pageType {
  schooling: textPageArrayType[] | null
  language: textPageArrayType[] | null
  realizations: textPageArrayType[] | null
  experiences: textPageArrayType[] | null
}

const pages = ref<pageType[]>([]);
const emptyValue = {
  schooling: null,
  language: null,
  realizations: null,
  experiences: null,
};

function paginatePages() {
  pages.value = [];
  pages.value[0] = cloneDeep(emptyValue);

  const paginateSchooling = paginateTextList(
    Object.values(props.idealProfile?.academic_backgrounds || {}),
    "14px",
    210,
    30,
  );

  const lastSchooling = last(paginateSchooling);

  const paginateLanguages = paginateTextList(
    Object.values(props.idealProfile?.languages || {}),
    "14px",
    210,
    30,
    lastSchooling?.lines,
  );

  const paginateExperiences = paginateTextList(
    Object.values(props.idealProfile?.experiences || {}),
    "14px",
    325,
    30,
  );

  const paginateRealizations = paginateTextList(
    Object.values(props.idealProfile?.achievements || {})
      .map((item: any) => item.description),
    "14px",
    325,
    30,
  );

  const totalPages
    = max([
      paginateSchooling.length,
      paginateLanguages.length,
      paginateExperiences.length,
      paginateRealizations.length,
    ]) || 0;

  for (let i = 0; i < totalPages; i++) {
    const _schooling = paginateSchooling[i];
    const _languages = paginateLanguages[i];
    const _experiences = paginateExperiences[i];
    const _realizations = paginateRealizations[i];

    if (!pages.value[i])
      pages.value[i] = cloneDeep(emptyValue);

    if (_schooling) {
      pages.value[i].schooling = [];
      pages.value[i].schooling = _schooling.texts;
    }
    if (_languages) {
      pages.value[i].language = [];
      pages.value[i].language = _languages.texts;
    }
    if (_experiences) {
      pages.value[i].experiences = [];
      pages.value[i].experiences = _experiences.texts;
    }
    if (_realizations) {
      pages.value[i].realizations = [];
      pages.value[i].realizations = _realizations.texts;
    }
  }
}

watchEffect(() => {
  paginatePages();
});
</script>

<template>
  <ExportPage
    v-for="(page, index) in pages"
    :key="index"
    :page-index="index"
    :is-export="$props.isExport"
    :page-name="t('exportPDF.summaryConstructionBeta.idealProfile')"
    v-bind="$attrs"
  >
    <div class="pdf-ideal-profile">
      <div class="box schooling">
        <div v-if="(page.schooling || []).length > 0">
          <div class="pdf-title">
            {{ t("exportPDF.labels.schooling") }}
          </div>
          <Resume
            :show-title="false"
            :show-label="false"
            :show-circle="bullet"
            font-size="0.875rem"
            line-height="1.25rem"
            :items="page.schooling?.map((item) => ({ value: item.text, bullet: !item.break }))"
          />
        </div>
        <div v-if="(page.language || []).length > 0" class="language">
          <div class="pdf-title">
            {{ t("exportPDF.labels.language") }}
          </div>
          <Resume
            :show-title="false"
            :show-label="false"
            :show-circle="bullet"
            font-size="0.875rem"
            line-height="1.25rem"
            :items="page.language?.map((item) => ({ value: item.text, bullet: !item.break }))"
          />
        </div>
      </div>
      <div class="box">
        <div v-if="(page.experiences || []).length > 0" class="experiences">
          <div class="pdf-title">
            {{ t("exportPDF.labels.professionalExperience") }}
          </div>
          <Resume
            :show-title="false"
            :show-label="false"
            :show-circle="bullet"
            font-size="0.875rem"
            line-height="1.25rem"
            :items="page.experiences?.map((item) => ({ value: item.text, bullet: !item.break }))"
          />
        </div>
      </div>
      <div class="box">
        <div v-if="(page.realizations || []).length > 0" class="realizations">
          <div class="pdf-title">
            {{ t("exportPDF.labels.realizations") }}
          </div>
          <Resume
            :show-title="false"
            :show-label="false"
            :show-circle="bullet"
            font-size="0.875rem"
            line-height="1.25rem"
            :items="page.realizations?.map((item) => ({ value: item.text, bullet: !item.break }))"
          />
        </div>
      </div>
    </div>
  </ExportPage>
</template>

<style lang="scss" scoped>
.pdf-ideal-profile {
  display: flex;
  flex-direction: row;
  gap: 2rem;

  .box {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 1.75rem;

    .pdf-title {
      margin-bottom: 1rem;
      color: $gray-dark;
      font-size: 1.125rem;
      font-weight: bold;
      line-height: 1.25rem;
      text-transform: uppercase;
    }
  }

  .box.schooling {
    max-width: 14.625rem;
  }
}
</style>
