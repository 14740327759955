<script lang="ts">
import type { Ref } from "vue";
import { defineComponent, ref, toRef, watch } from "vue";
import { useI18n } from "vue-i18n";
import { VueFinalModal } from "./components/VueModal";
import { Button as ButtonView } from "@/components/button";
import CloseIcon from "@/assets/images/square_x_icon.svg";

type ModalVariation = "warning" | undefined;
type OnCloseFn = () => void;

export default defineComponent({
  components: {
    VueFinalModal,
    CloseIcon,
    ButtonView,
  },
  props: {
    title: String,
    message: String,
    buttonText: String,
    variation: String as () => ModalVariation,
    buttonVariation: String,
    onClick: Function,
    onClose: Function,
    secondaryButtonText: String,
    secondaryOnClick: Function,
    secondaryVariation: String,
    attach: String,
    messageAlign: {
      type: String,
      default: "left",
    },
  },
  setup(props) {
    const { t } = useI18n();

    const show = ref(true);

    const onClose = toRef(props, "onClose") as Ref<OnCloseFn>;

    watch(show, (value) => {
      if (!value) {
        if (onClose.value)
          onClose.value();
      }
    });

    return {
      t,
      show,
    };
  },
});
</script>

<template>
  <VueFinalModal
    v-model="show"
    :classes="['modal-container', variation]"
    :attach="attach"
    :content-class="['modal-content', $attrs['wrapper-class'] || '']"
    overlay-class="position-fixed"
  >
    <button class="modal-close" :aria-label="t('text.close')" @click="show = false">
      <CloseIcon />
    </button>

    <div class="modal-header">
      <slot name="modalHeader">
        <slot name="titleIcon" />
        <span class="modal-title" :class="[$attrs['title-class'] || '']">
          {{ title }}
          <slot name="title" />
        </span>
      </slot>
    </div>

    <div class="modal-message" :class="[$attrs['message-class'] || '']">
      <slot name="message" />
      <span v-html="message" />
    </div>

    <div class="buttons" :class="[$attrs['buttons-wrapper-class']]">
      <div class="button-wrapper">
        <ButtonView
          :class="$attrs['confirm-button-class'] || ''"
          :text="buttonText"
          :variation="buttonVariation || variation"
          @click="onClick"
        />
      </div>

      <div v-if="secondaryButtonText" class="button-wrapper">
        <ButtonView
          :class="$attrs['cancel-button-class'] || ''"
          :text="secondaryButtonText"
          :variation="secondaryVariation || variation"
          @click="secondaryOnClick"
        />
      </div>
    </div>
  </VueFinalModal>
</template>

<style lang="scss" scoped>
.position-fixed {
  position: fixed;
}

::v-deep .modal-container {
  display: flex;
  align-items: center;
  justify-content: center;

  &.warning {
    color: $red;

    svg {
      stroke: $red;
    }
  }

  .modal-title {
    line-height: 1.3;
  }

  .modal-content {
    position: relative;
    display: flex;
    width: 500px;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 6px;
    background-color: white;

    .modal-header {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      padding: 10px;
      font-family: "Open Sans";
      font-size: 30px;
      font-weight: 600;
      gap: 0.5rem;
    }

    .modal-message {
      padding-top: 10px;
      padding-bottom: 20px;
      color: $gray;
      text-align: v-bind(messageAlign);
    }

    .modal-close {
      --position: 0.5rem;

      position: absolute;
      top: var(--position);
      right: var(--position);
      border: none;
      background-color: transparent;
      cursor: pointer;

      svg {
        stroke: $gray-low;
      }

      &:hover {
        svg {
          stroke: $primary;
        }
      }
    }
  }

  .buttons {
    display: flex;
  }

  .button-wrapper {
    padding: 10px;

    button {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
}
</style>
