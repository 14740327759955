<script lang="ts">
import type { Ref } from "vue";
import {
  computed,
  defineComponent,
  inject,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import { isNumber } from "lodash";
import moment from "moment";
import { mdiPlusCircleOutline } from "@mdi/js";
import TransformationEventForm from "./Form.vue";
import type { Corporation, TransformationEvent } from "@/entities/corporation";
import { Button2, ButtonIcon } from "@/components/button";
import { SvgIcon } from "@/components/icon";
import { CustomTimeline } from "@/components/timeline";
import { formatDate } from "@/utils/dates";

export default defineComponent({
  name: "TransformationEvents",
  components: {
    Button: Button2,
    ButtonIcon,
    CustomTimeline,
    SvgIcon,
    TransformationEventForm,
  },
  setup() {
    const { t, tm } = useI18n();
    const corporation = inject("corporation") as Ref<Corporation>;
    const submitCorporation = inject("submitCorporation") as
      (payload: Partial<Corporation>) => Promise<{ success: boolean }>;

    const transformationEvents = computed(() => {
      const { transformation_events } = corporation.value;

      if (transformation_events?.length) {
        const events = [...transformation_events];
        events.sort(({ end_date: aEndDate }, { end_date: bEndDate }) => moment(bEndDate).unix() - moment(aEndDate).unix());

        return events;
      }

      return [];
    });

    const transformationEventTypes = computed(
      () => tm("corporations.details.transformationEventType.enum"),
    );

    const hasEventEnded = (end_date: string): boolean =>
      end_date && moment(end_date).valueOf() < Date.now();

    const hasEventStarted = (start_date: string): boolean =>
      moment(start_date).valueOf() < Date.now();

    const formatDateToMonthAndYear = (date: string): string => {
      if (!date)
        return "-";
      return formatDate("MMM. YYYY", date);
    };

    const updateConnectors = (): void => {
      if (window) {
        const connectors = document.getElementsByClassName("event-timeline__connector");

        for (let i = 0, len = connectors.length; i < len; i += 1) {
          const connector = connectors.item(i);
          const event = transformationEvents.value[i];

          if (!hasEventStarted(event.start_date))
            connector.classList.add("--dashed");
          else connector.classList.remove("--dashed");
        }
      }
    };

    watch(transformationEvents, () => {
      updateConnectors();
    });

    const payload = reactive<Partial<TransformationEvent[]>>([
      ...corporation.value.transformation_events,
    ]);

    const editing = ref<number | null>(null);

    const addTransformationEvent = (): void => {
      payload.push({
        start_date: null,
        end_date: null,
        event_type: null,
        action: null,
        goal: null,
      });

      editing.value = -1;
    };

    const removeTransformationEvent = (eventIndex: number, cancelEdit: boolean): void => {
      payload.splice(1, eventIndex);
      if (cancelEdit)
        editing.value = null;
    };

    const lastTransformationEvent = computed<TransformationEvent>(
      () => payload[payload.length - 1],
    );

    const submit = async (
      { transformationEvent, eventIndex }: {
        transformationEvent: TransformationEvent
        eventIndex: number
      },
    ) => {
      payload[eventIndex] = transformationEvent;
      const { success } = await submitCorporation({ transformation_events: payload });
      if (success)
        editing.value = null;
    };

    onMounted(() => {
      updateConnectors();
    });

    return {
      t,
      transformationEvents,
      transformationEventTypes,
      isNumber,
      hasEventEnded,
      hasEventStarted,
      formatDateToMonthAndYear,
      payload,
      editing,
      addTransformationEvent,
      removeTransformationEvent,
      lastTransformationEvent,
      submit,
      addMore: mdiPlusCircleOutline,
    };
  },
});
</script>

<template>
  <div class="tab-container">
    <Button
      v-if="!isNumber(editing)"
      class="add-transformation-event"
      @click="addTransformationEvent()"
    >
      <SvgIcon
        type="mdi"
        margin="right"
        width="16"
        height="16"
        :icon="addMore"
      />
      {{ t("corporations.details.addTransformationEvent") }}
    </Button>

    <TransformationEventForm
      v-if="editing === -1"
      :transformation-event="payload"
      :event-index="payload.length - 1"
      @submit="submit($event)"
      @submit:cancel="removeTransformationEvent(payload.length - 1, true)"
    />

    <CustomTimeline class="event-timeline" :value="transformationEvents">
      <template #marker="{ item: event }">
        <span
          class="event-timeline__marker"
          :class="[
            { '--void': !hasEventEnded(event.end_date) },
          ]"
        />
      </template>

      <template #connector>
        <div class="event-timeline__connector" />
      </template>

      <template #content="{ item: event, index }">
        <div v-if="editing !== index" class="transformation-event">
          <div class="transformation-event__period">
            <span v-if="hasEventStarted(event.start_date)">
              {{ formatDateToMonthAndYear(event.start_date) }} /
              {{ formatDateToMonthAndYear(event.end_date) }}
            </span>

            <span v-else>
              ({{ t("corporations.details.forecast") }})
              <br>{{ formatDateToMonthAndYear(event.start_date) }}
            </span>
          </div>

          <div class="transformation-event__description">
            <h2>{{ transformationEventTypes[event.event_type] }}</h2>
            <p><b>{{ t("corporations.details.action") }}:</b> {{ event.action }}</p>
            <p><b>{{ t("corporations.details.goal") }}:</b> {{ event.goal }}</p>
          </div>

          <ButtonIcon
            class="edit"
            :class="[{ active: editing === index }]"
            @click="editing = index"
          >
            <SvgIcon icon="edit_icon" width="13" height="13" />
          </ButtonIcon>
        </div>

        <TransformationEventForm
          v-else-if="editing === index"
          :transformation-event="event"
          :event-index="index"
          @submit="submit($event)"
          @submit:cancel="editing = null"
        />
      </template>
    </CustomTimeline>

    <p v-if="!transformationEvents.length && !isNumber(editing)">
      {{ t("corporations.details.placeholders.noTransformationEvent") }}
    </p>
  </div>
</template>

<style lang="scss" scoped>
.tab-container {
  position: relative;
  padding-block: 3rem;
}

.edit {
  position: absolute;
  top: -1rem;
  right: 25%;
  color: $gray-medium;

  &:hover,
  &.active {
    color: initial;
  }
}

.add-transformation-event {
  position: absolute;
  top: 0;
  right: 0;

  svg:deep {
    vertical-align: bottom;
  }
}

.transformation-event {
  position: relative;
  display: grid;
  align-items: center;
  padding-bottom: 1.5rem;
  gap: 1rem;
  grid-template-columns: repeat(12, 1fr);

  h2,
  p { line-height: 1.2; }
  h2 { margin-bottom: 0.75rem; }
  p { margin-bottom: 0.5rem; }
  span { line-height: 1.3; }

  &__period {
    grid-column: 1 / 3;
  }

  &__description {
    grid-column: 3 / 9;
  }
}

.event-timeline {
  margin-top: 2rem;

  &__marker {
    display: inline-block;
    width: 12px;
    height: 12px;
    border: 2px solid $white;
    border-radius: 50%;
    background-color: $white;

    &.--void {
      background-color: transparent;
    }
  }

  &__connector {
    display: inline-block;
    height: 100%;
    border-right: 2px solid $white;
    background-color: $white;

    &.--dashed {
      border-style: dashed;
      background-color: transparent;
    }
  }

  .p-timeline-event:not(:last-child) {
    .transformation-event {
      border-bottom: 1px solid $white;
    }
  }
}
</style>
