export const terms = {
  title: "Termos de uso",
  header: {
    content: [
      "No presente Termo de Uso da Plataforma da IMUA, a FLOW EXECUTIVE FINDERS “FLOW\" estabelece os termos e condições para uso da Plataforma IMUA (“PLATAFORMA”) por parte dos usuários que queiram utilizar do serviço, seja ele de forma de consulta ou construção de projeto.",
      "Qualquer USUÁRIO que queira utilizar os SERVIÇOS oferecidos pela PLATAFORMA IMUA deve ler previamente e aceitar as condições estabelecidas no TERMO DE USO, e da POLÍTICA DE PRIVACIDADE, que ficam incorporados ao presente.",
      [
        "Qualquer USUÁRIO que não aceite as condições apresentadas no TERMO DE USO ou na",
        "POLÍTICA DE PRIVACIDADE",
        ", deverá abster-se de utilizar os SERVIÇOS oferecidos pela Plataforma IMUA.",
      ],
    ],
  },
  firstSection: {
    subtitle: "• 1. DESTINAÇÃO E USO DA PLATAFORMA",
    content: [
      "A PLATAFORMA IMUA oferece um serviço de conexão entre EMPREGADOS EM POTENCIAL e o CONSULTOR FLOW para apresentar a este pessoas passíveis de contratação de seu exclusivo interesse, através de um processo de seleção que conta com a realização de rodadas de contratações não vinculativas de convites de entrevistas. A ferramenta atua como uma ferramenta para facilitar a comunicação entre EMPREGADOS EM POTENCIAL (e sua triagem e seleção) e os CONSULTORES.",
      "A utilização da PLATAFORMA deverá ser utilizada pelos CONSULTORES FLOW de forma lícita e somente para os fins constantes no presente TERMO DE USO.",
    ],
  },
  secondSection: {
    subtitle: "• 2. CADASTRO DE USUÁRIO",
    content: [
      [
        "2.1 O USUÁRIO deverá:",
        "a) Ser maior de idade e capaz para exercício dos atos da vida civil.",
        "b) Ser um colaborador FLOW.",
        "c) Garantir a veracidade e atualização das informações fornecidas quando da criação de seu perfil para acesso à PLATAFORMA.",
        "d) Estar ciente que é responsável por todas as atividades que ocorram na sua conta dentro da PLATAFORMA.",
        "e) Concordar em manter como confidenciais todas as informações obtidas a partir da utilização da PLATAFORMA e dos SERVIÇOS da Plataforma IMUA.",
        "f) Garantir que não usará qualquer conteúdo, informações e dados pessoais enviado por EMPREGADOS EM POTENCIAL, de acordo com as leis de privacidade e proteção de dados.",
        "g) Abster-se de divulgar o nome ou a identidade de qualquer EMPREGADO EM POTENCIAL listado para rodada de contratações, para fora do seu departamento de recrutamento ou contratação, devendo garantir que seus colaboradores, prepostos, diretores, sócios etc., atuem de igual forma.",
        "h) Garantir que tomará as medidas físicas, técnicas e administrativas adequadas para proteger o conteúdo que obter com o uso da PLATAFORMA IMUA contra perda, mau uso, acesso não autorizado, divulgação, alteração ou destruição, de acordo com a Legislação Vigente.",
        "i) Notificar os administradores no caso de uso ou suspeita de uso não autorizado da sua conta junto a PLATAFORMA ou quanto aos SERVIÇOS oferecidos pela PLATAFORMA IMUA.",
        "j) De modo a poder utilizar imua.com.br como um CONSULTOR, a pessoa deve criar um cadastro. ",
      ],
      "2.2 A transmissão de informações incompletas e/ou inverídicas será considerada como infração ao TERMO DE USO, e poderá ocasionar na desativação da conta, em prazo hábil, sem prejuízo de outras medidas a serem adotadas.",
      "2.3 É de inteira responsabilidade do CONSULTOR o uso de sua conta, segurança e guarda das senhas de acesso à PLATAFORMA, não se responsabilizando a FLOW por qualquer mau uso, perda ou dano resultante de sua falha em cumprir as obrigações ora estipuladas.",
      "2.4 Na eventualidade de desativação da conta do CONSULTOR por qualquer infração, a FLOW poderá cancelar o fornecimento dos serviços, ficando resguardado o direito de redefinir qualquer projeto já concluído.",
      "2.5 O CONSULTOR concorda que não vai vender, transferir ou ceder o seu direito de usar o SERVIÇO, a qualquer terceiro, ou oferecer o SERVIÇO, ou qualquer conteúdo obtido a partir da PLATAFORMA, em outra base comercial, sem a anuência expressa da FLOW.",
      "2.6 Na eventualidade de práticas contrárias às obrigações constantes no presente TERMO DE USO, a FLOW poderá vetar a criação de novos usuários por pessoas direta ou indiretamente ligadas aos cadastros que tenham sido desativados.",
    ],
  },
  thirdSection: {
    subtitle: "• 3. DA PLATAFORMA IMUA",
    content: [
      "3.1 A plataforma IMUA fornece ao CONSULTOR, a localização, resultados de seleção e ferramentas de software que lhe permitam encontrar e se conectar com os EMPREGADOS EM POTENCIAL.",
      "3.2 Nenhuma obrigação contratual é criada tanto para o Funcionário em Potencial quanto para a CONSULTOR pela utilização do Serviço.",
      "3.3 Quaisquer acordos criados entre o CONTRATANTE e o EMPREGADO EM POTENCIAL não são vinculativos à FLOW. A FLOW não é responsável, ou obrigada a cumprir, quaisquer acordos entre o CONTRATANTE e o EMPREGADO EM POTENCIAL. A CONTRATANTE não considerará a FLOW, nem a GEEKHUNTER deverá ser interpretada como parte de acordos ou operações similares, independentemente de receber remuneração pela prestação do SERVIÇO ou mesmo disponibilizar acesso à PLATAFORMA.",
      "3.4 A Plataforma IMUA possui um sistema de rastreabilidade de EMPREGADOS EM POTENCIAL e CONTRATANTES na plataforma. O sistema da Plataforma IMUA permite gerar um histórico de todas as informações de movimentações na plataforma, tais como data e horário de inclusão de cada candidato nas etapas do projeto; login, cliques e visualização de informações geradas por cada um dos usuários. Tais informações podem ser solicitadas e apresentadas no caso de contestações por parte do CONSULTOR ou pela FLOW. ",
      "3.5 A Plataforma IMUA é responsável por garantir a possibilidade de criação de novos usuários com a finalidade de utilização da mesma conta do CONSULTOR na PLATAFORMA IMUA desde que esses usuários possuam vínculo direto e um e-mail corporativo da FLOW.",
    ],
  },
  fourthSection: {
    subtitle: "•  4. DA GARANTIA AO CONTRATANTE",
    content: {
      1: [
        "NÃO EXCLUSIVIDADE",
        "As PARTES não tem qualquer obrigação de manter exclusividade na relação entre FLOW/CONTRATANTE/EMPREGADO EM POTENCIAL, sendo permitido o cadastro/contratação/prestação de serviço semelhantes, sendo que o cadastro/contratação não gera nenhum vínculo exclusivo entre as PARTES.",
      ],
      2: [
        "CONFIDENCIALIDADE",
        "As PARTES deverão manter sigilo absoluto sobre os dados, materiais, informações, documentos, especificações comerciais e outras informações de propriedade da outra PARTE de que, eventualmente, tenham conhecimento (Informações Confidenciais). As PARTES não publicarão, divulgarão, colocarão à disposição ou farão uso das Informações Confidenciais sem autorização prévia e por escrito, sob pena de dar justa causa à rescisão deste Contrato, respondendo diretamente por eventuais perdas e danos da PARTE prejudicada decorrentes da não observância desta Cláusula.",
        "Não se aplicam as obrigações de confidencialidade contidas nesta cláusula a qualquer informação ou material que as PARTES demonstrem que: (i) já estavam em seu poder antes da divulgação pela outra PARTE; (ii) sejam ou se tornem de domínio público sem culpa, omissão ou outro ato de uma das PARTES; ou (iii) sejam legalmente obtidas de terceiros sem nenhuma obrigação de confidencialidade pelas PARTES.",
      ],
      3: [
        "DIREITO DE PROPRIEDADE INTELECTUAL",
        "A PLATAFORMA IMUA e os SERVIÇOS prestados pela FLOW são de propriedade exclusiva desta, não havendo, através de cadastro ou contratação, qualquer autorização para uso, venda, coparticipação, ou qualquer outro direito em relação a marca, código-fonte, know how etc., sendo vedado aos usuários copiar, modificar, distribuir, vender ou alugar qualquer parte da PLATAFORMA, nem poderá realizar engenharia reversa ou tentar extrair os códigos- fonte desta. Qualquer infração, danificação, descompilação ou extração de código-fonte, ou tentativa de infração, danificação, descompilação ou extração de código-fonte da Plataforma, deverá ser prontamente comunicada à FLOW.",
      ],
      4: [
        "AUTORIZAÇÕES",
        "A FLOW, a PLATAFORMA IMUA e o Contratante comprometem-se a cumprir as determinações da Lei nº 13.709/2018 (Lei Geral de Proteção de Dados - LGPD) e demais normatizações a ela referentes.",
        "As PARTES deverão tratar os dados pessoais com sigilo e unicamente para realização do objeto pactuado e pelo tempo necessário, obrigando-se a devolver ou destruí-los de forma segura. Deverão, ainda, adotar as medidas de segurança, técnicas e administrativas, aptas a proteger os dados pessoais de acessos não autorizados e de situações acidentais ou ilícitas de destruição, perda, alteração, comunicação ou qualquer forma de tratamento inadequado ou ilícito, observada a natureza dos dados tratados, mantendo o monitoramento, controle e segurança dos dados pessoais. É vedado ao CONTRATANTE e ao CONSULTOR vender, compartilhar ou ceder, a qualquer título, a terceiros, utilizar qualquer método que vise realizar engenharia reversa, identificar os titulares dos dados pessoais, transferir, excluir, acrescentar, alterar e de qualquer forma tratar os dados e informações, bem como a usar, compartilhar ou comercializar quaisquer eventuais elementos de dados, produtos ou subprodutos que se originem ou sejam criados a partir do tratamento de dados, sem prévia e escrita solicitação ou autorização da FLOW (Controladora).",
      ],
    },
  },
  fifthSection: {
    subtitle: "•  5. DA VIGÊNCIA.",
    content: [
      "5.1 Este TERMO DE USO vigorará por tempo indeterminado, e vinculará todas as relações havidas entre a FLOW e a CONTRATANTE e o EMPREGADO EM POTENCIAL.",
      "5.2 Este Termo de Uso poderá ser resilido pelo CONSULTOR ou pelo CONTRATANTE, a qualquer tempo, de forma imediata e sem aviso prévio, mediante a extinção do cadastro do EMPREGADO EM POTENCIAL ou do PROJETO EM ANDAMENTO, com a exclusão dos dados pessoais fornecidos quando do preenchimento de seu cadastro, ressalvadas suas obrigações legais, sem que disso decorra qualquer direito ou expectativa de direito para o EMPREGADO EM POTENCIAL. A resilição disciplinada nesta cláusula não conferirá a nenhuma das PARTES o direito de receber qualquer indenização ou valor.",
      "5.3 Sem prejuízo do disposto neste TERMO DE USO, e do pagamento de eventuais indenizações por perdas e danos eventualmente sofridos pelas partes, o inadimplemento de qualquer norma em vigor e/ou qualquer uma das obrigações previstas neste Termo de Uso ou dele decorrentes, facultará à parte prejudicada o direito de considerar este Termo de Uso rescindido de pleno direito, por justa causa, sem a necessidade de aviso prévio ou cumprimento antecedência mínima.",
    ],
  },
};
