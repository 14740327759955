<script setup lang="ts">
import IdealProfileTabs from "./components/IdealProfileTabs.vue";
import { SliderLevelView } from "@/components/slider";
import { CULTURAL_MATCH_TITLES } from "@/entities/project/construction-guide";
import { SvgIcon } from "@/components/icon";

const props = defineProps<{ id: string }>();

const AsyncCulturalMatchForm = defineAsyncComponent(() =>
  import("./components/forms/CulturalMatchForm.vue"));
const router = useRouter();
const { t } = useI18n();
const { data: project, error, isLoading: loading } = await useProject(props.id);

const sliderDefaults = {
  showLegend: false,
  showScore: true,
  showControlMarker: false,
  background: "white-background",
  selectedColor: "yellow-slider",
  selectedMarkerColor: "yellow-shadow-dark",
  controlColor: "yellow-slider",
  controlMarkerColor: "gray-shade-dark",
  showSelectedMarker: false,
};

const icons = {
  [CULTURAL_MATCH_TITLES.AFFECTIVE]: "heart_icon",
  [CULTURAL_MATCH_TITLES.LOGICAL]: "brain_icon",
  [CULTURAL_MATCH_TITLES.PRACTICAL]: "gears_icon",
  [CULTURAL_MATCH_TITLES.REFLEXIVE]: "feather_icon",
};

const {
  data: idealProfile,
} = useConstructionIdealProfile(props.id);

const choices = computed(() => ([
  "LOW",
  "AVERAGE",
  "HIGH",
  "VERY_HIGH",
]
  .map(data => ({
    label: t(`projects.generic.scale.enum.${data}`).toUpperCase(),
    value: data,
  }))
));

const {
  stageTabs,
} = useTabsProject(props.id);

const currentRoute = computed(() => stageTabs.value.findIndex(tab => tab.key === router.currentRoute.value.meta.tab));

const route = useRoute();

const {
  data: construction,
} = useConstructionOrganizationQuery(String(route.params.id));

const constructionId = computed(() => {
  return construction.value?._id;
});

const {
  mutate: patchIdealProfile,
} = usePatchIdealProfileQuery();

const editing = ref(false);

function saveCulturalMatch(data: any) {
  patchIdealProfile({
    _id: String(constructionId.value),
    cultural_match: data,
  });
}

const payload = computed(() => Object.keys(idealProfile.value?.cultural_match || []).map(match => ({
  key: match.toUpperCase(),
  title: t(`cultural_match.${match.toUpperCase()}`),
  tooltip: match.toUpperCase(),
  answer: idealProfile.value?.cultural_match[match],
})));

const titles = computed(() => ({
  control: t("projects.screening.guide.PostScreening.educationBackground.idealProfile"),
  candidate: t("projects.screening.labels.candidate"),
}));

// function getNumberRealization(value: number): string {
//   return String((index.value * group.value) + value);
// }
function checkActive(value: any) {
  return !value || value === "EMPTY" ? "" : "active";
}

function goTo(tabIndex: number) {
  if (!stageTabs.value || !stageTabs.value[tabIndex])
    return;

  router.push(stageTabs.value[tabIndex].path);
}

// TODO: watch resize and prevent rerender error
</script>

<template>
  <section class="project-section">
    <div v-if="error">
      could not load
    </div>
    <div class="project-container">
      <ProjectStages :id="props.id" />
      <div v-if="project" class="project-wrapper">
        <ProjectTabs :stage-tabs="stageTabs" :active="currentRoute" class="tabs" @change="goTo($event)" />
        <IdealProfileTabs :editing="editing" :project-id="project._id" :stages="project.stages" @edit="editing = true" />
        <div v-if="!editing" class="project-content">
          <div class="attributes-component">
            <div v-for="(item, index) in payload" :key="index" class="content">
              <div class="data">
                <span class="data-icon" :class="[checkActive(item.answer)]">
                  <SvgIcon :icon="icons[item.key as never]" margin="none" />
                </span>
                <span class="data-label">
                  {{ item.title }}
                </span>
              </div>
              <div class="item-evaluate">
                <div class="item-slider">
                  <SliderLevelView
                    :options="choices"
                    :selected-value="item.answer"
                    :description="t(`projects.shortlist.enums.cultural.${item.key}`)"
                    :show-labels="index === 0"
                    :show-selected-marker="sliderDefaults.showSelectedMarker"
                    :show-control-marker="sliderDefaults.showControlMarker"
                    :control-value="item.answer"
                    :control-color="sliderDefaults.controlColor"
                    :control-marker-color="sliderDefaults.controlMarkerColor"
                    :control-title="titles.control"
                    :selected-color="sliderDefaults.selectedColor"
                    :selected-marker-color="sliderDefaults.selectedMarkerColor"
                    :selected-title="titles.candidate"
                  />
                </div>
              </div>
            </div>
            <div v-if="sliderDefaults.showLegend" class="legends">
              <div class="item">
                <div class="marker" :class="[`--bg-${sliderDefaults.controlMarkerColor}`]" />
                <div class="marker-title">
                  {{ titles.control }}
                </div>
              </div>
              <div class="item">
                <div class="marker" :class="[`--bg-${sliderDefaults.selectedMarkerColor}`]" />
                <div class="marker-title">
                  {{ titles.candidate }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="project-content">
          <AsyncCulturalMatchForm
            :cultural-match="idealProfile.cultural_match"
            @cancel="editing = false"
            @submit="saveCulturalMatch($event)"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<route lang="yaml">
meta:
  layout: project-stage
  stage: construction
  tab: ideal-profile
  ideal-profile: cultural-match
</route>

<style scoped lang="scss">
.project-section {
  min-height: 100vh;
}
.project-container {
  padding-top: 3.75rem;
}
.project-wrapper {
  background: white;
  // padding-bottom: 2.5rem;
}
.project-content {
  margin-inline: 2.5rem;
}
</style>

<style scoped lang="scss">
.profile-container {
  position: relative;
  display: grid;
  font-size: 0.875rem;
  grid-template-columns: repeat(3, 1fr);
  letter-spacing: 0.49px;
  line-height: 1.375rem;
  padding-bottom: 2.5rem;

  .container-svg {
    position: absolute;
    top: 0;
    display: block;

    > line {
      stroke: $gray-border;
      stroke-width: 1;
    }
  }

  .content-center {
    position: absolute;
    z-index: 2;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;

    .icon {
      display: flex;
      width: 9rem;
      height: 9rem;
      align-items: center;
      justify-content: center;
      border-radius: 9rem;
      background-color: $white-background;
      box-shadow: 0 2px 4px #00000029;
    }
  }

  .content {
    padding-bottom: 1.5rem;

    .title {
      display: flex;
      align-items: center;
      font-size: 1.25rem;
      gap: 0.5rem;
      text-transform: uppercase;
      white-space: nowrap;

      .icon {
        display: flex;
        width: 2rem;
        height: 2rem;
        align-items: center;
        justify-content: center;
        border-radius: 2rem;
        background-color: $white-background;
        box-shadow: 0 2px 4px #00000029;
      }
    }

    ul {
      padding: 0.5rem;
      padding-bottom: 0;
      line-height: 1.25rem;
      list-style: disc;
      list-style-position: inside;

      li {
        color: $gray-lower;
        word-break: break-word;

        &::marker {
          color: $yellow;
          font-size: 1rem;
        }
      }
    }
  }
  .column-2 {
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      display: flex;
      width: 9rem;
      height: 9rem;
      align-items: center;
      justify-content: center;
      border-radius: 9rem;
      background-color: $white-background;
      box-shadow: 0 2px 4px #00000029;
    }
  }

  .column-1,
  .column-3 {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
}

.content-title {
  border-bottom: 1px solid #cecece;
  width: fit-content;
  padding-bottom: 0.125rem;
}

.clamp {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  word-break: break-word;
}
</style>

<style lang="scss" scoped>
.attributes-component {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-bottom: 3.5rem;
  gap: 1.5rem;
  padding-inline: 1.5rem;
  .content {
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr 4fr;

    .item-radios {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0 1rem 0 2rem;
    }
  }

  .data {
    display: flex;
    min-width: 13rem;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    .data-icon {
      display: flex;
      width: 55px;
      min-width: 55px;
      height: 55px;
      min-height: 55px;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      box-shadow: 0 2px 4px #00000029;
      color: $gray-border;
    }

    .data-icon.active {
      background-color: $white-background;
      color: $yellow;
    }

    .data-label {
      width: -webkit-fill-available;
      line-height: 1.3;
      text-transform: uppercase;
    }

    .help {
      text-transform: none;

      svg {
        color: $gray-shade-dark;
      }
    }
  }

  .legends {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 3rem;
    gap: 4rem;

    .item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;

      .marker {
        width: 7px;
        height: 20px;
        background: $gray-shade-dark;
      }

      .marker-title {
        font-size: 0.75rem;
      }
    }
  }

  .form-actions {
    display: flex;
    margin: 2rem 0;
    gap: 2rem;
    grid-column: 1 / -1;
  }

  .item-evaluate {
    display: flex;
    width: 100%;
    align-items: center;

    .item-slider {
      width: inherit;
    }

    .item-score {
      display: flex;
      width: 5rem;
      align-items: center;

      .score {
        display: flex;
        min-width: 5rem;
        height: fit-content;
        align-items: center;
        justify-content: center;
        padding: 0.4375rem 1.18rem;
        border: 1px solid $gray-border;
        border-radius: 6px;
        background: $white-full;
        color: $gray-text;
        font-size: 1.25rem;
        font-weight: bold;
      }
    }
  }
}

@mixin colors-change($colors-map) {
  @each $key, $value in $colors-map {
    .attributes-component {
      .legends {
        .item {
          .marker.--bg-#{$key} {
            background: $value;
          }
        }
      }
    }
  }
}

@include colors-change($all-colors);
</style>
