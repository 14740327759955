import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { CurrencyEnum } from "@/schemas/enums/currency";
import { PaymentFrequencyEnum } from "@/types/salary";
import type {
  PatchApprovalCandidateType,
} from "@/common/services/candidates";
import {
  getValidationPanelCandidates,
  patchApprovalCandidate,
} from "@/common/services/candidates";
import { getPerson, updatePerson } from "@/http_services/entities/persons";
import type { Person } from "@/schemas/person";

export function useFetchValidationPanel(project_id: string) {
  return useQuery({
    queryKey: ["validation-panel", project_id],
    queryFn: () => getValidationPanelCandidates(project_id),
  });
}

export function usePatchPanelCandidate(project_id: string) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["validation-panel", project_id],
    mutationFn: (data: {
      candidate_id: string
      body: PatchApprovalCandidateType
    }) => patchApprovalCandidate(data.candidate_id, data.body),
    onSuccess: async () => {
      await queryClient.cancelQueries({ queryKey: ["candidates"] });
      await queryClient.cancelQueries({ queryKey: ["mapping_candidates"] });
      await queryClient.cancelQueries({ queryKey: ["interview_candidates"] });
    },
  });
}

export function usePatchValidationPanelPerson(project_id: string) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["validation-panel", project_id],
    mutationFn: async (data: { person_id: string; payload: Partial<Person> }) => {
      const person = await getPerson(data.person_id);

      if (!person)
        return;

      return updatePerson(person, { person: { ...person, ...data.payload } });
    },
    onSuccess: async () => {
      await queryClient.cancelQueries({ queryKey: ["candidates"] });
      await queryClient.cancelQueries({ queryKey: ["mapping_candidates"] });
      await queryClient.cancelQueries({ queryKey: ["interview_candidates"] });
    },
  });
}

export function usePatchValidationForWorkExperiences(project_id: string) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["validation-panel", project_id],
    mutationFn: async (data: { person_id: string; payload: any }) => {
      const person = await getPerson(data.person_id);

      if (!person)
        return;

      const workExperiences = [...person.work_experience ?? []];
      const currentWork = Object.assign({}, person.work_experience?.find(we => we.is_current));
      const index = person.work_experience?.findIndex(we => we.is_current) ?? -1;
      if (!currentWork || index < 0)
        return;

      if (!currentWork.salary) {
        currentWork.salary = {
          amount: 0,
          contract_type: null,
          currency: CurrencyEnum.BRL,
          payment_frequency: PaymentFrequencyEnum.MONTHLY,
        };
      }

      workExperiences.splice(index, 1, { ...currentWork, salary: { ...currentWork.salary, ...data.payload } });

      return updatePerson(person, { person: { ...person, work_experience: workExperiences } });
    },
    onSuccess: async () => {
      await queryClient.cancelQueries({ queryKey: ["candidates"] });
      await queryClient.cancelQueries({ queryKey: ["mapping_candidates"] });
      await queryClient.cancelQueries({ queryKey: ["interview_candidates"] });
    },
  });
}
