import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { addInterviewsActivity, getInterviewActivity, getInterviewsActivities, patchInterviewAnnotations } from "@/common/services/interviews";
import type { SearchCandidatesType } from "@/common/services/candidates";
import { searchCandidates } from "@/common/services/candidates";
import type { ActivitiesSearchType } from "@/common/services/interviews";

export function useInterviewsActivitiesQuery(query: ActivitiesSearchType) {
  return useQuery({
    queryKey: ["interview_activities", "project", query.project_id],
    queryFn: () => getInterviewsActivities(query),
  });
}

export function useCandidateInterviewsActivities(query: ActivitiesSearchType) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["interview_activities", "project", query.project_id],
    mutationFn: () => getInterviewsActivities(query),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["interview_activities", "project", query.project_id] });
    },
  });
}
export function useCandidateInterviewsActivitiesWithBody(project_id: string) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["interview_activities", "project", project_id],
    mutationFn: (body: { project_id: string; candidate_person_id: string }) => getInterviewsActivities(body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["interview_activities", "project", project_id] });
    },
  });
}
export function useCreateInterviewActivity() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["interview_activities"],
    mutationFn: (activity: any) => addInterviewsActivity(activity),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["interview_activities"] });
    },
  });
}

export function useInterviewActivityQuery(id: string) {
  return useQuery({
    queryKey: ["interview_activities", id],
    queryFn: () => getInterviewActivity(id),
  });
}

export function useInterviewActivityMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["interview_activities"],
    mutationFn: (activity_id: string) => getInterviewActivity(activity_id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["interview_activities"] });
    },
  });
}

export function usePatchInterviewActivity() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["interview_activities"],
    mutationFn: (body: { project_id: string; activity: any }) => updateInterviewActvity(body.project_id, body.activity),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["interview_activities"] });
    },
  });
}

export function useInterviewFullCandidates() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params: SearchCandidatesType) => searchFullCandidates(params),
    onSuccess: async (data) => {
      await queryClient.refetchQueries({ queryKey: ["interview_candidates"] });
      return data;
    },
    onError: (error) => {
      console.log("error", error);
    },
  });
}

export function useInterviewCandidates(params: SearchCandidatesType) {
  return useQuery({
    queryKey: ["interview_candidates"],
    queryFn: () => searchCandidates(params),
  });
}

export function useInterviewCandidatesCount(params: SearchCandidatesType) {
  return useQuery({
    queryKey: ["interview_candidates"],
    queryFn: () => searchCandidates(params),
    select: data => data ? data.length : 0,
  });
}

export const isNotesSaving = ref(false);

export function usePatchInterviewAnnotationsMutation() {
  const queryClient = useQueryClient();
  let id = "";
  return useMutation({
    mutationFn: (body: { id: string; annotations: any }) => {
      id = body.id;
      return patchInterviewAnnotations(body.id, body.annotations);
    },
    onMutate: async (newAnnotations) => {
      isNotesSaving.value = true;
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: ["interview_activities", id, "annotations"] });

      // Snapshot the previous value
      const previousTodos = queryClient.getQueryData(["interview_activities", id, "annotations"]);
      // Optimistically update to the new value
      queryClient.setQueryData(["interview_activities", id, "annotations"], (old: any) => {
        return {
          ...old,
          annotations: {
            ...old?.annotations,
            ...newAnnotations,
          },
        };
      });

      // Return a context object with the snapshotted value
      return { previousTodos };
    },
    onError: () => {
      isNotesSaving.value = false;
    },
    onSettled: async () => {
      isNotesSaving.value = false;
      await queryClient.invalidateQueries({ queryKey: ["interview_activities", id, "annotations"] });
    },
  });
}
