<script setup lang="ts">
import { toTypedSchema } from "@vee-validate/zod";
import * as zod from "zod";
import get from "lodash-es/get";
import moment from "moment";
import type { PositionDetails, Project } from "@/schemas/project";
import { completeDate } from "@/utils/dates";
import { formatCurrency } from "@/utils/numbers";
import { Modal } from "@/components/modal";
import { Tooltip } from "@/components/tooltip";
import { SvgIcon } from "@/components/icon";
import { AvatarList } from "@/components/avatar-list";
import WarningIcon from "@/assets/images/alert_circle_icon.svg";
import { PROJECT_STATUS } from "@/entities/project";
import { Card } from "@/components/card";
import { Button2 as Button } from "@/components/button";
import { env } from "@/env";

const emit = defineEmits(["cancel", "edit-project"]);

const route = useRoute();
const { t, tm, locale } = useI18n();
const {
  data: project,
  refetch: refetchProject,
} = useProject(String(route.params.id));

const {
  mutate: updateProject,
} = updateProjectMutation(String(route.params.id));

const validationSchema = toTypedSchema(
  zod.object({
    name: zod.string({
      required_error: "Nome is required",
    }).nonempty("Can't be empty")
      .min(3, { message: "Too short" }),
    demo: zod.string({
      required_error: "Demo is required",
    }).nonempty("Can't be empty")
      .min(3, { message: "Too short" }),
    email: zod
      .string({
        required_error: "Email is required",
      })
      .nonempty({ message: "Cant be empty" })
      .email({ message: "Must be a valid email" }),
    password: zod
      .string({
        required_error: "Password is required",
      })
      .nonempty("Can't be empty")
      .min(8, { message: "Too short" }),

  }),
);

const { handleSubmit, isSubmitting, errors, controlledValues } = useForm({
  validationSchema,
});

const formState = ref({
  project_name: project.value?.project_name,
  position_name: project.value?.position_name,
  project_type: project.value?.project_type,
  confidential_placement: project.value?.confidential_placement,
  position_details: {
    address: {
      name: project.value?.position_details?.address?.name,
    },
    salary_range: {
      currency: project.value?.position_details?.salary_range?.currency,
      contract_type: project.value?.position_details?.salary_range?.contract_type,
      min_value: project.value?.position_details?.salary_range?.min_value,
      max_value: project.value?.position_details?.salary_range?.max_value,
    },
    position_class: project.value?.position_details?.position_class,
    level: project.value?.position_details?.level,
    bonus: project.value?.position_details?.bonus,
  },
} as Partial<Project>);

function submit() {
  updateProject({ ...project.value, project_name: formState.value.project_name } as Project);
  emit("cancel");
}

function cancel() {
  formState.value = { project_name: project.value?.project_name ?? "" };
  emit("cancel");
}
const publishBy = computed(
  () =>
    `${project?.value?.published_by?.first_name} ${project?.value?.published_by?.last_name}`
    || "",
);

const originTypes = computed(() => tm("projects.create.basicData.source.enum"));
const classTypes = computed(() => tm("projects.create.positionData.class.enum"));

const projectStatus = computed(() => project.value.status) as Ref<string>;
const showOptions = ref(false);
const showModalSuspend = ref(false);
const showModalCancel = ref(false);
const optionsRef = ref(null);

const enabledNetsuite = env.VITE_APP_NETSUITE_FATURAMENTO_URL;

const projectMap = computed(() => {
  const {
    position_name,
    position_details,
    corporation,
    published_by,
    placement_type,
  } = project.value;
  const { address, annual_package, level, salary_range, position_class, bonus }
    = position_details || {};
  const { currency: src, min_value: srmiv, max_value: srmav, contract_type: ct }
    = salary_range || {};
  const { currency: apc, min_value: apmiv, max_value: apmav } = annual_package || {};

  const getI18nString = (strings: TemplateStringsArray) =>
    `projects.generic.${strings[0]}`;

  const map = new Map();
  map.set(t(getI18nString`position`), position_name);
  map.set(t(getI18nString`location`), address?.name);
  map.set(
    t(getI18nString`status`),
    t(`projects.filters.projectStatus.enum.${project.value.status}`),
  );
  map.set(t(getI18nString`class`), classTypes.value[position_class]);
  map.set(t(getI18nString`level`), level);
  map.set(t(getI18nString`company`), corporation.corporation_name);

  let salaryString = "";
  if (ct && src) {
    const localeFormat = formatCurrency(src, locale.value);
    salaryString = `${ct} - ${t("text.conjunctions.of")} ${localeFormat(
      srmiv,
    )} - ${localeFormat(srmav)}`;
  }

  map.set(t(getI18nString`salary`), salaryString);
  map.set(t(getI18nString`bonus`), bonus);

  let annualString = "";
  if (apc) {
    const localeFormat = formatCurrency(apc, locale.value);
    annualString = `&nbsp;${localeFormat(apmiv)} - ${localeFormat(apmav)} <br><br>`;
  }

  map.set(
    { value: t(getI18nString`annualPackage`), style: "display: flex" },
    annualString,
  );
  map.set(t(getI18nString`requester`), publishBy.value);

  return map;
});
const positionDetails = computed<Partial<PositionDetails>>(
  () => project.value?.position_details || {},
);
const projectType = computed(() => {
  const project_type = get(project.value, "project_type", "");
  return project_type.charAt(0);
});

const DEFAULT_MAX_COUNT = 2;

const LIMIT_MAX_COUNT = 3;

const maxCounter = computed(() => {
  return get(project.value, "participants.persons_data")?.length > LIMIT_MAX_COUNT
    ? DEFAULT_MAX_COUNT
    : get(project.value, "participants.persons_data")?.length;
});

const projectParticipants = computed<IList[]>(() => {
  const participants = project.value.participants
    .persons_data as PersonInAggregation[];

  const participantsMap = ({
    _id,
    avatar_url,
    first_name,
    last_name,
  }: PersonInAggregation) => ({
    id: _id ?? first_name,
    src: avatar_url,
    alt: (first_name && first_name.concat(` ${last_name}`)) || "",
  });

  return participants?.map(participantsMap);
});

const lastUpdated = computed(() => {
  const { updated_at } = project.value;
  if (updated_at)
    return completeDate(updated_at);
  return "";
});
const expanded = ref(true);

const corporationName = computed(
  () => project?.value?.corporation?.corporation_name || "",
);
function cancelOption() {
  showOptions.value = false;
  showModalSuspend.value = false;
  showModalCancel.value = false;
}

const isSuspended = computed(() => {
  if (
    projectStatus.value === PROJECT_STATUS.SUSPENDED
    || projectStatus.value === PROJECT_STATUS.CANCELLED
  )
    return true;

  return false;
}) as Ref<boolean>;

// TODO: refact this component...so confusing...
const isCanceled = computed(() => {
  if (projectStatus.value === PROJECT_STATUS.CANCELLED)
    return true;

  return false;
}) as Ref<boolean>;

function getModalSuspendProject() {
  if (!isSuspended.value) {
    showModalSuspend.value = true;
    showOptions.value = false;
  }
}
async function getModalActivateProject() {
  if (isSuspended.value) {
    function findKeyByStatus(status: string): string | undefined {
      const stages = project?.value?.stages ?? {};
      return Object.keys(stages).find(key => stages[key] === status);
    }

    const suspendedKey = findKeyByStatus("SUSPENDED");
    updateProject({
      ...project.value,
      status: PROJECT_STATUS.SUSPENDED,
      stages: { ...project.value.stages, [suspendedKey]: PROJECT_STATUS.ACTIVE },
    }, {
      onSuccess: async () => {
        refetchProject();
        cancelOption();
      },
    });
  }
}

function getModalCancelProject() {
  if (!isCanceled.value) {
    showModalCancel.value = true;
    showOptions.value = false;
  }
}

async function confirmSuspendProject() {
  const result: any = await editProjectStatus(project.value._id, "suspend");
  if (result) {
    refetchProject();
    cancelOption();
  }
}

async function confirmCancelProject() {
  const result: any = await editProjectStatus(project.value._id, "cancel");

  if (result) {
    refetchProject();
    cancelOption();
  }
}

onClickOutside(optionsRef, () => (showOptions.value = false));

const {
  data: me,
} = useMeQuery();

function openNetsuiteProto() {
  if (!me)
    return;
  const protoURL = env.VITE_APP_NETSUITE_FATURAMENTO_URL;
  const token = localStorage.getItem("refresh_token");
  const project_netsuite_id = project.value?.external_ids?.netsuite_id.record_id;

  const params = new URLSearchParams({
    netsuite_id: String(project_netsuite_id),
    token: token!,
  });

  window.open(`${protoURL}/?${params}`, "_blank");
}
const startDate = ref("");
const endDate = ref("");
const diffDate = ref("");

function getDate(date: string | null) {
  try {
    if (date === null)
      return "";

    return moment(date).format("DD/MM/YYYY");
  }
  catch (_) {
    return "";
  }
}

function getDateDif(init: string, end: string): string {
  if (!init || init === "")
    return "";

  const formatInit = moment(init);
  let formatEnd = moment();
  if (end && end !== "")
    formatEnd = moment(end);

  const allDays = formatEnd.diff(formatInit, "days");

  const year = Math.floor(allDays / 365);

  const remainingDays = allDays - year * 365;

  const month = Math.floor(remainingDays / 30);

  const day = remainingDays - month * 30;

  const titleYear = year > 1 ? t("date.yearPlural") : t("date.yearSingular");
  const titleMonth = month > 1 ? "m" : "m";
  const titleDay = day > 1 ? t("date.dayPlural") : t("date.daySingular");

  if (year > 0 && month > 0)
    return `${year} ${titleYear} ${month}${titleMonth}`;
  else if (year > 0 && month === 0)
    return `${year} ${titleYear}`;
  else if (year === 0 && month > 0)
    return `${month}${titleMonth} ${day} ${titleDay}`;
  else if (year === 0 && month === 0)
    return `${day} ${titleDay}`;
  else return "";
}

onMounted(() => {
  startDate.value = getDate(project.value.started_at || project.value.inserted_at);
  endDate.value = getDate(project.value.finished_at);
  if (endDate.value !== "") {
    diffDate.value = getDateDif(
      project.value.started_at || project.value.inserted_at,
      project.value.finished_at,
    );
  }
  else {
    diffDate.value = getDateDif(
      project.value.started_at || project.value.inserted_at,
      moment().toISOString(),
    );
  }
});
</script>

<template>
  <Card class="project-card">
    <div class="project-card__header">
      <div>
        <span class="project-card__badge">{{ projectType }}</span>
        <h2>{{ project.project_name }}</h2>
        <span class="project-card__count">({{ project.serial }})</span>
        <span
          role="link"
          :title="t('projects.generic.edit')"
          :aria-label="t('projects.generic.edit')"
          @click="$emit('editProject')"
        >
          <SvgIcon icon="edit_icon" width="12" height="12" />
        </span>
      </div>

      <div>
        <Tooltip
          v-if="enabledNetsuite"
          class="netsuite-tooltip"
          :tooltip-text="t('corporations.details.currentRevenue')"
          tooltip-width="max-content"
          x-align="center"
          y-align="top"
        >
          <template #tooltip-message>
            <SvgIcon
              class="netsuite-svg"
              icon="netsuite"
              margin="none"
              width="6.25rem"
              height="0.625rem"
            />
          </template>
          <SvgIcon
            v-if="enabledNetsuite"
            class="billing-svg"
            icon="billing"
            width="16"
            height="16"
            margin="none"
            @click="openNetsuiteProto()"
          />
        </Tooltip>
        <span v-show="lastUpdated" class="project-card__last-update">
          <SvgIcon icon="refresh_icon" width="12" height="12" />
          {{ lastUpdated }}
        </span>

        <Tooltip
          :tooltip-text="t('projects.generic.confidential')"
          tooltip-width="max-content"
          x-align="center"
          y-align="top"
        >
          <SvgIcon
            v-show="project.confidential_placement"
            icon="padlock_icon"
            width="30"
            height="30"
            margin="none"
          />
        </Tooltip>

        <span
          :title="t('projects.generic.expand')"
          :aria-label="t('projects.generic.expand')"
          role="button"
          @click="expanded = !expanded"
        >
          <SvgIcon
            icon="chevron_icon"
            width="16"
            height="16"
            margin="none"
            :rotate="expanded ? '0' : '180deg'"
          />
        </span>
        <span ref="optionsRef" class="options-icon" @click="showOptions = !showOptions">
          <SvgIcon icon="points_icon" margin="none" />
        </span>
        <span v-if="showOptions" class="options-dropwdown">
          <span
            class="item"
            :class="[isCanceled ? 'disabled' : '']"
            @click="isSuspended ? getModalActivateProject() : getModalSuspendProject()"
          >
            {{
              isSuspended
                ? t("projects.details.options.activateProject")
                : t("projects.details.options.suspendProject")
            }}
          </span>
          <span class="item" :class="[isCanceled ? 'disabled' : '']" @click="getModalCancelProject()">
            {{ t("projects.details.options.cancelProject") }}
          </span>
        </span>
      </div>
    </div>

    <transition name="slide-down">
      <div v-show="expanded" class="project-card__content">
        <div>
          <template v-for="[key, value] in projectMap" :key="value">
            <div class="card-line" :style="key.style || ''">
              <b>{{ key.value || key }}: </b>
              <span v-if="value" v-html="value" />
            </div>
          </template>

          <div class="project-card__footer__duration">
            <div>
              <span class="label">{{ t('text.start') }}: </span>
              <span class="value">{{ startDate }}</span>
              <span v-if="endDate === '' && !diffDate" class="diff">({{ diffDate }})</span>
            </div>
            <div v-if="endDate !== ''">
              <span class="label">{{ t('text.end') }}: </span>
              <span class="value">{{ endDate }}</span>
              <span class="diff">({{ diffDate }})</span>
            </div>
          </div>
          <div class="project-card__participants">
            <AvatarList
              :list="projectParticipants"
              :max-counter="maxCounter"
              avatar-size="1.875rem"
              counter-size="1.689rem"
            />
          </div>

          <RouterLink :to="`/validation/${project?._id}`" target="_blank">
            <Button variation="secondary">
              {{ t("projects.validation.title") }}
            </Button>
          </RouterLink>
        </div>

        <template v-if="positionDetails?.about">
          <hr>

          <div class="project-card__about">
            <h4>{{ t("projects.generic.about") }}</h4>
            <div>{{ positionDetails?.about }}</div>
          </div>
        </template>
      </div>
    </transition>

    <div v-show="!expanded" class="project-card__footer">
      <div>
        <b>{{ t("projects.generic.position") }}: </b>
        {{ project.position_name }}
      </div>
      <div>
        <b>{{ t("projects.generic.company") }}: </b>
        {{ corporationName }}
      </div>
      <div>
        <b>{{ t("projects.generic.requester") }}: </b>
        {{ publishBy }}
      </div>
    </div>
    <Modal
      v-if="showModalSuspend"
      :title="t('projects.details.options.modalSuspend.title').toUpperCase()"
      :button-text="t('projects.details.options.modalSuspend.buttons.confirm')"
      :secondary-button-text="t('projects.details.options.modalSuspend.buttons.cancel')"
      :on-click="confirmSuspendProject"
      :secondary-on-click="cancelOption"
      :on-close="cancelOption"
      variation="warning"
      secondary-variation="light"
    >
      <template #titleIcon>
        <WarningIcon />
      </template>
      <template #message>
        <div class="modal-message">
          {{ t("projects.details.options.modalSuspend.resume") }}
        </div>
      </template>
    </Modal>
    <Modal
      v-if="showModalCancel"
      :title="t('projects.details.options.modalCancel.title').toUpperCase()"
      :button-text="t('projects.details.options.modalCancel.buttons.confirm')"
      :secondary-button-text="t('projects.details.options.modalCancel.buttons.cancel')"
      :on-click="confirmCancelProject"
      :secondary-on-click="cancelOption"
      :on-close="cancelOption"
      variation="warning"
      secondary-variation="light"
    >
      <template #titleIcon>
        <WarningIcon />
      </template>
      <template #message>
        <div class="modal-message">
          {{ t("projects.details.options.modalCancel.resume") }}
        </div>
      </template>
    </Modal>
  </Card>
</template>

<style lang="scss" scoped>
:deep(.tooltip-text) {
  left: -150%;
}

.project-card {
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    &>div {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }

  &__content {
    &>div:first-child {
      columns: auto 40ch;
    }
  }

  &__footer {
    columns: auto 40ch;
  }

  &__participants {
    display: flex;
    height: 60px;
    align-items: center;
    justify-content: flex-end;
  }

  &__badge {
    display: inline-block;
    width: 37px;
    height: 37px;
    border-radius: 50%;
    background-color: $primary;
    color: $white-full;
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 37px;
    text-align: center;
  }

  &__count {
    font-size: 0.875rem;
    font-weight: 400;
  }

  hr {
    border-color: $gray-lighter;
    border-top: none;
    margin-block: 1rem;
  }

  h2 {
    font-size: 1.125rem;
    text-transform: capitalize;
  }

  p,
  h4 {
    margin-bottom: 0.25rem;
    line-height: 1.5;
  }
}

.options-icon {
  color: $gray-dark;
  cursor: pointer;

  &:hover {
    color: $yellow;
  }
}

:deep(.modal-container .modal-content) {
  width: 600px;

  .modal-message {
    width: 100%;
  }
}

:deep(.modal-title) {
  font-size: 1.25rem;
  line-height: 1.5rem;
  text-align: center;
}

.modal-message {
  width: 100%;
  padding: 0 2%;
  line-height: 1.2rem;
  text-align: center;
}

.options-dropwdown {
  position: absolute;
  z-index: 99;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-radius: 6px;
  margin: 7.2rem 0 0 1.8rem;
  background: $white-full;
  box-shadow: 0 4px 6px #00000089;
  gap: 1rem;

  .item.disabled {
    color: $gray-x14;
    cursor: pointer;

    &:hover {
      color: $gray-x14;
    }
  }

  .item {
    color: $gray-x15;
    cursor: pointer;

    &:hover {
      color: $black;
    }
  }
}

.slide-down-enter-from,
.slide-down-leave-to {
  transform: scaleY(0);
}

.slide-down-enter-active,
.slide-down-leave-active {
  transform-origin: top;
  transition: transform ease-out 100ms;
}

.slide-down-enter-to,
.slide-down-leave-from {
  transform: scaleY(1);
}

:deep(.avatar-list__container) {
  position: absolute;
  display: flex;
  width: 110px;
  align-items: center;
}

:deep(.avatar-list__container > span) {
  &:nth-of-type(1) {
    z-index: 3;
  }

  &:nth-of-type(2) {
    z-index: 2;
    transform: translateX(-50%);
  }

  &:nth-of-type(3) {
    z-index: 1;
    transform: translateX(-105%);
  }

  &:nth-of-type(4) {
    z-index: 1;
    transform: translateX(-155%);
  }
}

:deep(.tooltip-list__avatar) {
  border: 2px solid #fff;
}

:deep(.avatar-list__counter) {
  margin-bottom: 0.1rem;
  font-size: 0.8rem;
}

:deep(.avatar-list__container > .extra__list--card-container) {
  z-index: 5;
}

:deep(.avatar-list__container > .extra__list--card-container > .extra__list--card) {
  cursor: default;
}

:deep(.tooltip-message) {
  color: $primary;
  font-weight: bold;
  text-transform: uppercase;
}

.card-line {
  margin-bottom: 0.25rem;
  line-height: 1.5;
}

.project-card__footer__duration {
  display: flex;
  flex-direction: row;
  gap: 1rem;

  div {
    display: flex;
    flex-direction: row;
    font-size: 0.875rem;
    gap: 0.2rem;

    .label {
      color: $gray-dark;
      font-weight: bold;
      font-size: 1rem;
    }

    .value,
    .diff {
      font-size: 1rem;
    }
  }
}

:deep(.netsuite-tooltip) .tooltip-message {
  color: $gray-dark;
  text-transform: none;
}

.billing-svg:hover path {
  fill: $red-violet;
}
</style>
