import { resumeService } from "../client";
import { NotFoundError } from "../errors";
import { loginMsParser } from "../auth";

const url = "resume";

export async function resumeParse(file: File) {
  try {
    // const { data } = await axios.get(
    //   "https://run.mocky.io/v3/ffac3f28-4655-49a4-a30f-44fbd4007937"
    // );

    if (!localStorage.getItem("imua_parser_acess_token"))
      await loginMsParser();

    const body = new FormData();
    body.append("file", file);

    const { data } = await resumeService.post(`${url}/upload_file`, body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return data;
  }
  catch (error) {
    if (error instanceof NotFoundError)
      return null;

    throw error;
  }
}
