<script setup lang="ts">
import { mdiClose as close } from "@mdi/js";
import { SvgIcon } from "@/components/icon";

const props = defineProps<{
  tag: string | undefined
  initials?: string
}>();
defineEmits(["click:close"]);

function parseInitials(text: string) {
  return text.split(" ")[0]?.charAt(0).toUpperCase() + (text.split(" ")[1] || text.slice(1, 2)).charAt(0).toUpperCase();
}

const colorsPalette = [
  "#e73c3e",
  "#e1a205",
  "#2cc86b",
  "#268437",
  "#fcaf45",
  "#e3a303",
  "#ff006e",
  "#7a7979",
  "#9a031e",
  "#45a256",
  "#62bbe7",
  "#f9844a",
  "#ffb600",
  "#d49800",
  "#9A031E",
  "#96185f",
  "#c23584",
  "#feb600",
  "#b00020",
  "#3289b4",
  "#666666",
  "#ac0608",
  "#f8d272",
  "#9510ac",
  "#fc0",
  "#da0063",
  "#ff0003",
  "#fb5607",
];

function hashString(str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0; // Convert to a 32-bit integer
  }
  return hash;
}

function randomColor(text: string) {
  const hash = hashString(props.initials ?? text);
  const index = Math.abs(hash) % colorsPalette.length;
  return colorsPalette[index];
}

const initials = computed(() => (props.initials || props.tag || ""));
</script>

<template>
  <div v-if="tag" class="tag-wrapper">
    <div
      class="initials-container"
      :style="{
        color: randomColor(initials),
        borderColor: randomColor(initials),
      }"
    >
      {{ parseInitials(initials) }}
    </div>
    <div class="business-tag">
      {{ tag.charAt(0).toUpperCase() + tag.toLowerCase().slice(1) }}
    </div>
    <div role="button" @click="$emit('click:close')">
      <SvgIcon type="mdi" :icon="close" width="1rem" height="1rem" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.tag-wrapper {
  display: grid;
  grid-template-columns: 1.875rem auto 1.5rem;
  gap: 0.5rem;
  border-radius: 0.5rem;
  background-color: $white-full;
  box-shadow: 0px 2px 4px 0px #00000029;
  color: $gray-dark;
  font-size: 0.75rem;
  word-wrap: break-word;
  align-items: center;
  white-space: pre-line;

  span[role="button"] {
    &:hover {
      color: lighten($gray-dark, 25%);
    }

    svg {
      vertical-align: text-bottom;
      margin: 0;
    }
  }
}

.business-tag {
  word-break: break-word;
}

.initials-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 0.5rem;
  font-size: 0.75rem;
  border: 1px solid;
  border-color: transparent;
  user-select: none;
  font-weight: 700;
}
</style>
