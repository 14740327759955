import moment from "moment";
import { extractDateFromFormat } from "@/utils/parsers";

export function isInputEmpty(value: string | number | Date | null | undefined): boolean {
  return value === null || value === undefined || value === "";
}

export function validateDateFormat(dateFormat: string, dateString: string): boolean {
  try {
    const { year, month, day } = extractDateFromFormat(dateFormat, dateString);

    const expectedDate = new Date(Number(year), Number(month) - 1, Number(day));

    const expectedYear = expectedDate.getFullYear();
    const expectedMonth = expectedDate.getMonth() + 1;
    const expectedDay = expectedDate.getDate();

    return (
      Number(year) === expectedYear
      && Number(month) === expectedMonth
      && Number(day) === expectedDay
    );
  }
  catch {
    return false;
  }
}

export function validateLinkedin(value: string): { isValid: boolean; message: string } {
  const pattern = /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)/;
  const isValid = pattern.test(value) || value === "";

  return {
    isValid,
    message: isValid ? "" : "persons.createPersonForm.validations.linkedin",
  };
}

export function validateBirthDate(value: Date | null): { isValid: boolean; message: string } {
  let isValid = true;

  if (value)
    isValid = moment().startOf("day").diff(moment(value).startOf("day"), "days") >= 0;

  return {
    isValid,
    message: isValid ? "" : "persons.createPersonForm.validations.date",
  };
}

export function validateFullName(value: string): { isValid: boolean; message: string } {
  const nameLength = value.split(" ").length;
  let isValid = true;
  if (nameLength <= 1)
    isValid = false;

  return {
    isValid,
    message: isValid ? "" : "persons.editablePersonForm.errorName",
  };
}
