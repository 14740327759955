<script setup lang="ts">
import SimpleSearch from "@/validation/pages/validation/[project_id]/components/SimpleSearch.vue";

const i18n = useI18n();
</script>

<template>
  <header class="actions">
    <SimpleSearch />
  </header>
</template>

<style scoped lang="scss">
.actions {
  height: 83px;
  background-color: $white-full;
  box-shadow: inset 0 25px 25px -30px;
  display: inline-flex;
  align-items: center;
  justify-content: end;
}
</style>
