type PrimevueLanguages = "pt-BR" | "en-US";

interface PrimeVueLocaleOptions {
  startsWith?: string
  contains?: string
  notContains?: string
  endWith?: string
  equals?: string
  notEquals?: string
  noFilter?: string
  lt?: string
  lte?: string
  gt?: string
  gte?: string
  dateIs?: string
  dateIsNot?: string
  dateBefore?: string
  dateAfter?: string
  clear?: string
  apply?: string
  matchAll?: string
  matchAny?: string
  addRule?: string
  removeRule?: string
  accept?: string
  reject?: string
  choose?: string
  upload?: string
  cancel?: string
  dayNames: string[]
  dayNamesShort: string[]
  dayNamesMin: string[]
  monthNames: string[]
  monthNamesShort: string[]
  today?: string
  weekHeader?: string
  firstDayOfWeek?: number
  dateFormat?: string
  weak?: string
  medium?: string
  strong?: string
  passwordPrompt?: string
  emptyFilterMessage?: string
  emptyMessage?: string
}

export const primevueLocales: Record<PrimevueLanguages, PrimeVueLocaleOptions> = {
  "pt-BR": {
    startsWith: "Começa com",
    contains: "Contém",
    notContains: "Não contém",
    endWith: "Termina com",
    equals: "Igual",
    notEquals: "Diferente",
    noFilter: "Sem filtros",
    lt: "Menor que",
    lte: "Menor ou igual que",
    gt: "Maior que",
    gte: "Maior ou igual que",
    dateIs: "Data é",
    dateIsNot: "Data não é",
    dateBefore: "Data antes",
    dateAfter: "Data após",
    clear: "Limpar",
    apply: "Aplicar",
    matchAll: "Todos iguais",
    matchAny: "Qualquer igual",
    addRule: "Adicionar regra",
    removeRule: "Remover regra",
    accept: "Sim",
    reject: "Não",
    choose: "Selecionar",
    upload: "Enviar",
    cancel: "Cancelar",
    dayNames: ["Doming", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"],
    dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
    dayNamesMin: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
    monthNames: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
    monthNamesShort: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
    today: "Hoje",
    weekHeader: "Sem",
    firstDayOfWeek: 0,
    dateFormat: "dd/mm/yy",
    weak: "Fraco",
    medium: "Médio",
    strong: "Forte",
    passwordPrompt: "Digite a senha",
    emptyFilterMessage: "Resultados não encontrados",
    emptyMessage: "Opções não disponíveis",
  },
  "en-US": {
    startsWith: "Starts with",
    contains: "Contains",
    notContains: "Not contains",
    endWith: "Ends with",
    equals: "Equals",
    notEquals: "Not equals",
    noFilter: "No Filter",
    lt: "Less than",
    lte: "Less than or equal to",
    gt: "Greater than",
    gte: "Greater than or equal to",
    dateIs: "Date is",
    dateIsNot: "Date is not",
    dateBefore: "Date is before",
    dateAfter: "Date is after",
    clear: "Clear",
    apply: "Apply",
    matchAll: "Match All",
    matchAny: "Match Any",
    addRule: "Add Rule",
    removeRule: "Remove Rule",
    accept: "Yes",
    reject: "No",
    choose: "Choose",
    upload: "Upload",
    cancel: "Cancel",
    dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    dayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
    monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    today: "Today",
    weekHeader: "Wk",
    firstDayOfWeek: 0,
    dateFormat: "mm/dd/yy",
    weak: "Weak",
    medium: "Medium",
    strong: "Strong",
    passwordPrompt: "Enter a password",
    emptyFilterMessage: "No results found",
    emptyMessage: "No available options",
  },
};

export function getLocale(lang: string): PrimeVueLocaleOptions {
  return primevueLocales[lang];
}
