<script lang="ts">
import find from "lodash-es/find";
import some from "lodash-es/some";
import { useI18n } from "vue-i18n";
import TimelineStage from "./TimelineStage.vue";
import { PROJECT_STAGE, PROJECT_STATUS } from "@/entities/project";

const iconMap = {
  [PROJECT_STAGE.CONSTRUCTION]: "construction_icon",
  [PROJECT_STAGE.MAPPING]: "search_icon",
  [PROJECT_STAGE.SCREENINGS]: "audio_icon",
  [PROJECT_STAGE.INTERVIEWS]: "forum_icon",
  [PROJECT_STAGE.SHORTLIST]: "fact_check_icon",
  [PROJECT_STAGE.UNDER_OFFER]: "handshake_icon",
  [PROJECT_STAGE.PLACEMENT]: "star_icon",
};

export default defineComponent({
  name: "ProjectProgress",
  components: {
    TimelineStage,
  },
  props: {
    id: {
      type: String,
    },
    iconSize: {
      type: [String, Number],
      default: 37,
    },
    cardSize: {
      type: [String, Number],
      default: null,
    },
    floatLabel: {
      type: Boolean,
      default: false,
    },
    stages: {
      type: Object,
    },
    activeStage: {
      required: false,
      type: String,
    },
  },
  emits: ["click:stage"],
  setup(props, { emit }) {
    const { t, tm } = useI18n();
    const showFloatLabel = ref(null);
    const blockedList = [`${PROJECT_STAGE.UNDER_OFFER}`];
    const stagesList = computed(() =>
      Object.entries(props.stages).map(([key, value]) => ({
        name: tm(`projects.generic.stages.enum.${key}`),
        icon: iconMap[key as never],
        status: value.status,
        key,
        blocked: blockedList.includes(key),
        count: value.count,
      })));

    const cancelled = computed(() => {
      const hasCancelledStage = some(stagesList.value, { status: PROJECT_STATUS.CANCELLED });
      return hasCancelledStage ? PROJECT_STATUS.CANCELLED : "";
    });

    const statusToClass = (status: string) => status?.toLowerCase();

    const getStageStatus = (status: string): string => {
      let statusToReturn = status;
      if (cancelled.value && status !== PROJECT_STATUS.DRAFT)
        statusToReturn = cancelled.value;
      return statusToClass(statusToReturn);
    };

    const isStageConcluded = (stage: string) => props.stages[stage].status === PROJECT_STATUS.CONCLUDED;

    const checkStageLiberate = (stage: string) => {
      return props.stages[stage].status !== PROJECT_STATUS.DRAFT;
    };

    const currentStage = ref(props.activeStage);
    watch(
      () => props.activeStage,
      (newValue, oldValue) => {
        currentStage.value = newValue;
      },
    );

    const changeStage = (stage: string) => {
      const { key = undefined } = find(stagesList.value, ["blocked", true]);

      if (key && stage === key)
        return;

      if (checkStageLiberate(stage)) {
        currentStage.value = stage;
        emit("click:stage", stage);
      }
    };
    const goToProjectStage = (key: string) => {
      if (checkStageLiberate(key) && key !== PROJECT_STAGE.UNDER_OFFER)
        return `/projects/${props.id}/${key}/activities`;
      else
        return `/projects/${props.id}/${PROJECT_STAGE.CONSTRUCTION}/activities`;
    };

    return {
      t,
      showFloatLabel,
      stagesList,
      cancelled,
      statusToClass,
      getStageStatus,
      isStageConcluded,
      goToProjectStage,
      PROJECT_STAGE,
      changeStage,
      currentStage,
    };
  },
});
</script>

<template>
  <div class="project-timeline">
    <div v-for="(stage, index) in stagesList" :key="stage.key" class="project-timeline__stage">
      <router-link :to="goToProjectStage(stage.key)" :style="{ textDecoration: 'none' }">
        <TimelineStage
          :key="stage.key"
          :icon="stage.icon"
          :label="stage.count"
          :icon-size="iconSize"
          :card-size="cardSize"
          :blocked="stage.blocked"
          :status="getStageStatus(stage.status)"
          :checked="!index && isStageConcluded(PROJECT_STAGE.CONSTRUCTION)"
          @mouseover="showFloatLabel = index"
          @mouseout="showFloatLabel = null"
          @click="changeStage(stage.key)"
        />
        <span
          class="project-timeline__label"
          :class="[
            {
              ['--float-label']: floatLabel,
              ['--float-label-active']:
                (floatLabel && showFloatLabel === index) || stage.key === currentStage,
            },
            stage.blocked ? 'project-timeline__blocked' : '',
          ]"
        >
          {{ stage.name }}
        </span>
      </router-link>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.project-timeline {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__blocked {
    cursor: not-allowed;
  }

  &__stage {
    position: relative;
    margin-bottom: 1.5rem;
    cursor: pointer;
    font-size: 0.75rem;
    text-align: center;
    text-transform: uppercase;
  }

  &__label {
    &:not(#{&}.--float-label) {
      display: inline-block;
      margin-top: 0.5rem;
    }

    &.--blocked {
      cursor: not-allowed;
    }

    &.--float-label {
      position: absolute;
      right: 0;
      left: 0;
      padding: 0.5rem 0.45rem;
      border-radius: 6px;
      background-color: $white-full;
      box-shadow: 0 2px 4px #00000029;
      opacity: 0;
      transition: transform 100ms ease-in-out, opacity 100ms ease-in-out;

      &-active {
        margin: auto;
        opacity: 1;
        transform: translateY(30%);
      }
    }
  }
}

@media screen and (min-width: 640px) {
  .project-timeline {
    &__stage {
      margin-bottom: 0.5rem;
    }
  }
}
</style>
