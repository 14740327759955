<script lang="ts">
import { defineComponent } from "vue";
import { mdiClose as close } from "@mdi/js";
import { SvgIcon } from "@/components/icon";

export default defineComponent({
  name: "Tag",
  components: {
    SvgIcon,
  },
  props: {
    tag: String,
  },
  emits: ["click:close"],
  setup() {
    return {
      close,
    };
  },
});
</script>

<template>
  <span class="tag">
    {{ tag }}

    <span role="button" @click="$emit('click:close')">
      <SvgIcon type="mdi" :icon="close" width="16px" height="16px" />
    </span>
  </span>
</template>

<style lang="scss" scoped>
    .tag {
      display: flex;
      width: max-content;
      gap: 0.5rem;
      max-width: 100%;
      padding: 0.5rem 0.75rem;
      border-radius: 8px;
      background-color: $white-full;
      box-shadow: 0 1px 3px #0003;
      color: $gray-dark;
      font-size: 0.75rem;
      word-wrap: break-word;

      span[role="button"] {
        &:hover {
          color: lighten($gray-dark, 25%);
        }

        svg {
          vertical-align: text-bottom;
          margin: 0;
        }
      }
    }
</style>
