<script setup lang="ts">
import { SvgIcon } from "@/components/icon";
import { Loading } from "@/components/loading";

const props = withDefaults(
  defineProps<{
    url?: string
    file?: File
    placeholder?: string | null
    multiple?: boolean
    loading?: boolean
    uploadPercentage?: number | null
  }>(),
  {
    placeholder: null,
  },
);

const emit = defineEmits(["update:file", "update:url"]);
const fileInput = ref<HTMLInputElement | null>(null);
const { t } = useI18n();

const active = ref(false);

async function dropFile(e: DragEvent) {
  if (!e?.dataTransfer?.files?.length)
    return;
  const file = e.dataTransfer.files[0];
  const url = URL.createObjectURL(file);
  emit("update:file", file);
  emit("update:url", url);
}

async function selectedFile(e: Event) {
  const target = e.target as HTMLInputElement;
  if (!target.files || !target.files[0])
    return;
  const file = target.files[0];
  const url = URL.createObjectURL(file);
  emit("update:url", url);
  emit("update:file", file);
}
</script>

<template>
  <div :class="{ 'active-dropzone': active }" class="dropzone">
    <label
      class="dropzone-label"
      for="dropzoneFile"
      @dragenter.prevent="active = true"
      @dragleave.prevent="active = false"
      @dragover.prevent
      @drop.prevent="dropFile"
      @mouseenter="active = true"
      @mouseleave="active = false"
    >
      <Loading v-if="loading" :spin-time="2" />
      <SvgIcon
        v-else
        icon="upload_cloud_icon"
        margin="none"
        :color="active ? '#fcaf45' : '#e4e4e4'"
        width="5rem"
        height="5rem"
      />
      <span>
        {{
          uploadPercentage
            ? `${uploadPercentage}%`
            : placeholder
              ? placeholder
              : t("persons.forms.resume.description")
        }}
      </span>
    </label>
    <input
      id="dropzoneFile"
      ref="fileInput"
      type="file"
      accept="application/pdf,
      application/msword,text/csv,
      application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
      application/vnd.ms-excel"
      class="dropzoneFile"
      :multiple="multiple ? true : false"
      @change="selectedFile"
    >
  </div>
</template>

<style scoped lang="scss">
.active-dropzone {
  border-color: $orange-medium;
  background-color: $white-full;
  color: $white-full;

  .dropzone-label {
    border: 5px dashed $orange-medium;
    cursor: pointer;

    span {
      color: $orange-medium;
    }
  }
}

.dropzone {
  display: flex;
  width: 100%;
  height: 12.5rem;

  .dropzone-label {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.25rem;
    border: 5px dashed $gray-x14;

    span {
      color: $gray-x14;
    }

    &:hover {
      border: 5px dashed $orange-medium;

      span {
        color: $orange-medium;
      }
    }
  }

  input {
    display: none;
  }
}
</style>
