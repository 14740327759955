import { BUSINESS_PROFICIENCY, DEGREE_LEVEL, PROFICIENCY } from "@/entities/common";

export const languages = {
  language: "Language",
  idealProfileProficiency: {
    [PROFICIENCY.BASIC]: "Basic",
    [PROFICIENCY.BASIC_INT]: "Basic to intermediate",
    [PROFICIENCY.INTERMEDIATE]: "Intermediate",
    [PROFICIENCY.ADVANCED]: "Advanced",
    [PROFICIENCY.FLUENT]: "Fluent or native",
  },
  proficiency: {
    [PROFICIENCY.BASIC]: "Basic",
    [PROFICIENCY.BASIC_INT]: "Basic to intermediate",
    [PROFICIENCY.INTERMEDIATE]: "Intermediate",
    [PROFICIENCY.ADVANCED]: "Advanced",
    [PROFICIENCY.FLUENT]: "Fluent or native",
    [BUSINESS_PROFICIENCY.ELEMENTARY]: "Basic for conversation",
    [BUSINESS_PROFICIENCY.LIMITED_WORKING]: "Intermediate, limited for business",
    [BUSINESS_PROFICIENCY.PROFESSIONAL_WORKING]: "Full business proficiency",
    [BUSINESS_PROFICIENCY.NATIVE]: "Native or bilingual proficiency",
  },
  degree: {
    [DEGREE_LEVEL.BACHELOR]: "Bachelor",
    [DEGREE_LEVEL.POST_BACHELOR]: "Post Bachelor",
    [DEGREE_LEVEL.MBA]: "MBA",
    [DEGREE_LEVEL.MASTER]: "Master",
    [DEGREE_LEVEL.DOCTORATE]: "Doctorate",
    [DEGREE_LEVEL.POST_DOCTORATE]: "Post doctorate",
    [DEGREE_LEVEL.EXTRA_CURRICULAR_COURSE]: "Extra curricular course",
  },
  name: {
    afr: { code: "afr", label: "Afrikaans" },
    aii: { code: "aii", label: "Assyrian Neo-Aramaic" },
    ain: { code: "ain", label: "Ainu" },
    akk: { code: "akk", label: "Akkadian" },
    alb: { code: "alb", label: "Albanian" },
    amh: { code: "amh", label: "Amharic" },
    ara: { code: "ara", label: "Arabic" },
    asm: { code: "asm", label: "Assamese" },
    ast: { code: "ast", label: "Leonese" },
    aym: { code: "aym", label: "Aymara" },
    aze: { code: "aze", label: "Azerbaijani" },
    baq: { code: "baq", label: "Basque languages" },
    bel: { code: "bel", label: "Belarusian" },
    ben: { code: "ben", label: "Bengali" },
    ber: { code: "ber", label: "Berber languages" },
    bos: { code: "bos", label: "Bosnian" },
    bre: { code: "bre", label: "Breton" },
    bul: { code: "bul", label: "Bulgarian" },
    cat: { code: "cat", label: "Valencian" },
    chr: { code: "chr", label: "Cherokee" },
    chu: { code: "chu", label: "Old Slavonic" },
    cmn: { code: "cmn", label: "Chines - Mandarin" },
    cor: { code: "cor", label: "Cornish" },
    cos: { code: "cos", label: "Corsican" },
    cze: { code: "cze", label: "Czech" },
    dak: { code: "dak", label: "Dakota" },
    dan: { code: "dan", label: "Danish" },
    deu: { code: "deu", label: "German" },
    egy: { code: "egy", label: "Egyptian (Ancient)" },
    eng: { code: "eng", label: "English" },
    epo: { code: "epo", label: "Esperanto" },
    est: { code: "est", label: "Estonian" },
    eus: { code: "eus", label: "Basque" },
    fao: { code: "fao", label: "Faroese" },
    fas: { code: "fas", label: "Persian" },
    fij: { code: "fij", label: "Fijian" },
    fil: { code: "fil", label: "Pilipino" },
    fin: { code: "fin", label: "Finnish" },
    fre: { code: "fre", label: "French" },
    frs: { code: "frs", label: "Eastern Frisian" },
    fry: { code: "fry", label: "Western Frisian" },
    fur: { code: "fur", label: "Friulian" },
    gla: { code: "gla", label: "Scottish Gaelic" },
    gle: { code: "gle", label: "Irish" },
    glg: { code: "glg", label: "Galician" },
    glv: { code: "glv", label: "Manx" },
    grc: { code: "grc", label: "Greek, Ancient (to 1453)" },
    gre: { code: "gre", label: "Greek" },
    grn: { code: "grn", label: "Guarani" },
    guj: { code: "guj", label: "Gujarati" },
    hat: { code: "hat", label: "Haitian Creole" },
    hau: { code: "hau", label: "Hausa" },
    haw: { code: "haw", label: "Hawaiian" },
    heb: { code: "heb", label: "Hebrew" },
    hin: { code: "hin", label: "Hindi" },
    hmn: { code: "hmn", label: "Mong" },
    hrv: { code: "hrv", label: "Croatian" },
    hun: { code: "hun", label: "Hungarian" },
    hye: { code: "hye", label: "Armenian" },
    ido: { code: "ido", label: "Ido" },
    ind: { code: "ind", label: "Indonesian" },
    inh: { code: "inh", label: "Ingush" },
    isl: { code: "isl", label: "Icelandic" },
    ita: { code: "ita", label: "Italian" },
    jpn: { code: "jpn", label: "Japanese" },
    kal: { code: "kal", label: "Kalaallisut" },
    kan: { code: "kan", label: "Kannada" },
    kas: { code: "kas", label: "Kashmiri" },
    kat: { code: "kat", label: "Georgian" },
    kaz: { code: "kaz", label: "Kazakh" },
    khm: { code: "khm", label: "Central Khmer" },
    kir: { code: "kir", label: "Kyrgyz" },
    kok: { code: "kok", label: "Konkani" },
    kor: { code: "kor", label: "Korean" },
    kur: { code: "kur", label: "Kurdish" },
    lad: { code: "lad", label: "Ladino" },
    lao: { code: "lao", label: "Lao" },
    lat: { code: "lat", label: "Latin" },
    lav: { code: "lav", label: "Latvian" },
    lit: { code: "lit", label: "Lithuanian" },
    ltz: { code: "ltz", label: "Luxembourgish" },
    mal: { code: "mal", label: "Malayalam" },
    mar: { code: "mar", label: "Marathi" },
    mkd: { code: "mkd", label: "Macedonian" },
    mni: { code: "mni", label: "Manipuri" },
    mon: { code: "mon", label: "Mongolian" },
    mri: { code: "mri", label: "Maori" },
    msa: { code: "msa", label: "Malay" },
    mya: { code: "mya", label: "Burmese" },
    nan: { code: "nan", label: "Chinese - Taiwan" },
    nep: { code: "nep", label: "Nepali" },
    nld: { code: "nld", label: "Dutch" },
    nor: { code: "nor", label: "Norwegian" },
    nov: { code: "nov", label: "Novial" },
    nya: { code: "nya", label: "Nyanja" },
    oci: { code: "oci", label: "Occitan (post 1500)" },
    oji: { code: "oji", label: "Ojibwa" },
    ori: { code: "ori", label: "Oriya" },
    pan: { code: "pan", label: "Punjabi" },
    pol: { code: "pol", label: "Polish" },
    por: { code: "por", label: "Portuguese" },
    pra: { code: "pra", label: "Prakrit languages" },
    ptb: { code: "ptb", label: "Brazilian Portuguese" },
    pus: { code: "pus", label: "Pushto" },
    que: { code: "que", label: "Quechua" },
    roh: { code: "roh", label: "Romansh" },
    rom: { code: "rom", label: "Romany" },
    ron: { code: "ron", label: "Romanian" },
    rus: { code: "rus", label: "Russian" },
    san: { code: "san", label: "Sanskrit" },
    sce: { code: "sce", label: "Santa - Dongxiang" },
    scn: { code: "scn", label: "Sicilian" },
    sco: { code: "sco", label: "Scots" },
    sin: { code: "sin", label: "Sinhalese" },
    slk: { code: "slk", label: "Slovak" },
    slv: { code: "slv", label: "Slovenian" },
    smi: { code: "smi", label: "Sami languages" },
    smo: { code: "smo", label: "Samoan" },
    snd: { code: "snd", label: "Sindhi" },
    spa: { code: "spa", label: "Spanish" },
    srp: { code: "srp", label: "Serbian" },
    swa: { code: "swa", label: "Swahili" },
    swe: { code: "swe", label: "Swedish" },
    tam: { code: "tam", label: "Tamil" },
    tel: { code: "tel", label: "Telugu" },
    tha: { code: "tha", label: "Thai" },
    tib: { code: "tib", label: "Tibetan" },
    ton: { code: "ton", label: "Tonga (Tonga Islands)" },
    tpi: { code: "tpi", label: "Tok Pisin" },
    tur: { code: "tur", label: "Turkish" },
    ukr: { code: "ukr", label: "Ukrainian" },
    und: { code: "und", label: "Undetermined" },
    urd: { code: "urd", label: "Urdu" },
    uzb: { code: "uzb", label: "Uzbek" },
    vie: { code: "vie", label: "Vietnamese" },
    wel: { code: "wel", label: "Welsh" },
    wuu: { code: "wuu", label: "Wu Chinese" },
    xho: { code: "xho", label: "Xhosa" },
    yid: { code: "yid", label: "Yiddish" },
    yor: { code: "yor", label: "Yoruba" },
    yue: { code: "yue", label: "Chinese - Cantonese" },
    zho: { code: "zho", label: "Chinese" },
    zul: { code: "zul", label: "Zulu" },
  },
};
