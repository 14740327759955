import { BUSINESS_PROFICIENCY, DEGREE_LEVEL, PROFICIENCY } from "@/entities/common";

export const languages = {
  language: "Idioma",
  idealProfileProficiency: {
    [PROFICIENCY.BASIC]: "Nível básico",
    [PROFICIENCY.BASIC_INT]: "Nível básico a intermediário",
    [PROFICIENCY.INTERMEDIATE]: "Nível intermediário",
    [PROFICIENCY.ADVANCED]: "Nível avançado",
    [PROFICIENCY.FLUENT]: "Fluente ou nativo",
  },
  proficiency: {
    [PROFICIENCY.BASIC]: "Nível básico",
    [PROFICIENCY.BASIC_INT]: "Nível básico a intermediário",
    [PROFICIENCY.INTERMEDIATE]: "Nível intermediário",
    [PROFICIENCY.ADVANCED]: "Nível avançado",
    [PROFICIENCY.FLUENT]: "Fluente ou nativo",
    [BUSINESS_PROFICIENCY.ELEMENTARY]: "Básico para conversação",
    [BUSINESS_PROFICIENCY.LIMITED_WORKING]: "Intermediário, limitado para negócio",
    [BUSINESS_PROFICIENCY.PROFESSIONAL_WORKING]: "Domínio para negócio",
    [BUSINESS_PROFICIENCY.NATIVE]: "Proficiência nativa ou bilíngue",
  },
  degree: {
    [DEGREE_LEVEL.BACHELOR]: "Graduação",
    [DEGREE_LEVEL.POST_BACHELOR]: "Pós-Graduação",
    [DEGREE_LEVEL.MBA]: "MBA",
    [DEGREE_LEVEL.MASTER]: "Mestrado",
    [DEGREE_LEVEL.DOCTORATE]: "Doutorado",
    [DEGREE_LEVEL.POST_DOCTORATE]: "Pós-Doutorado",
    [DEGREE_LEVEL.EXTRA_CURRICULAR_COURSE]: "Curso Extracurricular",
  },
  name: {
    akk: { code: "akk", label: "Acadiano" },
    pus: { code: "pus", label: "Afegão" },
    afr: { code: "afr", label: "Africanse" },
    aym: { code: "aym", label: "Aimará" },
    ain: { code: "ain", label: "Ainu" },
    alb: { code: "alb", label: "Albanês" },
    deu: { code: "deu", label: "Alemão" },
    amh: { code: "amh", label: "Amárico" },
    ara: { code: "ara", label: "Árabe" },
    hye: { code: "hye", label: "Armênio" },
    asm: { code: "asm", label: "Assamês" },
    aii: { code: "aii", label: "Assuri" },
    ast: { code: "ast", label: "Asturiano" },
    aze: { code: "aze", label: "Azeri" },
    frs: { code: "frs", label: "Baixo Saxão" },
    eus: { code: "eus", label: "Basca" },
    baq: { code: "baq", label: "Basque" },
    ben: { code: "ben", label: "Bengali" },
    ber: { code: "ber", label: "Berbere" },
    bel: { code: "bel", label: "Bielorrusso" },
    mya: { code: "mya", label: "Birmanês" },
    bos: { code: "bos", label: "Bósnio" },
    bre: { code: "bre", label: "Bretão" },
    bul: { code: "bul", label: "Búlgaro" },
    khm: { code: "khm", label: "Cambojano" },
    cat: { code: "cat", label: "Catalão" },
    kas: { code: "kas", label: "Caxemiriano" },
    kaz: { code: "kaz", label: "Cazaque" },
    chr: { code: "chr", label: "Cheroqui" },
    zho: { code: "zho", label: "Chinês" },
    wuu: { code: "wuu", label: "Chinês Shangai" },
    yue: { code: "yue", label: "Chinês - Cantonese" },
    cmn: { code: "cmn", label: "Chinês - Mandarin" },
    nan: { code: "nan", label: "Chinês - Taiwan" },
    sin: { code: "sin", label: "Cingalês" },
    kok: { code: "kok", label: "Concani" },
    gom: { code: "gom", label: "Concani" },
    kor: { code: "kor", label: "Coreano" },
    cor: { code: "cor", label: "Córnico" },
    cos: { code: "cos", label: "Corso" },
    hrv: { code: "hrv", label: "Croata" },
    kur: { code: "kur", label: "Curdo" },
    dak: { code: "dak", label: "Dakota" },
    dan: { code: "dan", label: "Dinarmaquês" },
    egy: { code: "egy", label: "Egípcio" },
    sco: { code: "sco", label: "Escocês" },
    gla: { code: "gla", label: "Escocês Gaelico" },
    chu: { code: "chu", label: "Eslavo Eclesiástico" },
    slk: { code: "slk", label: "Eslovaco" },
    slv: { code: "slv", label: "Eslovêno" },
    spa: { code: "spa", label: "Espanhol" },
    epo: { code: "epo", label: "Esperanto" },
    est: { code: "est", label: "Estoniano" },
    fao: { code: "fao", label: "Feroês" },
    fij: { code: "fij", label: "Fijiano" },
    fil: { code: "fil", label: "Filipino" },
    fin: { code: "fin", label: "Finlanês" },
    fre: { code: "fre", label: "Francês" },
    fry: { code: "fry", label: "Frísio" },
    fur: { code: "fur", label: "Friulano" },
    gle: { code: "gle", label: "Irlandês" },
    glg: { code: "glg", label: "Galego" },
    wel: { code: "wel", label: "Galês" },
    kat: { code: "kat", label: "Georgiano" },
    gre: { code: "gre", label: "Grego" },
    grc: { code: "grc", label: "Grego Antigo" },
    kal: { code: "kal", label: "Groenlandês" },
    grn: { code: "grn", label: "Guarani" },
    guj: { code: "guj", label: "Gujarati" },
    hat: { code: "hat", label: "Haitiano" },
    hau: { code: "hau", label: "Hauçá" },
    haw: { code: "haw", label: "Havaiano" },
    heb: { code: "heb", label: "Hebraico" },
    hin: { code: "hin", label: "Hindi" },
    hmn: { code: "hmn", label: "Hmong" },
    nld: { code: "nld", label: "Holandês" },
    hun: { code: "hun", label: "Húngaro" },
    yid: { code: "yid", label: "Ídiche" },
    ido: { code: "ido", label: "Ido" },
    ind: { code: "ind", label: "Indonésio" },
    eng: { code: "eng", label: "Inglês" },
    inh: { code: "inh", label: "Ingush" },
    yor: { code: "yor", label: "Iorubá" },
    isl: { code: "isl", label: "Islandês" },
    ita: { code: "ita", label: "Italiano" },
    jpn: { code: "jpn", label: "Japonês" },
    kan: { code: "kan", label: "Kanarês" },
    lad: { code: "lad", label: "Ladino" },
    lao: { code: "lao", label: "Laociano" },
    lat: { code: "lat", label: "Latim" },
    lav: { code: "lav", label: "Letão" },
    lit: { code: "lit", label: "Lituano" },
    ltz: { code: "ltz", label: "Luxemburguês" },
    mkd: { code: "mkd", label: "Macedônio" },
    mal: { code: "mal", label: "Malaiala" },
    msa: { code: "msa", label: "Malaio" },
    glv: { code: "glv", label: "Manês" },
    mni: { code: "mni", label: "Manipuri" },
    mri: { code: "mri", label: "Maori" },
    mar: { code: "mar", label: "Marati" },
    mon: { code: "mon", label: "Mongol" },
    nep: { code: "nep", label: "Nepalês" },
    nya: { code: "nya", label: "Nianja" },
    nor: { code: "nor", label: "Norueguês" },
    nov: { code: "nov", label: "Novial" },
    oci: { code: "oci", label: "Occitânico" },
    oji: { code: "oji", label: "Ojibua" },
    ori: { code: "ori", label: "Oriá" },
    und: { code: "und", label: "Outros" },
    pan: { code: "pan", label: "Panjabi" },
    fas: { code: "fas", label: "Persa" },
    pol: { code: "pol", label: "Polonês" },
    por: { code: "por", label: "Português" },
    ptb: { code: "ptb", label: "Português Brasileiro" },
    pra: { code: "pra", label: "Prakrit" },
    que: { code: "que", label: "Quíchua" },
    kir: { code: "kir", label: "Quirguistanês" },
    roh: { code: "roh", label: "Reto Romano" },
    rom: { code: "rom", label: "Romani" },
    ron: { code: "ron", label: "Romeno" },
    rus: { code: "rus", label: "Russo" },
    smi: { code: "smi", label: "Sámi" },
    smo: { code: "smo", label: "Samoano" },
    san: { code: "san", label: "Sânscrito" },
    sce: { code: "sce", label: "Santa" },
    srp: { code: "srp", label: "Sérvio" },
    scn: { code: "scn", label: "Siciliano" },
    snd: { code: "snd", label: "Sindi" },
    swa: { code: "swa", label: "Suaíli" },
    swe: { code: "swe", label: "Sueco" },
    tha: { code: "tha", label: "Tailandês" },
    tam: { code: "tam", label: "Tâmil" },
    cze: { code: "cze", label: "Tcheco" },
    tel: { code: "tel", label: "Telugu" },
    tib: { code: "tib", label: "Tibetano" },
    tpi: { code: "tpi", label: "Tok Pisin" },
    ton: { code: "ton", label: "Tonganês" },
    tur: { code: "tur", label: "Turco" },
    ukr: { code: "ukr", label: "Ucraniano" },
    urd: { code: "urd", label: "Urdu" },
    uzb: { code: "uzb", label: "Usbeque" },
    vie: { code: "vie", label: "Vietinamita" },
    xho: { code: "xho", label: "Xosa" },
    zul: { code: "zul", label: "Zulu" },
  },
};
