<script setup lang="ts">
import { useI18n } from "vue-i18n";
import ExportPage from "./ExportPage.vue";

interface pageSchema {
  id?: string
  page?: string
  name?: string
}

const props = defineProps<{
  companyName: string | null
  isExport: boolean
  listHiddePages: string[]
}>();

enum ConstructionSumaryEnum {
  aboutCompany = "COMPANY",
  governance = "GOVERNANCE",
  strategy = "STRATEGY",
  executionConditions = "EXECUTION_CONDITIONS",
  companyProfile = "COMPANY_PROFILE",
  idealProfile = "IDEAL_PROFILE_TAGS",
  attributes = "ATTRIBUTES",
  culturalMatch = "CULTURAL_MATCH",
  motivation = "MOTIVATION",
  searchField = "SEARCH_FIELD",
  schedule = "TIMELINE",
}
const { t, tm } = useI18n();

const summaryItems = tm("exportPDF.summaryEnum");

const summary = computed(() => {
  const formatted: pageSchema[] = [];
  Object.entries(ConstructionSumaryEnum).forEach(([key, value]) => {
    const showSummary = !props.listHiddePages.includes(value);
    const showIdealProfile = (props.listHiddePages.includes("IDEAL_PROFILE_TAGS")
      && !props.listHiddePages.includes("IDEAL_PROFILE")
    )
    || (!props.listHiddePages.includes("IDEAL_PROFILE_TAGS")
    && props.listHiddePages.includes("IDEAL_PROFILE"));

    if (showSummary) {
      formatted.push({
        id: value,
        page: value,
        name: summaryItems[key],
      });
    }

    else if (showIdealProfile && (value === "IDEAL_PROFILE_TAGS")) {
      formatted.push({
        id: value,
        page: value,
        name: summaryItems[key],
      });
    }
  });
  return formatted;
});
</script>

<template>
  <ExportPage :has-avatar="false" :company_name="props.company_name" :page-name="t('exportPDF.summary')" v-bind="$attrs">
    <template #title />
    <div class="summary">
      <div v-for="(item, index) in summary" :key="index" class="summary-item">
        <a :href="`#${item.id}`">
          {{ index + 1 }}. {{ item?.name?.toUpperCase() }}
        </a>
      </div>
    </div>
  </ExportPage>
</template>

<style scoped lang="scss">
.summary {
  display: grid;
  // margin-top: 4.5rem;
  margin-right: 6rem;
  column-gap: 15%;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(6, 1fr);
  row-gap: 1rem;
}

.summary-item {
  padding-block: 1.5rem;

  &:nth-child(6),
  &:nth-child(12) {
    padding-bottom: 0;
    border-bottom: none;
  }

  a {
    font-size: 1.25rem;
    text-decoration: none;
  }
}
</style>
