<script lang="ts">
import { computed, defineComponent, toRefs } from "vue";
import { SvgIcon } from "@/components/icon";

export default defineComponent({
  name: "Loading",
  components: {
    SvgIcon,
  },
  props: {
    isDisabled: Boolean,
    spinTime: Number,
    warning: Boolean,
    violet: Boolean,
    size: {
      type: [String, Number],
      default: 33,
    },
  },

  setup(props) {
    const { spinTime } = toRefs(props);

    const iconStyle = computed(() => ({ "--spin-time": `${spinTime.value || 2}${"s"}` }));

    return { iconStyle };
  },
});
</script>

<template>
  <SvgIcon
    v-if="!isDisabled"
    :style="iconStyle"
    icon="loading_icon"
    class="loading"
    :class="{
      warning,
      violet,
    }"
    :width="size"
    :height="size"
    margin="none"
  />
</template>

<style lang="scss" scoped>
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

.loading {
  animation: spin var(--spin-time) infinite linear;
  color: $secondary;
  stroke: $secondary;

  &.warning {
    color: $yellow;
    stroke: $yellow;
  }

  &.violet {
    color: $red-violet-medium;
    stroke: $red-violet-medium;
  }
}
</style>
