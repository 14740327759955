export const languages = [
  "akk",
  "pus",
  "afr",
  "aym",
  "ain",
  "alb",
  "deu",
  "amh",
  "ara",
  "hye",
  "asm",
  "aii",
  "ast",
  "aze",
  "frs",
  "eus",
  "baq",
  "ben",
  "ber",
  "bel",
  "mya",
  "bos",
  "bre",
  "bul",
  "khm",
  "cat",
  "kas",
  "kaz",
  "chr",
  "zho",
  "wuu",
  "yue",
  "cmn",
  "nan",
  "sin",
  "kok",
  "gom",
  "kor",
  "cor",
  "cos",
  "hrv",
  "kur",
  "dak",
  "dan",
  "egy",
  "sco",
  "gla",
  "chu",
  "slk",
  "slv",
  "spa",
  "epo",
  "est",
  "fao",
  "fij",
  "fil",
  "fin",
  "fre",
  "fry",
  "fur",
  "gle",
  "glg",
  "wel",
  "kat",
  "gre",
  "grc",
  "kal",
  "grn",
  "guj",
  "hat",
  "hau",
  "haw",
  "heb",
  "hin",
  "hmn",
  "nld",
  "hun",
  "yid",
  "ido",
  "ind",
  "eng",
  "inh",
  "yor",
  "isl",
  "ita",
  "jpn",
  "kan",
  "lad",
  "lao",
  "lat",
  "lav",
  "lit",
  "ltz",
  "mkd",
  "mal",
  "msa",
  "glv",
  "mni",
  "mri",
  "mar",
  "mon",
  "nep",
  "nya",
  "nor",
  "nov",
  "oci",
  "oji",
  "ori",
  "und",
  "pan",
  "fas",
  "pol",
  "por",
  "ptb",
  "pra",
  "que",
  "kir",
  "roh",
  "rom",
  "ron",
  "rus",
  "smi",
  "smo",
  "san",
  "sce",
  "srp",
  "scn",
  "snd",
  "swa",
  "swe",
  "tha",
  "tam",
  "cze",
  "tel",
  "tib",
  "tpi",
  "ton",
  "tur",
  "ukr",
  "urd",
  "uzb",
  "vie",
  "xho",
  "zul",
];
