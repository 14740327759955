<script setup lang="ts">
import { debounce } from "lodash-es";
import * as slider from "@zag-js/slider";
import { normalizeProps, useMachine } from "@zag-js/vue";
import bson from "bson-objectid";

const props = defineProps<{
  modelValue: number | undefined | null
}>();
const emit = defineEmits(["update:modelValue"]);

const [state, send] = useMachine(slider.machine({
  id: bson().toHexString(),
  min: 0,
  max: 100,
  step: 5,
  value: props.modelValue ? props.modelValue : 0,
  onChange,
}));

const api = computed(() => slider.connect(state.value, send, normalizeProps));

const currentValue = ref(props.modelValue);
function onChange(v: { value: number }) {
  currentValue.value = v.value;
}
function handleChange() {
  emit("update:modelValue", currentValue.value);
}

const debounceUpdate = debounce(handleChange, 350);
</script>

<template>
  <div class="slider-evaluation-wrapper">
    <div v-bind="api.controlProps">
      <div v-bind="api.trackProps">
        <div v-bind="api.rangeProps" :style="`width: ${(currentValue === 100) ? '100%' : `calc(${currentValue}% + ${currentValue === 0 ? '0px' : '0.5rem'})`}`" />
      </div>
      <div v-bind="api.thumbProps" :style="`left: ${(currentValue === 100) ? `calc(100% - 1rem)` : `${currentValue}%`};`">
        <input v-bind="api.hiddenInputProps" @input="debounceUpdate">
      </div>
    </div>
    <div v-bind="api.markerGroupProps" class="custom-marker">
      <span
        v-for="i in state.context.max"
        :key="i"
        :style="`visibility: ${currentValue === i ? 'visible' : 'hidden'}`"
        :class="[i >= 95 ? 'left-max' : '']"
        v-bind="api.getMarkerProps({ value: i })"
      >
        <div class="pointer-container">
          <span class="pointer-text">{{ i }}%</span>
          <span class="pointer" />
        </div>
      </span>
    </div>
    <div v-bind="api.markerGroupProps">
      <span v-for="value in [25, 50, 75, 95]" :key="value" v-bind="api.getMarkerProps({ value })">
        {{ value }}%
      </span>
    </div>
  </div>
</template>

<style scoped lang="scss">
.slider-evaluation-wrapper {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  margin-right: 0.25rem;
}

// TODO: add initial thumb style...white is not good
[data-part='thumb'] {
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 999px;
  border: 1px solid $gray-dark;
  background: #fff;
  cursor: pointer;
  z-index: 3;
}

[data-part='thumb']:hover,
[data-part='thumb']:focus-visible,
[data-part='thumb']:active {
  background: #fff;
  outline: 1px solid #2d2d2d;
  box-shadow: inset 0px 3px 6px #0000000B, 0px 3px 6px #00000029;
}

[data-part='range'] {
  background: #FCAF45;
  height: 1rem;
  border-radius: 1rem;
}

[data-part='track'] {
  background: #F5F5F5;
  height: 1rem;
  flex: 1;
  border-radius: 1rem;
  cursor: pointer;
}

[data-part='control'] {
  position: relative;
  align-items: center;
  display: flex;
}

[data-part='marker-group'] span {
  display: block;
}

[data-part='marker'] {
  color: #A8A8A8;
  font-size: 0.75rem;
  height: 0.75rem;
  margin-top: 0.25rem;
}

.custom-marker[data-part='marker-group'] {
  height: 3.5rem;
  margin-top: -3.5rem;
  z-index: 2;

  [data-part=marker] {
    width: 2.5rem;
    background: #FCAF45;
    height: 1.5rem;
    margin-top: 0;
    margin-left: -0.625rem;
    display: flex;
    justify-content: center;
    border-radius: 6px;
    color: white;
    align-items: center;

  }
}

// TODO: fix right margin...the tracker is 0.5rem to left
.custom-marker[data-part='marker-group'] [data-part=marker].left-max {
  // ignore important, due to lib props
  left: 95% !important;
}

.pointer-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.pointer {
  width: 0px;
  height: 0px;
  border-top: 0.5rem solid transparent;
  border-bottom: 0.5rem solid #FCAF45;
  border-left: 0.5rem solid #FCAF45;
  border-right: 0.5rem solid transparent;
  transform: rotate(-45deg);
  bottom: 0.75rem;
  position: relative;
  left: 0.75rem;
}

[data-part='marker-group'] span.pointer-text {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  color: #fff;
  font-weight: 600;
}
</style>
