<script lang="ts">
import type {
  PropType,
  Ref,
} from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import get from "lodash-es/get";
import moment from "moment";
import ProjectTimeline from "./Timeline.vue";
import { Tooltip } from "@/components/tooltip";
import type { IList } from "@/components/avatar-list";
import { AvatarList } from "@/components/avatar-list";
import { Button2 } from "@/components/button";
import { Card } from "@/components/card";
import { SvgIcon } from "@/components/icon";
import { CurrencyEnum } from "@/schemas/enums/currency";
import type { PositionDetails } from "@/schemas/project";
import type { PersonInAggregation } from "@/schemas/common";
import type { Project } from "@/types/project";
import { completeDate } from "@/utils/dates";
import { formatCurrency } from "@/utils/numbers";

export default defineComponent({
  name: "ProjectCard",
  components: {
    AvatarList,
    Button: Button2,
    Card,
    SvgIcon,
    ProjectTimeline,
    Tooltip,
  },
  props: {
    project: {
      type: Object as PropType<Project>,
      default: () => ({}),
    },
    viewType: {
      type: String,
      default: "list",
      validator: (value: string) => ["list", "card"].includes(value),
    },
  },
  setup(props) {
    const { t, locale } = useI18n();
    const router = useRouter();
    const project = toRef(props, "project") as Ref<Project>;
    const positionDetails = computed<Partial<PositionDetails>>(
      () => project.value?.position_details || {},
    );
    const projectType = computed(() => {
      const project_type = get(project.value, "project_type", "");
      return project_type.charAt(0);
    });
    const projectSalary = computed(() => {
      const { salary_range } = positionDetails.value;
      const { min_value, max_value, currency } = salary_range || {};

      const defaultCurrency = CurrencyEnum.BRL;
      const localeFormat = formatCurrency(currency || defaultCurrency, locale.value);

      return {
        min: min_value ? localeFormat(min_value) : "",
        max: max_value ? localeFormat(max_value) : "",
      };
    });

    const DEFAULT_MAX_COUNT = 2;

    const LIMIT_MAX_COUNT = 3;

    const maxCounter = computed(() => {
      return get(project.value, "participants", [])?.length > LIMIT_MAX_COUNT
        ? DEFAULT_MAX_COUNT
        : get(project.value, "participants", [])?.length;
    });

    const projectParticipants = computed<IList[]>(() => {
      const participants = (project?.value?.participants || []) as PersonInAggregation[];
      const participantsMap = ({
        _id,
        avatar_url,
        first_name,
        last_name,
      }: PersonInAggregation) => ({
        id: _id ?? first_name,
        src: avatar_url,
        alt: (first_name && first_name.concat(` ${last_name}`)) || "",
      });

      return participants?.map(participantsMap);
    });

    const lastUpdated = computed(() => {
      const { updated_at } = project.value;
      if (updated_at)
        return completeDate(updated_at);
      return "";
    });

    const onClickDetails = inject("onClickDetails");
    const onClickEdit = inject("onClickEdit");

    const startDate = ref("");
    const endDate = ref("");
    const diffDate = ref("");

    const goToStage = (stage: string) => {
      router.push({ path: `/projects/${project.value._id}/${stage}/activities` });
    };

    const getDate = (date: string | null) => {
      try {
        if (date === null)
          return "";

        return moment(date).format("DD/MM/YYYY");
      }
      catch (_) {
        return "";
      }
    };

    const getDateDif = (init: string, end: string): string => {
      if (!init || init === "")
        return "";

      const formatInit = moment(init);
      let formatEnd = moment();
      if (end && end !== "")
        formatEnd = moment(end);

      const allDays = formatEnd.diff(formatInit, "days");

      const year = Math.floor(allDays / 365);

      const remainingDays = allDays - year * 365;

      const month = Math.floor(remainingDays / 30);

      const day = remainingDays - month * 30;

      const titleYear = year > 1 ? t("date.yearPlural") : t("date.yearSingular");
      const titleMonth = month > 1 ? "m" : "m";
      const titleDay = day > 1 ? t("date.dayPlural") : t("date.daySingular");

      if (year > 0 && month > 0)
        return `${year} ${titleYear} ${month}${titleMonth}`;
      else if (year > 0 && month === 0)
        return `${year} ${titleYear}`;
      else if (year === 0 && month > 0)
        return `${month}${titleMonth} ${day} ${titleDay}`;
      else if (year === 0 && month === 0)
        return `${day} ${titleDay}`;
      else return "";
    };
    const getKeyByValue = (obj: Record<string, string>, value: string): string | undefined => {
      return Object.entries(obj).find(([_, val]) => val === value)?.[0];
    };
    onMounted(() => {
      startDate.value = getDate(project.value.started_at || project.value.inserted_at);
      endDate.value = getDate(project.value.finished_at);
      if (endDate.value !== "") {
        diffDate.value = getDateDif(
          project.value.started_at || project.value.inserted_at,
          project.value.finished_at,
        );
      }
      else {
        diffDate.value = getDateDif(
          project.value.started_at || project.value.inserted_at,
          moment().toISOString(),
        );
      }
    });

    return {
      t,
      positionDetails,
      projectType,
      projectSalary,
      projectParticipants,
      lastUpdated,
      onClickDetails,
      onClickEdit,
      goToStage,
      startDate,
      endDate,
      diffDate,
      maxCounter,
      getKeyByValue,
    };
  },
});
</script>

<template>
  <Card class="project-card">
    <div class="project-card__header">
      <div>
        <span class="project-card__badge">{{ projectType }}</span>
        <h2>{{ project.project_name }}</h2>
        <span class="project-card__count">({{ project.serial }})</span>
      </div>

      <div>
        <span v-show="lastUpdated" class="project-card__last-update">
          <SvgIcon v-if="project.status === 'CONCLUDED'" icon="star_yellow_icon" width="12" height="12" />
          <SvgIcon v-else icon="refresh_icon" width="12" height="12" />
          {{ lastUpdated }}
        </span>
        <Tooltip
          :tooltip-text="t('projects.generic.confidential')"
          tooltip-width="max-content"
          x-align="center"
          y-align="top"
        >
          <SvgIcon
            v-show="project.confidential_placement"
            icon="padlock_icon"
            width="40"
            height="40"
            margin="none"
          />
        </Tooltip>
        <router-link :to="`/projects/${project._id}/construction/activities`">
          <Button variation="secondary">
            {{ t("projects.generic.details") }}
          </Button>
        </router-link>
      </div>
    </div>

    <div class="project-card__content">
      <div v-if="project.corporation">
        <span>
          <strong>{{ t("projects.generic.company") }}:</strong>
          {{ project.corporation?.corporation_name }}
        </span>
      </div>
      <div class="double-coll">
        <span v-if="project.status">
          <strong>{{ t("projects.generic.status") }}:</strong>
          {{ t(`projects.filters.projectStatus.enum.${project.status}`) }}
        </span>
        <span v-if="project?.placement_details?.candidate?.person_id">
          <strong>{{ t("projects.generic.placement") }}:&nbsp;</strong>
          <span>
            {{ project?.placement_details?.candidate?.person_data?.first_name }}
            {{ project?.placement_details?.candidate?.person_data?.last_name }}
          </span>
        </span>
      </div>
      <div class="project-card__progress">
        <ProjectTimeline
          :id="project._id"
          :stages="project.stages"
          :active-stage="getKeyByValue(project?.stages, 'ACTIVE')?.toLocaleUpperCase()"
          @click:stage="goToStage($event)"
        />
      </div>
    </div>

    <div class="project-card__footer">
      <div>
        <strong>
          {{ t("projects.generic.salary") }}:
        </strong>
        {{ projectSalary?.min }}
        <span v-if="projectSalary?.max">~</span>
        <span v-else>Não informado</span>
        {{ projectSalary?.max }}
      </div>
      <div class="project-card__footer__duration">
        <div>
          <span class="label">{{ t('text.start') }}:</span>
          <span class="value">{{ startDate }}</span>
          <span v-if="endDate === '' && !diffDate" class="diff">({{ diffDate }})</span>
        </div>
        <div v-if="endDate !== ''">
          <span class="label">{{ t('text.end') }}:</span>
          <span class="value">{{ endDate }}</span>
          <span class="diff">({{ diffDate }})</span>
        </div>
      </div>
      <AvatarList :list="projectParticipants" :max-counter="maxCounter" avatar-size="1.875rem" counter-size="1.689rem" />
    </div>
  </Card>
</template>

<style lang="scss" scoped>
.project-card {
  margin-bottom: 1rem;

  &__header,
  &__header>div,
  &__footer {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
  }

  &__progress {
    margin-block: 1rem;
  }

  &__header > div > h2 {
    margin-block-start: 0;
    margin-block-end: 0;
  }

  &__header > div:last-child {
    align-self: stretch;

    a {
      width: max-content;
    }

    button {
      width: 100%;
    }

    svg {
      color: $gray-medium;
    }
  }

  &__header {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0.75rem;
    gap: 0.75rem;
  }

  &__last-update {
    display: flex;
    margin: 0 0.5rem;
    color: $gray-text;
    font-size: 0.875rem;
    align-items: center;
  }

  &__footer {
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    span {
      color: $gray-low;
    }

    &__duration {
      display: flex;
      flex-direction: row;
      gap: 1rem;

      div {
        display: flex;
        flex-direction: row;
        font-size: 0.875rem;
        gap: 0.2rem;

        .label {
          color: $gray-dark;
          font-weight: bold;
        }

        .value {
          color: $gray-text;
        }

        .diff {
          color: $red-violet;
          font-weight: bold;
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    font-size: 0.875rem;

    .double-coll {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__count {
    font-size: 00.875rem;
    font-weight: 400;
  }

  &__badge {
    display: inline-block;
    width: 2.3125rem;
    min-width: 2.3125rem;
    height: 2.3125rem;
    border-radius: 50%;
    background-color: $primary;
    color: $white-full;
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 2.3125rem;
    text-align: center;
    aspect-ratio: 1;
  }

  h2,
  p {
    line-height: 1.2;
  }

  h2 {
    font-size: 1.125rem;
  }

  p {
    font-size: 0.875rem;
  }
}

@media screen and (min-width: 640px) {
  .project-card {
    &__header {
      flex-direction: initial;
      justify-content: space-between;
      margin-bottom: 0.5rem;
    }

    &__header>div:last-child {
      gap: 0.25rem;
    }

    &__footer {
      flex-direction: initial;
    }

    // &__last-update {
    //   position: absolute;
    //   left: 50%;
    //   transform: translate(-50%);
    // }
  }
}

:deep(.avatar-list__container) {
  display: flex;
  align-items: center;
  max-width: 5rem;
}

:deep(.avatar-list__container > span) {
  &:nth-of-type(1) {
    z-index: 3;
  }

  &:nth-of-type(2) {
    z-index: 2;
    transform: translateX(-50%);
  }

  &:nth-of-type(3) {
    z-index: 1;
    transform: translateX(-105%);
  }

  &:nth-of-type(4) {
    z-index: 1;
    transform: translateX(-155%);
  }
}

:deep(.tooltip-list__avatar) {
  border: 2px solid #fff;
}

:deep(.avatar-list__counter) {
  margin-bottom: 0.1rem;
  font-size: 0.8rem;
}

:deep(.avatar-list__container > .extra__list--card-container) {
  z-index: 5;
}

:deep(.avatar-list__container > .extra__list--card-container > .extra__list--card) {
  cursor: default;
}

:deep(.tooltip-message) {
  color: $primary;
  font-weight: bold;
  text-transform: uppercase;
}

:deep(.tooltip-text) {
  left: -100%;
}
</style>
