<script lang="ts">
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import SvgIcon from "@jamescoyle/vue-icon";
import { Button2 } from "@/components/button";

// import { searchProject } from "@/http_services/entities/project";
export default defineComponent({
  name: "ContentHeader",
  components: {
    SvgIcon,
    Button: Button2,
  },
  props: {
    buttons: Array,
    title: String,
    searchTotal: Number,
    allProjets: Number,
  },
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
});
</script>

<template>
  <div class="content-wrapper">
    <h1 class="title">
      <slot name="icon" />
      <span class="title-wrapper">
        {{ title }}
        <span class="label"> {{ t("text.numberRegistration", { count: allProjets }) }}</span>
      </span>
    </h1>
    <div class="actions-wrapper">
      <Button
        v-for="(button, index) in buttons"
        :key="`button-${index}`"
        class="header-button"
        filled
        variation="primary"
        @click="button.onClick"
      >
        <SvgIcon v-if="button.buttonIcon" type="mdi" :path="button.buttonIcon" :size="16" />
        {{ button.text }}
      </Button>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "../../styles/_colors";

.content-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3rem;

  .title {
    display: flex;
    color: $gray-dark;
    font-family: "Open Sans";
    font-size: 2rem;
    font-weight: bold;
    gap: 17px;
    text-transform: uppercase;
  }

  .label {
    display: flex;
    margin-top: 0.7rem;
    color: $primary;
    font-size: 0.8rem;
    font-weight: 500;
    text-transform: lowercase;
  }

  .header-button:deep {
    svg {
      vertical-align: bottom;
    }
  }
}

.actions-wrapper {
  button {
    margin-right: 2rem;

    &:last-child {
      margin-right: 0.5rem;
    }
  }
}
</style>
