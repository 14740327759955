<script setup lang="ts">
import IdealProfileTagsPreview from "@/common/components/Export/IdealProfileTagsPreview.vue";

const props = withDefaults(
  defineProps<{
    isExport: boolean
    idealProfile: any
  }>(),
  {
    isExport: false,
  },
);

const { t, tm } = useI18n();

function limitText(text: string | null | undefined) {
  if (text === null || text === undefined)
    return "";

  return text.length >= 43 ? `${text.substring(0, 43)}...` : text;
}

function getFormattedPackage(data: any): string[] {
  if (!data)
    return [];

  const formatted = [];
  if (data.salary)
    formatted.push(`${t("projects.construction.labels.salary")}: ${limitText(data.salary)}`);

  if (data.total_cash)
    formatted.push(`${t("projects.construction.labels.totalCash")}: ${limitText(data.total_cash)}`);

  return formatted;
}

const ListItems = computed(() => {
  // TODO: remap those values
  if (!props.idealProfile)
    return { left: [], right: [] };
  return {
    left: [
      {
        id: "academic",
        icon: "project_academic",
        title: t("exportPDF.labels.schooling"),
        items: Object.values(props.idealProfile.academic_backgrounds || {}).concat([
          "__space__",
        ]).concat(
          Object.values(props.idealProfile.languages || {}),
        ),
        key: "academic",
      },
      {
        id: "experience",
        icon: "project_company",
        title: t("exportPDF.labels.experience"),
        items: Object.values(props.idealProfile.experiences_tags || [])
          .filter((tag, index) => tag.selected && index < 5)
          .map(tag => tag.text)
          // .filter(index => index < 4)
          .map(text => limitText(text)),
        key: "experience",
      },
      {
        id: "motivation",
        icon: "project_motivation",
        title: t("exportPDF.labels.motivation"),
        items: getFormattedPackage(props.idealProfile.package),
        key: "motivation",
      },
    ],
    right: [
      {
        id: "realizations",
        icon: "project_realizations",
        title: t("exportPDF.labels.realizations"),
        items: (props.idealProfile.achievements_tags || [])
          .filter((tag, index) => tag.selected && index < 5)
          .map(tag => tag.text)
          .map(text => limitText(text)),
        key: "achievements",
      },
      {
        id: "attributes",
        icon: "project_attributes",
        title: t("exportPDF.labels.attributes"),
        items: props.idealProfile.attributes
          ? Object.keys(props.idealProfile.attributes)
            .map(key => props.idealProfile.attributes[key] !== "EMPTY" ? key : null)
            .filter(Boolean)
            .map(key => t(`projects.details.constructionGuide.questions.${key?.toUpperCase()}`))
          : [],
        key: "attributes",
      },
      {
        id: "cultural_match",
        icon: "project_cultural_match",
        title: t("exportPDF.labels.culturalMatch"),
        items: props.idealProfile.cultural_match
          ? Object.keys(props.idealProfile.cultural_match)
            .map(key => props.idealProfile.cultural_match[key] !== "EMPTY" ? key : null)
            .filter(Boolean)
            .map(key => t(`projects.details.constructionGuide.questions.${key?.toUpperCase()}`))
          : [],
        key: "cultural-match",

      },
    ],
  };
});
</script>

<template>
  <IdealProfileTagsPreview :list="ListItems" icon="project_ideal_profile" />
</template>
