<script setup lang="ts">
import { uniqBy } from "lodash-es";
import CandidateCard from "./components/CandidateCard.vue";
import BaseTooltip from "@/common/components/BaseTooltip.vue";
import ProjectStages from "@/common/components/ProjectStages.vue";
import SearchLogo from "@/assets/images/search_icon.svg";
import type { SearchCandidate } from "@/common/composables/useShortlist";
import {
  userApproveScreeningsCandidate,
  userDeclineScreeningsCandidate,
} from "@/common/composables/useScreening";
import ScrollToTop from "@/components/scroll_to_top/ScrollToTop.vue";

const props = defineProps<{ id: string }>();

enum StagesEnum {
  MAPPING = "MAPPING",
  SCREENINGS = "SCREENINGS",
  INTERVIEWS = "INTERVIEWS",
  SHORTLIST = "SHORTLIST",
}

const route = useRoute();
const router = useRouter();
const { t, tm } = useI18n();

const { data: project } = await useProject(props.id);

const projectId = computed(() => String(route.params.id));
const loading = ref(false);
const emptyList = ref(false);
const visibleScrollTop = ref(false);
const searchInput = ref("");
const listAmount = ref(0);

const paginationParams = reactive({
  name: searchInput.value,
  project_id: projectId.value,
  saved_candidates: false,
  evaluated_in_screenings: true,
  project_stage: "SCREENINGS",
  sort: ["screenings_approval_status", "screenings_approval_priority"],
  limit: 10,
  skip: 0,
});

const {
  error,
  mutateAsync: useFullCandidates,
  isPending,
} = useScreeningsFullCandidates();

const {
  data: screening,
  mutate: fetchScreening,
} = useScreeeningEvaluationMutation();

const {
  mutateAsync: approveCandidate,
  isPending: pendingApprove,
} = userApproveScreeningsCandidate();

const {
  mutate: declineCandidate,
} = userDeclineScreeningsCandidate();

const {
  mutate: screeningToInterview,
} = useProjectStageScreeningToInterview(props.id);

const {
  mutate: patchApprovalCandidate,
} = usePatchApprovalCandidate();

const {
  data: recruiter,
} = await usePersonMe();

const newActivity = ref({
  description: "RECRUITER_MEETING",
  activity_date: null,
  project_id: props.id,
  recruiter: {
    person_id: recruiter.value?.data.id,
  },
  candidate: null,
});

const candidates = ref<SearchCandidate[]>([]);

const workModelEnum = computed(() => tm("projects.details.activities.screening.workModelEnum"));
const willingToRelocateEnum = computed(() => tm("projects.details.activities.screening.willingToRelocateEnum"));

function resetList() {
  paginationParams.name = "";
  listAmount.value = 0;
  candidates.value = [];
  paginationParams.skip = 0;
}

const {
  tabs,
  goTo,
} = useScreeningTabs(props.id);

const currentRoute = computed(() => tabs.value.findIndex(tab => tab.key === router.currentRoute.value.meta.tab));

const detailsOpened = ref("");

async function changeRating(data: { candidate_id: string; approval: any }) {
  patchApprovalCandidate({
    person_id: data.candidate_id,
    body: {
      approval: {
        ...data.approval,
      },
    },
  }, {
    onSuccess: () => {
      resetList();
      fetchCandidates();
    },
  });
}

async function goToActivity(person_id: string) {
  const activity = await getScreeningActivities({ project_id: props.id, candidate_person_id: person_id });
  if (activity[0])
    router.push(`/projects/${props.id}/screenings/person/${person_id}/activity/${activity[0]?._id}`);
}

async function onSearch() {
  resetList();
  loading.value = true;
  paginationParams.name = searchInput.value;
  await fetchCandidates();
}

async function nextPage() {
  try {
    loading.value = true;
    paginationParams.skip = candidates.value?.length;
    await fetchCandidates();
  }
  catch (error) {
    loading.value = false;
    console.log("error", error);
  }
}

const bodyHeight = ref(0);
function scrollToTop() {
  const body = document.querySelector("body");
  if (body?.scrollTop >= 100)
    body.scrollTop = bodyHeight.value - 150;
}

async function handleScroll(event: Event) {
  const {
    scrollTop: hiddenHeight,
    scrollHeight: totalHeight,
    clientHeight: totalVisible,
  } = event.target as HTMLElement;
  const bottomReached = hiddenHeight + totalVisible + 1 >= totalHeight;

  (hiddenHeight >= 700) ? (visibleScrollTop.value = true) : (visibleScrollTop.value = false);

  if (hiddenHeight && bottomReached && !loading.value && !emptyList.value) {
    bodyHeight.value = hiddenHeight;
    await nextPage();
  }
}

async function fetchCandidates() {
  await useFullCandidates(paginationParams, {
    onSuccess: (result) => {
      listAmount.value = result.count;
      candidates.value = uniqBy([...candidates.value, ...result.data], e => e._id);
      emptyList.value = result?.data?.length === 0;
      loading.value = false;
      scrollToTop();
    },
    onError: () => {
      loading.value = false;
    },
  });
}

function checkStage(stage: string) {
  return !(stage === StagesEnum.SCREENINGS);
}

async function approveToInterviews(id_: string, person_id: string) {
  try {
    if (pendingApprove.value)
      return;

    await approveCandidate({ id_ }, {
      onSuccess: () => {
        resetList();
        fetchCandidates();
        if (project.value?.stages?.interviews.status === "DRAFT")
          screeningToInterview(props.id);
      },
    });
  }
  catch (error) {
    console.log("error", error);
  }
}

function toggleDetails(person_id: string) {
  if (detailsOpened.value === person_id) {
    detailsOpened.value = "";
    return;
  }

  detailsOpened.value = person_id;
  const element = document.getElementById(person_id);
  element?.focus();

  fetchScreening({ project_id: String(route.params.id), person_id });
}

onMounted(() => {
  fetchCandidates();
  const body = document.querySelector("body");
  body?.addEventListener("scroll", (event: Event) => handleScroll(event));
});

watchEffect(() => {
  if (recruiter.value)
    newActivity.value.recruiter.person_id = recruiter.value?.data.id;
});
</script>

<template>
  <section class="project-activities">
    <div v-if="error">
      could not load
    </div>

    <div data-testid="project-candidates">
      <div class="project-container">
        <ProjectStages :id="props.id" />
        <ProjectTabs :stage-tabs="tabs" :active="currentRoute" class="tabs" @change="goTo($event)" />
        <div class="project-wrapper">
          <div class="project-header">
            <div class="amount">
              {{ listAmount }} {{ t("projects.details.tabs.candidates") }}
            </div>
            <div class="actions">
              <div class="form-search">
                <input
                  v-model="searchInput"
                  name="search"
                  :placeholder="t('persons.searchPerson')"
                  autocomplete="off"
                  @change="onSearch()"
                >
                <SearchLogo class="search-icon" width="1.25rem" height="1.25rem" />
              </div>
            </div>
          </div>
          <div class="project-body">
            <div v-if="candidates?.length" class="list-candidates">
              <CandidateCard
                v-for="(person, index) in candidates"
                :id="person.person_id"
                :key="index"
                :person="person"
                :active="checkStage(person.project_stage)"
                :show-details="detailsOpened === person.person_id"
                :prioritization="true"
                :disapproved="person.approval_and_priority?.approved_for_interview === 'NO'"
                @change:rating="changeRating($event)"
              >
                <template #actions>
                  <BaseTooltip
                    v-if="person.approval_and_priority?.approved_for_interview === 'NO'"
                    position-x="center"
                    position-y="top"
                  >
                    <Button
                      class="person-data-actions-buttons"
                      variation="secondary"
                      filled
                      disabled
                    >
                      {{ t("projects.details.constructionGuide.ranking.addToInterviews") }}
                    </Button>
                    <template #message>
                      <div class="tooltip-message">
                        {{ t("projects.screening.tooltip.failedScreening") }}
                      </div>
                    </template>
                  </BaseTooltip>
                  <Button
                    v-else-if="!checkStage(person.project_stage)"
                    class="person-data-actions-buttons"
                    variation="secondary"
                    :disabled="pendingApprove"
                    @click="approveToInterviews(person._id, person.person_id)"
                  >
                    {{ t("projects.details.constructionGuide.ranking.addToInterviews") }}
                  </Button>
                  <BaseTooltip
                    v-else
                    position-x="center"
                    position-y="top"
                  >
                    <Button
                      class="person-data-actions-buttons"
                      variation="secondary"
                      filled
                      disabled
                    >
                      {{ t("projects.details.constructionGuide.ranking.removeFromInterviews") }}
                    </Button>
                    <template #message>
                      <div class="tooltip-message">
                        {{ t('projects.mapping.card.tooltip') }}
                      </div>
                    </template>
                  </BaseTooltip>

                  <!-- toggle detais -->
                  <Button
                    v-if="detailsOpened !== person.person_id"
                    class="person-data-actions-buttons"
                    variation="secondary"
                    @click="toggleDetails(person.person_id)"
                  >
                    {{ t("projects.details.constructionGuide.ranking.openScreeningDetails") }}
                  </Button>
                  <Button
                    v-else
                    class="person-data-actions-buttons"
                    variation="secondary"
                    filled
                    @click="toggleDetails(person.person_id)"
                  >
                    {{ t("projects.details.constructionGuide.ranking.hideDetails") }}
                  </Button>

                  <!-- go to screening -->
                  <Button
                    class="person-data-actions-buttons"
                    variation="primary"
                    @click="goToActivity(person.person_id)"
                  >
                    {{ t("projects.details.constructionGuide.ranking.reassess") }}
                  </Button>
                </template>
                <template #details>
                  <div class="details">
                    <div class="details-title">
                      Formação Acadêmica
                    </div>
                    <div class="details-group">
                      <div class="items">
                        <p v-for="(academic_value, _, academic_key) of screening?.academic_backgrounds" :key="academic_key">
                          {{ academic_value }}
                        </p>
                      </div>
                      <div class="items">
                        <p><strong>Idiomas:</strong></p>
                        <p v-for="(language_value, _, language_key) of screening?.languages" :key="language_key">
                          {{ language_value }}
                        </p>
                      </div>
                    </div>
                    <div class="details-title">
                      Experiência Profissional
                    </div>
                    <div class="details-group">
                      <div
                        v-for="(experience_value, _, experience_index) of screening?.experiences"
                        :key="experience_index"
                        class="items"
                      >
                        <p v-if="experience_value">
                          <strong>
                            Experiência {{ experience_index + 1 }}
                          </strong>
                        </p>
                        <p>
                          {{ experience_value }}
                        </p>
                      </div>
                    </div>
                    <div class="details-title">
                      Motivação
                    </div>
                    <div class="details-group">
                      <div class="items">
                        <p><strong>Pacote:</strong></p>
                        <p v-if="screening?.package?.salary">
                          <span>
                            <span><strong>- Faixa salarial: </strong></span>
                            <span>{{ screening?.package?.salary }}</span>
                          </span>
                        </p>
                        <p v-if="screening?.package?.short_term_incentive">
                          <span>
                            <span><strong>- ICP: </strong></span>
                            <span>{{ screening?.package?.short_term_incentive }}</span>
                          </span>
                        </p>
                        <p v-if="screening?.package?.long_term_incentive">
                          <span>
                            <span><strong>- ILP: </strong></span>
                            <span>{{ screening?.package?.long_term_incentive }}</span>
                          </span>
                        </p>
                        <p v-if="screening?.package?.other_incentive">
                          <span>
                            <span><strong>- Outros: </strong></span>
                            <span>{{ screening?.package?.other_incentive }}</span>
                          </span>
                        </p>
                        <p v-if="screening?.package?.total_cash">
                          <span>
                            <span><strong>- Total cash: </strong></span>
                            <span>{{ screening?.package?.total_cash }}</span>
                          </span>
                        </p>
                      </div>
                      <div class="items">
                        <p>
                          <strong>
                            Possibilidade de mudança?
                          </strong>
                        </p>
                        <p v-if="screening?.willing_to_relocate">
                          {{ willingToRelocateEnum[screening?.willing_to_relocate] }}
                        </p>
                        <p>
                          <strong>
                            Modelo de trabalho?
                          </strong>
                        </p>
                        <p v-if="screening?.work_model">
                          {{ workModelEnum[screening?.work_model] }}
                        </p>
                      </div>
                    </div>
                  </div>
                </template>
              </CandidateCard>
            </div>
            <p v-if="!candidates.length && !isPending">
              {{
                t("projects.details.constructionGuide.ranking.candidateNotFound")
              }}
            </p>
            <div v-if="loading" class="loader-wrapper">
              <div class="loader" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <ScrollToTop v-if="visibleScrollTop" class-name="body" :top="350" />
  </section>
</template>

<route lang="yaml">
meta:
  layout: project-stage
  stage: screenings
  tab: prioritization
</route>

<style scoped lang="scss">
.project-activities {
  min-height: 100vh;
}
.project-container {
  padding-top: 3.75rem;
}

.tooltip-message {
  font-size: 1rem;
  font-weight: bold;
  color: $gray-dark;
  text-transform: uppercase;
}
.project-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 32rem;
  padding-inline: 4rem;
  padding-block: 2.5rem;
  background: $white-full;

  .project-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-block: 1.3125rem;
    margin-block-end: 1.3125rem;

    .amount {
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: $gray-dark;
      font-size: 1.125rem;
      font-weight: bold;
    }

    .actions {
      display: flex;
      flex-direction: column;

      .form-search {
        display: flex;
        width: 22.5rem;
        flex-direction: row;
        padding-block: 0.7rem;
        padding-inline: 0.875rem;
        border: 1px solid $gray-border;
        border-radius: 0.625rem;

        input {
          flex: 1;
          font-size: 0.875rem;
          outline: none;
          border: none;
        }
        .search-icon {
          color: $primary;
        }
      }
    }

    .button-pluss {
      display: flex;
      flex-direction: row;
      gap: 0.25rem;
    }
  }

  .project-body {
    display: flex;
    flex-direction: column;
    padding-block-end: 1rem;

    .list-candidates {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    .details {
      display: flex;
      flex-direction: column;
      padding-block: 1rem;
      gap: 1rem;

      &-title {
        color: $primary;
        font-size: 1.25rem;
        font-weight: bold;
        text-transform: uppercase;
      }

      &-group {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2rem;

        .items {
          flex: 1;
          font-size: 0.875rem;
        }
      }
    }
  }
}

.loader-wrapper {
  width: 100%;
  height: 2rem;
  padding-block: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader {
  width: 2rem;
  height: 2rem;
  border: 5px solid;
  border-color: $yellow transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

:deep(.tooltip) {
  .tooltip-message {
    font-size: 0.75rem;
    text-transform: uppercase;
  }
}
</style>
