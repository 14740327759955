export const filters = {
  moneyRange: {
    decimalDelimiter: ",",
    thousandsDelimiter: ".",
  },
  market: {
    search: "digite para filtrar um mercado",
    notFound: "Nenhum resultado encontrado",
    error: "Erro ao filtrar mercado. Tente novamente",
  },
};
