export const input = {
  placeholder: {
    username: "Username",
    findPeopleAndCompany: "find person, company or position",
    password: "Password",
    email: "E-mail",
    submitButton: "Sign in",
  },
  confirm: "Confirm",
  login: "Login",
  avatarInput: {
    defineImage: "Set image",
    cancel: "Cancel",
    change: "Change",
  },
  validations: {
    required: "Field not filled",
    email: "Invalid e-mail",
    minLength: "Must have at least {length} character(s)",
  },
};
