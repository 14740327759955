<script setup lang="ts">
import bson from "bson-objectid";
import { TrashButton } from "@/components/trash-button";
import { TooltipBox } from "@/components/tooltip";

const props = withDefaults(defineProps<{
  id?: string
  modelValue?: string | undefined
  placeholder?: string
  warningChars?: number
  warningMessage?: string
  enforceChars?: boolean
  autoFill?: boolean
  shortText?: boolean
  tooltipShow?: boolean
  tooltipMesage?: string
  tooltipColor?: string
  tooltipBackground?: string
  svgIcon?: string
  showIcon?: boolean
  verticalGrow?: boolean
}>(), {
  id: bson().toHexString(),
});

defineEmits(["update:modelValue", "click:on-button-click", "focus", "blur"]);

const { t } = useI18n();

const showTooltip = ref(false);

function checkMaxRows(event: KeyboardEvent, rows: string) {
  const numberOfLines = (props.modelValue?.match(/\n/g) || []).length + 1;
  const maxRows = Number.parseInt(rows);

  if (event.keyCode === 13 && numberOfLines === maxRows)
    event.preventDefault();
}
</script>

<template>
  <div class="text-area-wrapper" @mouseover="showTooltip = true" @mouseout="showTooltip = false">
    <TooltipBox
      :show="tooltipShow && showTooltip"
      :color="tooltipColor"
      :background="tooltipBackground"
      :mesage="tooltipMesage"
    />
    <div class="textarea-container">
      <textarea
        :id="id"
        :class="{ auto_fill: autoFill, short: shortText }"
        data-test="text-area"
        :data-limit-rows="enforceChars ? true : false"
        :rows="`${$attrs.rows || 4}`"
        :value="modelValue ?? ''"
        :placeholder="placeholder"
        :disabled="Boolean($attrs.disabled) ?? false"
        :maxlength="enforceChars ? warningChars : undefined"
        wrap="hard"
        @focus="$emit('focus')"
        @blur="$emit('blur')"
        @input="$emit('update:modelValue', $event.target.value)"
        @keypress="enforceChars ? checkMaxRows($event, $attrs.rows as string) : null"
      />
      <TrashButton :show-icon="props.showIcon" :svg-icon="props.svgIcon" @click="$emit('click:on-button-click')" />
    </div>
    <div v-if="warningChars" class="counter-wrapper">
      <div class="counter">
        {{ modelValue?.length || 0 }}/{{ warningChars }}
      </div>
      <div v-if="(modelValue?.length || 0) >= warningChars" class="warning">
        {{ warningMessage || t("text.textArea.defaultWarning", { count: warningChars }) }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.textarea-container {
  display: flex;

  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    color: inherit;
  }

  button {
    min-width: 2rem;
    height: 1.8rem;
    border: 1px solid $gray-border;
    border-radius: 6px;
    margin-left: 0.563rem;

    &:hover {
      border: 1px solid $red-violet-medium;
      background-color: $red-violet-medium;

      svg {
        color: $orange-medium;
      }
    }
  }
}

.text-area-wrapper {

  textarea {
    width: 100%;
    padding-block: 1rem;
    padding-inline: 0.5rem 1rem;
    border: 1px solid $gray-border;
    border-radius: 6px;
    color: $gray;
    font-family: "Open Sans";
    font-size: 0.875rem;
    outline: none;
    overflow-wrap: break-word;
    word-break: break-word;
    resize: vertical;
    scrollbar-color: #888 transparent;
    white-space: pre-wrap;

    &:focus {
      color: $gray-dark;
      border: 1px solid $gray-dark;

      &::-webkit-scrollbar-thumb {
        background-color: $gray-dark;
      }
    }

    &.short {
      padding: 0.25rem 0.5rem;
    }

    /* Works on Firefox */
    scrollbar-width: thin;

    &::placeholder {
      color: $gray-medium;
    }

    /* Works on Chrome, Edge, and Safari */
    &::-webkit-scrollbar {
      width: 2rem;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      min-height: 4rem;
      box-sizing: padding-box;
      border: 0.92rem solid transparent;
      border-radius: 1rem;
      background-clip: padding-box;
      background-color: #c6c6c6;
    }
  }

  .auto_fill {
    border: 2px solid $red-violet;
  }

  .counter-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 5px;
    font-size: 0.875rem;

    .warning {
      color: $red-warning;
    }

    .counter {
      padding-right: 0.5rem;
      color: $secondary;
    }
  }
}
</style>
