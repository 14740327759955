export const exportPDF = {
  construction: "APRESENTAÇÃO DA CONSTRUÇÃO",
  constructionTitle: "CONSTRUÇÃO - PREVIEW DE EXPORTAÇÃO",
  shortlistTitle: "SHORTLIST - PREVIEW DE EXPORTAÇÃO",
  previewSubtitle: "Desmarque a página {complement} para exportação",
  inactive: "Indesejada",
  confidential: "Material Confidencial",
  exportDocument: "Exportar documento",
  summary: "Agenda",
  shortlist: "APRESENTAÇÃO DA SHORTLIST",
  shortlisTitle: "SHORTLIST - PREVIEW DE EXPORTAÇÃO",
  idealProfile: "Perfil ideal - Persona",
  evaluation: "Avaliação",
  confidentialProject: "PROJETO CONFIDENCIAL",
  companyProfile: "Perfil de empresa",
  summaryEnum: {
    aboutCompany: "Sobre a empresa",
    governance: "Governança",
    strategy: "Estratégia",
    executionConditions: "Condições de execução",
    companyProfile: "Perfil de empresa",
    idealProfile: "Perfil ideal",
    idealProfileTags: "Perfil ideal - persona",
    realizations: "Realizações",
    attributes: "Atributos",
    culturalMatch: "Match Cultural",
    motivation: "Motivação",
    searchField: "Campo de busca",
    schedule: "Cronograma",
  },
  process: {
    processTitle: "Funil de processo",
    processDay: "dia de processo | {count} dia de processo",
    processDays: "{count} dia de processo | {count} dias de processos",
    mapping: {

      text: "Formação, tempo de experiência, tipo de organizações que passou, escopo da cadeira e principais realizações",
      textAfter: "identificados",
    },
    screenings: {

      text: "Interesse em participar, pacote de remuneração, localização, disponibilidade de viagem, escopo+ e realizações+",
      textAfter: "prévias",
    },
    interviews: {
      text: "Realizações++, pacote+, motivação e perfil",
      textAfter: "entrevistas",
    },
    shortlist: {
      text: "Taxa de aderência mínima do projeto (90%)",
      textAfter: "aprovados",
    },
    finalists: {
      text: "Melhores do ranking",
      textAfter: "finalistas",
    },
  },
  summaryShortlist: {
    aboutCompany: "Sobre a empresa",
    idealProfile: "Perfil ideal - Persona",
    realizations: "Realizações",
    motivation: "Motivação",
    searchField: "Campo de busca",
    process: "O processo",
    finalists: "Os finalistas",
    attributes: "Atributos",
  },
  summaryShortlistEnum: {
    idealProfile: "Perfil ideal",
    searchField: "Campo de busca",
    processFunnel: "Funil de processo",
    adherenceTable: "Tabela de aderência",
    candidatesAnalysis: "Análise dos candidatos",
    candidates: "Análise dos candidatos",
  },
  summaryConstructionBeta: {
    aboutCompany: "Sobre a empresa",
    governance: "Governança",
    strategy: "Estratégia",
    executionConditions: "Condições de execução",
    companyProfile: "Perfil de empresa",
    idealProfile: "Perfil ideal",
    attributes: "Atributos",
    culturalMatch: "Match Cultural",
    motivation: "Motivação",
    searchField: "Campo de busca",
    schedule: "Cronograma",
  },
  summaryShortlistBeta: {
    idealProfile: "Perfil ideal",
    searchField: "Campo de busca",
    processFunnel: "Funil do processo",
    adherenceTable: "Tabela de aderência",
    candidatesAnalysis: "Análise dos candidatos",
  },
  labels: {
    schooling: "Formação acadêmica",
    language: "Idiomas",
    experience: "Experiência",
    experiences: "Experiências",
    professionalExperience: "Experiência Profissional",
    realizations: "Realizações",
    package: "Pacote",
    career: "Carreira",
    environment: "Ambiente",
    personal: "Pessoal",
    salary: "Salário",
    icp: "ICP (Incentivo de Curto Prazo)",
    ilp: "ILP (Incentivo de Longo Prazo)",
    others: "Outros",
    totalCash: "Total Cash",
    adherenceIdealProfile: "Aderência ao Perfil Ideal",
    experienceProfessional: "Experiência Profissional",
    attributes: "Atributos",
    culturalMatch: "Match Cultural",
    cultural_match: "Match Cultural",
    motivation: "Motivação",
    motivationFactors: "Motivação - Fatores de Atração",
    weights: "Pesos",
    ideal: "Ideal",
    idealProfile: "Perfil Ideal",
    finalRate: "Taxa final de aderência",
    professionalTrajectory: "Trajetória Profissional",
    attraction: "Atração",
    retention: "Retenção",
    repulsion: "Expulsão",
    governance: "Governança",
    executionConditions: "Condições de execução",
    strategy: "Estratégia",
  },
  funnel: {
    labels: {
      identified: "Identificados",
      screenings: "Prévias",
      interviews: "Entrevistas",
      shortlists: "Aprovados",
      finalists: "Finalistas",
    },
    resumes: {
      identified: "Formação, tempo de experiência, tipo de organizações que passou, escopo da cadeira e principais realizações",
      screenings: "Interesse em participar, pacote remuneração, localização, disponibilidade viagem, escopo+ e realizações+",
      interviews: "Realizações, pacote, motivação e perfil",
      shortlists: "Taxa de aderência mínima do projeto (90%)",
      finalists: "Melhores do ranking",
    },
  },
};
