<script setup lang="ts">
import { useCounter } from "@vueuse/core";

const props = defineProps<{
  initial: number
}>();
const { count, inc, dec } = useCounter(props.initial);
</script>

<template>
  <div title="healthy">
    <div class="counter">
      {{ count }}
    </div>
    <button class="inc" @click="inc()">
      +
    </button>
    <button class="dec" @click="dec()">
      -
    </button>
  </div>
</template>
