import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import type { ApprovalCandidateType, SearchCandidatesType } from "@/common/services/candidates";
import { candidateApproveToShortlist, candidateDeclineFromShortlist, getCandidate, searchCandidates } from "@/common/services/candidates";

export interface PersonWorkExperience {
  id?: string | null
  corporation_id: string
  corporation_name: string
  role_name: string
  start_date: string
  is_current: boolean
  end_date: string | null
  description: string
}

export interface CalculatedScores {
  academic_formation_mean: number | null
  experiences_mean: number | null
  achievements_mean: number | null
  attributes_mean: number | null
  cultural_match_mean: number | null
  motivation_mean: number | null
  final_mean: number | null
}

export interface EmailAddress {
  is_main: boolean
  email_address: string
}

export interface PersonData {
  first_name: string
  last_name: string
  email_addresses: EmailAddress[]
  avatar_url: string
  address: any | null
  work_experience: PersonWorkExperience[]
  serial: number
  updated_at: string
  phones: {
    calling_code: string | number
    number: string | number
  }[]
}

export interface SearchCandidate {
  project_id: string
  person_id: string
  person_data: PersonData
  project_stage: string
  approval_and_priority: {
    approved_for_interview: string
    reason: string
    priority_classification: string
  }
  mini_cv: {
    candidate_feedbacks: any[]
    client_feedbacks: any[]
    current_company_nickname: string
    current_role_nickname: string
    hide_experiences_ids: string[]
    nickname: string
    recruiter_opinion: string
  }
  calculated_scores: CalculatedScores
  _version: number
  is_active: boolean
  serial: number
  inserted_at: string
  updated_at: string
  _id: string
}

export function useShortlistGetCandidate({ project_id, person_id }: { project_id: string; person_id: string }) {
  return useQuery({
    queryKey: ["candidate", "search", project_id, person_id],
    queryFn: () => getCandidate({
      project_id,
      person_id,
    }),
  });
}

export function useShortlistCandidates(params: SearchCandidatesType) {
  return useQuery({
    queryKey: ["candidate", "list", params.project_id],
    queryFn: () => searchCandidates(params),
  });
}
export function mutateShortlistCandidates() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (project_id: string) => getCandidatesFromShortlist(project_id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["candidate", "list"] });
    },
  });
}
export function useGetCandidatesFromShortlist(project_id: string) {
  return useQuery({
    queryKey: ["candidate", "list", project_id],
    queryFn: () => getCandidatesFromShortlist(project_id),
  });
}
export function useShortlistApprovalCandidate() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: ApprovalCandidateType) => candidateApproveToShortlist(body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["interview_candidates"] });
    },
  });
}

export function useShortlistDeclineCandidate() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: ApprovalCandidateType) => candidateDeclineFromShortlist(body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["interview_candidates"] });
    },
  });
}

export function useGetCandidateIdFromPersonId(projectId: string, personId: string) {
  return useQuery({
    queryKey: ["candidate", projectId, personId],
    queryFn: () => getCandidateIdFromPersonId(projectId, personId),
  });
}
export function useGetCandidateIdFromPersonIdMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: { projectId: string; personId: string }) => getCandidateIdFromPersonId(body.projectId, body.personId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["candidate"] });
    },
  });
}
