<script setup lang="ts">
import { format, parseISO } from "date-fns";
import { mdiPlusBox } from "@mdi/js";
import { useCreateInterviewActivity, useInterviewsActivitiesQuery } from "@/common/composables/useInterviews";
import { SvgIcon } from "@/components/icon";
import { usePersonMe } from "@/common/composables/usePerson";
import { Avatar } from "@/components/avatar";
import ActivitiesSchedule from "@/common/components/Export/ActivitiesSchedule.vue";
import { ButtonSpan } from "@/components/button";
import { SearchInput } from "@/components/inputs/modalInputs";
import type { Activity } from "@/types/activity";

const props = defineProps<{ id: string }>();
const router = useRouter();
const route = useRoute();
const { t, tm } = useI18n();

const projectId = computed(() => String(route.params.id));
const activitiesEnum = computed(() => tm("projects.details.activities.enum"));

const {
  error,
  isLoading: loading,
  isFetching,
  data: activities,
  refetch: fetchInterviewActivities,
} = useInterviewsActivitiesQuery({
  project_id: String(route.params.id),
  sort: ["activity_status"],
});
const {
  error: errorCreateActivity,
  mutate: createInterviewActivity,
} = useCreateInterviewActivity();

const {
  data: recruiter,
} = await usePersonMe();

const {
  data: project,
} = useProject(props.id);

const {
  mutate: updateProject,
} = updateProjectMutation(String(route.params.id));

const {
  mutate: patchInterviewActivity,
} = usePatchInterviewActivity();

const creatingSchedule = ref(false);
const dropdown = ref({ active: false });

const hasSchedule = computed(() => {
  if (!project.value?.schedules || !Object.values(project.value?.schedules).filter(Boolean).length)
    return false;
  const scheduleKeys = Object.values(project.value?.schedules || {});
  return scheduleKeys.filter(data => Object.values(data).filter(Boolean)).length > 0;
});

function submitSchedule(schedules) {
  updateProject({
    ...project.value,
    schedules,
  });
  creatingSchedule.value = false;
}

const creating = ref(false);
const editingDate = ref({});
const payloadDate = ref({});

const {
  tabs,
  goTo,
} = useInterviewTabs(props.id);

const currentRoute = computed(() => {
  tabs.value.findIndex(tab => tab.key === router.currentRoute.value.meta.tab);
});

// use a non paginated version
const {
  data: interviewCandidates,
} = await useInterviewCandidates({
  project_id: projectId.value,
  project_stage: "INTERVIEWS",
  skip: 0,
  limit: 30,
});

const interviewCandidatesStatus = ref({});
watchEffect(() => {
  interviewCandidates.value?.forEach((candidate: any) => {
    interviewCandidatesStatus.value = {
      ...interviewCandidatesStatus.value,
      [candidate.person_id]: candidate.interview_conducted,
    };
  });
});

function checkShowEditDate(id: string) {
  return editingDate.value[id] ?? false;
}

function showEditDate(id: string, date: string) {
  editingDate.value = {
    ...editingDate.value,
    [id]: true,
  };
  payloadDate.value = {
    ...payloadDate.value,
    [id]: formatDate(date),
  };
}

function closeEditDate(id: string) {
  delete editingDate.value[id];
  delete payloadDate.value[id];
}

function formatDate(date: string | null | undefined) {
  if (!date)
    return "";
  return format(parseISO(date), "dd/MM/yyyy");
}

function updateDateActivity(id: string, date: string) {
  const [interview] = activities.value.filter((item: { _id: string }) => item._id === id);
  patchInterviewActivity({
    project_id: id,
    activity: {
      ...interview,
      activity_date: date,
    },
  });
}

function createActivity(data: any) {
  data.activity_date = null;
  createInterviewActivity({
    project_id: props.id,
    ...data,
  });
  if (!errorCreateActivity.value)
    creating.value = false;
}

function toggleCreate() {
  creating.value = !creating.value;
}

function goToActivity(person_id: string, id: string) {
  router.push(`/projects/${props.id}/interviews/person/${person_id}/activity/${id}`);
}

const sortedActivities = ref();

function onInputSearch(value: string) {
  const filteredActivities = computed(() => {
    const searchTermLowerCase = value.toLowerCase().trim();
    return activities.value.filter((activity: Activity) => {
      const fullName = `${activity.candidate?.first_name} ${activity.candidate?.last_name}`;
      return fullName.toLowerCase().trim().includes(searchTermLowerCase);
    },
    );
  });
  if (!filteredActivities.value)
    return;

  sortedActivities.value = filteredActivities.value;
}

watchEffect(() => {
  const sortByStatus = (a, b) => {
    if (interviewCandidatesStatus.value[a.candidate?.person_id] && interviewCandidatesStatus.value[b.candidate?.person_id])
      return 1;

    if (interviewCandidatesStatus.value[b.candidate?.person_id] && interviewCandidatesStatus.value[a.candidate?.person_id])
      return 1;
    return 1;
  };

  sortedActivities.value = []
    .concat(activities.value)
    .sort(sortByStatus);
});

onMounted(() => fetchInterviewActivities());
</script>

<template>
  <section class="project-activities">
    <div v-if="error">
      could not load
    </div>
    <div v-if="activities" class="project-container" data-testid="project-activities">
      <ProjectStages :id="props.id" />
      <ProjectTabs :stage-tabs="tabs" :active="currentRoute" class="tabs" @change="goTo($event)" />
      <div class="project-wrapper">
        <div class="search-container">
          <SearchInput :placeholder="t('persons.searchPerson')" @search="onInputSearch($event)" />
        </div>
        <div v-if="!creating" class="project-header">
          <div class="title">
            {{ t(`projects.generic.stages.enum.interviews`) }}
          </div>
          <div class="actions">
            <Button class="button-pluss" variation="primary" @click="toggleCreate()">
              <SvgIcon icon="plus" margin="none" width="1.25rem" height="1.25rem" />
              {{ t("text.form.addElement") }}
            </Button>
          </div>
        </div>

        <div v-else class="project-form">
          <div class="project-form-title">
            {{ t("projects.details.activities.activityData") }}
          </div>
          <CreateActivity
            :project-id="projectId"
            :recruiter="recruiter?.data"
            :options="activitiesEnum"
            type="interviews"
            @submit="createActivity"
            @cancel="creating = false"
          />
        </div>

        <div class="project-body">
          <div class="list-activities">
            <div class="list-activities-titles">
              <div>
                {{ t("text.date") }}
              </div>
              <div>
                {{ t("text.name") }}
              </div>
              <div>
                {{ t("text.activitie") }}
              </div>
              <div class="status">
                {{ t("text.status") }}
              </div>
              <div class="actions" />
              <div />
            </div>
            <TransitionGroup>
              <div v-for="activity in sortedActivities" :key="activity._id" class="list-activities-candidate">
                <InputDateEditable
                  :activity="activity"
                  :model-value="formatDate(activity.activity_date)"
                  @submit="updateDateActivity(activity._id, $event)"
                />
                <div style="display: flex; align-content: center; gap: 1rem">
                  <Avatar :src="activity?.recruiter?.avatar_url" size="30px" image-size="small" />
                  {{ activity?.recruiter?.first_name }} {{ activity?.recruiter?.last_name }}
                </div>
                <div>
                  {{ t("projects.construction.titles.interview") }} - {{ `${activity?.candidate?.first_name} ${activity?.candidate?.last_name}` }}
                </div>
                <div class="status">
                  <BaseTooltip
                    v-if="activity?.candidate && activity.activity_date"
                    position-x="center"
                    position-y="top"
                  >
                    <SvgIcon
                      class="icon-checked"
                      icon="icon_checked"
                      margin="none"
                      width="1.875rem"
                      height="1.875rem"
                    />

                    <template #message>
                      <div class="tooltip-message">
                        {{ t("projects.interview.tooltip.interviewCompleted") }}
                      </div>
                    </template>
                  </BaseTooltip>

                  <BaseTooltip v-else position-x="center" position-y="top">
                    <SvgIcon icon="pending" margin="none" width="1.875rem" height="1.875rem" />
                    <template #message>
                      <div class="tooltip-message">
                        {{ t("projects.interview.tooltip.pendingInterview") }}
                      </div>
                    </template>
                  </BaseTooltip>
                </div>
                <div>
                  <Button
                    variation="secondary"
                    @click.self="goToActivity(activity.candidate.person_id, activity._id)"
                  >
                    {{ t("projects.details.activities.organizeAndAnalyze") }}
                  </Button>
                </div>
              </div>
            </TransitionGroup>
          </div>
          <div class="activities-tab__schedule-header">
            <div class="subtitle">
              {{ t("projects.details.activities.schedule.title") }}
            </div>

            <div v-if="project" class="position-relative">
              <Button
                class="align-center px-1"
                :variation="dropdown.active ? 'primary' : 'secondary'"
                @click="dropdown.active = !dropdown.active"
              >
                <SvgIcon icon="calendar2_icon" width="20" height="20" margin="none" />
                <SvgIcon
                  icon="chevron_icon"
                  width="20"
                  height="20"
                  margin="none"
                  :rotate="dropdown.active ? '180deg' : '0'"
                />
              </Button>

              <div v-show="dropdown.active" class="dropdown">
                <ButtonSpan
                  :icon="{ type: 'mdi', path: mdiPlusBox }"
                  :label="t('projects.details.activities.schedule.create')"
                  variation="default"
                  class="add-schedule"
                  :disabled="hasSchedule"
                  @click="(creatingSchedule = true), (dropdown.active = false)"
                />

                <ButtonSpan
                  icon="edit2_icon"
                  :label="t('projects.details.activities.schedule.edit')"
                  variation="default"
                  class="add-schedule"
                  :disabled="!hasSchedule"
                  @click="(creatingSchedule = true), (dropdown.active = false)"
                />
              </div>
            </div>
          </div>

          <div v-if="project">
            <Transition name="slide-fade">
              <ActitivieScheduleForm
                v-if="creatingSchedule"
                :schedules="project.schedules"
                @cancel="creatingSchedule = false"
                @submit="submitSchedule"
              />
            </Transition>
            <ActivitiesSchedule
              v-if="!creatingSchedule && hasSchedule"
              :schedules="project.schedules"
              :stages="project.stages"
              stage="construction"
            />
            <Checkpoint
              v-if="hasSchedule"
              :schedules="project.schedules"
              :stages="project.stages"
              stage="construction"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<route lang="yaml">
meta:
  layout: project-stage
  stage: interviews
  tab: activities
</route>

<style lang="scss" scoped>
.project-activities {
  min-height: 100vh;
}
.project-container {
  padding-top: 3.75rem;
}

.project-wrapper {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  padding-block-end: 2.5rem;
  background: $white-full;

  .project-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid $gray-border;
    padding-block: 1.3125rem;
    margin-block-end: 1.3125rem;

    .title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: $gray-dark;
      font-size: 1.125rem;
      font-weight: bold;
      text-transform: uppercase;
    }

    .button-pluss {
      display: flex;
      flex-direction: row;
      gap: 0.25rem;
    }
  }

  .project-body {
    display: flex;
    flex-direction: column;
    padding-block-end: 1rem;

    .list-activities {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .dates {
        div {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 1rem;

          span {
            width: 6.75rem;
          }

          input {
            width: 6rem;
            padding: 0.25rem;
            font-size: 1rem;
          }
        }
      }

      .status {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }

      &-titles {
        display: grid;
        grid-template-columns: 2fr 2fr 2fr 1fr 2fr;
        gap: 1rem;

        div {
          color: $primary;
          font-size: 0.75rem;
          font-weight: bold;
          text-transform: uppercase;
        }
      }

      &-candidate {
        display: grid;
        grid-template-columns: 2fr 2fr 2fr 1fr 2fr;
        align-items: center;
        gap: 1rem;
      }
    }
  }
}
.icon-checked {
  color: $green-medium;
}
</style>

<style lang="scss" scoped>
.align-center {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.justify-end {
  justify-content: flex-end;
}

.trash-icon {
  color: $gray-medium;
}

.check-icon {
  color: $green;
}

.px-1 {
  padding-inline: 0.5rem;
}

.position-relative {
  position: relative;
}

.add-schedule {
  color: $gray-low;

  &:hover {
    color: $primary;
    font-weight: bold;
  }
}

.dropdown {
  position: absolute;
  z-index: 10;
  top: 100%;
  right: 50%;
  display: flex;
  min-width: max-content;
  flex-direction: column;
  padding: 0.75rem;
  border-radius: 6px;
  background-color: $white-full;
  box-shadow: 0 2px 4px #00000029;
  gap: 0.5rem;
}

.activities-tab {
  overflow: hidden;

  &__header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
    gap: 1rem;
  }

  &__schedule-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e9ecef;
    margin: 1rem 0;
    padding-block: 1rem;
    margin-block-end: 3.5rem;
  }

  &__form {
    width: 50%;
  }
}

@media screen and (max-width: 1024px) {
  .activities-tab {
    &__form {
      width: auto;
    }
  }
}

.subtitle {
  color: #666;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 700;
}

.slide-fade {
  &-enter-active {
    transition: all 400ms ease-out;
  }

  &-leave-active {
    transition: all 200ms cubic-bezier(1, 0.5, 0.3, 1);
  }

  &-enter-from,
  &-leave-to {
    opacity: 0;
    transform: translateY(-1.5rem);
  }
}
.tooltip-message {
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  color: $gray-lower;
  text-transform: uppercase;
}
.project-body .list-activities .list-activities-candidate .status {
  :deep(.base-tooltip) .tooltip .tooltip-message {
    color: $gray-lower;
  }
}
.v-enter-active,
.v-leave-active {
  transition: opacity 400ms ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
.search-container {
  display: flex;
  justify-content: flex-end;
  .search {
    max-width: 20rem;
    padding-block: 0.375rem;
  }
}
</style>
