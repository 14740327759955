<script setup lang="ts">
import Analyses from "./components/Analyses.vue";
import IdealProfileTabs from "./components/IdealProfileTabs.vue";

const props = defineProps<{ id: string }>();

const AsyncBaseAnalysisForm = defineAsyncComponent(() => import("./components/forms/BaseAnalysisForm.vue"));

const router = useRouter();
const route = useRoute();

const { data: project, error } = await useProject(props.id);

const {
  data: construction,
} = useConstructionOrganizationQuery(String(route.params.id));

const {
  stageTabs,
} = useTabsProject(props.id);

const currentRoute = computed(() => stageTabs.value.findIndex(tab => tab.key === router.currentRoute.value.meta.tab));

function goTo(tabIndex: number) {
  if (!stageTabs.value || !stageTabs.value[tabIndex])
    return;

  router.push(stageTabs.value[tabIndex].path);
}

const editing = ref(false);

const constructionId = computed(() => {
  return construction.value?._id;
});

const {
  mutate: patchConstruction,
} = usePatchConstructionOrganizationQuery();

function update(data: { list: string[]; opinion: string }) {
  patchConstruction({
    _id: String(constructionId.value),
    governance: {
      ...construction.value?.governance,
      analyses: data.list.map(el => ({
        analysis_type: "GOVERNANCE",
        description: el,
      })),
      consultant_opinion: data.opinion,
    },
  });
}
</script>

<template>
  <section class="project-section">
    <div v-if="error">
      could not load
    </div>
    <div class="project-container">
      <ProjectStages :id="props.id" />
      <div v-if="project && construction" class="project-wrapper">
        <ProjectTabs :stage-tabs="stageTabs" :active="currentRoute" class="tabs" @change="goTo($event)" />
        <IdealProfileTabs
          :editing="editing"
          :project-id="project._id"
          :corporation-avatar-url="project.corporation?.avatar_url"
          :stages="project.stages"
          @edit="editing = true"
        />
        <div v-if="!editing" class="project-content">
          <Analyses
            :analyses="construction.governance.analyses.map(el => el.description)"
            :opinion="construction.governance.consultant_opinion"
          />
        </div>
        <div v-else class="project-content">
          <AsyncBaseAnalysisForm
            :list="construction.governance.analyses.map(el => el.description)"
            :consultant-opinion="construction.governance.consultant_opinion"
            @update="update($event)"
            @cancel="editing = false"
            @save="editing = false"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<route lang="yaml">
meta:
  layout: project-stage
  stage: construction
  tab: ideal-profile
  ideal-profile: governance
</route>

<style scoped lang="scss">
.project-section {
  min-height: 100vh;
}
.project-container {
  padding-top: 3.75rem;
}

.project-wrapper {
  background: white;
  padding-bottom: 2.5rem;
  margin-bottom: 2.5rem;
}
.project-content {
  margin-inline: 2.5rem;
}

.editable-container {
  margin: 1.5rem;
}
.base-item {
  display: flex;
  align-items: baseline;
  gap: 0.25rem;
  margin-block: 0.5rem 1.5rem;

  .item-input {
    width: 100%;
  }
}
button.panel-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  margin: 0.5rem 0 1.5rem 0;

  &:focus,
  &:hover {

    &.add,
    &.add > span {
      color: $red-violet-medium;

      svg {
        vertical-align: bottom;
      }
    }
  }

  &.add,
  &.add > span {
    color: $gray-dark;
    font-size: 0.75rem;
    letter-spacing: 0;
    font-weight: 600;

    svg {
      vertical-align: bottom;
    }
  }
}
.form-actions {
  display: flex;
  gap: 2rem;
  margin-block: 1.5rem;
}
</style>
