<script lang="ts">
import { useI18n } from "vue-i18n";
import { Avatar } from "@/components/avatar";
import { SvgIcon } from "@/components/icon";
import { completeDate } from "@/utils/dates";
import { parsePhone } from "@/utils/parsers";
import { getCorporation } from "@/http_services/entities/corporation";
import SectorSection from "@/corporations/pages/corporations/components/Sections/SectorSection.vue";
import DetailsSection from "@/corporations/pages/corporations/components/Sections/DetailsSection.vue";
import AboutSection from "@/corporations/pages/corporations/components/Sections/AboutSection.vue";
import StrategySection from "@/corporations/pages/corporations/components/Sections/StrategySection.vue";
import GovernanceSection from "@/corporations/pages/corporations/components/Sections/GovernanceSection.vue";
import HistoricSection from "@/corporations/pages/corporations/components/Sections/HistoricSection.vue";

export default defineComponent({
  name: "CorporationTab",
  components: {
    Avatar,
    SvgIcon,
    SectorSection,
    DetailsSection,
    AboutSection,
    HistoricSection,
    GovernanceSection,
    StrategySection,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n({ useScope: "global" });
    const corporation = ref(null);
    const corporationMap = computed(() => {
      const map = new Map();

      if (corporation.value) {
        const { phone, address: ad, url } = corporation.value;

        map.set(t("corporations.phone"), parsePhone(phone?.calling_code, phone?.number));
        map.set(t("corporations.location"), ad?.name);
        map.set(t("corporations.domain"), url);
      }

      return map;
    });

    const hoveredLinkedin = ref(false);
    const hoveredFacebook = ref(false);

    const lastUpdated = computed(() => {
      const { updated_at } = corporation.value;
      if (updated_at)
        return completeDate(updated_at);
      return "";
    });

    onMounted(async () => {
      corporation.value = await getCorporation(props.id);
    });

    return {
      t,
      corporation,
      corporationMap,
      hoveredLinkedin,
      hoveredFacebook,
      lastUpdated,
    };
  },
});
</script>

<template>
  <div class="tab-container">
    <template v-if="corporation">
      <div class="corporation-summary">
        <div>
          <Avatar
            :src="corporation.avatar_url"
            :alt="corporation.name"
            size="140px"
            placeholder="general-company"
            type="native"
            :has-alternative-icon="false"
            image-size="original"
          />

          <div>
            <p>
              <b>{{ corporation.name }}</b> <span>({{ corporation.serial }})</span>
            </p>

            <span v-show="lastUpdated">
              <SvgIcon icon="refresh_icon" width="12" height="12" />
              {{ lastUpdated }}
            </span>
          </div>
        </div>
        <div>
          <p v-for="[key, value] in corporationMap" :key="key">
            <b>{{ key }}:</b> {{ value }}
          </p>
          <p v-if="corporation.linkedin_url || corporation.facebook_url">
            <b>{{ t("corporations.socialNetworks") }}:</b>
            <a
              v-show="corporation.linkedin_url"
              :href="corporation.linkedin_url"
              target="_blank"
              @mouseover="hoveredLinkedin = true"
              @mouseout="hoveredLinkedin = false"
            >
              <SvgIcon
                v-show="!hoveredLinkedin"
                icon="linkedin_icon"
                margin="left"
                width="20"
                height="20"
              />
              <SvgIcon
                v-show="hoveredLinkedin"
                icon="linkedin_icon_blue"
                margin="left"
                width="20"
                height="20"
              />
            </a>

            <a
              v-show="corporation.facebook_url"
              :href="corporation.facebook_url"
              target="_blank"
              @mouseover="hoveredFacebook = true"
              @mouseout="hoveredFacebook = false"
            >
              <SvgIcon
                v-show="!hoveredFacebook"
                icon="facebook_icon"
                margin="left"
                width="20"
                height="20"
              />
              <SvgIcon
                v-show="hoveredFacebook"
                icon="facebook_icon_blue"
                margin="left"
                width="20"
                height="20"
              />
            </a>
          </p>
        </div>
      </div>

      <SectorSection
        class="tab-section --large-spacing"
        :business-categories="corporation.business_categories || []"
        :business-functions="corporation.business_function || []"
        no-edit
      />

      <DetailsSection
        class="tab-section"
        :annual-revenue="corporation.revenues"
        :net-worth="corporation.patrimony"
        :employees-number="corporation.number_of_employees"
        no-edit
      />

      <AboutSection class="tab-section" :description="corporation.description" no-edit />

      <HistoricSection
        class="tab-section"
        :historic="{
          foundation_year: corporation.foundation_year,
          historic: corporation.historic,
        }"
        no-edit
      />

      <GovernanceSection class="tab-section" :governance="corporation.governance || {}" no-edit />

      <StrategySection class="tab-section" :strategy="corporation.strategy || {}" no-edit />
    </template>
  </div>
</template>

  <style lang="scss" scoped>
  .tab-container {
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    padding-block-end: 2.5rem;
    background: #fff;
  }
  .tab-section {
    margin-bottom: 2.5rem;

    &.--large-spacing {
      margin-bottom: 3rem;
    }

    &:deep {
      .section-title {
        color: $gray-dark;
        text-transform: capitalize;
      }
    }
  }

  .corporation-summary {
    display: grid;
    align-items: space-between;
    margin-bottom: 2rem;
    gap: 1rem;
    grid-template-columns: 1fr;

    & > div:first-child {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;

      svg {
        color: $gray-medium;
      }

      span {
        color: $gray-low;
        font-size: 0.75rem;
      }

      div {
        display: flex;
        height: 50%;
        flex-direction: column;
        justify-content: space-around;
      }
    }

    & > div:last-child {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      p {
        margin-bottom: 1rem;
        line-height: 1.2;
      }

      svg {
        vertical-align: top;
      }
    }
  }

  @media (min-width: 768px) {
    .corporation-summary {
      gap: 0;
      grid-template-columns: repeat(2, 1fr);

      & > div:last-child p {
        margin-bottom: 0;
      }
    }
  }
  </style>
