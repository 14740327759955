<script lang="ts">
import type { Ref } from "vue";
import {
  defineComponent,
  inject,
} from "vue";
import { useI18n } from "vue-i18n";
import { SectionTitle } from "@/components/section_title";
import type { Person } from "@/http_services/entities/types";
import WorkExperiences from "@/persons/pages/persons/components/PersonDetail/WorkExperiences/WorkExperiences.vue";

export default defineComponent({
  name: "WorkExperienceTab",
  components: {
    SectionTitle,
    WorkExperiences,
  },
  setup() {
    const { t } = useI18n();
    const person = inject("person") as Ref<Person>;

    return {
      t,
      person,
    };
  },
});
</script>

<template>
  <div v-if="person" class="tab-container">
    <SectionTitle :title="t('persons.details.workExperience')" />
    <WorkExperiences v-if="person.work_experience?.length" :work-experiences="person.work_experience || []" />
    <p v-else>
      {{ t("persons.details.placeholders.emptyAttribute") }}
    </p>
  </div>
</template>

<style lang="scss" scoped>
.tab-section {
  margin-bottom: 2.5rem;

  &.--large-spacing {
    margin-bottom: 3rem;
  }
}
</style>
