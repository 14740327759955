<script lang="ts">
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { CircleStencil, Cropper } from "vue-advanced-cropper";
import Dialog from "primevue/dialog";
import { Button } from "@/components/button/";
import { resizeImageBase64 } from "@/utils/images/resize-images";
import "vue-advanced-cropper/dist/style.css";
import "vue-advanced-cropper/dist/theme.bubble.css";

export default defineComponent({
  name: "FileInput",
  components: {
    Dialog,
    Button,
    Cropper,
    // eslint-disable-next-line vue/no-unused-components
    CircleStencil,
  },
  emits: ["changeAvatar"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const displayCropper = ref(false);
    const imgCrop = ref();
    const cropper = ref();
    const fileInput = ref();
    const avatarBlobUrl = ref();
    const file = ref();
    const fileName = ref();

    const openCropper = () => {
      displayCropper.value = true;
    };

    const closeCropper = () => {
      displayCropper.value = false;
    };

    const handleFile = (event: Event) => {
      const input = event.target as HTMLInputElement;
      if (input.files)
        [file.value] = input.files;

      imgCrop.value = URL.createObjectURL(file.value as Blob);
      openCropper();
    };

    const cropImage = async () => {
      const { canvas } = cropper.value.getResult();
      avatarBlobUrl.value = await resizeImageBase64(canvas.toDataURL("image/jpg", 1));
      fileInput.value.value = null;
      closeCropper();
      fileName.value = file.value.name;
      emit("changeAvatar", avatarBlobUrl.value.split(",")[1]);
    };

    const clear = () => {
      emit("changeAvatar", null);
      fileName.value = "";
    };

    return {
      t,
      handleFile,
      fileName,
      displayCropper,
      imgCrop,
      openCropper,
      closeCropper,
      cropImage,
      cropper,
      fileInput,
      avatarBlobUrl,
      clear,
    };
  },
});
</script>

<template>
  <div class="avatar-upload">
    <input
      id="avatarUpload"
      ref="fileInput"
      type="file"
      accept=".png, .jpg, .jpeg"
      @change="handleFile"
    >
    <label for="avatarUpload" class="change-icon">
      <span class="search">{{ t("persons.forms.avatar.search") }}</span>
      <span class="file-name">
        <p>{{ fileName }}</p>
      </span>
    </label>
  </div>

  <Dialog v-model:visible="displayCropper" class="dialog" :closable="false" :draggable="false">
    <Cropper
      ref="cropper"
      class="cropper"
      :src="imgCrop"
      :stencil-component="$options.components.CircleStencil"
      :stencil-props="{
        aspectRatio: 1,
      }"
      :style="{ width: '1024px', height: '576px' }"
    />
    <template #footer>
      <div class="button-wrapper">
        <Button variation="light" filled type="submit" @click="closeCropper">
          {{ t("input.avatarInput.cancel") }}
        </Button>
        <Button variation="secondary" @click="cropImage">
          {{ t("input.avatarInput.defineImage") }}
        </Button>
      </div>
    </template>
  </Dialog>
</template>

<style lang="scss" scoped>
.avatar-upload {
  input {
    display: none;
  }

  label {
    display: flex;

    .search {
      display: flex;
      height: 28px;
      align-items: center;
      padding: 5px 10px;
      border: 1px solid $yellow-dark;
      border-radius: 6px;
      margin-right: 20px;
      color: $yellow-dark;
      cursor: pointer;
      font-size: 14px;
      text-transform: uppercase;

      &:hover {
        background-color: $yellow-dark;
        color: $white;
      }
    }

    .file-name {
      display: flex;
      align-items: center;
      padding: 5px 10px;
      border: 1px solid $gray-border;
      border-radius: 6px;
      color: $gray-medium;
      cursor: pointer;
      font-size: 12px;

      p {
        width: 200px;
        word-break: break-all;
      }
    }
  }
}

.button-wrapper {
  padding-inline-start: start;
  .button {
    display: inline;
    height: 36px;
    padding-right: 15px;
    padding-left: 15px;
    border-radius: 8px;

    &.cancel {
      background-color: $gray-lighter;
      color: $gray-dark;
      opacity: 0.6;
    }
  }
}
</style>
