export const errors = {
  titles: {
    error: "Error!",
    ops: "Ops!",
  },
  buttons: {
    tryAgain: "Try again",
    close: "Close",
  },
  messages: {
    save: "There was an error trying to save the project!",
    dates: "Invalid information, start date cannot be greater than the end date.",
  },
  pdf: {
    download: "There was an error trying to export the PDF!",
  },
};
