<template>
  <div>
    <div>Side Content</div>
    <div class="scrollable-content example-scrollable-full">
      <div v-for="v in 100" :key="v">
        {{ v }}
      </div>
    </div>
  </div>
</template>

<route lang="yaml">
meta:
  layout: home
</route>

<style scoped lang="scss">
.example-scrollable-full {
    gap: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 4rem;
    div {
        border: 1px solid;
        width: 10rem;
        text-align: center;
    }
}
</style>
