import { activitiesService } from "@/http_services/client";
import type {
  BaseScreeningsActivity,
  ScreeningAnnotationsPatch,
  ScreeningsActivity,
  ScreeningsActivityDto,
} from "@/types/screening-activity";
import {
  ScreeningsActivityDtoSchema,
} from "@/types/screening-activity";
import { parseParams } from "@/utils/parsers";

export interface ActivitiesSearchType {
  project_id: string
  candidate_person_id?: string
  recruiter_person_id?: string
  sort?: string[]
}

export async function getScreeningActivities(query: ActivitiesSearchType) {
  const params = parseParams(query);
  return activitiesService.get("screenings/activities/search", { params })
    .then(res => res.data?.data);
}

export async function getScreeningActivity(id: string): Promise<ScreeningsActivityDto> {
  return activitiesService.get(`screenings/activities/${id}`)
    .then(res => res.data?.data)
    .then((data) => {
      const result = ScreeningsActivityDtoSchema.safeParse(data);
      if (!result.success)
        console.warn("[screening-activity]", id, result.error);
      return data;
    });
}

export async function addScreeningActivity(activity: BaseScreeningsActivity) {
  return activitiesService.post("screenings/activities", { activity })
    .then(res => res.data?.data);
}

export async function updateScreeningActvity(id: string, activity: ScreeningsActivity) {
  return activitiesService.put(`screenings/activities/${id}`, { activity })
    .then(res => res.data?.data);
}

export async function patchScreeningAnnotations(id: string, annotations: ScreeningAnnotationsPatch) {
  return activitiesService.patch(`screenings/activities/${id}/annotations`, { annotations })
    .then(res => res.data?.data);
}

export async function addCandidateToScreening(activity_id: string, person_id: string) {
  return activitiesService.post(`screenings/activities/${activity_id}/add-candidate?person_id=${person_id}`, {})
    .then(res => res.data?.data);
}

export async function getScreeningListActivity(project_id: string) {
  return activitiesService.get(`screenings/activities/project/${project_id}/candidates/person-ids`)
    .then(res => res.data?.data);
}
