<script setup lang="ts">
import Slider from "primevue/slider";

const props = withDefaults(
  defineProps<{
    modelValue: number | number[] | undefined
    position?: string
    label: string
    canSave?: boolean
  }>(),
  {
    label: "",
    modelValue: 0,
    position: "left",
  },
);
const emit = defineEmits(["update:modelValue"]);
const { modelValue, position, label } = toRefs(props);
const canSaveSlider = ref(props.canSave);
watchEffect(() => {
  canSaveSlider.value = props.canSave;
});
const updatedValue: Ref<number | number[] | null> = ref(null);
function update(value?: number | number[] | null) {
  updatedValue.value = value === 0 ? null : (value as number | number[] | null);
  emit("update:modelValue", updatedValue.value);
  value ? (canSaveSlider.value = true) : (canSaveSlider.value = false);
}

function checkValue(value: number | number[]): number {
  return Array.isArray(value) ? 85 : value === 100 ? 85 : value >= 15 ? value - 8 : value;
}
</script>

<script lang="ts">
export default defineComponent({
  inheritAttrs: false,
});
</script>

<template>
  <div class="slider-input">
    <div :class="[canSaveSlider && !modelValue ? 'border' : 'no-border']">
      <p :class="label ? 'right' : position">
        <span class="slide-label">
          {{ label }}
        </span>
        <span
          class="slide-score"
          :style="{
            marginLeft: modelValue ? `${checkValue(modelValue)}%` : 0,
          }"
        >
          {{ modelValue }} %
        </span>
      </p>
      <Slider
        :model-value="modelValue"
        v-bind="$attrs"
        class="slider"
        @update:model-value="update($event)"
      />
    </div>
    <slot name="warning" v-bind="{ modelValue }" />
  </div>
</template>

<style lang="scss" scoped>
.border {
  padding: 0.3125rem;
  border: 1px solid $red-warning;
  border-radius: 0.375rem;
}

.no {
  border: none;
}

.slider-input {
  position: relative;
  transition: 200ms;

  p {
    position: relative;
    width: inherit;
    margin-bottom: 0.25rem;
    font-size: 0.875rem;
    margin-inline: 0.5rem;
  }

  .right,
  .left {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .slide-score {
    height: 1.5rem;
    margin-block-start: 0.625rem;
    transition: 100ms;
    white-space: nowrap;
  }
}

:deep(.slider.p-component) {
  height: 1rem;
  border: 1px solid $gray-border;
  background-color: $white-full;
  border-radius: 0.5rem;
  margin-inline: 0.5rem;

  .p-slider-range {
    top: -1px;
    left: -1px;
    height: 1rem;
    background-color: $yellow;
    border-radius: 0.5rem;
  }

  .p-slider-handle {
    border: none;
    box-shadow: inset 0 3px 6px #00000029, 0 3px 6px #00000029;

    &:hover {
      background-color: $white-full;
    }
  }
}

.no-border,
.border {
  display: flex;
  flex-direction: column;
}

:slotted(.warning) {
  color: $red-warning;
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin-inline-start: 1rem;
}
</style>
