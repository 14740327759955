import { z } from "zod";

import { EntitySchema } from "@/types/entity";

export const BasePersonInActivitySchema = z.object({
  person_id: z.string(),
});

export const PersonInActivitySchema = BasePersonInActivitySchema.extend({
  first_name: z.string(),
  last_name: z.string(),
  avatar_url: z.string().nullable(),
});

export const BaseActivitySchema = z.object({
  project_id: z.string(),
  activity_date: z.coerce.date().nullable(),
  recruiter: BasePersonInActivitySchema,
  candidate: PersonInActivitySchema.nullable(),
});

export const ActivitySchema = BaseActivitySchema.extend({
  recruiter: PersonInActivitySchema,
}).strict();

export type Activity = z.infer<typeof ActivitySchema>;

const ActivityStripSchema = ActivitySchema.strip();

export const ActivityDtoSchema = z.intersection(EntitySchema, ActivityStripSchema);

export type ActivityDto = z.infer<typeof ActivityDtoSchema>;
