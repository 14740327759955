import { filesService } from "../client";
import type { CreateAvatarType } from "./types";
import type { ImagesTypesEnum } from "@/schemas/enums/images";

export async function createAvatar(file: Blob, type: ImagesTypesEnum): Promise<CreateAvatarType> {
  const response = await filesService.post(`upload?associated_entity=${type}`, {
    file,
  }, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data.data;
}
