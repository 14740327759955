<script setup lang="ts">
import { computed, onMounted, provide, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import { pipe } from "lodash/fp";
import { cloneDeep, get, set, uniqBy } from "lodash";
import { completeDate } from "@/utils/dates";
import type { RelatedPerson } from "@/entities/common";
import type { Person } from "@/schemas/person";
import type { Project } from "@/entities/project";
import type { Corporation } from "@/entities/corporation";
import { Table } from "@/components/table";
import { Tooltip } from "@/components/tooltip";
import { Avatar } from "@/components/avatar";
import type { IList } from "@/components/avatar-list";
import { AvatarList } from "@/components/avatar-list";
import { getPerson } from "@/http_services/entities/persons";
import { searchCorporationDetailsProjects } from "@/http_services/entities/corporation";
import PersonDetail from "@/persons/pages/persons/components/PersonDetail/PersonDetail.vue";

interface Participant {
  src: string
  alt: string
}

interface TableData {
  project: string
  status: string
  participants: Participant[]
}

interface KeyObject {
  [key: string]: TableData
}

interface Status {
  [key: string]: string
}

const props = defineProps<{
  corporation: Partial<Corporation>
}>();

const ROWS_PER_PAGE = 10;

const { t } = useI18n();

const columns = [
  { field: "type", header: t("corporations.project.table.field.type") },
  { field: "project", header: t("corporations.project.table.field.project") },
  { field: "duration", header: t("corporations.project.table.field.period") },
  { field: "participants", header: t("corporations.project.table.field.participants") },
  { field: "status", header: t("corporations.project.table.field.status") },
];

const project_status: Status = {
  active: "--in-progress-project",
  cancelled: "--cancelled-project",
  concluded: "--approved-project",
  suspended: "--suspended-project",
  bullhorn: "--bullhorn-project",
  draft: "--draft-project",
};

const status: Status = {
  active: "--in-progress",
  cancelled: "--cancelled",
  concluded: "--approved",
  suspended: "--suspended",
  draft: "--draft",
};

const DEFAULT_MAX_COUNT = 3;

const LIMIT_MAX_COUNT = 4;

const table = ref<KeyObject>({});

const tableData = computed<TableData[]>(() => {
  const items = Object.keys(table.value)?.map((key: string) => table.value[key]);

  return items;
});

const selectedPerson = ref<Person | undefined>(undefined);

const showPersonDetail = ref(false);

const params = reactive({
  limit: 30,
  skip: 0,
});

const totalItems = ref(0);

const totalPages = ref(0);

function setProjectType(_projects: Partial<Project>[]) {
  _projects?.forEach((project: Partial<Project>) => {
    const key = project._id || "";

    set(table.value, key, {
      ...table.value[key],
      type: project?.project_type?.charAt(0) || "P",
    });
  });

  return _projects;
}

function setProject(_projects: Partial<Project>[]) {
  _projects?.forEach((project: Partial<Project>) => {
    const id = project._id || "";

    const title = `${project.serial} - `.concat(project.project_name || "");

    set(table.value, id, {
      ...table.value[id],
      title,
      id,
    });
  });

  return _projects;
}

function isCompletePeriod(project: Partial<Project>) {
  return (
    ["CONCLUDED", "CANCELLED"].includes(project?.status || "")
    && (project?.started_at || project?.inserted_at)
    && project?.finished_at
  );
}

function setProjectPeriod(_projects: Partial<Project>[]) {
  _projects?.forEach((project: Partial<Project>) => {
    const key = project._id || "";

    const started_at = project?.started_at || project?.inserted_at;

    set(table.value, key, {
      ...table.value[key],
      period: isCompletePeriod(project)
        ? `${completeDate(started_at)} - ${completeDate(project.finished_at)}`
        : completeDate(started_at),
    });
  });

  return _projects;
}

function setClassStatus(_projects: Partial<Project>[]) {
  _projects?.forEach((project: Partial<Project>) => {
    const key = project._id || "";

    if (project?.status) {
      const projectStatus = project.status.toLowerCase();

      const projectStatusKey
        = project?.external_ids?.bullhorn_id?.record_id
          && project?.external_ids?.bullhorn_id?.record_id !== 0
          ? "bullhorn"
          : projectStatus;

      set(table.value, key, {
        ...table.value[key],
        class_status_project: project_status[projectStatusKey],
        class_status: status[projectStatus],
      });
    }
  });

  return _projects;
}

function setStatus(_projects: Partial<Project>[]) {
  _projects?.forEach((project: Partial<Project>) => {
    const key = project._id || "";

    if (project?.status) {
      const person_data = get(project, "placement_details.candidate.person_data");
      const candidate_id = get(project, "placement_details.candidate.person_id");

      if (project.status === "CONCLUDED" && person_data) {
        const name = person_data?.first_name?.concat(` ${person_data.last_name}`);

        set(table.value, key, {
          ...table.value[key],
          status: {
            status: project.status,
            person: {
              id: candidate_id,
              name,
              avatar_url: person_data?.avatar_url,
            },
          },
        });
      }
      else {
        set(table.value, key, {
          ...table.value[key],
          status: {
            status: project.status,
          },
        });
      }
    }
  });

  return _projects;
}

function getAvatarParticipants(participants: Partial<RelatedPerson>[]): IList[] {
  if (!participants?.length)
    return [];

  return uniqBy(
    participants.map(participant => ({
      id: participant.person_id || "",
      src: participant.avatar_url || "",
      alt:
        (participant.first_name
          && participant.first_name.concat(` ${participant.last_name}`))
        || "",
    })),
    "id",
  );
}

function setParticipants(_projects: Partial<Project>[]) {
  _projects?.forEach((project: Partial<Project>) => {
    const key = project._id || "";

    set(table.value, key, {
      ...table.value[key],
      participants: getAvatarParticipants(get(project, "participants", [])),
      max_counter:
        get(project, "participants", [])?.length > LIMIT_MAX_COUNT
          ? DEFAULT_MAX_COUNT
          : get(project, "participants", [])?.length,
    });
  });

  return _projects;
}

async function onSelectPerson(id: string) {
  const data = await getPerson(id);

  if (data) {
    selectedPerson.value = cloneDeep(data);
    showPersonDetail.value = true;
  }
}

async function onPageChange(skip: number) {
  params.skip = skip;

  const isLastPage = tableData.value?.length === skip + ROWS_PER_PAGE;

  const haveMoreItems = tableData.value?.length < totalItems.value;

  if (isLastPage && haveMoreItems && props.corporation.id) {
    params.skip = skip + ROWS_PER_PAGE;

    const { data } = await searchCorporationDetailsProjects(props.corporation.id, params);
    pipe(
      setProjectType,
      setProject,
      setProjectPeriod,
      setParticipants,
      setClassStatus,
      setStatus,
    )(data);
  }
}

function showTooltip(text: string) {
  const maxLength = 31;

  return text?.length > maxLength;
}

provide("onCloseDetails", () => {
  showPersonDetail.value = false;
  selectedPerson.value = undefined;
});

provide("selectedPerson", selectedPerson);

onMounted(async () => {
  if (props.corporation.id) {
    const { data, total } = await searchCorporationDetailsProjects(
      props.corporation.id,
      params,
    );

    pipe(
      setProjectType,
      setProject,
      setProjectPeriod,
      setParticipants,
      setClassStatus,
      setStatus,
    )(data);

    totalItems.value = total;
    totalPages.value = data?.length / ROWS_PER_PAGE;
  }
});
</script>

<template>
  <div class="project__container" :style="{ height: tableData?.length ? '' : '280px' }">
    <Table
      :columns="columns"
      :data="tableData"
      :paginator="true"
      :rows="ROWS_PER_PAGE"
      :total-records="totalItems"
      @onChange="onPageChange"
    >
      <template #column-body-0="{ data }">
        <div class="table__row">
          <div class="table__cell--type">
            <span>{{ data.type }}</span>
          </div>
        </div>
      </template>
      <template #column-body-1="{ data }">
        <div class="table__row">
          <div class="table__cell--project">
            <router-link :to="`/projects/${data.id}/construction/activities`" target="_blank">
              <Tooltip
                v-if="showTooltip(data.title)"
                class="tooltip"
                :tooltip-title="data.title"
                tooltip-width="max-content"
                y-align="top"
                x-align="center"
              >
                <p :class="`cell__status${data.class_status_project}`">
                  {{ data.title }}
                </p>
              </Tooltip>
              <p v-else :class="`cell__status${data.class_status_project}`">
                {{ data.title }}
              </p>
            </router-link>
          </div>
        </div>
      </template>
      <template #column-body-2="{ data }">
        <div class="table__row">
          <p class="table__cell--period">
            {{ data.period }}
          </p>
        </div>
      </template>
      <template #column-body-3="{ data }">
        <div class="table__row">
          <div class="table__cell--participants">
            <AvatarList
              :list="data.participants"
              :max-counter="data.max_counter"
              avatar-size="2rem"
              counter-size="2rem"
            />
          </div>
        </div>
      </template>
      <template #column-body-4="{ data }">
        <div class="table__row">
          <div class="table__cell--status">
            <div
              v-if="data?.status?.status === 'CONCLUDED' && data?.status?.person"
              class="table__cell--status-concluded"
              @click="onSelectPerson(data.status.person.id)"
            >
              <Avatar
                size="1.875rem"
                placeholder="project_ideal_profile"
                :alt="data.status.person.name"
                :src="data.status.person.avatar_url"
                image-size="large"
              />
              <Tooltip
                v-if="showTooltip(data.status.person.name)"
                :tooltip-title="data.status.person.name"
                tooltip-width="max-content"
                y-align="top"
                x-align="center"
              >
                <p :class="`cell__status${data.class_status}`">
                  {{ data.status.person.name }}
                </p>
              </Tooltip>
              <p v-else :class="`cell__status${data.class_status}`">
                {{ data.status.person.name }}
              </p>
            </div>
            <p
              v-else-if="data?.status?.status === 'CONCLUDED' && !data?.status?.person"
              :class="`cell__status${data.class_status}`"
            >
              {{ t("corporations.project.cell.status.data_unavailable") }}
            </p>
            <p v-else :class="`cell__status${data.class_status}`">
              {{ t(`corporations.project.cell.status.${data?.status?.status}`) }}
            </p>
          </div>
        </div>
      </template>
    </Table>
  </div>
  <PersonDetail :visible="showPersonDetail" />
</template>

<style lang="scss" scoped>
.project {
  &__container {
    padding: 1.6rem 1rem 0;
  }
}

.table {
  &__row {
    display: flex;
    height: 56px;
    align-items: center;
    padding: 16px 8px;
    border-bottom: 1px solid rgb(102 102 102 / 10%);
    background: $white-full !important;
  }

  &__cell--type {
    display: flex;
    min-width: 2rem;
    max-width: 2rem;

    span {
      min-width: 2rem;
      max-width: 2rem;
      min-height: 2rem;
      max-height: 2rem;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin-left: 0.313rem;
      background-color: $gray-x16;
      color: $white-full;
      font-size: 1.5rem;
      font-weight: bold;
      line-height: 32px;
      text-align: center;
    }
  }

  &__cell--status {
    display: flex;
    min-width: 15.375rem;
    max-width: 18.375rem;
    align-items: center;

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-concluded {
      display: flex;
      min-width: 12.375rem;
      max-width: 12.375rem;
      align-items: center;
      cursor: pointer;

      img {
        min-width: 1.875rem;
        max-width: 1.875rem;
        margin-right: 0.5rem;
      }

      p {
        overflow: hidden;
        font-weight: bold;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  &__cell--project {
    display: flex;
    min-width: 16rem;
    flex: 1;
    cursor: pointer;
    font-weight: bold;
    text-decoration: none;

    a {
      text-decoration: none;
    }

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    a p {
      max-width: 16rem;
    }
  }

  &__cell--participants {
    display: flex;
    min-width: 9.375rem;
    max-width: 9.375rem;
    height: 60px;
    align-items: center;
  }

  &__cell--period {
    display: flex;
    min-width: 12.5rem;
    max-width: 12.5rem;
    flex: 1;
  }
}

.cell {
  &__status--in-progress-project {
    color: #96185f;
  }

  &__status--cancelled-project {
    color: #707070;
    font-weight: 700;
  }

  &__status--approved-project {
    color: #96185f;
  }

  &__status--suspended-project {
    color: #96185f;
  }

  &__status--bullhorn-project {
    color: #fcaf45;
  }

  &__status--draft-project {
    color: #666;
  }

  &__status--in-progress {
    color: #666;
  }

  &__status--cancelled {
    color: #707070;
    font-weight: 700;
  }

  &__status--approved {
    color: #96185f;
  }

  &__status--suspended {
    color: #666;
  }

  &__status--draft {
    color: #666;
  }
}

:deep(.p-datatable-thead) {
  background: #fcfcfc;
}

:deep(.p-datatable-tbody > tr > td) {
  padding: 0;
  background: none;
}

:deep(.avatar-list__container > span) {
  &:nth-of-type(1) {
    z-index: 3;
  }

  &:nth-of-type(2) {
    z-index: 2;
    transform: translateX(-50%);
  }

  &:nth-of-type(3) {
    z-index: 1;
    transform: translateX(-100%);
  }

  &:nth-of-type(4) {
    transform: translateX(-150%);
  }
}

:deep(.avatar-list__container > .extra__list--card-container) {
  z-index: 5;
}

:deep(.avatar-list__container > .extra__list--card-container > .extra__list--card) {
  cursor: default;
}
:deep(.imua-avatar) {
  margin-inline-end: 0.5rem;
}
:deep(.p-datatable) .p-datatable-tbody > tr:focus {
outline: none;
}
</style>
