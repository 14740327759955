import { activitiesService } from "@/http_services/client";
import type { BaseConstructionActivity, ConstructionActivityDto, ConstructionAnnotationsPatchType } from "@/types/construction-activity";
import { ConstructionActivityDtoSchema } from "@/types/construction-activity";
import { formatUrlParams } from "@/utils/parsers/url";
import { parseParams } from "@/utils/parsers";

export interface ActivitiesSearchType {
  project_id: string
  requester_person_id?: string
  recruiter_person_id?: string
}

export async function getProjectActivities(query: ActivitiesSearchType): Promise<ConstructionActivityDto[]> {
  const params = formatUrlParams(query);
  return activitiesService.get("constructions/activities/search", { params })
    .then(res => res.data?.data);
}

export async function getConstructionActivity(id: string): Promise<ConstructionActivityDto> {
  return activitiesService.get(`constructions/activities/${id}`)
    .then(res => res.data?.data)
    .then((data) => {
      const result = ConstructionActivityDtoSchema.safeParse(data);
      if (!result.success)
        console.warn("[construction-activity]", id, result.error);
      return data;
    });
}

export async function addConstructionActivities(activity: BaseConstructionActivity) {
  return activitiesService.post("constructions/activities", { activity })
    .then(res => res.data?.data);
}

export async function patchConstructionAnnotations(id: string, annotations: ConstructionAnnotationsPatchType) {
  return activitiesService.patch(`constructions/activities/${id}/annotations`, { annotations })
    .then(res => res.data?.data);
}

// move to zod
interface PaginationData {
  limit: number
  skip: number
  sort: string[]
  total: number
}
interface Success<T> { data: T }
type SuccessPaged<T> = PaginationData & Success<T>;
interface searchTagSchema {
  id: string
  inserted_at: string
  is_active: boolean
  serial: number
  text: string
  updated_at: string
  user_creator_id: string
}

export async function getTags(params: {
  tags_text: string
  skip: number
  limit: number
}): Promise<SuccessPaged<searchTagSchema[]>> {
  const parsedParams = parseParams(params);
  const { data } = await activitiesService.get("/tags/search/", {
    params: { ...parsedParams },
  });
  return data;
}
