<script lang="ts">
import { defineComponent } from "vue";
import SvgIcon from "@jamescoyle/vue-icon";

export default defineComponent({
  name: "ButtonIcon",
  components: {
    SvgIcon,
  },
  props: {
    icon: String,
    color: {
      type: String,
      default: "currentColor",
    },
    rotation: {
      type: [String, Number],
      default: 0,
    },
    size: {
      type: [String, Number],
      default: 24,
    },
  },
});
</script>

<template>
  <button
    v-bind="{
      ...$attrs,
      class: {
        [$attrs.class || '']: true,
        'imua-button-icon': true,
      },
      style: {
        ...$attrs.style,
        ['--icon-color']: color,
      },
    }"
  >
    <slot v-if="$slots.default" />
    <SvgIcon
      v-else
      class="icon"
      type="mdi"
      :path="icon"
      :size="size"
      :rotate="rotation"
    />
  </button>
</template>

<style lang="scss" scoped>
.imua-button-icon {
  padding: 0;
  border: none;
  margin: 0;
  background-color: transparent;
  color: $gray-border;
  cursor: pointer;

  &:hover {
    color: $gray;
  }

  .icon {
    transition: transform linear 100ms;
  }
}
</style>
