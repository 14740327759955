<script setup lang="ts">
import findIndex from "lodash-es/findIndex";
import findLastIndex from "lodash-es/findLastIndex";
import last from "lodash-es/last";
import cloneDeep from "lodash-es/cloneDeep";

import { estimatedTextLines, paginateTextPage } from "@/utils/text";

import type { IdealProfile, ScreeningActivity, ScreeningRankedCandidates } from "@/schemas/project";
import Resume from "@/common/components/Export/Resume.vue";
import ExportPage from "@/common/components/Export/ExportPage.vue";
import { formatScore } from "@/utils/numbers";

const props = withDefaults(
  defineProps<{
    bullet?: boolean
    isExport: boolean
    personName: string | null
    personFirstName: string | null
    idealProfile: IdealProfile
    screeningActivitie: ScreeningActivity
    screeningRanked: ScreeningRankedCandidates
    interviewAcademic: any
    interviewLanguage: any
    academicScore: number | null
    idealProfileAcademic: any
    idealProfileLanguage: any
  }>(),
  {
    bullet: true,
    isExport: false,
    personName: null,
    personFirstName: null,
  },
);
const { t } = useI18n();

interface PageValueType {
  id?: string | null
  score?: string | number | null
  bullet?: boolean
  value: string
}

interface PageItemsType {
  id?: string | null
  score?: string | number | null
  ideal_profile: PageValueType[]
  evaluated: PageValueType[]
}

interface PagesTypes {
  score?: string | number | null
  schoolings: PageItemsType[]
  languages: PageItemsType[]
}

const {
  isExport,
  bullet,
  personName,
  personFirstName,
  idealProfile,
  screeningActivitie,
  screeningRanked,
} = toRefs(props);

const emptyPage = {
  score: null,
  schoolings: [],
  languages: [],
} as PagesTypes;

const emptyItem = {
  id: "",
  score: null,
  ideal_profile: [],
  evaluated: [],
} as PageItemsType;

const pages = ref<PagesTypes[]>([]);

const boxWidthA = 410;
const boxWidthB = 395;
const maxLines = 16;
const fontSize = "14px";

function paginateSchooling() {
  const { schooling_presentation_evaluation }
    = screeningActivitie.value?.organization_and_assessment?.educational_background || {};

  const { ideal_profile_schooling_evaluation: evaluateSchooling }
    = schooling_presentation_evaluation || {};

  const _newPage: PagesTypes[] = [];

  _newPage[0] = cloneDeep(emptyPage);

  let _lines = 0;
  let _items = 0;

  const _list_items = idealProfile.value?.schooling || [];

  _list_items.forEach((item, index) => {
    const _new_empty_page = cloneDeep(emptyPage);
    _new_empty_page.score = screeningRanked.value?.experience_score || 0;

    if (index === 0)
      _newPage[0].score = _new_empty_page.score;

    const _list_b_index = findIndex(
      evaluateSchooling || [],
      evaluate => evaluate.ideal_profile_schooling_id === item.id,
    );
    const _list_b = (evaluateSchooling || [])[_list_b_index];

    const _new_empty_item = cloneDeep(emptyItem);
    _new_empty_item.id = item.id;
    _new_empty_item.score = _list_b?.score || null;

    if (index === 0)
      _newPage[0].schoolings[_items] = cloneDeep(_new_empty_item);

    const _last_page_index = findLastIndex(_newPage);
    const _last_exp_index = findLastIndex(_newPage[_last_page_index]?.schoolings);

    if (!_newPage[_last_page_index].schoolings[_items])
      _newPage[_last_page_index].schoolings[_items] = cloneDeep(_new_empty_item);

    const _last_a_exp = last(
      _newPage[_last_page_index]?.schoolings[_last_exp_index]?.ideal_profile,
    );
    const _last_b_exp = last(_newPage[_last_page_index]?.schoolings[_last_exp_index]?.evaluated);

    const _list_a_lines = estimatedTextLines(_last_a_exp?.value || "", fontSize, boxWidthA);
    const _list_b_lines = estimatedTextLines(_last_b_exp?.value || "", fontSize, boxWidthB);

    if (index > 0)
      _lines = _list_a_lines > _list_b_lines ? _list_a_lines : _list_b_lines;

    const _list_a_texts = paginateTextPage(
      item.education_text || "",
      fontSize,
      boxWidthA,
      maxLines,
      _lines,
    );
    const _list_b_texts = paginateTextPage(
      _list_b?.candidate_schooling || "",
      fontSize,
      boxWidthB,
      maxLines,
      _lines,
    );

    const _texts_length
      = _list_b_texts?.length > _list_a_texts?.length ? _list_b_texts?.length : _list_a_texts?.length;

    let _new_page_index = _last_page_index;

    for (let i = 0; i < _texts_length; i++) {
      const _a_value = _list_a_texts[i];
      const _b_value = _list_b_texts[i];

      if (!_newPage[_new_page_index].schoolings[_items]) {
        _newPage[_new_page_index].schoolings = [cloneDeep(_new_empty_item)];
        _items = 0;
      }

      if (_a_value) {
        _newPage[_new_page_index].schoolings[_items].ideal_profile.push({
          id: item.id,
          value: _a_value,
          bullet: !(i > 0),
        });
      }

      if (_b_value) {
        _newPage[_new_page_index].schoolings[_items].score
          = i + 1 === _texts_length ? _list_b?.score || 0 : null;

        _newPage[_new_page_index].schoolings[_items].evaluated.push({
          id: item.id,
          value: _b_value,
          bullet: !(i > 0),
        });
      }

      if (_texts_length > 1 && i + 1 < _texts_length) {
        _newPage.push(cloneDeep(_new_empty_page));
        _new_page_index++;
        _items = 0;
      }
    }
    _items++;
  });

  pages.value = _newPage;
}

function paginateLanguage() {
  const { languages_presentation_evaluation }
    = screeningActivitie.value?.organization_and_assessment?.educational_background || {};

  const { ideal_profile_languages_evaluation: evaluateLanguages }
    = languages_presentation_evaluation || {};

  const _newPage: PagesTypes[] = pages.value;

  let _lines = 0;
  let _items = 0;

  const _list_items = idealProfile.value?.languages || [];

  _list_items.forEach((item, index) => {
    const _new_empty_page = cloneDeep(emptyPage);
    _new_empty_page.score = screeningRanked.value?.experience_score || 0;

    const _list_b_index = findIndex(
      evaluateLanguages || [],
      evaluate => evaluate.ideal_profile_language_id === item.id,
    );
    const _list_b = (evaluateLanguages || [])[_list_b_index];

    const _new_empty_item = cloneDeep(emptyItem);
    _new_empty_item.id = item.id;
    _new_empty_item.score = _list_b?.score || null;

    const _last_page_index = findLastIndex(_newPage);
    const _last_exp_index = findLastIndex(_newPage[_last_page_index]?.languages);

    if (!_newPage[_last_page_index].languages[_items])
      _newPage[_last_page_index].languages[_items] = cloneDeep(_new_empty_item);

    const _last_a_exp = last(_newPage[_last_page_index]?.languages[_last_exp_index]?.ideal_profile);
    const _last_b_exp = last(_newPage[_last_page_index]?.languages[_last_exp_index]?.evaluated);

    const _list_a_lines = estimatedTextLines(_last_a_exp?.value || "", fontSize, boxWidthA);
    const _list_b_lines = estimatedTextLines(_last_b_exp?.value || "", fontSize, boxWidthB);

    if (index > 0) {
      _lines = _list_a_lines > _list_b_lines ? _list_a_lines : _list_b_lines;
    }
    else {
      const _last_schooling_index = findLastIndex(_newPage[_last_page_index]?.schoolings);
      const _schooling = _newPage[_last_page_index]?.schoolings[_last_schooling_index];

      const _last_schooling_ideal = last(_schooling.ideal_profile);
      const _last_schooling_evaluate = last(_schooling.evaluated);

      const _list_a_schooling = estimatedTextLines(
        _last_schooling_ideal?.value || "",
        fontSize,
        boxWidthA,
      );
      const _list_b_schooling = estimatedTextLines(
        _last_schooling_evaluate?.value || "",
        fontSize,
        boxWidthB,
      );

      _lines = _list_a_schooling > _list_b_schooling ? _list_a_schooling : _list_b_schooling;
    }

    const _list_a_texts = paginateTextPage(
      item.language_text || "",
      fontSize,
      boxWidthA,
      maxLines,
      _lines,
    );
    const _list_b_texts = paginateTextPage(
      _list_b?.candidate_language || "",
      fontSize,
      boxWidthB,
      maxLines,
      _lines,
    );

    const _texts_length
      = _list_b_texts?.length > _list_a_texts?.length ? _list_b_texts?.length : _list_a_texts?.length;

    let _new_page_index = _last_page_index;

    for (let i = 0; i < _texts_length; i++) {
      const _a_value = _list_a_texts[i];
      const _b_value = _list_b_texts[i];

      if (!_newPage[_new_page_index].languages[_items]) {
        _newPage[_new_page_index].languages = [cloneDeep(_new_empty_item)];
        _items = 0;
      }

      if (_a_value) {
        _newPage[_new_page_index].languages[_items].ideal_profile.push({
          id: item.id,
          value: _a_value,
          bullet: !(i > 0),
        });
      }

      if (_b_value) {
        _newPage[_new_page_index].languages[_items].score
          = i + 1 === _texts_length ? _list_b?.score || 0 : null;

        _newPage[_new_page_index].languages[_items].evaluated.push({
          id: item.id,
          value: _b_value,
          bullet: !(i > 0),
        });
      }

      if (_texts_length > 1 && i + 1 < _texts_length) {
        _newPage.push(cloneDeep(_new_empty_page));
        _new_page_index++;
        _items = 0;
      }
    }
    _items++;
  });

  pages.value = _newPage;
}

function checkIsValid(value: any) {
  return !(value === null || value === undefined);
}

watch(props, () => {
  pages.value = [];
  // paginateSchooling();
  // paginateLanguage();
});
</script>

<template>
  <ExportPage
    v-for="(page, index) in 1"
    :key="index"
    :is-export="isExport"
    :page-index="index"
    :page-name="t('exportPDF.labels.schooling')"
    :person-name="personName"
  >
    <div class="pdf-schooling">
      <div class="box-grid">
        <div class="titles titles-ideal">
          {{ t("exportPDF.labels.idealProfile") }}
        </div>
        <div class="titles titles-person">
          {{ personFirstName }}
        </div>
        <div class="box-item-score">
          <div class="box-score total">
            {{ formatScore(academicScore || 0) }}%
          </div>
        </div>
      </div>
      <div
        v-for="(item, item_idx) of idealProfileAcademic"
        :key="item_idx"
        class="box-grid"
        :class="{
          'box-border': item_idx + 1 === idealProfileAcademic.length && idealProfileLanguage.length > 0,
        }"
      >
        <div class="box-item-ideal">
          <Resume
            :show-title="false"
            :show-label="false"
            :show-circle="bullet"
            font-size="0.875rem"
            line-height="1.25rem"
            :items="[
              {
                bullet: true,
                value: item,
              },
            ]"
          />
        </div>
        <div class="box-item-persona">
          <Resume
            :show-title="false"
            :show-label="false"
            :show-circle="bullet"
            font-size="0.875rem"
            line-height="1.25rem"
            :items="[
              {
                bullet: true,
                value: interviewAcademic[item_idx].observations,
              },
            ]"
          />
        </div>
        <div class="box-item-score">
          <div v-if="checkIsValid(interviewAcademic[item_idx]?.score)" class="box-score">
            {{ formatScore(interviewAcademic[item_idx]?.score || 0) }}%
          </div>
        </div>
      </div>
      <div v-if="idealProfileLanguage" class="separator" />
      <div v-if="idealProfileLanguage" class="languages">
        <div v-for="(item, item_idx) in idealProfileLanguage" :key="item_idx" class="box-grid">
          <div class="box-item-ideal">
            <Resume
              :show-title="false"
              :show-label="false"
              :show-circle="bullet"
              font-size="0.875rem"
              line-height="1.25rem"
              :items="[
                {
                  bullet: true,
                  value: item,
                },
              ]"
            />
          </div>
          <div class="box-item-persona">
            <Resume
              :show-title="false"
              :show-label="false"
              :show-circle="bullet"
              font-size="0.875rem"
              line-height="1.25rem"
              :items="[
                {
                  bullet: true,
                  value: interviewLanguage[item_idx]?.observations,
                },
              ]"
            />
          </div>
          <div class="box-item-score">
            <div class="box-score">
              {{ formatScore(interviewLanguage[item_idx]?.score || 0) }}%
            </div>
          </div>
        </div>
      </div>
    </div>
  </ExportPage>
</template>

<style lang="scss" scoped>
:deep(.resume-component) {
  .profile-data {
    margin: 0;
  }
}

.pdf-schooling {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .separator {
    height: 1px;
    border-top: 1px solid $gray-border;
    margin: 1rem 0;
  }

  .box-grid {
    display: flex;
    flex-direction: row;
    padding-bottom: 1rem;
    gap: 3.25rem;
  }

  .box-grid.box-border {
    border-bottom: 1px solid $gray-border;
  }

  .titles {
    display: flex;
    flex: 1;
    align-items: flex-end;
    font-size: 1.125rem;
    font-weight: bold;
    line-height: 1.875rem;
    text-transform: uppercase;

    &-ideal {
      color: $gray-dark;
    }

    &-person {
      color: $yellow;
    }
  }

  .box-item-score {
    display: flex;
    min-width: 5rem;
    max-width: 5rem;
    flex: 1;
    align-items: flex-end;

    .box-score.total {
      border-color: $yellow;
      background: $yellow;
      color: $white-full;
    }

    .box-score {
      display: flex;
      width: 5rem;
      height: 2.625rem;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 1px solid $gray-text;
      border-radius: 6px;
      color: $gray-text;
      font-size: 1.25rem;
      font-weight: bold;
    }
  }

  .box-item-ideal,
  .box-item-persona {
    display: flex;
    flex: 1;
  }
}
</style>
