<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import Dialog from "primevue/dialog";
import { CircleStencil, Cropper } from "vue-advanced-cropper";
import { resizeImageBase64 } from "@/utils/images/resize-images";
import { Button } from "@/components/button/";
import "vue-advanced-cropper/dist/style.css";
import "vue-advanced-cropper/dist/theme.bubble.css";
import CompanyIcon from "@/assets/images/general-company.svg";

export default defineComponent({
  name: "AvatarInput",
  components: {
    Dialog,
    Button,
    Cropper,
    // eslint-disable-next-line vue/no-unused-components
    CircleStencil,
    CompanyIcon,
  },
  props: {
    avatarUrl: String,
    name: String,
    borderColor: String,
    size: {
      type: String,
      default: "180px",
    },
    entity: {
      type: String,
      default: "person",
    },
    modelValue: String,
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const displayCropper = ref(false);
    const avatarBlobUrl = ref();
    const imgCrop = ref();
    const cropper = ref();
    const blob = ref();
    const fileInput = ref();

    const colorClass = computed<string>(() => {
      const classes = {
        yellow: "border-yellow",
        gray: "border-gray",
        transparent: "no-border",
      };

      return classes[props.borderColor as string] || "";
    });

    const placeholderText = computed<string>(() => {
      const name = props.name as string;
      const allNames = name.split(/\s/).filter(i => i);

      if (allNames.length > 1) {
        const [firstLetter] = allNames[0].toUpperCase();
        const [secondLetter] = allNames.slice(-1)[0].toUpperCase();
        return `${firstLetter}${secondLetter}`;
      }

      return name.slice(0, 2).toUpperCase();
    });

    const openCropper = () => {
      displayCropper.value = true;
    };

    const closeCropper = () => {
      displayCropper.value = false;
    };

    const handleFile = (event: Event) => {
      const input = event.target as HTMLInputElement;
      let file = null;
      if (input.files)
        [file] = input.files;

      imgCrop.value = URL.createObjectURL(file as Blob);
      openCropper();
    };

    const cropImage = async () => {
      const { canvas } = cropper.value.getResult();
      avatarBlobUrl.value = await resizeImageBase64(canvas.toDataURL("image/jpg", 1));
      fileInput.value.value = null;
      closeCropper();
      emit("update:modelValue", avatarBlobUrl.value.split(",")[1]);
    };

    const backgroundColor = computed(() => {
      if (props.entity === "person" && !(props.avatarUrl || props.modelValue))
        return "#666";

      if (props.entity === "corporation" && !(props.avatarUrl || props.modelValue))
        return "#c5c1c1";

      return "#fff";
    });

    return {
      t,
      handleFile,
      openCropper,
      closeCropper,
      cropImage,
      avatarBlobUrl,
      imgCrop,
      displayCropper,
      cropper,
      blob,
      fileInput,
      colorClass,
      placeholderText,
      backgroundColor,
    };
  },
});
</script>

<template>
  <div class="image-placeholder" :class="colorClass">
    <div class="image-wrapper">
      <img v-if="modelValue" :src="avatarBlobUrl" :alt="`${name}`" class="avatar-image">
      <img v-else-if="avatarUrl" :src="avatarUrl" :alt="`${name}`" class="avatar-image">
      <span v-else>
        <template v-if="entity === 'person'">
          {{ placeholderText }}
        </template>
        <template v-if="entity === 'corporation'">
          <CompanyIcon class="company-icon" />
        </template>
      </span>
      <div class="avatar-edit">
        <input
          id="avatarUpload"
          ref="fileInput"
          type="file"
          accept=".png, .jpg, .jpeg"
          @change="handleFile"
        >
        <label for="avatarUpload" class="change-icon">
          <img src="@/assets/images/change-image.png">
          <span>{{ t("input.avatarInput.change") }}</span>
        </label>
      </div>
    </div>
  </div>
  <Dialog v-model:visible="displayCropper" class="dialog" :closable="false" :draggable="false">
    <Cropper
      ref="cropper"
      class="cropper"
      :src="imgCrop"
      :stencil-component="$options.components.CircleStencil"
      :stencil-props="{
        aspectRatio: 1,
      }"
      :style="{ width: '1024px', height: '576px' }"
    />
    <template #footer>
      <div class="button-wrapper">
        <Button variation="light" filled type="submit" @click="closeCropper">
          {{ t("input.avatarInput.cancel") }}
        </Button>
        <Button variation="secondary" @click="cropImage">
          {{ t("input.avatarInput.defineImage") }}
        </Button>
      </div>
    </template>
  </Dialog>
</template>

<style scoped lang="scss">
.image-placeholder {
  display: flex;
  overflow: hidden;
  width: v-bind(size);
  height: v-bind(size);
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  padding: 2px;
  border: 3px solid;
  border-radius: 100%;
  caret-color: transparent;
  font-size: 2rem;
  letter-spacing: 5px;

  &.border-yellow {
    border-color: $yellow-light;
  }

  &.border-gray {
    border-color: $gray-dark;
  }

  &.no-border {
    border: none;
  }

  span {
    color: #fff;
  }

  .image-wrapper {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 1px;
    border-radius: 100%;
    background-color: v-bind(backgroundColor);
    user-select: none;

    img {
      border-radius: 100%;
    }

    .company-icon {
      width: 70px;
      height: 70px;
    }

    .avatar-image {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      background: $gray-dark;
      color: $white-full;
      font-size: 3rem;
      opacity: 0.4;
    }

    .change-icon {
      opacity: 1;

      img {
        width: 60px;
        border-radius: 0%;
      }
    }

    .avatar-edit {
      input {
        display: none;
        caret-color: transparent;

        + label {
          position: absolute;
          top: 0%;
          left: 0%;
          display: flex;
          width: 100%;
          height: 100%;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          cursor: pointer;
          user-select: none;

          span {
            color: #ef3f43;
            font-family: "Open Sans";
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 0.5px;
          }
        }
      }
    }
  }
}

.button-wrapper {
  padding-block-start: 1rem;
  .button {
    display: inline;
    height: 36px;
    padding-right: 15px;
    padding-left: 15px;
    border-radius: 8px;

    &.cancel {
      background-color: $gray-lighter;
      color: $gray-dark;
      opacity: 0.6;
    }
  }
}
</style>
