import { z } from "zod";
import {
  AddressSchema,
  EmailSchema,
  ExternalIdsSchema,
  GenericSchema,
  RelatedCorporationSchema,
  RelatedRecruiterSchema,
  SalarySchema,
  TelephoneNumberSchema,
} from "../common/schemas";
import { LanguageEnumSchema, LanguageProficiencyEnumSchema } from "../enums/languages";

import { DegreeLevelEnumSchema } from "../enums/misc";

export const PersonLanguageSchema = z
  .object({
    id: z.string().optional(),
    language: LanguageEnumSchema,
    proficiency: LanguageProficiencyEnumSchema.nullish(),
    language_text: z.string().optional(),
  })
  .strict();

export const PersonEducationSchema = z
  .object({
    degree_level: DegreeLevelEnumSchema.nullish(),
    course_title: z.string().nullish(),
    start_date: z.string().nullish(),
    end_date: z.string().nullish(),
    institution: RelatedCorporationSchema,
  })
  .strict();

export const RealizationSchema = z
  .object({
    title: z.string(),
    description: z.string(),
  })
  .strict();

export const WorkExperienceSchema = z
  .object({
    id: z.string().optional(),
    role_name: z.string(),
    is_current: z.boolean(),
    salary: SalarySchema.nullish(),
    incentive_compensation: z.string().nullish(),
    job_location: z.string().nullish(),
    start_date: z.string().nullish(),
    end_date: z.string().nullish(),
    description: z.string().nullish(),
    conditions: z.string().nullish(),
    is_parsed: z.boolean().nullish().optional(),
    start_year: z.boolean().nullish().optional(),
    start_month: z.boolean().nullish().optional(),
    end_year: z.boolean().nullish().optional(),
    end_month: z.boolean().nullish().optional(),
    realizations: z.array(RealizationSchema),
    company: RelatedCorporationSchema,
  })
  .strict();

export const CertificationSchema = z
  .object({
    name: z.string(),
    field: z.string().nullish(),
    expedition_date: z.string().nullish(),
    expiration_date: z.string().nullish(),
    is_lifelong: z.boolean(),
    institution: RelatedCorporationSchema.nullish(),
  })
  .strict();

export const PersonPublicationSchema = z
  .object({
    headline: z.string(),
    publisher: z.string().nullish(),
    published_date: z.string(),
  })
  .strict();

export const PersonAwardSchema = z
  .object({
    title: z.string(),
    related_position: z.string().nullish(),
    award_date: z.string(),
    description: z.string().nullish(),
  })
  .strict();

export const PersonActivitySchema = z
  .object({
    title: z.string(),
    start_date: z.string(),
    is_current: z.boolean(),
    end_date: z.string().nullish(),
    description: z.string().nullish(),
  })
  .strict();

export const BasePersonSchema = z
  .object({
    first_name: z.string(),
    last_name: z.string(),
    birth_date: z.string().nullish(),
    email_addresses: z.array(EmailSchema).optional(),
    avatar_url: z.string().optional(),
    added_in_mapping: z.boolean(),
    address: AddressSchema.nullable(),
    phones: z.array(TelephoneNumberSchema).optional(),
    linkedin_url: z.string(),
    languages: z.array(PersonLanguageSchema).optional(),
    is_added_in_placement: z.boolean().optional(),
    description: z.string().nullish(),
    projects: z.array(PersonActivitySchema).optional(),
    publications: z.array(PersonPublicationSchema).optional(),
    awards: z.array(PersonAwardSchema).optional(),
    volunteering: z.array(PersonActivitySchema).optional(),
    included_in_project: z.boolean().optional(),
    restricted_profile: z.boolean().optional(),
    education: z.array(PersonEducationSchema).optional(),
    work_experience: z.array(WorkExperienceSchema).nullish(),
    certifications: z.array(CertificationSchema).optional(),
    related_recruiters: z.array(RelatedRecruiterSchema).nullish(),
    external_ids: ExternalIdsSchema.nullish(),
    user_id: z.string().nullish(),
    person_id: z.string().optional(),
  })
  .strict();

export const PersonSchema = BasePersonSchema.merge(GenericSchema).strict();
