import type { Module } from "vuex";
import type { ScreeningGuide, ScreeningOrganizationAndAssembly } from "@/schemas/project";

const ScreeningStoreModule: Module<any, any> = {
  state() {
    const screeningGuide: ScreeningGuide = null;
    const screeningEvaluate: ScreeningOrganizationAndAssembly = null;

    return {
      screeningGuide,
      screeningEvaluate,
    };
  },
  getters: {
    screeningGuide: state => state.screeningGuide,
    screeningEvaluate: state => state.screeningEvaluate,
  },
  mutations: {
    SET_SCREENING_GUIDE: (state, data) => {
      state.screeningGuide = data;
    },
    SET_SCREENING_EVALUATE: (state, data) => {
      state.screeningEvaluate = data;
    },
  },
};

export default ScreeningStoreModule;
