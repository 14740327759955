<script setup lang="ts">
import { computed } from "vue";
import { useHead } from "@vueuse/head";
import Counter from "@/components/example/Counter.vue";

useHead({
  title: computed(() => "IMUA - Status"),
});
</script>

<template>
  <div>
    <section>test new setup</section>
    <div>
      <Counter :initial="0" />
    </div>
    <div class="py-20 text-center text-white px-12">
      <div>hello world</div>
      <div class=" bg-red-500 grid mr-2 grid-cols-2 ">
        <div class="text-white p2">
          1
        </div>
        <div class="text-white p2">
          2
        </div>
      </div>
    </div>
  </div>
</template>
