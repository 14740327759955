<script setup lang="ts">
import { format, parseISO } from "date-fns";
import type { CandidateType } from "@/common/services/candidates";
import type { Person } from "@/entities/Person/person";
import { SvgIcon } from "@/components/icon";
import PersonDetail from "@/persons/pages/persons/components/PersonDetail/PersonDetail.vue";
import { getPerson } from "@/http_services/entities/persons";
import { changeImageSize } from "@/utils/images/resize-images";
import InfoDescription from "@/components/person_card/components/InfoDescription/InfoDescription.vue";

const props = withDefaults(
  defineProps<{
    person?: CandidateType | null
    active?: boolean
    showDetails?: boolean
    showScore?: boolean
    score?: number | null
    scoreIndex?: number
    id?: string | number
  }>(),
  {
    person: null,
    active: false,
    showDetails: false,
    showScore: false,
    score: 0,
    scoreIndex: 0,
  },
);

const emit = defineEmits(["click:avatar", "go:activity", "change:rating"]);

const { person, showDetails, active, score } = toRefs(props);

const progressScoreGap = computed(() => `${score.value || 0}%`);
const avatar = computed(() => changeImageSize(person.value?.person_data?.avatar_url, "large"));
const personName = computed(() => `${person.value?.person_data?.first_name} ${person.value?.person_data?.last_name}`);
const personInitials = computed(() => {
  const first = person.value?.person_data?.first_name?.substring(0, 1);
  const last = person.value?.person_data?.last_name?.substring(0, 1);
  return `${first}${last}`;
});
const lastUpdated = computed(() => {
  try {
    const { inserted_at, updated_at } = person.value || {};
    const date = updated_at ? parseISO(updated_at) : parseISO(inserted_at || "");
    return format(date, "dd/MM/yyyy");
  }
  catch (_) {
    return "";
  }
});
const workExperience = computed(() => {
  const experiences = person.value?.work_experience || [];
  return experiences.length > 0 ? experiences[0] : null;
});
const backgroundColor = computed(() => (person.value?.person_data?.avatar_url ? "#f4f4f4" : "#666"));

function formatScore(score: number): string | number {
  try {
    return (
      (!Number.isInteger(score) ? score.toFixed(2).replace(".", ",") : score.toFixed(0)) ?? 0
    );
  }
  catch (error) {
    return 0;
  }
}
function getYear(date: string | null | undefined) {
  if (!date)
    return "atual";
  return format(parseISO(date), "yyyy");
}
const showPersonDetail = ref(false);
const selectedPerson = ref(undefined);

async function onSelectRequester(id: string) {
  const data = await getPerson(id);
  if (data) {
    selectedPerson.value = toRaw(data);
    showPersonDetail.value = true;
  }
}
provide("onCloseDetails", () => {
  showPersonDetail.value = false;
  selectedPerson.value = undefined;
});

provide("selectedPerson", selectedPerson);

function getBorderStyle() {
  const isAddedInPlacement = person.value?.person_data?.is_added_in_placement;
  const addedInMapping = person.value?.person_data?.added_in_mapping;

  return isAddedInPlacement ? { border: "3px solid #040404" } : addedInMapping ? { border: "3px solid #fc0" } : {};
}

function formatPerson(data: CandidateType | null): Person | null {
  if (data) {
    return {
      avatar_url: data.person_data.avatar_url,
      first_name: data.person_data.first_name,
      last_name: data.person_data.last_name,
      linkedin_url: data.person_data.linkedin_url,
      email_addresses: data.person_data.email_addresses,
      work_experience: data.work_experience,
      address: data.person_data.address,
      phones: data.person_data.phones,
    };
  }
  return null;
}
</script>

<template>
  <div :id="id" class="candidate-card">
    <div class="ranking-progress">
      <div class="progress-container">
        <div class="progress-content">
          <div class="progress-base">
            <div />
            <div />
            <div />
            <div />
          </div>
        </div>
        <div class="progress-content">
          <div class="progress-score" :style="{ borderTopLeftRadius: (score < 2) ? '0.5rem' : 'none' }">
            <div
              class="score-floating"
              :class="{
                'floating-right-corner': score > 95,
                'floating-left-corner': score < 5,
                'floating-left-corner_min': score < 2,
              }"
            >
              <div>#{{ (scoreIndex || 0) + 1 }} -</div>
              <div>{{ formatScore(score) }}%</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="person-data">
      <div class="person-data-avatar">
        <div
          class="avatar"
          :style="getBorderStyle()"
          @click="onSelectRequester(person?.person_id)"
        >
          <img
            v-if="person?.person_data?.avatar_url"
            :src="avatar"
            :alt="personName"
            loading="lazy"
          >
          <p v-else class="initials">
            {{ personInitials }}
          </p>
        </div>
        <div class="last-update">
          <SvgIcon icon="refresh_icon" width="0.75rem" height="0.75rem" />{{ lastUpdated }}
        </div>
      </div>
      <div class="person-data-details">
        <div class="person-data-details-candidate">
          <span class="candidate-name">{{ personName }}</span>
          <span class="candidate-code">({{ person?.serial }})</span>
        </div>
        <div class="person-data-details-role">
          {{ person?.mini_cv.current_role_nickname }}
        </div>
        <div class="person-data-details-corporation">
          <span>
            {{ person?.mini_cv.current_company_nickname }}
          </span>
        </div>
        <InfoDescription
          :is-person-card="true"
          :person="formatPerson(person)"
        />
      </div>
      <div class="person-data-actions">
        <slot name="actions" />
      </div>
    </div>
    <div v-if="showDetails" class="candidate-card-details">
      <slot name="details" />
    </div>
  </div>
  <PersonDetail :visible="showPersonDetail" />
</template>

<style lang="scss" scoped>
$avatar_size: 8.75rem;

.candidate-card {
  display: flex;
  width: 100%;
  flex-direction: column;
  border-radius: 0.5rem;
  background: $white-full;
  box-shadow: 0 2px 4px #0003;

  .person-data {
    display: flex;
    height: 16.25rem;
    flex-direction: row;
    background: $white-full;
    gap: 2.5rem;
    padding-inline: 1.875rem;
    padding-block: 1.25rem;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;

    &-avatar {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: 0.625rem;

      .avatar {
        cursor: pointer;
        display: flex;
        width: $avatar_size;
        height: $avatar_size;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        background: v-bind("backgroundColor");
        box-sizing: unset;

        img {
          width: $avatar_size;
          height: $avatar_size;
          border-radius: 100%;
        }

        .initials {
          color: #fff;
          font-size: 2rem;
        }
      }

      .last-update {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.625rem;
      }
    }

    &-details {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-bottom: 1rem;
      gap: 0.75rem;

      &-candidate {
        display: flex;
        flex-direction: row;
        gap: 1rem;

        .candidate-name {
          color: $primary;
          font-size: 1.125rem;
          text-transform: initial;
          letter-spacing: 0.63px;
        }

        .candidate-code {
          color: $gray-dark;
          padding-block-start: 0.35rem;
          font-size: 0.75rem;
        }
      }

      &-role {
        color: $gray-dark;
        font-size: 0.875rem;
        letter-spacing: 0.49px;
      }

      &-corporation {
        display: flex;
        flex-direction: row;

        span {
          font-size: 0.8125rem;
        }

        span.bold {
          font-weight: bold;
        }
      }
    }

    &-actions {
      display: flex;
      flex-direction: column;
      gap: 0.625rem;

      &-buttons {
        font-size: 0.75rem;
      }
    }
  }

  .candidate-card-details {
    position: relative;
    z-index: 0;
    display: flex;
    flex-direction: column;
    padding: 1.56rem;
    margin-top: -0.5rem;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    background: $white-full;
    box-shadow: 0 2px 4px #0003;
  }

  .ranking-progress {
    position: relative;
    display: flex;
    width: 100%;
    height: 1.5rem;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    grid-column: span 2;

    &-hint {
      position: absolute;
      right: 50%;
      bottom: 2rem;
    }

    .progress-container {
      display: grid;
      width: 100%;
      height: 1.5rem;
      margin: 0 auto;
      grid-template-columns: 1fr;

      .progress-content {
        height: 1.5rem;
        grid-column-start: 1;
        grid-row-start: 1;

        .progress-base {
          display: grid;
          width: 100%;
          height: 1.5rem;
          grid-template-columns: repeat(4, 1fr);

          > div:nth-child(1) {
            background: $gray-border;
            border-top-left-radius: 8px;
          }

          > div:nth-child(2) {
            background: #dcbe8d;
          }

          > div:nth-child(3) {
            background: #ebbb69;
          }

          > div:nth-child(4) {
            background: $yellow-dark;
            border-top-right-radius: 8px;
          }
        }

        .progress-score {
          position: relative;
          height: 1.5rem;
          margin-left: v-bind(progressScoreGap);
          background: #dedede;
          // border-top-left-radius: 0.5rem;
          border-top-right-radius: 0.5rem;

          .score-floating {
            position: absolute;
            bottom: 2rem;
            left: -2.5rem;
            display: flex;
            justify-content: space-between;
            padding: 0.5rem;
            border-radius: 0.5rem;
            background: #fff;
            box-shadow: 0 2px 3px #0000003b;
            gap: 0.5rem;
            white-space: nowrap;

            &.floating-right-corner {
              left: -5rem;
            }

            &.floating-left-corner {
              left: -1.5rem;

              &_min {
                left: -0.5rem;
              }
            }

            > div:nth-child(1) {
              font-size: 1rem;
              font-weight: 700;
            }

            > div:nth-child(2) {
              color: $primary;
              font-size: 1rem;
              font-weight: 700;
            }
          }
        }
      }
    }
  }
}
</style>
