<script setup lang="ts">
import type { Ref } from "vue";
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import get from "lodash-es/get";
import set from "lodash-es/set";
import isNumber from "lodash-es/isNumber";
import { helpers } from "@vuelidate/validators";
import { FormInput, SearchDropdown, Select } from "@/components/inputs/modalInputs";
import { genYearsFrom, months, parseAndGetDates, watchDatesAndSetInObj, years } from "@/utils/dates";
import { searchEducationalCorporation } from "@/http_services/entities/corporation";
import { DegreeLevel } from "@/entities/Person/education";
import type { Corporation } from "@/entities/corporation";
import type { EducationPayload } from "@/entities/Person/form";

interface PropsType {
  education: EducationPayload
  educationEdit?: EducationPayload
  educationAutoFilledFields?: any
  autoFilledEditFields?: any
}

const props = defineProps<PropsType>();

const emit = defineEmits(["add:institution"]);

const { t } = useI18n();
const payload = toRef(props, "education") as Ref<EducationPayload>;
const parsedPayload = toRef(props, "educationEdit", payload.value) as Ref<EducationPayload>;
const searchInput = ref<InstanceType<typeof SearchDropdown> | null>(null);

const finalYearsOptions = computed(() => genYearsFrom(1970, 10));

const degreeOptions = computed(() =>
  Object.values(DegreeLevel).map(d => ({
    label: t(`persons.forms.academic.degree.${d}`),
    value: d,
  })),
);

const { startMonth, startYear } = parseAndGetDates<EducationPayload>("start_date", payload)();
watch([startMonth, startYear], watchDatesAndSetInObj<EducationPayload>("start_date", payload));

const { endMonth, endYear } = parseAndGetDates<EducationPayload>("end_date", payload)();
watch([endMonth, endYear], watchDatesAndSetInObj<EducationPayload>("end_date", payload));

watch(
  payload,
  (value) => {
    const { start_date, end_date, institution } = value;

    if (!start_date && !isNumber(startMonth.value) && !isNumber(startYear.value)) {
      startMonth.value = null;
      startYear.value = null;
    }

    if (!end_date && !isNumber(endMonth.value) && !isNumber(endYear.value)) {
      endMonth.value = null;
      endYear.value = null;
    }

    if (!institution?.name && searchInput.value)
      searchInput.value.setSearchValue("");
  },
  { deep: true },
);

const {
  startMonth: parsedStartMonth,
  startYear: parsedStartYear,
} = parseAndGetDates<EducationPayload>(
  "start_date",
  parsedPayload,
)();
watch(
  [parsedStartMonth, parsedStartYear],
  watchDatesAndSetInObj<EducationPayload>("start_date", parsedPayload),
);

const {
  endMonth: parsedEndMonth,
  endYear: parsedSEndYear,
} = parseAndGetDates<EducationPayload>(
  "end_date",
  parsedPayload,
)();
watch(
  [parsedEndMonth, parsedSEndYear],
  watchDatesAndSetInObj<EducationPayload>("end_date", parsedPayload),
);

function selectInstitution(corporation: { corporation: Corporation }) {
  payload.value.institution = {
    name: corporation.corporation_data.name,
    corporation_id: corporation.corporation_id,
  };

  emit("add:institution", corporation);
}

function dateValidate() {
  if (payload.value.start_date && payload.value.end_date) {
    const startDate = new Date(payload.value.start_date);
    const endDate = new Date(payload.value.end_date);
    return (endDate >= startDate);
  }
  return true;
}

const rules = computed(() => ({
  payload: {
    institution: {
      name: {},
    },
    course_title: {},
    degree_level: {},
    end_date: {
      dateValidate: helpers.withMessage(t("errors.messages.dates"), dateValidate),
    },
  },
  startMonth: {
    dateValidate: helpers.withMessage("", dateValidate),
  },
  startYear: {
    dateValidate: helpers.withMessage("", dateValidate),
  },
  endMonth: {
    dateValidate: helpers.withMessage("", dateValidate),
  },
  endYear: {
    dateValidate: helpers.withMessage("", dateValidate),
  },
}));

const v$ = useVuelidate(rules, {
  payload,
  startMonth,
  startYear,
  endMonth,
  endYear,
});

function removeOption(key: string) {
  if (props.autoFilledEditFields)
    set(props.autoFilledEditFields, key, false);
}
</script>

<template>
  <div class="person-form-wrapper" data-test="academic-form">
    <div class="form-input-wrapper">
      <label>
        {{ t("persons.forms.academic.label.institute") }}
        <span>:</span>
      </label>
      <div class="input-group">
        <SearchDropdown
          id="institute"
          ref="searchInput"
          fetch-param-key="name"
          :auto-fill="educationAutoFilledFields?.institution"
          :fetch-method="searchEducationalCorporation"
          :select-item-callback="selectInstitution"
          :update-input-model="(item) => item.corporation_data?.name"
          :initial-value="payload?.institution?.name || payload?.institution?.corporation_data?.name"
          :input-placeholder="t('persons.workExperienceForm.placeholders.company')"
          :search-not-found-label="t('text.searchCorporationNotFound')"
          :search-error-label="t('text.searchCorporationError')"
          data-test="institute"
          :error="get(v$, 'payload.institution.name.$error', false)"
          :error-message="get(v$, 'payload.institution.name.$errors.0.$message', '')"
        >
          <template #list-item="{ item }">
            {{ item.corporation_data?.name }}
          </template>
        </SearchDropdown>
        <SearchDropdown
          v-if="autoFilledEditFields?.institution"
          id="institute"
          ref="searchInput"
          fetch-param-key="name"
          svg-icon="trash_icon"
          show-icon
          :auto-fill="autoFilledEditFields?.institution"
          :fetch-method="searchEducationalCorporation"
          :select-item-callback="selectInstitution"
          :update-input-model="(item) => item?.institution?.name || item.corporation_data?.name"
          :initial-value="parsedPayload?.institution?.name || parsedPayload?.institution?.corporation_data?.name"
          :input-placeholder="t('persons.workExperienceForm.placeholders.company')"
          :search-not-found-label="t('text.searchCorporationNotFound')"
          :search-error-label="t('text.searchCorporationError')"
          data-test="institute"
          :error="get(v$, 'payload.institution.name.$error', false)"
          :error-message="get(v$, 'payload.institution.name.$errors.0.$message', '')"
          @click:on-button-click="removeOption('institution')"
        >
          <template #list-item="{ item }">
            {{ item.corporation_data?.name }}
          </template>
        </SearchDropdown>
      </div>
    </div>

    <div class="form-input-wrapper">
      <label>
        {{ t("persons.forms.academic.label.degreeLevel") }}
        <span>:</span>
      </label>
      <div class="input-group">
        <Select
          v-model="payload.degree_level"
          :auto-fill="educationAutoFilledFields?.degree_level"
          :options="degreeOptions"
          data-test="degree"
          :error="get(v$, 'payload.degree_level.$error', false)"
          :error-message="get(v$, 'payload.degree_level.$errors.0.$message', '')"
        />
        <Select
          v-if="autoFilledEditFields?.degree_level && parsedPayload?.degree_level"
          v-model="parsedPayload.degree_level"
          svg-icon="trash_icon"
          show-icon
          :auto-fill="autoFilledEditFields?.degree_level"
          :options="degreeOptions"
          data-test="degree"
          :error="get(v$, 'payload.degree_level.$error', false)"
          :error-message="get(v$, 'payload.degree_level.$errors.0.$message', '')"
          @click:on-button-click="removeOption('degree_level')"
        />
      </div>
    </div>

    <div class="form-input-wrapper">
      <label>
        {{ t("persons.forms.academic.label.field") }}
        <span>:</span>
      </label>
      <div class="input-group">
        <FormInput
          v-model="payload.course_title"
          data-test="field"
          :auto-fill="educationAutoFilledFields?.course_title"
          :error="get(v$, 'payload.course_title.$error', false)"
          :error-message="get(v$, 'payload.course_title.$errors.0.$message', '')"
        />
        <FormInput
          v-if="autoFilledEditFields?.course_title && parsedPayload?.course_title"
          v-model="parsedPayload.course_title"
          svg-icon="trash_icon"
          show-icon
          data-test="field"
          :auto-fill="autoFilledEditFields?.course_title"
          :error="get(v$, 'payload.course_title.$error', false)"
          :error-message="get(v$, 'payload.course_title.$errors.0.$message', '')"
          @click:on-button-click="removeOption('course_title')"
        />
      </div>
    </div>

    <div class="form-input-wrapper">
      <label>
        {{ t("persons.forms.academic.label.startDate") }}
        <span>:</span>
      </label>
      <div class="input-group">
        <div class="form-date-wrapper">
          <Select
            v-model="startMonth"
            :auto-fill="educationAutoFilledFields?.start_month"
            :placeholder="t('date.month')"
            :options="months"
            data-test="start-month"
            :error="get(v$, 'startMonth.$silentErrors.length', 0) > 0"
            :error-message="get(v$, 'startMonth.$silentErrors.0.$message', '')"
          />
          <Select
            v-model="startYear"
            :auto-fill="educationAutoFilledFields?.start_year"
            :placeholder="t('date.year')"
            :options="years"
            data-test="start-year"
            :error="get(v$, 'startYear.$silentErrors.length', 0) > 0"
            :error-message="get(v$, 'startYear.$silentErrors.0.$message', '')"
          />
        </div>
        <div class="form-date-wrapper">
          <Select
            v-if="autoFilledEditFields?.start_month"
            v-model="parsedStartMonth"
            svg-icon="trash_icon"
            show-icon
            :auto-fill="autoFilledEditFields?.start_month"
            :placeholder="t('date.month')"
            :options="months"
            data-test="start-month"
            :error="get(v$, 'startMonth.$silentErrors.length', 0) > 0"
            :error-message="get(v$, 'startMonth.$silentErrors.0.$message', '')"
            @click:on-button-click="removeOption('start_month')"
          />
          <Select
            v-if="autoFilledEditFields?.start_year"
            v-model="parsedStartYear"
            svg-icon="trash_icon"
            show-icon
            :auto-fill="autoFilledEditFields?.start_year"
            :placeholder="t('date.year')"
            :options="years"
            data-test="start-year"
            :error="get(v$, 'startYear.$silentErrors.length', 0) > 0"
            :error-message="get(v$, 'startYear.$silentErrors.0.$message', '')"
            @click:on-button-click="removeOption('start_year')"
          />
        </div>
      </div>
    </div>

    <div class="form-input-wrapper">
      <label>
        {{ t("persons.forms.academic.label.endDate") }}
        <span>:</span>
      </label>
      <div class="input-group">
        <div class="form-date-wrapper">
          <Select
            v-model="endMonth"
            :auto-fill="educationAutoFilledFields?.end_month"
            :placeholder="t('date.month')"
            :options="months"
            data-test="end-month"
            :error="get(v$, 'endMonth.$silentErrors.length', 0) > 0"
            :error-message="get(v$, 'endMonth.$silentErrors.0.$message', '')"
          />
          <Select
            v-model="endYear"
            :auto-fill="educationAutoFilledFields?.end_year"
            :placeholder="t('date.year')"
            :options="finalYearsOptions"
            data-test="end-year"
            :error="get(v$, 'endYear.$silentErrors.length', 0) > 0"
            :error-message="get(v$, 'endYear.$silentErrors.0.$message', '')"
          />
        </div>
        <div class="form-date-wrapper">
          <Select
            v-if="autoFilledEditFields?.end_month"
            v-model="parsedEndMonth"
            svg-icon="trash_icon"
            show-icon
            :auto-fill="autoFilledEditFields?.end_month"
            :placeholder="t('date.month')"
            :options="months"
            data-test="end-month"
            :error="get(v$, 'endMonth.$silentErrors.length', 0) > 0"
            :error-message="get(v$, 'endMonth.$silentErrors.0.$message', '')"
            @click:on-button-click="removeOption('end_month')"
          />
          <Select
            v-if="autoFilledEditFields?.end_year"
            v-model="parsedSEndYear"
            svg-icon="trash_icon"
            show-icon
            :auto-fill="autoFilledEditFields?.end_year"
            :placeholder="t('date.year')"
            :options="finalYearsOptions"
            data-test="end-year"
            :error="get(v$, 'endYear.$silentErrors.length', 0) > 0"
            :error-message="get(v$, 'endYear.$silentErrors.0.$message', '')"
            @click:on-button-click="removeOption('end_year')"
          />
        </div>
      </div>
      <label />
      <span v-if="get(v$, 'payload.end_date.$silentErrors.length', 0) > 0" class="date-message">
        {{ get(v$, 'payload.end_date.$silentErrors.0.$message', '') }}
      </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.input-group {
  width: 100%;
}

.person-form-wrapper:deep {
  .form-input-wrapper {
    display: grid;
    width: 100%;
    margin-bottom: 0.5rem;
    grid-template-columns: 150px 1fr;

    label {
      position: relative;
      bottom: 5px;
      display: flex;
      min-width: 114px;
      height: 28px;
      align-items: flex-end;
      color: $gray-dark;
      font-family: "Open Sans";
      font-size: 14px;
      letter-spacing: 0.5px;

      .required {
        color: $red;
      }
    }

    .date-message {
      color: $red-warning;
      font-size: 11px;
      margin-block-start: -0.5rem;
      margin-inline-start: 0.5rem;
    }

    .component-input {
      width: 100%;
    }

    .form-date-wrapper {
      display: grid;
      column-gap: 30px;
      grid-template-columns: 1fr 1fr;
    }
  }

  .error-wrapper {
    position: relative;

    span {
      position: absolute;
      white-space: nowrap;
    }
  }

  .multiselect {
    min-height: 30px;

    .multiselect-dropdown {
      overflow-y: scroll;
    }
  }
}
</style>
