<script setup lang="ts">
import * as zod from "zod";
import { mdiPlusCircleOutline } from "@mdi/js";
import { toTypedSchema } from "@vee-validate/zod";
import OpinionEditable from "../OpinionEditable.vue";
import { ButtonIcon } from "@/components/button";
import { SvgIcon } from "@/components/icon";

const props = defineProps<{
  list: string[]
  consultantOpinion: string
}>();

const emit = defineEmits(["save", "update", "cancel"]);

const { t } = useI18n();
const validationSchema = toTypedSchema(
  zod.object({
    list: zod.array(
      zod.string({
        required_error: "Item is required",
      })
        .nonempty("Can't be empty")
        .min(1, { message: "Too short" }),
    ),
    opinion: zod.string().nullable(),
  }),
);

const { handleSubmit, isSubmitting, meta } = useForm({
  initialValues: {
    list: props.list,
    opinion: props.consultantOpinion,
  },
  validationSchema,
});

const { remove, push, fields, update } = useFieldArray<string>("list");

const { value: opinion, setValue } = useField<string>("opinion");

const onUpdate = handleSubmit((values) => {
  emit("update", values);
});

function removeItem(idx: number) {
  remove(idx);
  onUpdate();
}

function onSubmit() {
  emit("save");
}
</script>

<template>
  <div class="form">
    <div v-for="(field, idx) in fields" :key="field.key" class="base-item">
      <TextArea
        :name="`item[${idx}]`"
        :model-value="field.value"
        :warning-chars="250"
        placeholder="Description"
        class="item-input"
        rows="5"
        @update:model-value="update(idx, $event)"
        @change="onUpdate"
      />
      <ButtonIcon @click="removeItem(idx)">
        <SvgIcon icon="trash_icon" width="20" height="20" margin="none" />
      </ButtonIcon>
    </div>
    <ButtonIcon class="panel-button add" @click="push('')">
      <SvgIcon
        :icon="mdiPlusCircleOutline"
        type="mdi"
        width="20"
        height="20"
        margin="none"
      />
      <span>
        {{ t("projects.details.activities.constructionPanel.addAnalysis") }}
      </span>
    </ButtonIcon>
    <OpinionEditable :value="opinion" @update:value="setValue($event)" @change="onUpdate" />
    <div class="form-actions">
      <Button type="submit" variation="secondary" :disabled="isSubmitting" @click="onSubmit">
        <!-- !meta.valid -->
        {{ t("text.form.conclude") }}
      </Button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.base-item {
  display: flex;
  align-items: baseline;
  gap: 0.25rem;
  margin-block: 0.5rem 1.5rem;

  .item-input {
    width: 100%;
  }
}
button.panel-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  margin-inline-start: auto;
  margin-block-start: 0.5rem;
  margin-block-end: 4rem;

  &:focus,
  &:hover {

    &.add,
    &.add > span {
      color: $red-violet-medium;

      svg {
        vertical-align: bottom;
      }
    }
  }

  &.add,
  &.add > span {
    color: $red-violet;
    font-size: 0.75rem;
    letter-spacing: 0;
    font-weight: 600;

    svg {
      vertical-align: bottom;
    }
  }
}
.form-actions {
  display: flex;
  gap: 2rem;
  margin-block: 1.5rem;
}

.form {
  margin: 1.5rem;
  margin-inline: 3.75rem;
}
:deep(.text-area-wrapper) {
    .textarea-container {
    textarea {
    line-height: 1.5rem;
    }
  }
}

  .text-area-wrapper :deep(.counter-wrapper) {
    justify-content: flex-start;
  }
</style>
