<script setup lang="ts">
import type { PropType } from "vue";
import { nextTick } from "vue";
import PhoneElement from "@/validation/pages/validation/[project_id]/components/table/PhoneElement.vue";
import type { PhoneItem } from "@/validation/pages/validation/[project_id]/types";

const props = defineProps({
  phones: { type: Array as PropType<PhoneItem[]>, default: () => ([]) },
  personId: { type: String, required: true },
});

const emit = defineEmits(["update:phones"]);

const input = ref<HTMLInputElement>();

const editable = ref(false);
const localPhones = ref<PhoneItem[]>([]);

const inlinePhones = ref("");

function savePhones() {
  editable.value = false;

  const list = inlinePhones.value.split(",")
    .filter(value => !!value.trim());

  localPhones.value = list.map((value) => {
    const [country, ...rest] = value.trim().replaceAll(/[^\d\s]/g, "").split(" ");

    return ({ calling_code: rest.length ? country : 55, number: rest.length ? rest.join("") : country });
  });

  return emit("update:phones", props.personId, localPhones.value);
}

function parsePhones() {
  localPhones.value = props.phones || [];
  const items = localPhones.value.map(value => (`${value.calling_code} ${value.number}`)) || [];

  inlinePhones.value = items.join(",");
}

function editMode() {
  editable.value = true;

  nextTick(() => input.value?.focus());
}

watch(props, () => parsePhones(), { deep: true, immediate: true });
</script>

<template>
  <div :class="{ 'editable-zone': !localPhones.length }" @click="editMode">
    <div class="phone-item">
      <template v-for="(phone, key) in localPhones" :key="key">
        <PhoneElement :number="phone.number" :code="phone.calling_code" :is-whatsapp="!key" />
      </template>
    </div>

    <input
      v-show="editable"
      ref="input"
      v-model="inlinePhones"
      class="edit-phones"
      type="text"
      @change="savePhones"
      @blur="editable = false"
    >
  </div>
</template>

<style lang="scss" scoped>
.phone-item {
  display: flex;
  justify-content: center;
}

.editable-zone {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

input.edit-phones {
  position: absolute;
  inset: 0;
  outline: $gray-lighter;
  color: $gray-dark;
  font-size: 1rem;
  text-align: center;
  font-family: "Open Sans", sans-serif;
}
</style>
