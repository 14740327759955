export const text = {
  close: "Fechar",
  tooltip: "Descartar Alteração",
  textExpand: {
    expandButton: "Ver mais",
  },
  textArea: {
    defaultWarning: "Para melhorar a performance, sugerimos um máximo de {count} caracteres.",
    secondaryWarning: "Sugerimos um máximo de {count} caracteres.",
    shorlistWarning: "Máximo de {count} caracteres.",
    funneltWarning: "Permitido no máximo de {count} caracteres.",
  },
  inputCounter: {
    defaultWarning: "Máximo de {count} caracteres.",
  },
  form: {
    restore_original: "Restaurar original",
    submit: "Salvar alterações",
    submitAvaliation: "Salvar avaliação",
    saveQuestion: "Salvar pergunta",
    save: "Salvar",
    finish: "Salvar e Finalizar",
    finalize: "Finalizar",
    conclude: "Concluir",
    change: "Alterar",
    cancel: "Cancelar",
    addElement: "Adicionar {complement}",
    editElement: "Editar {complement}",
    answerElement: "Responder {complement}",
    removeElement: "Remover {complement}",
    yes: "Sim",
    no: "Não",
    error: {
      modal: {
        genericTitle: "ERRO!",
        genericMessage: "Houve um erro, tente novamente",
        genericButtonMessage: "TENTAR NOVAMENTE",
      },
    },
    removeAvatar: "Remover Avatar",
    maxChars: "Máximo de {count} caracteres.",
  },
  filters: {
    loadMore: "Carregar mais ...",
  },
  viewProfileInNewPage: "Visualizar perfil em nova janela.",
  comingSoonTitle: "Em Breve!",
  comingSoonParagraph: "Este conteúdo ainda não está disponível.",
  pageNotFoundTitle: "Página não encontrada",
  pageNotFoundParagraph:
    "Não foi possível encontrar o que você estava procurando.<br>Mas não se preocupe, você pode continuar navegando tranquilamente.",
  pageNotFoundRedirection: "Voltar para a página inicial",
  paymentFrequency: {
    MONTHLY: ["Mensal", "mês"],
    ANNUALLY: ["Anual", "ano"],
  },
  start: "Início",
  end: "Fim",
  addRelationship: "add relationship",
  select: "Selecione",
  selected: "Selecione | 1 selecionado | {n} selecionados",
  searchProfile: "digite para buscar um perfil",
  searchProfileNotFound: "Nenhum perfil encontrado",
  searchProfileError: "Error ao buscar os perfis. Tente novamente",
  searchCorporation: "digite para buscar uma empresa",
  searchBusinessFunction: "digite para buscar uma linha de negócio",
  searchBusinessFunctionNotFound: "Nenhuma linha de negócio encontrada",
  searchCorporationNotFound: "Nenhuma empresa encontrada",
  searchBusinessFunctionError: "Error ao buscar linha de negócio. Tente novamente",
  searchCorporationError: "Error ao buscar as empresas. Tente novamente",
  startDate: "Data inicial",
  endDate: "Data final",
  presentationDate: "Data apresentação",
  emptyDate: "dd/mm/aaaa",
  toBeDefined: "A definir",
  presentationDate: "Data apresentação",
  emptyDate: "dd/mm/aaaa",
  draft: "Rascunho",
  searchText:
    "nunhum resultado para a busca realizada | 1 resultado para a busca realizada | {count} resultados para a busca realizada",
  conjunctions: {
    of: "de",
    and: " e ",
  },
  backToList: "Voltar para a lista",
  numberRegistration: "1 cadastro | {count} cadastros",
  messagePerson: "- Projeto {count} -",
  others: "outros",
  date: "Data",
  name: "Nome",
  activitie: "Atividade",
  status: "Status",
  fromCandidate: "- do candidato",
  loading: "Carregando",
  text: "Texto",
};
