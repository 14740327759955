import type { LoginResponse } from "@/http_services/auth/types";

/**
 * @param value
 * @returns convert unix timestamp to ecmascript timestamp
 */
export const secondsToMilliseconds = (value: number): number => value * 1000;

export function authenticate(authData: LoginResponse): void {
  // fix timestamps
  authData.access_expiry = secondsToMilliseconds(authData.access_expiry);
  authData.refresh_expiry = secondsToMilliseconds(authData.refresh_expiry);
  Object.entries(authData).forEach(([key, value]) => localStorage.setItem(key, String(value)));
}

export const hasToken = (): boolean => Boolean(localStorage.getItem("access_token"));
export function hasExpired(): boolean {
  return Boolean(
    Number(localStorage.getItem("refresh_expiry")) < Date.now(),
  );
}
