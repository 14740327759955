/** Tracks user custom events using Posthog */
import posthog from "posthog-js";

export default function useTracking() {
  const identify = (id: string, props: any) => {
    posthog.identify(id, props);
  };

  const capture = (event: string, props: any | null = null) => {
    posthog.capture(event, props);
  };

  return {
    identify,
    capture,
  };
}
