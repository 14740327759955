import { z } from "zod";
import { EntitySchema } from "@/types/entity";
import { BaseActivitySchema, BasePersonInActivitySchema, PersonInActivitySchema } from "@/types/activity";

const ConstructionAnnotationsSchema = z.object({
  header: z.string(),
  ideal_profile: z.string(),
  governance: z.string(),
  strategy: z.string(),
  executions_conditions: z.string(),
});

const ConstructionAnnotationsPatchSchema = ConstructionAnnotationsSchema.partial();

export type ConstructionAnnotationsPatchType = z.infer<typeof ConstructionAnnotationsPatchSchema>;

const ConstructionActivityDescriptionEnum = z.enum([
  "CONSTRUCTION_MEETING",
  "VALIDATION_MEETING",
  "INTERVIEWS",
  "RESEARCH_AND_PICKING",
]);

// use in create also
const BaseConstructionActivitySchema = BaseActivitySchema.extend({
  description: ConstructionActivityDescriptionEnum,
  requester: BasePersonInActivitySchema,
});

export type BaseConstructionActivity = z.infer<typeof BaseConstructionActivitySchema>;

// use on update also
const ConstructionActivitySchema = BaseActivitySchema.extend({
  description: ConstructionActivityDescriptionEnum,
  requester: PersonInActivitySchema,
  annotations: ConstructionAnnotationsSchema.nullable(),
}).strict();

type ConstructionActivity = z.infer<typeof ConstructionActivitySchema>;

const ConstructionActivityPatchSchema = ConstructionActivitySchema.partial();

type ConstructionActivityPatch = z.infer<typeof ConstructionActivityPatchSchema>;

const ActivityStripSchema = ConstructionActivitySchema.strip();

export const ConstructionActivityDtoSchema = z.intersection(EntitySchema, ActivityStripSchema);

export type ConstructionActivityDto = z.infer<typeof ConstructionActivityDtoSchema>;
