<script lang="ts">
import { useI18n } from "vue-i18n";
import type { Ref } from "vue";
import type { Person } from "@/components/person_card/interfaces";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const person = (inject("selectedPerson") || inject("person")) as Ref<Person>;

    const languages = ref(person.value.languages);

    return {
      languages,
      person,
      t,
    };
  },
});
</script>

<template>
  <div v-if="person.languages && person.languages.length" class="group-wrapper">
    <h4>
      {{ person.languages.length }}&nbsp;
      {{
        person.languages.length > 1 ? t("persons.details.languages") : t("persons.details.language")
      }}
    </h4>

    <div v-for="(language, index) of person.languages" :key="`language-${index}`">
      <p>
        - {{ t(`languages.name.${language.language.toLocaleLowerCase()}.label`) }}
        <span v-if="language.proficiency">
          - {{ t(`languages.proficiency.${language.proficiency}`) }}
        </span>
      </p>
    </div>
  </div>
</template>

<style scoped="true" lang="scss">
.group-wrapper {
  position: relative;
  padding-bottom: 1.5rem;
  margin-bottom: 2rem;

  &::after {
    position: absolute;
    bottom: -20px;
    left: 71px;
    width: calc(100% - 71px);
    height: 1px;
    background: #e9e5e5;
    content: " ";
  }
}

h4 {
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: $yellow-dark;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}

p {
  margin-top: 1rem;
  color: $gray-dark;
  font-size: 14px;
}
</style>
