<script setup lang="ts">
import IdealProfileTabs from "./components/IdealProfileTabs.vue";
import { capitalizeFirstLetter } from "@/utils/text";
import { SvgIcon } from "@/components/icon";
import { addIdealProfileLines } from "@/projects/pages/projects/[id]/construction/ideal-profile/utils/idealProfileSvgLines";

const props = defineProps<{ id: string }>();
const router = useRouter();
const { t } = useI18n();
const { data: project, error, isLoading: loading } = await useProject(props.id);

const AsyncIdealProfileForm = defineAsyncComponent(() =>
  import("./components/forms/IdealProfileForm.vue"));

const textSizeWidth = ref(500);

const {
  stageTabs,
} = useTabsProject(props.id);

const currentRoute = computed(() => stageTabs.value.findIndex(tab => tab.key === router.currentRoute.value.meta.tab));

const {
  data: idealProfile,
} = useConstructionIdealProfile(props.id);

function getFormattedPackage(data: { salary: string; total_cash: string }): string[] {
  if (!data)
    return [];
  const formattedData: string[] = [];
  if (data.salary)
    formattedData.push(`${t("projects.construction.labels.salary")}: ${data.salary}`);
  if (data.total_cash)
    formattedData.push(`${t("projects.construction.labels.totalCash")}: ${data.total_cash}`);

  return formattedData;
}
const profileData: Ref<Array<{
  title: string
  icon: string
  items: Array<string>
  key: string
}>> = ref([]);

function goTo(tabIndex: number) {
  if (!stageTabs.value || !stageTabs.value[tabIndex])
    return;

  router.push(stageTabs.value[tabIndex].path);
}

const editing = ref(false);
const attributesKeys = computed(() => {
  return {
    cognitive_intelligence: t("projects.details.constructionGuide.idealProfile.attributesKeys.cognitiveIntelligence"),
    emotional_intelligence: t("projects.details.constructionGuide.idealProfile.attributesKeys.emotionalIntelligence"),
    spiritual_intelligence: t("projects.details.constructionGuide.idealProfile.attributesKeys.spiritualIntelligence"),
    energy: t("projects.details.constructionGuide.idealProfile.attributesKeys.energy"),
    maturity: t("projects.details.constructionGuide.idealProfile.attributesKeys.maturity"),
  };
});
const culturalMatchKeys = computed(() => {
  return {
    affective: capitalizeFirstLetter(t("projects.details.constructionGuide.idealProfile.culturalMatchKeys.affective")),
    logical: capitalizeFirstLetter(t("projects.details.constructionGuide.idealProfile.culturalMatchKeys.logical")),
    practical: capitalizeFirstLetter(t("projects.details.constructionGuide.idealProfile.culturalMatchKeys.practical")),
    reflexive: capitalizeFirstLetter(t("projects.details.constructionGuide.idealProfile.culturalMatchKeys.reflexive")),
  };
});

watchEffect(() => {
  profileData.value = [
    {
      title: t("projects.details.constructionGuide.idealProfile.academicFormation"),
      icon: "project_academic",
      items: Object.values(idealProfile?.value?.academic_backgrounds || {}).concat(
        Object.values(idealProfile?.value?.languages || {}),
      ),
      key: "academic",
    },
    {
      title: t("projects.details.constructionGuide.idealProfile.experience"),
      icon: "project_company",
      items: Object.values(idealProfile?.value?.experiences_tags || [])
        .filter(tag => tag.selected)
        .map(tag => tag.text),
      key: "company",
    },
    {
      title: t("projects.details.constructionGuide.idealProfile.motivation"),
      icon: "project_motivation",
      items: getFormattedPackage(idealProfile?.value?.package),
      key: "motivation",
    },
    {
      title: t("projects.details.constructionGuide.idealProfile.realizations.title"),
      icon: "project_realizations",
      items: (idealProfile?.value?.achievements_tags || []).filter(tag => tag.selected).map(tag => tag.text),
      key: "achievements",
    },
    {
      title: t("projects.details.constructionGuide.idealProfile.attributes"),
      icon: "project_attributes",
      items: idealProfile?.value?.attributes
        ? Object.keys(idealProfile?.value?.attributes)
          .map((key: string) => idealProfile?.value?.attributes[key] !== "EMPTY" ? attributesKeys.value[key] : null)
          .filter(Boolean)
        : [],
      key: "attributes",
    },
    {
      title: t("projects.details.constructionGuide.idealProfile.culturalMatch"),
      icon: "project_cultural_match",
      items: idealProfile?.value?.cultural_match
        ? Object.keys(idealProfile?.value?.cultural_match)
          .map((key: string) => idealProfile?.value?.cultural_match[key] !== "EMPTY" ? culturalMatchKeys.value[key] : null)
          .filter(Boolean)
        : [],
      key: "cultural-match",
    },
  ];
});

const containerCenterIcon = ref<HTMLElement | null>(null);
const firstContainer = ref<HTMLElement | null>(null);
const thirdContainer = ref<HTMLElement | null>(null);
// left
const academicBackgroundUnderline = ref<HTMLElement | null>(null);
const experiencesUnderline = ref<HTMLElement | null>(null);
const motivationUnderline = ref<HTMLElement | null>(null);

const academicBackgroundSvgLine = ref<HTMLElement | null>(null);
const experiencesSvgLine = ref<HTMLElement | null>(null);
const motivationSvgLine = ref<HTMLElement | null>(null);
// right
const achievementsUnderline = ref<HTMLElement | null>(null);
const attributesUnderline = ref<HTMLElement | null>(null);
const culturalMatchUnderline = ref<HTMLElement | null>(null);

const achievementsSvgLine = ref<HTMLElement | null>(null);
const attributesSvgLine = ref<HTMLElement | null>(null);
const culturalMatchSvgLine = ref<HTMLElement | null>(null);

const debouncedFn = useDebounceFn(() => {
  addIdealProfileLines({
    academicBackgroundUnderline,
    experiencesUnderline,
    motivationUnderline,
    containerCenterIcon,
    academicBackgroundSvgLine,
    experiencesSvgLine,
    motivationSvgLine,
    achievementsUnderline,
    achievementsSvgLine,
    attributesUnderline,
    attributesSvgLine,
    culturalMatchUnderline,
    culturalMatchSvgLine,
    firstContainer,
    thirdContainer,
    titleHeight: 34,
  });
}, 50);
onMounted(() => debouncedFn());
</script>

<template>
  <section class="project-section" @vue:updated="debouncedFn()">
    <div v-if="error">
      could not load
    </div>
    <div class="project-container">
      <ProjectStages :id="props.id" />
      <div v-if="project && idealProfile" class="project-wrapper">
        <ProjectTabs :stage-tabs="stageTabs" :active="currentRoute" class="tabs" @change="goTo($event)" />
        <IdealProfileTabs :editing="editing" :project-id="project._id" :stages="project.stages" @edit="editing = true" />
        <div v-if="!editing" class="project-content">
          <div id="profile-container" class="profile-container">
            <div ref="firstContainer" class="column-1">
              <div
                v-for="(row) in profileData.slice(0, 3)"
                :id="row.key"
                :key="row.title"
                class="content col1"
                :style="{ 'max-width': textSizeWidth }"
              >
                <div class="title">
                  <div class="icon">
                    <SvgIcon
                      :icon="row.icon"
                      margin="none"
                      color="#FFB600"
                      width="1.125rem"
                      height="1.125rem"
                    />
                  </div>
                  <div class="content-title">
                    {{ row.title }}
                  </div>
                </div>
                <ul v-if="row.items">
                  <li v-for="item in row.items" :key="item" v-ellipsis="150">
                    <template v-if="item.length > 150">
                      <p class="clamp">
                        {{ item }}
                        {{ row }}
                      </p>
                    </template>
                    <template v-else>
                      {{ item }}
                    </template>
                  </li>
                </ul>
              </div>
            </div>
            <div class="column-2">
              <div ref="containerCenterIcon" class="icon">
                <SvgIcon
                  icon="project_ideal_profile"
                  margin="none"
                  color="#FFB600"
                  width="5rem"
                  height="5rem"
                />
              </div>
            </div>
            <div ref="thirdContainer" class="column-3">
              <div
                v-for="(row) in profileData.slice(3)"
                :id="row.key"
                :key="row.title"
                class="content"
                :style="{ 'max-width': textSizeWidth }"
              >
                <div class="title">
                  <div class="icon">
                    <SvgIcon
                      :icon="row.icon"
                      margin="none"
                      color="#FFB600"
                      width="1.125rem"
                      height="1.125rem"
                    />
                  </div>
                  <div class="content-title">
                    {{ row.title }}
                  </div>
                </div>
                <ul>
                  <li v-for="item in row.items" :key="item" v-ellipsis="150">
                    <template v-if="item.length > 150">
                      <p class="clamp">
                        {{ item }}
                      </p>
                    </template>
                    <template v-else>
                      {{ item }}
                    </template>
                  </li>
                </ul>
              </div>
            </div>
            <hr ref="academicBackgroundUnderline" class="svg-connection">
            <hr ref="experiencesUnderline" class="svg-connection">
            <hr ref="motivationUnderline" class="svg-connection motivation">
            <hr ref="achievementsUnderline" class="svg-connection">
            <hr ref="attributesUnderline" class="svg-connection">
            <hr ref="culturalMatchUnderline" class="svg-connection">
            <svg id="svg">
              <line ref="academicBackgroundSvgLine" class="line" />
              <line ref="experiencesSvgLine" class="line" />
              <line ref="motivationSvgLine" class="line" />
              <line ref="achievementsSvgLine" class="line" />
              <line ref="attributesSvgLine" class="line" />
              <line ref="culturalMatchSvgLine" class="line" />
            </svg>
          </div>
        </div>
        <div v-else class="project-content">
          <AsyncIdealProfileForm @cancel="editing = false" />
        </div>
      </div>
    </div>
  </section>
</template>

<route lang="yaml">
meta:
  layout: project-stage
  stage: construction
  tab: ideal-profile
  ideal-profile: ideal-profile-tags
</route>

<style scoped lang="scss">
.svg-connection {
  position: absolute;
  width: 22rem;
  border: 0;
  height: 1px;
  background: $gray-border;
    &.motivation {
    width: 35%;
    margin-left: 4rem;
    }
    &.attributes {
        width: 25%;
    }
    &.cultural-match {
        width: 15rem
    }
}
#svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

}
.line {
  stroke-width: 1px;
  stroke: $gray-border;
  z-index: 1;
}
.project-section {
  min-height: 100vh;
}
.project-container {
  padding-top: 3.75rem;
}
.project-wrapper {
  background: white;
}
.project-content {
  margin-inline: 2.5rem;
}
</style>

<style scoped lang="scss">
.profile-container {
  position: relative;
  display: grid;
  font-size: 0.875rem;
  grid-template-columns: repeat(3, 1fr);
  letter-spacing: 0.49px;
  line-height: 1.375rem;
  margin-bottom: 2.5rem;

  .container-svg {
    position: absolute;
    top: 0;
    display: block;

    > line {
      stroke: $gray-border;
      stroke-width: 1;
    }
  }

  .content-center {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;

    .icon {
      display: flex;
      width: 9rem;
      height: 9rem;
      align-items: center;
      justify-content: center;
      border-radius: 9rem;
      background-color: $white-background;
      box-shadow: 0 2px 4px #00000029;
    }
  }

  .content {
    padding-bottom: 1.5rem;
    &#motivation {
      margin-left: 4rem;
      }
    &#attributes {
      margin-left: 4rem;
    }
    .title {
      display: flex;
      align-items: center;
      font-size: 1.25rem;
      gap: 0.5rem;
      text-transform: uppercase;
      white-space: nowrap;

      .icon {
        display: flex;
        width: 2rem;
        height: 2rem;
        align-items: center;
        justify-content: center;
        border-radius: 2rem;
        background-color: $white-background;
        box-shadow: 0 2px 4px #00000029;
        aspect-ratio: 1;
      }
    }

    ul {
      padding: 0.5rem;
      padding-inline-start: 2rem;
      padding-bottom: 0;
      line-height: 1.25rem;
      list-style: disc;
      max-width: 20rem;

      li {
        color: $gray-lower;
        word-break: break-word;

        &::marker {
          color: $yellow;
          font-size: 1rem;
        }
      }
    }
  }
  .column-2 {
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      display: flex;
      width: 9rem;
      height: 9rem;
      align-items: center;
      justify-content: center;
      border-radius: 9rem;
      background-color: $white-background;
      box-shadow: 0 2px 4px #00000029;
    }
  }

  .column-1,
  .column-3 {
    display: grid;
    max-width: 22.5625rem;
    z-index: 2;
  }
  .column-2 .icon {
    z-index: 2;
  }
}

.content-title {
  width: fit-content;
  padding-bottom: 0.125rem;
}

.clamp {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  word-break: break-word;
}
</style>

<style global>
.export .clamp {
  display: initial;
}
</style>
