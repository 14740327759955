<script setup lang="ts">
import { computed, inject, ref } from "vue";
import { useI18n } from "vue-i18n";
import { completeDate } from "@/utils/dates";
import { formatCurrency } from "@/utils/numbers";
import type { Corporation } from "@/entities/corporation";
import { Avatar } from "@/components/avatar";
import { Card as BaseCard } from "@/components/card";
import { Button2 as Button } from "@/components/button";
import RefreshIcon from "@/assets/images/refresh_icon.svg";
import { parsePhone } from "@/utils/parsers";
import { SvgIcon } from "@/components/icon";
import LinkedinIcon from "@/assets/images/linkedin_icon.svg";
import LinkedinIconBlue from "@/assets/images/linkedin_icon_blue.svg";

interface PropsType {
  corporation: Corporation
}

const props = defineProps<PropsType>();

const { t, locale } = useI18n();
const router = useRouter();
const lastUpdated = computed(() => {
  const { updated_at } = props.corporation as Corporation;
  if (updated_at)
    return completeDate(updated_at);
  return "";
});
const corporationInfo = computed(() => {
  const {
    business_categories: sectors,
    number_of_employees: employees,
    revenues,
    phone,
    number_of_placements,
    job_vacancies,
  } = props.corporation as Corporation;

  const { number, calling_code } = phone || { number: "", calling_code: "" };
  const sector = sectors.length ? sectors[0] : { sector: "NOT_AVAILABLE" };
  const { amount, currency } = revenues?.length
    ? revenues[0]
    : { amount: null, currency: null };
  const map = new Map();

  map.set(t("corporations.sector.name"), t(`corporations.sector.enum.${sector.sector}`));
  map.set(
    t("corporations.employees.number"),
    employees ? t(`corporations.employees.enum.${employees}`) : "",
  );
  map.set(
    t("corporations.yearlyIncome"),
    amount ? formatCurrency(currency, locale.value)(amount) : "",
  );
  map.set(t("corporations.jobVacancies"), `${job_vacancies > 0 ? job_vacancies : ""}`);
  map.set(t("corporations.placements"), `${number_of_placements > 0 ? number_of_placements : ""}`);
  map.set(t("corporations.phone"), `${calling_code && number ? parsePhone(calling_code, number) : ""}`);

  return map;
});

function onClickDetails(corporation: Corporation) {
  router.push(`/corporations/${corporation.id}`);
}

const onClickEdit = inject("onClickEdit");
const hoveredLinkedin = ref(false);
function socialNetworkClick(url: string) {
  window.open(url, "_blank");
}
</script>

<template>
  <BaseCard class="company-card" v-bind="$attrs">
    <div class="avatar">
      <router-link :to="`/corporations/${corporation.id}`">
        <Avatar
          :src="corporation.avatar_url"
          :alt="corporation.name"
          image-size="large"
          size="140px"
          placeholder="general-company"
          type="native"
          :has-alternative-icon="false"
        />
      </router-link>
    </div>
    <div class="details">
      <div class="details__header">
        <h2 data-test="corporation-name" class="company-title">
          <span class="name">
            {{ corporation.name }}
          </span>
          <span class="serial">({{ corporation.serial }})</span>
          <span class="actions">
            <template v-if="corporation.linkedin_url">
              <span>
                <LinkedinIcon
                  v-show="!hoveredLinkedin"
                  class="social-icon"
                  width="0.875rem"
                  height="0.875rem"
                  @mouseenter="hoveredLinkedin = true"
                />
                <LinkedinIconBlue
                  v-show="hoveredLinkedin"
                  class="social-icon"
                  width="0.875rem"
                  height="0.875rem"
                  @mouseleave="hoveredLinkedin = false"
                  @click="socialNetworkClick(corporation.linkedin_url)"
                />
              </span>
            </template>
            <span
              role="span"
              aria-label="edit"
              target="_blank"
              class="edit-icon"
              @click="$router.push(`/corporations/${corporation.id}`)"
            >
              <SvgIcon icon="edit_icon" type="native" width="14px" />
            </span>
          </span>
        </h2>
        <div class="btn">
          <router-link :to="`/corporations/${corporation.id}`">
            <Button variation="secondary">
              {{ t("corporations.corporationCard.details") }}
            </Button>
          </router-link>
        </div>
      </div>

      <div class="details__content">
        <div v-for="[key, value] in corporationInfo" :key="key" class="company-info">
          <span class="info-value">{{ key }}:</span>
          <span v-if="value" v-html="` ${value ? value : ''}`" />
        </div>
      </div>
    </div>

    <div class="updated">
      <div v-show="lastUpdated" class="last-updated">
        <RefreshIcon :aria-label="t('corporations.corporationCard.refreshed')" class="refresh-icon" />
        <span>{{ lastUpdated }}</span>
      </div>
    </div>

    <div v-if="false" class="personel">
      <div class="employees">
        <img
          loading="lazy"
          class="recruiter"
          src="https://i.pravatar.cc/30"
          :alt="t('corporations.corporationCard.employeesAlt')"
        >
        <img
          loading="lazy"
          class="recruiter"
          src="https://i.pravatar.cc/30"
          :alt="t('corporations.corporationCard.employeesAlt')"
        >
        <img
          loading="lazy"
          class="recruiter"
          src="https://i.pravatar.cc/30"
          :alt="t('corporations.corporationCard.employeesAlt')"
        >
      </div>
    </div>
  </BaseCard>
</template>

<style lang="scss" scoped>
@mixin round-and-center($size) {
  width: $size;
  height: $size;
  border-radius: 50%;
  line-height: $size;
  text-align: center;
}

.edit-icon:hover {
  color: $primary !important;
  cursor: pointer;
}

.company-card {
  --card-areas: "avatar" "details" "personel" "updated";
  --card-colums: 1fr;

  display: grid;
  margin-bottom: 1rem;
  grid-gap: 1rem;
  grid-template-areas: var(--card-areas);
  grid-template-columns: var(--card-colums);

  .social-icon {
    cursor: pointer;
  }

  .details__header {
    .company-title {
      display: flex;
      max-width: 70%;
      margin: 0;
    }

    .actions {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
    }

    .btn {
      min-width: max-content;

      :deep(.imua-button) {
        padding: 0.375rem 0.75rem;
      }
    }
  }

  .avatar {
    grid-area: avatar;
    text-align: center;

    &__brand span {
      color: $gray-low;
    }
  }

  .details {
    grid-area: details;

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.625rem;

      .company-title {
        display: flex;
        align-items: flex-start;
        color: $gray-dark;
        font-size: 1.125rem;
        font-weight: bold;
        gap: 0.5rem;

        .serial {
          color: $gray-low;
          font-size: 0.75rem;
          font-weight: normal;
          padding-block-start: 0.2rem;
        }

        a {
          color: $gray-low;
          cursor: pointer;
        }
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      margin-block-start: 1.25rem;

      .company-info {
        color: $gray-dark;
        font-size: 0.875rem;

        .info-value {
          font-weight: bold;
        }
      }
    }
  }

  .updated {
    color: $gray-dark;
    grid-area: updated;
    text-align: center;

    .last-updated {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.75rem;
    }

    .refresh-icon {
      --size: 1.2em;

      width: var(--size);
      height: var(--size);
      margin-right: 0.5rem;
      stroke: $gray-low;
    }
  }

  .personel {
    display: flex;
    grid-area: personel;

    .employees {
      display: flex;
      margin: auto;

      .recruiter {
        --badge-size: 30px;

        display: inline-block;
        overflow: hidden;

        @include round-and-center(var(--badge-size));

        border: 2px solid var(--bg-card-color);

        &:nth-of-type(1) {
          z-index: 1;
          transform: translateX(50%);
        }

        &:nth-of-type(2) {
          transform: translateX(25%);
        }
      }
    }
  }

  .edit-icon {
    position: relative;
    width: 14px;
    margin-left: 0.3rem;
    stroke: #ccc;
  }
}

@media screen and (min-width: 640px) {
  .company-card {
    --card-areas: "avatar details" "updated personel";
    --card-colums: auto 1fr;

    .personel {
      .employees {
        margin: 0 0 0 auto;
      }
    }
  }
}
</style>
