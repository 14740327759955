<script lang="ts">
import { computed, defineComponent } from "vue";
import { has } from "lodash";

export default defineComponent({
  name: "ImuaRadioButton",
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number, Boolean],
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: [String, Number],
      required: true,
    },
    modelValue: {
      type: [String, Number],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const model = computed({
      get: () => props.modelValue,
      set: newValue => emit("update:modelValue", newValue),
    });

    return {
      model,
      has,
    };
  },
});
</script>

<template>
  <div
    class="imua-radiobutton"
    :class="[
      $attrs['wrapper-class'] || '',
      { 'imua-radiobutton--block': has($attrs, 'block') },
    ]"
  >
    <input
      :id="id"
      v-model="model"
      class="imua-radiobutton__radio"
      :class="[$attrs['input-class'] || '']"
      type="radio"
      :name="name"
      :value="value"
      :disabled="disabled"
    >
    <label :for="id" class="imua-radiobutton__label" :class="[$attrs['label-class'] || '']">
      {{ label }}
    </label>
  </div>
</template>

<style lang="scss" scoped>
.imua-radiobutton {
  display: inline-block;

  &--block {
    display: block;
  }

  &__radio {
    display: none;
  }

  &__radio:checked + &__label {
    color: $white-full;

    &::before {
      opacity: 1;
    }
  }

  &__label {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    cursor: pointer;
    font-size: 0.75rem;
    isolation: isolate;

    &::before {
      position: absolute;
      z-index: -1;
      border-radius: 8px;
      background-color: $primary;
      content: "";
      inset: 0;
      opacity: 0;
      transition: opacity ease-out 250ms;
    }
  }
}
</style>
