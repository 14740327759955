<script lang="ts">
import { computed, defineComponent, ref, watch } from "vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";

export default defineComponent({
  name: "Table",
  components: {
    DataTable,
    Column,
  },
  props: {
    columns: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      default: () => [],
    },
    paginator: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: Number,
      default: 10,
    },
    totalRecords: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { slots, emit }) {
    const isHeaderSlotForAllColumns = computed(() => !!slots["column-header"]);
    const isBodySlotForAllColumns = computed(() => !!slots["column-body"]);

    const firstRecordIndex = ref(0);

    const getSlotName = (slotName: string, forAllColumns: boolean, index: number) => {
      if (forAllColumns)
        return slotName;
      return `${slotName}-${index}`;
    };

    watch(firstRecordIndex, (value) => {
      emit("onChange", value);
    });

    return {
      isHeaderSlotForAllColumns,
      isBodySlotForAllColumns,
      getSlotName,
      firstRecordIndex,
    };
  },
});
</script>

<template>
  <DataTable
    v-model:first="firstRecordIndex"
    class="imua-table"
    :value="data"
    responsive-layout="scrollable"
    :paginator="paginator"
    :rows="rows"
    :total-records="totalRecords"
  >
    <template v-if="$slots['table-header']" #header>
      <slot name="table-header" />
    </template>

    <template v-if="$slots['table-footer']" #footer>
      <slot name="table-footer" />
    </template>

    <Column v-for="(column, index) in columns" :key="index" v-bind="column">
      <template
        v-if="$slots[getSlotName('column-header', isHeaderSlotForAllColumns, index)]"
        #header="slotProps"
      >
        <slot
          :name="getSlotName('column-header', isHeaderSlotForAllColumns, index)"
          v-bind="slotProps"
        />
      </template>

      <template
        v-if="$slots[getSlotName('column-body', isBodySlotForAllColumns, index)]"
        #body="slotProps"
      >
        <slot
          :name="getSlotName('column-body', isBodySlotForAllColumns, index)"
          v-bind="slotProps"
        />
      </template>
    </Column>
  </DataTable>
</template>

<style lang="scss" scoped>
.imua-table:deep {
  .p-datatable-table {
    border-collapse: separate;
    border-spacing: 0 0.5rem;
  }

  .p-datatable-header,
  .p-datatable-footer {
    padding-left: 0;
    background-color: transparent;
    color: $gray-dark;
    font-weight: bold;
    text-transform: uppercase;
  }

  .p-datatable-header {
    border-top: none;
  }

  .p-datatable-thead > tr > th {
    border: none;
    background-color: transparent;

    .p-column-title {
      color: $primary;
      font-size: 0.75rem;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  .p-datatable-tbody > tr > td {
    border: none;
    background-color: $white-lotion;
    vertical-align: middle;
  }
}
</style>
