<script setup lang="ts">
import type { Ref } from "vue";
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { helpers, required, requiredIf } from "@vuelidate/validators";
import get from "lodash-es/get";
import isNumber from "lodash-es/isNumber";
import set from "lodash-es/set";
import Realizations from "./Realizations.vue";
import CheckParserExperience from "./CheckParserExperience.vue";
import { FormInput, SearchDropdown, Select, TextArea } from "@/components/inputs/modalInputs";
import { CustomCheckBox } from "@/components/checkbox";
import { createCorporation, searchCorporation, searchCorporationSimilar } from "@/http_services/entities/corporation";
import { moneyCurrencies } from "@/utils/adapters/form";
import { months, parseAndGetDates, watchDatesAndSetInObj, years } from "@/utils/dates";
import { PAYMENT_FREQUENCY } from "@/entities/Person/work-experience";
import type { WorkExperiencePayload } from "@/entities/Person/form";
import type { Corporation } from "@/entities/corporation";
import { formatValueFloat } from "@/utils/numbers";
import { normalizeSearch } from "@/utils/text";

interface PropsType {
  edit?: boolean
  experience?: any
  experienceEdit?: any
  experienceFilledField?: any
  autoFilledEditFields?: any
  onRemove?: () => void
}

const props = withDefaults(defineProps<PropsType>(), {
  experience: {},
});

const emit = defineEmits(["click:add", "click:remove", "click:remove-edit", "add:company"]);

const { t } = useI18n();

const onRemove = toRef(props, "onRemove") as Ref<() => void>;
const payload = toRef(props, "experience") as Ref<WorkExperiencePayload>;

const parsedPayload = toRef(
  props,
  "experienceEdit",
  payload.value,
) as Ref<WorkExperiencePayload>;
const searchInput = ref<InstanceType<typeof SearchDropdown>>();
const corporationNotFound = ref(false);
const filledFields = toRef(props, "experienceFilledField");
const corporationParsedName = ref();

const paymentFrequencies = computed(() => [
  {
    label: t("persons.workExperienceForm.placeholders.paymentFrequencies.month"),
    value: PAYMENT_FREQUENCY.MONTHLY,
  },
  {
    label: t("persons.workExperienceForm.placeholders.paymentFrequencies.year"),
    value: PAYMENT_FREQUENCY.ANNUALLY,
  },
]);

function formatAmount(value: any) {
  return value ? Number.parseFloat(value).toFixed(2) : 0;
}

const salary = ref({ ...payload.value.salary });
watch(
  salary,
  ({ amount, currency, ...rest }) => {
    if (amount && currency) {
      payload.value.salary = {
        ...rest,
        currency,
        amount: formatValueFloat(amount),
      };
    }
  },
  { deep: true },
);

const parsedSalary = ref({ ...parsedPayload?.value?.salary });
watch(
  parsedSalary,
  ({ amount, currency, ...rest }) => {
    if (amount && currency) {
      parsedPayload.value.salary = {
        ...rest,
        currency,
        amount: formatValueFloat(amount),
      };
    }
  },
  { deep: true },
);

const { startMonth, startYear } = parseAndGetDates<WorkExperiencePayload>(
  "start_date",
  payload,
)();
watch(
  [startMonth, startYear],
  watchDatesAndSetInObj<WorkExperiencePayload>("start_date", payload),
);

const { endMonth, endYear } = parseAndGetDates<WorkExperiencePayload>("end_date", payload)();
watch([endMonth, endYear], watchDatesAndSetInObj<WorkExperiencePayload>("end_date", payload));

watch(
  payload,
  (value) => {
    const { start_date, end_date, company } = value;

    if (!start_date && !isNumber(startMonth.value) && !isNumber(startYear.value)) {
      startMonth.value = null;
      startYear.value = null;
    }

    if (!end_date && !isNumber(endMonth.value) && !isNumber(endYear.value)) {
      endMonth.value = null;
      endYear.value = null;
    }

    if (!company?.name && searchInput.value) {
      if (typeof searchInput.value.setSearchValue === "function")
        searchInput.value.setSearchValue("");
    }
  },
  { deep: true },
);

function changeCheckboxValue(event) {
  payload.value.is_current = event.target.checked;
  if (event.target.checked) {
    endMonth.value = null;
    endYear.value = null;
  }
}

function selectCorporation(corporation: Corporation) {
  const { name, id } = corporation;

  payload.value.company = {
    name,
    corporation_id: id,
  };

  emit("add:company", corporation);
}

function dateValidate() {
  if (payload.value.start_date && payload.value.end_date) {
    const startDate = new Date(payload.value.start_date);
    const endDate = new Date(payload.value.end_date);
    return (endDate >= startDate);
  }
  return true;
}

const rules = computed(() => ({
  payload: {
    role_name: {
      required: helpers.withMessage(t("validator.required"), required),
    },
    end_date: {
      dateValidate: helpers.withMessage(t("errors.messages.dates"), dateValidate),
    },
  },
  startMonth: {
    required: helpers.withMessage(t("validator.required"), requiredIf(!!startYear.value)),
    dateValidate: helpers.withMessage("", dateValidate),
  },
  startYear: {
    required: helpers.withMessage(
      t("validator.required"),
      requiredIf(isNumber(startMonth.value)),
    ),
    dateValidate: helpers.withMessage("", dateValidate),
  },
  endMonth: {
    required: helpers.withMessage(t("validator.required"), requiredIf(!!endYear.value)),
    dateValidate: helpers.withMessage("", dateValidate),
  },
  endYear: {
    required: helpers.withMessage(
      t("validator.required"),
      requiredIf(isNumber(endMonth.value)),
    ),
    dateValidate: helpers.withMessage("", dateValidate),
  },
}));

const v$ = useVuelidate(rules, {
  payload,
  startMonth,
  startYear,
  endMonth,
  endYear,
});

onUnmounted(() => {
  if (onRemove.value)
    onRemove.value();
});

async function handleSelect(corp: Corporation) {
  if (searchInput.value) {
    set(payload.value, "company.corporation_id", corp.id);
    await nextTick();
    corporationNotFound.value = false;
  }
}

async function searchAndCheckParsedCorporation() {
  const payloadCompanyName = get(payload.value, "company.name", "");
  if (payloadCompanyName && searchInput.value) {
    set(payload.value, "company.corporation_data.name", payloadCompanyName);
    const response = await searchCorporationSimilar({
      name: payloadCompanyName,
    });
    if (!(response.data || []).length) {
      corporationNotFound.value = true;
      return;
    }
    const responseCompanyName = get(response, "data[0].name", "");
    if (
      responseCompanyName
          && normalizeSearch(payloadCompanyName) === normalizeSearch(responseCompanyName)
    ) {
      set(payload.value, "company.corporation_id", response.data[0].id);
      corporationNotFound.value = false;
      await nextTick();
    }
    else {
      const corporationsAkas = response.data.map(corporation => (corporation.also_known_as || []).map(normalizeSearch));
      const corporationIdx = corporationsAkas.findIndex(akas => akas.includes(normalizeSearch(payloadCompanyName)));
      if (corporationIdx !== -1) {
        set(payload.value, "company.corporation_id", response.data[corporationIdx].id);
        set(payload.value, "company.name", response.data[corporationIdx].name);
        corporationParsedName.value = payloadCompanyName;
        // when an aka is found on parse, hint user with original informed name
        corporationNotFound.value = false;
        await nextTick();
      }
    }
  }
}

async function handleCreate(value: { name: string; domain: string }) {
  const response = await createCorporation({
    corporation: { ...value, business_categories: [] },
  });
  if (response)
    handleSelect(response);
}

function removeOption(key: string) {
  if (props.autoFilledEditFields)
    set(props.autoFilledEditFields, key, false);
}
watchEffect(async () => {
  if (filledFields.value?.company)
    await searchAndCheckParsedCorporation();
});
onMounted(async () => {
  if (salary.value.amount)
    salary.value.amount = formatAmount(salary.value.amount);

  if (parsedSalary.value.amount)
    parsedSalary.value.amount = formatAmount(parsedSalary.value.amount);
});

const handleCompanyBorder = computed(() => {
  if (corporationNotFound.value)
    return false;
  return filledFields.value?.company && (payload.value.company?.name || payload.value.company?.corporation_id);
});
</script>

<template>
  <div class="work-experience-wrapper">
    <div class="form-group">
      <label>
        {{ t("persons.workExperienceForm.roleName") }}:<strong class="required">*</strong>
      </label>

      <div class="input-group">
        <FormInput
          v-model="payload.role_name"
          :auto-fill="experienceFilledField?.role_name"
          :placeholder="t('persons.workExperienceForm.placeholders.roleName')"
          :error="get(v$, 'payload.role_name.$error', false)"
          :error-message="get(v$, 'payload.role_name.$errors.0.$message', '')"
        />
      </div>
    </div>

    <div :class="{ 'auto-filled': corporationNotFound }">
      <div class="form-group">
        <label>
          {{ t("persons.workExperienceForm.company") }}:
        </label>
        <div>
          <SearchDropdown
            id="company"
            ref="searchInput"
            :auto-fill="handleCompanyBorder"
            :auto-fill-color="!corporationNotFound && corporationParsedName ? '#ffb600' : '#c23584'"
            :fetch-method="searchCorporation"
            :select-item-callback="selectCorporation"
            :update-input-model="(corporation: Corporation) => corporation.name"
            :initial-value="payload.company?.corporation_data?.name || payload?.company?.name"
            :input-placeholder="t('persons.workExperienceForm.placeholders.company')"
            :search-not-found-label="t('text.searchCorporationNotFound')"
            :search-error-label="t('text.searchCorporationError')"
            :disabled="corporationNotFound ? true : false"
          >
            <template #list-item="{ item: corporation }">
              {{ corporation.name }}
            </template>
          </SearchDropdown>
          <div v-if="!corporationNotFound && corporationParsedName" class="corporation-alias-hint">
            {{ t('persons.workExperienceForm.corporationAliasHint.title') }} <span>"{{ corporationParsedName }}"</span> {{ t('persons.workExperienceForm.corporationAliasHint.as') }} <span>"{{ payload.company?.corporation_data?.name || payload?.company?.name }}"</span>
          </div>
          <CheckParserExperience
            v-if="corporationNotFound"
            :corporation-name="payload.company?.corporation_data?.name || payload?.company?.name"
            @select="handleSelect"
            @create="handleCreate"
          />
        </div>
      </div>
    </div>

    <div class="form-group salary">
      <label>{{ t("persons.workExperienceForm.salary") }}:</label>

      <div class="input-group">
        <div class="select-group">
          <Select
            v-model="salary.currency"
            class="select-list"
            :options="moneyCurrencies"
            :auto-fill="experienceFilledField?.salary"
          />
          <FormInput
            id="baseSalary"
            v-model="salary.amount"
            :currency="salary.currency"
            :money="true"
            :auto-fill="experienceFilledField?.salary"
            :placeholder="t('persons.workExperienceForm.placeholders.baseSalary')"
          />
          <Select
            v-model="salary.payment_frequency"
            class="select-list"
            :auto-fill="experienceFilledField?.salary"
            :options="paymentFrequencies"
          />
        </div>
        <div class="select-group">
          <Select
            v-if="autoFilledEditFields?.salary"
            v-model="parsedSalary.currency"
            class="select-list"
            :options="moneyCurrencies"
            :auto-fill="autoFilledEditFields?.salary"
          />
          <FormInput
            v-if="autoFilledEditFields?.salary"
            id="baseSalary"
            v-model="parsedSalary.amount"
            :currency="parsedSalary.currency"
            :money="true"
            :auto-fill="autoFilledEditFields?.salary"
            :placeholder="t('persons.workExperienceForm.placeholders.baseSalary')"
          />
          <Select
            v-if="autoFilledEditFields?.salary"
            v-model="parsedSalary.payment_frequency"
            class="select-list"
            :auto-fill="autoFilledEditFields?.salary"
            :options="paymentFrequencies"
          />
        </div>
      </div>
    </div>

    <div class="form-group">
      <label> {{ t("persons.workExperienceForm.incentiveCompensation") }}: </label>
      <div class="input-group">
        <FormInput
          v-model="payload.incentive_compensation"
          :auto-fill="experienceFilledField?.incentive_compensation"
          :placeholder="t('persons.workExperienceForm.placeholders.incentiveCompensation')"
        />
      </div>
    </div>

    <div class="form-group">
      <label> {{ t("persons.workExperienceForm.jobLocation") }}: </label>
      <div class="input-group">
        <FormInput
          v-model="payload.job_location"
          :auto-fill="experienceFilledField?.job_location"
          :placeholder="t('persons.workExperienceForm.placeholders.jobLocation')"
        />
      </div>
    </div>

    <CustomCheckBox
      class="current-job"
      :checked="payload.is_current"
      :label="t('persons.workExperienceForm.isCurrent')"
      :on-change="changeCheckboxValue"
    />

    <div class="form-group">
      <label>{{ t("persons.workExperienceForm.startDate") }}:</label>
      <div class="select-group" style="--grid-columns: 1fr 1fr">
        <div class="input-group">
          <Select
            v-model="startMonth"
            class="select-list"
            :auto-fill="experienceFilledField?.start_month"
            :options="months"
            :placeholder="t('persons.workExperienceForm.placeholders.month')"
            :error="get(v$, 'startMonth.$error', false)"
            :error-message="get(v$, 'startMonth.$errors.0.$message', '')"
          />
        </div>
        <div class="input-group">
          <Select
            key="fixed_start_year"
            v-model="startYear"
            class="select-list"
            :auto-fill="experienceFilledField?.start_year"
            :options="years"
            :placeholder="t('persons.workExperienceForm.placeholders.year')"
            :error="get(v$, 'startYear.$error', false)"
            :error-message="get(v$, 'startYear.$errors.0.$message', '')"
          />
        </div>
      </div>
    </div>

    <div class="form-group">
      <label>{{ t("persons.workExperienceForm.endDate") }}:</label>

      <div class="select-group" style="--grid-columns: 1fr 1fr">
        <div class="input-group">
          <Select
            v-model="endMonth"
            class="select-list"
            :auto-fill="experienceFilledField?.end_month"
            :placeholder="t('persons.workExperienceForm.placeholders.month')"
            :options="months"
            :disabled="payload.is_current"
            :error="get(v$, 'endMonth.$error', false)"
            :error-message="get(v$, 'endMonth.$errors.0.$message', '')"
          />
        </div>
        <div class="input-group">
          <Select
            v-model="endYear"
            class="select-list"
            :auto-fill="experienceFilledField?.end_year"
            :options="years"
            :disabled="payload.is_current"
            :placeholder="t('persons.workExperienceForm.placeholders.year')"
            :error="get(v$, 'endYear.$error', false)"
            :error-message="get(v$, 'endYear.$errors.0.$message', '')"
          />
        </div>
      </div>

      <label />
      <span v-if="get(v$, 'payload.end_date.$error', false)" class="date-message">
        {{ get(v$, 'payload.end_date.$errors.0.$message', '') }}
      </span>
    </div>

    <div class="form-group">
      <label>{{ t("persons.workExperienceForm.placeholders.description") }}:</label>

      <div class="textarea-group">
        <TextArea
          v-model="payload.description"
          :auto-fill="experienceFilledField?.description"
          :warning-chars="650"
          :placeholder="t('persons.workExperienceForm.placeholders.description')"
        />
      </div>
    </div>

    <template v-if="edit">
      <Realizations :realizations="payload.realizations || []" />

      <div class="form-group">
        <label for="conditions">{{ t("persons.workExperienceForm.conditions") }}:</label>
        <div class="textarea-group">
          <TextArea
            id="conditions"
            v-model="payload.conditions"
            data-test="conditions"
            :auto-fill="experienceFilledField?.conditions"
            :placeholder="t('persons.workExperienceForm.placeholders.description')"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.input-group {
  width: 100%;
}

.textarea-group {
  width: 100%;
}

.work-experience-wrapper:deep {
  .multiselect-wrapper {
    min-height: auto;
  }

  .form-group {
    display: grid;
    margin-bottom: 0.5rem;
    grid-template-columns: minmax(100px, 1fr) 4fr;

    & > label {
      display: flex;
      height: 28px;
      align-items: center;
      margin-right: 0.5rem;
      color: $gray-dark;
      font-family: "Open Sans";
      font-size: 14px;
      letter-spacing: 0.5px;
      word-wrap: break-word;

      .required {
        color: $red;
      }
    }

    .date-message {
      color: $red-warning;
      font-size: 11px;
      margin-block-start: -0.5rem;
      margin-inline-start: 0.5rem;
    }

    .error-message {
      margin-left: 0.5rem;
      color: $primary;
      font-size: 10px;
    }

    .select-group {
      --grid-columns: 1fr 2fr 1fr;

      display: grid;
      grid-gap: 5px;
      grid-template-columns: var(--grid-columns);
    }

    .text-area-wrapper {
      margin-bottom: 20px;
    }
  }

  .auto-filled {
    width: 103%;
    padding-top: 10px;
    padding-right: 10px;
    padding-left: 7px;
    border-radius: 2px;
    margin-left: -7px;
    background-color: #f4f4f4;
  }

  .component-input {
    width: 100%;
  }

  .current-job {
    margin-bottom: 1.25rem;
  }
}
.corporation-alias-hint {
  color: $red-violet-medium;
  font-size: 0.75rem;
  line-height: 17px;
  padding-block: 0.125rem;

  span {
    font-weight: 700;
    color: inherit;
  }
}
</style>
