<script setup lang="ts">
import CompanyAbout from "./components/CompanyAbout.vue";
import IdealProfileTabs from "./components/IdealProfileTabs.vue";
import { getCorporation } from "@/http_services/entities/corporation";
import type { Corporation } from "@/schemas/corporation";

const props = defineProps<{ id: string }>();

const AsyncAboutEdit = defineAsyncComponent(() => import("./components/forms/About.vue"));

const router = useRouter();
const { t } = useI18n();

const { data: project, error, isLoading: loading } = await useProject(props.id);

const {
  stageTabs,
} = useTabsProject(props.id);

const currentRoute = computed(() => stageTabs.value.findIndex(tab => tab.key === router.currentRoute.value.meta.tab));

function goTo(tabIndex: number) {
  if (!stageTabs.value || !stageTabs.value[tabIndex])
    return;

  router.push(stageTabs.value[tabIndex].path);
}

const editing = ref(false);

const {
  mutate: updateProject,
} = updateProjectMutation(props.id);

const corporation = ref<Corporation | null>(null);

async function fetchCorporation() {
  if (project.value)
    corporation.value = await getCorporation(project.value?.corporation?.corporation_id);
}

function save(data: { description: string; historic: string; foundationYear: string }) {
  updateProject({
    ...project.value,
    corporation: {
      ...project.value?.corporation,
      description: data.description,
      historic: data.historic,
      foundation_year: data.foundationYear,
    },
  });
}

watchEffect(async () => {
  await fetchCorporation();
});
</script>

<template>
  <section class="project-section">
    <div v-if="error">
      could not load
    </div>
    <div class="project-container">
      <ProjectStages :id="props.id" />
      <div v-if="project" class="project-wrapper">
        <ProjectTabs :stage-tabs="stageTabs" :active="currentRoute" class="tabs" @change="goTo($event)" />
        <IdealProfileTabs
          :editing="editing"
          :project-id="project._id"
          :stages="project.stages"
          :corporation-avatar-url="project.corporation?.avatar_url"
          @edit="editing = $event"
        />
        <div v-if="!editing" class="project-content">
          <CompanyAbout
            :historic="project?.corporation?.historic"
            :description="project?.corporation?.description"
            :foundation-year="project?.corporation?.foundation_year"
          />
        </div>
        <div v-else class="project-content">
          <AsyncAboutEdit
            :foundation-year="project?.corporation?.foundation_year"
            :historic="project?.corporation?.historic"
            :description="project?.corporation?.description"
            @cancel="editing = false"
            @save="save($event)"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<route lang="yaml">
meta:
  layout: project-stage
  stage: construction
  tab: ideal-profile
  ideal-profile: about
</route>

<style scoped lang="scss">
.project-section {
  min-height: 100vh;
}
.project-wrapper {
  background: white;
  padding-bottom: 2.5rem;
  margin-bottom: 2.5rem;
}
.project-container {
  padding-top: 3.75rem;
}
.project-content {
  margin-inline: 2.5rem;
}
</style>
