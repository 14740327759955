<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ImuaCard",
});
</script>

<template>
  <div class="imua-card">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.imua-card {
  --bg-card-color: #{$white-full};

  padding: 1.5rem;
  border-radius: 8px;
  background-color: var(--bg-card-color);
  box-shadow: 0 2px 4px #0003;
  outline: none;
}
</style>
