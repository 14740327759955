<script setup lang="ts">
import type { ICandidate } from "./types";
import { PriorityOptions } from "./types";
import HeaderValidation from "./components/HeaderValidation.vue";
import SalaryItem from "./components/table/SalaryItem.vue";
import CompanyItem from "./components/table/CompanyItem.vue";
import CandidateItem from "./components/table/CandidateItem.vue";
import LinkedinItem from "./components/table/LinkedinItem.vue";
import PriorityItem from "./components/table/PriorityItem.vue";
import ExperienceItem from "./components/table/ExperienceItem.vue";
import PhoneItem from "./components/table/PhoneItem.vue";
import RoleItem from "./components/table/RoleItem.vue";
import { Select } from "@/components/inputs/modalInputs";
import type { DataGridHeaderObject, DataGridRow, InitialSorter } from "@/components/datagrid/DataGrid.vue";
import DataGrid from "@/components/datagrid/DataGrid.vue";
import SvgIcon from "@/components/icon/Icon.vue";
import HeaderActions from "@/validation/pages/validation/[project_id]/components/HeaderActions.vue";
import { SALARYCURRENCY } from "@/entities/common";

type ScopedRow = DataGridRow<ICandidate>;

const router = useRouter();
const route = useRoute();
const { t } = useI18n();

const salaryRange = reactive({
  min: undefined,
  max: undefined,
});

const salaryCurrency = ref();
const disabledSalaryRange = ref(false);
const salarySelect = ref();

const sortPriority = [
  PriorityOptions.IN,
  PriorityOptions["A+"],
  PriorityOptions.A,
  PriorityOptions["A-"],
  PriorityOptions.B,
  PriorityOptions.C,
  PriorityOptions.D,
  PriorityOptions.F,
  null,
];

const headers: DataGridHeaderObject<ICandidate> = {
  priority: {
    id: "PRZ",
    name: t("projects.validation.column.priority"),
    size: 80,
    filterItems: [
      { value: null, label: `- ${t("projects.validation.extra.noPriority")}` },
      { value: PriorityOptions.IN, label: PriorityOptions.IN },
      { value: PriorityOptions["A+"], label: PriorityOptions["A+"] },
      { value: PriorityOptions.A, label: PriorityOptions.A },
      { value: PriorityOptions["A-"], label: PriorityOptions["A-"] },
      { value: PriorityOptions.B, label: PriorityOptions.B },
      { value: PriorityOptions.C, label: PriorityOptions.C },
      { value: PriorityOptions.D, label: PriorityOptions.D },
      { value: PriorityOptions.F, label: PriorityOptions.F },
    ],
    sortingFn: (candidateA, candidateB) => {
      const indexA = sortPriority.indexOf(candidateA.priority_classification);
      const indexB = sortPriority.indexOf(candidateB.priority_classification);

      if (indexA > indexB)
        return 1;

      if (indexA < indexB)
        return -1;

      return 0;
    },
    value: candidate => candidate.priority_classification,
    forInitialValue: candidate => sortPriority.indexOf(candidate.approval_and_priority?.priority_classification || null),
  },
  candidate: {
    name: t("projects.validation.column.candidate"),
    size: 365,
    value: candidate => `${candidate.first_name} ${candidate.last_name}`,
    forInitialValue: candidate => candidate.final_mean,
  },
  linkedin: {
    name: "LinkedIn",
    disableSort: true,
    size: 70,
    value: candidate => candidate.linkedin_url,
  },
  phone: {
    name: t("projects.validation.column.phone"),
    disableSort: true,
    minSize: 200,
    value: candidate => candidate.phones.map(phone => (`${phone.calling_code} ${phone.number}`)).join(" - "),
  },
  company: {
    name: t("projects.validation.column.company"),
    minSize: 200,
    value: candidate => candidate.current_corporation_name?.trim?.() || "",
  },
  role: {
    name: t("projects.validation.column.role"),
    minSize: 200,
    value: candidate => candidate.current_role_name,
  },
  salary: {
    name: t("projects.validation.column.salary"),
    size: 210,
    value: candidate => candidate.current_salary?.amount || 0,
    beforeApplyFilters: (filter) => {
      if (disabledSalaryRange.value) {
        (!salaryRange?.min && !salaryRange?.max) && (filter.extra = undefined);
        return;
      }

      const range = salaryRange?.min !== undefined || salaryRange?.max !== undefined
        ? [(salaryRange?.min ?? 0), (salaryRange?.max ?? 999999999)]
        : undefined;

      const currency = salaryCurrency.value;
      if ([range, currency].some(f => !!f))
        filter.extra = JSON.stringify(Object.assign({}, range ? { range } : {}, currency ? { currency } : {}));
      if (filter.extra)
        filter.search = [];
    },
    filterFn: (value, item, isForInternalFilter = false) => {
      if (isForInternalFilter && salaryCurrency.value)
        return item.current_salary?.currency === salaryCurrency.value;

      const extra = JSON.parse(route.query.extra?.toString() ?? "{}");
      const rangeCondition = extra?.range?.length === 2
        ? Number(value ?? 0) >= (extra?.range[0] ?? 0) && Number(value ?? 0) <= (extra?.range[1] ?? 999999999)
        : true;
      const currencyCondition = extra?.currency ? item.current_salary?.currency === extra?.currency : true;
      return rangeCondition && currencyCondition;
    },
    sortingFn: (candidateA, candidateB) => {
      const indexA = candidateA.current_salary?.amount || 0;
      const indexB = candidateB.current_salary?.amount || 0;

      if (indexA === 0)
        return 1;

      if (indexB === 0)
        return -1;

      if (indexA > indexB)
        return 1;

      if (indexA < indexB)
        return -1;

      return 0;
    },
  },
  experiences: {
    disableSort: true,
    name: t("projects.validation.column.past_experiences"),
    minSize: 250,
    value: candidate => candidate.work_experience.map(exp => exp.corporation_name).join(", "),
  },
};

const initialSort: Array<InitialSorter> = [
  { column: "candidate", order: "desc" },
  { column: "priority", order: "asc" },
];

const { project_id }: any = router.currentRoute.value.params;

const { data: candidates, refetch } = useFetchValidationPanel(project_id);
const { mutateAsync: updatePriority } = usePatchPanelCandidate(project_id);
const { mutateAsync: updatePhones } = usePatchValidationPanelPerson(project_id);
const { mutateAsync: updateRemuneration } = usePatchValidationForWorkExperiences(project_id);

function savePriority(id: string, priority: PriorityOptions) {
  return updatePriority({
    candidate_id: id,
    body: { approval: { priority_classification: priority } },
  }, { onSuccess: refetch });
}

function savePhones(id: string, phones: ICandidate["phones"]) {
  return updatePhones({
    person_id: id,
    payload: { phones },
  }, { onSuccess: () => refetch });
}

function saveRemuneration(id: string, amount: number) {
  return updateRemuneration({
    person_id: id,
    payload: { amount },
  }, { onSuccess: () => refetch });
}

const clearFilter = function (column: string) {
  if (column === "salary") {
    salaryRange.min = undefined;
    salaryRange.max = undefined;
    salaryCurrency.value = null;
    disabledSalaryRange.value = false;
    salarySelect.value?.clear();
  }
};

const changeCriteria = function (data: any) {
  if (data?.column === "salary") {
    disabledSalaryRange.value = data?.value?.length;
    salaryRange.min = undefined;
    salaryRange.max = undefined;
  }
};
</script>

<template>
  <div class="table-wrapper">
    <HeaderValidation :project-id="String(project_id)" />
    <HeaderActions />

    <DataGrid
      :headers="headers"
      :items="candidates"
      :initial-sort="initialSort"
      @clear-filter="clearFilter"
      @change-criteria="changeCriteria"
    >
      <template #filter[candidate]="{ item }: ScopedRow">
        <span v-text="`${item.first_name} ${item.last_name}`" />
      </template>

      <template #filter[salary]="{ item }: ScopedRow">
        <SalaryItem
          v-if="item.current_salary"
          :amount="item.current_salary?.amount || 0"
          :currency="item.current_salary?.currency"
          :person-id="item.person_id"
          class="text-start"
        />
      </template>

      <template #filter-head[salary]>
        <div v-text="t('projects.validation.extra.salaryRange')" />

        <div class="salary-head">
          <span v-text="t('projects.validation.extra.salaryRangeFrom')" />
          <Select
            ref="salarySelect"
            v-model="salaryCurrency"
            name="currency"
            class="select-list"
            :options="SALARYCURRENCY"
            :auto-fill="false"
            :disabled="disabledSalaryRange"
            no-feedback
            clear-value
          />
          <input
            v-model.number="salaryRange.min"
            :max="salaryRange?.max - 1"
            :min="0"
            :step="1000"
            placeholder="00.000,00"
            type="number"
            class="filter-range"
            :disabled="disabledSalaryRange"
          >
          <span v-text="t('projects.validation.extra.salaryRangeTo')" />
          <input
            v-model.number="salaryRange.max"
            :min="salaryRange?.min + 1"
            :step="1000"
            placeholder="00.000,00"
            type="number"
            class="filter-range"
            :disabled="disabledSalaryRange"
          >
        </div>
      </template>

      <template #head[linkedin]>
        <SvgIcon icon="linkedin_icon" width="24" height="24" />
      </template>

      <template #row[priority]="{ item }: ScopedRow">
        <PriorityItem :candidate="item" class="editable" @update:priority="savePriority" />
      </template>

      <template #row[candidate]="{ item }: ScopedRow">
        <CandidateItem
          :active="!item.is_added_in_placement"
          :name="`${item.first_name} ${item.last_name}`"
          :percent="item.final_mean"
          :person="item.person_id"
          :src="item.avatar_url"
          :stage="item.project_stage"
        />
      </template>

      <template #row[linkedin]="{ item }: ScopedRow">
        <LinkedinItem v-if="item.linkedin_url" :href="item.linkedin_url" />
      </template>

      <template #row[phone]="{ item }: ScopedRow">
        <PhoneItem :person-id="item.person_id" :phones="item.phones" class="editable" @update:phones="savePhones" />
      </template>

      <template #row[company]="{ item }: ScopedRow">
        <CompanyItem
          :name="item.current_corporation_name"
          :included="item.current_corporation_in_search_field"
          class="editable"
        />
      </template>

      <template #row[role]="{ item }: ScopedRow">
        <RoleItem :name="item.current_role_name" />
      </template>

      <template #row[salary]="{ item: { current_role_name, current_salary: salary, person_id } }: ScopedRow">
        <SalaryItem
          :amount="salary?.amount"
          :currency="salary?.currency"
          :person-id="person_id"
          :disabled="!current_role_name"
          :class="{ 'editable': current_role_name, 'empty-container': !salary }"
          @update:amount="saveRemuneration"
        />
      </template>

      <template #row[experiences]="{ item: { work_experience } }: ScopedRow">
        <ExperienceItem
          v-if="work_experience.length"
          :items="work_experience"
        />
        <span v-else class="empty" />
      </template>
    </DataGrid>
  </div>
</template>

<route lang="yaml">
  meta:
    layout: validation
</route>

<style lang="scss" scoped>
.empty-container {
  height: inherit;
}

.filter-range {
  height: 2.6rem !important;
}

.table-wrapper {
  height: 100vh;
  display: grid;
  align-content: flex-start;

  .editable {
    &:hover {
      &:before {
        position: absolute;
        cursor: pointer;
        inset: 0;
        content: ' ';
        border: 1px solid $gray;
      }
    }
  }
}

.text-start {
  :deep(.currency) {
    justify-content: start;
  }
}

span.empty {
  display: block;
  width: 100%;
  height: 100%;
}

.salary-head {
  font-weight: normal;
  margin-top: .5rem;
  display: flex;
  align-items: center;
  gap: .75rem;

  input, select {
    padding-left: .4rem;
    background: #FFFFFF 0 0 no-repeat padding-box;
    border: 1px solid #C0C0C0;
    border-radius: .5rem;
    height: 2rem;
    max-width: 6rem;
    outline: none;
  }
}
</style>
