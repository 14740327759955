export const accordionKeyMapInterviewEvaluation = {
  "academic": "academic_background",
  "languages": "languages",
  "experiences": "experiences",
  "achievements": "achievements",
  "attributes": "attributes",
  "cultural-match": "cultural_match",
  "motivation": "motivation",
} as const;

export type AccordionKey = keyof typeof accordionKeyMapInterviewEvaluation;
