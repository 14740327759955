<script lang="ts">
import type { ComputedRef, Ref } from "vue";
import moment from "moment";
import { useI18n } from "vue-i18n";
import get from "lodash-es/get";
import { useRouter } from "vue-router";
import { completeDate } from "@/utils/dates";
import ImageCard from "@/components/image_card/ImageCard.vue";
import { InfoHeader } from "@/components/person_card/components";
import RefreshIcon from "@/assets/images/refresh_icon.svg";
import { sortExperiences } from "@/utils/experiences-sorter";
import { formatCurrencyFromValue, parsePhones, yearFor } from "@/utils/parsers";
import type { Person, WorkExperience } from "@/schemas/person";
import { Button2 } from "@/components/button";
import { SvgIcon } from "@/components/icon";
import { PAYMENT_FREQUENCY } from "@/entities/Person/work-experience";
import SearchProject from "@/components/person_card/components/InfoHeader/components/SearchProject.vue";

interface extraPersonProps { restricted_profile: boolean; included_in_project: boolean }

type onSidebarRedirect = () => void;

export default defineComponent({
  components: {
    ImageCard,
    RefreshIcon,
    InfoHeader,
    Button: Button2,
    SvgIcon,
    SearchProject,
  },
  emits: ["update:project"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const onSidebarRedirect: onSidebarRedirect | undefined = inject("onSidebarRedirect");
    const person = inject("selectedPerson") as Ref<Person & extraPersonProps>;
    provide("person", person);
    const router = useRouter();
    const desableRestrictedButton = ref(true);

    const sortedExperiences = computed(() => {
      const experiences = person.value.work_experience;
      return sortExperiences<WorkExperience>(experiences);
    });

    const lastExperience = computed(
      () => sortedExperiences.value[0] || {},
    ) as ComputedRef<WorkExperience>;

    const lastExperienceDate = computed(() => {
      const start = lastExperience.value?.start_date;
      const end = lastExperience.value?.end_date;
      if (start)
        return `- ${yearFor(start)}/${yearFor(end)}`;
      return "";
    });

    const sector = get(
      lastExperience.value,
      "company.corporation_data.business_categories[0].sector",
      "",
    );

    const borderColor = computed(() =>
      person.value.restricted_profile || person.value.is_added_in_placement
        ? "gray"
        : person.value.included_in_project || person.value.added_in_mapping
          ? "yellow"
          : "transparent");

    const yearsFromNow = date => moment().diff(date, "years");

    const goToEditPerson = async () => {
      onSidebarRedirect && onSidebarRedirect();
      router.push(`/persons/create?id=${person.value.id}&edit=${true}`);
    };

    const paymentFrequencies = (value: string) => {
      const data = {
        [PAYMENT_FREQUENCY.MONTHLY]:
          t("persons.workExperienceForm.placeholders.paymentFrequencies.monthly"),
        [PAYMENT_FREQUENCY.ANNUALLY]:
          t("persons.workExperienceForm.placeholders.paymentFrequencies.yearly"),
      };
      if (data[value])
        return ` / ${data[value]}`;
      else
        return "";
    };
    const emitUpdateProjectList = () => {
      emit("update:project");
    };
    return {
      person,
      borderColor,
      desableRestrictedButton,
      completeDate,
      lastExperience,
      yearFor,
      yearsFromNow,
      t,
      formatCurrencyFromValue,
      lastExperienceDate,
      parsePhones,
      sector,
      goToEditPerson,
      onSidebarRedirect,
      paymentFrequencies,
      emitUpdateProjectList,
    };
  },
});
</script>

<template>
  <section class="header-wrapper">
    <div class="avatar-section">
      <ImageCard
        class="image-card"
        image-size="original"
        :image-url="person.avatar_url ? `${person.avatar_url}?version=${person._version}` : null"
        :name="`${person.first_name} ${person.last_name}`"
        :border-color="borderColor"
      />
      <p class="refresh-date">
        <span class="icon">
          <RefreshIcon />
        </span>
        {{ completeDate(person.updated_at || person.inserted_at) }}
      </p>
    </div>
    <div class="person-section">
      <div class="header">
        <InfoHeader
          class="info"
          :person="person"
          :can-be-restricted-profile="desableRestrictedButton"
          title-font-size="22px"
        />
        <div class="container-buttons">
          <SearchProject :selected="person" @update:selected="emitUpdateProjectList()" />
          <Button class="header-button" variation="primary" @click="goToEditPerson">
            <SvgIcon icon="parser_edit" margin="none" width="12" height="12" />
            {{ t("persons.details.editWithParser") }}
          </Button>
        </div>
      </div>
      <h3 v-if="lastExperience.role_name">
        {{ lastExperience.role_name }}
      </h3>
      <p class="highlight">
        <strong>{{ lastExperience.company?.corporation_data?.name }}</strong>
        <span v-if="sector"> - {{ t(`corporations.sector.enum.${sector}`) }}</span>
        {{ lastExperienceDate }}
      </p>
      <p v-if="lastExperience.salary?.amount">
        <strong>{{ t("persons.details.lastSalary") }} </strong>
        {{ formatCurrencyFromValue(lastExperience.salary.amount, lastExperience.salary.currency) }}
        {{ paymentFrequencies(lastExperience.salary?.payment_frequency) }}
      </p>
      <p v-if="person.phones?.length">
        <strong>{{ t("persons.details.phone") }} </strong>
        {{ `${parsePhones(person.phones.slice(0, 2))}` }}
      </p>
      <p v-if="person.email_addresses?.length">
        <strong>{{ t("persons.details.email") }} </strong>
        {{ (person.email_addresses.find((m) => m.is_main) || {}).email_address }}
      </p>
      <p v-if="person.birth_date">
        <strong>{{ t("persons.details.age") }} </strong>
        <span v-if="person.birth_date">
          {{ yearsFromNow(person.birth_date) }} {{ t("persons.details.years") }}
        </span>
      </p>
      <p v-if="person.address">
        <strong>{{ t("persons.details.location") }} </strong>
        {{ person.address?.name }}
      </p>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.header-wrapper {
  display: flex;
}

.header {
  .container-buttons {
    display: flex;
    flex-flow: column;
    gap: 1rem;
  }

  .header-button {
    max-height: 2.0625rem;
    padding: 0.375rem 1rem;
    flex-shrink: 0;
    gap: 2rem;
    white-space: nowrap;
  }

  .info {
    align-items: flex-start;
  }
}

.avatar-section {
  .refresh-date {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-left: -15px;
    color: $gray;
    font-size: 12px;
    text-align: center;
  }

  .icon {
    margin-right: 10px;
  }

  .icon svg {
    width: 12px;
    margin-left: 15px;
    stroke: $gray;
  }
}

.person-section {
  width: 100%;
  margin-left: 2rem;

  .highlight {
    color: $gray-dark;
    font-weight: bold;

    span {
      font-weight: 400;
    }
  }

  h3 {
    margin-bottom: 1rem;
    color: $gray-dark;
    font-size: 16px;
    font-weight: 600;
  }

  p {
    margin-top: 0.8rem;
    color: $gray-dark;
    font-size: 13px;
    font-weight: 400;
    text-transform: initial;

    strong {
      font-weight: 600;
    }
  }

  &:deep {
    .person-card__info__header {
      align-items: center;

      .title-area h2.text-red {
        color: $primary;
        font-size: 22px;
        font-weight: bold;
      }
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
}
</style>
