<script setup lang="ts">
import { Modal } from "@/components/modal";

const props = defineProps({
  name: { type: String },
});

const modalVisible = ref(false);

const i18n = useI18n();

const nonEditableMessage = computed<string>(() => {
  return i18n.t("projects.validation.extra.nonEditable", {
    name: `<b>${i18n.t("projects.validation.column.role").toLowerCase()}</b>`,
  });
});
</script>

<template>
  <span class="role" @click="modalVisible = true" v-text="props.name" />

  <Modal
    v-if="modalVisible"
    :on-click="() => modalVisible = false"
    :on-close="() => modalVisible = false"
    :title="i18n.t('projects.validation.extra.caution').concat('!').toUpperCase()"
    button-text="OK"
    button-variation="orange"
  >
    <template #message>
      <div class="text-center">
        <span v-html="nonEditableMessage" />
      </div>
    </template>
  </Modal>
</template>

<style scoped lang="scss">
.role {
  cursor: pointer;

  &:after {
    position: absolute;
    inset: 0;
    content: '';
  }
}

.text-center {
  text-align: center;
}
</style>
