<script setup lang="ts">
import {
  Subject,
  bufferTime,
  distinctUntilChanged,
  filter,
  from,
} from "rxjs";
import ScreeningEvaluation from "../../../components/ScreeningEvaluation.vue";
import SearchCandidates from "../../../components/SearchCandidates.vue";
import Editor from "@/components/editor/Editor.vue";
import { Sidebar } from "@/components/sidebar";
import { SvgIcon } from "@/components/icon";
import { getPerson } from "@/http_services/entities/persons";
import ScreeningHeader from "@/projects/pages/projects/[id]/screenings/components/ScreeningHeader.vue";
import PersonDetail from "@/persons/pages/persons/components/PersonDetail/PersonDetail.vue";
import { isNotesSaving, useScreeningNotes } from "@/common/composables/useScreening";
import { isSaving } from "@/common/composables/useScreeningEvaluation";
import { Modal } from "@/components/modal";

const props = defineProps<{ id: string }>();

interface ApprovalType {
  approved_for_interview: string | null
  reason: string | null
  priority_classification: string | null
}

interface Notes {
  header: string
  current_moment: string
  activities_scope: string
  background: string
  experience: string
}
export type NotesKeys = keyof Notes;

enum SideBarEnum {
  CANDIDATE_ADD = "CANDIDATE_ADD",
  CANDIDATE_PROFILE = "CANDIDATE_PROFILE",
}

const route = useRoute();
const router = useRouter();
const { t } = useI18n();

const projectId = computed(() => route.params.id || "") as ComputedRef<string>;
const personId = ref(route.params.person || "") as Ref<string | string[]>;
const activityId = computed(() => route.params.activity || "") as ComputedRef<string>;
const consultant = ref();

const { data: project } = useProject(projectId.value);
const draft = computed(() => personId.value === "draft");
const sideBarShow = ref("");

const approvalCandidate = ref<ApprovalType | null>(null);

const {
  data: screening,
  refetch: fetchScreening,
} = useScreeningActivityQuery(String(route.params.activity));

const {
  mutateAsync: useGetCandidateId,
} = useGetCandidateIdFromPersonIdMutation();

const minimized = ref<string[]>(["guide"]);
const titles = computed(() => ({
  guide: t("projects.details.tabs.screeningGuide"),
  notes: t("projects.details.activities.meetingsPanel.notes"),
  organization: t("projects.interview.panels.organizeAndEvaluate"),
}));

const {
  mutateAsync: patchScreeningActivity,
} = useScreeningActivityMutation();

const {
  mutate: patchScreeningAnnotations,
} = usePatchScreeningAnnotationsMutation(String(route.params.activity));

const {
  mutateAsync: patchApprovalCandidate,
} = usePatchApprovalCandidate();

const {
  mutateAsync: screeningToInterview,
} = useProjectStageScreeningToInterview(props.id);

const redirectToIndex = () => router.push(`/projects/${route.params.id}/screenings/activities`);
const redirectToNextStage = () => router.push(`/projects/${route.params.id}/screenings/prioritization`);
const sidebarClose = () => sideBarShow.value = "";

const notesSubject = new Subject<{ [key: string]: string }>();
const updates = ref(0);

const modalAttrDefault = {
  "class": "modal-confirm",
  "message-align": "center",
  "attach": "body",
  "variation": "warning",
  "secondary-variation": "light",
  "title-class": "modal-title",
  "message-class": "modal-message",
  "wrapper-class": "content-wrapper",
  "confirm-button-class": "confirm-button",
  "buttons-wrapper-class": "buttons-wrapper",
};

const showModalError = ref(false);
const showModalAddPerson = ref(false);
const showModalLinkedPerson = ref(false);
const showModalLinkedPersonApproval = ref(false);
const showModalApprovalCandidate = ref(false);

const modalError = computed(() => ({
  ...modalAttrDefault,
  "title": t("text.form.error.modal.genericTitle").toUpperCase(),
  "message": t("text.form.error.modal.genericMessage"),
  "button-text": t("text.form.error.modal.genericButtonMessage"),
  "on-click": () => showModalError.value = false,
  "on-close": () => showModalError.value = false,
}));

const modalAddPerson = computed(() => ({
  ...modalAttrDefault,
  "title": t("projects.screening.modal.title.linkedCandidate").toUpperCase(),
  "message": t("projects.screening.modal.message.linkedCandidate"),
  "button-text": t("projects.screening.modal.buttons.screeningActivities"),
  "secondary-button-text": t("projects.screening.modal.buttons.cancel"),
  "on-click": () => redirectToIndex(),
  "on-close": () => showModalAddPerson.value = false,
  "secondary-on-click": () => showModalAddPerson.value = false,
}));

const modalLinkedPerson = computed(() => ({
  ...modalAttrDefault,
  "title": t("projects.screening.modal.title.nonLinkedCandidate").toUpperCase(),
  "message": t("projects.screening.modal.message.nonLinkedCandidate"),
  "button-text": t("projects.screening.modal.buttons.goBackAndAdd"),
  "on-click": () => showModalLinkedPerson.value = false,
  "on-close": () => showModalLinkedPerson.value = false,
}));

const modalLinkedPersonApproval = computed(() => ({
  ...modalAttrDefault,
  "title": t("projects.screening.modal.title.nonLinkedCandidate").toUpperCase(),
  "message": t("projects.screening.modal.message.nonLinkedCandidateApproval"),
  "button-text": t("projects.screening.modal.buttons.goBackAndAdd"),
  "on-click": () => showModalLinkedPersonApproval.value = false,
  "on-close": () => showModalLinkedPersonApproval.value = false,
}));

const modalApprovalCandidate = computed(() => ({
  ...modalAttrDefault,
  "title": t("projects.screening.modal.title.approvalCandidate").toUpperCase(),
  "message": t("projects.screening.modal.message.approvalCandidate"),
  "button-text": t("projects.screening.modal.buttons.confirm"),
  "secondary-button-text": t("projects.screening.modal.buttons.cancel"),
  "on-click": () => confirmApprovalCandidate(),
  "on-close": () => cancelApprovalCandidate(),
  "secondary-on-click": () => cancelApprovalCandidate(),
}));

const {
  data: screeningNotes,
  refetch,
} = useScreeningNotes(String(route.params.activity));

const notes = ref(screeningNotes.value || {} as Notes);

function update(key: NotesKeys) {
  if (!notes.value)
    return;

  notesSubject.next({ [key]: notes.value[key] });
}

const result = from(notesSubject);
const buffered = result.pipe(
  bufferTime(1200),
  filter(changes => !!changes && changes.length > 0),
  distinctUntilChanged(),
);
buffered.subscribe((changes) => {
  const lastValues = changes.reduce((acc, curr) => ({ ...acc, ...curr }), {});
  patchScreeningAnnotations(lastValues);
});

async function patchApproval(empty = true) {
  await useGetCandidateId({
    projectId: String(projectId.value),
    personId: String(personId.value),
  }, {
    onSuccess: async (data) => {
      if (!data)
        return;

      const emptyApproval = {
        approved_for_interview: "ON_HOLD",
        reason: null,
        priority_classification: null,
      };

      if (!empty)
        approvalCandidate.value = data.approval_and_priority;

      if (!approvalCandidate.value)
        approvalCandidate.value = emptyApproval;

      await patchApprovalCandidate({
        person_id: data._id,
        body: {
          approval: approvalCandidate.value,
        },
      }, {
        onSuccess: async () => {
          if (project.value?.stages?.interviews.status === "DRAFT")
            await screeningToInterview(props.id);
          cancelApprovalCandidate();
        },
        onError: (error) => {
          console.error("Error approval candidate", error);
          showModalError.value = true;
        },
      });
    },
  });
}

async function finishActivity() {
  if (!screening.value?.candidate) {
    showModalLinkedPerson.value = true;
    return;
  }

  await patchApproval(false);

  if (!screening.value?.activity_date) {
    await fetchScreening()
      .then(async () => {
        await patchScreeningActivity({
          project_id: String(route.params.activity),
          activity: {
            ...screening.value,
            activity_date: (new Date()).toISOString(),
          },
        }, {
          onSuccess: () => {
            redirectToNextStage();
          },
          onError: (error) => {
            console.error("Error patch screening activity", error);
            showModalError.value = true;
          },
        });
      })
      .catch((error) => {
        console.error("Error fetch screening", error);
        showModalError.value = true;
      });
  }
  else {
    redirectToNextStage();
  }
}

async function reloadPerson(person_id: string) {
  await fetchScreening()
    .then(() => {
      sidebarClose();
      router.push(`/projects/${projectId.value}/screenings/person/${person_id}/activity/${activityId.value}`);
    })
    .catch((error) => {
      console.error("Error fetch screening", error);
      showModalError.value = true;
    });
}

function minimizedAdd(value: string) {
  minimized.value.push(value);
}

function minimizedRemove(value: string) {
  const indexToRemove = minimized.value.indexOf(value);
  if (indexToRemove > -1)
    minimized.value.splice(indexToRemove, 1);
}

const candidate = ref();
const recruiter = ref();
const showPersonDetail = ref(false);
const selectedPerson = ref(undefined);
const job = ref();

async function getCandidateData(id: string) {
  if (draft.value)
    return;

  const data = await getPerson(id);

  if (data) {
    selectedPerson.value = toRaw(data);
    job.value = data?.work_experience[0] ?? {};
  }
}

async function onSelectRequester() {
  if (selectedPerson.value)
    showPersonDetail.value = true;
}

const showSaving = computed(() => isSaving.value || isNotesSaving.value);

const academicGuide = [{
  title: "",
  questions: [
    "Instituição:",
    "Nível:",
    "Area/Curso:",
    "Idiomas:",
    "Skill Obrigatória:",
  ],
}];

const experienceGuide = [
  {
    title: "Experiência Atual",
    questions: [
      "Empresa:",
      "Tempo de casa:",
      "Posição/ões:",
      "Localização:",
      "Qual é o contexto desta empresa?",
      "Pra quem reporta?",
      "Quem reporta para esta pessoa?",
      "Quem são os pares?",
      "Quais processos não estão sobre sua responsabilidade?",
      "Quais processos estão sobre as suas responsabilidades?",
      "Como se estrutura sua equipe de trabalho? Tamanho / Nível / Funções:",
    ],
  },
  {
    title: "PRINCIPAIS ENTREGAS DA POSIÇÃO (REALIZAÇÕES):",
    questions: [
    ],
  },
  {
    title: "EXPERIÊNCIA ANTERIOR RELEVANTE",
    questions: [
      "Empresa:",
      "Tempo de casa:",
      "Posição/ões:",
      "Localização:",
      "Qual é o contexto desta empresa?",
      "Pra quem reporta?",
      "Quem reporta para esta pessoa?",
      "Quem são os pares?",
      "Quais processos não estão sobre sua responsabilidade?",
      "Quais processos estão sobre as suas responsabilidades?",
      "Como se estrutura sua equipe de trabalho? Tamanho / Nível / Funções:",
    ],
  },
];

const motivationGuide = [{
  title: "",
  questions: [
    "Ambiente:",
    "Carreira:",
    "Pessoal:",
    "Pacote:",
    "Salário:",
    "Bônus:",
    "Pacote anual:",
    "Possiblidade de mudança?",
  ],
}];

provide("onCloseDetails", () => {
  showPersonDetail.value = false;
  selectedPerson.value = undefined;
});

provide("selectedPerson", selectedPerson);

watchEffect(() => {
  personId.value = route.params.person || "";
  if (screening.value) {
    candidate.value = screening.value.candidate;
    recruiter.value = screening.value.recruiter;
  }
  if (screeningNotes.value)
    notes.value = { ...screeningNotes.value };
});

function confirmApprovalCandidate() {
  patchApproval();
  showModalApprovalCandidate.value = false;
}

function changeApprovalCandidate(data: ApprovalType) {
  showModalApprovalCandidate.value = true;
  approvalCandidate.value = data;
}

function cancelApprovalCandidate() {
  showModalApprovalCandidate.value = false;
  approvalCandidate.value = null;
  updates.value += 1;
}

async function fetchRecruiter() {
  const { data: consultantData } = await usePersonMe();
  consultant.value = consultantData;
}

onMounted(async () => {
  if (!draft.value)
    fetchScreening();

  await fetchRecruiter();
  await useGetCandidateId({
    projectId: String(projectId.value),
    personId: String(personId.value),
  }, {
    onSuccess: (data) => {
      approvalCandidate.value = data.approval_and_priority;
    },
  });
});
</script>

<template>
  <div>
    <div id="screening-activity" class="scrollable-container panel-wrapper">
      <ScreeningHeader
        :project-name="project?.project_name ?? ''"
        :corporation-name="project?.corporation?.corporation_name ?? ''"
        :candidate="candidate"
        :recruiter="recruiter"
        :job="job"
        :draft="draft"
        :loading="showSaving"
        @click:logo="redirectToIndex()"
        @click:profile="onSelectRequester()"
        @click:cancel="redirectToIndex()"
        @click:save="finishActivity()"
        @on-select-add-requester="sideBarShow = SideBarEnum.CANDIDATE_ADD"
        @vue:mounted="() => !draft ? getCandidateData(personId) : {}"
      />
      <div class="panels scrollable-content" :class="{ minimized: minimized.length > 0 }">
        <div v-if="minimized.length > 0" class="panel-minimized">
          <div
            v-if="minimized.includes('guide')"
            class="panel-box box-guide"
            @click="minimizedRemove('guide')"
          >
            <div class="panel-title">
              <SvgIcon
                icon="construction_square_x_icon"
                width="18"
                height="18"
                class="icon_title"
              />
              <span>{{ titles.guide }}</span>
            </div>
          </div>
          <div
            v-if="minimized.includes('notes')"
            class="panel-box box-notes"
            @click="minimizedRemove('notes')"
          >
            <div class="panel-title">
              <SvgIcon
                icon="construction_square_x_icon"
                width="18"
                height="18"
                class="icon_title"
              />
              <span>{{ titles.notes }}</span>
            </div>
          </div>
          <div
            v-if="minimized.includes('organization')"
            class="panel-box box-organization"
            @click="minimizedRemove('organization')"
          >
            <div class="panel-title">
              <SvgIcon
                icon="construction_square_x_icon"
                width="18"
                height="18"
                class="icon_title"
              />
              <span>{{ titles.organization }}</span>
            </div>
          </div>
        </div>
        <div v-if="!minimized.includes('guide')" class="scrollable-content panel">
          <div class="title" @click="minimizedAdd('guide')">
            <SvgIcon
              icon="construction_square_x_icon"
              width="18"
              height="18"
              class="icon_title"
            />
            <span>{{ titles.guide }}</span>
          </div>
          <div class="guide-container">
            <BoxAccordion :title="t('projects.screening.guide.accordeons.educationalBackground')">
              <div v-for="(group, idx) in academicGuide" :key="idx">
                <div v-if="group.title" class="guide-title">
                  {{ group.title }}
                </div>
                <div v-for="(question, questionidx) in group.questions" :key="questionidx" class="guide-question">
                  {{ question }}
                </div>
              </div>
            </BoxAccordion>
            <BoxAccordion :title="t('projects.details.constructionGuide.idealProfile.experience')">
              <div v-for="(group, idx) in experienceGuide" :key="idx">
                <div v-if="group.title" class="guide-title">
                  {{ group.title }}
                </div>
                <div v-for="(question, questionIdx) in group.questions" :key="`group-${idx}-${questionIdx}`" class="guide-question">
                  {{ question }}
                </div>
              </div>
            </BoxAccordion>
            <BoxAccordion :title="t('projects.interview.accordeons.motivation')">
              <div v-for="(group, idx) in motivationGuide" :key="idx">
                <div v-if="group.title" class="guide-title">
                  {{ group.title }}
                </div>
                <div v-for="(question, questionIdx) in group.questions" :key="`group-${idx}-${questionIdx}`" class="guide-question">
                  {{ question }}
                </div>
              </div>
            </BoxAccordion>
          </div>
        </div>
        <div v-if="!minimized.includes('notes')" class="scrollable-content panel panel-border-sm">
          <div class="title" @click="minimizedAdd('notes')">
            <SvgIcon
              icon="construction_square_x_icon"
              width="18"
              height="18"
              class="icon_title"
            />
            <span>{{ titles.notes }}</span>
          </div>
          <div class="scrollable-content example-scrollable-full">
            <div style="width: 100%">
              <Editor v-model="notes.header" :placeholder="t('text.text')" @update:model-value="update('header')" @blur="refetch()" />
            </div>
            <div style="width: 100%;">
              <div class="editor-title">
                {{ t("projects.details.activities.screening.form.labels.currentMoment") }}
              </div>
              <Editor v-model="notes.current_moment" :placeholder="t('projects.details.activities.screening.form.labels.currentMoment')" @update:model-value="update('current_moment')" @blur="refetch()" />
            </div>
            <div style="width: 100%;">
              <div class="editor-title">
                {{
                  t("projects.details.activities.screening.form.labels.scopeofActivities")
                }}
              </div>
              <Editor v-model="notes.activities_scope" :placeholder="t('projects.details.activities.screening.form.labels.scopeofActivities')" @update:model-value="update('activities_scope')" @blur="refetch()" />
            </div>
            <div style="width: 100%;">
              <div class="editor-title">
                {{
                  t("projects.details.activities.screening.form.labels.graduation")
                }}
              </div>
              <Editor v-model="notes.background" :placeholder="t('projects.details.activities.screening.form.labels.graduation')" @update:model-value="update('background')" @blur="refetch()" />
            </div>
            <div style="width: 100%;">
              <div class="editor-title">
                {{
                  t("projects.details.activities.screening.form.labels.experience")
                }}
              </div>
              <Editor v-model="notes.experience" :placeholder="t('projects.details.activities.screening.form.labels.experience')" @update:model-value="update('experience')" @blur="refetch()" />
            </div>
          </div>
        </div>
        <div v-if="!minimized.includes('organization')" class="scrollable-content panel panel-border-sm">
          <div class="title" @click="minimizedAdd('organization')">
            <SvgIcon
              icon="construction_square_x_icon"
              width="18"
              height="18"
              class="icon_title"
            />
            <span>{{ titles.organization }}</span>
          </div>
          <div class="scrollable-content example-scrollable-full">
            <ScreeningEvaluation
              :id="props.id"
              :key="updates"
              :consultant="consultant"
              :stages="project?.stages"
              @error:draft="showModalLinkedPersonApproval = true"
              @candidate:approval="changeApprovalCandidate($event)"
            />
          </div>
        </div>
      </div>
    </div>
    <Sidebar
      class="sidebar-preview"
      :visible="sideBarShow === SideBarEnum.CANDIDATE_ADD"
      :prevent-close-outside="false"
      :on-close="sidebarClose"
    >
      <SearchCandidates
        :activity-id="activityId"
        @error:add="showModalAddPerson = true"
        @reload:person="reloadPerson($event)"
      />
    </Sidebar>
  </div>
  <PersonDetail :visible="showPersonDetail" />

  <Modal v-if="showModalError" v-bind="modalError" />
  <Modal v-if="showModalAddPerson" v-bind="modalAddPerson" />
  <Modal v-if="showModalLinkedPerson" v-bind="modalLinkedPerson" />
  <Modal v-if="showModalLinkedPersonApproval" v-bind="modalLinkedPersonApproval" />
  <Modal v-if="showModalApprovalCandidate" v-bind="modalApprovalCandidate" />
</template>

<route lang="yaml">
meta:
  layout: activity
</route>

<style scoped lang="scss">
.panel-wrapper {
  display: flex;
  flex-direction: column;
  background: #f4f4f4;

  .header {
    background: #fff 0% 0% no-repeat padding-box;
  }

  .panels {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5rem;

  }

  .panels.minimized {
    grid-template-columns: 3.125rem repeat(calc(3 - v-bind("minimized.length")), 1fr);

    .panel-minimized {
      display: flex;
      width: 3.125rem;
      flex-direction: column;
      // grid-template-rows: 13rem 9rem auto;
      margin-block: 0.5rem;
      gap: 0.5rem;

      .box-guide {
        height: 13rem;
      }

      .box-notes {
        height: 9rem;
      }

      .box-organization {
        height: 15.5rem;
      }

      .panel-box {
        display: flex;
        flex-direction: column;
        width: 3rem;
        align-items: center;
        justify-content: center;
        background: $white-full;
        box-shadow: 0 3px 4px #00000029;
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        padding-block: 1rem;

        .panel-title {
          display: grid;
          grid-template-columns: auto 1fr;
          width: max-content;
          // height: 3.125rem;
          transform: rotate(-90deg);
          transform-origin: center;
          cursor: pointer;

          span {
            color: $primary;
            font-size: 0.875rem;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}
.example-scrollable-full {
  gap: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-inline: 0.5rem;

  &::-webkit-scrollbar {
    width: 23px;
    padding: 3px;
  }

  &::-webkit-scrollbar-thumb {
    width: 6px;
    border: 8px transparent solid;
    border-radius: 99px;
    background: #00000082 0% 0% no-repeat padding-box;
  }
}
.panel {
  margin-block: 0.5rem;
  border-radius: 8px;
  background: #fff 0% 0% no-repeat padding-box;
  box-shadow: 0 3px 4px #00000029;
  padding: 0.5rem;
}

.title {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-inline: 0.5rem;
  gap: 1rem;
  letter-spacing: 0.77px;
  cursor: pointer;

  span {
    color: $red-violet;
    font-weight: 600;
    font-size: 1.25rem;
    text-transform: uppercase;
    letter-spacing: inherit;
  }
}

.editor-title {
  padding: 0.5rem 0;
  margin-top: 1rem;
  font-size: 1.125rem;
  font-weight: 700;
  user-select: none;
  text-transform: uppercase;
}

.sidebar-preview {
  ::deep(.sidebar-wrapper) {
    width: 30rem;
  }
}
.guide {
  &-container {
    padding-inline: 0.875rem;
  }

  &-title {
    font-size: 1rem;
    padding-top: 1rem;
    text-transform: uppercase;
    font-weight: 700;
  }

  &-question {
    margin-block: 2rem;
    font-size: 0.875rem;
  }
}

.modal-confirm:deep {
  .modal-title {
    font-size: 1.25rem;
    font-weight: bold;
    text-align: center;
  }

  .modal-message {
    font-size: 1.25rem;
    font-weight: normal;
    line-height: 1.4;
    padding-inline: 1rem;
  }

  .content-wrapper {
    width: 46rem;
    min-width: 340px;
    padding: 3rem 3.25rem;
  }

  .buttons-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }

  .confirm-button {
    border-color: $secondary;
    background-color: $white-full;
    color: $secondary;

    &:hover {
      background-color: $secondary;
      color: $white-full;
    }
  }
}
</style>
