<script setup lang="ts">
import { defineEmits, ref, withDefaults } from "vue";
import { useI18n } from "vue-i18n";
import { Button2 as Button } from "@/components/button";
import { FormInput } from "@/components/inputs/modalInputs";

export interface IPersonContent {
  name: string
  role: string
  company: string
}

interface Props {
  personContent?: IPersonContent
}

const props = withDefaults(defineProps<Props>(), {});

const emit = defineEmits(["submit", "cancel", "restore"]);

const { t } = useI18n();

const personContent = ref<IPersonContent>(props.personContent);

const onSubmit = () => emit("submit", personContent.value);
</script>

<template>
  <form class="person__container" @submit.prevent="onSubmit()">
    <div class="person__form">
      <FormInput
        v-model="personContent.name"
        :placeholder="t('persons.createPersonForm.name')"
      />
      <FormInput
        v-model="personContent.company"
        :placeholder="t(`projects.filters.tagFilters.company[${0}]`)"
      />
      <FormInput
        v-model="personContent.role"
        :placeholder="t(`projects.filters.tagFilters.role[${0}]`)"
      />
      <span @click="emit('restore')">{{ t("text.form.restore_original") }}</span>
    </div>
    <div class="person__form--actions">
      <Button type="submit" variation="secondary">
        {{ t("text.form.change") }}
      </Button>
      <Button type="button" variation="light" filled @click="emit('cancel')">
        {{ t("text.form.cancel") }}
      </Button>
    </div>
  </form>
</template>

<style lang="scss" scoped>
.person {
  &__container {
    display: flex;
    width: 35rem;
  }

  &__form {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-right: 1rem;
    margin-bottom: 1rem;

    span {
      color: $red-violet;
      font-family: "Open Sans", Arial, sans-serif;
      font-size: 12px;
      letter-spacing: 0.42px;
      opacity: 1;
      text-align: right;

      &:hover {
        cursor: pointer;
      }
    }

    &--actions {
      button:first-child {
        margin-right: 0.5rem;
      }
    }
  }
}
</style>
