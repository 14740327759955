import type { Module } from "vuex";

const SystemStoreModule: Module<any, any> = {
  state() {
    const fullPageLoadingMessage = undefined;
    return {
      fullPageLoadingMessage,
    };
  },
  getters: {
    GET_FULL_PAGE_LOADING_MESSAGE(state) {
      return state.fullPageLoadingMessage;
    },
  },
  mutations: {
    SET_FULL_PAGE_LOADING_MESSAGE: (state, message) => {
      state.fullPageLoadingMessage = message;
    },
  },
};

export default SystemStoreModule;
