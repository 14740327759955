<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import SimpleMaskMoney from "simple-mask-money";
import { useI18n } from "vue-i18n";
import InputComponent from "./InputComponent.vue";
import { TrashButton } from "@/components/trash-button";
import { moneyArgs } from "@/utils/adapters/form";

export default defineComponent({
  name: "FormInput",
  components: {
    InputComponent,
    TrashButton,
  },
  props: {
    id: String,
    svgIcon: String,
    showIcon: {
      type: Boolean,
      default: false,
    },
    preIcon: {
      type: String,
      required: false,
    },
    autoFill: Boolean,
    autoFillColor: {
      type: String,
      required: false,
    },
    error: Boolean,
    errorMessage: String,
    placeholder: String,
    autocomplete: String,
    onFocus: Function,
    onBlur: Function,
    onEnter: Function,
    onKeyDown: Function,
    mask: String,
    money: {
      type: Boolean,
      default: false,
    },
    currency: String,
    modelValue: [String, Number],
    disabled: Boolean,
    tooltipShow: Boolean,
    tooltipMesage: String,
    tooltipColor: String,
    tooltipBackground: String,
    inputType: {
      type: String,
      default: "text",
    },
  },
  emits: ["update:modelValue", "click:onButtonClick"],
  setup(props, { emit }) {
    const currency = computed(() => props.currency);
    const args = computed(() => {
      return {
        allowNegative: false,
        negativeSignAfter: false,
        prefix: "",
        suffix: "",
        fixed: true,
        fractionDigits: currency.value ? moneyArgs[currency.value].fractionDigits : 2,
        decimalSeparator: currency.value
          ? moneyArgs[currency.value].decimalSeparator
          : ",",
        thousandsSeparator: currency.value
          ? moneyArgs[currency.value].thousandsSeparator
          : ".",
        cursor: "move",
      };
    });
    const value = computed({
      get: () => {
        if (props.money && props.modelValue && props.modelValue !== "")
          return SimpleMaskMoney.formatToMask(props.modelValue, args.value);
        else
          return props.modelValue;
      },
      set: (newValue) => {
        if (props.money) {
          const formatt = SimpleMaskMoney.formatToMask(newValue, args.value);
          emit("update:modelValue", newValue ? formatt : newValue);
        }
        else {
          emit("update:modelValue", newValue);
        }
      },
    });

    const showTooltip = ref(false);

    const { t } = useI18n();

    const onClick = () => {
      emit("click:onButtonClick");
    };

    const input = ref<HTMLElement>();

    const focus = () => input.value?.focus();

    return {
      t,
      value,
      input,
      onClick,
      focus,
      showTooltip,
    };
  },
  methods: {
    clear() {
      this.$emit("update:modelValue", "");
    },
  },
});
</script>

<template>
  <div class="form-input--container">
    <InputComponent
      v-if="mask"
      :id="id"
      ref="input"
      v-model="value"
      v-maska="mask"
      :auto-fill="autoFill"
      :auto-fill-color="autoFillColor"
      :error="error"
      :error-message="errorMessage"
      :placeholder="placeholder"
      :autocomplete="autocomplete"
      :on-focus="onFocus"
      :on-blur="onBlur"
      :on-enter="onEnter"
      :on-key-down="onKeyDown"
      :tooltip-show="tooltipShow"
      :tooltip-mesage="tooltipMesage"
      :tooltip-color="tooltipColor"
      :tooltip-background="tooltipBackground"
      :pre-icon="preIcon"
      :input-type="inputType"
    />
    <InputComponent
      v-else
      :id="id"
      ref="input"
      v-model="value"
      :auto-fill="autoFill"
      :auto-fill-color="autoFillColor"
      :error="error"
      :error-message="errorMessage"
      :placeholder="placeholder"
      :autocomplete="autocomplete"
      :on-focus="onFocus"
      :on-blur="onBlur"
      :on-enter="onEnter"
      :on-key-down="onKeyDown"
      :disabled="disabled"
      :tooltip-show="tooltipShow"
      :tooltip-mesage="tooltipMesage"
      :tooltip-color="tooltipColor"
      :tooltip-background="tooltipBackground"
      :pre-icon="preIcon"
      :input-type="inputType"
    >
      <template #preIcon>
        <slot name="preIcon" />
      </template>
    </InputComponent>
    <TrashButton :show-icon="showIcon" :svg-icon="svgIcon" @click="onClick" />
  </div>
</template>

<style lang="scss" scoped>
.form-input--container {
  display: flex;
  width: 100%;

  .component-input:deep {
    width: 100%;
  }
}

.component-input:deep {
  margin-bottom: 0.3rem;

  .input-wrapper {
    height: 28px;
    border: 1px solid $gray-border;
    border-radius: 6px;
  }

  .error-wrapper {
    justify-content: start;

    span {
      padding-top: 2px;
      margin-left: 5px;
    }
  }
}
</style>
