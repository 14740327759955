import {
  ACTIVITY,
  ATTRIBUTE,
  CULTURAL,
  DEGREE,
  EXTENSION_TYPE,
  INSTITUTION,
  PROJECT_STAGE,
  SCREENING_ACTIVITY,
} from "@/entities/project";
import {
  ATTRIBUTES_TITLES,
  BOARD_TYPES,
  COMPANY_CHARTS,
  COMPANY_MOMENTS,
  CULTURAL_MATCH_TITLES,
  EXECUTION_CONDITIONS_TITLES,
  FUNCTIONAL_SCOPE_TITLES,
  GOVERNANCE_INSTANCE,
  GOVERNANCE_TITLES,
  POSITION_STRATEGY_TITLES,
  PROFILE_TITLES,
  PROJECT_STAGES,
  REALIZATIONS_TITLES,
  SCALE,
  STRATEGY_STATUSES,
  STRATEGY_TITLES,
} from "@/entities/project/construction-guide";
import { ConstructionEnum, ShortlistEnum, ShortlistPersonEnum } from "@/schemas/project/pdf";

import { ANALYSIS_TYPES } from "@/entities/project/organization-and-assembly";

const projectTypes = {
  BOARD: "Board",
  CEO_AND_EXECUTIVE_OFFICERS: "CEO & Executive",
  LEADERSHIP_SUCCESSION: "Leadership Succession",
  MAPPING: "Mapping",
  ASSESSMENT: "Assessment",
  SCOPE_DESIGN: "Scope Design",
  SECOND_OPINION: "Second Opinion",
};

const sourceTypes = {
  NEW_CLIENT: "New Customer",
  ACTIVE_RECURRENCE: "Active Recurrence",
  PASSIVE_RECURRENCE: "Passive Recurrence",
  REACTIVATION: "Reactivation",
};

const allocatedTeam = [
  "Ana Paula Ramos",
  "André Behs",
  "Bernardo Cavour",
  "Brunna Baggio",
  "Camila Junqueira",
  "Igor Schultz",
  "Katyanna Salvador",
  "Luiz Gustavo Mariano",
  "Thiago Pimenta",
  "Leandro Pedrosa",
  "Saulo Ferreira",
  "IsadoraMi tteldorf",
  "Leon Affonso",
  "Gabriel Teixeira",
  "Prince Achugbu",
  "Ingrid Caetano",
  "Natalia Soares",
  "Marcela Dantas",
];

const projectStatus = {
  ACTIVE: "Active",
  DRAFT: "Draft",
  CONCLUDED: "Concluded",
  SUSPENDED: "Suspended",
  CANCELLED: "Canceled",
};

const activities = {
  [ACTIVITY.CONSTRUCTION_MEETING]: "Construction meeting",
  [ACTIVITY.VALIDATION_MEETING]: "Validation meeting",
  [ACTIVITY.INTERVIEWS]: "Interviews",
  [ACTIVITY.RESEARCH_AND_PICKING]: "Research and picking",
};

const screening_activities = {
  [SCREENING_ACTIVITY.SCREENING_MEETING]: "Screening",
};

const stagesTooltip = {
  [PROJECT_STAGE.CONSTRUCTION]: "Planejamento com o Cliente e Construção do Perfil Ideal.",
  [PROJECT_STAGE.MAPPING]: "Busca de perfis de pessoas compatíveis com o Perfil Ideal.",
  [PROJECT_STAGE.SCREENINGS]: "Triagem de candidatos para aderência ao projeto de vaga.",
  [PROJECT_STAGE.INTERVIEWS]: "Entrevistas detalhadas com candidatos triados.",
  [PROJECT_STAGE.SHORTLIST]: "Seleção e apresentação de lista de candidatos finalistas.",
  [PROJECT_STAGE.UNDER_OFFER]: "Negociação entre empresa e candidatos mais aderente.",
  [PROJECT_STAGE.PLACEMENT]: "Contratação e finalização do processo.",
};

const scale = {
  [SCALE.EMPTY]: "",
  [SCALE.LOW]: "Low",
  [SCALE.AVERAGE]: "Average",
  [SCALE.HIGH]: "High",
  [SCALE.VERY_HIGH]: "Very High",
};

const changeJobOptions = {
  BUSINESS_FUNCTION: "Business Function",
  PEOPLE_PROFILE: "People's Profile",
  COMPANY_CULTURE: "Company Culure",
};

const careerPathOptions = {
  GREATER_POSITION: "Greater Position",
  GREATER_CAREER: "Greater Career",
  WORK_ABROAD: "Opportunity Abroad",
  WORKPLACE: "Workplace",
};

const reasonsOptions = {
  EARNINGS: "Package",
  REMUNERATION_SYSTEM: "Remuneration System",
  CAREER_LIMITATIONS: "Career Limitations",
  LACK_OF_RELOCATION_OPPORTUNITIES: "Lack of Relocation Opportunity",
  BUSINESS_TYPE: "Business Type",
  FAMILY_SITUATION: "Family Situation",
  BOSS_RELATIONSHIP: "Relationship With the Boss",
  PEERS: "Peers",
  COMPANY_CULTURE: "Company Culture",
};

const validationPanel = {
  title: "Validation Panel",
  filters: {
    applyFilter: "Apply Filter",
    ascending: "Ascending",
    clearFilter: "Clean Filter",
    descending: "Descending",
    selectAll: "Select All",
    title: "Filter",
  },
  header: {
    close: "Close",
    company: "Company",
  },
  simpleSearch: {
    next: "Next",
    prev: "Previous",
    results: "{count} results were found",
    title: "What do you want to find?",
    tooltip: "Locate",
  },
  column: {
    candidate: "Candidate",
    company: "Company",
    past_experiences: "Past Experiences",
    phone: "Phone",
    priority: "Prioritization",
    role: "Role",
    salary: "Fixed Remuneration",
    salaryShort: "remuneration",
  },
  extra: {
    caution: "Attention",
    noPriority: "Without Priority",
    nonEditable: "It is only allowed to edit {name} in the Person's Profile.",
    nonEditableSalary: "In order to add {remuneration}, you need to add professional experience to the person's profile.",
    searchField: "Search Field",
    salaryRange: "Salary Range",
    salaryRangeFrom: "from",
    salaryRangeTo: "to",
  },
};

export const projects = {
  filters: {
    title: "FILTERS",
    searchProject: "Search project",
    searchProjectError: "type at least 2 characters to search",
    clearFilters: "clear filters",
    clearFilter: "Clear filter",
    tagFilter: "Insert the {tagFilter}",
    tagFilterClear: "Clear filter",
    tagFilters: {
      recruiter: "Recruiter",
      keywords: ["keywords", "keywords"],
      role: ["Role", "role"],
      company: ["Company", "company"],
      sector: ["Business line", "Business line"],
      revenue: ["Salary", "salary"],
      realizations: ["Achievements", "achievement"],
      executions: ["Execution condition", "execution condition"],
      position: ["Position", "position"],
    },
    errorListProject: "An error occurred while loading the projects. Try again later.",
    notFoundSearch: "Could not find any project with this search.",
    loadMore: "Load more...",
    myProjects: ["My", "Projects"],
    projectStatus: {
      name: "Status",
      enum: projectStatus,
      placeholder: "All",
    },
  },
  sort: {
    inserted_at: "Inserted date",
    status: "Status",
  },
  generic: {
    company: "Company",
    sector: "Business line",
    status: "Status",
    salary: "Salary",
    location: "Location",
    position: "Position",
    about: "About",
    class: "Class",
    level: "Level",
    bonus: "Bonus",
    annualPackage: "Annual package",
    requester: "Requester",
    requestOrigin: "Origin",
    details: "See details",
    confidential: "Confidential placement",
    edit: "Edit project",
    expand: "Expand",
    placement: "Placement",
    idealProfile: "Ideal profile",
    stages: {
      enum: {
        [PROJECT_STAGE.CONSTRUCTION]: "Construction",
        [PROJECT_STAGE.MAPPING]: "Mapping",
        [PROJECT_STAGE.SCREENINGS]: "Screenings",
        [PROJECT_STAGE.INTERVIEWS]: "Interviews",
        [PROJECT_STAGE.SHORTLIST]: "Shortlist",
        [PROJECT_STAGE.UNDER_OFFER]: "Under offer",
        [PROJECT_STAGE.PLACEMENT]: "Placement",
      },
      tooltip: stagesTooltip,
    },
    scale: {
      enum: scale,
    },
    companySize: "Size",
  },
  modal: {
    errorSave: {
      title: "Could not save project!",
      message: "Check the information entered and try again.",
      close: "Close Notice",
    },
    cantSave: {
      title: "Attention!",
      message:
        "Are you sure you want to finish without filling all the candidate's grades? The ranking will only be generated when all grades are assigned.",
      primaryBtn: "Go Back and Evaluate",
      secondaryBtn: "Save and finish",
    },
  },
  details: {
    tabs: {
      activities: "Activities",
      corporation: "Corporation",
      market: "Market",
      profession: "Profession",
      requester: "Requester",
      constructionGuide: "Construction guide",
      idealProfile: "Ideal profile",
      searchField: "Src field",
      screening: "Activities",
      mapping: "Mapping",
      screeningGuide: "Preview guide",
      ranking: "Ranking",
      candidates: "Candidates",
      interviewGuide: "Interview Guide",
      placement: "Placement",
    },
    edit: {
      save: "Save changes",
      cancel: "Cancel",
      projectName: {
        label: "Project name",
        placeholder: "Project name",
      },
      position: {
        label: "Position",
        placeholder: "Marketing manager",
      },
      projectType: {
        label: "Project type",
        enum: projectTypes,
      },
      status: {
        label: "Status",
        tooltipText: "Non-editable item",
        enum: projectStatus,
      },
      local: {
        label: "Local",
        placeholder: "São Paulo, SP, Brazil",
      },
      class: {
        label: "Class",
        placeholder: "C",
        enum: {
          CLASS_A: "A",
          CLASS_B: "B",
          CLASS_C: "C",
        },
      },
      level: {
        label: "Level",
        enum: ["+1", "0", "-1", "-2", "-3", "-4"],
      },
      about: {
        label: "About",
        placeholder: "Resume",
      },
      company: {
        label: "Company",
        placeholder: "LOFT Tecnologia",
      },
      salary: {
        label: "Salary",
        enum: {
          CLT: "CLT",
          PJ: "PJ",
        },
        from: "de",
        to: "a",
        range: "Salary Range",
        placeholder1: "CLT",
        placeholder2: "R$",
        placeholder3: "0,0",
        helptext: "Fill in all fields",
      },
      bonus: {
        label: "Bonus",
        placeholder: "R$ 80.000,00 - 12",
      },
      annualPackage: {
        label: "Annual Package",
        placeholder: "R$",
        helptext: "Fill in all fields",
      },
      requester: {
        label: "Requester",
        placeholder: "Alexandre Santoro",
      },
      origin: {
        label: "Origin",
        placeholder: "New client",
        enum: sourceTypes,
      },
      confidentialPlacement: {
        label: "Confidential Vacancy?",
      },
    },
    activities: {
      title: "Activity data",
      add: "Add",
      meetings: "Meetings",
      processes: "Processes",
      activityData: "Activity data",
      constructionMeeting: "Construction Meeting",
      goToMeeting: "Go to meeting",
      goToInterview: "Go to interview",
      organizeAndAssemble: "Organize and assemble",
      organizationAndAssembling: "Organization and assembling",
      organizeAndAnalyze: "Organize and analyze",
      mappingCandidates: "Mapping Candidates",
      schedule: {
        title: "Schedule",
        create: "Create schedule",
        edit: "Edit schedule",
        save: "Save schedule",
        cancel: "cancel",
        duration: "Stage duration",
        checkpoint: "Checkpoint",
        overdue: "Overdue",
      },
      organizationAndAssemblingForm: {
        addFormation: "Add Formation",
        addLanguages: "Add Language",
        addExperience: "Add Professional Experience",
        addCarreer: "Add Career",
        addAmbient: "Add Environment",
        addFamily: "Add Family",
        addPersonal: "Add Personal",
        addRealization: "Add Achievement",
      },
      enum: activities,
      screenings_enum: screening_activities,
      tabsCandidatesEnum: {
        activities: "Activities",
        candidates: "Candidates",
        interviewGuide: "Interview Guide",
        screeningGuide: "Screening Guide",
        priorization: "Priorization",
      },
      form: {
        labels: {
          startDate: "Date",
          consultantName: "Consultant name",
          activityDescription: "Activity description",
          participatingRequester: "Participating requester",
          candidateName: "Candidate name",
          fullname: "Candidate fullname",
          removeActivityTitle: "Remove activity",
          removeActivityMessage: "Are you sure you want to remove this activity?",
          removeProcessTitle: "Remove process",
          removeProcessMessage: "Are you sure you want to remove this process?",
          confirmRemove: "Confirm deletion",
          cancelRemove: "Cancel",
          saveInterview: "Save meeting",
          saveInfo: "Save information",
          saveConstruction: "Save Construction",
          you: "You",
          participatingRequestor: "requestor",
        },
      },
      screening: {
        title: "Preview datas",
        add: "Add",
        screening: "Screening",
        screenings: "Screenings",
        processes: "Processes",
        goToScreening: "Go to screening",
        enum: activities,
        checkpoint: "Checkpoint",
        evaluation: "Evaluation",
        reason: "Reason",
        disapproved: "Disapproved",
        approvalStatusEnum: {
          YES: "Yes",
          NO: "No",
          ON_HOLD: "On Hold",
        },
        disapprovalReasonEnum: {
          DECLINED: "Declined",
          LOCATION: "Location",
          UNDER_QUALIFIED: "Underqualified",
          OVER_QUALIFIED: "Overqualified",
          ON_HOLD: "On Hold",
          HIGH_COMPENSATION: "High Compensation",
          NO_FIT: "No Fit",
          NO_INTEREST: "No Interest",
        },
        motivationEnum: {
          salary: "Salary",
          attraction: "Attraction Factors",
          repulsion: "Repulsion Factors",
          retention: "Retention Factors",
          short_term_incentive: "STI (Short Term Incentive)",
          long_term_incentive: "LTI (Long Term Incentive)",
          other_incentive: "Other",
          total_cash: "Total Cash",
        },
        willingToRelocateEnum: {
          YES: "Yes",
          NO: "No",
        },
        workModelEnum: {
          REMOTE: "Remote",
          ONSITE: "Onsite",
          HYBRID: "Hybrid",
        },
        tabs: {
          screeningGuide: {
            form: {
              labels: {
                environment: "Environment",
                career: "Career",
                personal: "Personal",
                earnings: {
                  title: "Package",
                  salary: "Salary",
                  bonus: "Bônus",
                  package: "Anual package",
                },
                openToRelocation: "Possibility of change?",
                placeholders: {
                  addDescription: "Add description",
                  selectDegree: "Select course",
                  selectSkill: "Add Skill",
                },
              },
            },
          },
        },
        placeholders: {
          addPosition: "Add Position",
          addLocation: "Add Localization",
          createQuestion: "Create your question",
          select: "Select",
        },
        form: {
          titles: {
            currentExperience: "Current experience",
            realizations: "MAIN DELIVERIES OF THE POSITION (ACHIEVEMENTS):",
            previousExperience: "Relevant previous experience",
          },
          labels: {
            startDate: "Date",
            candidateName: "Candidate name",
            consultantName: "Consultant name",
            screeningsDescription: "Screening description",
            participatingRequester: "Participating Requester",
            removeScreeningsTitle: "Remove screening",
            removeScreeningsMessage: "Are you sure you want to remove this screening?",
            confirmRemove: "Confirm deletion",
            cancelRemove: "Cancel",
            saveInterview: "Save screening",
            saveInfo: "Save information",
            you: "You",
            selectProfile: "Select",
            fullName: "Full name",
            submit: "Save Activities",
            currentMoment: "Current moment",
            scopeofActivities: "Escope of Activitie",
            graduation: "Graduation",
            experience: "Experience",
            addExperience: "Add Experience",
            addRealization: "Add Realization",
            editRealization: "Edit Realization",
            tooltipMesage: "YOU WILL EDIT THIS CONTENT ON EACH APPLICANT'S Preview PANEL",
            company: "Company",
            yearsWorking: "Years working",
            position: "Position",
            positionButton: "position",
            localization: "Localization",
            realizationButton: "Achievement",
            question: "Question",
            years: "years",
          },
          textareas: {
            company_context: "What is the company context?",
            scope: "What is the scope of your work?",
            reports_to: "Who do you report to?",
            reported_by: "Who reports to this person?",
            peers: "Who are the peers?",
            not_responsible: "What processes are not your responsibility?",
            responsible: "What processes are under your responsibility?",
            team_structure: "How is your work team structured? Size / Level / Functions.",
          },
        },
      },
      checkpoint: {
        placeholders: {
          buttonComplete: "Complete checkpoint",
          buttonCompleted: "Checkpoint completed",
        },
        modal: {
          title: "Confirm Checkpoint",
          sub: "Are you sure you want to Confirm this checkpoint?",
          confirm: "Confirm",
          cancel: "cancel",
        },
      },
      table: {
        date: "Date",
        recruiter: "Name",
        activity: "Activity",
      },
      meetingsPanel: {
        notes: "Notes",
        projectDetails: "Project details",
        modal: {
          title: "ARE YOU SURE YOU WANT QUIT WITHOUT SAVE?",
          message:
            "You will be able to track your activities and review your notes by saving your process",
          confirm: "Keep Editing",
          cancel: "Leave anyway",
        },
      },
      constructionPanel: {
        analysisTypes: {
          [ANALYSIS_TYPES.GOVERNANCE]: "Governance",
          [ANALYSIS_TYPES.GOVERNANCE_HISTORIC_AND_COMPOSITION]: "About History and Composition",
          [ANALYSIS_TYPES.GOVERNANCE_DECISION_STRUCTURE]: "Decision structure",
          [ANALYSIS_TYPES.GOVERNANCE_ORGANIZATIONAL_CHART]: "Organizational Chart",
          [ANALYSIS_TYPES.GOVERNANCE_CULTURE]: "Culture / Work model and incentives",
          [ANALYSIS_TYPES.STRATEGY]: "Strategy",
          [ANALYSIS_TYPES.STRATEGY_BUSINESS]: "Company Business",
          [ANALYSIS_TYPES.STRATEGY_CORPORATION_MOMENT]: "Company Moment",
          [ANALYSIS_TYPES.STRATEGY_MOMENT]: "Strategy Moment",
          [ANALYSIS_TYPES.EXECUTION_CONDITIONS]: "Execution Conditions",
          [ANALYSIS_TYPES.EXECUTION_CONDITIONS_TEAM_MATURITY]: "Team Maturity",
          [ANALYSIS_TYPES.EXECUTION_CONDITIONS_POSITION_MANDATE]: "Power and Clarity of Position Mandate",
          [ANALYSIS_TYPES.EXECUTION_CONDITIONS_PROCESSES_MATURITY]: "Maturity of processes and systems",
          [ANALYSIS_TYPES.EXECUTION_CONDITIONS_BUDGET_AVAILABILITY]: "Budget availability",
          [ANALYSIS_TYPES.IDEAL_PROFILE]: "Ideal Profile",
        },
        analysisTypesTooltips: {
          [ANALYSIS_TYPES.GOVERNANCE_HISTORIC_AND_COMPOSITION]: "Investigate the origin of the<br/>company. What is the type of corporation?<br/> Who are the owners?<br/> What is the profile of the shareholders.",
          [ANALYSIS_TYPES.GOVERNANCE_DECISION_STRUCTURE]: "Is there a board? If there is, define it. What are the reporting structures? Are there board and committee structures List the types of company structures. If it is a multinational company, define: regional and global structures of report. In case of family business = owner CEO, define the hierarchical structure.",
          [ANALYSIS_TYPES.GOVERNANCE_ORGANIZATIONAL_CHART]: "Define the company's basic organization chart.<br/>Define the position area organization chart.<br/>Position Surroundings (Ex.: two Ns up and all pairs)",
          [ANALYSIS_TYPES.GOVERNANCE_CULTURE]: "<strong>Investigate and define whether the company is:</strong><br/>Results-oriented<br/>Methods and process-oriented<br/>Innovation and project-oriented<br/ >Oriented towards political relations.<br/><strong>Incentives:</strong><br/><strong>Investigate and define whether the company's incentives are:</strong><br/>More oriented towards fixed remuneration< br/>More oriented towards variable remuneration.<br/>More career oriented.<br/><strong>Work models</strong><br/>Flexibility / hybrid / remote / in-person",
          [ANALYSIS_TYPES.STRATEGY_BUSINESS]: "Define the sector / What are the main products or services? / Is there a revenue mix? / What are the customer markets? / What is the profile of the consumer public? / Investigate the internal processes / What are suppliers? / Who are the competitors? / Carry out a value chain analysis",
          [ANALYSIS_TYPES.STRATEGY_CORPORATION_MOMENT]: "Positioning (what is the company's focus and how is it seen in the market?) Momentum in the market (is it growing? is it maintained?) / What are the market risks? / What are the differentiators? / What are the trends ? / What are the transformation events?",
          [ANALYSIS_TYPES.STRATEGY_MOMENT]: "What is most relevant happening in the company? Is it changing strategy? Will it change? What is the level of maturity (has already changed / is changing / will change)? What are the main projects? What are they priorities? Are there interdependencies? Which ones?",
          [ANALYSIS_TYPES.EXECUTION_CONDITIONS_TEAM_MATURITY]: "These are the structures, authorities and resources available. It is every structural condition that the company has and will be made available to the new executive. The Execution Conditions can be divided into 4 major pillars: Team Maturity Understand the size of the team available. What is the level of qualification of the professionals? What are the direct reports? Example of analysis: Company has an above average level of qualification and team engagement. The team in the area will be small, initially counting on one position of Coordinator and another of Analyst. He will report directly to the Executive Committee.",
          [ANALYSIS_TYPES.EXECUTION_CONDITIONS_POSITION_MANDATE]: "What are the key interfaces of the position? Are there political and cultural barriers? Are there support structures? / Define them.",
          [ANALYSIS_TYPES.EXECUTION_CONDITIONS_PROCESSES_MATURITY]: "Are the area's processes already consolidated? Will it have support from other areas to carry out its duties? Do it have systems in place? Do any processes need to be modified? Are the processes mature enough?",
          [ANALYSIS_TYPES.EXECUTION_CONDITIONS_BUDGET_AVAILABILITY]: "What is the team's financial scenario that the professional will encounter?",
        },
        tabLabels: {
          governance: "These are the company's rituals and limits of action. It is the way the company is run. It is the definitions of rules, processes, policies that are the drivers for achieving objectives and business.",
          strategy: "It is the set of actions that are planned with specific objectives within the organization. Strategy is everything that describes the business and the market in which the company operates.",
          execution_conditions: "These are the structures, authorities and resources available. It is every structural condition that the company has and will be made available to the new executive.",
        },
        forms: {
          extensions: "Extensions",
          fild: "Field",
          professionalExperience: "Professional experience",
          placeholders: {
            professionalExperience: "e.g.: Experience in privately held national companies",
            field: "eg.: Administration",
            language: "ex.: Native or fluent English",
          },
          tags: {
            idealProfile: {
              placeholder: "Add {Title} tags",
              titles: "{Title} Tags",
              titlePath: ["Professional Experience", "Achievement"],
              titleDiv: "{Title} Tags",
              tagDesciption: [
                "Click in ",
                "to hide or show the tag in the presentation.",
                "to delete the tag.",
              ],
            },
          },
          enums: {
            academic: {
              degree: {
                [DEGREE.EMPTY]: "",
                [DEGREE.BACHELOR]: "Undergraduate",
                [DEGREE.POST_BACHELOR]: "Graduate",
                [DEGREE.MBA]: "MBA",
                [DEGREE.MASTER]: "Master",
                [DEGREE.DOCTORATE]: "PHD",
                [DEGREE.POST_DOCTORATE]: "PostDoc",
                [DEGREE.EXTRA_CURRICULAR_COURSE]: "Certificate",
              },
              institution: {
                [INSTITUTION.EMPTY]: "",
                [INSTITUTION.INDIFFERENT]: "Indifferent",
                [INSTITUTION.PREFERENTIALLY_FIRST_LINE]: "Preferably in a first-line institution",
                [INSTITUTION.MANDATORILY_FIRST_LINE]: "Obligatorily in a first-line institution",
              },
              extension: {
                [EXTENSION_TYPE.EMPTY]: "",
                [EXTENSION_TYPE.POST_BACHELOR]: "Post Bachelor",
                [EXTENSION_TYPE.MBA]: "MBA",
              },
            },
          },
        },
        tabs: {
          governance: "Governance",
          strategy: "Strategy",
          executionConditions: "Execution conditions",
          idealProfile: "Ideal profile",
          lineOfBusiness: "Search Field",
        },
        consultantOpinion: "Consultant advice",
        addAnalysis: "add analysis",
        editInsight: "Edit insight",
        editAnalysis: "Edit analysis",
        removeAnalysis: "Remove analysis",
        editorPlaceholder: "TEXT_",
        addLineOfBusiness: "add",
        addRealization: "add achievement",
        career: "Career",
        workEnvironment: "Environment",
        family: "Family:",
        personal: "Personal",
        salary: {
          package: "Package",
          packageAnnual: "Annual Package",
          bonus: "Bônus",
          title: "Salary",
        },
        profileForm: {
          placeholders: {
            career: "e.g.: Experience in the commercial area",
            workEnvironment: "e.g.: Working in an entrepreneurial environment",
            family: "e.g.: Living in Belo Horizonte",
            personal: "e.g.: Willingness to travel frequently",
          },
        },
        modal: {
          title: "ARE YOU SURE YOU WANT TO SUSPEND THIS PROJECT?",
          message:
            "You can reverse this suspension within 60 days. After this date, the project will be automatically canceled and can no longer be developed.",
          confirm: "CONFIRM SUSPENSION",
          cancel: "CANCEL",
        },
      },
    },
    constructionGuide: {
      form: {
        labels: {
          yes: "Yes",
          no: "No",
          saveAll: "Save all changes",
          addQuestion: "add new question",
          answer: "Answer question",
          edit: "Edit question title",
        },
        placeholders: {
          typeAnswer: "Type here your answer",
          position: "eg.: People Manager & Management",
          newQuestion: "Create your question",
        },
      },
      tabs: {
        company: "Company",
        position: "Position",
        strategy: "Strategy",
        governance: "Governance",
        profile: "Profile",
        executionConditions: "Execution conditions",
        achievements: "Achievements",
        functionalScope: "Functional scope",
      },
      aboutProject: "About Project",
      aboutMarket: " About the market",
      aboutProfession: "About the profession",
      aboutCompany: "About the company",
      description: "Description",
      tooltips: {
        [ATTRIBUTES_TITLES.COGNITIVE_INTELLIGENCE]:
          "Ability to observe, understand, diagnose and connect people, things, ideas and facts, identifying, proposing and solving problems and opportunities.",
        [ATTRIBUTES_TITLES.EMOTIONAL_INTELLIGENCE]:
          "sensitive, affective, skilled, people-oriented, sensible, calm, self-aware, adaptable, etc.",
        [ATTRIBUTES_TITLES.SPIRITUAL_INTELLIGENCE]:
          "Elevated vision to build a purpose that adds meaning to life, adopting ethical and moral values, in order to promote the engagement of others.",
        [ATTRIBUTES_TITLES.ENERGY]:
          "Doer, implementer, pragmatic, make it happen, multitask, auto volume, intensity, frequency, etc.",
        [ATTRIBUTES_TITLES.MATURITY]:
          "Experienced, resilient, sensitive, wisdom, experience, balanced, patient and so on.",
        [ATTRIBUTES_TITLES.POWER]: "Ready, big, complete and etc.",
        [CULTURAL_MATCH_TITLES.PRACTICAL]:
          "has great ability to execute, a lot of physical energy and prefers action to debate. He is an implementer, learns by doing and is not very fond of long, expository meetings. It is active, fast, impulsive and can be seen as aggressive. It is result-oriented and always competitive. When exacerbated, he tends to be “tractor” and not very sensitive to people. He feels good in situations and environments where there are goals, challenges and competition, both personally and in the market.",
        [CULTURAL_MATCH_TITLES.LOGICAL]:
          "has great organizational skills. He knows how to work with methods and processes and likes to have all the information he needs to make his analysis and then draw his conclusions. It is constant, precise, conservative, disciplinarian and can be perceived as arbitrary. When exacerbated, it can be uncreative and think “inside the box”. Tends to like structured and hierarchical environments.",
        [CULTURAL_MATCH_TITLES.REFLEXIVE]:
          "Has great power of abstraction, being able to diagnose and propose complex solutions out of common sense. Likes to think about the future and work on building new scenarios. It is planner, strategist, disruptive and can be seen as distant. When exacerbated, it can “travel to the world of the moon” and lose contact with everyday life. He likes transformation and structuring environments where there is space for innovative and creative projects.",
        [CULTURAL_MATCH_TITLES.AFFECTIVE]:
          "Has great ability to build and care for his teams and connections. He bases his actions and decisions heavily influenced by his personal relationships. “With whom” to do something is as important or more important than “what”, “why” or “how” to do it. He is enthusiastic, friendly and diplomatic. When exacerbated, you may have difficulty making decisions if they affect people around you. Likes environments where friendship, familiarity and complicity are valued.",
      },
      enums: {
        [STRATEGY_TITLES.COMPANY_MOMENT]: {
          [COMPANY_MOMENTS.GROWING]: "Crescendo",
          [COMPANY_MOMENTS.SHRINKING]: "Reduzindo",
          [COMPANY_MOMENTS.INVESTING]: "Investindo",
        },
        [STRATEGY_TITLES.STRATEGY_STATUS]: {
          [STRATEGY_STATUSES.ACTIVE]: "Ativa",
          [STRATEGY_STATUSES.INATIVE]: "Inativa",
        },
        [GOVERNANCE_TITLES.GOVERNANCE_INSTANCES]: {
          [GOVERNANCE_INSTANCE.COMMITTEE]: "Comitês",
          [GOVERNANCE_INSTANCE.ADVISORY_COUNCIL]: "Conselhos consultivos",
          [GOVERNANCE_INSTANCE.FAMILY_COUNCIL]: "Conselho familiar",
        },
        [GOVERNANCE_TITLES.COMPANY_CHART]: {
          [COMPANY_CHARTS.COUNCILS]: "Conselhos",
          [COMPANY_CHARTS.COMMITTEES]: "Comitês",
          [COMPANY_CHARTS.CEO]: "CEO",
          [COMPANY_CHARTS.C_LEVEL]: "C-level",
          [COMPANY_CHARTS.DOWN_LEVEL]: "Nível abaixo",
        },
        [GOVERNANCE_TITLES.BOARD_TYPE]: {
          [BOARD_TYPES.CONTROLLER]: "Controlador",
          [BOARD_TYPES.CAPITAL]: "Capital pulverizado",
        },
        [ATTRIBUTES_TITLES.COGNITIVE_INTELLIGENCE]: scale,
        [ATTRIBUTES_TITLES.EMOTIONAL_INTELLIGENCE]: scale,
        [ATTRIBUTES_TITLES.SPIRITUAL_INTELLIGENCE]: scale,
        [ATTRIBUTES_TITLES.ENERGY]: scale,
        [ATTRIBUTES_TITLES.MATURITY]: scale,
        [ATTRIBUTES_TITLES.POWER]: scale,
        [CULTURAL_MATCH_TITLES.PRACTICAL]: scale,
        [CULTURAL_MATCH_TITLES.LOGICAL]: scale,
        [CULTURAL_MATCH_TITLES.REFLEXIVE]: scale,
        [CULTURAL_MATCH_TITLES.AFFECTIVE]: scale,
        [EXECUTION_CONDITIONS_TITLES.PROJECT_STAGE]: {
          [PROJECT_STAGES.DIAGNOSTICS_WITH_CONSULTING]: "No Diagnóstico com consultoria",
          [PROJECT_STAGES.DIAGNOSTICS_WITHOUT_CONSULTING]: "No Diagnóstico sem Consultoria",
          [PROJECT_STAGES.EXPLANATION]: "No Convencimento/ Explicação",
          [PROJECT_STAGES.IMPLEMENTATION_WITH_CONSULTING]: "Em Implementação sem Consultoria",
          [PROJECT_STAGES.IMPLEMENTATION_WITHOUT_CONSULTING]: "Em Implementação sem Consultoria",
          [PROJECT_STAGES.FAILED_IMPLEMENTATION]: "Em Implementação com falhas",
        },
      },
      questions: {
        EMPTY: "No questions registered",
        [STRATEGY_TITLES.REVENUES_AND_INCOMINGS_SOURCES]:
          "What are the forms of revenue and how is compound the billing?",
        [STRATEGY_TITLES.MAIN_PRODUCT]: "What is the main product?",
        [STRATEGY_TITLES.SECONDARY_PRODUCT]: "What are the secondary products?",
        [STRATEGY_TITLES.GEOGRAPHIC_PRESENCE]: "What is the geography presence?",
        [STRATEGY_TITLES.COMPANY_MOMENT]: "What is the company moment?",
        [STRATEGY_TITLES.BUSINESS_PLAN]: "What is the business plan?",
        [STRATEGY_TITLES.STRUCTURING_PROJECTS]: "Exists some structure projects?",
        [STRATEGY_TITLES.ACQUISITION_PROJECTS]: "Exists some acquisition projects?",
        [STRATEGY_TITLES.ASSET_SALES_PROJECTS]: "Exists some active selling projects?",
        [STRATEGY_TITLES.MARKET_SCENARIO]: "What is the current scenario of the market?",
        [STRATEGY_TITLES.DEFINED_STRATEGY]:
          "Are the strategy defined and everybody knows about that?",
        [STRATEGY_TITLES.STRATEGY_STATUS]: "What is the strategy status?",
        [GOVERNANCE_TITLES.PARTNERSHIP_STRUCTURE]: "What is the corporate company structure?",
        [GOVERNANCE_TITLES.ADMINISTRATIVE_BOARD]: "Exists any board directors?",
        [GOVERNANCE_TITLES.MEMBERS_BOARD]: "Who are the members?",
        [GOVERNANCE_TITLES.BOARD_TYPE]: "Are the board directors present in some way?",
        [GOVERNANCE_TITLES.INDEPENDENT_MEMBERS]:
          "Have independent members on the board of directors?",
        [GOVERNANCE_TITLES.GOVERNANCE_INSTANCES]: "What are the governance instances?",
        [GOVERNANCE_TITLES.COMPANY_CHART]: "Draw the company organization chart.",
        [GOVERNANCE_TITLES.MANAGEMENT_MODEL]: "What is the management model?",
        [GOVERNANCE_TITLES.PROCESS_INTEGRATION]:
          "Exists some integration in the management process and persons (Integrated Management of Process and Persons)?",
        [GOVERNANCE_TITLES.POLICIES_AND_PROCESSES]:
          "What are the terms, processes, and scopes of the company management?",
        [GOVERNANCE_TITLES.ROLES_AND_RESPONSIBILITIES]:
          "Are defined and clear for everyone the objectives and responsibilities of leadership scopes?",

        [PROFILE_TITLES.POSITION_NAME]: "Position name:",
        [PROFILE_TITLES.ACADEMIC_BACKGROUND]: "Desired Academic Formation:",
        [PROFILE_TITLES.WORK_EXPERIENCE]: "Professional Experience / Experience time:",
        [PROFILE_TITLES.MOTIVATION_PACKAGE]: "Motivation - Package:",
        [PROFILE_TITLES.COMPANY_POLICY]:
          "What is the company policy vs market (remuneration analysis)?",
        [PROFILE_TITLES.ATTRIBUTES]: "Attributes:",
        [PROFILE_TITLES.CULTURAL_MATCH]: "Cultural match",
        [PROFILE_TITLES.REALIZATIONS]: "Realization of the person",
        [ATTRIBUTES_TITLES.COGNITIVE_INTELLIGENCE]: "Cognitive Intelligence",
        [ATTRIBUTES_TITLES.EMOTIONAL_INTELLIGENCE]: "Emotional Intelligence",
        [ATTRIBUTES_TITLES.SPIRITUAL_INTELLIGENCE]: "Spiritual Intelligence",
        [ATTRIBUTES_TITLES.ENERGY]: "Energy",
        [ATTRIBUTES_TITLES.MATURITY]: "Maturity",
        [ATTRIBUTES_TITLES.POWER]: "Power",
        [CULTURAL_MATCH_TITLES.PRACTICAL]: "Practical",
        [CULTURAL_MATCH_TITLES.LOGICAL]: "Logical",
        [CULTURAL_MATCH_TITLES.REFLEXIVE]: "Reflexive",
        [CULTURAL_MATCH_TITLES.AFFECTIVE]: "Affective",
        [EXECUTION_CONDITIONS_TITLES.PROJECT_STAGE]: "What stage is the project?",
        [EXECUTION_CONDITIONS_TITLES.PROJECT_IS_KNOWN]:
          "Is the project known and clear for all principal stakeholders?",
        [EXECUTION_CONDITIONS_TITLES.COMPANY_IMPACT]:
          "How is the delivery connects or impact the results and strategy of the company in the mid-term (2, 3 years)?",

        [POSITION_STRATEGY_TITLES.TIME_ALLOCATION]:
          "How are you see the time shared by the executive on these three agendas (diagnostic cycle - knowledge - implementation)?",
        [POSITION_STRATEGY_TITLES.EXECUTIVE_PEERS_LEVEL]:
          "About the company governance. What are the pairs and levels of executive pairs?",
        [POSITION_STRATEGY_TITLES.EXECUTIVE_REPORTING]: "Who does the executive report??",
        [POSITION_STRATEGY_TITLES.MANAGER_PROFILE]:
          "What is the manager profile (owner, an executive with a long career at the company, fresh in the market, where he came from, how did he come)?",
        [POSITION_STRATEGY_TITLES.BUDGET]: "What is the budget to finish the job?",
        [POSITION_STRATEGY_TITLES.APPROVAL_AUTHORITY]:
          "What is the approval autonomy (e.g: an executive would like to approve an important investment (R$ 50 million))?",
        [POSITION_STRATEGY_TITLES.APPROVAL_CYCLE]:
          "What is the governance approvals cycle he must go through?",
        [REALIZATIONS_TITLES.MAIN_DELIVERY]:
          "What is the main delivery that the executive should carry out in X time (months, year...) from home?",
        [REALIZATIONS_TITLES.BUSINESS_DEVELOPMENTS]:
          "What are the possible outspread in the business arising from this project?",
        [REALIZATIONS_TITLES.STRUCTURE_DEVELOPMENTS]:
          "What are the possible outspreads in the organizational and governance structure arising from this project?",
        [REALIZATIONS_TITLES.NEED_ADAPTATION]:
          "Is there a need to adapt the structure of the executive for the project to be delivered? (people, skills, systems, governance, scope, etc.)?",
        [FUNCTIONAL_SCOPE_TITLES.POSITION_ACTIVITIES]:
          "What are the activities/processes of the position?",
        [FUNCTIONAL_SCOPE_TITLES.POSITION_REPORT]: "Who reports for this vacancy?",
        [FUNCTIONAL_SCOPE_TITLES.OPERATIONAL_ROUTINES]:
          "What are the main operational routines that are part of the executive's daily?",
        [FUNCTIONAL_SCOPE_TITLES.ROUTINES_NEED_ADJUSTMENT]:
          "Do these routines work or do they need some adjustments?",
        [FUNCTIONAL_SCOPE_TITLES.RELEVANT_DELIVERIES]:
          "What other deliverables are considered relevant for the first year (essential activities for executive profile validation)?",
        [FUNCTIONAL_SCOPE_TITLES.REPORTER_POSITION]:
          "What is the reporting level of who reports (position)?",
        [FUNCTIONAL_SCOPE_TITLES.TEAM_SIZE]: "What is the team size?",
        [FUNCTIONAL_SCOPE_TITLES.TEAM_TRANSFORMATION_AGENDA]:
          "Have to change someone (team transformation agenda)?",
        [FUNCTIONAL_SCOPE_TITLES.PROCESS_TRANSFORMATION_AGENDA]:
          "Do you have to change processes and/or systems (structuring and/or transformation agenda)?",
        [FUNCTIONAL_SCOPE_TITLES.OPEN_VACANCIES]: "Do you have an open vacancy?",
        [FUNCTIONAL_SCOPE_TITLES.RELEVANT_STRUCTURE]: "What is the relevant structure?",
        [FUNCTIONAL_SCOPE_TITLES.TEAM_PROFILE]: "What is the team profile?",
      },
      idealProfile: {
        realization: "Achievement",
        realizations: {
          title: "Achievements",
          desiredAttribute: "Expected attributes",
          desiredCulturalMatch: "Expected cultural match",
        },
        experiencePlaceholders: {
          0: "Experiência em empresas nacionais de capital fechado com governança centralizada.",
          1: "Experiência em empresas que passara por eventos de liquidez.",
          2: "Desenvolvimento de agendas de transformação e estruturante",
        },
        realizationsPlaceholders: {
          0: "Ter realizado com sucesso o projeto de crescimento acelerado de empresa.",
          1: "Ter resultados satisfatórios em processo de profissionalização da companhia.",
          2: "Liderança em evento de liquidez dentro da empresa.",
        },
        headquartersLocation: "Headquarter location",
        companySize: "Size",
        consultantOpinion: "Consultant Conclusion",
        companyProfile: "Company Profile",
        attributes: "Attributes",
        culturalMatch: "Cultural Match",
        motivation: "Motivation",
        profile: "Ideal Profile - Persona",
        tabNext: "Next",
        tabPrevious: "Previous",
        academicFormation: "Academic Background",
        experience: "Professional Experience",
        experienceShort: "Experience",
        candidate: "Candidate",
        tooltip: {
          achievements: "Achievements",
          attributes: "Attributes",
          company: "Company",
          companyProfile: "Company Profile",
          culturalMatch: "Cultural Match",
          executionConditions: "Execution Conditions",
          governance: "Governance",
          motivation: "Motivation",
          profile: "Ideal Profile",
          strategy: "Strategy",
          export: "Export",
          exportVersionIMUA: "Export IMUA Version",
          exportVersionImuaBeta: "Export IMUA Beta",
          exportVersionBullhorn: "Export Legacy Version",
          shouldShowMore: "Show more...",
        },
        salary: "Salary (ideal target):",
        bonus: "Bonus:",
        package: "Package",
        attributesKeys: {
          cognitiveIntelligence: "Cognitive Intelligence",
          EmotionalIntelligence: "Emotional Intelligence",
          spiritualIntelligence: "Spiritual Intelligence",
          energy: "Energy",
          maturity: "Maturity",
        },
        culturalMatchKeys: {
          affective: "affective",
          logical: "logical",
          practical: "practical",
          reflexive: "reflexive",
        },
        addSubItem: "Add {user}",
      },
      ranking: {
        weights: "Weights",
        weight: "Weight",
        rankingWeightsSucessPart1: "The weights sum",
        rankingWeightsSucessPart2: "should be 100%",
        saveWeights: "Save weights",
        saveRanking: "Save ranking",
        addToInterviews: "Approve to interviews",
        removeFromInterviews: "Remove from interviews",
        openScreeningDetails: "Screening details",
        hideDetails: "Hide details",
        addToShortlist: "Approve to shortlist",
        approvedToInterviews: "Approved to interview?",
        approvedReason: "Reason",
        selectPriorityClassification: "Select or modify the priority classification",
        removeFromShortlist: "Remove from shortlist",
        openInterviewDetails: "Interview details",
        removerCandidate: "Remove",
        reassess: "Reassess",
        screeningInfo: "Screening info",
        requirementsMet: "Ideal profile met?",
        recruiterObservations: "Recruiter Observations",
        emptyObservations: "No observations",
        empty: "Not informed",
        salaryRange: "Salary Range",
        bonus: "Bônus",
        annualPackage: "Annual Package",
        addDescription: "Add description",
        language: "Language",
        proficiency: "Proficiency",
        experience: "Experience",
        relocationAvailability: "Relocation availability?",
        contractType: "Contract type",
        candidateNotFound: "No candidates found in this search",
        details: {
          title: "interview details",
          realizations: "Achievements",
          attributes: "Attributes",
          culturalMatch: "Cultural Match",
          motivation: "Motivation",
          consultantOpinion: "Consultant advice:",
          candidateAvaliation: "Overall Candidate Assessment",
          another: "Others",
          leaveCompany: "What would make you leave your current company?",
          stayInCompany: "What would make the candidate stay in the current company?",
          companyAttraction: "What would make the candidate go to a new company?",
        },
      },
      searchField: {
        title: "Business Line",
        forms: {
          placeholders: {
            buttonAddLine: "add business line",
            buttonAddCompany: "add company",
          },
        },
      },
    },
    searchField: {
      addBusinessLine: "add business line",
      searchFieldInputHint: "Insert keywords that represent the market or product you are looking for",
      termInputPlaceholer: "Keywords",
      termNotFound: "search terms not found",
      search: "Search",
      hideTermsList: "Hide list",
      showTermsList: "Show list",
    },
    options: {
      suspendProject: "Suspend project",
      activateProject: "Activate Project",
      cancelProject: "Cancel project",
      modalSuspend: {
        title: "Are you sure you want to suspend this project?",
        resume:
          "You can reverse this suspension within 60 days. After this date, the project will be automatically canceled and can no longer be developed.",
        buttons: {
          confirm: "Confirm suspension",
          cancel: "Cancel",
        },
      },
      modalCancel: {
        title: "Are you sure you want to cancel this project?",
        resume: "This action is final and cannot be reversed.",
        buttons: {
          confirm: "Confirm cancellation",
          cancel: "Cancel",
        },
      },
    },
    modalPresence: {
      title: "Another user is editing this file",
      messageBegin: "Wait",
      messageEnd: "close the file and try again later",
    },
  },
  construction: {
    titles: {
      construction: "Construction",
      academic: "Academic formation",
      language: "Language",
      experience: "Professional Experiences",
      realizations: "Achievements",
      attributes: "Attributes",
      culturalMatch: "Cultural Match",
      motivation: "Motivação",
      package: "Package",
      career: "Career",
      environment: "Environment",
      personal: "Personal",
      meetProfile: "Meets the ideal profile",
      screening: "Screening",
      interview: "Interview",
    },
    labels: {
      originalTitle: "Original title",
      originalRestore: "Restore original",
      modalRestoreOriginalTitle: "Caution!",
      modalRestoreOriginalResume: "Do you really want to restore the name to its original version?",
      modalClearTermsTitle: "Do you want to clear all search ground?",
      modalClearTermsContent: "This will exclude all business lines ",
      modalClearTermsContentComplement: "listed in your search.",
      searchingRelatedTerms: "Searching related business lines...",
      defined: "Defined",
      toDefine: "To define",
      salary: "Salary",
      icp: "STI (Short Term Incentive)",
      icpShort: "STI",
      ilp: "LTI (Long Term Incentive)",
      ilpShort: "LTI",
      others: "Others",
      otherIncentives: "Others incentives",
      totalCash: "Total Cash",
      realization: "Achievement",
      description: "Description",
      searchCorporations: "Search corporations",
      searchCorporation: "Search corporation",
      corporationsFound: "companies found",
      corporations: "empresas",
    },
    placeholders: {
      salary: "Ex: CLT $ 45,000.00",
      icp: "Ex: Quarterly bonus, opportunity for professional growth and commissions for specific projects.",
      ilp: "EX: Company profit sharing and Stock options",
      others: "Ex: Quarterly bonuses and commissions for specific projects",
      totalCash: "EX: $ 45,000.00",
    },
    fill: {
      empty: "Not filled",
      editing: "Partially filled",
      complete: "Filled",
    },
    modal: {
      titles: {
        warning: "Warning",
        wantToSave: "Are you sure you want to change?",
        wantToFinish: "Are you sure you want to finish?",
      },
      messages: {
        errorAcademic:
          "You need to add an Academic Background or Language to be able to take the assessment in the screening",
        errorExperience: "You need to add an Experience to be able to review the screening",
        constructionAfterSave:
          "Remember: when changing, inserting or deleting fields in the construction, you will affect the evaluation of the following steps.",
        constructionAfterFinish:
          "Remember: when changing, inserting or deleting fields in the construction, you will affect the evaluation of the following steps: {screening}{interview}.",
      },
      buttons: {
        closeAndExit: "Close and exit",
        continueEditing: "Continue editing",
        save: "Save",
        continue: "Continue",
        finish: "Finish",
        cancel: "Cancel",
      },
      fillOut: {
        title: "Complete the ideal profile",
        message: "To create all the evaluation parameters, it is essential to fill in all the fields of the ideal profile.",
        buttons: {
          primary: "Continue editing",
          secondary: "End anyway",
        },
      },
    },
    buttons: {
      confirmRestore: "Confirm",
      cancelRestore: "Cancel",
      clearAll: "Clear all",
    },
    pdf: {
      [ConstructionEnum.FRONT_COVER]: "Front Cover Flow",
      [ConstructionEnum.SUMMARY]: "Summary",
      [ConstructionEnum.COMPANY]: "About the company",
      [ConstructionEnum.GOVERNANCE]: "Governance",
      [ConstructionEnum.STRATEGY]: "Strategy",
      [ConstructionEnum.EXECUTION_CONDITIONS]: "Execution Conditions",
      [ConstructionEnum.REALIZATIONS]: "Achievements",
      [ConstructionEnum.IDEAL_PROFILE_TAGS]: "Ideal Profile - Persona",
      [ConstructionEnum.IDEAL_PROFILE]: "Ideal Profile - Persona",
      [ConstructionEnum.COMPANY_PROFILE]: "Company Profile",
      [ConstructionEnum.ATTRIBUTES]: "Attributes",
      [ConstructionEnum.CULTURAL_MATCH]: "Cultural Match",
      [ConstructionEnum.MOTIVATION]: "Motivation",
      [ConstructionEnum.SEARCH_FIELD]: "Search Field",
      [ConstructionEnum.TIMELINE]: "Schedule",
      [ConstructionEnum.BACK_COVER]: "Back Cover Flow",
      [ShortlistEnum.ADHERENCE_TABLE]: "Adherence table",
      [ShortlistEnum.PROCESS_FUNNEL]: "Process funnel",
      [ShortlistPersonEnum.USER_CARD_PERSON]: "Candidates analyses",
      [ShortlistPersonEnum.USER_ABOUT]: "Professional experience",
      [ShortlistPersonEnum.USER_ACADEMIC]: "Education Background",
      [ShortlistPersonEnum.USER_EXPERIENCE]: "Experience",
      [ShortlistPersonEnum.USER_REALIZATIONS]: "Achievements",
      [ShortlistPersonEnum.USER_ATTRIBUTES]: "Atrributes",
      [ShortlistPersonEnum.USER_CULTURAL_MATCH]: "Cultural match",
      [ShortlistPersonEnum.USER_MOTIVATION]: "Motivation",
      [ShortlistPersonEnum.USER_EVALUATION]: "Evaluation",
      [ShortlistPersonEnum.USER_EVALUATION_FINAL]: "Evaluation",
    },
  },
  mapping: {
    saveCandidates: "Save list of candidates",
    drag: "Drag or upload candidate list",
    displayAll: "Display All",
    displaySelectedCandidates: "Display only selected",
    filters: {
      schooling: "Schooling",
      salaryRange: "SalaryRange",
    },
    list: {
      select: {
        placeholder: "Mass action",
        options: {
          addToProject: "Add to project",
        },
      },
      sort: {
        placeholder: "Sort by",
        options: {
          priority: "Priority",
          name: "Alphabetical order",
          updated_at: "Updated date",
        },
      },
      total: {
        candidate: "No candidate | 1 candidate | {count} candidates",
      },
    },
    card: {
      addCandidate: "Add candidate",
      added: "Added",
      priority: "Prioritize candidate",
      remove: "Remove",
      excludeCandidate: "Exclude candidate",
      tooltip: "screening/interview conclued",
      placementCandidate: "Placement candidate",
    },
    modal: {
      title: {
        uploaded: "You have uploaded the candidate list",
        candidatesAdded: "{amount} candidates added to the project",
        candidatesNotAdded: "You have not added candidates to the project",
      },
      buttons: {
        ok: "Ok",
        goToScreening: "Go to screenings",
        continueMapping: "Continue mapping",
      },
      message: {
        accompanyCandidates: "Follow the list of candidates mapped in Preview",
        backToListMapping: "Go back to the mapping list and add candidates to the project.",
        hasAdded: "{response} people have been added to the project:",
        updated: [
          "{response} people already registered in IMUA.",
          "{response} person already registered in IMUA.",
        ],
        created: [
          "{response} new people created and added.",
          "{response} new person created and added.",
        ],
      },
      error: ["List(s) of candidates with", "ERROR"],
      ok: "Ok",
      errorMessage: "Your file must be in CSV or XLSX format.",
    },
  },
  screening: {
    guide: {
      accordeons: {
        educationalBackground: "Academic formation",
        experience: "Experience",
        motivation: "Motivation",
        realizations: "Achievements",
        culturalMatch: "Cultural Match",
        attributes: "Attributes",
        approval: "Approve and prioritize",
        candidateOrientations: "Consultant Guidelines",
        candidateQuestions: "Questions for Candidate",
        candidateRealizations: "Candidate Accomplishments",
        vacancyProcess: "Vacancy Process <br> <span>(instruction to the consultant)</span>",
      },
      institution: "Institution",
      level: "Level",
      course: "Field/Course",
      languages: "Languages",
      skill: "Required Skill",
      isRequiredSkill: "This skill is required?",
      isEliminatorySkill: "Is this skill eliminatory?",
      add: {
        formation: "Formation",
        language: "Language",
        skill: "Skill",
      },
      vacancyProcess:
        "The professional for this position must have conducted diagnoses to understand the main levers of the business, developed and implemented a new compensation and performance management model with emphasis on meritocracy, identifying opportunities for increased value generation for the company (process and cultural change).",
      panels: {
        screeningGuide: "Screening guide",
        anotations: "Anotations",
        organizationAndEvaluation: "Organization and Evaluation",
        profile: "Profile",
        save: "Save evaluation",
      },
      PostScreening: {
        jobModel: "Work model:",
        warning: "Assessment required to define the Ranking",
        workType: {
          REMOTE: "Remote",
          ONSITE: "Presential",
          HYBRID: "hybrid",
        },
        motivation: {
          consultantSeem: "Consultant's Opinion:",
          percentIdealProfile: "Attend the ideal profile?",
          addDescription: "Adicionar descrição",
          relevantExperience: "Experiência Anterior Relevante",
          actualExperience: "Experiência Anterior Relevante",
        },
        educationBackground: {
          languages: "Candidate languages",
          requiredSkill: "Required Skill",
          skill: "Skill",
          idealProfile: "Ideal Profile",
          candidateFormation: "Candidate Formation",
          fluent: "Fluent English",
        },
      },
    },
    list: {
      select: {
        placeholder: "Mass action",
        options: {
          addToProject: "Approve to interviews",
        },
      },
    },
    labels: {
      motivation: "Motivation",
      saveScreening: "Save Screening",
      saveEvaluation: "Save Evaluation",
      gotToScreening: "Go to screening",
      scheduleScreening: "Schedule Preview",
      screeningDate: "Screening date",
      ofCandidate: "of the candidate",
      candidate: "Candidate",
      linkedCandidateInScreening: "Candidate linked to screening",
      screenings: "Screenings",
      newScreening: "Create new screening",
    },
    tooltip: {
      failedScreening: "Failed in Screening",
      screeningCompleted: "Screening completed",
      pendingScreening: "Pending screening",
    },
    modal: {
      title: {
        linkedCandidate: "Candidate already linked to the project",
        nonLinkedCandidate: "Add a candidate",
        approvalCandidate: "Are you sure you want to approve the candidate?",
      },
      message: {
        linkedCandidate: "There is already a preview activity linked to the candidate in this project.",
        nonLinkedCandidate: "You need to assign this preview to a candidate before finalizing the activity.",
        nonLinkedCandidateApproval: "You need to assign this preview to a candidate before <strong>approving</strong> and <strong>prioritizing</strong>.",
        approvalCandidate: "This action automatically creates the interview activity and is irreversible.",
      },
      buttons: {
        confirm: "Confirm",
        cancel: "Cancel",
        screeningActivities: "Screening Activities",
        goBackAndAdd: "Go back and add",
      },
    },
  },
  interview: {
    tooltipMesage: "You will edit this content in each candidate's meeting panel",
    guide: {
      consultantGuidelinesOptions: [
        "Give a brief introduction to the design method.",
        "Bring advance information.",
        "Study the ideal profile achievement items and structure questions that lead you to understand the executive's real contribution in the area and company routines (operational agenda and participation in projects and their leading role.",
        "Bring the construction achievements (The interview must be based on these achievements).",
      ],
      consultantGuidelines: "Consultant Guidelines",
      questionsToCandidate: "Questions to Candidate",
      candidateRealizations: "Candidate Achievements",
      motivation: "Motivation",
      ambient: "Ambient",
      career: "Career",
      family: "Family",
      personal: "Personal",
      package: "Package",
      bonus: "Bônus",
      expulsion: "Expulsion factors from the current company:",
      retention: "Current company retention factors:",
      attraction: "Attracting factors of the current company:",
      orientation: "Motivation guidelines for the candidate:",
    },
    labels: {
      company: "Company",
      candidateUnderstanding: "O que o candidato entendeu sobre a vaga?",
      realizationsPlace:
        "Onde o candidato realizou as atividades das atribuições da vaga (Realizações)?",
      companyName: "Nome da empresa",
      project: "Projeto",
      goal: "Objetivo do Projeto:",
      diagnosis: "De onde partiu o diagnóstico?",
      decisionMakingModel:
        "Qual é o modelo de tomada de decisão da empresa? Analítico / Racional / Emocional",
      environmentPreference:
        "Em qual ambiente você prefere atuar? Analítico / Racional / Emocional",
      whoWasInvolved: "Quem estava envolvido?",
      involvedProtagonism: "Qual o nível de protagonismo dos envolvidos?",
      implementationLeadership: "Quem liderou a implementação?",
      governanceAndExposition:
        "Quais os níveis de exposição e governança que você teve no projeto?",
      howWasMade: "Como foi feito?",
      resistance: "Houve resistência (s)?",
      dealResistance: "Como você lidou com essas resistências?",
      changeNegativeBehavior: "Teve que mudar um comportamento negativo de alguém? Como?",
      results:
        "Quais foram os resultados (financeiro / tempo / produtividade / cursos / eficiência operacional)?",
      careerPath: "What is your career path?",
      talkAbout: "Talk about:",
      realization: "Achievement",
      consultantOpnion: "Parecer do consultor",
      meetsIdealProfile: "Atende ao perfil ideal",
      expectedPackage: "Qual o pacore de remuneração esperado?",
      salary: "Salary",
      bonus: "Bônus",
      annualPackage: "Annual Package",
      environmentQuestion: "Motivation to move to a new company?",
      careerQuestion: "What is the candidate's career projection?",
      leaveCompany: "What would make you leave your current company?",
      stayCompany: "What would make the candidate stay in the current company?",
      companyAttraction: "What would make the candidate go to a new company?",
      talkAboutMotivation: "Talk about motivation:",
      information: "Did you capture any motivating information during an interview?",
      reasonToLeave: "What would make you leave your current company?",
      counteroffer: "Guide the candidate on the retention factors / Chance of Counter-offer:",
      newQuestion: "New question",
      editQuestion: "Edit question",
      addQuestionInfo: "Add interview question with this candidate (Optional)",
      interviewDate: "Interview date:",
      errorCreateMessage: "It is not possible to create more than one activity per candidate.",
    },
    titles: {
      customizeInterview: "Customize Interview",
      screeningInformation: "Screening information",
      educationalBackground: "Academic Formation",
      professionalExperience: "Professional experience",
      motivation: "Motivation",
      caution: "Caution!",
      attraction: "Attracting factors",
      attractionShort: "Attraction",
      retention: "Retention factors",
      retentionShort: "Retention",
      repulsion: "Reasons to leave",
      repulsionShort: "Reasons to leave",
    },
    buttons: {
      reviewAndSchedule: "Review and schedule",
      scheduleInterview: "Schedule interview",
      goToInterview: "Go to interview",
      approveInterview: "Approve for interview",
      screeningDetails: "Show details",
      hideDetails: "Hide details",
      back: "Back",
    },
    selects: {
      businesFunction: "Busines Function",
      peopleProfile: "People Profile",
      companyCulture: "Company Culture",
      earnings: "Earnings",
      remunerationSystem: "Remuneration System",
      careerLimitations: "Career Limitations",
      lackOfRealocationOpportunities: "Lack of Realocation Opportunities",
      businessType: "Business Type",
      familySituation: "Family Situation",
      bossRelationship: "Boss Relationship",
      peers: "Peers",
      greaterPosition: "Greater Position",
      greaterCareer: "Greater Career",
      workAbroad: "Work Abroad",
      workplace: "Workplace",
    },
    placeholders: {
      select: "Select",
      addDescription: "Adicionar descrição",
      addCompany: "Adicionar empresa",
      addProject: "Adicionar Projeto",
    },
    panels: {
      interviewGuide: "Interview Guide",
      annotations: "Annotations",
      idealProfile: "Ideal Profile",
      organizeAndEvaluate: "Organize and evaluate",
    },
    accordeons: {
      educationalBackground: "Academic Formation",
      experience: "Experience",
      motivation: "Motivation",
      realizations: "Achievements",
      culturalMatch: "Cultural Match",
      attributes: "Attributes",
      approval: "Approval and Prioritize",
      candidateOrientations: "Orientações ao consultor",
      candidateQuestions: "Perguntas para o candidato",
      candidateRealizations: "Realizações do candidato",
    },
    enums: {
      changeJob: changeJobOptions,
      careerPath: careerPathOptions,
      reasons: reasonsOptions,
    },
    oritentations: {
      package:
        "Remuneration system offered by the company: fixed, bonus, ILP, shares, CLT, PJ, etc...",
      careerSituation:
        "Guide the candidate to discuss his/her career situation with the manager before going on the shortlist.",
      careerResignation: "Make a mock resignation request.",
      careerChange: "Explore opportunity change of area or transfer of location.",
      careerPromotion: "Explore promotion opportunity.",
      familyDiscuss: "Discuss with the family.",
      personalPerformanceEvaluation: "Revisit the last performance review.",
      personalEmotionalBonds: "Explore emotional reasons/links with current environment.",
    },
    tooltip: {
      interviewCompleted: "Interview completed",
      pendingInterview: "Pending interview",
    },
  },
  shortlist: {
    about: {
      about: "About",
      historic: "Professional Background",
      current: "Current",
      label: "Uncheck the unwanted trajectory for presentation",
      tooltip: "Professional trajectory can only be changed in the person detail",
      tableDescription: "Shortlist presentation",
    },
    titles: {
      package: "Package",
      career: "Career",
      motivation: "Motivation",
      personal: "Personal",
      environment: "Environment",
      leaveCompany: "FACTORS OF EXPULSION OF THE CURRENT COMPANY:",
      stayInCompany: "CURRENT COMPANY RETENTION FACTORS:",
      companyAttraction: "NEW COMPANY ATTRACTION FACTORS:",
      aboutCandidate: "About candidate",
      academicEducation: "Academic background",
      experience: "Experience",
      realizations: "Achievements",
      attributes: "Attributes",
      culturalMatch: "Cultural Match",
      evaluation: "Candidate evaluation",
      evaluationAbbreviated: "Evaluation",
      finalists: "The Finalists",
    },
    labels: {
      area: "Area",
      level: "Level",
      fieldOfStudy: "Field of study",
      languages: "Languages",
      academicAbbreviated: "Academic background",
      experienceAbbreviated: "Professional experience",
      realizations: "Achievements",
      culturalMatch: "Cultural Match",
      attributes: "Attributes",
      motivation: "Motivation",
      finalScore: "Final score",
      editOpnion: "Edit opnion",
      salary: "Salary (ideal target):",
      bonus: "Bonus:",
      career: "Career Motivation:",
      MotivationEnvironment: "Environment Motivation:",
      leaveCompany: "What would make NAME_CANDIDATE leave your current company?",
      stayInCompany: "What would make NAME_CANDIDATE stay in the current company?",
      companyAttraction: "What would make NAME_CANDIDATE go to the new company?",
      consultantOpnion: "Consultant advice:",
      company: "Company",
      role: "Role",
      name: "Name",
    },
    funnel: {
      title: "Funnel",
      originalNumbers: "Original Numbers",
      originalDescriptions: "Original Descriptions",
      addDescription: "Add description",
    },
    pdf: {
      [ShortlistEnum.FRONT_COVER]: "Front Cover Flow",
      [ShortlistEnum.SUMMARY]: "Summary",
      [ShortlistEnum.COMPANY]: "About the company",
      [ShortlistEnum.IDEAL_PROFILE]: "Perfil Ideal - Persona",
      [ShortlistEnum.IDEAL_PROFILE_TAGS]: "Perfil Ideal - Persona",
      [ShortlistEnum.REALIZATIONS]: "Achievements",
      [ShortlistEnum.MOTIVATION]: "Motivation",
      [ShortlistEnum.SEARCH_FIELD]: "Search Field",
      [ShortlistEnum.PROCESS_FUNNEL]: "Process Funnel",
      [ShortlistEnum.ADHERENCE_TABLE]: "Adherence Table",
      [ShortlistEnum.ADHERENCE_TABLE_FINAL]: "Adherence Table",
      [ShortlistEnum.BACK_COVER]: "Back Cover Flow",
      [ShortlistEnum.COMPANY_PROFILE_TAGS]: "Company Profile",
      [ShortlistEnum.ATTRIBUTES]: "Attributes",
      [ShortlistEnum.CULTURAL_MATCH]: "Cultural Match",
      [ShortlistPersonEnum.USER_CARD_PERSON]: "{user}",
      [ShortlistPersonEnum.USER_ABOUT]: "About {user}",
      [ShortlistPersonEnum.USER_ACADEMIC]: "{user} - Academic Education",
      [ShortlistPersonEnum.USER_EXPERIENCE]: "{user} - Experience",
      [ShortlistPersonEnum.USER_REALIZATIONS]: "{user} - Achievements",
      [ShortlistPersonEnum.USER_ATTRIBUTES]: "{user} - Attributes",
      [ShortlistPersonEnum.USER_CULTURAL_MATCH]: "{user} - Cultural Match",
      [ShortlistPersonEnum.USER_MOTIVATION]: "{user} - Motivation",
      [ShortlistPersonEnum.USER_MOTIVATION_ONE]: "{user} - Motivation",
      [ShortlistPersonEnum.USER_MOTIVATION_TWO]: "{user} - Motivation",
      [ShortlistPersonEnum.USER_MOTIVATION_FACTORS]: "{user} - Motivation",
      [ShortlistPersonEnum.USER_EVALUATION]: "{user} - Evaluation",
    },
    enums: {
      degree: {
        [DEGREE.EMPTY]: "",
        [DEGREE.BACHELOR]: "Undergraduate",
        [DEGREE.POST_BACHELOR]: "Graduate",
        [DEGREE.MBA]: "MBA",
        [DEGREE.MASTER]: "Master",
        [DEGREE.DOCTORATE]: "PHD",
        [DEGREE.POST_DOCTORATE]: "PostDoc",
        [DEGREE.EXTRA_CURRICULAR_COURSE]: "Certificate",
      },
      institution: {
        [INSTITUTION.EMPTY]: "",
        [INSTITUTION.INDIFFERENT]: "Indifferent",
        [INSTITUTION.FIRST_LINE]: "First line",
        [INSTITUTION.PREFERENTIALLY_FIRST_LINE]: "Preferably in a first-line institution",
        [INSTITUTION.MANDATORILY_FIRST_LINE]: "Obligatorily in a first-line institution",
      },
      extension: {
        [EXTENSION_TYPE.POST_BACHELOR]: "Post Bachelor",
        [EXTENSION_TYPE.MBA]: "MBA",
      },
      careerPathOptions: {
        EMPTY: "",
        GREATER_POSITION: "Greater Position",
        GREATER_CAREER: "Greater Career",
        WORK_ABROAD: "Opportunity Abroad",
        WORKPLACE: "Workplace",
      },
      changeJobOptions: {
        EMPTY: "",
        BUSINESS_FUNCTION: "Business Function",
        PEOPLE_PROFILE: "People's Profile",
        COMPANY_CULTURE: "Company Culure",
      },
      reasonsOptions: {
        EARNINGS: "Package",
        REMUNERATION_SYSTEM: "Remuneration System",
        CAREER_LIMITATIONS: "Career Limitations",
        LACK_OF_RELOCATION_OPPORTUNITIES: "Lack of Relocation Opportunity",
        BUSINESS_TYPE: "Business Type",
        FAMILY_SITUATION: "Family Situation",
        BOSS_RELATIONSHIP: "Relationship With the Boss",
        PEERS: "Peers",
        COMPANY_CULTURE: "Company Culture",
        EMPTY: "",
      },
      LanguageProficiencyEnum: {
        EMPTY: "",
        BASIC: "basic",
        BASIC_INT: "basic to intermediate",
        INTERMEDIATE: "intermediate",
        ADVANCED: "advanced",
        FLUENT: "fluent or native",
        ELEMENTARY: "Basic for conversation",
        LIMITED_WORKING: "Intermediate, limited for business",
        PROFESSIONAL_WORKING: "Full business proficiency",
        NATIVE: "Native or bilingual proficiency",
      },
      attributes: {
        [ATTRIBUTE.COGNITIVE_INTELLIGENCE]:
          "Ability to observe people and ideas, identifying and solving problems.",
        [ATTRIBUTE.EMOTIONAL_INTELLIGENCE]:
          "Ability to recognize and deal with one's and others' feelings.",
        [ATTRIBUTE.SPIRITUAL_INTELLIGENCE]:
          "Perceives things in their multiple connections and relationships with ethical values.",
        [ATTRIBUTE.ENERGY]:
          "Ability to act and interact in complex and intense environments and situations.",
        [ATTRIBUTE.MATURITY]: "Ability to understand the “time” and “nature” of things.",
      },
      cultural: {
        [CULTURAL.PRACTICAL]:
          "Has a lot of energy and prefer action to debate. Competitive and result-oriented.",
        [CULTURAL.LOGICAL]:
          "Organized and methodical. Likes to have all the information necessary to make decisions.",
        [CULTURAL.REFLEXIVE]: "Enjoys thinking about the future and creating new scenarios.",
        [CULTURAL.AFFECTIVE]:
          "Structure their actions and decisions influenced by personal relationships.",
      },
    },
  },
  create: {
    title: "Create Project",
    clear: "Clear Profile",
    save: "Save Project",
    clean: "Clean",
    continue: "Continue",
    back: "Back",
    successMessage: "Successfully registered project",
    clearMessage:
      "When clearing, all filled data will be lost, do you want to continue with the action?",
    basicData: {
      title: "Basic Data",
      projectName: "Project Name",
      positionName: "Position Name",
      projectType: {
        label: "Project Type",
        enum: projectTypes,
      },
      corporation: "Company",
      requester: "Requester",
      source: {
        label: "Origin",
        enum: sourceTypes,
      },
      allocatedTeam: {
        label: "Allocated Team",
        enum: allocatedTeam,
        noFound: "no results found",
        selected: "selected",
        Search: "_ Search Person",
      },
      confidentialVacancy: "Confidencial Vacancy?",
      yes: "yes",
      no: "no",
    },
    positionData: {
      title: "Position Data",
      locality: "Local",
      class: {
        label: "Class",
        enum: {
          CLASS_A: "A",
          CLASS_B: "B",
          CLASS_C: "C",
        },
      },
      level: {
        label: "Level",
      },
      salary: {
        label: "Salary",
        enum: {
          CLT: "CLT",
          PJ: "PJ",
        },
        from: "from",
        to: "to",
      },
      bonus: "Bônus",
      annualPackage: "Annual Package",
      about: "About",
    },
    placeholders: {
      projectName: "Project title",
      positionName: "eg.: Gerente de Gente & Gestão",
      corporation: "eg.: LOFT Tecnology",
      requester: "eg.: Requester name",
      select: "Select",
      locality: "eg.: São Paulo, SP, Brasil",
      about: "Resume",
      netWorth: "00,000.00",
      level: "eg.: +1",
    },
  },
  placement: {
    label: "Placement",
    card: {
      btnPlacement: "Set Placement",
      title: "Project concluded",
      position: "Position",
      company: "Company",
      salary: "Salary",
      bonus: "Bonus",
      totalPackage: "Total Package",
      placementStartDate: "Start date",
      placementUpdateWarning: "Update candidate work experience",
      shortTermIncentive: "Short Term Incentive",
      longTermIncentive: "Long Term Incentive",
      otherIncentive: "Other Incentive",
      definePlacement: "Do you confirm the placement of",
    },
    confirm: "Confirm placement of candidate",
    confirmText: "confirm",
    cancelText: "cancel",
  },
  validation: validationPanel,
};
