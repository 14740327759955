<script setup lang="ts">
import Dropdown from "primevue/dropdown";
import { AnnualRevenues, FormInput, Select } from "@/components/inputs/modalInputs";
import { RadioButton } from "@/components/radio";
import { registeredCountries } from "@/utils/adapters/form/consts";
import { updateCorporation } from "@/http_services/entities/corporation";
import type { Corporation } from "@/schemas/corporation";

const props = withDefaults(defineProps<{
  icon?: string
  editing?: boolean
  project?: any
  companyProfile?: any
  corporation?: Corporation
}>(), {
  icon: "project_company_profile",
  editing: false,
  project: {},
  companyProfile: {},
});

const emit = defineEmits(["submit:project", "submit:corporation", "submit:group", "cancel"]);
type Tags = { text: string; selected: boolean }[];

const { project, corporation } = toRefs(props);

const governance: Ref<string[]> = ref([]);
const strategy: Ref<string[]> = ref([]);
const executionConditions: Ref<string[]> = ref([]);

const tags: Ref<{ [key: string]: Tags }> = ref({
  governance: [],
  strategy: [],
  execution_conditions: [],
});

const baseKeysMap = {
  governance,
  strategy,
  execution_conditions: executionConditions,
};

type ConstructionBaseKey = keyof typeof baseKeysMap;

const { t, tm, locale } = useI18n();

interface RevenueModel {
  amount: number
  year: string
  currency: string
  max_amount: number
}

const corporationRevenues: Ref<RevenueModel[]> = ref([]);

const corporationEmployees = ref("501_TO_1000");

const corporationHeadquarters = ref(corporation.value?.governance?.headquarters ?? "BR");

const employeesOptions = Object.entries(tm("corporations.employees.enum")).map(
  ([value, label]) => ({ label, value }),
);

const businessHeadquarters = computed(() =>
  Object.entries(tm("corporations.details.headquarters.enum"))
    .filter(([key]) => key !== "OTHER_WITH_COUNTRY")
    .map(([value, label]) => ({ label, value })));

const localeLang = (locale.value as string).split("-")[0] || "pt-BR";

const selectedHeadquarters = ref(
  corporationHeadquarters.value === "BR" ? corporationHeadquarters.value : "EMPTY",
);
watch(selectedHeadquarters, (value: string) => {
  corporationHeadquarters.value = value !== "EMPTY" ? value : "";
}); // To keep selected on radio buttons

const countriesHeadquarters = computed(() =>
  Object.entries(registeredCountries.getNames(localeLang))
    .filter(([key]) => key !== "BR")
    .map(([value, label]) => ({ label, value })));

const route = useRoute();
const {
  data: construction,
} = useConstructionOrganizationQuery(String(route.params.id));

const constructionId = computed(() => {
  return construction.value?._id;
});

const {
  mutate: patchConstruction,
} = usePatchConstructionOrganizationQuery();

function saveTags(key: ConstructionBaseKey) {
  patchConstruction({
    _id: String(constructionId.value),
    [key]: {
      ...construction.value[key],
      analyses: baseKeysMap[key].value.map(el => ({
        analysis_type: key.toUpperCase(),
        description: el,
      })),
      tags: tags.value[key],
    },
  });
}

async function sendUpdateCorporation() {
  await updateCorporation(
    corporation.value.id,
    {
      corporation: {
        ...corporation.value,
        revenues: corporationRevenues.value,
        number_of_employees: corporationEmployees.value,
        governance: {
          ...corporation.value.governance,
          headquarters: corporationHeadquarters.value,
        },
      },
    },
  );
  emit("submit:corporation");
}

watchEffect(() => {
  if (corporation?.value) {
    corporationRevenues.value = corporation?.value?.revenues;
    corporationEmployees.value = corporation?.value?.number_of_employees;
  }
  if (construction.value) {
    governance.value = construction.value.governance.analyses.map((el: any) => el.description);
    strategy.value = construction.value.strategy.analyses.map((el: any) => el.description);
    executionConditions.value = construction.value.execution_conditions.analyses.map((el: any) => el.description);
    // tags
    tags.value.strategy = toRaw(construction.value.strategy.tags);
    tags.value.governance = toRaw(construction.value.governance.tags);
    tags.value.execution_conditions = toRaw(construction.value.execution_conditions.tags);
  }
});
</script>

<template>
  <div v-if="construction" class="form">
    <div>
      <div class="fields">
        <div class="governance">
          <h1 class="title-section">
            {{ t("projects.details.constructionGuide.tabs.governance") }}
          </h1>
          <div class="form-group form-group--flex">
            <label> {{ t("corporations.details.headquarters.name") }}: </label>
            <RadioButton
              v-for="nation in businessHeadquarters"
              :id="nation.value"
              :key="nation.value"
              v-model="selectedHeadquarters"
              name="headquarters"
              :value="nation.value"
              :label="nation.label"
              @update:model-value="corporationHeadquarters = $event, sendUpdateCorporation()"
            />
            <div class="flex-center">
              <Dropdown
                v-if="corporationHeadquarters !== 'BR'"
                v-model="corporationHeadquarters"
                class="countries"
                :class="[{ ['--not-selected']: false }]"
                option-label="label"
                option-value="value"
                :options="countriesHeadquarters"
                :placeholder="t('text.select')"
                @update:model-value="corporationHeadquarters = $event, sendUpdateCorporation()"
              />
              <!-- <small v-if="v$.governance.headquarters.$error" class="dropdown-error">
                {{ v$.governance.headquarters.$errors[0].$message }}
              </small> -->
              <div v-else>
                <FormInput
                  :placeholder="
                    t(`projects.details.constructionGuide.idealProfile.headquartersLocation`)
                  "
                  @update:model-value="sendUpdateCorporation()"
                />
              </div>
            </div>
          </div>
        </div>
        <TagsSearch
          is-corporation-profile
          :placeholder="
            t('projects.details.activities.constructionPanel.analysisTypes.GOVERNANCE')
          "
          :title="
            t('projects.details.activities.constructionPanel.forms.tags.idealProfile.titleDiv', {
              Title: t('projects.details.activities.constructionPanel.analysisTypes.GOVERNANCE'),
            })
          "
          :tags="tags.governance"
          @update-tag-list="tags.governance = $event, saveTags('governance')"
        />
      </div>
    </div>
    <div>
      <TagsSearch
        is-corporation-profile
        :placeholder="t('projects.details.activities.constructionPanel.analysisTypes.STRATEGY')"
        :title="
          t('projects.details.activities.constructionPanel.forms.tags.idealProfile.titleDiv', {
            Title: t('projects.details.activities.constructionPanel.analysisTypes.STRATEGY'),
          })
        "
        :tags="tags.strategy"
        @update-tag-list="tags.strategy = $event, saveTags('strategy')"
      />
    </div>
    <div>
      <div class="fields">
        <TagsSearch
          is-corporation-profile
          :placeholder="
            t('projects.details.activities.constructionPanel.analysisTypes.EXECUTION_CONDITIONS')
          "
          :title="
            t('projects.details.activities.constructionPanel.forms.tags.idealProfile.titleDiv', {
              Title: t(
                'projects.details.activities.constructionPanel.analysisTypes.EXECUTION_CONDITIONS',
              ),
            })
          "
          :tags="tags.execution_conditions"
          @update-tag-list="tags.execution_conditions = $event, saveTags('execution_conditions')"
        />
      </div>
    </div>
    <div>
      <div class="title">
        {{ t("projects.details.constructionGuide.idealProfile.companySize") }}
      </div>

      <div class="fields field__labelled" />
      <AnnualRevenues
        is-corporation-profile
        :revenues="corporationRevenues"
        @submit:revenues="corporationRevenues = $event"
        @update:revenues="sendUpdateCorporation()"
      />
      <div class="employees">
        <label class="form-label" for="employees">
          {{ t("corporations.details.employeesNumber") }}:
        </label>
        <Select
          id="employees"
          v-model="corporationEmployees"
          :placeholder="t('corporations.details.forms.placeholders.employeesSelect')"
          :options="employeesOptions"
          @update:model-value="sendUpdateCorporation()"
        />
      </div>
    </div>

    <div class="actions">
      <Button type="submit" variation="secondary" @click="$emit('cancel')">
        {{ t("text.form.conclude") }}
      </Button>
      <!-- <Button variation="light" filled @click="$emit('cancel')">
        {{ t("text.form.cancel") }}
      </Button> -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
.form {
  display: grid;
  gap: 2rem 8rem;
  grid-template-columns: repeat(2, 1fr);
  margin-inline: 1.5rem;
  padding-bottom: 1.5rem;
}

.title {
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.6875rem;
  margin-block-end: 0.3563rem;
}

.employees {
  display: flex;
  margin-block: 0.6563rem;
}

.fields {
  > div {
    padding-block: 0.5rem;

    &:first-child {
      padding-block: 0;
    }
  }

  &.field__labelled {
    display: grid;
    margin-right: 4rem;
    gap: 2rem;
    grid-template-columns: 1fr 8fr 1fr;
  }
}

:deep(.select-wrapper) {
  $max-height-select: 1.625rem;

  max-height: $max-height-select;

  .multiselect-wrapper {
    max-height: $max-height-select;
  }

  .multiselect-search {
    max-height: $max-height-select;
    margin-block: 2.1875rem;
  }
}

.countries:deep {
  &.--not-selected {
    border: 1px solid $red;
  }

  .p-dropdown-label {
    padding-block: 0.375rem;
  }

  .p-dropdown-trigger-icon {
    color: $gray-border;
  }
}

.flex-center {
  display: flex;
  flex-direction: column;
}

.form-group {
  position: relative;
  align-items: center;
  margin-bottom: 1rem;

  .form-label {
    font-size: 0.875rem;
  }

  &--grid {
    display: grid;
    grid-template-columns: 1fr 4fr;
  }

  &--flex {
    display: flex;
    gap: 1rem;
  }
}

.title-section{
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.6875rem;
  margin-block-end: 0.3563rem;
  text-transform: uppercase;
}
.actions {
  display: flex;
  gap: 2rem;
}
</style>
