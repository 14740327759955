<script lang="ts" setup>
import { SvgIcon } from "@/components/icon";
import ExportButton from "@/projects/pages/projects/[id]/shortlist/funnel/components/ExportButton.vue";
import type { ProcessFunnelType } from "@/types/project";
import { ShortlistEnum } from "@/schemas/project/pdf";
import { getSortedShortlistCandidates } from "@/projects/pages/projects/[id]/shortlist/adherence-table/utils";
import type { SearchCandidate } from "@/common/composables/useShortlist";

interface StageTabsType { key?: string; name?: string; path?: string; icon?: string; label?: string }

const EditFunnel = defineAsyncComponent(() => import("./components/EditFunnel.vue"));

const route = useRoute();
const router = useRouter();
const projectId = computed(() => String(route.params.id));

const { t } = useI18n();

const editing = ref(false);

const { data: counter, error, isSuccess } = useProjectCounters(String(route.params.id));
const { data: project, refetch } = await useProject(projectId);
const { mutateAsync: getShortlistCandidates } = mutateShortlistCandidates();

const funnel = ref();
const processFunnel = ref();
const processFunnelCounter: Ref<ProcessFunnelType["presentation_counters"] | undefined> = ref();
const processFunnelDescriptions: Ref<ProcessFunnelType["presentation_descriptions"] | undefined> = ref();
const isCounterDefault: Ref<boolean | undefined> = ref();
const isDescriptionsDefault: Ref<boolean | undefined> = ref();

const iconSize = "2.625rem";
const diffDiv = "3.0625rem";
function checkDefaultCounters(defaulted: string) {
  if (processFunnelCounter.value) {
    if (defaulted === "finalists")
      return isCounterDefault.value ? funnel.value.shortlist : (processFunnelCounter.value[defaulted] === 0 ? processFunnelCounter.value[defaulted] = funnel.value.shortlist : processFunnelCounter.value[defaulted]);
    else return isCounterDefault.value ? funnel.value[defaulted] : (processFunnelCounter.value[defaulted] === 0 ? processFunnelCounter.value[defaulted] = funnel.value[defaulted] : processFunnelCounter.value[defaulted]);
  }
}
function checkDescriptionsDefault(defaultIntl: string, defaultKey: string) {
  if (processFunnelDescriptions.value) {
    const descriptions = processFunnelDescriptions.value[defaultKey];
    return isDescriptionsDefault.value
      ? defaultIntl
      : descriptions && descriptions.length !== 0
        ? descriptions
        : (processFunnelDescriptions.value[defaultKey] = defaultIntl, defaultIntl);
  }
}
const listItems = computed(() => {
  return [
    {
      icon: "search_icon",
      counter: checkDefaultCounters("mapping") ?? 0,
      label: checkDescriptionsDefault(t("exportPDF.funnel.resumes.identified"), "mapping"),
      title: t("exportPDF.funnel.labels.identified"),
      key: "mapping",
    },
    {
      icon: "audio_icon",
      counter: checkDefaultCounters("screenings") ?? 0,
      label: checkDescriptionsDefault(t("exportPDF.funnel.resumes.screenings"), "screenings"),
      title: t("exportPDF.funnel.labels.screenings"),
      key: "screenings",
    },
    {
      icon: "forum_icon",
      counter: checkDefaultCounters("interviews") ?? 0,
      label: checkDescriptionsDefault(t("exportPDF.funnel.resumes.interviews"), "interviews"),
      title: t("exportPDF.funnel.labels.interviews"),
      key: "interviews",
    },
    {
      icon: "fact_check_icon",
      counter: checkDefaultCounters("shortlist") ?? 0,
      label: checkDescriptionsDefault(t("exportPDF.funnel.resumes.shortlists"), "shortlist"),
      title: t("exportPDF.funnel.labels.shortlists"),
      key: "shortlist",
    },
    {
      icon: "star_icon",
      counter: checkDefaultCounters("finalists") ?? 0,
      label: checkDescriptionsDefault(t("exportPDF.funnel.resumes.finalists"), "finalists"),
      title: t("exportPDF.funnel.labels.finalists"),
      key: "finalists",
    },
  ];
});

const activitiesTab = computed(() => ({
  key: "activities",
  name: t("projects.details.activities.tabsCandidatesEnum.activities"),
  path: `/projects/${route.params.id}/shortlist/activities`,
}));

const funnelTab = computed(() => ({
  key: "funnel",
  name: t("projects.shortlist.funnel.title"),
  path: `/projects/${route.params.id}/shortlist/funnel`,
}));

const adherenceTableTab = computed(() => ({
  key: "adherenceTable",
  name: t(`projects.shortlist.pdf.${ShortlistEnum.ADHERENCE_TABLE}`),
  path: `/projects/${route.params.id}/shortlist/adherence-table`,
}));

const stageTabs = ref<StageTabsType[]>([activitiesTab.value, funnelTab.value, adherenceTableTab.value]);
const currentRoute = ref(1);

function goTo(tabIndex: number) {
  if (tabIndex >= 0 && stageTabs.value)
    router.push(stageTabs.value[tabIndex]?.path || "#");
}

watchEffect(() => {
  if (isSuccess.value && project.value) {
    processFunnel.value = project.value?.process_funnel;
    processFunnelCounter.value = project.value?.process_funnel?.presentation_counters;
    processFunnelDescriptions.value = project.value?.process_funnel?.presentation_descriptions;
    isCounterDefault.value = project.value?.process_funnel?.presentation_counters?.show_default;
    isDescriptionsDefault.value = project.value?.process_funnel?.presentation_descriptions?.show_default;
    funnel.value = counter.value;
  }
});

watchEffect(() => {
  currentRoute.value = stageTabs.value.findIndex(tab => tab.key === String(route.params.person));
});

getShortlistCandidates(String(route.params.id), {
  onSuccess: async (candidateList: SearchCandidate[]) => {
    if (candidateList)
      stageTabs.value = getSortedShortlistCandidates(String(route.params.id), candidateList, stageTabs.value);
  },
});

function renderBoxTitle({ item }: { item: { counter: number; title: string } }) {
  return h("div", { class: "box-title" }, [
    `${item.counter} ${item.title}`,
  ]);
}
</script>

<template>
  <section class="project-activities">
    <div v-if="error">
      could not load
    </div>
    <div v-if="project" class="project-container">
      <ProjectStages :id="projectId" />
      <div v-if="project" class="project-wrapper">
        <ProjectTabs :stage-tabs="stageTabs" :active="1" class="tabs" @change="goTo($event)" />
        <div class="project-content">
          <ExportButton />
          <div v-if="isSuccess" class="funnel">
            <div class="title">
              <SvgIcon icon="funnel_icon" size="3.25rem" />
              <h2>{{ t('exportPDF.summaryShortlistBeta.processFunnel') }}</h2>
              <Button v-if="!editing" variation="primary" @click="editing = !editing">
                {{ t("text.form.editElement") }}
              </Button>
            </div>
            <EditFunnel
              v-if="editing"
              :id="String(route.params.id)"
              :list-items="listItems"
              :project="project"
              :is-counter-default="isCounterDefault"
              :is-descriptions-default="isDescriptionsDefault"
              @refetch="refetch()"
              @cancel="editing = !editing"
            />
            <template v-else>
              <div v-for="(item, index) in listItems" :key="index" class="box-item">
                <div class="box-count" :class="{ dark: index + 1 === listItems.length }">
                  <div class="box-count-content" :style="{ width: `calc(100% - ${index} * ${diffDiv})` }">
                    <div class="box-icon">
                      <SvgIcon :icon="item.icon" :width="iconSize" :height="iconSize" />
                    </div>
                    <renderBoxTitle :item="item" />
                  </div>
                </div>
                <div class="box-description">
                  <span v-html="item.label" />
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<route lang="yaml">
meta:
  layout: project-stage
  stage: shortlist
  tab: funnel
</route>

<style scoped lang="scss">
.project-activities {
  min-height: 100vh;
}

.project-container {
  padding-top: 3.75rem;
}

.project-wrapper {
  background: white;
  padding-bottom: 2.5rem;
  margin-bottom: 2.5rem;
}

.project-content {
  margin-inline: 1.5rem;
}

.funnel {
  display: flex;
  flex-direction: column;
  gap: 1.375rem;
  margin-inline: 2.25rem;

  .title {
    display: flex;
    align-items: center;
    gap: 0.7rem;
    text-transform: uppercase;
  }

  .box-item {
    display: flex;
    height: 5.625rem;
    flex-direction: row;
    border: 2px solid $gray-x17;
    border-radius: 0.5rem;
    &.edit {
      margin-bottom: 2.25rem;
    }

    .box-count,
    .box-description {
      display: flex;
      flex: 1;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    .box-description {
      line-height: 1.5;
      padding-inline: 2.25rem;

      span {
        line-height: 1.5;
        line-break: anywhere;
      }
    }

    .box-count {
      height: calc(5.625rem - 4px);
      border-radius: 8px;

      &-content {
        display: flex;
        height: 5.625rem;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        background: $gray-x17;
        border-radius: 8px;
        gap: 1rem;

        .box-icon {
          width: v-bind("iconSize");
          height: v-bind("iconSize");
          margin-left: 2.25rem;

          svg {
            color: $yellow;
          }
        }

        .box-title {
          color: $gray-dark;
          font-size: 1.25rem;
          font-weight: bold;
          text-transform: uppercase;
        }
      }
    }

    .box-count.dark {
      .box-count-content {
        background: $gray-dark;

        .box-title {
          color: $white-full;
        }
      }
    }
  }
}

.sidebar-preview {
  :deep(.sidebar-wrapper) {
    width: 1211px;
  }
}

.tab {
  position: relative;
  display: flex;
  height: 2.5rem;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  box-shadow: 0 2px 4px #0d0c0c29;
  color: #ccc;
  font-size: 1rem;
  border: 0;
  background: #fff;
  margin-inline-start: auto;
  margin-block: 1.625rem 1.5rem;

  &:hover {
    border: 2px solid $gray-shade-dark;
  }

  &.export {
    width: 2.5rem;
    border: 1px solid #c0c0c0;
    color: #9b9b9b;
    grid-column-start: -1;

    &:hover {
      border: 1px solid transparent;
      background-color: #f7f7f7;
      box-shadow: none;
      color: $primary;
    }
  }

  &:hover,
  &.checked {
    background: $yellow;
    color: white;
  }

  &:hover {
    .tab-tooltip {
      visibility: visible;
    }
  }

  .tab-tooltip {
    position: absolute;
    bottom: 0;
    padding: 0.25rem;
    border-radius: 0.25rem;
    margin-bottom: -1.5rem;
    box-shadow: 0 2px 4px #0d0c0c29;
    color: $gray-dark;
    font-size: 0.75rem;
    visibility: hidden;
    white-space: nowrap;

    &.hide {
      visibility: hidden;
    }
  }
}

.options-dropdown {
  position: absolute;
  z-index: 99;
  top: 112%;
  right: 0%;
  display: flex;
  flex-direction: column;
  padding: 0.25rem 0;
  background: #fff;
  border-radius: 6px;
  box-shadow: $box-shadow-card;

  .item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0.875rem 0.5rem;
    color: $gray-medium-dark;
    cursor: pointer;
    white-space: nowrap;

    &:hover {
      color: $red-violet;
    }
  }
}
</style>
