<script setup lang="ts">
import debounce from "lodash-es/debounce";
import uniqBy from "lodash-es/uniqBy";
import useVuelidate from "@vuelidate/core";
import { helpers, minLength, required, url } from "@vuelidate/validators";
import TelephoneInput from "@/components/inputs/modalInputs/TelephoneInput.vue";
import { createPerson, searchPersons } from "@/http_services/entities/persons";
import type { Person } from "@/entities/Person/person";
import { SvgIcon } from "@/components/icon";

interface Props {
  activityId: string
}
const props = defineProps<Props>();

const emit = defineEmits(["select:person", "new:person", "error:add", "reload:person"]);
const { t } = useI18n();

const { activityId } = toRefs(props);

const finished = ref(false);
const isLoading = ref(false);
const emptySearch = ref(false);
const loadError = ref(false);
const loading = ref(false);
const searchFinish = ref(false);
const selectedPerson = ref("");
const searchTotal = ref(0);

const {
  isSuccess: successAddCandidate,
  isError: errorAddCandidate,
  mutate: linkCandidate,
} = userAddCandidateToScreening();

const listCandidates = ref<Person[]>([]);

const paginationParams = ref({
  limit: 10,
  skip: 0,
  sort: "updated_at",
  saved_candidates: false,
});

const phoneError = ref<string>("");

const payload = ref({
  name: "",
  linkedin: "",
  code: "+55",
  phone: "",
});

async function getCandidates() {
  isLoading.value = true;
  try {
    emptySearch.value = false;
    loadError.value = false;
    loading.value = true;

    const response = await searchPersons(paginationParams.value);
    const { data, total } = response;
    searchTotal.value = total;
    listCandidates.value = uniqBy([...listCandidates.value, ...data], e => e.id);

    paginationParams.value.skip = listCandidates.value.length;

    if (listCandidates.value.length === 0)
      emptySearch.value = true;

    if (data.length === 0)
      searchFinish.value = true;
    else
      searchFinish.value = false;
  }
  catch {
    loadError.value = true;
  }
  finally {
    loading.value = false;
  }
}

function handleScroll(event: Event) {
  const {
    scrollTop: hiddenHeight,
    scrollHeight: totalHeight,
    clientHeight: totalVisible,
  } = event.target as HTMLElement;
  const bottomReached = hiddenHeight + totalVisible + 10 >= totalHeight;

  if (hiddenHeight && bottomReached && !searchFinish.value)
    getCandidates();
}

function resetPage() {
  searchTotal.value = 0;
  paginationParams.value.skip = 0;
  listCandidates.value = [];
}

function doSearchName() {
  selectedPerson.value = "";
  resetPage();
  paginationParams.value.search_ground = payload.value.name;
  if (payload.value.name.trim().length > 0)
    getCandidates();
}

function doSearchLinkedin() {
  selectedPerson.value = "";
  resetPage();
  paginationParams.value.linkedin_url = payload.value.linkedin;
  getErros("linkedin");
  if (payload.value.linkedin.trim().length > 0)
    getCandidates();
}
async function addCandidate() {
  try {
    linkCandidate({
      activity_id: activityId.value,
      person_id: selectedPerson.value,
    });
  }
  catch (error) {
    emit("error:add");
  }
}

function selectPerson(person_id: string) {
  if (selectedPerson.value === person_id)
    selectedPerson.value = "";
  else
    selectedPerson.value = person_id;
}

function getPersonAvatar(person: Person) {
  return `${person?.avatar_url}?version=${person?._version}`;
}

function getPersonName(person: Person) {
  return `${person?.first_name} ${person?.last_name}`;
}

function getPersonExperience(person: Person) {
  const experiences = person?.work_experience || [];
  return experiences[0];
}

function getPersonInitials(person: Person) {
  const first = person?.first_name?.substring(0, 1);
  const last = person?.last_name?.substring(0, 1);
  return `${first}${last}`;
}

function validatePhones() {
  phoneError.value = "";
  const pattern = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;
  phoneError.value = (pattern.test(payload.value.phone) && payload.value.phone.length >= 14)
    ? ""
    : t("persons.editablePersonForm.phoneError");

  return phoneError.value !== "";
}

const linkedinValidator = helpers.regex(
  /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)\/([-a-zA-Z0-9]+)\/*/,
);

const rules = computed(() => ({
  payload: {
    name: {
      required: helpers.withMessage(t("validator.required"), required),
    },
    linkedin: {
      url: helpers.withMessage(t("validator.url"), url),
      linkedin: helpers.withMessage(t("validator.linkedin"), linkedinValidator),
      required: helpers.withMessage(t("validator.required"), required),
    },
    phone: {
      minLength: helpers.withMessage(t("input.validations.minLength", { length: 14 }), minLength(14)),
    },
  },
}));

const validate = useVuelidate(
  rules,
  { payload },
);

function getErros(key: string) {
  const errors = validate.value.payload[key]?.$silentErrors;
  if (errors?.length > 0)
    return errors[0].$message;
}

async function addNewCandidate() {
  validate.value.payload.$validate();

  if (validate.value.payload.$invalid)
    return;

  const [firstName, ...surname] = (payload.value.name as string).split(" ");

  const formattedPayload = {
    first_name: firstName,
    last_name: surname.join(" "),
    email_addresses: [],
    phones: [{ calling_code: payload.value.code, number: payload.value.phone }],
    linkedin_url: payload.value.linkedin.trim().length === 0 ? null : payload.value.linkedin,
  };

  const result = await createPerson({ person: formattedPayload });

  if (!result?.id)
    return;

  selectedPerson.value = result?.id;
  addCandidate();
}

const debounceSearchName = debounce(doSearchName, 300);
const debounceSearLinkedin = debounce(doSearchLinkedin, 300);

onMounted(() => {
  const mainContent = document.getElementById("list-candidates");
  mainContent?.addEventListener("scroll", async (event: Event) => {
    await handleScroll(event);
  });
});

watchEffect(() => {
  if (errorAddCandidate.value)
    emit("error:add");

  if (successAddCandidate.value) {
    finished.value = true;
    setTimeout(() => {
      emit("reload:person", selectedPerson.value);
    }, 3000);
  }
});
</script>

<template>
  <div v-if="!finished" class="search__candidate">
    <div class="search__candidate--title">
      {{ t("header.createPerson") }}
    </div>
    <div class="search__candidate--form">
      <FormInput
        v-model="payload.name"
        placeholder="Nome Completo"
        :error-message="validate.payload.name.$dirty && getErros('name')"
        @input="validate.payload.name.$touch(), debounceSearchName()"
        @blur="validate.payload.name.$touch()"
      />
      <FormInput
        v-model="payload.linkedin"
        data-test="linkedin"
        placeholder="ex.: https://www.linkedin.com/in/usuario"
        :error-message="validate.payload.linkedin.$dirty && getErros('linkedin')"
        @input="validate.payload.name.$touch(), debounceSearLinkedin()"
        @blur="validate.payload.linkedin.$touch()"
      />
      <div class="form-phone">
        <TelephoneInput
          v-model:countryCode="payload.code"
          v-model:phoneNumber="payload.phone"
          :default-code="payload.code"
          :error-message="getErros('phone')"
          @v-node-updated="validatePhones()"
        />
      </div>
    </div>

    <div class="search__candidate--amount">
      {{ searchTotal }} perfis encontrados com os dados
    </div>
    <div id="list-candidates" class="search__candidate--list" :class="{ empty: listCandidates.length === 0 }">
      <div
        v-for="(person, index) in listCandidates"
        :key="index"
        class="search__candidate--person"
        :class="{ selected: selectedPerson === person.id }"
        @click="selectPerson(person.id)"
      >
        <div class="avatar">
          <img
            v-if="person?.avatar_url"
            :src="getPersonAvatar(person)"
            :alt="getPersonName(person)"
            loading="lazy"
          >
          <p v-else class="initials">
            {{ getPersonInitials(person) }}
          </p>
        </div>
        <div class="details">
          <div class="details-name">
            {{ getPersonName(person) }}
          </div>
          <div class="details-role">
            {{ getPersonExperience(person)?.role_name }}
          </div>
        </div>
      </div>
    </div>
    <div class="search__candidate--actions">
      <Button
        v-if="selectedPerson !== ''"
        class="mapping-candidates-list-buttons"
        variation="secondary"
        @click="addCandidate()"
      >
        VINCULAR CANDIDATO
      </Button>
      <Button
        class="mapping-candidates-list-buttons"
        variation="secondary"
        :disabled="validate?.payload?.$invalid"
        @click="addNewCandidate()"
      >
        ADICIONAR NOVO PERFIL
      </Button>
    </div>
  </div>
  <div v-else class="search__candidate">
    <div class="search__candidate--title">
      {{ t("header.createPerson") }}
    </div>
    <div class="search__candidate--finished">
      <div class="box-icon">
        <div class="icon">
          <SvgIcon
            class="icon-checked"
            icon="icon_checked"
            margin="none"
            width="2rem"
            height="2rem"
          />
        </div>
      </div>
      <div class="message">
        {{ t("projects.screening.labels.linkedCandidateInScreening") }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$avatar_size: 5.5rem;
.search__candidate {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &--title {
        color: $primary;
        font-size: 1.125rem;
        font-weight: bold;
        text-transform: uppercase;
    }

    &--amount {
        width: 100%;
        font-size: 0.875rem;
        text-align: center;
    }

    &--form {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;

        .form-phone {
            display: flex;
        }
    }

    &--list.empty {
      height: auto;
    }

    &--list {
        display: flex;
        height: 24rem;
        flex-direction: column;
        padding-inline-end: 0.7rem;
        gap: 1rem;
        overflow-y: auto;
    }

    &--person {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        gap: 2.8125rem;
        background: $white-background;
        padding-block: 0.5625rem;
        padding-inline: 0.8125rem;
        border: 1px solid transparend;

        .avatar {
            display: flex;
            width: $avatar_size;
            height: $avatar_size;
            justify-content: center;
            align-items: center;
            padding: 0.12rem;
            border: 3px solid $yellow-light;
            border-radius: 100%;
            background: $gray-border;

            img {
                width: $avatar_size;
                height: $avatar_size;
                border-radius: 100%;
            }

            .initials {
                color: #fff;
                font-size: 2rem;
            }
        }

        .details {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 1rem;

            &-name {
                font-weight: bold;
            }

            &-role {
                font-size: 0.875rem;
            }
        }
    }

    &--person.selected {
      border: 1px solid #D80000;
    }

    &--actions {
      display: flex;
      flex-direction: column;
      padding-block: 1rem;
      gap: 1rem;
    }

    &--finished {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-block: 3.5rem;
      gap: 1rem;

      .box-icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0.25rem;
        border-radius: 50%;
        border: 1px solid $yellow;

        .icon {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          width: 4.25rem;
          height: 4.25rem;
          background: $yellow;
          color: white;
        }
      }

      .message {
        color: $gray-dark;
        font-size: 1rem;
        text-transform: uppercase;
        letter-spacing: 0.56px;
      }
    }
}
</style>
