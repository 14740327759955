import { activitiesService } from "@/http_services/client";
import { parseParams } from "@/utils/parsers";

// export async function addTags(business_function: any) {
//   return activitiesAxios.post("constructions/business-functions", { business_function })
//     .then(res => res.data?.data);
// }

export async function getTagsQuery() {
  return activitiesService.get("tags")
    .then(res => res.data?.data);
}
interface PaginationData {
  limit: number
  skip: number
  sort: string[]
  total: number
}
interface Success<T> { data: T }
type SuccessPaged<T> = PaginationData & Success<T>;
interface searchTagSchema {
  id: string
  inserted_at: string
  is_active: boolean
  serial: number
  text: string
  updated_at: string
  user_creator_id: string
}
export async function getTagsSearchQuery(params: {
  tags_text: string
  skip: number
  limit: number
}): Promise<SuccessPaged<searchTagSchema[]>> {
  const parsedParams = parseParams(params);
  return activitiesService.get("/tags/search/", {
    params: { ...parsedParams },
  })
    .then(res => res.data?.data);
}
// export async function removeTags(id: string) {
//   return activitiesAxios.delete(`constructions/business-functions/${id}`)
//     .then(res => res.data?.data);
// }
