<script setup lang="ts">
import type { ProjectCounters } from "@/types/export";

// components
import { SvgIcon } from "@/components/icon";
import ExportPage from "@/common/components/Export/ExportPage.vue";
import type { ProcessFunnelType } from "@/types/project";

const props = withDefaults(
  defineProps<{
    isExport?: boolean
    funnel?: ProjectCounters
    funnelDefault: ProjectCounters
    processFunnel: ProcessFunnelType
  }>(),
  {
    isExport: false,
  },
);

const { t } = useI18n();

const iconSize = "2.625rem";
const diffDiv = "3.0625rem";

const processFunnelCounter: Ref<ProcessFunnelType["presentation_counters"] | undefined> = ref(props.processFunnel?.presentation_counters);
const processFunnelDescriptions: Ref<ProcessFunnelType["presentation_descriptions"] | undefined> = ref(props.processFunnel?.presentation_descriptions);
const isCounterDefault: Ref<boolean | undefined> = ref(props.processFunnel?.presentation_counters?.show_default);
const isDescriptionsDefault: Ref<boolean | undefined> = ref(props.processFunnel?.presentation_descriptions?.show_default);

function checkDefaultCounters(defaulted: string) {
  if (processFunnelCounter.value) {
    if (defaulted === "finalists")
      return isCounterDefault.value ? props.funnelDefault.shortlist : (processFunnelCounter.value[defaulted] === 0 ? processFunnelCounter.value[defaulted] = props.funnelDefault.shortlist : processFunnelCounter.value[defaulted]);
    else return isCounterDefault.value ? props.funnelDefault[defaulted] : (processFunnelCounter.value[defaulted] === 0 ? processFunnelCounter.value[defaulted] = props.funnelDefault[defaulted] : processFunnelCounter.value[defaulted]);
  }
}

function checkDescriptionsDefault(defaultIntl: string, defaultKey: string) {
  if (processFunnelDescriptions.value)
    return isDescriptionsDefault.value ? defaultIntl : (processFunnelDescriptions.value[defaultKey].length > 0 ? processFunnelDescriptions.value[defaultKey] : processFunnelDescriptions.value[defaultKey] = defaultIntl);
}

const listItems = computed(() => {
  return [
    {
      icon: "search_icon",
      counter: checkDefaultCounters("mapping") ?? 0,
      label: checkDescriptionsDefault(t("exportPDF.funnel.resumes.identified"), "mapping"),
      title: t("exportPDF.funnel.labels.identified"),
      key: "mapping",
    },
    {
      icon: "audio_icon",
      counter: checkDefaultCounters("screenings") ?? 0,
      label: checkDescriptionsDefault(t("exportPDF.funnel.resumes.screenings"), "screenings"),
      title: t("exportPDF.funnel.labels.screenings"),
      key: "screenings",
    },
    {
      icon: "forum_icon",
      counter: checkDefaultCounters("interviews") ?? 0,
      label: checkDescriptionsDefault(t("exportPDF.funnel.resumes.interviews"), "interviews"),
      title: t("exportPDF.funnel.labels.interviews"),
      key: "interviews",
    },
    {
      icon: "fact_check_icon",
      counter: checkDefaultCounters("shortlist") ?? 0,
      label: checkDescriptionsDefault(t("exportPDF.funnel.resumes.shortlists"), "shortlist"),
      title: t("exportPDF.funnel.labels.shortlists"),
      key: "shortlist",
    },
    {
      icon: "star_icon",
      counter: checkDefaultCounters("finalists") ?? 0,
      label: checkDescriptionsDefault(t("exportPDF.funnel.resumes.finalists"), "finalists"),
      title: t("exportPDF.funnel.labels.finalists"),
      key: "finalists",
    },
  ];
});
</script>

<template>
  <ExportPage :is-export="isExport" :page-name="t('exportPDF.summaryShortlistBeta.processFunnel')">
    <div class="pdf-view-funnel">
      <div v-for="(item, index) in listItems" :key="index" class="box-item">
        <div class="box-count" :class="{ dark: index + 1 === listItems.length }">
          <div class="box-count-content" :style="{ width: `calc(100% - ${index} * ${diffDiv})` }">
            <div class="box-icon">
              <SvgIcon :icon="item.icon" :width="iconSize" :height="iconSize" />
            </div>
            <div class="box-title">
              {{ item.counter }} {{ item.title }}
            </div>
          </div>
        </div>
        <div class="box-description">
          <span v-html="item.label" />
        </div>
      </div>
    </div>
  </ExportPage>
</template>

<style lang="scss" scoped>
.pdf-view-funnel {
  display: flex;
  flex-direction: column;
  gap: 1.375rem;

  .box-item {
    display: flex;
    height: 5.625rem;
    flex-direction: row;
    border: 2px solid $gray-x17;
    border-radius: 8px;

    .box-count,
    .box-description {
      display: flex;
      flex: 1;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    .box-description {
      line-height: 1.5;
      padding-inline: 2.25rem;

      span {
        line-height: 1.5;
        line-break: anywhere;
      }
    }

    .box-count {
      height: calc(5.625rem - 4px);
      border-radius: 8px;

      &-content {
        display: flex;
        height: 5.625rem;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        background: $gray-x17;
        border-radius: 8px;
        gap: 1rem;

        .box-icon {
          width: v-bind("iconSize");
          height: v-bind("iconSize");
          margin-left: 2.25rem;

          svg {
            color: $yellow;
          }
        }

        .box-title {
          color: $gray-dark;
          font-size: 1.25rem;
          font-weight: bold;
          text-transform: uppercase;
        }
      }
    }

    .box-count.dark {
      .box-count-content {
        background: $gray-dark;

        .box-title {
          color: $white-full;
        }
      }
    }
  }
}
</style>
