<script lang="ts">
import type {
  PropType,
  Ref,
} from "vue";
import {
  computed,
  defineComponent,
  inject,
  ref,
  toRef,
} from "vue";
import { useI18n } from "vue-i18n";
import { find, isString } from "lodash";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { SectionContent } from "@/components/section_content";
import EditIcon from "@/assets/images/edit_icon.svg";
import type { Corporation } from "@/entities/corporation";
import type { BusinessCategory } from "@/entities/common";
import Select from "@/components/inputs/modalInputs/Select.vue";
import { marketRelationship } from "@/entities/corporation/sector";
import Button2 from "@/components/button/Button2.vue";
import { Tag } from "@/components/tag";
import { searchBusinessFunctions } from "@/http_services/entities/common";
import { SearchDropdown } from "@/components/inputs/modalInputs";

interface businessFunction {
  key: string
  label: string
}

export default defineComponent({
  name: "SectorSection",
  components: {
    SectionContent,
    EditIcon,
    Select,
    Button: Button2,
    Tag,
    SearchDropdown,
  },
  props: {
    businessCategories: {
      type: Array as PropType<BusinessCategory[]>,
      required: true,
    },
    businessFunctions: Array<string>,
    isEditing: Boolean,
    noEdit: Boolean,
  },
  emits: ["submit:sector", "click:edit", "click:editCancel"],
  setup(props, { emit }) {
    const { t, tm } = useI18n();
    const corporation = inject("corporation") as Ref<Corporation>;
    const conclaOptions = Object(tm("concla"));
    const computedBusinessFunctions = computed(() => {
      const businessFunctions = props.businessFunctions || [];
      const sliced = businessFunctions.slice(0, 2).map(v => conclaOptions[v] || v);
      return businessFunctions.length > 2 ? sliced.concat("...") : sliced;
    });

    const computedBusinessFunctionsTitle = computed(() => {
      const businessFunctions = props.businessFunctions || [];
      return businessFunctions.map(v => conclaOptions[v] || v).join(", ");
    });

    const computedBusinessFunctionsTitleSep = computed(() => {
      const businessFunctions = props.businessFunctions || [];
      return businessFunctions.map(v => conclaOptions[v] || v).join("/ ");
    });

    const isEditing = toRef(props, "isEditing");

    const firstCategory = computed(() =>
      (props.businessCategories ? props.businessCategories[0] : null));

    const macro = ref(firstCategory.value?.sector);
    const micro = ref(firstCategory.value?.industry);

    const macroOptions = Object(tm("corporations.details.macroMarket.enum"));
    const allMicroOptions = Object(tm("corporations.details.microMarket.enum"));
    const microOptions = computed(() => {
      if (isString(macro.value)) {
        return marketRelationship[macro.value].map(key => ({
          value: key,
          label: allMicroOptions[key],
        }));
      }
      return null;
    });

    const rules = computed(() => ({
      micro: {
        required,
      },
      macro: {
        required,
      },
    }));

    const v$ = useVuelidate(rules, { macro, micro });

    const sectors = ref([...((props.businessFunctions || []) as Array<string>)]);

    const tagSearcher = ref({});
    const localBusinessFunctions: Ref<Array<businessFunction>> = ref([
      ...sectors.value.map(key => ({
        key,
        label: conclaOptions[key] || key,
      })),
    ]);

    const selectBusiness = (value: businessFunction) => {
      const tags = localBusinessFunctions.value.map((item: businessFunction) => item.key);
      if (tags.includes(value.key))
        return;

      localBusinessFunctions.value.push(value);
      tagSearcher.value.data = [];
      tagSearcher.value.ready = false;
    };

    const removeBusiness = (index: number) => {
      find(localBusinessFunctions.value, (item, i) => {
        if (i === index) {
          localBusinessFunctions.value.splice(i, 1);
          return true;
        }
        return false;
      });
    };

    const save = async () => {
      const isValid = await v$.value.$validate();
      if (isValid) {
        const payload = {
          business_categories: [
            {
              sector: macro.value,
              industry: micro.value,
              is_main: true,
            },
          ],
          governance: {
            ...(corporation.value.governance || {}),
          },
          business_function: localBusinessFunctions.value.map(item => item.key),
        };
        emit("submit:sector", payload);
        emit("click:editCancel");
      }
    };

    const editSection = () => {
      emit("click:edit");
      macro.value = firstCategory.value?.sector;
      micro.value = firstCategory.value?.industry;
      v$.value.$reset();
    };

    const titleMargin = computed(() => (!isEditing.value ? "center" : "start"));

    return {
      t,
      searchBusinessFunctions,
      computedBusinessFunctions,
      computedBusinessFunctionsTitle,
      computedBusinessFunctionsTitleSep,
      editSection,
      tagSearcher,
      firstCategory,
      macroOptions,
      macro,
      micro,
      titleMargin,
      save,
      isString,
      microOptions,
      v$,
      sectors,
      selectBusiness,
      removeBusiness,
      localBusinessFunctions,
    };
  },
});
</script>

<template>
  <section class="sector-section">
    <div class="section__content">
      <span v-if="!noEdit" role="button" aria-label="edit">
        <EditIcon v-if="!isEditing" class="edit-icon" @click="editSection" />
      </span>

      <template v-if="!isEditing">
        <SectionContent>
          <div class="section-item">
            <p>
              <b>{{ t("corporations.details.macroMarket.name") }}: </b>
              <template v-if="firstCategory?.sector">
                {{ t(`corporations.details.macroMarket.enum.${firstCategory.sector}`) }}
              </template>
            </p>
          </div>
          <div class="section-item">
            <p>
              <b>{{ t("corporations.details.microMarket.name") }}: </b>
              <template v-if="firstCategory?.industry">
                {{ t(`corporations.details.microMarket.enum.${firstCategory.industry}`) }}
              </template>
            </p>
          </div>
          <div class="section-item">
            <p
              class="text-ellipsis"
              :title="computedBusinessFunctionsTitle"
            >
              <b>{{ t("corporations.details.sector") }}: </b>
              <span v-for="(business, index) in computedBusinessFunctions" :key="index">
                {{ business }}
              </span>
            </p>
          </div>
        </SectionContent>
      </template>

      <template v-else>
        <SectionContent>
          <div class="section-item">
            <p>
              <b>{{ t("corporations.details.macroMarket.name") }}: </b>
              <template v-if="firstCategory?.sector">
                {{ t(`corporations.details.macroMarket.enum.${firstCategory.sector}`) }}
              </template>
            </p>
            <Select
              v-model="macro"
              class="select-input"
              :options="macroOptions"
              :placeholder="t('text.select')"
              :no-feedback="false"
              :error-message="v$.macro.$error ? t('validator.required') : null"
            />
          </div>

          <div class="section-item">
            <p>
              <b>{{ t("corporations.details.microMarket.name") }}: </b>
              <template v-if="firstCategory?.industry">
                {{ t(`corporations.details.microMarket.enum.${firstCategory.industry}`) }}
              </template>
            </p>
            <Select
              v-model="micro"
              class="select-input"
              :options="microOptions"
              :disabled="!isString(macro)"
              :placeholder="t('text.select')"
              :no-feedback="false"
              :error-message="v$.micro.$error ? t('validator.required') : null"
            />
          </div>
          <div class="section-item">
            <p
              class="text-ellipsis"
              :title="computedBusinessFunctionsTitle"
            >
              <b>{{ t("corporations.details.sector") }}: </b>
              <span>{{ computedBusinessFunctionsTitleSep }}</span>
            </p>
            <div>
              <div class="search-control">
                <SearchDropdown
                  fetch-param-key="term"
                  :fetch-method="searchBusinessFunctions"
                  :select-item-callback="selectBusiness"
                  :min-word-length="1"
                  :update-input-model="() => ''"
                  :input-placeholder="t('text.searchBusinessFunction')"
                  :search-not-found-label="t('text.searchBusinessFunctionNotFound')"
                  :search-error-label="t('text.searchBusinessFunctionError')"
                >
                  <template #list-item="{ item }">
                    {{ item.label }}
                  </template>
                </SearchDropdown>
              </div>
              <div class="tags">
                <Tag
                  v-for="(item, index) in localBusinessFunctions"
                  :key="index"
                  :tag="item.label"
                  @click:close="removeBusiness(index)"
                />
              </div>
            </div>
          </div>
        </SectionContent>
        <div class="action-buttons">
          <Button @click="save">
            {{ t("corporations.details.submit") }}
          </Button>
          <Button variation="light" filled @click="$emit('click:editCancel')">
            {{ t("corporations.details.cancel") }}
          </Button>
        </div>
      </template>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.sector-section {
  .section__content {
    position: relative;
    padding: 1.5rem 3rem;
    background-color: $white-background;

    .edit-icon {
      position: relative;
      position: absolute;
      top: 8px;
      right: 14px;
      width: 14px;
      stroke: #ccc;
    }

    .section-content {
      display: flex;
      text-align: v-bind(titleMargin);

      p:not(:first-child, :last-child),
      div:not(:last-child) {
        position: relative;

        &::before {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          border-top: none;
          border-right: 1px solid $gray-dark;
          border-bottom: none;
          content: "";
        }
      }

      p {
        overflow: hidden;
        height: 5ch;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }

      p.text-ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .select-input {
        width: 100%;
      }

      .section-item {
        width: 33.33%;
        height: 100%;
        padding: 0 0.5rem;
      }
    }

    .action-buttons {
      display: flex;
      width: 100%;
      justify-content: center;
      margin-top: 2rem;

      button:first-child {
        margin-right: 1rem;
      }
    }
  }

  .search-control {
    margin-bottom: 1rem;
  }

  .tags {
    .tag {
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }
}
</style>
