<script setup lang="ts">
import { SvgIcon } from "@/components/icon";

const props = withDefaults(defineProps<{
  open: boolean
  closed?: boolean
  clickable?: boolean
  title?: string
  mode?: string
  statusColor?: string | null
  statusLabel?: string
  id?: string
  icon?: string | null
}>(), {
  statusColor: "gray-medium",
});

const emit = defineEmits(["close-all", "selected"]);

const defaultClickable = ref(props.clickable || true);
const defaultOpen = ref(props.open || false);
// const defaultMode = ref(props.mode || "horizontal");

const opened = ref(defaultOpen.value);

watch(
  () => props.closed,
  () => {
    if (props.closed) {
      opened.value = props.open;
      emit("close-all", false);
    }
  },
);
watch(
  () => props.open,
  () => {
    opened.value = props.open;
  },
);

function toggleAccordeon() {
  emit("close-all", true);
  if (defaultClickable.value) {
    opened.value = !opened.value;
    emit("selected", opened.value);
  }
}
</script>

<template>
  <div class="box-accordeon">
    <div :id="id" class="header" @click="toggleAccordeon()">
      <div class="title" :class="{ active: opened }">
        <div v-if="statusColor" class="status" :class="[`--bg-${props.statusColor}`]" :title="statusLabel" />
        <div v-if="icon" class="icon">
          <SvgIcon :icon="icon" width="1.25rem" height="1.25rem" />
        </div>
        <div>
          <p class="mb-0.3" v-html="props.title" />
          <template v-if="opened">
            <slot name="accordion-status-label" />
          </template>
        </div>
      </div>
      <div class="icon-container">
        <SvgIcon
          icon="chevron_icon"
          margin="none"
          width="20"
          height="20"
          :rotate="opened ? '0deg' : '-180deg'"
        />
      </div>
    </div>
    <Transition>
      <KeepAlive>
        <div v-if="opened" class="body">
          <slot />
        </div>
      </KeepAlive>
    </Transition>
  </div>
</template>

<style lang="scss" scoped>
.box-accordeon {
  display: grid;
  margin-block-end: 1rem;

  .header {
    display: flex;
    justify-content: space-between;
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 1.75rem;
    text-transform: uppercase;
    align-items: baseline;
    cursor: pointer;
  }

  .body {
    padding-right: 0;
    padding-inline-start: 0;
    word-break: break-all;
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 100ms ease-out;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.status {
  width: 1rem;
  min-width: 1rem;
  height: 1rem;
  border-radius: 50%;
  margin-right: 0.75rem;
}

.icon {
  margin-right: 0.75rem;
}

.title {
  display: flex;
  align-items: baseline;
  :slotted(.label-ccordion) {
    font-size: 14px;
    text-transform:initial;
    font-weight: 400;
  }
}
.title > p {
  color: $gray-dark;
  user-select: none;
}
.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin colors-change($colors-map) {
  @each $key, $value in $colors-map {
  .status.--bg-#{$key} {
    background: $value;
  }
}
}

@include colors-change($all-colors);
</style>
