<script setup lang="ts">
import { Tooltip } from "@/components/tooltip";
import SearchIcon from "@/assets/images/search_icon.svg";

interface Props {
  cssSelector: string
}

const props = withDefaults(defineProps<Props>(), {
  cssSelector: "table td:not(.counter)",
});

const { t } = useI18n();

const criteria = ref("");
const matchResults = ref<Element[]>([]);
const matchIndex = ref(0);
const searched = ref(false);

const canGoBack = computed<boolean>(() => matchIndex.value > 0 && matchResults.value.length > 0);
const canGoForward = computed<boolean>(() => matchResults.value.length > 0 && matchIndex.value < matchResults.value.length - 1);
const foundText = computed<string>(() => t("projects.validation.simpleSearch.results", { count: matchResults.value.length.toString() }));
const canShowFooterButtons = computed<boolean>(() => (canGoBack.value || canGoForward.value) && !!criteria.value.length);

function search() {
  matchResults.value.forEach(match => match.classList.remove("selected-by-search"));
  if (!criteria.value) {
    matchResults.value = [];
    matchIndex.value = 0;
    return;
  }
  searched.value = true;
  const cells = document.querySelectorAll(props.cssSelector);
  matchResults.value = [];
  matchIndex.value = 0;
  cells.forEach((td) => {
    const text = td.innerText;
    if (text?.toLowerCase()?.includes(criteria.value.toLowerCase()))
      matchResults.value.push(td);
  });

  if (matchResults.value.length > 0) {
    const match = matchResults.value[matchIndex.value];
    match?.classList.add("selected-by-search");
    match && match?.scrollIntoView({ inline: "start", behavior: "smooth", block: "end" });
  }
}

function next() {
  if (matchIndex.value < matchResults.value.length - 1) {
    matchResults.value[matchIndex.value]?.classList.remove("selected-by-search");
    matchIndex.value++;
    const nextMatch = matchResults.value[matchIndex.value];
    nextMatch?.classList.add("selected-by-search");
    nextMatch && nextMatch?.scrollIntoView({ inline: "start", behavior: "smooth", block: "end" });
  }
}

function back() {
  if (matchIndex.value > 0) {
    matchResults.value[matchIndex.value]?.classList.remove("selected-by-search");
    matchIndex.value--;
    const prevMatch = matchResults.value[matchIndex.value];
    prevMatch?.classList.add("selected-by-search");
    prevMatch && prevMatch?.scrollIntoView({ inline: "start", behavior: "smooth", block: "end" });
  }
}

function closingPopover() {
  if (matchResults.value.length) {
    const actualMatch = matchResults.value[matchIndex?.value];
    actualMatch?.classList.remove("selected-by-search");
  }
  criteria.value = "";
  matchResults.value = [];
  matchIndex.value = 0;
  searched.value = false;
}

watch(criteria, (val) => {
  if (!val.length)
    searched.value = false;

  search();
});

const input = ref<HTMLElement>();

function openPopover() {
  setTimeout(() => input?.value?.focus(), 200);
}
</script>

<template>
  <Popover
    :close-on-blur="false"
    class="search-popover"
    close-button-class-name="close-button"
    @close="closingPopover"
    @open="openPopover"
  >
    <template #trigger>
      <a class="search-btn">
        <Tooltip
          :tooltip-text="t('projects.validation.simpleSearch.tooltip')"
          tooltip-width="max-content"
          x-align="left"
          y-align="bottom"
        >
          <SearchIcon class="search-icon" />
        </Tooltip>
      </a>
    </template>
    <template #content>
      <div class="card">
        <div class="card-header">
          <b><span class="card-title" v-text="t('projects.validation.simpleSearch.title')" /></b>
        </div>

        <div class="card-body">
          <div class="search-container">
            <FormInput ref="input" v-model="criteria" input-type="search" @keyup.enter="next">
              <template #preIcon>
                <SearchIcon class="input-pre-icon" />
              </template>
            </FormInput>
            <input v-if="false" v-model="criteria" type="text" @keyup.enter="search">
            <span :class="{ visible: searched, hidden: !searched }" v-text="foundText" />
          </div>
        </div>

        <div class="card-footer" :class="{ hidden: !canShowFooterButtons }">
          <Button
            variation="secondary"
            :disabled="!canGoBack"
            @click="back"
          >
            {{ t('projects.validation.simpleSearch.prev') }}
          </Button>
          <Button
            variation="secondary"
            :disabled="!canGoForward"
            @click="next"
          >
            {{ t('projects.validation.simpleSearch.next') }}
          </Button>
        </div>
      </div>
    </template>
  </Popover>
</template>

<style scoped lang="scss">
.search-btn {
  margin-right: 1em;

  .search-icon {
    width: 1.2em !important;
    height: 1.2em !important;
  }

  &:hover {
    color: $red-violet;
    cursor: pointer;
  }
}

.input-pre-icon {
  width: 1.2em !important;
  height: 1.2em !important;
  margin-left: 0.3rem;
  stroke: $gray-border;
}

:global(button:has(.close-button)) {
  border: 0;
  outline: none;
  box-shadow: none;
}

:global(.close-button) {
  color: $gray-dark;
}

:global(.close-button:hover) {
  color: $red-violet;
}

:global(.selected-by-search) {
  border: 2px solid $red-violet;
}

.card {
  padding: 1rem;

  .card-title {
    text-transform: uppercase;
  }

  .card-body {
    padding-top: 0.8rem;

    .search-container {
      display: flex;
      flex-direction: column;
      align-items: end;

      input {
        width: 20rem;
        height: 1.5rem;
      }

      span {
        font-size: 0.75rem;
        opacity: .6;

        &.visible {
          visibility: visible;
        }

        &.hidden {
          visibility: hidden;
        }
      }
    }
  }

  .card-header {
    display: flex;
    justify-content: center;
  }

  .card-footer {
    padding-top: 1rem;
    display: flex;
    justify-content: center;
    gap: 2rem;

    :deep(button) {
      width: 7rem;
    }
  }
}

:deep(.tooltip-message) {
  text-transform: uppercase;
  font-weight: bold;
}

:deep(.input-wrapper > input) {
  width: 20rem;
}

.hidden {
  visibility: hidden;
}

:deep(input[type="search"]::-webkit-search-cancel-button) {
  -webkit-appearance: none;
  background-color: $gray-border;
  -webkit-mask-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23777'><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/></svg>");
  background-size: 20px 20px;
  height: 20px;
  width: 20px;

  &:hover{
    background-color: $gray-dark;
  }
}
</style>
