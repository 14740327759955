import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import type { CorporationHeaderPatch } from "@/http_services/entities/corporation";
import { getCorporation, getEducationalOrganization, patchCorporationHeader, patchEducationalOrganization } from "@/http_services/entities/corporation";

export function useCorporation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["corporation"],
    mutationFn: (id: string) => getCorporation((id)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["corporation"] });
    },
  });
}
export function usePatchEducationalOrganization() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["educational"],
    mutationFn: (body: { id: string; educational_organization: educational_organization }) => patchEducationalOrganization(body.id, { educational_organization: body.educational_organization }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["educational"] });
    },
  });
}
export function useEducationalOrganization(id: string) {
  return useQuery({
    queryKey: ["projects", unref(id)],
    queryFn: () => getEducationalOrganization(unref(id)),
  });
}
export function usePatchCorporationHeader() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["corporation"],
    mutationFn: (body: { id: string; corporation_header_patch: CorporationHeaderPatch }) => patchCorporationHeader(body.id, { corporation_header_patch: body.corporation_header_patch }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["corporation"] });
    },
  });
}

interface educational_organization {
  abbreviation?: string
  corporation_type?: "PUBLIC" | "PRIVATE"
  first_line?: boolean
  corporation_id?: string
  user_creator_id?: string
  _version?: string
}
