<script lang="ts">
import { defineComponent, ref } from "vue";
import SvgIcon from "@jamescoyle/vue-icon";
import { TooltipBox } from "@/components/tooltip";

export default defineComponent({
  name: "Input",
  components: {
    SvgIcon,
    TooltipBox,
  },
  props: {
    error: Boolean,
    errorMessage: String,
    placeholder: String,
    autoFill: Boolean,
    autoFillColor: {
      required: false,
      type: String,
      default: "#c23584",
    },
    autocomplete: String,
    onFocus: Function,
    onBlur: Function,
    onEnter: Function,
    onKeyDown: Function,
    onKeyPress: Function,
    onClickIcon: Function,
    inputType: String,
    icon: String,
    preIcon: String,
    disabled: Boolean,
    id: String,
    modelValue: [String, Number],
    tooltipShow: {
      type: Boolean,
      default: false,
    },
    tooltipMesage: String,
    tooltipColor: String,
    tooltipBackground: String,
  },
  emits: ["update:modelValue"],
  setup(props) {
    const showTooltip = ref(false);
    return {
      showTooltip,
      borderColor: props.autoFillColor,
    };
  },
});
</script>

<template>
  <div
    class="component-input"
    @mouseover="showTooltip = true"
    @mouseout="showTooltip = false"
  >
    <TooltipBox
      :show="tooltipShow && showTooltip"
      :color="tooltipColor"
      :background="tooltipBackground"
      :mesage="tooltipMesage"
    />
    <div
      class="input-wrapper"
      :class="{ error: error || errorMessage, auto_fill: autoFill }"
      tabindex="1"
      data-test="input-wrapper"
      @focus="onFocus"
      @blur="onBlur"
    >
      <div class="icon-wrapper pre-icon">
        <SvgIcon
          v-if="preIcon"
          type="mdi"
          data-test="icon"
          :path="preIcon"
          @click="onClickIcon"
        />
        <slot name="preIcon" />
      </div>
      <input
        :id="id"
        :placeholder="placeholder"
        :autocomplete="autocomplete"
        :type="inputType"
        :disabled="disabled"
        :value="modelValue"
        data-test="input"
        @focus="onFocus"
        @blur="onBlur"
        @keydown="onKeyDown"
        @keypress="onKeyPress"
        @keypress.enter="onEnter"
        @input="$emit('update:modelValue', $event.target.value)"
      >
      <div class="icon-wrapper">
        <SvgIcon
          v-if="icon"
          type="mdi"
          data-test="icon"
          :path="icon"
          @click="onClickIcon"
        />
        <slot name="icon" />
      </div>
    </div>
    <div v-show="errorMessage" class="error-wrapper" data-test="message-error">
      <span>{{ errorMessage }}</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.component-input {
  // width: fit-content;

  .input-wrapper {
    position: relative;
    display: grid;
    width: 100%;
    height: 48px;
    box-sizing: border-box;
    border: none;
    border-radius: 10rem;
    background-color: white;
    grid-template-columns: auto 1fr auto;
    transition: background 1s ease-in;

    &.error {
      border: 1px solid $red-warning;
    }

    &.auto_fill {
      border: 2px solid v-bind('autoFillColor');
    }

    .icon-wrapper {
      display: flex;
      width: fit-content;
      align-items: center;
      justify-content: center;

      svg {
        color: #c4c4c4;

        &:hover {
          cursor: pointer;
        }
      }
    }

    input {
      display: block;
      min-width: 0;
      height: 100%;
      padding: 0 1rem;
      border: none;
      background: transparent;
      color: grey;
      font-size: 14px;
      font-weight: 500;
      outline: none;

      &::placeholder {
        color: $gray-medium;
      }
    }
  }

  .error-wrapper {
    display: flex;
    width: 100%;
    height: 10px;
    justify-content: flex-end;

    span {
      color: $red-warning;
      font-size: 10px;
      font-weight: 500;
    }
  }
}
</style>
