<script setup lang="ts">
import type { Ref } from "vue";
import { inject, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import cloneDeep from "lodash-es/cloneDeep";
import isEmpty from "lodash-es/isEmpty";
import omitBy from "lodash-es/omitBy";
import set from "lodash-es/set";
import { getOr, pipe } from "lodash/fp";
import { Button as ButtonDefault } from "@/components/button";
import TrashIcon from "@/assets/images/trash_icon.svg";
import SchoolIcon from "@/assets/images/school.svg";
import type { EducationPayload, PersonPayload } from "@/entities/Person/form";
import { AcademicFormItem, CertificationFormItem, LanguageFormItem } from "@/persons/pages/persons/components/forms";
import { Avatar } from "@/components/avatar";

interface PropsType {
  visible: boolean
  onSubmit: () => void
  onCancel: () => void
}

const props = defineProps<PropsType>();

const { t } = useI18n();
const person = inject("personPayload") as Ref<PersonPayload>;
const payload = ref({
  education: cloneDeep(person.value.education),
  certifications: cloneDeep(person.value.certifications),
  languages: cloneDeep(person.value.languages),
}) as Ref<PersonPayload>;

function appendEducation() {
  payload.value.education.push({
    course_title: undefined,
    degree_level: undefined,
    institution: undefined,
    start_date: undefined,
    end_date: undefined,
  });
}

function appendCertifications() {
  payload.value.certifications.push({
    name: "",
    institution: { name: "", corporation: "" },
    field: "",
    expedition_date: null,
    expiration_date: null,
    is_lifelong: false,
  });
}

function appendLanguages() {
  payload.value.languages.push({
    language: "",
  });
}

const typeMap = {
  education: () => appendEducation(),
  certifications: () => appendCertifications(),
  languages: () => appendLanguages(),
};

function add(type: "education" | "certifications" | "languages") {
  typeMap[type]();
}

function removeItem(type: "education" | "certifications" | "languages", index: number) {
  payload.value[type].splice(index, 1);
}

function removeEmptyValuesFromEducation(education: EducationPayload[]) {
  return education?.map(edu => omitBy(edu, isEmpty));
}

function filterEmptyObjectInEducation(education: EducationPayload[]) {
  return education.filter(edu => !isEmpty(edu));
}

function handleSubmit() {
  const education = pipe(
    getOr([], "education"),
    removeEmptyValuesFromEducation,
    filterEmptyObjectInEducation,
  )(payload?.value);

  set(payload.value, "education", education);

  props.onSubmit && props.onSubmit(payload.value);
}

const avatarVersion = ref(0);
function reloadAvatar(data: any, institution: any) {
  data.institution = {
    ...data.institution,
    corporation_data: {
      ...data.institution.corporation_data,
      avatar_url: institution?.corporation_data?.avatar_url,
    },
  };

  avatarVersion.value += 1;
}

onMounted(() => {
  if (!payload.value.education.length)
    add("education");
});
</script>

<template>
  <div class="academic-formation-group">
    <div class="editable-forms">
      <template v-for="(education, index) in payload.education" :key="education">
        <div class="group-wrapper">
          <div class="avatar-icon">
            <Avatar
              v-if="education.institution?.corporation_data?.avatar_url"
              :key="avatarVersion"
              :src="education.institution?.corporation_data?.avatar_url"
              :alt="education.institution?.name"
              size="71px"
              placeholder="school"
              type="native"
              :has-alternative-icon="false"
              image-size="medium"
            />
            <SchoolIcon v-else class="icon" />
          </div>
          <div class="course-wrapper">
            <div class="remove-wrapper">
              <TrashIcon @click="removeItem('education', index)" />
            </div>
            <AcademicFormItem :education="education" @add:institution="reloadAvatar(education, $event)" />
          </div>
        </div>
      </template>

      <div class="group-wrapper mb-1">
        <div />
        <ButtonDefault :text="t('persons.forms.academic.addEducation')" @click="add('education')" />
      </div>

      <div class="group-wrapper">
        <div />
        <div class="title-wrapper">
          <span>{{ t("persons.certifications") }}</span>
        </div>
      </div>

      <template v-for="(certification, index) in payload.certifications" :key="certification">
        <div class="group-wrapper">
          <div />
          <div class="course-wrapper">
            <div class="remove-wrapper">
              <TrashIcon @click="removeItem('certifications', index)" />
            </div>
            <CertificationFormItem :certification="certification" />
          </div>
        </div>
      </template>

      <div class="group-wrapper mb-1">
        <div />
        <ButtonDefault
          :text="t('persons.certificationForm.addCertification')"
          @click="add('certifications')"
        />
      </div>

      <div class="group-wrapper">
        <div />
        <div class="title-wrapper">
          <span>{{ t("persons.details.languages") }}</span>
        </div>
      </div>

      <template v-for="(language, index) in payload.languages" :key="language">
        <div class="group-wrapper">
          <div />
          <div class="course-wrapper">
            <div class="remove-wrapper">
              <TrashIcon @click="removeItem('languages', index)" />
            </div>
            <LanguageFormItem :language="language" />
          </div>
        </div>
      </template>

      <div class="group-wrapper mb-1">
        <div />
        <ButtonDefault :text="t('persons.forms.language.addLanguage')" @click="add('languages')" />
      </div>
    </div>

    <div class="edit-actions">
      <ButtonDefault
        class="mb-1 save-button"
        :text="t('persons.details.submit')"
        @click="handleSubmit()"
      />
      <ButtonDefault variation="secondary" :text="t('persons.details.cancel')" @click="onCancel()" />
    </div>
  </div>
</template>

<style scoped="true" lang="scss">
.mb-1 {
  margin-bottom: 1rem;
}

.group-wrapper {
  display: grid;
  gap: 15px;
  grid-template-columns: 65px 1fr;

  .avatar-icon {
    display: flex;
    width: 71px;
    height: 71px;
    align-items: center;
    justify-content: center;
    border-radius: 150px;
    background: #cecfd0;
    cursor: pointer;
  }

  .avatar-icon:hover > .update-icon {
    visibility: visible;
  }

  .update-icon {
    position: absolute;
    visibility: hidden;
  }
}

.title-wrapper {
  display: flex;
  width: 100%;

  span {
    color: $primary;
    font-family: "Open Sans";
    font-size: 18px;
  }
}

.title-wrapper > span {
  padding-bottom: 10px;
}

.edit-icon {
  position: relative;
  top: -4px;
  width: 14px;
  margin-left: 0.3rem;
  cursor: pointer;
  stroke: $gray-border;
}

.academic-formation:last-child {
  margin-bottom: 5px;
}

.academic-formation-group {
  display: flex;

  .academic-formation {
    padding: 15px 0;
  }

  .editable-forms {
    flex-grow: 1;
    margin-right: 7.5%;
  }

  .edit-actions {
    display: flex;
    flex-direction: column;
  }

  .items-wrapper .extra-activities {
    border-top: 1px solid $gray-light;

    &:first-of-type {
      border-top: 0;
    }
  }

  .remove-wrapper {
    display: flex;
    height: 35px;
    justify-content: flex-end;

    svg {
      height: 18px;
      stroke: $gray-light;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.save-button {
  padding-right: 1rem;
  padding-left: 1rem;
  white-space: nowrap;
}
</style>
