<script setup lang="ts">
import last from "lodash-es/last";
import ExportPage from "./ExportPage.vue";
import Resume from "./Resume.vue";
import { paginateTextList, paginateTexts } from "@/utils/text";

const props = withDefaults(
  defineProps<{
    isExport: boolean
    pageName?: string | null
    analyses: Analysis[] | null
    opinion?: string | null
    bullet?: boolean
  }>(),
  {
    pageName: null,
    analyses: null,
    bullet: true,
    isExport: false,
  },
);

enum ANALYSIS_TYPES {
  STRATEGY = "STRATEGY",
  GOVERNANCE = "GOVERNANCE",
  EXECUTION_CONDITIONS = "EXECUTION_CONDITIONS",
  IDEAL_PROFILE = "IDEAL_PROFILE",
  REALIZATIONS = "REALIZATIONS",
}

interface Analysis {
  analysis_type: ANALYSIS_TYPES
  description: string
  description_short: string
  insight: string
}

interface textPageArrayType {
  score?: number
  break: boolean
  text: string
}

interface PagesTypes {
  analyses: textPageArrayType[] | null
  opinion: string | null
}

const { isExport, pageName, analyses, opinion, bullet } = toRefs(props);

const pages = ref<PagesTypes[]>([]);

function paginatePages() {
  pages.value = [];
  pages.value[0] = { analyses: [], opinion: null };

  const paginateAnalyses = paginateTextList(
    analyses.value?.map(item => item.description) || [],
    "14px",
    990,
    30,
  );

  const lastAnalyses = last(paginateAnalyses);

  const paginateOpinion = paginateTexts(
    opinion?.value || "",
    "14px",
    903,
    25,
    lastAnalyses?.lines,
  );

  paginateAnalyses.forEach((item, index) => {
    if (!pages.value[index])
      pages.value[index] = { analyses: [], opinion: null };

    pages.value[index].analyses = item.texts;
  });

  const pagesLength = pages.value.length - 1;
  let lastPageIndex = pagesLength < 0 ? 0 : pagesLength;
  paginateOpinion.forEach((item, index) => {
    if (!pages.value[lastPageIndex])
      pages.value[lastPageIndex] = { analyses: [], opinion: null };

    pages.value[lastPageIndex].opinion = item.text;

    if (index + 1 < paginateOpinion.length)
      lastPageIndex++;
  });
}

watchEffect(() => {
  paginatePages();
});
</script>

<template>
  <ExportPage
    v-for="(page, index) in pages"
    :key="index"
    :page-index="index"
    :is-export="isExport"
    :page-name="pageName"
    :opinion="page.opinion"
    v-bind="$attrs"
  >
    <div class="pdf-analyses">
      <Resume
        :show-title="false"
        :show-label="false"
        :show-circle="bullet"
        font-size="0.875rem"
        line-height="1.375rem"
        :items="page.analyses?.map((item) => ({
          value: item.text,
          bullet: index === 0 && item.text === '' ? true : !item.break,
        }))"
      />
    </div>
  </ExportPage>
</template>

<style lang="scss" scoped>
.pdf-analyses {
  display: flex;
  flex-direction: column;
}
</style>
