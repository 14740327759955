<script setup lang="ts">
import { SvgIcon } from "@/components/icon";
import { addCompanyProfileLines } from "@/projects/pages/projects/[id]/construction/ideal-profile/utils/companyProfileSvgLines";

interface ListItemTyp {
  id: string
  key: string
  icon: string
  title: string
  items: string[]
}

interface ListType {
  left: ListItemTyp[]
  right: ListItemTyp[]
}

const props = withDefaults(
  defineProps<{
    list: ListType
    icon: string
    width?: string
    height?: string
    fontSize?: number
    colHeight?: string
    gap?: number
    sizeMiddle?: number
  }>(),
  {
    width: "1010px",
    height: "165mm",
    fontSize: 16,
    gap: 3.5,
    sizeMiddle: 9,
    colHeight: "auto",
  },
);

const { list, icon, width, height, gap, fontSize, sizeMiddle, colHeight } = toRefs(props);

const sizes = computed(() => ({
  size: `${fontSize.value}rem`,
  gap: `${gap.value}rem`,
  middle: `${sizeMiddle.value}rem`,
  maxMiddle: `${sizeMiddle.value + 2}rem`,
  iconMiddle: `${sizeMiddle.value - 4}rem`,
  colHeight: colHeight.value,
}));
const containerSvg = ref<HTMLElement | null>(null);
const governanceUnderline = ref<HTMLElement | null>(null);
const strategyUnderline = ref<HTMLElement | null>(null);
const strategyLineSvg = ref<HTMLElement | null>(null);
const governanceLineSvg = ref<HTMLElement | null>(null);
const executionConditionsLineSvg = ref<HTMLElement | null>(null);
const companySizeLineSvg = ref<HTMLElement | null>(null);
const executionConditionsUnderline = ref<HTMLElement | null>(null);
const companySizeUnderline = ref<HTMLElement | null>(null);

const debouncedFn = useDebounceFn(() => {
  addCompanyProfileLines({
    containerSvg,
    strategyUnderline,
    strategyLineSvg,
    governanceLineSvg,
    governanceUnderline,
    executionConditionsUnderline,
    companySizeUnderline,
    companySizeLineSvg,
    executionConditionsLineSvg,
    titleHeight: 32,
  });
}, 50);

onMounted(() => debouncedFn());
</script>

<template>
  <div
    id="profile-container"
    class="tags-preview"
    @vue:updated="debouncedFn()"
  >
    <hr ref="governanceUnderline" class="svg-connection">
    <hr ref="strategyUnderline" class="svg-connection gap-correction">
    <hr ref="executionConditionsUnderline" class="svg-connection">
    <hr ref="companySizeUnderline" class="svg-connection gap-correction">
    <svg id="svg">
      <line ref="strategyLineSvg" class="line" />
      <line ref="governanceLineSvg" class="line" />
      <line ref="executionConditionsLineSvg" class="line" />
      <line ref="companySizeLineSvg" class="line" />
    </svg>
    <div class="box-tags">
      <div class="box-tags-left">
        <div
          v-for="(item, index) in list.left"
          :id="item.key"
          :key="index"
          class="box-item"
          :style="{ height: `${sizes.colHeight}` }"
        >
          <div class="box-item-head">
            <div class="box-title">
              <div class="box-icon">
                <SvgIcon
                  :icon="item.icon"
                  margin="none"
                  color="#FFB600"
                  width="1.1875rem"
                  height="1.1875rem"
                />
              </div>
              <div class="box-text">
                {{ item.title }}
              </div>
            </div>
          </div>
          <div class="item-content">
            <div v-for="(data, id_data) in item.items" :key="id_data" class="list">
              <div v-if="data !== '__space__'" class="list-item">
                <div class="list-circle" />
                <div class="list-value">
                  {{ data }}
                </div>
              </div>
              <div v-else class="list-item space" />
            </div>
          </div>
        </div>
      </div>
      <div id="iconMiddleBox" ref="containerSvg" class="box-tags-middle">
        <div class="box-icon">
          <SvgIcon
            :icon="icon"
            margin="none"
            color="#FFB600"
            :width="sizes.iconMiddle"
            :height="sizes.iconMiddle"
          />
        </div>
      </div>
      <div class="box-tags-right">
        <div
          v-for="(item, index) in list.right"
          :id="item.key"
          :key="index"
          class="box-item"
          :style="{ height: `${sizes.colHeight}` }"
        >
          <div class="box-item-head">
            <div class="box-title">
              <div class="box-icon">
                <SvgIcon
                  :icon="item.icon"
                  margin="none"
                  color="#FFB600"
                  width="1.1875rem"
                  height="1.1875rem"
                />
              </div>
              <div class="box-text">
                {{ item.title }}
              </div>
            </div>
          </div>
          <div class="item-content">
            <div v-for="(data, id_data) in item.items" :key="id_data" class="list">
              <div v-if="data !== '__space__'" class="list-item">
                <div class="list-circle" />
                <div class="list-value">
                  {{ data }}
                </div>
              </div>
              <div v-else class="list-item space" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.box-tags-middle .box-icon {
  z-index: 2;
}
#svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  line {
    stroke-width: 1px;
    stroke: $gray-border;
  }
}

.svg-connection {
  position: absolute;
  width: 22rem;
  border: 0;
  height: 1px;
  background: $gray-border;
  &.gap-correction {
    margin-top: 2.5rem;

  }
}

.tags-preview {
  position: relative;
  display: flex;
  flex-direction: column;

  .box-tags {
    position: relative;
    display: flex;
    width: v-bind("width");
    min-height: v-bind("height");
    flex-direction: row;
    gap: v-bind("sizes.gap");

    .box-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      background: $white-background;
      border-radius: 50%;
      box-shadow: 0 2px 4px #00000029;
    }

    &-left,
    &-right {
      position: relative;
      display: flex;
      flex: 1;
      flex-direction: column;

      .box-item {
        display: flex;
        flex-direction: column;
        margin-block-end: 2rem;

        .box-item-head {
          display: flex;
          flex-direction: column;
          margin-block-end: 0.62rem;

          .box-title {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 0.75rem;

            .box-icon {
              width: 2rem;
              min-width: 2rem;
              height: 2rem;
              min-height: 2rem;
            }

            .box-text {
              color: $gray-dark;
              font-size: 1.25rem;
              font-weight: bold;
              letter-spacing: 0.5px;
              line-height: 1.5;
              text-transform: uppercase;
            }
          }

          .head-line {
            height: 1px;
            background: $gray-border;
            margin-block: 0.625rem;
          }
        }

        .item-content {
          position: relative;
          display: flex;
          flex-direction: column;
          padding-left: 0.8rem;
          gap: 0.5rem;
          margin-block-start: 0.62rem;

          .list {
            display: flex;
            flex-direction: column;

            .list-item.space {
              height: 1.5rem;
            }

            .list-item {
              display: flex;
              flex-direction: row;
              gap: 0.5rem;

              .list-circle {
                width: 0.5rem;
                min-width: 0.5rem;
                height: 0.5rem;
                min-height: 0.5rem;
                margin: 0.3rem 0;
                background: $yellow;
                border-radius: 50%;
              }

              .list-value {
                color: $gray-dark;
                font-size: 0.875rem;
                line-height: 1.3;
              }
            }
          }
        }
      }
    }

    &-middle {
      display: flex;
      max-width: v-bind("sizes.maxMiddle");
      flex: 1;
      align-items: center;
      justify-content: center;

      .box-icon {
        width: v-bind("sizes.middle");
        min-width: v-bind("sizes.middle");
        height: v-bind("sizes.middle");
        min-height: v-bind("sizes.middle");
      }
    }
  }
}
</style>
