<script lang="ts">
import { defineComponent, ref } from "vue";
import SvgIcon from "@jamescoyle/vue-icon";

export default defineComponent({
  name: "Input",
  components: {
    SvgIcon,
  },
  props: {
    error: Boolean,
    errorMessage: String,
    placeholder: String,
    onFocus: Function,
    onBlur: Function,
    onEnter: Function,
    onKeyDown: Function,
    onKeyPress: Function,
    onClickIcon: Function,
    inputType: String,
    icon: String,
    preIcon: String,
    disabled: Boolean,
  },
  setup() {
    const content = ref<string>("");
    const clear = () => (content.value = "");

    const setContent = (value: string) => {
      content.value = value;
    };

    return { content, clear, setContent };
  },
});
</script>

<template>
  <div class="component-input">
    <div
      class="input-wrapper"
      :class="{ error: error || errorMessage }"
      tabindex="1"
      data-test="input-wrapper"
      @focus="onFocus"
      @blur="onBlur"
    >
      <div class="icon-wrapper pre-icon">
        <SvgIcon
          v-if="preIcon"
          type="mdi"
          data-test="icon"
          :path="preIcon"
          @click="onClickIcon"
        />
        <slot name="preIcon" />
      </div>
      <input
        v-model="content"
        :placeholder="placeholder"
        :type="inputType"
        data-test="input"
        :disabled="disabled"
        @focus="onFocus"
        @blur="onBlur"
        @keydown="onKeyDown"
        @keypress="onKeyPress"
        @keypress.enter="onEnter"
      >
      <div class="icon-wrapper">
        <SvgIcon
          v-if="icon"
          type="mdi"
          data-test="icon"
          :path="icon"
          @click="onClickIcon"
        />
        <slot name="icon" />
      </div>
    </div>
    <div class="error-wrapper" data-test="message-error">
      <span>{{ errorMessage }}</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.component-input {
  width: fit-content;

  .input-wrapper {
    position: relative;
    display: grid;
    width: 100%;
    height: 48px;
    box-sizing: border-box;
    padding: 0 1rem;
    border: none;
    border-radius: 10rem;
    background-color: white;
    grid-template-columns: auto 1fr auto;
    transition: background 1s ease-in;

    &.error {
      border: 1px solid $red;
    }

    .icon-wrapper {
      display: flex;
      width: fit-content;
      align-items: center;
      justify-content: center;

      svg {
        color: #c4c4c4;

        &:hover {
          cursor: pointer;
        }
      }
    }

    input {
      height: 100%;
      border: none;
      background: transparent;
      color: grey;
      font-size: 14px;
      font-weight: 500;
      outline: none;

      &::placeholder {
        color: $gray-medium;
      }
    }
  }

  .error-wrapper {
    display: flex;
    width: 100%;
    height: 10px;
    justify-content: flex-end;

    span {
      color: $red;
      font-size: 10px;
      font-weight: 500;
    }
  }
}
</style>
