<script lang="ts">
import { useI18n } from "vue-i18n";
import Form from "./Form.vue";
import { Button2, ButtonIcon } from "@/components/button";
import { TrashButton } from "@/components/trash-button";
import { SvgIcon } from "@/components/icon";

export default defineComponent({
  name: "AcademicEducation",
  components: {
    Button: Button2,
    TrashButton,
    ButtonIcon,
    SvgIcon,
    Form,
  },
  props: {
    academic: Array,
    academicEdit: Array,
    autoFilledFields: Array,
    autoFilledEditFields: Array,
  },
  emits: ["click:add", "click:remove", "click:removeEdit"],
  setup(props) {
    const { t } = useI18n();

    const parsedEducationList = toRef(props, "academicEdit");

    const onParsingEditing = ref<boolean>(false);

    watch(parsedEducationList, (value) => {
      if (value?.length)
        onParsingEditing.value = true;
    }, { deep: true });

    return {
      t,
      onParsingEditing,
      parsedEducationList,
    };
  },
});
</script>

<template>
  <div class="work-form">
    <div class="title-wrapper">
      <span>{{ t("persons.forms.academic.title") }}</span>
    </div>
    <div
      v-for="(education, index) in academic"
      :key="`education-${index}`"
    >
      <div class="subtitle-wrapper">
        <ButtonIcon class="remove-button" @click="$emit('click:remove', index)">
          <SvgIcon icon="trash_icon" width="20" height="20" margin="none" />
        </ButtonIcon>
      </div>
      <Form
        :education="education"
        :education-index="index"
        :education-auto-filled-fields="autoFilledFields[index]"
      />
    </div>

    <div
      v-for="(parsedEducation, index) in parsedEducationList"
      :key="`parsed-education-${index}`"
    >
      <div class="subtitle-wrapper">
        <TrashButton
          class="remove-button"
          :show-icon="true"
          svg-icon="trash_icon"
          @click="$emit('click:removeEdit', index)"
        />
      </div>
      <Form
        :education="parsedEducation"
        :education-auto-filled-fields="autoFilledEditFields[index]"
      />
    </div>

    <Button class="add-button" variation="secondary" @click="$emit('click:add')">
      {{ t("persons.forms.academic.addEducation") }}
    </Button>
  </div>
</template>

<style scoped lang="scss">
.title-wrapper {
  display: flex;
  width: 100%;
  height: 50px;

  span {
    color: $primary;
    font-family: "Open Sans";
    font-size: 1.125rem;
    font-weight: bold;
    text-transform: uppercase;
  }
}

.subtitle-wrapper {
  display: flex;
  flex-grow: 1;
  margin-bottom: 1rem;

  .remove-button {
    margin-left: auto;
    color: $gray-light;
  }
}

.add-button {
  width: 100%;
  font-size: 0.8125rem;
}
</style>
