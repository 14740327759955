<script setup lang="ts">
import { Tab, TabGroup, TabList, TabPanels } from "@headlessui/vue";
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";
import { SvgIcon } from "@/components/icon";
import { Sidebar } from "@/components/sidebar";
import { Avatar } from "@/components/avatar";

const props = withDefaults(defineProps<{
  editing: boolean
  projectId: string
  stages: any
  corporationAvatarUrl?: string
}>(), {
  editing: false,
  stages: null,
});

defineEmits(["edit"]);

const router = useRouter();
const route = useRoute();
const { t } = useI18n();

const tabsDefs = computed(() => ({
  "about": {
    key: "about",
    label: t("projects.details.constructionGuide.aboutCompany"),
    tooltip: t("projects.details.constructionGuide.idealProfile.tooltip.company"),
    icon: "project_company",
  },
  "governance": {
    key: "governance",
    label: t("projects.details.constructionGuide.tabs.governance"),
    tooltip: t("projects.details.constructionGuide.idealProfile.tooltip.governance"),
    icon: "project_governance",
  },
  "strategy": {
    key: "strategy",
    label: t("projects.details.constructionGuide.tabs.strategy"),
    tooltip: t("projects.details.constructionGuide.idealProfile.tooltip.strategy"),
    icon: "project_strategy",
  },
  "execution-conditions": {
    key: "execution-conditions",
    label: t("projects.details.constructionGuide.tabs.executionConditions"),
    tooltip: t("projects.details.constructionGuide.idealProfile.tooltip.executionConditions"),
    icon: "project_conditions",
  },
  "company-profile": {
    key: "company-profile",
    label: t("projects.details.constructionGuide.idealProfile.companyProfile"),
    tooltip: t("projects.details.constructionGuide.idealProfile.tooltip.companyProfile"),
    icon: "project_company_profile",
  },
  "ideal-profile-tags": {
    key: "ideal-profile-tags",
    label: t("projects.details.constructionGuide.idealProfile.profile"),
    tooltip: t("projects.details.constructionGuide.idealProfile.tooltip.profile"),
    icon: "project_ideal_profile",
  },
  "ideal-profile": {
    key: "ideal-profile",
    label: t("projects.details.constructionGuide.idealProfile.profile"),
    tooltip: t("projects.details.constructionGuide.idealProfile.profile"),
    icon: "project_ideal_profile",
    editing: false,
    hasAvatar: false,
  },
  "attributes": {
    key: "attributes",
    label: t("projects.details.constructionGuide.idealProfile.attributes"),
    tooltip: t("projects.details.constructionGuide.idealProfile.tooltip.attributes"),
    icon: "project_attributes",
    controlColor: "gray-a8",
    controlMarkerColor: "yellow",
  },
  "cultural-match": {
    key: "cultural-match",
    label: t("projects.details.constructionGuide.idealProfile.culturalMatch"),
    tooltip: t("projects.details.constructionGuide.idealProfile.tooltip.culturalMatch"),
    icon: "project_cultural_match",
    controlColor: "yellow-slider",
    controlMarkerColor: "gray-shade-dark",
  },
  "motivation": {
    key: "motivation",
    label: t("projects.details.constructionGuide.idealProfile.motivation"),
    tooltip: t("projects.details.constructionGuide.idealProfile.tooltip.motivation"),
    icon: "project_motivation",
  },
}));

const tabKeys = Object.keys(tabsDefs.value);
const activeTab = computed(() => router.currentRoute.value.meta["ideal-profile"] as string);
const active = computed(() => tabKeys.indexOf(activeTab.value));
const showOptions = ref(false);
const showPreview = ref(false);

const {
  mutate: finishConstruction,
} = useProjectStageFinishConstruction(props.projectId);

function goTab(index: number) {
  router.push(`/projects/${route.params.id}/construction/ideal-profile/${tabKeys[index]}`);
}

function tabNext() {
  goTab(active.value + 1);
}

function tabPrev() {
  goTab(active.value - 1);
}

function changeStage() {
  showOptions.value = !showOptions.value;
  if (props.stages?.mapping?.status === "DRAFT")
    finishConstruction(props.projectId);
}

const pdfDownloadType = ref("");
const pdfErrorDownload = ref(false);
// TODO: add export error modal
const pdfModal = computed(() => ({
  title: t("errors.titles.error").toUpperCase(),
  message: t("errors.pdf.download"),
  buttonText: t("errors.buttons.tryAgain").toUpperCase(),
}));

function openPdfError(type: string) {
  pdfDownloadType.value = type;
  pdfErrorDownload.value = true;
}

const {
  executeBullhorn: exportBhConstruction,
} = useExport(String(route.params.id));

async function downloadBullhornExport() {
  showOptions.value = false;
  changeStage();
  await exportBhConstruction();
}

onMounted(() => {
  // TODO: improve this
  const tabs = document.querySelectorAll<HTMLButtonElement>(".tab-container .tab.checked");
  try {
    if (tabs && tabs.length > 0 && tabs[tabs.length - 1]) {
      tabs[tabs.length - 1]!.focus();
      tabs[tabs.length - 1]!.classList.add("last-checked");
    }
  }
  catch (e) {
    console.error(e);
  }
});
</script>

<template>
  <div v-if="activeTab && props.stages">
    <TabGroup :selected-index="active" @change="goTab($event)">
      <TabList class="tab-container">
        <template v-for="tab in tabsDefs" :key="tab.key">
          <!-- TODO: keep state focus on reload page -->
          <Tab
            class="tab"
            :class="{
              checked: tabKeys.indexOf(tab.key) <= tabKeys.indexOf(activeTab),
            }"
          >
            <SvgIcon :icon="tab.icon" margin="none" />
            <div class="tab-tooltip">
              {{ tab.tooltip }}
            </div>
          </Tab>
          <div
            class="line-wrapper"
            :style="{
              visibility:
                tabKeys.indexOf(tab.key) < tabKeys.indexOf(activeTab) ? 'visible' : 'hidden',
            }"
          >
            <div class="line" />
          </div>
        </template>
        <div class="tab export" role="button" @click="changeStage()">
          <SvgIcon icon="project_export" width="1rem" height="1rem" margin="none" />
          <div class="tab-tooltip" :class="{ hide: showOptions }">
            {{ t("projects.details.constructionGuide.idealProfile.tooltip.export") }}
          </div>
          <div v-if="showOptions" class="options-dropwdown">
            <div role="button" class="item" @click="() => (showPreview = true)">
              {{ t("projects.details.constructionGuide.idealProfile.tooltip.exportVersionImuaBeta") }}
            </div>
            <div role="button" class="item" @click="downloadBullhornExport()">
              {{ t("projects.details.constructionGuide.idealProfile.tooltip.exportVersionBullhorn") }}
            </div>
          </div>
        </div>
      </TabList>
      <TabPanels>
        <div class="heading-container">
          <div class="heading">
            <div v-if="!corporationAvatarUrl || !corporationAvatarUrl.length" class="icon">
              <SvgIcon :icon="tabsDefs[activeTab].icon" size="3.25rem" margin="none" />
            </div>
            <div v-else>
              <Avatar
                :src="corporationAvatarUrl"
                :alt="corporationAvatarUrl"
                size="3.25rem"
                placeholder="general-company"
                type="native"
                :has-alternative-icon="false"
              />
            </div>
            <div class="title">
              {{ tabsDefs[activeTab].label }}
            </div>
            <Button v-if="!props.editing" variation="primary" @click="$emit('edit', true)">
              {{ t("text.form.editElement") }}
            </Button>
            <div class="actions">
              <!-- Keep state when navigate -->
              <Button v-if="activeTab !== tabKeys.at(0)" direction="ltr" variation="secondary" @click="tabPrev()">
                <SvgIcon type="mdi" size="24" margin="none" :icon="mdiChevronLeft" />
                {{ t("projects.details.constructionGuide.idealProfile.tabPrevious") }}
              </Button>
              <Button v-if="activeTab !== tabKeys.at(-1)" variation="secondary" @click="tabNext()">
                {{ t("projects.details.constructionGuide.idealProfile.tabNext") }}
                <SvgIcon type="mdi" size="24" margin="none" :icon="mdiChevronRight" />
              </Button>
            </div>
          </div>
        </div>
      </TabPanels>
    </TabGroup>
    <Sidebar class="sidebar-preview" :visible="showPreview === true" :on-close="() => (showPreview = false)">
      <ExportExportConstruction @cancel="showPreview = false" />
    </Sidebar>
  </div>
</template>

<style scoped lang="scss">
.sidebar-preview {
  :deep(.sidebar-wrapper) {
    width: 1211px;
  }
}

.tab-container {
  display: grid;
  width: calc(100% - 5rem);
  grid-auto-flow: row;
  grid-row-gap: 1rem;
  grid-template-columns: repeat(auto-fill, 2.5rem);
  margin-block: 1.5rem;
  margin-inline: 2.5rem;

  .line-wrapper {
    display: flex;

    .line {
      width: 100%;
      height: 0.5rem;
      align-self: center;
      background: $yellow;
    }
  }

  .tab {
    position: relative;
    display: flex;
    height: 2.5rem;
    align-items: center;
    justify-content: center;
    border-radius: 0.25rem;
    box-shadow: 0 2px 4px #0d0c0c29;
    color: #ccc;
    font-size: 1rem;
    border: 0;
    background: #fff;

    &.export {
      width: 2.5rem;
      border: 1px solid #c0c0c0;
      color: #9b9b9b;
      grid-column-start: -2;

      &:hover {
        border: 1px solid transparent;
        background-color: #f7f7f7;
        box-shadow: none;
        color: $primary;
      }
    }

    &:hover,
    &.checked {
      background: $yellow;
      color: white;
    }

    &.checked.last-checked:focus {
      border: 2px solid $gray-shade-dark;
    }

    &:hover {
      .tab-tooltip {
        visibility: visible;
      }
    }

    .tab-tooltip {
      position: absolute;
      bottom: 0;
      padding: 0.25rem;
      border-radius: 0.25rem;
      margin-bottom: -1.5rem;
      box-shadow: 0 2px 4px #0d0c0c29;
      color: $gray-dark;
      font-size: 0.75rem;
      visibility: hidden;
      white-space: nowrap;

      &.hide {
        visibility: hidden;
      }
    }
  }
}

.heading-container {
  margin-inline: 1.875rem 3.125rem;
  margin-block: 4rem 1.875rem;
}

.heading {
  display: flex;
  align-items: center;
  padding-inline: 1.25rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #ccc;
  color: $gray-dark;

  .title {
    padding: 0 1rem;
    font-size: 2rem;
    font-weight: 700;
    text-transform: uppercase;
    white-space: nowrap;
  }

  >button>svg {
    color: #ccc;

    &:hover {
      color: $gray-dark;
    }
  }

  .icon {
    display: grid;
    width: 3.25rem;
    height: 3.25rem;
    border-radius: 50%;
    background: $gray-border;
    place-items: center;
  }

  .actions {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    gap: 1rem;

    button {
      display: flex;
      height: 1.875rem;
      align-items: center;
      padding: 0 0.5rem 0 1rem;
      font-size: 0.75rem;

      &[direction="ltr"] {
        padding: 0 1rem 0 0.5rem;
      }
    }
  }
}

.options-dropwdown {
  position: absolute;
  z-index: 99;
  top: 112%;
  right: 0%;
  display: flex;
  flex-direction: column;
  padding: 0.25rem 0;
  background: #fff;
  border-radius: 6px;
  box-shadow: $box-shadow-card;

  .item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0.875rem 0.5rem;
    color: $gray-medium-dark;
    cursor: pointer;
    white-space: nowrap;

    &:hover {
      color: $red-violet;
    }
  }
}
</style>
