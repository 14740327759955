import { z } from "zod";

export const UserSchema = z.object({
  user_id: z.string(),
  person_id: z.string(),
  first_name: z.string(),
  last_name: z.string(),
});

export type User = z.infer<typeof UserSchema>;
