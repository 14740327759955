<script lang="ts">
import type { Ref } from "vue";
import { defineComponent, ref, toRef } from "vue";
import { useI18n } from "vue-i18n";
import { Avatar } from "@/components/avatar";

export default defineComponent({
  name: "CorporationsList",
  components: {
    Avatar,
  },
  props: {
    corporations: Array,
    loadMoreData: Function,
  },
  emits: ["click:corporation"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const activeId = ref(null);
    const loadMoreData = toRef(props, "loadMoreData") as Ref<() => void>;

    const handleScroll = (event: Event) => {
      const {
        scrollTop: hiddenHeight,
        scrollHeight: totalHeight,
        clientHeight: totalVisible,
      } = event.target as HTMLElement;
      const bottomReached = hiddenHeight + totalVisible >= totalHeight;

      if (hiddenHeight && bottomReached)
        loadMoreData.value();
    };

    const onCorporationClick = (corporation) => {
      activeId.value = corporation.id;
      emit("click:corporation", corporation);
    };

    return {
      t,
      activeId,
      handleScroll,
      onCorporationClick,
    };
  },
});
</script>

<template>
  <div class="list-wrapper" @scroll="handleScroll">
    <div
      v-for="corporation of corporations"
      :key="`corporation-${corporation.id}`"
      class="card"
      :class="{ active: corporation.id === activeId }"
      @click="onCorporationClick(corporation)"
    >
      <Avatar
        class="image-card"
        :src="corporation.avatar_url"
        :alt="corporation.name"
        size="90px"
        :has-alternative-icon="false"
      />

      <div class="description">
        <p class="name">
          {{ corporation.name }}
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped="true" lang="scss">
.list-wrapper {
  max-height: 360px;
  padding: 0 1rem;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border: solid 2px transparent;
    border-radius: 2rem;
    background: #888;
    background-clip: content-box;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    border: solid 2px transparent;
    background: #555;
    background-clip: content-box;
  }
}

.card {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 15px;
  border: solid 1px transparent;
  background: $white-background;
  cursor: pointer;

  :deep(.image-card) {
    min-width: 5.625rem;
    aspect-ratio: 1;
  }

  &.active {
    border: solid 1px $red;
  }

  .description {
    margin-left: 0.5rem;
  }
}

.card:not(:first-child) {
  margin-top: 1rem;
}
</style>
