import { searchFieldService } from "@/http_services/client";

// TODO: types

export async function getBusinessFunctions(project_id: string) {
  return searchFieldService.get(`business-functions/project/${project_id}`)
    .then(res => res.data?.data);
}

export async function removeBusinessCorporation(data: { business_id: string; corporation_id: string }) {
  return searchFieldService.del(`business-functions/${data.business_id}/remove-corporation/${data.corporation_id}`)
    .then(res => res.data?.data);
}

export async function updateBusinessFunctions(payload: any) {
  const { _id } = payload;
  return searchFieldService.put(`business-functions/${_id}`, { business_function: payload })
    .then(res => res.data?.data);
}

export async function patchBusinessFunctions(payload: any) {
  const { _id } = payload;
  return searchFieldService.patch(`business-functions/${_id}`, { business_function: payload })
    .then(res => res.data?.data);
}
