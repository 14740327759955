import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import type { BaseOrganizationAndAssembly, OrganizationAndAssembly } from "@/types/construction-organization";
import type { IdealProfilePatch } from "@/types/ideal-profile";

export function useConstructionOrganizationQuery(project_id: string) {
  return useQuery({
    queryKey: ["projects", "construction_organization", project_id],
    queryFn: () => getConstructionOrganization(project_id),
  });
}

export function useConstructionIdealProfile(project_id: string) {
  return useQuery({
    queryKey: ["projects", "construction_organization", project_id],
    queryFn: () => getConstructionOrganization(project_id),
    select: data => data?.ideal_profile,
  });
}

export function useCreateConstructionOrganizationQuery() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["projects", "construction_organization"],
    mutationFn: (organization_and_assembly: BaseOrganizationAndAssembly) => addConstructionOrganization(organization_and_assembly),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["projects", "construction_organization"] });
    },
  });
}

export const isSaving = ref(false);

// TODO: add update from mutation response
// https://tanstack.com/query/v5/docs/react/guides/updates-from-mutation-responses

export function usePatchConstructionOrganizationQuery() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["projects", "construction_organization"],
    mutationFn: (organization_and_assembly: Partial<OrganizationAndAssembly> & { _id?: string }) => {
      const id = organization_and_assembly._id;
      // delete _id from the object
      delete organization_and_assembly._id;
      if (!id)
        return Promise.reject(new Error("No _id provided"));
      isSaving.value = true;
      return patchConstructionOrganization(id, organization_and_assembly);
    },
    onError: () => {
      isSaving.value = false;
    },
    onMutate: async (newOrganization) => {
      isSaving.value = false;
    },
    onSuccess: (data, { _id }) => {
      isSaving.value = false;
      queryClient.invalidateQueries({ queryKey: ["projects", "construction_activity", _id] });
      queryClient.invalidateQueries({ queryKey: ["projects", "construction_organization"] });
    },
    onSettled: () => {
      isSaving.value = false;
    },
  });
}

export function usePatchIdealProfileQuery() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["projects", "construction_organization"],
    mutationFn: (ideal_profile: IdealProfilePatch & { _id?: string }) => {
      const id = ideal_profile._id;
      // delete _id from the object
      delete ideal_profile._id;
      if (!id)
        return Promise.reject(new Error("No _id provided"));
      isSaving.value = true;
      return patchIdealProfile(id, ideal_profile);
    },
    onError: () => {
      isSaving.value = false;
    },
    onSuccess: (data, { _id }) => {
      isSaving.value = false;
      queryClient.invalidateQueries({ queryKey: ["projects", "construction_activity", _id] });
      queryClient.invalidateQueries({ queryKey: ["projects", "construction_organization"] });
    },
  });
}
