import { activitiesService } from "@/http_services/client";

export async function addInterviewEvaluation(organization_and_assessment: any) {
  return activitiesService.post("interviews/organization-and-assessment", { organization_and_assessment })
    .then(res => res.data?.data);
}

export async function getInterviewsEvaluation(project_id: string) {
  return activitiesService.get(`interviews/organization-and-assessment/project/${project_id}`, { params: { limit: 30 } })
    .then(res => res.data?.data);
}

export async function getInterviewEvaluation(project_id: string, person_id: string) {
  return activitiesService.get(`interviews/organization-and-assessment/project/${project_id}/person/${person_id}`)
    .then(res => res.data?.data);
}

export async function patchInterviewEvaluation(id: string, organization_and_assessment: any) {
  return activitiesService.patch(`interviews/organization-and-assessment/${id}`, { assessment_patch: organization_and_assessment })
    .then(res => res.data?.data);
}
