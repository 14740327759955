<script lang="ts" setup>
import { useVuelidate } from "@vuelidate/core";
import { helpers, required, url } from "@vuelidate/validators";
import Card from "@/components/card/Card.vue";
import type { Corporation } from "@/entities/corporation";
import AvatarInput from "@/components/inputs/AvatarInput/AvatarInput.vue";
import { FormInput } from "@/components/inputs/modalInputs";
import TelephoneInput from "@/components/inputs/modalInputs/TelephoneInput.vue";
import { ImagesTypesEnum } from "@/schemas/enums/images";
import { createAvatar } from "@/http_services/files/avatar";
import { RadioButton } from "@/components/radio";
import { EducationalOrganizationTypesEnum } from "@/schemas/enums/corporation";
import type { baseEducationalOrganization } from "@/schemas/corporation";
import type { CorporationHeaderPatch } from "@/http_services/entities/corporation";

const props = defineProps<{ educationalOrganization?: object | null; isEducational: boolean }>();
const emit = defineEmits(["cancel:edit", "update:corporation"]);
const { t, tm } = useI18n();

const { mutate: patchCorporationHeader, isSuccess: patchCorporationIsSuccess } = usePatchCorporationHeader();

const corporation = inject("corporation") as Ref<Corporation>;

const avatarFile = ref();

const state = reactive({
  avatar_url: corporation.value.avatar_url,
  name: corporation.value.name,
  address: corporation.value.address || { locality: "", name: "" },
  url: corporation.value.url,
  phone: corporation.value.phone || { calling_code: "+ 55", number: "" },
  linkedin_url: corporation.value.linkedin_url,
  facebook_url: corporation.value.facebook_url,
});

const linkedinValidator = helpers.regex(
  /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile|company)/,
);

const faceBookValidator = helpers.regex(/^(https?:\/\/)?((w{3}\.)?)facebook.com\/.*/i);

const rules = computed(() => ({
  name: {
    required: helpers.withMessage(t("validator.requiredName"), required),
  },
  url: {
    required: helpers.withMessage(t("validator.requiredDomain"), required),
    url: helpers.withMessage(t("validator.url"), url),
  },
  linkedin_url: {
    url: helpers.withMessage(t("validator.url"), url),
    linkedin: helpers.withMessage(t("validator.linkedin"), linkedinValidator),
  },
  facebook_url: {
    url: helpers.withMessage(t("validator.url"), url),
    linkedin: helpers.withMessage(t("validator.facebook"), faceBookValidator),
  },
}));

const v$ = useVuelidate(rules, state);

function removeAvatar() {
  state.avatar_url = "";
  avatarFile.value = null;
}

const educationalOrganization = ref(
  props.educationalOrganization,
) as Ref<baseEducationalOrganization>;

const isEducational = ref(props.isEducational);

const educationalOrganizationType: Ref<EducationalOrganizationTypesEnum | string> = ref(EducationalOrganizationTypesEnum.PRIVATE);

const isEducationalOrganization = ref("no");

const educationalOrganizationEnum = [
  { label: t("text.form.no"), value: "no" },
  { label: t("text.form.yes"), value: "yes" },
];

const educationalOrganizationTypes: ComputedRef<Array<{ label: any; value: string }>> = computed(() =>
  Object.entries(tm("corporations.enums.educationalOrganizationTypes")).map(
    ([value, label]) => ({ label, value }),
  ));
function cancel() {
  emit("cancel:edit");
}

async function save() {
  const isValid = await v$.value.$validate();

  if (isValid) {
    if (avatarFile.value) {
      const data = await createAvatar(avatarFile.value, ImagesTypesEnum.corporations);
      state.avatar_url = data.url;
    }

    const payload: CorporationHeaderPatch = {
      ...state,
      phone: (state.phone.calling_code && state.phone.number) ? state.phone : null,
      address: {
        ...state.address,
        name: state.address.name,
      },
      linkedin_url: state.linkedin_url || null,
      facebook_url: state.facebook_url || null,
      is_educational: isEducationalOrganization.value === "yes",
    };
    patchCorporationHeader({
      id: corporation.value?.id,
      corporation_header_patch: {
        ...payload,
        ...(payload.is_educational
          ? {
              educational_patch: {
                corporation_type: educationalOrganizationType.value,
              },
            }
          : null),
      },
    });
    if (patchCorporationIsSuccess)
      cancel();
  }
}

onMounted(() => {
  if (isEducational.value) {
    educationalOrganizationType.value
          = educationalOrganization.value?.corporation_type
          || EducationalOrganizationTypesEnum.PRIVATE;
    isEducationalOrganization.value = "yes";
  }
});
</script>

<template>
  <Card class="company-card">
    <div class="company-card__avatar-section">
      <span class="avatar">
        <AvatarInput
          v-model="avatarFile"
          :name="corporation.name"
          :avatar-url="state.avatar_url ? `${state.avatar_url}?version=${corporation._version}` : ''"
          size="145px"
          border-color="transparent"
          entity="corporation"
        />
        <Button class="avatar-button" variation="light" filled @click="removeAvatar">{{
          t("text.form.removeAvatar")
        }}</Button>
      </span>
      <span class="form-item">
        <label>{{ t("corporations.details.name") }}:</label>
        <FormInput
          v-model="state.name"
          class="form-input"
          :error="v$.name.$error"
          :error-message="v$.name.$errors[0] ? String(v$.name.$errors[0]?.$message) : null"
        />
      </span>
    </div>
    <div class="company-card__details-section">
      <div class="form">
        <div class="form-item">
          <label>{{ t("corporations.details.locality") }}:</label>
          <FormInput
            v-model="state.address.name"
            class="form-input"
            :placeholder="t('placeholders.locality')"
          />
        </div>
        <div class="form-item">
          <label>{{ t("corporations.details.domain") }}:</label>
          <FormInput
            v-model="state.url"
            class="form-input"
            :error="v$.url.$error"
            :error-message="v$.url.$errors[0] ? String(v$.url.$errors[0]?.$message) : null"
          />
        </div>
        <div class="form-item phone-input">
          <label>{{ t("corporations.details.phone") }}:</label>
          <div class="input-group">
            <TelephoneInput
              v-model:countryCode="state.phone.calling_code"
              v-model:phoneNumber="state.phone.number"
              class="form-input"
            />
          </div>
        </div>
        <div class="form-item">
          <label>{{ t("corporations.details.linkedin") }}:</label>
          <FormInput
            v-model="state.linkedin_url"
            class="form-input"
            :error="v$.linkedin_url.$error"
            :error-message="
              v$.linkedin_url.$errors[0] ? String(v$.linkedin_url.$errors[0]?.$message) : null
            "
            :placeholder="t('placeholders.linkedin')"
          />
        </div>
        <div class="form-item">
          <label>{{ t("corporations.details.facebook") }}:</label>
          <FormInput
            v-model="state.facebook_url"
            class="form-input"
            :error="v$.facebook_url.$error"
            :error-message="
              v$.facebook_url.$errors[0] ? String(v$.facebook_url.$errors[0]?.$message) : null
            "
            :placeholder="t('placeholders.facebook')"
          />
        </div>
        <div class="form-item form-educational">
          <label> {{ t("corporations.isEducationalOrganization") }} </label>
          <div class="form-educational-form">
            <RadioButton
              v-for="(eduOrg, index) in educationalOrganizationEnum"
              :id="eduOrg.label"
              :key="eduOrg.label"
              v-model="isEducationalOrganization"
              :name="`educationOrganizatprivateion-${index}`"
              :value="eduOrg.value"
              :label="eduOrg.label"
            />
            <!-- :disabled="isEducational && index === 0" -->
          </div>
          <div v-if="isEducationalOrganization === 'yes'" class="form-educational-type">
            <RadioButton
              v-for="(item, index) in educationalOrganizationTypes"
              :id="item.value"
              :key="index"
              v-model="educationalOrganizationType"
              name="educationOrganizationType"
              :value="item.value"
              :label="item.label"
            />
          </div>
        </div>
        <div class="action-buttons">
          <Button @click="save">
            {{ t("text.form.submit") }}
          </Button>
          <Button variation="light" filled @click="cancel">
            {{ t("text.form.cancel") }}
          </Button>
        </div>
      </div>
    </div>
  </Card>
</template>

<style lang="scss" scoped>
.form-educational {
  display: flex;
  margin-bottom: 1.25rem;
}

.form-educational-form {
  display: flex;
  gap: 1rem;
}

.form-educational-type {
  display: flex;
  padding-left: 1rem;
  gap: 1rem;

  :deep(.imua-radiobutton__label) {
    &::before {
      background-color: $gray-dark;
    }
  }
}

.company-card {
  display: flex;
  width: 100%;
  padding: 2rem;
  margin-bottom: 2rem;

  &__avatar-section {
    display: flex;
    width: 40%;

    .avatar {
      display: flex;
      width: min-content;
      flex-direction: column;

      .avatar-button {
        margin-top: 1rem;
      }
    }
  }

  &__details-section {
    position: relative;
    display: flex;
    width: 60%;
    color: $gray-dark;
  }

  .name-input {
    display: flex;
    align-self: flex-start;

    label {
      width: 25%;
    }

    .form-input {
      width: 75%;
    }
  }

  .form {
    width: 80%;
  }

  .form-item {
    display: flex;
    height: min-content;
    margin-bottom: 1.5rem;

    label {
      width: 20%;
      align-self: center;
    }

    .form-input {
      width: 80%;
    }
  }

  .action-buttons {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .phone-input {
    .input-group {
      width: 80%;

      :deep(.form-input) {
        width: 100%;

        input {
          width: 100%;
        }
      }
    }
  }
}
</style>
