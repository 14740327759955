import { z } from "zod";

export enum SectorsEnum {
  /**
   * Define Business Sectors.
   * Represents all available macro markets.
   */
  ADM_PUBLIC = "ADM_PUBLIC",
  AGRIBUSINESS = "AGRIBUSINESS",
  CONSTRUCTION = "CONSTRUCTION",
  CONSUMER_GOODS = "CONSUMER_GOODS",
  EDUCATION = "EDUCATION",
  FINANCIAL_SERVICES = "FINANCIAL_SERVICES",
  HEALTH_SERVICES = "HEALTH_SERVICES",
  INDUSTRY = "INDUSTRY",
  INSURANCE = "INSURANCE",
  LOGISTICS_TRANSPORT = "LOGISTICS_TRANSPORT",
  MINING_STEEL = "MINING_STEEL",
  OIL_GAS = "OIL_GAS",
  PHARMACEUTICAL = "PHARMACEUTICAL",
  PRIVATE_EQUITY = "PRIVATE_EQUITY",
  RETAIL = "RETAIL",
  SERVICES = "SERVICES",
  TELEMARKETING = "TELEMARKETING",
  UTILITIES = "UTILITIES",
}

export enum IndustriesEnum {
  /**
   * Define Industries.
   * Represents all available micro markets.
   */

  ADM_PUBLIC = "ADM_PUBLIC",
  ADM_STOCKS_MARKETS = "ADM_STOCKS_MARKETS",
  AGRICULTURE = "AGRICULTURE",
  ANIMAL_HEALTH = "ANIMAL_HEALTH",
  ASSOCIATION_FEDERATION = "ASSOCIATION_FEDERATION",
  AUTOMOTIVE_ASSEMBLY = "AUTOMOTIVE_ASSEMBLY",
  AUTOMOTIVE_DEALERSHIP = "AUTOMOTIVE_DEALERSHIP",
  AUTOMOTIVE_PARTS = "AUTOMOTIVE_PARTS",
  AVIATION_AEROPLANES = "AVIATION_AEROPLANES",
  AVIATION_AIRPORTS = "AVIATION_AIRPORTS",
  AVIATION_SERVICES = "AVIATION_SERVICES",
  BANKING = "BANKING",
  BEAUTY = "BEAUTY",
  BIOTECHNOLOGY = "BIOTECHNOLOGY",
  BROKERAGE = "BROKERAGE",
  BROKERAGE_FUNDS = "BROKERAGE_FUNDS",
  BROKERAGE_REAL_ESTATE = "BROKERAGE_REAL_ESTATE",
  CHARGING = "CHARGING",
  CHEMICALS_SPECIALTIES = "CHEMICALS_SPECIALTIES",
  CHEMICAL_FIBERS = "CHEMICAL_FIBERS",
  CLINIC = "CLINIC",
  CLOTHING = "CLOTHING",
  CONCESSION_MANAGEMENT = "CONCESSION_MANAGEMENT",
  COSMETICS = "COSMETICS",
  CREDIT = "CREDIT",
  DIAGNOSTICS = "DIAGNOSTICS",
  DISTRIBUTOR = "DISTRIBUTOR",
  DISTRIBUTOR_AGRICULTURE = "DISTRIBUTOR_AGRICULTURE",
  DISTRIBUTOR_AUTO_PARTS = "DISTRIBUTOR_AUTO_PARTS",
  DISTRIBUTOR_CAPITAL_GOODS = "DISTRIBUTOR_CAPITAL_GOODS",
  DISTRIBUTOR_FUEL = "DISTRIBUTOR_FUEL",
  DISTRIBUTOR_CHEMICAL_PRODUCTS = "DISTRIBUTOR_CHEMICAL_PRODUCTS",
  DISTRIBUTOR_TECHNOLOGY = "DISTRIBUTOR_TECHNOLOGY",
  DURABLE_GOODS = "DURABLE_GOODS",
  ECOMMERCE = "ECOMMERCE",
  EDUCATION = "EDUCATION",
  ELECTRONICS = "ELECTRONICS",
  ENERGY = "ENERGY",
  ENTERTAINMENT = "ENTERTAINMENT",
  EQUIPMENT = "EQUIPMENT",
  EXCHANGE = "EXCHANGE",
  EXTRACTION = "EXTRACTION",
  FAMILY_OFFICE = "FAMILY_OFFICE",
  FERTILIZERS_DEFENSIVES = "FERTILIZERS_DEFENSIVES",
  FISHING_AQUACULTURE = "FISHING_AQUACULTURE",
  FOOD = "FOOD",
  FOOD_DRINKS = "FOOD_DRINKS",
  FOOD_INGREDIENTS = "FOOD_INGREDIENTS",
  FURNITURE = "FURNITURE",
  FURNITURE_UTENSILS = "FURNITURE_UTENSILS",
  GAS = "GAS",
  HIGHWAYS = "HIGHWAYS",
  HOSPITAL = "HOSPITAL",
  HOSPITAL_SUPPLY = "HOSPITAL_SUPPLY",
  HOTELS_BUSINESS = "HOTELS_BUSINESS",
  IMPORT_EXPORT = "IMPORT_EXPORT",
  INCORPORATION = "INCORPORATION",
  INDUSTRY = "INDUSTRY",
  INFRASTRUCTURE = "INFRASTRUCTURE",
  INSTALLATION_MAINTENANCE = "INSTALLATION_MAINTENANCE",
  INSURANCE = "INSURANCE",
  INVESTMENTS = "INVESTMENTS",
  INVESTMENT_FUNDS_REAL_STATE = "INVESTMENT_FUNDS_REAL_STATE",
  LEASE = "LEASE",
  LIVESTOCK = "LIVESTOCK",
  LOGISTICS = "LOGISTICS",
  LUXURY_ITEMS = "LUXURY_ITEMS",
  MACHINERY = "MACHINERY",
  MACHINERY_EQUIPMENTS = "MACHINERY_EQUIPMENTS",
  MARITIME = "MARITIME",
  MATERIALS_CONSTRUCTION = "MATERIALS_CONSTRUCTION",
  MATERIALS_ELECTRONICS = "MATERIALS_ELECTRONICS",
  MEDIA = "MEDIA",
  MEDICINE = "MEDICINE",
  MINING_STEEL = "MINING_STEEL",
  MOBILITY = "MOBILITY",
  NON_METALLIC = "NON_METALLIC",
  OPTICS = "OPTICS",
  OTHERS = "OTHERS",
  OUTSOURCING = "OUTSOURCING",
  PACKAGING = "PACKAGING",
  PAPER = "PAPER",
  PARTS = "PARTS",
  PAYMENTS = "PAYMENTS",
  PETROCHEMICALS = "PETROCHEMICALS",
  PHARMACEUTICALS = "PHARMACEUTICALS",
  PHARMACOCHEMICAL = "PHARMACOCHEMICAL",
  PLASTICS = "PLASTICS",
  PRINTING = "PRINTING",
  PRIVATE_EQUITY = "PRIVATE_EQUITY",
  PRODUCTION = "PRODUCTION",
  PRODUCTS_HYGIENE = "PRODUCTS_HYGIENE",
  PRODUCTS_MEDICAL = "PRODUCTS_MEDICAL",
  RAILROADS = "RAILROADS",
  RECREATIONAL = "RECREATIONAL",
  REFUSE = "REFUSE",
  REINSURER = "REINSURER",
  RESEARCH = "RESEARCH",
  RESEARCH_CLINIC = "RESEARCH_CLINIC",
  RESIDENTIAL_CORPORATE = "RESIDENTIAL_CORPORATE",
  SANITATION = "SANITATION",
  SERVICES = "SERVICES",
  SERVICES_MANAGEMENT = "SERVICES_MANAGEMENT",
  SERVICES_PROFESSIONAL = "SERVICES_PROFESSIONAL",
  SERVICES_PROVIDERS = "SERVICES_PROVIDERS",
  SERVICES_SYSTEMS = "SERVICES_SYSTEMS",
  SHOPPING_CENTERS = "SHOPPING_CENTERS",
  SOCIAL_ASSISTANCE = "SOCIAL_ASSISTANCE",
  STORAGE = "STORAGE",
  SUGAR_ETHANOL = "SUGAR_ETHANOL",
  TELECOMMUNICATIONS = "TELECOMMUNICATIONS",
  TELEVISION = "TELEVISION",
  THIRD_SECTOR = "THIRD_SECTOR",
  TOBACCO = "TOBACCO",
  TOOLS = "TOOLS",
  TRADING = "TRADING",
  UNSKILLED = "UNSKILLED",
  UTENSILS = "UTENSILS",
  VENTURE_CAPITAL = "VENTURE_CAPITAL",
  VET = "VET",
  WELFARE = "WELFARE",
  WELFARE_FOUNDATION = "WELFARE_FOUNDATION",
}

export enum NumberOfEmployeesEnum {
  // Corporation size by it' employee number range.

  ONE_TO_FIFTY = "1_TO_50",
  FIFTY_ONE_TO_HUNDRED = "51_TO_100",
  HUNDRED_ONE_TO_FIVE_HUNDRED = "101_TO_500",
  FIVE_HUNDRED_ONE_TO_THOUSAND = "501_TO_1000",
  THOUSAND_ONE_TO_FIVE_THOUSAND = "1001_TO_5000",
  FIVE_THOUSAND_ONE_TO_TEN_THOUSAND = "5001_TO_10000",
  MORE_THAN_TEN_THOUSAND = "MORE_THAN_10000",
}

export enum BusinessTypeEnum {
  // Corporation type.

  PUBLICLY_TRADED = "PUBLICLY_TRADED",
  FAMILY_CONTROL = "FAMILY_CONTROL",
  BRAZILIAN_MULTINATIONAL = "BRAZILIAN_MULTINATIONAL",
  STARTUP = "STARTUP",
  STARTUP_WITH_PARTNER_FUND_PE_OR_VC = "STARTUP_WITH_PARTNER_FUND_PE_OR_VC",
  STRATEGIC_PARTNER_JV = "STRATEGIC_PARTNER_JV",
}

export enum BusinessLevelEnum {
  // Corporation level.

  CEO_OWNER = "CEO_OWNER",
  FAMILY_BUSINESS = "FAMILY_BUSINESS",
  PROFESSIONAL_MANAGEMENT = "PROFESSIONAL_MANAGEMENT",
}

export enum ParentBusinessEquityTypeEnum {
  // Company roles for investors or parent Corporations.

  INVESTOR_FUND_PE = "INVESTOR_FUND_PE",
  HOLDING_CONTROLLER = "HOLDING_CONTROLLER",
}

export enum SubsidiaryBusinessEquityTypeEnum {
  // Company roles for invested or subsidiaries Corporations.

  SUBSIDIARY = "SUBSIDIARY",
  BUSINESS_UNITY = "BUSINESS_UNITY",
  FUND_INVESTED = "FUND_INVESTED",
}

export enum TransformationEventTypeEnum {
  // Transformation event type.

  IPO = "IPO",
  FUSION_AND_ACQUISITION = "FUSION_AND_ACQUISITION",
  CHANGE_OF_CONTROL_OR_SELL = "CHANGE_OF_CONTROL_OR_SELL",
  PROFESSIONALIZATION_FAMILY_OUT = "PROFESSIONALIZATION_FAMILY_OUT",
  PROFESSIONALIZATION_BOARDS_AND_COMMITTEES = "PROFESSIONALIZATION_BOARDS_AND_COMMITTEES",
  ACCELERATED_GROWTH_OR_INVESTMENT_ROUNDS = "ACCELERATED_GROWTH_OR_INVESTMENT_ROUNDS",
  DOWNSIZING = "DOWNSIZING",
  JUDICIAL_RECOVERY = "JUDICIAL_RECOVERY",
  INTERNATIONALIZATION = "INTERNATIONALIZATION",
  ORGANIC_GROWTH = "ORGANIC_GROWTH",
  UNIT_CLOSURE = "UNIT_CLOSURE",
  COMPANY_CLOSING = "COMPANY_CLOSING",
  SYSTEM_IMPLEMENTATION = "SYSTEM_IMPLEMENTATION",
  DIGITAL_TRANSFORMATION = "DIGITAL_TRANSFORMATION",
  CHANNEL_TRANSFORMATION = "CHANNEL_TRANSFORMATION",
  PRODUCT_TRANSFORMATION = "PRODUCT_TRANSFORMATION",
  PROCESS_TRANSFORMATION = "PROCESS_TRANSFORMATION",
  BRAND_TRANSFORMATION = "BRAND_TRANSFORMATION",
  AREA_MERGE = "AREA_MERGE",
  CHAIR_MERGE = "CHAIR_MERGE",
}

export enum EducationalOrganizationTypesEnum {
  PRIVATE = "PRIVATE",
  PUBLIC = "PUBLIC",
}

export const NumberOfEmployeesEnumSchema = z.nativeEnum(NumberOfEmployeesEnum);
export const BusinessTypeEnumSchema = z.nativeEnum(BusinessTypeEnum);
export const BusinessLevelEnumSchema = z.nativeEnum(BusinessLevelEnum);
export const ParentBusinessEquityTypeEnumSchema = z.nativeEnum(ParentBusinessEquityTypeEnum);
export const SubsidiaryBusinessEquityTypeEnumSchema = z.nativeEnum(
  SubsidiaryBusinessEquityTypeEnum,
);
export const TransformationEventTypeEnumSchema = z.nativeEnum(TransformationEventTypeEnum);
export const SectorsEnumSchema = z.nativeEnum(SectorsEnum);
export const IndustriesEnumSchema = z.nativeEnum(IndustriesEnum);
export const EducationalOrganizationTypesEnumSchema = z.nativeEnum(
  EducationalOrganizationTypesEnum,
);
