<script lang="ts">
import { defineComponent, ref, toRef, watch } from "vue";
import { onClickOutside } from "@vueuse/core";
import CloseIcon from "@/assets/images/square_x_icon.svg";

export default defineComponent({
  name: "SidebarWrapper",
  components: {
    CloseIcon,
  },
  props: {
    hideClose: {
      type: Boolean,
      default: false,
    },
    resize: {
      type: Boolean,
      default: false,
    },
    visible: Boolean,
    onClose: Function,
    preventCloseOutside: Boolean,
  },
  setup(props) {
    const sidebarRef = ref(null);
    const overlayRef = ref(null);
    const wrapperVisible = ref(false);
    const visible = toRef(props, "visible");

    const openWrapper = () => (wrapperVisible.value = true);
    const closeWrapper = (e: KeyboardEvent) => {
      if (e.key === "Escape")
        wrapperVisible.value = false;
    };

    onClickOutside(sidebarRef, () => {
      if (!props.preventCloseOutside)
        wrapperVisible.value = false;
    });

    const addEscapeClick = () => window.addEventListener("keyup", closeWrapper);
    const removeEscapeClick = () => window.removeEventListener("keyup", closeWrapper);

    watch(visible, (value) => {
      const el: HTMLElement = document.querySelector("#base-template");
      if (value) {
        // el.style.position = "fixed";
        addEscapeClick();
      }
      else {
        // el.style.position = "relative";
        removeEscapeClick();
      }
    });

    const onDrag = (e: DragEvent) => {
      if (overlayRef?.value?.style)
        overlayRef.value.style.width = `${e.clientX + 2}px`;
    };

    const onDragStart = (e: DragEvent) => {
      e.dataTransfer?.setDragImage(new Image(), e.clientX, e.clientY);
    };

    const onDragEnd = (e: DragEvent) => {
      if (overlayRef?.value?.style)
        overlayRef.value.style.width = `${e.clientX + 2}px`;
    };

    return {
      sidebarRef,
      overlayRef,
      wrapperVisible,
      openWrapper,
      closeWrapper,
      onDrag,
      onDragStart,
      onDragEnd,
    };
  },
});
</script>

<template>
  <transition name="fade" @after-enter="openWrapper()">
    <section v-if="visible" ref="overlayRef" class="overlay">
      <div
        v-if="resize"
        class="resize-divider"
        draggable="true"
        @drag="onDrag"
        @dragstart="onDragStart"
        @dragend="onDragEnd"
      />
      <transition name="slide" @after-leave="onClose">
        <article
          v-show="wrapperVisible"
          ref="sidebarRef"
          class="sidebar-wrapper"
          tabindex="0"
        >
          <CloseIcon
            v-if="!hideClose"
            role="button"
            class="close"
            @click="closeWrapper({ key: 'Escape' })"
          />

          <slot />
        </article>
      </transition>
    </section>
  </transition>
</template>

<style scoped="true" lang="scss">
$divider-size: 2px;

.overlay {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  background: rgb(0 0 0 / 70%);
}

.sidebar-wrapper {
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 78px 35px;
  background: #fff;
  box-shadow: -1px -1px 3px $gray-light, 1px -1px 3px $gray-light;
  overflow-x: hidden;
  overflow-y: auto;

  &.visible {
    transform: translateX(0);
  }

  .close {
    position: absolute;
    top: 23px;
    left: 17px;
    width: 18px;
    height: 18px;
    cursor: pointer;
    stroke: #a8a8a8;
  }

  .close:hover {
    stroke: $primary;
  }
}

.resize-divider {
  position: relative;
  z-index: 101;
  left: 0;
  width: $divider-size;
  height: 100%;
  background: #fff;
  cursor: col-resize;
  opacity: 0.2;
  -webkit-touch-callout: none;
  transition: background 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.1s;
  -webkit-user-select: none;
  user-select: none;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity ease-out 200ms;
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(100%);
}

.slide-enter-active,
.slide-leave-active {
  transition: transform ease-in-out 200ms 100ms;
}

.slide-enter-to,
.slide-leave-from {
  transform: translateX(0);
}

@media only screen and (min-width: 900px) {
  .sidebar-wrapper {
    width: #{"max(50vw, 780px)"};
  }
}
</style>
