import { z } from "zod";

export enum QuestionTypeEnum {
  TEXT = "TEXT",
  INPUT = "INPUT",
  BOOLEAN = "BOOLEAN",
  SELECT = "SELECT",
  RADIO = "RADIO",
  MULTI_ROW = "MULTI_ROW",
  SALARY = "SALARY",
  LIST = "LIST",
}

export enum CompanyMomentEnum {
  GROWING = "GROWING",
  SHRINKING = "SHRINKING",
  INVESTING = "INVESTING",
  STABLE = "STABLE",
}

export enum StrategyStatusEnum {
  ACTIVE = "ACTIVE",
  INATIVE = "INATIVE",
}

export enum ScaleEnum {
  LOW = "LOW",
  AVERAGE = "AVERAGE",
  HIGH = "HIGH",
  VERY_HIGH = "VERY_HIGH",
}

export enum BoardTypeEnum {
  CONTROLLER = "CONTROLLER",
  CAPITAL = "CAPITAL",
}

export enum ProjectStageEnum {
  DIAGNOSTICS_WITH_CONSULTING = "DIAGNOSTICS_WITH_CONSULTING",
  DIAGNOSTICS_WITHOUT_CONSULTING = "DIAGNOSTICS_WITHOUT_CONSULTING",
  EXPLANATION = "EXPLANATION",
  IMPLEMENTATION_WITH_CONSULTING = "IMPLEMENTATION_WITH_CONSULTING",
  IMPLEMENTATION_WITHOUT_CONSULTING = "IMPLEMENTATION_WITHOUT_CONSULTING",
  FAILED_IMPLEMENTATION = "FAILED_IMPLEMENTATION",
}

export enum GovernanceInstancesEnum {
  COMMITTEE = "COMMITTEE",
  ADVISORY_COUNCIL = "ADVISORY_COUNCIL",
  FAMILY_COUNCIL = "FAMILY_COUNCIL",
}

export enum CompanyChartEnum {
  COUNCILS = "COUNCILS",
  COMMITTEES = "COMMITTEES",
  CEO = "CEO",
  C_LEVEL = "C_LEVEL",
  DOWN_LEVEL = "DOWN_LEVEL",
}

export enum RequesterPositionEnum {
  PLUS_1 = "PLUS_1",
  ZERO = "ZERO",
  MINUS_1 = "MINUS_1",
  MINUS_2 = "MINUS_2",
  MINUS_3 = "MINUS_3",
}

export enum StrategyMapEnum {
  REVENUES_AND_INCOMINGS_SOURCES = "REVENUES_AND_INCOMINGS_SOURCES",
  MAIN_PRODUCT = "MAIN_PRODUCT",
  SECONDARY_PRODUCT = "SECONDARY_PRODUCT",
  GEOGRAPHIC_PRESENCE = "GEOGRAPHIC_PRESENCE",
  COMPANY_MOMENT = "COMPANY_MOMENT",
  BUSINESS_PLAN = "BUSINESS_PLAN",
  STRUCTURING_PROJECTS = "STRUCTURING_PROJECTS",
  ACQUISITION_PROJECTS = "ACQUISITION_PROJECTS",
  ASSET_SALES_PROJECTS = "ASSET_SALES_PROJECTS",
  MARKET_SCENARIO = "MARKET_SCENARIO",
  DEFINED_STRATEGY = "DEFINED_STRATEGY",
  STRATEGY_STATUS = "STRATEGY_STATUS",
}

export enum GovernanceMapEnum {
  PARTNERSHIP_STRUCTURE = "PARTNERSHIP_STRUCTURE",
  ADMINISTRATIVE_BOARD = "ADMINISTRATIVE_BOARD",
  MEMBERS_BOARD = "MEMBERS_BOARD",
  BOARD_TYPE = "BOARD_TYPE",
  INDEPENDENT_MEMBERS = "INDEPENDENT_MEMBERS",
  GOVERNANCE_INSTANCES = "GOVERNANCE_INSTANCES",
  COMPANY_CHART = "COMPANY_CHART",
  MANAGEMENT_MODEL = "MANAGEMENT_MODEL",
  PROCESS_INTEGRATION = "PROCESS_INTEGRATION",
  POLICIES_AND_PROCESSES = "POLICIES_AND_PROCESSES",
  ROLES_AND_RESPONSIBILITIES = "ROLES_AND_RESPONSIBILITIES",
}

export enum ProfileMapEnum {
  POSITION_NAME = "POSITION_NAME",
  ACADEMIC_BACKGROUND = "ACADEMIC_BACKGROUND",
  WORK_EXPERIENCE = "WORK_EXPERIENCE",
  MOTIVATION_PACKAGE = "MOTIVATION_PACKAGE",
  COMPANY_POLICY = "COMPANY_POLICY",
  ATTRIBUTES = "ATTRIBUTES",
  CULTURAL_MATCH = "CULTURAL_MATCH",
  REALIZATIONS = "REALIZATIONS",
}

export enum AttributesMapEnums {
  EMPTY = "EMPTY",
  COGNITIVE_INTELLIGENCE = "COGNITIVE_INTELLIGENCE",
  EMOTIONAL_INTELLIGENCE = "EMOTIONAL_INTELLIGENCE",
  SPIRITUAL_INTELLIGENCE = "SPIRITUAL_INTELLIGENCE",
  ENERGY = "ENERGY",
  MATURITY = "MATURITY",
}

export enum CulturalMatchMapEnums {
  EMPTY = "EMPTY",
  PRACTICAL = "PRACTICAL",
  LOGICAL = "LOGICAL",
  REFLEXIVE = "REFLEXIVE",
  AFFECTIVE = "AFFECTIVE",
}

export enum ExecutionConditionsMapEnums {
  PROJECT_STAGE = "PROJECT_STAGE",
  PROJECT_IS_KNOWN = "PROJECT_IS_KNOWN",
  COMPANY_IMPACT = "COMPANY_IMPACT",
}

export enum PositionStrategyMapEnums {
  TIME_ALLOCATION = "TIME_ALLOCATION",
  EXECUTIVE_PEERS_LEVEL = "EXECUTIVE_PEERS_LEVEL",
  EXECUTIVE_REPORTING = "EXECUTIVE_REPORTING",
  MANAGER_PROFILE = "MANAGER_PROFILE",
  BUDGET = "BUDGET",
  APPROVAL_AUTHORITY = "APPROVAL_AUTHORITY",
  APPROVAL_CYCLE = "APPROVAL_CYCLE",
}

export enum RealizationsMapEnums {
  MAIN_DELIVERY = "MAIN_DELIVERY",
  BUSINESS_DEVELOPMENTS = "BUSINESS_DEVELOPMENTS",
  STRUCTURE_DEVELOPMENTS = "STRUCTURE_DEVELOPMENTS",
  NEED_ADAPTATION = "NEED_ADAPTATION",
}

export enum FunctionalScopeMapEnums {
  POSITION_ACTIVITIES = "POSITION_ACTIVITIES",
  POSITION_REPORT = "POSITION_REPORT",
  OPERATIONAL_ROUTINES = "OPERATIONAL_ROUTINES",
  ROUTINES_NEED_ADJUSTMENT = "ROUTINES_NEED_ADJUSTMENT",
  RELEVANT_DELIVERIES = "RELEVANT_DELIVERIES",
  REPORTER_POSITION = "REPORTER_POSITION",
  TEAM_SIZE = "TEAM_SIZE",
  TEAM_TRANSFORMATION_AGENDA = "TEAM_TRANSFORMATION_AGENDA",
  PROCESS_TRANSFORMATION_AGENDA = "PROCESS_TRANSFORMATION_AGENDA",
  OPEN_VACANCIES = "OPEN_VACANCIES",
  RELEVANT_STRUCTURE = "RELEVANT_STRUCTURE",
  TEAM_PROFILE = "TEAM_PROFILE",
}

export enum RequesterMapEnum {
  REQUESTER_REPORT = "REQUESTER_REPORT",
  MAIN_MARKET_AND_PROFESSION = "MAIN_MARKET_AND_PROFESSION",
  REQUESTER_POSITION = "REQUESTER_POSITION",
  REQUESTER_BACKGROUND = "REQUESTER_BACKGROUND",
  LENGTH_OF_STAY = "LENGTH_OF_STAY",
  REQUESTER_EXPERIENCE = "REQUESTER_EXPERIENCE",
  PREVIOUS_COMPANY_PROFILE = "PREVIOUS_COMPANY_PROFILE",
  FUNCTIONAL_CAREER_PATH = "FUNCTIONAL_CAREER_PATH",
  SPECIALIST_OR_GENERALIST = "SPECIALIST_OR_GENERALIST",
  SECTOR_MATURITY = "SECTOR_MATURITY",
  ACADEMIC_BACKGROUND = "ACADEMIC_BACKGROUND",
  NETWORKING = "NETWORKING",
}

export const QuestionTypeEnumSchema = z.nativeEnum(QuestionTypeEnum);
export const CompanyMomentEnumsSchema = z.nativeEnum(CompanyMomentEnum);
export const StrategyStatusEnumsSchema = z.nativeEnum(StrategyStatusEnum);
export const ScaleEnumSchema = z.nativeEnum(ScaleEnum);
export const BoardTypeEnumSchema = z.nativeEnum(BoardTypeEnum);
export const ProjectStageEnumSchema = z.nativeEnum(ProjectStageEnum);
export const GovernanceInstancesEnumSchema = z.nativeEnum(GovernanceInstancesEnum);
export const CompanyChartEnumSchema = z.nativeEnum(CompanyChartEnum);
export const RequesterPositionEnumSchema = z.nativeEnum(RequesterPositionEnum);
export const StrategyMapEnumSchema = z.nativeEnum(StrategyMapEnum);
export const GovernanceMapEnumSchema = z.nativeEnum(GovernanceMapEnum);
export const ProfileMapEnumSchema = z.nativeEnum(ProfileMapEnum);
export const AttributesMapEnumsSchema = z.nativeEnum(AttributesMapEnums);
export const CulturalMatchMapEnumsSchema = z.nativeEnum(CulturalMatchMapEnums);
export const ExecutionConditionsMapEnumsSchema = z.nativeEnum(ExecutionConditionsMapEnums);
export const PositionStrategyMapEnumsSchema = z.nativeEnum(PositionStrategyMapEnums);
export const RealizationsMapEnumsSchema = z.nativeEnum(RealizationsMapEnums);
export const FunctionalScopeMapEnumsSchema = z.nativeEnum(FunctionalScopeMapEnums);
export const RequesterMapEnumSchema = z.nativeEnum(RequesterMapEnum);
