import { useI18n } from "vue-i18n";
import moment from "moment";
import parsePhoneNumber from "libphonenumber-js";
import { cloneDeep, pull } from "lodash";
import type { PersonPhone } from "@/http_services/entities/types";

interface ParsedDate {
  year: string
  month: string
  day: string
}

export function formatDate(dateFormat: string, year: number | string, month: number | string, day: number | string): string {
  return dateFormat
    .replace("yy", String(year).padStart(4, "0"))
    .replace("mm", String(month).padStart(2, "0"))
    .replace("dd", String(day).padStart(2, "0"));
}

export function extractDateFromFormat(dateFormat: string, dateString: string): ParsedDate {
  const dateFormatArray = dateFormat.split("/");
  const dateStringArray = dateString.split("/");

  const yearIndex = dateFormatArray.indexOf("yy");
  const monthIndex = dateFormatArray.indexOf("mm");
  const dayIndex = dateFormatArray.indexOf("dd");

  const year = dateStringArray[yearIndex];
  const month = dateStringArray[monthIndex];
  const day = dateStringArray[dayIndex];

  return { year, month, day };
}

export function formatCurrencyFromValue(value: number | string, currency: string, locale = "pt-BR"): string {
  if (value && currency) {
    return Number(value).toLocaleString(locale, {
      style: "currency",
      currency,
    });
  }
  return "";
}

export function yearFor(date: string): string {
  const { t } = useI18n();

  if (!date)
    return t("persons.personCard.currentYear");

  return moment(date).year().toString();
}

export function parsePhone(calling_code: string, number: string): string {
  const phoneNumber = parsePhoneNumber(`${calling_code} ${number}`);
  if (phoneNumber)
    return phoneNumber.formatInternational();

  if (calling_code && number)
    return `${calling_code} ${number}`.trim();

  return "";
}

export function parsePhones(phones: PersonPhone[]): string {
  return phones.map(el => parsePhone(el.calling_code, el.number)).join(" / ");
}

export function checkActive(value: any) {
  return !value || value === "EMPTY" ? "" : "active";
}

export function removeEmpty(option: string, array: string[]) {
  if (option === "EMPTY")
    pull(array, "EMPTY");
}

export function limitedAnswer(atts: { answer: string | null }[], att: { answer: string | null }): boolean {
  const answeredAtts = atts.filter(e => e.answer !== null && e.answer !== "EMPTY");
  return att.answer === null || (att.answer === "EMPTY" && answeredAtts.length >= 2);
}

export function parseParams(params: any) {
  try {
    const parsed: Record<string, any> = cloneDeep(params);

    for (const [key, value] of Object.entries(params)) {
      if (Array.isArray(value))
        parsed[key] = value?.filter(Boolean).join(";");
    }

    return parsed;
  }
  catch (error: any) {
    console.log("error-parse-params", error);
    return params;
  }
}
