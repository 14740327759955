<script setup lang="ts">
import { sortBy } from "lodash-es";
import { format, parseISO } from "date-fns";
import { SvgIcon } from "@/components/icon";
import type { SearchCandidate } from "@/common/composables/useShortlist";
import { changeImageSize } from "@/utils/images/resize-images";

interface ExperienceSchema {
  current_company_nickname: string
  current_role_nickname: string
};

const props = withDefaults(
  defineProps<{
    person: SearchCandidate | null
    inProject?: boolean
    shadow?: boolean
    hasPlacement?: boolean
    isPlacement?: boolean
    close?: boolean
  }>(),
  {
    inProject: false,
    shadow: true,
    hasPlacement: false,
    isPlacement: false,
  },
);

const emit = defineEmits(["click:avatar", "set:placement", "change:person", "close"]);

const { t } = useI18n();

const { person, inProject, shadow, hasPlacement, isPlacement } = toRefs(props);

const {
  mutate: patchCandidate,
} = usePatchCandidate();

const miniCV = computed(() => person.value?.mini_cv);
const personData = computed(() => person.value?.person_data);
const showPersonDetail = ref(false);
const selectedPerson: Ref<SearchCandidate | null> = ref(null);

const editing = ref(props.close);

const emptyPayload = {
  nickname: null,
  current_company_nickname: null,
  current_role_nickname: null,
};

const payload = ref<{
  nickname: string | null
  current_company_nickname: string | null
  current_role_nickname: string | null
}>({
  ...emptyPayload,
},
);

const avatar = computed(() => changeImageSize(personData.value?.avatar_url, "large"));

const personName = computed(() => {
  if (!miniCV.value && !personData.value)
    return "";

  return miniCV.value?.nickname
    ? `${miniCV.value?.nickname}`
    : `${personData.value?.first_name} ${personData.value?.last_name}`;
});

const personInitials = computed(() => {
  const formatInitials = personName.value
    ?.replaceAll(" da ", " ")
    .replaceAll(" de ", " ")
    .replaceAll(" do ", " ")
    .replaceAll(" das ", " ")
    .replaceAll(" dos ", " ");

  const initials = formatInitials.split(" ");

  const first = initials[0]?.substring(0, 1);
  const last = initials[1] ? initials[1]?.substring(0, 1) : "";
  return `${first}${last}`;
});

const personPhones = computed(() => {
  return personData.value?.phones
    ?.map(item => `${item?.calling_code} ${item?.number}`)
    ?.join("/ ");
});

const personEmails = computed(() => {
  return person.value?.person_data?.email_addresses
    ?.map(item => (item.is_main ? item.email_address : null))
    ?.join("");
});

const lastUpdated = computed(() => {
  try {
    const { inserted_at, updated_at } = person.value || {};
    const date = updated_at ? parseISO(updated_at) : parseISO(inserted_at || "");
    return format(date, "dd/MM/yyyy");
  }
  catch (_) {
    return "";
  }
});

const workExperience = computed(() => {
  return {
    corporation_name: miniCV.value?.current_company_nickname,
    role_name: miniCV.value?.current_role_nickname,
    salary: null,
  };
});

const backgroundColor = computed(() => (personData.value?.avatar_url ? "#f4f4f4" : "#666"));

function showEdit() {
  payload.value = {
    nickname: miniCV.value?.nickname || null,
    current_company_nickname: miniCV.value?.current_company_nickname || null,
    current_role_nickname: miniCV.value?.current_role_nickname || null,
  };
  editing.value = true;
  emit("close");
}

function cancelEdit() {
  payload.value = { ...emptyPayload };
  editing.value = false;
}

function changePersonData() {
  patchCandidate({
    candidate_id: person.value?._id,
    candidate: {
      ...person.value,
      mini_cv: {
        ...person.value?.mini_cv,
        ...payload.value,
      },
    },
  });
  editing.value = false;
  emit("change:person");
};

function getCurrentExperience(): ExperienceSchema | null {
  const workExperience = person.value?.person_data?.work_experience || [];
  let sorted = workExperience?.sort((a, b) => (b.start_date.localeCompare(a.start_date)));

  sorted = sortBy(sorted, "end_date").reverse();

  if (sorted[0]) {
    return {
      current_company_nickname: sorted[0]?.corporation_name,
      current_role_nickname: sorted[0]?.role_name,
    };
  }

  return null;
}

function restorePersonData() {
  const corporation: ExperienceSchema = {
    current_company_nickname: "",
    current_role_nickname: "",
  };

  const currentExperience = getCurrentExperience();

  if (currentExperience) {
    corporation.current_company_nickname = currentExperience?.current_company_nickname;
    corporation.current_role_nickname = currentExperience?.current_role_nickname;
  }
  patchCandidate({
    candidate_id: person.value?._id,
    candidate: {
      ...person.value,
      mini_cv: {
        ...person.value?.mini_cv,
        current_company_nickname: corporation.current_company_nickname,
        current_role_nickname: corporation.current_role_nickname,
        nickname: `${person.value?.person_data?.first_name} ${person.value?.person_data?.last_name}`,
      },
    },
  }, {
    onSuccess: () => {
      editing.value = false;
      emit("change:person");
    },
  });
}

function getBorderStyle() {
  const isAddedInPlacement = person.value?.person_data?.is_added_in_placement;
  const addedInMapping = person.value?.person_data?.added_in_mapping;

  return isAddedInPlacement ? { border: "3px solid #040404" } : addedInMapping ? { border: "3px solid #fc0" } : {};
}

async function clickAvatar() {
  emit("click:avatar");
}

provide("onCloseDetails", () => {
  showPersonDetail.value = false;
  selectedPerson.value = null;
});

provide("selectedPerson", selectedPerson);

watchEffect(() => {
  editing.value = props.close;
});
</script>

<template>
  <div class="person-card">
    <div class="person-card__content">
      <div class="details">
        <div class="person-avatar">
          <div
            class="avatar"
            :style="getBorderStyle()"
            :class="{ 'in-project': inProject }"
            @click="clickAvatar()"
          >
            <img
              v-if="personData?.avatar_url"
              :src="avatar"
              :alt="personName"
              loading="lazy"
            >
            <p v-else class="initials">
              {{ personInitials }}
            </p>
          </div>
        </div>
        <div class="person-data">
          <div v-if="!editing" class="data">
            <div class="data__person">
              <div class="person-name">
                {{ personName }}
              </div>
              <div class="person-code">
                {{ person?.serial }}
              </div>
              <div class="person-edit">
                <SvgIcon
                  icon="edit_icon"
                  margin="none"
                  width="0.875rem"
                  height="0.875rem"
                  @click="showEdit()"
                />
              </div>
            </div>
            <div v-if="workExperience" class="data__work">
              <div class="company-name">
                {{ workExperience.corporation_name }}
              </div>
              <div v-if="workExperience.role_name" class="company-role">
                {{ workExperience.role_name }}
              </div>
              <div v-if="workExperience.salary" class="company-salary">
                $ {{ workExperience.salary }}
              </div>
            </div>
          </div>
          <div v-else class="form">
            <FormInput
              v-model="payload.nickname"
              name="name"
              :placeholder="t('projects.shortlist.labels.name')"
              autocomplete="off"
            />
            <FormInput
              v-model="payload.current_company_nickname"
              name="company"
              :placeholder="t('projects.shortlist.labels.company')"
              autocomplete="off"
            />
            <FormInput
              v-model="payload.current_role_nickname"
              name="role"
              :placeholder="t('projects.shortlist.labels.role')"
              autocomplete="off"
            />
            <div class="person-restore noselect" @click="restorePersonData()">
              {{ t("projects.construction.labels.originalRestore") }}
            </div>
          </div>

          <div class="infos">
            <div v-if="personData?.phones?.length" class="info-phone">
              <SvgIcon icon="phone_icon" width="0.875rem" height="0.875rem" />
              {{ personPhones }}
            </div>
            <div v-if="personData?.email_addresses?.length">
              <SvgIcon icon="mail_icon" width="0.875rem" height="0.875rem" />
              {{ personEmails }}
            </div>
            <div>
              <SvgIcon icon="map_pin_icon" width="0.875rem" height="0.875rem" />
              {{ personData?.address?.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="actions">
        <div class="last-update">
          <SvgIcon icon="refresh_icon" width="0.875rem" height="0.875rem" />
          {{ lastUpdated }}
        </div>
        <div v-if="!editing" class="buttons">
          <Button v-if="isPlacement" class="pl-1">
            {{ t("persons.restrictedProfile") }}
          </Button>
          <Button v-else-if="hasPlacement" disabled>
            {{ t("projects.placement.card.btnPlacement") }}
          </Button>
          <Button v-else @click="$emit('set:placement')">
            {{ t("projects.placement.card.btnPlacement") }}
          </Button>
        </div>
        <div v-else class="buttons">
          <Button variation="secondary" @click="changePersonData">
            {{ t("text.form.change") }}
          </Button>
          <Button variation="light" filled @click="cancelEdit()">
            {{ t("text.form.cancel") }}
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$avatar_size: 8.6875rem;

.person-card {
    display: flex;
    flex-direction: column;
    padding-inline: 1.25rem;

    &__content {
        display: flex;
        flex-direction: column;
        margin-block-end: 1.5rem;
        gap: 0.5rem;

        .details {
            display: grid;
            grid-template-columns: calc($avatar_size + 0.5rem) 1fr;
            align-items: center;
            gap: 3rem;

            .person-data {
              display: flex;
              flex-direction: row;
              gap: 3rem;
            }

            .person-avatar {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                gap: 1.25rem;

                .avatar {
                    cursor: pointer;
                    display: flex;
                    width: $avatar_size;
                    height: $avatar_size;
                    justify-content: center;
                    align-items: center;
                    border-radius: 100%;
                    background: v-bind("backgroundColor");
                    box-sizing: unset;

                    img {
                        width: $avatar_size;
                        height: $avatar_size;
                        border-radius: 100%;
                    }

                    .initials {
                        color: #fff;
                        font-size: 2rem;
                    }
                }

                .avatar.in-project {
                    border-color: $black-variant;
                }
            }

            .form {
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              flex: 1;
              gap: 0.25rem;

              .person-restore {
                  cursor: pointer;
                  color: $primary;
                  padding-block-start: 0.25rem;
                  font-size: 0.75rem;
              }
            }

            .data {
                display: flex;
                flex-direction: column;
                flex: 1;
                gap: 0.5625rem;

                &__person {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 1rem;

                    .person-name {
                        height: fit-content;
                        color: $primary;
                        font-size: 1.125rem;
                        font-weight: bold;
                        text-transform: initial;
                    }

                    .person-code {
                        height: fit-content;
                        color: $gray-dark;
                        font-size: 0.75rem;
                    }

                    .person-edit {
                        cursor: pointer;
                        height: fit-content;
                    }
                }

                &__work {
                    display: flex;
                    flex-direction: column;
                    gap: 0.5625rem;

                    .company-name {
                        color: $gray-dark;
                        font-size: 0.875rem;
                        font-weight: 600;
                        letter-spacing: 0.036rem;
                    }

                    .company-role {
                        color: $gray-dark;
                        font-size: 0.75rem;
                        font-weight: 600;
                    }
                }
            }

            .infos {
                display: flex;
                flex-direction: column;
                flex: 1;
                font-size: 0.8125rem;
                gap: 0.5625rem;

                .info-phone {
                    font-size: 0.6875rem;
                }
            }
        }

        .actions {
            display: grid;
            grid-template-columns: calc($avatar_size + 0.5rem) 1fr;
            gap: 3rem;

            .buttons {
              display: flex;
              flex-direction: row;
              gap: 1rem;
            }

            .last-update {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 0.75rem;
            }
        }
    }
}
</style>
