<script lang="ts">
import type { PropType } from "vue";
import { defineComponent } from "vue";
import { ButtonIcon } from "@/components/button";
import EditIcon from "@/assets/images/edit_icon.svg";

type Tag = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

export default defineComponent({
  name: "SectionTitle",
  components: {
    ButtonIcon,
    EditIcon,
  },
  props: {
    tag: {
      type: String as PropType<Tag>,
      default: "h2",
    },
    title: {
      type: String,
      required: true,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    showEdit: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["click:edit"],
});
</script>

<template>
  <component
    :is="tag"
    class="section-title"
  >
    {{ title }}

    <ButtonIcon
      v-show="!isEditing && showEdit"
      class="edit-icon"
      @click="$emit('click:edit')"
    >
      <EditIcon />
    </ButtonIcon>
  </component>
</template>

<style lang="scss" scoped>
.section-title {
  margin-bottom: 1rem;
  color: $primary;
  font-weight: bold;
  text-transform: uppercase;
}

$h1-font-size: 1.125rem;

@for $i from 1 through 6 {
  $current-font-size: $h1-font-size - 0.125rem;

  h#{$i}.section-title {
    font-size: $current-font-size;
  }
}

.edit-icon {
  margin-left: 0.5rem;

  svg {
    width: var(--size);
    height: var(--size);
    stroke: $gray-border;

    --size: 0.875rem;
  }
}
</style>
