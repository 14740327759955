<script setup lang="ts">
import CulturalMatch from "./CulturalMatch.vue";
import ExportPage from "./ExportPage.vue";

const props = withDefaults(
  defineProps<{
    isExport: boolean
    personName?: string | null
    activitie?: any | null
    selectedValue?: string | null
    showLegend?: boolean
    rows: any | null
    score: number | null
    culturalMatch: any
  }>(),
  {
    isExport: false,
    activitie: null,
    personName: null,
    selectedValue: null,
    showLegend: false,
    rows: null,
    score: null,
  },
);

const { t } = useI18n();

const padding = !props.score ? "2rem" : 0;

function formatScore(score: number): string | number {
  try {
    return (
      (!Number.isInteger(score) ? score.toFixed(2).replace(".", ",") : score.toFixed(0)) ?? 0
    );
  }
  catch (error) {
    return 0;
  }
}
</script>

<template>
  <ExportPage
    :is-export="isExport"
    :person-name="personName"
    :page-name="t('exportPDF.summaryConstructionBeta.culturalMatch')"
  >
    <div class="pdf-cultural-match">
      <div v-if="score" class="box-score">
        <div class="box-score-total">
          {{ formatScore(score || 0) }}%
        </div>
      </div>
      <CulturalMatch
        :construction="rows"
        :cultural-match="culturalMatch"
        :show-legend="showLegend"
      />
    </div>
  </ExportPage>
</template>

<style lang="scss" scoped>
:deep(.attributes-component) {
  gap: 1.75rem;
  padding-block: v-bind("padding");
}

.pdf-cultural-match {
  display: flex;
  flex-direction: column;

  .box-score {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin-block-end: 1rem;

    &-total {
      display: flex;
      width: 5rem;
      min-width: 5rem;
      max-width: 5rem;
      height: 2.625rem;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 1px solid $yellow;
      background: $yellow;
      border-radius: 6px;
      color: $white-full;
      font-size: 1.25rem;
      font-weight: bold;
    }
  }

  .attributes-content {
    padding-inline: 0 3rem;

    &__header {
      align-items: center;
      text-align: center;
    }

    .help {
      display: none;
    }
  }
}
</style>
