import { z } from "zod";

const EntityInDatabaseSchema = z.object({
  _version: z.number(),
  is_active: z.boolean(),
  serial: z.number(),
  user_creator_id: z.nullable(z.string()),
  inserted_at: z.string(),
  updated_at: z.string(),
});

export const EntitySchema = EntityInDatabaseSchema.extend({
  _id: z.string(),
});

// TODO: is returning with underscore on private fields
