<script setup lang="ts">
import { useI18n } from "vue-i18n";
import Form from "./Form.vue";
import { ButtonIcon } from "@/components/button";
import { SvgIcon } from "@/components/icon";
import { TrashButton } from "@/components/trash-button";

interface WorkExperienceType {
  id: string
  role_name: string
  start_date: string
  is_current: boolean
  end_date: string | null
  description: string | null
  job_location: string | null
  salary: any | null
  incentive_compensation: any | null
  conditions: any | null
  realizations: any | null
  is_parsed: boolean | undefined | null
  company: {
    corporation_id: string
    name: string
    corporation_data: {
      _id: string
      name: string
      url: string
      avatar_url: string
      business_categories: any[]
      business_function: string[]
    }
  }
}

interface PropsType {
  workExperiences: WorkExperienceType[]
  autoFilledFields: any
}

const props = defineProps<PropsType>();
const emit = defineEmits(["click:add", "click:remove", "click:remove-edit"]);

const { t } = useI18n();

const work_experiences: Ref<WorkExperienceType[]> = toRef(props, "workExperiences");
</script>

<template>
  <div class="work-form">
    <div class="title-wrapper">
      <span>{{ t("persons.forms.workExperience.professionalData") }}</span>
    </div>
    <div
      v-for="(experience, index) in work_experiences"
      :key="experience"
    >
      <div class="subtitle-wrapper">
        <span>{{ t("persons.forms.workExperience.title") }}</span>
        <TrashButton
          v-if="experience?.is_parsed"
          :show-icon="true"
          svg-icon="trash_icon"
          @click="$emit('click:remove', index)"
        />
        <ButtonIcon v-else class="remove-button" @click="$emit('click:remove', index)">
          <SvgIcon icon="trash_icon" width="20" height="20" margin="none" />
        </ButtonIcon>
      </div>
      <Form :experience="experience" :experience-index="index" :experience-filled-field="autoFilledFields[index]" />
    </div>

    <Button class="add-button" variation="secondary" @click="$emit('click:add')">
      {{ t("persons.forms.workExperience.addWorkExperience") }}
    </Button>
  </div>
</template>

<style scoped lang="scss">
.work-form:deep {
  .form-item + .form-item {
    margin-top: 40px;
  }
}

.title-wrapper {
  display: flex;
  width: 100%;
  height: 50px;

  span {
    color: $primary;
    font-family: "Open Sans";
    font-size: 1.125rem;
    font-weight: bold;
    text-transform: uppercase;
  }
}

.subtitle-wrapper {
  display: flex;
  height: 50px;
  flex-grow: 1;
  align-items: flex-start;
  justify-content: space-between;

  span {
    color: $primary;
    font-family: "Open Sans";
    font-size: 18px;
  }

  .remove-button {
    color: $gray-light;
  }
}

.add-button {
  width: 100%;
  font-size: 0.8125rem;
}
</style>
