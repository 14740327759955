<script lang="ts">
import { useI18n } from "vue-i18n";
import Form from "./Form.vue";
import { Button2, ButtonIcon } from "@/components/button";
import { TrashButton } from "@/components/trash-button";
import { SvgIcon } from "@/components/icon";

export default defineComponent({
  name: "LanguagesForm",
  components: {
    Button: Button2,
    TrashButton,
    ButtonIcon,
    SvgIcon,
    Form,
  },
  props: {
    languages: Array,
    languagesEdit: Array,
    autoFilledFields: Array,
    autoFilledEditFields: Array,
  },
  emits: ["click:add", "click:remove", "click:removeEdit"],
  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
});
</script>

<template>
  <div class="work-form">
    <div class="title-wrapper">
      <span>{{ t("persons.forms.language.title") }}</span>
    </div>
    <div v-for="(language, index) in languages" :key="`language-${index}`">
      <div class="subtitle-wrapper">
        <ButtonIcon class="remove-button" @click="$emit('click:remove', index)">
          <SvgIcon icon="trash_icon" width="20" height="20" margin="none" />
        </ButtonIcon>
      </div>
      <Form
        :language="language"
        :language-auto-filled-fields="autoFilledFields[index]"
      />
    </div>

    <div v-for="(language, index) in languagesEdit" :key="`language-${index}`">
      <div class="subtitle-wrapper">
        <TrashButton
          class="remove-button"
          :show-icon="true"
          svg-icon="trash_icon"
          @click="$emit('click:removeEdit', index)"
        />
      </div>
      <Form
        v-if="languagesEdit?.length && autoFilledFields?.length && autoFilledEditFields?.length"
        :language="language"
        :language-edit="languagesEdit[index]"
        :language-auto-filled-fields="autoFilledFields[index]"
        :auto-filled-edit-fields="autoFilledEditFields[index]"
      />
    </div>

    <Button class="add-button" variation="secondary" @click="$emit('click:add')">
      {{ t("persons.forms.language.addLanguage") }}
    </Button>
  </div>
</template>

<style scoped lang="scss">
.title-wrapper {
  display: flex;
  width: 100%;
  height: 0;

  span {
    color: $primary;
    font-family: "Open Sans";
    font-size: 1rem;
  }
}

.subtitle-wrapper {
  display: flex;
  flex-grow: 1;

  .remove-button {
    margin-bottom: 1rem;
    margin-left: auto;
    color: $gray-light;
  }
}

.add-button {
  width: 100%;
  font-size: 0.8125rem;
}
</style>
