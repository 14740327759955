<script setup lang="ts">
import { Avatar } from "@/components/avatar";
import Calendar from "@/components/inputs/modalInputs/Calendar.vue";
import { getPerson } from "@/http_services/entities/persons";
import PersonDetail from "@/persons/pages/persons/components/PersonDetail/PersonDetail.vue";
import { SvgIcon } from "@/components/icon";

const props = defineProps<{ id: string }>();

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const { data: project, error } = useProject(String(route.params.id));

interface PlacementInfo {
  start_date?: Date | string | null
  salary?: string
  total_cash?: string
  long_term_incentive?: string
  short_term_incentive?: string
  other_incentive?: string
}

const placement: Ref<{ first_name: string; last_name: string; avatar_url: string } | undefined> = ref();
const placementInfo: Ref<PlacementInfo> = ref({});

function format(date: Date | string | null | undefined) {
  return date ? new Date(date).toLocaleDateString() : null;
}

watchEffect(() => {
  placement.value = project.value?.placement_details?.candidate?.person_data || {};

  placementInfo.value = {
    start_date: format(project.value?.placement_details?.start_date),
    salary: project.value?.placement_details?.salary,
    total_cash: project.value?.placement_details?.total_cash,
    long_term_incentive: project.value?.placement_details?.long_term_incentive,
    short_term_incentive: project.value?.placement_details?.short_term_incentive,
    other_incentive: project.value?.placement_details?.other_incentive,
  };
});

const showPersonDetail = ref(false);

const selectedPerson = ref();

async function onSelectRequester(id: string) {
  const data = await getPerson(id);

  if (data) {
    selectedPerson.value = toRaw(data);
    showPersonDetail.value = true;
  }
}
provide("onCloseDetails", () => {
  showPersonDetail.value = false;
  selectedPerson.value = undefined;
});

provide("selectedPerson", selectedPerson);

const stageTabs = [
  {
    key: "activities",
    name: "Placement",
    path: `/projects/${props.id}/placement/activities`,
  },
];

async function save() {
  await patchPlacement(props.id, {
    ...project.value?.placement_details,
    ...placementInfo.value,
    start_date: placementInfo.value.start_date
      ? new Date(placementInfo.value.start_date).toISOString()
      : null,
  });
}

const currentRoute = computed(() => stageTabs.findIndex(tab => tab.key === router.currentRoute.value.meta.tab));

const debounceSave = useDebounceFn(async () => await save, 1000);

function goTo(tabIndex: number) {
  if (!stageTabs || !stageTabs[tabIndex])
    return;

  router.push(stageTabs[tabIndex].path);
}
</script>

<template>
  <section class="project-activities">
    <div v-if="error">
      could not load
    </div>
    <div v-if="project" class="project-container">
      <ProjectStages :id="props.id" />
      <ProjectTabs :stage-tabs="stageTabs" :active="currentRoute" class="tabs" @change="goTo($event)" />
      <div v-if="project" class="project-wrapper project-wrapper-placement">
        <div class="placement-wrapper">
          <div class="placement-card-title">
            {{ t("projects.placement.card.title") }}
          </div>
        </div>
        <div class="placement-card">
          <div>
            <div class="palcement-icon">
              <Avatar
                :src="placement?.avatar_url"
                size="8rem"
                placeholder="project_ideal_profile"
                type="native"
                class="avatar"
                :has-alternative-icon="false"
              />
            </div>
            <div class="icon-text">
              <SvgIcon
                margin="none"
                color="#FFB600"
                width="1.125rem"
                height="1.125rem"
                icon="star_icon"
              />
              {{ t("projects.placement.label") }}
            </div>
          </div>
          <div class="placement-items">
            <div class="name">
              {{ placement?.first_name }}
              {{ placement?.last_name }}
            </div>
            <div>
              <span class="title"> {{ t("projects.placement.card.position") }}:</span>
              {{ project.position_name }}
            </div>
            <div class="item-input">
              <span class="title"> {{ t("projects.placement.card.company") }}:</span>
              {{ project.corporation?.corporation_name }}
            </div>
            <div class="input-grid">
              <div class="title">
                {{ t("projects.placement.card.salary") }}:
              </div>
              <div>
                <FormInput
                  v-model="placementInfo.salary"
                  money
                  currency="BRL"
                  :placeholder="t('projects.placement.card.salary')"
                  @input="debounceSave"
                />
              </div>
              <div class="title" style="align-self: start">
                {{ t("projects.construction.labels.totalCash") }}:
              </div>
              <div>
                <TextArea
                  v-model="placementInfo.total_cash"
                  :placeholder="t('projects.construction.labels.totalCash')"
                  :short-text="true"
                  @input="debounceSave"
                />
              </div>
              <div class="title" style="align-self: start">
                {{ t("projects.placement.card.shortTermIncentive") }}:
              </div>
              <div>
                <TextArea
                  v-model="placementInfo.short_term_incentive"
                  :placeholder="t('projects.placement.card.shortTermIncentive')"
                  :short-text="true"
                  @input="debounceSave"
                />
              </div>
              <div class="title" style="align-self: start">
                {{ t("projects.placement.card.longTermIncentive") }}:
              </div>
              <div>
                <TextArea
                  v-model="placementInfo.long_term_incentive"
                  :placeholder="t('projects.placement.card.longTermIncentive')"
                  :short-text="true"
                  @input="debounceSave"
                />
              </div>
              <div class="title" style="align-self: start">
                {{ t("projects.placement.card.otherIncentive") }}:
              </div>
              <div>
                <TextArea
                  v-model="placementInfo.other_incentive"
                  :placeholder="t('projects.placement.card.otherIncentive')"
                  :short-text="true"
                  @input="debounceSave"
                />
              </div>
              <div class="title">
                {{ t("projects.placement.card.placementStartDate") }}:
              </div>
              <div class="item-input">
                <Calendar
                  v-model="placementInfo.start_date"
                  :placeholder="t('projects.placement.card.placementStartDate')"
                  class="date-input"
                  @date-select="debounceSave"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="placement-warning" style="cursor: pointer" @click="onSelectRequester(String(project?.placement_details?.candidate?.person_id))">
          {{ t("projects.placement.card.placementUpdateWarning") }}
        </div>
      </div>
      <PersonDetail :visible="showPersonDetail" />
    </div>
  </section>
</template>

<route lang="yaml">
meta:
  layout: project-stage
  stage: placement
  </route>

<style scoped lang="scss">
.project-activities {
  min-height: 100vh;
}
.project-container {
  padding-top: 3.75rem;
}
.project-wrapper {
  display: flex;
  flex-direction: column;
  padding: 3.5rem 1.5rem;
  padding-block-end: 2.5rem;
  background: $white-full;
  align-items: center;
}

.placement-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.5rem 3.5rem 4rem;
}

.placement-card {
  display: flex;
  gap: 2rem;
}

.placement-card-title {
  color: $red-violet;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.7px;
  line-height: 13.2px;
  text-transform: uppercase;
}

.placement-items {
  display: flex;
  flex-direction: column;
  font-size: 1.125rem;
  gap: 0.5rem;

  .name {
    padding-bottom: 0.25rem;
    font-weight: 700;
  }

  .title {
    font-weight: 600;
    letter-spacing: 0.63px;
    line-height: 13.2px;
  }
}

.item-input {
  display: flex;
  height: 28px;
  align-items: center;
  gap: 0.5rem;
}

.input-grid {
  display: grid;
  grid-template-columns: 1fr 202px;
  gap: 0.5rem;
  row-gap: 0.5rem;
  column-gap: 0.5rem;
  align-items: center;
  grid-template-rows: repeat(3, 1fr);

  .bonus-grid {
    grid-row-start: 2;
    grid-row-end: 5;
  }
}

.placement-warning {
  padding: 0.5rem 1rem;
  margin-block: 2.5rem;
  border: 1px solid $red-violet;
  border-radius: 6px;
  font-size: 0.75rem;
  letter-spacing: 0.42px;
  line-height: 13.2px;
  text-transform: uppercase;
  transition: 200ms;
  &:hover {
    background-color: $red-violet-medium;
    color: $white-full;
  }
}

.icon-text {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  font-weight: 700;
  gap: 0.25rem;
  letter-spacing: 0.49px;
  line-height: 13.2px;
  padding-block: 0.5rem;
  text-transform: uppercase;
}
.palcement-icon {
  border-radius: 100%;
  padding: 0;
  display: grid;
  place-items: center;
  aspect-ratio: 1;
}
.avatar {
  border: 2px solid rgb(4, 4, 4);
}
.date-input {
  font-size: 1.125rem;
  :deep(.p-inputtext) {
    color: $gray;
  }
}
</style>
