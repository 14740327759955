<script lang="ts">
import { defineComponent, ref } from "vue";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiTriangle, mdiTriangleOutline } from "@mdi/js";

export default defineComponent({
  name: "ContentHeader",
  components: {
    SvgIcon,
  },
  props: {
    skip: {
      type: Number,
      default: 0,
    },
    top: {
      type: Number,
      default: 0,
      required: false,
    },
    className: {
      type: String,
      default: ".main-wrapper-box",
      required: false,
    },
  },
  setup(props) {
    const { className, top } = toRefs(props);
    const hovering = ref(false);
    const rootElement = document.querySelector(className.value);

    const scrollToTop = () => {
      rootElement?.scrollTo({
        top: top.value,
        left: 0,
        behavior: "smooth",
      });
    };

    return {
      hovering,
      mdiTriangleOutline,
      mdiTriangle,
      scrollToTop,
    };
  },
});
</script>

<template>
  <div
    class="scrollto-top"
    @mouseenter="hovering = true"
    @mouseleave="hovering = false"
    @click="scrollToTop()"
  >
    <div class="scrollto-top__icon">
      <SvgIcon type="mdi" :path="hovering ? mdiTriangle : mdiTriangleOutline" size="24" />
    </div>
  </div>
</template>

<style scoped lang="scss">
$padding-clamp: clamp(1rem, calc(0.1923076923076924rem + 3.5897435897435894vw), 4.5rem);

.scrollto-top {
  position: fixed;
  right: $padding-clamp;
  bottom: 5%;
  display: flex;
  width: 3.5rem;
  height: 3.5rem;
  align-items: center;
  justify-content: center;
  border: 1px solid $primary;
  border-radius: 0.3rem;
  background: transparent;
  color: $primary;
  cursor: pointer;
  z-index: 998;

  &__icon {
    svg {
      height: 1.375rem;
      color: $primary;
    }
  }

  &:hover {
    background: $primary;
    box-shadow: 0 2px 2px 2px rgb(0 0 0 / 10%);
    color: $white-full;

    svg {
      color: $white-full;
    }
  }
}
</style>
