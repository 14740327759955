<script setup lang="ts">
import { defineEmits, markRaw, ref } from "vue";
import { useI18n } from "vue-i18n";
import { ComingSoon, Projects } from "./components";
import { useOnScroll } from "@/composables";
import type { ProjectsParticipated } from "@/entities/Person/activity";

const props = defineProps({
  projects: {
    type: Array as () => ProjectsParticipated[],
    required: true,
  },
});
const emit = defineEmits(["onMore"]);
const { t } = useI18n();

const projectsProps = ref({ projects: props.projects });

watch(props, () => {
  projectsProps.value.projects = props.projects;
});

const tabs = computed(() => [
  {
    id: "historic",
    label: t("persons.activity.tabs.historic"),
    component: markRaw(ComingSoon),
    props: {},
  },
  {
    id: "projects",
    label: t("persons.activity.tabs.projects"),
    component: markRaw(Projects),
    props: projectsProps.value,
  },
  {
    id: "activities",
    label: t("persons.activity.tabs.activities"),
    component: markRaw(ComingSoon),
    props: {},
  },
  {
    id: "relationship",
    label: t("persons.activity.tabs.relationship"),
    component: markRaw(ComingSoon),
    props: {},
  },
]);

const defaultTab = ref(tabs.value[1]);

const onMore = () => emit("onMore");

const { scrollRef } = useOnScroll(onMore);

const activityTabs = ["historic", "activities", "relationship"];
</script>

<template>
  <div class="person-activity">
    <nav id="header-tabs" class="person-activity__header">
      <ul>
        <li
          v-for="tab in tabs"
          :key="tab.id"
          class="person-activity__header-item"
          :class="[
            { '--active': tab.id === defaultTab.id },
          ]"
          @click="defaultTab = tab"
        >
          <span>{{ tab.label }}</span>
        </li>
      </ul>
    </nav>
    <div class="person-activity__content">
      <component
        :is="defaultTab.component"
        v-if="activityTabs.includes(defaultTab.id)"
        v-bind="defaultTab.props"
      />
      <ul v-else ref="scrollRef">
        <component :is="defaultTab.component" v-bind="defaultTab.props" />
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.person-activity {
  display: flex;
  height: 11.5rem;
  flex-direction: column;
  border: 1px solid $gray-x14;
  border-radius: 0.313rem;
  background: $white-full;

  &__header {
    display: flex;
    height: 3.313rem;
    align-items: center;
    padding: 0.719rem 2.125rem;
    border-bottom: 1px solid $gray-x14;
    background: $black-transparent;

    ul {
      display: flex;
    }

    &-item {
      display: flex;
      width: fit-content;
      height: 30px;
      align-items: center;
      justify-content: center;
      padding: 0 0.5rem;
      margin-right: 3rem;
      cursor: pointer;

      span {
        color: $gray-dark;
        font-size: 0.75rem;
        font-weight: 300;
        letter-spacing: 0.42px;
        text-transform: uppercase;
      }
    }

    .--active {
      border-radius: 8px;
      background: $orange-medium;

      span {
        color: $white-full;
      }
    }
  }

  &__content {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 0.875rem 0.5rem 0.875rem 2.5rem;
    background: $white-full;

    ul {
      max-height: 6.8rem;
      overflow-y: scroll;
      scroll-behavior: smooth;
      margin: 0;

      &::-webkit-scrollbar {
        width: 7px;
        -webkit-appearance: none;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgb(0 0 0 / 50%);
        box-shadow: 0 0 1px rgb(255 255 255 / 50%);
      }
    }
  }
}
</style>
