<script setup lang="ts">
import { debounce } from "lodash-es";
import ShortlistTabs from "./ShortlistTabs.vue";
import CardPerson from "./CardPerson.vue";
import { SvgIcon } from "@/components/icon";
import type { SearchCandidate } from "@/common/composables/useShortlist";
import { Modal } from "@/components/modal";
import PersonDetail from "@/persons/pages/persons/components/PersonDetail/PersonDetail.vue";
import { getPerson } from "@/http_services/entities/persons";
import { ShortlistEnum } from "@/schemas/project/pdf";
import type { InterviewCandidates } from "@/types/candidates";

interface StageTabsType {
  key: string
  name: string
  path: string
}

interface PropsSchema {
  project: any
  candidate: SearchCandidate | null
  error: Error | null
  score?: string | number | null
  isEditing?: boolean
  possibleToEdit?: boolean
  showHeader?: boolean
  showButtonEdit?: boolean
  showButtonReevaluate?: boolean
  icon?: string
  title?: string
}

const props = withDefaults(defineProps<PropsSchema>(), {
  possibleToEdit: true,
  showHeader: true,
  showButtonEdit: true,
  showButtonReevaluate: true,
});
const emit = defineEmits(["change:person", "edit"]);

const { t } = useI18n();

const { project, candidate, error } = toRefs(props);

const route = useRoute();
const router = useRouter();

const projectId = computed(() => String(route.params.id));
const personId = computed(() => String(route.params.person));

const stageTabs = ref<StageTabsType[]>([]);
const currentRoute = ref(0);

const hasSubmission = ref(false);
const showPersonDetail = ref(false);
const closeMiniCV = ref(false);

const selectedCandidate: Ref<SearchCandidate | null> = ref(null);

const {
  data: listCandidates,
  isPending: listPending,
} = useGetCandidatesFromShortlist(String(route.params.id));

const {
  mutate: finishProject,
} = useFinishProject(projectId.value);
const {
  mutate: candidateInterviewsActivities,
} = useCandidateInterviewsActivitiesWithBody(String(route.params.id));

const miniCV = computed(() => candidate.value?.mini_cv);
const personData = computed(() => candidate.value?.person_data);

const personName = computed(() =>
  miniCV.value?.nickname
    ? `${miniCV.value?.nickname}`
    : `${personData.value?.first_name} ${personData.value?.last_name}`);

const defaultTag = computed(() => [
  {
    key: "activities",
    name: t("projects.details.activities.tabsCandidatesEnum.activities"),
    path: `/projects/${String(route.params.id)}/shortlist/activities`,
  },
  {
    key: "funnel",
    name: t("projects.shortlist.funnel.title"),
    path: `/projects/${route.params.id}/shortlist/funnel`,
  },
  {
    key: "adherence-table",
    name: t(`projects.shortlist.pdf.${ShortlistEnum.ADHERENCE_TABLE}`),
    path: `/projects/${route.params.id}/shortlist/adherence-table`,
  },
]);

function goTo(tabIndex: number) {
  closeMiniCV.value = false;
  if (tabIndex >= 0 && stageTabs.value)
    router.push(stageTabs.value[tabIndex]?.path || "#");
}

function changePerson(event: Event) {
  emit("change:person", event);
}

function formatScore(score: string | number | null | undefined) {
  if (!score)
    return 0;
  return (
    (!Number.isInteger(score) ? score?.toFixed(2).replace(".", ",") : score?.toFixed(0)) ?? 0
  );
}

async function clickAvatar() {
  if (candidate.value) {
    const data = await getPerson(candidate.value.person_id);
    if (data) {
      selectedCandidate.value = toRaw(data);
      showPersonDetail.value = true;
    }
  }
}
const {
  capture,
} = useTracking();

async function sendsPersonToPlacement() {
  await setPlacement({
    candidate: {
      person_id: candidate.value?.person_id,
      project_id: projectId.value,
    },
  }).then(() => {
    capture("imua-project-stage-updated", {
      project_id: projectId.value,
      from: "interviews",
      to: "placement",
    });

    capture("imua-project-placement-added", {
      project_id: projectId.value,
      person_id: candidate.value?.person_id,
    });

    stageShortlistToUnderoffer(projectId.value).then(() => {
      stageUnderofferToPlacement(projectId.value).then(() => {
        finishProject(projectId.value, {
          onSuccess: () => {
            hasSubmission.value = !hasSubmission.value;
            router.push(`/projects/${projectId.value}/placement/activities`);
          },
        });
      });
    });
  });
}

const debouceCandidates = debounce(changePerson, 300);

provide("onCloseDetails", () => {
  showPersonDetail.value = false;
  selectedCandidate.value = null;
});

provide("selectedPerson", selectedCandidate);

watchEffect(() => {
  if (!listCandidates.value) {
    stageTabs.value = defaultTag.value;
  }
  else {
    stageTabs.value = [
      ...defaultTag.value,
      ...listCandidates.value.slice()
        .map((candidate: SearchCandidate) => ({
          key: candidate?.person_id,
          name: `${candidate?.person_data?.first_name} ${candidate?.person_data?.last_name}`,
          path: `/projects/${String(route.params.id)}/shortlist/${candidate.person_id}/evaluation`,
        })),
    ];
  }
  if (personId.value)
    currentRoute.value = stageTabs.value.findIndex(tab => tab.key === personId.value);
});

async function goToActivity(person_id: string) {
  candidateInterviewsActivities(
    {
      project_id: String(route.params.id),
      candidate_person_id: person_id,
    },
    {
      onSuccess: (data: InterviewCandidates[]) => {
        if (data && data.length > 0) {
          const firstItemIdx = (data || [])[0]?._id;
          if (!firstItemIdx)
            return;
          router.push(
            `/projects/${route.params.id}/interviews/person/${person_id}/activity/${firstItemIdx}?redirect_source=${route.meta.tab}`,
          );
        }
      },
    },
  );
}
function editSection() {
  emit("edit");
};
</script>

<template>
  <section class="project-activities">
    <div v-if="error">
      could not load
    </div>
    <div class="project-container">
      <ProjectStages :id="String(route.params.id)" />
      <div v-if="project && !listPending" class="project-wrapper">
        <ProjectTabs :stage-tabs="stageTabs" :active="currentRoute" class="tabs" @change="goTo($event)" />
        <div class="project-header">
          <ShortlistTabs />
          <CardPerson
            :person="candidate"
            :has-placement="!!project.placement_details.candidate"
            :shadow="false"
            :close="closeMiniCV"
            @change:person="debouceCandidates"
            @click:avatar="clickAvatar()"
            @set:placement="hasSubmission = !hasSubmission"
            @close="closeMiniCV = true"
          />
        </div>
        <div class="project-content">
          <div v-if="showHeader" class="content-header">
            <div class="header-title">
              <div v-if="icon" class="header-title-icon">
                <SvgIcon :icon="icon" width="1.5rem" height="1.5rem" margin="none" />
              </div>
              <div v-if="title" class="header-title-text">
                {{ title }}
              </div>
              <Button v-if="!isEditing && possibleToEdit && showButtonEdit" class="edit" @click="editSection()">
                {{ t("corporations.corporationCard.edit") }}
              </Button>
            </div>
            <div class="reassess">
              <Button
                v-if="!isEditing && possibleToEdit && showButtonReevaluate && candidate"
                class="edit"
                @click="goToActivity(candidate?.person_id)"
              >
                {{ t("projects.details.constructionGuide.ranking.reassess") }}
              </Button>
              <div v-if="score && !isEditing" class="header-score">
                {{ formatScore(score) }}%
              </div>
            </div>
          </div>
          <div class="content-body" :class="{ 'header-hidde': !showHeader }">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </section>
  <Modal
    v-if="hasSubmission"
    :button-text="t('persons.addToProjectBtn.confirm')"
    :secondary-button-text="t('projects.details.activities.form.labels.cancelRemove')"
    secondary-variation="light"
    :on-click="sendsPersonToPlacement"
    :secondary-on-click="() => (hasSubmission = false)"
    :on-close="() => (hasSubmission = false)"
  >
    <template #message>
      {{ t("projects.placement.card.definePlacement").toLocaleUpperCase() }}
      <strong>{{ personName.toLocaleUpperCase() }}</strong>
    </template>
  </Modal>
  <PersonDetail :visible="showPersonDetail" @close:deails="$emit('change:person')" />
</template>

<style lang="scss" scoped>
.project-activities {
  min-height: 100vh;
}

.project-container {
  padding-top: 3.75rem;
}

.project-wrapper {
  background: white;
  padding-bottom: 2.5rem;
  margin-bottom: 2.5rem;
}

.project-header {
  margin-inline: 2.5rem;
  margin-block-end: 3rem;
  border-bottom: 1px solid #ccc;
}

.project-content {
  margin-inline: 3rem;

  .content-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .header-title {
      display: flex;
      flex-direction: row;
      align-items: center;

      &-icon {
        display: flex;
        width: 3.125rem;
        height: 3.125rem;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-right: 0.625rem;
        background: $gray-border;
      }

      &-text {
        color: $gray-dark;
        font-size: 1.25rem;
        font-weight: 700;
        text-transform: uppercase;
      }

      .edit {
        margin-inline-start: 1.25rem;
      }
    }

    .reassess {
      display: flex;
      gap: 1.25rem;
    }

    .header-score {
      min-width: 4.6806rem;
      height: fit-content;
      padding: 0.4375rem 0.5rem;
      background: $yellow;
      border-radius: 6px;
      color: $white-full;
      font-size: 1.25rem;
      font-weight: 700;
      text-align: center;
    }
  }

  .content-body.header-hidde {
    margin-block-start: 0;
    margin-block-end: 2.7rem;
  }

  .content-body {
    margin-block: 2.7rem;

    .tooltip-body {
      position: absolute;
      width: 12rem;
      padding: 0.5rem;
      border-radius: 8px;
      margin-top: -2.5rem;
      margin-left: 8.5rem;
      background: #fff;
      box-shadow: 0 2px 3px #0000003b;
      font-size: 0.75rem;
      font-weight: normal;
      line-height: 1.06rem;
    }
  }
}
</style>
