<script lang="ts">
import { defineComponent, onMounted, provide } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { mdiChevronLeft } from "@mdi/js";
import SvgIcon from "@jamescoyle/vue-icon";
import PersonCard from "../../components/PersonCard.vue";

import {
  generatePersonApiMethods,
  generateTabs,
} from "./Details";
import { PageWrapper } from "@/components/page_wrapper";
import { Tabs } from "@/components/tab";

export default defineComponent({
  name: "PersonDetails",
  components: {
    SvgIcon,
    PageWrapper,
    Tabs,
    PersonCard,
  },
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const route = useRoute();
    const { currentTab, tabs, availableTabs } = generateTabs(t);
    const { person, fetchPerson } = generatePersonApiMethods(router);

    provide("person", person);

    onMounted(() => {
      const { params: { id } } = route;
      if (id)
        fetchPerson(id as string);

      if (window && window.location.hash)
        currentTab.value = window.location.hash.replace("#", "");
    });

    return {
      t,
      currentTab,
      tabs,
      availableTabs,
      person,
      chevron: mdiChevronLeft,
    };
  },
});
</script>

<template>
  <PageWrapper content-columns="1fr">
    <template #content>
      <div>
        <div class="back-to-list" @click="$router.push(`/persons`)">
          <SvgIcon type="mdi" :path="chevron" size="16" />
          <span>{{ t("corporations.details.backToList") }}</span>
        </div>

        <PersonCard />
      </div>

      <Tabs v-model:activeTab="currentTab" :tabs="tabs">
        <template #tab-content="{ props, events, component }">
          <component v-bind="props" :is="component" v-on="events" />
        </template>
      </Tabs>
    </template>
  </PageWrapper>
</template>

<style lang="scss" scoped>
.back-to-list {
  font-size: 0.625rem;
  font-weight: 600;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;

  span {
    vertical-align: super;
  }

  &:hover {
    span {
      color: lighten($gray-dark, 20%);
    }
  }
}

.tab-container {
  margin: 1rem 2rem 2rem 1rem;
}
</style>
