import { authService, resumeService } from "../client";
import type { Success } from "../types";
import { NotFoundError } from "../errors";
import type {
  LoginRequest,
  LoginResponse,
  RefreshToken,
  RefreshTokenRequest,
  UpdatePasswordResquest,
} from "./types";
import { env } from "@/env";

export async function login(data: LoginRequest): Promise<Success<LoginResponse>> {
  const response = await authService.post("/identity/callback", data);
  return response.data;
}

export async function loginMsParser() {
  try {
    const body = new FormData();

    body.append("username", env.VITE_APP_RESUME_PARSER_USER);
    body.append("password", env.VITE_APP_RESUME_PARSER_PASSWORD);

    const { data } = await resumeService.post("login/access-token", body);

    resumeService.client().defaults.headers.Authorization = `Bearer ${data.access_token}`;

    localStorage.setItem("imua_parser_acess_token", data.access_token);
  }
  catch (error) {
    if (error instanceof NotFoundError)
      return null;

    throw error;
  }
}

export async function refreshToken(data: RefreshTokenRequest): Promise<
  Success<RefreshToken>
> {
  const response = await authService.post("/identity/exchange", data);
  return response.data;
}

export async function updatePassword(userId: string, data: UpdatePasswordResquest): Promise<
  Success<void>
> {
  const response = await authService.put(`/identity/users/${userId}`, data);
  return response.data;
}
