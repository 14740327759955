<script setup lang="ts">
import { uniqBy } from "lodash-es";
import debounce from "lodash-es/debounce";
import { useQueryClient } from "@tanstack/vue-query";
import CardRanking from "./components/CardRanking.vue";
import WeightEditButton from "./components/WeightEditButton.vue";
import ProjectStages from "@/common/components/ProjectStages.vue";
import SearchLogo from "@/assets/images/search_icon.svg";
import { SvgIcon } from "@/components/icon";
import type { SearchCandidate } from "@/common/composables/useShortlist";
import type { ProjectWeights } from "@/schemas/project";
import Weights from "@/projects/pages/projects/[id]/interviews/components/Weights.vue";
import ScrollToTop from "@/components/scroll_to_top/ScrollToTop.vue";

const props = defineProps<{ id: string }>();

enum StagesEnum {
  MAPPING = "MAPPING",
  SCREENINGS = "SCREENINGS",
  INTERVIEWS = "INTERVIEWS",
  SHORTLIST = "SHORTLIST",
  PLACEMENT = "PLACEMENT",
}

const router = useRouter();
const route = useRoute();
const { t, tm } = useI18n();

const workModelEnum = computed(() => tm("projects.details.activities.screening.workModelEnum"));
const willingToRelocateEnum = computed(() => tm("projects.details.activities.screening.willingToRelocateEnum"));

const projectId = computed(() => String(route.params.id));
const searchInput = ref("");
const listAmount = ref(0);
const loading = ref(false);
const emptyList = ref(false);
const rankingWeights = ref();
const editWeights = ref(false);
const visibleScrollTop = ref(false);

const paginationParams = reactive({
  name: searchInput.value,
  project_id: projectId.value,
  project_stage: "INTERVIEWS",
  evaluated_in_interviews: true,
  sort: ["ranking:desc"],
  skip: 0,
  limit: 10,
});

const {
  data: construction,
} = useConstructionOrganizationQuery(String(route.params.id));

const {
  data: project,
  refetch: refetchProject,
} = useProject(String(route.params.id));

const {
  mutate: updateProject,
} = updateProjectMutation(String(route.params.id));

const {
  mutateAsync: approveCandidate,
  isPending: pendingApprove,
} = useShortlistApprovalCandidate();

const {
  mutateAsync: declineCandidate,
  isPending: pendingDecline,
} = useShortlistDeclineCandidate();

const {
  mutate: interviewToShortlist,
} = useProjectInterviewToShortlist(props.id);

const {
  data: screening,
  mutate: fetchScreening,
} = useScreeeningEvaluationMutation();

// TODO: this cannot be a mutation
const {
  data: interview,
  mutate: fetchInterview,
} = useInterviewEvaluationMutation();

const {
  error,
  mutateAsync: useFullCandidates,
  isPending,
} = useInterviewFullCandidates();

watchEffect(() => {
  if (project.value?.ranking_weights)
    rankingWeights.value = project.value.ranking_weights;
});
async function updateWeights(newWeights: ProjectWeights) {
  updateProject({
    ...project.value,
    ranking_weights: newWeights,
  }, {
    onSuccess: () => {
      cancel();
      editWeights.value = false;
      refetchProject();
    },
  });
}

function cancel() {
  editWeights.value = false;
}

const candidates = ref<SearchCandidate[]>([]);

function resetList() {
  paginationParams.name = "";
  listAmount.value = 0;
  candidates.value = [];
  paginationParams.skip = 0;
}

const notesList = ref([
  {
    key: "academic_formation_mean",
    icon: "project_academic",
    label: "academicAbbreviated",
  },
  {
    key: "achievements_mean",
    icon: "project_realizations",
    label: "realizations",
  },
  {
    key: "cultural_match_mean",
    icon: "project_cultural_match",
    label: "culturalMatch",
  },
  {
    key: "experiences_mean",
    icon: "project_cultural_match",
    label: "experienceAbbreviated",
  },
  {
    key: "attributes_mean",
    icon: "project_attributes",
    label: "attributes",
  },
  {
    key: "motivation_mean",
    icon: "project_motivation",
    label: "motivation",
  },
]);

const {
  tabs,
  goTo,
} = useInterviewTabs(props.id);

const currentRoute = computed(() => tabs.value.findIndex(tab => tab.key === router.currentRoute.value.meta.tab));

const detailsOpened = ref("");

async function goToActivity(person_id: string) {
  const activity = await getInterviewsActivities({ project_id: props.id, candidate_person_id: person_id });
  if (activity[0])
    router.push(`/projects/${props.id}/interviews/person/${person_id}/activity/${activity[0]?._id}`);
}

async function onSearch() {
  resetList();
  loading.value = true;
  paginationParams.name = searchInput.value;
  fetchCandidates();
}

function checkStage(stage: string) {
  return (stage === StagesEnum.SHORTLIST || stage === StagesEnum.PLACEMENT);
}

const queryClient = useQueryClient();

async function approveToShortlist(id_: string) {
  try {
    if (pendingApprove.value)
      return;

    await approveCandidate({ id_ }, {
      onSuccess: () => {
        resetList();
        fetchCandidates();
      },
      onSettled: async () => {
        await queryClient.refetchQueries({
          queryKey: ["projects", props.id],
        });
      },
    });

    if (project.value?.stages?.interviews?.status === "ACTIVE")
      interviewToShortlist(props.id);
  }
  catch (error) {
    console.log("error", error);
  }
}

async function declineFromShortlist(id_: string) {
  if (pendingApprove.value)
    return;

  await declineCandidate({ id_ }, {
    onSuccess: () => {
      resetList();
      fetchCandidates();
    },
    onSettled: async () => {
      await queryClient.refetchQueries({
        queryKey: ["projects", props.id],
      });
    },
  });
}

function toggleDetails(person_id: string) {
  if (detailsOpened.value === person_id) {
    detailsOpened.value = "";
    return;
  }

  detailsOpened.value = person_id;
  const element = document.getElementById(person_id);
  element?.focus();

  fetchScreening({ project_id: String(route.params.id), person_id });
  fetchInterview({ project_id: String(route.params.id), person_id });
}

function formatScore(score: number | null | undefined) {
  try {
    if (!score)
      return 0;
    return (
      (!Number.isInteger(score) ? score?.toFixed(2).replace(".", ",") : score?.toFixed(0)) ?? 0
    );
  }
  catch (error) {
    return 0;
  }
}

async function nextPage() {
  try {
    loading.value = true;
    paginationParams.skip = candidates.value?.length;
    await fetchCandidates();
  }
  catch (error) {
    loading.value = false;
    console.log("error", error);
  }
}

const bodyHeight = ref(0);
function scrollToTop() {
  const body = document.querySelector("body");
  if (body?.scrollTop >= 100)
    body.scrollTop = bodyHeight.value - 150;
}

async function handleScroll(event: Event) {
  const {
    scrollTop: hiddenHeight,
    scrollHeight: totalHeight,
    clientHeight: totalVisible,
  } = event.target as HTMLElement;
  const bottomReached = hiddenHeight + totalVisible + 20 >= totalHeight;

  (hiddenHeight >= 700) ? (visibleScrollTop.value = true) : (visibleScrollTop.value = false);

  if (hiddenHeight && bottomReached && !loading.value && !emptyList.value) {
    bodyHeight.value = hiddenHeight;
    await nextPage();
  }
}

async function fetchCandidates() {
  await useFullCandidates(paginationParams, {
    onSuccess: (result) => {
      listAmount.value = result.count;
      candidates.value = uniqBy([...candidates.value, ...result.data], e => e._id);
      emptyList.value = result?.data?.length === 0;
      loading.value = false;
      scrollToTop();
    },
    onError: () => {
      loading.value = false;
    },
  });
}

const debounceSearch = debounce(onSearch, 1000);

onMounted(() => {
  fetchCandidates();
  const body = document.querySelector("body");
  body?.addEventListener("scroll", (event: Event) => handleScroll(event));
});
</script>

<template>
  <section class="project-activities">
    <div v-if="error">
      could not load
    </div>
    <div data-testid="project-candidates">
      <div class="project-container">
        <ProjectStages :id="props.id" />
        <ProjectTabs :stage-tabs="tabs" :active="currentRoute" class="tabs" @change="goTo($event)" />
        <div class="project-wrapper">
          <div class="project-header">
            <div class="amount">
              {{ listAmount }} {{ t("projects.details.tabs.candidates") }}
            </div>
            <div class="actions">
              <WeightEditButton :is-edditing="editWeights" @click="editWeights = !editWeights" />
              <div class="form-search">
                <input
                  v-model="searchInput"
                  name="search"
                  :placeholder="t('persons.searchPerson')"
                  autocomplete="off"
                  @input="debounceSearch"
                >
                <SearchLogo class="search-icon" width="1.25rem" height="1.25rem" />
              </div>
            </div>
          </div>
          <Weights
            v-if="project?.ranking_weights && editWeights"
            :weights="rankingWeights"
            @update="updateWeights"
            @cancel="cancel"
          />
          <div class="project-body">
            <div v-if="candidates.length > 0" class="list-candidates">
              <CardRanking
                v-for="(person, index) in candidates"
                :id="person.person_id"
                :key="index"
                :person="person"
                :score-index="index"
                :score="person.calculated_scores.final_mean"
                :active="checkStage(person.project_stage)"
                :show-details="detailsOpened === person.person_id"
              >
                <template #actions>
                  <Button
                    v-if="!checkStage(person.project_stage)"
                    class="person-data-actions-buttons"
                    variation="secondary"
                    :disabled="pendingApprove"
                    @click="approveToShortlist(person._id)"
                  >
                    {{ t("projects.details.constructionGuide.ranking.addToShortlist") }}
                  </Button>
                  <Button
                    v-else
                    class="person-data-actions-buttons"
                    variation="secondary"
                    filled
                    :disabled="pendingDecline"
                    @click="declineFromShortlist(person._id)"
                  >
                    {{ t("projects.details.constructionGuide.ranking.removeFromShortlist") }}
                  </Button>
                  <Button
                    v-if="detailsOpened !== person.person_id"
                    class="person-data-actions-buttons"
                    variation="secondary"
                    @click="toggleDetails(person.person_id)"
                  >
                    {{ t("projects.details.constructionGuide.ranking.openInterviewDetails") }}
                  </Button>
                  <Button
                    v-else
                    class="person-data-actions-buttons"
                    variation="secondary"
                    filled
                    @click="toggleDetails(person.person_id)"
                  >
                    {{ t("projects.details.constructionGuide.ranking.hideDetails") }}
                  </Button>
                  <Button class="person-data-actions-buttons" variation="primary" @click="goToActivity(person.person_id)">
                    {{ t("projects.details.constructionGuide.ranking.reassess") }}
                  </Button>
                </template>
                <template #details>
                  <div class="details">
                    <div class="separate" />
                    <div class="details-title gray-dark">
                      {{ t("projects.shortlist.titles.evaluation") }}
                    </div>
                    <div class="details-evaluation">
                      <div class="notes">
                        <div v-for="(note, note_index) in notesList" :key="note_index" class="note">
                          <div class="icon">
                            <SvgIcon :icon="note.icon" width="1.25rem" height="1.25rem" margin="none" />
                          </div>
                          <div class="value">
                            {{ t(`projects.shortlist.labels.${note.label}`) }} -
                            {{ formatScore(person?.calculated_scores[note.key as never]) }}%
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="details-title gray-dark">
                      {{ t("projects.details.constructionGuide.ranking.details.title") }}
                    </div>
                    <div class="separate" />
                    <div class="details-title">
                      {{ t("projects.shortlist.titles.achievements") }}
                    </div>
                    <div class="details-group">
                      <div
                        v-for="(achievement_value, _, achievement_index) of interview?.achievements"
                        :key="achievement_index"
                        class="items"
                      >
                        <div v-if="achievement_value.observations">
                          <strong>
                            {{ t("projects.construction.labels.realization") }} {{ achievement_index + 1 }}
                          </strong>
                        </div>
                        <div>
                          {{ achievement_value.observations }}
                        </div>
                        <div>
                          <strong>
                            {{ t("projects.screening.guide.PostScreening.motivation.percentIdealProfile") }}
                          </strong>
                        </div>
                        <div>
                          {{ formatScore(achievement_value.score) }}%
                        </div>
                      </div>
                    </div>

                    <div class="details-title">
                      {{ t("projects.details.constructionGuide.ranking.details.attibutes") }}
                    </div>
                    <div class="details-group">
                      <div
                        v-for="(attribute_value, attribute_key, attribute_index) of construction?.ideal_profile?.attributes"
                        v-show="attribute_value !== 'EMPTY'"
                        :key="attribute_index"
                        class="items"
                      >
                        <div>
                          <strong>
                            {{ t(`attributes.${String(attribute_key).toUpperCase()}`) }}
                            <template
                              v-if="interview?.attributes[attribute_key]?.level && interview?.attributes[attribute_key]?.level !== 'EMPTY'"
                            >
                              - {{
                                t(`projects.generic.scale.enum.${String(interview?.attributes[attribute_key]?.level).toUpperCase()}`)
                              }}
                            </template>
                          </strong>
                        </div>
                        <div>
                          {{ interview?.attributes[attribute_key]?.observations }}
                        </div>
                        <div>
                          <strong>
                            {{ t("projects.screening.guide.PostScreening.motivation.percentIdealProfile") }}
                          </strong>
                        </div>
                        <div>
                          {{ formatScore(interview?.attributes[attribute_key]?.score) }}%
                        </div>
                      </div>
                    </div>

                    <div class="details-title">
                      {{ t("projects.details.constructionGuide.ranking.details.culturalMatch") }}
                    </div>
                    <div class="details-group">
                      <div
                        v-for="(cultural_value, cultural_key, cultural_index) of construction?.ideal_profile?.cultural_match"
                        v-show="cultural_value !== 'EMPTY'"
                        :key="cultural_index"
                        class="items"
                      >
                        <div>
                          <strong>
                            {{ t(`cultural_match.${String(cultural_key).toUpperCase()}`) }}
                            <template
                              v-if="interview?.cultural_match[cultural_key]?.level && interview?.cultural_match[cultural_key]?.level !== 'EMPTY'"
                            >
                              - {{
                                t(`projects.generic.scale.enum.${String(interview?.cultural_match[cultural_key]?.level).toUpperCase()}`)
                              }}
                            </template>
                          </strong>
                        </div>
                        <div>
                          {{ interview?.cultural_match[cultural_key]?.observations }}
                        </div>
                        <div>
                          <strong>
                            {{ t("projects.screening.guide.PostScreening.motivation.percentIdealProfile") }}
                          </strong>
                        </div>
                        <div>
                          {{ formatScore(interview?.cultural_match[cultural_key]?.score) }}%
                        </div>
                      </div>
                    </div>

                    <div class="details-title">
                      {{ t("projects.details.constructionGuide.ranking.details.motivation") }}
                    </div>
                    <div class="details-group">
                      <div class="items">
                        <div>
                          <strong>
                            {{ t("projects.construction.titles.environment").toUpperCase() }}:
                          </strong>
                        </div>
                        <div>
                          <strong>
                            {{ t("projects.screening.guide.PostScreening.motivation.percentIdealProfile") }}
                          </strong>
                        </div>
                        <div>
                          {{ formatScore(interview?.work_environment.score) }}%
                        </div>

                        <div v-if="interview?.work_environment?.attraction" class="items">
                          <div>
                            <strong>
                              {{ t("projects.interview.titles.attraction") }}
                            </strong>
                          </div>
                          <div>
                            {{ interview?.work_environment?.attraction }}
                          </div>
                        </div>

                        <div v-if="interview?.work_environment?.repulsion" class="items">
                          <div>
                            <strong>
                              {{ t("projects.interview.titles.repulsion") }}
                            </strong>
                          </div>
                          <div>
                            {{ interview?.work_environment?.repulsion }}
                          </div>
                        </div>

                        <div v-if="interview?.work_environment?.retention" class="items">
                          <div>
                            <strong>
                              {{ t("projects.interview.titles.retention") }}
                            </strong>
                          </div>
                          <div>
                            {{ interview?.work_environment?.retention }}
                          </div>
                        </div>
                      </div>

                      <div class="items">
                        <div>
                          <strong>
                            {{ t("projects.construction.titles.personal").toUpperCase() }}:
                          </strong>
                        </div>
                        <div>
                          <strong>
                            {{ t("projects.screening.guide.PostScreening.motivation.percentIdealProfile") }}
                          </strong>
                        </div>
                        <div>
                          {{ formatScore(interview?.personal_life.score) }}%
                        </div>

                        <div v-if="interview?.personal_life?.attraction" class="items">
                          <div>
                            <strong>
                              {{ t("projects.interview.titles.attraction") }}
                            </strong>
                          </div>
                          <div>
                            {{ interview?.personal_life?.attraction }}
                          </div>
                        </div>

                        <div v-if="interview?.personal_life?.repulsion" class="items">
                          <div>
                            <strong>
                              {{ t("projects.interview.titles.repulsion") }}
                            </strong>
                          </div>
                          <div>
                            {{ interview?.personal_life?.repulsion }}
                          </div>
                        </div>

                        <div v-if="interview?.personal_life?.retention" class="items">
                          <div>
                            <strong>
                              {{ t("projects.interview.titles.retention") }}
                            </strong>
                          </div>
                          <div>
                            {{ interview?.personal_life?.retention }}
                          </div>
                        </div>
                      </div>

                      <div class="items">
                        <div>
                          <strong>
                            {{ t("projects.construction.titles.career").toUpperCase() }}:
                          </strong>
                        </div>
                        <div>
                          <strong>
                            {{ t("projects.screening.guide.PostScreening.motivation.percentIdealProfile") }}
                          </strong>
                        </div>
                        <div>
                          {{ formatScore(interview?.career.score) }}%
                        </div>

                        <div v-if="interview?.career?.attraction" class="items">
                          <div>
                            <strong>
                              {{ t("projects.interview.titles.attraction") }}
                            </strong>
                          </div>
                          <div>
                            {{ interview?.career?.attraction }}
                          </div>
                        </div>

                        <div v-if="interview?.career?.repulsion" class="items">
                          <div>
                            <strong>
                              {{ t("projects.interview.titles.repulsion") }}
                            </strong>
                          </div>
                          <div>
                            {{ interview?.career?.repulsion }}
                          </div>
                        </div>

                        <div v-if="interview?.career?.retention" class="items">
                          <div>
                            <strong>
                              {{ t("projects.interview.titles.retention") }}
                            </strong>
                          </div>
                          <div>
                            {{ interview?.career?.retention }}
                          </div>
                        </div>
                      </div>

                      <div class="items">
                        <div>
                          <strong>
                            {{ t("projects.construction.titles.package").toUpperCase() }}:
                          </strong>
                        </div>
                        <div>
                          <strong>
                            {{ t("projects.screening.guide.PostScreening.motivation.percentIdealProfile") }}
                          </strong>
                        </div>
                        <div>
                          {{ formatScore(interview?.package.score) }}%
                        </div>

                        <div v-if="interview?.package?.salary">
                          <span>
                            <span>
                              <strong>
                                - {{ t("projects.details.constructionGuide.ranking.salaryRange") }}:
                              </strong>
                            </span>
                            <span>{{ interview?.package?.salary }}</span>
                          </span>
                        </div>

                        <div v-if="interview?.package?.long_term_incentive">
                          <span>
                            <span>
                              <strong>
                                - {{ t("projects.construction.labels.ilp").toUpperCase() }}:
                              </strong>
                            </span>
                            <span>{{ interview?.package?.long_term_incentive }}</span>
                          </span>
                        </div>

                        <div v-if="interview?.package?.short_term_incentive">
                          <span>
                            <span>
                              <strong>
                                - {{ t("projects.construction.labels.icp").toUpperCase() }}:
                              </strong>
                            </span>
                            <span>{{ interview?.package?.short_term_incentive }}</span>
                          </span>
                        </div>

                        <div v-if="interview?.package?.other_incentive">
                          <span>
                            <span>
                              <strong>
                                - {{ t("projects.construction.labels.otherIncentives") }}:
                              </strong>
                            </span>
                            <span>{{ interview?.package?.other_incentive }}</span>
                          </span>
                        </div>

                        <div v-if="interview?.package?.attraction" class="items">
                          <div>
                            <strong>
                              {{ t("projects.interview.titles.attraction") }}
                            </strong>
                          </div>
                          <div>
                            {{ interview?.package?.attraction }}
                          </div>
                        </div>

                        <div v-if="interview?.package?.repulsion" class="items">
                          <div>
                            <strong>
                              {{ t("projects.interview.titles.repulsion") }}
                            </strong>
                          </div>
                          <div>
                            {{ interview?.package?.repulsion }}
                          </div>
                        </div>

                        <div v-if="interview?.package?.retention" class="items">
                          <div>
                            <strong>
                              {{ t("projects.interview.titles.retention") }}
                            </strong>
                          </div>
                          <div>
                            {{ interview?.package?.retention }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="details-title gray-dark">
                      {{ t("projects.details.constructionGuide.ranking.openScreeningDetails") }}
                    </div>
                    <div class="separate" />

                    <div class="details-title">
                      {{ t("projects.construction.titles.academic") }}
                    </div>
                    <div class="details-group">
                      <div class="items">
                        <div
                          v-for="(academic_value, _, academic_key) of screening?.academic_backgrounds"
                          :key="academic_key"
                        >
                          {{ academic_value }}
                        </div>
                      </div>
                      <div class="items">
                        <div>
                          <strong>
                            {{ t("projects.screening.guide.languages") }}:
                          </strong>
                        </div>
                        <div v-for="(language_value, _, language_key) of screening?.languages" :key="language_key">
                          {{ language_value }}
                        </div>
                      </div>
                    </div>
                    <div class="details-title">
                      {{ t("projects.interview.titles.professionalExperience") }}
                    </div>
                    <div class="details-group">
                      <div
                        v-for="(experience_value, _, experience_index) of screening?.experiences"
                        :key="experience_index"
                        class="items"
                      >
                        <div v-if="experience_value">
                          <strong>
                            {{ t("projects.screening.guide.accordeons.experience") }} {{ experience_index + 1 }}
                          </strong>
                        </div>
                        <div>
                          {{ experience_value }}
                        </div>
                      </div>
                    </div>
                    <div class="details-title">
                      {{ t("projects.details.constructionGuide.ranking.details.motivation") }}
                    </div>
                    <div class="details-group">
                      <div class="items">
                        <div>
                          <strong>
                            {{ t("projects.details.constructionGuide.idealProfile.package") }}:
                          </strong>
                        </div>
                        <div v-if="screening?.package?.salary">
                          <span>
                            <span><strong>
                              - {{ t("projects.details.constructionGuide.ranking.salaryRange") }}:
                            </strong> </span>
                            <span>{{ screening?.package?.salary }}</span>
                          </span>
                        </div>
                        <div v-if="screening?.package?.short_term_incentive">
                          <span>
                            <span><strong>- {{ t("projects.construction.labels.icpShort") }}: </strong></span>
                            <span>{{ screening?.package?.short_term_incentive }}</span>
                          </span>
                        </div>
                        <div v-if="screening?.package?.long_term_incentive">
                          <span>
                            <span><strong>- {{ t("projects.construction.labels.ilpShort") }}: </strong></span>
                            <span>{{ screening?.package?.long_term_incentive }}</span>
                          </span>
                        </div>
                        <div v-if="screening?.package?.other_incentive">
                          <span>
                            <span><strong>- {{ t("projects.construction.labels.others") }}: </strong></span>
                            <span>{{ screening?.package?.other_incentive }}</span>
                          </span>
                        </div>
                        <div v-if="screening?.package?.total_cash">
                          <span>
                            <span><strong>- {{ t("projects.construction.labels.totalCash") }}: </strong></span>
                            <span>{{ screening?.package?.total_cash }}</span>
                          </span>
                        </div>
                      </div>
                      <div class="items">
                        <div>
                          <strong>
                            {{ t("projects.details.constructionGuide.ranking.relocationAvailability") }}
                          </strong>
                        </div>
                        <div v-if="screening?.willing_to_relocate">
                          {{ willingToRelocateEnum[screening?.willing_to_relocate] }}
                        </div>
                        <div>
                          <strong>
                            {{ t("projects.details.constructionGuide.ranking.contractType") }}
                          </strong>
                        </div>
                        <div v-if="screening?.work_model">
                          {{ workModelEnum[screening?.work_model] }}
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </CardRanking>
            </div>
            <p v-if="!candidates.length">
              {{
                t("projects.details.constructionGuide.ranking.candidateNotFound")
              }}
            </p>
            <div v-if="loading" class="loader-wrapper">
              <div class="loader" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <ScrollToTop v-if="visibleScrollTop" class-name="body" :top="350" />
  </section>
</template>

<route lang="yaml">
meta:
  layout: project-stage
  stage: interviews
  tab: ranking
</route>

<style scoped lang="scss">
.project-activities {
  min-height: 100vh;
}

.project-container {
  padding-top: 3.75rem;
}

.project-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 32rem;
  padding-inline: 4rem;
  padding-block: 2.5rem;
  background: $white-full;

  .separate {
    width: 100%;
    height: 1px;
    background: $gray-border;
  }

  .project-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-block: 1.3125rem;
    margin-block-end: 1.3125rem;

    .amount {
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: $gray-dark;
      font-size: 1.125rem;
      font-weight: bold;
    }

    .actions {
      display: flex;
      gap: 1rem;
      align-items: center;

      .form-search {
        display: flex;
        width: 22.5rem;
        flex-direction: row;
        padding-block: 0.7rem;
        padding-inline: 0.875rem;
        border: 1px solid $gray-border;
        border-radius: 0.625rem;

        input {
          flex: 1;
          font-size: 0.875rem;
          outline: none;
          border: none;
        }

        .search-icon {
          color: $primary;
        }
      }
    }

    .button-pluss {
      display: flex;
      flex-direction: row;
      gap: 0.25rem;
    }
  }

  .project-body {
    display: flex;
    flex-direction: column;
    padding-block-end: 1rem;

    .list-candidates {
      display: flex;
      flex-direction: column;
      padding-block: 1rem;
      gap: 4.5rem;
    }

    .details {
      display: flex;
      flex-direction: column;
      padding-block: 1rem;
      gap: 2rem;

      .gray-dark {
        color: $gray-dark;
      }

      &-title {
        color: $primary;
        font-size: 1.25rem;
        font-weight: bold;
        text-transform: uppercase;
        margin-block-start: 1rem;
      }

      &-group {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2rem;

        .items {
          font-size: 0.875rem;
        }
      }

      &-evaluation {
        display: grid;
        grid-template-columns: 3fr 1fr;
        margin-block: 1.5rem;

        .notes {
          display: grid;
          grid-template-columns: 1fr 1fr;

          .note {
            display: flex;
            flex-direction: row;
            margin-bottom: 2.5rem;
            align-items: center;

            .icon {
              display: flex;
              width: 2rem;
              height: 2rem;
              align-items: center;
              justify-content: center;
              border-radius: 6px;
              margin-right: 0.625rem;
              color: $primary;
              box-shadow: 0px 2px 4px #00000029;
            }

            .value {
              color: $gray-low;
              font-weight: bold;
            }
          }
        }
      }

      &-group {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2rem;

        .items {
          display: flex;
          flex-direction: column;
          font-size: 0.875rem;
          gap: 1rem;
        }
      }
    }
  }
}

.loader-wrapper {
  width: 100%;
  height: 2rem;
  padding-block: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  width: 2rem;
  height: 2rem;
  border: 5px solid;
  border-color: $yellow transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
