<script lang="ts">
import { h, toRefs } from "vue";

export const colors = {
  red: getComputedStyle(document.documentElement).getPropertyValue("--red"),
  yellow: getComputedStyle(document.documentElement).getPropertyValue("--yellow"),
  gray: getComputedStyle(document.documentElement).getPropertyValue("--gray-lower"),
};

export default {
  props: {
    tag: {
      type: String,
      default: "li",
      required: false,
    },
    marker: {
      type: String,
      default: "rounded",
    },
    fontSize: {
      type: String,
      default: "1rem",
      required: false,
    },
    fontWeight: {
      type: String,
      default: "normal",
      required: false,
    },
    markerColor: {
      type: String,
      default: colors.yellow,
    },
    markerSize: {
      type: String,
      default: "0.45rem",
      required: false,
    },
    color: {
      type: String,
      default: colors.gray,
    },
    align: {
      type: String,
      default: "left",
      required: false,
    },
    mb: {
      type: String,
      required: false,
    },
  },
  setup(props, { slots }) {
    const { tag, marker, fontSize, fontWeight, color, align, mb } = toRefs(props);
    const slot = slots ? slots.default?.() : [];
    return () =>
      h(
        `${tag.value}`,
        {
          class: [marker.value, "default"],
          style: {
            fontSize: fontSize.value,
            fontWeight: fontWeight.value,
            color: color.value,
            textAlign: align.value,
            marginBlock: mb.value,
          },
        },
        slot,
      );
  },
};
// <Li tag="span" marker="square" markerColor="colors.red" fontWeight="bold" mb="0 1.25rem">
//   {{ value }}
// </Li>
</script>

<style scoped lang="scss">
:global(:root) {
  --red: #e73c3e;
  --yellow: #ffb600;
  --gray-lower: #707070;
}

.default {
  display: grid;
  align-items: start;
  gap: 0.5rem;
  grid-template-columns: auto 1fr;
  line-height: 1.2;
  list-style: none;

  &::before {
    position: relative;
    top: 0.2rem;
    display: block;
    padding: 0;
  }
}

.square {
  &::before {
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 3px;
    background-color: v-bind(markerColor);
    content: "";
  }
}

.rounded {
  gap: 0.5rem;

  &::before {
    top: 0.35rem;
    width: v-bind(markerSize);
    height: v-bind(markerSize);
    aspect-ratio: 1;
    border-radius: 100%;
    background-color: v-bind(markerColor);
    content: "";
    margin-inline: 0;
  }
}
</style>
