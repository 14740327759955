<script lang="ts">
import codes from "country-calling-code";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import { defineComponent, ref, watch } from "vue";
import Dropdown from "primevue/dropdown";
import { TrashButton } from "@/components/trash-button";

import { sortByKey } from "@/utils/array";

export default defineComponent({
  name: "TelephoneInput",
  components: {
    Dropdown,
    TrashButton,
  },
  props: {
    defaultCode: String,
    autoFill: Boolean,
    error: Boolean,
    errorMessage: String,
    placeholder: String,
    countryCode: {
      type: String,
    },
    phoneNumber: {
      type: String,
    },
    svgIcon: String,
    showIcon: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["click:onButtonClick"],
  setup(props, { emit }) {
    const countryCodes = codes
      .map(i => ({
        code: `+ ${i.countryCodes[0]}`,
        flag: getUnicodeFlagIcon(i.isoCode2),
        codeNum: Number(i.countryCodes[0].replace("-", "")),
        isoCode: i.isoCode2.toLocaleLowerCase(),
      }))
      .sort(sortByKey("codeNum"));

    const phoneCode = ref(
      countryCodes.filter(el => el.code === props.countryCode)[0] || {
        code: "+ 55",
        flag: "🇧🇷",
        codeNum: 55,
        isoCode: "br",
      },
    );

    const allowPhoneChar = (event: KeyboardEvent) => {
      const { key } = event;

      const isNum = Boolean(key.match(/[0-9]/));
      const allowedChar = ["-", " "].includes(key);

      if (!isNum && !allowedChar)
        event.preventDefault();
    };

    watch(phoneCode, (value) => {
      if (value)
        emit("update:countryCode", value.code);
    });

    const onClick = () => {
      emit("click:onButtonClick");
    };

    return {
      countryCodes,
      phoneCode,
      allowPhoneChar,
      onClick,
    };
  },
});
</script>

<template>
  <div>
    <div class="telephone-input-wrapper">
      <div class="select">
        <Dropdown
          v-model="phoneCode"
          :options="countryCodes"
          option-label="code"
          :filter="true"
          :show-clear="false"
          class="dropdown"
          :class="{ auto_fill: autoFill }"
          panel-class="phoneinput-panel"
        >
          <template #value="slotProps">
            <div v-if="slotProps.value" class="country-item country-item-value">
              <div>
                <span class="fi" :class="`fi-${slotProps.value.isoCode}`" />
                {{ slotProps.value.code }}
              </div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="country-item" style="font-size: 12px;">
              <div>
                <span class="fi" :class="`fi-${slotProps.option.isoCode}`" />
                {{ slotProps.option.code }}
              </div>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="input-wrapper">
        <input
          v-maska="['(##) #####-####', '(##) ####-####', '(###) ####-####', '(###) #####-####']"
          :placeholder="placeholder"
          :class="{ auto_fill: autoFill }"
          :value="phoneNumber"
          @input="$emit('update:phoneNumber', $event.target.value)"
        >
        <TrashButton :show-icon="showIcon" :svg-icon="svgIcon" @click="onClick" />
      </div>
    </div>
    <p class="help-message">
      {{ errorMessage }}
    </p>
  </div>
</template>

<style src="flag-icons/sass/flag-icons.scss" lang="scss"></style>

<style lang="scss" scoped>
.input-wrapper {
  display: flex;

  button {
    min-width: 2rem !important;
    height: 1.8rem !important;
    border: 1px solid $gray-border !important;
    border-radius: 6px;
    margin-left: 0.563rem !important;

    &:hover {
      border: 1px solid $red-violet-medium;
      background-color: $red-violet-medium;

      svg {
        color: $orange-medium;
      }
    }
  }

  .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    color: inherit;
  }
}

.help-message {
  margin-top: 3px;
  color: $red-dark;
  font-family: "Open Sans";
  font-size: 11px;
}

.auto_fill {
  border: 2px solid $red-violet !important;
}

.telephone-input-wrapper {
  display: flex;
  width: 100%;
  height: 28px;

  .tel-select {
    height: 100%;
    padding-right: 10px;
    padding-left: 10px;
    border: 1px solid $gray-border;
    border-radius: 5px;
    margin-right: 0.5rem;
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    background-color: white;
    background-image: url("../../../assets/images/arrow_down.svg");
    background-position-x: 80px;
    background-position-y: 7px;
    background-repeat: no-repeat;
    background-size: 20px;
    outline: none;
  }

  input {
    width: 100%;
    height: 28px;
    padding: 0 10px;
    border: 1px solid #c0c0c0;
    border-radius: 6px;
    margin-left: 0.5rem;
    color: #666;
    font-family: "Open Sans";

    &::placeholder {
      color: #c6c6c6;
    }

    &:focus {
      outline: none;
    }
  }
}

::v-deep(.dropdown.p-dropdown) {
  width: 130px;
  height: 28px;
  border: 1px solid #c0c0c0;
  border-radius: 6px;
  box-shadow: none;

  .country-item {
    position: relative;
    top: -1px;
    font-size: 13px;
  }
}
</style>
