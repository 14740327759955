<script lang="ts">
import { useI18n } from "vue-i18n";
import Multiselect from "@vueform/multiselect";
import PublicationDataForm from "./PublicationDataForm.vue";
import AwardDataForm from "./AwardDataForm.vue";
import ProjectDataForm from "./ProjectDataForm.vue";
import VolunteerDataForm from "./VolunteerDataForm.vue";
import TrashIcon from "@/assets/images/trash_icon.svg";

export default defineComponent({
  name: "ExtraActivitiesForm",
  components: {
    PublicationDataForm,
    AwardDataForm,
    ProjectDataForm,
    VolunteerDataForm,
    TrashIcon,
    Multiselect,
  },
  props: {
    extraActivities: Object,
  },
  emits: ["click:add", "click:remove"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const extraActivitiesOptions = computed(() => [
      {
        value: "projects",
        label: t("persons.createPersonForm.extraActivitiesTypes.projects"),
      },
      {
        value: "publications",
        label: t("persons.createPersonForm.extraActivitiesTypes.publications"),
      },
      {
        value: "awards",
        label: t("persons.createPersonForm.extraActivitiesTypes.awards"),
      },
      {
        value: "volunteering",
        label: t("persons.createPersonForm.extraActivitiesTypes.volunteer"),
      },
    ]);

    const removeItem = (type, index: number) => {
      emit("click:remove", [type, index]);
    };

    const appendActivity = (value) => {
      emit("click:add", value);
    };

    return {
      extraActivitiesOptions,
      t,
      appendActivity,
      removeItem,
    };
  },
});
</script>

<template>
  <div class="container">
    <form class="extra-activities-form">
      <div class="title-wrapper">
        <span>{{ t("persons.createPersonForm.extraActivities") }}</span>
      </div>

      <div>
        <ProjectDataForm
          v-for="(project, i) in extraActivities.projects"
          :key="project"
          :project="project"
        >
          <template #icon>
            <div class="remove-wrapper">
              <TrashIcon @click="removeItem('projects', i)" />
            </div>
          </template>
        </ProjectDataForm>

        <PublicationDataForm
          v-for="(publication, i) in extraActivities.publications"
          :key="publication"
          :publication="publication"
        >
          <template #icon>
            <div class="remove-wrapper">
              <TrashIcon @click="removeItem('publications', i)" />
            </div>
          </template>
        </PublicationDataForm>

        <VolunteerDataForm
          v-for="(volunteering, i) in extraActivities.volunteering"
          :key="volunteering"
          :volunteering="volunteering"
        >
          <template #icon>
            <div class="remove-wrapper">
              <TrashIcon @click="removeItem('volunteering', i)" />
            </div>
          </template>
        </VolunteerDataForm>

        <AwardDataForm
          v-for="(award, i) in extraActivities.awards"
          :key="award"
          :award="award"
        >
          <template #icon>
            <div class="remove-wrapper">
              <TrashIcon @click="removeItem('awards', i)" />
            </div>
          </template>
        </AwardDataForm>
      </div>

      <div class="extra-activities-select component-select">
        <Multiselect
          :options="extraActivitiesOptions"
          :placeholder="t('persons.createPersonForm.selectPlaceholder')"
          value-prop="value"
          :caret="false"
          :can-clear="false"
          @change="appendActivity"
        >
          <template #placeholder>
            <div class="multiselect-single-label">
              {{ t("persons.createPersonForm.addExtraActivities") }}
            </div>
          </template>
          <template #singlelabel>
            <div class="multiselect-single-label">
              {{ t("persons.createPersonForm.addExtraActivities") }}
            </div>
          </template>
        </Multiselect>
      </div>
    </form>
  </div>
</template>

<style lang="scss" scoped>
.container {
  width: 570px;
}

.extra-activities-form {
  display: flex;
  flex-direction: column;

  button {
    width: 70px;
  }

  .multiselect {
    min-height: 35px;
    border: 1px solid transparent;
    border-color: $yellow-dark;
    color: $gray-low;

    &.is-open {
      background: $yellow-dark;

      .multiselect-single-label {
        color: #fff;
      }
    }

    .multiselect-dropdown {
      overflow-y: scroll;
    }

    .multiselect-single-label {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      padding: 0.5rem 2rem;
      border: none;
      border-radius: 0.5rem;
      color: $yellow-dark;
      font-family: "Open Sans";
      text-transform: uppercase;
    }
  }

  .remove-wrapper {
    display: flex;
    height: 35px;
    justify-content: flex-end;

    svg {
      height: 18px;
      stroke: $gray-light;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .button-wrapper {
    width: 100%;

    button {
      width: 100%;
      border-color: $yellow-dark;
      color: $yellow-dark;
    }
  }
}

.title-wrapper {
  display: flex;
  width: 100%;
  height: 50px;

  span {
    color: $primary;
    font-family: "Open Sans";
    font-size: 1.125rem;
    font-weight: bold;
    text-transform: uppercase;
  }
}

.extra-activities:last-child {
  margin-bottom: 5px;
}

.extra-activities {
  padding: 15px 0;
}
</style>
