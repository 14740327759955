<script lang="ts">
import type { PropType, Ref } from "vue";
import {
  computed,
  defineComponent,
  inject,
} from "vue";
import ChartPersonCard from "./ChartPersonCard.vue";
import type { Corporation } from "@/entities/corporation";
import type { Person } from "@/entities/Person/person";

export default defineComponent({
  name: "ChartPage",
  components: {
    ChartPersonCard,
  },
  props: {
    employees: {
      type: Array as PropType<Person[]>,
      required: true,
    },
  },
  emits: ["show:person"],
  setup(props) {
    const corporation = inject("corporation") as Ref<Corporation>;

    const filteredEmployees = computed(() =>
      (props.employees as Person[]).filter(({ work_experience }) => work_experience.some(({ is_current }) => !!is_current)));

    return {
      corporation,
      filteredEmployees,
    };
  },
});
</script>

<template>
  <div class="tab-container">
    <ChartPersonCard
      v-for="employee in filteredEmployees"
      :key="employee"
      :employee="employee"
      :corporation="corporation"
      @click:person="$emit('show:person', $event)"
    />
  </div>
</template>

<style lang="scss" scoped>
.tab-container {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
}
</style>
