<script lang="ts">
import type { PropType, Ref } from "vue";
import { useI18n } from "vue-i18n";
import { ListType } from "@/components/list_type";
import { Select } from "@/components/inputs";
import { Loading } from "@/components/loading";
import { CustomCheckBox } from "@/components/checkbox";
import { PersonCard } from "@/components/person_card";
import type { Person } from "@/entities/Person/person";

interface CandidateMap {
  [id: string]: Person
}

interface SelectedCandidate {
  [id: string]: boolean
}

export default defineComponent({
  components: {
    ListType,
    Select,
    PersonCard,
    CustomCheckBox,
    Loading,
  },
  props: {
    candidates: Array as PropType<Person[]>,
    loading: Boolean,
    isSearching: Boolean,
    searchTotal: Number,
  },
  setup(props) {
    const { t } = useI18n();
    const candidatesList = toRef(props, "candidates") as Ref<Person[]>;

    const selectedCandidates = ref<SelectedCandidate>({});
    const candidates = computed<CandidateMap>(() => {
      const entries = candidatesList.value.map(candidate => [candidate.id, candidate]);
      return Object.fromEntries(entries);
    });
    watch(
      candidates,
      (newValue, oldValue) => {
        const newValueIds = Object.keys(newValue);
        const oldValuesIds = oldValue ? Object.keys(oldValue) : [];
        const diff = newValueIds.filter(id => !oldValuesIds.includes(id));
        diff.forEach(id => (selectedCandidates.value[id] = false));
      },
      { deep: true, immediate: true },
    );

    const allSelected = computed(() => Object.values(selectedCandidates.value).every(i => i));

    const toggleSelectAll = () => {
      const isAllSelected = allSelected.value;
      const newSelection = candidatesList.value.map(candidate => [candidate.id, !isAllSelected]);
      selectedCandidates.value = Object.fromEntries(newSelection);
    };

    const toggleCandidate = (candidate: Person, event: Event) => {
      const isChecked = (event.target as HTMLInputElement).checked;
      selectedCandidates.value[candidate.id] = isChecked;
    };

    return {
      t,
      toggleSelectAll,
      toggleCandidate,
      selectedCandidates,
      allSelected,
    };
  },
});
</script>

<template>
  <section class="list-persons">
    <header class="header">
      <div v-if="false" class="left-items">
        <CustomCheckBox :on-change="toggleSelectAll" :checked="allSelected" />
        <Select
          :options="[{ label: t('persons.addToProject'), value: 'add' }]"
          :placeholder="t('persons.bulkAction')"
          :width="240"
        />
      </div>
      <div class="right-items">
        <ListType selected-type="card" />
        <Select
          :options="[
            { label: t('persons.relevants'), value: 'relevant' },
            { label: t('persons.recents'), value: 'recents' },
          ]"
          :searchable="false"
          :placeholder="t('persons.sortBy')"
          :width="150"
        />
      </div>
    </header>
    <div class="list">
      <p v-if="!loading && isSearching && searchTotal > 0" class="search-count">
        {{ t("text.searchText", searchTotal) }}
      </p>
      <div v-for="candidate of candidates" :key="`candidate-${candidate.id}`">
        <PersonCard
          :person="candidate"
          :selected="selectedCandidates[candidate.id]"
          :on-check-change="(event) => toggleCandidate(candidate, event)"
          is-person-card
        />
      </div>
      <transition name="fade">
        <div class="loading">
          <Loading v-show="loading" :spin-time="2" />
        </div>
      </transition>
      <slot name="tail" />
    </div>
  </section>
</template>

<style scoped="true" lang="scss">
.header {
  display: flex;
  padding-right: 0.5rem;
  padding-left: 1.2rem;
  margin-bottom: 2rem;

  .left-items,
  .right-items {
    display: flex;
    flex: 1;
    align-items: center;
  }

  .left-items {
    justify-content: left;

    > div {
      margin-right: 1rem;
    }
  }

  .right-items {
    justify-content: right;
  }

  .component-select:deep {
    min-height: auto;

    .error-wrapper {
      height: 0;
    }
  }
}

.list {
  position: relative;

  .search-count {
    margin-bottom: 1.5rem;
    margin-left: 0.5rem;
    font-size: 1.563rem;
    font-weight: bolder;
  }
}

.loading {
  z-index: 9;
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: #fff;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
