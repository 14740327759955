import type { Module } from "vuex";
import type {
  ConstructionActivity,
  ConstructionStage,
  OrganizationAndAssembly,
} from "@/schemas/project";

const ConstructionStoreModule: Module<any, any> = {
  state() {
    const construction: ConstructionStage = null;
    const constructionOldOrganization: OrganizationAndAssembly = null;
    const constructionOrganization: OrganizationAndAssembly = null;
    const constructionActivitie: ConstructionActivity = null;

    return {
      construction,
      constructionOldOrganization,
      constructionOrganization,
      constructionActivitie,
    };
  },
  getters: {
    construction: state => state.construction,
    constructionOldOrganization: state => state.constructionOldOrganization,
    constructionOrganization: state => state.constructionOrganization,
    constructionActivitie: state => state.constructionActivitie,
  },
  mutations: {
    SET_CONSTRUCTION: (state, data) => {
      state.construction = data;
    },
    SET_CONSTRUCTION_ORGANIZATION: (state, data) => {
      state.constructionOrganization = data;
    },
    SET_CONSTRUCTION_ACTIVITIE: (state, data) => {
      state.constructionActivitie = data;
    },
    SET_OLD_ORGANIZATION: (state, data) => {
      state.constructionOldOrganization = data;
    },
  },
};

export default ConstructionStoreModule;
