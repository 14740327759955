<script lang="ts" setup>
import InputSwitch from "primevue/inputswitch";
import { SvgIcon } from "@/components/icon";
import { FormInput } from "@/components/inputs/modalInputs";
import type { Project } from "@/schemas/project";

interface StageTabsType { key: string; name: string; path: string; icon: string; label?: string; counter?: number; title?: string }

const props = defineProps<{
  listItems: StageTabsType[]
  id: string
  project: Project
  isCounterDefault: boolean | undefined
  isDescriptionsDefault: boolean | undefined
}>();

const emit = defineEmits(["cancel", "refetch"]);

const { t } = useI18n();
const isCounterDefault = toRef(props.isCounterDefault);
const isDescriptionsDefault = toRef(props.isDescriptionsDefault);
const listItems = toRef(props.listItems);
const {
  mutate: updateProject,
} = updateProjectMutation(props.id);

const iconSize = "2.625rem";
const diffDiv = "3.0625rem";

function updateProjectFunnelPresentationCounters(key: string, value: boolean | string) {
  updateProject({
    ...props.project,
    process_funnel: {
      ...props.project.process_funnel,
      presentation_counters: {
        ...props.project.process_funnel.presentation_counters,
        [key]: value,
      },
    },
  }, {
    onSuccess: () => {
      emit("refetch");
    },
  });
};
function updateProjectFunnelPresentationDescriptions(key: string, value: boolean | string | undefined) {
  updateProject({
    ...props.project,
    process_funnel: {
      ...props.project.process_funnel,
      presentation_descriptions: {
        ...props.project.process_funnel.presentation_descriptions,
        [key]: value,
      },
    },
  }, {
    onSuccess: () => {
      emit("refetch");
    },
  });
};

function renderBoxTitle({ item }: { item: { counter: number | undefined; title: string | undefined; key: string } }) {
  return h("div", { class: "box-title" }, !isCounterDefault.value
    ? [
        h(FormInput, {
          "class": "form-input",
          "modelValue": item.counter,
          "disabled": isCounterDefault.value,
          "onUpdate:modelValue": (value: string) => {
            updateProjectFunnelPresentationCounters(item.key, value);
          },

        }),
`${item.title}`,
      ]
    : `${item.counter} ${item.title}`);
}
watchEffect(() => {
  listItems.value = toRaw(props.listItems);
});
</script>

<template>
  <div class="container-switch">
    <div class="check-switch">
      <InputSwitch
        v-model="isCounterDefault"
        @change="updateProjectFunnelPresentationCounters('show_default', isCounterDefault)"
      /> {{
        t("projects.shortlist.funnel.originalNumbers") }}
    </div>
    <div class="check-switch">
      <InputSwitch
        v-model="isDescriptionsDefault"
        @change="updateProjectFunnelPresentationDescriptions('show_default', isDescriptionsDefault)"
      /> {{
        t("projects.shortlist.funnel.originalDescriptions") }}
    </div>
  </div>
  <div v-for="(item, index) in listItems" :key="index" class="box-item edit">
    <div class="box-count" :class="{ dark: index + 1 === listItems.length }">
      <div class="box-count-content" :style="{ width: `calc(100% - ${index} * ${diffDiv})` }">
        <div class="box-icon">
          <SvgIcon :icon="item.icon" :width="iconSize" :height="iconSize" />
        </div>
        <renderBoxTitle :item="item" />
      </div>
    </div>
    <div class="box-description">
      <TextArea
        v-if="!isDescriptionsDefault"
        v-model="item.label"
        class="item-input"
        :name="`box-${index}`"
        :warning-chars="160"
        :rows="3"
        :placeholder="t('projects.shortlist.funnel.addDescription')"
        :enforce-chars="true"
        :warning-message="t('text.textArea.funneltWarning', { count: 160 })"
        @change="updateProjectFunnelPresentationDescriptions(item.key, item.label)"
      />
      <span v-else v-html="item.label" />
    </div>
  </div>
  <div class="actions">
    <Button class="conclude-button" type="submit" variation="secondary" @click="$emit('cancel')">
      {{ t("text.form.conclude") }}
    </Button>
  </div>
</template>

<style lang="scss" scoped>
:deep(.form-input--container).form-input .input-wrapper {
  width: 2.5rem;
  border: 1px solid $gray-border;

  input {
    font-size: 1.25rem;
    width: auto;
    padding-inline: 0.3rem;
    text-align: center;
    font-weight: bold;

  }
}

.box-title {
  display: flex;
  gap: 0.625rem;
}

.box-description {
  position: relative;
  :deep(.textarea-container) {
    position: absolute;
    top: 0;
    width: 33.75rem;
    height: 100%;

    textarea {
      margin: 10px;
      resize: none;
    }
  }
  :deep(.text-area-wrapper) {
    .counter-wrapper {
      position: absolute;
      bottom: -1.8rem;
      margin-left: 1.25rem;
      justify-content: flex-start;
    }
  }
}
:deep(.p-inputswitch) {
  width: 2.5rem;
  height: 1.125rem;

  &.p-inputswitch-checked {
    outline: none;

    .p-inputswitch-slider {
      background: $yellow;
      box-shadow: 0px 3px 6px #00000015;
      border: 1px solid $yellow;
      width: 2.5rem;
      height: 1.125rem;

      &:hover {
        background: $yellow;
        outline: none;
      }
    }
  }

  &.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
    background: $yellow;
  }

  &.p-inputswitch-checked .p-inputswitch-slider {
    background: $yellow;
  }

  .p-inputswitch-slider:before {
    width: 0.625rem;
    height: 0.625rem;
    top: 0.8rem;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
  }

  &.p-focus .p-inputswitch-slider,
  &.p-focus .p-inputswitch-slider:focus {
    outline: none;
    box-shadow: none;
  }
}

.container-switch {
  display: flex;
  justify-content: space-between;
  margin-inline: 0.5rem;

  .check-switch {
    display: flex;
    align-items: center;
    gap: 0.4375rem;
    font-size: 14px;
    letter-spacing: 0.49px;
  }
}
</style>
