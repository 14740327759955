<script setup lang="ts">
import type { PropType } from "vue";
import type { ICandidate } from "@/validation/pages/validation/[project_id]/types";

const props = defineProps({
  items: { type: Array as PropType<ICandidate["work_experience"]> },
});

const expanded = ref(false);
const container = ref<HTMLElement>();

const isOverflown = computed(() => {
  if (!container?.value)
    return false;
  const element = container?.value;
  return element?.scrollHeight > element?.clientHeight || element?.scrollWidth > element?.clientWidth;
});

const focusOut = () => expanded.value = false;
</script>

<template>
  <div
    ref="container"
    tabindex="-1"
    class="items"
    :class="{ expanded, overflown: isOverflown }"
    @click="isOverflown && (expanded = !expanded)"
    @focusout="focusOut"
  >
    <span
      v-for="item in props.items"
      :key="item.corporation_name"
      :class="{ match: item.in_search_field }"
      class="experience"
      v-text="item.corporation_name"
    />
  </div>
</template>

<style scoped lang="scss">
.items {
  inset: 0;
  position: absolute;
  cursor: pointer;
  user-select: none;
  text-align: start;
  text-wrap: nowrap;
  overflow: hidden;
  padding: 0.4rem;
  min-height: 100%;
  display: flex;
  align-items: center;
  gap: .5rem;

  .experience {
    color: $gray-lower;

    &:after {
      content: ', ';
    }
  }

  .match {
    color: $green-medium;
    font-weight: bold;
  }
}

.items.overflown {
  &:before {
    content: '';
    position: absolute;
    bottom: -10px;
    right: -10px;
    width: 18px;
    height: 18px;
    rotate: 45deg;
    z-index: 0;
    background: $gray-low;
  }
}

.items.expanded {
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
  height: max-content;
  z-index: 10;
  background-color: $white-full;
  text-wrap: wrap;
  display: block;
}
</style>
