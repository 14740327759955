<script lang="ts">
import { useI18n } from "vue-i18n";
import ListableInputForm from "@/components/form/ListableForm/ListableInputForm.vue";
import { getData, removeForm } from "@/utils/adapters/form/fns";
import FormInput from "@/components/inputs/FormInput.vue";

export default defineComponent({
  components: {
    ListableInputForm,
    FormInput,
  },
  props: {
    formErrors: Object,
  },
  setup(props) {
    const { t } = useI18n();
    const forms = ref([]);

    const listableInputForm = ref<InstanceType<typeof ListableInputForm>>(null);
    const listableInputContent = computed(() => listableInputForm.value || "");

    const clear = () => {
      const index = Object.keys(listableInputForm.value.items);
      index.forEach((r, i) => {
        if (i !== 0)
          listableInputForm.value.removeItem(i);
      });
      forms.value[0].clear();
    };

    return {
      forms,
      listableInputForm,
      listableInputContent,
      t,
      getData: getData(forms),
      removeForm: removeForm(forms),
      clear,
    };
  },
});
</script>

<template>
  <ListableInputForm ref="listableInputForm" :link-text="t('persons.createPersonForm.addEmail')">
    <template #default="{ index }">
      <div class="form-group" :class="`email-${index}`">
        <label v-if="index === 0">{{ t("persons.createPersonForm.email") }}:</label>
        <label v-else />

        <FormInput
          :ref="
            (form) => {
              if (form) forms[index] = form;
            }
          "
          placeholder="ex.: mail@mail.com"
          :on-remove="removeForm(index)"
          :error-message="t(formErrors[`email_${index}`] || '')"
        />
      </div>
    </template>
  </ListableInputForm>
</template>
