<script setup lang="ts">
import { addDottedLine, formatBreakLines } from "@/utils/text";

defineProps<{
  description?: string
  historic?: string
  foundationYear?: string | number
}>();

const { t } = useI18n();
</script>

<template>
  <div class="company-about" @vue:mounted="addDottedLine('line-brake-dotted')">
    <div class="company-about-left">
      <span v-if="description" class="description" v-html="formatBreakLines(description)" />
    </div>
    <div class="company-about-right">
      <div class="col-title">
        {{ t("corporations.details.historic") }}
      </div>
      <div v-if="foundationYear" class="foundation">
        <strong> {{ t("corporations.details.foundationYear") }}: </strong>
        <span v-html="foundationYear" />
      </div>
      <p v-if="historic" class="line-brake-dotted">
        {{ historic }}
      </p>
    </div>
  </div>
</template>

<style scoped lang="scss">
.company-about {
  display: grid;
  grid-template-columns: 22rem auto;
  gap: 8.7rem;
  padding-inline: 0.5rem;

  &-left {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-top: 3.2rem;

    span {
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }

  &-right {
    display: flex;
    flex: 1;
    flex-direction: column;
    .foundation {
      margin-block-start: 1.5rem;
    }
    .col-title {
      color: $gray-dark;
      font-size: 1.125rem;
      font-weight: 600;
      line-height: 1.5rem;
      text-transform: uppercase;
    }

    span {
      font-size: 0.875rem;
      line-height: 1.25rem;
    }
  }
}

.line-brake {
  overflow-wrap: break-word;
  white-space: pre-wrap;
}

.line-brake-dotted {
  overflow-wrap: break-word;
  white-space: pre-wrap;

  :deep(.line-no-dotted) {
    position: relative;
    display: flex;

    &::before {
      display: block;
      content: "";
      margin-block: 0.45rem;
    }
  }

  $line-height: 1.2;
  $font-size: 1;

  :deep(.line) {
    position: relative;
    display: flex;
    line-height: #{$line-height}rem;

    &::before {
      display: block;
      width: 0.5rem;
      height: 0.5rem;
      margin-top: 0.27rem;
      aspect-ratio: 1;
      background-color: $yellow;
      border-radius: 100%;
      content: "";
      margin-inline-end: 0.5rem;
    }
  }
}
</style>
