import { createStore } from "vuex";

import UserStoreModule from "./modules/User";
import LanguageStoreModule from "./modules/Language";
import SystemStoreModule from "./modules/System";
import ProjectStoreModule from "./modules/Project";
import InterviewStoreModule from "./modules/Interviews";
import ScreeningStoreModule from "./modules/Screening";
import ConstructionStoreModule from "./modules/Construction";

export default createStore({
  modules: {
    LanguageStoreModule,
    UserStoreModule,
    SystemStoreModule,
    ProjectStoreModule,
    InterviewStoreModule,
    ScreeningStoreModule,
    ConstructionStoreModule,
  },
});
