<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "TextInput",
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    errorMessage: {
      type: String,
      default: "",
    },
    modelValue: String,
  },
});
</script>

<template>
  <div class="TextInput" :class="{ 'has-error': !!errorMessage }">
    <input
      :placeholder="placeholder"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target?.value)"
    >

    <p class="help-message">
      {{ errorMessage }}
    </p>
  </div>
</template>

<style scoped lang="scss">
.has-error {
  input {
    border: 1px solid $red-dark;
  }
}

input {
  width: 100%;
  height: 28px;
  padding: 0 10px;
  border: 1px solid #c0c0c0;
  border-radius: 6px;
  color: #666;
  font-family: "Open Sans";

  &::placeholder {
    color: #c6c6c6;
  }

  &:focus {
    outline: none;
  }
}

.help-message {
  margin-top: 3px;
  color: $red-dark;
  font-family: "Open Sans";
  font-size: 11px;
}
</style>
