<script setup lang="ts">
import PersonIcon from "@/assets/images/person_icon.svg";
import { SvgIcon } from "@/components/icon";
import { changeImageSize } from "@/utils/images/resize-images";
import type { ImageSizesType } from "@/schemas/enums/images";

interface PropsType {
  src?: string | null
  size?: string
  alt?: string | null
  placeholder?: string
  hasAlternativeIcon?: boolean
  lazy?: boolean
  imageSize?: ImageSizesType
}

const props = withDefaults(defineProps<PropsType>(), {
  src: null,
  size: "",
  alt: null,
  hasAlternativeIcon: true,
  lazy: true,
  imageSize: "large",
});

defineEmits(["click"]);

const avatarSize = computed(() => (props.size ? `--avatar-size: ${props.size}` : ""));
const imgSrc = ref(props.src);
const imgError = ref(false);

const shouldShowImage = computed(
  () => !imgError.value || (!props.placeholder && (props.src || props.alt)),
);
const shouldShowPersonIcon = computed(() => !imgSrc.value && props.hasAlternativeIcon);
const shouldShowPlaceholder = computed(() => props.placeholder && !props.hasAlternativeIcon);

function handleImageError() {
  imgError.value = true;
}

onBeforeMount(() => {
  imgSrc.value = props.src;
});
</script>

<template>
  <img
    v-if="shouldShowImage"
    v-memo="[changeImageSize(src, imageSize), changeImageSize(imgSrc, imageSize)]"
    :loading="lazy ? 'lazy' : 'eager'"
    :data-test="$attrs['data-test']"
    class="imua-avatar"
    :class="[$attrs.class]"
    :role="$attrs.role"
    :src="changeImageSize(imgSrc, imageSize)"
    :alt="alt"
    :srcset="$attrs.srcset"
    :sizes="$attrs.sizes"
    :style="avatarSize"
    @error="handleImageError"
    @click="$emit('click')"
  >
  <span v-else-if="shouldShowPersonIcon" class="imua-avatar" :class="[$attrs.class]" :style="avatarSize">
    <PersonIcon />
  </span>
  <span
    v-else-if="shouldShowPlaceholder"
    class="imua-avatar"
    :class="[$attrs.class]"
    :style="avatarSize"
  >
    <SvgIcon
      :type="$attrs.type || ''"
      :icon="placeholder"
      margin="none"
      width="50%"
      height="50%"
    />
  </span>
</template>

<style lang="scss" scoped>
.imua-avatar {
  --avatar-size: 3.7rem;

  display: inline-flex;
  overflow: hidden;
  width: var(--avatar-size);
  height: var(--avatar-size);
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: $gray-light;
  line-height: var(--avatar-size);
  text-align: center;
  text-indent: -1rem;
}

@-moz-document url-prefix("") {
  .imua-avatar {
    text-indent: 0;
  }
}
</style>
