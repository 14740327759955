<script setup lang="ts">
import { computed } from "vue";
import { Modal } from "@/components/modal";
import { TooltipBox } from "@/components/tooltip";
import SvgIcon from "@/components/icon/Icon.vue";

const props = defineProps({
  name: { type: String },
  included: { type: Boolean, default: () => true },
});

const i18n = useI18n();

const modalVisible = ref(false);
const tooltipVisible = ref(false);

const hasContent = computed(() => !!props.name);
const tooltipColor = computed(() => props.included ? "green-medium" : "red-warning");
const nonEditableMessage = computed<string>(() => {
  return i18n.t("projects.validation.extra.nonEditable", {
    name: `<b>${i18n.t("projects.validation.column.company").toLowerCase()}</b>`,
  });
});
</script>

<template>
  <TooltipBox v-if="hasContent" :color="tooltipColor" :show="tooltipVisible" class="tooltip">
    <SvgIcon v-show="props.included" icon="icon_done" width="12" height="12" />
    <SvgIcon v-show="!props.included" icon="icon_error" width="12" height="12" />
    {{ i18n.t('projects.validation.extra.searchField').toUpperCase() }}
  </TooltipBox>

  <div
    :class="{ included: !props.included && hasContent }"
    class="company"
    v-bind="$attrs"
    @click="modalVisible = true"
    @mouseout="tooltipVisible = false"
    @mouseover="tooltipVisible = true"
    v-text="props.name"
  />

  <Modal
    v-if="modalVisible"
    :on-click="() => modalVisible = false"
    :on-close="() => modalVisible = false"
    :title="i18n.t('projects.validation.extra.caution').concat('!').toUpperCase()"
    button-text="OK"
    button-variation="orange"
  >
    <template #message>
      <div class="text-center">
        <span v-html="nonEditableMessage" />
      </div>
    </template>
  </Modal>
</template>

<style scoped lang="scss">
.company {
  cursor: pointer;
  padding: 0 .4rem;

  &:after {
    position: absolute;
    inset: 0;
    content: '';
  }
}

.included {
  &:after {
    border-left-width: 3px;
    border-left-style: solid;
    border-left-color: $red-warning;
  }
}

.text-center {
  text-align: center;
}

.tooltip {
  width: 165px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .3rem;
}
</style>
