import type { ImageSizesType } from "@/schemas/enums/images";
import { ImageSizesEnum } from "@/schemas/enums/images";

export function resizeImageBase64(base64Str: string, maxWidth = 300, maxHeight = 300): Promise<string> {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = base64Str;
    img.onload = () => {
      const canvas = document.createElement("canvas");
      const MAX_WIDTH = maxWidth;
      const MAX_HEIGHT = maxHeight;
      let { width } = img;
      let { height } = img;

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      }
      else if (height > MAX_HEIGHT) {
        width *= MAX_HEIGHT / height;
        height = MAX_HEIGHT;
      }
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext("2d");
      ctx?.drawImage(img, 0, 0, width, height);
      resolve(canvas.toDataURL());
    };
  });
}

export function changeImageSize(
  url: string | null | undefined,
  type: ImageSizesType,
  current_type = ImageSizesEnum.original,
): string | any {
  if (!url || url === "" || !ImageSizesEnum[type as never])
    return url;

  return url?.replace(current_type, ImageSizesEnum[type as never]);
}
