<script setup lang="ts">
import debounce from "lodash-es/debounce";
import { type AccordionKey, accordionKeyMapInterviewEvaluation } from "@/projects/pages/projects/[id]/interviews/utils/index";
import InterviewEvaluation from "@/projects/pages/projects/[id]/interviews/components/InterviewEvaluation.vue";
import Editor from "@/components/editor/Editor.vue";
import { SvgIcon } from "@/components/icon";
import { getPerson } from "@/http_services/entities/persons";
import PersonDetail from "@/persons/pages/persons/components/PersonDetail/PersonDetail.vue";
import { Modal } from "@/components/modal";
import { isNotesSaving } from "@/common/composables/useInterviews";
import { isSaving } from "@/common/composables/useInterviewsEvaluation";

type NotesKeys = keyof typeof notes.value;

interface Notes {
  header: string
  current_moment: string
  activities_scope: string
  background: string
  experience: string
}

const route = useRoute();
const router = useRouter();
const { t } = useI18n();

const projectId = computed(() => route.params.id || "") as Ref<string>;
const personId = computed(() => route.params.person || "") as Ref<string>;
const activityId = computed(() => route.params.activity || "") as Ref<string>;

const loading = ref(false);
const interview = ref(null);

const { data: project } = useProject(projectId.value);

const {
  data: candidateActivities,
  mutate: refetchCandidateActivities,
} = useCandidateInterviewsActivities({
  project_id: projectId.value,
  candidate_person_id: personId.value,
});

const {
  mutate: fetchInterview,
} = useInterviewActivityMutation();

const {
  data: candidateData,
  refetch: fetchCandidate,
} = useFetchCandidate(projectId.value, personId.value);

const recruiters = computed(() => {
  let candidates = [...(candidateActivities.value || [])];
  const selected = candidates.filter(item => item._id === activityId.value);

  if (selected[0]) {
    const selectedIndex = candidates.indexOf(selected[0]);
    candidates?.splice(selectedIndex, 1);
    if (selectedIndex > -1)
      candidates = [...selected, ...candidates];
  }
  return candidates?.map(item => ({ ...item.recruiter, activity_id: item._id }));
});

const notes: Ref<Notes> = ref({
  header: "",
  current_moment: "",
  activities_scope: "",
  background: "",
  experience: "",
});

const minimized = ref<string[]>(["guide"]);
const titles = computed(() => ({
  guide: t("projects.details.tabs.interviewGuide"),
  notes: t("projects.details.activities.meetingsPanel.notes"),
  organization: t("projects.interview.panels.organizeAndEvaluate"),
}));

const {
  mutate: patchInterviewAnnotations,
} = usePatchInterviewAnnotationsMutation();

const {
  mutate: patchInterviewActivity,
} = usePatchInterviewActivity();

const debouncePatchAnnotations = debounce(patchInterviewAnnotations, 300);

function update(key: NotesKeys) {
  debouncePatchAnnotations({
    id: activityId.value,
    annotations: { [key]: notes.value[key] },
  });
}

const redirectToIndex = () => router.push(`/projects/${route.params.id}/interviews/activities`);

function redirectToNextStage(): void {
  const redirectMap: { [key: string]: string } = {
    "adherence-table": "adherence-table",
    "academic": "academic",
    "experiences": "experiences",
    "achievements": "achievements",
    "attributes": "attributes",
    "cultural-match": "cultural-match",
    "motivation": "motivation",
    "evaluation": "evaluation",
    "about": "about",
  };

  const redirectSource = String(route.query?.redirect_source);
  const defaultPath: string = "interviews/ranking";
  const pathSuffix: string = redirectMap[redirectSource] || defaultPath;

  const basePath: string = `/projects/${route.params.id}`;
  const personIdValue: string = String(personId.value);
  const targetPath: string = pathSuffix === "adherence-table"
    ? `${basePath}/shortlist/${pathSuffix}`
    : pathSuffix === defaultPath
      ? `${basePath}/${defaultPath}`
      : `${basePath}/shortlist/${personIdValue}/${pathSuffix}`;

  router.push(targetPath);
}

function minimizedAdd(value: string) {
  minimized.value.push(value);
}

function minimizedRemove(value: string) {
  const indexToRemove = minimized.value.indexOf(value);
  if (indexToRemove > -1)
    minimized.value.splice(indexToRemove, 1);
}

function finishActivity() {
  if (!interview.value?.activity_date) {
    fetchInterview(activityId.value, {
      onSuccess: (result) => {
        interview.value = result;
        patchInterviewActivity({
          project_id: String(route.params.activity),
          activity: {
            ...interview.value,
            activity_date: (new Date()).toISOString(),
          },
        });
        redirectToNextStage();
      },
    });
  }
  else {
    redirectToNextStage();
  }
}

const candidate = ref();
const recruiter = ref();
const showPersonDetail = ref(false);
const selectedPerson = ref(undefined);
const job = ref();

async function getCandidateData(id: string) {
  const data = await getPerson(id);

  if (data) {
    selectedPerson.value = toRaw(data);
    job.value = data?.work_experience[0] ?? {};
  }
}

const academicGuide = [{
  title: "",
  questions: [
    "Instituição:",
    "Nível:",
    "Area/Curso:",
    "Idiomas:",
    "Skill Obrigatória:",
  ],
}];

const experienceGuide = [
  {
    title: "Experiência Atual",
    questions: [
      "Empresa:",
      "Tempo de casa:",
      "Posição/ões:",
      "Localização:",
      "Qual é o contexto desta empresa?",
      "Pra quem reporta?",
      "Quem reporta para esta pessoa?",
      "Quem são os pares?",
      "Quais processos não estão sobre sua responsabilidade?",
      "Quais processos estão sobre as suas responsabilidades?",
      "Como se estrutura sua equipe de trabalho? Tamanho / Nível / Funções:",
    ],
  },
  {
    title: "PRINCIPAIS ENTREGAS DA POSIÇÃO (REALIZAÇÕES):",
    questions: [
    ],
  },
  {
    title: "EXPERIÊNCIA ANTERIOR RELEVANTE",
    questions: [
      "Empresa:",
      "Tempo de casa:",
      "Posição/ões:",
      "Localização:",
      "Qual é o contexto desta empresa?",
      "Pra quem reporta?",
      "Quem reporta para esta pessoa?",
      "Quem são os pares?",
      "Quais processos não estão sobre sua responsabilidade?",
      "Quais processos estão sobre as suas responsabilidades?",
      "Como se estrutura sua equipe de trabalho? Tamanho / Nível / Funções:",
    ],
  },
];

const motivationGuide = [{
  title: "",
  questions: [
    "Ambiente:",
    "Carreira:",
    "Pessoal:",
    "Pacote:",
    "Salário:",
    "Bônus:",
    "Pacote anual:",
    "Possiblidade de mudança?",
  ],
}];

function toggleAccordeon(key: string) {
}

async function onSelectRequester() {
  if (selectedPerson.value)
    showPersonDetail.value = true;
}

const interviewEvaluationRef = ref();
const showInterviewModal = ref(false);
const showWarningSlider = ref(false);
const candidateStatus = ref();

function confirm() {
  fetchCandidate().then(() => {
    loadData();
    if (!candidateStatus.value?.interview_conducted)
      showInterviewModal.value = true;

    else finishActivity();
  });
}

provide("onCloseDetails", () => {
  showPersonDetail.value = false;
  selectedPerson.value = undefined;
});

provide("selectedPerson", selectedPerson);

const showSaving = computed(() => isSaving.value || isNotesSaving.value);

function loadData() {
  if (candidateData.value && candidateData.value[0])
    candidateStatus.value = candidateData.value[0];

  if (interview.value) {
    candidate.value = interview.value?.candidate;
    recruiter.value = interview.value?.recruiter;
    notes.value = { ...interview.value?.annotations };
  }
}

async function fetchActivity() {
  interview.value = null;
  fetchInterview(activityId.value, {
    onSuccess: (result) => {
      interview.value = result;
      loadData();
      loading.value = false;
    },
    onError: () => {
      loadData();
      loading.value = false;
    },
  });
}

function changeRecruiter(activity_id: string) {
  router.push(`/projects/${projectId.value}/interviews/person/${personId.value}/activity/${activity_id}`);
  setTimeout(() => fetchActivity(), 300);
}

onMounted(async () => {
  await getCandidateData(personId.value);
  await refetchCandidateActivities();
  await fetchActivity();
  loadData();
});
</script>

<template>
  <div v-if="loading" class="loader-wrapper">
    <div class="loader" />
  </div>
  <div v-else>
    <div id="interview-activity" class="scrollable-container panel-wrapper">
      <HeaderActivity
        :project-name="project?.project_name ?? ''"
        :corporation-name="project?.corporation?.corporation_name ?? ''"
        :candidate="candidate"
        :recruiters="recruiters"
        :project-id="projectId"
        :candidate-id="personId"
        :activity-id="activityId"
        :job="job"
        :loading="showSaving"
        @click:logo="redirectToIndex()"
        @click:profile="onSelectRequester()"
        @click:cancel="redirectToIndex()"
        @click:save="confirm"
        @click:temp="confirm"
        @change:recruiter="changeRecruiter($event)"
      />
      <div class="panels scrollable-content" :class="{ minimized: minimized.length > 0 }">
        <div v-if="minimized.length > 0" class="panel-minimized">
          <div
            v-if="minimized.includes('guide')"
            class="panel-box box-guide"
            @click="minimizedRemove('guide')"
          >
            <div class="panel-title">
              <SvgIcon
                icon="construction_square_x_icon"
                width="18"
                height="18"
                class="icon_title"
              />
              <span>{{ titles.guide }}</span>
            </div>
          </div>
          <div
            v-if="minimized.includes('notes')"
            class="panel-box box-notes"
            @click="minimizedRemove('notes')"
          >
            <div class="panel-title">
              <SvgIcon
                icon="construction_square_x_icon"
                width="18"
                height="18"
                class="icon_title"
              />
              <span>{{ titles.notes }}</span>
            </div>
          </div>
          <div
            v-if="minimized.includes('organization')"
            class="panel-box box-organization"
            @click="minimizedRemove('organization')"
          >
            <div class="panel-title">
              <SvgIcon
                icon="construction_square_x_icon"
                width="18"
                height="18"
                class="icon_title"
              />
              <span>{{ titles.organization }}</span>
            </div>
          </div>
        </div>
        <div v-if="!minimized.includes('guide')" class="scrollable-content panel">
          <div class="title" @click="minimizedAdd('guide')">
            <SvgIcon
              icon="construction_square_x_icon"
              width="18"
              height="18"
              class="icon_title"
            />
            <span>{{ titles.guide }}</span>
          </div>
          <div>
            <div class="guide-container">
              <BoxAccordion
                :title="t('projects.screening.guide.accordeons.educationalBackground')"
                @selected="toggleAccordeon('academicGuide')"
              >
                <div v-for="(group, idx) in academicGuide" :key="idx">
                  <div v-if="group.title" class="guide-title">
                    {{ group.title }}
                  </div>
                  <div v-for="(question, questionidx) in group.questions" :key="questionidx" class="guide-question">
                    {{ question }}
                  </div>
                </div>
              </BoxAccordion>
              <BoxAccordion
                :title="t('projects.details.constructionGuide.idealProfile.experience')"
                @selected="toggleAccordeon('experienceGuide')"
              >
                <div v-for="(group, idx) in experienceGuide" :key="idx">
                  <div v-if="group.title" class="guide-title">
                    {{ group.title }}
                  </div>
                  <div
                    v-for="(question, questionIdx) in group.questions"
                    :key="`group-${idx}-${questionIdx}`"
                    class="guide-question"
                  >
                    {{ question }}
                  </div>
                </div>
              </BoxAccordion>
              <BoxAccordion
                :title="t('projects.interview.accordeons.motivation')"
                @selected="toggleAccordeon('motivationGuide')"
              >
                <div v-for="(group, idx) in motivationGuide" :key="idx">
                  <div v-if="group.title" class="guide-title">
                    {{ group.title }}
                  </div>
                  <div
                    v-for="(question, questionIdx) in group.questions"
                    :key="`group-${idx}-${questionIdx}`"
                    class="guide-question"
                  >
                    {{ question }}
                  </div>
                </div>
              </BoxAccordion>
            </div>
          </div>
        </div>
        <div
          v-if="!minimized.includes('notes')"
          class="scrollable-content panel panel-border-sm"
        >
          <div class="title" @click="minimizedAdd('notes')">
            <SvgIcon
              icon="construction_square_x_icon"
              width="18"
              height="18"
              class="icon_title"
            />
            <span>{{ titles.notes }}</span>
          </div>
          <div class="scrollable-content example-scrollable-full">
            <div style="width: 100%">
              <Editor
                v-model="notes.header"
                :placeholder="t('text.text')"
                @update:model-value="update('header')"
                @blur="fetchActivity()"
              />
            </div>
            <div style="width: 100%;">
              <div class="editor-title">
                {{ t("projects.details.activities.screening.form.labels.currentMoment") }}
              </div>
              <Editor
                v-model="notes.current_moment"
                :placeholder="t('projects.details.activities.screening.form.labels.currentMoment')"
                @update:model-value="update('current_moment')"
                @blur="fetchActivity()"
              />
            </div>
            <div style="width: 100%;">
              <div class="editor-title">
                {{
                  t("projects.details.activities.screening.form.labels.scopeofActivities")
                }}
              </div>
              <Editor
                v-model="notes.activities_scope"
                :placeholder="t('projects.details.activities.screening.form.labels.scopeofActivities')"
                @update:model-value="update('activities_scope')"
                @blur="fetchActivity()"
              />
            </div>
            <div style="width: 100%;">
              <div class="editor-title">
                {{
                  t("projects.details.activities.screening.form.labels.graduation")
                }}
              </div>
              <Editor
                v-model="notes.background"
                :placeholder="t('projects.details.activities.screening.form.labels.graduation')"
                @update:model-value="update('background')"
                @blur="fetchActivity()"
              />
            </div>
            <div style="width: 100%;">
              <div class="editor-title">
                {{
                  t("projects.details.activities.screening.form.labels.experience")
                }}
              </div>
              <Editor
                v-model="notes.experience"
                :placeholder="t('projects.details.activities.screening.form.labels.experience')"
                @update:model-value="update('experience')"
                @blur="fetchActivity()"
              />
            </div>
          </div>
        </div>
        <div v-if="!minimized.includes('organization')" class="scrollable-content panel panel-border-sm">
          <div class="title" @click="minimizedAdd('organization')">
            <SvgIcon
              icon="construction_square_x_icon"
              width="18"
              height="18"
              class="icon_title"
            />
            <span>{{ titles.organization }}</span>
          </div>
          <div class="scrollable-content example-scrollable-full">
            <InterviewEvaluation
              :ref="ref => interviewEvaluationRef = ref"
              :show-warning-slider="showWarningSlider"
              :accordion-opened="accordionKeyMapInterviewEvaluation[route.query?.redirect_source as AccordionKey] ?? accordionKeyMapInterviewEvaluation.academic"
              @pending="isPending = $event"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal
    v-if="showInterviewModal"
    class="modal-confirm"
    :on-click="() => (showInterviewModal = false, showWarningSlider = true)"
    :on-close="() => (showInterviewModal = false, showWarningSlider = true)"
    :title="t('projects.modal.cantSave.title')"
    :message="t('projects.modal.cantSave.message')"
    :button-text="t('projects.modal.cantSave.primaryBtn')"
    :secondary-button-text="t('projects.modal.cantSave.secundaryBtn')"
    button-variation="filled"
    buttons-wrapper-class="buttons-wrapper"
    wrapper-class="content-wrapper"
    message-align="center"
    :secondary-on-click="redirectToIndex"
  />
  <PersonDetail :visible="showPersonDetail" />
</template>

<route lang="yaml">
meta:
  layout: activity
</route>

<style lang="scss" scoped>
.panel-wrapper {
  display: flex;
  flex-direction: column;
  background: #f4f4f4;

  .header {
    background: #fff 0% 0% no-repeat padding-box;
  }

  .panels {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5rem;

  }

  .panels.minimized {
    grid-template-columns: 3.125rem repeat(calc(3 - v-bind("minimized.length")), 1fr);

    .panel-minimized {
      display: flex;
      width: 3.125rem;
      flex-direction: column;
      // grid-template-rows: 13rem 9rem auto;
      margin-block: 0.5rem;
      gap: 0.5rem;

      .box-guide {
        height: 13rem;
      }

      .box-notes {
        height: 9rem;
      }

      .box-organization {
        height: 15.5rem;
      }

      .panel-box {
        display: flex;
        flex-direction: column;
        width: 3rem;
        align-items: center;
        justify-content: center;
        background: $white-full;
        box-shadow: 0 3px 4px #00000029;
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        padding-block: 1rem;
        cursor: pointer;

        .panel-title {
          display: grid;
          grid-template-columns: auto 1fr;
          width: max-content;
          // height: 3.125rem;
          transform: rotate(-90deg);
          transform-origin: center;
          cursor: pointer;

          span {
            color: $primary;
            font-size: 0.875rem;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}

.example-scrollable-full {
  gap: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-inline: 0.5rem;

  &::-webkit-scrollbar {
    width: 23px;
    padding: 3px;
  }

  &::-webkit-scrollbar-thumb {
    width: 6px;
    border: 8px transparent solid;
    border-radius: 99px;
    background: #00000082 0% 0% no-repeat padding-box;
  }
}
.panel {
  margin-block: 0.5rem;
  border-radius: 8px;
  background: #fff 0% 0% no-repeat padding-box;
  box-shadow: 0 3px 4px #00000029;
  padding: 0.5rem;
}
.title {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-inline: 0.5rem;
  gap: 1rem;
  letter-spacing: 0.77px;
  cursor: pointer;

  span {
    color: $red-violet;
    font-weight: 600;
    font-size: 1.25rem;
    text-transform: uppercase;
    letter-spacing: inherit;
  }
}

.editor-title {
  padding: 0.5rem 0;
  margin-top: 1rem;
  font-size: 1.125rem;
  font-weight: 700;
  user-select: none;
}

.guide {
  &-container {
    padding-inline: 0.875rem;
  }

  &-title {
    font-size: 1rem;
    padding-top: 1rem;
    text-transform: uppercase;
    font-weight: 700;
  }

  &-question {
    margin-block: 2rem;
    font-size: 0.875rem;
  }
}
.modal-confirm:deep {
  .modal-title {
    font-size: 1.25rem;
    font-weight: bold;
    text-align: center;
  }

  .modal-message {
    font-size: 1.25rem;
    font-weight: normal;
    line-height: 1.4;
    padding-inline: 1rem;
    text-align: center;
  }

  .content-wrapper {
    width: 46rem;
    min-width: 340px;
    padding: 3rem 3.25rem;
  }

  .buttons-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }

  .confirm-button {
    border-color: $secondary;
    background-color: $white-full;
    color: $secondary;

    &:hover {
      background-color: $secondary;
      color: $white-full;
    }
  }
}

.loader-wrapper {
  width: 100%;
  height: 100vh;
  padding-block: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  width: 2rem;
  height: 2rem;
  border: 5px solid;
  border-color: $yellow transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
