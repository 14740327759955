<script lang="ts">
import type { Ref } from "vue";
import {
  computed,
  defineComponent,
  inject,
} from "vue";
import { useI18n } from "vue-i18n";
import { SectionTitle } from "@/components/section_title";
import type { Person } from "@/http_services/entities/types";
import AcademicFormation from "@/persons/pages/persons/components/PersonDetail/AcademicFormation.vue";
import Certifications from "@/persons/pages/persons/components/PersonDetail/Certifications.vue";
import Language from "@/persons/pages/persons/components/PersonDetail/Language.vue";

export default defineComponent({
  name: "AcademicFormationTab",
  components: {
    SectionTitle,
    AcademicFormation,
    Certifications,
    Language,
  },
  setup() {
    const { t } = useI18n();
    const person = inject("person") as Ref<Person>;
    const noAttributes = computed(() => {
      const { education: e, certifications: c, languages: l } = person.value;
      return !e?.length && !c?.length && !l?.length;
    });

    return {
      t,
      person,
      noAttributes,
    };
  },
});
</script>

<template>
  <div v-if="person" class="tab-container">
    <SectionTitle :title="t('persons.details.academicFormation')" />

    <AcademicFormation v-if="person.education?.length" class="academic-content" />

    <Language v-if="person.languages?.length" class="academic-content" />

    <Certifications v-if="person.certifications?.length" class="academic-content" />

    <p v-if="noAttributes">
      {{ t("persons.details.placeholders.emptyAttribute") }}
    </p>
  </div>
</template>

<style lang="scss" scoped>
.tab-section {
  margin-bottom: 2.5rem;

  &.--large-spacing {
    margin-bottom: 3rem;
  }

  .academic-content {
    margin-bottom: 2rem;
  }
}
</style>
