<script lang="ts" setup>
import { useStore } from "vuex";
import { debounce, isEmpty } from "lodash";
import { mdiChevronLeft as chevron } from "@mdi/js";
import SvgIcon from "@jamescoyle/vue-icon";
import {
  CorporationHeader,
  CorporationHeaderEditable,
} from "../components/CorporationDetail/Header";
import { useCorporation, useEmployees, usePersonDisplay, useTabs } from "./Details";
import { PageWrapper } from "@/components/page_wrapper";
import { Tabs } from "@/components/tab";
import { Modal } from "@/components/modal";
import WarningIcon from "@/assets/images/alert_circle_icon.svg";

import type { baseEducationalOrganization } from "@/schemas/corporation";

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const store = useStore();
const editing = ref("");

const { currentTab, tabs } = useTabs(t);

const EducationalOrganization = ref(null) as Ref<baseEducationalOrganization>;
const isEducational = ref(false);

const {
  corporation,
  // corporationPayload,
  loading,
  hasSubmitionError,
  fetchCorporation,
  provideCorporation,
} = useCorporation({ router, route, store });

const {
  data: educationalOrganization,
  refetch: refetchEducationalOrganization,
} = useEducationalOrganization(String(route.params.id));

const debounceUpdate = debounce(async () => {
  refetchEducationalOrganization();
  await fetchCorporation();
}, 300);

provideCorporation();

const { fetchEmployees, provideEmployees } = useEmployees();

provideEmployees();
watchEffect(() => {
  if (corporation.value?.id) {
    if (educationalOrganization.value && educationalOrganization.value.length > 0) {
      isEducational.value = true;
      EducationalOrganization.value = educationalOrganization.value[0];
    }
  }
});

watchEffect(async () => {
  await fetchCorporation();

  if (corporation.value)
    await fetchEmployees(corporation.value.name);
});

const { showPersonDetails, PersonComponent, provideDisplay } = usePersonDisplay();

provideDisplay();
</script>

<template>
  <PageWrapper content-columns="1fr">
    <template #content>
      <template v-if="!loading">
        <div>
          <div class="back-to-list" @click="$router.push(`/corporations`)">
            <SvgIcon type="mdi" :path="chevron" size="16" />
            <span>{{ t("corporations.details.backToList") }}</span>
          </div>
          <CorporationHeader
            v-if="!isEmpty(corporation) && editing === ''"
            :corporation="corporation"
            :educational-organization="EducationalOrganization"
            :is-educational="isEducational"
            @edit:corporation="editing = 'header'"
          />
          <CorporationHeaderEditable
            v-if="!isEmpty(corporation) && editing === 'header'"
            :corporation="corporation"
            :educational-organization="educationalOrganization ? educationalOrganization[0] : {}"
            :is-educational="isEducational"
            @cancel:edit="editing = '', debounceUpdate()"
            @update:corporation="debounceUpdate()"
          />
        </div>

        <Tabs v-model:activeTab="currentTab" :tabs="tabs" color="secondary">
          <template #tab-content="{ props, events, component }">
            <component v-bind="props" :is="component" v-on="events" />
          </template>
        </Tabs>
      </template>
    </template>
  </PageWrapper>

  <component :is="PersonComponent" :visible="showPersonDetails" />

  <Modal
    v-if="hasSubmitionError"
    :title="t('text.form.error.modal.genericTitle')"
    :message="t('text.form.error.modal.genericMessage')"
    :button-text="t('text.form.error.modal.genericButtonMessage')"
    :on-close="() => (hasSubmitionError = false)"
    variation="warning"
    secondary-variation="light"
    @click="fetchCorporation()"
  >
    <template #titleIcon>
      <WarningIcon />
    </template>
  </Modal>
</template>

<route lang="yaml">
meta:
  layout: default-page
</route>

<style lang="scss" scoped>
.back-to-list {
  font-size: 0.625rem;
  font-weight: 600;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;

  span {
    vertical-align: super;
  }

  &:hover {
    span {
      color: lighten($gray-dark, 20%);
    }
  }
}

.tab-container {
  margin: 1rem 2rem 2rem 1rem;
}
</style>
