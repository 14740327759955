<script setup lang="ts">
import ExportPage from "./ExportPage.vue";
import FlowIcon from "@/assets/images/flow_ef_yellow.svg?component";

const props = withDefaults(
  defineProps<{
    isExport: boolean
  }>(),
  {
    isExport: false,
  },
);

const { isExport } = toRefs(props);
</script>

<template>
  <ExportPage :is-export="isExport" :full-page="true">
    <div class="pdf-back-cover">
      <div class="icon-container">
        <div class="flow-icon">
          <FlowIcon width="16rem" height="10rem" />
        </div>
      </div>
    </div>
  </ExportPage>
</template>

<style lang="scss" scoped>
.pdf-back-cover {
  display: flex;
  height: calc(var(--pdf-height) - 4px);
  flex-direction: column;

  .icon-container {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }

  .flow-icon {
    position: relative;
    display: flex;
    align-items: center;
  }
}
</style>
