<script lang="ts">
import { computed, defineComponent, ref } from "vue";

export default defineComponent({
  name: "Tooltip",
  props: {
    tooltipTitle: String,
    tooltipText: String,
    tooltipWidth: String,
    yAlign: {
      type: String,
      default: "bottom",
    },
    yCenter: {
      type: Boolean,
      default: false,
    },
    xAlign: {
      type: String,
      default: "right",
    },
    xCenter: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const hovered = ref(false);
    const tooltipComputedWidth = computed(() =>
      props.tooltipWidth ? `--tooltip-width: ${props.tooltipWidth}` : "");

    return {
      hovered,
      tooltipComputedWidth,
    };
  },
});
</script>

<template>
  <span
    class="tooltip"
    :class="[{ 'tooltip--active': hovered }]"
    @mouseover="hovered = true"
    @mouseleave="hovered = false"
  >
    <slot />
    <p
      v-show="hovered"
      class="tooltip-text"
      :class="[
        {
          [`--${yCenter ? 'y-center' : yAlign}`]: true,
          [`--${xCenter ? 'x-center' : xAlign}`]: true,
        },
      ]"
      :style="tooltipComputedWidth"
    >
      <template v-if="tooltipTitle">
        <span class="tooltip-title">{{ tooltipTitle }}</span>
      </template>

      <span class="tooltip-message">
        <span v-html="tooltipText" />
        <slot name="tooltip-message" />
      </span>
    </p>
  </span>
</template>

<style lang="scss" scoped>
.tooltip {
  position: relative;
  cursor: pointer;

  &--active {
    color: $primary;
  }
}

.tooltip-text {
  position: absolute;
  z-index: 1100;
  width: var(--tooltip-width, 130px);
  padding: 5px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 4px #0003;
  color: $gray-dark;
  font-size: 12px;
  font-weight: normal;

  &.--top {
    bottom: 100%;
  }

  &.--bottom {
    top: 100%;
  }

  &.--left {
    right: 100%;
  }

  &.--right {
    left: 100%;
  }

  &.--y-center {
    top: 50%;
    transform: translateY(-50%);
  }

  &.--x-center {
    left: 50%;
  }
}

.tooltip-title {
  display: block;
  color: $primary;
  font-weight: bold;
}

.tooltip-title~.tooltip-message:not(:empty) {
  margin-bottom: 0.5rem;
}

.tooltip-message {
  word-break: normal;
}
</style>
