<script setup lang="ts">
import bson from "bson-objectid";
import { mdiPlusCircleOutline } from "@mdi/js";
import { ButtonIcon } from "@/components/button";
import { SvgIcon } from "@/components/icon";

interface Achievements {
  description?: string
  desired_attribute?: string
  desired_cultural_match?: string
}
interface AchievementsMap {
  [key: string]: Achievements
}
interface Profile {
  [key: string]: string
}

const props = defineProps<{
  achievements: AchievementsMap
  academicBackgrounds: Profile
  languages: Profile
  experiences: Profile
  constructionId: string | undefined
}>();

const emit = defineEmits(["cancel"]);
const { t } = useI18n();
const { mutate: patchIdealProfile, isPending } = usePatchIdealProfileQuery();

function onUpdate(key: string, data: Partial<typeof idealProfileList>): void {
  if (!isPending.value) {
    patchIdealProfile({
      _id: String(props.constructionId),
      [key]: data,
    });
  }
}

const idealProfileList: {
  academic_backgrounds_and_languages: {
    academic_backgrounds: Profile
    languages: Profile
  }
  experiences: Profile
  achievements: AchievementsMap
} = reactive({
  academic_backgrounds_and_languages: {
    academic_backgrounds: {},
    languages: {},
  },
  experiences: {},
  achievements: {},
});

watchEffect(() => {
  idealProfileList.achievements = toRaw(props.achievements);
  idealProfileList.experiences = toRaw(props.experiences);
  idealProfileList.academic_backgrounds_and_languages = { academic_backgrounds: toRaw(props.academicBackgrounds), languages: toRaw(props.languages) };
});

function addItem(key: keyof typeof idealProfileList): void {
  if (!isPending.value) {
    let tmp = { ...idealProfileList[key] };
    tmp = tmp || {};
    const newid: string = bson().toHexString();

    tmp[newid] = "";
    idealProfileList[key] = tmp;
  }
}
function addItemAcadcemic(key: keyof typeof idealProfileList.academic_backgrounds_and_languages): void {
  if (!isPending.value) {
    let tmp = { ...idealProfileList.academic_backgrounds_and_languages[key] };
    tmp = tmp || {};
    const newid: string = bson().toHexString();

    tmp[newid] = "";
    idealProfileList.academic_backgrounds_and_languages[key] = tmp;
  }
}
function addAchievements(): void {
  if (!isPending.value) {
    let tmp: AchievementsMap = { ...idealProfileList.achievements };
    tmp = tmp || {};
    const newid: string = bson().toHexString();

    tmp[newid] = {
      description: "",
      desired_attribute: "EMPTY",
      desired_cultural_match: "EMPTY",
    };
    idealProfileList.achievements = tmp;
  }
}
function removeItemAcademic(key: keyof typeof idealProfileList.academic_backgrounds_and_languages, idx: number | string): void {
  if (!isPending.value) {
    let tmp = { ...idealProfileList.academic_backgrounds_and_languages[key] };
    tmp = tmp || {};
    delete tmp[idx];
    idealProfileList.academic_backgrounds_and_languages[key] = tmp;
    onUpdate(key, idealProfileList.academic_backgrounds_and_languages[key]);
  }
}
function removeItem(key: Exclude<keyof typeof idealProfileList, "academic_backgrounds_and_languages">, idx: number | string): void {
  if (!isPending.value) {
    let tmp = { ...idealProfileList[key] };
    tmp = tmp || {};
    delete tmp[idx];
    idealProfileList[key] = tmp;
    onUpdate(key, idealProfileList[key]);
  }
}
const sectionsTitles = computed(() => {
  return {
    academic_backgrounds: t("projects.shortlist.titles.academicEducation"),
    experiences: t("projects.shortlist.titles.experience"),
    achievements: t("projects.shortlist.titles.achievements"),
    languages: t("projects.screening.guide.languages"),
  };
});
</script>

<template>
  <div v-if="idealProfileList" class="form">
    <div v-for="(item, profileKey) in idealProfileList" :key="`idealProfile-${profileKey}`" class="form-item">
      <h3 v-if="profileKey !== 'academic_backgrounds_and_languages'">
        {{ sectionsTitles[profileKey].toLocaleUpperCase() }}
      </h3>
      <template v-if="profileKey === 'academic_backgrounds_and_languages'">
        <div v-for="(academicLanguage, key) in item" :key="key">
          <h3 v-if="sectionsTitles[key]">
            {{ sectionsTitles[key].toLocaleUpperCase() }}
          </h3>
          <template
            v-for="(_, indx) in academicLanguage"
            :key="indx"
          >
            <ButtonIcon class="remove-icon" @click="removeItemAcademic(key, indx)">
              <SvgIcon icon="trash_icon" width="20" height="20" margin="none" />
            </ButtonIcon>
            <TextArea
              v-model="academicLanguage[indx]"
              class="item-input"
              :name="`achievement-${indx}`"
              :warning-chars="650"
              rows="5"
              :placeholder="sectionsTitles[key]"
              :warning-message="t('text.textArea.shorlistWarning', { count: 650 })"
              @change="onUpdate(key, academicLanguage)"
            />
          </template>

          <ButtonIcon class="add-button" @click="addItemAcadcemic(key)">
            <SvgIcon
              :icon="mdiPlusCircleOutline"
              type="mdi"
              width="20"
              height="20"
              margin="none"
            />
            {{ t("projects.details.constructionGuide.idealProfile.addSubItem", { user: sectionsTitles[key] }) }}
          </ButtonIcon>
        </div>
      </template>
      <template v-else>
        <div v-for="(form, idx) of item" :key="idx" class="base-item">
          <template v-if="profileKey !== 'achievements'">
            <ButtonIcon class="remove-icon" @click="removeItem(profileKey, idx)">
              <SvgIcon icon="trash_icon" width="20" height="20" margin="none" />
            </ButtonIcon>
            <TextArea
              v-model="item[idx]"
              class="item-input"
              :name="`achievement-${form}`"
              :warning-chars="650"
              rows="5"
              :placeholder="sectionsTitles[profileKey]"
              :warning-message="t('text.textArea.shorlistWarning', { count: 650 })"
              @change="onUpdate(profileKey, item)"
            />
          </template>
          <template v-else>
            <ButtonIcon class="remove-icon" @click="removeItem(profileKey, idx)">
              <SvgIcon icon="trash_icon" width="20" height="20" margin="none" />
            </ButtonIcon>
            <TextArea
              v-model="item[idx].description"
              class="item-input"
              :name="`achievement-${form}`"
              :warning-chars="650"
              rows="5"
              :placeholder="sectionsTitles[profileKey]"
              :warning-message="t('text.textArea.shorlistWarning', { count: 650 })"
              @change="onUpdate(profileKey, item)"
            />
          </template>
        </div>
      </template>
      <ButtonIcon v-if="profileKey !== 'academic_backgrounds_and_languages'" class="add-button" @click="(profileKey === 'achievements') ? addAchievements() : addItem(profileKey)">
        <SvgIcon
          :icon="mdiPlusCircleOutline"
          type="mdi"
          width="20"
          height="20"
          margin="none"
        />
        {{ t("projects.details.constructionGuide.idealProfile.addSubItem", { user: sectionsTitles[profileKey] }) }}
      </ButtonIcon>
    </div>
  </div>
  <Button class="conclude-button" type="submit" variation="secondary" @click="emit('cancel')">
    {{ t("text.form.conclude") }}
  </Button>
</template>

<style scoped lang="scss">
.form {
  display: flex;
  flex-wrap: wrap;
  gap: 0 2.2rem;
  margin-block: 0.75rem;
  margin-inline: 40px;

  &-item {
    flex-basis: 30%;
    margin-block: 2rem 0;

    h3 {
      font-weight: 600;
    }
  }
}

.base-item {
  gap: 0.25rem;
  margin-block: 0.5rem 1.5rem;

  .item-input {
    width: 100%;
  }
}

.remove-icon {
  display: block;
  margin-inline-start: auto;
  margin-inline-end: 0.75rem;
  margin-block-end: 0.5rem;
}

.add-button {
  display: flex;
  color: $red-violet;
  margin-inline-start: auto;
  text-align: center;
  align-items: center;
  gap: 0.5rem;
  &:hover {
    color: $red-violet-medium;
  }
}

.panel-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  margin: 0.5rem 0 1.5rem 0;

  &:focus,
  &:hover {
    &.add,
    &.add > span {
      color: $red-violet-medium;

      svg {
        vertical-align: bottom;
      }
    }
  }

  &.add,
  &.add > span {
    font-size: 0.75rem;
    letter-spacing: 0;
    font-weight: 600;

    svg {
      vertical-align: bottom;
    }
  }
}

.form-actions {
  display: flex;
  gap: 2rem;
  margin-block: 1.5rem;
}

.conclude-button {
  margin: 2rem;
}
</style>
