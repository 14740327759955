import type { SearchCandidate } from "@/common/composables/useShortlist";
import type { InterviewCandidates } from "@/types/candidates";

interface StageTabsType { key?: string; name?: string; path?: string; icon?: string; label?: string }

export interface RankingWeights {
  academic_formation: number
  experiences: number
  achievements: number
  attributes: number
  cultural_match: number
  motivation: number
};
export interface RankingWeightsKeysConverted {
  schooling?: number
  professionalExperience?: number
  realizations?: number
  attributes?: number
  cultural_match?: number
  motivation?: number
}
export type CandidateRank = InterviewCandidates & { scores: {
  schooling: number
  professionalExperience: number
  realizations: number
  attributes: number
  cultural_match: number
  motivation: number
} };

export function getSortedShortlistCandidates(id: string, listCandidates: SearchCandidate[], stageTabs: StageTabsType[]) {
  if (!listCandidates)
    return stageTabs;

  const sortedCandidates = listCandidates
    .map((candidate: SearchCandidate) => ({
      key: candidate?.person_id,
      name: `${candidate?.person_data?.first_name} ${candidate?.person_data?.last_name}`,
      path: `/projects/${id}/shortlist/${candidate.person_id}/evaluation`,
    }));

  return stageTabs.concat(sortedCandidates);
}

export function calculateWeightsByOrder(rankingWeights: RankingWeights | undefined) {
  return computed(() => ({
    schooling: rankingWeights?.academic_formation,
    professionalExperience: rankingWeights?.experiences,
    realizations: rankingWeights?.achievements,
    attributes: rankingWeights?.attributes,
    cultural_match: rankingWeights?.cultural_match,
    motivation: rankingWeights?.motivation,
  }));
}

export function calculateShortlistCandidatesRank(listCandidates: CandidateRank[]) {
  if (!listCandidates)
    return computed(() => []);

  return computed(() => {
    return listCandidates
      .map(candidate => ({
        ...candidate,
        scores: {
          schooling: candidate.calculated_scores?.academic_formation_mean || 0,
          professionalExperience: candidate.calculated_scores?.experiences_mean || 0,
          realizations: candidate.calculated_scores?.achievements_mean || 0,
          attributes: candidate.calculated_scores?.attributes_mean || 0,
          cultural_match: candidate.calculated_scores?.cultural_match_mean || 0,
          motivation: candidate.calculated_scores?.motivation_mean || 0,
        },
      }));
  });
}
