<script setup lang="ts">
import { useI18n } from "vue-i18n";
import ExportPage from "./ExportPage.vue";
import IdealProfileTags from "./IdealProfileTags.vue";

defineProps<{
  profile: any
  avatarUrl: string
}>();
const { t } = useI18n();
</script>

<template>
  <ExportPage
    :is-export="false"
    :has-avatar="false"
    :page-name="t('exportPDF.summaryEnum.idealProfileTags')"
    :avatar-url="avatarUrl"
  >
    <template #title />
    <IdealProfileTags :ideal-profile="profile" />
  </ExportPage>
</template>

<style scoped lang="scss">
:deep(.content-list-item) {
  li:nth-of-type(1n + 6) {
    display: none;
  }
}
</style>
