<script setup lang="ts">
import ListItem from "@/components/text/ListItem.vue";

defineProps<{
  list: string[]
}>();

function formatBreakLine(value: string | undefined) {
  if (!value)
    return value;

  return value.replaceAll("\n", "<br>");
}
</script>

<template>
  <div class="simple-list">
    <ul class="simple-list__list">
      <ListItem v-for="(item, idx) in list" :key="idx" mb="0 1.125rem" tag="p">
        <span v-html="formatBreakLine(item)" />
      </ListItem>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.simple-list {
  $li-padding: 1rem;
  $half-padding: calc($li-padding / 2);

  padding-bottom: 1.5rem;

  &__list {
    padding-bottom: 0;
    margin-block-end: 3rem;
    padding-inline: 0;

    .rounded.default {
      line-height: 1.375rem;
    }
  }
}
</style>
