import { z } from "zod";
import { MonetaryAmountRange } from "@/types/currency";
import { SalaryRangeSchema } from "@/types/salary";
import { UserSchema } from "@/types/user";
import { ConclaEnumArraySchema } from "@/types/business_function";
import { EntitySchema } from "@/types/entity";

// TODO: use enums from here...
enum ProjectTypeEnum {
  BOARD = "BOARD",
  CEO_AND_EXECUTIVE_OFFICERS = "CEO_AND_EXECUTIVE_OFFICERS",
  LEADERSHIP_SUCCESSION = "LEADERSHIP_SUCCESSION",
  MAPPING = "MAPPING",
  ASSESSMENT = "ASSESSMENT",
  SECOND_OPINION = "SECOND_OPINION",
  SCOPE_DESIGN = "SCOPE_DESIGN",
}

enum PlacementTypeEnum {
  NEW_CLIENT = "NEW_CLIENT",
  ACTIVE_RECURRENCE = "ACTIVE_RECURRENCE",
  PASSIVE_RECURRENCE = "PASSIVE_RECURRENCE",
  REACTIVATION = "REACTIVATION",
}
const BaseParticipant = z.object({
  person_id: z.string().optional(),
});

const Participant = BaseParticipant.extend({
  first_name: z.string().optional(),
  last_name: z.string().optional(),
  avatar_url: z.string().nullable().default(""),
});

const Address = z.object({
  name: z.string().nullable().optional(),
  street: z.string().nullable().optional(),
  postal_code: z.string().nullable().optional(),
  region: z.string().nullable().optional(),
  neighborhood: z.string().nullable().optional(),
  locality: z.string().nullable().optional(),
});

enum PositionClassEnum {
  CLASS_A = "CLASS_A",
  CLASS_B = "CLASS_B",
  CLASS_C = "CLASS_C",
}

const PositionDetails = z.object({
  address: Address.optional(),
  position_class: z.nativeEnum(PositionClassEnum).optional(),
  level: z.string().min(1).nullable().refine((value) => {
    const validLevels = ["+1", "0", "-1", "-2", "-3", "-4"];
    return value === null || validLevels.includes(value);
  }, { message: "Invalid level. Allowed values: +1, 0, -1, -2, -3, -4" }),
  salary_range: SalaryRangeSchema.optional(),
  bonus: z.string().min(1).nullable().optional(),
  annual_package: MonetaryAmountRange.optional(),
  about: z.string().min(1).nullable().optional(),
});

const BaseCorporationInProject = z.object({
  corporation_id: z.string().optional(),
});

const CorporationInProject = BaseCorporationInProject.extend({
  corporation_name: z.string().optional(),
  description: z.string().nullable().optional(),
  historic: z.string().nullable().optional(),
  business_function: ConclaEnumArraySchema.nullable(),
  foundation_year: z.string().nullable().optional(),
}).strict();

const BasePublishedBy = z.object({
  person_id: z.string().optional(),
});

const PublishedBy = BasePublishedBy.extend({
  first_name: z.string().optional(),
  last_name: z.string().optional(),
});

const BaseProject = z.object({
  project_name: z.string().optional(),
  position_name: z.string().optional(),
  project_type: z.nativeEnum(ProjectTypeEnum).optional(),
  position_details: PositionDetails.optional(),
  placement_type: z.nativeEnum(PlacementTypeEnum).optional().nullable(),
  confidential_placement: z.boolean().optional(),
  corporation: BaseCorporationInProject.optional().nullable(),
  published_by: BasePublishedBy.optional().nullable(),
  participants: z.array(BaseParticipant).optional(),
});

const Schedule = z.object({
  start_date: z.coerce.date().nullable().optional(),
  end_date: z.coerce.date().nullable().optional(),
  checkpoint_date: z.coerce.date().nullable().optional(),
  checkpoint_done_date: z.coerce.date().nullable().optional(),
});

const ProjectSchedules = z.object({
  construction: Schedule.optional(),
  mapping: Schedule.optional(),
  screenings: Schedule.optional(),
  interviews: Schedule.optional(),
  shortlist: Schedule.optional(),
  under_offer: Schedule.optional(),
  placement: Schedule.optional(),
});

const ProjectStagesWeights = z.object({
  academic_formation: z.number().default(10.0),
  experiences: z.number().default(20.0),
  achievements: z.number().default(20.0),
  attributes: z.number().default(20.0),
  cultural_match: z.number().default(15.0),
  motivation: z.number().default(15.0),
});

enum StatusEnum {
  DRAFT = "DRAFT",
  ACTIVE = "ACTIVE",
  CONCLUDED = "CONCLUDED",
  SUSPENDED = "SUSPENDED",
  CANCELLED = "CANCELLED",
}

const Candidate = z.object({
  person_id: z.string().optional(),
  person_data: z.any(),
  mini_cv: z.any(),
});

const PlacementDetails = z.object({
  candidate: Candidate.optional(),
  recruiter: UserSchema.optional(),
  inserted_at: z.coerce.date().nullable().optional(),
  start_date: z.coerce.date().nullable().optional(),
  salary: z.string().nullable().default(""),
  short_term_incentive: z.string().nullable().default(""),
  long_term_incentive: z.string().nullable().default(""),
  other_incentive: z.string().nullable().default(""),
  total_cash: z.string().nullable().default(""),
});

const ProjectStages = z.object({
  construction: z.object({
    status: z.nativeEnum(StatusEnum).default(StatusEnum.ACTIVE),
    counter: z.number().default(0),
  }),
  mapping: z.object({
    status: z.nativeEnum(StatusEnum).default(StatusEnum.DRAFT),
    counter: z.number().default(0),
  }),
  screenings: z.object({
    status: z.nativeEnum(StatusEnum).default(StatusEnum.DRAFT),
    counter: z.number().default(0),
  }),
  interviews: z.object({
    status: z.nativeEnum(StatusEnum).default(StatusEnum.DRAFT),
    counter: z.number().default(0),
  }),
  shortlist: z.object({
    status: z.nativeEnum(StatusEnum).default(StatusEnum.DRAFT),
    counter: z.number().default(0),
    first_shortlist_date: z.date().nullable().optional(),
    first_shortlist_recruiter: z.string().nullable().optional(),
  }),
  under_offer: z.object({
    status: z.nativeEnum(StatusEnum).default(StatusEnum.DRAFT),
    counter: z.number().default(0),
  }),
  placement: z.object({
    status: z.nativeEnum(StatusEnum).default(StatusEnum.DRAFT),
    counter: z.number().default(0),
  }),
});

const ExternalId = z.object({
  entity_id: z.string().nullable().default(""),
  record_id: z.number().nullable().default(0),
});

const ExternalIds = z.object({
  bullhorn_id: ExternalId,
  netsuite_id: ExternalId,
  hubspot_id: ExternalId,
});
const ProcessFunnel = z.object({
  presentation_counters: z.object({
    show_default: z.boolean().optional(),
    mapping: z.number().optional(),
    screenings: z.number().optional(),
    interviews: z.number().optional(),
    shortlist: z.number().optional(),
    finalists: z.number().optional(),
  }).optional(),
  presentation_descriptions: z.object({
    show_default: z.boolean().optional(),
    mapping: z.string().optional(),
    screenings: z.string().optional(),
    interviews: z.string().optional(),
    shortlist: z.string().optional(),
    finalists: z.string().optional(),
  }).optional(),
});
export type ProcessFunnelType = z.infer<typeof ProcessFunnel>;

const ProjectSchema = BaseProject.extend({
  schedules: ProjectSchedules.optional(),
  started_at: z.coerce.date().nullable().optional(),
  finished_at: z.coerce.date().nullable().optional(),
  ranking_weights: ProjectStagesWeights,
  stages: ProjectStages,
  placement_details: PlacementDetails,
  corporation: CorporationInProject.optional(),
  published_by: PublishedBy.optional(),
  participants: z.array(Participant).optional(),
  external_ids: ExternalIds.optional(),
  process_funnel: ProcessFunnel.optional(),
  shortlist_presentation_date: z.coerce.date().nullable().optional(),
  construction_presentation_date: z.coerce.date().nullable().optional(),
}).strict();

export type Project = z.infer<typeof ProjectSchema>;

const ProjectStripSchema = ProjectSchema.strip();

export const ProjectDtoSchema = z.intersection(EntitySchema, ProjectStripSchema);

export type ProjectDto = z.infer<typeof ProjectDtoSchema>;
