<script setup lang="ts">
import { Tooltip } from "@/components/tooltip";
import { changeImageSize } from "@/utils/images/resize-images";
import type { ImageSizesType } from "@/schemas/enums/images";

interface PropsType {
  url?: string | null
  name: string | null
  tooltip?: string
  active?: boolean
  size?: string
  borderColor?: string
  borderWidth?: string
  imageSize?: ImageSizesType
}

withDefaults(defineProps<PropsType>(), {
  url: null,
  name: null,
  borderColor: "#C23584FF",
  borderWidth: "3px",
  active: false,
  size: "3.25rem",
  tooltip: "",
  imageSize: "medium",
});

function personInitials(text: string | null | undefined) {
  if (!text)
    return "";
  const textFormat = text?.replaceAll(" da ", " ")
    ?.replaceAll(" de ", " ")
    ?.replaceAll(" do ", " ")
    ?.replaceAll(" das ", " ")
    ?.replaceAll(" dos ", " ");

  const splited = textFormat.split(" ");
  return `${(splited[0] || "").substring(0, 1)}${(splited[1] || "").substring(0, 1)}`;
}
</script>

<template>
  <Tooltip v-if="tooltip?.trim()" :tooltip-title="tooltip" tooltip-width="max-content" x-align="center">
    <div class="person-avatar" :class="{ active }" v-bind="$attrs">
      <span v-if="!url" v-text="personInitials(name)" />
      <img v-else :src="changeImageSize(url, imageSize)">
    </div>
  </Tooltip>

  <div v-else class="person-avatar" :class="{ active }" v-bind="$attrs">
    <span v-if="!url" v-text="personInitials(name)" />
    <img v-else :src="changeImageSize(url, imageSize)">
  </div>
</template>

<style lang="scss" scoped>
.person-avatar {
  display: flex;
  flex-direction: column;
  width: v-bind("size");
  height: v-bind("size");
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: $gray-light;
  border: 2px solid $white-full;

  img {
    width: 100%;
    border-radius: 50%;
  }
}

.person-avatar.active {
  border-width: v-bind(borderWidth);
  border-style: solid;
  border-color: v-bind(borderColor);
}
</style>
