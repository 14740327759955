export const persons = {
  activity: {
    tabs: {
      historic: "Histórico",
      projects: "Projetos",
      activities: "Atividades",
      relationship: "Relacionamentos",
    },
  },
  add: "adicionar",
  addToProject: "Adicionar a projeto",
  seeDetails: "Ver detalhes",
  bulkAction: "Ação em massa",
  relevants: "Relevantes",
  recents: "Recentes",
  sortBy: "Ordernar por",
  filters: "FILTROS",
  clearFilters: "Limpar Filtros",
  searchPerson: "buscar pessoa",
  searchProject: "buscar projeto",
  searchPersonError: "informe pelo menos 2 caracteres para fazer a busca",
  createPerson: "CRIAR PESSOA",
  errorListPerson: "Houve um erro ao carregar as pessoas. Tente novamente",
  notFoundSearch: "Não foi possível achar nenhuma pessoa com essa busca",
  mauiMapping: "Maui está mapeando os melhores perfis",
  errorListProjects: "Houve um erro ao carregar os projetos. Tente novamente",
  notFoundProjectsSearch: "Não foi possível achar nenhum projeto com essa busca",
  profileNotFound: "Não foi possível achar nenhum resultado com essa busca",
  attributeEmpty: "Ainda não existem informações sobre este atributo.",
  addAttribute: "ADICIONE",
  restrictedProfile: "Perfil Restrito",
  tagFilter: "Digite {tagFilter}",
  tagFilterClear: "Limpar filtro",
  tagFilters: {
    keywords: ["Palavras-chave", "a palavra-chave"],
    job: ["Cargo", "o cargo"],
    corporation: ["Empresa", "a empresa"],
    sector: ["Linhas de negócios", "a linha de negócio"],
    location: ["Local", "a localização"],
    governance: ["Governança", "a governança"],
    graduation: ["Formação", "a formação"],
    realizations: ["Realizações", "a realização"],
    conditions: ["Condições de execução", "a condição"],
    salary: ["Faixa salarial", "o salário"],
  },
  forms: {
    academic: {
      title: "Formação Acadêmica",
      addEducation: "Adicionar Formação",
      label: {
        institute: "Instituição",
        degreeLevel: "Formação",
        field: "Campo de estudo",
        startDate: "Data de início",
        endDate: "Data de fim",
      },
      degree: {
        BACHELOR: "Graduação",
        POST_BACHELOR: "Pós-Graduação",
        MBA: "MBA",
        MASTER: "Mestrado",
        DOCTORATE: "Doutorado",
        POST_DOCTORATE: "Pós-Doutorado",
        EXTRA_CURRICULAR_COURSE: "Curso Extracurricular",
      },
      error: {
        endDate: "Data de término inválida",
      },
    },
    language: {
      title: "Idioma",
      addLanguage: "Adicionar Idioma",
      placeholder: {
        language: "Escolher idioma",
        proficiency: "Proficiência",
      },
    },
    workExperience: {
      title: "Experiência profissional",
      professionalData: "Dados Profissionais",
      addWorkExperience: "Adicionar Experiência Profissional",
      removeWorkExperience: "Excluir Experiência Profissional",
    },
    avatar: {
      title: "Avatar",
      helpText: "A imagem deve ter tamanho mínimo de 200px X 200px e ser to tipo JPG ou PNG",
      search: "Procurar",
    },
    resume: {
      description: "Arraste ou faça upload do CV.",
      importData: "Importar dados",
      discardData: "Limpar campos",
      importDocument: "Importar documento",
    },
  },
  addToProjectBtn: {
    title: "Deseja adicionar o candidato ao(s) projeto(s) selecionado(s)?",
    message: "Você não poderá desfazer essa ação por aqui, somente na lista de mapeamento do projeto adicionado.",
    confirm: "Confirmar",
    cancel: "Cancelar",
    remove: "Remover selecionados",
  },
  createPersonForm: {
    personalData: "Dados Pessoais",
    name: "Nome",
    surname: "Sobrenome",
    birth_date: "Data de Nasc.",
    location: "Localização",
    phone: "Telefone",
    email: "E-mail",
    linkedin: "Linkedin",
    responsible: "Responsável",
    description: "Resumo",
    extraActivities: "Atividades Extras",
    addExtraActivities: "Adicionar atividade",
    selectPlaceholder: "Selecionar",
    selectExtraActivitiesError: "Selecione um tipo",
    extraActivitiesTypes: {
      projects: "Projetos",
      awards: "Premiações",
      publications: "Publicações",
      volunteer: "Voluntariado",
    },
    validations: {
      date: "Data inválida",
      linkedin: "URL inválida",
    },
    buttons: {
      submit: "salvar",
      clear: "limpar perfil",
    },
    submitMessages: {
      success: "Pessoa cadastrada com sucesso!",
    },
    addPhone: "adicionar telefone",
    addEmail: "adicionar email",
    modal: {
      submitFail: {
        title: "ERRO!",
        titleConflict: "Atenção!",
        message: "Houve um erro, tente novamente",
        button: "TENTAR NOVAMENTE",
        buttonConflict: "Voltar",
        linkedin: "Já existe um perfil cadastrado com esse Linkedin.",
        email: "Já existe um perfil cadastrado com esse e-mail.",
        generic: "Já existe um perfil cadastrado",
      },
      uploadFail: {
        title: "ERRO!",
        message: "Algo deu errado ao carregar os dados",
        button: "TENTAR NOVAMENTE",
      },
    },
  },
  personCard: {
    currentYear: "Atual",
  },
  mockData: {
    filters: [
      {
        name: "Palavras-Chave",
        options: ["Palavra 1", "Palavra 2", "Palavra 3"],
      },
      {
        name: "Cargo",
        options: ["CFO", "CTO", "CEO"],
      },
      {
        name: "Empresa",
        options: ["Flow", "Wolf", "MC Donalds"],
      },
      {
        name: "Linhas de negócios",
        options: ["R&S", "Alimentício", "Transportes"],
      },
    ],
  },
  checkConflict: {
    where: "na",
    profilesFound: "perfis encontrados com os dados",
    notFound: "Nenhum perfil pré-existente encontrado com os dados",
    linkAndEdit: "Ver e Editar",
    newAnalysis: "Fazer nova análise",
    createNewProfile: "Continuar e criar novo perfil",
    continue: "Continuar",
    email: "E-mail",
    fullName: "Nome completo",
    createPerson: "Criar novo perfil",
  },
  details: {
    years: "anos",
    lastSalary: "Último salário: ",
    workExperience: "Experiência profissional e realizações",
    phone: "Telefone: ",
    rightNow: "o momento",
    email: "E-mail: ",
    emailExist: "O E-mail já existe",
    age: "Idade: ",
    location: "Local: ",
    projects: "Projetos",
    project: "Projeto",
    addProject: "Adicionar Projeto",
    awards: "Premiações",
    award: "Premiação",
    addAward: "Adicionar Premiação",
    publications: "Publicações",
    publication: "Publicação",
    addPublication: "Adicionar Publicação",
    volunteerings: "Voluntariados",
    volunteering: "Voluntariado",
    addVolunteering: "Adicionar Voluntariado",
    extraActivities: "Atividades extras",
    academicFormation: "Formação Acadêmica",
    language: "Idioma",
    languages: "Idiomas",
    in: "em",
    of: "de",
    summary: "Resumo",
    submit: "Salvar Alterações",
    cancel: "Cancelar",
    editWithParser: "Editar com parser",
    tabs: {
      about: "Sobre",
      workExperience: "Experiência Profissional",
      academicFormation: "Formação Acadêmica",
      extraActivities: "Atividades Extras",
    },
    placeholders: {
      emptyAttribute: "Ainda não há informações sobre este atributo",
    },
  },
  current: "Atual",
  from: "de",
  to: "a",
  apply: "aplicar",
  publicationForm: {
    headline: "Título",
    publisher: "Publicação",
    datePublished: "Data de publi.",
    placeholders: {
      headline: "Ex.: Economia internacional comparada",
      publisher: "Ex.: Harvard Business Review",
    },
    validations: {
      linkedin: "URL inválida",
    },
  },
  projectForm: {
    title: "Título",
    startDate: "Data de início",
    endDate: "Data de fim",
    description: "Resumo",
    current: "Projeto atual",
    placeholders: {
      title: "Ex.: FGV, USP, UNICAMP",
      year: "Ano",
      month: "Mês",
    },
  },
  volunteerForm: {
    title: "Título",
    startDate: "Data de início",
    endDate: "Data de fim",
    description: "Resumo",
    current: "Projeto atual",
    placeholders: {
      title: "Ex.: Projeto X",
      year: "Ano",
      month: "Mês",
    },
  },
  awardForm: {
    title: "Título",
    relatedPosition: "Área relacio.",
    awardDate: "Data",
    placeholders: {
      title: "Ex.: Profissional do ano - 2020",
      relatedPosition: "Ex.: Controller, Pesquisa acadêmica do curso de Economia",
      month: "Mês",
      year: "Ano",
      description: "Descrição",
    },
  },
  certificationForm: {
    name: "Nome",
    institution: "Instituição",
    field: "Área",
    expeditionDate: "Emissão",
    expirationDate: "Validade",
    withoutExpirationDate: "Sem data de validade",
    addCertification: "Adicionar Certificação",
  },
  workExperienceForm: {
    roleName: "Título",
    company: "Empresa",
    salary: "Salário",
    incentiveCompensation: "Bônus",
    jobLocation: "Local",
    isCurrent: "Posição atual",
    startDate: "Data de início",
    endDate: "Data de fim",
    noCompaniesFound: "Nenhuma empresa encontrada",
    realization: "Realização",
    realizationDescription: "Descrição da realização",
    addRealization: "adicionar realização",
    conditions: "Condições de execução",
    placeholders: {
      roleName: "ex: Gerente Operacional, CFO, etc.",
      company: "ex: Itaú, Raízen, etc.",
      baseSalary: "ex: 45.000,00",
      description: "Descrição",
      incentiveCompensation: "ex: 4x salário, participação nos lucros",
      jobLocation: "ex: São Paulo, SP, Brasil",
      month: "Mês",
      year: "Ano",
      paymentFrequencies: {
        month: "Mês",
        year: "Ano",
        monthly: "Mensal",
        yearly: "Anual",
      },
    },
    corporationAliasHint: {
      title: "Nossa Inteligência encontrou",
      as: "como",
    },
  },
  project: "Projeto",
  publication: "Publicação",
  award: "Premiação",
  volunteer: "Voluntariado",
  certifications: "Certificação | Certificações | {count} Certificações",
  editablePersonForm: {
    name: "Nome",
    errorName: "Informe o nome completo",
    linkedin: "Linkedin",
    linkedinError: "URL Inválida",
    email: "E-mail",
    emailError: "E-mail inválido",
    location: "Localização",
    birth_date: "Data de Nasc.",
    tooltipText: "Altere a experiência profissional para alterar esse campo",
    phoneError: "Telefone inválido",
    removeAvatar: "Remover Avatar",
  },
};
