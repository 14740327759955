<script lang="ts" setup>
import { ref, toRef, watch } from "vue";
import { useI18n } from "vue-i18n";
import remove from "lodash-es/remove";
import some from "lodash-es/some";
import { SearchDropdown } from "@/components/inputs/modalInputs";
import { searchMarket } from "@/http_services/entities/common";
import { Tag } from "@/components/tag";

interface Market {
  key: string
  label: string
}

const props = defineProps({
  clear: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(["filter:select"]);

const { t } = useI18n();
const marketsResults = ref<Market[]>([]);
const clear = toRef(props, "clear");

function clearAll() {
  marketsResults.value = [];
  emit("filter:select", marketsResults.value);
}

watch(clear, () => {
  clearAll();
});

watch(marketsResults.value, (value) => {
  emit(
    "filter:select",
    value.map(i => i.key),
  );
});

function selectMarket(market: Market) {
  if (!some(marketsResults.value, market))
    marketsResults.value.push(market);
}

function removeMarket(market: Market) {
  remove(marketsResults.value, market);
}
</script>

<template>
  <div class="filter-wrapper">
    <div v-if="marketsResults.length > 0" class="tags">
      <Tag
        v-for="(item, index) in marketsResults"
        :key="index"
        :tag="item.label"
        @click:close="removeMarket(item)"
      />
    </div>
    <SearchDropdown
      id="market-filter"
      fetch-param-key="term"
      :fetch-method="searchMarket"
      :select-item-callback="selectMarket"
      :min-word-length="1"
      :update-input-model="() => ''"
      :input-placeholder="t('filters.market.search')"
      :search-not-found-label="t('filters.market.notFound')"
      :search-error-label="t('filters.market.error')"
    >
      <template #list-item="{ item }">
        {{ item.label }}
      </template>
    </SearchDropdown>
    <span class="clear-filter" role="button" @click="clearAll()">
      > {{ t(`persons.clearFilters`) }}
    </span>
  </div>
</template>

<style lang="scss" scoped>
.filter-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.select-filter {
  margin-top: 1rem;
}

.clear-filter {
  margin-left: 0.875rem;
  font-size: 0.875rem;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  gap: 0.5rem;
}
</style>
