<script setup lang="ts">
import find from "lodash-es/find";
import some from "lodash-es/some";

const props = withDefaults(defineProps<{
  iconSize: string | number | null
  cardSize: string | number | null
  floatLabel: boolean
  stages: any
  activeStage: string
}>(), {
  iconSize: 37,
  cardSize: null,
  floatLabel: false,
  stages: {},
});

const emit = defineEmits(["click:stage"]);

enum PROJECT_STATUS {
  ACTIVE = "ACTIVE",
  CONCLUDED = "CONCLUDED",
  CANCELLED = "CANCELLED",
  SUSPENDED = "SUSPENDED",
  DRAFT = "DRAFT",
}
enum PROJECT_STAGE {
  CONSTRUCTION = "construction",
  MAPPING = "mapping",
  SCREENINGS = "screenings",
  INTERVIEWS = "interviews",
  SHORTLIST = "shortlist",
  UNDER_OFFER = "under_offer",
  PLACEMENT = "placement",
}

const iconMap = {
  [PROJECT_STAGE.CONSTRUCTION]: "construction_icon",
  [PROJECT_STAGE.MAPPING]: "search_icon",
  [PROJECT_STAGE.SCREENINGS]: "audio_icon",
  [PROJECT_STAGE.INTERVIEWS]: "forum_icon",
  [PROJECT_STAGE.SHORTLIST]: "fact_check_icon",
  [PROJECT_STAGE.UNDER_OFFER]: "handshake_icon",
  [PROJECT_STAGE.PLACEMENT]: "star_icon",
};

const { tm } = useI18n();
const showFloatLabel = ref(null);

const blockedList = [`${PROJECT_STAGE.UNDER_OFFER}`];

const stagesList = computed(() =>
  Object.entries(props.stages).map(([key, value]) => ({
    name: tm(`projects.generic.stages.enum.${key}`),
    icon: iconMap[key],
    status: value.status,
    key,
    blocked: blockedList.includes(key),
    count: value.count,
  })));

const cancelled = computed(() => {
  const hasCancelledStage = some(stagesList.value, { status: PROJECT_STATUS.CANCELLED });
  return hasCancelledStage ? PROJECT_STATUS.CANCELLED : "";
});

const statusToClass = (status: string) => status?.toLowerCase();

function getStageStatus(status: string): string {
  let statusToReturn = status;
  if (cancelled.value && status !== PROJECT_STATUS.DRAFT)
    statusToReturn = cancelled.value;

  return statusToClass(statusToReturn);
}

function isStageConcluded(stage: string) {
  return props.stages[stage].status === PROJECT_STATUS.CONCLUDED;
}

function checkStageLiberate(stage: string) {
  return (
    props.stages[stage].status !== PROJECT_STATUS.DRAFT
  );
}

// toRef keeps reactivity
const currentStage = computed(() => props.activeStage);

function changeStage(stage: string) {
  const { key } = find(stagesList.value, ["blocked", true]);

  if (key && stage === key)
    return;

  // TODO: rollback
  if (checkStageLiberate(stage))
    emit("click:stage", stage);
}
</script>

<template>
  <div class="project-timeline">
    <div v-for="(stage, index) in stagesList" :key="stage.key" class="project-timeline__stage">
      <TimelineStage
        :icon="stage.icon"
        :label="stage.count"
        :icon-size="iconSize"
        :card-size="cardSize"
        :blocked="stage.blocked"
        :status="getStageStatus(stage.status)"
        :checked="!index && isStageConcluded(PROJECT_STAGE.CONSTRUCTION)"
        @mouseover="showFloatLabel = index"
        @mouseout="showFloatLabel = null"
        @click="changeStage(stage.key)"
      />
      <span
        class="project-timeline__label"
        :class="[
          {
            ['--float-label']: floatLabel,
            ['--float-label-active']:
              (floatLabel && showFloatLabel === index) || stage.key === currentStage,
          },
          stage.blocked ? 'project-timeline__blocked' : '',
        ]"
      >
        {{ stage.name }}
      </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.project-timeline {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__blocked {
    cursor: not-allowed;
  }

  &__stage {
    position: relative;
    margin-bottom: 1.5rem;
    cursor: pointer;
    font-size: 0.75rem;
    text-align: center;
    text-transform: uppercase;
  }

  &__label {
    &:not(#{&}.--float-label) {
      display: inline-block;
      margin-top: 0.5rem;
    }

    &.--blocked {
      cursor: not-allowed;
    }

    &.--float-label {
      position: absolute;
      right: 0;
      left: 0;
      padding: 0.5rem 0.45rem;
      border-radius: 6px;
      background-color: $white-full;
      box-shadow: 0 2px 4px #00000029;
      opacity: 0;
      transition: transform 100ms ease-in-out, opacity 100ms ease-in-out;

      &-active {
        margin: auto;
        opacity: 1;
        transform: translateY(30%);
      }
    }
  }
}

@media screen and (min-width: 640px) {
  .project-timeline {
    &__stage {
      margin-bottom: 0.5rem;
    }
  }
}
</style>
