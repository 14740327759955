<script lang="ts">
import moment from "moment";
import { useI18n } from "vue-i18n";
import type { Ref } from "vue";
import type { Person } from "@/components/person_card/interfaces";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const person = inject("selectedPerson") as Ref<Person> || inject("person");

    const {
      projects,
      volunteering,
      publications,
      awards,
    } = person.value;

    const formatDate = (date: Date, format = "MM/YYYY") => moment(date).format(format);

    return {
      projects,
      volunteering,
      publications,
      awards,
      formatDate,
      t,
    };
  },
});
</script>

<template>
  <div v-if="projects?.length" class="group-wrapper">
    <h4>
      {{ projects.length }}&nbsp;
      {{ projects.length > 1 ? t('persons.details.projects') : t('persons.details.project') }}
    </h4>

    <div v-for="(project, index) of projects" :key="`project-${index}`">
      <p>
        - {{ project.title }} |
        {{ formatDate(project.start_date) }} -
        {{ project.is_current ? t('persons.current') : formatDate(project.end_date) }}
      </p>
      <p v-if="project.description" class="description">
        {{ project.description }}
      </p>
    </div>
  </div>
  <div v-if="awards?.length" class="group-wrapper">
    <h4>
      {{ awards.length }}&nbsp;
      {{ awards.length > 1 ? t('persons.details.awards') : t('persons.details.award') }}
    </h4>

    <div v-for="(award, index) of awards" :key="`award-${index}`">
      <p>
        - {{ award.title }}
        <span v-if="award.related_position"> | {{ award.related_position }} </span>
        <span> | {{ formatDate(award.award_date, 'YYYY') }} </span>
      </p>
      <p v-if="award.description" class="description">
        {{ award.description }}
      </p>
    </div>
  </div>
  <div v-if="publications?.length" class="group-wrapper">
    <h4>
      {{ publications.length }}&nbsp;
      {{ publications.length > 1
        ? t('persons.details.publications')
        : t('persons.details.publication') }}
    </h4>

    <div v-for="(publication, index) of publications" :key="`publication-${index}`">
      <p>
        - {{ publication.headline }}
        <span v-if="publication.publisher">| {{ publication.publisher }} </span>
        <span> | {{ formatDate(publication.published_date, 'YYYY') }} </span>
      </p>
    </div>
  </div>
  <div v-if="volunteering?.length" class="group-wrapper">
    <h4>
      {{ volunteering.length }}&nbsp;
      {{ volunteering.length > 1
        ? t('persons.details.volunteerings')
        : t('persons.details.volunteering') }}
    </h4>

    <div v-for="(vt, index) of volunteering" :key="`vt-${index}`">
      <p>
        - {{ vt.title }} |
        {{ formatDate(vt.start_date) }} -
        {{ vt.is_current ? t('persons.current') : formatDate(vt.end_date) }}
      </p>
      <p v-if="vt.description" class="description">
        {{ vt.description }}
      </p>
    </div>
  </div>
</template>

<style scoped="true" lang="scss">
.group-wrapper:not(:last-child) {
  padding-bottom: 1.5rem;
  border-bottom: solid 1px #e9e5e5;
}

h4 {
  margin-top: 1.5rem;
  color: $yellow-dark;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}

p {
  margin-top: 1rem;
  color: $gray-dark;
  font-size: 14px;

  &.description {
    padding-left: 10px;
    margin-top: 10px;
  }
}
</style>
