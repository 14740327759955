<script lang="ts" setup>
import { ref, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import { FileDropInput } from "@/components/inputs/modalInputs";

defineProps<{
  modelValue: string
}>();

const emit = defineEmits(["update:modelValue"]);

const { t } = useI18n();

const file = ref();
const url = ref();

watchEffect(() => {
  emit("update:modelValue", { file, url });
});
</script>

<template>
  <div class="form-wrapper">
    <h1>{{ t("persons.forms.resume.importDocument") }}</h1>
    <div>
      <FileDropInput v-model:file="file" v-model:url="url" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.form-wrapper {
  h1 {
    margin-bottom: 2rem;
    color: $primary;
    font-size: 18px;
    text-transform: uppercase;
  }
}
</style>
