export enum CONTRACT_TYPE {
  CLT = "CLT",
  PJ = "PJ",
}

export enum CURRENCY {
  BRL = "BRL",
  USD = "USD",
  EUR = "EUR",
}

export enum SALARYCURRENCY {
  BRL = "R$",
  USD = "US$",
  EUR = "€",
}

export enum DEGREE_LEVEL {
  BACHELOR = "BACHELOR",
  POST_BACHELOR = "POST_BACHELOR",
  MBA = "MBA",
  MASTER = "MASTER",
  DOCTORATE = "DOCTORATE",
  POST_DOCTORATE = "POST_DOCTORATE",
  EXTRA_CURRICULAR_COURSE = "EXTRA_CURRICULAR_COURSE",
}

export interface Address {
  name: string
  street: string | null
  postal_code: string | null
  region: string | null
  neighborhood: string | null
  locality: string | null
}

export interface Phone {
  number: string
  calling_code: string
}

export interface Email {
  email_address: string
  is_main: boolean
}

export interface BusinessCategory {
  sector: string
  industry: string
  is_main: boolean
}

export interface Salary {
  amount: number
  currency: CURRENCY
  payment_frequency?: string
  contract_type?: CONTRACT_TYPE
}

export interface RelatedCorporation {
  name: string
  corporation_id: string
  business_categories?: BusinessCategory[]
  business_function?: string[]
  description?: string
  url?: string
  avatar_url?: string
  linkedin_url?: string
  address?: Address
}

export interface RelatedPerson {
  _id?: string
  first_name: string
  last_name: string
  person_id: string
  email_addresses?: { email_address: string; is_main: true }
  linkedin_url?: string
  avatar_url?: string
}

export interface Corporation {
  name: string
  corporation: RelatedCorporation
}

export enum PROFICIENCY {
  BASIC = "BASIC",
  BASIC_INT = "BASIC_INT",
  INTERMEDIATE = "INTERMEDIATE",
  ADVANCED = "ADVANCED",
  FLUENT = "FLUENT",
}

export enum BUSINESS_PROFICIENCY {
  ELEMENTARY = "ELEMENTARY",
  LIMITED_WORKING = "LIMITED_WORKING",
  PROFESSIONAL_WORKING = "PROFESSIONAL_WORKING",
  NATIVE = "NATIVE",
}

export interface Language {
  language?: string
  proficiency?: PROFICIENCY
  language_text?: string
}
