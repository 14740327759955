import type {
  Address,
  CURRENCY,
  DEGREE_LEVEL,
  Language,
  RelatedCorporation,
  RelatedPerson,
  Salary,
} from "../common";
import type { DegreeLevel } from "../Person/education";
import type { ATTRIBUTES_TITLES, CULTURAL_MATCH_TITLES } from "./construction-guide";

import type { ANALYSIS_TYPES } from "./organization-and-assembly";
import type { Optional } from "@/utils/typing";

export type { SearchParams } from "./search";

export enum PROJECT_TYPE {
  BOARD = "BOARD",
  CEO_AND_EXECUTIVE_OFFICERS = "CEO_AND_EXECUTIVE_OFFICERS",
  LEADERSHIP_SUCCESSION = "LEADERSHIP_SUCCESSION",
  MAPPING = "MAPPING",
  ASSESSMENT = "ASSESSMENT",
  SCOPE_DESIGN = "SCOPE_DESIGN",
}

export enum POSITION_CLASS {
  CLASS_A = "CLASS_A",
  CLASS_B = "CLASS_B",
  CLASS_C = "CLASS_C",
}

export enum CONTRACT_TYPE {
  CLT = "CLT",
  PJ = "PJ",
}

export enum PLACEMENT_TYPE {
  NEW_CLIENT = "NEW_CLIENT",
  ACTIVE_RECURRENCE = "ACTIVE_RECURRENCE",
  PASSIVE_RECURRENCE = "PASSIVE_RECURRENCE",
  REACTIVATION = "REACTIVATION",
}

export enum ACTIVITY {
  CONSTRUCTION_MEETING = "CONSTRUCTION_MEETING",
  VALIDATION_MEETING = "VALIDATION_MEETING",
  INTERVIEWS = "INTERVIEWS",
  RESEARCH_AND_PICKING = "RESEARCH_AND_PICKING",
}

export enum SCREENING_ACTIVITY {
  SCREENING_MEETING = "SCREENING_MEETING",
}

export enum INTERVIEWS_ACTIVITY {
  RECRUITER_MEETING = "RECRUITER_MEETING",
}

export enum PROJECT_STATUS {
  ACTIVE = "ACTIVE",
  CONCLUDED = "CONCLUDED",
  CANCELLED = "CANCELLED",
  SUSPENDED = "SUSPENDED",
  DRAFT = "DRAFT",
}

export enum PROJECT_STAGE {
  CONSTRUCTION = "construction",
  MAPPING = "mapping",
  SCREENINGS = "screenings",
  INTERVIEWS = "interviews",
  SHORTLIST = "shortlist",
  UNDER_OFFER = "under_offer",
  PLACEMENT = "placement",
}

export enum PROJECT_STAGE_KEYS {
  CONSTRUCTION = "CONSTRUCTION",
  MAPPING = "MAPPING",
  SCREENINGS = "SCREENINGS",
  INTERVIEWS = "INTERVIEWS",
  SHORTLIST = "SHORTLIST",
  UNDER_OFFER = "UNDER_OFFER",
  PLACEMENT = "PLACEMENT",
}

export enum QUESTION_TYPE {
  TEXT = "TEXT",
  INPUT = "INPUT",
  RADIO = "RADIO",
  SELECT = "SELECT",
  SALARY = "SALARY",
  BOOLEAN = "BOOLEAN",
  MULTI_ROW = "MULTI_ROW",
}

export enum DEGREE {
  BACHELOR = "BACHELOR",
  POST_BACHELOR = "POST_BACHELOR",
  INDIFFERENT = "INDIFFERENT",
  MBA = "MBA",
  MASTER = "MASTER",
  DOCTORATE = "DOCTORATE",
  POST_DOCTORATE = "POST_DOCTORATE",
  EXTRA_CURRICULAR_COURSE = "EXTRA_CURRICULAR_COURSE",
  EMPTY = "EMPTY",
}

export enum INSTITUTION {
  EMPTY = "EMPTY",
  FIRST_LINE = "FIRST_LINE",
  MANDATORILY_FIRST_LINE = "MANDATORILY_FIRST_LINE",
  PREFERENTIALLY_FIRST_LINE = "PREFERENTIALLY_FIRST_LINE",
  INDIFFERENT = "INDIFFERENT",
}

export enum EXTENSION_TYPE {
  EMPTY = "EMPTY",
  POST_BACHELOR = "POST_BACHELOR",
  MBA = "MBA",
  MASTER = "MASTER",
  DOCTORATE = "DOCTORATE",
  POST_DOCTORATE = "POST_DOCTORATE",
  EXTRA_CURRICULAR_COURSE = "EXTRA_CURRICULAR_COURSE",
}

export enum ATTRIBUTE {
  COGNITIVE_INTELLIGENCE = "COGNITIVE_INTELLIGENCE",
  EMOTIONAL_INTELLIGENCE = "EMOTIONAL_INTELLIGENCE",
  SPIRITUAL_INTELLIGENCE = "SPIRITUAL_INTELLIGENCE",
  ENERGY = "ENERGY",
  MATURITY = "MATURITY",
}

export enum CULTURAL {
  PRACTICAL = "PRACTICAL",
  LOGICAL = "LOGICAL",
  REFLEXIVE = "REFLEXIVE",
  AFFECTIVE = "AFFECTIVE",
}

export interface SalaryRange {
  currency: CURRENCY
  min_value: number
  max_value: number
  contract_type: CONTRACT_TYPE
}

export interface AnnualPackage {
  currency: CURRENCY
  min_value: number
  max_value: number
}

export interface PositionDetails {
  address: Address
  position_class: POSITION_CLASS
  level: string
  salary_range: SalaryRange
  bonus: string
  annual_package: AnnualPackage
  about: string
}

export interface Question {
  question_type: QUESTION_TYPE
  title: string
  answer: string | null
  choices?: string[]
  options?: string[]
  rows?: Question[]
  tooltip?: string
}

export interface Questions {
  default_questions: Question[]
  extra_questions: Question[]
}

export interface ConstructionGuide {
  company?: {
    strategy: Questions
    governance: Questions
  }
  position?: {
    profile: Questions
    execution_conditions: Questions
    strategy: Questions
    realizations: Questions
    functional_scope: Questions
  }
  requester?: Questions
}

export interface AnnotationsTypes {
  header: string
  governance: string
  strategy: string
  executions_conditions: string
  ideal_profile: string
}
export interface searchFields {
  business_function: string
  corporations: {
    corporation_id: string
    name: string
  }
}

export interface Activity {
  activity_date?: string
  recruiter_person?: RelatedPerson | string
  description?: ACTIVITY
  requester_person?: RelatedPerson | string
  is_process?: boolean
  panel?: {
    construction_guide: ConstructionGuide
    annotations: AnnotationsTypes
  }
}
export interface Screenings {
  screening_date?: string
  recruiter_person?: RelatedPerson | string
  candidate_person?: RelatedPerson | string
  // panel?: ScreeningPanel;
  is_process?: boolean
  annotations: AnnotationsTypesScreening
  screening_guide: ScreeningGuide
}

export interface SchoolingBackground {
  institution: RelatedCorporation | string
  degree: Optional<DEGREE_LEVEL>
  course: Optional<string>
}

export interface AnnotationsTypesScreening {
  activities_scope?: string
  background?: string
  current_moment?: string
  experience?: string
  header?: string
}
export interface Skill {
  tag: string
  eliminatory: boolean
}

export interface EducationalBackground {
  schooling: SchoolingBackground[]
  languages: Language[]
  skills: Skill[]
}
export interface Motivation {
  environment: string | undefined
  career: string | undefined
  personal: string | undefined
  earnings: {
    annual_income: {
      currency: CURRENCY
      amount: number
    }
    bonus: string | undefined
    open_to_relocation: boolean
    salary: {
      amount: number
      contract_type: CONTRACT_TYPE
      currency: CURRENCY
    }
  }
}

export interface ScreeningGuide {
  educational_background: EducationalBackground
  process: { information: Optional<string> }
  motivation: Motivation
}

export interface Schedule {
  start_date: string
  end_date: string
  checkpoint_date: string
  complete_date: string
  checkpoint_done_date: string
}

export interface Analysis {
  analysis_type: ANALYSIS_TYPES
  description: string
  insight: string
}

export interface Analyses {
  analyses: Analysis[]
  consultant_opinion: string
  tags: [{
    text: string
    selected: boolean
  }]
}

export interface BusinessFunction {
  tag: string
  label_nickname?: string
  corporations?: RelatedCorporation[] | string[]
}

export interface Schooling {
  degree: DEGREE
  extension_type: EXTENSION_TYPE
  field: string
  field_short?: string
  institution_type: INSTITUTION
  education_text?: string | null
}

export interface Realization {
  description: string
  description_short?: string
  desired_attribute: ATTRIBUTES_TITLES
  desired_cultural_match: CULTURAL_MATCH_TITLES
}

export interface MotivationPackage {
  annual_package: Salary
  bonus: string
  salary: Salary
  title: string
  tooltip?: string
}

export interface MotivationPackagePresentation {
  salary: string
  short_term_incentive: string
  long_term_incentive: string
  other_incentive: string
  total_cash: string
}

export interface OrganizationAndAssembly {
  governance: Analyses
  strategy: Analyses
  execution_conditions: Analyses
  business_functions: BusinessFunction[]
  ideal_profile: {
    attributes: Question
    cultural_match: Question
    career: string[]
    consultant_opinion: string
    family: string[]
    languages: Language[]
    motivation_package: MotivationPackage
    motivation_package_presentation: MotivationPackagePresentation | null
    personal_life: string[]
    realizations: Realization[]
    schooling: Schooling[]
    work_environment: string[]
    work_experiences: string[]
  }
}

export interface Schedules {
  [PROJECT_STAGE.CONSTRUCTION]: Schedule
  [PROJECT_STAGE.MAPPING]: Schedule
  [PROJECT_STAGE.SCREENINGS]: Schedule
  [PROJECT_STAGE.INTERVIEWS]: Schedule
  [PROJECT_STAGE.SHORTLIST]: Schedule
  [PROJECT_STAGE.UNDER_OFFER]: Schedule
  [PROJECT_STAGE.PLACEMENT]: Schedule
}

export interface Stage {
  status?: PROJECT_STATUS
  count?: number
}

export interface MappingStage {
  favorite_candidates: string[]
  saved_candidates: string[]
}

export interface ScreeningStage {
  candidates_ids: string[]
}

export interface Stages {
  construction: Stage
  mapping: Stage & MappingStage
  screenings: Stage & ScreeningStage
  interviews: Stage
  shortlist: Stage
  under_offer: Stage
  placement: Stage
}

export interface ScreeningSchooling {
  institution: string | RelatedCorporation
  degree: DegreeLevel
  course: string
}

export interface ExternalIdsEntityRecord {
  entity_id: string
  record_id: number
}

export interface Project {
  _version: number
  id: string
  serial: number
  is_active: boolean
  description: string
  corporation: RelatedCorporation | string
  project_name: string
  position_name: string
  project_type: PROJECT_TYPE
  position_details: PositionDetails
  published_by: RelatedPerson | string
  placement_type: PLACEMENT_TYPE
  participants: RelatedPerson[] | string[]
  activities: Activity[]
  screenings: Screenings[]
  screening_guide: ScreeningGuide
  confidential_placement: boolean
  user_creator_id: string
  inserted_at: Date
  updated_at: Date
  started_at?: Date
  finished_at?: Date
  construction_guide: ConstructionGuide
  organization_and_assembly: OrganizationAndAssembly
  schedules?: Schedules
  stages: Stages
  status?: PROJECT_STATUS
  external_ids?: {
    bullhorn_id: ExternalIdsEntityRecord
    hubspot_id: ExternalIdsEntityRecord
    netsuite_id: ExternalIdsEntityRecord
  }
}

export interface ProjectPayload extends Project {
  corporation: string
  published_by: string
  participants: string[]
  organization_and_assembly: OrganizationAndAssembly
  activities: Activity[]
  screenings: Screenings[]
  screening_guide: ScreeningGuide
}

export class BuildProjectPayload {
  payload: Partial<ProjectPayload> = {};
  constructor(public project: Project) { }

  buildCorporation() {
    const { corporation_id } = this.project.corporation as RelatedCorporation;
    this.payload.corporation = corporation_id;
  }

  buildPublishedBy() {
    const { person_id } = this.project.published_by as RelatedPerson;
    this.payload.published_by = person_id;
  }

  buildParticipants() {
    const participants_ids = (this.project.participants as RelatedPerson[]).map((person) => {
      return person.person_id;
    });
    this.payload.participants = participants_ids;
  }

  buildOrganizationAndAssembly() {
    const business_functions = this.project.organization_and_assembly.business_functions.map(
      (bf) => {
        return {
          ...bf,
          corporations: (bf.corporations as RelatedCorporation[]).map(c => c.corporation_id),
        };
      },
    );
    this.payload.organization_and_assembly = {
      ...this.project.organization_and_assembly,
      business_functions,
    };
  }

  buildActivities() {
    const activities = this.project.activities.map((activity) => {
      const { requester_person: rqp, recruiter_person: rcp } = activity;
      return {
        ...activity,
        requester_person: typeof rqp === "string" ? rqp : rqp?.person_id,
        recruiter_person: typeof rcp === "string" ? rcp : rcp?.person_id,
      };
    });
    this.payload.activities = activities;
  }

  buildScreenings() {
    const screenings = this.project.screenings.map((screening) => {
      const { candidate_person: cdp, recruiter_person: rcp } = screening;
      return {
        ...screening,
        candidate_person: typeof cdp === "string" ? cdp : cdp?.person_id,
        recruiter_person: typeof rcp === "string" ? rcp : rcp?.person_id,
      };
    });
    this.payload.screenings = screenings;
  }

  buildScreeningGuide() {
    const screening_guide = {
      ...this.project.screening_guide,
      educational_background: {
        ...this.project.screening_guide.educational_background,
        schooling: this.project.screening_guide.educational_background.schooling.map(
          (schooling) => {
            const { institution } = schooling;
            return {
              ...schooling,
              institution:
                typeof institution === "string" ? institution : institution?.corporation_id,
            };
          },
        ),
      },
    };
    this.payload.screening_guide = screening_guide;
  }

  getProjectPayload() {
    this.buildCorporation();
    this.buildPublishedBy();
    this.buildParticipants();
    this.buildOrganizationAndAssembly();
    this.buildActivities();
    this.buildScreenings();
    this.buildScreeningGuide();
    return { ...this.project, ...this.payload };
  }
}
