<script lang="ts">
import type { Ref } from "vue";
import { defineComponent, onMounted, ref, toRef, toRefs, watch } from "vue";
import debounce from "lodash-es/debounce";
import { FormInput } from "@/components/inputs/modalInputs";
import { Dropdown } from "@/components/dropdown";
import { Loading } from "@/components/loading";
import { TooltipBox } from "@/components/tooltip";

export default defineComponent({
  name: "SearchDropdown",
  components: {
    FormInput,
    Dropdown,
    Loading,
    TooltipBox,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    fetchMethod: {
      type: Function,
      required: true,
    },
    selectItemCallback: {
      type: Function,
      required: true,
    },
    minWordLength: {
      type: Number,
      default: 1,
    },
    fetchParamKey: {
      type: String,
      default: "name",
    },
    responseDataKey: {
      type: String,
      default: "data",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    autoFill: {
      type: Boolean,
      default: false,
    },
    tooltipShow: {
      type: Boolean,
      default: false,
    },
    tooltipMesage: String,
    tooltipColor: String,
    tooltipBackground: String,
    updateInputModel: Function,
    initialValue: String,
    recruiterId: {
      type: String,
      default: null,
    },
    inputPlaceholder: String,
    searchNotFoundLabel: String,
    searchErrorLabel: String,
    error: Boolean,
    errorMessage: String,
    svgIcon: String,
    showIcon: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["clear", "selectItem", "click:onButtonClick"],
  setup(props, { emit }) {
    const showTooltip = ref(false);
    const searchInput = ref((props.initialValue as string) || "");
    const ready = ref(false);
    const fetchError = ref(false);
    const loading = ref(false);
    const noFetch = ref(false);
    const dropdownActive = ref(false);
    const data = ref([]);
    const { fetchParamKey, responseDataKey, initialValue, recruiterId } = toRefs(props);
    const fetchMethod = toRef(props, "fetchMethod") as Ref<(params) => Promise<any>>;
    const selectItemCallback = toRef(props, "selectItemCallback") as Ref<(item) => void>;
    const updateInputModel = toRef(props, "updateInputModel") as Ref<(item) => string>;

    const onInputFocus = () => {
      if (ready.value)
        dropdownActive.value = true;
    };

    const onInputBlur = (e) => {
      if (e?.relatedTarget?.id !== `${props.id}-list`)
        dropdownActive.value = false;
    };

    const fetchData = async (searchTerm: string): Promise<void> => {
      try {
        dropdownActive.value = true;
        loading.value = true;
        ready.value = false;
        fetchError.value = false;

        const response = await fetchMethod.value({
          limit: 10,
          skip: 0,
          [fetchParamKey.value as string]: searchTerm,
        });
        data.value = response[responseDataKey.value as string];
        if (fetchMethod.value.name === "searchCandidate")
          data.value = data.value.filter(e => !e.is_added_in_placement);

        ready.value = true;
      }
      catch (err) {
        fetchError.value = true;
      }
      finally {
        loading.value = false;
      }
    };

    const debouncedFetchData = debounce(fetchData, 1000);

    watch(searchInput, (value, oldValue) => {
      if (value && value.length >= props.minWordLength) {
        if (noFetch.value)
          noFetch.value = false;
        else
          debouncedFetchData(value);
      }

      if (oldValue && !value)
        emit("clear");
    });

    watch(initialValue, (value = "") => {
      noFetch.value = true;
      searchInput.value = value;
    });

    const selectItem = (item) => {
      noFetch.value = true;
      dropdownActive.value = false;

      if (updateInputModel.value)
        searchInput.value = updateInputModel.value(item);
      else searchInput.value = "";

      emit("selectItem");
      selectItemCallback.value(item);
    };
    onMounted(() => {
      if (recruiterId.value !== null)
        selectItemCallback.value(recruiterId.value);
    });

    const setSearchValue = (value: string): void => {
      noFetch.value = true;
      searchInput.value = value;
    };

    const onButtonClick = () => {
      emit("click:onButtonClick");
    };

    return {
      searchInput,
      ready,
      fetchError,
      loading,
      dropdownActive,
      data,
      onInputFocus,
      onInputBlur,
      selectItem,
      setSearchValue,
      showTooltip,
      onButtonClick,
    };
  },
});
</script>

<template>
  <div class="search-dropdown" @mouseover="showTooltip = true" @mouseout="showTooltip = false">
    <TooltipBox
      :show="tooltipShow && showTooltip"
      :color="tooltipColor"
      :background="tooltipBackground"
      :mesage="tooltipMesage"
    />
    <FormInput
      :id="`${id}-search`"
      v-model="searchInput"
      :svg-icon="svgIcon"
      :show-icon="showIcon"
      :auto-fill="autoFill"
      :placeholder="inputPlaceholder"
      autocomplete="off"
      :on-focus="onInputFocus"
      :on-blur="onInputBlur"
      :error="error"
      :error-message="errorMessage"
      :disabled="disabled"
      @click:on-button-click="onButtonClick"
    />

    <Dropdown :id="`${id}-list`" :active="dropdownActive" @blur="dropdownActive = false">
      <div v-if="loading" class="search-dropdown-loading">
        <Loading />
      </div>

      <ul v-else-if="ready" class="search-dropdown-list">
        <li
          v-for="item in data"
          :key="item"
          class="search-dropdown-list__item"
          role="button"
          @click="selectItem(item)"
        >
          <slot name="list-item" v-bind="{ item }" />
        </li>

        <li
          v-if="!data?.length"
          class="search-dropdown-list__item search-dropdown-list__item--not-found"
        >
          {{ searchNotFoundLabel }}
        </li>
      </ul>

      <span v-if="fetchError" class="search-dropdown-list__item search-dropdown-list__item--error">
        {{ searchErrorLabel }}
      </span>
    </Dropdown>
  </div>
</template>

<style lang="scss" scoped>
:deep(.imua-dropdown) {
  padding: 0;

  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border: solid 2px transparent;
    border-radius: 2rem;
    background: #888;
    background-clip: content-box;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    border: solid 2px transparent;
    background: #555;
    background-clip: content-box;
  }
}

.search-dropdown {
  position: relative;

  &-loading {
    text-align: center;
  }

  &-list {
    padding: 0;

    &__item {
      display: block;
      padding-block: 1rem;
      padding-inline: 0.875rem;

      &:hover {
        background: #f4f4f4;
      }

      &--not-found,
      &--error {
        &:hover {
          background-color: $white-full;
        }
      }

      &--error {
        color: $primary;
      }
    }
  }
}
</style>
