import { candidatesService } from "@/http_services/client";
import { formatUrlParams } from "@/utils/parsers/url";
import type { WorkExperience } from "@/schemas/person";

export interface getCandidateType {
  project_id: string
  person_id?: string
}

export interface SearchCandidatesType {
  name?: string
  project_id: string
  person_id?: string
  project_stage?: string
  sort?: string[]
  skip: number
  limit: number
  evaluated_in_interviews?: boolean
}

export interface ApprovalCandidateType {
  id_: string
}

export interface PatchApprovalCandidateType {
  approval: {
    approved_for_interview?: string | null
    reason?: string | null
    priority_classification?: string
  }
}

export interface CandidateToMappingType {
  candidate: getCandidateType
}

// PERSON
export interface ApprovalAndPriority {
  approved_for_interview: string
  reason: string
  priority_classification: string
}

export interface CalculatedScores {
  academic_formation_mean: null
  experiences_mean: null
  achievements_mean: null
  attributes_mean: null
  cultural_match_mean: null
  motivation_mean: null
  final_mean: null
}

export interface MiniCv {
  candidate_feedbacks: any[]
  nickname: string
  client_feedbacks: any[]
  recruiter_opinion: string
  current_company_nickname: string
  current_role_nickname: string
  hide_experiences_ids: any[]
}

export interface PersonData {
  first_name: string
  last_name: string
  email_addresses: any[]
  avatar_url: string
  address: null
  phones: any[]
  work_experience: any[]
  serial: number
  updated_at: string
  linkedin_url: string
}

export interface StagesTracking {
  authorized_user: string
  stage_from: string
  stage_to: string
  update_date: string
}

export interface CandidateType {
  project_id: string
  person_id: string
  person_data: PersonData
  project_stage: string
  mini_cv: MiniCv
  calculated_scores: CalculatedScores
  stages_tracking: StagesTracking[]
  approval_and_priority: ApprovalAndPriority
  _version: number
  is_active: boolean
  serial: number
  inserted_at: string
  updated_at: string
  work_experience: WorkExperience[]
  _id: string
}

// TODO: TYPE ALL API CONTRACTS
export function getCandidate(query: getCandidateType) {
  const params = formatUrlParams(query);
  return candidatesService.get("candidates/search", { params })
    .then(res => res.data?.data);
}

export function searchCandidates(query: SearchCandidatesType): Promise<any> {
  const params = formatUrlParams(query);
  return candidatesService.get("candidates/search", { params })
    .then(res => res.data?.data);
}

export function searchFullCandidates(query: SearchCandidatesType): Promise<any> {
  const params = formatUrlParams(query);
  return candidatesService.get("candidates/search", { params })
    .then(res => res.data);
}

export function getListMappingCandidates(project_id: string) {
  return candidatesService.get(`candidates/project/${project_id}/mapping-person-ids`)
    .then(res => res.data?.data);
}

export function getValidationPanelCandidates(project_id: string) {
  return candidatesService.get(`candidates/project/${project_id}/validation-panel`)
    .then(res => res.data?.data);
}

export function patchCandidate(candidate_id: string, candidate: CandidateType) {
  return candidatesService.patch(`candidates/${candidate_id}`, { candidate })
    .then(res => res.data?.data);
}

export function candidateToMapping(body: CandidateToMappingType) {
  return candidatesService.post("candidates/person-to-mapping", body)
    .then(res => res.data?.data);
}

export function declineToMapping(body: CandidateToMappingType) {
  return candidatesService.post("candidates/remove", body)
    .then(res => res.data?.data);
}

export function saveListMapping(project_id: string) {
  return candidatesService.post(`candidates/save-mapping-list/${project_id}`, {})
    .then(res => res.data?.data);
}

export function candidateApproveToScreenings(body: ApprovalCandidateType) {
  return candidatesService.post(`candidates/${body.id_}/approve-to-screenings`, body)
    .then(res => res.data?.data);
}

export function candidateDeclineFromScreenings(body: ApprovalCandidateType) {
  return candidatesService.post(`candidates/${body.id_}/decline-from-screenings`, body)
    .then(res => res.data?.data);
}

export function candidateApproveToInterviews(body: ApprovalCandidateType) {
  return candidatesService.post(`candidates/${body.id_}/approve-to-interviews`, body)
    .then(res => res.data?.data);
}

export function candidateDeclineFromInterviews(body: ApprovalCandidateType) {
  return candidatesService.post(`candidates/${body.id_}/decline-from-interviews`, body)
    .then(res => res.data?.data);
}

// this should use project id as path
export function candidateApproveToShortlist(body: ApprovalCandidateType) {
  return candidatesService.post(`candidates/${body.id_}/approve-to-shortlist`, body)
    .then(res => res.data?.data);
}

export function candidateDeclineFromShortlist(body: ApprovalCandidateType) {
  return candidatesService.post(`candidates/${body.id_}/decline-from-shortlist`, body)
    .then(res => res.data?.data);
}

export function getCandidateIdFromPersonId(project_id: string, person_id: string) {
  return candidatesService.get(`candidates/project/${project_id}/person/${person_id}`)
    .then(res => res.data?.data);
}

export function patchApprovalCandidate(person_id: string, body: PatchApprovalCandidateType) {
  return candidatesService.patch(`candidates/${person_id}/patch-approval`, body)
    .then(res => res.data?.data[0]);
}

export async function getCandidatesFromShortlist(id: string) {
  return candidatesService.get(`candidates/project/${id}/shortlist`, { project_id: id })
    .then(res => res.data?.data);
}
