<script setup lang="ts">
import { defineEmits, ref, withDefaults } from "vue";
import { OpinionEdit, OpinionValue } from "./components";
import type { IEllipsis } from "@/directives";

const props = withDefaults(
  defineProps<{
    opinion: string
    linebreak?: boolean
    ellipsis?: IEllipsis
    asExport?: boolean
    editMode?: boolean
    showIcon?: boolean
    fontSize?: string
  }>(),
  {
    opinion: "",
    linebreak: false,
    asExport: false,
    editMode: false,
    showIcon: true,
    fontSize: "1rem",
  },
);

const emit = defineEmits(["onSave"]);

const editMode = ref(props.editMode ?? false);
</script>

<template>
  <div class="opinion">
    <div v-if="showIcon" class="opinion__avatar" />
    <OpinionEdit
      v-if="editMode"
      :opinion="props.opinion"
      @on-cancel="editMode = false"
      @on-save="emit('onSave', $event), editMode = false"
    />
    <OpinionValue
      v-if="!editMode"
      :ellipsis="props.ellipsis"
      :opinion="props.opinion"
      :linebreak="props.linebreak"
      :as-export="props.asExport"
      :font-size="props.fontSize"
      @on-edit="editMode = true"
    />
  </div>
</template>

<style lang="scss" scoped>
.opinion {
  position: relative;
  display: flex;
  width: 100%;

  &__avatar {
    position: absolute;
    display: flex;
    overflow: hidden;
    width: 3.125rem;
    height: 3.125rem;
    align-items: center;
    justify-content: center;
    background: url("@/assets/images/flow_ef_dark.png") no-repeat center center;
    background-size: 3.5rem 3.5rem;
    border-radius: 50%;
  }

  &__content {
    width: 100%;
    min-height: 100px;
    padding: 1.3rem;
    margin: 0.5rem;
    background: $white-background;
    border-radius: 8px;
    box-shadow: 0 2px 4px #00000029;
    line-height: 1.5rem;
  }
}
</style>
