import { z } from "zod";
import { MonetaryAmount, MonetaryAmountRange } from "@/types/currency";
import { ContractTypeEnum } from "@/types/misc";

export enum PaymentFrequencyEnum {
  MONTHLY = "MONTHLY",
  ANNUALLY = "ANNUALLY",
}

export enum ContractTypeMotivationEnum {
  CLT = "CLT",
  PJ = "PJ",
  PARTNER = "PARTNER",
  STATUTORY = "STATUTORY",
}

export const SalarySchema = MonetaryAmount.extend({
  contract_type: z.nativeEnum(ContractTypeEnum).optional(),
  payment_frequency: z.nativeEnum(PaymentFrequencyEnum).optional(),
});

export const SalaryMotivationSchema = MonetaryAmount.extend({
  contract_type: z.nativeEnum(ContractTypeMotivationEnum).default(
    ContractTypeMotivationEnum.CLT,
  ),
});

export const SalaryRangeSchema = MonetaryAmountRange.extend({
  contract_type: z.nativeEnum(ContractTypeEnum).optional(),
});

export type Salary = z.infer<typeof SalarySchema>;
export type SalaryMotivation = z.infer<typeof SalaryMotivationSchema>;
export type SalaryRange = z.infer<typeof SalaryRangeSchema>;
