export const filters = {
  moneyRange: {
    decimalDelimiter: ".",
    thousandsDelimiter: ",",
  },
  market: {
    search: "type to filter a market",
    notFound: "No results found",
    error: "Error filtering market. Try again",
  },
};
