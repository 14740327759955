import { z } from "zod";
import {
  AnalysisTypesEnumSchema,
  ConstructionActivityDescriptionEnumSchema,
  EducationDegreeEnumSchema,
  ExtensionTypeEnumSchema,
  InstitutionTypeEnumSchema,
} from "../enums/project";
import { AttributesMapEnumsSchema, CulturalMatchMapEnumsSchema } from "../enums/construction_guide";
import {
  CorporationInAggregationSchema,
  MonetaryAmountSchema,
  RelatedPersonSchema,
  SalarySchema,
} from "../common/schemas";
import { LanguageEnumSchema, LanguageProficiencyEnumSchema } from "../enums/languages";

export const DinamicFormSchema = z
  .object({
    default_questions: z.array(z.any()),
    extra_questions: z.array(z.any()),
  })
  .strict();

export const CompanyQuestionSchema = z
  .object({
    strategy: DinamicFormSchema,
    governance: DinamicFormSchema,
  })
  .strict();

export const PositionQuestionsSchema = z
  .object({
    profile: DinamicFormSchema,
    execution_conditions: DinamicFormSchema,
    strategy: DinamicFormSchema,
    realizations: DinamicFormSchema,
    functional_scope: DinamicFormSchema,
  })
  .strict();

export const ConstructionGuideSchema = z
  .object({
    company: CompanyQuestionSchema,
    position: PositionQuestionsSchema,
    requester: DinamicFormSchema,
  })
  .strict();

export const ConstructionAnnotationsSchema = z
  .object({
    header: z.string(),
    ideal_profile: z.string(),
    governance: z.string(),
    strategy: z.string(),
    executions_conditions: z.string(),
  })
  .strict();

export const ConstructionActivitySchema = z
  .object({
    id: z.string().nullish(),
    activity_date: z.string(),
    guide: ConstructionGuideSchema.nullish(),
    annotations: ConstructionAnnotationsSchema,
    is_process: z.boolean().optional(),
    description: ConstructionActivityDescriptionEnumSchema,
    recruiter: RelatedPersonSchema,
    requester: RelatedPersonSchema,
  })
  .strict();

export const AnalysisSchema = z
  .object({
    analysis_type: AnalysisTypesEnumSchema,
    description: z.string(),
    description_short: z.string(),
    insight: z.string().nullish(),
  })
  .strict();

export const CategorySchema = z
  .object({
    analyses: z.array(AnalysisSchema).optional(),
    consultant_opinion: z.string().optional(),
    tags: z.array(
      z.object({
        text: z.string(),
        selected: z.boolean(),
      }),
    ),
  })
  .strict();

export const EducationSchema = z
  .object({
    id: z.string().optional(),
    degree: EducationDegreeEnumSchema.optional(),
    institution_type: InstitutionTypeEnumSchema.optional(),
    extension_type: ExtensionTypeEnumSchema.optional().nullable(),
    field: z.string().nullish(),
    field_short: z.string().nullish(),
    education_text: z.string().nullish(),
  })
  .strict();

export const LanguagesSchema = z
  .object({
    id: z.string().optional(),
    language: z.string(LanguageEnumSchema).nullish(),
    proficiency: z.string(LanguageProficiencyEnumSchema).nullish(),
    language_text: z.string().optional(),
  })
  .strict();

export const RealizationSchema = z
  .object({
    id: z.string().optional().nullish(),
    description: z.string(),
    description_short: z.string().optional(),
    desired_attribute: AttributesMapEnumsSchema.optional(),
    desired_cultural_match: CulturalMatchMapEnumsSchema.optional(),
  })
  .strict();

export const MotivationPackageSchema = z.object({
  title: z.string(),
  tooltip: z.string(),
  question_type: z.string(),
  salary: SalarySchema,
  bonus: z.string(),
  annual_package: MonetaryAmountSchema,
});

export const MultiRowQuestionSchema = z
  .object({
    title: z.string(),
    tooltip: z.string(),
    question_type: z.string(),
    rows: z.array(
      z.object({
        title: z.string(),
        tooltip: z.string(),
        answer: z.string().nullable(),
        question_type: z.string(),
        choices: z.array(z.string()),
      }),
    ),
  })
  .strict();

export const MotivationPackagePresentation = z
  .object({
    salary: z.string().nullish(),
    short_term_incentive: z.string().nullish(),
    long_term_incentive: z.string().nullish(),
    other_incentive: z.string().nullish(),
    total_cash: z.string().nullish(),
  })
  .strict();

export const WorkExperiencesSchema = z
  .object({
    id: z.string().optional().nullish(),
    experience: z.string().nullish(),
  })
  .strict();

export const TagsSchema = z
  .object({
    text: z.string().nullish(),
    selected: z.boolean(),
  })
  .strict();

export const IdealProfileSchema = z
  .object({
    schooling: z.array(EducationSchema).nullish(),
    languages: z.array(LanguagesSchema).nullish(),
    work_experiences: z.array(WorkExperiencesSchema).nullish(),
    work_experiences_short: z.array(z.string()),
    work_experiences_tags: z.array(TagsSchema).nullish(),
    attributes: MultiRowQuestionSchema,
    cultural_match: MultiRowQuestionSchema,
    motivation_package: MotivationPackageSchema,
    motivation_package_presentation: MotivationPackagePresentation.optional().nullable(),
    career: z.array(z.string()),
    career_short: z.array(z.string()).nullish(),
    work_environment: z.array(z.string()),
    work_environment_short: z.array(z.string()).nullish(),
    family: z.array(z.string()),
    family_short: z.array(z.string()).nullish(),
    personal_life: z.array(z.string()),
    personal_life_short: z.array(z.string()).nullish(),
    realizations: z.array(RealizationSchema),
    realizations_tags: z.array(TagsSchema).nullish(),
    consultant_opinion: z.string(),
  })
  .strict();

export const BusinessFunctionSchema = z
  .object({
    tag: z.string(),
    label_nickname: z.string().nullable(),
    corporations_ids: z.array(z.string()),
    corporations_data: z.array(CorporationInAggregationSchema).nullable(),
  })
  .strict();

export const OrganizationAndAssemblySchema = z
  .object({
    governance: CategorySchema.optional(),
    strategy: CategorySchema.optional(),
    execution_conditions: CategorySchema.optional(),
    ideal_profile: IdealProfileSchema.optional(),
    business_functions: z.array(BusinessFunctionSchema).optional(),
  })
  .nullish();
