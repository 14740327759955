import { useAxios } from "@vueuse/integrations/useAxios";
import { useQuery } from "@tanstack/vue-query";
import type { Person } from "../schemas/person";
import { entitiesAxios } from "@/http_services/client";

const { identify } = useTracking();

export interface UsePersonQueryType {
  data: Person
}

export interface UsePersonsAllQueryType {
  data: Array<Person>
}

export async function usePersonQuery(id: string) {
  const {
    data,
    isLoading,
    error,
    execute,
  } = useAxios<
    UsePersonQueryType
  >(`/persons/${id}`,
    entitiesAxios,
  );
  return {
    error,
    result: data,
    loading: isLoading,
    execute,
  };
}

export async function useUpdatePersonQuery(id: string) {
  const {
    data,
    isLoading,
    error,
    execute,
  } = useAxios<
    UsePersonQueryType,
    { person: Person }
  >(`/persons/${id}`,
    { method: "PUT" },
    entitiesAxios,
    { immediate: false },
  );
  return {
    error,
    result: data,
    loading: isLoading,
    execute,
  };
}

export async function usePersonsAllQuery() {
  const {
    data,
    isLoading,
    error,
    execute,
  } = useAxios<
    UsePersonsAllQueryType
  >("/persons",
    entitiesAxios,
  );
  return {
    error,
    result: data,
    loading: isLoading,
    execute,
  };
}

export async function usePersonMe() {
  const {
    data,
    error,
    execute,
  } = useAxios<
    UsePersonQueryType
  >("/persons/me",
    { method: "GET" },
    entitiesAxios,
  );
  return {
    error,
    data,
    execute,
  };
}

function getMe() {
  return entitiesAxios.get("/persons/me")
    .then(res => res.data?.data)
    .then((data: any) => {
      identify(data?.id, data);
      return data;
    });
}

export function useMeQuery() {
  return useQuery({
    queryKey: ["me"],
    queryFn: () => getMe(),
  });
}

export function getByUserId(user_id: string) {
  return entitiesAxios.get(`/search/persons?user_id=${user_id}`)
    .then(res => res.data?.data[0]);
}
