<script setup lang="ts">
import moment from "moment";
import { chunk, findLastIndex } from "lodash-es";
import ShortlistBody from "./components/ShortlistBody.vue";
import { CustomCheckBox } from "@/components/checkbox";

import type { CandidateType } from "@/common/services/candidates";
import { SvgIcon } from "@/components/icon";
import { capitalizeFirstLetter } from "@/utils/text";
import { getDateDif } from "@/utils/dates";
import { Tooltip } from "@/components/tooltip";

interface PeriodType {
  init?: string | null
  end?: string | null
  current: boolean
}
interface CompanyListType {
  id: string
  company_name: string
  company_id: string
  init_date?: string | null
  end_date?: string | null
  period?: string | null
  experiences: ExperiencesList[]
}
interface ExperiencesList {
  is_current: boolean
  role_name: string
  start_date: string
  end_date: string
  id: string
}

const route = useRoute();
const { t, locale } = useI18n();

const { data: project, error } = await useProject(String(route.params.id));

const {
  mutateAsync: patchCandidate,
} = usePatchCandidate();

const person = ref<CandidateType | null>(null);

const months = computed(() => {
  return (JSON.parse(t("date.months")) || []).map((item: string) => item.substring(0, 3).toUpperCase());
});

function getDate(date: string): string {
  if (!date || date === "")
    return "";

  const dateFormat = moment(date);
  const year = dateFormat.format("YYYY");
  const month = Number.parseInt(dateFormat.format("M")) - 1;
  const dateIn = t("date.dateIn");

  return locale.value === "pt-BR"
    ? `${capitalizeFirstLetter(months.value[month].toLowerCase())}. ${dateIn} ${year}`
    : `${year} ${dateIn} ${months.value[month]}`;
}

async function fetchCandidate() {
  if (!route.params.person)
    return;
  const response = await searchCandidates({
    project_id: String(route.params.id),
    person_id: String(route.params.person),
    project_stage: "SHORTLIST",
    skip: 0,
    limit: 1,
  });
  person.value = (response && response[0]) ? response[0] : null;
}

function sortExperiencies() {
  const workExperience = person.value?.person_data?.work_experience || [];

  const current = workExperience
    .filter(item => (item.is_current && item.start_date))
    .sort((a, b) => (b.start_date ?? "").localeCompare(a.start_date ?? ""));

  const previous = workExperience
    .filter(item => (!item.is_current && item.start_date))
    .sort((a, b) => (a.start_date ?? "").localeCompare(b.start_date ?? ""))
    .reverse();

  const dateEmpty = workExperience
    .filter(item => (!item.start_date))
    .sort((a, b) => (a.corporation_name ?? "").localeCompare(b.corporation_name ?? ""));

  return current.concat(previous, dateEmpty);
}

function paginateExperiences() {
  const paginated: CompanyListType[][] = [];

  const experiencesTotal = sortExperiencies();
  const chunked = chunk(experiencesTotal, Math.ceil(experiencesTotal.length / 2));

  let col = 0;

  paginated[col] = [];

  chunked?.forEach((experiences) => {
    experiences.forEach((item) => {
      const company_name = item?.corporation_name || "";
      const company_id = item?.corporation_id || "";
      const experience: ExperiencesList = {
        id: item?.id || "",
        is_current: item.is_current,
        role_name: item.role_name,
        start_date: item.start_date || "",
        end_date: item.end_date || "",
      };

      const lastIndexCol = findLastIndex(paginated[col]);

      if (lastIndexCol < 0) {
        paginated[col]?.push({
          id: item?.id,
          company_name,
          company_id,
          experiences: [experience],
        });
      }
      else {
        const companyData = paginated[col][lastIndexCol];
        if (company_name === companyData?.company_name) {
          paginated[col][lastIndexCol].experiences.push(experience);
        }
        else {
          paginated[col]?.push({
            id: item?.id,
            company_name,
            company_id,
            experiences: [experience],
          });
        }
      }
    });

    col++;
    paginated[col] = [];
  });

  paginated.map(cols => cols.map((items) => {
    const period: PeriodType[] = items.experiences?.reduce((acc: PeriodType[], exp) => {
      acc.push({
        init: exp.start_date,
        end: exp.end_date,
        current: exp.is_current,
      });
      return acc;
    }, []);

    items.period = getDateDif(period);
    if (items.experiences.length > 1) {
      items.init_date = items.experiences[items.experiences.length - 1]?.start_date;
      items.end_date = items.experiences[0]?.end_date;
    }
    return items;
  }));

  return paginated;
}

const workExperiences = computed<CompanyListType[][]>(() => paginateExperiences());
const hiddenExperience = ref([]) as Ref<string[]>;

function addToHidden(id: string, all: boolean = false) {
  if (!hiddenExperience.value.includes(id))
    hiddenExperience.value.push(id);

  if (all) {
    workExperiences.value.forEach(cols => cols.forEach((items) => {
      if (items.id === id) {
        items.experiences.forEach((item) => {
          if (!hiddenExperience.value.includes(item.id))
            hiddenExperience.value.push(item.id);
        });
      }
    }));
  }
}

function removeFromHidden(id: string, all: boolean = false) {
  const hidde_index = hiddenExperience.value.indexOf(id);
  if (hidde_index >= 0)
    hiddenExperience.value.splice(hidde_index, 1);

  if (all) {
    workExperiences.value.forEach(cols => cols.forEach((items) => {
      if (items.id === id) {
        items.experiences.forEach((item) => {
          const hidde_item_index = hiddenExperience.value.indexOf(item.id);
          if (hidde_item_index >= 0)
            hiddenExperience.value.splice(hidde_item_index, 1);
        });
      }
    }));
  }
}

function isHiddeExperience(id: string): boolean {
  return hiddenExperience.value.includes(id);
}

function isHiddeAllExperiences(data: CompanyListType): boolean {
  return data.experiences.every(item => hiddenExperience.value.includes(item.id));
}

const refresh = ref(0);
function toggleExperiences(id: string, all: boolean = false) {
  if (!person.value || !id)
    return;

  const { mini_cv } = person.value;

  if (hiddenExperience.value.includes(id))
    removeFromHidden(id, all);
  else
    addToHidden(id, all);

  patchCandidate({
    candidate_id: person.value?._id,
    candidate: {
      ...person.value,
      mini_cv: {
        ...mini_cv,
        hide_experiences_ids: hiddenExperience.value,
      },
    },
  }, {
    onSuccess: (data: CandidateType) => {
      person.value = data;
      hiddenExperience.value = data.mini_cv.hide_experiences_ids;
      refresh.value += 1;
    },
  });
}

watchEffect(async () => {
  await fetchCandidate();
});
watchEffect(() => {
  if (person.value)
    hiddenExperience.value = person.value.mini_cv.hide_experiences_ids;
});
</script>

<template>
  <ShortlistBody
    :project="project"
    :candidate="person"
    :error="error"
    :possible-to-edit="false"
    :show-header="false"
    @change:person="fetchCandidate"
  >
    <div class="about">
      <div class="about-head">
        <p class="title">
          <span class="about-title">
            {{ t("projects.shortlist.about.historic").toUpperCase() }}
          </span>
          <Tooltip class="tooltip" :tooltip-text="t('projects.shortlist.about.tooltip')" y-align="top" x-align="center">
            <SvgIcon icon="alert_circle_icon" width="1.25rem" height="1.25rem" margin="none" />
          </Tooltip>
        </p>
        <span class="about-head-subtitle">
          {{ t('projects.shortlist.about.label') }}
        </span>
      </div>
      <div class="about-body">
        <div v-for="(experience, index) in workExperiences" :key="index" class="column">
          <template v-for="(item) in experience" :key="item.id">
            <div class="col-item">
              <div class="item-name">
                <div class="circles yellow" :class="{ disabled: isHiddeAllExperiences(item) }" />
                <span class="text-title bold" :class="{ disabled: isHiddeAllExperiences(item) }">
                  {{ item.company_name }}
                </span>
                <template v-if="item.experiences?.length > 1">
                  <CustomCheckBox
                    :key="refresh"
                    :checked="!isHiddeAllExperiences(item)"
                    variation="secondary"
                    @on-change="toggleExperiences(item.id, true)"
                  />
                </template>
              </div>

              <template v-if="item.experiences?.length > 1">
                <div class="item-duration" :class="{ disabled: isHiddeAllExperiences(item) }">
                  {{ item.period }}
                </div>
              </template>

              <template v-for="(exp, exp_index) in item.experiences" :key="exp_index">
                <div class="item-experience" :class="{ unic: item.experiences.length === 1 }">
                  <div class="history">
                    <div class="circles gray" />
                    <div v-if="item.experiences.length - 1 !== exp_index" class="horizontal-line" />
                  </div>
                  <div class="experience-data">
                    <span class="text bold" :class="{ disabled: isHiddeExperience(exp.id) }">
                      {{ exp.role_name }}
                      <CustomCheckBox
                        variation="secondary"
                        :checked="!isHiddeExperience(exp.id)"
                        @on-change="toggleExperiences(exp.id, false)"
                      />
                    </span>
                    <span class="text text-info" :class="{ disabled: isHiddeExperience(exp.id) }">
                      <span>
                        {{ getDate(exp.start_date) }}
                      </span>
                      <span v-if="getDate(exp.end_date) !== ''"> - {{ getDate(exp.end_date) }} </span>
                      <span v-if="exp.is_current"> - {{ t("projects.shortlist.about.current") }} </span>
                      <span>
                        - {{ getDateDif([{ init: exp.start_date, end: exp.end_date, current: exp.is_current }]) }}
                      </span>
                    </span>
                  </div>
                </div>
              </template>
            </div>
          </template>
        </div>
      </div>
    </div>
  </ShortlistBody>
</template>

<route lang="yaml">
meta:
  layout: project-stage
  stage: shortlist
  tab: about
</route>

<style lang="scss" scoped>
.about {
  display: flex;
  flex-wrap: wrap;

  .about-head {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-block-end: 2.31rem;

    .about-title {
      color: $gray-dark;
      font-size: 1.25rem;
      font-weight: 700;
    }
    &-subtitle {
      font-size: 0.75rem;
    }
  }

  .text-title {
    width: inherit;
    color: $gray-dark;
    font-size: 1rem;
  }
  .text {
    width: inherit;
    color: $gray-dark;
    font-size: 0.875rem;
  }
  .text.bold,
  .text-title.bold {
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .text-info,
  .text-info span {
    font-size: 0.875rem;
  }
  .text-info.disabled span {
    color: $gray-a8;
  }

  .about-body {
    display: grid;
    width: 100%;
    gap: 4rem;
    grid-template-columns: 1fr 1fr;
    .column {
      display: flex;
      width: 100%;
      flex-direction: column;
    }

    .col-item {
      display: flex;
      width: 100%;
      flex-direction: column;
      padding-bottom: 0.5rem;

      .item-name {
        display: flex;
        width: 100%;
        flex-direction: row;
        font-size: 1rem;
        padding-block-end: 0.2rem;
      }

      .item-duration {
        display: flex;
        width: 100%;
        font-size: 0.875rem;
        padding-inline-start: 1.4rem;
        padding-block-end: 1rem;
      }
      .item-duration.disabled {
        color: $gray-a8;
      }

      .item-experience.unic {
        margin-block-start: 0.25rem;
        padding-inline-start: 1.3rem;

        .history {
          display: none;
        }

        .experience-data {
          padding-bottom: 0.75rem;
        }
      }

      .item-experience {
        display: flex;
        flex-direction: row;
        margin-top: -5px;

        .history {
          display: flex;
          width: 0.9rem;
          flex-direction: column;
          margin-right: 0.5rem;
        }

        .experience-data {
          display: flex;
          width: 100%;
          flex-direction: column;
          padding-bottom: 1.2rem;
          gap: 0.3rem;
        }
      }
    }

    .circles {
      width: 0.65rem;
      height: 0.65rem;
      min-height: 0.65rem;
      border-radius: 50%;
      margin-top: 0.3rem;
      margin-right: 0.75rem;
      aspect-ratio: 1;
    }

    .horizontal-line {
      position: relative;
      width: 1.5px;
      height: -webkit-fill-available;
      margin-left: 0.29rem;
      background: #c0c0c0;
    }

    .yellow {
      background: $yellow;
    }
    .yellow.disabled {
      background: $gray-a8;
    }

    .gray {
      background: $gray-border;
    }
  }
}
.tooltip-message {
  text-align: center;
  font-size: 0.75rem;
  font-weight: bold;
  color: #707070;
  text-transform: uppercase;
}
.tooltip {
  display: flex;
  width: 3rem;
  svg {
    stroke: $red-warning;
  }
  .tooltip-text {
    top: 0;
  }
}
.title {
  display: flex;
  align-items: center;
  margin-block-end: 0.37rem;
  gap: 0.5rem;
}
.text.bold.disabled {
  color: $gray-a8;
}
.text-title.bold.disabled {
  color: $gray-a8;
}
</style>
