<script setup lang="ts">
import bson from "bson-objectid";
import { mdiPlusCircleOutline } from "@mdi/js";
import OpinionEditable from "../OpinionEditable.vue";
import { ButtonIcon } from "@/components/button";
import { SvgIcon } from "@/components/icon";

type MotivationMapKey = keyof typeof motivationMap;

const props = defineProps<{
  motivationPackage: any
  career: any
  workEnvironment: any
  personalLife: any
  consultantOpinion: any
}>();

const emit = defineEmits(["update", "cancel"]);

const { t } = useI18n();

const career = ref([]);
const workEnvironment = ref([]);
const personalLife = ref([]);
const motivationPackage = ref({
  salary: "",
  short_term_incentive: "",
  long_term_incentive: "",
  other_incentive: "",
  total_cash: "",
});

const consultantOpinion = ref("");

const motivationMap = {
  career,
  package: motivationPackage,
  work_environment: workEnvironment,
  personal_life: personalLife,
  consultant_opinion: consultantOpinion,
};

function addItem(key: MotivationMapKey) {
  const newid = bson().toHexString();

  motivationMap[key].value[newid] = "";
  update(key);
}

function removeItem(key: MotivationMapKey, idx: number | string) {
  delete motivationMap[key].value[idx];
  update(key);
}

function update(key: MotivationMapKey) {
  emit("update", {
    [key]: motivationMap[key].value,
  });
}

watchEffect(() => {
  career.value = toRaw(props.career);
  workEnvironment.value = toRaw(props.workEnvironment);
  personalLife.value = toRaw(props.personalLife);
  motivationPackage.value = toRaw(props.motivationPackage);
  consultantOpinion.value = toRaw(props.consultantOpinion);
});
</script>

<template>
  <!-- TODO: finish and move to veevalidate -->
  <div class="form">
    <div class="form-items">
      <div class="form-item">
        <div class="item-label item-label-lg">
          {{ t("projects.construction.titles.package") }}
        </div>

        <div class="motivation-items">
          <div class="item-label">
            {{ t("projects.construction.labels.salary") }}:
          </div>
          <TextArea
            v-model.trim="motivationPackage.salary"
            rows="5"
            :placeholder="t('projects.construction.labels.salary')"
            @change="update('package')"
          />
          <div class="item-label">
            {{ t("projects.construction.labels.icp") }}:
          </div>
          <TextArea
            v-model.trim="motivationPackage.short_term_incentive"
            rows="5"
            :placeholder="t('projects.construction.labels.icp')"
            @change="update('package')"
          />
          <div class="item-label">
            {{ t("projects.construction.labels.ilp") }}:
          </div>
          <TextArea
            v-model.trim="motivationPackage.long_term_incentive"
            rows="5"
            :placeholder="t('projects.construction.labels.ilp')"
            @change="update('package')"
          />
          <div class="item-label">
            {{ t("projects.construction.labels.others") }}:
          </div>
          <TextArea
            v-model.trim="motivationPackage.other_incentive"
            rows="5"
            :placeholder="t('projects.construction.labels.otherIncentives')"
            @change="update('package')"
          />
          <div class="item-label">
            {{ t("projects.construction.labels.totalCash") }}:
          </div>
          <TextArea
            v-model.trim="motivationPackage.total_cash"
            rows="5"
            :placeholder="t('projects.construction.labels.totalCash')"
            @change="update('package')"
          />
        </div>
      </div>

      <div class="form-item">
        <div class="item-label item-label-lg">
          {{ t("projects.construction.titles.career") }}
        </div>
        <div v-for="(_, key) of career" :key="key">
          <div class="base-item">
            <TextArea
              v-model.trim="career[key]"
              class="base-item-input"
              rows="5"
              :placeholder="t('projects.construction.titles.career')"
              @change="update('career')"
            />
            <ButtonIcon @click="removeItem('career', key)">
              <SvgIcon icon="trash_icon" width="20" height="20" margin="none" />
            </ButtonIcon>
          </div>
        </div>
        <ButtonIcon class="panel-button add" @click="addItem('career')">
          <SvgIcon
            :icon="mdiPlusCircleOutline"
            type="mdi"
            width="20"
            height="20"
            margin="none"
          />
          <span>
            {{ t("text.form.addElement", { complement: t("projects.construction.titles.career") }) }}
          </span>
        </ButtonIcon>
      </div>

      <div class="form-item">
        <div class="item-label item-label-lg">
          {{ t("projects.construction.titles.environment") }}
        </div>
        <div v-for="(_, key) of workEnvironment" :key="key">
          <div class="base-item">
            <TextArea
              v-model.trim="workEnvironment[key]"
              class="base-item-input"
              rows="5"
              :placeholder="t('projects.construction.titles.environment')"
              @change="update('work_environment')"
            />
            <ButtonIcon @click="removeItem('work_environment', key)">
              <SvgIcon icon="trash_icon" width="20" height="20" margin="none" />
            </ButtonIcon>
          </div>
        </div>
        <ButtonIcon class="panel-button add" @click="addItem('work_environment')">
          <SvgIcon
            :icon="mdiPlusCircleOutline"
            type="mdi"
            width="20"
            height="20"
            margin="none"
          />
          <span>
            {{ t("text.form.addElement", { complement: t("projects.construction.titles.environment") }) }}
          </span>
        </ButtonIcon>
      </div>

      <div class="form-item">
        <div class="item-label item-label-lg">
          {{ t("projects.construction.titles.personal") }}
        </div>
        <div v-for="(_, key) of personalLife" :key="key">
          <div class="base-item">
            <TextArea
              v-model.trim="personalLife[key]"
              class="base-item-input"
              rows="5"
              :placeholder="t('projects.construction.titles.personal')"
              @change="update('personal_life')"
            />
            <ButtonIcon @click="removeItem('personal_life', key)">
              <SvgIcon icon="trash_icon" width="20" height="20" margin="none" />
            </ButtonIcon>
          </div>
        </div>
        <ButtonIcon class="panel-button add" @click="addItem('personal_life')">
          <SvgIcon
            :icon="mdiPlusCircleOutline"
            type="mdi"
            width="20"
            height="20"
            margin="none"
          />
          <span>
            {{ t("text.form.addElement", { complement: t("projects.construction.titles.personal") }) }}
          </span>
        </ButtonIcon>
      </div>
    </div>
    <OpinionEditable
      :value="consultantOpinion"
      @update:value="consultantOpinion = $event"
      @change="update('consultant_opinion')"
    />

    <div class="form-actions">
      <Button type="submit" variation="secondary" @click="$emit('cancel')">
        {{ t("text.form.conclude") }}
      </Button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.form {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  &-items {
    display: flex;
    flex-direction: row;
    gap: 2rem;
  }

  &-item {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
}

.form-actions {
  display: flex;
  margin: 2rem 0;
  gap: 2rem;
  grid-column: 1 / -1;
}
.item-label {
  color: $gray-dark;
  font-size: .875rem;
  font-weight: 700;
  margin: 1.25rem 0 0.25rem 0;
}

.item-label-lg {
  font-size: 1rem;
  text-transform: uppercase;
}

.motivation-items {
  display: flex;
  flex-direction: column;
}
button.panel-button {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  margin: 0.5rem 0 1.5rem 0;

  &:focus,
  &:hover {

    &.add,
    &.add>span {
      color: $red-violet-medium;

      svg {
        vertical-align: bottom;
      }
    }
  }

  &.add,
  &.add>span {
    color: $red-violet;
    font-size: 0.75rem;
    letter-spacing: 0;
    font-weight: 600;
    margin-inline-start: auto;

    svg {
      vertical-align: bottom;
    }
  }
}

.base-item-input {
  width: 100%;
}

.base-item {
  display: flex;
  align-items: baseline;
  gap: 0.25rem;
  margin-block: 0.5rem 1.5rem;

  .item-input {
    width: 100%;
  }
}
</style>
