<script setup lang="ts">
import { mdiPlusCircleOutline } from "@mdi/js";
import Filter from "./components/Filter/Filter.vue";
import List from "./components/List/List.vue";
import { corporationApiMethods, provideCardClickEvents } from "./Home.module";
import { ContentHeader } from "@/components/content_header";
import PageWrapper from "@/components/page_wrapper/PageWrapper.vue";
import CompanyIcon from "@/assets/images/general-company.svg";
import { ScrollToTop } from "@/components/scroll_to_top";
import CheckConflict from "@/corporations/pages/corporations/components/CheckConflict/CheckConflict.vue";

const { t } = useI18n();
const router = useRouter();
const corporationHome = ref(null);
const visibleScrollTop = ref(false);
const searchFinish = ref(false);
const searchTerm = ref("");
const allCorporations = ref(0);
const totalSearched = ref(0);
const {
  loading,
  corporations,
  loadError,
  emptySearch,
  paginationParams,
  fetchCompanies,
  fetchOnSort,
  fetchOnScroll,
  fetchOnFilter,
  resetPage,
  searchText,
  searchTotal,
  isSearching,
  isWait,
  allCorporationsLength,
} = corporationApiMethods();
const goToCreate = () => router.push({ name: "CorporationCreate" });
const checkConflict = ref(false);
const toggleCheckConflict = () => (checkConflict.value = !checkConflict.value);

provideCardClickEvents(router);

async function doSearch(search: string) {
  searchText.value = search;
  searchTerm.value = search;
  paginationParams.value.search_ground = search;
  resetPage();
  fetchCompanies();
}

async function handleScroll(event: Event) {
  const {
    scrollTop: hiddenHeight,
    scrollHeight: totalHeight,
    clientHeight: totalVisible,
  } = event.target as HTMLElement;
  const bottomReached = hiddenHeight + totalVisible + 10 >= totalHeight;
  const filtersWrapper = document.querySelector(".filters-wrapper");

  if (filtersWrapper) {
    const { offsetHeight } = filtersWrapper as HTMLElement;

    offsetHeight >= hiddenHeight
      ? (visibleScrollTop.value = false)
      : (visibleScrollTop.value = true);
  }

  if (hiddenHeight && bottomReached && !searchFinish.value && !isWait.value) {
    isWait.value = true;
    await fetchCompanies();
  }
}

onMounted(async () => {
  await fetchCompanies();
  allCorporations.value = await allCorporationsLength.value;
  if (corporationHome.value)
    fetchOnScroll(corporationHome.value.$el);
});
</script>

<template>
  <PageWrapper class="projects-home" @scroll="handleScroll($event)">
    <template #header>
      <ContentHeader
        :title="t('header.companies')"
        :buttons="[
          {
            type: 'primary',
            text: t('corporations.createCompany'),
            onClick: toggleCheckConflict,
            buttonIcon: mdiPlusCircleOutline,
          },
        ]"
        :search-total="totalSearched"
        :all-projets="allCorporations"
      >
        <template #icon>
          <CompanyIcon />
        </template>
      </ContentHeader>
    </template>

    <template #content>
      <Filter data-test="corporation-filters" @search:text="doSearch($event)" @filter="fetchOnFilter($event)" />

      <List
        data-test="list-component"
        :corporations="corporations"
        :loading="loading"
        :is-searching="isSearching"
        :search-total="searchTotal"
        @sort="fetchOnSort"
      >
        <template #tail>
          <div v-if="loadError" class="messsage">
            <span>{{ t("corporations.errorListCorporations") }}</span>
          </div>

          <div v-if="emptySearch" class="messsage">
            <span>{{ t("corporations.notFoundSearch") }}</span>
          </div>
        </template>
      </List>
      <ScrollToTop v-if="visibleScrollTop" />
    </template>
  </PageWrapper>
  <CheckConflict :visible="checkConflict" @close="toggleCheckConflict" />
</template>

<route lang="yaml">
meta:
  layout: listpage
</route>

<style scoped lang="scss">
.projects-home {
  display: flex;
  justify-content: center;
  padding-block: 2rem;
  padding-inline: auto;
  width: 100vw;
}
</style>
