<script setup lang="ts">
import { useI18n } from "vue-i18n";
import ConsultantOpinion from "@/common/components/Export/ConsultantOpinion.vue";
import { SvgIcon } from "@/components/icon";

const props = withDefaults(
  defineProps<{
    fullPage?: boolean
    isExport?: boolean
    showSeparate?: boolean
    show?: boolean
    personName?: string | null
    pageName?: string | null
    opinion?: string | null
    pageIndex?: number | null
  }>(),
  {
    show: true,
    fullPage: false,
    showSeparate: true,
    isExport: false,
    personName: null,
    pageName: null,
    opinion: null,
    pageIndex: null,
  },
);

const { t } = useI18n();

const { show, fullPage, isExport, showSeparate, personName, pageName, opinion } = toRefs(props);
</script>

<template>
  <div
    v-if="show"
    class="break-page export-page"
    :class="{
      'full-page': fullPage,
      'is-export': isExport,
    }"
  >
    <div v-if="!fullPage" class="export-page-header">
      <div class="export-page-header-title">
        <slot name="title">
          <span v-if="personName">
            <span class="title-normal"> {{ personName }} - </span>
            <span class="text-normal">{{ pageName }}</span>
            <span v-if="pageIndex && pageIndex > 0" class="text-normal">
              - {{ pageIndex + 1 }}</span>
          </span>
          <span v-else class="title-upper">
            <span>
              {{ pageName }}
              <span v-if="pageIndex && pageIndex > 0"> - {{ pageIndex + 1 }}</span>
            </span>
          </span>
        </slot>
      </div>
      <div class="export-page-header-icon">
        <a href="#FRONT_COVER">
          <div class="header-box-icon">
            <SvgIcon width="4.5rem" height="2.5rem" icon="flow_ef_yellow" />
          </div>
          <span class="confidential">{{ t("exportPDF.confidential") }}</span>
        </a>
      </div>
    </div>
    <div v-if="!fullPage" class="separate">
      <hr v-if="showSeparate">
    </div>
    <div class="export-page-body">
      <slot />
      <slot v-if="opinion" name="opinion">
        <div class="opinion-container">
          <ConsultantOpinion
            :opinion="opinion"
            font-size="0.875rem"
            linebreak
            :ellipsis="{
              maxLength: 20000,
              showEllipsis: false,
            }"
            as-export
          />
        </div>
      </slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
:global(:root) {
  --pdf-width: 100%;
  --pdf-height: 209.5mm;
}

.break-page {
  break-after: avoid;
  break-before: avoid;
  break-inside: avoid;
  page-break-before: always;
}

.export-page.is-export,
.export-page.full-page.is-export {
  border: none;
  margin-block: 0;
}

.export-page.full-page {
  height: var(--pdf-height);
  max-height: var(--pdf-height);
  padding: 0;

  .export-page-body {
    min-height: var(--pdf-height);
    max-height: var(--pdf-height);
    padding: 0;
    padding-inline: 0;
  }
}

.export-page {
  display: flex;
  overflow: hidden !important;
  width: var(--pdf-width);
  height: var(--pdf-height);
  max-height: var(--pdf-height);
  flex-direction: column;
  padding: 1rem 1.3125rem;
  border: 1px solid #c0c0c0;
  background: white;
  margin-block: 2rem;

  .separate {
    width: 100%;
    margin-block: 1.5rem;
    padding-inline: 2.1875rem;

    hr {
      border: 1px solid $gray-border;
      margin: 0;
    }
  }

  &-header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    &-title {
      padding-top: 1rem;
      padding-left: 2.1875rem;
      color: $gray-dark;
      font-size: 2rem;

      .title-normal {
        font-weight: bold;
      }

      .title-upper {
        font-weight: bold;
        text-transform: uppercase;
      }
    }

    &-icon {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 0.25rem;

      a {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 0.25rem;
        outline: none;
        text-decoration: none;
      }

      .confidential {
        color: $gray-border;
        font-size: 0.5rem;
        letter-spacing: 0.28px;
        line-height: 0.6875rem;
        text-transform: uppercase;
      }
    }
  }

  &-body {
    display: flex;
    overflow: hidden;
    min-height: 165mm;
    max-height: 165mm;
    flex-direction: column;
    justify-content: space-between;
    padding-inline: 2.1875rem;

    .opinion-container {
      margin-bottom: 1rem;
    }
  }
}
</style>
