import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import type { PatchApprovalCandidateType } from "@/common/services/candidates";

export function useScreeeningEvaluationQuery(project_id: string, person_id: string) {
  return useQuery({
    queryKey: ["projects", "screening_evaluation", project_id, "person", person_id],
    queryFn: () => getScreeeningEvaluation(project_id, person_id),
  });
}

export function useScreeeningEvaluationMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["projects", "screening_evaluation"],
    mutationFn: (data: { project_id: string; person_id: string }) => getScreeeningEvaluation(data.project_id, data.person_id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["projects", "screening_evaluation"] });
    },
  });
}

export function useScreeeningEvaluationByActivityQuery(activity_id: string) {
  return useQuery({
    queryKey: ["projects", "screening_evaluation", "activity", activity_id],
    queryFn: () => getScreeeningEvaluationByActivity(activity_id),
  });
}

export function useCreateScreeeningEvaluationQuery() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["projects", "screening_evaluation"],
    mutationFn: (organization_and_assessment: any) => addScreeeningEvaluation(organization_and_assessment),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["projects", "screening_evaluation"] });
    },
  });
}

export const isSaving = ref(false);

export function usePatchScreeeningEvaluationQuery() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["projects", "screening_evaluation"],
    mutationFn: (screening: any) => patchScreeeningEvaluation(screening._id, screening),
    onMutate: async () => {
      isSaving.value = true;
    },
    onError: () => {
      isSaving.value = false;
    },
    onSuccess: () => {
      isSaving.value = false;
      queryClient.invalidateQueries({ queryKey: ["projects", "screening_evaluation"] });
    },
  });
}

export function useScreeeningApprovalCandidateToInterview() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["projects", "screening_evaluation"],
    mutationFn: (data: { person_id: string; body: PatchApprovalCandidateType }) => patchApprovalCandidate(data.person_id, data.body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["projects", "screening_evaluation"] });
    },
  });
}
