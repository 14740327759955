<script setup lang="ts">
import ShortlistBody from "./components/ShortlistBody.vue";
import { ConsultantOpinion } from "@/components/consultant_opinion";
import type { SearchCandidate } from "@/common/composables/useShortlist";

const route = useRoute();
const { t } = useI18n();

const { data: project, error } = await useProject(String(route.params.id));

const {
  mutate: patchCandidate,
} = usePatchCandidate();

const person = ref<SearchCandidate | null>(null);

function formatScore(score: number | null | undefined) {
  try {
    if (!score)
      return 0;
    return (
      (!Number.isInteger(score) ? score?.toFixed(2).replace(".", ",") : score?.toFixed(0)) ?? 0
    );
  }
  catch (error) {
    return 0;
  }
}

function saveOpinion(value: string) {
  patchCandidate({
    candidate_id: person.value?._id,
    candidate: {
      ...person.value,
      mini_cv: {
        ...person.value?.mini_cv,
        recruiter_opinion: value,
      },
    },
  }, {
    onSuccess: async () => {
      await fetchCandidate();
    },
  });
}

async function fetchCandidate() {
  if (!route.params.person)
    return;
  const response = await searchCandidates({
    project_id: String(route.params.id),
    person_id: String(route.params.person),
    project_stage: "SHORTLIST",
    skip: 0,
    limit: 1,
  });
  person.value = (response && response[0]) ? response[0] : null;
}

watchEffect(async () => {
  await fetchCandidate();
});
</script>

<template>
  <ShortlistBody
    :project="project"
    :candidate="person"
    :error="error"
    :score="person?.calculated_scores?.final_mean"
    :possible-to-edit="true"
    :show-button-edit="false"
    :show-button-reevaluate="true"
    icon="icon_shortlist_evaluation"
    :title="t('projects.shortlist.titles.evaluation')"
    @change:person="fetchCandidate"
  >
    <div class="evaluation">
      <div class="box-item adherence">
        <div class="item-title">
          {{ t("exportPDF.labels.adherenceIdealProfile") }}
        </div>
        <div class="item-value">
          {{ formatScore(person?.calculated_scores?.final_mean) }}%
        </div>
      </div>
      <div class="box-notes">
        <div class="box-item">
          <div class="item-title">
            {{ t("exportPDF.labels.schooling") }}
          </div>
          <div class="item-value">
            {{ formatScore(person?.calculated_scores?.academic_formation_mean) }}%
          </div>
        </div>
        <div class="box-item">
          <div class="item-title">
            {{ t("exportPDF.labels.experienceProfessional") }}
          </div>
          <div class="item-value">
            {{ formatScore(person?.calculated_scores?.experiences_mean) }}%
          </div>
        </div>
        <div class="box-item">
          <div class="item-title">
            {{ t("exportPDF.labels.realizations") }}
          </div>
          <div class="item-value">
            {{ formatScore(person?.calculated_scores?.achievements_mean) }}%
          </div>
        </div>
        <div class="box-item">
          <div class="item-title">
            {{ t("exportPDF.labels.attributes") }}
          </div>
          <div class="item-value">
            {{ formatScore(person?.calculated_scores?.attributes_mean) }}%
          </div>
        </div>
        <div class="box-item">
          <div class="item-title">
            {{ t("exportPDF.labels.culturalMatch") }}
          </div>
          <div class="item-value">
            {{ formatScore(person?.calculated_scores?.cultural_match_mean) }}%
          </div>
        </div>
        <div class="box-item">
          <div class="item-title">
            {{ t("exportPDF.labels.motivation") }}
          </div>
          <div class="item-value">
            {{ formatScore(person?.calculated_scores?.motivation_mean) }}%
          </div>
        </div>
      </div>
    </div>
    <div>
      <ConsultantOpinion :opinion="person?.mini_cv?.recruiter_opinion" @onSave="saveOpinion($event)" />
    </div>
  </ShortlistBody>
</template>

<route lang="yaml">
meta:
  layout: project-stage
  stage: shortlist
  tab: evaluation
</route>

<style lang="scss" scoped>
.evaluation {
  display: flex;
  flex-direction: row;
  padding-top: 2rem;
  gap: 3.875rem;
  margin-block: 3rem;

  .box-item {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 1rem;

    .item-title {
      width: 100%;
      height: 3.25rem;
      font-size: 0.875rem;
      font-weight: bold;
      line-height: 1.5;
      text-align: center;
      text-transform: uppercase;
    }

    .item-value {
      display: flex;
      width: 100%;
      height: 3.25rem;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 1px solid $gray-border;
      border-radius: 3px;
      font-size: 1.125rem;
      font-weight: bold;
    }
  }

  .box-notes {
    display: flex;
    flex: 4;
    flex-direction: row;
    gap: 1.1875rem;

    .item-value {
      font-weight: normal;
    }
  }

  .adherence {
    min-width: 13.5rem;
    flex: 1;

    .item-title {
      padding-top: 0.8rem;
      color: $yellow;
    }

    .item-value {
      border-color: $gray-dark;
      background: $gray-dark;
      color: $yellow;
    }
  }
}
</style>
