import type { AxiosProgressEvent } from "axios";
import type { Maybe, PaginationParams, SuccessPaged } from "../types";
import { entitiesService, importCSVService } from "../client";
import { NotFoundError } from "../errors";
import type { UpdateCorporationDetails } from "./types";
import type { CorporationDetails } from "@/schemas/corporation";
import type { PROJECT_STAGE_KEYS, SearchParams } from "@/entities/project";
import type { Project, SearchTag } from "@/schemas/project";

import { ProjectSchema } from "@/schemas/project/project";
import { parseParams } from "@/utils/parsers";
import type { ExportPDF } from "@/types/export";
import { env } from "@/env";

export interface IUpdateCorporation {
  description: string | null
  historic: string
  foundation_year: string
}

const url = "/projects";

export async function listProject(params: PaginationParams = {}): Promise<SuccessPaged<Project[]>> {
  const { data } = await entitiesService.get(url, { params: { ...params } });
  return data;
}

export async function createProject(payload: { project: Partial<Project> }): Promise<Project> {
  const {
    data: { data },
  } = await entitiesService.post(url, payload);
  return data;
}

export async function searchProject(params: PaginationParams & SearchParams = {}): Promise<SuccessPaged<Project[]>> {
  const parsedParams = parseParams(params);
  const { data } = await entitiesService.get("/search/projects", { params: { ...parsedParams } });
  return data;
}

export async function getProjects(params: PaginationParams & SearchParams = {}): Promise<SuccessPaged<Project[]>> {
  const parsedParams = parseParams(params);
  const { data } = await entitiesService.get("/search/projects-cards", {
    params: { ...parsedParams },
  });
  return data;
}

export async function getProject(id: string): Promise<Maybe<Project>> {
  try {
    const {
      data: { data },
    } = await entitiesService.get(`${url}/${id}`);

    const validation = ProjectSchema.safeParse(data);

    return data;
  }
  catch (error) {
    if (error instanceof NotFoundError)
      return null;

    throw error;
  }
}

export async function updateProject(project_id: string, payload: { project: Project }): Promise<Project> {
  const {
    data: { data },
  } = await entitiesService.put(`${url}/${project_id}`, payload);
  return data;
}

export async function exportConstructionMigrate(payload: ExportPDF): Promise<{ success: boolean; blob?: Blob }> {
  try {
    const jobConstructionURI = `${env.VITE_APP_EXPORT_CONSTRUCTION_URL}/${env.VITE_APP_EXPORT_CONSTRUCTION_PATH
      }`;

    const { data } = await entitiesService.post(jobConstructionURI, payload, {
      responseType: "blob",
    });
    return { success: true, blob: data };
  }
  catch (error) {
    return { success: false };
  }
}

export async function exportShortlistMigrate(payload: ExportPDF): Promise<{ success: boolean; blob?: Blob }> {
  try {
    const jobConstructionURI = `${env.VITE_APP_EXPORT_CONSTRUCTION_URL}/${env.VITE_APP_EXPORT_SHORTLIST_PATH
      }`;

    const { data } = await entitiesService.post(jobConstructionURI, payload, {
      responseType: "blob",
    });
    return { success: true, blob: data };
  }
  catch (error) {
    return { success: false };
  }
}

export async function exportBullhornShortlistMigrate(payload: ExportPDF): Promise<{ success: boolean; blob?: Blob }> {
  try {
    const jobConstructionURI = `${env.VITE_APP_EXPORT_CONSTRUCTION_URL}/${env.VITE_APP_EXPORT_BULLHORN_SHORTLIST_PATH
      }`;

    const { data } = await entitiesService.post(jobConstructionURI, payload, {
      responseType: "blob",
    });
    return { success: true, blob: data };
  }
  catch (error) {
    return { success: false };
  }
}

export async function exportConstructionBullhorn(payload: ExportPDF): Promise<{ success: boolean; blob?: Blob }> {
  try {
    const jobConstructionURI = `${env.VITE_APP_EXPORT_CONSTRUCTION_URL}/${env.VITE_APP_EXPORT_BULLHORN_CONSTRUCTION_PATH
      }`;

    const { data } = await entitiesService.post(jobConstructionURI, payload, {
      responseType: "blob",
    });
    return { success: true, blob: data };
  }
  catch (error) {
    return { success: false };
  }
}

export async function updateProjectStatus(project_id: string, payload: { stage_from: PROJECT_STAGE_KEYS; candidates: boolean }): Promise<Project> {
  const {
    data: { data },
  } = await entitiesService.post(`${url}/${project_id}/update/status`, {
    status: payload,
  });
  return data;
}

export async function getCorporationDetail(project_id: string): Promise<Maybe<CorporationDetails>> {
  try {
    const {
      data: { data },
    } = await entitiesService.get(`${url}/${project_id}/corporation_details`);

    return data;
  }
  catch (error) {
    if (error instanceof NotFoundError)
      return null;

    throw error;
  }
}

export async function updateCorporationDetail(project_id: string, payload: UpdateCorporationDetails) {
  try {
    const response = await entitiesService.patch(`${url}/${project_id}/corporation_details`, {
      corporation_details: {
        description: payload.description,
        historic: payload.historic,
      },
    });

    return response;
  }
  catch (error) {
    if (error instanceof NotFoundError)
      return null;

    throw error;
  }
}

export async function updateSearchField(project_id: string) {
  try {
    const response = await entitiesService.post(`/projects/${project_id}/search_field`, {});

    return response;
  }
  catch (error) {
    if (error instanceof NotFoundError)
      return null;

    throw error;
  }
}

export async function suspendProject(project_id: string) {
  try {
    const response = await entitiesService.post(`/projects/${project_id}/suspend`, {});

    return response;
  }
  catch (error) {
    if (error instanceof NotFoundError)
      return null;

    throw error;
  }
}

export async function cancelProject(project_id: string) {
  try {
    const response = await entitiesService.post(`/projects/${project_id}/cancel`, {});

    return response;
  }
  catch (error) {
    if (error instanceof NotFoundError)
      return null;

    throw error;
  }
}

interface PlacementCreatePayload {
  placement_info: {
    person_id: string
    placement_start_date: string
  }
}

export async function placementCreate(project_id: string, payload: PlacementCreatePayload) {
  try {
    const response = await entitiesService.post(
      `/projects/${project_id}/placement/add-person/`,
      payload,
    );

    return response;
  }
  catch (error) {
    if (error instanceof NotFoundError)
      return null;

    throw error;
  }
}

export async function submitCsv(project_id: string | undefined, payload: File) {
  try {
    const body = new FormData();
    body.append("files", payload);
    let uploadPercentage = 0;
    const { data } = await importCSVService.post(`/candidates/spreadsheet/add-to-project/${project_id}`, body, {
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        uploadPercentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return { ...data, uploadPercentage };
  }
  catch (error) {
    return error;
  }
}

export async function getTags(params: {
  tags_text: string
  skip: number
  limit: number
}): Promise<SuccessPaged<SearchTag[]>> {
  const parsedParams = parseParams(params);
  const { data } = await entitiesService.get("/search/tag", {
    params: { ...parsedParams },
  });
  return data;
}

export async function patchOrgarnizationAndAssembly(project_id: string | undefined, payload: EventTarget | string) {
  try {
    const { data } = await entitiesService.patch(
      `/projects/${project_id}/construction/organization-and-assembly`,
      { organization_and_assembly: payload },
    );

    return { ...data };
  }
  catch (error) {
    if (error instanceof NotFoundError)
      return null;

    throw error;
  }
}
