<script lang="ts">
import type { Ref } from "vue";

import { useI18n } from "vue-i18n";
import { TextArea } from "@/components/inputs";
import { Button as ButtonDefault } from "@/components/button";
import type { Person } from "@/components/person_card/interfaces";

export default defineComponent({
  name: "SummaryEditable",
  components: {
    TextArea,
    ButtonDefault,
  },
  props: {
    onSubmit: Function,
    onCancel: Function,
  },
  setup() {
    const { t } = useI18n();
    const person = inject("selectedPerson") as Ref<Person>;
    const summaryRef = ref<InstanceType<typeof TextArea>>(null);

    const payload = computed(() => ({ description: summaryRef.value.content }));

    return {
      t,
      person,
      summaryRef,
      payload,
    };
  },
});
</script>

<template>
  <div class="summary-content">
    <div class="resume-area-group">
      <div class="container editable-forms">
        <TextArea
          :ref="
            (el) => {
              if (el) {
                el.setContent(person.description);
                summaryRef = el;
              }
            }
          "
          :warning-chars="650"
          :warning-message="t('text.textArea.defaultWarning', { count: 650 })"
        />
      </div>
      <div class="edit-actions">
        <ButtonDefault
          class="save-button"
          :text="t('persons.details.submit')"
          @click="onSubmit(payload)"
        />
        <ButtonDefault
          variation="secondary"
          :text="t('persons.details.cancel')"
          @click="onCancel()"
        />
      </div>
    </div>
  </div>
</template>

<style scoped="true" lang="scss">
.resume-area-group {
  display: flex;

  .container {
    flex: 1 0 auto;
    margin-right: 5%;
  }

  .edit-actions {
    display: flex;
    flex-direction: column;

    .save-button {
      padding-right: 1rem;
      padding-left: 1rem;
      margin-bottom: 1rem;
      white-space: nowrap;
    }

    .cancel-button {
      background-color: #c4c4c4;
      color: #666;
      opacity: 0.6;
    }
  }
}
</style>
