<script lang="ts">
import { useVModel } from "@vueuse/core";
import { useI18n } from "vue-i18n";
import SimpleAvatarInput from "@/components/inputs/modalInputs/SimpleAvatarInput.vue";

export default defineComponent({
  name: "AvatarForm",
  components: {
    SimpleAvatarInput,
  },
  props: {
    data: String,
  },
  setup(props, { emit }) {
    const { t } = useI18n();

    const data = useVModel(props, "data", emit);

    const changeAvatar = (base64) => {
      data.value = base64;
    };

    return {
      t,
      changeAvatar,
    };
  },
});
</script>

<template>
  <div class="form-wrapper">
    <h1>{{ t("persons.forms.avatar.title") }}</h1>
    <div>
      <p>{{ t("persons.forms.avatar.helpText") }}</p>
      <SimpleAvatarInput @changeAvatar="changeAvatar" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.form-wrapper {
  h1 {
    margin-bottom: 2rem;
    color: $primary;
    font-size: 18px;
    text-transform: uppercase;
  }

  div {
    padding-left: 1rem;

    p {
      margin-bottom: 1rem;
      color: $gray-medium;
      font-size: 13px;
    }
  }
}
</style>
